import { Box, createStyles } from '@mantine/core';
import React, { PropsWithChildren, useEffect, useRef, useCallback } from 'react';

export interface DropDownProps extends PropsWithChildren {
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    onMouseDown?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function DropDown(props: DropDownProps) {
    return (
        <Box
            pos="absolute"
            bg="gray.0"
            w="max-content"
            className={props.className}
            onClick={props.onClick}
            onMouseDown={props.onMouseDown}>
            {props.children}
        </Box>
    );
}

const useStyles = createStyles((theme, { isHeader }: { isHeader: boolean }) => ({
    container: {
        padding: theme.spacing.xs,
        cursor: 'pointer',
        backgroundColor: isHeader
            ? theme.colorScheme === 'dark'
                ? theme.colors.dark[3]
                : theme.colors.gray[2]
            : 'transparent',

        [!isHeader ? ':hover' : '']: {
            backgroundColor: isHeader
                ? 'transparent'
                : theme.colorScheme === 'dark'
                ? theme.colors.dark[2]
                : theme.colors.gray[1]
        }
    }
}));

export interface DropDownItemProps<T> extends PropsWithChildren {
    className?: string;
    onMouseDown?: (event: React.MouseEvent<HTMLDivElement>, value?: T) => void;
    isActive?: boolean;
    isHeader?: boolean;
    value?: T;
    onSelect?: (e: T) => void;
}

export function DropDownItem<T>({
    className,
    onMouseDown,
    isActive,
    isHeader,
    value,
    onSelect,
    children
}: DropDownItemProps<T>) {
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isActive) {
            container.current?.scrollIntoView({ block: 'nearest' });
        }
    }, [isActive]);

    const handleSelect = useCallback(() => {
        if (onSelect && value) onSelect(value);
    }, [onSelect, value]);

    const handleMouseDown = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            onMouseDown?.(event, value);
        },
        [onMouseDown, value]
    );

    const { classes } = useStyles({ isHeader: isHeader ?? false });

    return (
        <Box
            classNames={className}
            ref={container}
            onClick={handleSelect}
            className={classes.container}
            onMouseDown={handleMouseDown}>
            {children}
        </Box>
    );
}
