import { Container, Image, Title, Text, Center, Flex, Box, Stack } from '@mantine/core';
import Error from '../../assets/Error.svg';

export function BrowserNotSupportedScreen() {
    return (
        <Center w="100%" h="100svh">
            <Container>
                <Flex direction={'row'} justify={'space-between'} align={'center'}>
                    <Stack w="55%">
                        <Title>Something went wrong...</Title>
                        <Text>
                            Browser you are using is not supported by Contacts. Please upgrade your
                            browser to the latest version of Chrome, Firefox, Safari etc.
                        </Text>
                    </Stack>
                    <Box w="40%">
                        <Image src={Error} />
                    </Box>
                </Flex>
            </Container>
        </Center>
    );
}
