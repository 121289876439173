export type Job<T> =
    | {
          state: 'ready' | 'processing';
          start: () => Promise<T>;
      }
    | {
          state: 'done';
          start: () => Promise<T>;
          value:
              | {
                    success: true;
                    value: T;
                }
              | {
                    success: false;
                    error: unknown;
                };
      };
export type JobResult<T> =
    | {
          type: 'finished';
          value: T;
      }
    | {
          type: 'inprogress';
          job: Job<T>;
      };

export class JobManager {
    private jobMap = new Map<string, Job<unknown>>();

    startJob<T>(jobId: string, func: () => Promise<T>, startJustNow?: boolean) {
        const oldJob = jobId ? this.jobMap.get(jobId) : undefined;
        if (oldJob) {
            return oldJob as Job<T>;
        }
        let promise: Promise<T> | null = null;
        const job: Job<T> = {
            state: 'ready',
            start: () => {
                if (promise) {
                    return promise;
                }
                promise = (async () => {
                    job.state = 'processing';
                    try {
                        const res = await func();
                        job.state = 'done';
                        if (job.state === 'done') {
                            job.value = {
                                success: true,
                                value: res
                            };
                        }
                        return res;
                    } catch (e) {
                        job.state = 'done';
                        if (job.state === 'done') {
                            job.value = {
                                success: false,
                                error: e
                            };
                        }
                        throw e;
                    } finally {
                        this.jobMap.delete(jobId);
                    }
                })();
                return promise;
            }
        };
        if (jobId) {
            this.jobMap.set(jobId, job);
        }
        if (startJustNow) {
            job.start();
        }
        return job as Job<T>;
    }
}
