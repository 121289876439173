import { Group, MultiSelect, Text } from '@mantine/core';
import { IconLoader } from '@tabler/icons-react';
import { useState, useRef, useMemo, useCallback, forwardRef } from 'react';
import { flushSync } from 'react-dom';
import { FieldValues, FieldPath, ControllerRenderProps, Controller } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import {
    loadContacsAsync,
    selectCachedContactsAndUsers,
    selectUsernameToName
} from '../../store/DataCacheSlice';
import { Email, Username } from '../../types/Types';
import { UserUtils } from '../../utils/UserUtils';
import { UserSelectValue } from './UserSelectorValue';
import { contactToOption, querryToOption, getSelectCreateLabel } from './helpers';
import { UsersSelectorProps, UserSelectOptionType } from './types';
import { api } from '../../api/Api';
import UserAvatar from '../../atoms/UserAvatar';
import useToast from '../../hooks/useToast';
import { Validator } from '../../utils/Validator';
import { PrivateContactBadge } from '../../screens/ContactsScreen/PrivateContactBadge';

export function UsersSelector<
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>
>({
    required = true,
    control,
    name,
    contacts,
    creatable,
    hasError,
    isDisabled,
    keepCurrentUser,
    label,
    loading,
    placeholder,
    description,
    onlyStaff
}: UsersSelectorProps<TFieldValues, TName>) {
    const [creatingContactEmail, setCreatingContactEmail] = useState<Email | null>(null);
    const selectRef = useRef<HTMLInputElement>(null);
    const currentUser = useAppSelector(selectCurrentUser);
    const cachedUsersOrContacts = useAppSelector(selectCachedContactsAndUsers);
    const usersOrContacts = useMemo(() => {
        if (!onlyStaff) {
            return cachedUsersOrContacts;
        }
        return cachedUsersOrContacts.filter((x) => x.type === 'user' && x.user.role === 'staff');
    }, [cachedUsersOrContacts, onlyStaff]);

    const toast = useToast();
    const contactsNameMap = useAppSelector(selectUsernameToName);

    const mantineOptions = useMemo(() => {
        return keepCurrentUser === 'hidden'
            ? UserUtils.userOrContactToSelectOption(usersOrContacts, contactsNameMap).filter(
                  (x) => x.value !== currentUser.username
              )
            : UserUtils.userOrContactToSelectOption(usersOrContacts, contactsNameMap);
    }, [usersOrContacts, contactsNameMap, currentUser.username, keepCurrentUser]);

    const [searchValue, onSearchChange] = useState('');
    const [newUsers, setNewUsers] = useState<UserSelectOptionType[]>([]);

    const dispatch = useAppDispatch();

    const handleCreateOption = useCallback(
        async (inputValue: string, changeFun: (option: UserSelectOptionType) => void) => {
            if (contacts && !UserUtils.canCreateContact(inputValue, contacts)) {
                return;
            }
            const email = inputValue as Email;
            setCreatingContactEmail(email);
            try {
                const contact = await api.addContact(UserUtils.getNewContactModel(email));
                const contactOption = contactToOption(contact);
                changeFun(contactOption);
                flushSync(() => {
                    setNewUsers((prev) => [
                        ...prev.filter((x) => x.value === email),
                        contactOption
                    ]);
                });
                dispatch(loadContacsAsync);
                return contactOption;
            } catch (e) {
                console.error('Unable to create contact');
                if (api.getContactByEmailSync(email)) {
                    toast('Contact with given email already exists', 'error');
                } else {
                    toast('Unable to create contact, try again later', 'error');
                }
            } finally {
                setCreatingContactEmail(null);
            }
        },
        [contacts, dispatch, toast]
    );

    const handleSelectChange = useCallback(
        (labels: string[], field: ControllerRenderProps<TFieldValues, TName>) => {
            selectRef.current?.blur();
            const newValue = labels.map((value) =>
                [...mantineOptions, ...newUsers].find((o) => o?.value === value)
            );
            field.onChange(newValue);
        },
        [mantineOptions, newUsers]
    );

    const handleCreate = useCallback(
        (query: string, field: ControllerRenderProps<TFieldValues, TName>) => {
            query = query.toLocaleLowerCase();
            if (!UserUtils.canCreateContact(query, contacts || [])) return;
            const newUser = querryToOption(query, 'contact');
            handleCreateOption(query, (newVal: UserSelectOptionType) => {
                field.onChange([...field.value, newVal]);
            });
            return newUser;
        },
        [handleCreateOption, contacts]
    );

    return (
        <Controller
            control={control}
            rules={{ required: required }}
            name={name}
            render={({ field, fieldState }) => {
                return (
                    <MultiSelect
                        required={required}
                        ref={selectRef}
                        error={fieldState.error?.message ?? fieldState.error?.type}
                        icon={(creatingContactEmail || loading) && <IconLoader />}
                        label={label}
                        placeholder={placeholder}
                        description={description}
                        searchable
                        creatable={creatable}
                        data={[...mantineOptions, ...newUsers]}
                        searchValue={searchValue}
                        onSearchChange={onSearchChange}
                        valueComponent={UserSelectValue}
                        shouldCreate={(query) => {
                            if (checkIsEmailValid(query)) {
                                return true;
                            }
                            return false;
                        }}
                        nothingFound={
                            'Please provide the email address of the person you would like to send your message to.'
                        }
                        onChange={(value) => handleSelectChange(value, field)}
                        value={field.value?.map((v: UserSelectOptionType) => {
                            return v?.value;
                        })}
                        getCreateLabel={(querry) => getSelectCreateLabel(querry, contacts)}
                        itemComponent={SelectItem}
                        onCreate={(querry) => handleCreate(querry, field)}
                        filter={(query, selected, item) => {
                            if (selected) return false;
                            const contact = contacts?.find((x) => x.email === item.email);
                            const requireExactMatch =
                                contact &&
                                contact.isPrivate &&
                                !contact.privateContactVisibleForUsers.includes(
                                    api.getCurrentUserUsername()
                                );
                            if (requireExactMatch) {
                                return (
                                    item?.label?.toLowerCase() === query.toLowerCase() ||
                                    item?.email?.toLowerCase() === query.toLowerCase()
                                );
                            } else {
                                return (
                                    item?.label?.toLowerCase().includes(query.toLowerCase()) ||
                                    item?.email?.toLowerCase().includes(query.toLowerCase())
                                );
                            }
                        }}
                    />
                );
            }}
        />
    );
}
function checkIsEmailValid(email: string) {
    return Validator.isValid(email, ['email', 'required']);
}

const SelectItem = forwardRef<HTMLDivElement, UserSelectOptionType>(
    (
        { value, label, data, email, type, isPrivateContact, ...others }: UserSelectOptionType,
        ref
    ) => {
        return (
            <div ref={ref} {...others}>
                <Group noWrap>
                    <UserAvatar
                        showTooltip={false}
                        color={type === 'contact' ? 'gray' : undefined}
                        username={type === 'contact' ? undefined : (label as Username)}
                    />
                    <div>
                        <Group spacing="xs">
                            <Text size="sm">{label}</Text>
                            {isPrivateContact && <PrivateContactBadge />}
                        </Group>
                        {email !== label && (
                            <Text size="xs" color="dimmed">
                                {email}
                            </Text>
                        )}
                    </div>
                </Group>
            </div>
        );
    }
);
