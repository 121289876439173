import * as PmxApi from 'privmx-server-api';
import { EciesEncryptor } from './EciesEncryptor';
import * as privmx from 'privfs-client';
import { AttachmentMeta } from './AttachmentUtils';

export class InquiryAttachmentMetaEncryptor {
    async encrypt(
        attachmentMeta: AttachmentMeta,
        pub: privmx.crypto.ecc.PublicKey | PmxApi.api.core.EccPubKey
    ) {
        const pubKey =
            typeof pub === 'string' ? privmx.crypto.ecc.PublicKey.fromBase58DER(pub) : pub;
        return (await EciesEncryptor.encryptObjectToBase64(
            pubKey,
            attachmentMeta
        )) as PmxApi.api.attachment.AttachmentMeta;
    }

    async decrypt(
        attachmentMeta: PmxApi.api.attachment.AttachmentMeta,
        priv: privmx.crypto.ecc.PrivateKey | PmxApi.api.core.EccWif
    ) {
        const privKey =
            typeof priv === 'string' ? privmx.crypto.ecc.PrivateKey.fromWIF(priv) : priv;
        return (await EciesEncryptor.decryptObjectFromBase64(
            privKey,
            attachmentMeta
        )) as AttachmentMeta;
    }
}
