import { useModals } from '../../hooks/useModal';
import { useAppDispatch, useAppSelector } from '../../store';
import {
    selectNewMeetingModalOpen,
    selectNewMeetingModalPayload,
    selectNewMessageModalOpen,
    selectNewMessageModalPayload,
    toggleNewMeetingModal,
    toggleNewMessageModal
} from '../../store/ModalsSlice';
import { ChatAddModal } from '../ChatAddPage/ChatAddModal';
import { MeetingAddModal } from '../MeetingPage/MeetingModals/MeetingAddPage/MeetingAddModal';
import CompanyModalOutlet from './CompanyModalOutlet';
import UserSettingsModalOutlet from './UserSettingsModal/UserSettingsModalOutlet';
import { DraftsOutlet } from './DraftsOutlet';
import { NewCompanyModalOutlet } from './NewCompanyModal';
import { NewContactModalOutlet } from './NewContactModal';
import { ShareAccessModal } from './ShareAccesModal/ShareAccessModal';
import { PostAddContactsModalOutlet } from './PostAddContactsModal';
import { AccountsAddedOutlet } from './AccountsAdded';
import AdminPanelModal from './AdminPanelModal/AdminPanelModal';

export default function ModalsOutlet() {
    const { modals, onFocus } = useModals();
    const dispatch = useAppDispatch();
    const newMessageOpen = useAppSelector(selectNewMessageModalOpen);
    const newMessagePayload = useAppSelector(selectNewMessageModalPayload);

    const newMeetingOpen = useAppSelector(selectNewMeetingModalOpen);
    const newMeetingPayload = useAppSelector(selectNewMeetingModalPayload);

    return (
        <>
            <UserSettingsModalOutlet />
            <AdminPanelModal />
            <CompanyModalOutlet />
            <DraftsOutlet />
            <NewCompanyModalOutlet />
            <NewContactModalOutlet />
            <PostAddContactsModalOutlet />
            <ShareAccessModal />
            <AccountsAddedOutlet />
            {newMessageOpen && (
                <ChatAddModal
                    opened={newMessageOpen}
                    data={newMessagePayload}
                    index={1}
                    onClose={() => dispatch(toggleNewMessageModal({ open: false }))}
                    onFocus={() => {}}
                />
            )}
            {newMeetingOpen && (
                <MeetingAddModal
                    opened={newMeetingOpen}
                    data={newMeetingPayload}
                    index={1}
                    onClose={() => dispatch(toggleNewMeetingModal({ open: false }))}
                    onFocus={() => {}}
                />
            )}

            {modals
                .filter((modal) => !!modal)
                .map((modal, i) => {
                    const Component = modal.component;
                    return (
                        <Component
                            onFocus={() => onFocus(i)}
                            index={i}
                            key={modal.id}
                            onClose={modal.onClose}
                            data={modal.data}
                        />
                    );
                })}
        </>
    );
}
