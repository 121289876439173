import * as types from '../../types/Types';
import { PdfFileViewer } from './PdfFileViewer';

export interface FileViewerProps {
    file: types.AttachmentEx | types.InquiryAttachmentEx;
    isVisible: boolean;
}

export interface FileViewer {
    component: React.ComponentType<FileViewerProps>;
}

export class FileViewers {
    private static readonly viewers: { [mimetype: string]: FileViewer } = {
        'application/pdf': { component: PdfFileViewer }
    };

    static hasViewer(mimetype: types.Mimetype): boolean {
        return mimetype in this.viewers;
    }

    static getViewer(mimetype: types.Mimetype): FileViewer | undefined {
        return this.viewers[mimetype];
    }
}
