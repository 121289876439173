import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './Store';
import * as PmxApi from 'privmx-server-api';
import { MeetingId } from '../types/Types';
import { modalStore } from '../hooks/useModal';
import { VideoConferenceModal } from '../components/VideoConference/VideoConferenceModal';

export type VideoRoomInfo = PmxApi.api.videoV2.RoomInfo<PmxApi.api.videoV2.ThreadResourceId>;
export type VideoRoomState = 'nonexistent' | 'available' | 'joining' | 'active';
export interface VideoConferenceType {
    meetingId: MeetingId;
    meetingTitle: string;
    thread: PmxApi.api.thread.Thread;
    creating: boolean;
}

export interface VideoState {
    availableRooms: VideoRoomInfo[];
    lobbyMetadata: VideoConferenceType | null;
}

const initialState: VideoState = {
    availableRooms: [],
    lobbyMetadata: null
};

export const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {
        joinMeetingLobby: (state, action: PayloadAction<VideoConferenceType>) => {
            state.lobbyMetadata = action.payload;
        },
        leaveMeetingLobby: (state) => {
            state.lobbyMetadata = null;
        },
        setAvailableRooms: (state, action: PayloadAction<VideoRoomInfo[]>) => {
            state.availableRooms = action.payload;
        },
        setAvailableRoom: (state, action: PayloadAction<VideoRoomInfo>) => {
            const idx = state.availableRooms.findIndex(
                (room) => room.resourceId.id === action.payload.resourceId.id
            );
            const roomExists = action.payload.users.length > 0;
            if (roomExists) {
                if (idx >= 0) {
                    state.availableRooms[idx] = action.payload;
                } else {
                    state.availableRooms.push(action.payload);
                }
            } else if (idx >= 0) {
                state.availableRooms.splice(idx, 1);
            }
        },
        resetVideoState: () => {
            return initialState;
        }
    }
});

export const {
    setAvailableRooms,
    setAvailableRoom,
    resetVideoState,
    joinMeetingLobby,
    leaveMeetingLobby
} = videoSlice.actions;

export const selectVideoSlice = (state: RootState) => state.video;

export const selectAvailableRooms = createSelector(
    [selectVideoSlice],
    (state) => state.availableRooms
);
// export const _selectAvailableRooms = (state: RootState) => state.video.availableRooms;
export const selectLobbyMetadata = createSelector(
    [selectVideoSlice],
    (state) => state.lobbyMetadata
);

export const selectVideoRoomState =
    (meetingId: MeetingId) =>
    (state: RootState): VideoRoomState => {
        const currModal = modalStore.getModals().find((x) => x?.component === VideoConferenceModal);
        const conferenceMeetingId = currModal?.data.videoConference.meetingId;
        const room = state.video.availableRooms.find((x) => x.resourceId.id === (meetingId as any));
        if (room) {
            if (room.resourceId.id === (conferenceMeetingId as string)) {
                return 'active';
            } else {
                return 'available';
            }
        } else {
            if (meetingId === conferenceMeetingId) {
                return 'joining';
            } else {
                return 'nonexistent';
            }
        }
    };

export default videoSlice.reducer;
