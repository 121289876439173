import { BaseModalData } from '../../types/Modals';
import Modal from '../../atoms/Modal';
import StateView from '../../atoms/State';
import useLoader from '../../hooks/useLoader';
import FormCreatorView from './FormCreatorView';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface FormAddModalData extends BaseModalData {}

export function FormAddModal(props: {
    onClose: () => void;
    data: FormAddModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useLoader(useCallback((x) => x.loadFormAdding(), []));
    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            width={props.data.width ?? window.innerWidth * 0.8}
            height={props.data.height ?? window.innerHeight * 0.85}
            fullWidth
            resizable
            title={props.data.title ?? t('modal.formAdd.title')}>
            <StateView state={state} retry={revalidate}>
                {(data) => (
                    <FormCreatorView
                        id={data.form.id}
                        name={data.form.name}
                        questions={data.form.questions}
                        tags={data.form.tags}
                        managers={data.form.managers}
                        users={data.form.users}
                        managerList={data.managers}
                        userList={data.users}
                        onClose={props.onClose}
                    />
                )}
            </StateView>
        </Modal>
    );
}
