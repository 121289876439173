import { Utils } from '../../api/privmx/utils/Utils';
import { useCallback, useMemo, useEffect } from 'react';
import StateView from '../../atoms/State';
import { LoadingMeetingList } from '../../components/Loading/LoadingSection';
import { modifyState } from '../../hooks/useLoader';
import { useAppDispatch } from '../../store';
import { setMeetingTags } from '../../store/ModalsSlice';
import MeetingsScreenView from './MeetingScreenView';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { setContacts } from '../../store/DataCacheSlice';

export default function MeetingScreen() {
    const { state, revalidate, revalidateQuiet } = useDataSubscribion(
        useCallback((x) => x.subscribeForMeetings(), [])
    );
    const { state: users } = useDataSubscribion(useCallback((x) => x.subscribeForContacts(), []));

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (users.type === 'done') dispatch(setContacts(users.data));
    }, [dispatch, users]);

    const meetingsState = useMemo(
        () =>
            modifyState(state, (data) => {
                const allTags = Utils.unique(data.meetings.map((x) => x.tags).flat());

                dispatch(setMeetingTags(allTags));
                return data.meetings.filter((x) => !x.archived);
            }),
        [state, dispatch]
    );

    return (
        <StateView state={meetingsState} retry={revalidate} loading={LoadingMeetingList}>
            {(meetings) => {
                return <MeetingsScreenView revalidate={revalidateQuiet} meetings={meetings} />;
            }}
        </StateView>
    );
}
