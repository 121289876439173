export class Deferred<T> {
    
    private promise: Promise<T>;
    private promiseResolver: (value: T) => void = () => {};
    private promiseRejector: (reason?: any) => void = () => {};
    
    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.promiseResolver = resolve;
            this.promiseRejector = reject;
        });
    }
    
    getPromise(): Promise<T> {
        return this.promise;
    }
    
    resolve(value: T): void {
        this.promiseResolver(value);
    }
    
    reject(reason?: any): void {
        this.promiseRejector(reason);
    }
    
    static delay(ms: number): Promise<void> {
        const deferred = new Deferred<void>();
        setTimeout(() => {
            deferred.resolve();
        }, ms);
        return deferred.promise;
    }
    
}
