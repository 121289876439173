import { Paper, rem, Box, Text, ScrollArea } from '@mantine/core';
import { Editor } from '@tiptap/react';
import { forwardRef, useState, useEffect, useImperativeHandle, useRef, useCallback } from 'react';
import HoverContainer from '../../mantineAtoms/HoverContainer';

export const MentionList = forwardRef(
    (
        props: {
            items: Array<{ username?: string; email: string }>;
            editor: Editor;
        },
        ref
    ) => {
        const scrollContainer = useRef<HTMLDivElement>(null);
        const [selectedIndex, setSelectedIndex] = useState(0);

        const selectItem = (index: number) => {
            const item = props.items[index];

            if (item) {
                (props as any).command({ id: item.username });
            }
        };

        const upHandler = () => {
            setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
        };

        const downHandler = () => {
            setSelectedIndex((selectedIndex + 1) % props.items.length);
        };

        const enterHandler = () => {
            selectItem(selectedIndex);
        };

        useEffect(() => setSelectedIndex(0), [props.items]);

        useImperativeHandle(ref, () => ({
            onKeyDown: ({ event }: { event: KeyboardEvent }) => {
                if (event.key === 'ArrowUp') {
                    upHandler();
                    return true;
                }

                if (event.key === 'ArrowDown') {
                    downHandler();
                    return true;
                }

                if (event.key === 'Enter') {
                    enterHandler();
                    return true;
                }

                return false;
            }
        }));

        const scrollToElement = useCallback((top: number) => {
            scrollContainer.current?.scrollTo({ top: top });
        }, []);

        return (
            <Paper>
                <ScrollArea h={180} viewportRef={scrollContainer}>
                    {props.items.length ? (
                        props.items.map((item, index) => (
                            <MentionListElement
                                onClick={() => selectItem(index)}
                                key={index}
                                scrollFn={scrollToElement}
                                hovered={index === selectedIndex}>
                                {item.username}
                                <Text span size="xs" ml="xs">
                                    {item.email}
                                </Text>
                            </MentionListElement>
                        ))
                    ) : (
                        <Box p="xs" pb={0}>
                            No result
                        </Box>
                    )}
                </ScrollArea>
            </Paper>
        );
    }
);

export function MentionListElement({
    hovered,
    onClick,
    children,
    scrollFn
}: {
    hovered: boolean;
    onClick: VoidFunction;
    children: React.ReactNode;
    scrollFn: (top: number) => void;
}) {
    const first = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (hovered && first.current) scrollFn(first.current?.offsetTop);
    }, [hovered, scrollFn]);

    return (
        <div ref={first}>
            <HoverContainer
                styles={{ link: { padding: rem(8), cursor: 'pointer' } }}
                hovered={hovered}>
                <Text size="sm" onClick={onClick}>
                    {children}
                </Text>
            </HoverContainer>
        </div>
    );
}
