import { Global } from '@emotion/react';
import { CSSObject, MantineTheme, MantineThemeOverride, rem } from '@mantine/core';
import { CustomizationProvider } from './api/customization/CustomizationProvider';

// brand: [
//     '#E5E4F0',
//     '#D5D4E8',
//     '#C6C4DF',
//     '#B6B4D7',
//     '#A6A3CE',
//     '#9793C5',
//     '#8B87BC',
//     '#7E7AB3',
//     '#726EAA',
//     '#6561A1'
// ]
// [
//     '#F8F0FF',
//     '#EAE0F2',
//     '#D1BFDD',
//     '#B69DC8',
//     '#9F7FB6',
//     '#916CAB',
//     '#8A63A6',
//     '#785292',
//     '#6B4883',
//     '#5D3C75'
// ];
export function getHoverColor(theme: MantineTheme) {
    return theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0];
}

export function getActiveColor(theme: MantineTheme) {
    return theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1];
}

export function getRootHoverColors(theme: MantineTheme): Record<string, CSSObject> {
    return {
        root: {
            '&:hover': {
                backgroundColor: getHoverColor(theme)
            }
        }
    };
}

export const mantineOtherOverride = {
    getHoverColor,
    getActiveColor
};

export const customTheme = (
    colorScheme: MantineThemeOverride['colorScheme']
): MantineThemeOverride => ({
    fontFamily: 'Aneba, arial, sans-serif',
    colorScheme,
    colors: {
        brand: CustomizationProvider.get().getBrandColors()
    },
    primaryShade: {
        light: 5,
        dark: 8
    },
    primaryColor: 'brand',
    components: {
        MultiSelect: {
            styles: {
                label: { textTransform: 'capitalize' },
                values: { maxHeight: 68, overflowY: 'auto' }
            }
        },
        Input: {
            styles: (theme, params, context) => {
                return {
                    input: {
                        '&::placeholder': {
                            color: theme.colorScheme === 'dark' ? 'inherit' : theme.colors.gray[5]
                        }
                    }
                };
            }
        },
        Avatar: {
            defaultProps(theme) {
                return {
                    variant: theme.colorScheme === 'dark' ? 'filled' : 'light'
                };
            },
            styles(theme, params, context) {
                return {
                    placeholder: {
                        opacity: 0.8
                    }
                };
            }
        }
    },
    other: mantineOtherOverride,
    white: '#FCFCFD',
    black: '#212529',
    globalStyles: (theme) => ({
        '.withHover': {
            '&:hover': {
                backgroundColor: getHoverColor(theme)
            }
        },
        '.form-box': {
            width: '40%',
            boxShadow: theme.shadows.lg,
            marginBottom: theme.spacing.md,
            padding: theme.spacing.xl,
            paddingLeft: theme.spacing.md,
            marginInline: 'auto'
        },
        '.rich-editor-quote': {
            padding: theme.spacing.md,
            borderLeft: `${rem(4)} solid ${theme.colors[theme.primaryColor][1]}`
        },
        '.tippy-content': {
            backgroundColor: theme.white,
            color: theme.colors.dark[9],
            borderBottomColor: theme.colors.gray[0],
            boxShadow: theme.shadows.sm,
            padding: 0
        },
        '.tippy-box[data-placement^=top]>.tippy-arrow:before': {
            backgroundColor: theme.white,
            color: theme.colors.dark[9],
            borderTopColor: 'white'
        },
        '.tippy-content > .answer-author': {
            padding: theme.spacing.xs
        },
        '.unread-row': {
            fontWeight: 'bolder'
        }
    })
});

export const TableStyles = () => (
    <Global
        styles={(theme) => ({
            '.ag-theme-alpine': {
                '--ag-foreground-color': 'var(--custom-form-foreground)',
                '--ag-background-color': 'var(--custom-form-bg)',
                '--ag-header-background-color': 'var(--custom-form-header)',
                '--ag-odd-row-background-color': 'var(--custom-form-odd-row)',
                '--ag-border-color': 'transparent',
                '--ag-row-border-color': 'transparent',
                '--ag-row-hover-color': `${theme.fn.lighten(theme.colors['brand'][1], 0.7)}`,
                '--ag-alpine-active-color': `${theme.fn.lighten(theme.colors['brand'][5], 0.3)}`,
                '--ag-selected-row-background-color': `${theme.fn.lighten(
                    theme.colors['brand'][2],
                    0.5
                )}`,
                '--ag-input-focus-border-color': `${theme.fn.lighten(
                    theme.colors['brand'][2],
                    0.3
                )}`
            }
        })}
    />
);
