import { Center, Group, Modal, ModalProps } from '@mantine/core';
import React from 'react';

export interface ConfirmModalProps extends React.PropsWithChildren, ModalProps {}

function ConfirmModal({ children, ...props }: ConfirmModalProps) {
    return (
        <Modal withCloseButton={false} centered zIndex={500} {...props}>
            <Center>
                <Group h="fit-content">{children}</Group>
            </Center>
        </Modal>
    );
}

export default ConfirmModal;
