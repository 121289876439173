import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { api } from '../../api/Api';
import { MeetingId } from '../../types/Types';
import { Anchor } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { notifications } from '@mantine/notifications';

export interface VideoConferenceToastProps {
    toastId: string;
    meetingId: MeetingId;
    meetingTitle: string;
}

export default function VideoConferenceToast(props: VideoConferenceToastProps) {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleOpenMeetingClick = useCallback(() => {
        notifications.hide(props.toastId);
        navigate(`/meetings/${props.meetingId}`);
    }, [navigate, props.toastId, props.meetingId]);

    const location = useLocation();

    useEffect(() => {
        if (
            location.pathname.split('/').find((x) => x === 'meetings') &&
            location.pathname.split('/').find((x) => x === props.meetingId)
        ) {
            notifications.hide(props.toastId);
        }
    }, [location.pathname, props.meetingId, props.toastId]);

    return (
        <Anchor component={'button'} onClick={handleOpenMeetingClick}>
            {t('action.goToMeeting')}
        </Anchor>
    );
}

export async function showVideoConferenceToast(meetingId: MeetingId) {
    const meeting = await api.getMeeting(meetingId);
    if (!meeting) {
        return;
    }
    const meetingTitle = meeting.title;

    notifications.show({
        id: `meeting-started-${meetingId}`,
        title: `${meetingTitle} Meeting has just started`,
        message: (
            <VideoConferenceToast
                toastId={`meeting-started-${meetingId}`}
                meetingId={meetingId}
                meetingTitle={meetingTitle}
            />
        ),
        withCloseButton: true,
        autoClose: 10000
    });
}
