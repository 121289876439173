import { useCallback, useState } from 'react';
import { SharedFile, SharedFileId } from '../../types/Types';
import { DetailLink } from '../../components/DetailLink';
import { api } from '../../api/Api';
import Icon, { iconAdd, iconTrash } from '../../components/Icon';
import useModal from '../../hooks/useModal';
import SharedFilePage from '../../components/SharedFilePage';
import { ActionIcon, Button, Flex } from '@mantine/core';
import RouteListContainer from '../../atoms/RouteScreen/RouteListContainer';
import ListElement from '../../mantineAtoms/ListElement';
import ConfirmModal from '../../atoms/ConfirmModal';
import useKeyNavigation from '../../hooks/useKeyNavigation';
import Resizer from '../../atoms/Resizer';
import ScreenScrollContainer from '../../mantineAtoms/ScreenScrollContainer';
import { useSortSharedFiles } from '../../hooks/useSort';
import { useTranslation } from 'react-i18next';

interface SharedFilesScreenViewProps {
    sharedFiles: SharedFile[];
    activeSharedFileId?: SharedFileId;
    onSharedFileChoose: (sharedFile: SharedFile) => void;
    filter: string;
    onFilterSubmit?: (filter: string) => void;
    onRefresh: () => void;
}

export default function SharedFilesScreenView(props: SharedFilesScreenViewProps) {
    return (
        <>
            <Flex pos="relative">
                <Resizer>
                    <Resizer.Left>
                        <SharedFilesList {...props} />
                    </Resizer.Left>

                    <Resizer.Right>
                        {props.activeSharedFileId && (
                            <SharedFilePage id={props.activeSharedFileId} />
                        )}
                    </Resizer.Right>
                </Resizer>
            </Flex>
        </>
    );
}

export function SharedFilesList(props: SharedFilesScreenViewProps) {
    const { t } = useTranslation();
    const [confirmDeleteSharedFile, setConfirmDeleteSharedFile] = useState<SharedFile | null>(null);
    const modalService = useModal();
    const onSharedFileChoose = props.onSharedFileChoose;
    const sharedFiles = useSortSharedFiles(props.sharedFiles);

    const { currentIndex, clicked, overrideIndex } = useKeyNavigation(
        props.sharedFiles.length,
        useCallback(
            (i) => onSharedFileChoose(props.sharedFiles[i]),
            [onSharedFileChoose, props.sharedFiles]
        ),
        useCallback((id) => props.sharedFiles.findIndex((x) => x.id === id), [props.sharedFiles])
    );
    const handleElementClick = useCallback(
        (sharedFile: SharedFile, e: React.MouseEvent) => {
            if (e.altKey) {
                modalService.openSharedFileModal({ id: sharedFile.id });
            } else {
                clicked.current = true;
                onSharedFileChoose(sharedFile);
                overrideIndex(props.sharedFiles.findIndex((x) => x.id === sharedFile.id));
            }
        },
        [onSharedFileChoose, modalService, clicked, overrideIndex, props.sharedFiles]
    );

    const handleAddButtonClick = useCallback(() => {
        modalService.openAddSharedFileModal({});
    }, [modalService]);

    const handleMaybeDeleteClick = useCallback(
        async (sharedFile: SharedFile, e: React.MouseEvent) => {
            e.stopPropagation();
            setConfirmDeleteSharedFile(sharedFile);
        },
        []
    );

    const onRefresh = props.onRefresh;
    const handleConfirmDeleteClick = useCallback(async () => {
        if (!confirmDeleteSharedFile) {
            return;
        }
        setConfirmDeleteSharedFile(null);
        await api.deleteSharedFile(confirmDeleteSharedFile.id);
        onRefresh();
    }, [confirmDeleteSharedFile, onRefresh]);

    const handleConfirmDeleteModalClose = useCallback(() => {
        setConfirmDeleteSharedFile(null);
    }, []);

    return (
        <>
            <RouteListContainer>
                <ScreenScrollContainer>
                    {sharedFiles.map((item, i) => (
                        <SharedFileLink
                            hovered={currentIndex === i}
                            key={item.id}
                            item={item}
                            active={item.id === props.activeSharedFileId}
                            handleElementClick={handleElementClick}
                            onDelete={handleMaybeDeleteClick}
                        />
                    ))}
                </ScreenScrollContainer>
                <ActionIcon
                    onClick={handleAddButtonClick}
                    size={'lg'}
                    pos="absolute"
                    bottom={30}
                    right={30}
                    variant="filled"
                    radius="xl">
                    <Icon icon={iconAdd} />
                </ActionIcon>
            </RouteListContainer>
            {confirmDeleteSharedFile && (
                <ConfirmModal
                    onClose={handleConfirmDeleteModalClose}
                    opened={Boolean(confirmDeleteSharedFile)}
                    title={t('question.deleteSharedFileWithName', {
                        name: confirmDeleteSharedFile.userData.title
                    })}>
                    <>
                        <Button onClick={handleConfirmDeleteClick}>{t('yes')}</Button>
                        <Button onClick={handleConfirmDeleteModalClose}>{t('no')}</Button>
                    </>
                </ConfirmModal>
            )}
        </>
    );
}

function SharedFileLink(props: {
    active: boolean;
    item: SharedFile;
    handleElementClick: (sharedFile: SharedFile, e: React.MouseEvent) => void;
    onDelete: (sharedFile: SharedFile, e: React.MouseEvent) => void;
    hovered?: boolean;
}) {
    const onDelete = props.onDelete;
    const handleMaybeDeleteClick = useCallback(
        (e: React.MouseEvent) => {
            onDelete(props.item, e);
        },
        [onDelete, props.item]
    );
    return (
        <DetailLink
            onClick={props.handleElementClick as any}
            element={props.item}
            data-shared-file-id={props.item.id}>
            {(onClick) => (
                <ListElement
                    hovered={props.hovered}
                    onClick={onClick}
                    active={props.active}
                    title={props.item.userData.title}
                    actionIcons={
                        <>
                            <ActionIcon>
                                <Icon onClick={handleMaybeDeleteClick} icon={iconTrash} />
                            </ActionIcon>
                        </>
                    }
                />
            )}
        </DetailLink>
    );
}
