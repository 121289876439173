import { createStyles, Flex, FlexProps } from '@mantine/core';
import React from 'react';

type Props = FlexProps;

const useStyles = createStyles((theme) => ({
    container: {
        justifyContent: 'space-between',
        height: '100svh',
        borderRight: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
        }`,
        position: 'relative',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
    }
}));

export default function RouteListContainer({ children, className, ...rest }: Props) {
    const { classes, cx } = useStyles();
    return (
        <Flex className={cx(classes.container, className)} direction="column" {...rest}>
            {children}
        </Flex>
    );
}
