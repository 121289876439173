import * as privmx from 'privfs-client';
import { UUID } from '../../../utils/UUID';
import { Utils } from '../utils/Utils';

export class CosignersChecker {
    constructor(
        private authData: privmx.types.core.UserDataEx,
        private srpSecure: privmx.core.PrivFsSrpSecure
    ) {}

    async check() {
        if (!this.authData.myData.raw.isAdmin) {
            return;
        }
        const publishedCosignersResult = await Utils.tryPromise(() =>
            this.srpSecure.getPublishedCosigners()
        );
        if (
            publishedCosignersResult.success === true &&
            Object.keys(publishedCosignersResult.result).length > 0
        ) {
            return;
        }
        console.debug(
            'Invalid cosigners state, fixing...',
            publishedCosignersResult.success === false
                ? publishedCosignersResult.error
                : 'Empty cosigners list'
        );
        await this.publish();
    }

    private async publish() {
        const host = this.srpSecure.gateway.getHost();
        const keystore = await this.getServerKeystoreWithoutCosigners(host);
        const cosigners: { [domain: string]: privmx.pki.Types.keystore.IKeyStore2 } = {};
        cosigners[host] = keystore;
        const data = {
            state: 'ACTIVE',
            uuid: UUID.generateUUID(),
            hashmail: this.authData.identity.hashmail,
            keystore: keystore
        };
        try {
            await Promise.all([
                this.srpSecure.publishCosigners(this.authData.identityKeypair, cosigners),
                this.srpSecure.setCosigner(host, data)
            ]);
        } catch (err) {
            console.error('Cannot publish cosigner', err);
        }
    }

    private async getServerKeystoreWithoutCosigners(domain: string) {
        const privmxPKI = privmx.core.PrivFsSrpSecure.createDefaultPki(this.srpSecure.gateway);
        await privmxPKI.setOptions({ domain: domain });
        const result = await privmxPKI.getServerKeyStore();
        return result.keystore as privmx.pki.Types.keystore.IKeyStore2;
    }
}
