import { useCallback, useEffect, useMemo } from 'react';
import { Utils } from '../../api/privmx/utils/Utils';
import StateView from '../../atoms/State';
import LoadingThreadsList from '../../components/Loading/LoadingSection';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { useSearchParamsEx, modifyState } from '../../hooks/useLoader';
import { useAppDispatch } from '../../store';
import { setChatTags } from '../../store/ModalsSlice';
import { Chat } from '../../types/Types';
import ChatsScreenView from './ChatScreenView';
import { setCompanies, setContacts } from '../../store/DataCacheSlice';
import { useGetUnreadThreadIds } from '../../hooks/useIsUnread';
import { api } from '../../api/Api';
import { useSearchParams } from 'react-router-dom';

export default function ChatScreen() {
    const [serarchParams] = useSearchParams();

    const { state, revalidate, revalidateQuiet } = useDataSubscribion(
        useCallback((x) => x.subscribeForChats(), [])
    );
    const { state: users } = useDataSubscribion(useCallback((x) => x.subscribeForContacts(), []));
    const { state: companies } = useDataSubscribion(
        useCallback((x) => x.subscribeForCompanies(), [])
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (users.type === 'done') dispatch(setContacts(users.data));
    }, [dispatch, users]);

    useEffect(() => {
        if (companies.type === 'done') dispatch(setCompanies(companies.data));
    }, [dispatch, companies]);

    const unreadThreads = useGetUnreadThreadIds(
        state.type === 'done' ? state.data.chats.map((x) => x.id) : []
    );

    const { navigateKeepingSearch } = useSearchParamsEx();
    const filterParam = serarchParams.get('filter') || 'all';
    const chatsState = useMemo(
        () =>
            modifyState(state, (data) => {
                const allTags = Utils.unique(data.chats.map((x) => x.tags).flat());
                dispatch(setChatTags(allTags));
                const filteredChats = data.chats.filter((thread) => {
                    const isThreadUnread =
                        unreadThreads.find((unreadThread) => unreadThread === thread.id) !==
                        undefined;

                    if (isThreadUnread && thread.archived) {
                        api.setChatArchived(thread.id, false);
                        thread.archived = false;
                    }

                    if (filterParam === 'archived') {
                        return thread.archived;
                    }
                    if (filterParam === 'favorite') {
                        return thread.pinned;
                    }
                    return isThreadUnread ? true : !thread.archived;
                });

                return filteredChats;
            }),
        [state, dispatch, filterParam, unreadThreads]
    );
    const handleChatChoose = useCallback(
        (chat: Chat) => navigateKeepingSearch(`./${chat.id}`),
        [navigateKeepingSearch]
    );

    return (
        <StateView state={chatsState} retry={revalidate} loading={LoadingThreadsList}>
            {(chats) => {
                return (
                    <ChatsScreenView
                        revalidate={revalidateQuiet}
                        chats={chats}
                        onChatChoose={handleChatChoose}
                    />
                );
            }}
        </StateView>
    );
}
