import * as PmxApi from 'privmx-server-api';
import { BaseApi } from './BaseApi';

export class ConfigApi extends BaseApi implements PmxApi.api.config.IConfigApi {
    getConfig(): Promise<PmxApi.api.config.ServerConfig> {
        return this.request('getConfig', {});
    }

    getUserConfig(): Promise<PmxApi.api.config.UserConfig> {
        return this.request('getUserConfig', {});
    }

    getConfigEx(): Promise<PmxApi.api.config.ConfigEx> {
        return this.request('getConfigEx', {});
    }
}
