import { MutableCollection } from '../utils/collection/MutableCollection';
import { Utils } from '../utils/Utils';
import * as privmx from 'privfs-client';
import { KvdbCache } from './KvdbCache';
import { KvdbStateService } from './KvdbStateService';
import * as PmxApi from 'privmx-server-api';
import { IWhenable, runAsync } from '../utils/async';

export class KvdbCollection<T extends privmx.types.db.KvdbEntry> {
    collection: MutableCollection<T>;
    seq: number;

    constructor(public kvdb: privmx.db.KeyValueDb<T>, private cache: KvdbCache) {
        this.seq = 0;
        this.collection = new MutableCollection<T>();
    }

    get dbId() {
        return this.kvdb.dbId;
    }

    get extKey() {
        return this.kvdb.extKey;
    }

    get newlyCreated() {
        return this.kvdb.newlyCreated;
    }

    static async createAndInit<T extends privmx.types.db.KvdbEntry>(
        kvdb: privmx.db.KeyValueDb<T>,
        cache: KvdbCache,
        kvdbStateService: KvdbStateService
    ) {
        const res = new KvdbCollection(kvdb, cache);
        await res.tryLoadCache();
        if (res.seq !== kvdbStateService.getState(res.dbId as PmxApi.api.kvdb.KvdbId)) {
            await res.refresh();
        }
        return res;
    }

    getKvdb() {
        return this.kvdb;
    }

    async getAll(): Promise<T[]> {
        return this.collection.list.slice();
    }

    async get(key: string): Promise<T | undefined> {
        return this.getSync(key);
    }

    async opt(key: string, defaultValue: T): Promise<T> {
        return this.optSync(key, defaultValue);
    }

    hasSync(key: string): boolean {
        return this.getSync(key) != null;
    }

    getAllSync(): T[] {
        return this.collection.list.slice();
    }

    getSync(key: string): T | undefined {
        return this.collection.find((x) => !!x.secured && x.secured.key === key);
    }

    getSyncWithCheck(key: string): T {
        const res = this.getSync(key);
        if (res == null) {
            throw new Error("Cannot get entry with key '" + key + "'");
        }
        return res;
    }

    optSync(key: string, defaultValue: T): T {
        const res = this.getSync(key);
        return res ? res : defaultValue;
    }

    async set(key: string, value: T, useVersion?: boolean) {
        const entryKey = await this.kvdb.getEntryKey(key);
        return this.kvdb.set(key, value, useVersion).then(() => {
            this.updateElement(value, entryKey.toString('hex'));
        });
    }

    async withLock(
        key: string,
        func: (content: T | false, lockId: string, entryKey: Buffer) => IWhenable<T | null>
    ) {
        const entryKey = await this.kvdb.getEntryKey(key);
        let value: T | null = null;
        await this.kvdb.withLock(key, async (content, lockId, entKey) => {
            return (value = await func(content, lockId, entKey)) as T;
        });
        if (!value) {
            return;
        }
        this.updateElement(value, entryKey.toString('hex'));
    }

    private async tryLoadCache() {
        const cached = await this.cache.getKvdb<T>(this.dbId);
        if (!cached) {
            return;
        }
        this.seq = cached.seq;
        this.collection.rebuild(cached.entries);
    }

    async refresh() {
        const data = await this.kvdb.getAll(0);
        this.processUpdate(data);
    }

    processUpdate(data: privmx.types.db.KvdbFetchResult<T>) {
        try {
            this.collection.changeEvent.hold();
            let changed = this.seq !== data.seq;
            this.seq = data.seq;
            for (const key in data.map) {
                changed = true;
                this.updateElement(data.map[key], key);
            }
            if (changed) {
                runAsync(() =>
                    this.cache.setKvdb({
                        dbId: this.dbId,
                        seq: this.seq,
                        entries: this.collection.getListCopy()
                    })
                );
            }
        } finally {
            this.collection.changeEvent.release();
        }
    }

    private updateElement(value: T, hashedKey: string) {
        (value as any).__key = hashedKey;
        const index = this.collection.indexOfBy((x) => (x as any).__key === hashedKey);
        if (index === -1) {
            this.collection.add(value);
        } else {
            const oldValue = this.collection.get(index);
            const res = Utils.updateObject(oldValue, value);
            if (res.changed) {
                this.collection.setAt(index, res.value);
            }
        }
    }

    onChange(func: (entry: T) => any) {
        this.collection.changeEvent.add((e) => {
            if (e.type === 'add' || e.type === 'update') {
                func(e.element);
            }
        });
    }
}
