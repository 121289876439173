import { Transition, Box, Center, Flex, Title, Divider, Group, Button, Text } from '@mantine/core';
import { Dispatch, useCallback, useState } from 'react';
import {
    CreationMessageAction,
    CreationProcessType
} from '../../components/ChatAddPage/ChatAddModal';
import { useTranslation } from 'react-i18next';
import {
    AccountsAddedList,
    PasswordSendReminder
} from '../../components/ModalsOutlet/AccountsAdded';

export default function ThreadAdded({
    state,
    creationDispatch,
    onClose,
    onGoToThread,
    comunicateConfig
}: {
    state: CreationProcessType;
    onClose: VoidFunction;
    creationDispatch?: Dispatch<CreationMessageAction>;
    onGoToThread?: VoidFunction;
    comunicateConfig: {
        title: string;
        subTitle?: string | React.ReactNode;
        goToButton?: string;
    };
}) {
    const { t } = useTranslation();

    const [confirmModalState, setConfirmModalState] = useState<'closed' | 'open' | 'redirect'>(
        'closed'
    );

    const handleClose = useCallback(
        (redirectToThread: boolean) => {
            if (state.payload?.createdUsers?.size) {
                setConfirmModalState(redirectToThread ? 'redirect' : 'open');
            } else {
                creationDispatch?.({ type: 'reset' });
                onClose();
                if (redirectToThread && onGoToThread) onGoToThread();
            }
        },
        [onClose, creationDispatch, state?.payload, onGoToThread]
    );

    const handleGoToThread = useCallback(() => {
        handleClose(true);
    }, [handleClose]);

    if (confirmModalState !== 'closed') {
        return (
            <PasswordSendReminder
                onBack={() => setConfirmModalState('closed')}
                onProceed={() => {
                    creationDispatch?.({
                        type: 'reset'
                    });
                    onClose();

                    if (confirmModalState === 'redirect') {
                        if (onGoToThread) onGoToThread();
                    }
                }}
            />
        );
    }

    return (
        <Transition transition={'slide-left'} mounted={state.state === 'created'}>
            {(styles) => (
                <Box style={styles} display="flex" h="100%">
                    <Center h="100%" sx={{ flexGrow: 1, width: '100%' }}>
                        <Flex direction="column" gap={4} sx={{ width: '90%' }}>
                            <Title order={1} align="center">
                                {comunicateConfig.title}
                            </Title>
                            <Text align="center" color="dimmed" mb="xl">
                                {comunicateConfig.subTitle}
                            </Text>
                            {state.payload?.createdUsers?.size ||
                            state.payload?.failedUsers?.length ||
                            state.payload?.privateContactsMadeVisible?.length ? (
                                <>
                                    <AccountsAddedList
                                        createdUsers={Array.from(
                                            (state.payload.createdUsers ?? new Map()).entries()
                                        )}
                                        privateContactsMadeVisible={
                                            state.payload.privateContactsMadeVisible ?? []
                                        }
                                        failedUsers={
                                            state.payload.failedUsers ?? []
                                        }></AccountsAddedList>

                                    <Divider mb="xl" />
                                </>
                            ) : (
                                <></>
                            )}
                            <Group position="center" w="100%">
                                <Button variant="light" onClick={() => handleClose(false)}>
                                    {t('action.done')}
                                </Button>
                                {comunicateConfig.goToButton && (
                                    <Button onClick={handleGoToThread}>
                                        {comunicateConfig.goToButton}
                                    </Button>
                                )}
                            </Group>
                        </Flex>
                    </Center>
                </Box>
            )}
        </Transition>
    );
}
