import * as PmxApi from 'privmx-server-api';
import StateView from '../../atoms/State';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import * as types from '../../types/Types';
import { Fragment, memo, useCallback, useState } from 'react';
import { Box, Checkbox, Group, List, Paper } from '@mantine/core';
import { UserAvatarX } from '../../atoms/UserAvatar';
import UserDisplayedName from '../../atoms/UserDisplayedName';
import { useTranslation } from 'react-i18next';

export interface ThreadUsersProps {
    threadId: types.ThreadId;
}

export function ThreadUsers(props: ThreadUsersProps) {
    const { state, revalidate } = useDataSubscribion(
        useCallback(
            (x) => x.subscribeForThreadUsersWithAccessibleTimeRanges(props.threadId),
            [props.threadId]
        )
    );
    return (
        <StateView state={state} retry={revalidate}>
            {(data) => <ThreadUsersView threadId={props.threadId} users={data.users} />}
        </StateView>
    );
}

export interface ThreadUsersViewProps {
    threadId: types.ThreadId;
    users: PmxApi.api.thread.UserWithAccessibleTimeRanges[];
}

export function ThreadUsersView(props: ThreadUsersViewProps) {
    const { t } = useTranslation();
    const [showAllUsers, setShowAllUsers] = useState<boolean>(false);

    return (
        <Box>
            <Checkbox
                label={t('entityProps.showUsersThatNoLongerCanAccess')}
                checked={showAllUsers}
                onChange={(evt) => setShowAllUsers(evt.target.checked)}
            />
            <Box>
                {props.users.map((user) => (
                    <Fragment key={user.username}>
                        {(showAllUsers || doesUserHaveAccessNow(user)) && (
                            <Paper shadow="sm" mt="md" p="md">
                                <Group>
                                    <UserAvatarX user={{ type: 'user', username: user.username }} />
                                    <UserDisplayedName user={user.username} />
                                </Group>
                                <Box mt="md">
                                    {t('canReadDataCreated')}:
                                    <List ml="md">
                                        {user.accessibleTimeRanges.map((range, idx) => (
                                            <List.Item key={idx}>
                                                <code>
                                                    <DateTimeView timestamp={range.start} />
                                                    {' - '}
                                                    <DateTimeView timestamp={range.end} />
                                                </code>
                                            </List.Item>
                                        ))}
                                    </List>
                                </Box>
                            </Paper>
                        )}
                    </Fragment>
                ))}
            </Box>
        </Box>
    );
}

interface DateTimeViewProps {
    timestamp: PmxApi.api.core.TimestampN;
}
const DateTimeView = memo((props: DateTimeViewProps) => {
    return (
        <span>{props.timestamp === -1 ? 'now' : new Date(props.timestamp).toLocaleString()}</span>
    );
});

function doesUserHaveAccessNow(user: PmxApi.api.thread.UserWithAccessibleTimeRanges) {
    return user.accessibleTimeRanges.some((x) => x.end === -1);
}
