import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SendMessageProps } from '../components/SendMessageView';
import { Message } from '../types/Types';
import { RootState } from './Store';

export type FullEditorProps = Pick<
    SendMessageProps,
    'chatMessageDraft' | 'meetingMessageDraft' | 'chatId' | 'meetingId'
>;

type PayloadWithChatId<T> = PayloadAction<{ data: T; id: string | undefined }>;

export function PayloadWithIdFactory(id: string | undefined) {
    return function <T>(arg: T) {
        return { data: arg, id };
    };
}
export interface FullTextEditor {
    content: string;
    open: boolean;
    pendingMessage: Message | null;
    title?: string;
    props?: FullEditorProps;
}

const initialState: FullTextEditor = {
    content: '',
    pendingMessage: null,
    open: false
};

function compareId(props: FullEditorProps | undefined, id: string | undefined): boolean {
    if (!props) return false;
    return props.chatId === id || props.meetingId === id;
}

export const fullTextEditorSlice = createSlice({
    name: 'fullTextEditorSlice',
    initialState,
    reducers: {
        setPendingMessage: (state, action: PayloadWithChatId<Message>) => {
            if (!compareId(state.props, action.payload.id)) return;
            state.pendingMessage = { ...action.payload.data };
        },
        deletePendingMessage: (state, action: PayloadWithChatId<null>) => {
            state.pendingMessage = action.payload.data;
        },
        clearTextEditor: (state) => {
            return { ...initialState, pendingMessage: state.pendingMessage, props: state.props };
        },
        resetFullTextEditorState: () => {
            return initialState;
        }
    }
});

export const {
    setPendingMessage,
    deletePendingMessage,
    clearTextEditor,
    resetFullTextEditorState
} = fullTextEditorSlice.actions;

export const selectFullTextEdit = (state: RootState) => state.fullTextEditor;

export const selectPendingMessage = createSelector(
    [selectFullTextEdit, (_, id: string) => id],
    (FullTextEdit, id) => {
        const pendingMessage = compareId(FullTextEdit.props, id)
            ? FullTextEdit.pendingMessage
            : null;
        return pendingMessage;
    }
);
export const selectFullEditorContent = createSelector(
    [selectFullTextEdit, (_, id: string) => id],
    (FullTextEdit, id) => (compareId(FullTextEdit.props, id) ? FullTextEdit?.content || '' : null)
);
export const selectFullEditor = createSelector(
    [selectFullTextEdit, (_, id: string) => id],
    (FullTextEdit, id) => (compareId(FullTextEdit.props, id) ? FullTextEdit?.open || false : null)
);

export default fullTextEditorSlice.reducer;
