import {
    Box,
    Popover,
    TextInput,
    rem,
    ScrollArea,
    Group,
    Badge,
    ActionIcon,
    Text
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { IconSearch, IconBuilding } from '@tabler/icons-react';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store';
import { selectCachedCompanies } from '../../store/DataCacheSlice';
import { Company } from '../../types/Types';
import { useTagsStyle } from '../TagList';

export function CompanyPopoverSelect({
    company,
    onCompanySelect,
    onCompanyCreate,
    target
}: {
    onCompanyCreate?: (querry: string) => void;
    onCompanySelect?: (companyId: string) => void;
    company?: Company;
    target?: (onClick: VoidFunction) => React.ReactElement;
}) {
    const { classes, cx } = useTagsStyle();

    const globalCompanies = useAppSelector(selectCachedCompanies);
    const [tagSearch, setTagSearch] = useInputState('');

    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const handleTagSelect = useCallback(
        (e: any, x: string) => {
            e.preventDefault();
            e.stopPropagation();
            onCompanySelect?.(x);
            setIsOpen(false);
        },
        [onCompanySelect]
    );
    const narrowedCompanies = globalCompanies.filter((x) => x.name.includes(tagSearch));
    const companiesElements = narrowedCompanies.map((x, i) => (
        <Box
            key={x.id + i}
            sx={{ cursor: 'pointer' }}
            onClick={(e: any) => handleTagSelect(e, x.id)}
            className={cx(classes.hoverElement, classes.tagListElement)}>
            {x.name}
        </Box>
    ));

    const elementNotFound = !onCompanyCreate && narrowedCompanies.length === 0 && (
        <Box className={cx(classes.hoverElement, classes.tagListElement)}>
            <Text color="dimmed">{t('warningMessage.companyNotFound')}</Text>
        </Box>
    );

    const addCompanyButton = (
        <Popover
            opened={isOpen}
            keepMounted={false}
            trapFocus
            onChange={setIsOpen}
            position="bottom-start">
            <Popover.Target>
                {target ? (
                    target(() => {
                        setIsOpen((o) => !o);
                        // e.stopPropagation();
                        // e.preventDefault();
                    })
                ) : (
                    <Text
                        onClick={(e: any) => {
                            setIsOpen((o) => !o);
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        color="dimmed"
                        px={4}
                        size={12}>
                        + {t('action.assignCompany')}
                    </Text>
                )}
            </Popover.Target>
            <Popover.Dropdown sx={{ zIndex: 100, padding: 4 }}>
                <TextInput
                    styles={{ input: { border: 0 } }}
                    icon={<IconSearch size={rem(12)} />}
                    value={tagSearch}
                    onChange={setTagSearch}
                    placeholder={`${t('action.searchCompany')}...`}
                    size="xs"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                />
                <ScrollArea.Autosize h={140} scrollbarSize={8}>
                    {onCompanyCreate && (
                        <Box
                            sx={{ cursor: 'pointer' }}
                            onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setIsOpen(false);
                                onCompanyCreate(tagSearch);
                            }}
                            className={cx(classes.hoverElement, classes.tagListElement)}>
                            {tagSearch.length ? (
                                <Text color="dimmed">+ Add company : "{tagSearch}"</Text>
                            ) : (
                                <Text color="dimmed">+ Add new company</Text>
                            )}
                        </Box>
                    )}
                    {companiesElements}
                    {elementNotFound}
                </ScrollArea.Autosize>
            </Popover.Dropdown>
        </Popover>
    );

    if (!company) return <Box className={classes.tags}>{addCompanyButton}</Box>;

    return (
        <>
            <Group>
                <Badge
                    variant="outline"
                    color="gray"
                    size="md"
                    fw={400}
                    c="gray.9"
                    leftSection={
                        <ActionIcon size="xs" radius="xl" variant="transparent">
                            <IconBuilding size={rem(12)} />
                        </ActionIcon>
                    }
                    sx={(theme) => ({
                        '&:hover': {
                            cursor: 'pointer',
                            border: `1px solid ${theme.colors.gray[7]}`,
                            backgroundColor: theme.colors.gray[1]
                        }
                    })}
                    tt="capitalize">
                    {company.name}
                </Badge>
            </Group>
        </>
    );
}
