import Mention from '@tiptap/extension-mention';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import { EditorOptions, ReactRenderer, useEditor } from '@tiptap/react';
import { SuggestionOptions } from '@tiptap/suggestion';
import tippy, { Instance } from 'tippy.js';
import { useAppSelector } from '../../store';
import { selectUsers } from '../../store/UsersSlice';
import { Link } from '@mantine/tiptap';
import { User } from '../../types/Types';
import { MentionList } from './MentionList';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/translucent.css';
import Placeholder from '@tiptap/extension-placeholder';

const suggestion: (
    users: User[]
) => Omit<SuggestionOptions<{ username?: string; email: string }>, 'editor'> = (users) => ({
    items: ({ query }) => {
        return users
            .map((user) => ({ username: user.username, email: user.email }))
            .filter((item) => item.email.toLowerCase().startsWith(query.toLowerCase()));
    },

    render: () => {
        let component: ReactRenderer;
        let popup: Instance<any>[];

        return {
            onStart: (props: any) => {
                component = new ReactRenderer(MentionList, {
                    props,
                    editor: props.editor
                });

                if (!props.clientRect) {
                    return;
                }

                popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                    arrow: false
                });
            },

            onUpdate(props) {
                component.updateProps(props);

                if (!props.clientRect) {
                    return;
                }

                popup[0].setProps({
                    getReferenceClientRect: props.clientRect
                });
            },

            onKeyDown(props) {
                if (props.event.key === 'Escape') {
                    popup[0].hide();

                    return true;
                }
                return (component?.ref as any).onKeyDown(props);
            },

            onExit() {
                if (popup[0]) popup[0].destroy();
                component.destroy();
            }
        };
    }
});

export function useTextEditorConfig(
    configOverride?: (Partial<EditorOptions> & { placeholder?: string }) | undefined
) {
    const users = useAppSelector(selectUsers);

    const editor = useEditor({
        extensions: [
            StarterKit.configure({
                blockquote: { HTMLAttributes: { class: 'rich-editor-quote' } }
            }),
            Placeholder.configure({ placeholder: configOverride?.placeholder || '' }),
            Link,
            Mention.configure({ suggestion: suggestion(users) }),
            Underline
        ],
        ...configOverride
    });

    return editor;
}
