import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '../../api/Api';
import PageDetailsHeader from '../../atoms/DetailPage/PageDetailsHeader';
import { useAppSelector } from '../../store/Hooks';
import { selectVideoRoomState, VideoRoomState } from '../../store/VideoSlice';
import {
    AttachmentEx,
    Meeting,
    MeetingId,
    Message,
    MessageId,
    Timestamp,
    Username,
    ThreadMessageDraftWithAttachments,
    ThreadType
} from '../../types/Types';
import Icon, { iconLoading, iconMeetingLobby, iconUnread, iconVideo, iconWarning } from '../Icon';
import { FullMessagesView } from '../MessageView';
import * as PmxApi from 'privmx-server-api';
import { FeatureDetection } from '../../utils/FeatureDetection';
import useModal, { useModals } from '../../hooks/useModal';
import { VideoConferenceModal } from '../VideoConference/VideoConferenceModal';
import StateView from '../../atoms/State';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    Flex,
    Group,
    Modal,
    Stack,
    Tabs,
    Title
} from '@mantine/core';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import FileAndMessageSummary from '../../mantineAtoms/FileAndMessageSummary';
import { Link, useParams } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons-react';
import { UsersList } from '../../screens/ChatScreen/components/UserList';
import { useDisclosure } from '@mantine/hooks';
import MeetingEditDialog from './MeetingEditDialog';
import { selectAutoMarkAsRead } from '../../store/UserPreferenceSlice';
import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';
import { TagList } from '../../mantineAtoms/TagList';

export function MeetingPage(props: { id?: MeetingId }) {
    const { meetingId } = useParams();

    const id = (meetingId || props.id || '') as MeetingId;

    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForMeetingWithMessagesAndAttachments(id, true), [id])
    );
    return (
        <StateView state={state} retry={revalidate}>
            {(data) => (
                <MeetingPageView
                    meeting={data.meeting}
                    meetingMessageDraft={data.meetingMessageDraft}
                    messages={data.messages}
                    hasAccess={data.hasAccessToThreadContent}
                    attachments={data.attachments}
                />
            )}
        </StateView>
    );
}

const supportsVideoConferences = FeatureDetection.supportsVideoConferences();
type TabId = 'messages' | 'attachments' | 'users';

export function MeetingPageView(props: {
    meeting: Meeting;
    meetingMessageDraft?: ThreadMessageDraftWithAttachments;
    messages: Message[];
    hasAccess: boolean;
    attachments: AttachmentEx[];
    highlightedMessageIds?: MessageId[];
    scrollToMessageId?: MessageId;
}) {
    const { t } = useTranslation();
    const currentUser = useAppSelector(selectCurrentUser);
    const modalService = useModal();
    const [activeTabId, setActiveTabId] = useState<TabId>('messages');

    const videoRoomState = useAppSelector(selectVideoRoomState(props.meeting.id));
    const currentVideoModal = useModals().modals.find((x) => x?.component === VideoConferenceModal);
    const isUserInAnyVideo = !!currentVideoModal;

    const openVideo = useCallback(
        async (isNewVideo: boolean) => {
            const thread = await api.getThread(
                props.meeting.id as string as PmxApi.api.thread.ThreadId
            );
            if (!thread) {
                return;
            }
            if (currentVideoModal) {
                currentVideoModal.onClose();
            }
            modalService.openVideoConferenceModal({
                videoConference: {
                    meetingId: props.meeting.id,
                    meetingTitle: props.meeting.title,
                    thread: thread,
                    creating: isNewVideo
                }
            });
        },
        [currentVideoModal, modalService, props.meeting.id, props.meeting.title]
    );

    const handleStartVideoButtonClick = useCallback(async () => {
        openVideo(true);
    }, [openVideo]);

    const handleJoinVideoButtonClick = useCallback(async () => {
        openVideo(false);
    }, [openVideo]);

    const handleLeaveVideoButtonClick = useCallback(async () => {
        if (currentVideoModal) {
            currentVideoModal.onClose();
        }
    }, [currentVideoModal]);

    const handleMarkAllMessagesAsReadClick = useCallback(() => {
        const unreadService = api.getUnreadService();
        if (!unreadService) {
            return;
        }
        const threadId = props.meeting.id;
        if (!threadId) {
            return;
        }
        unreadService.markAllMessagesInThreadAsRead(threadId);
    }, [props.meeting.id]);
    const autoMarkAsRead = useAppSelector(selectAutoMarkAsRead);

    const [editingMeeting, { open, close }] = useDisclosure();

    const videoRoomData = useMemo(() => {
        const videoRoomData = {
            videoRoomState: videoRoomState,
            isUserInAnyVideo: isUserInAnyVideo,
            onStartVideoButtonClick: handleStartVideoButtonClick,
            onJoinVideoButtonClick: handleJoinVideoButtonClick,
            onLeaveVideoButtonClick: handleLeaveVideoButtonClick
        };
        if (videoRoomData.videoRoomState === 'nonexistent') {
            const msg = props.messages.find((msg) => {
                try {
                    if (msg.mimetype !== 'application/json') {
                        return false;
                    }
                    const data = JSON.parse(msg.text);
                    if (data.type === 'meetingStarted') {
                        return true;
                    }
                    return false;
                } catch (err) {
                    console.error(err);
                    return false;
                }
            });
            if (msg) {
                videoRoomData.videoRoomState = 'available';
            }
        }
        return videoRoomData;
    }, [
        videoRoomState,
        isUserInAnyVideo,
        props.messages,
        handleStartVideoButtonClick,
        handleJoinVideoButtonClick,
        handleLeaveVideoButtonClick
    ]);

    const canManageMeeting = currentUser.username
        ? props.meeting.managers.includes(currentUser.username)
        : false;

    const lobbyUsers = useMemo(
        () => props.meeting.meetingState?.lobbyUsers ?? [],
        [props.meeting.meetingState?.lobbyUsers]
    );
    const lobbyUsersCount = api.getPresentThreadMeetingLobbyUsersCount(lobbyUsers);

    const handleOpenLobbyClick = useCallback(() => {
        if (props.meeting.id) {
            modalService.openMeetingLobbyManagementModal({ meetingId: props.meeting.id });
        }
    }, [modalService, props.meeting.id]);

    const [, setForcedUpdateId] = useState(0);
    const forceUpdate = useCallback(() => setForcedUpdateId((x) => x + 1), []);

    useEffect(() => {
        const expiresAt = api.getNextThreadMeetingLobbyUserExpirationTime(lobbyUsers);
        if (expiresAt !== null) {
            const timeLeft = Date.now() - expiresAt;
            const timeoutId = window.setTimeout(() => {
                forceUpdate();
            }, timeLeft + 100);
            return () => {
                window.clearTimeout(timeoutId);
            };
        }
    }, [lobbyUsers, forceUpdate]);

    return (
        <Flex direction="column" sx={{ height: '100svh' }} justify="space-between">
            <Stack justify="space-between" m="md" mb={0} spacing="xs">
                <Group position="apart">
                    <Group align="center">
                        <Box w={20}>
                            <Anchor component={Link} to="/meetings">
                                <ActionIcon size={24}>
                                    <IconArrowLeft />
                                </ActionIcon>
                            </Anchor>
                        </Box>
                        <Group align="center">
                            <Title order={2} size={20} weight={'bolder'}>
                                {props.meeting.title}
                            </Title>
                            <UsersList
                                usernames={props.meeting.users.map((x) => ({
                                    type: 'user',
                                    username: x
                                }))}
                            />
                            {props.meeting.tags.length !== 0 && (
                                <TagList tags={props.meeting.tags} />
                            )}
                        </Group>
                    </Group>
                    <Group>
                        <Group>
                            {videoRoomData && props.meeting.type === 'meeting' && (
                                <>
                                    {!supportsVideoConferences && <NoVideoWarning />}
                                    {videoRoomData.videoRoomState === 'nonexistent' &&
                                        canManageMeeting && (
                                            <Button
                                                onClick={videoRoomData.onStartVideoButtonClick}
                                                disabled={!supportsVideoConferences}
                                                leftIcon={<Icon icon={iconVideo} />}>
                                                {videoRoomData.isUserInAnyVideo
                                                    ? t('action.switchVideo')
                                                    : t('action.startVideo')}
                                            </Button>
                                        )}
                                    {videoRoomData.videoRoomState === 'joining' && (
                                        <Button
                                            disabled
                                            leftIcon={<Icon icon={iconLoading} spin={true} />}>
                                            {t('progress.startingVideo')}
                                        </Button>
                                    )}
                                    {videoRoomData.videoRoomState === 'available' && (
                                        <Button
                                            onClick={videoRoomData.onJoinVideoButtonClick}
                                            disabled={!supportsVideoConferences}
                                            leftIcon={<Icon icon={iconVideo} />}>
                                            {videoRoomData.isUserInAnyVideo
                                                ? t('action.switchVideo')
                                                : t('action.joinVideo')}
                                        </Button>
                                    )}
                                    {videoRoomData.videoRoomState === 'active' && (
                                        <Button
                                            onClick={videoRoomData.onLeaveVideoButtonClick}
                                            disabled={!supportsVideoConferences}
                                            leftIcon={<Icon icon={iconVideo} />}>
                                            {t('action.leaveVideo')}
                                        </Button>
                                    )}
                                </>
                            )}
                            {canManageMeeting && (
                                <>
                                    <Button
                                        variant="light"
                                        leftIcon={<Icon icon={iconMeetingLobby} />}
                                        className={lobbyUsersCount > 0 ? 'pulsating' : ''}
                                        onClick={handleOpenLobbyClick}>
                                        {t('action.viewLobby')}
                                        {lobbyUsersCount > 0 ? ` (${lobbyUsersCount})` : ''}
                                    </Button>
                                    <Button onClick={open}>{t('action.edit')}</Button>
                                </>
                            )}
                        </Group>

                        <Modal
                            opened={editingMeeting}
                            size="md"
                            onClose={close}
                            title={t('modal.meetingEdit.titleWithoutName')}
                            overlayProps={{ opacity: 0.2, blur: 3 }}>
                            <MeetingEditDialog id={props.meeting.id} onClose={close} />
                        </Modal>
                    </Group>
                </Group>
                {props.hasAccess && (
                    <Box mx={-16} sx={{ borderBottom: '0.5px solid rgba(0,0,0,0.2)' }}>
                        <Tabs ml={36} defaultValue="Messages">
                            <Tabs.List sx={{ border: 0 }} position="left">
                                <Tabs.Tab
                                    value="Messages"
                                    onClick={() => setActiveTabId('messages')}>
                                    {t('tab.messages')}
                                </Tabs.Tab>
                                <Tabs.Tab
                                    value="Attachments"
                                    onClick={() => setActiveTabId('attachments')}>
                                    {t('tab.attachments')}
                                </Tabs.Tab>
                                {props.meeting.managers.includes(
                                    currentUser.username ?? ('' as Username)
                                ) && (
                                    <Tabs.Tab value="Users" onClick={() => setActiveTabId('users')}>
                                        {t('tab.users')}
                                    </Tabs.Tab>
                                )}
                                {!autoMarkAsRead && (
                                    <Button
                                        ml="auto"
                                        mr="md"
                                        size="xs"
                                        variant="light"
                                        onClick={handleMarkAllMessagesAsReadClick}
                                        title={t('action.markAllMessagesAsRead')}>
                                        <Icon icon={iconUnread} />
                                    </Button>
                                )}
                            </Tabs.List>
                        </Tabs>
                    </Box>
                )}
            </Stack>
            <FullMessagesView
                attachmentsViewMode="list"
                activeTabId={activeTabId}
                chatName={props.meeting.title}
                meetingId={props.meeting.id as any}
                loadingMessages={!props.messages}
                messages={props.messages}
                hasAccess={props.hasAccess}
                attachments={props.attachments}
                meetingMessageDraft={props.meetingMessageDraft}
                highlightedMessageIds={props.highlightedMessageIds}
                scrollToMessageId={props.scrollToMessageId}
            />
        </Flex>
    );
}

export interface MeetingHeaderProps {
    title: string;
    users: Username[];
    startDate: Timestamp;
    mesagesCount: number;
    filesCount: number;
    hasEditBtn: boolean;
    hasOpenBtn: boolean;
    hasRefreshBtn: boolean;
    videoRoomData?: {
        videoRoomState: VideoRoomState;
        isUserInAnyVideo: boolean;
        onStartVideoButtonClick: () => void;
        onJoinVideoButtonClick: () => void;
        onLeaveVideoButtonClick: () => void;
    };
    canManageMeeting: boolean;
    threadType: ThreadType;
    meetingId?: MeetingId;
    meetingState?: PmxApi.api.thread.MeetingState;
    editBtnText?: string;
    onEditClick?: () => void;
    onOpenClick?: () => void;
    onRefreshClick?: () => void;
}

export function MeetingHeader(props: MeetingHeaderProps) {
    const { t } = useTranslation();
    const modalService = useModal();
    const lobbyUsers = useMemo(
        () => props.meetingState?.lobbyUsers ?? [],
        [props.meetingState?.lobbyUsers]
    );
    const lobbyUsersCount = api.getPresentThreadMeetingLobbyUsersCount(lobbyUsers);
    const [, setForcedUpdateId] = useState(0);
    const forceUpdate = useCallback(() => setForcedUpdateId((x) => x + 1), []);

    useEffect(() => {
        const expiresAt = api.getNextThreadMeetingLobbyUserExpirationTime(lobbyUsers);
        if (expiresAt !== null) {
            const timeLeft = Date.now() - expiresAt;
            const timeoutId = window.setTimeout(() => {
                forceUpdate();
            }, timeLeft + 100);
            return () => {
                window.clearTimeout(timeoutId);
            };
        }
    }, [lobbyUsers, forceUpdate]);

    const handleOpenLobbyClick = useCallback(() => {
        if (props.meetingId) {
            modalService.openMeetingLobbyManagementModal({ meetingId: props.meetingId });
        }
    }, [modalService, props.meetingId]);

    const canManageMeeting = props.canManageMeeting;

    return (
        <PageDetailsHeader
            name={
                props.threadType === 'meeting'
                    ? `${props.title}, ${new Date(props.startDate).toDateString()}`
                    : props.title
            }
            usernames={props.users}
            button={
                <>
                    <Group>
                        {props.videoRoomData && props.threadType === 'meeting' && (
                            <>
                                {!supportsVideoConferences && <NoVideoWarning />}
                                {props.videoRoomData.videoRoomState === 'nonexistent' &&
                                    canManageMeeting && (
                                        <Button
                                            onClick={props.videoRoomData.onStartVideoButtonClick}
                                            disabled={!supportsVideoConferences}
                                            leftIcon={<Icon icon={iconVideo} />}>
                                            {props.videoRoomData.isUserInAnyVideo
                                                ? t('action.switchVideo')
                                                : t('action.startVideo')}
                                        </Button>
                                    )}
                                {props.videoRoomData.videoRoomState === 'joining' && (
                                    <Button
                                        disabled
                                        leftIcon={<Icon icon={iconLoading} spin={true} />}>
                                        {t('progress.startingVideo')}
                                    </Button>
                                )}
                                {props.videoRoomData.videoRoomState === 'available' && (
                                    <Button
                                        onClick={props.videoRoomData.onJoinVideoButtonClick}
                                        disabled={!supportsVideoConferences}
                                        leftIcon={<Icon icon={iconVideo} />}>
                                        {props.videoRoomData.isUserInAnyVideo
                                            ? t('action.switchVideo')
                                            : t('action.joinVideo')}
                                    </Button>
                                )}
                                {props.videoRoomData.videoRoomState === 'active' && (
                                    <Button
                                        onClick={props.videoRoomData.onLeaveVideoButtonClick}
                                        disabled={!supportsVideoConferences}
                                        leftIcon={<Icon icon={iconVideo} />}>
                                        {t('action.leaveVideo')}
                                    </Button>
                                )}
                            </>
                        )}
                        {props.canManageMeeting && (
                            <>
                                <Button
                                    variant="light"
                                    leftIcon={<Icon icon={iconMeetingLobby} />}
                                    className={lobbyUsersCount > 0 ? 'pulsating' : ''}
                                    onClick={handleOpenLobbyClick}>
                                    {t('action.viewLobby')}
                                    {lobbyUsersCount > 0 ? ` (${lobbyUsersCount})` : ''}
                                </Button>
                                <Button variant="light" onClick={props.onEditClick}>
                                    {props.editBtnText ?? t('action.edit')}
                                </Button>
                            </>
                        )}
                        {props.hasOpenBtn && (
                            <Button variant="light" onClick={props.onOpenClick}>
                                {t('action.open')}
                            </Button>
                        )}
                        {props.hasRefreshBtn && (
                            <Button onClick={props.onRefreshClick}>{t('action.refresh')}</Button>
                        )}
                    </Group>
                </>
            }>
            <FileAndMessageSummary
                filesCount={props.filesCount}
                mesagesCount={props.mesagesCount}
            />
        </PageDetailsHeader>
    );
}

function NoVideoWarning() {
    const { t } = useTranslation();
    return (
        <span>
            <Text c="red" inline>
                <Icon icon={iconWarning} /> {t('errorMessage.noE2eeVideo.1')}
                <br /> {t('errorMessage.noE2eeVideo.2')}
            </Text>
        </span>
    );
}

export default MeetingPage;
