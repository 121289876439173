import * as privfs from 'privfs-client';
import { KvdbSettingEntry } from '../kvdb/KvdbUtils';
import { PrivmxConst } from '../PrivmxConst';
import { KvdbCollection } from '../kvdb/KvdbCollection';

export class SharedDbChecker {
    constructor(
        private sharedKvdb: KvdbCollection<KvdbSettingEntry>,
        private adminKvdb: KvdbCollection<KvdbSettingEntry>,
        private messageManager: privfs.message.MessageManager
    ) {}

    async checkSharedDb() {
        await this.ensureAdminSinkEntryInSharedKvdb();
    }

    private async ensureAdminSinkEntryInSharedKvdb() {
        return this.ensureEntryInKvdb({
            kvdb: this.sharedKvdb,
            key: PrivmxConst.ADMIN_SINK_KEY,
            creator: async () =>
                (await this.ensureAdminSinkEntryInAdminKvdb()) as privfs.message.MessageSink,
            serializer: (sink) => sink.pub58,
            deserializer: (sinkPub58) =>
                privfs.message.MessageSink.fromSerialized(sinkPub58, '', '')
        });
    }

    private async ensureAdminSinkEntryInAdminKvdb() {
        return this.ensureEntryInKvdb({
            kvdb: this.adminKvdb,
            key: PrivmxConst.ADMIN_SINK_KEY,
            creator: async () => {
                const sink = new privfs.message.MessageSinkPriv(
                    privfs.crypto.serviceSync.eccPrivRandom(),
                    '',
                    '',
                    'public',
                    null,
                    {
                        acl: {
                            manage: '<admin>',
                            create: '<admin>',
                            delete: '<admin>',
                            modify: '<admin>',
                            post: '<local>',
                            read: '<admin>'
                        }
                    }
                );
                await this.messageManager.storage.sinkCreate(sink.id, 'public', '', sink.options);
                return sink;
            },
            serializer: (sink) => sink.privWif,
            deserializer: (sinkWif) =>
                privfs.message.MessageSinkPriv.fromSerialized(
                    sinkWif,
                    '',
                    '',
                    'public',
                    null,
                    null as any
                )
        });
    }

    private async ensureEntryInKvdb<T>({
        kvdb,
        key,
        creator,
        serializer,
        deserializer
    }: {
        kvdb: KvdbCollection<KvdbSettingEntry>;
        key: string;
        creator: () => T | Promise<T>;
        serializer: (v: T) => string;
        deserializer: (v: string) => T;
    }) {
        const entry = await kvdb.opt(key, null as any);
        if (entry) {
            return deserializer(entry.secured.value);
        }
        const value = await creator();
        await kvdb.set(key, { secured: { value: serializer(value) } });
        return value;
    }
}
