import * as privmx from 'privfs-client';
import * as PmxApi from 'privmx-server-api';
import { KeyValueStorage } from './KeyValueStorage';

type MapEntry =
    | {
          promise: Promise<EccPubKey>;
      }
    | {
          key: EccPubKey;
      };

export class PkiService {
    private keyValueStorage: KeyValueStorage;
    private cache = new Map<string, MapEntry>();

    constructor(private srpSecure: privmx.core.PrivFsSrpSecure, private cachePrefix: string) {
        this.keyValueStorage = new KeyValueStorage(this.cachePrefix);
    }

    async getUserPubKeyUsingCache(user: string, host: string) {
        const storageKey = `pub:${user}#${host}`;
        const entry = this.cache.get(storageKey);
        if (entry) {
            return 'key' in entry ? entry.key : entry.promise;
        }
        const pubStr = this.keyValueStorage.get<PmxApi.api.core.EccPubKey>(storageKey);
        if (pubStr) {
            const pub = new EccPubKey(pubStr, null);
            this.cache.set(storageKey, { key: pub });
            return pub;
        }
        const promise = (async () => {
            try {
                const ecc = await this.getUserPubKey(user, host);
                const b58 = ecc.toBase58DER() as PmxApi.api.core.EccPubKey;
                const pub = new EccPubKey(b58, ecc);
                this.keyValueStorage.set(storageKey, b58);
                this.cache.set(storageKey, { key: pub });
                return pub;
            } catch (e) {
                this.cache.delete(storageKey);
                throw e;
            }
        })();
        this.cache.set(storageKey, { promise });
        return promise;
    }

    async getUserPubKey(user: string, host: string) {
        const keystoreResult = await this.srpSecure.privmxPKI.getKeyStore(
            this.srpSecure.getUserKeystoreName(user, host),
            {
                pkiOptions: {
                    domain: host
                }
            }
        );
        const keystore = keystoreResult.keystore as privmx.pki.Types.keystore.IKeyStore2;
        const key = (keystore.getPrimaryKey().keyPair as privmx.pki.KeyStore.EccKeyPair).keyPair;
        return new privmx.crypto.ecc.PublicKey(key);
    }
}

export class EccPubKey {
    constructor(
        private b58: PmxApi.api.core.EccPubKey | null,
        private pub: privmx.crypto.ecc.PublicKey | null
    ) {}

    getBase58Der() {
        if (!this.b58) {
            if (!this.pub) {
                throw new Error('Invalid state');
            }
            this.b58 = this.pub.toBase58DER() as PmxApi.api.core.EccPubKey;
        }
        return this.b58;
    }

    getPublicKey() {
        if (!this.pub) {
            if (!this.b58) {
                throw new Error('Invalid state');
            }
            this.pub = privmx.crypto.ecc.PublicKey.fromBase58DER(this.b58);
        }
        return this.pub;
    }
}
