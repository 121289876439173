import UserAvatar from '../../atoms/UserAvatar';
import ConfirmModal from '../../atoms/ConfirmModal';
import { ChatId, MeetingId, Message, Username, VerifyMessageResultX } from '../../types/Types';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { api, chatApi } from '../../api/Api';
import { Deferred } from '../../utils/Deferred';
import Timestamp from '../../atoms/Timestamp';
import MessageContent from './Message/MessageContent';
import MessageControls from './Message/MessageControls';
import {
    ActionIcon,
    Box,
    Button,
    Center,
    createStyles,
    getStylesRef,
    Group,
    Stack,
    Text,
    ThemeIcon,
    Flex,
    rem,
    Popover,
    HoverCard,
    ScrollArea,
    Tooltip,
    Loader
} from '@mantine/core';
import UserDisplayedName from '../../atoms/UserDisplayedName';
import {
    IconExclamationCircle,
    IconMoodSmile,
    IconThumbDown,
    IconThumbUp
} from '@tabler/icons-react';
import { useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { EmojiIconName } from '../EmojiIcon/emojiIcons';
import { useTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import { useDisclosure } from '@mantine/hooks';
import { AppMessage } from '../SendMessageView';

const useStyles = createStyles(
    (theme, { isHoverable, type }: { isHoverable: boolean; type: 'message' | 'event' }) => {
        const borderStyle = `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[1]
        }`;

        const messageContentBg = theme.colorScheme === 'dark' ? theme.colors.dark[7] : 'white';
        const eventContentBg = 'transparent';
        const messageContentBgHover =
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0];

        return {
            root: {
                '&:hover': {
                    '& .message-content': {
                        backgroundColor: isHoverable ? messageContentBgHover : eventContentBg,
                        [`& .${getStylesRef('card')}`]: {
                            backgroundColor: 'transparent'
                        },
                        [`& .${getStylesRef('actions')} .mantine-ActionIcon-root`]: {
                            display: 'flex'
                        },
                        '.message-actions': {
                            display: 'flex'
                        },
                        '.subsequent-groupped-message-hour': {
                            display: 'flex'
                        }
                    }
                },
                '&.event': {
                    marginBottom: theme.spacing.sm
                },
                '& .message-content': {
                    paddingInline: theme.spacing.sm,
                    backgroundColor: type === 'message' ? messageContentBg : eventContentBg
                },
                '&.subsequent-groupped-message + .not-subsequent-groupped-message': {
                    '& .message-content': {
                        borderTop: borderStyle
                    }
                },
                '&.subsequent-groupped-message': {
                    '& .message-content': {
                        border: borderStyle,
                        borderTop: 0,
                        borderBottom: 0
                    }
                },
                '&.not-subsequent-groupped-message': {
                    '& .message-content': {
                        border: borderStyle,
                        borderBottom: 0,
                        borderTopLeftRadius: theme.radius.md,
                        borderTopRightRadius: theme.radius.md
                    }
                },
                '&.is-last': {
                    '& .message-content': {
                        borderBottom: borderStyle,
                        paddingBottom: theme.spacing.sm,
                        borderBottomLeftRadius: theme.radius.md,
                        borderBottomRightRadius: theme.radius.md
                    },
                    marginBottom: theme.spacing.sm
                }
            },
            card: {
                ref: getStylesRef('card'),
                position: 'relative',
                flexGrow: 1
            },
            subsequentGrouppedMessageHour: {
                position: 'absolute',
                left: -45,
                top: 3,
                display: 'none'
            },

            actionsContainerForSubsequentGrouppedMessage: {
                display: 'flex',
                justifyContent: 'flex-end',
                overflow: 'visible'
            },
            actions: {
                ref: getStylesRef('actions'),
                zIndex: 20,
                padding: 4,
                paddingInline: theme.spacing.sm,
                '& .mantine-ActionIcon-root': {
                    display: 'none'
                },
                '& .mantine-ActionIcon-root[data-favorite="true"]': {
                    display: 'flex'
                }
            },
            avatar: {
                alignSelf: 'flex-start',
                marginTop: 2
            },
            unread: {
                borderLeft: `4px solid ${
                    theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 6 : 7]
                }`,
                fontWeight: 'bold',
                '.mantine-Text-root': {
                    fontWeight: 'bold'
                }
            },
            highlighted: {
                boxShadow:
                    'inset 0 0 6px ' +
                    theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 6 : 7]
            }
        };
    }
);

const iconObject = {
    ok1: <IconThumbUp />,
    nieok1: <IconThumbDown />
} as const;

const icons = [
    { name: 'ok1', icon: <IconThumbUp /> },
    { name: 'nieok1', icon: <IconThumbDown /> }
] as const;

export default memo(function MessageView(props: {
    message: Message | AppMessage;
    chatId?: ChatId;
    meetingId?: MeetingId;
    isUnsavedPreview?: boolean;
    highlighted?: boolean;
    scrollIntoView?: boolean;
    isSubsequentGrouppedMessage?: boolean;
    isLast?: boolean;
    canBeEdited?: boolean;
}) {
    const { t } = useTranslation();
    const [deleteConfirmModalDeferred, setDeleteConfirmModalDeferred] = useState<
        Deferred<boolean> | undefined
    >(undefined);
    const validMsg =
        props.message.verified === 'verified' || props.message.verified === 'processing';
    const verificationInfo = getVerificationInfo(props.message.verified);
    const [showMessage, setShowMessage] = useState(validMsg);
    const canAddEmojis = api.canAddEmojis();

    const handleDeleteConfirmModalCancelClick = useCallback(() => {
        deleteConfirmModalDeferred?.resolve(false);
        setDeleteConfirmModalDeferred(undefined);
    }, [deleteConfirmModalDeferred]);

    const handleDeleteConfirmModalDeleteClick = useCallback(() => {
        deleteConfirmModalDeferred?.resolve(true);
        setDeleteConfirmModalDeferred(undefined);
        chatApi.deleteMessage(props.message.id);
    }, [deleteConfirmModalDeferred, props.message.id]);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!props.scrollIntoView) {
            return;
        }
        setTimeout(() => {
            containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 0);
    }, [props.scrollIntoView]);

    const isTextMessage = props.message.mimetype !== 'application/json';
    const { classes, cx } = useStyles({
        isHoverable: isTextMessage,
        type: isTextMessage ? 'message' : 'event'
    });

    const currentUser = useAppSelector(selectCurrentUser);
    const [isEmojisOpen, { close: closeEmoji, toggle: toggleEmoji }] = useDisclosure(false);

    const handleEmojiIconSelected = useCallback(
        (iconName: EmojiIconName) => {
            if (props.chatId) {
                api.setChatMessageEmoji(
                    props.chatId,
                    props.message.id,
                    currentUser.username!,
                    iconName
                );
            } else if (props.meetingId) {
                api.setMeetingMessageEmoji(
                    props.meetingId,
                    props.message.id,
                    currentUser.username!,
                    iconName
                );
            }
            closeEmoji();
        },
        [props.chatId, props.meetingId, currentUser, props.message.id, closeEmoji]
    );

    const isFailed = 'failed' in props.message ? props.message.failed : false;

    return (
        <>
            <Flex
                className={cx(classes.root, {
                    event: !isTextMessage,
                    'subsequent-groupped-message':
                        props.isSubsequentGrouppedMessage && isTextMessage,
                    'not-subsequent-groupped-message':
                        !props.isSubsequentGrouppedMessage && isTextMessage,
                    'is-last': props.isLast && isTextMessage
                })}>
                {isTextMessage && (
                    <Box miw={26} mr="sm">
                        {!props.isSubsequentGrouppedMessage && isTextMessage ? (
                            <UserAvatar
                                className={classes.avatar}
                                username={(props.message.author || '') as Username}
                                isAnonymousMeetingUser={props.message.authorIsAnonymousMeetingUser}
                            />
                        ) : (
                            <></>
                        )}
                    </Box>
                )}

                <Stack className="message-content" spacing={0} sx={{ flexGrow: 1 }}>
                    {isTextMessage && (
                        <Group
                            h={props.isSubsequentGrouppedMessage ? 0 : 30}
                            position="apart"
                            w="100%"
                            className={
                                props.isSubsequentGrouppedMessage
                                    ? classes.actionsContainerForSubsequentGrouppedMessage
                                    : ''
                            }>
                            {!props.isSubsequentGrouppedMessage && (
                                <Group spacing="xs">
                                    <UserDisplayedName
                                        user={props.message.author}
                                        isAnonymousMeetingUser={
                                            props.message.authorIsAnonymousMeetingUser
                                        }
                                    />
                                    <Text size="xs" color="dimmed">
                                        <Timestamp date={props.message.date} />
                                    </Text>
                                </Group>
                            )}
                            <Group
                                spacing={'sm'}
                                className={cx(classes.actions, 'message-actions')}>
                                {canAddEmojis && (
                                    <Popover position="bottom" opened={isEmojisOpen}>
                                        <Popover.Target>
                                            <ActionIcon size={18} onClick={toggleEmoji}>
                                                <IconMoodSmile size={rem(18)} />
                                            </ActionIcon>
                                        </Popover.Target>

                                        <Popover.Dropdown p="xs">
                                            <Group>
                                                {icons.map((x, i) => (
                                                    <ActionIcon
                                                        size={'xs'}
                                                        key={`${x.name}${i}`}
                                                        onClick={() =>
                                                            handleEmojiIconSelected(x.name)
                                                        }>
                                                        {x.icon}
                                                    </ActionIcon>
                                                ))}
                                            </Group>
                                        </Popover.Dropdown>
                                    </Popover>
                                )}
                                {!props.isUnsavedPreview && (
                                    <MessageControls
                                        canBeEdited={props.canBeEdited ?? false}
                                        message={props.message}
                                        setDeleteModal={setDeleteConfirmModalDeferred}
                                    />
                                )}
                            </Group>
                        </Group>
                    )}

                    <Box
                        className={cx(classes.card, {
                            [classes.highlighted]: props.highlighted
                        })}
                        ref={containerRef}>
                        {!validMsg && (
                            <Box>
                                <h2>{t('warningMessage.hiddenFakeMessage')}</h2>
                                <h3>{verificationInfo.info}</h3>
                                <button onClick={() => setShowMessage(!showMessage)}>
                                    {showMessage
                                        ? t('action.hideTheMessage')
                                        : t('action.showItAnyway')}
                                </button>
                            </Box>
                        )}
                        {showMessage && (
                            <Stack spacing={0} sx={{ flexGrow: 1 }}>
                                {props.message.pending && (
                                    <Group
                                        position="apart"
                                        style={{ position: 'absolute', left: -40, top: 3 }}>
                                        <Center>
                                            <Loader size={'xs'} color="gray" />
                                        </Center>
                                    </Group>
                                )}
                                {isFailed ? (
                                    <Group
                                        position="apart"
                                        style={{ position: 'absolute', left: -40, top: 3 }}>
                                        <Center>
                                            <Tooltip label="Error while sending this message">
                                                <ThemeIcon
                                                    variant="outline"
                                                    sx={{ border: 0 }}
                                                    color="red">
                                                    <IconExclamationCircle size={rem(20)} />
                                                </ThemeIcon>
                                            </Tooltip>
                                        </Center>
                                    </Group>
                                ) : null}
                                {props.message.deleted ? (
                                    <Text color="dimmed" size="xs">
                                        {t('infoMessage.messageDeleted')}
                                    </Text>
                                ) : (
                                    <>
                                        {props.isSubsequentGrouppedMessage &&
                                            !props.message.pending &&
                                            !isFailed && (
                                                <Text
                                                    size="xs"
                                                    color="gray"
                                                    mr="md"
                                                    className={`${classes.subsequentGrouppedMessageHour} subsequent-groupped-message-hour`}>
                                                    <Timestamp
                                                        date={props.message.date}
                                                        displayTimeOfDay
                                                    />
                                                </Text>
                                            )}
                                        <MessageContent message={props.message} />
                                    </>
                                )}
                            </Stack>
                        )}
                    </Box>
                    <Group display="inline-flex" spacing="sm">
                        <Group spacing="xs" mt={props.message.emojis.length > 0 ? 'xs' : '0'}>
                            {props.message.emojis.map((icon, i) => (
                                <HoverCard key={`${icon.icon}${i}`}>
                                    <HoverCard.Target key={`${icon.icon}${i}`}>
                                        <Group spacing={0}>
                                            <ThemeIcon
                                                variant="outline"
                                                sx={{ border: 0 }}
                                                color="gray"
                                                size="xs">
                                                {
                                                    (iconObject as any)[
                                                        icon.icon as unknown as any
                                                    ] as any
                                                }
                                            </ThemeIcon>
                                            <Text size={'xs'} color="dimmed">
                                                {icon.users.length}
                                            </Text>
                                        </Group>
                                    </HoverCard.Target>
                                    <HoverCard.Dropdown p="xs">
                                        <ScrollArea.Autosize mah={160}>
                                            {icon.users.map((x) => (
                                                <Text key={x} size="xs">
                                                    <UserDisplayedName user={x} size={'xs'} />
                                                </Text>
                                            ))}
                                        </ScrollArea.Autosize>
                                    </HoverCard.Dropdown>
                                </HoverCard>
                            ))}
                        </Group>
                    </Group>
                </Stack>
            </Flex>
            {deleteConfirmModalDeferred && (
                <ConfirmModal
                    opened={!!deleteConfirmModalDeferred}
                    onClose={handleDeleteConfirmModalCancelClick}
                    title={t('question.deleteMessage')}>
                    <>
                        <Button onClick={handleDeleteConfirmModalDeleteClick}>Delete</Button>
                        <Button onClick={handleDeleteConfirmModalCancelClick}>Cancel</Button>
                    </>
                </ConfirmModal>
            )}
        </>
    );
});

export function getVerificationInfo(verified: VerifyMessageResultX) {
    const { t } = i18next;
    if (verified === 'invalid') {
        return { color: '#f21616', info: t('infoMessage.messageStatus.verificationFailed') };
    }
    if (verified === 'no-signature') {
        return { color: '#f21616', info: t('infoMessage.messageStatus.notSigned') };
    }
    if (verified === 'processing') {
        return { color: '#999999', info: t('infoMessage.messageStatus.verificationInProgress') };
    }
    if (verified === 'verified') {
        return { color: '#0fa827', info: t('infoMessage.messageStatus.verified') };
    }
    return { color: '#ff7800', info: t('infoMessage.messageStatus.unknown') };
}
