import { AvailableDevices, VideoConference, VideoConferenceInitOptions } from "./VideoConference";
import { VideoConferenceState, VideoConferenceParticipant, VideoConferenceConnectionLostReason, VideoConferenceConnectionOptions } from "./Types";
import * as Types from "./Types";
import { CustomDominantSpeakerService } from "./utils/CustomDominantSpeakerService";
import { DominantSpeakerService } from "./utils/DominantSpeakerService";
import { VideoResolutions } from "./VideoResolutions";
import { Deferred } from "./utils/Deferred";
import { JitsiMeetJS as JitsiMeetJSTypes } from "./jitsi/lib-jitsi-meet";
import { CameraConfiguration } from "./utils";

declare global {
    interface Window { JitsiMeetJS: typeof JitsiMeetJSTypes; }
}

declare const JitsiMeetJS: typeof JitsiMeetJSTypes;

enum JitsiParticipantProperty {
    E2EEENABLED = "e2eeEnabled",
    E2EE = "features_e2ee",
    E2EE_KEY = "e2ee.idKey",
}

type VideoTrackSource = "camera" | "desktop";
type VideoTrackType = VideoTrackSource;

export class JitsiVideoConference extends VideoConference {
    
    static readonly ENABLE_E2EE: boolean = true;
    static readonly ENABLE_E2E_PING: boolean = true;
    
    private _isE2EEEnabled: boolean = JitsiVideoConference.ENABLE_E2EE;
    private connection: JitsiMeetJSTypes.JitsiConnection | null = null;
    protected conference: JitsiMeetJSTypes.JitsiConference | null = null;
    protected localDesktopTrack: JitsiMeetJSTypes.JitsiLocalTrack | null = null;
    protected localVideoTrack: JitsiMeetJSTypes.JitsiLocalTrack | null = null;
    protected localAudioTrack: JitsiMeetJSTypes.JitsiLocalTrack | null = null;
    protected remoteVideoTracks: { [participantId: string]: JitsiMeetJSTypes.JitsiRemoteTrack } = {};
    protected remoteAudioTracks: { [participantId: string]: JitsiMeetJSTypes.JitsiRemoteTrack } = {};
    protected localParticipant: VideoConferenceParticipant<null> | null = null;
    protected participants: { [participantId: string]: VideoConferenceParticipant<JitsiMeetJSTypes.JitsiParticipant> } = {};
    private uniqueConnectionId: string | null = null;
    private dominantSpeakerService: DominantSpeakerService = new DominantSpeakerService();
    private customDominantSpeakerService: CustomDominantSpeakerService = new CustomDominantSpeakerService();
    
    constructor(options: VideoConferenceInitOptions) {
        super(options);
        this.dominantSpeakerService.addOnUpdateHandler(this.onDominantSpeakerChanged.bind(this));
        this.customDominantSpeakerService.addOnUpdateHandler(this.onCustomDominantSpeakerChanged.bind(this));
    }
    
    isE2EEEnabled(): boolean {
        return this._isE2EEEnabled;
    }
    
    updateE2EEEnabled(options: Types.VideoConferenceOptions): void {
        this._isE2EEEnabled = JitsiVideoConference.ENABLE_E2EE && !options.disableEncryption;
    }
    
    afterScriptLoaded(version: string): void {
        super.afterScriptLoaded(version);
        JitsiVideoConference.initJitsi();
        JitsiMeetJS.mediaDevices.addEventListener(JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED, () => {
            this.refreshDevicesList();
        });
    }
    
    
    
    
    
    /*****************************************
    ******* Connection and conferences *******
    *****************************************/
    async connect(connectionOptions: VideoConferenceConnectionOptions): Promise<void> {
        try {
            await this.connectCore(connectionOptions);
        }
        catch (e) {
            this.onConnectionLost("connectingFailed", `${e}`);
            console.error("JitsiVideoConference.connect():", e);
            throw e;
        }
    }
    
    private async connectCore(connectionOptions: VideoConferenceConnectionOptions): Promise<void> {
        // Check state, set new state
        if (this.state != VideoConferenceState.DISCONNECTED) {
            throw new Error("JitsiVideoConference.connect(): wrong state (1)");
        }
        this.setState(VideoConferenceState.CONNECTING);
        
        // Connection parameters
        const { configuration, tmpUserName, tmpUserPassword, options } = connectionOptions;
        const uniqueConnectionId = Math.random().toString(36).substr(2);
        this.configuration = configuration;
        this.uniqueConnectionId = uniqueConnectionId;
        this.localParticipant = null;
        this.participants = {};
        const creatingConference = !!(tmpUserName && tmpUserPassword);
        this.updateE2EEEnabled(options);
        const encryptedLocalParticipantName = await this.encryptParticipantName(this.configuration.hashmail);
        this.assertConnectingNotInterrupted(uniqueConnectionId);
                    
        // Create connection and create event listeners
        const connectedDeferred = new Deferred<void>();
        this.connection = new JitsiMeetJS.JitsiConnection(this.configuration.appId!, this.configuration.token!, {
            hosts: {
                anonymousdomain: `guest.${this.configuration.domain}`,
                domain: this.configuration.domain,
                muc: `conference.${this.configuration.domain}`,
            },
            serviceUrl: `https://${this.configuration.domain}/http-bind`,
        });
        this.connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, () => {
            connectedDeferred.resolve();
        });
        this.connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, () => {
            this.onConnectionLost("connectingFailed", "JitsiMeetJS.events.connection.CONNECTION_FAILED");
            connectedDeferred.reject("JitsiVideoConference.connect(): connection failed (JitsiMeetJS.events.connection.CONNECTION_FAILED)");
        });
        this.connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, () => {
            this.onConnectionLost("connectionLost", "JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED");
        });
        this.connection.addEventListener(JitsiMeetJS.errors.connection.SERVER_ERROR, () => {
            this.onConnectionLost("connectionLost", "JitsiMeetJS.errors.connection.SERVER_ERROR");
        });
        this.connection.addEventListener(JitsiMeetJS.errors.connection.CONNECTION_DROPPED_ERROR, () => {
            this.onConnectionLost("connectionLost", "JitsiMeetJS.errors.connection.CONNECTION_DROPPED_ERROR");
        });
        
        // Connect
        if (creatingConference) {
            this.connection.connect({
                id: tmpUserName!,
                password: tmpUserPassword!,
            });
        }
        else {
            this.connection.connect();
        }
        
        await connectedDeferred.getPromise();
        this.assertConnectingNotInterrupted(uniqueConnectionId);
        
        // Join the conference and create event listeners
        const joinedDeferred = new Deferred<void>();
        const e2eeDataChannelDeferred = new Deferred<void>();
        let dataChannelId: number = 0;
        this.conference = this.connection.initJitsiConference(this.configuration.conferenceId, {
            openBridgeChannel: "websocket",
            p2p: {
                enabled: !!options.disableEncryption,
                preferredCodec: options.experimentalH264 ? "h264" : "vp8",
                disabledCodec: options.experimentalH264 ? "vp8" : "h264",
                enforcePreferredCodec: true,
            },
            e2eping: {
                pingInterval: JitsiVideoConference.ENABLE_E2E_PING ? 10000 : -1,
            },
            videoQuality: {
                preferredCodec: options.experimentalH264 ? "h264" : "vp8",
                disabledCodec: options.experimentalH264 ? "vp8" : "h264",
                enforcePreferredCodec: true,
            },
            disallowUnencryptedFrames: true,
            enableEncodedTransformSupport: true,
        });
        // https://github.com/jitsi/jitsi-videobridge/blob/master/doc/allocation.md
        (<any>this.conference).setSenderVideoConstraint(180); // 1080, 720, 540
        (<any>this.conference).setReceiverConstraints({
            constraints: {},
            defaultConstraints: { "maxHeight": 180 },
            // lastN,
            // onStageEndpoints: [],
            // selectedEndpoints: [],
        });
        if (!this.conference.isE2EESupported()) {
            throw new Error(VideoConference.ERROR_E2EE_NOT_SUPPORTED);
        }
        this.localParticipant = {
            id: this.conference.myUserId(),
            hashmail: this.configuration.hashmail,
            e2ee: {
                supports: false,
                enabled: false,
                hasKey: false,
            },
            isTalking: false,
            _participant: null,
        };
        this.dominantSpeakerService.setSpeaker({
            id: this.localParticipant.id,
            isLocal: true,
        });
        this.conference.on(JitsiMeetJS.events.conference.TRACK_ADDED, this.onRemoteTrackAdded.bind(this));
        this.conference.on(JitsiMeetJS.events.conference.TRACK_REMOVED, this.onRemoteTrackRemoved.bind(this));
        this.conference.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {
            this.setState(VideoConferenceState.CONNECTED);
            joinedDeferred.resolve();
        });
        this.conference.on(JitsiMeetJS.events.conference.CONFERENCE_FAILED, () => {
            this.onConnectionLost("connectingFailed", "JitsiMeetJS.events.conference.CONFERENCE_FAILED");
            joinedDeferred.reject("JitsiVideoConference.connect(): conference failed (JitsiMeetJS.events.conference.CONFERENCE_FAILED)");
        });
        this.conference.addEventListener(JitsiMeetJS.errors.conference.CONNECTION_ERROR, () => {
            this.onConnectionLost("connectionLost", "JitsiMeetJS.errors.conference.CONNECTION_ERROR");
        });
        this.conference.addEventListener(JitsiMeetJS.errors.conference.CONFERENCE_DESTROYED, () => {
            this.onConnectionLost("connectionLost", "JitsiMeetJS.errors.conference.CONFERENCE_DESTROYED");
        });
        this.conference.addEventListener("conference.dataChannelOpened", () => {
            dataChannelId++;
            if (dataChannelId === 1) {
                return;
            }
            e2eeDataChannelDeferred.resolve();
        });
        this.conference.addEventListener(JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED, this.onParticipantPropertyChanged.bind(this));
        this.conference.addEventListener(JitsiMeetJS.events.conference.USER_ROLE_CHANGED, this.onParticipantRoleChanged.bind(this));
        this.conference.addEventListener(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoined.bind(this));
        this.conference.addEventListener(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft.bind(this));
        this.conference.addEventListener(JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED, (participantId: string) => {
            this.dominantSpeakerService.setDominantSpeaker(participantId)
        });
        this.conference.addEventListener(JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED, (stats: JitsiMeetJSTypes.ConferenceStats) => {
            // console.log(stats);
            const localParticipant = this.getLocalParticipant();
            if (localParticipant) {
                this.updateParticipantConnectionStats(localParticipant.id, stats);
            }
        });
        this.conference.addEventListener(JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED, (participantId: string, stats: JitsiMeetJSTypes.ConferenceStats) => {
            this.updateParticipantConnectionStats(participantId, stats);
        });
        this.conference.addEventListener("e2eping.e2e_rtt_changed", (participant: JitsiMeetJSTypes.JitsiParticipant, e2ePing: number) => {
            this.updateParticipantConnectionStats(participant._id, {
                e2ePing: e2ePing,
            });
        });
        this.conference.addEventListener(JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED, this.onTrackAudioLevelChanged.bind(this));
        this.conference.setDisplayName(encryptedLocalParticipantName);
        if (creatingConference) {
            this.conference.join();
        }
        else {
            this.conference.join(this.configuration.conferencePassword);
        }
        
        await joinedDeferred.getPromise();
        this.assertConnectingNotInterrupted(uniqueConnectionId);
        
        await this.enableE2EE();
        this.assertConnectingNotInterrupted(uniqueConnectionId);
        
        if (!creatingConference) {
            await Promise.race([
                Deferred.delay(5000),
                e2eeDataChannelDeferred.getPromise(),
            ]);
            this.assertConnectingNotInterrupted(uniqueConnectionId);
            let tracksCreated: boolean = false;
            try {
                await this.createLocalTracks();
                this.assertConnectingNotInterrupted(uniqueConnectionId);
                tracksCreated = true;
            }
            catch {}
            if (!tracksCreated) {
                await Deferred.delay(2500);
                this.assertConnectingNotInterrupted(uniqueConnectionId);
                await this.createLocalTracks();
                this.assertConnectingNotInterrupted(uniqueConnectionId);
            }
        }
    }
    
    private assertConnectingNotInterrupted(uniqueConnectionId: string): void {
        if (this.state != VideoConferenceState.CONNECTING && this.state != VideoConferenceState.CONNECTED) {
            throw new Error("JitsiVideoConference.connect(): wrong state");
        }
        if (this.uniqueConnectionId != uniqueConnectionId) {
            throw new Error("Connecting cancelled due to a new connection attempt");
        }
    }
    
    async disconnect(): Promise<void> {
        this.uniqueConnectionId = null;
        
        // Check state, set new state
        if (this.state == VideoConferenceState.DISCONNECTED) {
            return;
        }
        this.setState(VideoConferenceState.DISCONNECTING);
        
        // Cleanup
        try {
            await this.cleanup();
        }
        catch (e) {
            console.error("JitsiVideoConference.disconnect():", e);
            throw e;
        }
        finally {
            this.setState(VideoConferenceState.DISCONNECTED);
            this.onConnectionLost("disconnected", "JitsiVideoConference.disconnect()");
        }
    }
    
    private async cleanup(): Promise<void> {
        // Clear tracks
        if (this.conference) {
            await this.clearTracks();
        }
        
        // Leave the conference
        if (this.conference) {
            await this.conference.leave();
        }
        
        // Disconnect
        if (this.connection) {
            await this.connection.disconnect();
        }
        
        // Cleanup
        this.conference = null;
        this.connection = null;
    }
    
    private async enableE2EE(): Promise<void> {
        if (!this.conference || this.conference.isE2EEEnabled() || !this._isE2EEEnabled) {
            return;
        }
        await this.conference.toggleE2EE(true);
        if (!this.conference.isE2EEEnabled()) {
            throw new Error("Could not enable E2EE");
        }
        this.conference.setLocalParticipantProperty("e2eeEnabled", true);
        const participantE2ee = this.getParticipant(this.conference.myUserId())?.e2ee;
        if (participantE2ee) {
            participantE2ee.enabled = true;
            participantE2ee.hasKey = true;
            participantE2ee.supports = true;
        }
    }
    
    private async onConnectionLost(reason: VideoConferenceConnectionLostReason, extraInfo: string): Promise<void> {
        this.uniqueConnectionId = null;
        if (this.state == VideoConferenceState.DISCONNECTING) {
            return;
        }
        try {
            await this.cleanup()
        }
        catch (e) {
            console.log("onConnectionLost:", e);
            throw e;
        }
        finally {
            this.setState(VideoConferenceState.DISCONNECTED);
            this.onConnectionLostCallback(reason, extraInfo);
        }
    }
    
    
    
    
    
    /*****************************************
    ***************** Tracks *****************
    *****************************************/
    private async createLocalAudioTrack(deviceId?: string): Promise<void> {
        if (!this.conference) {
            return;
        }
        const options: JitsiMeetJSTypes.JitsiCreateLocalTracksOptions = {
            devices: ["audio"],
            micDeviceId: deviceId ? deviceId : undefined,
        };
        try {
            const tracks = await JitsiMeetJS.createLocalTracks(options);
            const track = tracks[0];
            if (track !== undefined) {
                this.localAudioInputDeviceId = track.getDeviceId();
                await this.addOrReplaceJitsiAudioTrack(track);
            }
            this.onLocalAudioTrackCreatedCallback();
        }
        catch (e) {
            this.showErrorMessage(Types.I18N_KEYS.error_createLocalAudioTrackFailed);
            this.disableLocalAudioInput();
        }
    }
    
    private async createLocalVideoTrack(deviceId?: string): Promise<void> {
        if (!this.conference || !this.isLocalVideoInputEnabled) {
            return;
        }
        const prevRes = this.previouslySetResolution;
        const targetWidth = prevRes ? prevRes.width : VideoResolutions.DEFAULT_RESOLUTION.width;
        const targetHeight = prevRes ? prevRes.height : VideoResolutions.DEFAULT_RESOLUTION.height;
        
        const options: JitsiMeetJSTypes.JitsiCreateLocalTracksOptions = {
            devices: ["video"],
            cameraDeviceId: deviceId ? deviceId : undefined,
            constraints: {
                video: {
                    width: {
                        ideal: targetWidth,
                        min: VideoResolutions.MIN_RESOLUTION.width,
                        max: VideoResolutions.MAX_RESOLUTION.width,
                    },
                    height: {
                        ideal: targetHeight,
                        min: VideoResolutions.MIN_RESOLUTION.height,
                        max: VideoResolutions.MAX_RESOLUTION.height,
                    },
                },
            },
        };
        try {
            const tracks = await JitsiMeetJS.createLocalTracks(options);
            const track = tracks[0];
            const videoTrack = track?.stream.getVideoTracks()[0];
            if (!this.conference || !this.isLocalVideoInputEnabled) {
                return;
            }
            if (track && videoTrack) {
                const currentTrackSettings = videoTrack ? videoTrack.getSettings() : null;
                if (currentTrackSettings && (currentTrackSettings.width != targetWidth || currentTrackSettings.height != targetHeight)) {
                    await videoTrack.applyConstraints({
                        advanced: [{
                            width: targetWidth,
                            height: targetHeight,
                            aspectRatio: targetWidth / targetHeight,
                        }],
                    });
                }
                this.localVideoInputDeviceId = track.getDeviceId();
                await this.addOrReplaceJitsiVideoTrack(track, "camera");
                if (this.supportsVideoEffects()) {
                    await this.applyLocalVideoEffect();
                }
            }
            this.onLocalVideoTrackCreatedCallback();
        }
        catch (e) {
            this.showErrorMessage(Types.I18N_KEYS.error_createLocalVideoTrackFailed);
            this.disableLocalVideoInput();
        };
    }
    
    async createLocalTracks(audioDeviceId?: string, videoDeviceId?: string): Promise<void> {
        await Promise.all([
            this.createLocalAudioTrack(audioDeviceId || this.localAudioInputDeviceId || undefined),
            this.isLocalVideoInputEnabled ? this.createLocalVideoTrack(videoDeviceId || this.localVideoInputDeviceId || undefined) : null,
        ]);
    }
    
    private onRemoteTrackAdded(localOrRemoteTrack: JitsiMeetJSTypes.JitsiTrack): void {
        if (localOrRemoteTrack.isLocal()) {
            return;
        }
        const track = localOrRemoteTrack as JitsiMeetJSTypes.JitsiRemoteTrack;
        const participantId = track.getParticipantId();
        if (!this.isParticipantReady(participantId)) {
            return;
        }
        if (track.getType() == "audio") {
            this.remoteAudioTracks[participantId] = track;
            this.updateIsParticipantTalking(participantId);
            this.onRemoteAudioTrackCreatedCallback(participantId);
            track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, this.onTrackMutedStatusChanged.bind(this));
        }
        else {
            track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, this.onTrackMutedStatusChanged.bind(this));
            track.addEventListener(JitsiMeetJS.events.track.TRACK_VIDEOTYPE_CHANGED, (trackType: VideoTrackType) => {
                this.onTrackVideoTypeChanged(participantId, trackType);
            });
            this.customDominantSpeakerService.setSpeakerIsSharingDesktop(participantId, track.videoType == "desktop");
            this.remoteVideoTracks[participantId] = track;
            this.onRemoteVideoTrackCreatedCallback(participantId);
        }
    }
    
    private onRemoteTrackRemoved(localOrRemoteTrack: JitsiMeetJSTypes.JitsiTrack): void {
        if (localOrRemoteTrack.isLocal()) {
            return;
        }
        const track = localOrRemoteTrack as JitsiMeetJSTypes.JitsiRemoteTrack;
        const participantId = track.getParticipantId();
        if (track.videoType == "desktop") {
            this.customDominantSpeakerService.setSpeakerIsSharingDesktop(participantId, false);
        }
        if (track.getType() == "audio") {
            if (this.remoteAudioTracks[participantId]) {
                this.onRemoteAudioTrackDeletedCallback(participantId);
                delete this.remoteAudioTracks[participantId];
            }
        }
        else {
            if (this.remoteVideoTracks[participantId]) {
                this.onRemoteVideoTrackDeletedCallback(participantId);
                delete this.remoteVideoTracks[participantId];
            }
        }
    }
    
    private onTrackMutedStatusChanged(localOrRemoteTrack: JitsiMeetJSTypes.JitsiRemoteTrack): void {
        if (this.onTrackMutedStatusChangedCallback) {
            this.onTrackMutedStatusChangedCallback(localOrRemoteTrack);
        }
    }
    
    private onTrackVideoTypeChanged(participantId: string, trackType: VideoTrackType): void {
        const isSharingDesktop = trackType == "desktop";
        this.customDominantSpeakerService.setSpeakerIsSharingDesktop(participantId, isSharingDesktop);
    }
    
    private onTrackAudioLevelChanged(participantId: string, audioLevel: number): void {
        this.customDominantSpeakerService.setSpeakerAudioLevel(participantId, audioLevel);
        this.updateIsParticipantTalking(participantId, audioLevel);
        if (this.onTrackAudioLevelChangedCallback) {
            this.onTrackAudioLevelChangedCallback(participantId, audioLevel);
        }
    }
    
    private async clearLocalAudioTrack(): Promise<void> {
        if (!this.localAudioTrack) {
            return;
        }
        await this.conference?.removeTrack(this.localAudioTrack);
        const localAudioTrack = this.localAudioTrack;
        this.localAudioTrack = null;
        await localAudioTrack.dispose();
    }
    
    private async clearLocalVideoTrack(): Promise<void> {
        if (!this.localVideoTrack) {
            return;
        }
        await this.conference?.removeTrack(this.localVideoTrack);
        const localVideoTrack = this.localVideoTrack;
        this.localVideoTrack = null;
        this.cameraConfiguration = null;
        await localVideoTrack.dispose();
    }
    
    private async clearLocalTracks(): Promise<void> {
        await Promise.all([
            this.clearLocalAudioTrack(),
            this.clearLocalVideoTrack(),
            this.clearLocalDesktopTrack(),
        ]);
    }
    
    private clearRemoteAudioTracks(): void {
        for (const participantId in this.remoteAudioTracks) {
            delete this.remoteAudioTracks[participantId];
        }
    }
    
    private clearRemoteVideoTracks(): void {
        for (const participantId in this.remoteVideoTracks) {
            delete this.remoteVideoTracks[participantId];
        }
    }
    
    private clearRemoteTracks(): void {
        this.clearRemoteAudioTracks();
        this.clearRemoteVideoTracks();
    }
    
    private async clearTracks(): Promise<void> {
        await Promise.all([
            this.clearLocalTracks(),
            this.clearRemoteTracks(),
        ]);
    }
    
    private async addOrReplaceJitsiTrack(oldTrack: JitsiMeetJSTypes.JitsiLocalTrack | null, newTrack: JitsiMeetJSTypes.JitsiLocalTrack, disposeOldTrack: boolean): Promise<void> {
        if (oldTrack == newTrack) {
            return;
        }
        if (oldTrack) {
            await this.conference?.replaceTrack(oldTrack, newTrack);
            if (disposeOldTrack) {
                await oldTrack.dispose();
            }
        }
        else {
            await this.conference?.addTrack(newTrack);
        }
    }
    
    private async addOrReplaceJitsiAudioTrack(newAudioTrack: JitsiMeetJSTypes.JitsiLocalTrack): Promise<void> {
        const oldAudioTrack = this.localAudioTrack;
        this.localAudioTrack = newAudioTrack;
        await this.addOrReplaceJitsiTrack(oldAudioTrack, newAudioTrack, true);
    }
    
    private async addOrReplaceJitsiVideoTrack(newVideoTrack: JitsiMeetJSTypes.JitsiLocalTrack, newVideoTrackSource: VideoTrackSource): Promise<void> {
        const oldVideoTrack = this.conference?.getLocalTracks().filter(track => track.getType() == "video")[0] as JitsiMeetJSTypes.JitsiLocalTrack | null;
        const oldVideoTrackSource = oldVideoTrack ? this.getAttachedTrackSource(oldVideoTrack) : null;
        const isSameSource = oldVideoTrackSource == newVideoTrackSource;
        if (newVideoTrackSource == "desktop") {
            this.localDesktopTrack = newVideoTrack;
        }
        else if (newVideoTrackSource == "camera") {
            this.localVideoTrack = newVideoTrack;
        }
        await this.addOrReplaceJitsiTrack(oldVideoTrack, newVideoTrack, isSameSource);
    }
    
    private getAttachedTrackSource(track: JitsiMeetJSTypes.JitsiLocalTrack): VideoTrackSource | null {
        if (track == this.localVideoTrack) {
            return "camera";
        }
        if (track == this.localDesktopTrack) {
            return "desktop";
        }
        return null;
    }
    
    
    
    
    
    /*****************************************
    ************** Audio, video **************
    *****************************************/
    enableLocalAudioOutput(): void {
        if (this.isLocalAudioOutputEnabled) {
            return;
        }
        this.isLocalAudioOutputEnabled = true;
        if (this.onLocalAudioOutputEnabledCallback) {
            this.onLocalAudioOutputEnabledCallback();
        }
    }
    
    disableLocalAudioOutput(): void {
        if (!this.isLocalAudioOutputEnabled) {
            return;
        }
        this.isLocalAudioOutputEnabled = false;
        if (this.onLocalAudioOutputDisabledCallback) {
            this.onLocalAudioOutputDisabledCallback();
        }
    }
    
    async enableLocalAudioInput(): Promise<void> {
        if (this.isLocalAudioInputEnabled) {
            return;
        }
        this.isLocalAudioInputEnabled = true;
        try {
            await this.localAudioTrack?.unmute();
            if (this.isLocalAudioInputEnabled && this.onLocalAudioInputEnabledCallback) {
                this.onLocalAudioInputEnabledCallback();
            }
        }
        catch(e) {
            this.isLocalAudioInputEnabled = false;
            throw e;
        };
    }
    
    async disableLocalAudioInput(): Promise<void> {
        if (!this.isLocalAudioInputEnabled) {
            return;
        }
        await this.localAudioTrack?.mute();
        if (this.onLocalAudioInputDisabledCallback) {
            this.onLocalAudioInputDisabledCallback();
        }
        this.isLocalAudioInputEnabled = false;
    }
    
    async enableLocalVideoInput(): Promise<void> {
        if (this.isLocalVideoInputEnabled) {
            return;
        }
        this.isLocalVideoInputEnabled = true;
        
        if (this.isDesktopSharingEnabled) {
            await this.disableSharingDesktop();
        }
        if (this.localVideoTrack) {
            if (this.onLocalVideoInputEnabledCallback) {
                this.onLocalVideoInputEnabledCallback();
            }
            return;
        }
        
        try {
            await this.createLocalVideoTrack(this.localVideoInputDeviceId ?? undefined);
            if (this.isLocalVideoInputEnabled && this.onLocalVideoInputEnabledCallback) {
                this.onLocalVideoInputEnabledCallback();
            }
        }
        catch (e) {
            this.isLocalVideoInputEnabled = false;
            throw e;
        };
    }
    
    async disableLocalVideoInput(): Promise<void> {
        if (!this.isLocalVideoInputEnabled) {
            return;
        }
        this.isLocalVideoInputEnabled = false;
        
        // Hack that fixes jitsi bug
        // Jitsi bug: disposing track that was replaced causes detaching the new track
        // Hack: clear local video track when disabling desktop sharing
        if (!this.isDesktopSharingEnabled) {
            this.clearLocalVideoTrack();
        }
        if (this.onLocalVideoInputDisabledCallback) {
            this.onLocalVideoInputDisabledCallback();
        }
    }
    
    setAudioOutputDeviceId(deviceId: string): void {
        this.localAudioOutputDeviceId = deviceId;
        JitsiMeetJS.mediaDevices.setAudioOutputDevice(deviceId);
        this.refreshDevicesList();
    }
    
    setAudioInputDeviceId(deviceId: string): void {
        if (!this.isLocalAudioInputEnabled) {
            this.isLocalAudioInputEnabled = true;
            if (this.onLocalAudioInputEnabledCallback) {
                this.onLocalAudioInputEnabledCallback();
            }
        }
        this.localAudioInputDeviceId = deviceId;
        this.createLocalAudioTrack(deviceId);
        this.refreshDevicesList();
    }
    
    setVideoInputDeviceId(deviceId: string): void {
        if (this.isLocalVideoInputEnabled && this.localVideoInputDeviceId == deviceId) {
            return;
        }
        if (!this.isLocalVideoInputEnabled) {
            this.localVideoInputDeviceId = deviceId;
            this.enableLocalVideoInput();
            this.refreshDevicesList();
            return;
        }
        this.localVideoInputDeviceId = deviceId;
        this.createLocalVideoTrack(deviceId);
        this.refreshDevicesList();
    }
    
    protected updateIsParticipantTalking(participantId: string, newAudioLevel: number | null = null): void {
        const participant = this.getParticipant(participantId) as VideoConferenceParticipant<any>;
        if (!participant) {
            return;
        }
        const track = participant == this.localParticipant ? this.localAudioTrack : this.remoteAudioTracks[participantId];
        const audioLevel = newAudioLevel === null ? (track ? track.audioLevel : 0) : newAudioLevel;
        participant.isTalking = audioLevel > VideoConference.PARTICIPANT_TALKING_AUDIO_LEVEL_THRESHOLD;
    }
    
    
    
    
    
    /*****************************************
    ***************** Devices ****************
    *****************************************/
    private async listAvailableDevices(): Promise<MediaDeviceInfo[]> {
        const devicesDeferred = new Deferred<MediaDeviceInfo[]>();
        JitsiMeetJS.mediaDevices.enumerateDevices((devices: MediaDeviceInfo[]) => {
            devicesDeferred.resolve(devices);
        });
        return devicesDeferred.getPromise();
    }
    
    private async refreshDevicesList(): Promise<void> {
        return this.listAvailableDevices().then(devices => {
            this.onDevicesListChangedCallback(devices);
        });
    }
    
    async getAvailableDevices(): Promise<AvailableDevices> {
        return this.listAvailableDevices().then(mediaDevices => {
            return <AvailableDevices>{
                audioOutput: mediaDevices.filter(x => x.kind == "audiooutput").map(x => ({ id: x.deviceId, name: x.label, mediaDeviceInfo: x })),
                audioInput: mediaDevices.filter(x => x.kind == "audioinput").map(x => ({ id: x.deviceId, name: x.label, mediaDeviceInfo: x })),
                videoInput: mediaDevices.filter(x => x.kind == "videoinput").map(x => ({ id: x.deviceId, name: x.label, mediaDeviceInfo: x })),
            };
        });
    }
    
    
    
    
    
    /*****************************************
    ************* Desktop sharing ************
    *****************************************/
    async enableSharingDesktop(): Promise<void> {
        if (this.isDesktopSharingEnabled) {
            return;
        }
        this.isDesktopSharingEnabled = true;
        await this.createDesktopTrack();
        if (this.isDesktopSharingEnabled) {
            this.onDesktopSharingEnabledCallback();
        }
    }
    
    async disableSharingDesktop(): Promise<void> {
        if (!this.isDesktopSharingEnabled) {
            return;
        }
        this.isDesktopSharingEnabled = false;
        
        // Hack that fixes jitsi bug
        // Jitsi bug: disposing track that was replaced causes detaching the new track
        // Hack: clear local video track when disabling desktop sharing
        if (!this.isLocalVideoInputEnabled) {
            await this.clearLocalVideoTrack();
        }
        
        await this.clearLocalDesktopTrack();
        if (this.localVideoTrack && this.isLocalVideoInputEnabled) {
            await this.addOrReplaceJitsiVideoTrack(this.localVideoTrack, "camera");
        }
        this.onDesktopSharingDisabledCallback();
    }
    
    getLocalDesktopTrack(): JitsiMeetJSTypes.JitsiLocalTrack | null {
        return this.isDesktopSharingEnabled ? this.localDesktopTrack : null;
    }
    
    private async clearLocalDesktopTrack(): Promise<void> {
        if (!this.localDesktopTrack) {
            return;
        }
        await this.conference?.removeTrack(this.localDesktopTrack);
        const localDesktopTrack = this.localDesktopTrack;
        this.localDesktopTrack = null;
        await localDesktopTrack.dispose();
    }
    
    private async createDesktopTrack(): Promise<void> {
        if (!this.conference) {
            return;
        }
        const options: JitsiMeetJSTypes.JitsiCreateLocalTracksOptions = <any>{
            devices: ["desktop"],
            // desktopSharingFrameRate: {
            //     min: 5,
            //     max: 5,
            // },
            // constraints: {
            //     video: {
            //         width: {
            //             // ideal: targetWidth,
            //             // min: VideoResolutions.MIN_RESOLUTION.width,
            //             // max: VideoResolutions.MAX_RESOLUTION.width,
            //             max: 2880,
            //         },
            //         height: {
            //             // ideal: targetHeight,
            //             // min: VideoResolutions.MIN_RESOLUTION.height,
            //             // max: VideoResolutions.MAX_RESOLUTION.height,
            //             max: 1620,
            //         },
            //         mandatory: {
            //             // chromeMediaSource: 'desktop',
            //             // chromeMediaSourceId: streamId,
            //             // minFrameRate: desktopSharingFrameRate?.min ?? SS_DEFAULT_FRAME_RATE,
            //             // maxFrameRate: desktopSharingFrameRate?.max ?? SS_DEFAULT_FRAME_RATE,
            //             maxWidth: 2880,
            //             maxHeight: 1620,
            //         }
            //     },
            // },
        };
        try {
            const tracks = await JitsiMeetJS.createLocalTracks(options);
            const track = tracks[0]!;
            const msTrack = (<any>track).track as MediaStreamTrack;
            if (!track) {
                return;
            }
            
            // Resizing based on jitsi-meet
            const { height, width } = (msTrack.getSettings() ?? msTrack.getConstraints()) as { width: number, height: number };
            const isPortrait = height >= width;
            const DESKTOP_STREAM_CAP = 720;
            const highResolutionTrack = width > DESKTOP_STREAM_CAP || height > DESKTOP_STREAM_CAP;
            if (highResolutionTrack) {
                let desktopResizeConstraints: any = {};
                if (height && width) {
                    const advancedConstraints = [ { aspectRatio: (width / height).toPrecision(4) } ];
                    const constraint = isPortrait ? { width: 1620 } : { height: 1620 };
                    advancedConstraints.push(<any>constraint);
                    desktopResizeConstraints.advanced = advancedConstraints;
                } else {
                    desktopResizeConstraints = {
                        width: 2880,
                        height: 1620
                    };
                }
                await msTrack.applyConstraints(desktopResizeConstraints);
            }
            
            await this.addOrReplaceJitsiVideoTrack(track, "desktop");
        }
        catch (e) {
            this.showErrorMessage(Types.I18N_KEYS.error_createLocalDesktopTrackFailed);
            await this.disableSharingDesktop();
        };
    }
    
    
    
    
    
    /*****************************************
    ************** Participants **************
    *****************************************/
    getLocalParticipant(): VideoConferenceParticipant<null> | null {
        return this.localParticipant;
    }
    
    getLocalParticipantId(): string {
        if (!this.conference) {
            let stackTrace: string;
            try {
                throw new Error();
            }
            catch (e) {
                stackTrace = (e as Error).stack ?? "";
            }
            throw new Error(`Video not ready: ${stackTrace}`);
        }
        return this.conference.myUserId();
    }
    
    getParticipants(): { [participantId: string]: VideoConferenceParticipant<JitsiMeetJSTypes.JitsiParticipant> } {
        return this.participants;
    }
    
    getParticipant(participantId: string): VideoConferenceParticipant<JitsiMeetJSTypes.JitsiParticipant | null> | null {
        if (!this.conference) {
            return null;
        }
        return this.conference.myUserId() == participantId ? this.localParticipant : this.participants[participantId] ?? null;
    }
    
    private isParticipantReady(participantId: string): boolean {
        const participant = this.getParticipant(participantId);
        if (!participant) {
            return false;
        }
        if (this._isE2EEEnabled) {
            return participant.e2ee.enabled && participant.e2ee.hasKey;
        }
        else {
            return true;
        }
    }
    
    private async onUserJoined(participantId: string, participant: JitsiMeetJSTypes.JitsiParticipant): Promise<void> {
        this.participants[participantId] = {
            id: participant._id,
            hashmail: participant._displayName,
            e2ee: {
                supports: false,
                enabled: false,
                hasKey: false,
            },
            isTalking: false,
            _participant: participant,
        };
        this.customDominantSpeakerService.setSpeaker({
            id: participantId,
            audioLevel: 0,
            isLocal: false,
            isSharingDesktop: false,
        });
        this.dominantSpeakerService.setSpeaker({
            id: participantId,
            isLocal: false,
        });
        const hashmail = await this.decryptParticipantName(participant._displayName);
        const videoConferenceParticipant = this.participants[participantId];
        if (videoConferenceParticipant) {
            videoConferenceParticipant.hashmail = hashmail;
            this.onUserJoinedCallback(participantId);
        }
    }
    
    private onUserLeft(participantId: string): void {
        delete this.participants[participantId];
        this.customDominantSpeakerService.removeSpeaker(participantId);
        this.dominantSpeakerService.removeSpeaker(participantId);
        this.onUserLeftCallback(participantId);
    }
    
    // @ts-ignore
    private onParticipantPropertyChanged(jitsiParticipant: JitsiMeetJS.JitsiParticipant, propertyName: JitsiParticipantProperty, oldValue: any, newValue: any): void {
        const participant = this.getParticipant(jitsiParticipant._id);
        if (!participant) {
            return;
        }
        if (propertyName == JitsiParticipantProperty.E2EE) {
            const isEnabled = newValue === "true" || newValue === true;
            participant.e2ee.supports = isEnabled;
        }
        else if (propertyName == JitsiParticipantProperty.E2EEENABLED) {
            const isEnabled = newValue === "true" || newValue === true;
            participant.e2ee.enabled = isEnabled;
        }
        else if (propertyName == JitsiParticipantProperty.E2EE_KEY) {
            const isEnabled = !!newValue;
            participant.e2ee.hasKey = isEnabled;
        }
    }
    
    private onParticipantRoleChanged(participantId: string, role: "none "| "moderator"): void {
        if (this.conference && this.configuration && participantId == this.conference.myUserId() && role == "moderator" && !this.conference.room.locked) {
            this.conference.lock(this.configuration.conferencePassword);
        }
    }
    
    private onCustomDominantSpeakerChanged(): void {
        if (this.onCustomDominantSpeakerChangedCallback) {
            this.onCustomDominantSpeakerChangedCallback();
        }
    }
    
    private onDominantSpeakerChanged(): void {
        if (this.onDominantSpeakerChangedCallback) {
            this.onDominantSpeakerChangedCallback();
        }
    }
    
    getCustomDominantSpeaker(): VideoConferenceParticipant<any> | null {
        const dominantSpeakerId = this.customDominantSpeakerService.getDominantSpeakerId();
        if (!dominantSpeakerId) {
            return this.getDefaultDominantSpeaker();
        }
        const participant = this.getParticipant(dominantSpeakerId);
        return participant;
    }
    
    getDominantSpeaker(): VideoConferenceParticipant<any> | null {
        const dominantSpeakerId = this.dominantSpeakerService.getDominantSpeakerId();
        if (!dominantSpeakerId) {
            return null;
        }
        const participant = this.getParticipant(dominantSpeakerId);
        return participant;
    }
    
    private getDefaultDominantSpeaker(): VideoConferenceParticipant<any> | null {
        const localParticipantId = this.localParticipant ? this.localParticipant.id : null;
        for (const participantId in this.participants) {
            if (localParticipantId != participantId) {
                return this.participants[participantId]!;
            }
        }
        return localParticipantId ? (this.participants[localParticipantId] ?? null) : null;
    }
    
    private updateParticipantConnectionStats(participantId: string, stats: JitsiMeetJSTypes.ConferenceStats): void {
        this.onParticipantConnectionStatsUpdatedCallback(participantId, stats);
    }
    
    
    
    
    
    /*****************************************
    ********** Jitsi initialization **********
    *****************************************/
    private static isJitsiInitialized: boolean = false;
    private static initJitsi(): void {
        if (this.isJitsiInitialized) {
            return;
        }
        this.isJitsiInitialized = true;
        JitsiMeetJS.init({});
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.WARN);
    }
    
    
    
    
    
    /*****************************************
    ********** Camera configuration **********
    *****************************************/
    protected async trySetupCameraConfiguration(): Promise<void> {
        if (this.conference && this.conference.getLocalVideoTrack() && this.conference.getLocalVideoTrack().stream) {
            const localVideoTrack = this.conference.getLocalVideoTrack();
            const mediaStream = localVideoTrack.stream;
            const cameraConfiguration = new CameraConfiguration(mediaStream, VideoResolutions.AVAILABLE_RESOLUTIONS);
            this.cameraConfiguration = cameraConfiguration;
            await cameraConfiguration.setResolution(VideoResolutions.DEFAULT_RESOLUTION);
        }
    }
    
    
    
    
    
    /*****************************************
    ************** Video effects *************
    *****************************************/
    supportsVideoEffects(): boolean | undefined {
        if (this.scriptVersion === undefined) {
            return undefined;
        }
        return this.compareVersions(this.scriptVersion, "1.1.0") >= 0;
    }
    protected async applyLocalVideoEffect(): Promise<boolean> {
        if (!this.localVideoTrack || !this.supportsVideoEffects()) {
            return false;
        }
        let result: boolean = false;
        try {
            let virtualBackground = undefined;
            if (this.localVideoEffect) {
                if (this.localVideoEffect.type === "blur") {
                    virtualBackground = {
                        backgroundEffectEnabled: true,
                        backgroundType: JitsiMeetJS.VIRTUAL_BACKGROUND_TYPE.BLUR,
                        blurValue: this.localVideoEffect.blur,
                        virtualSource: "",
                    };
                }
                else if (this.localVideoEffect.type === "image") {
                    virtualBackground = {
                        backgroundEffectEnabled: true,
                        backgroundType: JitsiMeetJS.VIRTUAL_BACKGROUND_TYPE.IMAGE,
                        blurValue: 0,
                        virtualSource: this.localVideoEffect.image,
                    };
                }
            }
            result = await this.localVideoTrack.toggleVirtualBackground({
                enabled: !!this.localVideoEffect,
                virtualBackground: virtualBackground,
            });
        }
        catch (e) {
            console.error("JitsiVideoConference.applyLocalVideoEffect():", e);
        }
        return result;
    }
    
    
    
    
    
    /*****************************************
    ****************** Misc ******************
    *****************************************/
    // @ts-ignore
    private log(...args: any[]): void {
        if (args.length > 0 && typeof(args[0]) == "string") {
            args[0] = "%c" + args[0];
            args.splice(1, 0, "background:#333; color:#fff; padding:2px;");
            console.log(...args);
        }
    }
    
    supportsScriptVersion(version: string): boolean {
        return this.compareVersions(version, "1.0.26") >= 0;
    }
    
}
