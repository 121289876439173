export class UrlBuilder {
    static readonly origin = document.location.origin;
    static readonly contextPath = process.env.REACT_APP_CONTEXT_PATH || '/';
    static readonly baseUrl = UrlBuilder.origin + UrlBuilder.contextPath;

    private static joinUrl(a: string, b: string) {
        const postA = a.endsWith('/') ? a : a + '/';
        const postB = b.startsWith('/') ? b.slice(1) : b;
        return postA + postB;
    }

    static buildUrl(url: string) {
        return UrlBuilder.joinUrl(UrlBuilder.baseUrl, url);
    }
}
