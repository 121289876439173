import {
    TextInputProps,
    TextareaProps,
    TextInput,
    Textarea,
    Group,
    ScrollArea,
    ActionIcon,
    rem,
    Text,
    Box,
    Tooltip,
    Kbd,
    Button
} from '@mantine/core';
import {
    useDisclosure,
    useInputState,
    useClickOutside,
    useFocusTrap,
    getHotkeyHandler
} from '@mantine/hooks';
import { IconDeviceFloppy, IconExternalLink, IconX } from '@tabler/icons-react';
import { useContactViewStyles } from './styles';
import { ChangeEvent, ReactNode, useCallback, useState } from 'react';
import useOsHotkeys from '../../../hooks/useOsHotkeys';
import { ValidationRule, Validator } from '../../../utils/Validator';
import { useTranslation } from 'react-i18next';

type ContactFieldInputProps = {
    inputProps?: TextInputProps;
    value?: string;
    type: 'input';
};

type ContactFieldTextareaProps = {
    inputProps?: TextareaProps;
    value?: string;
    type: 'textarea';
};

type FieldProps = {
    name: string;
    icon: ReactNode;
    placeholder: string;
    onSave: (newValue: string | undefined) => void | Promise<void>;
    validator?: ValidationRule[];
    maxLength?: { value: number; message: string };
    isUrl?: boolean;
};

type ContactFieldProps = FieldProps & (ContactFieldInputProps | ContactFieldTextareaProps);

export function ContactField({
    onSave,
    placeholder,
    inputProps,
    value,
    type,
    icon,
    name,
    validator,
    maxLength,
    isUrl
}: ContactFieldProps) {
    const { classes, cx } = useContactViewStyles();
    const { t } = useTranslation();

    const [isEditing, { open, close }] = useDisclosure(false);

    const [newValue, setInputState] = useInputState(value || '');
    const [hasError, setError] = useState<boolean>(false);

    const onValueChange = useCallback(
        (e: ChangeEvent<any>) => {
            setError(false);
            setInputState(e);
        },
        [setInputState]
    );

    const handleCancel = useCallback(
        function () {
            close();
            setError(false);
            setInputState('');
        },
        [close, setInputState]
    );

    const handleSave = useCallback(
        function () {
            if (maxLength && newValue.length > maxLength.value) {
                return;
            }
            const valueToSave = newValue.trim();
            if (valueToSave !== value) {
                try {
                    onSave(Validator.check(valueToSave, validator || []));
                } catch (e) {
                    console.error(e);
                    setError(true);
                    return;
                }
            }
            close();

            setInputState('');
        },
        [close, newValue, onSave, setInputState, validator, value, maxLength]
    );

    const ref = useClickOutside(() => handleCancel());
    const focusTrapRef = useFocusTrap();

    function handleEdit() {
        setInputState(value);
        open();
    }

    const keyMap = useOsHotkeys();

    function getErrorMessage() {
        if (hasError) {
            return 'Invalid value';
        } else if (maxLength && newValue.length > maxLength?.value) {
            return maxLength.message;
        } else {
            return undefined;
        }
    }

    if (isEditing) {
        if (type === 'input') {
            return (
                <Group position="apart" className={cx(classes.placeholder, classes.hovered)}>
                    <Group sx={{ flexShrink: 0 }}>
                        {icon}
                        <Text color="dimmed">{name}</Text>
                    </Group>
                    <div className={classes.fieldContent} ref={ref}>
                        <Box
                            display={'flex'}
                            w="100%"
                            sx={{ flexDirection: 'row' }}
                            component={'form'}
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSave();
                            }}>
                            <TextInput
                                size="xs"
                                mr="sm"
                                error={getErrorMessage()}
                                sx={{ flexGrow: 1 }}
                                maxLength={maxLength?.value}
                                placeholder={placeholder}
                                value={newValue}
                                onChange={onValueChange}
                                ref={focusTrapRef}
                                onKeyDown={getHotkeyHandler([['Escape', handleCancel]])}
                                inputWrapperOrder={['label', 'input', 'error', 'description']}
                                description={
                                    <>
                                        Press <Kbd size="xs">Enter</Kbd> to save or{' '}
                                        <Kbd size="xs">Esc</Kbd> to cancel
                                    </>
                                }
                                {...inputProps}
                            />
                            <Tooltip label="Save" openDelay={500}>
                                <ActionIcon mr={4} color="green" type="submit">
                                    <IconDeviceFloppy size={rem(14)} />
                                </ActionIcon>
                            </Tooltip>
                            <Tooltip label="Cancel" openDelay={500}>
                                <ActionIcon color="red" onClick={handleCancel}>
                                    <IconX size={rem(14)} />
                                </ActionIcon>
                            </Tooltip>
                        </Box>
                    </div>
                </Group>
            );
        } else {
            return (
                <Group position="apart" className={cx(classes.placeholder, classes.hovered)}>
                    <Group sx={{ flexShrink: 0 }}>
                        {icon}
                        <Text color="dimmed">{name}</Text>
                    </Group>
                    <div className={classes.fieldContent} ref={ref}>
                        <Box
                            display={'flex'}
                            w="100%"
                            sx={{ flexDirection: 'row' }}
                            component={'form'}
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSave();
                            }}>
                            <Textarea
                                inputWrapperOrder={['label', 'input', 'error', 'description']}
                                description={
                                    <>
                                        Press <Kbd size="xs">{keyMap['mod']}+Enter</Kbd> to save or{' '}
                                        <Kbd size="xs">Esc</Kbd> to cancel
                                    </>
                                }
                                maxLength={maxLength?.value}
                                error={getErrorMessage()}
                                size="xs"
                                mr="sm"
                                sx={{ flexGrow: 1 }}
                                placeholder={placeholder}
                                value={newValue}
                                onChange={onValueChange}
                                onKeyDown={getHotkeyHandler([
                                    ['Escape', handleCancel],
                                    ['mod+Enter', handleSave]
                                ])}
                                {...inputProps}
                            />
                            <Tooltip label="Save" openDelay={500}>
                                <ActionIcon mr={4} color="green" type="submit">
                                    <IconDeviceFloppy size={rem(14)} />
                                </ActionIcon>
                            </Tooltip>
                            <Tooltip label="Cancel" openDelay={500}>
                                <ActionIcon color="red" type="reset">
                                    <IconX size={rem(14)} />
                                </ActionIcon>
                            </Tooltip>
                        </Box>
                    </div>
                </Group>
            );
        }
    }

    if (!value) {
        return (
            <Group position="apart" className={classes.hover} onClick={handleEdit}>
                <Group sx={{ flexShrink: 0 }}>
                    {icon}
                    <Text color="dimmed">{name}</Text>
                </Group>
                <div className={classes.fieldContent}>
                    <Text
                        align="right"
                        className={cx(classes.placeholder, classes.hover)}
                        color="dimmed"
                        onClick={open}>
                        + {placeholder}
                    </Text>
                </div>
            </Group>
        );
    }

    return (
        <Group
            position="apart"
            className={cx(classes.placeholder, classes.hover)}
            onClick={handleEdit}>
            <Group sx={{ flexShrink: 0 }}>
                {icon}
                <Text color="dimmed">{name}</Text>
            </Group>
            <div className={classes.fieldContent}>
                <ScrollArea.Autosize mah={150}>
                    <Text className={classes.fieldValue}>
                        {value}
                        {isUrl && (
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={value.match(/^https?:\/\//) ? value : `https://${value}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                className={cx(classes.link)}>
                                <Button
                                    rightIcon={<IconExternalLink size={rem(14)} />}
                                    size="xs"
                                    ml="sm"
                                    variant="outline">
                                    {t('action.openInNewTab')}
                                </Button>
                            </a>
                        )}
                    </Text>
                </ScrollArea.Autosize>
            </div>
        </Group>
    );
}
