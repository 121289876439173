import * as privfs from 'privfs-client';
import { PrivmxConst } from '../../PrivmxConst';
import { AdminDataV2Encryptor } from '../AdminDataV2Encryptor';
import { UserSettingsService } from '../../UserSettingsService';
import {
    AdminData,
    UserSinks,
    UserKvdbs,
    UserCredentials,
    UserProfile,
    UserCreationContextSimple,
    UserCreationParamsForContextBuilder,
    UserProfileData,
    AdminDataInner,
    SharedExtKeyProvider
} from './Types';
import { SinkCreator } from './SinkCreator';
import { KvdbCreator } from './KvdbCreator';
import { UserPreferences } from '../../UserPreferences';

export class UserCreationContextBuilder {
    constructor(
        private gateway: privfs.gateway.RpcGateway,
        private pki: privfs.pki.PrivmxPKI,
        private adminDataV2Encryptor: AdminDataV2Encryptor,
        private sharedExtKeyProvider: SharedExtKeyProvider
    ) {}

    async build(params: UserCreationParamsForContextBuilder): Promise<UserCreationContextSimple> {
        const result = await this.generateIdentity(params);
        const identity = result.identity;
        const adminData = await this.createAdminData({
            username: params.username,
            password: params.password,
            masterSeed: identity.masterRecord.l1.masterSeed,
            recovery: identity.masterRecord.l1.recovery
        });
        const sinks = await this.createSinks(identity.masterExtKey);
        const keystore = await this.createKeystore({
            username: params.username,
            data: params.profile,
            privKey: identity.identityKey,
            inbox: sinks.inbox.sink
        });
        const kvdbs = await this.createKvdbs(identity.masterExtKey);
        const kvdbInserts = await this.createKvdbInserts(kvdbs, params.shareKvdb, params.profile);
        const context: UserCreationContextSimple = {
            registerResult: result,
            identity: identity,
            adminData: adminData,
            sinks: sinks,
            keystore: keystore,
            kvdbs: kvdbs,
            kvdbInserts: kvdbInserts,
            messages: [],
            section: null
        };
        return context;
    }

    private async generateIdentity(
        params: privfs.types.core.RegisterParamsEx2
    ): Promise<privfs.types.core.RegisterCoreResultWithIdentity> {
        const userApi = new privfs.core.UserBaseApi(this.gateway);
        const info = await userApi.info();
        return await privfs.core.Registration.registerCoreEx(
            info,
            PrivmxConst.IDENTITY_INDEX,
            params
        );
    }

    private async createAdminData(credentials: UserCredentials): Promise<AdminData> {
        const adminData: AdminDataInner = {
            masterSeed: credentials.masterSeed,
            recovery: credentials.recovery,
            generatedPassword: credentials.password
        };
        return {
            data: adminData,
            encrypted: await this.adminDataV2Encryptor.encrypt(adminData)
        };
    }

    private async createSinks(extKey: privfs.crypto.ecc.ExtKey): Promise<UserSinks> {
        const sinkCreator = await this.createSinkCreator(extKey);
        const sinks: UserSinks = {
            inbox: await sinkCreator.createInbox(),
            outbox: await sinkCreator.createOutbox(),
            trash: await sinkCreator.createTrash(),
            contact: await sinkCreator.createContactForm()
        };
        this.createSinkProxy(sinks.contact, sinks.inbox);
        return sinks;
    }

    private async createKeystore(
        userProfile: UserProfile
    ): Promise<privfs.types.core.KeystoreWithInsertionParams> {
        const keyPair = privfs.pki.KeyStore.EccKeyPair.generateFromKey(userProfile.privKey.key);
        const identityKeypair: privfs.types.identity.IdentityKeypair = {
            username: userProfile.username,
            keyPair: keyPair
        };
        return await privfs.core.KeystoreUtils.createKeystoreFromProfileWithInsertionSignatureData(
            this.pki,
            identityKeypair,
            {
                ...(userProfile.data || {}),
                sinks: [userProfile.inbox]
            }
        );
    }

    private async createKvdbs(extKey: privfs.crypto.ecc.ExtKey): Promise<UserKvdbs> {
        const kvdbCreator = new KvdbCreator(extKey);
        const kvdbs: UserKvdbs = {
            settings: await kvdbCreator.createSettingsKvdb(),
            tagsProvider: await kvdbCreator.createTagsProviderKvdb(),
            mailFilter: await kvdbCreator.createMailFilterKvdb(),
            pkiCache: await kvdbCreator.createPkiCacheKvdb(),
            contacts: await kvdbCreator.createContactsKvdb()
        };
        return kvdbs;
    }

    private async createKvdbInserts(
        kvdbs: UserKvdbs,
        shareKvdb: boolean,
        profile?: UserProfileData
    ): Promise<privfs.types.db.KvdbSetEntryModel[]> {
        const inserts: privfs.types.db.KvdbSetEntryModel[] = [];
        if (shareKvdb) {
            inserts.push(
                await UserSettingsService.createInsertion(
                    kvdbs.settings,
                    this.sharedExtKeyProvider.getSharedExtKey()
                )
            );
        }
        if (profile) {
            inserts.push(await UserPreferences.createInsertion(kvdbs.settings, profile));
        }
        return inserts;
    }

    // ====================
    //       HELPERS
    // ====================

    private async createSinkCreator(masterExtKey: privfs.crypto.ecc.ExtKey) {
        const sinkEncryptor = await this.createSinkEncryptor(masterExtKey);
        return new SinkCreator(sinkEncryptor);
    }

    private async createSinkEncryptor(masterExtKey: privfs.crypto.ecc.ExtKey) {
        const extKey = await privfs.core.CryptoUtils.deriveHardened(
            masterExtKey,
            PrivmxConst.SINK_ENCRYPTOR_INDEX
        );
        return privfs.crypto.service.getObjectEncryptor(extKey.getChainCode());
    }

    private createSinkProxy(
        from: privfs.types.message.SinkWithCreateModel,
        to: privfs.types.message.SinkWithCreateModel
    ) {
        from.sink.addToProxyTo(to.sink.id);
        to.sink.addToProxyFrom(from.sink.id);
        to.data.options = to.sink.options;
        from.data.options = from.sink.options;
    }
}
