import * as privmx from 'privfs-client';
import {
    ConnectionScreenCoverCheckingConnectionStatusState,
    ConnectionScreenCoverConnectedState,
    ConnectionScreenCoverReloggingState,
    ConnectionScreenCoverReloginFailedState,
    setConnectionScreenCoverStateEventName
} from '../../components/ConnectionScreenCover';
import { Deferred } from '../../utils/Deferred';
import { IReconnectController } from './ConnectionChecker';
import { NetworkStatusService } from './NetworkStatusService';
import { t } from 'i18next';

export class ReconnectService {
    constructor(private networkStatusService: NetworkStatusService) {}

    onDisconnect(reason: 'destroy' | 'connection-error') {
        console.info('onDisconnect', Date.now(), reason);
        if (reason !== 'destroy') {
            window.dispatchEvent(
                new CustomEvent<ConnectionScreenCoverCheckingConnectionStatusState>(
                    setConnectionScreenCoverStateEventName,
                    { detail: { state: 'checkingConnectionStatus' } }
                )
            );
        }
        this.networkStatusService.pauseNetworkActivity();
    }

    onConnected() {
        window.dispatchEvent(
            new CustomEvent<ConnectionScreenCoverConnectedState>(
                setConnectionScreenCoverStateEventName,
                {
                    detail: { state: 'connected' }
                }
            )
        );
        this.networkStatusService.restoreNetworkActivity();
    }

    onBeforeReconnect() {}

    onBeforeRelogin() {
        window.dispatchEvent(
            new CustomEvent<ConnectionScreenCoverReloggingState>(
                setConnectionScreenCoverStateEventName,
                {
                    detail: { state: 'relogging' }
                }
            )
        );
    }

    onReloginFailure(errorMessage: string) {
        window.dispatchEvent(
            new CustomEvent<ConnectionScreenCoverReloginFailedState>(
                setConnectionScreenCoverStateEventName,
                {
                    detail: { state: 'reloginFailed', errorMessage }
                }
            )
        );
    }

    onRelogin(reloginFunc: () => Promise<void>): IReconnectController {
        const deferred = new Deferred<void>();
        this.onReloginCore(reloginFunc, deferred);
        return {
            cancelReconnect: () => {
                deferred.reject('loginError.cancelled');
            },
            promise: deferred.promise
        };
    }

    private async onReloginCore(reloginFunc: () => Promise<void>, deferred: Deferred<void>) {
        try {
            await reloginFunc();
            deferred.resolve();
        } catch (e) {
            console.error('Error during relogin', e);
            let msg = '';
            let shouldRetry = true;
            if (e === 'additional-login-step-fail') {
                shouldRetry = false;
                msg = t('loginError.additionalVerificationFailed');
            } else if (e === 'additional-login-step-cancel') {
                shouldRetry = false;
                msg = t('loginError.additionalVerificationCancelled');
            } else if (privmx.core.ApiErrorCodes.isAssertError(e, 'ALLOW_WITH_MAINTENANCE')) {
                msg = t('loginError.maintenanceMode');
            } else if (privmx.core.ApiErrorCodes.isEx(e, 'MAINTENANCE_MODE')) {
                msg = t('loginError.maintenanceMode');
            } else if (privmx.core.ApiErrorCodes.isEx(e, 'DIFFERENT_M1')) {
                shouldRetry = false;
                msg = t('loginError.invalidPassword');
            } else if (privmx.core.ApiErrorCodes.isEx(e, 'USER_DOESNT_EXIST')) {
                shouldRetry = false;
                msg = t('loginError.invalidPassword');
            } else if (privmx.core.ApiErrorCodes.isEx(e, 'USER_BLOCKED')) {
                shouldRetry = false;
                msg = t('loginError.accountBlocked');
            } else if (privmx.core.ApiErrorCodes.isEx(e, 'DEVICE_BLOCKED')) {
                shouldRetry = false;
                msg = t('loginError.deviceBlocked');
            } else if (this.isServerApiError(e)) {
                msg = t('loginError.apiVersion');
            } else if (privmx.core.ApiErrorCodes.isEx(e, 'INVALID_VERIFIER')) {
                shouldRetry = false;
                msg = t('loginError.invalidPassword');
            } else if (privmx.core.ApiErrorCodes.isEx(e, 'LOGIN_BLOCKED')) {
                shouldRetry = false;
                msg = t('loginError.serverBlocked');
            } else {
                msg = `${e}`;
            }
            if (!shouldRetry) {
                deferred.reject({ shouldRetry: false });
            }
            deferred.reject(msg);
        }
    }

    private isServerApiError(e: unknown): boolean {
        return (
            this.isErrorLike(e) &&
            (e.message.includes('Unkown server agent') ||
                e.message.includes('Unkown api version') ||
                e.message.includes('Server api version too low'))
        );
    }

    private isErrorLike(e: unknown): e is { message: string } {
        return (
            typeof e == 'object' &&
            e != null &&
            typeof (e as { message: string }).message == 'string'
        );
    }
}
