import { Grid, Text, Modal, Center, Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { ImprtedFileImportResultFlat } from './types';

interface ImportContactsModalProps {
    handleClose: () => void;
    importResults: ImprtedFileImportResultFlat[];
}

export function ImportContactsModal(props: ImportContactsModalProps) {
    const { t } = useTranslation();

    return (
        <Modal
            opened
            onClose={props.handleClose}
            title={t('modal.importContactsResults.title')}
            size={900}>
            {props.importResults.map((res, idx) => (
                <Grid columns={12} key={idx}>
                    <Grid.Col span={6}>
                        <Text truncate>
                            {res.contactName
                                ? `${res.contactName} (${res.importedFile.name})`
                                : res.importedFile.name}
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Text color={res.result === 'imported' ? 'green' : 'red'}>
                            {res.message === undefined
                                ? t(`modal.importContactsResults.result.${res.result}`)
                                : res.message}
                        </Text>
                    </Grid.Col>
                </Grid>
            ))}
            <Center>
                <Button mt="lg" onClick={props.handleClose}>
                    {t('action.close')}
                </Button>
            </Center>
        </Modal>
    );
}
