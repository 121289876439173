import { Sx, MantineTheme, Text } from '@mantine/core';
import parse, { HTMLReactParserOptions, attributesToProps } from 'html-react-parser';
import { JsonMessage } from '../../../../components/MessageView/Message/jsonMessages/JsonMessage';
import { LastMessage } from '../../../../types/Types';

export const lastEventStyle: Sx = (theme: MantineTheme) => {
    return {
        '& .mantine-Text-root': {
            fontSize: theme.fontSizes.xs,
            fontWeight: 'normal',
            color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6]
        },
        '& .mantine-Group-root': {
            marginLeft: 0,
            justifyContent: 'flex-start'
        },
        '& .mantine-Avatar-root': {
            display: 'none'
        },
        '& .meeting-scheduled-event': {
            flexDirection: 'row',
            gap: 4,
            '& .go-to-meeting-btn': {
                display: 'none'
            }
        }
    };
};

export const lastMessageParser = (lastMessage: LastMessage) => {
    if (lastMessage.mimetype === 'application/json') {
        return (
            <Text size="xs" sx={lastEventStyle}>
                <JsonMessage
                    withButton={false}
                    isLastMessage
                    withBorder={false}
                    message={lastMessage}
                />
            </Text>
        );
    }

    const options: HTMLReactParserOptions = {
        replace(domNode) {
            if (domNode.type === 'tag' && 'name' in domNode && domNode.name === 'a') {
                const props = attributesToProps(domNode.attribs);
                return <span {...props}></span>;
            }
        }
    };
    return parse(lastMessage.text, options);
};
