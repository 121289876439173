import { createContext, useContext } from 'react';
import { useConfirmDialog } from '.';

const confirmDialogContext = createContext<ReturnType<typeof useConfirmDialog>>({
    controller: { open: false, onAccept: { current: undefined }, onCancel: { current: undefined } },
    openDialog() {}
});

export function useConfirmDialogContext() {
    return useContext(confirmDialogContext);
}

export const ConfirmDialogContextProvider = confirmDialogContext.Provider;
