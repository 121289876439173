import type { StylesConfig } from 'react-select';
import { DefaultTheme } from 'styled-components';

export type ThemeName = 'light' | 'dark';
export const themeNames: ThemeName[] = ['light', 'dark'];

export function isTheme(themeName: string): themeName is ThemeName {
    return themeNames.includes(themeName as ThemeName);
}

const localStorageThemeKey = 'theme';

export function getThemeFromLocalStorage() {
    const value = localStorage.getItem(localStorageThemeKey);
    return value && isTheme(value) ? value : 'light';
}

export function setThemeInLocalStorage(theme: ThemeName) {
    localStorage.setItem(localStorageThemeKey, theme);
}

const grey = {
    '50': '#FFFFFF',
    '100': '#FAFAFA',
    '200': '#F5F5F5',
    '300': '#F0F0F0',
    '400': '#E7E7E7',
    '500': '#E2E2E2',
    '600': '#D9D9D9',
    '700': '#959595',
    '800': '#3F3F3F',
    '900': '#000000'
};

const darkThemev2 = {
    '50': '#1D1C1E',
    '100': '#151515',
    '200': '#1C1C1C',
    '300': '#2C2C2C',
    '400': '#3A3A3A',
    '500': '#4A4A4C',
    '600': '#C0C0C0',
    '700': '#D9D9D9',
    '900': '#E8E8E8',
    '800': '#FFFFFF'
};

const red = {
    '50': '#FEF2F2',
    '100': '#FEE2E2',
    '200': '#FECACA',
    '300': '#FCA5a5',
    '400': '#F87171',
    '500': '#EF4444',
    '600': '#dc2626',
    '700': '#b91c1c',
    '800': '#991b1b',
    '900': '#7f1d1d'
};

const green = {
    '50': '#F0FDF4',
    '100': '#DCFCE7',
    '200': '#BBF7d0',
    '300': '#86efac',
    '400': '#4ADE80',
    '500': '#22C55e',
    '600': '#16a34a',
    '700': '#15803d',
    '800': '#166534',
    '900': '#14532d'
};

const unread = '#00a4db';

export const darkTheme: DefaultTheme = {
    type: 'dark',
    pallete: {
        grey: darkThemev2,
        red,
        green
    },
    contrastScale: 0.5,
    colors: {
        primary: {
            background: darkThemev2['50'],
            border: darkThemev2['500'],
            font: darkThemev2['700'],
            backgroundContrast: darkThemev2['300'],
            card: darkThemev2['400']
        },
        secondary: {
            background: '',
            border: '',
            font: '',
            backgroundContrast: '',
            card: ''
        },
        succes: {
            background: green['200'],
            border: green['900'],
            font: green['900'],
            backgroundContrast: green['300'],
            card: green['400']
        },
        error: {
            background: red['200'],
            border: red['900'],
            font: red['900'],
            backgroundContrast: red['300'],
            card: red['400']
        },
        unread
    },
    spacing: function (size: number): string {
        const calc = size * 4 + Math.round(size / 16);
        return `${calc / 16}rem`;
    },
    borderRadius: {
        small: '0.3rem',
        default: '0.5rem',
        large: '1rem',
        full: '999px'
    },
    fontSize: {
        s: `${14 / 16}rem`,
        m: `${16 / 16}rem`,
        l: `${18 / 16}rem`,
        xl: `${20 / 16}rem`,
        '2xl': `${28 / 16}rem`
    },
    shadow: {
        '1': `0px 0px 13px rgba(255,255,255, 0.1)`,
        '2': '',
        '3': '',
        '4': '',
        '5': ''
    }
};

export const theme: DefaultTheme = {
    type: 'light',
    pallete: {
        grey: grey,
        red,
        green
    },
    contrastScale: 1,
    colors: {
        primary: {
            background: grey['50'],
            border: grey['500'],
            font: grey['800'],
            backgroundContrast: grey['400'],
            card: grey['200']
        },
        secondary: {
            background: '',
            border: '',
            font: '',
            card: '',
            backgroundContrast: ''
        },
        succes: {
            background: green['200'],
            border: green['900'],
            font: green['900'],
            backgroundContrast: grey['400'],
            card: grey['50']
        },
        error: {
            background: red['200'],
            border: red['900'],
            font: red['900'],
            backgroundContrast: grey['400'],
            card: grey['50']
        },
        unread
    },
    spacing: function (size: number): string {
        const calc = size * 4 + Math.round(size / 16);
        return `${calc / 16}rem`;
    },
    borderRadius: {
        small: '0.3rem',
        default: '0.5rem',
        large: '1rem',
        full: '999px'
    },
    fontSize: {
        s: `${14 / 16}rem`,
        m: `${16 / 16}rem`,
        l: `${18 / 16}rem`,
        xl: `${20 / 16}rem`,
        '2xl': `${28 / 16}rem`
    },
    shadow: {
        '1': `0px 0px 13px rgba(0,0,0, 0.1)`,
        '2': '',
        '3': '',
        '4': '',
        '5': ''
    }
};
export function getTheme(mode: ThemeName) {
    return mode === 'light' ? theme : darkTheme;
}

export const selectTheme: (selectedTheme: DefaultTheme) => StylesConfig = (selectedTheme) => {
    return {
        control: (baseStyles) => ({
            ...baseStyles,
            boxShadow: 'none',
            borderColor: selectedTheme.colors.primary.border,
            background: selectedTheme.colors.primary.background,
            ':hover': {
                borderColor: selectedTheme.pallete.grey['600'],
                background: selectedTheme.colors.primary.card
            }
        }),
        input: (baseStyles) => ({
            ...baseStyles,
            color: selectedTheme.colors.primary.font
        }),
        container: (baseStyles, state) => ({
            ...baseStyles,
            border: `1px solid ${
                (state.className ?? '').includes('has-error')
                    ? selectedTheme.pallete.red['700']
                    : 'transparent'
            }`,
            borderRadius: selectedTheme.borderRadius.default,
            backgroundColor: selectedTheme.colors.primary.background
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            background: state.isSelected
                ? selectedTheme.colors.primary.card
                : state.isFocused
                ? selectedTheme.colors.primary.backgroundContrast
                : selectedTheme.colors.primary.card,
            color: selectedTheme.colors.primary.font,
            cursor: 'pointer',
            ':hover': {
                background: selectedTheme.colors.primary.border
            }
        }),
        multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            color: selectedTheme.colors.primary.font
        }),
        multiValue: (baseStyles) => ({
            ...baseStyles,
            background: selectedTheme.colors.primary.card,
            color: selectedTheme.colors.primary.font
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            color: selectedTheme.colors.primary.font
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            background: selectedTheme.colors.primary.card
        }),
        loadingIndicator: (baseStyles) => ({
            ...baseStyles,
            color: selectedTheme.colors.primary.font
        })
    };
};
