export class Path {
    static join(a: string, b: string): string {
        return a + (a === '/' ? '' : '/') + b;
    }

    static dirname(path: string) {
        if (path === '/') {
            return '/';
        }
        const splitted = path.split('/');
        return splitted.length === 2 ? '/' : splitted.slice(0, splitted.length - 1).join('/');
    }

    static filename(path: string) {
        return path.split('/').slice(-1)[0] || '';
    }

    static extension(filename: string) {
        const lastIndexOf = filename.lastIndexOf('.');
        return lastIndexOf === -1 ? '' : filename.substring(lastIndexOf + 1);
    }

    static splitFilename(filename: string) {
        const lastIndexOf = filename.lastIndexOf('.');
        return lastIndexOf === -1
            ? { name: filename, ext: '' }
            : { name: filename.substring(0, lastIndexOf), ext: filename.substring(lastIndexOf) };
    }
}
