import { ScreenObtainerResult } from "./utils";
import { VideoConferenceSettings } from "./VideoConferenceSettings";
import { JitsiMeetJS } from "./jitsi/lib-jitsi-meet";

export interface Model {
    roomMetadata: RoomMetadata;
    videoConferenceSettings: Required<VideoConferenceSettings>;
}

export interface RoomMetadata {
    creatorHashmail: string;
    title: string;
    disableEncryption?: boolean;
    experimentalH264?: boolean;
    experimentalDominantSpeaker?: boolean;
}

export enum VideoConferenceState {
    DISCONNECTED = "disconnected",
    CONNECTING = "connecting",
    CONNECTED = "connected",
    DISCONNECTING = "disconnecting",
}

export interface VideoConferenceConfiguration {
    domain: string;
    appId: string | null;
    token: string | null;
    
    hashmail: string;
    
    conferenceId: string;
    conferencePassword: string;
    conferenceEncryptionKey?: string;
    conferenceEncryptionIV?: string;
}

export interface VideoConferenceOptions {
    title?: string;
    disableEncryption?: boolean;
    experimentalH264?: boolean;
    experimentalDominantSpeaker?: boolean;
}

export interface VideoConferenceConnectionOptions {
    configuration: VideoConferenceConfiguration;
    tmpUserName?: string;
    tmpUserPassword?: string;
    options: VideoConferenceOptions;
}

export type VideoConferenceConnectionLostReason = "connectingFailed" | "connectionLost" | "disconnected";

export enum VideoRoomAction {
    CREATE,
    JOIN,
}

export type JoinResult = JoinGetResult|JoinCreateResult;

export interface JoinGetResult {
    action: VideoRoomAction.JOIN;
    roomSecret: string;
    tsVideoRoomId: string;
}

export interface JoinCreateResult {
    action: VideoRoomAction.CREATE;
    domain: string;
    tmpUser: {
        username: string;
        password: string;
    };
    token: string;
}

export interface SwitchVideoRoomStateResult {
    tsVideoRoomId: string;
}

export interface ConferenceData {
    id: string | null;
    sectionId: string;
    users: string[];
}

export interface BlurVideoEffect {
    type: "blur";
    blur: number;
}

export interface ImageVideoEffect {
    type: "image";
    image: string;
}

export type VideoEffect = BlurVideoEffect | ImageVideoEffect;

export interface VideoConferenceTrack {
    containers: HTMLElement[];
    audioLevel: number;
    getType(): "audio" | "video";
    isMuted(): boolean;
    attach(container: HTMLElement): void;
    detach(container: HTMLElement): void;
    getId(): string;
    setAudioOutput(deviceId: string): void;
    isEnded(): boolean;
    setEffect(effect: any): void;
    isLocal(): boolean;
    getParticipantId(): string;
}

export interface VideoConferenceParticipant<T> {
    id: string;
    hashmail: string;
    e2ee: {
        supports: boolean;
        enabled: boolean;
        hasKey: boolean;
    };
    isTalking: boolean;
    _participant: T;
}

export interface VideoResolution {
    width: number;
    height: number;
}

export interface RequestedMediaDevices {
    videoInput?: boolean;
    audioInput?: boolean;
    audioOutput?: boolean;
};

export interface RequestedMediaDevicesResult {
    videoInput?: string;
    audioInput?: string;
    audioOutput?: string;
    rawResult?: {
        selected: boolean;
        videoInput?: string | false;
        audioInput?: string | false;
        audioOutput?: string | false;
    };
}
    
export interface RoomSecretData {
    domain: string;
    roomName: string;
    roomPassword: string;
    encryptionKey: string;
    encryptionIV: string;
    metadata: RoomMetadata;
}

export interface ConferenceInfo {
    jitsiDomain: string;
    id: string;
    password: string;
    encryptionKey: string;
    encryptionIV: string;
    tsVideoRoomId: string;
    roomMetadata: RoomMetadata;
}

export interface CreatorData {
    domain: string;
    roomName: string;
    tmpUser: {
        username: string;
        password: string;
    };
    token: string;
    conferencePassword: string;
}

export interface ConferenceCreatorResult {
    encryptionKey?: string;
    encryptionIV?: string;
    roomMetadata: RoomMetadata;
}

export interface ConnectionParameters {
    domain: string;
    tmpUserName?: string;
    tmpUserPass?: string;
    conferenceId: string;
    conferencePassword: string;
    encryptionKey?: string;
    encryptionIV?: string;
}

export type ConferenceCreator = (creatorData: CreatorData) => Promise<ConferenceCreatorResult>;

export interface VideoConferencesPollingResult {
    hostHash: string;
    conferencesData: ConferenceData[];
}

export interface Providers {
    escapeHtml(text: string): string;
    getGongMessage(clickedButton: HTMLButtonElement): Promise<string | false>;
    getMediaDevices(types: RequestedMediaDevices, showDeviceSelector: boolean): Promise<RequestedMediaDevicesResult>;
    getPersonName(hashmail: string): string;
    getScreenObtainerResult(): Promise<ScreenObtainerResult>;
    i18n(key: string, ...args: any[]): string;
    renderAvatars(): void;
    settingChangedCallback(settingName: keyof VideoConferenceSettings, settingValue: any): void;
    hideTalkingWhenMutedNotification(): void;
    showTalkingWhenMutedNotification(): void;
    
    onAvailableResolutionsChanged(resolutions: VideoResolution[]): void;
    onParticipantConnectionStatsUpdated(participantId: string, stats: JitsiMeetJS.ConferenceStats | null): void;
    onUnsupportedBrowser(): void;
}

export enum I18N_KEYS {
    e2eeEnabled_title = "e2eeEnabled.title",
    e2eeDisabled_title = "e2eeDisabled.title",
    button_disableLocalVideoInput_title = "button.disableLocalVideoInput.title",
    button_enableLocalVideoInput_title = "button.enableLocalVideoInput.title",
    button_disableLocalAudioInput_title = "button.disableLocalAudioInput.title",
    button_enableLocalAudioInput_title = "button.enableLocalAudioInput.title",
    button_disableLocalAudioOutput_title = "button.disableLocalAudioOutput.title",
    button_enableLocalAudioOutput_title = "button.enableLocalAudioOutput.title",
    button_toggleExtraSettings_title = "button.toggleExtraSettings.title",
    button_disconnect_title = "button.disconnect.title",
    button_gong_title = "button.gong.title",
    button_disableDesktopSharing_title = "button.disableDesktopSharing.title",
    button_enableDesktopSharing_title = "button.enableDesktopSharing.title",
    button_switchModeToTiles_title = "button.switchModeToTiles.title",
    button_switchModeToSingleSpeaker_title = "button.switchModeToSingleSpeaker.title",
    button_showLocalParticipant_title = "button.showLocalParticipant.title",
    button_hideLocalParticipant_title = "button.hideLocalParticipant.title",
    audioOutput_label = "audioOutput.label",
    audioInput_label = "audioInput.label",
    videoInput_label = "videoInput.label",
    button_ok_text = "button.ok.text",
    button_cancel_text = "button.cancel.text",
    button_join_text = "button.join.text",
    resolutionsGroup_horizontal = "resolutionsGroup.horizontal",
    resolutionsGroup_vertical = "resolutionsGroup.vertical",
    deviceSelect_disabled = "deviceSelect.disabled",
    error_createLocalAudioTrackFailed = "error.createLocalAudioTrackFailed",
    error_createLocalVideoTrackFailed = "error.createLocalVideoTrackFailed",
    error_createLocalDesktopTrackFailed = "error.createLocalDesktopTrackFailed",
}
