import React, {
    useContext,
    useEffect,
    useRef,
    createContext,
    useState,
    useCallback,
    useMemo
} from 'react';
import Icon, { iconResizeHorizontal } from '../../components/Icon';
import { Box, createStyles } from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';

export interface ResizerProps {
    onResize: (leftPanelWidthPercent: number) => void;
    initialLeftPanelWidthPercent: number;
    leftPanelSize: number;
    minimumWidth?: number;
    maximumWidth?: number;
}

const useResizerStyles = createStyles({
    root: {
        cursor: 'ew-resize',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0.3,
        zIndex: 20,
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: 10
    }
});

export function ResizerHandle(props: ResizerProps) {
    const onResize = props.onResize;
    const resizerElementRef = useRef<HTMLDivElement>(null);
    const {
        classes: { root }
    } = useResizerStyles();
    useEffect(() => {
        if (!resizerElementRef.current) {
            return;
        }
        const resizerElement = resizerElementRef.current;

        let dragStartPageX = 0;
        let totalWidthPx = 0;
        let initialLeftPercent = 0;
        const mouseDownHandler = (event: MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            window.addEventListener('mouseup', mouseUpHandler);
            window.addEventListener('mousemove', mouseMoveHandler);
            dragStartPageX = event.pageX;
            totalWidthPx = resizerElement.parentElement?.scrollWidth ?? 1000;
            initialLeftPercent = resizerElement
                ? (parseFloat(window.getComputedStyle(resizerElement).left) / totalWidthPx) * 100
                : 40;
        };
        const mouseUpHandler = () => {
            window.removeEventListener('mouseup', mouseUpHandler);
            window.removeEventListener('mousemove', mouseMoveHandler);
        };
        const mouseMoveHandler = (event: MouseEvent) => {
            const deltaX = event.pageX - dragStartPageX;
            const deltaPercent = (deltaX / totalWidthPx) * 100;
            const leftPercent = Math.max(
                props.minimumWidth ?? 25,
                Math.min(props.maximumWidth ?? 80, initialLeftPercent + deltaPercent)
            );
            onResize(leftPercent);
        };

        resizerElement.addEventListener('mousedown', mouseDownHandler);
        return () => {
            if (resizerElement) {
                resizerElement.removeEventListener('mousedown', mouseDownHandler);
            }
            window.removeEventListener('mouseup', mouseUpHandler);
            window.removeEventListener('mousemove', mouseMoveHandler);
        };
    }, [onResize, props.minimumWidth, props.maximumWidth]);

    return (
        <Box className={root} ref={resizerElementRef} style={{ left: props.leftPanelSize }}>
            <Icon icon={iconResizeHorizontal} />
        </Box>
    );
}

type ResizerContextType = {
    width: number;
    ref: React.MutableRefObject<HTMLDivElement> | null;
};

const ResizerContext = createContext<ResizerContextType>({ width: 0, ref: null });

const Resizer = ({ children }: { children: React.ReactElement[] }) => {
    if (React.Children.count(children) > 2) throw Error('Resizer can have only two children');

    const [leftPanelWidthPercent, setLeftPanelWidthPercent] = useState(35);
    const [ref, rect] = useResizeObserver<HTMLDivElement>();

    const _panels = React.Children.toArray(children);
    const handleResizerResize = useCallback((widthPercent: number) => {
        setLeftPanelWidthPercent(widthPercent);
    }, []);

    const contextValue: ResizerContextType = useMemo(() => {
        return { width: leftPanelWidthPercent, ref };
    }, [leftPanelWidthPercent, ref]);

    if (!_panels) return <></>;
    const LeftPanel = _panels[0];
    const RightPanel = _panels[1];

    return (
        <ResizerContext.Provider value={contextValue}>
            {LeftPanel}
            <ResizerHandle
                leftPanelSize={rect.right}
                minimumWidth={10}
                maximumWidth={60}
                onResize={handleResizerResize}
                initialLeftPanelWidthPercent={leftPanelWidthPercent}
            />
            {RightPanel}
        </ResizerContext.Provider>
    );
};

const useResizerPanelStyle = createStyles((_) => ({
    leftPanel: {
        minWidth: 'fit-content'
    },
    rightPanel: {
        flexGrow: 1
    }
}));

const LeftPanel = ({ children }: { children: React.ReactNode }) => {
    const { ref, width } = useContext(ResizerContext);
    const { classes } = useResizerPanelStyle();
    return (
        <div ref={ref} className={classes.leftPanel} style={{ width: `${width}%` }}>
            {children}
        </div>
    );
};

const RightPanel = ({ children }: { children: React.ReactNode }) => {
    const { width } = useContext(ResizerContext);
    const { classes } = useResizerPanelStyle();
    return (
        <div className={classes.rightPanel} style={{ width: `${100 - width}%` }}>
            {children}
        </div>
    );
};

Resizer.Left = LeftPanel;
Resizer.Right = RightPanel;
Resizer.Handle = ResizerHandle;

export default Resizer;
