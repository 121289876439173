import * as PmxApi from 'privmx-server-api';

export class KvdbStateService {
    private kvdbs: PmxApi.api.core.ObjectMap<PmxApi.api.kvdb.KvdbId, PmxApi.api.kvdb.Seq>;

    constructor() {
        this.kvdbs = {};
    }

    setState(kvdbs: PmxApi.api.kvdb.KvdbModState[]) {
        this.kvdbs = {};
        for (const kvdb of kvdbs) {
            this.kvdbs[kvdb.id] = kvdb.seq;
        }
    }

    getState(kvdbId: PmxApi.api.kvdb.KvdbId): PmxApi.api.kvdb.Seq {
        return this.kvdbs[kvdbId];
    }

    exists(kvdbId: PmxApi.api.kvdb.KvdbId): boolean {
        return this.kvdbs[kvdbId] != null;
    }
}
