import { useCallback } from 'react';
import StateView from '../../atoms/State';
import FormAnswering from '../../components/FormCreator/components/FormAnswering';
import useLoader from '../../hooks/useLoader';
import { Flex, Paper } from '@mantine/core';

export default function ContactFormScreen() {
    const { state, revalidate } = useLoader(useCallback((x) => x.loadContactForm(), []));
    return (
        <StateView state={state} retry={revalidate}>
            {(data) => (
                <Paper h="100%" sx={{ textAlign: 'center' }}>
                    <Flex direction={'column'} align={'center'} justify={'center'} h="100%">
                        <Flex direction={'column'} gap={'md'}>
                            <FormAnswering model={data.form} />
                        </Flex>
                    </Flex>
                </Paper>
            )}
        </StateView>
    );
}
