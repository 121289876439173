import { useAppSelector } from '../../store';
import { selectDocumentsToAccept } from '../../store/AcceptedDocumentsSlice';

export interface NoUnacceptedDocumentsGuardProps extends React.PropsWithChildren {}

export function NoUnacceptedDocumentsGuard(props: NoUnacceptedDocumentsGuardProps) {
    const documentsToAcceptRaw = useAppSelector(selectDocumentsToAccept);
    const hasDocumentsToAccept = documentsToAcceptRaw !== null && documentsToAcceptRaw.length > 0;
    if (hasDocumentsToAccept) {
        return <></>;
    }
    return <>{props.children}</>;
}
