import { Navigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';

export function OnlyStaff(props: { children: JSX.Element }) {
    const currentUser = useAppSelector(selectCurrentUser);

    if (currentUser.role !== 'staff') {
        return <Navigate to="/" />;
    }

    return props.children;
}

export function OnlyLogged(props: { children: JSX.Element }) {
    const currentUser = useAppSelector(selectCurrentUser);

    if (currentUser.role === null) {
        return <Navigate to="/" />;
    }

    return props.children;
}

export function OnlyGuest(props: { navigateTo?: string; children: JSX.Element }) {
    const currentUser = useAppSelector(selectCurrentUser);

    if (currentUser.role !== null) {
        return <Navigate to={props.navigateTo || '/home'} />;
    }

    return props.children;
}

export function OnlyValidMeetingUser(props: { children: JSX.Element }) {
    const currentUser = useAppSelector(selectCurrentUser);
    const routeParams = useParams();

    if (currentUser.role === null && currentUser.pub === null) {
        const meetingDataStr = routeParams.meetingData;
        if (meetingDataStr) {
            return <Navigate to={`/joinMeeting/${meetingDataStr}`} />;
        } else {
            return <Navigate to="/" />;
        }
    }

    return props.children;
}
