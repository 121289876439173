import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    icon: {
        display: 'inline-block',
        fontWeight: 900,
        fontSize: '1.2em',
        lineHeight: 1,
        background: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 7 : 5],
        color: '#fff',
        padding: '3px 7px',
        borderRadius: '50px'
    }
}));

export function HelpIcon() {
    const { classes } = useStyles();
    return <span className={classes.icon}>?</span>;
}
