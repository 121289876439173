import {
    Container,
    ScrollArea,
    Title,
    Text,
    CopyButton,
    Button,
    Box,
    rem,
    Group,
    Input,
    Paper,
    useMantineColorScheme,
    createStyles
} from '@mantine/core';
import { t } from 'i18next';
import React, { useCallback, useLayoutEffect, useRef } from 'react';
import {
    isNewFormId,
    useFormCreatorContext
} from '../FormCreator/components/FormState/useFormState';
import { useParams } from 'react-router-dom';
import { UrlBuilder } from '../../../utils/UrlBuilder';
import { IconCopy } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { Controller, useForm } from 'react-hook-form';
import { api } from '../../../api/Api';
import { FormId } from '../../../types/Types';
import { useUserInteraction } from '../../../hooks/useUserInteraction';
import { Validator } from '../../../utils/Validator';
const QRious = require('qrious');

interface ChangeLinkForm {
    link: string;
}

function separateSlug(link: string) {
    const slugStart = link.lastIndexOf('/');
    const domain = link.slice(0, slugStart);
    const slug = link.slice(slugStart + 1);

    return [domain, slug];
}

function getSlugFromPath(currentShortLink: string) {
    const hash = separateSlug(api.getLinkShortenerConfig().customUrlPrefix)[1];
    const currentSlug = separateSlug(currentShortLink)[1];

    const splitedSlug = currentSlug.split('-');

    if (`${splitedSlug[0]}-` === hash) {
        splitedSlug.shift();
        return splitedSlug.join('-');
    } else {
        return '';
    }
}

const useFormShareStyles = createStyles((theme) => {
    return {
        container: {
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1],
            alignItems: 'center',
            '&:focus-within': {
                borderColor:
                    theme.colorScheme === 'dark'
                        ? theme.colors['brand'][6]
                        : theme.colors['brand'][8]
            },
            display: 'flex',
            width: '100%'
        },
        flexGrow: {
            flexGrow: 1
        },
        disabledText: {}
    };
});

export function FormShare() {
    const { formId } = useParams();
    const { formModel, revalidate } = useFormCreatorContext();

    const qr = useRef<HTMLDivElement>(null);

    const url = UrlBuilder.buildUrl(`/form-answer/${formModel.id}`);

    useLayoutEffect(() => {
        const canvas = new QRious({
            level: 'M',
            size: 200,
            value: url
        });

        if (qr.current) {
            qr.current.innerHTML = '';
            qr.current.append(canvas.canvas);
        }
    }, [url]);

    const { control, handleSubmit, reset, setError } = useForm<ChangeLinkForm>({
        defaultValues: { link: formModel.shortLink ? getSlugFromPath(formModel.shortLink) : '' }
    });

    const [isChangingLink, { open: startChangingLink, close: stopChangingLink }] =
        useDisclosure(false);
    const linkInput = useRef<HTMLInputElement>(null);

    const { startInteraction, isProcessing } = useUserInteraction({
        successTitle: t('screen.forms.formShare.successTitle'),
        onError(error) {
            if ('msg' in error && (error as any).msg === 'Given slug already in use') {
                setError('link', {
                    type: 'value',
                    message: t('screen.forms.formShare.linkAlreadyInUse')
                });
            }
        },
        async action(data: ChangeLinkForm) {
            const newModel = await api.generateShortLinkForForm(formId as FormId, data.link);
            reset({ link: separateSlug(newModel.shortLink || '')[1] });
            linkInput.current?.blur();
            stopChangingLink();
            revalidate();
        }
    });

    const handleCustomizeLink = useCallback(
        async (data: ChangeLinkForm) => {
            const result = Validator.getErrors(data.link, ['slug'], true);
            let hasError = false;
            if (result !== false) {
                if (result.includes('size')) {
                    setError('link', {
                        type: 'value',
                        message: t('screen.forms.formShare.maxInputErrorLength', {
                            minLength: Validator.constraints['slug'].minLength,
                            maxLength: Validator.constraints['slug'].maxLength
                        })
                    });
                    hasError = true;
                }
                if (result.includes('slug')) {
                    setError('link', {
                        type: 'value',
                        message: t('screen.forms.formShare.invalidValueError')
                    });
                    hasError = true;
                }
            }
            if (!hasError) {
                startInteraction(data);
            }
        },
        [startInteraction, setError]
    );

    const colorScheme = useMantineColorScheme();

    const { classes } = useFormShareStyles();

    if (isNewFormId(formId)) {
        return (
            <Container>
                <Text mb="md">{t('form.creatorInstructions.willBeAvailAfterSaving')}</Text>
            </Container>
        );
    }

    return (
        <ScrollArea>
            <Container>
                <Box mb="xl">
                    <Title order={3} fw="normal">
                        {t('screen.formShare.labels.usingLink')}
                    </Title>
                    <Text mb="md">{t('screen.formShare.descriptions.usingLink')}</Text>
                    <Text>Short link</Text>

                    <Group mb="lg" w="100%">
                        {isChangingLink ? (
                            <Box
                                component="form"
                                sx={{ flexGrow: 1 }}
                                onSubmit={handleSubmit(handleCustomizeLink)}>
                                <Controller
                                    name="link"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <Paper className={classes.container} withBorder>
                                                <Text
                                                    pl={'xs'}
                                                    opacity={
                                                        colorScheme.colorScheme === 'dark'
                                                            ? 0.8
                                                            : 0.5
                                                    }
                                                    pr={8}>
                                                    {api.getLinkShortenerConfig().customUrlPrefix}
                                                </Text>

                                                <Input
                                                    error={
                                                        fieldState.error?.message ??
                                                        fieldState.error?.type
                                                    }
                                                    disabled={!isChangingLink}
                                                    ref={linkInput}
                                                    onChange={(e: any) =>
                                                        field.onChange(e.target.value)
                                                    }
                                                    styles={{
                                                        wrapper: { flexGrow: 1 },
                                                        input: { border: 0 }
                                                    }}
                                                    value={field.value}
                                                />

                                                <Button
                                                    onClick={() => {
                                                        stopChangingLink();
                                                        reset();
                                                    }}
                                                    type="button"
                                                    h={34}
                                                    variant="subtle">
                                                    Cancel
                                                </Button>
                                                {isChangingLink && (
                                                    <Button
                                                        loading={isProcessing}
                                                        h={34}
                                                        mr={1}
                                                        type="submit">
                                                        Save
                                                    </Button>
                                                )}
                                            </Paper>
                                            {(fieldState.error?.message ??
                                                fieldState.error?.type) && (
                                                <Input.Error>
                                                    {fieldState.error?.message ??
                                                        fieldState.error?.type}
                                                </Input.Error>
                                            )}
                                        </>
                                    )}
                                />
                            </Box>
                        ) : (
                            <Box
                                component="form"
                                sx={{ flexGrow: 1 }}
                                onSubmit={handleSubmit(handleCustomizeLink)}>
                                <Input.Wrapper w="100%">
                                    <Paper w="100%" display={'flex'} withBorder>
                                        <Input
                                            disabled={!isChangingLink}
                                            ref={linkInput}
                                            onChange={() => {}}
                                            styles={{
                                                wrapper: { flexGrow: 1 },
                                                input: { border: 0, paddingLeft: 8 }
                                            }}
                                            value={formModel.shortLink ?? url}
                                        />

                                        <Button
                                            onClick={() => {
                                                startChangingLink();
                                                setTimeout(() => {
                                                    linkInput.current?.focus();
                                                }, 200);
                                            }}
                                            type="button"
                                            h={34}
                                            mr={1}
                                            bg={
                                                colorScheme.colorScheme === 'dark'
                                                    ? 'dark.4'
                                                    : 'gray.2'
                                            }
                                            variant="light">
                                            Customize
                                        </Button>
                                    </Paper>
                                </Input.Wrapper>
                            </Box>
                        )}

                        {!isChangingLink && (
                            <CopyButton value={formModel.shortLink ?? url}>
                                {({ copied, copy }) => {
                                    return (
                                        <>
                                            <Button
                                                leftIcon={<IconCopy size={rem(16)} />}
                                                onClick={copy}
                                                color={copied ? 'green' : undefined}>
                                                {copied
                                                    ? t('screen.formShare.actions.copied')
                                                    : t('screen.formShare.actions.usingLink')}
                                            </Button>
                                        </>
                                    );
                                }}
                            </CopyButton>
                        )}
                    </Group>
                    <Text>Scan QR code</Text>
                    <Box ref={qr} />
                </Box>
            </Container>
        </ScrollArea>
    );
}
