import { BaseModalData } from '../../types/Modals';
import Modal from '../../atoms/Modal';
import VideoConference, { VideoConferenceData } from '.';
import { useTranslation } from 'react-i18next';

export interface VideoConferenceModalData extends BaseModalData {
    videoConference: VideoConferenceData;
}

export function VideoConferenceModal(props: {
    onClose: () => void;
    data: VideoConferenceModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const meetingTitle = props.data.videoConference.meetingTitle;

    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            height={props.data.height ?? 500}
            width={props.data.width ?? 300}
            title={
                props.data.title ??
                (meetingTitle
                    ? t('modal.videoConference.titleWithName', { name: meetingTitle })
                    : t('modal.videoConference.titleWithoutName'))
            }>
            <VideoConference videoConference={props.data.videoConference} />
        </Modal>
    );
}
