import { Text, Loader, Overlay, Flex, useMantineTheme } from '@mantine/core';
import React from 'react';

export function LoadingMessageOverlay({
    visible,
    message = 'Loading'
}: {
    visible: boolean;
    message?: string;
}) {
    const theme = useMantineTheme();
    if (!visible) return <></>;

    return (
        <Overlay
            opacity={0.75}
            color={theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white}
            blur={2}
            center>
            <Flex align={'center'} direction={'column'} gap="sm">
                <Text align="center" size="lg">
                    {message}
                </Text>
                <Loader mx="auto" />
            </Flex>
        </Overlay>
    );
}
