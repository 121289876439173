import { Mimetype } from '../../types/Types';
import { FC } from 'react';

export interface BaseFilterOption {
    label: string;
    type: 'author' | 'date' | (string & {});
}

export interface ExtendedFilterOption<T extends string | void = void>
    extends Omit<BaseFilterOption, 'type'> {
    type: T extends void ? BaseFilterOption['type'] : BaseFilterOption['type'] | T;
}

export type DropDownElementsMap<Key extends string = string> = Record<
    Key,
    FC<{ onSelect: (value: string) => void; onGoTo: (value: string) => void; [key: string]: any }>
>;
export interface FilterSelectProps<T extends BaseFilterOption> {
    filterOptions?: T[];
    dropdownElementMap: DropDownElementsMap;
}

export const fileCategory = [
    'document',
    'presentation',
    'spreadsheet',
    'image',
    'video',
    'audio',
    'other',
    'any'
] as const;

export interface MimetypeCategory {
    extension: string;
    mimetype: Mimetype | string;
    category: (typeof fileCategory)[number];
}

export type FilterDropdownComponent<T extends Record<string, any> = {}> = (
    props: T & Parameters<DropDownElementsMap[string]>[0]
) => JSX.Element;
