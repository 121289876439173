import * as PmxApi from 'privmx-server-api';

export class CustomizationApi implements PmxApi.api.customization.ICustomizationApi {
    constructor(private gateway: { request<T>(method: string, params: unknown): Promise<T> }) {}

    private async request<T>(method: string, params: unknown): Promise<T> {
        return await this.gateway.request(method, params);
    }

    getCustomization(): Promise<PmxApi.api.customization.CustomizationData> {
        return this.request('getCustomization', {});
    }

    getCustomization2(): Promise<PmxApi.api.customization.CustomizationData2> {
        return this.request('getCustomization2', {});
    }

    saveCustomization(model: {
        data: PmxApi.api.customization.CustomizationData;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('saveCustomization', model);
    }
}
