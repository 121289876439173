import { Stack } from '@mantine/core';
import * as FormsTypes from '../FormsTypes';
import FormGroupElements from './FormGroupElements';
import parse from 'html-react-parser';

export default function FormGroup(props: FormsTypes.RenderFormGroupOptions) {
    const inputType = props.question.answers[0].inputType;

    if (inputType === 'block') {
        if (typeof props.question.answers[0].value === 'string') {
            return <>{parse(props.question.answers[0].value)}</>;
        }
    }

    if (inputType === 'select' || inputType === 'single') {
        return (
            <Stack>
                <FormGroupElements options={props} />
            </Stack>
        );
    }
    if (
        inputType === 'short' ||
        inputType === 'long' ||
        inputType === 'file' ||
        inputType === 'phone' ||
        inputType === 'email'
    ) {
        return <FormGroupElements options={props} />;
    }
    return <></>;
}
