import { File } from '../../types/Types';
import { Stack } from '@mantine/core';
import React, { Fragment } from 'react';
import { useSortFiles } from '../../hooks/useSort';

interface Props {
    attachments: File[];
    element: (arg: File, i?: number) => React.ReactNode;
}

export default function FileList({ attachments, element }: Props) {
    const sortedFiles = useSortFiles(attachments);
    return (
        <Stack spacing={0}>
            {sortedFiles.map((item, i) => (
                <Fragment key={item.id}>{element(item, i)}</Fragment>
            ))}
        </Stack>
    );
}
