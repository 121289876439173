import React, { MouseEventHandler } from 'react';
import {
    ActionIcon,
    ActionIconProps,
    ThemeIcon,
    ThemeIconProps,
    Tooltip,
    TooltipProps
} from '@mantine/core';

interface IconProps {
    info: string;
    children: React.ReactNode;
    tooltipProps?: Partial<TooltipProps>;
    onClick?: MouseEventHandler;
}

type Props = IconProps &
    (
        | ({ type: 'default' } & ThemeIconProps)
        | ({ type: 'action' } & ActionIconProps)
        | { type?: 'none' }
    );

export default function TooltipIcon({
    info,
    type = 'default',
    children,
    tooltipProps,
    onClick,
    ...rest
}: Props) {
    return (
        <Tooltip label={info} openDelay={350} {...tooltipProps}>
            <div onClick={onClick}>
                {type === 'action' && (
                    <ActionIcon
                        {...(rest as ActionIconProps)}
                        variant={(rest as ActionIconProps).variant}>
                        {children}
                    </ActionIcon>
                )}
                {type === 'default' && 'variant' in rest && (
                    <ThemeIcon {...rest} variant={rest.variant}>
                        {children}
                    </ThemeIcon>
                )}
                {!(type === 'action') && !(type === 'default' && 'variant' in rest) && children}
            </div>
        </Tooltip>
    );
}
