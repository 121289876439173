import {
    Flex,
    Checkbox,
    Group,
    rem,
    Box,
    Skeleton,
    Tabs,
    TextInput,
    Menu,
    ScrollArea,
    Stack,
    Switch,
    Button,
    MediaQuery
} from '@mantine/core';
import {
    IconStar,
    IconStarFilled,
    IconArchive,
    IconMailOpened,
    IconArchiveOff,
    IconSearch
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import {
    DropDownElementsMap,
    ExtendedFilterOption,
    FilterDropdownComponent,
    FilterSelect,
    useFilterContext,
    documentTypes,
    baseFilterOptions
} from './FilterSelect';
import { Tag, Username } from '../types/Types';
import { ReactNode, useEffect, useState } from 'react';
import { useInputState, useMediaQuery } from '@mantine/hooks';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../store';
import { toggleSidebar } from '../store/UserPreferenceSlice';

export type ThreadMutationType = 'archive' | 'unarchive' | 'unread' | 'favourite' | 'unfavourite';
export type BatchMutations = Record<ThreadMutationType, () => void>;

export const THREADS_FILTERS = ['all', 'unread'] as const;
export type ThreadsFiltersType = (typeof THREADS_FILTERS)[number];

export function ThreadsListActionsBarLoader({
    withIcons,
    buttons
}: {
    buttons?: React.ReactNode;
    withIcons?: boolean;
}) {
    const wrapNavbar = useMediaQuery('(min-width: 62rem');

    return (
        <Box p={'md'} pb={4}>
            <Stack spacing={4}>
                <Group position={'apart'} mb={4}>
                    <MediaQuery smallerThan="sm" styles={{ display: 'block' }}>
                        <Skeleton display={'none'} width={78} height={36}></Skeleton>
                    </MediaQuery>
                    <Skeleton
                        height={30}
                        sx={{
                            width: 'auto',
                            flexGrow: 1,
                            '@media (min-width: 62rem)': {
                                flexGrow: 0,
                                minWidth: 400
                            }
                        }}
                    />
                    <MediaQuery smallerThan={'md'} styles={{ width: '100%' }}>
                        <Group ml="auto" position={wrapNavbar ? 'right' : 'apart'}>
                            <Switch size="xs" labelPosition="left" label="Unread Only" />
                            {buttons}
                        </Group>
                    </MediaQuery>
                </Group>
            </Stack>
        </Box>
    );
}

type ThreadListFilterOptions = ExtendedFilterOption<'file' | 'tag' | 'company'>;

export function ThreadsListActionsBar({
    onCheckboxChange,
    batchMutations,
    setFilterState,
    threadsCount,
    buttons,
    authors,
    tags,
    search,
    type
}: {
    search?: (filterSelect: ReactNode) => ReactNode | ReactNode;
    threadsCount: number;
    onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    batchMutations: BatchMutations;
    setFilterState: (item: ThreadsFiltersType) => void;
    buttons?: React.ReactNode;
    authors: Username[];
    tags: Tag[];
    type: 'Meeting' | 'Chat';
}) {
    const { t } = useTranslation();

    const [currentTab, setCurrentTab] = useState('');

    const { contextState, hasFilters, dispatch } = useFilterContext();

    useEffect(() => {
        if (type === 'Meeting') {
            setCurrentTab('filter');
            return;
        }
        setCurrentTab((prev) => (hasFilters ? 'filter' : prev));
    }, [threadsCount, hasFilters, type]);

    useEffect(() => {
        if (type === 'Meeting') {
            setCurrentTab('filter');
            return;
        }
        setCurrentTab((prevTab) => {
            return threadsCount !== 0 ? 'icons' : prevTab;
        });
    }, [threadsCount, type]);

    useEffect(() => {
        if (threadsCount === 0 && !hasFilters) {
            setCurrentTab('');
        }
    }, [hasFilters, threadsCount]);

    const filterMap: DropDownElementsMap<ThreadListFilterOptions['type']> = {
        author: (props) => <FilterSelect.AuthorDropdown authors={authors} {...props} />,
        date: FilterSelect.DateDropdown,
        file: FileDropdown,
        tag: (props) => <FilterSelect.TagDropdown tags={tags} {...props} />,
        company: FilterSelect.CompanyDropdown
    };

    const filterOptions: ThreadListFilterOptions[] =
        type === 'Chat'
            ? [
                  ...baseFilterOptions,
                  { label: 'Tag', type: 'tag' },
                  { label: 'File', type: 'file' },
                  { label: 'Company', type: 'company' }
              ]
            : [...baseFilterOptions, { label: 'Tag', type: 'tag' }];

    const [params] = useSearchParams();
    useEffect(() => {
        const paramsArray = Array.from(params.entries());
        if (paramsArray && paramsArray.length) {
            dispatch({ type: 'syncParams', filters: paramsArray });
        }
    }, [dispatch, params]);

    const { state } = useLocation();

    useEffect(() => {
        if (state?.originState && Array.isArray(state?.originState)) {
            dispatch({ type: 'set', state: state?.originState });
        }
    }, [dispatch, state, state?.originState]);

    const activeTopicFilter = params.get('filter') || 'none';

    const wrapNavbar = useMediaQuery('(min-width: 62rem');

    const reduxDispatch = useAppDispatch();

    function showSidebar() {
        reduxDispatch(toggleSidebar({}));
    }

    return (
        <Box p="md" pb="0">
            <Stack spacing={4}>
                <Group mb={4}>
                    <MediaQuery smallerThan="sm" styles={{ display: 'block' }}>
                        <Button onClick={showSidebar} display={'none'}>
                            Menu
                        </Button>
                    </MediaQuery>
                    {typeof search === 'function'
                        ? search(
                              <FilterSelect
                                  filterOptions={filterOptions}
                                  size={'xs'}
                                  dropdownElementMap={filterMap}
                              />
                          )
                        : search}

                    <MediaQuery smallerThan={'md'} styles={{ width: '100%' }}>
                        <Group ml="auto" position={wrapNavbar ? 'right' : 'apart'}>
                            <Switch
                                onChange={(e) =>
                                    e.currentTarget.checked
                                        ? setFilterState('unread')
                                        : setFilterState('all')
                                }
                                size="xs"
                                labelPosition="left"
                                label="Unread Only"
                            />
                            {buttons}
                        </Group>
                    </MediaQuery>
                </Group>
                <Tabs value={currentTab}>
                    <Tabs.Panel value={'icons'}>
                        <Flex
                            sx={{ overflow: 'visible', position: 'relative', zIndex: 1 }}
                            align={'center'}>
                            <Checkbox onChange={onCheckboxChange} mr="md" />
                            <Group
                                display="inline-flex"
                                spacing={'md'}
                                pos="relative"
                                sx={{ zIndex: 900 }}>
                                {activeTopicFilter !== 'archived' && (
                                    <Button
                                        variant="light"
                                        size="xs"
                                        leftIcon={<IconMailOpened size={14} />}
                                        onClick={batchMutations.unread}>
                                        {t('action.markAsRead')}
                                    </Button>
                                )}
                                {activeTopicFilter !== 'archived' && (
                                    <Button
                                        variant="light"
                                        size="xs"
                                        leftIcon={<IconArchive size={14} />}
                                        onClick={batchMutations.archive}>
                                        {t('action.archive')}
                                    </Button>
                                )}
                                {activeTopicFilter === 'archived' && (
                                    <Button
                                        variant="light"
                                        size="xs"
                                        leftIcon={<IconArchiveOff size={14} />}
                                        onClick={batchMutations.unarchive}>
                                        {t('action.unarchive')}
                                    </Button>
                                )}
                                <Flex align={'center'} gap={4}>
                                    {activeTopicFilter !== 'favorite' && (
                                        <Button
                                            variant="light"
                                            size="xs"
                                            leftIcon={<IconStarFilled size={14} />}
                                            onClick={batchMutations.favourite}>
                                            {t('action.addToFavorites')}
                                        </Button>
                                    )}
                                    <Button
                                        variant="light"
                                        size="xs"
                                        leftIcon={<IconStar size={14} />}
                                        onClick={batchMutations.unfavourite}>
                                        {t('action.removeFromFavourites')}
                                    </Button>
                                </Flex>
                            </Group>
                        </Flex>
                    </Tabs.Panel>
                    <Tabs.Panel value={'filter'}>
                        <Group align={'center'} mb="md">
                            {type === 'Chat' && <Checkbox onChange={onCheckboxChange} />}
                            <Group spacing={'sm'}>
                                {contextState.map((x) => (
                                    <FilterSelect.Tag key={x} filter={x} />
                                ))}
                            </Group>
                        </Group>
                    </Tabs.Panel>
                </Tabs>
            </Stack>
        </Box>
    );
}

const FileDropdown: FilterDropdownComponent = (props) => {
    const [queryName, setQueryName] = useInputState('');
    const { t } = useTranslation();
    return (
        <Menu.Dropdown>
            <TextInput
                size={'xs'}
                placeholder={t('screen.topics.placeholders.actionsBar.fileDropdown')}
                icon={<IconSearch size={rem(12)} />}
                value={queryName}
                onChange={setQueryName}
                styles={{ input: { border: 0 } }}
            />
            <Menu.Divider mb="sm" />
            <ScrollArea.Autosize h={140}>
                {queryName ? (
                    <Menu.Item onClick={() => props.onSelect(`file:${queryName}`)}>
                        Filter {queryName}
                    </Menu.Item>
                ) : null}
                {documentTypes.map((x) => (
                    <Menu.Item
                        key={x.type}
                        onClick={() => {
                            if (queryName) props.onSelect(`file:${queryName}`);
                            props.onSelect(`file type:${x.type}`);
                        }}
                        icon={<x.icon size={rem(12)} />}>
                        {x.type}
                    </Menu.Item>
                ))}
            </ScrollArea.Autosize>
        </Menu.Dropdown>
    );
};
