import Modal from '../../atoms/Modal';
import StateView from '../../atoms/State';
import { BaseModalData } from '../../types/Modals';
import { MeetingId } from '../../types/Types';
import { MeetingLobbyManagementPageView } from './index';
import { useCallback } from 'react';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { useTranslation } from 'react-i18next';

export interface MeetingLobbyModalData extends BaseModalData {
    meetingId: MeetingId;
}

export function MeetingLobbyManagementModal(props: {
    onClose: () => void;
    data: MeetingLobbyModalData;

    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useDataSubscribion(
        useCallback(
            (x) => x.subscribeForThreadMeetingWithLobby(props.data.meetingId),
            [props.data.meetingId]
        )
    );
    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            height={props.data.height ?? 800}
            width={props.data.width}
            title={
                props.data.title ??
                (state.type === 'done' && state.data.meeting && state.data.meeting.title
                    ? t('modal.meetingLobbyManagement.titleWithName', {
                          name: state.data.meeting.title
                      })
                    : t('modal.meetingLobbyManagement.titleWithoutName'))
            }>
            <StateView state={state} retry={revalidate}>
                {(data) => (
                    <MeetingLobbyManagementPageView meeting={data.meeting} lobby={data.lobby} />
                )}
            </StateView>
        </Modal>
    );
}
