import * as types from '../../types/Types';
import { Box, Button, Group, List, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export default function DuplicatedFilesAction({
    fileNames,
    closeFn
}: {
    fileNames: types.FileName[];
    closeFn: (action?: types.DuplicatedFilesAction) => void;
}) {
    const { t } = useTranslation();
    return (
        <Box p="md">
            <Stack>
                {fileNames.length === 1 && (
                    <Text>
                        {t('modal.duplicatedFilesAction.fileAlreadyExistsWhatToDo.beforeFilename')}{' '}
                        <Text italic display="inline">
                            {fileNames.map((x) => `"${x}"`).join(', ')}
                        </Text>{' '}
                        {t('modal.duplicatedFilesAction.fileAlreadyExistsWhatToDo.afterFilename')}:
                    </Text>
                )}
                {fileNames.length > 1 && (
                    <Text>
                        {t('modal.duplicatedFilesAction.filesAlreadyExistWhatToDo.beforeFilename')}{' '}
                        <Text italic display="inline">
                            {fileNames.map((x) => `"${x}"`).join(', ')}
                        </Text>{' '}
                        {t('modal.duplicatedFilesAction.filesAlreadyExistWhatToDo.afterFilename')}:
                    </Text>
                )}
                <List mb="xl">
                    <List.Item>
                        {t('modal.duplicatedFilesAction.action.uploadNewVersion')}
                    </List.Item>
                    <List.Item>{t('modal.duplicatedFilesAction.action.uploadNewFile')}</List.Item>
                </List>
                <Group position="center">
                    <Button onClick={() => closeFn('addAsNewVersion')}>
                        {t('action.uploadAsANewVersion')}
                    </Button>
                    <Button onClick={() => closeFn('renameNewFile')}>Upload as a new file</Button>
                    <Button onClick={() => closeFn('cancel')} variant="light">
                        {t('action.cancel')}
                    </Button>
                </Group>
            </Stack>
        </Box>
    );
}
