import * as PmxApi from 'privmx-server-api';
import { api } from '../../api/Api';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Center } from '@mantine/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import StateView from '../../atoms/State';
import { MeetingId, ThreadLinkData, Username } from '../../types/Types';
import { useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { useTranslation } from 'react-i18next';
import { UsersList, getUserModels } from '../MeetingLobbyManagementPage';

export function MeetingLobbyWaitingPage() {
    const routeParams = useParams();
    const meetingData = useMemo(
        () => api.parseThreadLinkStringData(routeParams.meetingData ?? '')!,
        [routeParams]
    );
    const { state, revalidate } = useDataSubscribion(
        useCallback(
            (x) => x.subscribeForThreadMeetingLobby(meetingData.threadId),
            [meetingData.threadId]
        )
    );
    return (
        <StateView state={state} retry={revalidate}>
            {(data) => (
                <MeetingLobbyWaitingView
                    meetingDataStr={routeParams.meetingData ?? ''}
                    meetingData={meetingData}
                    lobby={data.lobby}
                />
            )}
        </StateView>
    );
}

interface MeetingLobbyPageViewProps {
    meetingDataStr: string;
    meetingData: ThreadLinkData;
    lobby: PmxApi.api.thread.MeetingLobby;
}

export function MeetingLobbyWaitingView(props: MeetingLobbyPageViewProps) {
    const { t } = useTranslation();
    const users = useMemo(
        () => getUserModels({ regularUsers: [], anonymousUsers: {} }, props.lobby, []),
        [props.lobby]
    );
    const navigate = useNavigate();
    const currentUser = useAppSelector(selectCurrentUser);
    const isAnonymous = currentUser.pub !== null;
    const userId = currentUser.pub !== null ? currentUser.pub : currentUser.username;
    const isInLobby = !!props.lobby.lobbyUsers.find(
        (x) =>
            (isAnonymous && x.type === 'anonymous' && x.pub === userId) ||
            (!isAnonymous && x.type === 'regular' && x.username === userId)
    );
    const [waitingState, setWaitingState] = useState<'waiting' | 'deniedAccess'>('waiting');

    useEffect(() => {
        if (waitingState !== 'waiting') {
            return;
        }
        const intervalId = window.setInterval(() => {
            api.commitPresenceInThreadLobby(props.meetingData.threadId);
        }, 60 * 1000);
        return () => {
            window.clearInterval(intervalId);
        };
    }, [props.meetingData.threadId, waitingState]);

    useEffect(() => {
        if (!isInLobby && waitingState === 'waiting') {
            (async () => {
                let isInTheMeeting = false;
                try {
                    const meeting = await api.getMeeting(
                        props.meetingData.threadId as string as MeetingId
                    );
                    if (!meeting) {
                        throw new Error('Meeting not found');
                    }
                    if (isAnonymous) {
                        isInTheMeeting = !!meeting.meetingState.anonymousUsers[userId!];
                    } else {
                        isInTheMeeting = meeting.meetingState.regularUsers.includes(
                            userId as string as Username
                        );
                    }
                } catch (e) {
                    console.error(e);
                }
                if (isInTheMeeting) {
                    navigate(`/meetingRoom/${props.meetingDataStr}`);
                } else {
                    setWaitingState('deniedAccess');
                }
            })();
        }
    }, [
        isInLobby,
        props.meetingDataStr,
        isAnonymous,
        userId,
        navigate,
        props.meetingData.threadId,
        waitingState
    ]);

    return (
        <Center w="100%" h="100svh" py="md">
            <Box miw={500} mah="100%">
                <UsersList
                    type="lobby"
                    title={t('entityProps.meetingLobbyWithName', { name: props.meetingData.title })}
                    regular={users.lobby.regular}
                    anonymous={users.lobby.anonymous}
                    isProcessing={false}>
                    {waitingState === 'waiting' && (
                        <Box mt="md">{t('progress.waitingInLobby')}</Box>
                    )}
                    {waitingState === 'deniedAccess' && (
                        <Box mt="md">{t('errorMessage.meetingAccessDenied')}</Box>
                    )}
                </UsersList>
            </Box>
        </Center>
    );
}

export default MeetingLobbyWaitingPage;
