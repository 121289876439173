import { ActionIcon, Button, Modal, createStyles } from '@mantine/core';
import * as types from '../../types/Types';
import { Carousel, Embla } from '@mantine/carousel';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FileImage } from './tiles/FileImage';
import Icon, { iconDownload } from '../Icon';
import { IconArrowLeft, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { api } from '../../api/Api';
import { useTranslation } from 'react-i18next';
import { FileViewers } from '../fileViewers/FileViewers';
import { DownloadProgress } from '../../api/privmx/AttachmentUtils';
import { fileSizeFormatter } from '../../utils/Formatters';
import { Limits } from '../../api/Limits';

const useGalleryStyles = createStyles((theme) => {
    return {
        modal: {
            '> div > section': {
                background: 'transparent',
                '> div': {
                    padding: 0
                }
            },
            '.mantine-Carousel-controls .mantine-UnstyledButton-root': {
                background: 'transparent',
                color: '#fff',
                border: 'none',
                boxShadow: 'none'
            }
        }
    };
});

export interface GalleryProps {
    files: (types.AttachmentEx | types.InquiryAttachmentEx)[];
    getShowFilePreview: (showFilePreview: (fileId: types.AttachmentId) => void) => void;
}

export function Gallery(props: GalleryProps) {
    const { classes } = useGalleryStyles();
    const [slideId, setSlideId] = useState<number | null>(null);
    const [isCarouselHidden, setIsCarouselHidden] = useState(true);
    const modalRef = useRef<HTMLDivElement>(null);
    const handleGetEmblaApi = useCallback((embla: Embla) => {
        setTimeout(() => {
            setIsCarouselHidden(false);
            embla.reInit();
        }, 500);
    }, []);
    const handleShowFilePreview = useCallback(
        (fileId: types.AttachmentId) => {
            const idx = props.files.findIndex((x) => x.id === fileId);
            if (idx >= 0) {
                setIsCarouselHidden(true);
                setSlideId(idx);
            }
        },
        [props.files]
    );
    const getShowFilePreview = props.getShowFilePreview;
    useEffect(() => {
        getShowFilePreview(handleShowFilePreview);
    }, [handleShowFilePreview, getShowFilePreview]);
    const handleCarouselClose = useCallback(() => {
        setSlideId(null);
        setIsCarouselHidden(true);
    }, []);
    const handleModalClick = useCallback(
        (e: React.MouseEvent) => {
            const el = e.target as HTMLElement;
            if (
                el.classList.contains('mantine-Modal-content') ||
                el.classList.contains('slide-container')
            ) {
                handleCarouselClose();
            }
        },
        [handleCarouselClose]
    );
    return (
        <Modal
            size={'100%'}
            fullScreen
            opened={slideId !== null}
            onClose={handleCarouselClose}
            withCloseButton={false}
            className={classes.modal}
            onClick={handleModalClick}>
            {slideId !== null && (
                <Carousel
                    onSlideChange={(idx) => setSlideId(idx)}
                    initialSlide={slideId}
                    height="100svh"
                    slideGap="xl"
                    controlsOffset="xs"
                    nextControlIcon={<IconChevronRight size={64} />}
                    previousControlIcon={<IconChevronLeft size={64} />}
                    slideSize="100%"
                    loop
                    dragFree
                    draggable={false}
                    getEmblaApi={handleGetEmblaApi}
                    ref={modalRef}
                    style={{ opacity: isCarouselHidden ? 0 : 1 }}>
                    {props.files.map((file, idx) => (
                        <PreviewSlide
                            key={idx}
                            id={idx}
                            activeSlideId={slideId ?? -1}
                            numSlides={props.files.length}
                            file={file}
                            onCloseClick={handleCarouselClose}
                        />
                    ))}
                </Carousel>
            )}
        </Modal>
    );
}

const usePreviewSlideStyles = createStyles((theme) => {
    return {
        slide: {
            width: '100vw',
            maxWidth: '100vw',
            paddingRight: 0
        },
        container: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            width: '65vw',
            paddingBottom: theme.spacing.sm,
            fontSize: theme.fontSizes.lg,
            fontWeight: 500
        },
        fileName: {
            flex: '1 1 auto',
            color: '#fff'
        },
        imageContainer: {
            background: '#000',
            width: '65vw',
            flex: '0 0 80svh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 0
        },
        noPreviewInfoContainer: {
            textAlign: 'center',
            color: '#fff'
        },
        fileIdx: {
            color: '#fff',
            marginTop: theme.spacing.md
        }
    };
});

export interface PreviewSlideProps extends React.PropsWithChildren {
    id: number;
    activeSlideId: number;
    numSlides: number;
    onCloseClick: () => void;
}

function PreviewSlide(
    props: PreviewSlideProps & { file: types.AttachmentEx | types.InquiryAttachmentEx }
) {
    const { t } = useTranslation();
    const { classes } = usePreviewSlideStyles();
    const preActiveSlideId = (props.activeSlideId - 1 + props.numSlides) % props.numSlides;
    const postActiveSlideId = (props.activeSlideId + 1) % props.numSlides;
    const [downloading, setDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState<DownloadProgress | null>(null);
    const handleDownloadClick = useCallback(async () => {
        if (downloading) {
            return;
        }
        try {
            setDownloading(true);
            await api.downloadAttachment({
                fileName: props.file.name,
                attachmentId: props.file.id,
                attachmentSourceType: props.file.sourceType,
                thumb: false,
                onProgress: (progress) => setDownloadProgress(progress)
            });
        } catch (e) {
            console.log('Download error', e);
        } finally {
            setDownloading(false);
        }
    }, [props.file.id, props.file.name, props.file.sourceType, downloading]);
    if (![preActiveSlideId, props.activeSlideId, postActiveSlideId].includes(props.id)) {
        return <Carousel.Slide className={classes.slide}></Carousel.Slide>;
    }

    const FileViewer = FileViewers.getViewer(props.file.contentType)?.component;
    const hasImagePreview =
        props.file.contentType.startsWith('image/') &&
        props.file.size <= Limits.MAX_PREVIEWABLE_FILE_SIZE_B;

    return (
        <Carousel.Slide className={classes.slide}>
            <div className={classes.container + ' slide-container'}>
                <div className={classes.header}>
                    <ActionIcon
                        mr="xs"
                        onClick={props.onCloseClick}
                        variant="transparent"
                        size={40}>
                        <IconArrowLeft color="#fff" size={40} />
                    </ActionIcon>
                    <div className={classes.fileName}>{props.file.name}</div>
                    <ActionIcon
                        onClick={handleDownloadClick}
                        variant="transparent"
                        style={{ color: '#fff', fontSize: '24px' }}
                        size={40}>
                        <Icon icon={iconDownload} />
                    </ActionIcon>
                </div>
                <div className={classes.imageContainer}>
                    {hasImagePreview && <FileImage file={props.file} fullSize />}
                    {!hasImagePreview && FileViewer !== undefined && (
                        <FileViewer
                            file={props.file}
                            isVisible={props.activeSlideId === props.id}
                        />
                    )}
                    {!hasImagePreview && FileViewer === undefined && (
                        <div className={classes.noPreviewInfoContainer}>
                            <h3>{t('fileWithoutPreview.header')}</h3>
                            <p>{t('fileWithoutPreview.text')}</p>
                            <Button
                                leftIcon={<Icon icon={iconDownload} />}
                                mt="sm"
                                variant="default"
                                onClick={handleDownloadClick}>
                                {t('action.download')}
                            </Button>
                            {downloading && downloadProgress && (
                                <div>
                                    <div style={{ marginTop: '20px' }}>
                                        {fileSizeFormatter(downloadProgress.downloaded)}
                                        {' / '}
                                        {fileSizeFormatter(downloadProgress.size)}
                                    </div>
                                    <div>{downloadProgress.percentage.toFixed(2)}%</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={classes.fileIdx}>
                    {props.id + 1} / {props.numSlides}
                </div>
            </div>
        </Carousel.Slide>
    );
}
