import React from 'react';
import { AnswerType, QuestionModel } from '../../types/Types';

export type FormDispatch = React.Dispatch<FormAction>;

export type FormType =
    | 'add_question'
    | 'change_title'
    | 'change_questionType'
    | 'add_option'
    | 'change_option'
    | 'remove_option'
    | 'remove_question'
    | 'move_question'
    | 'change_required';

export interface BaseAction {
    type: FormType;
}

export interface BaseChangeAction extends BaseAction {
    newValue: string;
    index: number;
}

export interface BaseModifyAction extends BaseAction {
    index: number;
}

export interface ChangeTitle extends BaseChangeAction {
    type: 'change_title';
}

export interface ChangeRequired extends BaseAction {
    type: 'change_required';
    index: number;
    newValue: boolean;
}

export interface ChangeQuestionType extends BaseChangeAction {
    type: 'change_questionType';
}

export interface AddOption extends BaseModifyAction {
    type: 'add_option';
}

export interface ChangeOption extends BaseChangeAction {
    type: 'change_option';
    optionIndex: number;
}

export interface RemoveOption extends BaseAction {
    index: number;
    type: 'remove_option';
    optionIndex: number;
}

export interface RemoveQuestion extends BaseModifyAction {
    type: 'remove_question';
}

export interface LoadForm {
    type: 'load_form';
    questions: QuestionModel[];
}

export interface MoveQuestion extends BaseModifyAction {
    type: 'move_question';
    direction: 'up' | 'down';
}

export type FormAction =
    | ChangeOption
    | AddOption
    | ChangeTitle
    | ChangeQuestionType
    | ChangeRequired
    | RemoveOption
    | RemoveQuestion
    | LoadForm
    | MoveQuestion
    | { type: 'add_question' };

//Add suport for range type
export const answerType = ['short', 'select', 'single', 'long', 'block', 'email', 'phone'];
export function isAnswerType(x: string): x is AnswerType {
    if (answerType.find((t) => t === x)) return true;
    return false;
}
