import * as PmxApi from 'privmx-server-api';
import { TagEncryptionService } from './TagEncryptionService';
import { Utils } from './utils/Utils';

export interface ThreadSticker {
    u: PmxApi.api.core.Username;
    t: PmxApi.api.core.TimestampN;
    s: string;
}

export class StickerService {
    constructor(private tagEncryptionService: TagEncryptionService) {}

    async encryptSticker(sticker: string) {
        return (await this.tagEncryptionService.encryptTag(
            sticker
        )) as string as PmxApi.api.thread.ThreadStickerId;
    }

    async decryptStickers(stickers: PmxApi.api.thread.ThreadSticker[]) {
        const res = await Promise.all(
            stickers.map(async (x) => {
                const tag = await this.tagEncryptionService.tryDecryptTag(
                    x.s as string as PmxApi.api.tag.Tag
                );
                if (!tag) {
                    return null;
                }
                const res: ThreadSticker = {
                    u: x.u,
                    t: x.t,
                    s: tag
                };
                return res;
            })
        );
        return res.filter(Utils.isDefined);
    }
}
