import {
    createContext,
    Dispatch,
    ReactNode,
    useContext,
    useMemo,
    useReducer,
    useDebugValue
} from 'react';
import { Company, Username } from '../../types/Types';
import { DatesRangeValue } from '@mantine/dates';

export class FilterFactory {
    static user(username: string) {
        return `author:${username}` as `author:${Username}`;
    }

    static tag(tag: string) {
        return `tag:${tag}` as `tag:${Username}`;
    }

    static company(company: Company | string) {
        if (typeof company === 'string') {
            return `company:${company}` as `company:${Company['name']}`;
        }
        return `company:${company.name}` as `company:${Company['name']}`;
    }

    static date(dateRange: DatesRangeValue) {
        if (dateRange && dateRange[0] && dateRange[1]) {
            if (dateRange[0]?.getTime() === dateRange[1]?.getTime()) {
                return `date:${dateRange[0]?.getTime()}`;
            } else {
                return `date:${dateRange.map((x) => x?.getTime()).join('-')}`;
            }
        }
    }
}

interface FilterContext {
    contextState: string[];
    hasFilters: boolean;
    filters: Array<{ type: string; value: string }>;
}

type FilterContextAction =
    | { type: 'add'; filter: string }
    | { type: 'remove'; filter: string }
    | { type: 'sync'; filters: string[] }
    | { type: 'syncParams'; filters: Array<[string, string]> } // Array<[filterType,username]>
    | { type: 'clear' }
    | { type: 'set'; state: string[] };

const filterContext = createContext<FilterContext & { dispatch: Dispatch<FilterContextAction> }>({
    contextState: [],
    filters: [],
    dispatch() {},
    hasFilters: false
});

export function useFilterContext() {
    useDebugValue('useFilterContext');
    return useContext(filterContext);
}
useFilterContext.displayName = 'useFilterContext';

function reducer(state: FilterContext, action: FilterContextAction) {
    const newState = structuredClone(state) as typeof state;
    switch (action.type) {
        case 'remove':
            newState.contextState = newState.contextState.filter((x) => x !== action.filter);
            break;
        case 'add':
            if (!newState.contextState.find((x) => x === action.filter))
                newState.contextState.push(action.filter);
            break;
        case 'set':
            newState.contextState = action.state;
            break;
        case 'sync':
            const mappedFilter = action.filters
                .map((x) => {
                    const [type, value] = x.split(':');
                    if (type === 'file' || type === 'file type') {
                        if (value === 'any') return null;
                        return `type:${value}`;
                    }
                    return x;
                })
                .filter((x) => x !== null) as string[];
            newState.contextState = mappedFilter;
            break;
        case 'clear':
            newState.contextState = [];
            break;
        case 'syncParams':
            for (const [type, value] of action.filters) {
                if (type === 'user') {
                    newState.contextState.push(FilterFactory.user(value));
                } else if (type === 'company') {
                    newState.contextState.push(FilterFactory.company(value));
                }
            }
    }

    newState.filters = newState.contextState.map((filter) => {
        const [type, value] = filter.split(':');
        return { type, value };
    });

    newState.hasFilters = newState.filters.length > 0;

    return newState;
}

export const FilterContextProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, {
        contextState: [],
        filters: [],
        hasFilters: false
    });

    const contextState: FilterContext & { dispatch: Dispatch<FilterContextAction> } =
        useMemo(() => {
            return { ...state, dispatch };
        }, [state]);

    return <filterContext.Provider value={contextState}>{children}</filterContext.Provider>;
};
