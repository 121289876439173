import React, { useCallback, useEffect, useRef } from 'react';
import Block from './components/Block';
import InputFactory from './components/InputFactory';
import { Button, Card, ScrollArea, Stack, TextInput, createStyles, rem } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconPlus } from '@tabler/icons-react';
import { useFormCreatorContext } from './components/FormState/useFormState';

export const useFormCreatorStyles = createStyles((theme) => {
    return {
        wrapper: {
            height: 'calc(100% - 90px)',
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
        },
        blockRoot: {
            padding: theme.spacing.xl,
            overflow: 'visible',
            width: '50%',
            marginInline: 'auto',
            marginBottom: theme.spacing.md
        }
    };
});

export function FormCreator() {
    return <FormCreatorView />;
}

function FormCreatorView() {
    const {
        creatorState: state,
        dispatch,
        formName: [formName, setFormName],
        formAutoResponse: [formAutoResponse, setFormAutoResponse],
        formModel,
        formNameError: [formNameError]
    } = useFormCreatorContext();

    const scrollArea = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();

    const handleAutoResponseChange = useCallback(
        (newId: string) => {
            setFormAutoResponse(newId);
        },
        [setFormAutoResponse]
    );

    const handleAddQuestion = useCallback(() => {
        dispatch({ type: 'add_question' });
        setTimeout(() => {
            scrollArea.current?.scrollTo({
                top: scrollArea.current?.scrollHeight,
                behavior: 'smooth'
            });
        }, 200);
    }, [dispatch]);

    const { classes } = useFormCreatorStyles();

    useEffect(() => {
        if (formModel.id !== 'new' && formModel.name && formModel.questions) {
            dispatch({ type: 'load_form', questions: formModel.questions });
        }
    }, [formModel.id, formModel.name, formModel.questions, dispatch]);
    return (
        <Stack py="xl" className={classes.wrapper}>
            <Card className={classes.blockRoot}>
                <TextInput
                    error={formNameError}
                    label="Form Title"
                    size="lg"
                    value={formName}
                    onChange={(e) => setFormName(e.target.value)}
                    placeholder={t('screen.formCreator.placeholders.formTitle')}
                    styles={{
                        input: {
                            borderTop: 0,
                            borderRight: 0,
                            borderLeft: 0
                        }
                    }}
                />
            </Card>
            <ScrollArea
                viewportRef={scrollArea}
                hidden={false}
                type="always"
                sx={{ flexGrow: 1 }}
                pos="relative">
                {state.map((question, i) => (
                    <Block
                        key={i}
                        autoResponseFieldId={formAutoResponse || ''}
                        question={question}
                        dispatch={dispatch}
                        onAutoResponseChange={handleAutoResponseChange}
                        index={i}>
                        <InputFactory question={question} index={i} dispatch={dispatch} />
                    </Block>
                ))}
                <Button
                    variant="light"
                    leftIcon={<IconPlus size={rem(14)} />}
                    mx="auto"
                    mt="lg"
                    onClick={() => handleAddQuestion()}
                    display={'block'}>
                    New Form block
                </Button>
            </ScrollArea>
        </Stack>
    );
}
