import { Flex, Skeleton, Divider, Group, Button } from '@mantine/core';

export default function ThreadAddModalLoader({ isMeeting = false }: { isMeeting?: boolean }) {
    return (
        <Flex h="100%" direction="column" gap={8} pb="sm">
            <Skeleton height={22} width="50%"></Skeleton>
            <Divider my={4}></Divider>
            <div>
                <Skeleton mb={4} height={21} width={80}></Skeleton>
                <Skeleton height={34}></Skeleton>
            </div>

            <div>
                <Skeleton mb={4} height={21} width={35}></Skeleton>
                <Skeleton height={34}></Skeleton>
            </div>
            {isMeeting ? (
                <Group grow>
                    <div>
                        <Skeleton mb={4} height={21} width={50}></Skeleton>
                        <Skeleton height={34}></Skeleton>
                    </div>
                    <div>
                        <Skeleton mb={4} height={21} width={80}></Skeleton>
                        <Skeleton height={34}></Skeleton>
                    </div>
                </Group>
            ) : (
                <></>
            )}
            <Skeleton mt={16} height={21} width={120}></Skeleton>
            <Skeleton mb={8} sx={{ flexGrow: 1 }}></Skeleton>
            <Group position="apart">
                <Skeleton width={150}>
                    <Button>Attach</Button>
                </Skeleton>
                <Skeleton width={100}>
                    <Button>Submit</Button>
                </Skeleton>
            </Group>
        </Flex>
    );
}
