import React from 'react';
import { UsersList } from '../../screens/ChatScreen/components/UserList';
import { Username } from '../../types/Types';
import { Box, createStyles, Group, Stack, Title } from '@mantine/core';

const usePageHeader = createStyles((theme) => ({
    users: {
        '.mantine-Avatar-root': {
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0]
        }
    }
}));

export default function PageDetailsHeader(props: {
    name?: React.ReactNode | string;
    usernames?: Username[];
    children?: React.ReactNode;
    button?: JSX.Element;
    className?: string;
}) {
    const { classes } = usePageHeader();

    return (
        <Group className={props.className} position="apart" px="md" noWrap>
            <Stack spacing={'xs'} sx={{ flexGrow: 1, minWidth: 0 }}>
                <Group noWrap>
                    {typeof props.name === 'string' ? (
                        <Title
                            sx={{ whiteSpace: 'break-spaces' }}
                            lineClamp={2}
                            truncate="end"
                            miw={0}
                            fz={{ base: 'md', xl: 'lg' }}>
                            {props.name}
                        </Title>
                    ) : (
                        <Group>{props.name}</Group>
                    )}
                    {props.usernames && (
                        <UsersList
                            className={classes.users}
                            usernames={props.usernames.map((x) => ({ type: 'user', username: x }))}
                        />
                    )}
                </Group>
                <Box>{props.children}</Box>
            </Stack>
            <Box sx={{ flexShrink: 0, alignSelf: 'start' }}>{props.button}</Box>
        </Group>
    );
}
