import * as privmx from 'privfs-client';
import { CredentialsHolder } from './CredentialsHolder';
import { runAsync } from '../privmx/utils/async';
import { ReconnectService } from './ReconnectService';

export interface IReconnectController {
    cancelReconnect(): void;
    promise: Promise<void>;
}

export class ConnectionChecker {
    private destroyed: boolean = false;
    private reconnecting: boolean = false;
    private currentDelayResolve?: () => void;
    private currentReconnectController?: IReconnectController;

    constructor(
        private gateway: privmx.gateway.RpcGateway,
        private credentialsHolder: CredentialsHolder,
        private service: ReconnectService,
        private logout: () => void
    ) {
        this.gateway.addEventListener('disconnected', () => {
            this.onDisconnect();
        });
        this.gateway.addEventListener('connected', () => {
            this.onConnected();
        });
        this.gateway.addEventListener('sessionLost', () => {
            this.onSessionLost();
        });
    }

    connectAsapIfNeeded() {
        if (this.currentDelayResolve) {
            this.currentDelayResolve();
        }
        runAsync(() => this.reconnectLoop());
    }

    async destroy() {
        this.destroyed = true;
        if (this.currentDelayResolve) {
            this.currentDelayResolve();
        }
        if (this.currentReconnectController) {
            this.currentReconnectController.cancelReconnect();
        }
        await Promise.race([
            (async () => {
                try {
                    if (this.gateway.isConnected()) {
                        await this.gateway.request('logout', {});
                    }
                } catch (e) {
                    const hasSessionNotBeenEstablished =
                        typeof e === 'object' &&
                        e &&
                        'msg' in e &&
                        (e as any).msg === "Failed assert SESSION_ESTABLISHED for method 'logout'";
                    if (!hasSessionNotBeenEstablished) {
                        console.error('Logout error', e);
                    }
                }
            })(),
            new Promise((resolve) => setTimeout(resolve, 200))
        ]);
        this.gateway.destroy();
    }

    async disconnectAndDestroy() {
        if (this.gateway.isConnected()) {
            this.service.onDisconnect('destroy');
        }
        await this.destroy();
    }

    private onSessionLost() {
        runAsync(() => this.reconnectLoop());
    }

    private onConnected() {}

    private onDisconnect() {
        runAsync(() => this.reconnectLoop());
    }

    private async reconnectLoop() {
        if (this.destroyed) {
            return;
        }
        if (this.gateway.isConnected()) {
            return;
        }
        if (this.reconnecting) {
            return;
        }
        this.service.onDisconnect('connection-error');
        this.reconnecting = true;
        let failed: boolean | undefined = undefined;
        while (true) {
            if (this.destroyed) {
                return;
            }
            try {
                this.service.onBeforeReconnect();
                await this.tryReconnect();
                this.service.onConnected();
                this.reconnecting = false;
                failed = false;
                return;
            } catch (e) {
                if (e && typeof e === 'object' && 'shouldRetry' in e) {
                    const shouldRetry = (e as any).shouldRetry as boolean;
                    if (!shouldRetry) {
                        failed = true;
                        break;
                    }
                }
                if (e instanceof privmx.rpc.SessionLostError) {
                    continue;
                } else if (e instanceof privmx.rpc.AlertError && e.isError('Unknown session')) {
                    continue;
                } else if (e instanceof privmx.rpc.ConnectionError) {
                    console.error('ConnectionError during reconnect loop', e);
                } else {
                    console.error('unknown reconnection error', e);
                }
                await this.delay(3000);
            }
        }
        if (failed) {
            this.service.onConnected();
            this.logout();
        }
    }

    private delay(time: number) {
        return new Promise<void>((resolve) => {
            this.currentDelayResolve = resolve;
            setTimeout(resolve, time);
        });
    }

    private async tryReconnect() {
        if (this.gateway.isSessionEstablished()) {
            await this.gateway.verifyConnection();
        } else if (this.gateway.isRestorableBySession()) {
            await this.gateway.tryRestoreBySession();
        } else {
            const { username, password } = await this.getSavedCredentials();
            if (username === undefined || password === undefined) {
                throw new Error('No credentials');
            }
            try {
                this.currentReconnectController = this.service.onRelogin(async () => {
                    this.service.onBeforeRelogin();
                    try {
                        if (this.destroyed) {
                            throw new Error('Connection checker already destroyed');
                        }
                        await this.gateway.probe();
                        await this.gateway.srpRelogin(username, password);
                    } catch (e) {
                        this.service.onReloginFailure(`${e}`);
                        throw e;
                    }
                });
                await this.currentReconnectController!.promise;
            } finally {
                this.currentReconnectController = undefined;
            }
        }
    }

    private async getSavedCredentials() {
        const password = await this.credentialsHolder.getPassword();
        const username = this.credentialsHolder.getUsername();
        return { username, password };
    }
}
