import { Template } from "../../../core/utils";

const html = require("./main.html").default;

export interface MainTemplateModel {
    isE2EEEnabled: boolean | null | undefined;
    title: string | null | undefined;
    hideWelcomeMessage: boolean;
    isResolutionSwitchEnabled: boolean;
}

export class MainTemplate extends Template<HTMLDivElement> {
    
    constructor(i18n: (key: string, ...args: string[]) => string) {
        super(html, i18n);
    }
    
    render(model: MainTemplateModel): HTMLDivElement {
        const $rendered = super.render(model);
        
        const $titleContainer = $rendered.querySelector<HTMLDivElement>(".title-container")!;
        const $conferenceTitle = $rendered.querySelector<HTMLDivElement>(".conference-title")!;
        const $e2eeIcon = $rendered.querySelector<HTMLElement>(".e2ee-icon")!;
        const $noE2eeIcon = $rendered.querySelector<HTMLElement>(".no-e2ee-icon")!;
        
        $conferenceTitle.innerText = model.title ?? "";
        
        if (model.isE2EEEnabled === true) {
            $noE2eeIcon.remove();
        }
        else if (model.isE2EEEnabled === false) {
            $e2eeIcon.remove();
        }
        else {
            $noE2eeIcon.remove();
            $e2eeIcon.remove();
            $conferenceTitle.remove();
        }
        
        if (!model.isResolutionSwitchEnabled) {
            $rendered.querySelector<HTMLDivElement>(".resolution-select-container")!.style.display = "none";
        }
        
        $titleContainer.classList.toggle("hide-welcome-message", model.hideWelcomeMessage);
        
        return $rendered;
    }
    
}
