import Icon, { mimetypeToIcon } from '../../components/Icon';
import { File } from '../../types/Types';
import { Utils } from '../../utils/Utils';
import Timestamp from '../../atoms/Timestamp';
import { Box, createStyles, Grid, rem, Text } from '@mantine/core';
import HoverContainer from '../../mantineAtoms/HoverContainer';
import { useUnreadContentStyles } from '../../mantineAtoms/UnreadContent';
import { useIsAttachmentUnread } from '../../hooks/useIsUnread';
import { useRef, useEffect } from 'react';
import { useScrollTo } from '../../mantineAtoms/ScreenScrollContainer';

interface FileElementProps {
    active: boolean;
    file: File;
    onClick?: Function;
    className?: string;
    hovered?: boolean;
}

const useStyles = createStyles((theme) => ({
    wrapper: {
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        cursor: 'pointer',
        ':hover': {
            backgroundColor:
                theme.colorScheme === 'dark'
                    ? theme.fn.rgba(theme.colors['gray'][9], 0.25)
                    : theme.colors['gray'][0]
        }
    },
    active: {
        backgroundColor:
            theme.colorScheme === 'dark'
                ? theme.fn.rgba(theme.colors['gray'][9], 0.25)
                : theme.colors['gray'][0],
        fontWeight: 500,
        borderLeftColor: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 6 : 7],
        color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 2 : 7]
    },
    hovered: {
        backgroundColor:
            theme.colorScheme === 'dark'
                ? theme.fn.rgba(theme.colors['gray'][9], 0.25)
                : theme.colors['gray'][0]
    },
    root: {
        fontSize: theme.fontSizes.sm,
        flexWrap: 'nowrap'
    },
    row: {
        whiteSpace: 'nowrap',
        padding: `calc(${theme.spacing.xs} / 2) 0px`,
        minWidth: rem(30)
    },
    text: {
        minWidth: 0,
        flexShrink: 1,
        alignSelf: 'start',
        marginRight: 'auto',
        alignContent: 'center',
        padding: rem(8),
        wordBreak: 'break-all'
    }
}));

export function FileListElement({ active, file, onClick, className, hovered }: FileElementProps) {
    const { classes, cx } = useStyles();
    const isUnread = useIsAttachmentUnread(file.id);
    const { classes: unread } = useUnreadContentStyles({ isUnread });

    const wrapper = useRef<HTMLDivElement>(null);
    const scrollTo = useScrollTo();

    useEffect(() => {
        if (!scrollTo) return;

        if (active) {
            scrollTo({ top: (wrapper.current?.offsetTop || 200) - 200 });
        }
        if (hovered) {
            scrollTo({ top: (wrapper.current?.offsetTop || 200) - 200 });
        }
    }, [hovered, active, scrollTo]);

    return (
        <div ref={wrapper}>
            <HoverContainer className={classes.wrapper} active={active} hovered={hovered} my={0}>
                <Grid
                    justify="space-between"
                    onClick={(e) => onClick?.(e)}
                    className={cx(classes.root, className, unread.content)}>
                    <Grid.Col span="content">
                        <Box component={Icon} icon={mimetypeToIcon(file.contentType)} />
                    </Grid.Col>
                    <Grid.Col unstyled span={'content'} className={classes.text}>
                        <Text lineClamp={2}>{file.name}</Text>
                    </Grid.Col>
                    <Grid.Col span={'content'}>
                        <Text sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }} lineClamp={1}>
                            {Utils.bytesSize(file.size)}
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={'content'}>
                        <Text align="center" truncate="end" lineClamp={1}>
                            {file.creator}
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={'content'}>
                        <Text
                            align="right"
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                            {<Timestamp date={file.createdDate} />}
                        </Text>
                    </Grid.Col>
                </Grid>
            </HoverContainer>
        </div>
    );
}
