import { createStyles, Anchor, AnchorProps } from '@mantine/core';
import { Link } from 'react-router-dom';

const useAnchorStyles = createStyles(() => ({
    link: {
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'none'
        }
    }
}));

export const ElementAnchor = ({
    children,
    className,
    to,
    ...props
}: AnchorProps & { to: string }) => {
    const { classes, cx } = useAnchorStyles();
    return (
        <Anchor component={Link} to={to} className={cx(className, classes.link)} {...props}>
            {children}
        </Anchor>
    );
};
