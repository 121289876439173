import * as privfs from 'privfs-client';

export class HashmailResolver {
    constructor(private srpSecure: privfs.core.PrivFsSrpSecure) {}

    async resolveHashmail(
        hashmail: privfs.identity.Hashmail | string,
        includeImage?: boolean
    ): Promise<{ data: privfs.types.core.UserInfo; receivers: privfs.message.MessageReceiver[] }> {
        const hashmailObj =
            hashmail instanceof privfs.identity.Hashmail
                ? hashmail
                : new privfs.identity.Hashmail(hashmail);
        const data = await this.srpSecure.getUserInfo(
            hashmailObj.user,
            hashmailObj.host,
            includeImage ? ['info', 'image'] : ['info']
        );
        const receivers = (data.profile.sinks || []).map(
            (x) => new privfs.message.MessageReceiver(x, data.user)
        );
        return {
            data: data,
            receivers: receivers
        };
    }
}
