import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '../api/Api';
import { LoadingState } from './useLoader';
import * as types from '../types/Types';
import * as PmxApi from 'privmx-server-api';
import { store } from '../store';
import { selectCachedForm, selectCachedThread } from '../store/DataCacheSlice';
import { Deferred } from '../utils/Deferred';
import { Lock } from '../utils/Lock';

export type SubscriptionListener = () => void;

export type EventListener<T = unknown> = (event: T) => unknown;
export interface EventDispatcher {
    addEventListener(type: string, listener: EventListener): void;
    removeEventListener(type: string, listener: EventListener): void;
}

export interface TypedEventDispatcher<T extends { type: string }> {
    addEventListener(type: T['type'], listener: EventListener<T>): void;
    removeEventListener(type: T['type'], listener: EventListener<T>): void;
}

export interface Subscription<T = unknown> {
    state: LoadingState<T>;
    revalidate: (switchToLoadingState?: boolean) => void;
    revalidateQuiet: () => void;
    clear(): void;
    addOnChangeListener(listener: SubscriptionListener): void;
    removeOnChangeListener(listener: SubscriptionListener): void;
}

export class SingleSubscription<T = unknown> implements Subscription {
    state: LoadingState<T> = { type: 'loading', deferred: new Deferred<void>() };
    revalidate: (switchToLoadingState?: boolean) => void;
    revalidateQuiet: () => void;
    private listeners: SubscriptionListener[] = [];
    private bindedEvents: {
        dispatcher: EventDispatcher;
        eventType: string;
        listener: EventListener;
    }[] = [];

    constructor(load: () => Promise<T>) {
        const lock = new Lock();
        this.revalidate = async (switchToLoadingState = true) => {
            return lock.withLock(async () => {
                if (switchToLoadingState) {
                    this.setState({ type: 'loading', deferred: new Deferred<void>() });
                }
                try {
                    // Load takes at least 300 ms
                    const [data] = await Promise.all([
                        load(),
                        new Promise((resolve) => setTimeout(resolve, 300))
                    ]);
                    const oldState = this.state;
                    this.setState({ type: 'done', data: data });
                    if (oldState.type === 'loading') {
                        oldState.deferred.resolve();
                    }
                } catch (e) {
                    console.error('SingleSubscription error while loading:', e);
                    const oldState = this.state;
                    this.setState({ type: 'error', error: e });
                    if (oldState.type === 'loading') {
                        oldState.deferred.reject(e);
                    }
                }
            });
        };
        this.revalidateQuiet = () => {
            this.revalidate(false);
        };
    }

    clear() {
        this.listeners = [];
        for (const binding of this.bindedEvents) {
            binding.dispatcher.removeEventListener(binding.eventType, binding.listener);
        }
        this.bindedEvents = [];
    }

    addOnChangeListener(listener: SubscriptionListener) {
        this.listeners.push(listener);
    }

    removeOnChangeListener(listener: SubscriptionListener) {
        const index = this.listeners.indexOf(listener);
        if (index !== -1) {
            this.listeners.splice(index, 1);
        }
    }

    setState(state: LoadingState<T>) {
        this.state = state;
        this.dispatch();
    }

    private dispatch() {
        for (const listener of this.listeners) {
            try {
                listener();
            } catch (e) {
                console.error('Error during calling subscription listener', e);
            }
        }
    }

    bindEvent<T extends { type: string }>(
        dispatcher: TypedEventDispatcher<T>,
        eventType: T['type'],
        listener: EventListener<T>
    ): void {
        dispatcher.addEventListener(eventType, listener);
        this.bindedEvents.push({ dispatcher, eventType, listener: listener as EventListener });
    }

    modifyState(modifier: (data: T) => T | void) {
        if (this.state.type === 'done') {
            this.modifyStateCore(modifier);
        } else if (this.state.type === 'loading') {
            const deferred = this.state.deferred;
            deferred.promise.then(async () => {
                this.modifyStateCore(modifier);
            });
        }
    }

    private modifyStateCore(modifier: (data: T) => T | void) {
        if (this.state.type === 'done') {
            const newData = modifier(this.state.data);
            if (typeof newData !== 'undefined') {
                this.setState({ type: 'done', data: newData });
            }
        }
    }
}

type _S<A> = SubscriptionHandle<A>;
type _Subs2<A1, A2> = [_S<A1>, _S<A2>];
type _Subs3<A1, A2, A3> = [A3] extends [never] ? _Subs2<A1, A2> : [_S<A1>, _S<A2>, _S<A3>];
type _Subs4<A1, A2, A3, A4> = [A4] extends [never]
    ? _Subs3<A1, A2, A3>
    : [_S<A1>, _S<A2>, _S<A3>, _S<A4>];
type _Subs5<A1, A2, A3, A4, A5> = [A5] extends [never]
    ? _Subs4<A1, A2, A3, A4>
    : [_S<A1>, _S<A2>, _S<A3>, _S<A4>, _S<A5>];
type _Subs6<A1, A2, A3, A4, A5, A6> = [A6] extends [never]
    ? _Subs5<A1, A2, A3, A4, A5>
    : [_S<A1>, _S<A2>, _S<A3>, _S<A4>, _S<A5>, _S<A6>];
type _Subs7<A1, A2, A3, A4, A5, A6, A7> = [A6] extends [never]
    ? _Subs6<A1, A2, A3, A4, A5, A6>
    : [_S<A1>, _S<A2>, _S<A3>, _S<A4>, _S<A5>, _S<A6>, _S<A7>];
type _Subs8<A1, A2, A3, A4, A5, A6, A7, A8> = [A7] extends [never]
    ? _Subs7<A1, A2, A3, A4, A5, A6, A7>
    : [_S<A1>, _S<A2>, _S<A3>, _S<A4>, _S<A5>, _S<A6>, _S<A7>, _S<A8>];
type _Subs9<A1, A2, A3, A4, A5, A6, A7, A8, A9> = [A8] extends [never]
    ? _Subs8<A1, A2, A3, A4, A5, A6, A7, A8>
    : [_S<A1>, _S<A2>, _S<A3>, _S<A4>, _S<A5>, _S<A6>, _S<A7>, _S<A8>, _S<A9>];
type ChildSubscriptions<A1, A2, A3, A4, A5, A6, A7, A8, A9> = _Subs9<
    A1,
    A2,
    A3,
    A4,
    A5,
    A6,
    A7,
    A8,
    A9
>;

export class MergedSubscription<
    T,
    A1,
    A2,
    A3 = never,
    A4 = never,
    A5 = never,
    A6 = never,
    A7 = never,
    A8 = never,
    A9 = never
> {
    state: LoadingState<T> = { type: 'loading', deferred: new Deferred<void>() };
    revalidate: (switchToLoadingState?: boolean) => void;
    revalidateQuiet: () => void;
    private listeners: SubscriptionListener[] = [];
    private listener: () => void;

    constructor(
        public subscriptions: ChildSubscriptions<A1, A2, A3, A4, A5, A6, A7, A8, A9>,
        private modifier: (data: [A1, A2, A3, A4, A5, A6, A7, A8, A9]) => T
    ) {
        this.revalidate = async (switchToLoadingState = true) => {
            if (switchToLoadingState) {
                this.setState({ type: 'loading', deferred: new Deferred<void>() });
            }
            for (const sub of this.subscriptions) {
                sub.revalidate(true);
            }
        };
        this.revalidateQuiet = () => {
            this.revalidate(false);
        };
        this.listener = () => {
            if (this.subscriptions.some((x) => x.state.type !== 'done')) {
                return;
            }
            const data = this.subscriptions.map((x) =>
                x.state.type === 'done' ? x.state.data : undefined
            );
            const oldState = this.state;
            this.setState({
                type: 'done',
                data: this.modifier(data as [A1, A2, A3, A4, A5, A6, A7, A8, A9])
            });
            if (oldState.type === 'loading') {
                oldState.deferred.resolve();
            }
        };
        for (const sub of this.subscriptions) {
            sub.addOnChangeListener(this.listener);
        }
        this.listener();
    }

    clear() {
        this.listeners = [];
        for (const sub of this.subscriptions) {
            sub.removeOnChangeListener(this.listener);
            subscriptionRegistry.unregister(sub);
        }
    }

    addOnChangeListener(listener: SubscriptionListener) {
        this.listeners.push(listener);
    }

    removeOnChangeListener(listener: SubscriptionListener) {
        const index = this.listeners.indexOf(listener);
        if (index !== -1) {
            this.listeners.splice(index, 1);
        }
    }

    private setState(state: LoadingState<T>) {
        this.state = state;
        this.dispatch();
    }

    private dispatch() {
        for (const listener of this.listeners) {
            try {
                listener();
            } catch (e) {
                console.error('Error during calling subscription listener', e);
            }
        }
    }
}

export class SubscriptionHandle<T = unknown> implements Subscription<T> {
    state: LoadingState<T>;
    revalidate: (switchToLoadingState?: boolean) => void;
    revalidateQuiet: () => void;
    private listeners: SubscriptionListener[] = [];
    private listener: () => void;

    constructor(public id: string, private original: Subscription<T>) {
        this.state = original.state;
        this.revalidate = async (switchToLoadingState = true) => {
            if (switchToLoadingState) {
                this.setState({ type: 'loading', deferred: new Deferred<void>() });
            }
            this.original.revalidateQuiet();
        };
        this.revalidateQuiet = () => {
            this.revalidate(false);
        };
        this.listener = () => {
            const oldState = this.state;
            this.setState(this.original.state);
            if (oldState.type === 'loading' && this.state.type !== 'loading') {
                oldState.deferred.resolve();
            }
        };
        this.original.addOnChangeListener(this.listener);
    }

    setState(state: LoadingState<T>) {
        this.state = state;
        this.dispatch();
    }

    clear() {
        this.listeners = [];
        this.original.removeOnChangeListener(this.listener);
    }

    addOnChangeListener(listener: SubscriptionListener) {
        this.listeners.push(listener);
    }

    removeOnChangeListener(listener: SubscriptionListener) {
        const index = this.listeners.indexOf(listener);
        if (index !== -1) {
            this.listeners.splice(index, 1);
        }
    }

    private dispatch() {
        for (const listener of this.listeners) {
            try {
                listener();
            } catch (e) {
                console.error('Error during calling subscription listener', e);
            }
        }
    }
}

export function useDataSubscribion<T>(func: (srv: SubscriptionService) => SubscriptionHandle<T>) {
    const subscription = useMemo(() => func(subscriptionService), [func]); // przy zmianie func zawsze tworzymy nowe subscription
    const [state, setState] = useState(subscription.state);
    const onSubscriptionChange = useCallback(() => {
        setState(subscription.state);
    }, [subscription]); // callback na to że w danych się coś zmieniło
    useEffect(() => {
        setState(subscription.state);
        return () => subscriptionService.unsubscribe(subscription);
    }, [subscription]); // za każdym razem jak zmieni nam się subscription albo odmontowywujemy komponent to odłączamy się od subskrybcji
    useEffect(() => {
        subscription.addOnChangeListener(onSubscriptionChange);
        return () => subscription.removeOnChangeListener(onSubscriptionChange);
    }, [subscription, onSubscriptionChange]); // podpinamy się pod zmiany w danych i odpinamy
    return {
        state,
        revalidate: subscription.revalidate,
        revalidateQuiet: subscription.revalidateQuiet
    };
}

export type DataSubscriptionState = ReturnType<typeof useDataSubscribion>['state'];
export type SuccesState<T> = T extends Exclude<DataSubscriptionState, { type: 'done' }>
    ? { type: 'loading' } | { type: 'error'; error: 'unknown' }
    : T;

class SubscriptionRegistry {
    private map = new Map<
        string,
        { sub: Subscription; handles: SubscriptionHandle[]; removeTid: NodeJS.Timeout | null }
    >();

    create<T>(id: string, creator: () => Subscription<T>) {
        const entry = this.map.get(id);
        if (entry) {
            if (entry.removeTid) {
                clearTimeout(entry.removeTid);
                entry.removeTid = null;
            }
            const newHandle = new SubscriptionHandle(id, entry.sub as Subscription<T>);
            entry.handles.push(newHandle);
            return newHandle;
        }
        const sub = creator();
        const handle = new SubscriptionHandle(id, sub);
        this.map.set(id, { sub, handles: [handle], removeTid: null });
        sub.revalidate();
        return handle;
    }

    unregister(subscription: SubscriptionHandle) {
        subscription.clear();
        const entry = this.map.get(subscription.id);
        if (entry) {
            const index = entry.handles.indexOf(subscription);
            if (index !== -1) {
                entry.handles.splice(index, 1);
            }
            if (entry.handles.length === 0) {
                entry.removeTid = setTimeout(() => {
                    entry.sub.clear();
                    this.map.delete(subscription.id);
                }, 30000);
            }
        }
    }

    unregisterAll() {
        for (const entry of this.map.values()) {
            entry.sub.clear();
        }
        this.map.clear();
    }

    revalidateAll() {
        for (const entry of this.map.values()) {
            entry.sub.revalidate();
        }
    }
}

export const subscriptionRegistry = new SubscriptionRegistry();
const subscriptionService = {
    subscribeForChat: (chatId: types.ChatId) =>
        subscriptionRegistry.create(`chat-${chatId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const chat = await api.getChat(chatId);
                if (!chat) {
                    throw new Error('Chat not found');
                }
                return { chat };
            });
            subscription.bindEvent<types.ChatUpdatedEvent>(api, 'chatupdated', (event) => {
                if (event.chat.id !== chatId) {
                    return;
                }
                subscription.modifyState((data) => {
                    return {
                        chat: event.chat
                    };
                });
            });
            subscription.bindEvent<types.ChatVerifiedStatusChangedEvent>(
                api,
                'chatverifiedstatuschanged',
                (event) => {
                    if (event.chat.id !== chatId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            chat: event.chat
                        };
                    });
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'thread' || event.targetId !== chatId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            chat: { ...data.chat, ...event.taggableEntity }
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForChatMessageDraft: (chatId: types.ChatId) =>
        subscriptionRegistry.create(`chatMessageDraft-${chatId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const chatMessageDraft = await api.getThreadMessageDraft(chatId);
                if (chatMessageDraft && chatMessageDraft.message.messageThreadType !== 'chat') {
                    throw new Error('Invalid draft message type');
                }
                return { chatMessageDraft };
            });
            subscription.bindEvent<types.NewDraftEvent>(api, 'newdraft', (event) => {
                const draft = event.draft;
                if (draft.type !== 'threadMessageDraft' || draft.threadId !== chatId) {
                    return;
                }
                subscription.modifyState((data) => {
                    return {
                        chatMessageDraft: draft
                    };
                });
            });
            subscription.bindEvent<types.DraftChangedEvent>(api, 'draftchanged', (event) => {
                const draft = event.draft;
                if (draft.type !== 'threadMessageDraft' || draft.threadId !== chatId) {
                    return;
                }
                subscription.modifyState((data) => {
                    return {
                        chatMessageDraft: draft
                    };
                });
            });
            subscription.bindEvent<types.DraftDeletedEvent>(api, 'draftdeleted', (event) => {
                const draftId = event.draftId;
                subscription.modifyState((data) => {
                    if (data.chatMessageDraft?.id !== draftId) {
                        return data;
                    }
                    return {
                        chatMessageDraft: undefined
                    };
                });
            });
            return subscription;
        }),
    subscribeForChatWithMessages: (chatId: types.ChatId, silentThreadContentFailure = false) =>
        subscriptionRegistry.create(`chatWithMessages-${chatId}`, () => {
            return new MergedSubscription(
                [
                    subscriptionService.subscribeForChat(chatId),
                    subscriptionService.subscribeForThreadMessages(
                        chatId,
                        silentThreadContentFailure
                    ),
                    subscriptionService.subscribeForChatMessageDraft(chatId)
                ],
                ([chatRes, messagesRes, chatMessageDraftRes]) => ({
                    chat: chatRes.chat,
                    messages: messagesRes.messages,
                    chatMessageDraft: chatMessageDraftRes.chatMessageDraft,
                    hasAccessToThreadContent: messagesRes.hasAccess
                })
            );
        }),
    subscribeForChatWithMessagesAndAttachments: (
        chatId: types.ChatId,
        silentThreadContentFailure = false
    ) =>
        subscriptionRegistry.create(`chatWithMessagesAndAttachments-${chatId}`, () => {
            return new MergedSubscription(
                [
                    subscriptionService.subscribeForChat(chatId),
                    subscriptionService.subscribeForThreadMessages(
                        chatId,
                        silentThreadContentFailure
                    ),
                    subscriptionService.subscribeForChatMessageDraft(chatId),
                    subscriptionService.subscribeForThreadAttachments(
                        chatId,
                        silentThreadContentFailure
                    )
                ],
                ([chatRes, messagesRes, chatMessageDraftRes, attachmentsRes]) => ({
                    chat: chatRes.chat,
                    messages: messagesRes.messages,
                    chatMessageDraft: chatMessageDraftRes.chatMessageDraft,
                    attachments: attachmentsRes.attachments,
                    hasAccessToThreadContent: messagesRes.hasAccess
                })
            );
        }),
    subscribeForChats: () =>
        subscriptionRegistry.create(`chats`, () => {
            const subscription = new SingleSubscription(async () => {
                const chats = await api.getChats();
                return { chats };
            });
            subscription.bindEvent<types.ChatUpdatedEvent>(api, 'chatupdated', (event) => {
                subscription.modifyState((data) => {
                    return { chats: upsert(data.chats, event.chat, (x) => x.id) };
                });
            });
            subscription.bindEvent<types.ChatVerifiedStatusChangedEvent>(
                api,
                'chatverifiedstatuschanged',
                (event) => {
                    subscription.modifyState((data) => {
                        return { chats: upsert(data.chats, event.chat, (x) => x.id) };
                    });
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'thread') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            chats: data.chats.map((x) =>
                                event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForUserChats: (user: types.Username) =>
        subscriptionRegistry.create(`userChats-${user}`, () => {
            const subscription = new SingleSubscription(async () => {
                const chats = await api.getChatsOfUser(user);
                return { chats };
            });
            subscription.bindEvent<types.ChatUpdatedEvent>(api, 'chatupdated', (event) => {
                if (!event.chat.users.find((x) => x === user)) return;

                subscription.modifyState((data) => {
                    return { chats: upsert(data.chats, event.chat, (x) => x.id) };
                });
            });
            subscription.bindEvent<types.ChatVerifiedStatusChangedEvent>(
                api,
                'chatverifiedstatuschanged',
                (event) => {
                    if (!event.chat.users.find((x) => x === user)) return;

                    subscription.modifyState((data) => {
                        return { chats: upsert(data.chats, event.chat, (x) => x.id) };
                    });
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'thread') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            chats: data.chats.map((x) =>
                                event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForCompanyChats: (comapnyId: types.CompanyId) =>
        subscriptionRegistry.create(`companyChats-${comapnyId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const chats = await api.getChatsOfComapny(comapnyId);
                return { chats };
            });
            subscription.bindEvent<types.ChatUpdatedEvent>(api, 'chatupdated', (event) => {
                subscription.modifyState((data) => {
                    return { chats: upsert(data.chats, event.chat, (x) => x.id) };
                });
            });
            subscription.bindEvent<types.ChatVerifiedStatusChangedEvent>(
                api,
                'chatverifiedstatuschanged',
                (event) => {
                    subscription.modifyState((data) => {
                        return { chats: upsert(data.chats, event.chat, (x) => x.id) };
                    });
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'thread') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            chats: data.chats.map((x) =>
                                event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForUserAttachments: (user: types.Username) =>
        subscriptionRegistry.create(`userAttachments-${user}`, () => {
            const subscription = new SingleSubscription(async () => {
                const attachments = await api.getAttachmentsOfUser(user);
                return { attachments };
            });
            subscription.bindEvent<types.NewThreadAttachmentsEvent>(
                api,
                'newthreadattachments',
                (event) => {
                    const thread = selectCachedThread(event.threadId as types.ChatId)(
                        store.getState()
                    );
                    if (!thread || !thread.users.includes(user)) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            attachments: upsertMany(
                                data.attachments,
                                event.attachments,
                                (x) => x.group
                            )
                        };
                    });
                }
            );
            subscription.bindEvent<types.DeletedThreadAttachmentsEvent>(
                api,
                'deletedthreadattachments',
                (event) => {
                    const thread = selectCachedThread(event.threadId as types.ChatId)(
                        store.getState()
                    );
                    if (!thread || !thread.users.includes(user)) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            attachments: removeMany(
                                data.attachments,
                                event.attachmentIds,
                                (x) => x.id
                            )
                        };
                    });
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'threadAttachment') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            attachments: data.attachments.map((x) =>
                                event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForUserForms: (user: types.Username) =>
        subscriptionRegistry.create(`userForm-${user}`, () => {
            const subscription = new SingleSubscription(async () => {
                const forms = await api.getUserForms(user);
                return { forms };
            });
            subscription.bindEvent<types.FormChangedEvent>(api, 'formchanged', async (event) => {
                if (event.form.type !== 'inquiry') {
                    return;
                }
                const form: types.FormModel2 = {
                    ...event.form,
                    main: event.isMain,
                    published: event.form.status === 'published'
                };
                subscription.modifyState((data) => ({
                    forms: update(data.forms, form, (x) => x.id)
                }));
            });
            subscription.bindEvent<types.NewFormSubmitResponseEvent>(
                api,
                'newformsubmitresponse',
                async (event) => {
                    if (event.response.creator !== user) {
                        return;
                    }
                    const form = selectCachedForm(event.formId)(store.getState());
                    if (!form) {
                        return;
                    }
                    subscription.modifyState((data) => ({
                        forms: upsert(data.forms, form, (x) => x.id)
                    }));
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'inquiry') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            forms: data.forms.map((x) =>
                                event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),

    subscribeForUserData: (user: types.Username) =>
        subscriptionRegistry.create(`userData-${user}`, () => {
            const subscription = new MergedSubscription(
                [
                    subscriptionService.subscribeForUserChats(user),
                    subscriptionService.subscribeForUserAttachments(user),
                    subscriptionService.subscribeForUserMeetings(user),
                    subscriptionService.subscribeForUserForms(user)
                ],
                ([chatRes, attachmentsRes, meetings, formsRes]) => ({
                    chats: chatRes.chats,
                    attachments: attachmentsRes.attachments,
                    meetings: meetings.meetings,
                    forms: formsRes.forms
                })
            );
            return subscription;
        }),
    subscribeForCompanyAttachments: (companyId: types.CompanyId) =>
        subscriptionRegistry.create(`companyAttachments-${companyId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const attachments = await api.getAttachmentsOfCompany(companyId);
                return { attachments };
            });
            subscription.bindEvent<types.NewThreadAttachmentsEvent>(
                api,
                'newthreadattachments',
                (event) => {
                    const users = api
                        .getContactsSync()
                        .filter((x) => x.username && x.companyId === companyId)
                        .map((x) => x.username!);
                    const thread = selectCachedThread(event.threadId as types.ChatId)(
                        store.getState()
                    );
                    if (!thread || !thread.users.some((x) => users.includes(x))) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            attachments: upsertMany(
                                data.attachments,
                                event.attachments,
                                (x) => x.group
                            )
                        };
                    });
                }
            );
            subscription.bindEvent<types.DeletedThreadAttachmentsEvent>(
                api,
                'deletedthreadattachments',
                (event) => {
                    const users = api
                        .getContactsSync()
                        .filter((x) => x.username && x.companyId === companyId)
                        .map((x) => x.username!);
                    const thread = selectCachedThread(event.threadId as types.ChatId)(
                        store.getState()
                    );
                    if (!thread || !thread.users.some((x) => users.includes(x))) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            attachments: removeMany(
                                data.attachments,
                                event.attachmentIds,
                                (x) => x.id
                            )
                        };
                    });
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'threadAttachment') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            attachments: data.attachments.map((x) =>
                                event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForCompanyUsers: (companyId: types.CompanyId) =>
        subscriptionRegistry.create(`companyUsers-${companyId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const contacts = await api.getContactsOfCompany(companyId);
                return { contacts };
            });
            subscription.bindEvent<types.NewContactEvent>(api, 'newcontact', (event) => {
                if (event.contact.companyId !== companyId) {
                    return;
                }
                subscription.modifyState((data) => {
                    return { contacts: upsert(data.contacts, event.contact, (x) => x.id) };
                });
            });
            subscription.bindEvent<types.ContactChangedEvent>(api, 'contactchanged', (event) => {
                if (event.contact.companyId !== companyId) {
                    return;
                }
                subscription.modifyState((data) => {
                    return { contacts: upsert(data.contacts, event.contact, (x) => x.id) };
                });
            });
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'contact') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            contacts: data.contacts.map((x) =>
                                event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForCompanyForms: (companyId: types.CompanyId) =>
        subscriptionRegistry.create(`companyForm-${companyId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const forms = await api.getCompanyForms(companyId);
                return { forms };
            });
            subscription.bindEvent<types.FormChangedEvent>(api, 'formchanged', async (event) => {
                if (event.form.type !== 'inquiry') {
                    return;
                }
                const form: types.FormModel2 = {
                    ...event.form,
                    main: event.isMain,
                    published: event.form.status === 'published'
                };
                subscription.modifyState((data) => ({
                    forms: update(data.forms, form, (x) => x.id)
                }));
            });
            subscription.bindEvent<types.NewFormSubmitResponseEvent>(
                api,
                'newformsubmitresponse',
                async (event) => {
                    const users = api
                        .getContactsSync()
                        .filter((x) => x.username && x.companyId === companyId)
                        .map((x) => x.username!);
                    if (!users.includes(event.response.creator)) {
                        return;
                    }
                    const form = selectCachedForm(event.formId)(store.getState());
                    if (!form) {
                        return;
                    }
                    subscription.modifyState((data) => ({
                        forms: upsert(data.forms, form, (x) => x.id)
                    }));
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'inquiry') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            forms: data.forms.map((x) =>
                                event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForCompanyData: (companyId: types.CompanyId) =>
        subscriptionRegistry.create(`companyData-${companyId}`, () => {
            const subscription = new MergedSubscription(
                [
                    subscriptionService.subscribeForCompanyChats(companyId),
                    subscriptionService.subscribeForCompanyAttachments(companyId),
                    subscriptionService.subscribeForCompanyMeetings(companyId),
                    subscriptionService.subscribeForCompanyForms(companyId),
                    subscriptionService.subscribeForCompanyUsers(companyId)
                ],
                ([chatRes, attachmentsRes, meetings, formsRes, usersRes]) => ({
                    chats: chatRes.chats,
                    attachments: attachmentsRes.attachments,
                    meetings: meetings.meetings,
                    forms: formsRes.forms,
                    users: usersRes.contacts
                })
            );
            return subscription;
        }),
    subscribeForMeeting: (meetingId: types.MeetingId) =>
        subscriptionRegistry.create(`meeting-${meetingId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const meeting = await api.getMeeting(meetingId);
                if (!meeting) {
                    throw new Error('Meeting not found');
                }
                return { meeting };
            });
            subscription.bindEvent<types.MeetingUpdatedEvent>(api, 'meetingupdated', (event) => {
                if (event.meeting.id !== meetingId) {
                    return;
                }
                subscription.modifyState((data) => {
                    return {
                        meeting: event.meeting
                    };
                });
            });
            subscription.bindEvent<types.MeetingVerifiedStatusChangedEvent>(
                api,
                'meetingverifiedstatuschanged',
                (event) => {
                    if (event.meeting.id !== meetingId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            meeting: event.meeting
                        };
                    });
                }
            );
            subscription.bindEvent<types.ThreadMeetingLobbyChangeEvent>(
                api,
                'threadlobbychange',
                (event) => {
                    if (event.threadId !== meetingId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            meeting: {
                                ...data.meeting,
                                meetingState: {
                                    ...data.meeting.meetingState,
                                    lobbyUsers: event.lobbyUsers
                                }
                            }
                        };
                    });
                }
            );
            subscription.bindEvent<types.ThreadMeetingMeetingChangeEvent>(
                api,
                'threadmeetingchange',
                (event) => {
                    if (event.threadId !== meetingId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            meeting: {
                                ...data.meeting,
                                meetingState: {
                                    ...data.meeting.meetingState,
                                    anonymousUsers: event.anonymousUsers,
                                    regularUsers: event.regularUsers
                                }
                            }
                        };
                    });
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'thread' || event.targetId !== meetingId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            meeting: { ...data.meeting, ...event.taggableEntity }
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForThreadMeetingLobby: (threadId: PmxApi.api.thread.ThreadId) =>
        subscriptionRegistry.create(`threadMeetingLobby-${threadId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const lobby = await api.getThreadMeetingLobby(threadId);
                return { lobby };
            });
            subscription.bindEvent<types.ThreadMeetingLobbyChangeEvent>(
                api,
                'threadlobbychange',
                (event) => {
                    if (event.threadId !== threadId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            lobby: { ...data.lobby, lobbyUsers: event.lobbyUsers }
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForThreadMeetingWithLobby: (threadId: PmxApi.api.thread.ThreadId) =>
        subscriptionRegistry.create(`threadMeetingWithLobby-${threadId}`, () => {
            return new MergedSubscription(
                [
                    subscriptionService.subscribeForMeeting(threadId as types.MeetingId),
                    subscriptionService.subscribeForThreadMeetingLobby(threadId)
                ],
                ([meetingRes, lobbyRes]) => ({
                    meeting: meetingRes.meeting,
                    lobby: lobbyRes.lobby
                })
            );
        }),
    subscribeForMeetingMessageDraft: (meetingId: types.MeetingId) =>
        subscriptionRegistry.create(`meetingMessageDraft-${meetingId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const meetingMessageDraft = await api.getThreadMessageDraft(meetingId);
                if (
                    meetingMessageDraft &&
                    meetingMessageDraft.message.messageThreadType !== 'chat'
                ) {
                    throw new Error('Invalid draft message type');
                }
                return { meetingMessageDraft };
            });
            subscription.bindEvent<types.NewDraftEvent>(api, 'newdraft', (event) => {
                const draft = event.draft;
                if (draft.type !== 'threadMessageDraft' || draft.threadId !== meetingId) {
                    return;
                }
                subscription.modifyState((data) => {
                    return {
                        meetingMessageDraft: draft
                    };
                });
            });
            subscription.bindEvent<types.DraftChangedEvent>(api, 'draftchanged', (event) => {
                const draft = event.draft;
                if (draft.type !== 'threadMessageDraft' || draft.threadId !== meetingId) {
                    return;
                }
                subscription.modifyState((data) => {
                    return {
                        meetingMessageDraft: draft
                    };
                });
            });
            subscription.bindEvent<types.DraftDeletedEvent>(api, 'draftdeleted', (event) => {
                const draftId = event.draftId;
                subscription.modifyState((data) => {
                    if (data.meetingMessageDraft?.id !== draftId) {
                        return data;
                    }
                    return {
                        meetingMessageDraft: undefined
                    };
                });
            });
            return subscription;
        }),
    subscribeForMeetingWithMessages: (
        meetingId: types.MeetingId,
        silentThreadContentFailure = false
    ) =>
        subscriptionRegistry.create(`meetingWithMessages-${meetingId}`, () => {
            return new MergedSubscription(
                [
                    subscriptionService.subscribeForMeeting(meetingId),
                    subscriptionService.subscribeForThreadMessages(
                        meetingId,
                        silentThreadContentFailure
                    ),
                    subscriptionService.subscribeForMeetingMessageDraft(meetingId)
                ],
                ([meetingRes, messagesRes, meetingMessageDraftRes]) => ({
                    meeting: meetingRes.meeting,
                    messages: messagesRes.messages,
                    meetingMessageDraft: meetingMessageDraftRes.meetingMessageDraft,
                    hasAccessToThreadContent: messagesRes.hasAccess
                })
            );
        }),
    subscribeForMeetingWithMessagesAndAttachments: (
        meetingId: types.MeetingId,
        silentThreadContentFailure = false
    ) =>
        subscriptionRegistry.create(`meetingWithMessagesAndAttachments-${meetingId}`, () => {
            return new MergedSubscription(
                [
                    subscriptionService.subscribeForMeeting(meetingId),
                    subscriptionService.subscribeForThreadMessages(
                        meetingId,
                        silentThreadContentFailure
                    ),
                    subscriptionService.subscribeForMeetingMessageDraft(meetingId),
                    subscriptionService.subscribeForThreadAttachments(
                        meetingId,
                        silentThreadContentFailure
                    )
                ],
                ([meetingRes, messagesRes, meetingMessageDraftRes, attachmentsRes]) => ({
                    meeting: meetingRes.meeting,
                    messages: messagesRes.messages,
                    meetingMessageDraft: meetingMessageDraftRes.meetingMessageDraft,
                    attachments: attachmentsRes.attachments,
                    hasAccessToThreadContent: messagesRes.hasAccess
                })
            );
        }),
    subscribeForMeetings: () =>
        subscriptionRegistry.create(`meetings`, () => {
            const subscription = new SingleSubscription(async () => {
                const meetings = await api.getMeetings();
                return { meetings };
            });
            subscription.bindEvent<types.MeetingUpdatedEvent>(api, 'meetingupdated', (event) => {
                subscription.modifyState((data) => {
                    return { meetings: upsert(data.meetings, event.meeting, (x) => x.id) };
                });
            });
            subscription.bindEvent<types.MeetingVerifiedStatusChangedEvent>(
                api,
                'meetingverifiedstatuschanged',
                (event) => {
                    subscription.modifyState((data) => {
                        return { meetings: upsert(data.meetings, event.meeting, (x) => x.id) };
                    });
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'thread') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            meetings: data.meetings.map((x) =>
                                event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForUserMeetings: (user: types.Username) =>
        subscriptionRegistry.create(`userMeetings-${user}`, () => {
            const subscription = new SingleSubscription(async () => {
                const meetings = await api.getMeetingsOfUser(user);
                return { meetings };
            });
            subscription.bindEvent<types.MeetingUpdatedEvent>(api, 'meetingupdated', (event) => {
                subscription.modifyState((data) => {
                    return { meetings: upsert(data.meetings, event.meeting, (x) => x.id) };
                });
            });
            subscription.bindEvent<types.MeetingVerifiedStatusChangedEvent>(
                api,
                'meetingverifiedstatuschanged',
                (event) => {
                    subscription.modifyState((data) => {
                        return { meetings: upsert(data.meetings, event.meeting, (x) => x.id) };
                    });
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'thread') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            meetings: data.meetings.map((x) =>
                                event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForCompanyMeetings: (comapnyId: types.CompanyId) =>
        subscriptionRegistry.create(`companyMeetings-${comapnyId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const meetings = await api.getMeetingsOfComapny(comapnyId);
                return { meetings };
            });
            subscription.bindEvent<types.MeetingUpdatedEvent>(api, 'meetingupdated', (event) => {
                subscription.modifyState((data) => {
                    return { meetings: upsert(data.meetings, event.meeting, (x) => x.id) };
                });
            });
            subscription.bindEvent<types.MeetingVerifiedStatusChangedEvent>(
                api,
                'meetingverifiedstatuschanged',
                (event) => {
                    subscription.modifyState((data) => {
                        return { meetings: upsert(data.meetings, event.meeting, (x) => x.id) };
                    });
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'thread') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            meetings: data.meetings.map((x) =>
                                event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForThreadMessages: (threadId: PmxApi.api.thread.ThreadId, silentFailure = false) =>
        subscriptionRegistry.create(`threadMessages-${threadId}`, () => {
            const subscription = new SingleSubscription(async () => {
                try {
                    const messages = await api.getThreadMessages(threadId);
                    return { messages, hasAccess: true };
                } catch (err: any) {
                    if (!silentFailure || err.msg !== 'Access denied') {
                        throw err;
                    }
                    return { messages: [], hasAccess: false };
                }
            });
            subscription.bindEvent<types.NewChatMessageEvent>(api, 'newchatmessage', (event) => {
                if (event.threadId !== threadId) {
                    return;
                }
                subscription.modifyState((data) => {
                    return {
                        messages: upsert(data.messages, event.message, (x) => x.id),
                        hasAccess: data.hasAccess
                    };
                });
            });
            subscription.bindEvent<types.MessageVerifiedStatusChangedEvent>(
                api,
                'messageverifiedstatuschanged',
                (event) => {
                    if (event.threadId !== threadId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        subscription.setState({
                            type: 'done',
                            data: {
                                messages: upsert(data.messages, event.message, (x) => x.id),
                                hasAccess: data.hasAccess
                            }
                        });
                    });
                }
            );
            subscription.bindEvent<types.FavoritesUpdatedEvent>(
                api,
                'favoritesupdated',
                (event) => {
                    subscription.modifyState((data) => {
                        const updatedMessages: types.Message[] = [];
                        for (const msg of data.messages) {
                            const isFavorite = event.favorites.some(
                                (fav) => fav.messageId === msg.id
                            );
                            if (isFavorite !== msg.favorite) {
                                updatedMessages.push({ ...msg, favorite: isFavorite });
                            }
                        }
                        if (updatedMessages.length > 0) {
                            subscription.setState({
                                type: 'done',
                                data: {
                                    messages: upsertMany(
                                        data.messages,
                                        updatedMessages,
                                        (x) => x.id
                                    ),
                                    hasAccess: data.hasAccess
                                }
                            });
                        }
                    });
                }
            );
            return subscription;
        }),
    subscribeForMessagesWithChats: (messageIds: types.MessageId[]) =>
        subscriptionRegistry.create(`messagesWithChats-${messageIds.sort().join(',')}`, () => {
            const subscription = new SingleSubscription(() => api.getMessagesWithChats(messageIds));
            subscription.bindEvent<types.NewChatMessageEvent>(api, 'newchatmessage', (event) => {
                if (!messageIds.includes(event.message.id)) {
                    return;
                }
                subscription.modifyState((data) => {
                    return {
                        messages: upsert(data.messages, event.message, (x) => x.id),
                        chats: data.chats
                    };
                });
            });
            subscription.bindEvent<types.MessageVerifiedStatusChangedEvent>(
                api,
                'messageverifiedstatuschanged',
                (event) => {
                    if (!messageIds.includes(event.message.id)) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        subscription.setState({
                            type: 'done',
                            data: {
                                messages: upsert(data.messages, event.message, (x) => x.id),
                                chats: data.chats
                            }
                        });
                    });
                }
            );
            subscription.bindEvent<types.ChatUpdatedEvent>(api, 'chatupdated', (event) => {
                subscription.modifyState((data) => {
                    return {
                        messages: data.messages,
                        chats: upsert(data.chats, event.chat, (x) => x.id)
                    };
                });
            });
            subscription.bindEvent<types.ChatVerifiedStatusChangedEvent>(
                api,
                'chatverifiedstatuschanged',
                (event) => {
                    subscription.modifyState((data) => {
                        return {
                            messages: data.messages,
                            chats: upsert(data.chats, event.chat, (x) => x.id)
                        };
                    });
                }
            );
            subscription.bindEvent<types.MeetingUpdatedEvent>(api, 'meetingupdated', (event) => {
                subscription.modifyState((data) => {
                    return {
                        messages: data.messages,
                        chats: upsert(
                            data.chats,
                            event.meeting as unknown as types.Chat,
                            (x) => x.id
                        )
                    };
                });
            });
            subscription.bindEvent<types.MeetingVerifiedStatusChangedEvent>(
                api,
                'meetingverifiedstatuschanged',
                (event) => {
                    subscription.modifyState((data) => {
                        return {
                            messages: data.messages,
                            chats: upsert(
                                data.chats,
                                event.meeting as unknown as types.Chat,
                                (x) => x.id
                            )
                        };
                    });
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'thread') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            messages: data.messages,
                            chats: data.chats.map((x) =>
                                event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForThreadUsersWithAccessibleTimeRanges: (threadId: types.ThreadId) =>
        subscriptionRegistry.create(`threadUsersWithAccessibleTimeRanges-${threadId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const users = await api.getThreadUsersWithAccessibleTimeRanges(threadId);
                if (!users) {
                    throw new Error('Thread not found');
                }
                return { users };
            });
            subscription.bindEvent<types.ThreadUsersWithAccessibleTimeRangesUpdatedEvent>(
                api,
                'threaduserswithaccessibletimerangesupdatedevent',
                (event) => {
                    if (event.threadId !== threadId) {
                        return;
                    }
                    subscription.modifyState(() => {
                        return {
                            users: event.users
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForThreadAttachments: (threadId: PmxApi.api.thread.ThreadId, silentFailure = false) =>
        subscriptionRegistry.create(`threadAttachments-${threadId}`, () => {
            const subscription = new SingleSubscription(async () => {
                try {
                    const attachments = await api.getThreadAttachments(threadId);
                    return { attachments, hasAccess: true };
                } catch (err: any) {
                    if (!silentFailure || err.msg !== 'Access denied') {
                        throw err;
                    }
                    return { attachments: [], hasAccess: false };
                }
            });
            subscription.bindEvent<types.NewThreadAttachmentsEvent>(
                api,
                'newthreadattachments',
                (event) => {
                    if (event.threadId !== threadId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            attachments: upsertMany(
                                data.attachments,
                                event.attachments,
                                (x) => x.group
                            ),
                            hasAccess: data.hasAccess
                        };
                    });
                }
            );
            subscription.bindEvent<types.DeletedThreadAttachmentsEvent>(
                api,
                'deletedthreadattachments',
                (event) => {
                    if (event.threadId !== threadId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            attachments: removeMany(
                                data.attachments,
                                event.attachmentIds,
                                (x) => x.id
                            ),
                            hasAccess: data.hasAccess
                        };
                    });
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'threadAttachment') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            hasAccess: data.hasAccess,
                            attachments: data.attachments.map((x) =>
                                event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForFiles: () =>
        subscriptionRegistry.create(`files`, () => {
            const subscription = new SingleSubscription(async () => {
                const files = await api.getFiles();
                return { files };
            });
            subscription.bindEvent<types.NewThreadAttachmentsEvent>(
                api,
                'newthreadattachments',
                (event) => {
                    subscription.modifyState((data) => {
                        return {
                            files: upsertMany(data.files, event.attachments, (x) => x.group)
                        };
                    });
                }
            );
            subscription.bindEvent<types.DeletedThreadAttachmentsEvent>(
                api,
                'deletedthreadattachments',
                (event) => {
                    subscription.modifyState((data) => {
                        return {
                            files: removeMany(data.files, event.attachmentIds, (x) => x.id)
                        };
                    });
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'threadAttachment') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            files: data.files.map((x) =>
                                event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForFilesWithThreads: () =>
        subscriptionRegistry.create(`filesWithThreads`, () => {
            return new MergedSubscription(
                [
                    subscriptionService.subscribeForFiles(),
                    subscriptionService.subscribeForChats(),
                    subscriptionService.subscribeForMeetings()
                ],
                ([filesRes, chatsRes, meetingsRes]) => ({
                    files: filesRes.files,
                    chats: chatsRes.chats,
                    meetings: meetingsRes.meetings
                })
            );
        }),
    subscribeForDraft: (draftId: types.DraftId) =>
        subscriptionRegistry.create(`draft-${draftId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const draft = await api.getDraftWithAttachments(draftId);
                return { draft };
            });
            subscription.bindEvent<types.DraftChangedEvent>(api, 'draftchanged', (event) => {
                if (event.draft.id !== draftId) {
                    return;
                }
                subscription.modifyState((data) => {
                    return {
                        draft: event.draft
                    };
                });
            });
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'draft' || event.targetId !== draftId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        if (!data.draft) {
                            return data;
                        }
                        const draft = { ...data.draft };
                        if (draft.type === 'threadDraft') {
                            draft.thread = {
                                ...draft.thread,
                                ...event.taggableEntity
                            };
                        }
                        return {
                            draft: draft
                        };
                    });
                }
            );
            subscription.bindEvent<types.DraftDeletedEvent>(api, 'draftdeleted', (event) => {
                const draftId = event.draftId;
                subscription.modifyState((data) => {
                    if (data.draft?.id !== draftId) {
                        return data;
                    }
                    return {
                        draft: undefined
                    };
                });
            });
            return subscription;
        }),
    subscribeForDrafts: () =>
        subscriptionRegistry.create(`drafts`, () => {
            const subscription = new SingleSubscription(async () => {
                const drafts = await api.getDrafts();
                return { drafts };
            });
            subscription.bindEvent<types.NewDraftEvent>(api, 'newdraft', (event) => {
                subscription.modifyState((data) => {
                    if (!data.drafts) {
                        return data;
                    }
                    return {
                        drafts: upsert(data.drafts, event.draft, (x) => x.id)
                    };
                });
            });
            subscription.bindEvent<types.DraftChangedEvent>(api, 'draftchanged', (event) => {
                subscription.modifyState((data) => {
                    if (!data.drafts) {
                        return data;
                    }
                    return {
                        drafts: upsert(data.drafts, event.draft, (x) => x.id)
                    };
                });
            });
            subscription.bindEvent<types.DraftDeletedEvent>(api, 'draftdeleted', (event) => {
                const draftId = event.draftId;
                subscription.modifyState((data) => ({
                    drafts: data.drafts?.filter((x) => x.id !== draftId)
                }));
            });
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'draft') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        const drafts = data.drafts?.map((draft) => {
                            const newDraft = { ...draft };
                            if (newDraft.type === 'threadDraft') {
                                newDraft.thread = {
                                    ...newDraft.thread,
                                    ...event.taggableEntity
                                };
                            }
                            return newDraft;
                        });
                        return {
                            drafts: drafts
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForCompany: (companyId: types.CompanyId) =>
        subscriptionRegistry.create(`company-${companyId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const company = await api.getCompany(companyId);
                if (!company) {
                    throw new Error('Company not found');
                }
                return { company };
            });
            subscription.bindEvent<types.CompanyChangedEvent>(
                api,
                'companychanged',
                async (event) => {
                    if (event.company.id !== companyId) {
                        return;
                    }
                    subscription.modifyState(() => ({ company: event.company }));
                }
            );
            subscription.bindEvent<types.NewCompanyEvent>(api, 'newcompany', async (event) => {
                if (event.company.id !== companyId) {
                    return;
                }
                subscription.modifyState(() => ({ company: event.company }));
            });
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'company' || event.targetId !== companyId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            company: { ...data.company, ...event.taggableEntity }
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForCompanies: () =>
        subscriptionRegistry.create(`companies`, () => {
            const subscription = new SingleSubscription(async () => api.getCompanies());
            subscription.bindEvent<types.CompanyChangedEvent>(
                api,
                'companychanged',
                async (event) => {
                    subscription.modifyState((data) => {
                        return upsert(data, event.company, (x) => x.id);
                    });
                }
            );
            subscription.bindEvent<types.NewCompanyEvent>(api, 'newcompany', async (event) => {
                subscription.modifyState((data) => {
                    return upsert(data, event.company, (x) => x.id);
                });
            });
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'company') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return data.map((x) =>
                            event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                        );
                    });
                }
            );
            return subscription;
        }),
    subscribeForCompaniesWithContacts: () =>
        subscriptionRegistry.create(`companies-with-contacts`, () => {
            return new MergedSubscription(
                [
                    subscriptionService.subscribeForCompanies(),
                    subscriptionService.subscribeForContacts()
                ],
                ([companies, contacts]) => ({
                    contacts: contacts,
                    companies: companies.map((x) => {
                        const res: types.CompanyWithContacts = {
                            ...x,
                            contacts: contacts.filter((c) => c.companyId === x.id)
                        };
                        return res;
                    })
                })
            );
        }),
    subscribeForContact: (contactId: types.ContactId) =>
        subscriptionRegistry.create(`contact-${contactId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const contact = await api.getContact(contactId);
                if (!contact) {
                    throw new Error('Contact not found');
                }
                return { contact };
            });
            subscription.bindEvent<types.ContactChangedEvent>(
                api,
                'contactchanged',
                async (event) => {
                    if (event.contact.id !== contactId) {
                        return;
                    }
                    subscription.modifyState(() => ({ contact: event.contact }));
                }
            );
            subscription.bindEvent<types.NewContactEvent>(api, 'newcontact', async (event) => {
                if (event.contact.id !== contactId) {
                    return;
                }
                subscription.modifyState(() => ({ contact: event.contact }));
            });
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'contact' || event.targetId !== contactId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            contact: { ...data.contact, ...event.taggableEntity }
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForContacts: () =>
        subscriptionRegistry.create(`contacts`, () => {
            const subscription = new SingleSubscription(() => api.getContacts());
            subscription.bindEvent<types.ContactChangedEvent>(
                api,
                'contactchanged',
                async (event) => {
                    subscription.modifyState((data) => {
                        return upsert(data, event.contact, (x) => x.id);
                    });
                }
            );
            subscription.bindEvent<types.NewContactEvent>(api, 'newcontact', async (event) => {
                subscription.modifyState((data) => {
                    return upsert(data, event.contact, (x) => x.id);
                });
            });
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'contact') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return data.map((x) =>
                            event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                        );
                    });
                }
            );
            return subscription;
        }),
    subscribeForItemCountsGrouppedByUsers: () =>
        subscriptionRegistry.create(`itemCountGrouppedByUsers`, () => {
            const subscription = new SingleSubscription(() => api.getItemCountsGrouppedByUsers());
            subscription.bindEvent<types.ItemCountsGrouppedByUsersChangedEvent>(
                api,
                'itemcountsgrouppedbyuserschanged',
                (event) => {
                    subscription.modifyState((data) => {
                        return event.itemCountsGrouppedByUser;
                    });
                }
            );
            return subscription;
        }),
    subscribeForContactsWithItemCounts: () =>
        subscriptionRegistry.create(`contactsWithItemCounts`, () => {
            return new MergedSubscription(
                [
                    subscriptionService.subscribeForContacts(),
                    subscriptionService.subscribeForItemCountsGrouppedByUsers()
                ],
                ([contacts, itemCountsGrouppedByUser]) => ({
                    contacts,
                    itemCountsGrouppedByUser
                })
            );
        }),
    subscribeForContactWithCompany: (contactId: types.ContactId) =>
        subscriptionRegistry.create(`contactWithCompany-${contactId}`, () => {
            return new MergedSubscription(
                [
                    subscriptionService.subscribeForContact(contactId),
                    subscriptionService.subscribeForCompanies()
                ],
                ([contactRes, companiesRes]) => ({
                    contact: contactRes.contact,
                    company: companiesRes.find((x) => x.id === contactRes.contact.companyId)
                })
            );
        }),
    subscribeForForm: (formId: types.FormId) =>
        subscriptionRegistry.create(`form-${formId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const form = await api.getForm(formId);
                if (!form) {
                    throw new Error('Form not found');
                }
                return { form };
            });
            subscription.bindEvent<types.FormChangedEvent>(api, 'formchanged', async (event) => {
                if (event.form.id !== formId) {
                    return;
                }
                subscription.modifyState(() => ({ form: event.form }));
            });
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'inquiry' || event.targetId !== formId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            form: { ...data.form, ...event.taggableEntity }
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForFormWithEntries: (formId: types.FormId) =>
        subscriptionRegistry.create(`formWithEntries-${formId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const formEntry = await api.getFormEntry(formId);
                if (!formEntry) {
                    throw new Error('Form not found');
                }
                return formEntry;
            });
            subscription.bindEvent<types.FormChangedEvent>(api, 'formchanged', async (event) => {
                if (event.form.id !== formId) {
                    return;
                }
                subscription.modifyState((data) => ({
                    ...event.form,
                    main: event.isMain,
                    answers: data.answers
                }));
            });
            subscription.bindEvent<types.NewFormSubmitEvent>(
                api,
                'newformsubmit',
                async (event) => {
                    if (event.formId !== formId) {
                        return;
                    }
                    subscription.modifyState((data) => ({
                        ...data,
                        answers: upsert(data.answers, event.submit, (x) => x.id)
                    }));
                }
            );
            subscription.bindEvent<types.FormSubmitChangedEvent>(
                api,
                'formsubmitchanged',
                async (event) => {
                    if (event.formId !== formId) {
                        return;
                    }
                    subscription.modifyState((data) => ({
                        ...data,
                        answers: upsert(data.answers, event.submit, (x) => x.id)
                    }));
                }
            );
            subscription.bindEvent<types.FormSubmitDeletedEvent>(
                api,
                'formsubmitdeleted',
                async (event) => {
                    if (event.formId !== formId) {
                        return;
                    }
                    subscription.modifyState((data) => ({
                        ...data,
                        answers: data.answers.filter((x) => x.id !== event.formSubmitId)
                    }));
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'inquiry' || event.targetId !== formId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            ...data,
                            ...event.taggableEntity
                        };
                    });
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'inquirySubmit') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        const newData = { ...data };
                        newData.answers = newData.answers.map((x) => ({
                            ...x,
                            tags: x.id === event.targetId ? event.tags : x.tags
                        }));
                        return {
                            ...data,
                            ...event.taggableEntity
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForFormWithEntriesAndContacts: (formId: types.FormId) =>
        subscriptionRegistry.create(`formWithEntriesAndContacts-${formId}`, () => {
            return new MergedSubscription(
                [
                    subscriptionService.subscribeForFormWithEntries(formId),
                    subscriptionService.subscribeForContacts()
                ],
                ([formRes, contactsRes]) => ({
                    form: formRes,
                    contacts: contactsRes
                })
            );
        }),
    subscribeForForms: () =>
        subscriptionRegistry.create(`forms`, () => {
            const subscription = new SingleSubscription(() => api.getFormList());
            subscription.bindEvent<types.NewFormEvent>(api, 'newform', async (event) => {
                if (event.form.type !== 'inquiry') {
                    return;
                }
                const form: types.FormModel2 = {
                    ...event.form,
                    main: event.isMain,
                    published: event.form.status === 'published'
                };
                subscription.modifyState((data) => upsert(data, form, (x) => x.id));
            });
            subscription.bindEvent<types.FormChangedEvent>(api, 'formchanged', async (event) => {
                if (event.form.type !== 'inquiry') {
                    return;
                }
                const form: types.FormModel2 = {
                    ...event.form,
                    main: event.isMain,
                    published: event.form.status === 'published'
                };
                subscription.modifyState((data) => upsert(data, form, (x) => x.id));
            });
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'inquiry') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return data.map((x) =>
                            event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                        );
                    });
                }
            );
            return subscription;
        }),
    subscribeForEmailInboxes: () =>
        subscriptionRegistry.create(`emailInboxes`, () => {
            const subscription = new SingleSubscription(() => api.getEmailInboxesList());
            subscription.bindEvent<types.NewFormEvent>(api, 'newform', async (event) => {
                if (event.form.type !== 'emailInbox') {
                    return;
                }
                const form: types.FormModel2 = {
                    ...event.form,
                    main: event.isMain,
                    published: event.form.status === 'published'
                };
                subscription.modifyState((data) => upsert(data, form, (x) => x.id));
            });
            subscription.bindEvent<types.FormChangedEvent>(api, 'formchanged', async (event) => {
                if (event.form.type !== 'emailInbox') {
                    return;
                }
                const form: types.FormModel2 = {
                    ...event.form,
                    main: event.isMain,
                    published: event.form.status === 'published'
                };
                subscription.modifyState((data) => upsert(data, form, (x) => x.id));
            });
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'inquiry') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return data.map((x) =>
                            event.targetId === x.id ? { ...x, ...event.taggableEntity } : x
                        );
                    });
                }
            );
            return subscription;
        }),
    subscribeForFormRow: (formRowId: types.FormRowId) =>
        subscriptionRegistry.create(`formRow-${formRowId}`, () => {
            const subscription = new SingleSubscription(async () => {
                if (formRowId === '') {
                    return { formRow: null };
                }
                let formRow: types.FormRow | null = null;
                try {
                    formRow = await api.getFormRow(formRowId);
                } catch (err) {
                    console.error(err);
                }
                return { formRow };
            });
            subscription.bindEvent<types.FormSubmitChangedEvent>(
                api,
                'formsubmitchanged',
                async (event) => {
                    if (event.submit.id !== formRowId) {
                        return;
                    }
                    subscription.modifyState((data) => ({
                        formRow: event.submit
                    }));
                }
            );
            subscription.bindEvent<types.FormSubmitDeletedEvent>(
                api,
                'formsubmitdeleted',
                async (event) => {
                    if (event.formSubmitId !== formRowId) {
                        return;
                    }
                    subscription.modifyState((data) => ({
                        formRow: null
                    }));
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'inquirySubmit' && event.targetId === formRowId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            formRow: data.formRow
                                ? { ...data.formRow, ...event.taggableEntity }
                                : null
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForFormRowResponses: (formRowId: types.FormRowId) =>
        subscriptionRegistry.create(`formRowResponses-${formRowId}`, () => {
            const subscription = new SingleSubscription(async () => {
                if (formRowId === '') {
                    return { responses: [] };
                }
                let responses: types.InquirySubmitResponse[] = [];
                try {
                    responses = await api.getInquirySubmitResponses(formRowId);
                } catch (err) {
                    console.error(err);
                }
                return { responses };
            });
            subscription.bindEvent<types.NewFormSubmitResponseEvent>(
                api,
                'newformsubmitresponse',
                async (event) => {
                    if (event.formRowId !== formRowId) {
                        return;
                    }
                    subscription.modifyState((data) => ({
                        responses: upsert(data.responses, event.response, (x) => x.id)
                    }));
                }
            );
            return subscription;
        }),
    subscribeForFormRowResponse: (formRowResponseId: types.InquirySubmitResponseId) =>
        subscriptionRegistry.create(`formRowResponse-${formRowResponseId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const response = await api.getInquirySubmitResponse(formRowResponseId);
                if (!response) {
                    throw new Error('Response not found');
                }
                return { response };
            });
            return subscription;
        }),
    subscribeForEncryptedPublicFormRowResponse: (
        formRowResponseId: types.InquirySubmitResponseId
    ) =>
        subscriptionRegistry.create(`encryptedPublicFormRowResponse-${formRowResponseId}`, () => {
            const subscription = new SingleSubscription(async () => {
                const response = await api.getEncryptedPublicInquirySubmitResponse(
                    formRowResponseId
                );
                if (!response) {
                    throw new Error('Response not found');
                }
                return { response };
            });
            return subscription;
        }),
    subscribeForFormRowWithResponses: (formRowId: types.FormRowId) =>
        subscriptionRegistry.create(`formRowWithResponses-${formRowId}`, () => {
            return new MergedSubscription(
                [
                    subscriptionService.subscribeForFormRow(formRowId),
                    subscriptionService.subscribeForFormRowResponses(formRowId)
                ],
                ([formRow, formRowResponses]) => ({
                    formRow: formRow.formRow,
                    formRowResponses: formRowResponses.responses
                })
            );
        }),
    subscribeForFavorites: () =>
        subscriptionRegistry.create(`favorites`, () => {
            const subscription = new SingleSubscription(() => api.getFavorites());
            subscription.bindEvent<types.FavoritesUpdatedEvent>(
                api,
                'favoritesupdated',
                async (event) => {
                    subscription.modifyState(() => event.favorites);
                }
            );
            return subscription;
        }),
    subscribeForSharedFiles: () =>
        subscriptionRegistry.create('sharedFiles', () => {
            const subscription = new SingleSubscription(async () => {
                const sharedFiles = await api.getSharedFiles();
                return { sharedFiles };
            });
            subscription.bindEvent<types.NewSharedFileEvent>(
                api,
                'newsharedfile',
                async (event) => {
                    subscription.modifyState((data) => ({
                        sharedFiles: upsert(data.sharedFiles, event.sharedFile, (x) => x.id)
                    }));
                }
            );
            subscription.bindEvent<types.SharedFileChangedEvent>(
                api,
                'sharedfilechanged',
                async (event) => {
                    subscription.modifyState((data) => ({
                        sharedFiles: upsert(data.sharedFiles, event.sharedFile, (x) => x.id)
                    }));
                }
            );
            subscription.bindEvent<types.SharedFileDeletedEvent>(
                api,
                'sharedfiledeleted',
                async (event) => {
                    subscription.modifyState((data) => ({
                        sharedFiles: data.sharedFiles.filter((x) => x.id !== event.sharedFileId)
                    }));
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'sharedFileFile') {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            sharedFiles: data.sharedFiles.map((x) =>
                                event.targetId === x.file.attachmentId
                                    ? {
                                          ...x,
                                          file: {
                                              ...x.file,
                                              ...event.taggableEntity
                                          }
                                      }
                                    : x
                            )
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForSharedFile: (sharedFileId: types.SharedFileId) =>
        subscriptionRegistry.create(`sharedFile-${sharedFileId}`, () => {
            const subscription = new SingleSubscription(async () => {
                let sharedFile: types.SharedFile | null = null;
                try {
                    sharedFile = await api.getSharedFile(sharedFileId);
                } catch (err) {
                    console.error(err);
                }
                return { sharedFile };
            });
            subscription.bindEvent<types.SharedFileChangedEvent>(
                api,
                'sharedfilechanged',
                async (event) => {
                    if (event.sharedFile.id !== sharedFileId) {
                        return;
                    }
                    subscription.modifyState((data) => ({
                        sharedFile: event.sharedFile
                    }));
                }
            );
            subscription.bindEvent<types.SharedFileDeletedEvent>(
                api,
                'sharedfiledeleted',
                async (event) => {
                    if (event.sharedFileId !== sharedFileId) {
                        return;
                    }
                    subscription.modifyState((data) => ({
                        sharedFile: null
                    }));
                }
            );
            subscription.bindEvent<types.PrivateTagsChangeEvent>(
                api,
                'privatetagschange',
                (event) => {
                    if (event.targetType !== 'sharedFileFile' || event.targetId !== sharedFileId) {
                        return;
                    }
                    subscription.modifyState((data) => {
                        return {
                            sharedFile:
                                data.sharedFile &&
                                data.sharedFile.file.attachmentId === event.targetId
                                    ? {
                                          ...data.sharedFile,
                                          file: {
                                              ...data.sharedFile.file,
                                              ...event.taggableEntity
                                          }
                                      }
                                    : data.sharedFile
                        };
                    });
                }
            );
            return subscription;
        }),
    subscribeForEncryptedPublicSharedFile: (sharedFileId: types.SharedFileId) =>
        subscriptionRegistry.create(`publicSharedFile-${sharedFileId}`, () => {
            const subscription = new SingleSubscription(async () => {
                let encryptedPublicSharedFile: PmxApi.api.sharedFile.PublicSharedFile | null = null;
                try {
                    encryptedPublicSharedFile = await api.getEncryptedPublicSharedFile(
                        sharedFileId
                    );
                } catch (err) {
                    console.error(err);
                }
                return { encryptedPublicSharedFile: encryptedPublicSharedFile };
            });
            return subscription;
        }),
    subscribeForNothing: () =>
        subscriptionRegistry.create('nothing', () => {
            const subscription = new SingleSubscription(async () => {
                return {};
            });
            return subscription;
        }),
    unsubscribe: (subscription: SubscriptionHandle) => {
        subscriptionRegistry.unregister(subscription);
    }
};
export type SubscriptionService = typeof subscriptionService;
(window as any).subscriptionRegistry = subscriptionRegistry;

function update<T>(list: T[], element: T, idGetter: (x: T) => unknown) {
    return list.map((x) => (idGetter(x) === idGetter(element) ? element : x));
}
function upsert<T>(list: T[], element: T, idGetter: (x: T) => unknown) {
    return list.some((x) => idGetter(x) === idGetter(element))
        ? list.map((x) => (idGetter(x) === idGetter(element) ? element : x))
        : [...list, element];
}
function upsertMany<T>(list: T[], elements: T[], idGetter: (x: T) => unknown) {
    const res = [...list];
    for (const element of elements) {
        const idx = res.findIndex((x) => idGetter(x) === idGetter(element));
        if (idx >= 0) {
            res[idx] = element;
        } else {
            res.push(element);
        }
    }
    return res;
}
function removeMany<T>(list: T[], elementIds: unknown[], idGetter: (x: T) => unknown) {
    return list.filter((x) => !elementIds.includes(idGetter(x)));
}
