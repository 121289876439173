import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Button, Group } from '@mantine/core';
import React from 'react';
import Icon from '../../components/Icon';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

export interface FormButtonsOptions extends React.PropsWithChildren {
    onSave?: () => void;
    onCancel?: () => void;
    isSaving?: boolean;
    noCancel?: boolean;
    saveIcon?: IconDefinition;
    spinSaveIcon?: boolean;
    saveLabel?: string;
    className?: string;
}

function FormButtons(props: FormButtonsOptions) {
    return (
        <Group className={props.className}>
            <SaveButton
                onClick={props.onSave}
                disabled={props.isSaving}
                saveIcon={props.saveIcon}
                spinSaveIcon={props.spinSaveIcon}
                label={props.saveLabel}
            />
            {!props.noCancel && <CancelButton onClick={props.onCancel} disabled={props.isSaving} />}
            {props.children}
        </Group>
    );
}

function SaveButton(props: {
    onClick?: () => void;
    disabled?: boolean;
    saveIcon?: IconDefinition;
    spinSaveIcon?: boolean;
    label?: string;
}) {
    const { t } = useTranslation();
    return (
        <Button
            type="submit"
            onClick={props.onClick}
            disabled={props.disabled}
            loading={props.spinSaveIcon}
            leftIcon={props.saveIcon && <Icon icon={props.saveIcon} spin={props.spinSaveIcon} />}>
            {props.label || t('action.save')}
        </Button>
    );
}

function CancelButton(props: { onClick?: () => void; disabled?: boolean }) {
    return (
        <Button type="button" onClick={props.onClick} disabled={props.disabled}>
            {t('action.cancel')}
        </Button>
    );
}

export default FormButtons;
