import * as PmxApi from 'privmx-server-api';
import { UserCreationParams, UserCreationContext, UserOriginDetails } from './Types';
import { AddUserModelBuilder } from './api/AddUserModelBuilder';
import { UserCreationContextBuilder } from './UserCreationContextBuilder';
import { AdminApi } from '../../AdminApi';
import { UserService } from '../../UserService';
import { Username } from '../../../../types/Types';
import { Utils } from '../../utils/Utils';

export class UserCreationService {
    constructor(
        private userCreationContextBuilder: UserCreationContextBuilder,
        private addUserModelBuilder: AddUserModelBuilder,
        private adminApi: AdminApi,
        private userService: UserService
    ) {}

    async createUser(params: UserCreationParams): Promise<UserCreationContext> {
        const context = await Utils.tryMultipleTimes({
            func: async () => {
                const context = await this.userCreationContextBuilder.build(params);
                const model = this.addUserModelBuilder.build(context, params);
                await this.adminApi.addUserEx(model);
                return context;
            },
            isErrorRecoverable: (e) =>
                Utils.isJsonRpcError(e) &&
                e.data.error.code === 0x009d &&
                e.msg === 'PKI tree is too old',
            maxTimes: 5
        });
        await this.userService.refresh();
        return { ...context, params };
    }

    async updateUsersOriginDetails(
        usernames: Username[],
        originDetails: UserOriginDetails
    ): Promise<void> {
        await this.adminApi.updateUsersOriginDetails({
            usernames,
            originDetails: JSON.stringify(originDetails) as PmxApi.api.admin.UserOriginDetails
        });
        await this.userService.refresh();
    }
}
