export class Debouncer {
    private timeout: number | null = null;
    private nextCallId: number = 1;

    constructor(private msec: number, private action: (callId: number) => void) {}

    schedule() {
        if (this.timeout !== null) {
            window.clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(() => {
            this.timeout = null;
            this.action(this.nextCallId++);
        }, this.msec);
    }

    runSync() {
        if (this.timeout !== null) {
            window.clearTimeout(this.timeout);
        }
        this.action(this.nextCallId++);
    }

    isScheduled() {
        return this.timeout !== null;
    }

    getLastCallId() {
        return this.nextCallId === 1 ? null : this.nextCallId - 1;
    }
}
