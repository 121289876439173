import Icon, { iconTime } from '../../components/Icon';
import { Meeting } from '../../types/Types';
import { useCallback } from 'react';
import MeetingIcons from './MeetingIcons';
import { useAppSelector } from '../../store';
import { selectVideoRoomState } from '../../store/VideoSlice';
import { useAreMessagesInThreadUnread } from '../../hooks/useIsUnread';
import { Box, Text, ThemeIcon } from '@mantine/core';
import ListElement from '../../mantineAtoms/ListElement';
import FileAndMessageSummary from '../../mantineAtoms/FileAndMessageSummary';
import { displayformatedTime } from '../../utils/RelativeTimeFormat';
import { IconVideo } from '@tabler/icons-react';

interface MeetingDetailLinkProps {
    meeting: Meeting;
    onClick: (e: MouseEvent) => void;
    editFn: (meeting: Meeting, event: MouseEvent) => void;
    active: boolean;
    hovered?: boolean;
}

type Props = MeetingDetailLinkProps;

function getDataHour(date: number) {
    return `${
        (new Date(date).toLocaleString() ?? '')
            ?.split(',')[1]
            ?.split(':')
            ?.slice(0, 2)
            ?.join(':') || ''
    }`;
}

export default function MeetingDetailLink(props: Props) {
    const videoRoomState = useAppSelector(selectVideoRoomState(props.meeting.id));
    const isUnread = useAreMessagesInThreadUnread(props.meeting.id);

    const onEditClick = props.editFn;
    const handleEditClick = useCallback(
        (event: MouseEvent) => {
            onEditClick(props.meeting, event);
        },
        [onEditClick, props.meeting]
    );
    const formatedStartData = displayformatedTime(props.meeting.startDate);

    const formatedDuration = `${getDataHour(props.meeting.startDate)} - ${getDataHour(
        props.meeting.startDate + props.meeting.duration
    )}`;

    return (
        <ListElement
            hovered={props.hovered}
            active={props.active}
            onClick={props.onClick}
            isUnread={isUnread}
            title={
                <>
                    <Text span mr="md">
                        {formatedStartData}
                    </Text>
                    <Text span sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                        <Box component={Icon} icon={iconTime} />
                        {formatedDuration}
                    </Text>
                    {(videoRoomState === 'active' || videoRoomState === 'joining') && (
                        <ThemeIcon
                            color="green"
                            ml="xs"
                            size={17}
                            sx={{
                                display: 'inline-flex',
                                animation: 'pulseAnimation 1s ease-in-out infinite alternate'
                            }}>
                            <IconVideo size={14} />
                        </ThemeIcon>
                    )}
                    {videoRoomState === 'available' && (
                        <ThemeIcon
                            variant="light"
                            ml="xs"
                            size={17}
                            sx={{
                                display: 'inline-flex',
                                animation: 'pulseAnimation 1s ease-in-out infinite alternate'
                            }}>
                            <IconVideo size={14} />
                        </ThemeIcon>
                    )}
                </>
            }
            subTitle={props.meeting.title}
            usernames={props.meeting.users.map((x) => ({ type: 'user', username: x }))}
            actionIcons={<MeetingIcons meeting={props.meeting} editFn={handleEditClick} />}
            tags={props.meeting.tags}
            info={
                <>
                    <FileAndMessageSummary
                        position="right"
                        filesCount={props.meeting.filesCount}
                        mesagesCount={props.meeting.mesagesCount}
                        size="sm"
                    />
                </>
            }
        />
    );
}
