import * as PmxApi from 'privmx-server-api';
import { BaseApi } from './BaseApi';

export class RequestApi extends BaseApi implements PmxApi.api.request.IRequestApi {
    getRequestConfig(): Promise<PmxApi.api.request.RequestConfig> {
        return this.request('getRequestConfig', {});
    }

    createRequest(
        model: PmxApi.api.request.CreateRequestModel
    ): Promise<PmxApi.api.request.CreateRequestResult> {
        return this.request('createRequest', model);
    }

    destroyRequest(model: PmxApi.api.request.DestroyRequestModel): Promise<PmxApi.api.core.OK> {
        return this.request('destroyRequest', model);
    }

    sendChunk(model: PmxApi.api.request.ChunkModel): Promise<PmxApi.api.core.OK> {
        return this.request('sendChunk', model);
    }

    commitFile(model: PmxApi.api.request.CommitFileModel): Promise<PmxApi.api.core.OK> {
        return this.request('commitFile', model);
    }
}
