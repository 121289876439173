import { Button, Grid, List, Stack, Text, Textarea, Title } from '@mantine/core';
import { useState, Fragment } from 'react';
import { FormImporter, ImportedForm } from '../../../utils/FormImporter';
import { useTranslation } from 'react-i18next';

export interface ImportFormProps {
    alreadyHasQuestions: boolean;
    onImportForm: (form: ImportedForm) => void;
}

export function ImportForm(props: ImportFormProps) {
    const { t } = useTranslation();
    const [formHtml, setFormHtml] = useState('');
    const [formsToChoose, setFormsToChoose] = useState<ImportedForm[]>([]);
    const [importError, setImportError] = useState<string | null>(null);

    const handleImportClick = () => {
        const importer = new FormImporter();
        const importedForms = importer.import(formHtml);
        setFormsToChoose([]);
        setImportError(null);
        if (importedForms.length === 0) {
            setImportError(t('formImport.noFormsFound'));
        } else if (importedForms.length === 1) {
            importForm(importedForms[0]);
        } else {
            setFormsToChoose(importedForms);
        }
    };

    const importForm = props.onImportForm;

    return (
        <Stack>
            <Title order={4}>{t('formImport.title')}</Title>
            <Text>{t('formImport.description.text')}</Text>
            <List type="ordered">
                <List.Item>{t('formImport.description.list.1')}</List.Item>
                <List.Item>{t('formImport.description.list.2')}</List.Item>
            </List>
            <Textarea
                placeholder={t('formImport.htmlCodeOfYourForm')}
                minRows={5}
                value={formHtml}
                onChange={(event) => setFormHtml(event.target.value)}
            />
            {props.alreadyHasQuestions && (
                <Text color="red" italic>
                    {t('formImport.alreadyHasQuestionsWarning')}
                </Text>
            )}
            {importError && (
                <Text color="red" weight="bold">
                    {importError}
                </Text>
            )}
            <Button onClick={handleImportClick} disabled={formHtml.trim().length === 0}>
                {t('action.import')}
            </Button>
            {formsToChoose.length > 0 && (
                <>
                    <Text>{t('formImport.chooseFormToImport')}:</Text>
                    <Grid>
                        {formsToChoose.map((x) => (
                            <Fragment key={x.id}>
                                <Grid.Col span={8}>
                                    <Text>{x.name}</Text>
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <Button onClick={() => importForm(x)}>
                                        {t('action.import')}
                                    </Button>
                                </Grid.Col>
                            </Fragment>
                        ))}
                    </Grid>
                </>
            )}
        </Stack>
    );
}
