import { Button } from '@mantine/core';
import Modal from '../../atoms/Modal';
import { BaseModalData } from '../../types/Modals';
import { useTranslation } from 'react-i18next';

export interface AlertModalData extends BaseModalData {
    message: string;
}

export function AlertModal(props: {
    onClose: () => void;
    data: AlertModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    return (
        <Modal
            onFocus={props.onFocus}
            index={props.index}
            onClose={props.onClose}
            minWidth={600}
            minHeight={200}
            height={props.data.height ?? 200}
            width={props.data.width ?? 600}
            styles={{ content: { padding: 0 } }}
            resizable
            title={props.data.title || t('modal.alert.title')}>
            <div style={{ padding: '20px 30px' }}>
                <div style={{ fontSize: '18px', lineHeight: '1.5' }}>{props.data.message}</div>
                <div style={{ textAlign: 'right', margin: '20px 0' }}>
                    <Button size="sm" variant="light" onClick={props.onClose}>
                        OK
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
