import { useDebouncedValue, useHotkeys } from '@mantine/hooks';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function useKeyNavigation(
    length: number,
    onSettle: (index: number) => void,
    updateIndex: (id: string) => number
) {
    const [index, setIndex] = useState(0);
    const [debouncedIndex] = useDebouncedValue(index, 200);
    const clicked = useRef(true);
    const navigation = useLocation();

    useEffect(() => {
        const id = navigation.pathname.split('/')[2];
        setIndex(updateIndex(id));
        clicked.current = true;
    }, [navigation, updateIndex]);

    useHotkeys([
        [
            'ArrowDown',
            () => {
                clicked.current = false;
                setIndex((prevIndex) => {
                    return prevIndex < length - 1 ? prevIndex + 1 : prevIndex;
                });
            }
        ],
        [
            'ArrowUp',
            () => {
                clicked.current = false;
                setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
            }
        ]
    ]);

    useEffect(() => {
        if (!clicked.current && length > 0) onSettle(length <= 0 ? 0 : debouncedIndex);
    }, [onSettle, debouncedIndex, length]);

    return { settledIndex: debouncedIndex, currentIndex: index, clicked, overrideIndex: setIndex };
}
