import {
    Text,
    Container,
    LoadingOverlay,
    TextInput,
    PasswordInput,
    Group,
    Button,
    MediaQuery,
    createStyles,
    Title,
    FocusTrap
} from '@mantine/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { api, userApi } from '../../api/Api';
import { useAppDispatch } from '../../store';
import { setCurrentUser } from '../../store/CurrentUserSlice';
import * as Types from '../../types/Types';
import { CommonLoginUtils } from './CommonLoginUtils';
import { resolveLoginErrorMessage } from './resolveLoginErrorMessage';
import ConfirmModal from '../../atoms/ConfirmModal';

const useLoginScreenFormStyles = createStyles((theme) => ({
    useMnemonic: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));

export default function LoginScreenForm() {
    const { classes } = useLoginScreenFormStyles();
    const { t } = useTranslation();
    const [isProcessing, setIsProcessing] = useState(false);
    const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
    const [username, setUsername] = useState(
        CommonLoginUtils.getLoginFromLocationHash() as Types.Username
    );
    useEffect(() => {
        async function decodeToken() {
            await api.ensurePrivmxClientScriptLoaded();
            const decoded = CommonLoginUtils.readRegistrationToken();
            if (decoded && decoded.userLogin) {
                setUsername(decoded.userLogin as Types.Username);
            }
        }
        decodeToken();
    }, []);

    const [password, setPassword] = useState('' as Types.UserPassword);
    const [mnemonic, setMnemonic] = useState('' as Types.UserPassword);
    const [hasUsernameError, setHasUsernameError] = useState(false);
    const [hasPasswordError, setHasPasswordError] = useState(false);
    const [hasMnemonicError, setHasMnemonicError] = useState(false);
    const [loginError, setLoginError] = useState<string | null>(null);
    const [loginMethod, setLoginMethod] = useState<'password' | 'mnemonic'>('password');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const routeParams = useParams();
    const meetingData = useMemo(
        () =>
            routeParams.meetingData
                ? api.parseThreadLinkStringData(routeParams.meetingData)
                : undefined,
        [routeParams.meetingData]
    );

    const validateUsername = useCallback((value: Types.Username) => {
        const isOk = value !== '';
        setHasUsernameError(!isOk);
        return isOk;
    }, []);
    const validatePassword = useCallback((value: Types.UserPassword) => {
        const isOk = value !== '';
        setHasPasswordError(!isOk);
        return isOk;
    }, []);
    const validateMnemonic = useCallback((value: string) => {
        const isOk = value !== '';
        setHasMnemonicError(!isOk);
        return isOk;
    }, []);

    const handleUsernameChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value.trim() as Types.Username;
            setUsername(value);
            validateUsername(value);
        },
        [validateUsername]
    );
    const handlePasswordChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value.trim() as Types.UserPassword;
            setPassword(value);
            validatePassword(value);
        },
        [validatePassword]
    );
    const handleMnemonicChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value.trim() as Types.UserPassword;
            setMnemonic(value);
            validateMnemonic(value);
        },
        [validateMnemonic]
    );

    const handleLogInClick = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            let isFormOk = true;
            isFormOk = validateUsername(username) && isFormOk;
            const isMnemonic = loginMethod === 'mnemonic';
            if (isMnemonic) {
                isFormOk = validateMnemonic(mnemonic) && isFormOk;
            } else {
                isFormOk = validatePassword(password) && isFormOk;
            }
            if (!isFormOk) {
                return;
            }
            setIsProcessing(true);
            try {
                const result = await userApi.login(
                    username,
                    isMnemonic ? mnemonic : password,
                    isMnemonic
                );
                if (result.success === false) {
                    setLoginError(resolveLoginErrorMessage(result.error));
                } else {
                    const { user, credentials } = result.userWithCredentials;
                    if (user.role === 'staff') {
                        dispatch(setCurrentUser({ ...user, pub: null }));
                    } else {
                        dispatch(
                            setCurrentUser({
                                userType: user.userType,
                                avatar: user.avatar,
                                id: user.id,
                                isAdmin: false,
                                name: user.name,
                                email: user.email,
                                password: password,
                                role: user.role,
                                username: user.username,
                                blocked: false,
                                creationDate: -1 as Types.Timestamp,
                                lastLoggedIn: -1 as Types.Timestamp,
                                pub: null
                            })
                        );
                    }
                    if (routeParams.meetingData && meetingData) {
                        const joinResult = await api.joinToThreadMeetingLobby(meetingData.threadId);
                        if (joinResult === 'already-at-the-meeting') {
                            navigate(`/meetingRoom/${routeParams.meetingData}`);
                        } else {
                            navigate(`/meetingLobby/${routeParams.meetingData}`);
                        }
                        return;
                    }
                    const onboardingPassed = api.getIsOnboardingPassed();
                    if (!onboardingPassed) {
                        navigate('/welcome/mnemonic', {
                            state: { password: await credentials.getPassword() }
                        });
                    }
                }
            } catch (error) {
                setLoginError(t('errorMessage.incorrectLoginData'));
            } finally {
                setIsProcessing(false);
            }
        },
        [
            dispatch,
            username,
            navigate,
            password,
            mnemonic,
            validateUsername,
            validatePassword,
            validateMnemonic,
            loginMethod,
            routeParams.meetingData,
            meetingData,
            t
        ]
    );

    const handleToggleMnemonicClick = useCallback(() => {
        setLoginMethod((loginMethod) => (loginMethod === 'password' ? 'mnemonic' : 'password'));
    }, []);

    const handleForgotPasswordClick = useCallback(() => {
        setIsForgotPasswordModalOpen(true);
    }, []);

    return (
        <form onSubmit={(e) => handleLogInClick(e)}>
            <div
                style={{
                    position: 'fixed',
                    bottom: '0',
                    right: '0',
                    color: '#ccc',
                    margin: '5px',
                    fontSize: '13px'
                }}>
                ver. {api.getVersion()}
            </div>
            <MediaQuery styles={{ width: '100%', marginInline: 'auto' }} smallerThan={'sm'}>
                <Container w={450} h={250} pos="relative">
                    <LoadingOverlay visible={isProcessing} zIndex={150} />
                    {meetingData && (
                        <Text align="center" size="lg">
                            {t('screen.login.joinMeetingWithTitle', { title: meetingData.title })}
                        </Text>
                    )}
                    {loginError && (
                        <Text align="center" c="red.5">
                            {loginError}
                        </Text>
                    )}
                    <TextInput
                        label={t('entityProps.email')}
                        placeholder="mail@example.com"
                        value={username}
                        onChange={handleUsernameChange}
                        error={hasUsernameError}
                        required
                        mb="sm"
                    />
                    {loginMethod === 'password' && (
                        <PasswordInput
                            value={password}
                            onChange={handlePasswordChange}
                            disabled={isProcessing}
                            label={t('entityProps.password')}
                            error={hasPasswordError}
                            required
                            mb="md"
                        />
                    )}
                    {loginMethod === 'mnemonic' && (
                        <TextInput
                            value={mnemonic}
                            onChange={handleMnemonicChange}
                            disabled={isProcessing}
                            label={t('entityProps.mnemonic')}
                            error={hasMnemonicError}
                            placeholder={t('entityProps.yourMnemonic')}
                            required
                            my="md"
                        />
                    )}
                    {loginMethod === 'password' && (
                        <Group position={'apart'}>
                            <Text
                                sx={(theme) => ({ color: theme.fn.primaryColor() })}
                                size="xs"
                                className={classes.useMnemonic}
                                onClick={handleForgotPasswordClick}>
                                {t('screen.login.forgotPasswordQuestion')}
                            </Text>
                            <Text size="xs" color="dimmed">
                                {t('allDataE2ee')}
                            </Text>
                        </Group>
                    )}
                    <Group grow>
                        {loginMethod === 'mnemonic' && (
                            <Button mt="xl" variant="light" onClick={handleToggleMnemonicClick}>
                                {t('action.cancel')}
                            </Button>
                        )}
                        <Button fullWidth={loginMethod === 'password'} mt="xl" type="submit">
                            {t('action.signIn')}
                        </Button>
                    </Group>
                </Container>
            </MediaQuery>
            {isForgotPasswordModalOpen && (
                <ConfirmModal opened onClose={() => setIsForgotPasswordModalOpen(false)}>
                    <Group position="center" p="lg">
                        <Title order={2}>{t('modal.forgotPassword.title')}</Title>
                        <Text>{t('modal.forgotPassword.text')}</Text>
                        <Group position="center" mt="lg">
                            <FocusTrap active>
                                <Button
                                    type="button"
                                    onClick={() => setIsForgotPasswordModalOpen(false)}
                                    variant="light">
                                    {t('action.close')}
                                </Button>
                                <Button
                                    data-autofocus
                                    type="button"
                                    onClick={() => {
                                        setIsForgotPasswordModalOpen(false);
                                        setLoginMethod('mnemonic');
                                    }}>
                                    {t('action.useMnemonic')}
                                </Button>
                            </FocusTrap>
                        </Group>
                    </Group>
                </ConfirmModal>
            )}
        </form>
    );
}
