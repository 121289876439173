import * as privmx from 'privfs-client';
import * as PmxApi from 'privmx-server-api';
import { Utils } from './utils/Utils';

export interface TagEncryptionKeys {
    iv: Buffer;
    hmacKey: Buffer;
    key: Buffer;
}

export class TagEncryptionService {
    private readonly cipherLength = 16 * 6;
    private readonly tagSizeLength = 1;
    private readonly maxTagLength = this.cipherLength - this.tagSizeLength;
    private readonly hmacLength = 4;
    private tagToEncryptedCache = new Map<string, Promise<PmxApi.api.tag.Tag>>();
    private encryptedToTagCache = new Map<PmxApi.api.tag.Tag, Promise<string>>();

    constructor(private tagEncryptionKeys: TagEncryptionKeys | null) {}

    hasKeys() {
        return !!this.tagEncryptionKeys;
    }

    async encryptTags(tags: string[]): Promise<PmxApi.api.tag.Tag[]> {
        return Promise.all(tags.map((x) => this.encryptTag(x)));
    }

    async encryptTag(tag: string) {
        const fromCache = this.tagToEncryptedCache.get(tag);
        if (fromCache) {
            return fromCache;
        }
        const promise = (async () => {
            if (!this.tagEncryptionKeys) {
                throw new Error("Can't encrypt tags without an encryption key");
            }
            const tagBuf = Buffer.from(tag, 'utf8');
            if (tagBuf.length > this.maxTagLength) {
                throw new Error(`Tag buffer cannot be longer than ${this.maxTagLength} characters`);
            }
            const plain = Buffer.alloc(this.cipherLength);
            plain.writeUInt8(tagBuf.length, 0);
            let index = this.tagSizeLength;
            while (true) {
                const bytesToCopy =
                    index + tagBuf.length > plain.length ? plain.length - index : tagBuf.length;
                tagBuf.copy(plain, index, 0, bytesToCopy);
                index += bytesToCopy;
                if (index >= plain.length) {
                    break;
                }
            }
            const cipher = await privmx.crypto.service.aes256CbcNoPadEncrypt(
                plain,
                this.tagEncryptionKeys.key,
                this.tagEncryptionKeys.iv
            );
            const hmac = await this.getCipherHmac(cipher);
            return Buffer.concat([cipher, hmac]).toString('base64') as PmxApi.api.tag.Tag;
        })();
        this.tagToEncryptedCache.set(tag, promise);
        return promise;
    }

    async decryptTags<T extends string = string>(tags: PmxApi.api.tag.Tag[]): Promise<T[]> {
        return (await Promise.all(tags.map((x) => this.decryptTag(x)))) as T[];
    }

    async tryDecryptTags<T extends string = string>(tags: PmxApi.api.tag.Tag[]): Promise<T[]> {
        return (await Promise.all(tags.map((x) => this.tryDecryptTag(x)))).filter(
            Utils.isDefined
        ) as T[];
    }

    async decryptTag(encryptedTag: PmxApi.api.tag.Tag) {
        const fromCache = this.encryptedToTagCache.get(encryptedTag);
        if (fromCache) {
            return fromCache;
        }
        const promise = (async () => {
            if (!this.tagEncryptionKeys) {
                throw new Error("Can't decrypt tags without an encryption key");
            }
            const data = Buffer.from(encryptedTag, 'base64');
            const cipher = data.slice(0, data.length - this.hmacLength);
            const orgHmac = data.slice(data.length - this.hmacLength);
            const hmac = await this.getCipherHmac(cipher);
            if (!orgHmac.equals(hmac)) {
                throw new Error('Invalid tag hmac');
            }
            const plain = await privmx.crypto.service.aes256CbcNoPadDecrypt(
                cipher,
                this.tagEncryptionKeys.key,
                this.tagEncryptionKeys.iv
            );
            const tagLength = plain[0];
            return plain.slice(1, 1 + tagLength).toString('utf8');
        })();
        this.encryptedToTagCache.set(encryptedTag, promise);
        return promise;
    }

    async tryDecryptTag(encryptedTag: PmxApi.api.tag.Tag) {
        try {
            return await this.decryptTag(encryptedTag);
        } catch {}
        return null;
    }

    private async getCipherHmac(cipher: Buffer) {
        if (!this.tagEncryptionKeys) {
            throw new Error("Can't encrypt/decrypt tags without an encryption key");
        }
        const hmac = await privmx.crypto.service.hmacSha256(this.tagEncryptionKeys.hmacKey, cipher);
        return hmac.slice(0, this.hmacLength);
    }
}
