import { useEffect, useState } from 'react';

export function useFetch(url: string) {
    const [state, setState] = useState<'inProgress' | 'ok' | 'error'>('inProgress');
    const [result, setResult] = useState<string>();

    useEffect(() => {
        let cancelled: boolean = false;
        (async () => {
            try {
                const response = await fetch(url);
                const text = await response.text();
                if (!cancelled) {
                    setResult(text);
                    setState('ok');
                }
            } catch (err) {
                console.error(err);
                if (!cancelled) {
                    setState('error');
                }
            }
        })();
        return () => {
            cancelled = true;
        };
    }, [url]);

    return { state, result };
}
