import { useState, useCallback, useMemo } from 'react';
import { api } from '../../../api/Api';
import { Utils } from '../../../api/privmx/utils/Utils';
import { useAppSelector } from '../../../store';
import {
    AttachmentId,
    DraftAttachmentEx,
    MeetingCreateModel,
    MeetingEditModel,
    MeetingId,
    MessageCreateModel,
    MessageCreateModelAtt,
    PreparedAttachment,
    Tag,
    TagOption,
    Timespan,
    Timestamp,
    Username
} from '../../../types/Types';
import { InputPreference, InputPreferenceTypes } from '../../../store/UserPreferenceSlice';
import { UserSelectOptionType } from '../../UsersSelector';
import { toUserOption } from '../../UsersSelector/helpers';
import { selectUsedTags } from '../../../store/DataCacheSlice';
export interface MeetingFormData {
    title: string;
    users: UserSelectOptionType[];
    managers: UserSelectOptionType[];
    messageText: string;
    tags: TagOption[];
    startDate: Timestamp;
    duration: Timespan;
    span: [string, string];
}

export const getLabel = (msec: number) => {
    const h = Math.floor(msec / 3600 / 1000);
    msec -= h * 3600 * 1000;
    const m = Math.floor(msec / 60 / 1000);
    return `${h}:${m.toString().padStart(2, '0')}`;
};
export const durations = new Array(6 * 4)
    .fill(0)
    .map((_, i) => (i + 1) * 15 * 60 * 1000)
    .map((msec) => ({ label: getLabel(msec), value: getLabel(msec), data: msec }));

export const getMeetingFromData = (
    data: MeetingFormData,
    id?: MeetingId
): MeetingEditModel | MeetingCreateModel => {
    const newMeeting: MeetingCreateModel = {
        title: data.title.trim(),
        tags: data.tags.map((x) => x.value),
        users: data.users.map((x) => toUserOption(x)),
        managers: data.managers
            .map((x) => x.value)
            .filter((x) => x !== null) as unknown as Username[],
        startDate: data.startDate,
        duration: data.duration
    };

    if (id) {
        const meetingEdit: MeetingEditModel = { ...newMeeting, id, isMeetingCancelled: false };
        return meetingEdit;
    }

    return newMeeting;
};

export const getMessageFromData = (data: MeetingFormData) => {
    const message: MessageCreateModel = {
        text: data.messageText,
        mimetype: 'text/markdown'
    };
    return message;
};
export const getMessageFromDataAtt = (
    data: MeetingFormData,
    attachments: (PreparedAttachment | DraftAttachmentEx)[],
    mimetype: InputPreferenceTypes,
    meetingId: MeetingId
) => {
    let message: MessageCreateModelAtt;
    if (data.messageText.trim() !== '') {
        message = {
            text: data.messageText,
            mimetype: InputPreference[mimetype],
            attachments: attachments
        };
    } else {
        const meetingSubmitedEvent = {
            type: 'meetingScheduled',
            meetingId: meetingId
        };
        message = {
            attachments: [],
            mimetype: 'application/json',
            text: JSON.stringify(meetingSubmitedEvent)
        };
    }
    return message;
};

export function useAttachemnts(initialValue?: (PreparedAttachment | DraftAttachmentEx)[]) {
    const [attachments, setAttachments] = useState<(PreparedAttachment | DraftAttachmentEx)[]>(
        initialValue ?? []
    );

    const handleAttachFilesClick = useCallback(async () => {
        const newAttachments = await api.chooseFilesAsAttachments();
        if (newAttachments.length > 0) {
            setAttachments((attachments) => [...attachments, ...newAttachments]);
        }
    }, [setAttachments]);

    const handleDeleteAttachmentClick = useCallback(
        (attachmentId: AttachmentId) => {
            setAttachments((prev) => {
                api.deleteAttachment(attachmentId);
                return prev.filter((attachment) => attachment.id !== attachmentId);
            });
        },
        [setAttachments]
    );

    return {
        attachments,
        attachFile: handleAttachFilesClick,
        deleteAttachment: handleDeleteAttachmentClick
    };
}

export function useTags(initialTags?: Tag[]) {
    const globalTags = useAppSelector(selectUsedTags);

    const tags = useMemo(() => initialTags || [], [initialTags]);

    const [newTags, setNewTags] = useState<Tag[]>([]);

    const tagOptions = [...(globalTags || []), ...newTags].map((tag) => ({
        label: tag,
        value: tag
    }));

    const defaultTagsValue = useMemo(
        () => tags.map((x) => tagOptions.find((o) => o.value === x)).filter(Utils.isDefined),
        [tagOptions, tags]
    );

    return { tagOptions, initialTagOptions: defaultTagsValue, setNewTags: setNewTags, newTags };
}
