import { createStyles, rem } from '@mantine/core';
import { DATE_TEXT_WIDTH, USER_TEXT_WIDTH } from './ContactsList';
import { getHoverColor } from '../../MantineConfigs';

export const useAdminPanelStyles = createStyles((theme) => {
    return {
        userElement: {
            flexWrap: 'nowrap',
            gap: theme.spacing.sm,
            padding: 8,
            '&:hover': {
                cursor: 'pointer',
                borderRadius: theme.radius.sm,
                backgroundColor: getHoverColor(theme)
            }
        },
        date: {
            width: DATE_TEXT_WIDTH
        },
        name: {
            width: USER_TEXT_WIDTH
        },
        list: {
            borderRadius: theme.radius.md,
            width: '100%',
            marginBottom: theme.spacing['xl'],
            height: `calc(100% - ${rem(55 + 16)})`,
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing['md']
        },
        listContainer: {}
    };
});
