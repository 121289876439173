import {
    AttachmentEx,
    ChatId,
    MeetingId,
    Message,
    MessageId,
    NewChatMessageEvent,
    ThreadId,
    ThreadMessageDraftWithAttachments
} from '../../types/Types';
import { AppMessage, SendMessageProps } from '../SendMessageView';
import MessagesView from './MessagesView';
import useScrolledContainer from '../../hooks/useScrolledContainer';
import { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { api, chatApi } from '../../api/Api';
import { useSelector } from 'react-redux';
import {
    deletePendingMessage,
    selectFullTextEdit,
    selectPendingMessage
} from '../../store/FullTextEditorSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import { Box, Button, Container, ScrollArea, Stack, Sx } from '@mantine/core';
import { selectAutoMarkAsRead } from '../../store/UserPreferenceSlice';
import Icon, { iconUnread } from '../Icon';
import { ThreadUsers } from './ThreadUsers';
import { useTranslation } from 'react-i18next';
import FilesSidebar from './Sidebar/FilesSidebar';
import MessageInput from '../SendMessageView/MessageInput';

export type SortByType = 'nameAsc' | 'nameDesc' | 'dateDesc' | 'dateAsc';
export type GroupByType = 'day' | 'month' | 'year' | 'user' | 'tag' | 'all';

export interface MessageViewProps {
    loadingMessages: boolean;
    messages: Message[];
    hasAccess: boolean;
    attachments: AttachmentEx[];
    chatMessageDraft?: ThreadMessageDraftWithAttachments;
    meetingMessageDraft?: ThreadMessageDraftWithAttachments;
    chatId?: ChatId;
    meetingId?: MeetingId;
    chatName: string;
    activeTabId: 'messages' | 'attachments' | 'users';
    highlightedMessageIds?: MessageId[];
    scrollToMessageId?: MessageId;
    onSendEmailNotification?: () => void;
    variant?: SendMessageProps['variant'];
    attachmentsViewMode: 'list' | 'tiles';
}

const flexGrow: Sx = { flexGrow: 1, padding: 8, paddingBottom: 0 };

export function FullMessagesView(props: MessageViewProps) {
    const { t } = useTranslation();
    const [pendingMessages, setPendingMessages] = useState<Array<Message | AppMessage>>([]);
    const textEditorPendingMessage = useAppSelector((state) =>
        selectPendingMessage(state, props.chatId || '')
    );
    const prevTextEditorPendingMessageRef = useRef<Message | null>(null);
    const dispatch = useAppDispatch();
    const argsWithId = useCallback(
        function <T>(arg: T) {
            return { data: arg, id: props.chatId };
        },
        [props.chatId]
    );
    const autoMarkAsRead = useAppSelector(selectAutoMarkAsRead);

    useEffect(() => {
        function listener(e: NewChatMessageEvent) {
            if (textEditorPendingMessage && textEditorPendingMessage.msgId === e.message.msgId) {
                dispatch(deletePendingMessage(argsWithId(null)));
            }
            setPendingMessages((prevPending) => {
                const index = prevPending.findIndex((msg) => msg.msgId === e.message.msgId);
                if (index >= 0) prevPending.splice(index, 1);
                return prevPending;
            });
        }

        chatApi.addEventListener('newchatmessage', listener);
        return () => {
            chatApi.removeEventListener('newchatmessage', listener);
        };
    }, [dispatch, textEditorPendingMessage, argsWithId]);

    useEffect(() => {
        if (textEditorPendingMessage) {
            setPendingMessages((prevPending) => [...prevPending, textEditorPendingMessage]);
        } else {
            const prevTextEditorPendingMessage = prevTextEditorPendingMessageRef.current;
            if (prevTextEditorPendingMessage) {
                setPendingMessages((prevPending) =>
                    prevPending.filter((x) => x.msgId !== prevTextEditorPendingMessage.msgId)
                );
            }
        }
        prevTextEditorPendingMessageRef.current = textEditorPendingMessage;
    }, [textEditorPendingMessage]);

    const allMessages = useMemo(() => {
        if (textEditorPendingMessage) {
            return [...props.messages, ...pendingMessages];
        }
        return [...props.messages, ...pendingMessages];
    }, [pendingMessages, props.messages, textEditorPendingMessage]);
    const { messageContainer, scrollToBottom } = useScrolledContainer(allMessages);
    const isFullEditorOpen = useSelector(selectFullTextEdit);
    const handleMarkAllAttachmentsAsReadClick = useCallback(() => {
        const unreadService = api.getUnreadService();
        if (!unreadService) {
            return;
        }
        const threadId = props.chatId ?? props.meetingId;
        if (!threadId) {
            return;
        }
        unreadService.markAllAttachmentsInThreadAsRead(threadId);
    }, [props.chatId, props.meetingId]);

    const displayInput =
        !props.loadingMessages &&
        (!isFullEditorOpen?.open || isFullEditorOpen?.props?.chatId !== props.chatId);

    return (
        <>
            {props.activeTabId === 'messages' && (
                <Container h={'calc(100% - 98px)'} w="100%" sx={flexGrow} size="xl">
                    <Box sx={flexGrow} h="100%" display={'flex'} mx="auto">
                        <Stack spacing={0} sx={flexGrow} w="100%" h="100%">
                            <ScrollArea viewportRef={messageContainer} sx={flexGrow}>
                                <MessagesView
                                    loadingMessages={props.loadingMessages}
                                    messages={allMessages}
                                    hasAccess={props.hasAccess}
                                    chatId={props.chatId}
                                    meetingId={props.meetingId}
                                    highlightedMessageIds={props.highlightedMessageIds}
                                    scrollToMessageId={props.scrollToMessageId}
                                />
                            </ScrollArea>

                            {displayInput && props.hasAccess && (
                                <>
                                    <MessageInput
                                        chatId={props.chatId}
                                        chatMessageDraft={props.chatMessageDraft}
                                        meetingId={props.meetingId}
                                        meetingMessageDraft={props.meetingMessageDraft}
                                        updatePendingMessages={setPendingMessages}
                                        scrollDown={scrollToBottom}
                                        onSendEmailNotification={props.onSendEmailNotification}
                                    />
                                </>
                            )}
                        </Stack>
                    </Box>
                </Container>
            )}
            {props.activeTabId === 'attachments' && (
                <>
                    {!autoMarkAsRead && (
                        <Box pr="md" pt="md" style={{ textAlign: 'right' }}>
                            <Button
                                size="xs"
                                variant="light"
                                onClick={handleMarkAllAttachmentsAsReadClick}
                                title={t('action.markAllAttachmentsAsRead')}>
                                <Icon icon={iconUnread} />
                            </Button>
                        </Box>
                    )}
                    <FilesSidebar
                        loadingMessages={props.loadingMessages}
                        attachments={props.attachments}
                        hasAccess={props.hasAccess}
                        viewMode={props.attachmentsViewMode}
                    />
                </>
            )}
            {props.activeTabId === 'users' && (
                <>
                    <ScrollArea px="md" pt="md" sx={flexGrow}>
                        <ThreadUsers
                            threadId={props.chatId ?? props.meetingId ?? ('' as ThreadId)}
                        />
                    </ScrollArea>
                </>
            )}
        </>
    );
}
