import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectDocumentsToAccept, setAcceptedDocuments } from '../../store/AcceptedDocumentsSlice';
import { Doc } from '../../data/docs';
import { api } from '../../api/Api';
import {
    Box,
    Button,
    Center,
    Checkbox,
    Group,
    Modal,
    ScrollArea,
    Stack,
    Title,
    createStyles
} from '@mantine/core';
import { Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconArrowLeft } from '@tabler/icons-react';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { useFetch } from '../../hooks/useFetch';
import Loading from '../Loading';
import { selectCurrentUser } from '../../store/CurrentUserSlice';

const useStyles = createStyles((theme) => ({
    docLink: {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: theme.colors['brand'][6]
    }
}));

export default function AcceptDocuments() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { classes } = useStyles();
    const currentUser = useAppSelector(selectCurrentUser);
    const [checkedDocuments, setCheckedDocuments] = useState<string[]>([]);
    const [previewedDocument, setPreviewedDocument] = useState<Doc | null>(null);
    const documentsToAcceptRaw = useAppSelector(selectDocumentsToAccept);
    const documentsToAccept = useMemo(() => {
        return documentsToAcceptRaw ?? [];
    }, [documentsToAcceptRaw]);
    const [isSaving, setIsSaving] = useState(false);

    const isAnyDocumentNotAccepted = documentsToAccept.some(
        (doc) => !checkedDocuments.some((checkedDoc) => checkedDoc === doc.model.documentId)
    );

    const handleConfirmClick = useCallback(async () => {
        try {
            setIsSaving(true);
            for (const document of documentsToAccept) {
                await api.acceptDocument(document.model);
            }
            dispatch(setAcceptedDocuments(await api.getAcceptedDocuments()));
        } catch (err) {
            console.error(err);
            dispatch(setAcceptedDocuments(await api.getAcceptedDocuments()));
        } finally {
            setIsSaving(false);
        }
    }, [dispatch, documentsToAccept]);

    if (currentUser.id === null || documentsToAccept.length === 0) {
        return <></>;
    }

    return (
        <Modal
            withCloseButton={false}
            centered
            zIndex={999999}
            opened
            onClose={() => {}}
            size={900}>
            <Center h={700} w={'100%'}>
                {previewedDocument === null && (
                    <Stack>
                        <Title order={1}>{t('modal.acceptDocuments.title')}</Title>
                        <Stack my="xl">
                            {documentsToAccept.map((doc) => (
                                <Checkbox
                                    key={doc.model.documentId}
                                    checked={checkedDocuments.some(
                                        (checkedDoc) => checkedDoc === doc.model.documentId
                                    )}
                                    label={
                                        <Group spacing={5}>
                                            {t('modal.acceptDocuments.iAgreeWithThe')}
                                            <Text
                                                className={classes.docLink}
                                                onClick={(e: React.MouseEvent) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    setPreviewedDocument(doc);
                                                }}>
                                                {doc.name === 'DPA' && t('documentNames.dpa')}
                                                {doc.name === 'PRIVACY' &&
                                                    t('documentNames.privacy')}
                                                {doc.name === 'TERMS' && t('documentNames.terms')}
                                            </Text>
                                        </Group>
                                    }
                                    onChange={(e) => {
                                        if (e.currentTarget.checked) {
                                            setCheckedDocuments((checkedDocuments) => [
                                                ...checkedDocuments,
                                                doc.model.documentId
                                            ]);
                                        } else {
                                            setCheckedDocuments((checkedDocuments) =>
                                                checkedDocuments.filter(
                                                    (x) => x !== doc.model.documentId
                                                )
                                            );
                                        }
                                    }}
                                />
                            ))}
                        </Stack>
                        <Group position="center">
                            <Button
                                disabled={isAnyDocumentNotAccepted}
                                onClick={handleConfirmClick}
                                loading={isSaving}>
                                {t('action.confirm')}
                            </Button>
                        </Group>
                    </Stack>
                )}
                {previewedDocument !== null && (
                    <Box w="100%">
                        <ScrollArea h={630}>
                            <DocumentContentViewer doc={previewedDocument} />
                        </ScrollArea>
                        <Center mt="lg">
                            <Button
                                variant="light"
                                leftIcon={<IconArrowLeft />}
                                onClick={() => setPreviewedDocument(null)}>
                                {t('action.back')}
                            </Button>
                        </Center>
                    </Box>
                )}
            </Center>
        </Modal>
    );
}

interface DocumentContentViewerProps {
    doc: Doc;
}

function DocumentContentViewer(props: DocumentContentViewerProps) {
    const { state, result } = useFetch(props.doc.file);
    const { t } = useTranslation();

    if (state === 'inProgress') {
        return <Loading />;
    }
    if (state === 'error') {
        return <Box>{t('errorMessage.couldNotLoadDocument')}</Box>;
    }

    return (
        <Box>
            <ReactMarkdown remarkPlugins={[remarkGfm]} children={result ?? ''} />
        </Box>
    );
}
