import {
    ModalProps,
    Flex,
    ActionIcon,
    Box,
    Group,
    TextInput,
    rem,
    ScrollArea,
    Stack,
    UnstyledButton,
    ThemeIcon,
    Divider,
    Text
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { IconX, IconSearch, IconBuilding } from '@tabler/icons-react';
import { useState, useEffect, useCallback, useLayoutEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useConfirmDialog } from '../../../mantineAtoms/ConfirmDialog';
import { useAppDispatch } from '../../../store';
import { toggleCompanyModal } from '../../../store/ModalsSlice';
import { Company, Contact } from '../../../types/Types';
import CenteredModal from '../CenteredModal';
import { api } from '../../../api/Api';
import { CompanyEditForm } from './types';
import { useCompanyModalStyles } from './CompanyModal.styles';
import { CompanyDescription } from '../../CompanyPage/CompanyDescription';

export function CompanyModal({
    company,
    newCompanyContacts,
    allCompanies,
    isNew,
    ...props
}: {
    isNew?: boolean;
    allCompanies?: Company[];
    company: Company;
    newCompanyContacts?: Contact[] | null;
} & Omit<ModalProps, 'onClose'>) {
    const { classes } = useCompanyModalStyles();

    const [companyContacts, setCompanyContacts] = useState<Contact[] | null>(
        newCompanyContacts || null
    );

    useEffect(() => {
        if (!company || newCompanyContacts) return;
        setCompanyContacts(null);
        const f = async () => {
            const contacts = await api.getContactsOfCompany(company.id);
            setCompanyContacts(contacts);
        };
        f();
    }, [company, newCompanyContacts]);

    const form = useForm<CompanyEditForm>(
        !isNew
            ? {
                  defaultValues: {
                      ...company,
                      users: companyContacts
                  }
              }
            : {}
    );
    const dispatch = useAppDispatch();
    const reset = form.reset;
    const setValue = form.setValue;
    const formIsDirty = form.formState.isDirty;

    const { openDialog } = useConfirmDialog();

    const handleClose = useCallback(() => {
        if (formIsDirty) {
            openDialog({
                onAcceptCallback() {
                    dispatch(toggleCompanyModal({ open: false }));
                }
            });
        } else {
            dispatch(toggleCompanyModal({ open: false }));
        }
    }, [dispatch, formIsDirty, openDialog]);

    useEffect(() => {
        if (isNew) {
            setValue('name', company.name, { shouldDirty: true });
            setValue('users', companyContacts, { shouldDirty: true });
        }
    }, [setValue, isNew, company.name, companyContacts]);

    useLayoutEffect(() => {
        setValue('users', companyContacts);
    }, [companyContacts, setValue]);

    const handleCompanyChange = useCallback(
        (companyId: Company['id']) => {
            if (formIsDirty) {
                openDialog({
                    onAcceptCallback() {
                        dispatch(
                            toggleCompanyModal({ open: true, payload: { company: companyId } })
                        );
                        reset({ ...company, users: null });
                    }
                });
            } else {
                dispatch(toggleCompanyModal({ open: true, payload: { company: companyId } }));
                reset({ ...company, users: null });
            }
        },
        [dispatch, company, reset, openDialog, formIsDirty]
    );

    const [query, changeQuery] = useInputState('');

    const searchedCompanies = useMemo(
        () =>
            (allCompanies || []).filter((x) => x.name.toLowerCase().includes(query.toLowerCase())),
        [query, allCompanies]
    );

    return (
        <CenteredModal onClose={handleClose} {...props}>
            <Flex h="100%" pos={'relative'}>
                <ActionIcon
                    onClick={handleClose}
                    pos={'absolute'}
                    top={16}
                    right={16}
                    sx={{ zIndex: 200 }}>
                    <IconX />
                </ActionIcon>
                {allCompanies && (
                    <Box h={'100%'} w={'25%'} className={classes.companiesList}>
                        <Group mb="md" spacing={'xs'}>
                            <Text color={'dimmed'} size={'xs'}>
                                Companies
                            </Text>
                            <TextInput
                                onChange={changeQuery}
                                value={query}
                                size="xs"
                                variant="filled"
                                icon={<IconSearch size={rem(16)} />}
                                sx={{ flexGrow: 1 }}
                                styles={(theme) => ({
                                    wrapper: {
                                        flexGrow: 1
                                    },
                                    input: {
                                        background: 'transparent',
                                        border: `1px solid transparent`,
                                        ':focus': {
                                            border: `1px solid ${theme.colors.gray[7]}`
                                        }
                                    }
                                })}
                            />
                        </Group>
                        <ScrollArea.Autosize w="100%" h="calc(100% - 40px)" sx={{ flexGrow: 1 }}>
                            <Stack spacing={0}>
                                {searchedCompanies.map((x) => (
                                    <UnstyledButton
                                        key={x.id}
                                        onClick={() => handleCompanyChange(x.id)}
                                        className={classes.companyListElement}>
                                        <Group>
                                            <ThemeIcon
                                                radius={999}
                                                variant={'light'}
                                                color={'gray'}
                                                size={'md'}>
                                                <IconBuilding size={rem(16)} />
                                            </ThemeIcon>
                                            <Box>
                                                <Text>{x.name}</Text>
                                                <Text>{x.email}</Text>
                                            </Box>
                                        </Group>
                                    </UnstyledButton>
                                ))}
                            </Stack>
                        </ScrollArea.Autosize>
                    </Box>
                )}
                <Divider h="90%" my="auto" orientation="horizontal" />
                <CompanyDescription
                    company={company}
                    isNew={isNew}
                    newCompanyContacts={newCompanyContacts}
                />
            </Flex>
        </CenteredModal>
    );
}
