import { MultiSelect, MultiSelectProps } from '@mantine/core';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function TagsSelect({ creatable = true, onChange, ...props }: MultiSelectProps) {
    const { t } = useTranslation();
    const [searchValue, onSearchChange] = useState('');

    const selectRef = useRef<HTMLInputElement>(null);

    const handleChange = useCallback(
        (value: string[]) => {
            if (selectRef) {
                selectRef.current?.blur();
            }
            onChange?.(value);
        },
        [onChange]
    );

    return (
        <MultiSelect
            searchable
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            creatable={creatable}
            getCreateLabel={(query) => t('action.addTagWithName', { name: query })}
            onChange={handleChange}
            {...props}
        />
    );
}
