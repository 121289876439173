import { useCallback, useState, MouseEvent, useMemo } from 'react';
import { ActionIcon, Box, Group, Loader, Sx, Tooltip } from '@mantine/core';
import {
    IconArchive,
    IconArchiveOff,
    IconEdit,
    IconMailOpened,
    IconStar,
    IconStarFilled
} from '@tabler/icons-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAreMessagesInThreadUnread } from '../../../../hooks/useIsUnread';
import useToast from '../../../../hooks/useToast';
import { mimetypeMap, useFilterContext } from '../../../../mantineAtoms/FilterSelect';
import { useAppSelector } from '../../../../store';
import { selectCurrentUserUsername } from '../../../../store/CurrentUserSlice';
import { selectChatFiles } from '../../../../store/DataCacheSlice';
import { Chat, Tag } from '../../../../types/Types';
import { FilePreview } from './FilePreview';
import { api } from '../../../../api/Api';
import ListElement from '../../../../mantineAtoms/ThreadListElement/ListElement';
import { lastMessageParser } from './helpers';

const iconStyle: Sx = (theme) => {
    return {
        border: 0,
        '&:hover': {
            color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[7],
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }
    };
};

export function ChatListElement(props: {
    active: boolean;
    chat: Chat;
    onEditClick?: (e?: any) => void;
    hovered?: boolean;
}) {
    const { t } = useTranslation();
    const [isProcessingPin, setIsProcessingPin] = useState<boolean>(false);
    const [isProcessingArchive, setIsProcessingArchive] = useState<boolean>(false);
    const isUnread = useAreMessagesInThreadUnread(props.chat.id);
    const currentUserUsername = useAppSelector(selectCurrentUserUsername);
    const { contextState, filters } = useFilterContext();
    const chatAttachments = useAppSelector((state) => selectChatFiles(state, props.chat.id));

    const handleTogglePinnedClick = useCallback(
        async (event: MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            setIsProcessingPin(true);
            const newPinned = !props.chat.pinned;
            try {
                await api.setChatPinned(props.chat.id, newPinned);
            } finally {
                props.chat.pinned = newPinned; // hack to prevent blink after loading disapear
                setIsProcessingPin(false);
            }
        },
        [props.chat]
    );

    const handleToggleArchivedClick = useCallback(
        async (event: MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            setIsProcessingArchive(true);
            const newArchived = !props.chat.archived;
            try {
                await api.setChatArchived(props.chat.id, newArchived);
            } finally {
                // props.chat.archived = newArchived; // hack to prevent blink after loading disapear
                setIsProcessingArchive(false);
            }
        },
        [props.chat]
    );

    const handleMarkAsRead = useCallback(() => {
        const service = api.getUnreadService();
        if (service) {
            return service.markAllMessagesInThreadAsRead(props.chat.id);
        }
    }, [props.chat.id]);

    const archiveIcon = isProcessingArchive ? (
        <Loader size={16} />
    ) : props.chat.archived ? (
        <IconArchiveOff size={16} />
    ) : (
        <IconArchive size={16} />
    );

    const starIcon = isProcessingPin ? (
        <Loader size={16} />
    ) : props.chat.pinned ? (
        <IconStarFilled />
    ) : (
        <IconStar className="star" />
    );

    const filteredChatAttachments = useMemo(() => {
        if (contextState && chatAttachments) {
            let filteredAttachments = [...chatAttachments];
            if (filters.find((filter) => filter.type === 'file' || filter.type === 'file type')) {
                for (const filter of filters) {
                    if (filter.type === 'file type') {
                        filteredAttachments = filteredAttachments.filter((x) => {
                            if (filter.value === 'any') return true;
                            return mimetypeMap.get(x.contentType) === filter.value;
                        });
                    } else if (filter.type === 'file') {
                        filteredAttachments = filteredAttachments.filter((x) =>
                            x.name.toLowerCase().includes(filter.value.toLowerCase())
                        );
                    }
                }
                return filteredAttachments;
            }
        }
        return null;
    }, [chatAttachments, contextState, filters]);

    const emitToast = useToast();
    const handleSetNewTags = useCallback(
        (newTags: string[]) => {
            const oldTags = props.chat.tags;
            try {
                props.chat.tags = newTags as Tag[];
                api.setThreadTags(props.chat.id, newTags as Tag[]);
            } catch (error) {
                props.chat.tags = oldTags;
                emitToast('Error while adding new tags', 'error');
            }
        },
        [props.chat, emitToast]
    );

    const canEditThread = props.chat.managers.findIndex((x) => x === currentUserUsername) !== -1;
    const tags = props.chat.tags;

    const [params] = useSearchParams();

    const navigate = useNavigate();
    const handleChatListElementClick = useCallback(
        (e: React.MouseEvent) => {
            navigate(
                `./${props.chat.id}${
                    params.get('filter') ? `?filter=${params.get('filter')}` : ''
                }`,
                { state: { originState: contextState } }
            );
        },
        [navigate, contextState, props.chat.id, params]
    );

    return (
        <Box
            td="none"
            onClick={handleChatListElementClick}
            sx={(theme) => ({
                borderBottom: `1px solid ${
                    theme.colorScheme === 'dark'
                        ? theme.fn.rgba(theme.colors.dark[4], 0.5)
                        : theme.fn.rgba(theme.colors.gray[4], 0.5)
                }`,
                '&:last-child': {
                    border: 0
                }
            })}>
            <ListElement
                type="topic"
                withCheckbox
                archived={props.chat.archived}
                hovered={props.hovered}
                active={props.active}
                isUnread={isUnread}
                title={props.chat.title}
                hasProblem={
                    props.chat.verified === 'verified' || props.chat.verified === 'processing'
                        ? false
                        : props.chat.verified
                }
                starIcon={
                    <Tooltip
                        label={
                            props.chat.pinned
                                ? t('action.removeFromFavourites')
                                : t('action.addToFavorites')
                        }>
                        <ActionIcon sx={iconStyle} onClick={handleTogglePinnedClick} size={16}>
                            {starIcon}
                        </ActionIcon>
                    </Tooltip>
                }
                actionIcons={
                    <Group spacing={0} className="dots" position="right" align="center">
                        {canEditThread && (
                            <Tooltip label="Edit">
                                <ActionIcon
                                    component="div"
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        props.onEditClick?.();
                                    }}
                                    variant="outline"
                                    sx={iconStyle}>
                                    <IconEdit size={16} />
                                </ActionIcon>
                            </Tooltip>
                        )}
                        <Tooltip label="Mark as read">
                            <ActionIcon
                                component="div"
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleMarkAsRead();
                                }}
                                variant="outline"
                                sx={iconStyle}>
                                <IconMailOpened size={16} />
                            </ActionIcon>
                        </Tooltip>

                        <Tooltip
                            label={props.chat.archived ? 'Mark as unarchived' : 'Mark as archived'}>
                            <ActionIcon
                                component="div"
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleToggleArchivedClick(e);
                                }}
                                variant="outline"
                                sx={iconStyle}>
                                {archiveIcon}
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                }
                usernames={props.chat.users.map((x) => ({ type: 'user', username: x }))}
                tags={tags}
                onTagSelect={handleSetNewTags}
                value={props.chat.id}
                lastActive={props.chat.lastMsgDate}
                description={
                    props.chat.lastMsg?.text ? (
                        <Box
                            sx={(theme) => ({
                                '& .mantine-Text-root': {
                                    fontSize: 13,
                                    color:
                                        theme.colorScheme === 'dark'
                                            ? theme.colors.dark[2]
                                            : theme.colors.gray[6],
                                    fontWeight: 'normal',
                                    textTransform: 'none',
                                    fontStyle: 'normal'
                                },
                                '& span': {
                                    color:
                                        theme.colorScheme === 'dark'
                                            ? theme.colors.dark[2]
                                            : theme.colors.gray[6],
                                    fontSize: 13
                                },
                                '& p': {
                                    color:
                                        theme.colorScheme === 'dark'
                                            ? theme.colors.dark[2]
                                            : theme.colors.gray[6],
                                    margin: 0,
                                    display: 'inline',
                                    marginRight: '2px',
                                    fontWeight: 'normal',
                                    fontSize: 13,
                                    '& *': {
                                        fontSize: 13,
                                        color:
                                            theme.colorScheme === 'dark'
                                                ? theme.colors.dark[2]
                                                : theme.colors.gray[6],
                                        fontWeight: 'normal',
                                        textTransform: 'none',
                                        fontStyle: 'normal'
                                    }
                                }
                            })}>
                            {lastMessageParser(props.chat.lastMsg)}
                        </Box>
                    ) : (
                        ''
                    )
                }
                filePreview={
                    filteredChatAttachments ? (
                        <FilePreview chatId={props.chat.id} attachments={filteredChatAttachments} />
                    ) : null
                }
            />
        </Box>
    );
}
