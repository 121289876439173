import { Box, Group, ThemeIcon, Text, Tooltip, MediaQuery, BoxProps } from '@mantine/core';
import { IconEdit, IconClockHour2, IconVideo } from '@tabler/icons-react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAreMessagesInThreadUnread } from '../../hooks/useIsUnread';
import useToast from '../../hooks/useToast';
import { useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { selectVideoRoomState } from '../../store/VideoSlice';
import { Meeting, Tag } from '../../types/Types';
import { api } from '../../api/Api';
import { displayFormatedTimeOfDay, displayformatedTime } from '../../utils/RelativeTimeFormat';
import ListElement from '../../mantineAtoms/ThreadListElement/ListElement';

export function MeetingListElement({
    meeting,
    onEditClick,
    hovered,
    active,
    isDone,
    ...rest
}: {
    meeting: Meeting;
    onEditClick?: (e?: any) => void;
    hovered?: boolean;
    active: boolean;
    isDone?: boolean;
} & Omit<BoxProps, 'children'>) {
    const isUnread = useAreMessagesInThreadUnread(meeting.id);
    const videoRoomState = useAppSelector(selectVideoRoomState(meeting.id));
    const currentUser = useAppSelector(selectCurrentUser);

    const toast = useToast();
    const formatedStartData = displayformatedTime(meeting.startDate);

    const formatedDuration = `${displayFormatedTimeOfDay(
        meeting.startDate
    )} - ${displayFormatedTimeOfDay(meeting.startDate + meeting.duration)}`;

    const handleSetNewTags = useCallback(
        (newTags: string[]) => {
            const oldTags = meeting.tags;
            try {
                meeting.tags = newTags as Tag[];
                api.setThreadTags(meeting.id, newTags as Tag[]);
            } catch (error) {
                meeting.tags = oldTags;
                toast('Error while adding new tags', 'error');
            }
        },
        [meeting, toast]
    );

    const isMeetingManager = currentUser.username
        ? meeting.managers.includes(currentUser.username)
        : false;

    return (
        <Box
            component={Link}
            td="none"
            to={`/meetings/${meeting.id}`}
            opacity={isDone ? 0.5 : 1}
            display={'block'}
            className="element"
            sx={(theme) => ({
                borderBottom: `1px solid ${
                    theme.colorScheme === 'dark'
                        ? theme.fn.rgba(theme.colors.dark[4], 0.5)
                        : theme.fn.rgba(theme.colors.gray[4], 0.5)
                }`,
                '&[data-is-last = "true"]': {
                    border: 0
                }
            })}
            {...rest}>
            <ListElement
                type="meeting"
                archived={meeting.archived}
                hovered={hovered}
                active={active}
                isUnread={isUnread}
                title={meeting.title}
                starIcon={<></>}
                hasProblem={meeting.verified !== 'verified' && meeting.verified}
                actionIcons={
                    <Group spacing={0} className="dots" position="right" align="center">
                        {isMeetingManager && (
                            <Tooltip label="Edit">
                                <ThemeIcon
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        onEditClick?.();
                                    }}
                                    variant="outline"
                                    sx={{ border: 0 }}
                                    color="gray.6">
                                    <IconEdit size={16} />
                                </ThemeIcon>
                            </Tooltip>
                        )}
                    </Group>
                }
                usernames={meeting.users.map((x) => ({ type: 'user', username: x }))}
                tags={meeting.tags}
                onTagSelect={isDone ? undefined : handleSetNewTags}
                value={meeting.id}
                description={
                    <Group noWrap spacing={0} fz="sm" h={22} sx={{ '*': { textWrap: 'nowrap' } }}>
                        <Text span mr={8} size={'xs'}>
                            {formatedStartData}
                        </Text>
                        <Group spacing={4}>
                            <MediaQuery smallerThan={'sm'} styles={{ display: 'none' }}>
                                <ThemeIcon
                                    display="inline-flex"
                                    size={16}
                                    variant="transparent"
                                    sx={{ border: 0 }}>
                                    <IconClockHour2 size={16} />
                                </ThemeIcon>
                            </MediaQuery>
                            <Text
                                span
                                size={'xs'}
                                sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                                {formatedDuration}
                            </Text>
                        </Group>
                        {(videoRoomState === 'active' || videoRoomState === 'joining') && (
                            <ThemeIcon
                                color="green"
                                ml="xs"
                                size={17}
                                sx={{
                                    display: 'inline-flex',
                                    animation: 'pulseAnimation 1s ease-in-out infinite alternate'
                                }}>
                                <IconVideo size={14} />
                            </ThemeIcon>
                        )}
                        {videoRoomState === 'available' && (
                            <ThemeIcon
                                variant="light"
                                ml="xs"
                                size={17}
                                sx={{
                                    display: 'inline-flex',
                                    animation: 'pulseAnimation 1s ease-in-out infinite alternate'
                                }}>
                                <IconVideo size={14} />
                            </ThemeIcon>
                        )}
                    </Group>
                }
                lastActive={meeting.lastMsgDate}
            />
        </Box>
    );
}
