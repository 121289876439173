import { Menu, TextInput, rem, ScrollArea, Text } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { IconSearch, IconUser } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useAppSelector } from '../../../store';
import { selectCachedCompaniesWithUsers } from '../../../store/DataCacheSlice';
import { FilterFactory } from '../FilterContext';
import { FilterDropdownComponent } from '../types';

export const FilterSelectCompanyDropdown: FilterDropdownComponent = ({ onGoTo, onSelect }) => {
    const companies = useAppSelector(selectCachedCompaniesWithUsers);
    const [filter, setFilter] = useInputState('');

    const filteredCompanies = useMemo(() => {
        return companies.filter((x) => {
            const lowerCaseFilter = filter?.toLowerCase();
            return (
                x.name?.toLowerCase().includes(lowerCaseFilter) ||
                x.email?.toLowerCase().includes(lowerCaseFilter)
            );
        });
    }, [filter, companies]);

    return (
        <Menu.Dropdown>
            <TextInput
                icon={<IconSearch size={rem(12)} />}
                placeholder={'Search by name...'}
                value={filter}
                size={'xs'}
                onChange={setFilter}
                styles={{
                    input: { border: 0 }
                }}
            />
            <Menu.Divider mb={'xs'} />
            <ScrollArea.Autosize h={140}>
                {filteredCompanies.map((x) => {
                    return (
                        <Menu.Item
                            key={x.id}
                            onClick={(event: React.MouseEvent) => {
                                event.stopPropagation();
                                onSelect(FilterFactory.company(x));
                            }}
                            icon={<IconUser size={rem(14)} />}>
                            <Text size="xs">{x.name}</Text>
                            <Text size="xs">{x.email}</Text>
                        </Menu.Item>
                    );
                })}
            </ScrollArea.Autosize>
        </Menu.Dropdown>
    );
};
