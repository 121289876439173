import * as PmxApi from 'privmx-server-api';
import { BaseApi } from './BaseApi';

export class InquiryApi extends BaseApi implements PmxApi.api.inquiry.IInquiryApi {
    getInquiry(
        model: PmxApi.api.inquiry.GetInquiryModel
    ): Promise<{ inquiry: PmxApi.api.inquiry.Inquiry }> {
        return this.request('getInquiry', model);
    }

    getInquiries(
        model: PmxApi.api.inquiry.GetInquiriesModel
    ): Promise<{ inquiries: PmxApi.api.inquiry.Inquiry[] }> {
        return this.request('getInquiries', model);
    }

    getInquirySubmitsOfCompany(model: PmxApi.api.inquiry.GetInquirySubmitsOfCompanyModel): Promise<{
        inquiries: PmxApi.api.inquiry.Inquiry[];
        submits: PmxApi.api.inquiry.InquirySubmit[];
    }> {
        return this.request('getInquirySubmitsOfCompany', model);
    }

    getInquirySubmitsOfUser(model: PmxApi.api.inquiry.GetInquirySubmitsOfUserModel): Promise<{
        inquiries: PmxApi.api.inquiry.Inquiry[];
        submits: PmxApi.api.inquiry.InquirySubmit[];
    }> {
        return this.request('getInquirySubmitsOfUser', model);
    }

    getInquirySubmitsByThreadIds(
        model: PmxApi.api.inquiry.GetInquirySubmitsByThreadIdsModel
    ): Promise<{
        inquiries: PmxApi.api.inquiry.Inquiry[];
        submits: PmxApi.api.inquiry.InquirySubmit[];
    }> {
        return this.request('getInquirySubmitsByThreadIds', model);
    }

    createInquiry(
        model: PmxApi.api.inquiry.CreateInquiryModel
    ): Promise<{ inquiry: PmxApi.api.inquiry.Inquiry }> {
        return this.request('createInquiry', model);
    }

    updateInquiry(
        model: PmxApi.api.inquiry.UpdateInquiryModel
    ): Promise<{ inquiry: PmxApi.api.inquiry.Inquiry }> {
        return this.request('updateInquiry', model);
    }

    setInquiryTags(
        model: PmxApi.api.inquiry.SetInquiryTagsModel
    ): Promise<{ inquiry: PmxApi.api.inquiry.Inquiry }> {
        return this.request('setInquiryTags', model);
    }

    publishInquiry(
        model: PmxApi.api.inquiry.PublishInquiryModel
    ): Promise<{ inquiry: PmxApi.api.inquiry.Inquiry }> {
        return this.request('publishInquiry', model);
    }

    unpublishInquiry(
        model: PmxApi.api.inquiry.UnpublishInquiryModel
    ): Promise<{ inquiry: PmxApi.api.inquiry.Inquiry }> {
        return this.request('unpublishInquiry', model);
    }

    setCurrentInquiryPublication(
        model: PmxApi.api.inquiry.SetCurrentInquiryPublicationModel
    ): Promise<{ inquiry: PmxApi.api.inquiry.Inquiry }> {
        return this.request('setCurrentInquiryPublication', model);
    }

    getInquiryPublicView(
        model: PmxApi.api.inquiry.GetInquiryPublicViewModel
    ): Promise<{ inquiry: PmxApi.api.inquiry.InquiryPublicView }> {
        return this.request('getInquiryPublicView', model);
    }

    submitInquiry(model: PmxApi.api.inquiry.SubmitInquiryModel): Promise<PmxApi.api.core.OK> {
        return this.request('submitInquiry', model);
    }

    updateInquirySubmit(
        model: PmxApi.api.inquiry.UpdateInquirySubmitModel
    ): Promise<{ submit: PmxApi.api.inquiry.InquirySubmit }> {
        return this.request('updateInquirySubmit', model);
    }

    setInquirySubmitTags(
        model: PmxApi.api.inquiry.SetInquirySubmitTagsModel
    ): Promise<{ submit: PmxApi.api.inquiry.InquirySubmit }> {
        return this.request('setInquirySubmitTags', model);
    }

    getInquirySubmit(
        model: PmxApi.api.inquiry.GetInquirySubmitModel
    ): Promise<{ submit: PmxApi.api.inquiry.InquirySubmit }> {
        return this.request('getInquirySubmit', model);
    }

    getInquirySubmits(
        model: PmxApi.api.inquiry.GetInquirySubmitsModel
    ): Promise<{ submits: PmxApi.api.inquiry.InquirySubmit[] }> {
        return this.request('getInquirySubmits', model);
    }

    deleteInquirySubmit(
        model: PmxApi.api.inquiry.DeleteInquirySubmitModel
    ): Promise<PmxApi.api.core.OK> {
        return this.request('deleteInquirySubmit', model);
    }

    getMainInquiry(): Promise<{ id: PmxApi.api.inquiry.InquiryId | null }> {
        return this.request('getMainInquiry', {});
    }

    setMainInquiry(model: PmxApi.api.inquiry.SetMainInquiryModel): Promise<PmxApi.api.core.OK> {
        return this.request('setMainInquiry', model);
    }

    getInquiryAttachmentData(
        model: PmxApi.api.inquiry.GetInquiryAttachmentDataModel
    ): Promise<{ data: Buffer }> {
        return this.request('getInquiryAttachmentData', model);
    }

    getInquiryAttachment(model: PmxApi.api.inquiry.GetInquiryAttachmentModel): Promise<{
        attachment: PmxApi.api.inquiry.InquiryAttachment;
        inquiry: PmxApi.api.inquiry.Inquiry;
    }> {
        return this.request('getInquiryAttachment', model);
    }

    getInquiryAttachments(model: PmxApi.api.inquiry.GetInquiryAttachmentsModel): Promise<{
        attachments: PmxApi.api.inquiry.InquiryAttachment[];
        inquiry: PmxApi.api.inquiry.Inquiry;
    }> {
        return this.request('getInquiryAttachments', model);
    }

    getInquirySubmitAttachments(
        model: PmxApi.api.inquiry.GetInquirySubmitAttachmentsModel
    ): Promise<{
        attachments: PmxApi.api.inquiry.InquiryAttachment[];
        inquiry: PmxApi.api.inquiry.Inquiry;
        inquirySubmit: PmxApi.api.inquiry.InquirySubmit;
    }> {
        return this.request('getInquirySubmitAttachments', model);
    }

    sendInquirySubmitResponse(model: PmxApi.api.inquiry.SendInquirySubmitResponseModel): Promise<{
        inquirySubmitResponse: PmxApi.api.inquiry.InquirySubmitResponse;
        inquiry: PmxApi.api.inquiry.Inquiry;
        inquirySubmit: PmxApi.api.inquiry.InquirySubmit;
    }> {
        return this.request('sendInquirySubmitResponse', model);
    }

    getInquirySubmitResponses(model: PmxApi.api.inquiry.GetInquirySubmitResponsesModel): Promise<{
        inquirySubmitResponses: PmxApi.api.inquiry.InquirySubmitResponse[];
        inquiry: PmxApi.api.inquiry.Inquiry;
        inquirySubmit: PmxApi.api.inquiry.InquirySubmit;
    }> {
        return this.request('getInquirySubmitResponses', model);
    }

    getInquirySubmitResponse(model: PmxApi.api.inquiry.GetInquirySubmitResponseModel): Promise<{
        inquirySubmitResponse: PmxApi.api.inquiry.InquirySubmitResponse;
        inquiry: PmxApi.api.inquiry.Inquiry;
        inquirySubmit: PmxApi.api.inquiry.InquirySubmit;
    }> {
        return this.request('getInquirySubmitResponse', model);
    }

    getPublicInquirySubmitResponse(
        model: PmxApi.api.inquiry.GetPublicInquirySubmitResponseModel
    ): Promise<{ publicInquirySubmitResponse: PmxApi.api.inquiry.PublicInquirySubmitResponse }> {
        return this.request('getPublicInquirySubmitResponse', model);
    }

    generateInquiryShortLink(
        model: PmxApi.api.inquiry.GenerateInquiryShortLinkModel
    ): Promise<{ inquiry: PmxApi.api.inquiry.Inquiry }> {
        return this.request('generateInquiryShortLink', model);
    }
}
