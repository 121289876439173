import { createStyles } from '@mantine/core';
import { Utils } from '../../../utils/Utils';

export const useSelectValueStyles = createStyles(
    (
        theme,
        {
            hasRightPadding,
            value,
            isContact
        }: { hasRightPadding: boolean; value: string; isContact: boolean }
    ) => {
        const color = isContact ? 'gray' : Utils.getColorHash(value);

        return {
            root: {
                display: 'flex',
                alignItems: 'center',
                backgroundColor:
                    theme.colorScheme === 'dark' ? theme.colors[color][7] : theme.colors[color][1],
                color:
                    theme.colorScheme === 'dark' ? theme.colors[color][0] : theme.colors[color][7],
                height: 22,
                paddingLeft: 14,
                paddingRight: hasRightPadding ? 6 : 14,
                fontWeight: 500,
                fontSize: theme.fontSizes.sm,
                borderRadius: theme.radius.sm,
                cursor: 'default',
                userSelect: 'none',
                maxWidth: `calc(100%)`
            },
            text: {
                lineHeight: 1,
                fontSize: 12,
                display: 'block',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            },
            closeButton: {
                color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
                opacity: 0.6,
                marginLeft: 6
            }
        };
    }
);
