import './App.css';
import Sidebar from './components/Sidebar';
import { Outlet, useLocation } from 'react-router-dom';
import { store, useAppDispatch, useAppSelector } from './store';
import { selectCurrentUser } from './store/CurrentUserSlice';
import ConnectionScreenCover from './components/ConnectionScreenCover';
import ModalsOutlet from './components/ModalsOutlet';
import { AppShell, Box, ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { TableStyles, customTheme } from './MantineConfigs';
import { useCallback, useEffect, useState } from 'react';
import HostVerifier from './components/HostVerifier';
import { MeetingLobbyObserver } from './utils/MeetingLobbyObserver';
import { Notifications } from '@mantine/notifications';
import {
    loadFilesConfig,
    loadPlanSummaryAsync,
    selectShouldSyncSummary
} from './store/UserPreferenceSlice';
import { SummaryBar } from './components/PlanSummary/SummaryBar';
import { api } from './api/Api';
import { setAcceptedDocuments } from './store/AcceptedDocumentsSlice';
import AcceptDocuments from './components/AcceptDocuments';
import { NoUnacceptedDocumentsGuard } from './components/AcceptDocuments/NoUnacceptedDocumentsGuard';
import { GotAcceptedDocumentsEvent } from './types/Types';
import { CustomizationProvider } from './api/customization/CustomizationProvider';
import { LinkDetector } from './utils/LinkDetector';
import ApiError from './components/ApiError';

LinkDetector.setShortLinksDataProvider(() => {
    return {
        forms: store.getState().dataCache.forms
    };
});

const NoSidebar = () => <Box display="none"></Box>;

function App() {
    const [customizationInitialized, setCustomizationInitialized] = useState(false);
    const [verificationResult, setVerificationResult] = useState('');
    useEffect(() => {
        (async () => {
            try {
                await CustomizationProvider.get().init();
                setCustomizationInitialized(true);
            } catch (e) {
                console.log('Customization load error', e);
                setVerificationResult('404 Not found');
            }
        })();
    }, []);
    return customizationInitialized ? (
        <AppInner />
    ) : (
        <HostVerifier verificationResult={verificationResult} />
    );
}

function AppInner() {
    const currentUser = useAppSelector(selectCurrentUser);
    const location = useLocation();

    const nav =
        location.pathname.startsWith('/form-answer') ||
        location.pathname.startsWith('/welcome') ||
        location.pathname.startsWith('/meetingLobby/') ||
        location.pathname.startsWith('/meetingRoom') ||
        location.pathname.startsWith('/share/') ||
        location.pathname.startsWith('/formSubmitResponse/') ? (
            <NoSidebar />
        ) : currentUser.id ? (
            <NoUnacceptedDocumentsGuard>
                <Sidebar />
            </NoUnacceptedDocumentsGuard>
        ) : (
            <NoSidebar />
        );

    const [colorScheme, setColorScheme] = useState<'light' | 'dark'>('light');

    useEffect(() => {
        if (currentUser.id) {
            const savedScheme = window.localStorage.getItem(`color-scheme-${currentUser.id}`) as
                | 'light'
                | 'dark'
                | undefined;
            setColorScheme(savedScheme ? savedScheme : 'light');
        }
    }, [currentUser.id]);

    useEffect(() => {
        const meetingLobbyObserver = new MeetingLobbyObserver();
        return () => {
            meetingLobbyObserver.destroy();
        };
    }, []);

    const toggleColorScheme = (value?: ColorScheme) => {
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
        window.localStorage.setItem(
            `color-scheme-${currentUser.id}`,
            value || (colorScheme === 'dark' ? 'light' : 'dark')
        );
    };

    const dispatch = useAppDispatch();
    const shouldSyncSummary = useAppSelector(selectShouldSyncSummary);
    useEffect(() => {
        dispatch(loadFilesConfig());
    }, [dispatch, currentUser.id, currentUser.pub]);

    const syncSummary = useCallback(() => {
        dispatch(loadPlanSummaryAsync());
        if (shouldSyncSummary) {
            setTimeout(syncSummary, 1000 * 60 * 5);
        }
    }, [dispatch, shouldSyncSummary]);

    useEffect(() => {
        if (shouldSyncSummary && currentUser.isAdmin) {
            syncSummary();
        }
    }, [syncSummary, shouldSyncSummary, currentUser.id, currentUser.isAdmin]);

    useEffect(() => {
        const onGotAcceptedDocuments = (evt: GotAcceptedDocumentsEvent) => {
            dispatch(setAcceptedDocuments(evt.data));
        };
        api.addEventListener('gotaccepteddocuments', onGotAcceptedDocuments);
        return () => {
            api.removeEventListener('gotaccepteddocuments', onGotAcceptedDocuments);
        };
    }, [dispatch]);

    return (
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider
                withGlobalStyles
                withNormalizeCSS
                withCSSVariables
                theme={customTheme(colorScheme)}>
                <AppShell
                    styles={{
                        main: {
                            minHeight: '100svh',
                            height: '100svh'
                        }
                    }}
                    padding={0}
                    navbar={nav}
                    navbarOffsetBreakpoint="sm">
                    <TableStyles />
                    <NoUnacceptedDocumentsGuard>
                        <Box h="100svh" w="100%">
                            {currentUser.id && currentUser.role === 'staff' ? (
                                <Box mb={4}>
                                    <SummaryBar />
                                </Box>
                            ) : (
                                <></>
                            )}
                            <Outlet />
                            <ModalsOutlet />
                            <Notifications />
                        </Box>
                    </NoUnacceptedDocumentsGuard>
                    <ConnectionScreenCover />
                    <AcceptDocuments />
                    <ApiError />
                </AppShell>
            </MantineProvider>
        </ColorSchemeProvider>
    );
}

export default App;
