import { dbGenerator } from '../../api/Db';
import { QuestionModel, Answer, MultipleAnswerQuestionModel } from '../../types/Types';
import { FormAction, isAnswerType } from './types';

const EMPTY_QUESTION: QuestionModel = {
    title: 'New Question',
    type: 'short',
    answer: { input: '', type: 'short' },
    id: '',
    required: false
};

const EMPTY_SINGLEANSWER: (type: 'short' | 'long' | 'email' | 'phone') => Omit<Answer, 'id'> = (
    type
) => ({
    input: '',
    type
});

const EMPTY_MULTIPLEANSWERS: (type: 'select' | 'single') => Omit<Answer, 'id'> = (type) => ({
    input: 'answer1',
    type
});

type AnswerableBlock = Exclude<QuestionModel, { type: 'block' }>;

export function formReducer(state: QuestionModel[], action: FormAction): QuestionModel[] {
    switch (action.type) {
        case 'load_form':
            return JSON.parse(JSON.stringify(action.questions));
        case 'add_question':
            const newState = state.slice();
            newState.push({ ...EMPTY_QUESTION, id: dbGenerator.generateId() });
            return newState;
        case 'move_question':
            const movedState = state.slice();
            if (action.direction === 'up') {
                if (action.index === 0) return movedState;
                const movedQuesion = { ...movedState[action.index] };
                movedState[action.index] = { ...movedState[action.index - 1] };
                movedState[action.index - 1] = movedQuesion;
                return movedState;
            } else {
                if (action.index === state.length - 1) return movedState;
                const movedQuesion = { ...movedState[action.index] };
                movedState[action.index] = { ...movedState[action.index + 1] };
                movedState[action.index + 1] = movedQuesion;
                return movedState;
            }
        case 'change_title':
            const newStateTitle = state.slice();
            newStateTitle[action.index].title = action.newValue;
            return newStateTitle;
        case 'change_required':
            const newStateRequired = state.slice();
            newStateRequired[action.index].required = action.newValue;
            return newStateRequired;
        case 'change_questionType':
            const newStateQType = state.slice();
            if (action.newValue === 'block') {
                newStateQType[action.index] = {
                    type: 'block',
                    content: '',
                    id: '',
                    required: false,
                    title: ''
                };
            } else if (isAnswerType(action.newValue)) {
                if (newStateQType[action.index].type === 'block') {
                } else if (action.newValue === 'select' || action.newValue === 'single') {
                    if (
                        newStateQType[action.index].type === 'select' ||
                        newStateQType[action.index].type === 'single'
                    ) {
                        const editedBlock = newStateQType[action.index] as
                            | MultipleAnswerQuestionModel<'select'>
                            | MultipleAnswerQuestionModel<'single'>;
                        editedBlock.answer = (editedBlock.answer as Omit<Answer, 'id'>[]).map(
                            (answer) => {
                                return { ...answer, type: action.newValue as 'select' | 'single' };
                            }
                        );
                        newStateQType[action.index] = editedBlock;
                    } else if (newStateQType[action.index].type === 'block') {
                        newStateQType[action.index] = {
                            ...EMPTY_QUESTION,
                            type: action.newValue,
                            answer: [
                                { ...EMPTY_MULTIPLEANSWERS(action.newValue) },
                                { ...EMPTY_MULTIPLEANSWERS(action.newValue) }
                            ]
                        };
                    } else {
                        const editedBlock = newStateQType[action.index] as Exclude<
                            QuestionModel,
                            { type: 'block' }
                        >;

                        editedBlock.type = action.newValue;
                        editedBlock.answer = [
                            { ...EMPTY_MULTIPLEANSWERS(action.newValue) },
                            { ...EMPTY_MULTIPLEANSWERS(action.newValue) }
                        ];
                        newStateQType[action.index] = editedBlock;
                    }
                    newStateQType[action.index].type = action.newValue;
                } else if (
                    action.newValue === 'short' ||
                    action.newValue === 'long' ||
                    action.newValue === 'email' ||
                    action.newValue === 'phone'
                ) {
                    newStateQType[action.index].type = action.newValue;
                    if (newStateQType[action.index].type === 'block') {
                        newStateQType[action.index] = {
                            ...EMPTY_QUESTION,
                            type: action.newValue,
                            answer: {
                                ...EMPTY_SINGLEANSWER(action.newValue)
                            }
                        };
                    } else {
                        const editedBlock = newStateQType[action.index] as Exclude<
                            QuestionModel,
                            { type: 'block' }
                        >;

                        editedBlock.answer = {
                            ...EMPTY_SINGLEANSWER(action.newValue)
                        };
                        newStateQType[action.index] = editedBlock;
                    }
                }
            }
            return newStateQType;
        case 'add_option':
            const newStateOption = state.slice();
            if (newStateOption[action.index].type === 'block') {
                return newStateOption;
            } else if (Array.isArray((newStateOption[action.index] as AnswerableBlock).answer)) {
                ((newStateOption[action.index] as AnswerableBlock).answer as any[]).push({
                    input: 'answer',
                    type: newStateOption[action.index].type
                });
            }
            return newStateOption;
        case 'change_option':
            const newStateO = state.slice();
            if (newStateO[action.index].type === 'block') {
                return newStateO;
            } else if (Array.isArray((newStateO[action.index] as AnswerableBlock).answer)) {
                ((newStateO[action.index] as AnswerableBlock).answer as Omit<Answer, 'id'>[])[
                    action.optionIndex
                ].input = action.newValue;
            }
            return newStateO;
        case 'remove_option':
            const newStateR = state.slice();
            if (newStateR[action.index].type === 'block') {
                return newStateR;
            } else if (Array.isArray((newStateR[action.index] as AnswerableBlock).answer)) {
                (
                    (newStateR[action.index] as AnswerableBlock).answer as Omit<Answer, 'id'>[]
                ).splice(action.optionIndex, 1);
            }
            return newStateR;
        case 'remove_question':
            const reducedState = state.slice();
            reducedState.splice(action.index, 1);
            return reducedState;
        default:
            return state;
    }
}
