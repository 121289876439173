import { Dispatch, ReactNode, SetStateAction, createContext, useContext } from 'react';

export type RouteContextType =
    | { route: 'staff'; id?: never }
    | { route: 'staff/new'; id?: never; revalidate?: Function }
    | { route: 'staff/detail'; id: string }
    | { route: 'contacts'; id?: never }
    | { route: 'contacts/detail'; id: string }
    | { route: 'plan'; id?: never };

export type SelectableRoutes = Extract<
    RouteContextType['route'],
    'personal' | 'staff' | 'contacts'
>;

const routesContext = createContext<[RouteContextType, Dispatch<SetStateAction<RouteContextType>>]>(
    [{ route: 'staff' }, () => {}]
);

export const useRouteContext = () => useContext(routesContext);
export const RouteContextProvider = ({
    children,
    context
}: {
    children: ReactNode;
    context: [RouteContextType, Dispatch<SetStateAction<RouteContextType>>];
}) => {
    return <routesContext.Provider value={context}>{children}</routesContext.Provider>;
};
