import { Answer, AnswerType, FormPublicView, Question, SubmitedAnswer } from '../../../types/Types';
import { UUID } from '../../../utils/UUID';
import { ErrorType, Validator } from '../../../utils/Validator';
import * as FormsTypes from '../FormsTypes';

function collectResponses(
    questions: FormsTypes.QuestionModel[],
    refs: FormsTypes.RefsMap
): FormsTypes.Responses {
    const responses: FormsTypes.Responses = {};
    questions.forEach((model) => {
        responses[model.id] = getQuestionResponse(model, refs);
    });
    return responses;
}

function prepareAttachmentsPlaceholders(
    questions: FormsTypes.QuestionModel[]
): FormsTypes.Responses {
    const responses: FormsTypes.Responses = {};
    const filesQuestions = questions.filter((x) => x.answers[0].inputType === 'file');

    const answers: FormsTypes.Answer[] = [];
    const answersTypesMap: FormsTypes.AnswersTypesMap = {};

    for (const questionModel of filesQuestions) {
        for (const answerModel of questionModel.answers) {
            answers.push({
                questionId: questionModel.id,
                answerModelId: answerModel.id,
                value: undefined
            });
            answersTypesMap[answerModel.id] = answerModel.inputType;
        }
        responses[questionModel.id] = {
            questionId: questionModel.id,
            answers: answers,
            answersTypesMap: answersTypesMap
        };
    }
    return responses;
}

function getQuestionResponse(
    questionModel: FormsTypes.QuestionModel,
    refs: FormsTypes.RefsMap
): FormsTypes.QuestionResponse {
    const answers: FormsTypes.Answer[] = [];
    const answersTypesMap: FormsTypes.AnswersTypesMap = {};

    for (const answerModel of questionModel.answers) {
        if (answerModel.inputType === 'file') {
            answers.push({
                questionId: questionModel.id,
                answerModelId: answerModel.id,
                value: refs[answerModel.id].current.customValue as File[]
            });
        } else if (answerModel.inputType === 'block') {
            answers.push({
                questionId: questionModel.id,
                answerModelId: answerModel.id,
                value: ''
            });
        } else {
            answers.push({
                questionId: questionModel.id,
                answerModelId: answerModel.id,
                value: getHTMLInputValueByItsType(
                    refs[answerModel.id].current as HTMLInputElement,
                    answerModel.inputType
                )
            });
        }
        answersTypesMap[answerModel.id] = answerModel.inputType;
    }
    return { questionId: questionModel.id, answers: answers, answersTypesMap: answersTypesMap };
}

function validateQuestion(
    questionModel: FormsTypes.QuestionModel,
    response: FormsTypes.QuestionResponse
): FormsTypes.ValidationByType {
    const isValidByType: FormsTypes.ValidationByType = {};
    const inputType = response.answersTypesMap[response.answers[0].answerModelId];

    isValidByType['minValue'] = questionModel.validation?.minValue
        ? validateMinValue(response, questionModel.validation.minValue)
        : true;
    isValidByType['maxValue'] = questionModel.validation?.maxValue
        ? validateMaxValue(response, questionModel.validation.maxValue)
        : true;
    isValidByType['required'] = questionModel.validation?.required
        ? validateRequired(response)
        : true;

    const specialFields: AnswerType[] = ['email', 'phone'];
    if (specialFields.includes(inputType)) {
        const validationResult = validateByFieldSpecialType(response);
        isValidByType['type'] = validationResult !== false ? validationResult : true;
    } else {
        isValidByType['type'] = true;
    }
    return isValidByType;
}

function validateForm(
    questionModels: FormsTypes.QuestionModel[],
    responses: FormsTypes.Responses,
    t: (...params: any[]) => string
): FormsTypes.Errors {
    const errors: FormsTypes.Errors = {};
    const questionsMap: { [questionId: string]: FormsTypes.QuestionModel } = {};
    questionModels.every((x) => (questionsMap[x.id] = x));

    for (const questionModel of questionModels) {
        const response = responses[questionModel.id];
        const validationByType = validateQuestion(questionModel, response);
        for (const [key, value] of Object.entries(validationByType)) {
            if (!value || Array.isArray(value)) {
                if (!(questionModel.id in errors)) {
                    errors[questionModel.id] = [
                        {
                            type: key as FormsTypes.ValidationType,
                            msg: getErrorMessageByType(
                                questionModel,
                                key as FormsTypes.ValidationType,
                                value,
                                t
                            )
                        }
                    ];
                } else {
                    errors[questionModel.id].push({
                        type: key as FormsTypes.ValidationType,
                        msg: getErrorMessageByType(
                            questionModel,
                            key as FormsTypes.ValidationType,
                            value,
                            t
                        )
                    });
                }
            }
        }
    }
    return Object.values(errors).length > 0 ? errors : false;
}

function validateFormModel(model: FormPublicView, t: (...params: any[]) => string) {
    const ids: { [id: string]: Question } = {};
    for (const q of model.questions.filter((q) => q.type !== 'block')) {
        if (q.id in ids) {
            return {
                type: 'duplicated-questions',
                data: model.questions.filter((x) => x.id === q.id)
            };
        }
        ids[q.id] = q;
    }
    return true;
}

function convertModel(
    model: FormPublicView,
    t: (...params: any[]) => string
): FormsTypes.QuestionModel[] {
    return model.questions.map((question) => {
        if (question.type === 'block') {
            return {
                id: question.id as FormsTypes.QuestionId,
                text: question.title,
                answers: [
                    {
                        questionId: question.id as FormsTypes.QuestionId,
                        id: question.id as FormsTypes.AnswerModelId,
                        inputType: question.type,
                        required: false,
                        label: question.title,
                        value: question.content
                    } as FormsTypes.AnswerInputModel
                ],
                validation: {
                    required: false
                }
            };
        }

        const answers: Answer[] = Array.isArray(question.answer)
            ? question.answer
            : [question.answer];

        const groupName = answers[0].type === 'single' ? UUID.generateUUID() : undefined;
        const convAnswers: FormsTypes.AnswerInputModel[] = answers.map((x) => {
            return {
                questionId: question.id as FormsTypes.QuestionId,
                id: x.id as FormsTypes.AnswerModelId,
                inputType: x.type,
                required: false,
                label: x.input as string,
                radioGroupName: groupName
            };
        });

        const maxValueForType = getDefaultMaxValue(question.type);
        const validation: FormsTypes.Validation = {
            required: question.required,
            maxValue: getDefaultMaxValue(question.type),
            requiredExceptionMessage: t('errorMessage.requiredField'),
            maxValueExceptionMessage: maxValueForType
                ? t('errorMessage.maxLengthRequired', { count: maxValueForType })
                : undefined
        };
        return {
            id: question.id as FormsTypes.QuestionId,
            text: question.title,
            answers: convAnswers,
            validation: validation
        };
    });
}

function getDefaultMaxValue(answerType: AnswerType): number | undefined {
    if (answerType === 'short') {
        return Validator.constraints['shorttext'].maxLength;
    }
    if (answerType === 'long') {
        return Validator.constraints['richtext'].maxLength;
    }
    return undefined;
}

function convertResponsesToSubmitedAnswers(
    questions: FormsTypes.QuestionModel[],
    responses: FormsTypes.Responses | undefined,
    formAttachments: FormsTypes.SelectedAttachments
): SubmitedAnswer[] {
    const fullData: SubmitedAnswer[] = Object.values(responses || {}).map((response) => {
        const questionModel = questions.find((x) => x.id === response.questionId);
        const answers: Answer[] = [];
        for (const [i, x] of response.answers.entries()) {
            const type = response.answersTypesMap[x.answerModelId];
            if (type === 'file') {
                const iterableValue = (x.value as File[]) || [];
                for (const f of iterableValue) {
                    answers.push({
                        id: x.answerModelId,
                        type: type,
                        input: f.name
                    });
                }
                if (
                    iterableValue.length === 0 &&
                    questionModel &&
                    questionModel.id in formAttachments
                ) {
                    for (const f of formAttachments[questionModel.id]) {
                        answers.push({
                            id: x.answerModelId,
                            type: type,
                            input: f.name
                        });
                    }
                }
            } else if (type === 'select' || type === 'single') {
                if (x.value) {
                    answers.push({
                        id: x.answerModelId,
                        type: type,
                        input: questionModel?.answers[i].label || ''
                    });
                }
            } else if (type === 'block') {
                answers.push({
                    id: x.answerModelId,
                    type: 'block',
                    input: ''
                });
            } else {
                answers.push({
                    id: x.answerModelId,
                    type: type,
                    input: x.value
                } as Answer);
            }
        }

        const submitedAnswer: SubmitedAnswer = {
            id: response.questionId,
            type: response.answersTypesMap[response.answers[0].answerModelId],
            answer: answers
        };
        return submitedAnswer;
    });
    return fullData;
}

function extractAttachments(responses: FormsTypes.Responses | undefined): File[] {
    return Object.values(responses || {}).flatMap((response) => {
        const filesAnswers = response.answers.filter(
            (answer) => response.answersTypesMap[answer.answerModelId] === 'file'
        );
        return filesAnswers.flatMap((t) => (t.value ? (t.value as File[]) : []));
    });
}

// internal impl
function validateMinValue(response: FormsTypes.QuestionResponse, minValue: number): boolean {
    const inputType = response.answersTypesMap[response.answers[0].answerModelId];
    if (
        inputType === 'short' ||
        inputType === 'long' ||
        inputType === 'phone' ||
        inputType === 'email'
    ) {
        return (response.answers[0].value as string).length >= minValue;
    }
    if (inputType === 'select') {
        return response.answers.filter((x) => (x.value as boolean) === true).length >= minValue;
    }
    if (inputType === 'single') {
        return true;
    }
    if (inputType === 'file') {
        const inputVal = response.answers[0].value;
        return Array.isArray(inputVal) && inputVal.length >= minValue;
    }
    return false;
}

function validateMaxValue(response: FormsTypes.QuestionResponse, maxValue: number): boolean {
    const inputType = response.answersTypesMap[response.answers[0].answerModelId];
    if (
        inputType === 'short' ||
        inputType === 'long' ||
        inputType === 'phone' ||
        inputType === 'email'
    ) {
        return (response.answers[0].value as string).length <= maxValue;
    }
    if (inputType === 'select') {
        return response.answers.filter((x) => (x.value as boolean) === true).length <= maxValue;
    }
    if (inputType === 'single') {
        return true;
    }
    if (inputType === 'file') {
        const inputVal = response.answers[0].value;
        return Array.isArray(inputVal) && inputVal.length <= maxValue;
    }

    return false;
}

function validateRequired(response: FormsTypes.QuestionResponse) {
    const inputType = response.answersTypesMap[response.answers[0].answerModelId];
    if (
        inputType === 'short' ||
        inputType === 'long' ||
        inputType === 'phone' ||
        inputType === 'email'
    ) {
        return (response.answers[0].value as string).length > 0;
    }
    if (inputType === 'select' || inputType === 'single') {
        return response.answers.filter((x) => (x.value as boolean) === true).length > 0;
    }
    if (inputType === 'file') {
        const inputVal = response.answers[0].value;
        return Array.isArray(inputVal) && inputVal.length > 0;
    }
    return false;
}

function validateByFieldSpecialType(response: FormsTypes.QuestionResponse) {
    const inputType = response.answersTypesMap[response.answers[0].answerModelId];
    if (inputType === 'phone' || inputType === 'email') {
        const validationResult = Validator.getErrors(
            response.answers[0].value as string,
            [inputType],
            true
        );
        return validationResult;
    }
    return false;
}

function getErrorMessageByType(
    questionModel: FormsTypes.QuestionModel,
    type: FormsTypes.ValidationType,
    validationResult: boolean | ErrorType[],
    t: (...params: any[]) => string
) {
    switch (type) {
        case 'minValue':
            return questionModel.validation?.minValueExceptionMessage || undefined;
        case 'maxValue':
            return questionModel.validation?.maxValueExceptionMessage || undefined;
        case 'required':
            return questionModel.validation?.requiredExceptionMessage || undefined;
        case 'type':
            // return validationResult !== false ? validationResult : undefined;
            if (validationResult !== false) {
                if ((validationResult as ErrorType[]).includes('phone')) {
                    return t('errorMessage.invalidPhone');
                }
                if ((validationResult as ErrorType[]).includes('email')) {
                    return t('errorMessage.invalidEmail');
                }
                return undefined;
            }
            break;
        default:
            return undefined;
    }
}

function getHTMLInputValueByItsType(input: HTMLInputElement, inputType: FormsTypes.InputType) {
    if (
        inputType === 'short' ||
        inputType === 'long' ||
        inputType === 'phone' ||
        inputType === 'email'
    ) {
        return input.value;
    }
    if (inputType === 'single' || inputType === 'select') {
        return input.checked;
    }
    if (inputType === 'file') {
        return input.value;
    }
}

function isValueAString(value: FormsTypes.InputValue): value is string {
    return value !== undefined && typeof value === 'string';
}

function isValueABoolean(value: FormsTypes.InputValue): value is boolean {
    return value !== undefined && typeof value === 'boolean';
}

function isValueAFilesList(value: FormsTypes.InputValue): value is File[] {
    return (
        value !== undefined &&
        Array.isArray(value) &&
        (value.length === 0 || value[0].lastModified !== undefined)
    );
}

export {
    getQuestionResponse,
    collectResponses,
    validateQuestion,
    validateForm,
    convertModel,
    prepareAttachmentsPlaceholders,
    isValueABoolean,
    isValueAFilesList,
    isValueAString,
    convertResponsesToSubmitedAnswers,
    extractAttachments,
    validateFormModel
};
