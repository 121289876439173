import * as PmxApi from 'privmx-server-api';
import { BaseApi } from './BaseApi';

export class UserApi extends BaseApi implements PmxApi.api.user.IUserApi {
    ping(): Promise<'pong'> {
        return this.request('ping', {});
    }

    srpInfo(): Promise<PmxApi.api.user.SrpInfo> {
        return this.request('srpInfo', {});
    }

    getMyData(): Promise<PmxApi.api.user.RawMyData> {
        return this.request('getMyData', {});
    }

    setUserInfo(model: {
        data: PmxApi.api.pki.PkiKeystore;
        kis: PmxApi.api.pki.PkiSignature;
    }): Promise<PmxApi.api.pki.SerializedKeyStoreMessage> {
        return this.request('setUserInfo', model);
    }

    getPrivDataEx(): Promise<PmxApi.api.user.GetPrivDataResult> {
        return this.request('getPrivDataEx', {});
    }

    setSrp(model: PmxApi.api.user.SrpModel): Promise<PmxApi.api.core.OK> {
        return this.request('setSrp', model);
    }

    setPrivDataL2(model: { privDataL2: PmxApi.api.core.Base64 }): Promise<PmxApi.api.core.OK> {
        return this.request('setPrivDataL2', model);
    }

    authorizeWebSocket(model: {
        key: PmxApi.api.core.Base64;
        addWsChannelId: boolean;
    }): Promise<{ wsChannelId: PmxApi.api.core.WsChannelId }> {
        return this.request('authorizeWebSocket', model);
    }

    unauthorizeWebSocket(): Promise<PmxApi.api.core.OK> {
        return this.request('unauthorizeWebSocket', {});
    }

    logout(): Promise<PmxApi.api.core.OK> {
        return this.request('logout', {});
    }

    getAcceptedDocuments(): Promise<{ documents: PmxApi.api.system.AcceptedDocument[] }> {
        return this.request('getAcceptedDocuments', {});
    }

    acceptDocument(
        model: PmxApi.api.user.AcceptDocumentModel
    ): Promise<{ document: PmxApi.api.system.AcceptedDocument }> {
        return this.request('acceptDocument', model);
    }
}
