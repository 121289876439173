import { useCallback, useState } from 'react';
import {
    ActionIcon,
    Badge,
    Box,
    createStyles,
    Divider,
    Flex,
    Group,
    Input,
    Menu,
    rem,
    ScrollArea,
    SimpleGrid,
    Tabs,
    Tooltip
} from '@mantine/core';
import { RichTextEditor, RichTextEditorProps, useRichTextEditorContext } from '@mantine/tiptap';
import { IconMoodSmile, IconPaperclip, IconSend, IconVideo } from '@tabler/icons-react';
import { BubbleMenu, Editor } from '@tiptap/react';
import { faces, food, hands, nature } from '../UnicodeEmojiSelector/unicodeEmojis';
import { useTextEditorConfig } from './useTextEditorConfig';
import { useTranslation } from 'react-i18next';
import { useColorScheme } from '@mantine/hooks';
import { useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { selectFileConfig } from '../../store/UserPreferenceSlice';

export type TextEditorProps = Omit<
    RichTextEditorProps,
    'editor' | 'children' | 'onChange' | 'value'
> & {
    maxHeight?: number;
    children?: React.ReactNode;
    onAttach?: VoidFunction;
    defaultValue?: string;
    error?: string | React.ReactNode;
} & (
        | {
              onChange: (text: string) => void;
              value: string;
              editor?: never;
          }
        | {
              onChange?: never;
              value?: never;
              editor?: Editor | null;
          }
    );

type FullTextEditorProps = TextEditorProps & {
    scheduleVideo?: never;
    onSubmit?: never;
    attachments?: never;
};

type SmallTextEditorProps = TextEditorProps & {
    scheduleVideo?: () => void;
    onSubmit?: () => void;
    attachments?: React.ReactNode;
    isEditing?: boolean;
};

const useTextEditorStyles = createStyles((theme, { type }: { type: 'small' | 'full' }) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        fontSize: theme.fontSizes.md,
        margin: 1,
        position: 'relative',
        '&:focus-within': {
            margin: 0,
            border: `${rem(2)} solid ${
                theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4]
            }`
        },
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : 'transparent'
    },
    editor: {},
    content: {
        height: '98%',
        maxWidth: type === 'full' ? `calc(63vw - ${rem(36)})` : 'auto',
        wordBreak: 'break-word',
        '& .ProseMirror': {
            flexGrow: 1,
            padding: `${rem(8)} ${rem(16)}`,
            paddingLeft: type === 'full' ? rem(8) : 0,
            height: '100%'
        }
    },
    stylesProvider: {
        overflow: 'visible',
        display: 'table-row',
        minHeight: '98%',
        height: '98%'
    },
    fullHeight: { overflow: 'visible', height: '100%' },
    flexGrow: { flexGrow: 1 },
    viewport: { overflow: 'visible', minHeight: '100%', '& > div': { height: '100%' } },
    addFileIcon: {
        placeSelf: 'flex-start',
        marginTop: rem(6)
    },
    editingBadge: {
        backgroundColor: theme.colorScheme === 'dark' ? undefined : theme.colors.gray[0],
        zIndex: 2,
        position: 'absolute',
        top: `calc(${rem(16)} * -1)`,
        left: 0
    }
}));

const ICON_SIZE = rem(18);

type EditorProps =
    | ({ type: 'full' } & FullTextEditorProps)
    | ({ type: 'small' } & SmallTextEditorProps);

export default function TextEditor({ type, ...props }: EditorProps) {
    if (type === 'full') {
        return <FullTextEditor {...props} />;
    }
    return <SmallTextEditor {...props} />;
}

export function SmallTextEditor({
    onChange,
    maxHeight,
    value,
    editor: configEditor,
    onAttach,
    defaultValue,
    scheduleVideo,
    onSubmit,
    attachments,
    isEditing,
    error,
    ...props
}: SmallTextEditorProps) {
    const { t } = useTranslation();
    const defaultEditor = useTextEditorConfig({
        content: defaultValue,
        placeholder: t('placeholder.newMessage'),
        onUpdate: ({ editor }) => {
            if (!onChange) return;
            onChange(JSON.stringify(editor.getJSON()));
        }
    });
    const { classes, cx } = useTextEditorStyles({ type: 'small' });
    const currentUser = useAppSelector(selectCurrentUser);

    const editor = configEditor || defaultEditor;

    const theme = useColorScheme();

    const fileConfig = useAppSelector(selectFileConfig);
    return (
        <Input.Wrapper error={error}>
            <RichTextEditor
                editor={editor}
                classNames={{
                    content: classes.content,
                    typographyStylesProvider: cx(classes.stylesProvider, classes.flexGrow),
                    root: classes.root
                }}
                {...props}>
                {isEditing && (
                    <Badge
                        size="sm"
                        color={theme === 'dark' ? 'gray' : 'gray'}
                        variant="outline"
                        className={classes.editingBadge}>
                        Editing
                    </Badge>
                )}
                {attachments ? (
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            flexGrow: 1,
                            flexDirection: 'column'
                        }}>
                        <ScrollArea offsetScrollbars sx={{ flexGrow: 1 }}>
                            <Group p="xs" pb={0} noWrap>
                                {attachments}
                            </Group>
                        </ScrollArea>
                        <Divider mx="xs" />
                    </Box>
                ) : null}
                <Flex sx={{ overflow: 'visible' }} direction="row" align={'center'} w="100%">
                    {onAttach && (
                        <Tooltip
                            label={
                                t('action.attachFile') +
                                ` (Max. number of attachments: ${fileConfig.maxFilesCount})`
                            }
                            onClick={() => onAttach()}>
                            <ActionIcon ml="xs" className={classes.addFileIcon}>
                                <IconPaperclip size={ICON_SIZE} />
                            </ActionIcon>
                        </Tooltip>
                    )}

                    <ScrollArea.Autosize
                        ml="xs"
                        classNames={{
                            root: classes.fullHeight,
                            viewport: classes.viewport
                        }}
                        h="100%"
                        mah={maxHeight ? maxHeight : 200}
                        sx={{ flexGrow: 1, overflow: 'visible' }}>
                        {editor && (
                            <BubbleMenu editor={editor}>
                                <RichTextEditor.ControlsGroup>
                                    <RichTextEditor.Bold />
                                    <RichTextEditor.Italic />
                                    <RichTextEditor.Underline />
                                    <RichTextEditor.Strikethrough />
                                    <RichTextEditor.ClearFormatting />
                                </RichTextEditor.ControlsGroup>
                            </BubbleMenu>
                        )}
                        <RichTextEditor.Content p={0} />
                    </ScrollArea.Autosize>

                    <Tooltip label="Add emoji">
                        <ActionIcon mr={4} className={classes.addFileIcon}>
                            <EmojiSelect />
                        </ActionIcon>
                    </Tooltip>
                    {scheduleVideo && currentUser.role === 'staff' && (
                        <Tooltip label="Schedule new meeting">
                            <ActionIcon
                                onClick={scheduleVideo}
                                mr={4}
                                className={classes.addFileIcon}>
                                <IconVideo size={ICON_SIZE} />
                            </ActionIcon>
                        </Tooltip>
                    )}
                    {onSubmit && (
                        <Tooltip label="Send">
                            <ActionIcon
                                onClick={onSubmit}
                                type="button"
                                color="brand"
                                mr="xs"
                                variant="light"
                                className={classes.addFileIcon}>
                                <IconSend size={ICON_SIZE} />
                            </ActionIcon>
                        </Tooltip>
                    )}
                </Flex>
            </RichTextEditor>
        </Input.Wrapper>
    );
}

export function FullTextEditor({
    onChange,
    maxHeight,
    value,
    editor: configEditor,
    onAttach,
    defaultValue,
    error,
    ...props
}: TextEditorProps) {
    const editor = useTextEditorConfig({
        content: defaultValue,
        placeholder: 'Type your message...',
        onUpdate: ({ editor }) => {
            if (!onChange) return;
            onChange(editor.getHTML());
        }
    });
    const { classes } = useTextEditorStyles({ type: 'full' });

    return (
        <>
            <Input.Error>{error}</Input.Error>
            <RichTextEditor
                editor={configEditor || editor}
                classNames={{
                    content: classes.content,
                    typographyStylesProvider: classes.stylesProvider,
                    root: classes.root
                }}
                {...props}>
                <RichTextEditor.Toolbar tabIndex={2}>
                    <RichTextEditor.ControlsGroup key={1}>
                        <RichTextEditor.Bold tabIndex={2} />
                        <RichTextEditor.Italic tabIndex={2} />
                        <RichTextEditor.Underline tabIndex={2} />
                        <RichTextEditor.Strikethrough tabIndex={2} />
                        <RichTextEditor.ClearFormatting tabIndex={2} />
                        <RichTextEditor.Code tabIndex={2} />
                    </RichTextEditor.ControlsGroup>
                    <RichTextEditor.ControlsGroup key={2}>
                        {onAttach && <ControllGroupAttachFiles onAttach={onAttach} />}
                        <RichTextEditor.Control tabIndex={2}>
                            <EmojiSelect />
                        </RichTextEditor.Control>
                    </RichTextEditor.ControlsGroup>
                </RichTextEditor.Toolbar>
                <ScrollArea.Autosize
                    classNames={{
                        root: classes.fullHeight,
                        viewport: classes.viewport
                    }}
                    h="100%"
                    mah={maxHeight ? maxHeight : 200}
                    sx={{ flexGrow: 1, overflow: 'visible' }}>
                    <RichTextEditor.Content tabIndex={1} p={0} />
                </ScrollArea.Autosize>
            </RichTextEditor>
        </>
    );
}

function ControllGroupAttachFiles({ onAttach }: { onAttach: VoidFunction }) {
    const { t } = useTranslation();
    return (
        <Tooltip label={t('action.attachFile')} onClick={onAttach}>
            <RichTextEditor.Control tabIndex={2}>
                <IconPaperclip size={16} />
            </RichTextEditor.Control>
        </Tooltip>
    );
}

function EmojiSelect() {
    const { editor } = useRichTextEditorContext();

    const [opened, setOpened] = useState(false);

    const appendEmoji = useCallback(
        (emoji: string) => {
            editor.commands?.insertContent(emoji);
            editor.commands?.focus();
            setOpened(false);
        },
        [editor?.commands]
    );

    const [currentTab, setCurrentTab] = useState<string | null>('faces');

    return (
        <Menu shadow="md" position="top-end" opened={opened} onChange={setOpened}>
            <Menu.Target>
                <div>
                    <ActionIcon component="div" variant="subtle">
                        <IconMoodSmile size={ICON_SIZE} />
                    </ActionIcon>
                </div>
            </Menu.Target>
            <Menu.Dropdown>
                <Tabs
                    defaultValue="faces"
                    value={currentTab}
                    onTabChange={(tab) => setCurrentTab(tab)}>
                    <Tabs.List position="center">
                        <Box p="xs" onClick={() => setCurrentTab('faces')}>
                            &#x1F642;
                        </Box>
                        <Box p="xs" onClick={() => setCurrentTab('hands')}>
                            &#x1F590;
                        </Box>
                        <Box p="xs" onClick={() => setCurrentTab('nature')}>
                            &#x1F415;
                        </Box>
                        <Box p="xs" onClick={() => setCurrentTab('food')}>
                            &#x1F353;
                        </Box>
                    </Tabs.List>

                    <Tabs.Panel value="faces">
                        <EmojiList emojis={faces} onSelect={appendEmoji} />
                    </Tabs.Panel>
                    <Tabs.Panel value="hands">
                        <EmojiList emojis={hands} onSelect={appendEmoji} />
                    </Tabs.Panel>
                    <Tabs.Panel value="nature">
                        <EmojiList emojis={nature} onSelect={appendEmoji} />
                    </Tabs.Panel>
                    <Tabs.Panel value="food">
                        <EmojiList emojis={food} onSelect={appendEmoji} />
                    </Tabs.Panel>
                </Tabs>
            </Menu.Dropdown>
        </Menu>
    );
}

function EmojiList({ emojis, onSelect }: { emojis: string[]; onSelect: (emoji: string) => void }) {
    return (
        <ScrollArea.Autosize w="100%" mah={120} offsetScrollbars>
            <SimpleGrid cols={5} spacing={0}>
                {emojis.map((emoji, idx) => (
                    <ActionIcon component="div" key={idx} onClick={() => onSelect(emoji)}>
                        {emoji}
                    </ActionIcon>
                ))}
            </SimpleGrid>
        </ScrollArea.Autosize>
    );
}
