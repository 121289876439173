import { Menu, TextInput, rem, ScrollArea } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import { Tag } from '../../../types/Types';
import { FilterDropdownComponent } from '../types';

export const FilterSelectTagDropdown: FilterDropdownComponent<{ tags: Tag[] }> = ({
    tags,
    onSelect
}) => {
    const [queryName, setQueryName] = useInputState('');

    const filteredTags = tags.filter((tag) => tag.includes(queryName));

    return (
        <Menu.Dropdown>
            <TextInput
                size={'xs'}
                placeholder={'Search tag'}
                icon={<IconSearch size={rem(14)} />}
                onChange={setQueryName}
                value={queryName}
                styles={{ input: { border: 0 } }}
            />
            <Menu.Divider mb="sm" />
            <ScrollArea.Autosize h={140}>
                {filteredTags.map((t) => (
                    <Menu.Item key={t} onClick={() => onSelect(`tag:${t}`)}>
                        {t}
                    </Menu.Item>
                ))}
            </ScrollArea.Autosize>
        </Menu.Dropdown>
    );
};
