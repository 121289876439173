import { Box, Flex, Group, MediaQuery, Text } from '@mantine/core';
import { useContext, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import UserAvatar from '../../../atoms/UserAvatar';
import useToast from '../../../hooks/useToast';
import { CompanyBadge } from '../../../mantineAtoms/CompanyBadge';
import { useConfirmDialog } from '../../../mantineAtoms/ConfirmDialog';
import { useAppDispatch, useAppSelector } from '../../../store';
import { toggleNewComapnyModal } from '../../../store/ModalsSlice';
import { Company, CompanyId, Contact } from '../../../types/Types';
import { isDirtyContext } from '../ContactsScreenView';
import { api } from '../../../api/Api';
import { useContactStyleElement } from './ContactListElementStyles.styles';
import { TagList } from '../../../mantineAtoms/TagList';
import { mutateContact, selectContactName } from '../../../store/DataCacheSlice';
import { PrivateContactBadge } from '../PrivateContactBadge';

export function ContactListElement({
    contact,
    active,
    contactCompany
}: {
    active?: boolean;
    contactCompany?: Company;
    contact: Contact;
}) {
    const { classes, cx } = useContactStyleElement();
    const dispatch = useAppDispatch();
    const { isDirty } = useContext(isDirtyContext);
    const toast = useToast();

    const contactName = useAppSelector(selectContactName(contact.username)) ?? contact.name;

    const { openDialog } = useConfirmDialog();

    const handleCompanyCreate = useCallback(
        (query: string) => {
            dispatch(
                toggleNewComapnyModal({
                    opened: true,
                    initialUsers: [contact],
                    initialName: query
                })
            );
        },
        [contact, dispatch]
    );

    const handleAddComapny = useCallback(
        async (companyId: string) => {
            try {
                const newContact = await api.updateContact({
                    ...contact,
                    companyId: companyId as CompanyId
                });
                dispatch(mutateContact(newContact));
            } catch (error) {
                console.error(error);
                toast('Something went wrong. Try again', 'error');
            }
        },
        [contact, toast, dispatch]
    );

    const companyTag = contactCompany ? (
        <CompanyBadge companyId={contactCompany.id}>
            <Text>{contactCompany.name}</Text>
        </CompanyBadge>
    ) : (
        <CompanyBadge
            className={'addCompany'}
            onCreate={handleCompanyCreate}
            onSelect={handleAddComapny}
        />
    );

    const name = (() => {
        if ((contactName as string) === contact.email) {
            return contact.username;
        }
        return contactName ? contactName : contact.username;
    })();

    const navigate = useNavigate();

    function handleNavigate() {
        if (isDirty) {
            openDialog({
                onAcceptCallback() {
                    navigate(`${contact.id}${document.location.search}`);
                }
            });
        } else {
            navigate(`${contact.id}${document.location.search}`);
        }
    }

    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!active) {
            return;
        }
        setTimeout(() => {
            containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 0);
    }, [active]);

    return (
        <Box
            ref={containerRef}
            p="sm"
            onClick={(e: any) => {
                handleNavigate();
            }}
            className={cx(classes.root, { [classes.active]: active })}>
            <Flex align="center" justify="space-between">
                <Group spacing={'xs'} noWrap sx={{ flexGrow: 1, flexBasis: '40%', flexShrink: 1 }}>
                    <UserAvatar size={24} showTooltip={false} username={contact.username} />
                    <Box>
                        {name ? (
                            <>
                                <Group>
                                    <Text color="dimmed" size={13}>
                                        {name}
                                    </Text>
                                    {contact.isPrivate && <PrivateContactBadge />}
                                </Group>
                                <Text size={13}>{contact.email}</Text>
                            </>
                        ) : (
                            <Group>
                                <Text size={13}>{contact.email}</Text>
                                {contact.isPrivate && <PrivateContactBadge />}
                            </Group>
                        )}
                    </Box>
                </Group>
                <Flex align={'center'} justify={'flex-end'} w="60%" ml="auto">
                    <MediaQuery smallerThan={'xl'} styles={{ display: 'none' }}>
                        <Box w="100%">
                            <TagList className={classes.tags} tags={contact.tags} />
                        </Box>
                    </MediaQuery>
                    <Box sx={{ flexShrink: 0 }}>{companyTag}</Box>
                </Flex>
            </Flex>
        </Box>
    );
}
