import * as PmxApi from 'privmx-server-api';
import { PrivateTagsChangeEvent, Tag } from '../../types/Types';
import { KvdbSettingEntryX } from './kvdb/KvdbUtils';
import { TagEncryptionService } from './TagEncryptionService';
import { Utils } from './utils/Utils';

export type TagScope = 'private' | 'shared';

interface EventDispatcher {
    dispatchEvent<T extends { type: string }>(event: T): void;
}

type Tags = Tag[];
export type KvdbEntryType = KvdbSettingEntryX<Tags | null>;

export interface UserSettingsKvdb {
    getSync(key: string): KvdbEntryType | undefined;
    getAllSync(): KvdbEntryType[];
    set(key: string, value: KvdbEntryType): Promise<void>;
}

export type TagKeyGroup =
    | 'company'
    | 'contact'
    | 'draft'
    | 'draftAttachment'
    | 'inquiry'
    | 'inquirySubmit'
    | 'inquirySubmitAttachment'
    | 'sharedFileFile'
    | 'thread'
    | 'threadAttachment';

export type TagTypeFilter = 'system' | 'user' | 'all';

export interface SharedTagsContainer {
    tags: PmxApi.api.tag.Tag[];
}

export interface TaggableEntity {
    tags: Tags;
    archived: boolean;
    pinned: boolean;
}

export class TagService {
    static readonly TAG_SCOPE: TagScope = 'private';
    static readonly SYSTEM_TAG_PREFIX = 'system:';
    static readonly SYSTEM_TAG_ARCHIVED = `${this.SYSTEM_TAG_PREFIX}archived` as Tag;
    static readonly SYSTEM_TAG_PINNED = `${this.SYSTEM_TAG_PREFIX}pinned` as Tag;

    private static readonly KEY_PREFIX = 'privateTags';

    constructor(
        private userSettingsKvdb: UserSettingsKvdb,
        private eventDispatcher: EventDispatcher,
        private tagEncryptionService: TagEncryptionService
    ) {}

    isPrivateScope(): boolean {
        return TagService.TAG_SCOPE === 'private';
    }

    async getTags(
        group: TagKeyGroup,
        id: string,
        filter: TagTypeFilter,
        sharedTagsContainer: SharedTagsContainer
    ): Promise<Tags> {
        let tags: Tags = [];
        if (TagService.TAG_SCOPE === 'private') {
            const key = this.getKvdbKey(group, id);
            tags = this.userSettingsKvdb.getSync(key)?.secured.value ?? [];
        } else {
            tags = await this.tagEncryptionService.tryDecryptTags(sharedTagsContainer.tags);
        }
        if (filter === 'system') {
            return tags.filter((x) => x.startsWith(TagService.SYSTEM_TAG_PREFIX));
        }
        if (filter === 'user') {
            return tags.filter((x) => !x.startsWith(TagService.SYSTEM_TAG_PREFIX));
        }
        return tags;
    }

    async getTagsAsTaggableEntity(
        group: TagKeyGroup,
        id: string,
        sharedTagsContainer: SharedTagsContainer
    ): Promise<TaggableEntity> {
        const tags = await this.getTags(group, id, 'all', sharedTagsContainer);
        return this.mapTagsToTaggableEntity(tags);
    }

    mapTagsToTaggableEntity(tags: Tags): TaggableEntity {
        const taggableEntity: TaggableEntity = {
            tags: tags.filter((x) => !x.startsWith(TagService.SYSTEM_TAG_PREFIX)),
            archived: tags.includes(TagService.SYSTEM_TAG_ARCHIVED),
            pinned: tags.includes(TagService.SYSTEM_TAG_PINNED)
        };
        return taggableEntity;
    }

    async setTags(
        group: TagKeyGroup,
        id: string,
        tags: Tags,
        sharedTagsContainer: SharedTagsContainer
    ): Promise<void> {
        if (TagService.TAG_SCOPE === 'private') {
            const key = this.getKvdbKey(group, id);
            const value = tags.length > 0 ? Utils.unique(tags) : null;
            await this.userSettingsKvdb.set(key, { secured: { value } });
            this.dispatchChangeEvent(group, id, tags);
        } else {
            sharedTagsContainer.tags = await this.tagEncryptionService.encryptTags(tags);
        }
    }

    async setTagsOrGetEncrypted(
        group: TagKeyGroup,
        id: string,
        tags: Tags
    ): Promise<PmxApi.api.tag.Tag[]> {
        const sharedTagsContainer: SharedTagsContainer = { tags: [] };
        await this.setTags(group, id, tags, sharedTagsContainer);
        if (TagService.TAG_SCOPE === 'private') {
            return [];
        } else {
            sharedTagsContainer.tags = await this.tagEncryptionService.encryptTags(tags);
            return sharedTagsContainer.tags;
        }
    }

    async getEncryptedTagsIfSharedScope(tags: Tags): Promise<PmxApi.api.tag.Tag[]> {
        if (TagService.TAG_SCOPE === 'shared') {
            return this.tagEncryptionService.encryptTags(tags);
        }
        return [];
    }

    async setTagsIfPrivateScope(group: TagKeyGroup, id: string, tags: Tags): Promise<void> {
        if (TagService.TAG_SCOPE === 'private') {
            return this.setTags(group, id, tags, { tags: [] });
        }
    }

    async setTagsFromTaggableEntity(
        group: TagKeyGroup,
        id: string,
        taggableEntity: TaggableEntity,
        sharedTagsContainer: SharedTagsContainer
    ): Promise<void> {
        const tags = this.readAllTagsFromTaggableEntity(taggableEntity);
        if (TagService.TAG_SCOPE === 'private') {
            const key = this.getKvdbKey(group, id);
            const value = tags.length > 0 ? Utils.unique(tags) : null;
            await this.userSettingsKvdb.set(key, { secured: { value } });
            this.dispatchChangeEvent(group, id, tags);
        } else {
            sharedTagsContainer.tags = await this.tagEncryptionService.encryptTags(tags);
        }
    }

    async clearTags(
        group: TagKeyGroup,
        id: string,
        sharedTagsContainer: SharedTagsContainer
    ): Promise<void> {
        return this.setTags(group, id, [], sharedTagsContainer);
    }

    async addTag(
        group: TagKeyGroup,
        id: string,
        tag: Tag,
        sharedTagsContainer: SharedTagsContainer
    ): Promise<void> {
        return this.addTags(group, id, [tag], sharedTagsContainer);
    }

    async addTags(
        group: TagKeyGroup,
        id: string,
        tags: Tags,
        sharedTagsContainer: SharedTagsContainer
    ): Promise<void> {
        const allTags = [...(await this.getTags(group, id, 'all', sharedTagsContainer)), ...tags];
        return this.setTags(group, id, allTags, sharedTagsContainer);
    }

    async removeTag(
        group: TagKeyGroup,
        id: string,
        tag: Tag,
        sharedTagsContainer: SharedTagsContainer
    ): Promise<void> {
        return this.removeTags(group, id, [tag], sharedTagsContainer);
    }

    async removeTags(
        group: TagKeyGroup,
        id: string,
        tags: Tags,
        sharedTagsContainer: SharedTagsContainer
    ): Promise<void> {
        const newTags = (await this.getTags(group, id, 'all', sharedTagsContainer)).filter(
            (tag) => !tags.includes(tag)
        );
        return this.setTags(group, id, newTags, sharedTagsContainer);
    }

    async toggleTag(
        group: TagKeyGroup,
        id: string,
        tag: Tag,
        sharedTagsContainer: SharedTagsContainer,
        state?: boolean
    ): Promise<Tags> {
        let tags = await this.getTags(group, id, 'all', sharedTagsContainer);
        if (tags.includes(tag)) {
            if (state === false || state === undefined) {
                tags = tags.filter((x) => x !== tag);
            }
        } else {
            if (state === true || state === undefined) {
                tags = [...tags, tag];
            }
        }
        await this.setTags(group, id, tags, sharedTagsContainer);
        return tags;
    }

    readAllTagsFromTaggableEntity(taggableEntity: TaggableEntity): Tags {
        const tags = [...taggableEntity.tags];
        if (taggableEntity.archived) {
            tags.push(TagService.SYSTEM_TAG_ARCHIVED);
        }
        if (taggableEntity.pinned) {
            tags.push(TagService.SYSTEM_TAG_PINNED);
        }
        return tags;
    }

    private getKvdbKey(group: TagKeyGroup, id: string) {
        return `${TagService.KEY_PREFIX}/${group}/${id}`;
    }

    private dispatchChangeEvent(tagKeyGroup: TagKeyGroup, id: string, tags: Tags): void {
        this.eventDispatcher.dispatchEvent<PrivateTagsChangeEvent>({
            type: 'privatetagschange',
            targetType: tagKeyGroup,
            targetId: id,
            tags: tags,
            taggableEntity: this.mapTagsToTaggableEntity(tags)
        });
    }
}

export class MockUserSettingsKvdbForTagService implements UserSettingsKvdb {
    getSync(_key: string): KvdbEntryType | undefined {
        return undefined;
    }

    getAllSync(): KvdbEntryType[] {
        return [];
    }

    async set(_key: string, _value: KvdbEntryType): Promise<void> {}
}
