import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { api } from '../../../../api/Api';
import { MeetingEditLoaderData } from '../../../../utils/router/loaders';
import { Timestamp } from '../../../../types/Types';
import * as types from '../../../../types/Types';
import { useMemo } from 'react';
import { Deferred } from '../../../../utils/Deferred';
import ModalForm from '../../../ModalForm';
import { DateTimePicker } from '@mantine/dates';
import { Anchor, Button, Checkbox, Select } from '@mantine/core';
import { getMeetingFromData, MeetingFormData, useTags } from '../utils';
import ConfirmModal from '../../../../atoms/ConfirmModal';
import { usePreventClose } from '../../../../hooks/usePreventClose';
import { useTranslation } from 'react-i18next';
import { UserUtils } from '../../../../utils/UserUtils';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectUsernameToName } from '../../../../store/DataCacheSlice';
import { meetingDurations } from '../MeetingAddPage';
import { useUserInteraction } from '../../../../hooks/useUserInteraction';
import { Link } from 'react-router-dom';
import { toggleAccountsAddedtModal } from '../../../../store/ModalsSlice';

function timestampToSpan(timestamp: Timestamp | undefined | number): string {
    if (!timestamp) return '00:00';
    const date = new Date(timestamp);
    const h = date.getHours().toString().padStart(2, '0');
    const m = date.getMinutes().toString().padStart(2, '0');
    return `${h}:${m}`;
}

export const MeetingEditPage = forwardRef(
    (props: { meetingEdit: MeetingEditLoaderData; handleClose: () => void }, ref) => {
        const { t } = useTranslation();
        const meeting =
            props.meetingEdit.meeting && typeof props.meetingEdit.meeting === 'object'
                ? props.meetingEdit.meeting
                : null;
        const meetingId = meeting?.id;
        const [confirmModalDeferred, setConfirmModalDeferred] = useState<
            Deferred<boolean> | undefined
        >(undefined);
        const displayMap = useAppSelector(selectUsernameToName);

        const form = useForm<MeetingFormData>({
            defaultValues: {
                tags: props.meetingEdit.meeting?.tags.map((x) => ({ label: x, value: x })) ?? [],
                title: props.meetingEdit.meeting?.title ?? '',
                duration: props.meetingEdit.meeting?.duration,
                startDate: props.meetingEdit.meeting?.startDate,
                span: [
                    timestampToSpan(props.meetingEdit.meeting?.startDate),
                    timestampToSpan(
                        (props.meetingEdit.meeting?.startDate || 0) +
                            (props.meetingEdit.meeting?.duration || 0)
                    )
                ],
                users: UserUtils.usernameOrIdToOption(
                    meeting?.users.map((x) => ({ type: 'user', username: x })) || [],
                    props.meetingEdit.users,
                    displayMap
                ),
                managers: UserUtils.usernameOrIdToOption(
                    meeting?.managers.map((x) => ({ type: 'user', username: x })) || [],
                    props.meetingEdit.managers.map((manager) =>
                        UserUtils.userUnionToUserOrContact(manager)
                    ),
                    displayMap
                )
            }
        });
        const getValues = form.getValues;
        const isDirty = form.formState.isDirty;
        const setError = form.setError;
        const handleClose = props.handleClose;
        const [updateKeysWhenAddingUsers, setUpdateKeysWhenAddingUsers] = useState(true);
        usePreventClose(isDirty);

        useImperativeHandle(ref, () => ({
            beforeClose() {
                if (!isDirty) {
                    return true;
                }
                const deferred = new Deferred<boolean>();
                setConfirmModalDeferred(deferred);
                return deferred.promise;
            }
        }));

        const dispatch = useAppDispatch();

        const editMeeting = useCallback(
            async (data: MeetingFormData) => {
                const newMeeting = getMeetingFromData(data, meetingId);
                if ('id' in newMeeting) {
                    const { newAccounts, privateContactsMadeVisible } = await api.updateMeeting(
                        newMeeting,
                        updateKeysWhenAddingUsers
                    );
                    if (
                        newMeeting.duration !== props.meetingEdit.meeting?.duration ||
                        newMeeting.startDate !== props.meetingEdit.meeting.startDate
                    ) {
                        const msg = {
                            type: 'meetingEdit',
                            prevStartDate: props.meetingEdit.meeting?.startDate,
                            currentStartDate: newMeeting.startDate,
                            prevDuration: props.meetingEdit.meeting?.duration,
                            currentDuration: newMeeting.duration
                        };
                        const msgId = api.generateMessageId();
                        api.sendMeetingMessage(
                            newMeeting.id,
                            'application/json',
                            JSON.stringify(msg),
                            msgId,
                            []
                        );
                    }
                    if (newAccounts.size > 0 || privateContactsMadeVisible.length > 0) {
                        dispatch(
                            toggleAccountsAddedtModal({
                                open: true,
                                title: t('modal.topicAdd.sendPasswords'),
                                addedUsers: Array.from(newAccounts.entries()),
                                privateContactsMadeVisible
                            })
                        );
                    }
                }
            },
            [
                dispatch,
                t,
                meetingId,
                props.meetingEdit.meeting?.duration,
                props.meetingEdit.meeting?.startDate,
                updateKeysWhenAddingUsers
            ]
        );

        const { startInteraction, isProcessing } = useUserInteraction({
            successTitle: 'Meeting Edited',
            successMessage: (
                <Anchor component={Link} to={`/meetings/${meeting?.id}`}>
                    Go to meeting
                </Anchor>
            ),
            action: editMeeting,
            onSuccess: handleClose
        });

        const handleFormSubmit = useCallback(
            async (data: MeetingFormData) => {
                if (!isDirty) {
                    handleClose();
                    return;
                }
                if (data.title.trim() === '') {
                    setError('title', {});
                    return;
                }
                startInteraction(data);
            },
            [startInteraction, isDirty, handleClose, setError]
        );

        const handleConfirmModalSaveClick = useCallback(() => {
            confirmModalDeferred?.resolve(false);
            setConfirmModalDeferred(undefined);
            handleFormSubmit(getValues());
        }, [handleFormSubmit, confirmModalDeferred, setConfirmModalDeferred, getValues]);

        const handleConfirmModalCancelClick = useCallback(() => {
            confirmModalDeferred?.resolve(false);
            setConfirmModalDeferred(undefined);
        }, [confirmModalDeferred, setConfirmModalDeferred]);

        const handleConfirmModalRejectChangesClick = useCallback(() => {
            confirmModalDeferred?.resolve(true);
            setConfirmModalDeferred(undefined);
        }, [confirmModalDeferred, setConfirmModalDeferred]);

        const contacts = useMemo(() => {
            return props.meetingEdit.users
                .filter((user) => user.type === 'contact')
                .map((user) => (user as types.ContactOpt).contact);
        }, [props.meetingEdit.users]);

        const { initialTagOptions, setNewTags, tagOptions } = useTags(
            props.meetingEdit.meeting?.tags
        );

        return (
            <ModalForm
                onSubmit={handleFormSubmit}
                form={form}
                onClose={handleClose}
                isProcesing={isProcessing}
                loadingMessage={'Saving changes'}>
                <ModalForm.TextField
                    span={2}
                    name="title"
                    label={t('entityProps.title')}
                    required
                />
                <ModalForm.UserSelectField
                    span={2}
                    keepCurrentUser
                    name="users"
                    label={t('entityProps.users')}
                    contacts={contacts}
                />
                <ModalForm.UserSelectField
                    span={2}
                    keepCurrentUser
                    label={t('entityProps.management')}
                    name="managers"
                    contacts={contacts}
                />

                <ModalForm.Field>
                    <Controller
                        control={form.control}
                        name="startDate"
                        render={({ field }) => {
                            return (
                                <DateTimePicker
                                    popoverProps={{
                                        withinPortal: true,
                                        middlewares: { shift: true, flip: true }
                                    }}
                                    minDate={new Date()}
                                    value={new Date(field.value)}
                                    onChange={(selectedDate) =>
                                        field.onChange(selectedDate?.getTime())
                                    }
                                    label="Pick date and time"
                                    placeholder="Pick date and time"
                                    maw={400}
                                />
                            );
                        }}
                    />
                </ModalForm.Field>
                <ModalForm.Field>
                    <Controller
                        control={form.control}
                        name="duration"
                        render={({ field }) => {
                            return (
                                <Select
                                    data={meetingDurations}
                                    value={field.value?.toString()}
                                    onChange={(selectedDuration) =>
                                        field.onChange(parseInt(selectedDuration || ''))
                                    }
                                    label="Select duration"
                                    placeholder="Pick date and time"
                                    maw={400}
                                />
                            );
                        }}
                    />
                </ModalForm.Field>

                <ModalForm.TagsSelectField
                    name="tags"
                    label={t('entityProps.tags')}
                    defaultValue={initialTagOptions}
                    addNewTag={(string) => {
                        setNewTags((prev) => [...prev, string as types.Tag]);
                    }}
                    tagOptions={tagOptions}
                />
                <ModalForm.Field span={2}>
                    <Checkbox
                        checked={!updateKeysWhenAddingUsers}
                        label={t('addedUsersCanSeeOldStuff')}
                        onChange={(event) =>
                            setUpdateKeysWhenAddingUsers(!event.currentTarget.checked)
                        }
                    />
                </ModalForm.Field>
                <ConfirmModal
                    opened={Boolean(confirmModalDeferred)}
                    onClose={handleConfirmModalCancelClick}
                    title={t('warningMessage.unsavedChangesWhatToDo')}>
                    <>
                        <Button type="button" onClick={handleConfirmModalSaveClick}>
                            {t('action.save')}
                        </Button>
                        <Button type="button" onClick={handleConfirmModalRejectChangesClick}>
                            {t('action.rejectChanges')}
                        </Button>
                        <Button type="button" onClick={handleConfirmModalCancelClick}>
                            {t('action.cancel')}
                        </Button>
                    </>
                </ConfirmModal>
            </ModalForm>
        );
    }
);

export default MeetingEditPage;
