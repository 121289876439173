import {
    Group,
    rem,
    Flex,
    Stack,
    Title,
    Tooltip,
    ActionIcon,
    Divider,
    Button,
    Paper,
    Avatar,
    Text,
    ScrollArea,
    TextInput,
    Box,
    Kbd,
    MediaQuery,
    Menu
} from '@mantine/core';
import {
    IconMail,
    IconVideo,
    IconBuilding,
    IconPhone,
    IconHome,
    IconNote,
    IconExternalLink,
    IconUsers,
    IconWorld,
    IconEdit,
    IconDeviceFloppy,
    IconX,
    IconChevronLeft,
    IconDotsVertical
} from '@tabler/icons-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useToast from '../../../hooks/useToast';
import LinkWithFilter from '../../../mantineAtoms/SearchBar/LinkWithFilter';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
    mutateCompany,
    mutateContact,
    selectCachedContacts,
    setContacts
} from '../../../store/DataCacheSlice';
import { toggleNewMessageModal, toggleNewMeetingModal } from '../../../store/ModalsSlice';
import { Contact, MobilePhone, Company } from '../../../types/Types';
import { UserUtils } from '../../../utils/UserUtils';
import { api } from '../../../api/Api';
import { ContactField, useContactViewStyles } from '../../ContactsScreen';
import { CompanyMembersList } from './CompanyMembersList';
import { UserSearch } from '../../../components/ModalsOutlet/CompanyModal/UserSearch';
import { SearchElements } from '../../../components/ModalsOutlet/CompanyModal/types';
import { getHotkeyHandler, useClickOutside, useFocusTrap, useInputState } from '@mantine/hooks';
import { Link } from 'react-router-dom';
import { Validator } from '../../../utils/Validator';
import { getEmptyContactFromQuery } from '../../../components/ModalsOutlet/NewCompanyModal/NewCompanyModal';

const FIELD_ICON_SIZE = 16;

export function CompanyPageView({ company }: { company: Company }) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const initialContacts = useRef<Contact[] | null>();
    const [companyContacts, setCompanyContacts] = useState<Contact[] | null>(null);

    useEffect(() => {
        if (!company) return;
        setCompanyContacts(null);
        const f = async () => {
            const contacts = await api.getContactsOfCompany(company.id);
            setCompanyContacts(contacts);
            initialContacts.current = contacts;
        };
        f();
    }, [company]);

    const toast = useToast();

    const handleCompanyChange = useCallback(
        async (newValues: Partial<Company>) => {
            const oldCompany = { ...company };
            try {
                const newCompany: Company = {
                    ...company,
                    ...newValues
                };
                dispatch(mutateCompany(newCompany));
                await api.updateCompany(newCompany);
            } catch (error) {
                dispatch(mutateCompany(oldCompany));
                console.error(error);
                toast('Unable to edit contact, try again later', 'error');
            }
        },
        [company, dispatch, toast]
    );

    const handleMemberRemove = async (memberToRemove: Contact) => {
        const oldContact = { ...memberToRemove };
        const oldCompanyContacts = [...(companyContacts || [])];
        try {
            const newMember: Contact = {
                ...memberToRemove,
                companyId: undefined
            };
            dispatch(mutateContact(newMember));
            const newMembers = (companyContacts || []).filter((x) => x.id !== memberToRemove.id);
            setCompanyContacts(newMembers);

            await api.removeCompanyFormUser([memberToRemove]);
        } catch (error) {
            dispatch(mutateContact(oldContact));
            setCompanyContacts(oldCompanyContacts);
            console.error(error);
            toast('Unable to edit contact, try again later', 'error');
        }
    };
    const contacts = useAppSelector(selectCachedContacts);

    const handleMemberAdd = async (newMember: string | null | Contact) => {
        const memberToAdd =
            typeof newMember === 'string' ? contacts.find((x) => x.id === newMember) : newMember;

        if (!memberToAdd) return;

        const oldContact = { ...memberToAdd };
        const oldCompanyContacts = [...(companyContacts || [])];
        try {
            const newMember: Contact = {
                ...memberToAdd,
                companyId: company.id
            };
            dispatch(mutateContact(newMember));
            const newMembers = [...(companyContacts || []), newMember];
            setCompanyContacts(newMembers);

            await api.asssignCompanyToUsers(company.id, [memberToAdd]);
        } catch (error) {
            dispatch(mutateContact(oldContact));
            setCompanyContacts(oldCompanyContacts);
            console.error(error);
            toast('Unable to edit contact, try again later', 'error');
        }
    };

    const handlCreateNewContact = async (email: string) => {
        const [prefix, newEmail] = email.split(':');
        if (prefix === 'new') {
            const newContact = getEmptyContactFromQuery(newEmail);
            const contactResult = await api.addContact(newContact);
            dispatch(setContacts([...contacts, contactResult]));
            handleMemberAdd(contactResult);
        }
    };

    const { classes, theme } = useContactViewStyles();

    const interactionButtons = [
        {
            icon: <IconMail />,
            name: t('screen.contacts.sendMessage'),
            action: () =>
                dispatch(
                    toggleNewMessageModal({
                        open: true,
                        payload: {
                            users: (companyContacts || []).map((contact) =>
                                UserUtils.userUnionToUsernameOrContactId(contact)
                            )
                        }
                    })
                )
        },
        {
            icon: <IconVideo />,
            name: t('screen.contacts.scheduleMeeting'),
            action: () =>
                dispatch(
                    toggleNewMeetingModal({
                        open: true,
                        payload: {
                            users: (companyContacts || []).map((contact) =>
                                UserUtils.userUnionToUsernameOrContactId(contact)
                            )
                        }
                    })
                )
        }
    ];

    const querriedContacts = useMemo(() => {
        const filteredContacts: SearchElements = [];
        if (companyContacts === null) return [];
        for (const contact of contacts) {
            if ((companyContacts || []).findIndex((usr) => usr.id === contact.id) === -1) {
                let name = '';
                if (contact.name && (contact.name as any) !== contact.email) {
                    name = contact.name;
                } else if (contact.username) {
                    name = contact.username;
                } else {
                    name = contact.email;
                }

                filteredContacts.push({
                    type: 'contact',
                    value: contact.id,
                    label: name ? name : '',
                    email: contact.email
                });
            }
        }
        return filteredContacts;
    }, [companyContacts, contacts]);

    const companyFields = (
        <>
            <Paper className={classes.fieldGroup} mb="lg">
                <Stack>
                    <ContactField
                        icon={<IconPhone size={rem(FIELD_ICON_SIZE)} />}
                        name="Number"
                        type="input"
                        value={company.mobilePhone}
                        onSave={(newNumber) =>
                            handleCompanyChange({ mobilePhone: newNumber as MobilePhone })
                        }
                        validator={['phone']}
                        placeholder="Add number"
                        inputProps={{ type: 'number' }}
                        maxLength={{
                            message: 'Phone number cannot be longer than 20 characters',
                            value: 20
                        }}
                    />

                    <ContactField
                        icon={<IconHome size={rem(FIELD_ICON_SIZE)} />}
                        name="Adress"
                        type="input"
                        value={company.address}
                        onSave={(newValue) => {
                            handleCompanyChange({ address: newValue as Company['address'] });
                        }}
                        validator={['safeDefault']}
                        placeholder="Add adress"
                        maxLength={{
                            message: 'Adress cannot be longer than 100 characters',
                            value: 100
                        }}
                    />
                    <ContactField
                        icon={<IconWorld size={rem(FIELD_ICON_SIZE)} />}
                        name="Website"
                        isUrl
                        type="input"
                        value={company.website}
                        onSave={(newValue) => {
                            handleCompanyChange({ website: newValue as Company['website'] });
                        }}
                        validator={['website', 'no_sanitize']}
                        placeholder="Add website"
                        maxLength={{
                            message: 'Website cannot be longer than 100 characters',
                            value: 100
                        }}
                    />
                    <ContactField
                        icon={<IconNote size={rem(FIELD_ICON_SIZE)} />}
                        name="Note"
                        type="textarea"
                        value={company.note}
                        onSave={(newValue) =>
                            handleCompanyChange({ note: newValue as Company['note'] })
                        }
                        validator={['safeDefault']}
                        placeholder="Add note"
                        inputProps={{ minRows: 3, maxRows: 7 }}
                        maxLength={{
                            message: 'Note cannot be longer than 200 characters',
                            value: 200
                        }}
                    />
                </Stack>
            </Paper>
            <Paper className={classes.fieldGroup}>
                <Stack>
                    <Group position="apart">
                        <Group sx={{ flexShrink: 0 }}>
                            <IconUsers size={rem(FIELD_ICON_SIZE)} />
                            <Text color="dimmed">Members</Text>
                        </Group>
                        <div className={classes.fieldContent}>
                            <UserSearch
                                placeholder={`${t('action.addUser')}...`}
                                sx={{ flexGrow: 1 }}
                                onAddNewContact={(email) => {
                                    const [prefix, newEmail] = email.split(':');
                                    if (prefix === 'new') {
                                        const newContact: Contact =
                                            getEmptyContactFromQuery(newEmail);

                                        setCompanyContacts((prevMembers) => {
                                            const m = [
                                                ...(prevMembers || []),
                                                [...contacts, newContact].find(
                                                    (x) => x.id === email
                                                )
                                            ].filter((x) => x) as Contact[];
                                            return m;
                                        });
                                    }
                                }}
                                onChange={async (id) => {
                                    if (id?.startsWith('new:')) {
                                        await handlCreateNewContact(id);
                                    } else {
                                        handleMemberAdd(id);
                                    }
                                }}
                                contacts={querriedContacts}
                            />
                        </div>
                    </Group>
                    <CompanyMembersList
                        contacts={companyContacts}
                        handleRemoveUser={handleMemberRemove}>
                        {companyContacts && companyContacts.length === 0 && (
                            <Text align="center">{t('infoMessage.noUsers')}</Text>
                        )}
                    </CompanyMembersList>
                </Stack>
            </Paper>
        </>
    );

    const [companyName, setCompanyName] = useInputState(company.name);
    const [companyEditState, setCompanyEditState] = useState<'editing' | null>(null);
    const ref = useClickOutside(() => handleCancel());
    const focusTrapRef = useFocusTrap();
    const [companyNameError, setCompanyNameError] = useState<null | string>(null);

    const handleEditCompanyName = useCallback(() => {
        try {
            const newName = Validator.check(companyName);
            if (newName.length >= 50) {
                setCompanyNameError(
                    t('screen.companies.maxLengthCompanyName', {
                        characters: 50
                    })
                );
                return;
            }

            handleCompanyChange({ name: newName as Company['name'] });
            setCompanyEditState(null);
        } catch (error) {
            setCompanyNameError(
                t('screen.companies.incorectCompanyName', {
                    characters: Validator.getSafeDefaultChars()
                })
            );
        }
    }, [companyName, handleCompanyChange, t]);

    const handleCancel = () => {
        setCompanyName(company.name);
        setCompanyEditState(null);
        setCompanyNameError(null);
    };
    const questionBg = theme.colorScheme === 'dark' ? 'dark.7' : 'gray.0';

    return (
        <ScrollArea h="100svh" bg={questionBg}>
            <Flex pos="relative" h="100%" mah={'100svh'} direction="column" p="md">
                <MediaQuery largerThan={'md'} styles={{ display: 'none' }}>
                    <ActionIcon component={Link} to="/companies" pos="absolute" top={16} left={16}>
                        <IconChevronLeft />
                    </ActionIcon>
                </MediaQuery>
                <Stack align="center" mx="auto" my="xl" mb="xl" spacing={'sm'}>
                    <Avatar size={90}>
                        <IconBuilding />
                    </Avatar>
                    {companyEditState ? (
                        <Box
                            display={'flex'}
                            mx="auto"
                            sx={{ flexDirection: 'row' }}
                            ref={ref}
                            component="form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleEditCompanyName();
                            }}>
                            <Group spacing={0}>
                                <TextInput
                                    error={companyNameError}
                                    mr="sm"
                                    maxLength={50}
                                    ref={focusTrapRef}
                                    value={companyName}
                                    onChange={setCompanyName}
                                    onKeyDown={getHotkeyHandler([
                                        ['Escape', handleEditCompanyName]
                                    ])}
                                    inputWrapperOrder={['label', 'input', 'description', 'error']}
                                    description={
                                        <>
                                            Press <Kbd size="xs">Enter</Kbd> to save or{' '}
                                            <Kbd size="xs">Esc</Kbd> to cancel
                                        </>
                                    }
                                />
                                <Tooltip label="Save" openDelay={500}>
                                    <ActionIcon mr={4} color="green" type="submit">
                                        <IconDeviceFloppy size={rem(14)} />
                                    </ActionIcon>
                                </Tooltip>
                                <Tooltip label="Cancel" openDelay={500}>
                                    <ActionIcon color="red" onClick={handleCancel}>
                                        <IconX size={rem(14)} />
                                    </ActionIcon>
                                </Tooltip>
                            </Group>
                        </Box>
                    ) : (
                        <Group spacing={'xs'}>
                            <Title align="center" order={3}>
                                {company.name}
                            </Title>
                            <Tooltip label="Edit company name" openDelay={300}>
                                <ActionIcon onClick={() => setCompanyEditState('editing')}>
                                    <IconEdit size={rem(14)} />
                                </ActionIcon>
                            </Tooltip>
                        </Group>
                    )}
                    <Group spacing={'sm'}>
                        {interactionButtons.map((button) => (
                            <Tooltip key={button.name} label={button.name} openDelay={300}>
                                <ActionIcon color="brand" component="div" onClick={button.action}>
                                    {button.icon}
                                </ActionIcon>
                            </Tooltip>
                        ))}
                        <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                            <Divider mx={8} orientation="vertical" />
                        </MediaQuery>
                        <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                            <LinkWithFilter
                                to={'chat'}
                                filter={{
                                    company: [company.name]
                                }}>
                                <Button
                                    rightIcon={<IconExternalLink size={rem(14)} />}
                                    variant="outline">
                                    {t('screen.companies.showThreads')}
                                </Button>
                            </LinkWithFilter>
                        </MediaQuery>
                        <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                            <LinkWithFilter
                                to={'meetings'}
                                filter={{
                                    company: [company.name]
                                }}>
                                <Button
                                    rightIcon={<IconExternalLink size={rem(14)} />}
                                    variant="outline">
                                    {t('screen.companies.showMeetings')}
                                </Button>
                            </LinkWithFilter>
                        </MediaQuery>
                        <MediaQuery styles={{ display: 'none' }} largerThan={'md'}>
                            <Menu>
                                <Menu.Target>
                                    <ActionIcon>
                                        <IconDotsVertical />
                                    </ActionIcon>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Menu.Item
                                        component={LinkWithFilter}
                                        to={'chat'}
                                        filter={{
                                            company: [company.name]
                                        }}>
                                        {t('screen.companies.showThreads')}
                                    </Menu.Item>
                                    <Menu.Item
                                        component={LinkWithFilter}
                                        to={'meetings'}
                                        filter={{
                                            company: [company.name]
                                        }}>
                                        {t('screen.companies.showMeetings')}
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        </MediaQuery>
                    </Group>
                </Stack>

                {companyFields}
            </Flex>
        </ScrollArea>
    );
}
