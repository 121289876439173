import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import StateView from '../../atoms/State';
import * as types from '../../types/Types';
import { Box, Button, Center, Loader, Paper, Stack, Text, TextInput } from '@mantine/core';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { api } from '../../api/Api';
import { useParams } from 'react-router-dom';
import * as PmxApi from 'privmx-server-api';
import { useTranslation } from 'react-i18next';

export function PublicFormSubmitResponsePage() {
    const userKeyHalf = useMemo(() => {
        const key = new URLSearchParams(document.location.hash.replace('#', '?')).get(
            'userKeyHalf'
        );
        return key ? key : undefined;
    }, []);
    const routeParams = useParams();
    const responseId = routeParams.responseId as types.InquirySubmitResponseId;
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForEncryptedPublicFormRowResponse(responseId), [responseId])
    );
    return (
        <StateView state={state} retry={revalidate}>
            {(data) => {
                return (
                    <EncryptedPublicFormRowResponsePageView
                        userKeyHalf={userKeyHalf}
                        encryptedPublicResponse={data.response}
                        onRefresh={revalidate}
                    />
                );
            }}
        </StateView>
    );
}

export interface EncryptedPublicFormRowResponsePageViewProps {
    encryptedPublicResponse: PmxApi.api.inquiry.PublicInquirySubmitResponse | null;
    userKeyHalf: string | undefined;
    onRefresh: () => void;
}

export function EncryptedPublicFormRowResponsePageView(
    props: EncryptedPublicFormRowResponsePageViewProps
) {
    const { t } = useTranslation();
    const [publicResponse, setPublicResponse] = useState<types.PublicInquirySubmitResponse | null>(
        null
    );
    const [password, setPassword] = useState<string | null>(null);
    const [hasPasswordError, setHasPasswordError] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const autoDecryptionStarted = useRef<boolean>(false);

    const decryptResponse = useCallback(
        async (
            encryptedPublicResponse: PmxApi.api.inquiry.PublicInquirySubmitResponse,
            userKeyHalf: string | undefined
        ) => {
            const pwd = passwordInputRef.current?.value ?? '';
            setIsProcessing(true);
            try {
                const decryptedPublicRespones = await api.decryptPublicInquirySubmitResponse(
                    encryptedPublicResponse,
                    pwd,
                    userKeyHalf,
                    undefined
                );
                setPassword(pwd);
                setPublicResponse(decryptedPublicRespones);
            } catch (err) {
                console.error(err);
                setHasPasswordError(true);
            } finally {
                setIsProcessing(false);
            }
        },
        []
    );

    useEffect(() => {
        if (autoDecryptionStarted.current) {
            return;
        }
        if (
            props.encryptedPublicResponse &&
            props.userKeyHalf &&
            props.encryptedPublicResponse.protection === 'none'
        ) {
            autoDecryptionStarted.current = true;
            decryptResponse(props.encryptedPublicResponse, props.userKeyHalf);
        }
    }, [decryptResponse, props.encryptedPublicResponse, props.userKeyHalf]);

    const handleShowResponseSubmit = useCallback(async () => {
        if (props.encryptedPublicResponse && props.userKeyHalf) {
            decryptResponse(props.encryptedPublicResponse, props.userKeyHalf);
        }
    }, [decryptResponse, props.encryptedPublicResponse, props.userKeyHalf]);

    if (props.encryptedPublicResponse === null) {
        return (
            <Center p="xl" w="100%">
                {t('errorMessage.responseDoesNotExist')}
            </Center>
        );
    }

    if (publicResponse !== null) {
        return (
            <PublicFormRowResponsePageView
                publicResponse={publicResponse}
                onRefresh={props.onRefresh}
                userKeyHalf={props.userKeyHalf}
                password={password ?? undefined}
            />
        );
    }

    const isPasswordRequired = props.encryptedPublicResponse.protection === 'password';
    const showLoader = isProcessing || !props.userKeyHalf;
    const showPasswordPrompt = !showLoader && isPasswordRequired && password === null;

    return (
        <Center p="xl" w="100%">
            {showPasswordPrompt && (
                <Stack>
                    <form onSubmit={handleShowResponseSubmit}>
                        <TextInput
                            label={t('entityProps.password')}
                            ref={passwordInputRef}
                            error={hasPasswordError}
                        />
                        <Button type="submit">{t('action.showResponse')}</Button>
                    </form>
                </Stack>
            )}
            {showLoader && <Loader />}
        </Center>
    );
}

export interface PublicFormRowResponsePageViewProps {
    publicResponse: types.PublicInquirySubmitResponse;
    userKeyHalf: string | undefined;
    password: string | undefined;
    onRefresh: () => void;
}

export function PublicFormRowResponsePageView(props: PublicFormRowResponsePageViewProps) {
    return (
        <Center w="100%" mt={100}>
            <Paper
                radius={'sm'}
                w="100%"
                maw="1200px"
                p="xl"
                bg="gray:9"
                sx={{ position: 'relative' }}>
                <Box sx={{ position: 'absolute', right: 15, top: 15 }}>
                    <Text size="sm">{new Date(props.publicResponse.created).toLocaleString()}</Text>
                </Box>
                <Box mt="l" mb="xl">
                    <Stack mt="md" spacing={2}>
                        <Text weight="bolder">{props.publicResponse.data.title}</Text>
                        <Text>{props.publicResponse.data.message}</Text>
                    </Stack>
                </Box>
            </Paper>
        </Center>
    );
}

export default PublicFormSubmitResponsePage;
