import Modal from '../../atoms/Modal';
import StateView from '../../atoms/State';
import useLoader from '../../hooks/useLoader';
import { BaseModalData } from '../../types/Modals';
import { MessageId } from '../../types/Types';
import { SingleChatMessage } from './index';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface SingleChatMessageModalData extends BaseModalData {
    messageId: MessageId;
}

export function SingleChatMessageModal(props: {
    onClose: () => void;
    data: SingleChatMessageModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useLoader(
        useCallback((x) => x.loadMessage(props.data.messageId), [props.data.messageId])
    );
    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            height={props.data.height ?? 400}
            width={props.data.width}
            title={props.data.title ?? t('modal.singleChatMessage.title')}>
            <StateView state={state} retry={revalidate}>
                {(data) => (
                    <SingleChatMessage singleChatMessage={data.data} handleClose={props.onClose} />
                )}
            </StateView>
        </Modal>
    );
}
