import * as PmxApi from 'privmx-server-api';
import * as privfs from 'privfs-client';
import {
    UserCreationContextSimple,
    UserSinks,
    UserKvdbs,
    MessageToSent,
    UserSection,
    UserCreationParamsForAddUser,
    UserCreationParamsForRegisterInfo
} from '../Types';
import { ApiSerializer } from './ApiSerializer';

export class AddUserModelBuilder {
    constructor(private apiSerializer: ApiSerializer) {}

    build(context: UserCreationContextSimple, params: UserCreationParamsForAddUser) {
        const model: PmxApi.api.admin.AddUserExModel = {
            username: params.username,
            email: params.email,
            description: params.description,
            notificationEnabled: params.notificationEnabled,
            language: params.language,
            privateSectionAllowed: params.privateSectionAllowed,
            type: params.type,
            registerInfo: this.buildRegisterInfo(context, params),
            tasks: {
                sinks: this.getSinks(context.sinks),
                kvdbs: this.getKvdbs(context.kvdbs),
                kvdbInserts: context.kvdbInserts.map((x) => this.convertKvdbInsert(x)),
                messages: this.getMesssages(context.messages),
                sections: this.getSections(context.section ? [context.section] : [])
            },
            adminData: context.adminData.encrypted,
            adminDataV2: true,
            generatedPassword: params.generatedPassword,
            originDetails: JSON.stringify(
                params.originDetails
            ) as PmxApi.api.admin.UserOriginDetails,
            sendContactInvitation: true
        };
        return model;
    }

    private buildRegisterInfo(
        context: UserCreationContextSimple,
        params: UserCreationParamsForRegisterInfo
    ) {
        const result = context.registerResult;
        const registerInfo: PmxApi.api.admin.UserRegisterInfo = {
            host: params.host,
            signature: this.apiSerializer.getEccSignature(result.signature),
            privDataL1: this.apiSerializer.getBase64(result.authParams.masterRecord.l1),
            privDataL2: this.apiSerializer.getBase64(result.authParams.masterRecord.l2),
            dataVersion: result.authParams.version as PmxApi.api.user.PrivDataVersion,
            srp: {
                login: result.authParams.srp.login as PmxApi.api.user.UserLogin,
                salt: this.apiSerializer.getHex(result.authParams.srp.salt),
                verifier: this.apiSerializer.getHex(result.authParams.srp.verifier),
                params: result.authParams.srp.params as PmxApi.api.user.SrpParams,
                data: this.apiSerializer.getBase64(result.authParams.srp.encryptedL1),
                weakPassword: params.weakPassword
            },
            lbk: {
                pub: this.apiSerializer.getEccPublicKey(result.authParams.lbk.publicKey),
                data: this.apiSerializer.getBase64(result.authParams.lbk.encryptedL1)
            },
            keystore: {
                pub: this.apiSerializer.getPkiKeystore(context.keystore.keystore.getPublicView()),
                kis: this.apiSerializer.getPkiSignature(
                    context.keystore.params.kis as privfs.pki.Types.keystore.ISignature2
                )
            }
        };
        return registerInfo;
    }

    private getSinks(userSinks: UserSinks) {
        const sinks = this.createSinksListFromUserSinks(userSinks);
        return sinks.map((x) => this.convertSink(x.data));
    }

    private createSinksListFromUserSinks(userSinks: UserSinks) {
        return [userSinks.inbox, userSinks.outbox, userSinks.trash, userSinks.contact];
    }

    private convertSink(sink: privfs.types.message.SinkCreateModel) {
        const res: PmxApi.api.sink.CreateSinkModel = {
            sid: sink.id as PmxApi.api.sink.Sid,
            acl: sink.acl as PmxApi.api.sink.SinkAcl,
            data: sink.data as PmxApi.api.sink.SinkData,
            options: sink.options as PmxApi.api.sink.SinkOptions
        };
        return res;
    }

    private getKvdbs(userKvdbs: UserKvdbs) {
        const kvdbs = this.createKvdbsListFromUserKvdbs(userKvdbs);
        return kvdbs.map((x) => this.convertKvdb(x.extKey));
    }

    private createKvdbsListFromUserKvdbs(userKvdbs: UserKvdbs) {
        return [userKvdbs.settings, userKvdbs.mailFilter, userKvdbs.pkiCache, userKvdbs.contacts];
    }

    private convertKvdb(kvdb: privfs.crypto.ecc.ExtKey) {
        return privfs.db.KeyValueDb.getDbId(kvdb) as PmxApi.api.kvdb.KvdbId;
    }

    private convertKvdbInsert(x: privfs.types.db.KvdbSetEntryModel) {
        return {
            dbId: x.dbId as PmxApi.api.kvdb.KvdbId,
            key: x.key as PmxApi.api.kvdb.EntryKey,
            value: x.value
        };
    }

    private getMesssages(messages: MessageToSent[]) {
        return messages.map((x) => this.convertMesssage(x));
    }

    private convertMesssage(message: MessageToSent) {
        const result: PmxApi.api.admin.MessageCreateModel = {
            sid: message.data.data.sid as PmxApi.api.sink.Sid,
            senderPub58: message.data.data.senderPub58 as PmxApi.api.core.EccPubKey,
            senderHashmail: message.data.data.senderHashmail as PmxApi.api.core.Hashmail,
            extra: message.data.data.extra as PmxApi.api.message.MessageExtra,
            signature: message.data.data.signature as PmxApi.api.core.EccSignature,
            tags: message.tags
        };
        return result;
    }

    private getSections(sections: UserSection[]) {
        return sections.map((x) => this.getSection(x));
    }

    private getSection(section: UserSection) {
        const result: PmxApi.api.admin.SectionCreateModel = {
            executor: section.executor,
            id: section.model.id,
            data: section.model.data,
            keyId: section.model.keyId,
            group: {
                type: section.model.group.type,
                users: section.model.group.users,
                groups: section.model.group.groups
            },
            state: section.model.state,
            acl: section.model.acl,
            tasks: section.model.tasks,
            keys: section.model.keys,
            allowAccessForSubidentities: section.model.allowAccessForSubidentities
        };
        if (section.model.parentId) {
            result.parentId = section.model.parentId;
        }
        return result;
    }
}
