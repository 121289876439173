import Select, { GroupBase, Props, SelectInstance } from 'react-select';
import SelectCreatable, { CreatableProps } from 'react-select/creatable';
import React, { forwardRef } from 'react';

declare module 'react' {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
    ): (props: P & React.RefAttributes<any>) => React.ReactElement | null;
}

export interface CustomSelectBasicProps<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
> extends Props<Option, IsMulti, Group> {
    creatable?: false;
}
export interface CustomSelectCreatableProps<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
> extends CreatableProps<Option, IsMulti, Group> {
    creatable: true;
}

export type CustomSelectProps<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
> = (
    | CustomSelectBasicProps<Option, IsMulti, Group>
    | CustomSelectCreatableProps<Option, IsMulti, Group>
) & {
    ref?: React.ForwardedRef<SelectInstance<Option, IsMulti, Group>>;
};

function CustomSelect<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    { creatable, ...fwdProps }: CustomSelectProps<Option, IsMulti, Group>,
    ref?: React.ForwardedRef<SelectInstance<Option, IsMulti, Group>>
) {
    if (creatable) {
        return <SelectCreatable {...fwdProps} ref={ref} />;
    } else {
        return <Select {...fwdProps} ref={ref} />;
    }
}

export default forwardRef(CustomSelect);
