import { MultiSelectValueProps, Box, CloseButton } from '@mantine/core';
import { useAppSelector } from '../../../store';
import { selectCurrentUser } from '../../../store/CurrentUserSlice';
import { UserSelectOptionType } from '../types';
import { useSelectValueStyles } from './UserSelectorValueStyles';

export function UserSelectValue({
    value,
    label,
    onRemove,
    classNames,
    type,
    isPrivateContact,
    ...others
}: Omit<MultiSelectValueProps, 'onRemove'> & UserSelectOptionType & { onRemove?: () => void }) {
    const currentUser = useAppSelector(selectCurrentUser);

    const { classes } = useSelectValueStyles({
        isContact: type === 'contact',
        hasRightPadding: value !== currentUser.username && !!onRemove,
        value
    });

    return (
        <div {...others}>
            <Box className={classes.root}>
                <Box className={classes.text}>{label}</Box>
                {value !== currentUser.username && onRemove && (
                    <CloseButton
                        ml={6}
                        onMouseDown={onRemove}
                        variant="transparent"
                        size={12}
                        iconSize={14}
                        tabIndex={-1}
                        className={classes.closeButton}
                    />
                )}
            </Box>
        </div>
    );
}

export function UserSelectValueHidden({
    value,
    label,
    onRemove,
    classNames,
    type,
    isPrivateContact,
    ...others
}: MultiSelectValueProps & UserSelectOptionType) {
    const currentUser = useAppSelector(selectCurrentUser);
    const { classes } = useSelectValueStyles({
        isContact: type === 'contact',
        hasRightPadding: value !== currentUser.username,
        value
    });

    if (currentUser.username === value) return <></>;

    return (
        <div {...others}>
            <Box className={classes.root}>
                <Box className={classes.text}>{label}</Box>
                {value !== currentUser.username && (
                    <CloseButton
                        ml={6}
                        onMouseDown={onRemove}
                        variant="transparent"
                        size={12}
                        iconSize={14}
                        tabIndex={-1}
                        className={classes.closeButton}
                    />
                )}
            </Box>
        </div>
    );
}
