import { Dispatch, SetStateAction } from 'react';

export function setObjectUrl(
    func: Dispatch<SetStateAction<string | null>>,
    newValue: { content: Buffer; mimetype: string } | null
) {
    func((oldValue) => {
        if (oldValue) {
            URL.revokeObjectURL(oldValue);
        }
        if (newValue) {
            const url = createObjectURL(newValue.content, newValue.mimetype);
            return url;
        }
        return null;
    });
}

export function createObjectURL(buffer: Buffer, mimetype: string) {
    return URL.createObjectURL(new Blob([buffer], { type: mimetype }));
}
