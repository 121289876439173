import { ActionIcon } from '@mantine/core';
import Icon, { iconAdd } from '../../components/Icon';

export interface FloatingAddButtonProps {
    onClick?: (e: any) => void;
    class?: string;
    children?: React.ReactNode;
}

function FloatingAddButton({
    children = <Icon icon={iconAdd} />,
    ...props
}: FloatingAddButtonProps) {
    return (
        <ActionIcon
            variant="filled"
            radius="xl"
            right={0}
            bottom={0}
            m="md"
            pos="absolute"
            size="xl"
            className={props.class}
            onClick={props.onClick}>
            {children}
        </ActionIcon>
    );
}

export default FloatingAddButton;
