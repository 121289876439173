import { store } from '../store';
import { StateUpdaterInterface } from './StateUpdaterInterface';
import { upsertAnonymousUser as _upsertAnonymousUser } from '../store/UsersSlice';

export class StateUpdater implements StateUpdaterInterface {
    dispatch(data: unknown): void {
        return store.dispatch(data as any);
    }
    upsertAnonymousUser(payload: unknown): unknown {
        return _upsertAnonymousUser(payload as any);
    }
}
