import { Modal } from '@mantine/core';
import { BaseModalData } from '../../types/Modals';
import CreateUser from './index';
import { useTranslation } from 'react-i18next';

export interface CreateUserModalData extends BaseModalData {}

export function CreateUserModal(props: { onClose: () => void; data: CreateUserModalData }) {
    const { t } = useTranslation();
    return (
        <Modal
            centered
            onClose={props.onClose}
            opened={true}
            title={props.data.title ?? t('modal.createUser.title')}>
            <CreateUser handleClose={props.onClose} />
        </Modal>
    );
}
