import { BaseModalData } from '../../types/Modals';
import Modal from '../../atoms/Modal';
import StateView from '../../atoms/State';
import { ChatPageView } from './index';
import { ChatId } from '../../types/Types';
import { useCallback } from 'react';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { useTranslation } from 'react-i18next';

export interface ChatModalData extends BaseModalData {
    id: ChatId;
}

const modalStyles = { content: { padding: 0, minWidth: 500 } };

export function ChatModal(props: {
    onClose: () => void;
    data: ChatModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useDataSubscribion(
        useCallback(
            (x) => x.subscribeForChatWithMessagesAndAttachments(props.data.id, true),
            [props.data.id]
        )
    );
    return (
        <Modal
            resizable
            styles={modalStyles}
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            height={props.data.height ?? 800}
            width={props.data.width}
            title={
                props.data.title ??
                (state.type === 'done' && state.data.chat && state.data.chat.title
                    ? t('modal.chatPage.titleWithName', { name: state.data.chat.title })
                    : t('modal.chatPage.titleWithoutName'))
            }>
            <StateView state={state} retry={revalidate}>
                {(data) => (
                    <ChatPageView
                        chat={data.chat}
                        chatMessageDraft={data.chatMessageDraft}
                        goToBtn={false}
                        refreshBtn={true}
                        onRefresh={revalidate}
                        onGoTo={() => {}}
                        loadingMessages={!data}
                        messages={data.messages}
                        hasAccess={data.hasAccessToThreadContent}
                        attachments={data.attachments}
                    />
                )}
            </StateView>
        </Modal>
    );
}
