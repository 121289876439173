import * as privmx from 'privfs-client';

export interface ApiErrorEvent {
    type: 'error';
    errorCode: number;
}

export class BaseApi {
    private static listeners = new Map<string, ((event: any) => void)[]>();

    static addEventListener(
        type: ApiErrorEvent['type'],
        listener: (event: ApiErrorEvent) => void
    ): void;
    static addEventListener<T extends { type: string }>(
        type: T['type'],
        listener: (event: T) => void
    ) {
        const listeners = this.listeners.get(type);
        if (listeners) {
            listeners.push(listener);
        } else {
            this.listeners.set(type, [listener]);
        }
    }

    static removeEventListener<T extends { type: string }>(
        type: T['type'],
        listener: (event: T) => void
    ) {
        const listeners = this.listeners.get(type);
        if (listeners) {
            const index = listeners.indexOf(listener);
            if (index !== -1) {
                listeners.splice(index, 1);
            }
        }
    }

    private static dispatchEvent<T extends { type: string }>(event: T) {
        const listeners = this.listeners.get(event.type);
        if (!listeners) {
            return;
        }
        for (const listener of listeners) {
            try {
                listener(event);
            } catch (e) {
                console.error('Error during calling event listener', e);
            }
        }
    }

    constructor(private gateway: privmx.gateway.RpcGateway) {}

    protected async request<T>(method: string, params: unknown): Promise<T> {
        try {
            return await this.gateway.request(method, params);
        } catch (err) {
            const errorCode = this.getErrorCode(err);
            if (errorCode !== null) {
                BaseApi.dispatchEvent({
                    type: 'error',
                    errorCode
                });
            }
            throw err;
        }
    }

    private getErrorCode(err: any): number | null {
        const errorCode = err?.data?.error?.code;
        if (typeof errorCode === 'number') {
            return errorCode;
        }
        return null;
    }
}
