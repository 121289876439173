import Modal from '../../atoms/Modal';
import { BaseModalData } from '../../types/Modals';
import useLoader from '../../hooks/useLoader';
import StateView from '../../atoms/State';
import { ContactEditPage } from './index';
import { Contact } from '../../types/Types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface ContactAddModalData extends BaseModalData {
    defaults?: Partial<Contact>;
}
const initialStyle = {
    content: {
        minHeight: 405
    }
};
export function ContactAddModal(props: {
    onClose: () => void;
    data: ContactAddModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useLoader(
        useCallback((x) => x.loadContactForAdding(props.data.defaults), [props.data.defaults])
    );

    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            styles={initialStyle}
            maw={800}
            title={props.data.title ?? t('modal.contactAdd.title')}>
            <StateView state={state} retry={revalidate}>
                {(data) => <ContactEditPage contactEdit={data} handleClose={props.onClose} />}
            </StateView>
        </Modal>
    );
}
