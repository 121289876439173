import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../types/Types';
import { RootState } from './Store';
import * as PmxApi from 'privmx-server-api';

export type CurrentUserState = {
    [Property in keyof Omit<User, 'role'>]: User[Property] | null;
} & { role: 'staff' | 'client' | null; pub: PmxApi.api.core.EccPubKey | null };

export const initialState: CurrentUserState = {
    id: null,
    userType: null,
    role: null,
    username: null,
    email: null,
    name: null,
    avatar: null,
    password: null,
    isAdmin: null,
    blocked: null,
    creationDate: null,
    lastLoggedIn: null,
    pub: null
};

export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        setCurrentUser: (_state, action: PayloadAction<CurrentUserState>) => {
            return action.payload;
        },
        resetCurrentUserState: () => {
            return initialState;
        }
    }
});

export const { setCurrentUser, resetCurrentUserState } = currentUserSlice.actions;

export const selectCurrentUser = (state: RootState) => state.currentUser;

export const selectCurrentUserRole = createSelector(
    [selectCurrentUser],
    (currentUser) => currentUser.role
);

export const selectCurrentUserUsername = createSelector(
    [selectCurrentUser],
    (currentUser) => currentUser.username
);

export default currentUserSlice.reducer;
