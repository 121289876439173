import * as privmx from 'privfs-client';
import { RequestApi } from './RequestApi';
import * as PmxApi from 'privmx-server-api';
import { DataEncryptor } from './DataEncryptor';
import { EncKey, KeyProvider, SerializableEncKey } from './KeyProvider';
import {
    AttachmentMeta,
    AttachmentUtils,
    DownloadOptions,
    ExistingAttachmentsProvider,
    PreparedFile
} from './AttachmentUtils';
import * as types from '../../types/Types';
import { SharedFileApi } from './SharedFileApi';
import { TagService } from './TagService';
import { UrlBuilder } from '../../utils/UrlBuilder';
import { DataCacheInterface } from '../DataCacheInterface';
import { ModalsInterface } from '../ModalsInterface';

export interface SharedFileAttachment {
    hmac: string; // base64
    name: types.FileName;
    mimetype: types.Mimetype;
    size: types.FileSize;
    hasThumb: boolean;
}

type FinalEncKeyKey = Buffer & { __finalEncKeyKey: never };
interface FinalEncKey {
    id: PmxApi.api.core.KeyId;
    key: FinalEncKeyKey;
}

export class SharedFileService {
    private requestApi: RequestApi;
    private sharedFileApi: SharedFileApi;
    private userDataEncryptor = new DataEncryptor<
        types.SharedFileUserData,
        PmxApi.api.sharedFile.SharedFileUserData
    >();
    private fileMetaEncryptor = new DataEncryptor<
        AttachmentMeta,
        PmxApi.api.attachment.AttachmentMeta,
        FinalEncKey
    >();
    private attachmentUtils: AttachmentUtils<SharedFileAttachment, FinalEncKey>;

    constructor(
        private gateway: privmx.gateway.RpcGateway,
        private keyProvider: KeyProvider,
        private userDataEncKey: EncKey | null,
        private tagService: TagService,
        private existingAttachmentsProvider: ExistingAttachmentsProvider,
        private cacheService: DataCacheInterface,
        private modalsService: ModalsInterface
    ) {
        this.requestApi = new RequestApi(this.gateway);
        this.sharedFileApi = new SharedFileApi(this.gateway);
        this.attachmentUtils = new AttachmentUtils(
            this.requestApi,
            this.fileMetaEncryptor,
            (preparedFile, hasThumb) =>
                this.preparedFileToAttachmentConverter(preparedFile, hasThumb),
            this.existingAttachmentsProvider,
            this.cacheService,
            this.modalsService
        );
    }

    canManageSharedFiles() {
        return !!this.userDataEncKey;
    }

    async createSharedFile(
        permission: PmxApi.api.sharedFile.SharedFilePermission,
        userDataForCreate: types.SharedFileUserDataForCreate,
        setFileModel: types.SharedFileSetFileModel
    ) {
        if (!this.userDataEncKey) {
            throw new Error('User has no permission to manage shared files');
        }
        const userData: types.SharedFileUserData = {
            ...userDataForCreate,
            fileMetaEncKeyStr: userDataForCreate.fileMetaEncKeyStr
                ? userDataForCreate.fileMetaEncKeyStr
                : this.fileMetaEncKeyToString(this.generateFileMetaEncKey())
        };
        const fileMetaEncKey = this.fileMetaEncKeyFromString(userData.fileMetaEncKeyStr);
        const finalFileMetaEncKey = await this.getFinalEncKey(fileMetaEncKey, userData.password);
        const fileModel = await this.prepareSetFileModel(setFileModel, finalFileMetaEncKey);
        await this.sharedFileApi.createSharedFile({
            permission: permission,
            userData: await this.userDataEncryptor.encrypt(userData, this.userDataEncKey),
            file: fileModel
        });
    }

    async deleteSharedFile(id: types.SharedFileId) {
        await this.sharedFileApi.deleteSharedFile({ id: id });
    }

    async updateSharedFile(
        oldSharedFile: types.SharedFile,
        permission: PmxApi.api.sharedFile.SharedFilePermission,
        userData: types.SharedFileUserData,
        setFileModel?: types.SharedFileSetFileModel
    ) {
        if (!this.userDataEncKey) {
            throw new Error('User has no permission to manage shared files');
        }
        const fileMetaEncKey = this.fileMetaEncKeyFromString(userData.fileMetaEncKeyStr);
        const finalFileMetaEncKey = await this.getFinalEncKey(fileMetaEncKey, userData.password);
        const fileModel = setFileModel
            ? await this.prepareSetFileModel(setFileModel, finalFileMetaEncKey)
            : undefined;
        const fileUpdateModel = setFileModel
            ? undefined
            : await this.prepareFileUpdateModel(oldSharedFile, finalFileMetaEncKey);
        await this.sharedFileApi.updateSharedFile({
            id: oldSharedFile.id,
            permission: permission,
            userData: await this.userDataEncryptor.encrypt(userData, this.userDataEncKey),
            newFile: fileModel,
            updateFile: fileUpdateModel
        });
    }

    private async prepareSetFileModel(
        setFileModel: types.SharedFileSetFileModel,
        fileMetaEncKey: FinalEncKey
    ) {
        const attInfo = await this.attachmentUtils.prepareAttachments(
            fileMetaEncKey,
            fileMetaEncKey.key,
            null,
            setFileModel.type === 'uploadNewFile' ? [setFileModel.file] : [],
            setFileModel.type === 'copyAttachment' ? [setFileModel.attachmentToCopy] : []
        );
        const fileModel: PmxApi.api.sharedFile.SetFileModel =
            setFileModel.type === 'copyAttachment'
                ? {
                      type: 'copyAttachment',
                      attachmentToCopy: attInfo.attachmentsToCopy[0]!
                  }
                : {
                      type: 'uploadNewFile',
                      file: attInfo.request!.list[0]!,
                      requestId: attInfo.request!.requestId
                  };
        return fileModel;
    }

    private async prepareFileUpdateModel(
        oldSharedFile: types.SharedFile,
        finalFileMetaEncKey: FinalEncKey
    ) {
        const group = (
            await privmx.crypto.service.hmacSha256(
                finalFileMetaEncKey.key,
                Buffer.from(oldSharedFile.file.meta.name, 'utf8')
            )
        ).toString('hex') as PmxApi.api.attachment.AttachmentGroup;
        const meta = await this.fileMetaEncryptor.encrypt(
            oldSharedFile.file.meta,
            finalFileMetaEncKey
        );
        const fileUpdateModel: PmxApi.api.attachment.AttachmentToUpdate = {
            attachmentId: oldSharedFile.file.attachmentId,
            group: group,
            meta: meta
        };
        return fileUpdateModel;
    }

    async getSharedFile(id: types.SharedFileId) {
        const { sharedFile } = await this.sharedFileApi.getSharedFile({ id: id });
        return this.decryptSharedFile(sharedFile);
    }

    async getSharedFiles() {
        const { sharedFiles } = await this.sharedFileApi.getSharedFiles();
        return Promise.all(sharedFiles.map((sharedFile) => this.decryptSharedFile(sharedFile)));
    }

    async getSharedFileData(id: types.SharedFileId, thumb: boolean, options: DownloadOptions) {
        const sharedFile = await this.getSharedFile(id);
        const data = await this.getSharedFileDataCore(sharedFile, thumb, options);
        return data;
    }

    async getSharedFileWithData(id: types.SharedFileId, thumb: boolean, options: DownloadOptions) {
        const sharedFile = await this.getSharedFile(id);
        const data = await this.getSharedFileDataCore(sharedFile, thumb, options);
        return { sharedFile, data };
    }

    async getSharedFileDataCore(
        sharedFile: types.SharedFile,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const data = await this.readFile(sharedFile, !!thumb, false, options);
        return data;
    }

    async getPublicSharedFile(id: types.SharedFileId, fileMetaEncKey: EncKey, password?: string) {
        const { publicSharedFile } = await this.sharedFileApi.getPublicSharedFile({ id: id });
        return this.decryptPublicSharedFile(publicSharedFile, fileMetaEncKey, password);
    }

    async getEncryptedPublicSharedFile(id: types.SharedFileId) {
        const { publicSharedFile } = await this.sharedFileApi.getPublicSharedFile({ id: id });
        return publicSharedFile;
    }

    async getPublicSharedFileData(
        id: types.SharedFileId,
        fileMetaEncKey: EncKey,
        password: string | undefined,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const publicSharedFile = await this.getPublicSharedFile(id, fileMetaEncKey, password);
        const data = this.getPublicSharedFileDataCore(publicSharedFile, thumb, options);
        return data;
    }

    async getPublicSharedFileWithData(
        id: types.SharedFileId,
        fileMetaEncKey: EncKey,
        password: string | undefined,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const publicSharedFile = await this.getPublicSharedFile(id, fileMetaEncKey, password);
        const data = this.getPublicSharedFileDataCore(publicSharedFile, thumb, options);
        return { publicSharedFile, data };
    }

    async getPublicSharedFileDataCore(
        publicSharedFile: types.PublicSharedFile,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const data = await this.readFile(publicSharedFile, !!thumb, true, options);
        return data;
    }

    async decryptSharedFile(sharedFile: PmxApi.api.sharedFile.SharedFile) {
        if (!this.userDataEncKey) {
            throw new Error('User has no permission to manage shared files');
        }
        const tags = await this.tagService.getTags(
            'sharedFileFile',
            sharedFile.file.id,
            'all',
            sharedFile.file
        );
        const userData = await this.userDataEncryptor.decrypt(
            sharedFile.userData,
            this.userDataEncKey
        );
        const fileMetaEncKey = this.fileMetaEncKeyFromString(userData.fileMetaEncKeyStr);
        const finalFileMetaEncKey = await this.getFinalEncKey(fileMetaEncKey, userData.password);
        const meta = await this.fileMetaEncryptor.decrypt(
            sharedFile.file.meta,
            finalFileMetaEncKey
        );
        return this.convertSharedFile(sharedFile, tags, userData, meta);
    }

    generateSharedFilePublicUrl(sharedFile: types.SharedFile) {
        const key = this.fileMetaEncKeyFromString(sharedFile.userData.fileMetaEncKeyStr);
        const keyStr = encodeURIComponent(key.key.toString('base64'));
        const url = UrlBuilder.buildUrl(`/share/${sharedFile.id}#key=${keyStr}`);
        return url;
    }

    private async convertSharedFile(
        sharedFile: PmxApi.api.sharedFile.SharedFile,
        tags: types.Tag[],
        userData: types.SharedFileUserData,
        meta: AttachmentMeta
    ) {
        const file: types.SharedFileFile = {
            sharedFileId: sharedFile.id,
            group: sharedFile.file.group,
            tags: tags,
            attachmentId: sharedFile.file.id,
            author: sharedFile.author,
            date: sharedFile.createDate,
            meta: meta,
            hasThumb: !!sharedFile.file.thumb
        };
        const res: types.SharedFile = {
            id: sharedFile.id,
            createDate: sharedFile.createDate,
            lastModDate: sharedFile.lastModDate,
            author: sharedFile.author,
            permission: sharedFile.permission,
            userData: userData,
            file: file
        };
        return res;
    }

    async decryptPublicSharedFile(
        sharedFile: PmxApi.api.sharedFile.PublicSharedFile,
        fileMetaEncKey: EncKey,
        password?: string
    ) {
        const finalFileMetaEncKey = await this.getFinalEncKey(fileMetaEncKey, password);
        const meta = await this.fileMetaEncryptor.decrypt(
            sharedFile.file.meta,
            finalFileMetaEncKey
        );
        return this.convertPublicSharedFile(sharedFile, meta);
    }

    getPublicSharedFileEncKeyFromString(encKeyStr: string) {
        return this.fileMetaEncKeyFromString(
            JSON.stringify({
                id: 'sharedFileEncKey',
                key: encKeyStr
            })
        );
    }

    private async convertPublicSharedFile(
        sharedFile: PmxApi.api.sharedFile.PublicSharedFile,
        meta: AttachmentMeta
    ) {
        const file: types.PublicSharedFileFile = {
            sharedFileId: sharedFile.id,
            group: sharedFile.file.group,
            attachmentId: sharedFile.file.id,
            date: sharedFile.createDate,
            meta: meta,
            hasThumb: !!sharedFile.file.thumb
        };
        const res: types.PublicSharedFile = {
            id: sharedFile.id,
            createDate: sharedFile.createDate,
            lastModDate: sharedFile.lastModDate,
            permission: sharedFile.permission,
            file: file
        };
        return res;
    }

    private async readFile(
        sharedFile: types.SharedFile | types.PublicSharedFile,
        thumb: boolean,
        isPublic: boolean,
        options: DownloadOptions
    ) {
        const getDataFn = (
            model:
                | PmxApi.api.sharedFile.GetPublicSharedFileDataModel
                | PmxApi.api.sharedFile.GetSharedFileDataModel
        ) =>
            isPublic
                ? this.sharedFileApi.getPublicSharedFileData(model)
                : this.sharedFileApi.getSharedFileData(model);
        const meta = sharedFile.file.meta;
        const [currentMeta] = (() => {
            if (thumb) {
                if (!meta.thumb) {
                    throw new Error('File has no thumbnail');
                }
                return [meta.thumb, meta.thumb.size];
            }
            return [meta, meta.size];
        })();
        return AttachmentUtils.downloadAttachment(
            currentMeta,
            (range) =>
                getDataFn({
                    id: sharedFile.id,
                    range: range,
                    thumb: thumb
                }),
            options
        );
    }

    private preparedFileToAttachmentConverter(
        preparedFile: PreparedFile,
        hasThumb: boolean
    ): SharedFileAttachment {
        const res: SharedFileAttachment = {
            hmac: preparedFile.hmac.toString('base64'),
            name: preparedFile.file.name as types.FileName,
            size: preparedFile.file.size as types.FileSize,
            mimetype: preparedFile.file.type as types.Mimetype,
            hasThumb: hasThumb
        };
        return res;
    }

    private generateFileMetaEncKey() {
        const fileMetaEncKey = this.keyProvider.generateKey();
        return fileMetaEncKey;
    }

    private fileMetaEncKeyToString(fileMetaEncKey: EncKey): string {
        const serializableEncKey: SerializableEncKey = {
            id: fileMetaEncKey.id,
            key: fileMetaEncKey.key.toString('base64')
        };
        return JSON.stringify(serializableEncKey);
    }

    private fileMetaEncKeyFromString(fileMetaEncKeyStr: string): EncKey {
        const serializableEncKey: SerializableEncKey = JSON.parse(fileMetaEncKeyStr);
        return {
            id: serializableEncKey.id,
            key: privmx.Buffer.Buffer.from(serializableEncKey.key, 'base64')
        };
    }

    private async getFinalEncKey(fileMetaEncKey: EncKey, password: string | undefined) {
        const key: FinalEncKey = {
            id: fileMetaEncKey.id,
            key: (password
                ? await privmx.crypto.service.pbkdf2(
                      Buffer.from(password, 'utf8'),
                      fileMetaEncKey.key,
                      100000,
                      32,
                      'sha256'
                  )
                : fileMetaEncKey.key) as FinalEncKeyKey
        };
        return key;
    }
}
