import React, { useMemo } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { Center, Stack, Image, useMantineColorScheme, MediaQuery } from '@mantine/core';
import AnonymousLoginScreen from './AnonymousLoginScreen';
import { api } from '../../api/Api';
import AnonymousPasswordLoginScreen from './AnonymousPasswordLoginScreen';
import LoginScreenForm from './LoginScreenForm';
import { CustomizationProvider } from '../../api/customization/CustomizationProvider';
import POWERED_BY_LOGO from '../../assets/poweredby.png';

type LoginScreenType = 'client' | 'staff' | 'anonymous' | 'anonymous-password';

export interface LoginScreenProps {
    userType?: LoginScreenType | 'inquirySubmitter';
}

export default function LoginScreen(props: LoginScreenProps) {
    const currentUser = useAppSelector(selectCurrentUser);
    const routeParams = useParams();

    const meetingData = useMemo(() => {
        const rawMeetingData = routeParams.meetingData;
        try {
            if (rawMeetingData && typeof rawMeetingData === 'string' && rawMeetingData.length > 0) {
                const meetingData = api.parseThreadLinkStringData(rawMeetingData);
                if (meetingData && meetingData.type === 'formThread') {
                    return meetingData;
                }
            }
        } catch (error) {
            return null;
        }
        return null;
    }, [routeParams]);

    const loginScreenType: LoginScreenType | undefined =
        props.userType === 'inquirySubmitter'
            ? meetingData?.loginMethod === 'password'
                ? 'anonymous-password'
                : 'client'
            : props.userType;

    if (currentUser.role !== null) {
        return <Navigate to="/chat" />;
    }

    return (
        <MediaQuery styles={{ width: '98%' }} smallerThan={'sm'}>
            <Center w="30%" mx="auto" h="100svh">
                <Stack spacing={0}>
                    <Link to="/">
                        <Logo />
                    </Link>
                    {!loginScreenType && <LoginScreenForm />}
                    {(loginScreenType === 'client' || loginScreenType === 'staff') && (
                        <LoginScreenForm />
                    )}
                    {CustomizationProvider.get().hasCustomLoginPageLogo() && PoweredByLogo()}
                    {loginScreenType === 'anonymous' && <AnonymousLoginScreen />}
                    {loginScreenType === 'anonymous-password' && <AnonymousPasswordLoginScreen />}
                </Stack>
            </Center>
        </MediaQuery>
    );
}

function Logo() {
    const { colorScheme } = useMantineColorScheme();
    return (
        <Image
            mx="auto"
            alt="logo"
            maw={360}
            mah={300}
            mb={40}
            fit="cover"
            style={{ filter: colorScheme === 'dark' ? 'invert(1)' : 'none', maxHeight: '300px' }}
            src={CustomizationProvider.get().getLoginPageLogo(colorScheme)}
        />
    );
}

function PoweredByLogo() {
    const { colorScheme } = useMantineColorScheme();
    return (
        <Image
            mt={20}
            mx="auto"
            alt="logo"
            maw={180}
            mah={50}
            mb={40}
            fit="contain"
            style={{ filter: colorScheme === 'dark' ? 'invert(1)' : 'none', maxHeight: '50px' }}
            src={POWERED_BY_LOGO}
        />
    );
}
