import { useMemo } from 'react';
import { Box, Modal, ModalBaseStylesNames, ModalProps, Styles, rem } from '@mantine/core';

const getModalStyles = (height: string): Styles<ModalBaseStylesNames> => ({
    body: { height: height.endsWith('px') ? `calc(${height} - 60px)` : height, padding: 0 },
    content: { height: height },
    root: { height: height },
    inner: { width: `calc(100% - ${rem(250)})`, left: 250 }
});

export default function CenteredModal({
    onClose,
    opened,
    children,
    height,
    ...props
}: ModalProps & { height?: number | string }) {
    const modalStyles = useMemo(() => {
        const modalHeight =
            height === undefined ? '100%' : typeof height === 'number' ? `${height}px` : height;
        return getModalStyles(modalHeight);
    }, [height]);
    return (
        <Modal
            withCloseButton={false}
            size="100%"
            styles={modalStyles}
            opened={opened}
            onClose={onClose}
            transitionProps={{
                duration: 200,
                transition: 'slide-up',
                timingFunction: 'ease-in'
            }}
            {...props}>
            <Box h="100%">{children}</Box>
        </Modal>
    );
}
