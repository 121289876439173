import { useCallback } from 'react';
import StateView from '../../atoms/State';
import useModal from '../../hooks/useModal';
import * as types from '../../types/Types';
import { Box, Button, Group, Text, Title } from '@mantine/core';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import AttachmentPreview from '../AttachmentPreview';
import PageDetailsHeader from '../../atoms/DetailPage/PageDetailsHeader';
import { useMemo } from 'react';
import { api } from '../../api/Api';
import TextWithCopyButton from '../../atoms/TextWithCopyButton';
import { useTranslation } from 'react-i18next';

export function SharedFilePage(props: { id: types.SharedFileId }) {
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForSharedFile(props.id), [props.id])
    );
    return (
        <StateView state={state} retry={revalidate}>
            {(data) => {
                return <SharedFilePageView sharedFile={data.sharedFile} onRefresh={revalidate} />;
            }}
        </StateView>
    );
}

export interface SharedFilePageViewProps {
    sharedFile: types.SharedFile | null;
    onRefresh: () => void;
}

export function SharedFilePageView(props: SharedFilePageViewProps) {
    const { t } = useTranslation();
    const modalService = useModal();

    const handleEditClick = useCallback(() => {
        if (props.sharedFile !== null) {
            modalService.openEditSharedFileModal({ id: props.sharedFile.id });
        }
    }, [modalService, props.sharedFile]);

    const att: types.SharedFileFileAsAttachment | null = props.sharedFile
        ? {
              author: props.sharedFile.file.author,
              contentType: props.sharedFile.file.meta.mimetype,
              date: props.sharedFile.file.date,
              group: props.sharedFile.file.group,
              hasThumb: props.sharedFile.file.hasThumb,
              id: props.sharedFile.id as any,
              name: props.sharedFile.userData.title as types.FileName,
              sharedFileId: props.sharedFile.id,
              size: props.sharedFile.file.meta.size,
              sourceType: 'sharedFile',
              tags: props.sharedFile.file.tags
          }
        : null;

    const publicUrl = useMemo(() => {
        return props.sharedFile ? api.generateSharedFilePublicUrl(props.sharedFile) : '';
    }, [props.sharedFile]);

    if (!props.sharedFile) {
        return <Box p="md">{t('errorMessage.fileDoesNotExist')}</Box>;
    }

    return (
        <Box p="md">
            <PageDetailsHeader
                name={
                    <>
                        <Title fz={{ base: 'md', xl: 'lg' }}>
                            {props.sharedFile.userData.title}{' '}
                        </Title>
                    </>
                }
                button={
                    <Group spacing="sm">
                        <Button onClick={handleEditClick} variant="light">
                            {t('action.edit')}
                        </Button>
                    </Group>
                }></PageDetailsHeader>
            <Box mt="xl">
                <Title order={4}>{t('entityProps.share')}:</Title>
                <Group grow position="center">
                    <div>
                        <Text display="block" weight={'lighter'}>
                            {t('entityProps.url')}:{' '}
                        </Text>
                        <TextWithCopyButton text={publicUrl} />
                    </div>
                    <div>
                        <Text display="block" weight={'lighter'}>
                            {t('entityProps.password')}:{' '}
                        </Text>
                        {props.sharedFile.userData.password ? (
                            <TextWithCopyButton text={props.sharedFile.userData.password} />
                        ) : (
                            <Text weight={'lighter'}>({t('noneLowercase')})</Text>
                        )}
                    </div>
                </Group>
            </Box>
            <Box mt="xl">
                <Title order={4}>{t('entityProps.note')}:</Title>
                {props.sharedFile.userData.note ? (
                    props.sharedFile.userData.note
                ) : (
                    <Text weight={'lighter'}>({t('noneLowercase')})</Text>
                )}
            </Box>
            {att && (
                <Box mt="xl">
                    <Title order={4}>{t('entityProps.file')}:</Title>
                    <AttachmentPreview
                        handleClose={() => {}}
                        attachmentPreview={{ attachment: att }}
                        hideShareButton
                    />
                </Box>
            )}
        </Box>
    );
}

export default SharedFilePage;
