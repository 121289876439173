import { api } from '../api/Api';
import { FormModel2 } from '../types/Types';
import { UrlBuilder } from './UrlBuilder';

type BaseLinkMetadata = {
    link: string;
    relativeLink: string;
    domain: string;
    start: number;
    end: number;
};

export type FormLinkMetadata = BaseLinkMetadata & {
    type: 'form';
    formId: string;
};

export type MeetingLinkMetadata = BaseLinkMetadata & {
    type: 'meeting';
    meetingId: string;
};

export type ExternalLinkMetadata = BaseLinkMetadata & {
    type: 'external';
};

export type LinkMetadata = FormLinkMetadata | MeetingLinkMetadata | ExternalLinkMetadata;

export type LinkType = LinkMetadata['type'];

type InternalLinkData =
    | Pick<FormLinkMetadata, 'type' | 'formId'>
    | Pick<MeetingLinkMetadata, 'type' | 'meetingId'>;

export interface ShortLinksData {
    forms: FormModel2[];
}

export type ShortLinksDataProvider = () => ShortLinksData;

export class LinkDetector {
    private static shortLinksDataProvider: ShortLinksDataProvider | null = null;

    static setShortLinksDataProvider(provider: ShortLinksDataProvider): void {
        this.shortLinksDataProvider = provider;
    }

    static detectLinks(text: string): LinkMetadata[] {
        const res: LinkMetadata[] = [];
        const matches = [
            ...text.matchAll(
                /href="(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\b([-a-zA-Z0-9()@:%_+.~#?&//=;]*))/g
            )
        ];
        for (const match of matches) {
            const url = match[1] ?? '';
            const index = (match.index ?? 0) + 'href="'.length;
            const shortLinkResult = this.tryResolveShortLink(url);
            const baseMetadata: BaseLinkMetadata = {
                link: url,
                relativeLink: this.getInternalRelativeLink(url),
                domain: new URL(url).hostname,
                start: index,
                end: index + url.length
            };
            if (shortLinkResult) {
                res.push({
                    ...shortLinkResult,
                    ...baseMetadata
                });
            } else if (this.isFormLink(url)) {
                res.push({
                    type: 'form',
                    formId: this.getFormId(url),
                    ...baseMetadata
                });
            } else if (this.isMeetingLink(url)) {
                res.push({
                    type: 'meeting',
                    meetingId: this.getMeetingId(url),
                    ...baseMetadata
                });
            } else {
                res.push({
                    type: 'external',
                    ...baseMetadata
                });
            }
        }
        return res;
    }

    private static isFormLink(url: string): boolean {
        return url.startsWith(UrlBuilder.buildUrl('/form-answer/'));
    }

    private static isMeetingLink(url: string): boolean {
        return url.startsWith(UrlBuilder.buildUrl('/joinMeeting/'));
    }

    private static getInternalRelativeLink(url: string): string {
        const baseUrl = UrlBuilder.buildUrl('/');
        if (url.startsWith(baseUrl)) {
            let res = url.slice(baseUrl.length);
            if (!res.startsWith('/')) {
                res = '/' + res;
            }
            return res;
        }
        return url;
    }

    private static getFormId(url: string): string {
        const relativeLink = this.getInternalRelativeLink(url);
        const parts = relativeLink.split('/').filter((s) => s.length > 0);
        return parts[parts.length - 1];
    }

    private static getMeetingId(url: string): string {
        const relativeLink = this.getInternalRelativeLink(url);
        const parts = relativeLink.split('/').filter((s) => s.length > 0);
        const data = parts[parts.length - 1];
        try {
            const res = api.parseThreadLinkStringData(data);
            if (res) {
                return res.threadId;
            }
        } catch {}
        return parts[parts.length - 1];
    }

    private static tryResolveShortLink(url: string): InternalLinkData | null {
        if (!this.shortLinksDataProvider) {
            return null;
        }
        const data = this.shortLinksDataProvider();
        const form = data.forms.find((form) => url === form.shortLink);
        if (form) {
            return {
                type: 'form',
                formId: form.id
            };
        }
        return null;
    }
}
