import {
    AttachmentEx,
    ChatId,
    MeetingId,
    Tag,
    Thread,
    ThreadId,
    Username
} from '../../types/Types';
import { api } from '../../api/Api';
import { BatchMutations, ThreadsFiltersType } from '../../mantineAtoms/ThreadsListActionsBar';
import React, { useCallback, useMemo, useState } from 'react';
import { useGetUnreadThreadIds } from '../../hooks/useIsUnread';
import { DAY_MILLISECONDS } from '../../components/MessageView/ThreadAttachments';
import { useAppSelector } from '../../store';
import {
    selectCachedAttachments,
    selectCachedCompaniesWithUsers
} from '../../store/DataCacheSlice';
import { isFileCategory, mimetypeMap, useFilterContext } from '../../mantineAtoms/FilterSelect';

const handleBatchMethod = (
    threadId: ChatId[],
    method: (thread: ChatId) => Promise<void> | void
) => {
    Promise.all(
        threadId.map(async (id) => {
            return await method(id);
        })
    );
};
type ThreadsType = 'Chat' | 'Meeting';

function setArchived(id: ThreadId[], type: ThreadsType) {
    handleBatchMethod(id as any, async (id) => await api[`set${type}Archived`](id as any, true));
}

function setUnarchived(id: ThreadId[], type: ThreadsType) {
    handleBatchMethod(id as any, async (id) => await api[`set${type}Archived`](id as any, false));
}

const setUnread = (id: (ChatId | MeetingId)[]) =>
    handleBatchMethod(id as any, async (id) => {
        const service = api.getUnreadService();
        if (!service) return new Promise((resolve) => resolve());
        return service.markAllMessagesInThreadAsRead(id);
    });
const setFavourite = (id: (ChatId | MeetingId)[], type: ThreadsType) =>
    handleBatchMethod(id as any, async (id) => await api[`set${type}Pinned`](id as any, true));
const setUnFavourite = (id: ChatId[] | MeetingId[], type: ThreadsType) =>
    handleBatchMethod(id as any, async (id) => await api[`set${type}Pinned`](id as any, false));

function filterThreads(
    threads: Thread[],
    filterState: ThreadsFiltersType,
    unreadThreads: ThreadId[]
): Thread[] {
    const reducedUnreadThreads = [...unreadThreads];
    return threads.filter((thread) => {
        if (filterState === 'all') return true;
        const isUnread = reducedUnreadThreads.find((x) => x === thread.id) !== undefined;
        return isUnread;
    });
}

export type BatchState = {
    type: ThreadsType;
    archive: {
        actionType: 'enable' | 'disable';
    };
    favourite: {
        actionType: 'enable' | 'disable';
    };
};

export function useActionBar(
    type: ThreadsType,
    revalidate: VoidFunction,
    allThreadIds: ThreadId[],
    searchedElements: Thread[]
) {
    const { contextState } = useFilterContext();

    const [filterState, setFilterState] = useState<ThreadsFiltersType>('all');
    const unreadThreads = useGetUnreadThreadIds(allThreadIds);

    const files = useAppSelector(selectCachedAttachments);

    const threadsWithFiles: Map<ThreadId, AttachmentEx[]> = useMemo(() => {
        const map = new Map<ThreadId, AttachmentEx[]>();

        files.forEach((file) => {
            if (map.has(file.chatId)) {
                map.set(file.chatId, [...(map.get(file.chatId) || []), file]);
            } else {
                map.set(file.chatId, [file]);
            }
        });

        return map;
    }, [files]);

    const companies = useAppSelector(selectCachedCompaniesWithUsers);

    const filteredElements = useMemo(() => {
        const filters = contextState.map((filter) => {
            const [type, value] = filter.split(':');
            return { type, value };
        });

        const narrowedThreads = filterThreads(searchedElements, filterState, unreadThreads);
        let filteredThreadsList = [...narrowedThreads];
        for (const filter of filters) {
            switch (filter.type) {
                case 'author':
                    filteredThreadsList = filteredThreadsList.filter((x) =>
                        x.users.find((user) => user === filter.value)
                    );
                    break;
                case 'company':
                    const comapny = companies.find((x) => x.name === filter.value);
                    if (comapny) {
                        filteredThreadsList = filteredThreadsList.filter((thread) =>
                            thread.users.find((user) =>
                                comapny.contacts.find(
                                    (comapnyContact) => comapnyContact.username === user
                                )
                            )
                        );
                    }

                    break;
                case 'date':
                    const [start, end] = filter.value.split('-');
                    filteredThreadsList = filteredThreadsList.filter((thread) => {
                        const startDate =
                            'startDate' in thread ? thread.startDate : thread.lastMsgDate;
                        if (end) {
                            if (
                                !(
                                    startDate < parseInt(end) + DAY_MILLISECONDS &&
                                    startDate > parseInt(start)
                                )
                            )
                                return false;
                        } else {
                            if (
                                !(
                                    startDate < parseInt(start) + DAY_MILLISECONDS &&
                                    startDate > parseInt(start)
                                )
                            )
                                return false;
                        }
                        return true;
                    });
                    break;
                case 'tag':
                    filteredThreadsList = filteredThreadsList.filter((thread) =>
                        thread.tags.find((tag) => tag === filter.value)
                    );
                    break;
                case 'file':
                    filteredThreadsList = filteredThreadsList.filter((thread) => {
                        if (threadsWithFiles.has(thread.id)) {
                            const threadFiles = threadsWithFiles.get(thread.id) || [];

                            if (
                                !threadFiles.find((thread) =>
                                    thread.name.toLowerCase().includes(filter.value.toLowerCase())
                                )
                            ) {
                                return false;
                            }
                        } else {
                            return false;
                        }
                        return true;
                    });
                    break;
                case 'file type':
                    filteredThreadsList = filteredThreadsList.filter((x) => {
                        if (threadsWithFiles.has(x.id)) {
                            const threadFiles = threadsWithFiles.get(x.id) || [];
                            if (isFileCategory(filter.value)) {
                                if (filter.value === 'any') return true;
                                if (
                                    !threadFiles.find(
                                        (x) => mimetypeMap.get(x.contentType) === filter.value
                                    )
                                ) {
                                    return false;
                                }
                            }
                        } else {
                            return false;
                        }
                        return true;
                    });
            }
        }

        return filteredThreadsList;
    }, [filterState, searchedElements, unreadThreads, contextState, threadsWithFiles, companies]);

    const [selectedThreads, setSelectedItems] = useState<ThreadId[]>([]);
    const onCheckboxChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.currentTarget.checked) {
                setSelectedItems(filteredElements.map((x) => x.id));
            } else {
                setSelectedItems([]);
            }
        },
        [filteredElements]
    );

    const threadsMembers = useMemo(() => {
        const usernames = new Set<Username>();
        for (const thread of searchedElements) {
            thread.users.forEach((username) => {
                if (!usernames.has(username)) {
                    usernames.add(username);
                }
            });
        }
        return Array.from(usernames.values());
    }, [searchedElements]);

    const threadsTags = useMemo(() => {
        const tags = new Set<Tag>();
        for (const thread of searchedElements) {
            thread.tags.forEach((username) => {
                if (!tags.has(username)) {
                    tags.add(username);
                }
            });
        }
        return Array.from(tags.values());
    }, [searchedElements]);

    const handleBatchMutation: BatchMutations = useMemo(() => {
        return {
            unarchive() {
                setUnarchived(selectedThreads, type);
            },
            archive: () => {
                setArchived(selectedThreads, type);
                revalidate();
            },
            favourite() {
                setFavourite(selectedThreads, type);
                revalidate();
            },
            unfavourite() {
                setUnFavourite(selectedThreads as any, type);
                revalidate();
            },
            unread() {
                setUnread(selectedThreads);
                revalidate();
            }
        };
    }, [revalidate, selectedThreads, type]);

    return {
        filterState,
        handleBatchMutation,
        onCheckboxChange,
        setFilterState,
        filteredElements,
        selectedThreads,
        setSelectedItems,
        threadsMembers,
        threadsTags
    };
}
