import { Box, Center, Loader } from '@mantine/core';

function Loading(props: { info?: string }) {
    return (
        <Box sx={{ height: '100%', width: '100%', marginTop: '100px' }}>
            <Center>
                <Loader />
            </Center>
        </Box>
    );
}

export default Loading;
