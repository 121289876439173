import { Modal, Box, Center, Flex, Title, Group, Button, Text } from '@mantine/core';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { PasswordSendReminder } from '.';
import { AccountsAddedList } from './AccountsAddedList';
import { MappedUser } from '.';
import { Contact } from '../../../types/Types';

interface AccountsAddedProps {
    onClose: VoidFunction;
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    visible: boolean;
    addedUsers: MappedUser[];
    privateContactsMadeVisible: Contact[];
}

export function AccountsAdded(props: AccountsAddedProps) {
    const [confirmModalState, setConfirmModalState] = useState<'closed' | 'open'>('closed');

    function handleClose() {
        setConfirmModalState(props.addedUsers.length > 0 ? 'open' : 'closed');
        if (props.addedUsers.length === 0) {
            props.onClose();
        }
    }

    useEffect(() => {
        return () => {
            setConfirmModalState('closed');
        };
    }, []);

    return (
        <>
            <Modal
                opened={props.visible && confirmModalState !== 'closed'}
                onClose={props.onClose}
                closeOnClickOutside={false}
                centered
                zIndex={300}
                size={'xl'}
                title="New members">
                <Box py="xl">
                    <PasswordSendReminder
                        onBack={() => setConfirmModalState('closed')}
                        onProceed={props.onClose}
                    />
                </Box>
            </Modal>
            <Modal
                opened={props.visible && confirmModalState === 'closed'}
                onClose={props.onClose}
                closeOnClickOutside={false}
                centered
                zIndex={300}
                size={'xl'}
                title="New members">
                <Box display="flex" h="100%" py="xl">
                    <Center h="100%" sx={{ flexGrow: 1, width: '100%' }}>
                        <Flex direction="column" gap={4}>
                            <Title order={1} align="center">
                                {props.title ??
                                    (props.addedUsers.length > 0
                                        ? t('modal.topicAdd.sendPasswords')
                                        : props.privateContactsMadeVisible.length === 1
                                        ? t('modal.topicAdd.contactAlreadyExists')
                                        : t('modal.topicAdd.contactsAlreadyExist'))}
                            </Title>
                            <Text align="center" color="dimmed" mb="xl">
                                {props.subTitle}
                            </Text>
                            <AccountsAddedList
                                createdUsers={props.addedUsers}
                                failedUsers={[]}
                                privateContactsMadeVisible={props.privateContactsMadeVisible}
                            />
                            <Group position="center" w="100%">
                                <Button variant="light" onClick={() => handleClose()}>
                                    {t('action.done')}
                                </Button>
                            </Group>
                        </Flex>
                    </Center>
                </Box>
            </Modal>
        </>
    );
}
