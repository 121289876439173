import * as PmxApi from 'privmx-server-api';
import { Container, LoadingOverlay, Paper, TextInput, Text, Button } from '@mantine/core';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { api, userApi } from '../../api/Api';
import { useTranslation } from 'react-i18next';
import * as i18next from 'i18next';

export default function AnonymousPasswordLoginScreen() {
    const { t } = useTranslation();
    const routeParams = useParams();
    const meetingData = useMemo(
        () => api.parseThreadLinkStringData(routeParams.meetingData ?? '')!,
        [routeParams]
    );
    const [isProcessing, setIsProcessing] = useState(false);
    const [password, setPassword] = useState('');
    const [hasPasswordError, setHasPasswordError] = useState(false);
    const [loginError, setLoginError] = useState<string | null>(null);
    const navigate = useNavigate();

    const validatePassword = useCallback((value: string) => {
        const isOk = value !== '';
        setHasPasswordError(!isOk);
        return isOk;
    }, []);

    const handlePasswordChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value.trim();
            setPassword(value);
            validatePassword(value);
        },
        [validatePassword]
    );

    const handleJoinClick = useCallback(async () => {
        let isFormOk = true;
        isFormOk = validatePassword(password) && isFormOk;
        if (!isFormOk) {
            return;
        }
        setIsProcessing(true);
        try {
            if (meetingData.type !== 'formThread') {
                throw new Error('Invalid thread data');
            }
            const result = await userApi.enterMeetingLobbyAsAnonymous(
                meetingData.threadId,
                i18next.t('meeting.defaultAnonymousNickname') as PmxApi.api.thread.MeetingNickname
            );
            if (result === 'already-at-the-meeting') {
                api.setThreadLinkData(meetingData);
                api.setFormThreadPassword(password ? password : null);
                navigate(`/meetingRoom/${routeParams.meetingData}`);
            } else {
                navigate(`/meetingLobby/${routeParams.meetingData}`);
            }
        } catch (error) {
            console.error(error);
            setLoginError(t('errorMessage.incorrectLoginData'));
        } finally {
            setIsProcessing(false);
        }
    }, [password, navigate, validatePassword, routeParams.meetingData, meetingData, t]);

    return (
        <Container w={450} my={40} pos="relative">
            <LoadingOverlay visible={isProcessing} />
            {loginError && (
                <Text align="center" c="red.5">
                    {loginError}
                </Text>
            )}
            <Text align="center" size="lg">
                {t('screen.login.joinConversation')}
            </Text>
            <Paper withBorder shadow="md" p={30} mt={30} radius="sm">
                <TextInput
                    label={t('entityProps.password')}
                    placeholder={t('entityProps.password')}
                    value={password}
                    onChange={handlePasswordChange}
                    error={hasPasswordError}
                    required
                />
                <Button fullWidth mt="xl" onClick={handleJoinClick}>
                    {t('action.join')}
                </Button>
            </Paper>
            <Text align="center" size="md" my={'md'}>
                {t('screen.login.haveAccountQuestion')}
                <br />
                <Link to={`/${routeParams.meetingData}`}>
                    {t('screen.login.joinWithUsernameAndPwd')}
                </Link>
            </Text>
        </Container>
    );
}
