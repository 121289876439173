import {
    ActionIcon,
    Box,
    Collapse,
    createStyles,
    getStylesRef,
    Group,
    rem,
    ThemeIcon,
    Text,
    UnstyledButton,
    UnstyledButtonProps,
    Skeleton,
    Button,
    MediaQuery,
    Flex
} from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconLogout, IconPlus } from '@tabler/icons-react';
import { MouseEventHandler, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectCurrentUser, selectCurrentUserRole } from '../../store/CurrentUserSlice';
import { useTranslation } from 'react-i18next';
import UserAvatar from '../../atoms/UserAvatar';
import { userApi } from '../../api/Api';
import { toggleUserSettingsModal } from '../../store/ModalsSlice';
import { useMediaQuery } from '@mantine/hooks';

const useLinkStyles = createStyles((theme) => ({
    control: {
        ...theme.fn.focusStyles(),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textDecoration: 'none',
        fontSize: theme.fontSizes.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
        padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
        borderRadius: theme.radius.sm,
        fontWeight: 500,
        width: '100%',
        '&[data-hoverable = "true"]:hover': {
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black
        }
    },

    link: {
        ref: getStylesRef('link'),
        fontWeight: 500,
        display: 'block',
        textDecoration: 'none',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        paddingLeft: 31,
        marginLeft: 30,
        fontSize: theme.fontSizes.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        borderLeft: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
        }`,
        borderTopRightRadius: theme.radius.sm,
        borderBottomRightRadius: theme.radius.sm,
        '&:hover': {
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black
        }
    },
    linkActive: {
        '&, &:hover': {
            backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor })
                .background,
            color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color
        }
    },
    dot: {
        width: theme.spacing.sm,
        height: theme.spacing.sm,
        borderRadius: theme.radius.xl,
        backgroundColor: theme.colors[theme.primaryColor][6],
        justifySelf: 'end'
    },
    chevron: {
        transition: 'transform 200ms ease'
    },
    addButton: {
        padding: `calc(${theme.spacing.xs} / 3) ${theme.spacing.sm}`,
        backgroundColor: theme.fn.primaryColor(),
        color: 'white',
        marginInline: theme.spacing.md,
        '&:hover': {
            backgroundColor:
                theme.colorScheme === 'dark'
                    ? theme.colors[theme.primaryColor][7]
                    : theme.colors[theme.primaryColor][2],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            '& .mantine-ThemeIcon-root': {
                backgroundColor:
                    theme.colorScheme === 'dark'
                        ? theme.colors[theme.primaryColor][7]
                        : theme.colors[theme.primaryColor][2]
            }
        },
        width: `calc(100% - ${rem(32)})`,
        alignItems: 'center',
        justifyContent: 'center'
    },
    logoutButton: {
        padding: theme.spacing.md,
        [`@media (min-width: ${theme.breakpoints.sm})`]: {
            padding: 4
        }
    }
}));

type LinkProps = {
    icon: any;
    label: string;
    onClick?: VoidFunction;
    hasUnreadContent?: boolean;
    staffOnly: boolean;
};
type LinkOptions =
    | {
          type: 'button';
          links?: { label: string; to: string; staffOnly: boolean }[];
          initiallyOpened?: boolean;
          to?: never;
      }
    | { type: 'link'; to: string; links?: never; initiallyOpened?: never };

export type NavbarLinkProps = LinkProps & LinkOptions;

export function LinksGroup({
    type,
    icon: Icon,
    label,
    initiallyOpened,
    links,
    to,
    onClick,
    hasUnreadContent,
    staffOnly
}: NavbarLinkProps) {
    const { classes, cx, theme } = useLinkStyles();
    const hasLinks = Array.isArray(links);
    const [opened, setOpened] = useState(initiallyOpened || false);
    const ChevronIcon = theme.dir === 'ltr' ? IconChevronRight : IconChevronLeft;
    const currentUserRole = useAppSelector(selectCurrentUserRole);
    const shouldDisplay = staffOnly ? currentUserRole === 'staff' : true;

    const items = (hasLinks ? links : []).map((link) => {
        const showLink = link.staffOnly ? currentUserRole === 'staff' : true;
        return (
            type === 'button' &&
            showLink && (
                <NavLink
                    className={(active) =>
                        cx(classes.link, { [classes.linkActive]: active.isActive })
                    }
                    to={link.to}
                    key={link.label}>
                    {link.label}
                </NavLink>
            )
        );
    });

    const content = (
        <Group position="apart" spacing="xs">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ThemeIcon variant="light" size={30}>
                    <Icon size={18} />
                </ThemeIcon>
                <Box ml="md">{label}</Box>
            </Box>
            {hasLinks && (
                <ChevronIcon
                    className={classes.chevron}
                    size={14}
                    stroke={1.5}
                    style={{
                        transform: opened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none'
                    }}
                />
            )}
        </Group>
    );

    if (!shouldDisplay) return <></>;

    if (type === 'link')
        return (
            <NavLink
                to={to || ''}
                className={(isActive) =>
                    cx(classes.control, { [classes.linkActive]: isActive.isActive })
                }>
                {content}
                {hasUnreadContent && <div className={classes.dot} />}
            </NavLink>
        );

    return (
        <>
            <UnstyledButton
                className={classes.control}
                onClick={() => {
                    hasLinks ? setOpened((o) => !o) : onClick?.();
                }}>
                {content}
                {hasUnreadContent && <div className={classes.dot} />}
            </UnstyledButton>
            {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
        </>
    );
}

export function SidebarCreateMessage(props: UnstyledButtonProps & { onClick: VoidFunction }) {
    const { t } = useTranslation();
    const { classes, cx } = useLinkStyles();

    return (
        <>
            <MediaQuery styles={{ display: 'none' }} smallerThan={'lg'}>
                <UnstyledButton
                    className={cx(classes.control, classes.addButton)}
                    mb="sm"
                    p="md"
                    {...props}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box tt={'capitalize'} mx="md">
                            {t('action.newTopic')}
                        </Box>
                    </Box>
                </UnstyledButton>
            </MediaQuery>
            <MediaQuery styles={{ display: 'none' }} largerThan={'lg'}>
                <UnstyledButton
                    className={cx(classes.control, classes.addButton)}
                    w="auto"
                    mx="auto"
                    mb={'xs'}
                    py="xs"
                    {...props}>
                    <ActionIcon size={'sm'} component="div" variant="transparent" tt={'capitalize'}>
                        <IconPlus />
                    </ActionIcon>
                </UnstyledButton>
            </MediaQuery>
        </>
    );
}

export function SidebarUserButton(props: UnstyledButtonProps) {
    const { classes, cx } = useLinkStyles();

    const currentUser = useAppSelector(selectCurrentUser);
    const currentUserName = currentUser.name || currentUser.username || '';

    const dispatch = useAppDispatch();

    const handleOpenUserSettings = () => {
        dispatch(toggleUserSettingsModal({ open: true }));
    };

    const handleLogOut: MouseEventHandler = useCallback((e) => {
        userApi.logout();
        e.stopPropagation();
    }, []);

    const isMobile = useMediaQuery('(max-width: 48em)');

    if (!currentUser.username) return <Skeleton width={217} h={50} />;
    return (
        <Button.Group
            sx={{
                '@media (min-width:48em) and (max-width:1200px)': {
                    flexDirection: 'column-reverse',
                    flexWrap: 'wrap-reverse',
                    width: '100%'
                }
            }}>
            <UnstyledButton
                data-hoverable={isMobile ? 'false' : 'true'}
                className={cx(classes.control)}
                px={0}
                sx={{
                    justifyContent: 'left',
                    width: '100%'
                }}
                styles={{
                    inner: { justifyContent: 'start' },
                    label: { flexGrow: 1 }
                }}
                mb="sm"
                onClick={() => !isMobile && handleOpenUserSettings()}
                {...props}>
                <Flex
                    sx={{
                        '@media (min-width:48em) and (max-width:1200px)': {
                            marginInline: 'auto',
                            width: 'auto'
                        }
                    }}
                    w="100%"
                    gap={8}>
                    {currentUserName && <UserAvatar size={30} username={currentUser.username} />}
                    <MediaQuery styles={{ display: 'none' }} smallerThan={'lg'} largerThan={'sm'}>
                        <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
                            <Text maw={90} truncate>
                                {currentUserName}
                            </Text>
                        </Box>
                    </MediaQuery>
                </Flex>
            </UnstyledButton>
            <Button
                variant="subtle"
                size="lg"
                className={classes.logoutButton}
                onClick={handleLogOut}>
                <ActionIcon
                    variant="transparent"
                    component={'div'}
                    sx={(theme) => ({
                        color:
                            theme.colorScheme === 'dark'
                                ? theme.colors.dark[1]
                                : theme.colors.gray[7],
                        '&:hover': {
                            color:
                                theme.colorScheme === 'dark'
                                    ? theme.colors.dark[0]
                                    : theme.colors.gray[4]
                        }
                    })}
                    size={30}>
                    <IconLogout size={18} />
                </ActionIcon>
            </Button>
        </Button.Group>
    );
}
