export type NumericEnumerable = { [index: number]: number };

export interface DetectionResult {
    ext: string;
    mime: string;
}

export class ImageTypeDetector {
    private static readonly MAGIC_HEADER_MAX_LENGTH = 8;

    static check(header: number[], data: NumericEnumerable) {
        for (let i = 0; i < header.length; i++) {
            if (header[i] !== data[i]) {
                return false;
            }
        }
        return true;
    }

    static detect(data: NumericEnumerable): DetectionResult | null {
        if (ImageTypeDetector.check([0xff, 0xd8, 0xff], data)) {
            return {
                ext: 'jpg',
                mime: 'image/jpeg'
            };
        }
        if (ImageTypeDetector.check([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a], data)) {
            return {
                ext: 'png',
                mime: 'image/png'
            };
        }
        if (ImageTypeDetector.check([0x47, 0x49, 0x46], data)) {
            return {
                ext: 'gif',
                mime: 'image/gif'
            };
        }
        if (
            ImageTypeDetector.check([0x49, 0x49, 0x2a, 0x0], data) ||
            ImageTypeDetector.check([0x4d, 0x4d, 0x0, 0x2a], data)
        ) {
            return {
                ext: 'tif',
                mime: 'image/tiff'
            };
        }
        if (ImageTypeDetector.check([0x42, 0x4d], data)) {
            return {
                ext: 'bmp',
                mime: 'image/bmp'
            };
        }
        return null;
    }

    static extractBufferFromDatatUrl(dataUrl: string): Buffer {
        const comaIndex = dataUrl.indexOf(',');
        return Buffer.from(dataUrl.substring(comaIndex + 1), 'base64');
    }

    static createDataUrlFromBuffer(buffer: Buffer): string {
        const mime = ImageTypeDetector.detect(buffer);
        return (
            'data:' +
            (mime == null ? 'image/jpeg' : mime.mime) +
            ';base64,' +
            buffer.toString('base64')
        );
    }

    static getFirstBytesFromBase64(base64: string, length: number): Buffer {
        const base64Length = (Math.floor(length / 3) + (length % 3 === 0 ? 0 : 1)) * 4;
        return Buffer.from(base64.substring(0, base64Length), 'base64');
    }

    static createDataUrlFromBase64(base64: string): string {
        const header = ImageTypeDetector.getFirstBytesFromBase64(
            base64,
            ImageTypeDetector.MAGIC_HEADER_MAX_LENGTH
        );
        const mime = ImageTypeDetector.detect(header);
        return 'data:' + (mime == null ? 'image/jpeg' : mime.mime) + ';base64,' + base64;
    }
}
