import { Template } from "../../../core/utils";

const html = require("./userContainer.html").default;

export interface UserContainerTemplateModel {
    participantId: string;
    participantHashmail: string;
    participantName: string;
    localParticipantClass: string;
    dominantSpeakerClass: string;
}

export class UserContainerTemplate extends Template<HTMLDivElement> {
    
    constructor(i18n: (key: string, ...args: string[]) => string) {
        super(html, i18n);
    }
    
    render(model: UserContainerTemplateModel): HTMLDivElement {
        const $rendered = super.render(model);
        if (model.localParticipantClass) {
            $rendered.classList.add(model.localParticipantClass);
        }
        if (model.dominantSpeakerClass) {
            $rendered.classList.add(model.dominantSpeakerClass);
        }
        $rendered.setAttribute("data-participant-id", model.participantId);
        $rendered.setAttribute("data-hashmail", model.participantHashmail);
        $rendered.querySelectorAll<HTMLSpanElement>(".participant-name").forEach(element => {
            element.innerText = model.participantName;
        });
        $rendered.querySelectorAll<HTMLCanvasElement>("canvas.not-rendered").forEach(canvas => {
            canvas.setAttribute("data-hashmail-image", model.participantHashmail);
            canvas.setAttribute("data-tooltip-trigger", model.participantHashmail);
        });
        $rendered.querySelector(".connection-quality-indicator")?.setAttribute("data-connection-stats-id", model.participantId);
        $rendered.querySelector("video")?.setAttribute("data-participant-id", model.participantId);
        return $rendered;
    }
    
}
