import {
    Box,
    BoxProps,
    Button,
    Flex,
    Group,
    Overlay,
    Paper,
    Radio,
    Stack,
    Switch,
    Text,
    TextInput,
    Transition,
    rem
} from '@mantine/core';
import { IconAt, IconDeviceFloppy, IconPhone, IconReload } from '@tabler/icons-react';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { api } from '../../api/Api';
import {
    ChallengeModelSerializable,
    TwofaApi,
    TwofaEnableData,
    TwofaMethod,
    TwofaResult
} from '../../api/privmx/TwofaApi';
import { TwofaPromptModalData } from '../../components/TwofaPrompt/TwofaPromptModal';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import TwofaPrompt from '../../components/TwofaPrompt';
import { useTranslation } from 'react-i18next';
import { notifications } from '@mantine/notifications';
import { useSettingsStyle } from './SettingsScreen';
const QRious = require('qrious');

export interface TwofaSettingsProps {
    twofaResult: TwofaResult;
}

function checkIsEmailValid(email: string) {
    return /^[a-z0-9_.+-]+@[a-z0-9_.-]+$/.test(email);
}

function checkIsMobileValid(mobile: string) {
    return /^[0-9+ ]{3,18}$/.test(mobile);
}

const QR_SIZE = 150;

export function TwofaSettings({ twofaResult, className, ...props }: TwofaSettingsProps & BoxProps) {
    const [isProcessing, setIsProcessing] = useState(false);
    const [enablingTwofa, setEnablingTwofa] = useState(true);
    const [isTwofaEnabledOnServer, setIsTwofaEnabledOnServer] = useState(twofaResult.data.enabled);
    const [isTwofaEnabled, setIsTwofaEnabled] = useState(twofaResult.data.enabled);
    const [attemptsLeft, setAtteptsLeft] = useState(3);
    const [twofaMethod, setTwofaMethod] = useState<TwofaMethod>(
        twofaResult.data.enabled ? twofaResult.data.type : 'googleAuthenticator'
    );
    const [googleAuthenticatorKey, setGoogleAuthenticatorKey] = useState(
        twofaResult.data.enabled && twofaResult.data.type === 'googleAuthenticator'
            ? twofaResult.data.googleAuthenticatorKey
            : api.generateGoogleAuthenticatorKey() ?? ''
    );
    const [email, setEmail] = useState(
        twofaResult.data.enabled && twofaResult.data.type === 'email' ? twofaResult.data.email : ''
    );
    const [mobile, setMobile] = useState(
        twofaResult.data.enabled && twofaResult.data.type === 'sms' ? twofaResult.data.mobile : ''
    );
    const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);
    const [isMobileValid, setIsMobileValid] = useState<boolean | null>(null);
    const googleAuthenticatorQrCodePlaceholderRef = useRef<HTMLDivElement>(null);

    const [confirmModal, { open, close }] = useDisclosure();
    const ref = useClickOutside(() => close);

    const handleToggleTwofaEnabled = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked === false && isTwofaEnabledOnServer) {
                open();
                setIsTwofaEnabled(true);
            } else {
                setIsTwofaEnabled(event.target.checked);
                setGoogleAuthenticatorKey(api.generateGoogleAuthenticatorKey());
            }
        },
        [open, isTwofaEnabledOnServer]
    );

    const generateNewGoogleAuthenticatorKey = useCallback(() => {
        setGoogleAuthenticatorKey(api.generateGoogleAuthenticatorKey() ?? '');
    }, []);

    const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }, []);

    const handleSmsChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setMobile(event.target.value);
    }, []);

    const handleEmailBlur = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsEmailValid(checkIsEmailValid(event.target.value));
    }, []);

    const handleMobileBlur = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsMobileValid(checkIsMobileValid(event.target.value));
    }, []);

    const [two2FAConfirm, setTwo2FAConfirm] = useState<boolean>(false);
    const [two2FAOptions, setTwo2FAOptions] = useState<TwofaPromptModalData | null>(null);

    const { t } = useTranslation();

    const disableTwofa = async () => {
        setIsProcessing(true);
        try {
            const res = await api.disableTwofa();
            setAtteptsLeft(res.attempts);
            setEnablingTwofa(false);
            setTwo2FAConfirm(true);
            setTwo2FAOptions({
                options: {
                    method: twofaMethod,
                    showRememberDevice: false,
                    errorMessage: '',
                    uf2Login: res.webauthnLogin
                }
            });
            close();
        } catch (e) {
        } finally {
            setIsProcessing(false);
        }
    };

    const handleSave = useCallback(async () => {
        if (!isTwofaEnabled) {
            return;
        }
        if (isTwofaEnabled && twofaMethod === TwofaApi.TWOFA_METHOD_EMAIL) {
            const isValid = checkIsEmailValid(email);
            setIsEmailValid(isValid);
            if (!isValid) {
                return;
            }
        }
        if (isTwofaEnabled && twofaMethod === TwofaApi.TWOFA_METHOD_SMS) {
            const isValid = checkIsMobileValid(mobile);
            setIsMobileValid(isValid);
            if (!isValid) {
                return;
            }
        }
        setIsProcessing(true);
        try {
            let data: TwofaEnableData | null = null;
            if (twofaMethod === TwofaApi.TWOFA_METHOD_GOOGLE_AUTHENTICATOR) {
                data = {
                    type: 'googleAuthenticator',
                    googleAuthenticatorKey: googleAuthenticatorKey
                };
            } else if (twofaMethod === TwofaApi.TWOFA_METHOD_EMAIL) {
                data = {
                    type: 'email',
                    email: email
                };
            } else if (twofaMethod === TwofaApi.TWOFA_METHOD_SMS) {
                data = {
                    type: 'sms',
                    mobile: mobile
                };
            } else if (twofaMethod === TwofaApi.TWOFA_METHOD_U2F) {
                data = {
                    type: 'u2f'
                };
            }
            if (data) {
                const res = await api.enableTwofa(data);
                setAtteptsLeft(res.attempts);
                const webauthnRegister = res.webauthnRegister;
                setEnablingTwofa(true);
                setTwo2FAConfirm(true);
                setTwo2FAOptions({
                    options: {
                        method: twofaMethod,
                        errorMessage: '',
                        uf2Register: webauthnRegister
                    }
                });
            }
        } catch (e) {
        } finally {
            setIsProcessing(false);
        }
    }, [isTwofaEnabled, twofaMethod, googleAuthenticatorKey, email, mobile]);

    const handleResendTwofa = async () => {
        await api.resendTwofaCode();
        notifications.show({
            message: t('twofa.message.codeSent')
        });
    };

    const handleSaveTwofa = useCallback(
        async (model: ChallengeModelSerializable) => {
            let errorMessage = '';
            try {
                await api.twofaChallenge(api.deserializeTwofaChallengeModel(model));
                setTwo2FAConfirm(false);
                setIsTwofaEnabled(enablingTwofa);
                setIsTwofaEnabledOnServer(enablingTwofa);
                notifications.show({
                    message: t(
                        enablingTwofa
                            ? 'twofa.message.settingsSaved'
                            : 'twofa.message.twofaDisabled'
                    )
                });
            } catch (e) {
                console.error('2FA Error', e);
                if (isError(e)) {
                    if (e.msg === 'Invalid code') {
                        const newAttemptsLeft = attemptsLeft - 1;
                        setAtteptsLeft(newAttemptsLeft);
                        errorMessage = `${e.msg} (${newAttemptsLeft} attempt${
                            newAttemptsLeft === 1 ? '' : 's'
                        } Left)`;
                        if (two2FAOptions) {
                            setTwo2FAOptions({
                                options: {
                                    ...two2FAOptions.options,
                                    errorMessage: errorMessage
                                }
                            });
                        }
                    } else {
                        notifications.show({
                            message: e.msg,
                            color: 'red'
                        });
                        setTwo2FAConfirm(false);
                    }
                } else {
                    notifications.show({
                        message: t('twofa.error.settingsNotSaved'),
                        color: 'red'
                    });
                }
            }
        },
        [enablingTwofa, attemptsLeft, two2FAOptions, t]
    );

    useLayoutEffect(() => {
        if (!isTwofaEnabled || twofaMethod !== TwofaApi.TWOFA_METHOD_GOOGLE_AUTHENTICATOR) {
            return;
        }
        const qr = new QRious({
            level: 'M',
            size: QR_SIZE,
            value: api.getGoogleAuthenticatorKeyUri(googleAuthenticatorKey)
        });
        if (googleAuthenticatorQrCodePlaceholderRef.current) {
            googleAuthenticatorQrCodePlaceholderRef.current.innerHTML = '';
            googleAuthenticatorQrCodePlaceholderRef.current.append(qr.canvas);
        }
    }, [googleAuthenticatorKey, isTwofaEnabled, twofaMethod]);

    const { classes, cx } = useSettingsStyle();

    return (
        <Box className={cx(classes.withHover)} {...props}>
            <Flex className={classes.element}>
                <Text weight={300}>2FA</Text>
                <Switch ml="auto" onChange={handleToggleTwofaEnabled} checked={isTwofaEnabled} />
            </Flex>
            {two2FAConfirm && two2FAOptions && (
                <>
                    <TwofaPrompt
                        onResend={handleResendTwofa}
                        onSave={handleSaveTwofa}
                        handleClose={() => setTwo2FAConfirm(false)}
                        twofaPrompt={two2FAOptions.options}
                    />
                </>
            )}
            <Transition mounted={isTwofaEnabled && !two2FAConfirm} keepMounted transition={'fade'}>
                {(styles) => (
                    <Group py="md" style={styles} position="center" align="flex-start">
                        <Radio.Group
                            name="twofaMethod"
                            value={twofaMethod}
                            onChange={(value) => {
                                setTwofaMethod(value as TwofaMethod);
                            }}>
                            <Stack w={250} spacing="xs">
                                {twofaResult.methods.includes(
                                    TwofaApi.TWOFA_METHOD_GOOGLE_AUTHENTICATOR
                                ) && (
                                    <Radio
                                        value={TwofaApi.TWOFA_METHOD_GOOGLE_AUTHENTICATOR}
                                        label="Google Authenticator"
                                    />
                                )}
                                {twofaResult.methods.includes(TwofaApi.TWOFA_METHOD_EMAIL) && (
                                    <Radio value={TwofaApi.TWOFA_METHOD_EMAIL} label="Email" />
                                )}
                                {twofaResult.methods.includes(TwofaApi.TWOFA_METHOD_SMS) && (
                                    <Radio value={TwofaApi.TWOFA_METHOD_SMS} label="Sms" />
                                )}
                                {twofaResult.methods.includes(TwofaApi.TWOFA_METHOD_U2F) && (
                                    <Radio value={TwofaApi.TWOFA_METHOD_U2F} label="U2F" />
                                )}
                                <Button
                                    w={100}
                                    size="xs"
                                    variant="outline"
                                    onClick={handleSave}
                                    leftIcon={<IconDeviceFloppy size={rem(12)} />}
                                    loading={isProcessing}>
                                    Save
                                </Button>
                            </Stack>
                        </Radio.Group>
                        <Flex h={QR_SIZE} w={250} justify="center">
                            {twofaMethod === TwofaApi.TWOFA_METHOD_EMAIL && (
                                <TextInput
                                    label="Your email"
                                    style={styles}
                                    w={250}
                                    icon={<IconAt size={16} />}
                                    placeholder={'email@domain.com'}
                                    type="text"
                                    value={email}
                                    onChange={handleEmailChange}
                                    onBlur={handleEmailBlur}
                                    className={isEmailValid === false ? 'with-error' : ''}
                                />
                            )}
                            {twofaMethod === TwofaApi.TWOFA_METHOD_SMS && (
                                <TextInput
                                    label="Your phone number"
                                    style={styles}
                                    w={250}
                                    icon={<IconPhone size={16} />}
                                    placeholder={'000 000 000'}
                                    type="number"
                                    value={email}
                                    onChange={handleSmsChange}
                                    onBlur={handleMobileBlur}
                                    className={isMobileValid === false ? 'with-error' : ''}
                                />
                            )}
                            {twofaMethod === TwofaApi.TWOFA_METHOD_GOOGLE_AUTHENTICATOR && (
                                <Flex gap="sm" style={styles}>
                                    <Box>
                                        <Box
                                            pos="relative"
                                            sx={{
                                                '&:hover': {
                                                    '& .mantine-Overlay-root': {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }
                                                }
                                            }}>
                                            <Box
                                                sx={{
                                                    '& canvas': {
                                                        height: QR_SIZE,
                                                        width: QR_SIZE
                                                    }
                                                }}
                                                ref={googleAuthenticatorQrCodePlaceholderRef}
                                            />
                                            <Overlay
                                                styles={{ root: { display: 'none' } }}
                                                onClick={generateNewGoogleAuthenticatorKey}
                                                opacity={0.4}>
                                                <Box component={IconReload} color="white" />
                                            </Overlay>
                                        </Box>
                                    </Box>
                                </Flex>
                            )}
                        </Flex>
                    </Group>
                )}
            </Transition>
            {confirmModal && (
                <Overlay center opacity={0}>
                    <Paper ref={ref} p="md" shadow="md">
                        <Text mb="md" align="center">
                            Are you sure you want to disable 2FA?
                        </Text>
                        <Group position="center">
                            <Button
                                variant="light"
                                onClick={() => {
                                    setIsTwofaEnabled(true);
                                    close();
                                }}>
                                Cancel
                            </Button>
                            <Button onClick={disableTwofa}>Disable 2FA</Button>
                        </Group>
                    </Paper>
                </Overlay>
            )}
        </Box>
    );
}

function isError(e: unknown): e is { msg: string } {
    return !!e && typeof e === 'object' && 'msg' in e && typeof (e as any).msg === 'string';
}
