import React, { useCallback } from 'react';
import { useDataSubscribion } from '../../../hooks/useDataSubscribion';
import { useParams } from 'react-router-dom';
import { FormId } from '../../../types/Types';
import { Center, Title } from '@mantine/core';
import StateView from '../../../atoms/State';
import { FormResponseView } from './FormResponseView';
import { FormResponsesSkeleton } from './FormResponsesSkeleton';
import { isNewFormId } from '../FormCreator/components/FormState/useFormState';

export function FormResponses() {
    const { formId } = useParams();

    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForFormWithEntriesAndContacts(formId as FormId), [formId])
    );

    if (isNewFormId(formId)) {
        return (
            <Center h="100%">
                <Title color="dimmed">You have to publish form first</Title>
            </Center>
        );
    }

    return (
        <StateView state={state} retry={revalidate} loading={FormResponsesSkeleton}>
            {(data) => <FormResponseView form={data.form} contacts={data.contacts} />}
        </StateView>
    );
}
