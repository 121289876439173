export function mimetypeToType(mimetype: string) {
    if (mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return 'Word File';
    }
    if (mimetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return 'Exel File';
    }
    if (mimetype.startsWith('audio/')) {
        return 'Audio File';
    }
    if (mimetype.startsWith('image/')) {
        return 'Image File';
    }
    if (mimetype.startsWith('video/')) {
        return 'Video File';
    }
    if (mimetype === 'application/pdf') {
        return 'PDF File';
    }
    if (mimetype === 'text/plain') {
        return 'Text File';
    }
    return '----';
}

export function fileSizeFormatter(size: number) {
    let reducedSize = size / 1000;
    if (reducedSize < 1024) return `${reducedSize.toFixed(1)}KB`;
    reducedSize = reducedSize / 1000;
    if (reducedSize < 1024) return `${reducedSize.toFixed(2)}MB`;
    reducedSize = reducedSize / 1000;
    if (reducedSize < 1024) return `${reducedSize.toFixed(2)}GB`;
}

export function milisecondsToTime(time: number) {
    let hours: number = 0;
    let minutes: number = 0;

    if (time <= 0) {
        return '0min';
    }

    hours = Math.floor(time / (1000 * 60 * 60));
    minutes = Math.floor((time - hours * 1000 * 60 * 60) / (1000 * 60));

    return `${hours > 0 ? `${hours}h` : ''} ${
        hours <= 0 ? `${minutes}min` : minutes > 0 ? `${minutes}min` : ''
    }`;
}
