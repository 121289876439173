import * as components from "./components";
import * as core from "./core";
import "./styles.js";


export {
    components,
    core,
}

const privmxVideoConferences = {
    components,
    core,
};

declare global {
    interface Window {
        privmxVideoConferences: typeof privmxVideoConferences,
    }
}

if (typeof(window) !== "undefined") {
    const wnd = window as any;
    if (!wnd.privmx) {
        wnd.privmx = {};
    }
    wnd.privmx.videoConferences = {
        components,
        core,
    };
}

(<any>this).privmxVideoConferences = privmxVideoConferences;
