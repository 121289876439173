import { Skeleton, Checkbox, Button, Grid } from '@mantine/core';
import { useRef, useCallback } from 'react';
import StateView from '../../atoms/State';
import useLoader from '../../hooks/useLoader';
import { MeetingId } from '../../types/Types';
import MeetingEditPage from './MeetingModals/MeetingEditPage';
import { useTranslation } from 'react-i18next';

function MeetingEditDialogLoader() {
    const { t } = useTranslation();
    return (
        <Grid columns={2}>
            {new Array(3).fill('').map((_, i) => (
                <Grid.Col key={i} span={2}>
                    <Skeleton height={21} width={50} mb={4} opacity={0.5}></Skeleton>
                    <Skeleton height={36} width="100%" opacity={0.4}></Skeleton>
                </Grid.Col>
            ))}
            <Grid.Col span={1}>
                <Skeleton height={21} width={50} mb={4} opacity={0.5}></Skeleton>
                <Skeleton height={36} width="100%" opacity={0.4}></Skeleton>
            </Grid.Col>
            <Grid.Col span={1}>
                <Skeleton height={21} width={50} mb={4} opacity={0.5}></Skeleton>
                <Skeleton height={36} width="100%" opacity={0.4}></Skeleton>
            </Grid.Col>
            <Grid.Col span={2}>
                <Skeleton height={21} width={50} mb={4} opacity={0.5}></Skeleton>
                <Skeleton height={36} width="100%" opacity={0.4}></Skeleton>
            </Grid.Col>
            <Grid.Col span={2}>
                <Skeleton opacity={0.4}>
                    <Checkbox label={t('addedUsersCanSeeOldStuff')} />
                </Skeleton>
            </Grid.Col>
            <Grid.Col span={2}>
                <Skeleton opacity={0.4}>
                    <Button>{t('action.submit')}</Button>
                </Skeleton>
            </Grid.Col>
        </Grid>
    );
}

export default function MeetingEditDialog(props: { id: MeetingId; onClose: () => void }) {
    const ref = useRef();
    const { state, revalidate } = useLoader(
        useCallback((x) => x.loadMeetingForEditing(props.id), [props.id])
    );
    return (
        <StateView state={state} retry={revalidate} loading={MeetingEditDialogLoader}>
            {(data) => <MeetingEditPage ref={ref} meetingEdit={data} handleClose={props.onClose} />}
        </StateView>
    );
}
