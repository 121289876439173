import * as PmxApi from 'privmx-server-api';
import * as privfs from 'privfs-client';

export class ApiSerializer {
    getEccPublicKey(pubKey: privfs.crypto.ecc.PublicKey) {
        return pubKey.toBase58DER() as PmxApi.api.core.EccPubKey;
    }

    getEccSignature(signature: Buffer) {
        return signature.toString('base64') as PmxApi.api.core.EccSignature;
    }

    getPkiKeystore(keystore: privfs.pki.Types.keystore.IKeyStore2) {
        return keystore.serialize().toString('base64') as PmxApi.api.pki.PkiKeystore;
    }

    getPkiSignature(keystore: privfs.pki.Types.keystore.ISignature2) {
        return keystore.serialize().toString('base64') as PmxApi.api.pki.PkiSignature;
    }

    getHex(buffer: Buffer) {
        return buffer.toString('hex') as PmxApi.api.core.Hex;
    }

    getBase64(buffer: Buffer) {
        return buffer.toString('base64') as PmxApi.api.core.Base64;
    }
}
