import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    Attachment,
    AttachmentEx,
    ChatId,
    MeetingId,
    Message,
    File,
    Tag,
    InquiryAttachment,
    InquiryAttachmentEx,
    SharedFileFileAsAttachment,
    DuplicatedFilesAction,
    UsernameOrContactId,
    DraftId,
    ThreadId,
    Username,
    Contact,
    Company,
    User
} from '../types/Types';
import type { RootState, store as appStore } from './Store';
import { Deferred } from '../utils/Deferred';
import { ChallengeModelSerializable } from '../api/privmx/TwofaApi';
import { ContactId } from '../types/Types';
import { NewThreadFormData } from '../components/ChatAddPage';
import { PostAddContactsModalData } from '../components/PostAddContactsModal';
import { MappedUser } from '../components/ModalsOutlet/AccountsAdded';

export interface AttachmentPreview {
    attachment:
        | Attachment
        | AttachmentEx
        | InquiryAttachment
        | InquiryAttachmentEx
        | SharedFileFileAsAttachment;
}

export interface SingleChatMessage {
    message: Message;
    chatId?: ChatId;
    meetingId?: MeetingId;
    chatOrMeetingTitle: string;
}

export interface GalleryState {
    files: File[];
    activeFile: File;
}

export type TwofaResult =
    | { cancelled: true }
    | { cancelled: false; resendCode: true }
    | { cancelled: false; challengeModel: ChallengeModelSerializable }
    | { error: string };

export interface BatchMessage {
    chatIds: ChatId[];
    message: string;
    open: boolean;
}

type EditModal = { type: 'none'; payload: {} } | { type: 'contact'; payload: { id: ContactId } };

export interface ModalsState {
    newMessageOpen: {
        opened: boolean;
        payload: {
            users?: UsernameOrContactId[];
            draftId?: DraftId;
            formSnapshot?: NewThreadFormData;
            firstMessage?: string;
        };
    };
    newMeetingOpen: {
        opened: boolean;
        payload: {
            users?: UsernameOrContactId[];
            draftId?: DraftId;
            basedOnThreadId?: ThreadId;
            managers?: Username[];
        };
    };
    shareContactAccessOpen: {
        opened: boolean;
        username?: Username;
        temporaryPassword?: string;
    };
    companyModal: {
        opened: boolean;
        state: 'preview' | 'new';
        payload: {
            company?: Company['id'];
            member?: Contact | User;
            newCompany?: {
                name: string;
                contacts: Array<Contact>;
            };
        };
    };
    newContact: {
        opened: boolean;
    };
    postAddContacts: {
        opened: boolean;
        data: PostAddContactsModalData;
    };
    newCompany: {
        opened: boolean;
        initialUsers: Contact[];
        initialName: string;
    };
    userSettings: {
        open: boolean;
    };
    adminPanel: {
        open: boolean;
        initialTab?: 'staff' | 'contacts' | 'plan';
    };
    accountsAdded: {
        open: boolean;
        title?: React.ReactNode;
        subTitle?: React.ReactNode;
        addedUsers: MappedUser[];
        privateContactsMadeVisible: Contact[];
        onClose?: VoidFunction;
    };
    editModal: EditModal;
    chatTags: Tag[] | null;
    meetingTags: Tag[] | null;
    formTags: Tag[] | null;
    twofaResult: TwofaResult | null;
    duplicatedFilesAction: DuplicatedFilesAction | null;
}

const initialState: ModalsState = {
    accountsAdded: { open: false, addedUsers: [], privateContactsMadeVisible: [] },
    newContact: { opened: false },
    postAddContacts: { opened: false, data: { numAddedContacts: 0 } },
    newCompany: { initialUsers: [], opened: false, initialName: '' },
    userSettings: { open: false },
    adminPanel: { open: false },
    editModal: { type: 'none', payload: {} },
    companyModal: { opened: false, payload: {}, state: 'preview' },
    newMeetingOpen: { opened: false, payload: {} },
    newMessageOpen: { opened: false, payload: {} },
    shareContactAccessOpen: {
        opened: false
    },
    chatTags: null,
    meetingTags: null,
    formTags: null,
    twofaResult: null,
    duplicatedFilesAction: null
};

export const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        toggleAccountsAddedtModal: (
            state,
            action: PayloadAction<Partial<ModalsState['accountsAdded']>>
        ) => {
            state.accountsAdded = { ...state.accountsAdded, ...action.payload };
        },
        toggleNewContactModal: (
            state,
            action: PayloadAction<Partial<ModalsState['newContact']>>
        ) => {
            state.newContact = { ...state.newContact, ...action.payload };
        },
        togglePostAddContactsModal: (
            state,
            action: PayloadAction<Partial<ModalsState['postAddContacts']>>
        ) => {
            state.postAddContacts = { ...state.postAddContacts, ...action.payload };
        },
        toggleNewComapnyModal: (
            state,
            action: PayloadAction<Partial<ModalsState['newCompany']>>
        ) => {
            state.newCompany = { ...state.newCompany, ...action.payload };
        },
        toggleUserSettingsModal: (
            state,
            action: PayloadAction<{
                open: boolean;
            }>
        ) => {
            state.userSettings.open = action.payload.open;
        },
        toggleAdminSettingsModal: (
            state,
            action: PayloadAction<{
                open: boolean;
                initialTab?: ModalsState['adminPanel']['initialTab'];
            }>
        ) => {
            state.adminPanel.open = action.payload.open;
            state.adminPanel.initialTab = action.payload.initialTab;
        },
        toggleCompanyModal: (
            state,
            action: PayloadAction<{
                open: boolean;
                state?: ModalsState['companyModal']['state'];
                payload?: ModalsState['companyModal']['payload'];
            }>
        ) => {
            state.companyModal.opened = action.payload.open;
            if (action.payload.open === false) {
                state.companyModal.state = 'preview';
                state.companyModal.payload = {};
                return;
            }
            if (action.payload.state) {
                state.companyModal.state = action.payload.state;
            }
            if (action.payload.payload) state.companyModal.payload = action.payload.payload;
        },

        toggleNewMessageModal: (
            state,
            action: PayloadAction<{
                open: boolean;
                payload?: ModalsState['newMessageOpen']['payload'];
            }>
        ) => {
            state.newMessageOpen.opened = action.payload.open;
            state.newMessageOpen.payload = action.payload.payload || {};
        },
        toggleNewMeetingModal: (
            state,
            action: PayloadAction<{
                open: boolean;
                payload?: ModalsState['newMeetingOpen']['payload'];
            }>
        ) => {
            state.newMeetingOpen.opened = action.payload.open;
            state.newMeetingOpen.payload = action.payload.payload || {};
        },
        toggleShareAccessModal: (
            state,
            action: PayloadAction<ModalsState['shareContactAccessOpen']>
        ) => {
            state.shareContactAccessOpen = action.payload;
        },
        setChatTags: (state, action: PayloadAction<Tag[] | null>) => {
            state.chatTags = action.payload;
        },
        addNewChatTag: (state, action: PayloadAction<Tag[]>) => {
            state.chatTags = state.chatTags
                ? [...state.chatTags, ...action.payload]
                : [...action.payload];
        },
        setFormTags: (state, action: PayloadAction<Tag[] | null>) => {
            state.formTags = action.payload;
        },
        setMeetingTags: (state, action: PayloadAction<Tag[] | null>) => {
            state.meetingTags = action.payload;
        },
        setTwofaResult: (state, action: PayloadAction<TwofaResult | null>) => {
            state.twofaResult = action.payload;
        },
        setDuplicatedFilesAction: (state, action: PayloadAction<DuplicatedFilesAction | null>) => {
            state.duplicatedFilesAction = action.payload;
        },

        resetModalsState: () => {
            return initialState;
        }
    }
});

export const {
    toggleAdminSettingsModal,
    toggleAccountsAddedtModal,
    toggleNewContactModal,
    togglePostAddContactsModal,
    toggleUserSettingsModal,
    toggleCompanyModal,
    addNewChatTag,
    toggleNewMeetingModal,
    toggleNewMessageModal,
    toggleShareAccessModal,
    setTwofaResult,
    setChatTags,
    setMeetingTags,
    setFormTags,
    setDuplicatedFilesAction,
    resetModalsState,
    toggleNewComapnyModal
} = modalsSlice.actions;

export const selectModalState = (state: RootState) => state.modals;

function getNewMessageOpen(modal: ModalsState) {
    return modal.newMessageOpen.opened;
}
function getNewMessagePayload(modal: ModalsState) {
    return modal.newMessageOpen.payload;
}
function getNewMeetingOpen(modal: ModalsState) {
    return modal.newMeetingOpen.opened;
}
function getNewMeetingPayload(modal: ModalsState) {
    return modal.newMeetingOpen.payload;
}
export const selectNewMessageModalOpen = createSelector([selectModalState], getNewMessageOpen);
export const selectNewMessageModalPayload = createSelector(
    [selectModalState],
    getNewMessagePayload
);

export const selectNewMeetingModalOpen = createSelector([selectModalState], getNewMeetingOpen);
export const selectNewMeetingModalPayload = createSelector(
    [selectModalState],
    getNewMeetingPayload
);

export const selectCompanyModalState = createSelector(
    [selectModalState],
    (modal) => modal.companyModal.state
);

export const selectNewCompanyModalState = createSelector([selectModalState], (state) => {
    return state.newCompany;
});

export const selectCompanyModalPayload = createSelector(
    [selectModalState],
    (modal) => modal.companyModal.payload
);
export const selectCompanyModalOpen = createSelector(
    [selectModalState],
    (modal) => modal.companyModal.opened
);

export const selectCurrentEditModalOpen = createSelector(
    [selectModalState],
    (modal) => modal.editModal
);

export const selectNewContactModalState = createSelector(
    [selectModalState],
    (state) => state.newContact
);

export const selectAccountAddedModalState = createSelector(
    [selectModalState],
    (state) => state.accountsAdded
);

export const selectPostAddContactsModalState = createSelector(
    [selectModalState],
    (state) => state.postAddContacts
);

export const shareAccessModalState = createSelector(
    [selectModalState],
    (state) => state.shareContactAccessOpen
);

export const selectChatTags = createSelector([selectModalState], (modals) => modals.chatTags);
export const selectMeetingTags = createSelector([selectModalState], (modals) => modals.meetingTags);

export const selectFormTags = (state: RootState) => state.modals.formTags;
export const selectTwofaResult = (state: RootState) => state.modals.twofaResult;
export const selectDuplicatedFilesAction = (state: RootState) => state.modals.duplicatedFilesAction;

export const selectUserSettings = createSelector([selectModalState], (state) => state.userSettings);
export const selectUserSettingsOpen = createSelector(
    [selectUserSettings],
    (modalState) => modalState.open
);

export const selectAdminSettings = createSelector([selectModalState], (state) => state.adminPanel);
export const selectAdminSettingsOpen = createSelector(
    [selectAdminSettings],
    (modalState) => modalState.open
);
export const selectAdminSettingsInitialTab = createSelector(
    [selectAdminSettings],
    (modalState) => modalState.initialTab
);

export default modalsSlice.reducer;

interface ModalPromptOptions<T> {
    clearResult: () => void;
    showModal: () => void;
    extractResultFromState: (state: RootState) => { exists: false } | { exists: true; result: T };
}

export function modalPrompt<T>(store: typeof appStore, options: ModalPromptOptions<T>) {
    options.clearResult();
    const deferred = new Deferred<T>();
    const unsubscribe = store.subscribe(() => {
        const state = store.getState();
        const extracted = options.extractResultFromState(state);
        if (!extracted.exists) {
            return;
        }
        const result = extracted.result;
        options.clearResult();
        deferred.resolve(result);
    });
    deferred.promise.finally(() => {
        unsubscribe();
    });
    options.showModal();
    return deferred.promise;
}
