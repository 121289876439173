import * as privmx from 'privfs-client';
import * as PmxApi from 'privmx-server-api';
import { BaseApi } from './BaseApi';

export class DraftApi extends BaseApi implements PmxApi.api.draft.IDraftApi {
    getDrafts(): Promise<{ drafts: PmxApi.api.draft.Draft[] }> {
        return this.request('getDrafts', {});
    }

    getDraft(model: PmxApi.api.draft.GetDraftModel): Promise<{ draft: PmxApi.api.draft.Draft }> {
        return this.request('getDraft', model);
    }

    getThreadMessageDraft(
        model: PmxApi.api.draft.GetThreadMessageDraftModel
    ): Promise<{ draft: PmxApi.api.draft.Draft | null }> {
        return this.request('getThreadMessageDraft', model);
    }

    createDraft(
        model: PmxApi.api.draft.CreateDraftModel
    ): Promise<{ draft: PmxApi.api.draft.Draft }> {
        return this.request('createDraft', model);
    }

    updateDraft(
        model: PmxApi.api.draft.UpdateDraftModel
    ): Promise<{ draft: PmxApi.api.draft.Draft }> {
        return this.request('updateDraft', model);
    }

    deleteDraft(model: PmxApi.api.draft.DeleteDraftModel): Promise<PmxApi.api.core.OK> {
        return this.request('deleteDraft', model);
    }

    getDraftAttachmentData(
        model: PmxApi.api.draft.GetDraftAttachmentDataModel
    ): Promise<{ data: privmx.Buffer.Buffer }> {
        return this.request('getDraftAttachmentData', model);
    }

    getDraftAttachment(
        model: PmxApi.api.draft.GetDraftAttachmentModel
    ): Promise<{ attachment: PmxApi.api.draft.DraftAttachmentEx; draft: PmxApi.api.draft.Draft }> {
        return this.request('getDraftAttachment', model);
    }

    getDraftAttachments(model: PmxApi.api.draft.GetDraftAttachmentsModel): Promise<{
        attachments: PmxApi.api.draft.DraftAttachmentEx[];
        draft: PmxApi.api.draft.Draft;
    }> {
        return this.request('getDraftAttachments', model);
    }
}
