import { api } from '../../api/Api';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mantine/core';
import { useCallback, useEffect, useMemo } from 'react';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import StateView from '../../atoms/State';
import { Meeting, MeetingId, ThreadLinkData, Username } from '../../types/Types';
import { useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { MeetingLobbyPage } from '../MeetingPage/MeetingLobbyView';

export function MeetingRoomPage() {
    const routeParams = useParams();
    const meetingData = useMemo(
        () => api.parseThreadLinkStringData(routeParams.meetingData ?? '')!,
        [routeParams]
    );
    const { state, revalidate } = useDataSubscribion(
        useCallback(
            (x) => x.subscribeForMeeting(meetingData.threadId as MeetingId),
            [meetingData.threadId]
        )
    );
    return (
        <StateView state={state} retry={revalidate}>
            {(data) => (
                <MeetingRoomPageView
                    meetingDataStr={routeParams.meetingData ?? ''}
                    meetingData={meetingData}
                    meeting={data.meeting}
                />
            )}
        </StateView>
    );
}

interface MeetingRoomPageViewProps {
    meetingDataStr: string;
    meetingData: ThreadLinkData;
    meeting: Meeting;
}

export function MeetingRoomPageView(props: MeetingRoomPageViewProps) {
    const navigate = useNavigate();
    const currentUser = useAppSelector(selectCurrentUser);
    const isAnonymous = currentUser.pub !== null;
    const userId = currentUser.pub !== null ? currentUser.pub : currentUser.username;

    useEffect(() => {
        let hasAccess = false;
        if (userId) {
            if (isAnonymous) {
                if (props.meeting.meetingState.anonymousUsers[userId]) {
                    hasAccess = true;
                }
            } else {
                if (props.meeting.meetingState.regularUsers.includes(userId as Username)) {
                    hasAccess = true;
                }
            }
        }
        if (!hasAccess) {
            navigate(`/joinMeeting/${props.meetingDataStr}`);
        }
    }, [props.meeting.meetingState, props.meetingDataStr, isAnonymous, userId, navigate]);

    return (
        <Box w="100%">
            <MeetingLobbyPage id={props.meeting.id} />
        </Box>
    );
}

export default MeetingRoomPage;
