import { ScrollArea, Stack, CopyButton, Code, ThemeIcon, Text, Grid } from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import { MappedUser } from '.';
import UserAvatar from '../../../atoms/UserAvatar';
import HoverContainer from '../../../mantineAtoms/HoverContainer';
import { getHoverColor } from '../../../MantineConfigs';
import { FailedUser } from '../../ChatAddPage/ChatAddModal';
import { useTranslation } from 'react-i18next';
import { Contact } from '../../../types/Types';
import { useMemo } from 'react';
import { api } from '../../../api/Api';

export function AccountsAddedList(props: {
    createdUsers: Array<MappedUser>;
    privateContactsMadeVisible: Contact[];
    failedUsers: FailedUser[];
}) {
    const privateContactsMadeVisibleEx = useMemo(() => {
        const users = api.getUsersSync();
        return props.privateContactsMadeVisible
            .map((contact) => {
                const user = users.find((x) => x.username === contact.username);
                return {
                    contact,
                    justCreated: props.createdUsers.some(
                        ([username]) => username === contact.username
                    ),
                    hasLoggedIn: user ? user.lastLoggedIn !== -1 : false
                };
            })
            .filter((x) => !x.justCreated);
    }, [props.privateContactsMadeVisible, props.createdUsers]);
    const { t } = useTranslation();
    return (
        <ScrollArea.Autosize mah={220}>
            <Stack h={220}>
                {props.createdUsers.length > 0 && (
                    <Grid columns={12} ml={0} mr={0}>
                        <Grid.Col span={1} pb={0} />
                        <Grid.Col span={5} pb={0}>
                            <Text color="dimmed">{t('entityProps.login')}</Text>
                        </Grid.Col>
                        <Grid.Col span={4} pb={0}>
                            <Text color="dimmed">{t('entityProps.tmpPassword')}</Text>
                        </Grid.Col>
                        <Grid.Col span={2} pb={0} />
                    </Grid>
                )}
                {props.createdUsers.map(([username, data]) => (
                    <CopyButton value={data.password} key={username}>
                        {({ copied, copy }) => (
                            <HoverContainer
                                withBorder={false}
                                onClick={copy}
                                p="xs"
                                styles={(theme) => ({
                                    link: {
                                        '&:hover': {
                                            backgroundColor: getHoverColor(theme)
                                        }
                                    }
                                })}>
                                <Grid columns={12}>
                                    <Grid.Col span={1}>
                                        <UserAvatar username={username} />
                                    </Grid.Col>
                                    <Grid.Col span={5}>
                                        <Text sx={{ wordBreak: 'break-word' }}>{data.email}</Text>
                                    </Grid.Col>
                                    <Grid.Col span={4}>
                                        <Code color={copied ? 'green.0' : 'gray.0'}>
                                            {data.password}
                                        </Code>
                                    </Grid.Col>
                                    <Grid.Col span={2} sx={{ textAlign: 'right' }}>
                                        {data.password && (
                                            <ThemeIcon
                                                variant="outline"
                                                size={16}
                                                sx={{
                                                    border: 0
                                                }}>
                                                {copied ? <IconCheck /> : <IconCopy />}
                                            </ThemeIcon>
                                        )}
                                    </Grid.Col>
                                </Grid>
                            </HoverContainer>
                        )}
                    </CopyButton>
                ))}
                {privateContactsMadeVisibleEx.map(({ contact, hasLoggedIn }) => (
                    <Grid columns={2} key={contact.email}>
                        <Grid.Col span={1}>
                            <Text size="sm">{contact.email}</Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                            <Text size="sm">
                                {hasLoggedIn &&
                                    t('temporaryPasswordsInfo.privateContactMessage.hasLoggedIn')}
                                {!hasLoggedIn &&
                                    t(
                                        'temporaryPasswordsInfo.privateContactMessage.hasNotLoggedIn'
                                    )}
                            </Text>
                        </Grid.Col>
                    </Grid>
                ))}
            </Stack>
        </ScrollArea.Autosize>
    );
}
