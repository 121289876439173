import { Text, Image, Center, Stack, Title } from '@mantine/core';
import { CustomizationProvider } from '../../api/customization/CustomizationProvider';
import NO_TOPICS from '../../assets/empty_states/no_topics.svg';
import NO_MATCHES from '../../assets/empty_states/no_matches.svg';
import NO_MEETINGS from '../../assets/empty_states/no_meetings.svg';
import NO_FORMS from '../../assets/empty_states/no_forms.svg';
import NO_USERS from '../../assets/empty_states/no_users.svg';
import NO_FORM_RESPONSES from '../../assets/empty_states/no_form_responses.svg';
import NO_BOOKMARKS from '../../assets/empty_states/no_bookmarks.svg';

import GREY_NO_TOPICS from '../../assets/empty_states/grey/no_topics.svg';
import GREY_NO_MATCHES from '../../assets/empty_states/grey/no_matches.svg';
import GREY_NO_MEETINGS from '../../assets/empty_states/grey/no_meetings.svg';
import GREY_NO_FORMS from '../../assets/empty_states/grey/no_forms.svg';
import GREY_NO_USERS from '../../assets/empty_states/grey/no_users.svg';
import GREY_NO_FORM_RESPONSES from '../../assets/empty_states/grey/no_form_responses.svg';
import GREY_NO_BOOKMARKS from '../../assets/empty_states/grey/no_bookmarks.svg';

const imageMap = {
    NO_TOPICS,
    NO_MATCHES,
    NO_MEETINGS,
    NO_FORMS,
    NO_USERS,
    NO_FORM_RESPONSES,
    NO_BOOKMARKS
} as const;

const greyImageMap = {
    NO_TOPICS: GREY_NO_TOPICS,
    NO_MATCHES: GREY_NO_MATCHES,
    NO_MEETINGS: GREY_NO_MEETINGS,
    NO_FORMS: GREY_NO_FORMS,
    NO_USERS: GREY_NO_USERS,
    NO_FORM_RESPONSES: GREY_NO_FORM_RESPONSES,
    NO_BOOKMARKS: GREY_NO_BOOKMARKS
} as const;

function getImageMap() {
    return CustomizationProvider.get().hasCustomColors() ? greyImageMap : imageMap;
}

export function EmptyState({
    title,
    subTitle,
    image,
    button
}: {
    title: string;
    subTitle?: string;
    image: keyof typeof imageMap;
    button?: React.ReactNode;
}) {
    return (
        <Center h="100%">
            <Stack mb="xl" spacing={'sm'} align="center">
                <Image width={250} src={getImageMap()[image]} />
                <Title fw="normal" align="center">
                    {title}
                </Title>
                <Stack align="center" spacing={'lg'}>
                    {subTitle && (
                        <Text align="center" color="dimmed">
                            {subTitle}
                        </Text>
                    )}
                    {button}
                </Stack>
            </Stack>
        </Center>
    );
}
