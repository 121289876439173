import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';

export default function useToast() {
    const emitToast = useCallback(
        (msg: string, type: 'success' | 'error', description?: React.ReactNode) => {
            notifications.show({
                title: msg,
                message: description,
                color: type === 'error' ? 'red' : undefined
            });
        },
        []
    );

    return emitToast;
}
