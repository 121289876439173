import { FileInput, rem } from '@mantine/core';
import { IconUpload } from '@tabler/icons-react';
import * as FormsTypes from '../FormsTypes';
import { isValueAFilesList } from '../utils/FormsUtils';
export default function FileUploadInput({
    options
}: {
    options: FormsTypes.RenderFormElementOptions;
}) {
    const { inputRef, model, updateDirty, updateSelectedFiles } = options;
    return (
        <FileInput
            multiple
            id={model.id}
            label={model.label}
            icon={<IconUpload size={rem(14)} />}
            onChange={(files) => {
                inputRef.current.customValue = files;
                inputRef.current.isDirty = files.length > 0;
                updateDirty();
                if (updateSelectedFiles && typeof updateSelectedFiles === 'function') {
                    updateSelectedFiles(files);
                }
            }}
            ref={inputRef}
            defaultValue={isValueAFilesList(model.value) ? model.value : undefined}
        />
    );
}
