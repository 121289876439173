import { api } from '../api/Api';
import { UserSelectOptionType } from '../components/UsersSelector';
import {
    UserOrContact,
    User,
    Contact,
    UsernameOrContactId,
    Email,
    ContactId,
    ContactName,
    ContactAddress,
    MobilePhone,
    Phone,
    CompanyId,
    ContactNote,
    Avatar,
    Language,
    Username,
    Timestamp
} from '../types/Types';

interface UsersAndContactList {
    users: User[];
    contacts: Contact[];
}

export class UserUtils {
    static userUnionToUsernameOrContactId(user: User | Contact): UsernameOrContactId {
        if (user.username) {
            return { type: 'user', username: user.username };
        } else {
            return { type: 'contact', contactId: user.id as ContactId };
        }
    }
    static userUnionToUserOrContact(user: User | Contact): UserOrContact {
        if ('tags' in user) {
            return { type: 'contact', contact: user };
        } else {
            return { type: 'user', user: user };
        }
    }
    static usersListFromOption(users: UserOrContact[]): UsersAndContactList {
        const usersList: UsersAndContactList = { users: [], contacts: [] };
        users.forEach((x) => {
            if (x.type === 'user') {
                usersList.users.push(x.user);
            } else {
                usersList.contacts.push(x.contact);
            }
        });

        return usersList;
    }
    static userOrContactToSelectOption(
        users: UserOrContact[],
        nameMap: Map<Username, Contact['name']>
    ): UserSelectOptionType[] {
        const options: UserSelectOptionType[] = [];

        users.forEach((user) => {
            if (user.type === 'user') {
                const name = nameMap.get(user.user.username);
                let label: string = user.user.name;
                if ((name as string) === user.user.email) {
                    label = user.user.username;
                }
                const contact = api.getContactByUsernameSync(user.user.username);
                const isPrivateContact = contact?.isPrivate || false;

                const userSelectOption: UserSelectOptionType = {
                    data: user.user.username,
                    label: label,
                    type: 'staff',
                    value: user.user.username,
                    disabled: false,
                    email: user.user.email,
                    isPrivateContact: isPrivateContact
                };
                options.push(userSelectOption);
            } else {
                if (user.contact?.username) {
                    const userSelectOption: UserSelectOptionType = {
                        data: user.contact.id,
                        label: user.contact.name || user.contact.username,
                        type: 'user',
                        value: user.contact.username,
                        disabled: false,
                        email: user.contact.email,
                        isPrivateContact: user.contact.isPrivate
                    };
                    options.push(userSelectOption);
                } else if (user.contact) {
                    const contactSelectOption: UserSelectOptionType = {
                        data: user.contact.id,
                        label: user.contact.email,
                        type: 'contact',
                        value: user.contact.id,
                        disabled: false,
                        email: user.contact.email,
                        isPrivateContact: user.contact.isPrivate
                    };
                    options.push(contactSelectOption);
                }
            }
        });
        return options;
    }

    static usernameOrIdToOption(
        usernamesOrIds: UsernameOrContactId[],
        allUsers: UserOrContact[],
        nameMap: Map<Username, Contact['name']>
    ) {
        const users: UserOrContact[] = [];

        usernamesOrIds.forEach((u) => {
            if (u.type === 'contact') {
                const user = allUsers.find(
                    (au) => au.type === 'contact' && au.contact.id === u.contactId
                );
                if (user) users.push(user);
            } else {
                const user = allUsers.find((au) => {
                    if (au.type === 'user') {
                        return au.user.username === u.username;
                    } else {
                        return au.contact.username === u.username;
                    }
                });
                if (user) users.push(user);
            }
        });

        const options = this.userOrContactToSelectOption(users, nameMap);

        return options;
    }

    static geUserSelectOptionFromUsernameOrId(
        usernamesOrIds: UsernameOrContactId[],
        options: UserSelectOptionType[]
    ): UserSelectOptionType[] {
        return options.filter((option) =>
            usernamesOrIds.find((usernameOrId) => {
                if (usernameOrId.type === 'contact') {
                    return (
                        option.value === usernameOrId.contactId ||
                        option.data === usernameOrId.contactId
                    );
                } else {
                    return option.value === usernameOrId.username;
                }
            })
        );
    }

    static getNewContactModel(email: Email): Contact {
        const currentUserUsername = api.getCurrentUserUsername();
        return {
            id: '' as ContactId,
            userType: 'email',
            name: '' as ContactName,
            email: email.trim() as Email,
            address: '' as ContactAddress,
            mobilePhone: '' as MobilePhone,
            phone: '' as Phone,
            companyId: '' as CompanyId,
            note: '' as ContactNote,
            avatar: '' as Avatar,
            language: 'en' as Language,
            tags: [],
            pinned: false,
            archived: false,
            createdBy: '' as Username,
            createdDate: 0 as Timestamp,
            lastModifiedBy: '' as Username,
            lastModifiedDate: 0 as Timestamp,
            isPrivate: currentUserUsername !== undefined,
            privateContactVisibleForUsers:
                currentUserUsername !== undefined ? [currentUserUsername] : []
        };
    }

    static canCreateContact(
        inputValue: string,
        contacts: Contact[],
        accountEmails: Email[] = []
    ): boolean {
        if (!/^[a-z0-9_.+-]+@[a-z0-9_.-]+$/.test(inputValue)) {
            return false;
        }
        if (contacts.find((x) => x.email === inputValue)) {
            return false;
        }
        if (accountEmails.includes(inputValue as Email)) {
            return false;
        }
        return true;
    }
}
