import { Text, Box, Flex, HoverCard, ActionIcon, Tooltip, LoadingOverlay } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { chatApi, fileApi } from '../../api/Api';
import { EncKey } from '../../api/privmx/KeyProvider';
import StateView from '../../atoms/State';
import useLoader from '../../hooks/useLoader';
import useModal from '../../hooks/useModal';
import { useAppSelector } from '../../store';
import { AttachmentPreview as AttachmentPreviewData } from '../../store/ModalsSlice';
import { selectAutoMarkAsRead } from '../../store/UserPreferenceSlice';
import { AttachmentEx } from '../../types/Types';
import { Utils } from '../../utils/Utils';
import { IconDownload, IconInfoCircle, IconShare } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { createObjectURL } from '../../utils/ReactUtils';
import { displayformatedTime } from '../../utils/RelativeTimeFormat';
import { Limits } from '../../api/Limits';

interface PreviewData {
    type: 'image' | 'video' | 'audio' | 'text' | 'unknown';
    dataUri: string;
    text: string;
}

export interface AttachmentPreviewProps {
    attachmentPreview: AttachmentPreviewData;
    fileEncKey?: EncKey;
    filePassword?: string;
    hideTags?: boolean;
    hideShareButton?: boolean;
    handleClose: () => void;
}

export function AttachmentPreview(props: AttachmentPreviewProps) {
    const { t } = useTranslation();

    const modalService = useModal();
    const attachment = props.attachmentPreview.attachment;
    const { state, revalidate } = useLoader(
        useCallback(async () => {
            let dataType: PreviewData['type'] = 'unknown';
            if (
                attachment.contentType.startsWith('image/') &&
                attachment.size <= Limits.MAX_PREVIEWABLE_FILE_SIZE_B
            ) {
                dataType = 'image';
            } else if (attachment.contentType.startsWith('audio/') && attachment.size < 12000000) {
                dataType = 'audio';
            } else if (attachment.contentType.startsWith('video/') && attachment.size < 25000000) {
                dataType = 'video';
            } else if (attachment.contentType.startsWith('text/') && attachment.size < 512000) {
                dataType = 'text';
            }
            if (dataType === 'unknown') {
                const res: PreviewData = {
                    type: dataType,
                    dataUri: '',
                    text: ''
                };
                return res;
            } else {
                const attachmentData = await fileApi.getAttachmentFromSource(
                    attachment.id,
                    attachment.sourceType,
                    false,
                    props.fileEncKey,
                    props.filePassword
                );
                const buff = attachmentData.content;
                const res: PreviewData = {
                    type: dataType,
                    dataUri: createObjectURL(buff, attachmentData.contentType),
                    text: dataType === 'text' ? buff.toString('utf-8') : ''
                };
                return res;
            }
        }, [
            attachment.id,
            attachment.size,
            attachment.contentType,
            attachment.sourceType,
            props.fileEncKey,
            props.filePassword
        ]),
        true,
        useCallback((oldState: PreviewData) => URL.revokeObjectURL(oldState.dataUri), [])
    );

    const autoMarkAsRead = useAppSelector(selectAutoMarkAsRead);
    useEffect(() => {
        if (chatApi.isAnonymousMeetingClient()) {
            return;
        }
        if (!autoMarkAsRead) {
            return;
        }
        chatApi.getUnreadService()?.markAttachmentAsRead(attachment.id);
    }, [attachment.id, autoMarkAsRead]);

    const [downloading, setDownloading] = useState(false);
    const onDownloadClick = useCallback(async () => {
        if (downloading) {
            return;
        }
        try {
            if (state.type === 'done' && state.data.dataUri) {
                const a = document.createElement('a');
                a.download = attachment.name;
                a.href = state.data.dataUri;
                a.click();
                return;
            }
            await fileApi.downloadAttachment({
                fileName: attachment.name,
                attachmentId: attachment.id,
                attachmentSourceType: attachment.sourceType,
                thumb: false
            });
        } catch (e) {
            console.log('Download error', e);
        } finally {
            setDownloading(false);
        }
    }, [attachment.id, attachment.name, attachment.sourceType, state, downloading]);

    const onShareClick = useCallback(() => {
        modalService.openAddSharedFileModal({
            sharedFileSetFileModel: {
                type: 'copyAttachment',
                attachmentToCopy: attachment as AttachmentEx
            }
        });
    }, [attachment, modalService]);

    return (
        <Flex mx="auto" w="100%" pos="relative" align="center" justify="center">
            <StateView
                state={state}
                retry={revalidate}
                loading={() => (
                    <Box w={'100%'} h="100%" miw={300} mih={300} pos="relative">
                        <LoadingOverlay visible />
                    </Box>
                )}>
                {(previewData) => (
                    <>
                        <Box pos="absolute" top={16} right={16}>
                            <HoverCard>
                                <HoverCard.Target>
                                    <ActionIcon variant="light">
                                        <IconInfoCircle />
                                    </ActionIcon>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                    <Box maw={150}>
                                        <Text maw={150} fw={500}>
                                            {attachment.name}
                                        </Text>
                                        <Text fz="xs">
                                            {'createdDate' in attachment &&
                                                displayformatedTime(attachment.createdDate)}
                                            {t('byPerson', { name: attachment.author })}
                                        </Text>
                                        <Text fw={400} size="xs">
                                            {t('entityProps.size')}:{' '}
                                            {Utils.bytesSize(attachment.size)}
                                        </Text>
                                        {'modificationDates' in attachment &&
                                        attachment.modificationDates.at(-1) !==
                                            attachment.createdDate ? (
                                            <Text w={400} size="xs">
                                                {t('entityProps.lastModified')}:{' '}
                                                {attachment.contributors.at(-1)}{' '}
                                                {displayformatedTime(
                                                    attachment.modificationDates.at(-1) || 0
                                                )}
                                            </Text>
                                        ) : (
                                            <></>
                                        )}
                                    </Box>
                                </HoverCard.Dropdown>
                            </HoverCard>
                        </Box>
                        <Flex gap="md" pos="absolute" left={16} top={16}>
                            <Tooltip label={t('action.downloadFile')}>
                                <ActionIcon
                                    variant="light"
                                    onClick={() => {
                                        onDownloadClick();
                                    }}>
                                    <IconDownload />
                                </ActionIcon>
                            </Tooltip>
                            <Tooltip label={t('action.shareFile')}>
                                <ActionIcon variant="light" onClick={() => onShareClick()}>
                                    <IconShare />
                                </ActionIcon>
                            </Tooltip>
                        </Flex>
                        {previewData.type === 'image' && (
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    overflow: 'hidden'
                                }}>
                                <Box
                                    src={previewData.dataUri}
                                    component="img"
                                    sx={{
                                        margin: '0 auto',
                                        fit: 'contain',
                                        height: 'auto',
                                        width: 'auto',
                                        maxHeight: '100%',
                                        maxWidth: '100%'
                                    }}></Box>
                            </Box>
                        )}
                        {previewData.type === 'video' && (
                            <video src={previewData.dataUri} controls />
                        )}
                        {previewData.type === 'audio' && (
                            <audio src={previewData.dataUri} controls />
                        )}
                        {previewData.type === 'text' && (
                            <Text color="dimmed" mx="auto" w={800} align="center">
                                {t('errorMessage.cantDisplayPreview')}
                            </Text>
                        )}
                        {previewData.type === 'unknown' && (
                            <Text color="dimmed" mx="auto" w={800} align="center">
                                {t('errorMessage.cantDisplayPreview')}
                            </Text>
                        )}
                    </>
                )}
            </StateView>
        </Flex>
    );
}

export default AttachmentPreview;
