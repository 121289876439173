import { createContext } from 'react';

import { FilterContextProvider } from '../../mantineAtoms/FilterSelect';

import { ContactsList } from './ContactsList';
import { ContactsScreenViewProps } from './types';

export default function ContactsScreenView(props: ContactsScreenViewProps) {
    return (
        <FilterContextProvider>
            <ContactsList {...props} />
        </FilterContextProvider>
    );
}

export const isDirtyContext = createContext({ isDirty: false, set: (isDirty: boolean) => {} });
