import { Template } from "../../../core/utils";

const html = require("./localParticipantAudio.html").default;

export interface LocalParticipantAudioTemplateModel {
}

export class LocalParticipantAudioTemplate extends Template<HTMLAudioElement> {
    
    constructor(i18n: (key: string, ...args: string[]) => string) {
        super(html, i18n);
    }
    
    render(model: LocalParticipantAudioTemplateModel): HTMLAudioElement {
        const $rendered = super.render(model);
        return $rendered;
    }
    
}
