import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '../../api/Api';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import {
    joinMeetingLobby,
    selectLobbyMetadata,
    selectVideoRoomState,
    VideoRoomState
} from '../../store/VideoSlice';
import {
    AttachmentEx,
    Meeting,
    MeetingId,
    Message,
    MessageId,
    ThreadMessageDraftWithAttachments,
    Username
} from '../../types/Types';
import { FullMessagesView } from '../MessageView';
import * as PmxApi from 'privmx-server-api';
import { FeatureDetection } from '../../utils/FeatureDetection';
import { useModals } from '../../hooks/useModal';
import { VideoConferenceModal } from '../VideoConference/VideoConferenceModal';
import StateView from '../../atoms/State';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    Center,
    CopyButton,
    Flex,
    Group,
    MediaQuery,
    Menu,
    Modal,
    Paper,
    SegmentedControl,
    Skeleton,
    Stack,
    Tabs,
    Text,
    Title,
    Tooltip,
    createStyles,
    rem
} from '@mantine/core';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { Link, useParams } from 'react-router-dom';
import {
    IconArrowLeft,
    IconCheck,
    IconChevronsLeft,
    IconChevronsRight,
    IconCopy,
    IconDotsVertical,
    IconEdit
} from '@tabler/icons-react';
import { UsersList } from '../../screens/ChatScreen/components/UserList';
import { useDisclosure } from '@mantine/hooks';
import MeetingEditDialog from './MeetingEditDialog';
import VideoConference from '../VideoConference';
import { MeetingLobbyManagement } from './MeetingLobbyManagment';
import {
    displayFormatedTimeOfDay,
    displayRelativeTime,
    displayformatedTime
} from '../../utils/RelativeTimeFormat';
import { useTranslation } from 'react-i18next';
import { Deferred } from '../../utils/Deferred';
import ConfirmModal from '../../atoms/ConfirmModal';
import { TagList } from '../../mantineAtoms/TagList';

const useMeetingViewStyles = createStyles((theme) => {
    return {
        tab: {
            flexGrow: 1,
            padding: 8,
            backgroundColor: 'transparent',
            border: '2px solid rgba(125,125,125,0.0)',
            '&[data-active="true"]': {
                borderRadius: theme.radius.sm,
                border: '2px solid rgba(125,125,125,0.6)'
            }
        },
        flexGrow: {
            flexGrow: 1
        },
        video: {
            display: 'none',
            [`@media (min-width: ${theme.breakpoints.md})`]: {
                display: 'block'
            }
        },
        sidebarColor: {
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
        },
        sidebar: {
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'end',
            borderLeft: `1px solid ${
                theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4]
            }`,

            '&[data-open=true]': {
                width: '30%',
                minWidth: 500
            },
            '&[data-open=false]': {
                width: 0,
                minWidth: 0
            },
            [`@media (max-width: ${theme.breakpoints.md})`]: {
                width: '100%',
                minWidth: 500,
                '&[data-open=true]': {
                    width: '100%',
                    minWidth: 500
                }
            }
        }
    };
});

function MeetingLobbyViewLoader() {
    const { t } = useTranslation();
    const { classes } = useMeetingViewStyles();

    return (
        <Flex direction="column" h="100svh" gap="md" justify="space-between">
            <Stack justify="space-between" m="md" mb={0} spacing="xs">
                <Group position="apart">
                    <Group align="center">
                        <Box w={20}>
                            <Anchor component={Link} to="/meetings">
                                <ActionIcon size={24}>
                                    <IconArrowLeft />
                                </ActionIcon>
                            </Anchor>
                        </Box>
                        <Group align="center">
                            <Skeleton height={28} width={200} />
                            <MediaQuery smallerThan={'sm'} styles={{ display: 'none' }}>
                                <Group spacing={4}>
                                    {new Array(3).fill('').map((_, i) => (
                                        <Skeleton circle height={18} key={i} />
                                    ))}
                                </Group>
                            </MediaQuery>
                            <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                                <Group spacing={4}>
                                    {new Array(3).fill('').map((_, i) => (
                                        <Skeleton visible height={16} w={80} key={i}></Skeleton>
                                    ))}
                                </Group>
                            </MediaQuery>
                        </Group>
                    </Group>
                    <MediaQuery largerThan={'lg'} styles={{ display: 'flex' }}>
                        <Group display={'none'}>
                            <Button variant="light" disabled leftIcon={<IconCopy size={rem(14)} />}>
                                Share link
                            </Button>
                            <Button leftIcon={<IconEdit size={rem(14)} />} variant="light" disabled>
                                {t('action.edit')}
                            </Button>
                        </Group>
                    </MediaQuery>
                    <MediaQuery smallerThan={'lg'} styles={{ display: 'block' }}>
                        <ActionIcon display={'none'}>
                            <IconDotsVertical />
                        </ActionIcon>
                    </MediaQuery>
                </Group>
            </Stack>

            <Tabs display="flex" defaultValue="messages" variant="outline">
                <Flex className={classes.flexGrow}>
                    <MediaQuery smallerThan={'md'} styles={{ display: 'none' }}>
                        <Box className={classes.flexGrow} pos={'relative'}>
                            <Flex gap="sm" align="center" justify={'flex-end'} pr="md">
                                <Tooltip label="Close sidebar" openDelay={300}>
                                    <ActionIcon>
                                        <IconChevronsRight />
                                    </ActionIcon>
                                </Tooltip>
                            </Flex>
                            <Box h={`calc(100% - 40px)`} pos={'relative'}>
                                <Center h="100%">
                                    <Stack align="center">
                                        <Skeleton width={500} height={42}></Skeleton>
                                        <Skeleton w={180}>
                                            <Button disabled>{t('action.joinMeeting')}</Button>
                                        </Skeleton>
                                    </Stack>
                                </Center>
                            </Box>
                        </Box>
                    </MediaQuery>

                    <Paper
                        withBorder
                        w={{ base: '100%', md: '30%' }}
                        miw={500}
                        h={`calc(100svh - 68px)`}
                        className={classes.sidebar}>
                        <SegmentedControl
                            data={[
                                { label: 'Chat', value: 'messages' },
                                { label: 'Lobby', value: 'settings' }
                            ]}
                            value="messages"
                            disabled
                        />
                        <Tabs.Panel h="100%" value="messages">
                            <Flex direction="column" justify="space-between" h="100%">
                                <Flex direction={'column'} className={classes.flexGrow} m="md">
                                    {new Array(8).fill('').map((_, i) => (
                                        <Stack key={i} spacing="xs" mb="md">
                                            <Group spacing="xs">
                                                <Skeleton circle height={30} />
                                                <Skeleton height={12} width={'30%'} />
                                            </Group>
                                            <Skeleton ml={42} width="80%" h={32}></Skeleton>
                                        </Stack>
                                    ))}
                                </Flex>
                                <Skeleton m="sm" height={35} width="calc(100% - 32px)"></Skeleton>
                            </Flex>
                        </Tabs.Panel>
                    </Paper>
                </Flex>
            </Tabs>
        </Flex>
    );
}

export function MeetingLobbyPage(props: { id?: MeetingId }) {
    const { meetingId } = useParams();

    const id = (meetingId || props.id || '') as MeetingId;

    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForMeetingWithMessagesAndAttachments(id, true), [id])
    );

    return (
        <StateView state={state} retry={revalidate} loading={MeetingLobbyViewLoader}>
            {(data) => (
                <MeetingLobbyView
                    revalidate={revalidate}
                    meeting={data.meeting}
                    meetingMessageDraft={data.meetingMessageDraft}
                    messages={data.messages}
                    hasAccess={data.hasAccessToThreadContent}
                    attachments={data.attachments}
                />
            )}
        </StateView>
    );
}

const supportsVideoConferences = FeatureDetection.supportsVideoConferences();
type TabId = 'messages' | 'attachments' | 'users' | 'closed';

export function MeetingLobbyView(props: {
    meeting: Meeting;
    meetingMessageDraft?: ThreadMessageDraftWithAttachments;
    messages: Message[];
    hasAccess: boolean;
    attachments: AttachmentEx[];
    highlightedMessageIds?: MessageId[];
    scrollToMessageId?: MessageId;
    revalidate: VoidFunction;
}) {
    const { t } = useTranslation();
    const { classes, cx } = useMeetingViewStyles();

    const currentUser = useAppSelector(selectCurrentUser);
    const [activeTabId, setActiveTabId] = useState<TabId>('messages');

    const videoRoomState = useAppSelector(selectVideoRoomState(props.meeting.id));
    const currentVideoModal = useModals().modals.find((x) => x?.component === VideoConferenceModal);
    const isUserInAnyVideo = !!currentVideoModal;

    const lobbyMetadata = useAppSelector(selectLobbyMetadata);
    const dispatch = useAppDispatch();

    const openVideo = useCallback(
        async (isNewVideo: boolean) => {
            const thread = await api.getThread(
                props.meeting.id as string as PmxApi.api.thread.ThreadId
            );
            if (!thread) {
                return;
            }
            if (currentVideoModal) {
                currentVideoModal.onClose();
            }

            dispatch(
                joinMeetingLobby({
                    meetingId: props.meeting.id,
                    meetingTitle: props.meeting.title,
                    thread: thread,
                    creating: isNewVideo
                })
            );
        },
        [currentVideoModal, props.meeting.id, props.meeting.title, dispatch]
    );

    const handleStartVideoButtonClick = useCallback(async () => {
        openVideo(true);
    }, [openVideo]);

    const handleJoinVideoButtonClick = useCallback(async () => {
        openVideo(false);
    }, [openVideo]);

    const handleLeaveVideoButtonClick = useCallback(async () => {
        if (currentVideoModal) {
            currentVideoModal.onClose();
        }
    }, [currentVideoModal]);

    const [editingMeeting, { open, close }] = useDisclosure();

    const videoRoomData = useMemo(() => {
        const videoRoomData = {
            videoRoomState: videoRoomState,
            isUserInAnyVideo: isUserInAnyVideo,
            onStartVideoButtonClick: handleStartVideoButtonClick,
            onLeaveVideoButtonClick: handleLeaveVideoButtonClick
        };
        if (videoRoomData.videoRoomState === 'nonexistent') {
            const msg = props.messages.find((msg) => {
                try {
                    if (msg.mimetype !== 'application/json') {
                        return false;
                    }
                    const data = JSON.parse(msg.text);
                    if (data.type === 'meetingStarted') {
                        return true;
                    }
                    return false;
                } catch (err) {
                    console.error(err);
                    return false;
                }
            });
            if (msg) {
                videoRoomData.videoRoomState = 'available';
            }
        }
        return videoRoomData;
    }, [
        videoRoomState,
        isUserInAnyVideo,
        props.messages,
        handleStartVideoButtonClick,
        handleLeaveVideoButtonClick
    ]);

    const canManageMeeting = currentUser.username
        ? props.meeting.managers.includes(currentUser.username)
        : false;

    const lobbyUsers = useMemo(
        () => props.meeting.meetingState?.lobbyUsers || [],
        [props.meeting.meetingState?.lobbyUsers]
    );

    const [, setForcedUpdateId] = useState(0);
    const forceUpdate = useCallback(() => setForcedUpdateId((x) => x + 1), []);

    useEffect(() => {
        const expiresAt = api.getNextThreadMeetingLobbyUserExpirationTime(lobbyUsers);
        if (expiresAt !== null) {
            const timeLeft = Date.now() - expiresAt;
            const timeoutId = window.setTimeout(() => {
                forceUpdate();
            }, timeLeft + 100);
            return () => {
                window.clearTimeout(timeoutId);
            };
        }
    }, [lobbyUsers, forceUpdate]);

    const buttonState: Record<
        VideoRoomState | 'disabled' | 'overdue' | 'cancelled' | 'ended',
        { button: React.ReactNode; info: string | React.ReactNode; subTitle?: React.ReactNode }
    > = {
        //user jest już w meetingu
        active: { button: <Button>{t('action.leave')}</Button>, info: t('action.leaveMeeting') },
        //meeting jest rozpoczęte
        available: {
            button: <Button onClick={handleJoinVideoButtonClick}>{t('action.joinMeeting')}</Button>,
            info: t('meeting.isOpen')
        },
        //w trakcie dołączania
        joining: {
            button: <Button loading>{t('progress.joining')}</Button>,
            info: t('meeting.hasStarted')
        },
        //jeszcze się nie rozpoczeło
        nonexistent: {
            button: canManageMeeting ? (
                <Button onClick={handleStartVideoButtonClick}>{t('action.startMeeting')}</Button>
            ) : (
                <Button disabled>{t('action.joinMeeting')}</Button>
            ),
            info: t('meeting.scheduledForWithDate', {
                date: displayRelativeTime(props.meeting.startDate)
            }),
            subTitle: canManageMeeting ? undefined : t('meeting.waitForHost')
        },
        disabled: {
            button: <Button disabled>{t('action.joinMeeting')}</Button>,
            info: (
                <Text align="center" size="md">
                    {t('meeting.noE2eeVideo.1')}
                    <br />
                    {t('meeting.noE2eeVideo.2')}
                </Text>
            )
        },
        overdue: {
            button: <Button disabled>{t('action.joinMeeting')}</Button>,
            info: t('meeting.endedOnWithDate', {
                date: displayformatedTime(props.meeting.startDate)
            })
        },
        cancelled: {
            button: <Button disabled>{t('action.joinMeeting')}</Button>,
            info: t('meeting.cancelled')
        },
        ended: {
            info: 'Meeting ended',
            button: <></>
        }
    };

    const isMeetingOverdue =
        props.meeting.startDate + props.meeting.duration < Date.now() &&
        videoRoomData.videoRoomState !== 'available';

    const videoState = useMemo(() => {
        if (props.meeting.isMeetingCancelled) {
            return 'cancelled';
        }
        if (!supportsVideoConferences) {
            return 'disabled';
        }
        if (isMeetingOverdue) {
            return 'overdue';
        }

        return videoRoomData.videoRoomState;
    }, [isMeetingOverdue, props.meeting.isMeetingCancelled, videoRoomData.videoRoomState]);

    const meetingEndTime = new Date(props.meeting.startDate + props.meeting.duration);

    const [isCancellingMeeting, setIsCancellingMeeting] = useState(false);
    const [cancelMeetingConfirmModalDeferred, setCancelMeetingConfirmModalDeferred] = useState<
        Deferred<boolean> | undefined
    >(undefined);
    const handleCancelMeetingConfirmModalNoClick = useCallback(() => {
        cancelMeetingConfirmModalDeferred?.resolve(false);
        setCancelMeetingConfirmModalDeferred(undefined);
    }, [cancelMeetingConfirmModalDeferred]);

    const handleCancelMeetingConfirmModalYesClick = useCallback(async () => {
        cancelMeetingConfirmModalDeferred?.resolve(true);
        setCancelMeetingConfirmModalDeferred(undefined);
        setIsCancellingMeeting(true);
        try {
            await api.updateMeeting(
                {
                    id: props.meeting.id,
                    managers: props.meeting.managers,
                    title: props.meeting.title,
                    users: props.meeting.users.map((x) => ({ type: 'user', username: x })),
                    tags: props.meeting.tags,
                    duration: props.meeting.duration,
                    startDate: props.meeting.startDate,
                    isMeetingCancelled: true
                },
                true
            );
        } finally {
            setIsCancellingMeeting(false);
        }
    }, [cancelMeetingConfirmModalDeferred, props.meeting]);
    const cancelMeeting = useCallback(() => {
        setCancelMeetingConfirmModalDeferred(new Deferred());
    }, []);

    const meetingLink = useMemo(
        () => api.generateThreadMeetingLink(props.meeting),
        [props.meeting]
    );

    const isOverdue = props.meeting.startDate + props.meeting.duration >= Date.now();

    return (
        <Flex
            direction="column"
            h="100svh"
            gap="md"
            justify="space-between"
            className={classes.sidebarColor}>
            <Stack justify="space-between" m="md" mb={0} spacing="xs">
                <Group position="apart">
                    <Group align="center" noWrap>
                        <Box w={20}>
                            <Anchor component={Link} to="/meetings">
                                <ActionIcon size={24}>
                                    <IconArrowLeft />
                                </ActionIcon>
                            </Anchor>
                        </Box>
                        <Group align="center" noWrap>
                            <Title truncate miw={0} order={2} size={20} weight={'bolder'}>
                                {props.meeting.title}, (
                                {displayformatedTime(props.meeting.startDate)} -{' '}
                                {displayFormatedTimeOfDay(meetingEndTime.getTime())})
                            </Title>
                            <MediaQuery smallerThan={'sm'} styles={{ display: 'none' }}>
                                <UsersList
                                    usernames={props.meeting.users.map((x) => ({
                                        type: 'user',
                                        username: x
                                    }))}
                                />
                            </MediaQuery>
                            {props.meeting.tags.length !== 0 && (
                                <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                                    <TagList tags={props.meeting.tags} />
                                </MediaQuery>
                            )}
                        </Group>
                    </Group>
                    <Group>
                        <MediaQuery largerThan={'lg'} styles={{ display: 'flex' }}>
                            <Group display={'none'}>
                                {canManageMeeting &&
                                    !props.meeting.isMeetingCancelled &&
                                    isOverdue && (
                                        <Button
                                            onClick={cancelMeeting}
                                            variant="light"
                                            disabled={
                                                cancelMeetingConfirmModalDeferred !== undefined ||
                                                isCancellingMeeting
                                            }>
                                            {t('meeting.cancelMeeting')}
                                        </Button>
                                    )}
                                {canManageMeeting && (
                                    <>
                                        {!props.meeting.isMeetingCancelled && isOverdue && (
                                            <CopyButton value={meetingLink}>
                                                {({ copied, copy }) => (
                                                    <Button
                                                        variant="light"
                                                        onClick={copy}
                                                        color={copied ? 'green' : undefined}
                                                        leftIcon={
                                                            copied ? (
                                                                <IconCheck size={rem(14)} />
                                                            ) : (
                                                                <IconCopy size={rem(14)} />
                                                            )
                                                        }>
                                                        {copied
                                                            ? 'Link to Meeting copied'
                                                            : 'Share link'}
                                                    </Button>
                                                )}
                                            </CopyButton>
                                        )}
                                        <Button
                                            leftIcon={<IconEdit size={rem(14)} />}
                                            variant="light"
                                            onClick={open}>
                                            {t('action.edit')}
                                        </Button>
                                    </>
                                )}
                            </Group>
                        </MediaQuery>
                        <MediaQuery smallerThan={'lg'} styles={{ display: 'block' }}>
                            <Menu position="bottom-end">
                                <Menu.Target>
                                    <ActionIcon display={'none'}>
                                        <IconDotsVertical />
                                    </ActionIcon>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    {canManageMeeting &&
                                        !props.meeting.isMeetingCancelled &&
                                        isOverdue && (
                                            <Menu.Item>{t('meeting.cancelMeeting')}</Menu.Item>
                                        )}
                                    {canManageMeeting && (
                                        <>
                                            {!props.meeting.isMeetingCancelled && isOverdue && (
                                                <CopyButton value={meetingLink}>
                                                    {({ copied, copy }) => (
                                                        <Menu.Item
                                                            onClick={copy}
                                                            color={copied ? 'green' : undefined}
                                                            icon={
                                                                copied ? (
                                                                    <IconCheck size={rem(14)} />
                                                                ) : (
                                                                    <IconCopy size={rem(14)} />
                                                                )
                                                            }>
                                                            {copied
                                                                ? 'Link to Meeting copied'
                                                                : 'Share link'}
                                                        </Menu.Item>
                                                    )}
                                                </CopyButton>
                                            )}
                                            <Menu.Item
                                                icon={<IconEdit size={rem(14)} />}
                                                onClick={open}>
                                                {t('action.edit')}
                                            </Menu.Item>
                                        </>
                                    )}
                                </Menu.Dropdown>
                            </Menu>
                        </MediaQuery>

                        {cancelMeetingConfirmModalDeferred && (
                            <ConfirmModal
                                opened={!!cancelMeetingConfirmModalDeferred}
                                onClose={handleCancelMeetingConfirmModalNoClick}
                                title={t('question.cancelMeeting')}>
                                <>
                                    <Button onClick={handleCancelMeetingConfirmModalYesClick}>
                                        {t('Yes')}
                                    </Button>
                                    <Button onClick={handleCancelMeetingConfirmModalNoClick}>
                                        {t('No')}
                                    </Button>
                                </>
                            </ConfirmModal>
                        )}

                        <Modal
                            opened={editingMeeting}
                            size="md"
                            onClose={close}
                            title={t('action.editMeeting')}
                            overlayProps={{ opacity: 0.2, blur: 3 }}>
                            <MeetingEditDialog id={props.meeting.id} onClose={close} />
                        </Modal>
                    </Group>
                </Group>
            </Stack>

            <Tabs
                display="flex"
                value={activeTabId}
                onTabChange={(value) => setActiveTabId(value as TabId)}
                defaultValue="messages"
                variant="pills">
                <Flex className={cx(classes.flexGrow, classes.sidebarColor)}>
                    <Box className={cx(classes.flexGrow, classes.video)} pos={'relative'}>
                        <Flex gap="sm" align="center" pr="md" justify={'flex-end'}>
                            {activeTabId === 'closed' ? (
                                <Tooltip label={'open sidebar'} openDelay={300}>
                                    <ActionIcon onClick={() => setActiveTabId('messages')}>
                                        <IconChevronsLeft />
                                    </ActionIcon>
                                </Tooltip>
                            ) : (
                                <Tooltip label={'close sidebar'} openDelay={300}>
                                    <ActionIcon onClick={() => setActiveTabId('closed')}>
                                        <IconChevronsRight />
                                    </ActionIcon>
                                </Tooltip>
                            )}
                        </Flex>
                        <Box h={`calc(100% - 40px)`} pos={'relative'}>
                            {lobbyMetadata !== null ? (
                                <VideoConference videoConference={lobbyMetadata} />
                            ) : (
                                <Center h="100%">
                                    <Stack align="center">
                                        <Box>
                                            <Title px="xl" size="h3">
                                                {buttonState[videoState].info}
                                            </Title>
                                            {buttonState[videoState].subTitle ? (
                                                <Text align="center" color="dimmed">
                                                    {buttonState[videoState].subTitle}
                                                </Text>
                                            ) : null}
                                        </Box>
                                        {buttonState[videoState].button}
                                    </Stack>
                                </Center>
                            )}
                        </Box>
                    </Box>
                    <Box
                        data-open={activeTabId === 'closed' ? 'false' : 'true'}
                        h={`calc(100svh - 68px)`}
                        className={classes.sidebar}>
                        {currentUser.role === 'staff' && (
                            <SegmentedControl
                                data={[
                                    { label: 'Chat', value: 'messages' },
                                    { label: 'Lobby', value: 'settings' }
                                ]}
                                value={activeTabId}
                                onChange={(val) => setActiveTabId(val as TabId)}
                            />
                        )}
                        <Tabs.Panel h="calc(100% - 42px)" value="messages">
                            <Flex direction={'column'} h="100%">
                                <FullMessagesView
                                    attachmentsViewMode="list"
                                    variant="compact"
                                    activeTabId={'messages'}
                                    chatName={props.meeting.title}
                                    meetingId={props.meeting.id as any}
                                    loadingMessages={!props.messages}
                                    messages={props.messages}
                                    hasAccess={props.hasAccess}
                                    attachments={props.attachments}
                                    meetingMessageDraft={props.meetingMessageDraft}
                                    highlightedMessageIds={props.highlightedMessageIds}
                                    scrollToMessageId={props.scrollToMessageId}
                                />
                            </Flex>
                        </Tabs.Panel>
                        {props.meeting.managers.includes(
                            currentUser.username ?? ('' as Username)
                        ) && (
                            <Tabs.Panel value="settings">
                                <MeetingLobbyManagement meetingId={props.meeting.id} />
                            </Tabs.Panel>
                        )}
                    </Box>
                </Flex>
            </Tabs>
        </Flex>
    );
}
