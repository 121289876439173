import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Utils } from '../../api/privmx/utils/Utils';
import StateView from '../../atoms/State';
import LoadingThreadsList from '../../components/Loading/LoadingSection';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { useSearchParamsEx, modifyState } from '../../hooks/useLoader';
import { useAppDispatch } from '../../store';
import { setFormTags } from '../../store/ModalsSlice';
import { FormId, FormModel2, Tag } from '../../types/Types';
import { filterForms } from '../../utils/router/loaders';
import FormsScreenView, { resolveActiveTab, TabId } from './FormsScreenView';

export function FormsScreen() {
    const { state, revalidate } = useDataSubscribion(useCallback((x) => x.subscribeForForms(), []));
    const routeParams = useParams();
    const { searchParams, updateSearchParams, navigateKeepingSearch } = useSearchParamsEx();
    const filter = useMemo(() => searchParams.get('filter') || '', [searchParams]);
    const activeTab = useMemo(
        () => resolveActiveTab(searchParams.get('tab')) ?? 'current',
        [searchParams]
    );
    const dispatch = useAppDispatch();
    const formsState = useMemo(
        () =>
            modifyState(state, (data) => {
                const allTags = Utils.unique(data.map((x) => x.tags as Tag[]).flat());
                dispatch(setFormTags(allTags));
                const forms = (() => {
                    if (activeTab === 'all') {
                        return data;
                    } else if (activeTab === 'archived') {
                        return data.filter((x) => x.archived);
                    } else if (activeTab === 'current') {
                        return data.filter((x) => !x.archived);
                    } else if (activeTab === 'pinned') {
                        return data.filter((x) => x.pinned);
                    }
                    return data;
                })();
                return filterForms(forms, filter);
            }),
        [state, filter, dispatch, activeTab]
    );
    const handleFormChoose = useCallback(
        (form: FormModel2) => navigateKeepingSearch(`./${form.id}`),
        [navigateKeepingSearch]
    );
    const handleFilterSubmit = useCallback(
        (filter: string) => updateSearchParams({ filter: filter }),
        [updateSearchParams]
    );
    const handleTabChange = useCallback(
        (tab: TabId) => updateSearchParams({ tab: tab }),
        [updateSearchParams]
    );

    return (
        <StateView state={formsState} retry={revalidate} loading={LoadingThreadsList}>
            {(forms) => {
                const activeFormId = routeParams.formId as FormId;
                return (
                    <FormsScreenView
                        forms={forms}
                        activeFormId={
                            activeFormId && forms.some((x) => x.id === activeFormId)
                                ? activeFormId
                                : undefined
                        }
                        onFormChoose={handleFormChoose}
                        filter={filter}
                        onFilterSubmit={handleFilterSubmit}
                        activeTab={activeTab}
                        onTabChange={handleTabChange}
                    />
                );
            }}
        </StateView>
    );
}
