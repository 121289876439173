import Modal from '../../atoms/Modal';
import StateView from '../../atoms/State';
import { BaseModalData } from '../../types/Modals';
import { ContactPageView } from './index';
import { ContactId } from '../../types/Types';
import { useCallback } from 'react';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export interface ContactModalData extends BaseModalData {
    id: ContactId;
}

export function ContactModal(props: {
    onClose: () => void;
    data: ContactModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForContactWithCompany(props.data.id), [props.data.id])
    );
    return (
        <Modal
            onFocus={props.onFocus}
            index={props.index}
            onClose={props.onClose}
            height={props.data.height ?? 800}
            width={props.data.width}
            styles={{ content: { padding: 0 } }}
            resizable
            title={
                props.data.title ??
                (state.type === 'done' && state.data.contact && state.data.contact.name
                    ? t('modal.contact.titleWithName', { name: state.data.contact.name })
                    : t('modal.contact.titleWithoutName'))
            }>
            <StateView state={state} retry={revalidate}>
                {(data) =>
                    data.contact ? (
                        <Box sx={{ flexGrow: 1 }}>
                            <ContactPageView
                                contact={data.contact}
                                company={data.company}
                                onRefresh={revalidate}
                                onGoTo={() => {}}
                                goToBtn={false}
                            />
                        </Box>
                    ) : (
                        <div>404 Not Found</div>
                    )
                }
            </StateView>
        </Modal>
    );
}
