import { Template } from "../../../core/utils";

const html = require("./localParticipantAudio.html").default;

export interface RemoteParticipantAudioTemplateModel {
    participantId: string;
}

export class RemoteParticipantAudioTemplate extends Template<HTMLAudioElement> {
    
    constructor(i18n: (key: string, ...args: string[]) => string) {
        super(html, i18n);
    }
    
    render(model: RemoteParticipantAudioTemplateModel): HTMLAudioElement {
        const $rendered = super.render(model);
        $rendered.setAttribute("data-participant-id", model.participantId);
        return $rendered;
    }
    
}
