import { modifyState, useSearchParamsEx } from '../../hooks/useLoader';
import CompaniesScreenView, { resolveActiveTab, TabId } from './CompaniesScreenView';
import { useCallback, useMemo } from 'react';
import StateView from '../../atoms/State';
import { filterCompanies } from '../../utils/router/loaders';
import { useParams } from 'react-router-dom';
import { CompanyId, CompanyWithContacts } from '../../types/Types';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { Paper, Box, Skeleton, Flex, Divider } from '@mantine/core';
import RouteListContainer from '../../atoms/RouteScreen/RouteListContainer';
import UserAvatar from '../../atoms/UserAvatar';
import ScreenScrollContainer from '../../mantineAtoms/ScreenScrollContainer';
import { CompanyScreenInitial } from './CompanyScreenInitial';

export function CompanyScreenLoader() {
    return (
        <RouteListContainer direction="row">
            <Paper
                sx={{
                    flexGrow: 1,
                    flexDirection: 'column',
                    display: 'flex',
                    borderTopRightRadius: 16,
                    borderBottomRightRadius: 16
                }}>
                <Box px="md" my="md">
                    <Skeleton height={36} />
                </Box>
                <ScreenScrollContainer px="md">
                    {new Array(20).fill('').map((_, i) => (
                        <Paper key={i} w="100%" p="sm">
                            <Flex align="center" justify="flex-start" gap="md" w="100%">
                                <UserAvatar size={24} showTooltip={false} loading />
                                <Skeleton display={'block'} height={21} />
                            </Flex>
                        </Paper>
                    ))}
                </ScreenScrollContainer>
            </Paper>
            <Divider orientation="vertical" h="95%" my="auto" opacity={0.4} />
            <Paper w="40%" miw={700} bg="transparent">
                <CompanyScreenInitial />
            </Paper>
        </RouteListContainer>
    );
}

export function CompaniesScreen() {
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForCompaniesWithContacts(), [])
    );
    const routeParams = useParams();
    const { searchParams, updateSearchParams, navigateKeepingSearch } = useSearchParamsEx();
    const filter = useMemo(() => searchParams.get('filter') || '', [searchParams]);
    const activeTab = useMemo(
        () => resolveActiveTab(searchParams.get('tab')) ?? 'current',
        [searchParams]
    );
    const companiesState = useMemo(
        () =>
            modifyState(state, (data) => {
                const companies = (() => {
                    if (activeTab === 'all') {
                        return data.companies;
                    } else if (activeTab === 'archived') {
                        return data.companies.filter((x) => x.archived);
                    } else if (activeTab === 'current') {
                        return data.companies.filter((x) => !x.archived);
                    } else if (activeTab === 'pinned') {
                        return data.companies.filter((x) => x.pinned);
                    }
                    return data.companies;
                })();
                return filterCompanies(companies, filter);
            }),
        [state, filter, activeTab]
    );
    const handleCompanyChoose = useCallback(
        (company: CompanyWithContacts) => navigateKeepingSearch(`./${company.id}`),
        [navigateKeepingSearch]
    );
    const handleFilterSubmit = useCallback(
        (filter: string) => updateSearchParams({ filter: filter }),
        [updateSearchParams]
    );
    const handleTabChange = useCallback(
        (tab: TabId) => updateSearchParams({ tab: tab }),
        [updateSearchParams]
    );

    return (
        <StateView state={companiesState} retry={revalidate} loading={CompanyScreenLoader}>
            {(companies) => {
                const activeCompanyId = routeParams.companyId as CompanyId;
                return (
                    <CompaniesScreenView
                        companies={companies}
                        activeCompanyId={
                            activeCompanyId && companies.some((x) => x.id === activeCompanyId)
                                ? activeCompanyId
                                : undefined
                        }
                        onCompanyChoose={handleCompanyChoose}
                        filter={filter}
                        onFilterSubmit={handleFilterSubmit}
                        activeTab={activeTab}
                        onTabChange={handleTabChange}
                    />
                );
            }}
        </StateView>
    );
}
