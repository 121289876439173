import { useAppDispatch, useAppSelector } from '../../../store';
import {
    selectPostAddContactsModalState,
    togglePostAddContactsModal
} from '../../../store/ModalsSlice';
import { useCallback } from 'react';
import * as postAddContactsModal from '../../PostAddContactsModal';

export function PostAddContactsModal() {
    const modalState = useAppSelector(selectPostAddContactsModalState);
    const dispatch = useAppDispatch();
    const closeModal = useCallback(
        function () {
            dispatch(togglePostAddContactsModal({ opened: false }));
        },
        [dispatch]
    );

    return (
        <postAddContactsModal.PostAddContactsModal
            data={modalState.data}
            index={1}
            onClose={closeModal}
            onFocus={() => {}}
            opened={modalState.opened}
        />
    );
}
