import { store } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import * as types from '../../types/Types';

export class UnreadContainer<
    TItemId extends string,
    TContainerId extends string,
    TItem extends { id: TItemId },
    TContainer extends { id: TContainerId }
> {
    constructor(
        private getReadItemsData: (
            containerId: TContainerId,
            emptyDataCreator: () => types.ReadItemsData<TItemId>
        ) => types.ReadItemsData<TItemId>,
        private getContainerIdFromItem: (item: TItem) => TContainerId,
        private getDateFromItem: (item: TItem) => types.Timestamp,
        private getAuthorFromItem: (item: TItem) => types.Username | undefined,
        private getItemsCountFromContainer: (container: TContainer) => number,
        private getLastItemDateFromContainer: (container: TContainer) => types.Timestamp,
        private getContainer: (containerId: TContainerId) => TContainer | undefined,
        private getItemsByContainerId: (containerId: TContainerId) => Promise<TItem[]>,
        private getCachedItemsByContainerId: ((containerId: TContainerId) => TItem[]) | null,
        private dispatchEventsAndSave: (needsOptimizingDataForContainerIds?: TContainerId[]) => void
    ) {}

    isItemRead(item: TItem) {
        const container = this.getContainer(this.getContainerIdFromItem(item));
        if (!container) {
            return true;
        }
        if (this.getCurrentUser().username === this.getAuthorFromItem(item)) {
            return true;
        }
        const data = this.getReadItemsData(container.id, () => this.getEmptyReadItemsData());
        if (!data) {
            return false;
        }
        if (this.getDateFromItem(item) <= data.lastAllReadTimestamp) {
            if (data.olderUnreadIds.includes(item.id)) {
                return false;
            }
            return true;
        }
        return data.newerReadIds.includes(item.id);
    }

    isContainerRead(container: TContainer) {
        if (this.getItemsCountFromContainer(container) === 0) {
            return true;
        }
        const data = this.getReadItemsData(container.id, () => this.getEmptyReadItemsData());
        if (data.olderUnreadIds.length > 0) {
            return false;
        }
        const lastItemDate = this.getLastItemDateFromContainer(container);
        if (lastItemDate <= data.lastAllReadTimestamp) {
            return true;
        }
        const cachedItems = this.getCachedItemsByContainerId?.(container.id);
        if (cachedItems) {
            return this.areAllItemsRead(cachedItems);
        }
        return false;
    }

    areAllItemsRead(items: TItem[]) {
        for (const item of items) {
            if (!this.isItemRead(item)) {
                return false;
            }
        }
        return true;
    }

    areAllContainersRead(containers: TContainer[]) {
        for (const container of containers) {
            if (!this.isContainerRead(container)) {
                return false;
            }
        }
        return true;
    }

    markItemAsRead(item: TItem) {
        return this.markItemsAsRead([item]);
    }

    markItemAsUnread(item: TItem) {
        return this.markItemsAsUnread([item]);
    }

    markContainerAsRead(container: TContainer) {
        return this.markContainersAsRead([container]);
    }

    markItemsAsRead(items: TItem[]) {
        items = items.filter((items) => !this.isItemRead(items));
        if (items.length === 0) {
            return;
        }
        const needsOptimizingDataForContainerIds: TContainerId[] = [];
        for (const item of items) {
            const containerId = this.getContainerIdFromItem(item);
            const data = this.getReadItemsData(containerId, () => this.getEmptyReadItemsData());
            if (this.getDateFromItem(item) <= data.lastAllReadTimestamp) {
                if (data.olderUnreadIds.includes(item.id)) {
                    data.olderUnreadIds.splice(data.olderUnreadIds.indexOf(item.id), 1);
                }
            } else {
                if (!data.newerReadIds.includes(item.id)) {
                    data.newerReadIds.push(item.id);
                    if (!needsOptimizingDataForContainerIds.includes(containerId)) {
                        needsOptimizingDataForContainerIds.push(containerId);
                    }
                }
            }
        }
        return this.dispatchEventsAndSave(needsOptimizingDataForContainerIds);
    }

    markItemsAsUnread(items: TItem[]) {
        items = items.filter((items) => this.isItemRead(items));
        if (items.length === 0) {
            return;
        }
        for (const item of items) {
            const containerId = this.getContainerIdFromItem(item);
            const data = this.getReadItemsData(containerId, () => this.getEmptyReadItemsData());
            if (this.getDateFromItem(item) <= data.lastAllReadTimestamp) {
                if (!data.olderUnreadIds.includes(item.id)) {
                    data.olderUnreadIds.push(item.id);
                }
            } else {
                if (data.newerReadIds.includes(item.id)) {
                    data.newerReadIds.splice(data.newerReadIds.indexOf(item.id), 1);
                }
            }
        }
        return this.dispatchEventsAndSave();
    }

    markContainersAsRead(containers: TContainer[]) {
        const now = Date.now() as types.Timestamp;
        for (const container of containers) {
            const data = this.getReadItemsData(container.id, () => this.getEmptyReadItemsData());
            data.olderUnreadIds = [];
            data.newerReadIds = [];
            data.lastAllReadTimestamp = now;
        }
        return this.dispatchEventsAndSave();
    }

    private getEmptyReadItemsData(): types.ReadItemsData<TItemId> {
        return {
            lastAllReadTimestamp: 0 as types.Timestamp,
            newerReadIds: [],
            olderUnreadIds: []
        };
    }

    private getCurrentUser() {
        const state = store.getState();
        const currentUser = selectCurrentUser(state);
        return currentUser;
    }

    async optimizeReadItemsData(containerIds: TContainerId[]) {
        for (const containerId of containerIds) {
            const data = this.getReadItemsData(containerId, () => this.getEmptyReadItemsData());
            if (!data) {
                continue;
            }
            const items = await this.getItemsByContainerId(containerId);
            const itemsUnorderedSeq = items.map((x) => ({
                id: x.id,
                date: this.getDateFromItem(x),
                isRead: this.isItemRead(x)
            }));
            const lastAllReadSequenceElement =
                this.getLastAllReadSequenceElement(itemsUnorderedSeq);
            if (
                lastAllReadSequenceElement !== null &&
                lastAllReadSequenceElement.date > data.lastAllReadTimestamp
            ) {
                data.lastAllReadTimestamp = Math.max(
                    data.lastAllReadTimestamp,
                    lastAllReadSequenceElement.date
                ) as types.Timestamp;
                data.newerReadIds = data.newerReadIds.filter((x) => {
                    const item = items.find((y) => y.id === x);
                    if (!item) {
                        return false;
                    }
                    return this.getDateFromItem(item) > data.lastAllReadTimestamp;
                });
            }
        }
    }

    private getLastAllReadSequenceElement<TId>(
        unorderedSeq: Array<{ id: TId; date: types.Timestamp; isRead: boolean }>
    ) {
        const seq = [...unorderedSeq].sort((a, b) => a.date - b.date);
        for (let i = 0; i < seq.length; ++i) {
            if (!seq[i].isRead) {
                return i > 0 ? seq[i - 1] : null;
            }
        }
        return seq[seq.length - 1];
    }
}
