import { useRef } from 'react';
import { BaseModalData } from '../../types/Modals';
import Modal from '../../atoms/Modal';
import StateView from '../../atoms/State';
import { FormRowEditPage } from './index';
import { FormRowId } from '../../types/Types';
import { useCallback } from 'react';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { useTranslation } from 'react-i18next';

export interface FormRowEditModalData extends BaseModalData {
    id: FormRowId;
}

export function FormRowEditModal(props: {
    onClose: () => void;
    data: FormRowEditModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const ref = useRef();
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForFormRow(props.data.id), [props.data.id])
    );
    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            beforeClose={() => (ref.current as any)?.beforeClose()}
            height={props.data.height ?? 330}
            width={props.data.width}
            title={props.data.title ?? t('modal.formRowEdit.title')}>
            <StateView state={state} retry={revalidate}>
                {(data) => (
                    <FormRowEditPage ref={ref} formRow={data.formRow} handleClose={props.onClose} />
                )}
            </StateView>
        </Modal>
    );
}
