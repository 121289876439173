import { Anchor, AnchorProps } from '@mantine/core';
import { forwardRef, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { RouterPaths } from '../../utils/router';

type LinkWithFilterProps = AnchorProps &
    Omit<LinkProps, 'to'> & {
        to: RouterPaths;
        filter: {
            users?: string[];
            text?: string[];
            tag?: string[];
            company?: string[];
        };
    };

function LinkWithFilter({ children, to, filter, ...props }: LinkWithFilterProps, ref: any) {
    const filterParams = useMemo(() => {
        let queryString = '?';

        if (filter.users) {
            queryString += `user=${filter.users.join(',')}`;
        }

        if (filter.company) {
            queryString += `company=${filter.company.join(',')}`;
        }

        return queryString;
    }, [filter.users, filter.company]);

    return (
        <Anchor ref={ref} component={Link} to={`/${to}${filterParams}`} {...props}>
            {children}
        </Anchor>
    );
}

export default forwardRef<HTMLAnchorElement, LinkWithFilterProps>(LinkWithFilter);
