import {
    Stack,
    Title,
    TextInput,
    Textarea,
    Group,
    Switch,
    Paper,
    Box,
    Input,
    Skeleton,
    Button,
    ScrollArea
} from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { UsersSelector } from '../../../components/UsersSelector';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../hooks/useLoader';
import { usePreventClose } from '../../../hooks/usePreventClose';
import useToast from '../../../hooks/useToast';
import { useAppSelector } from '../../../store';
import { selectCurrentUser } from '../../../store/CurrentUserSlice';
import { selectUsernameToName } from '../../../store/DataCacheSlice';
import { UsernameOrContactId, Username, FormId, FormModel2 } from '../../../types/Types';
import { UserUtils } from '../../../utils/UserUtils';
import { useParams } from 'react-router-dom';
import StateView from '../../../atoms/State';
import { FormModelLoaderData } from '../../../utils/router/loaders';
import {
    isNewFormId,
    useFormCreatorContext
} from '../FormCreator/components/FormState/useFormState';
import { useFormCreatorSave } from '../FormScreen/FormScreen';
import { LoadingMessageOverlay } from '../../../mantineAtoms/LoadingMessageOverlay';
import { Validator } from '../../../utils/Validator';
import { notifications } from '@mantine/notifications';
import { useDevMode } from '../../../hooks/useDevMode';

interface FormData {
    users: { label: string; value: string; type: 'contact' | 'user' | 'staff' }[];
    managers: { label: string; value: string; type: 'contact' | 'staff' | 'user' }[];
    autoResponseEnabled: boolean;
    responseTitle?: string;
    responseMessage?: string;
}

export function FormSettings() {
    const { formId } = useParams();

    const { state, revalidate } = useLoader(
        useCallback((x) => x.loadFormModel(formId as FormId), [formId])
    );

    return (
        <StateView state={state} retry={revalidate} loading={FormSettingsLoader}>
            {(data) => <FormSettingsView {...data} />}
        </StateView>
    );
}

function FormSettingsLoader() {
    const [devMode] = useDevMode();
    const { t } = useTranslation();

    return (
        <Box w="50%" miw={500} mx="auto">
            <Paper p="lg" radius={'md'} mb="lg">
                <Title order={4} mb="md" fw="normal">
                    {t('screen.forms.formSettings.title')}
                </Title>
                <Stack spacing={'xs'}>
                    <Input.Wrapper
                        label={t('screen.forms.formSettings.labels.users')}
                        description={t('screen.forms.formSettings.descriptions.users')}>
                        <Skeleton h={34} mt={5} />
                    </Input.Wrapper>
                    <Input.Wrapper
                        label={t('screen.forms.formSettings.labels.managers')}
                        description={t('screen.forms.formSettings.descriptions.managers')}>
                        <Skeleton h={34} mt={5} />
                    </Input.Wrapper>

                    <Switch
                        mt="sm"
                        checked={false}
                        disabled
                        label={t('addedUsersCanSeeOldSubmits')}
                    />
                </Stack>
            </Paper>
            {devMode && (
                <Paper p="lg" radius={'md'}>
                    <Group position="apart" mb="md">
                        <Title order={4} fw="normal">
                            {t('entityProps.autoResponse')}
                        </Title>
                        <Switch checked={false} name="autoResponseEnabled" disabled />
                    </Group>
                    <Stack spacing={'xs'}>
                        <Input.Wrapper label={t('entityProps.title')}>
                            <Skeleton h={34} m={1} />
                        </Input.Wrapper>
                        <Input.Wrapper label={t('entityProps.message')}>
                            <Skeleton h={173} m={1} />
                        </Input.Wrapper>
                    </Stack>
                </Paper>
            )}
            <Button pos="absolute" top={20} right={20}>
                {t('screen.forms.formSettings.saveForm')}
            </Button>
        </Box>
    );
}

function FormSettingsView({ form, managers: allStaff, users: allUsers }: FormModelLoaderData) {
    const { t } = useTranslation();
    const { dispatch: dispatchFormCreator, formName } = useFormCreatorContext();

    const [updateKeysWhenAddingUsers, setUpdateKeysWhenAddingUsers] = useState(true);

    const displayMap = useAppSelector(selectUsernameToName);
    const currentUser = useAppSelector(selectCurrentUser);

    useEffect(() => {
        if (!isNewFormId(form.id) && form.name && form.questions) {
            dispatchFormCreator({ type: 'load_form', questions: form.questions });
        }
    }, [form.id, form.name, form.questions, dispatchFormCreator]);

    const {
        control,
        getValues,
        setError,
        register,
        watch,
        formState: { errors, isDirty }
    } = useForm<FormData>({
        defaultValues: {
            users: UserUtils.usernameOrIdToOption(
                form.users.map((x) => ({ type: 'user', username: x })),
                allUsers,
                displayMap
            ),
            managers: form.managers.map((x) => ({
                label: x,
                value: x,
                type: 'staff',
                email: allStaff.find((u) => u.username === x)?.email
            })),
            autoResponseEnabled: form.autoResponseData.enabled,
            responseMessage: form.autoResponseData.message,
            responseTitle: form.autoResponseData.title
        }
    });
    const autoResponseEnabled = watch('autoResponseEnabled');

    usePreventClose(isDirty);

    const toast = useToast();

    const { isProcessing: isSaving, handleSubmitWithParams } = useFormCreatorSave();

    async function handleSaveSettingsChanges(data: FormData) {
        try {
            const users = data.users.map((x) => ({
                type: x.type === 'staff' ? 'user' : 'contact',
                [x.type === 'contact' ? 'contactId' : 'username']: x.value
            }));
            const managers = data.managers
                .map((x) => (x.type === 'user' || x.type === 'staff' ? x.value : null))
                .filter((x) => x !== null) as Username[];

            const autoResponseModel: FormModel2['autoResponseData'] = {
                enabled: autoResponseEnabled,
                emailFieldId: form.autoResponseData.emailFieldId,
                title: data.responseTitle || '',
                message: data.responseMessage || ''
            };
            await handleSubmitWithParams({
                users: users as unknown as UsernameOrContactId[],
                managers: managers,
                autoResponseData: autoResponseModel
            });

            toast(t('screen.forms.formSettings.formSaved'), 'success');
        } catch (error) {
            console.error(error);
            notifications.show({
                title: t('screen.forms.formSettings.errorMessage.genericErrorTitle'),
                message: t('screen.forms.formSettings.errorMessage.genericErrorMessage'),
                color: 'red'
            });
        }
    }

    function validateForm(formData: FormData) {
        let isValid = true;
        if (!formData.users.find((x) => x.value === currentUser.username)) {
            isValid = false;
            toast(t('form.error.incSelfInUsers'), 'error');
        }
        if (!formData.managers.find((x) => x.value === currentUser.username)) {
            isValid = false;
            toast(t('form.error.incSelfInManagers'), 'error');
        }
        if (autoResponseEnabled) {
            //Auto response title validation
            if (!formData.responseTitle || formData.responseTitle.trim().length === 0) {
                isValid = false;
                setError('responseTitle', {
                    type: 'required',
                    message: t('form.error.autoRespTitleEmpty')
                });
            }
            if (formData.responseTitle && Validator.getErrors(formData.responseTitle)) {
                isValid = false;
                setError('responseTitle', {
                    type: 'value',
                    message: t('screen.forms.formSettings.errorMessage.invalidTitle', {
                        characters: Validator.getSafeDefaultChars()
                    })
                });
            }
            //Auto response message validation
            if (!formData.responseMessage || formData.responseMessage.trim().length === 0) {
                isValid = false;
                setError('responseMessage', {
                    type: 'required',
                    message: t('form.error.autoRespMessageEmpty')
                });
            }
            if (formData.responseMessage && Validator.getErrors(formData.responseMessage)) {
                isValid = false;
                setError('responseMessage', {
                    type: 'value',
                    message: t('screen.forms.formSettings.errorMessage.invalidMessage', {
                        characters: Validator.getSafeDefaultChars()
                    })
                });
            }
        }

        let wrongUser: string = '';
        for (const user of formData.users) {
            wrongUser = user.value;
            const userErrors = Validator.getErrors(user.value, ['safeDefault', 'username']);
            if (userErrors) {
                setError('users', {
                    message: t('screen.forms.formSettings.errorMessage.incorectUser', {
                        user: wrongUser
                    })
                });
                isValid = false;
                break;
            }
        }

        let wrongManager: string = '';
        for (const user of formData.managers) {
            wrongManager = user.value;
            const userErrors = Validator.getErrors(user.value, ['safeDefault', 'username']);
            if (userErrors) {
                setError('managers', {
                    message: t('screen.forms.formSettings.errorMessage.incorectUser', {
                        user: wrongManager
                    })
                });
                isValid = false;
                break;
            }
        }

        return isValid;
    }

    async function handleSubmit() {
        const formData = getValues();
        if (!validateForm(formData)) return;
        await handleSaveSettingsChanges(formData);
    }

    const loadingMessage =
        isSaving && isSaving === 'editing'
            ? t('screen.forms.formSettings.loadingMessages.editingInfo', { name: formName[0] })
            : t('screen.forms.formSettings.loadingMessages.creatingForm');

    return (
        <Box w="50%" miw={500} mx="auto" h="calc(100% - 160px)">
            <LoadingMessageOverlay visible={isSaving !== null} message={loadingMessage} />
            <ScrollArea h="100%" type="always" offsetScrollbars>
                <Paper p="lg" radius={'md'} mb="lg">
                    <Title order={4} mb="md" fw="normal">
                        {t('screen.forms.formSettings.title')}
                    </Title>
                    <Stack spacing={'xs'}>
                        <UsersSelector
                            label={t('screen.forms.formSettings.labels.users')}
                            control={control}
                            name="users"
                            description={t('screen.forms.formSettings.descriptions.users')}
                            hasError={!!errors.users}
                            isDisabled={isSaving !== null}
                            onlyStaff
                        />
                        <UsersSelector
                            label={t('screen.forms.formSettings.labels.managers')}
                            description={t('screen.forms.formSettings.descriptions.managers')}
                            control={control}
                            name="managers"
                            hasError={!!errors.managers}
                            isDisabled={isSaving !== null}
                            onlyStaff
                        />
                        <Switch
                            mt="sm"
                            checked={!updateKeysWhenAddingUsers}
                            onChange={(event) =>
                                setUpdateKeysWhenAddingUsers(!event.currentTarget.checked)
                            }
                            disabled={isSaving !== null}
                            label={t('addedUsersCanSeeOldSubmits')}
                        />
                    </Stack>
                </Paper>
                {form.autoResponseData.emailFieldId &&
                    form.autoResponseData.emailFieldId.length > 0 && (
                        <Paper p="lg" radius={'md'}>
                            <Group position="apart" mb="md">
                                <Title order={4} fw="normal">
                                    {t('entityProps.autoResponse')}
                                </Title>
                                <Controller
                                    name="autoResponseEnabled"
                                    control={control}
                                    render={({ field }) => (
                                        <Switch
                                            checked={field.value}
                                            onChange={(event) => {
                                                field.onChange(event.currentTarget.checked);
                                            }}
                                            disabled={isSaving !== null}
                                        />
                                    )}
                                />
                            </Group>
                            <Stack spacing={'xs'}>
                                <TextInput
                                    disabled={!autoResponseEnabled || isSaving !== null}
                                    placeholder={t(
                                        'screen.forms.formSettings.placeholders.formSubmission'
                                    )}
                                    {...register('responseTitle', {
                                        maxLength: {
                                            value: 100,
                                            message: 'Title cannot be longer than 100 characters'
                                        }
                                    })}
                                    error={
                                        errors.responseTitle?.message ?? errors.responseTitle?.type
                                    }
                                    label={t('entityProps.title')}
                                />
                                <Textarea
                                    disabled={!autoResponseEnabled || isSaving !== null}
                                    placeholder={t(
                                        'screen.forms.formSettings.placeholders.thanksForAnswering'
                                    )}
                                    {...register('responseMessage', {
                                        maxLength: {
                                            value: 300,
                                            message: 'Message cannot be longer than 100 characters'
                                        }
                                    })}
                                    error={
                                        errors.responseMessage?.message ??
                                        errors.responseMessage?.type
                                    }
                                    label={t('entityProps.message')}
                                    minRows={7}
                                />
                            </Stack>
                        </Paper>
                    )}
            </ScrollArea>
            <Button pos="absolute" top={20} right={20} onClick={handleSubmit}>
                Save
            </Button>
        </Box>
    );
}
