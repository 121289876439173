import { api } from '../../../api/Api';
import {
    Company,
    CompanyName,
    Email,
    CompanyWebsite,
    CompanyAddress,
    Phone,
    CompanyNote,
    MobilePhone
} from '../../../types/Types';

export function getCompanyFromQuery(query: string): Company {
    const c: Company = {
        name: query.trim() as CompanyName,
        email: '' as Email,
        website: '' as CompanyWebsite,
        address: '' as CompanyAddress,
        phone: '' as Phone,
        note: '' as CompanyNote,
        mobilePhone: '' as MobilePhone,
        tags: [],
        pinned: false,
        archived: false,
        id: `new:${query}` as Company['id'],
        createdBy: '' as Company['createdBy'],
        createdDate: -1 as Company['createdDate'],
        lastModifiedBy: '' as Company['lastModifiedBy'],
        lastModifiedDate: -1 as Company['lastModifiedDate']
    };

    return c;
}

export async function getCompanyId(company?: Company) {
    if (company === undefined) {
        return undefined;
    } else if (company.id.startsWith('new:')) {
        const newCompany = await api.addCompany(company);
        return newCompany.id;
    } else {
        return company.id;
    }
}
