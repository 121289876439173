import { Group, Select, SelectProps, Text } from '@mantine/core';
import { SearchElements } from './types';
import { forwardRef, useCallback, useState } from 'react';
import { Contact, Username } from '../../../types/Types';
import { useInputState } from '@mantine/hooks';
import { Validator } from '../../../utils/Validator';
import { useTranslation } from 'react-i18next';

type OneSearchElement =
    | {
          type: 'contact';
          label: string;
          email: string;
          value: Contact['id'];
      }
    | {
          type: 'user';
          label: string;
          email: string;
          value: Username;
      };

const SelectItem = forwardRef<HTMLDivElement, OneSearchElement>(
    ({ label, email, type, value, ...others }: OneSearchElement, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                {label && <Text size="sm">{label}</Text>}
                <Text size="xs" opacity={0.65}>
                    {email}
                </Text>
            </Group>
        </div>
    )
);

export const UserSearch = ({
    onChange,
    contacts,
    onAddNewContact,
    ...props
}: Omit<SelectProps, 'data' | 'onCreate'> & {
    contacts: SearchElements;
    onAddNewContact?: (newContactEmail: string) => void;
}) => {
    const [searchValue, setSearchValue] = useInputState('');
    const [inputError, setInputError] = useState<React.ReactNode>(undefined);
    const { t } = useTranslation();

    function checkNewEmail(query: string) {
        const result = Validator.getErrors(query, ['email', 'safeDefault'], true);
        if (result !== false) {
            if (result.includes('email')) {
                setInputError(t('components.userSearch.incorectEmail'));
            }
            if (result.includes('size')) {
                setInputError(
                    t('components.userSearch.maxLength', {
                        count: Validator.constraints['email'].maxLength
                    })
                );
            }
            return false;
        }
        return true;
    }

    const handleChange = useCallback(
        (value: string) => {
            onChange?.(value);
        },
        [onChange]
    );

    const handleSearchChange = (query: string) => {
        setInputError(undefined);
        setSearchValue(query);
    };

    return (
        <Select
            error={inputError}
            creatable={!!onAddNewContact}
            onCreate={(querry) => {
                if (checkNewEmail(querry)) {
                    onAddNewContact?.(`new:${querry}`);
                    setSearchValue('');
                    return `new:${querry}`;
                }
                return undefined;
            }}
            getCreateLabel={(querry) => {
                return t('components.userSearch.addNewContact', { email: querry });
            }}
            searchable
            value={''}
            searchValue={searchValue}
            onSearchChange={handleSearchChange}
            itemComponent={SelectItem}
            nothingFound={t('components.userSearch.nothingFound')}
            onChange={handleChange}
            data={contacts}
            {...props}
        />
    );
};
