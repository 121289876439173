import * as privfs from 'privfs-client';
import { HashmailResolver } from './HashmailResolver';
import { MessageCreator } from './MessageCreator';
import { MessageTagsFactory } from './MessageTagsFactory';

export class MessageService {
    constructor(
        private messageManager: privfs.message.MessageManager,
        private identity: privfs.identity.Identity,
        private hashmailResolver: HashmailResolver
    ) {}

    createMessage(
        oneOrMoreReceivers: privfs.message.MessageReceiver | privfs.message.MessageReceiver[],
        subject: string,
        text: string
    ): privfs.message.Message {
        return MessageCreator.createMessage(this.identity, oneOrMoreReceivers, subject, text);
    }

    async sendSimpleMessage2(
        hashmail: string,
        subject: string,
        text: string,
        type?: string,
        extra?: string,
        addToSink?: boolean
    ): Promise<privfs.types.message.ReceiverData[]> {
        const info = await this.hashmailResolver.resolveHashmail(hashmail);
        if (info.receivers.length === 0) {
            throw new Error('Cannot send message to ' + hashmail + ' there is no sink.');
        }
        const msg = this.createMessage(info.receivers[0], subject, text);
        if (type) {
            msg.type = type;
        }
        return this.sendSimpleMessage(msg, extra, addToSink);
    }

    sendSimpleMessage(
        message: privfs.message.Message,
        extra?: string,
        addToSink?: boolean,
        tags?: string[]
    ): Promise<privfs.types.message.ReceiverData[]> {
        return this.sendSimpleMessageOpt({ message, extra, addToSink, tags });
    }

    async sendSimpleMessageOpt({
        message,
        extra,
        tags,
        addTypeTag
    }: {
        message: privfs.message.Message;
        extra?: string;
        addToSink?: boolean;
        tags?: string[];
        addTypeTag?: boolean;
    }): Promise<privfs.types.message.ReceiverData[]> {
        const baseTags = MessageTagsFactory.getMessageTagsEx(message, addTypeTag !== false);
        const outTags = tags ? baseTags.concat(tags) : baseTags;
        const opts: privfs.types.message.SendOptions = {};
        if (extra) {
            opts.extra = extra;
        }
        const res = (await this.messageManager.messagePost(
            message,
            outTags,
            opts
        )) as privfs.types.message.ReceiverData[];
        return res;
    }
}
