import { Deferred } from './Deferred';

export class Lock {
    private deferred: Deferred<void> = new Deferred<void>();

    constructor() {
        this.deferred.resolve();
    }

    async withLock<T>(func: () => Promise<T>): Promise<T> {
        await this.obtain();
        try {
            return await func();
        } finally {
            this.release();
        }
    }

    private async obtain() {
        const deferred = new Deferred<void>();
        const oldDeferred = this.deferred;
        this.deferred = deferred;
        await oldDeferred.promise;
    }

    private async release() {
        this.deferred.resolve();
    }
}
