import { Validator } from './Validator';
import i18n from 'i18next';
export interface PasswordStrengthResultOk {
    status: 'ok';
}

export interface PasswordStrengthResultError {
    status: 'error';
    message: string;
}

export type PasswordStrengthResult = PasswordStrengthResultOk | PasswordStrengthResultError;

export class PasswordStrength {
    static readonly MIN_LENGTH = Validator.constraints['password'].minLength || 0;
    static readonly MAX_LENGTH =
        Validator.constraints['password'].maxLength || Number.POSITIVE_INFINITY;
    static readonly REQUIRE_MIXED_CASE = true;
    static readonly REQUIRE_DIGITS = true;
    static readonly REQUIRE_SPECIAL = true;

    static validate(pwd: string): PasswordStrengthResult {
        if (pwd.length < this.MIN_LENGTH) {
            return {
                status: 'error',
                message: i18n.t('utils.passwordStrength.minLengthErr', {
                    LENGTH: this.MIN_LENGTH
                })
            };
        }
        if (pwd.length > this.MAX_LENGTH) {
            return {
                status: 'error',
                message: i18n.t('utils.passwordStrength.maxLengthErr', {
                    LENGTH: this.MAX_LENGTH
                })
            };
        }
        if (this.REQUIRE_MIXED_CASE && !(pwd.match(/[a-z]/) && pwd.match(/[A-Z]/))) {
            return { status: 'error', message: i18n.t('utils.passwordStrength.caseCharacterErr') };
        }
        if (this.REQUIRE_DIGITS && !pwd.match(/[0-9]/)) {
            return { status: 'error', message: i18n.t('utils.passwordStrength.numberErr') };
        }
        if (this.REQUIRE_SPECIAL && !pwd.match(/\W|_/)) {
            return { status: 'error', message: i18n.t('utils.passwordStrength.specialSymbolErr') };
        }
        return { status: 'ok' };
    }
}

export const PASSWORD_REQUIREMENTS = () => [
    {
        re: new RegExp(`^.{${PasswordStrength.MIN_LENGTH},}$`),
        label: i18n.t('utils.passwordStrength.minLength', {
            LENGTH: PasswordStrength.MIN_LENGTH
        })
    },
    {
        re: new RegExp(`^.{0,${PasswordStrength.MAX_LENGTH}}$`),
        label: i18n.t('utils.passwordStrength.maxLength', {
            LENGTH: PasswordStrength.MAX_LENGTH
        })
    },
    { re: /[a-z]/, label: i18n.t('utils.passwordStrength.lowercaseLetter') },
    { re: /[A-Z]/, label: i18n.t('utils.passwordStrength.uppercaseLetter') },
    { re: /[0-9]/, label: i18n.t('utils.passwordStrength.number') },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: i18n.t('utils.passwordStrength.specialSymbol') }
];
