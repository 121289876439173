import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type AnyFunction = (...any: any) => any;
interface Interaction<T extends AnyFunction> {
    action: T;
    onError?: (error: InteractionError | Error) => void;
    onSuccess?: () => void;
    onEndInteraction?: () => void;
    successTitle: string;
    successMessage?: React.ReactNode;
    emitSuccessToast?: boolean;
    errorTitle?: React.ReactNode;
    errorMessage?: React.ReactNode;
}

export class InteractionError extends Error {
    title: string;
    message: string;

    constructor(title: string, msg?: string) {
        super(msg);
        this.title = title;
        this.message = msg ?? 'Please try again later';
    }
}

export function useUserInteraction<T extends AnyFunction>({
    action,
    onError,
    onSuccess,
    successMessage,
    successTitle,
    errorMessage,
    errorTitle,
    onEndInteraction,
    emitSuccessToast = true
}: Interaction<T>) {
    const [isProcessing, { close: stopProcessing, open: startProcessing }] = useDisclosure();
    const { t } = useTranslation();

    const notifyError = useCallback(
        (error: InteractionError | Error) => {
            stopProcessing();
            const title =
                error instanceof InteractionError
                    ? error.title
                    : errorTitle || t('errorMessage.somethingWentWrong');
            const message =
                error instanceof InteractionError
                    ? error.message
                    : errorMessage || t('errorMessage.pleaseTryAgainLater');

            notifications.show({
                id: 'error-msg',
                withCloseButton: true,
                color: 'red',
                title: title,
                message: message
            });
            console.error(error);
            onError?.(error);
        },
        [stopProcessing, onError, errorMessage, errorTitle, t]
    );

    const notifySuccess = useCallback(() => {
        notifications.show({
            id: successTitle,
            withCloseButton: true,
            title: successTitle,
            message: successMessage
        });
        stopProcessing();
        onSuccess?.();
    }, [onSuccess, successMessage, stopProcessing, successTitle]);

    const startInteraction: (...args: Parameters<T>) => void = useCallback(
        async (args) => {
            try {
                startProcessing();
                await action(args);
                if (emitSuccessToast) {
                    notifySuccess();
                }
            } catch (error) {
                notifyError(error as any);
            } finally {
                onEndInteraction?.();
            }
        },
        [action, startProcessing, notifyError, notifySuccess, onEndInteraction, emitSuccessToast]
    );

    return { isProcessing, startInteraction, notifyError, notifySuccess } as const;
}
