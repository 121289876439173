import * as privmx from 'privfs-client';
import { InquiryApi } from './InquiryApi';
import * as PmxApi from 'privmx-server-api';
import { DataEncryptor } from './DataEncryptor';
import { EncKey, KeyProvider } from './KeyProvider';
import { FormRowAdminData, Mimetype, Question, SubmitedAnswer } from '../../types/Types';
import * as types from '../../types/Types';
import { InquiryPublicDataEncryptor } from './InquiryPublicDataEncryptor';
import { InquirySubmitDataEncryptor } from './InquirySubmitDataEncryptor';
import { Utils } from './utils/Utils';
import {
    AttachmentMeta,
    AttachmentUtils,
    DownloadOptions,
    ExistingAttachmentsProvider,
    PreparedFile
} from './AttachmentUtils';
import { RequestApi } from './RequestApi';
import { InquiryAttachmentMetaEncryptor } from './InquiryAttachmentMetaEncryptor';
import { InquirySubmitAdminDataEncryptor } from './InquirySubmitAdminDataEncryptor';
import { InquirySubmitResponseAdminDataEncryptor } from './InquirySubmitResponseAdminDataEncryptor';
import { InquirySubmitResponseDataEncryptorWithPubKey } from './InquirySubmitResponseDataEncryptorWithPubKey';
import { TagService } from './TagService';
import { api } from '../Api';
import { t } from 'i18next';
import { UrlBuilder } from '../../utils/UrlBuilder';
import { DataCacheInterface } from '../DataCacheInterface';
import { ModalsInterface } from '../ModalsInterface';

export interface Inquiry {
    raw: PmxApi.api.inquiry.Inquiry;
    data: InquiryData;
    keys: EncKey[];
    tags: string[];
}

export interface InquiryData {
    name: string;
    priv: PmxApi.api.core.EccWif;
    questions: Question[];
    autoResponseData: PmxApi.api.inquiry.AutoResponseData;
}

export interface InquirySubmit {
    raw: PmxApi.api.inquiry.InquirySubmit;
    data: SubmitData;
    adminData: FormRowAdminData;
    attachments: InquirySubmitAttachment[];
}

export interface InquirySubmitAttachment {
    hmac: string; // base64
    name: string;
    mimetype: Mimetype;
    size: number;
    hasThumb: boolean;
}

export interface SubmitData {
    answers: SubmitedAnswer[];
}

export interface InquiryPublicData {
    name: string;
    pub: PmxApi.api.core.EccPubKey;
    questions: Question[];
}

export interface PmxAttachment {
    inquiryId: PmxApi.api.inquiry.InquiryId;
    inquirySubmitId: PmxApi.api.inquiry.InquirySubmitId;
    group: PmxApi.api.attachment.AttachmentGroup;
    tags: string[];
    attachmentId: PmxApi.api.attachment.AttachmentId;
    author: PmxApi.api.core.Username;
    date: PmxApi.api.core.TimestampN;
    meta: AttachmentMeta;
    hasThumb: boolean;
}

export interface PmxAttachmentEx extends PmxAttachment {
    createdDate: PmxApi.api.core.TimestampN;
    creator: PmxApi.api.core.Username;
    versions: number;
    contributors: PmxApi.api.core.Username[];
    modificationDates: PmxApi.api.core.TimestampN[];
}

interface EncryptedInquirySubmitResponseDataEx {
    data: PmxApi.api.inquiry.InquirySubmitResponseData;
    serverKeyHalf: PmxApi.api.core.Base64;
    userKeyHalf: PmxApi.api.core.Base64;
}

export class InquiryService {
    public inquiryApi: InquiryApi;
    private requestApi: RequestApi;
    private inquiryDataEncryptor = new DataEncryptor<InquiryData, PmxApi.api.inquiry.InquiryData>();
    private submitDataEncryptor = new InquirySubmitDataEncryptor();
    private submitAdminDataEncryptor = new InquirySubmitAdminDataEncryptor();
    private inquiryPublicDataEncryptor = new InquiryPublicDataEncryptor();
    private submitResponseAdminDataEncryptor = new InquirySubmitResponseAdminDataEncryptor();
    private submitResponseDataEncryptorWithPubKey =
        new InquirySubmitResponseDataEncryptorWithPubKey();
    private submitResponseDataEncryptorWithEncKey = new DataEncryptor<
        types.InquirySubmitResponseData,
        PmxApi.api.inquiry.InquirySubmitResponseData,
        EncKey
    >();
    private attachmentMetaEncryptor = new InquiryAttachmentMetaEncryptor();
    private attachmentUtils: AttachmentUtils<
        InquirySubmitAttachment,
        privmx.crypto.ecc.PublicKey | PmxApi.api.core.EccPubKey
    >;

    constructor(
        private gateway: privmx.gateway.RpcGateway,
        private keyProvider: KeyProvider,
        private tagService: TagService,
        existingAttachmentsProvider: ExistingAttachmentsProvider,
        private cacheService: DataCacheInterface,
        private modalsService: ModalsInterface
    ) {
        this.inquiryApi = new InquiryApi(this.gateway);
        this.requestApi = new RequestApi(this.gateway);
        this.attachmentUtils = new AttachmentUtils(
            this.requestApi,
            this.attachmentMetaEncryptor,
            (preparedFile, hasThumb) =>
                this.preparedFileToAttachmentConverter(preparedFile, hasThumb),
            existingAttachmentsProvider,
            this.cacheService,
            this.modalsService
        );
    }

    async getMainInquiryId() {
        const { id } = await this.inquiryApi.getMainInquiry();
        return id;
    }

    async setMainInquiryId(id: PmxApi.api.inquiry.InquiryId) {
        await this.inquiryApi.setMainInquiry({ id: id });
    }

    async getInquiry(id: PmxApi.api.inquiry.InquiryId) {
        const { inquiry } = await this.inquiryApi.getInquiry({ id: id });
        return this.decryptInquiry(inquiry);
    }

    async getInquires() {
        const { inquiries } = await this.inquiryApi.getInquiries({ type: 'inquiry' });
        return Promise.all(inquiries.map((x) => this.decryptInquiry(x)));
    }

    async getEmailInboxes() {
        const { inquiries } = await this.inquiryApi.getInquiries({ type: 'emailInbox' });
        return Promise.all(inquiries.map((x) => this.decryptInquiry(x)));
    }

    async getInquiresOfUser(user: PmxApi.api.core.Username) {
        const { inquiries } = await this.inquiryApi.getInquirySubmitsOfUser({ user });
        return Promise.all(inquiries.map((x) => this.decryptInquiry(x)));
    }

    async getInquiresOfCompany(companyId: PmxApi.api.company.CompanyId) {
        const { inquiries } = await this.inquiryApi.getInquirySubmitsOfCompany({ companyId });
        return Promise.all(inquiries.map((x) => this.decryptInquiry(x)));
    }

    async getInquiriesWithSubmitsByThreadIds(threadIds: PmxApi.api.thread.ThreadId[]) {
        const { inquiries: inquiriesRaw, submits: submitsRaw } =
            await this.inquiryApi.getInquirySubmitsByThreadIds({ threadIds });
        const inquiries = await Promise.all(inquiriesRaw.map((x) => this.decryptInquiry(x)));
        const inquirySubmits = (
            await Promise.all(
                submitsRaw.map(async (x) => {
                    const inquiry = inquiries.find((y) => y.raw.id === x.inquiryId);
                    if (!inquiry) {
                        return null;
                    }
                    const canAccess = await this.canAccessSubmit(inquiry, x);
                    if (!canAccess) {
                        return null;
                    }
                    return this.decryptSubmit(inquiry, x);
                })
            )
        ).filter((x) => !!x) as InquirySubmit[];
        return { inquiries, inquirySubmits };
    }

    async getSubmits(inquiry: Inquiry) {
        const { submits } = await this.inquiryApi.getInquirySubmits({ id: inquiry.raw.id });
        return (
            await Promise.all(
                submits.map(async (x) => {
                    const canAccess = await this.canAccessSubmit(inquiry, x);
                    if (!canAccess) {
                        return null;
                    }
                    return this.decryptSubmit(inquiry, x);
                })
            )
        ).filter((x) => !!x) as InquirySubmit[];
    }

    async createInquiry(
        name: string,
        questions: Question[],
        users: PmxApi.api.core.Username[],
        managers: PmxApi.api.core.Username[],
        tags: string[],
        publish: boolean,
        type: PmxApi.api.inquiry.InquiryType,
        autoResponseData: PmxApi.api.inquiry.AutoResponseData,
        captchaEnabled?: boolean
    ) {
        const currentKey = this.keyProvider.generateKey();
        const data: InquiryData = {
            name: name,
            priv: privmx.crypto.serviceSync.eccPrivRandom().toWIF() as PmxApi.api.core.EccWif,
            questions: questions,
            autoResponseData: autoResponseData
        };
        const allUsers = Utils.unique(users.concat(managers));
        const { inquiry } = await this.inquiryApi.createInquiry({
            keyId: currentKey.id,
            type: type,
            data: await this.inquiryDataEncryptor.encrypt(data, currentKey),
            users: users,
            managers: managers,
            tags: await this.tagService.getEncryptedTagsIfSharedScope(tags as types.Tag[]),
            keys: await this.keyProvider.prepareKeysList(allUsers, [], currentKey),
            autoResponseData: autoResponseData,
            captchaEnabled: captchaEnabled
        });
        await this.tagService.setTagsIfPrivateScope('inquiry', inquiry.id, tags as types.Tag[]);
        const res = await this.createInquiryObj(inquiry, data, currentKey);
        return publish ? this.publishInquiry(res) : res;
    }

    async updateInquiry(
        info: Inquiry,
        name: string,
        questions: Question[],
        users: PmxApi.api.core.Username[],
        managers: PmxApi.api.core.Username[],
        tags: string[],
        publish: boolean,
        updateKeysWhenAddingUsers: boolean,
        autoResponseData: PmxApi.api.inquiry.AutoResponseData,
        captchaEnabled?: boolean
    ) {
        const data: InquiryData = {
            name: name,
            priv: info.data.priv,
            questions: questions,
            autoResponseData: autoResponseData
        };
        const oldUsers = Utils.unique(info.raw.users.concat(info.raw.managers));
        const newUsers = Utils.unique(users.concat(managers));
        const usersDiff = Utils.calcDiff(oldUsers, newUsers);
        const keys = updateKeysWhenAddingUsers
            ? []
            : await this.prepareKeysForNewUsers(info, usersDiff.newOnes);
        const removedAnyUsers = usersDiff.removed.length > 0;
        const addedAnyUsers = usersDiff.newOnes.length > 0;
        const currentKey = await (async () => {
            const needsNewKey = removedAnyUsers || (addedAnyUsers && updateKeysWhenAddingUsers);
            if (!needsNewKey) {
                return this.getKeyForInquiry(info, info.raw.keyId);
            }
            const newKey = this.keyProvider.generateKey();
            Utils.addMany(keys, await this.keyProvider.prepareKeysList(newUsers, [], newKey));
            return newKey;
        })();
        const { inquiry } = await this.inquiryApi.updateInquiry({
            id: info.raw.id,
            keyId: currentKey.id,
            data: this.inquiryDataTheSame(info, currentKey, data)
                ? info.raw.data
                : await this.inquiryDataEncryptor.encrypt(data, currentKey),
            users: users,
            managers: managers,
            tags: await this.tagService.setTagsOrGetEncrypted(
                'inquiry',
                info.raw.id,
                tags as types.Tag[]
            ),
            keys: keys,
            force: true,
            lastVersion: info.raw.version,
            autoResponseData: autoResponseData,
            captchaEnabled: captchaEnabled
        });
        const res = await this.createInquiryObj(inquiry, data, currentKey);
        return publish ? this.publishInquiry(res) : res;
    }

    async toggleInquiryTag(inquiryId: PmxApi.api.inquiry.InquiryId, tag: string, enabled: boolean) {
        const inquiry = await this.getInquiry(inquiryId);
        const newTags = await this.tagService.toggleTag(
            'inquiry',
            inquiryId,
            tag as types.Tag,
            { tags: [] },
            enabled
        );
        const newInquiry = await this.updateInquiry(
            inquiry,
            inquiry.data.name,
            inquiry.data.questions,
            inquiry.raw.users,
            inquiry.raw.managers,
            newTags,
            inquiry.raw.currentPublication !== null,
            true,
            inquiry.data.autoResponseData
        );
        return newInquiry;
    }

    private async prepareKeysForNewUsers(info: Inquiry, newUsers: PmxApi.api.core.Username[]) {
        const res: PmxApi.api.core.KeyEntrySet[] = [];
        for (const keyEntry of info.raw.keys) {
            const key = await this.getKeyForInquiry(info, keyEntry.keyId);
            Utils.addMany(res, await this.keyProvider.prepareKeysList(newUsers, [], key));
        }
        return res;
    }

    private inquiryDataTheSame(info: Inquiry, currentKey: EncKey, currentData: InquiryData) {
        return (
            info.raw.keyId === currentKey.id &&
            JSON.stringify(info.data) === JSON.stringify(currentData)
        );
    }

    async publishInquiry(info: Inquiry) {
        const { inquiry } = await this.inquiryApi.publishInquiry({
            id: info.raw.id,
            keyId: info.raw.keyId,
            data: info.raw.data,
            setAsCurrent: true,
            force: true,
            version: info.raw.version,
            publicData: this.inquiryPublicDataEncryptor.encrypt(info.data)
        });
        return this.createInquiryObj(inquiry, info.data, info.keys);
    }

    async getInquiryPublicView(id: PmxApi.api.inquiry.InquiryId) {
        const { inquiry } = await this.inquiryApi.getInquiryPublicView({ id: id });
        const data = this.inquiryPublicDataEncryptor.decrypt(inquiry.data);
        return { raw: inquiry, data: data };
    }

    async createSubmit(
        id: PmxApi.api.inquiry.InquiryId,
        publicationId: PmxApi.api.inquiry.InquiryPublicationId,
        pubKey: PmxApi.api.core.EccPubKey,
        data: SubmitData,
        files: File[],
        autoResponseEmail: string | undefined,
        captcha: PmxApi.api.captcha.CaptchaObj | undefined
    ) {
        const pubKeyBuff = Buffer.from(pubKey);
        const attInfo = await this.attachmentUtils.prepareAttachments(
            pubKey,
            pubKeyBuff,
            null,
            files
        );
        await this.inquiryApi.submitInquiry({
            id: id,
            publicationId: publicationId,
            data: await this.submitDataEncryptor.encrypt(pubKey, data),
            attachments: attInfo.request,
            autoResponseEmail: autoResponseEmail,
            captcha: captcha
        });
    }

    async updateSubmit(
        inquirySubmitid: PmxApi.api.inquiry.InquirySubmitId,
        inquiryId: PmxApi.api.inquiry.InquiryId,
        adminData: FormRowAdminData,
        tags: PmxApi.api.tag.Tag[]
    ) {
        const pubKey = (await this.getInquiryPublicView(inquiryId)).data.pub;
        const encryptedAdminData: PmxApi.api.inquiry.InquirySubmitAdminData =
            await this.encryptSubmitAdminData(pubKey, adminData);
        const updatedSubmit = await this.inquiryApi.updateInquirySubmit({
            id: inquirySubmitid,
            adminData: encryptedAdminData,
            tags: await this.tagService.setTagsOrGetEncrypted(
                'inquirySubmit',
                inquirySubmitid,
                tags as types.Tag[]
            )
        });
        return updatedSubmit;
    }

    async setSubmitTags(id: PmxApi.api.inquiry.InquirySubmitId, tags: PmxApi.api.tag.Tag[]) {
        const updatedSubmit = await this.inquiryApi.setInquirySubmitTags({
            id: id,
            tags: await this.tagService.setTagsOrGetEncrypted(
                'inquirySubmit',
                id,
                tags as types.Tag[]
            )
        });
        return updatedSubmit;
    }

    async deleteSubmit(id: PmxApi.api.inquiry.InquirySubmitId) {
        await this.inquiryApi.deleteInquirySubmit({ id: id });
    }

    async getInquiryAttachments(inquiry: Inquiry) {
        const { attachments } = await this.inquiryApi.getInquiryAttachments({
            inquiryId: inquiry.raw.id
        });
        const groupMap = new Map<
            PmxApi.api.attachment.AttachmentGroup,
            PmxApi.api.inquiry.InquiryAttachment[]
        >();
        for (const attachment of attachments) {
            const list = groupMap.get(attachment.group);
            if (list) {
                list.push(attachment);
            } else {
                groupMap.set(attachment.group, [attachment]);
            }
        }
        return Promise.all(
            [...groupMap.values()].map((x) => this.decryptAttachmentGroup(inquiry, x))
        );
    }

    async getInquirySubmitAttachments(inquirySubmitId: PmxApi.api.inquiry.InquirySubmitId) {
        return (await this.getInquirySubmitAttachmentsEx(inquirySubmitId)).attachmentsRes;
    }

    async getInquirySubmitAttachmentsEx(inquirySubmitId: PmxApi.api.inquiry.InquirySubmitId) {
        const { attachments, inquiry, inquirySubmit } =
            await this.inquiryApi.getInquirySubmitAttachments({
                inquirySubmitId: inquirySubmitId
            });
        const decryptedInquiry = await this.decryptInquiry(inquiry);
        const decryptedInquirySubmit = await this.decryptSubmit(decryptedInquiry, inquirySubmit);
        const groupMap = new Map<
            PmxApi.api.attachment.AttachmentGroup,
            PmxApi.api.inquiry.InquiryAttachment[]
        >();
        for (const attachment of attachments) {
            const list = groupMap.get(attachment.group);
            if (list) {
                list.push(attachment);
            } else {
                groupMap.set(attachment.group, [attachment]);
            }
        }
        return {
            attachmentsRes: await Promise.all(
                [...groupMap.values()].map((x) => this.decryptAttachmentGroup(decryptedInquiry, x))
            ),
            inquiry: decryptedInquiry,
            inquirySubmit: decryptedInquirySubmit
        };
    }

    async getAttachmentCore(attachmentId: PmxApi.api.attachment.AttachmentId) {
        const { attachment, inquiry } = await this.inquiryApi.getInquiryAttachment({
            attachmentId
        });
        const decryptedInquiry = await this.decryptInquiry(inquiry);
        const key = await this.getKeyForInquiry(decryptedInquiry, attachment.keyId);
        const publication = decryptedInquiry.raw.publications.find(
            (x) => x.id === attachment.inquiryPublicationId
        )!;
        const privData = await this.inquiryDataEncryptor.decrypt(publication.data, key);
        const meta = await this.attachmentMetaEncryptor.decrypt(attachment.meta, privData.priv);
        return {
            attachment: attachment,
            meta: meta
        };
    }

    async getAttachment(id: PmxApi.api.attachment.AttachmentId) {
        const { attachment, meta } = await this.getAttachmentCore(id);
        return this.convertAttachment(attachment, meta);
    }

    async readAttachment(
        attachment: PmxApi.api.inquiry.InquiryAttachment,
        meta: AttachmentMeta,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const [currentMeta] = (() => {
            if (thumb) {
                if (!attachment.thumb || !meta.thumb) {
                    throw new Error('Attachment has no thumbnail');
                }
                return [meta.thumb, attachment.thumb.size];
            }
            return [meta, attachment.size];
        })();
        return AttachmentUtils.downloadAttachment(
            currentMeta,
            (range) =>
                this.inquiryApi.getInquiryAttachmentData({
                    attachmentId: attachment.id,
                    range: range,
                    thumb: thumb
                }),
            options
        );
    }

    async decryptInquiry(inquiry: PmxApi.api.inquiry.Inquiry) {
        const currentKey = await this.keyProvider.getCurrentKey(inquiry);
        const data = await this.inquiryDataEncryptor.decrypt(inquiry.data, currentKey);
        return this.createInquiryObj(inquiry, data, currentKey);
    }

    private async decryptAttachmentGroup(
        inquiry: Inquiry,
        attachments: PmxApi.api.inquiry.InquiryAttachment[]
    ) {
        return Utils.tryPromise(() => this.decryptAttachmentGroupCore(inquiry, attachments));
    }

    private async decryptAttachmentGroupCore(
        inquiry: Inquiry,
        attachments: PmxApi.api.inquiry.InquiryAttachment[]
    ): Promise<PmxAttachmentEx> {
        const first = attachments.reduce((a, b) => (a.created < b.created ? a : b));
        const attachment = attachments.reduce((a, b) => (a.created > b.created ? a : b));
        const key = await this.getKeyForInquiry(inquiry, attachment.keyId);
        const publication = inquiry.raw.publications.find(
            (x) => x.id === attachment.inquiryPublicationId
        )!;
        const privData = await this.inquiryDataEncryptor.decrypt(publication.data, key);
        const meta = await this.attachmentMetaEncryptor.decrypt(attachment.meta, privData.priv);
        const tags = await this.tagService.getTags(
            'inquirySubmitAttachment',
            attachment.group,
            'all',
            attachment
        );
        return {
            inquiryId: attachment.inquiryId,
            group: attachment.group,
            tags: tags,
            versions: attachments.length,
            contributors: [...new Set(attachments.map((x) => x.author))],
            modificationDates: [...new Set(attachments.map((x) => x.created))],
            attachmentId: attachment.id,
            inquirySubmitId: attachment.inquirySubmitId,
            author: attachment.author,
            date: attachment.created,
            meta: meta,
            createdDate: first.created,
            creator: first.author,
            hasThumb: !!attachment.thumb
        };
    }

    private async createInquiryObj(
        inquiry: PmxApi.api.inquiry.Inquiry,
        data: InquiryData,
        key: EncKey | EncKey[]
    ) {
        const tags = await this.tagService.getTags('inquiry', inquiry.id, 'all', inquiry);
        const res: Inquiry = {
            raw: inquiry,
            data: data,
            keys: Array.isArray(key) ? key : [key],
            tags: tags
        };
        return res;
    }

    async decryptSubmit(inquiry: Inquiry, submit: PmxApi.api.inquiry.InquirySubmit) {
        const publication = inquiry.raw.publications.find((x) => x.id === submit.publicationId);
        if (!publication) {
            throw new Error('Cannot find corresponding publication');
        }
        const key = await this.getKeyForInquiry(inquiry, publication.keyId);
        const privData = await this.inquiryDataEncryptor.decrypt(publication.data, key);
        const data = await this.submitDataEncryptor.decrypt(privData.priv, submit.data);
        const adminData = await this.decryptSubmitAdminData(privData.priv, submit.adminData);
        const attachments = await Promise.all(
            submit.attachments.map(async (attachment) => {
                const meta = await this.attachmentMetaEncryptor.decrypt(
                    attachment.meta,
                    privData.priv
                );
                return {
                    hmac: meta.hmac,
                    name: meta.name,
                    size: meta.size,
                    mimetype: meta.mimetype,
                    hasThumb: !!meta.thumb
                };
            })
        );
        const res: InquirySubmit = {
            raw: submit,
            data: data,
            adminData: adminData,
            attachments: attachments
        };
        return res;
    }

    private canAccessSubmit(inquiry: Inquiry, submit: PmxApi.api.inquiry.InquirySubmit) {
        const publication = inquiry.raw.publications.find((x) => x.id === submit.publicationId);
        if (!publication) {
            throw new Error('Cannot find corresponding publication');
        }
        return this.keyProvider.hasKey(inquiry.raw.keys, publication.keyId);
    }

    private async getKeyForInquiry(inquiry: Inquiry, keyId: PmxApi.api.core.KeyId) {
        const alreadyDecrypted = inquiry.keys.find((x) => x.id === keyId);
        if (alreadyDecrypted) {
            return alreadyDecrypted;
        }
        const key = await this.keyProvider.getKey(inquiry.raw.keys, keyId);
        inquiry.keys.push(key);
        return key;
    }

    private preparedFileToAttachmentConverter(
        preparedFile: PreparedFile,
        hasThumb: boolean
    ): InquirySubmitAttachment {
        const res: InquirySubmitAttachment = {
            hmac: preparedFile.hmac.toString('base64'),
            name: preparedFile.file.name,
            size: preparedFile.file.size,
            mimetype: preparedFile.file.type as Mimetype,
            hasThumb: hasThumb
        };
        return res;
    }

    private async convertAttachment(
        attachment: PmxApi.api.inquiry.InquiryAttachment,
        meta: AttachmentMeta
    ) {
        const tags = await this.tagService.getTags(
            'inquirySubmitAttachment',
            attachment.group,
            'all',
            attachment
        );
        const res: PmxAttachment = {
            inquiryId: attachment.inquiryId,
            inquirySubmitId: attachment.inquirySubmitId,
            group: attachment.group,
            tags: tags,
            attachmentId: attachment.id,
            author: attachment.author,
            date: attachment.created,
            meta: meta,
            hasThumb: !!attachment.thumb
        };
        return res;
    }

    private async encryptSubmitAdminData(
        pub: PmxApi.api.core.EccPubKey,
        adminData: FormRowAdminData
    ): Promise<PmxApi.api.inquiry.InquirySubmitAdminData> {
        return this.submitAdminDataEncryptor.encrypt(pub, adminData);
    }

    private async decryptSubmitAdminData(
        pub: PmxApi.api.core.EccWif,
        encryptedAdminData: PmxApi.api.inquiry.InquirySubmitAdminData
    ): Promise<FormRowAdminData> {
        if (encryptedAdminData === '') {
            return {
                note: ''
            };
        }
        return await this.submitAdminDataEncryptor.decrypt(pub, encryptedAdminData);
    }

    getEmailInboxQuestions() {
        const questionModels: Question[] = [
            {
                id: 'from',
                title: 'From',
                type: 'short',
                required: false,
                answer: {
                    id: 'from',
                    input: '',
                    type: 'short'
                }
            },
            {
                id: 'to',
                title: 'To',
                type: 'short',
                required: false,
                answer: {
                    id: 'to',
                    input: '',
                    type: 'short'
                }
            },
            {
                id: 'subject',
                title: 'Subject',
                type: 'short',
                required: false,
                answer: {
                    id: 'subject',
                    input: '',
                    type: 'short'
                }
            },
            {
                id: 'text',
                title: 'Text',
                type: 'long',
                required: false,
                answer: {
                    id: 'text',
                    input: '',
                    type: 'long'
                }
            }
        ];
        return questionModels;
    }

    async sendInquirySubmitResponse(
        inquiryId: types.FormId,
        inquirySubmitId: types.FormRowId,
        protection: types.InquirySubmitResponseProtection,
        email: string,
        title: string,
        message: string,
        password: string | undefined,
        receiverPubKey: privmx.crypto.ecc.PublicKey | undefined
    ) {
        const adminData: types.InquirySubmitResponseAdminData = {
            email: email,
            title: title,
            message: message,
            password: password
        };
        const data: types.InquirySubmitResponseData = {
            title: title,
            message: message
        };
        const pubKey = (await this.getInquiryPublicView(inquiryId)).data.pub;
        const encDataRes = await this.encryptInquirySubmitResponseData(
            data,
            protection,
            password,
            receiverPubKey
        );
        adminData.userKeyHalf = encDataRes.userKeyHalf;
        const {
            inquirySubmitResponse: inquirySubmitResponseRaw,
            inquiry: inquiryRaw,
            inquirySubmit: inquirySubmitRaw
        } = await this.inquiryApi.sendInquirySubmitResponse({
            inquiryId: inquiryId,
            inquirySubmitId: inquirySubmitId,
            protection: protection,
            adminData: await this.encryptInquirySubmitResponseAdminData(pubKey, adminData),
            data: encDataRes.data,
            keyHalf: encDataRes.serverKeyHalf as PmxApi.api.inquiry.InquirySubmitResponseKeyHalf,
            sendEmail: this.getInquirySubmitResponseEmail(protection, adminData, encDataRes)
        });
        const inquiry = await this.decryptInquiry(inquiryRaw);
        const privKey = await this.getInquirySubmitResponsePrivKey(inquiry, inquirySubmitRaw);
        const inquirySubmitResponse = await this.decryptInquirySubmitResponseCore(
            inquirySubmitResponseRaw,
            privKey
        );
        return inquirySubmitResponse;
    }

    async getInquirySubmitResponses(inquirySubmitId: types.FormRowId) {
        const {
            inquirySubmitResponses: inquirySubmitResponsesRaw,
            inquiry: inquiryRaw,
            inquirySubmit: inquirySubmitRaw
        } = await this.inquiryApi.getInquirySubmitResponses({
            inquirySubmitId: inquirySubmitId
        });
        if (inquirySubmitResponsesRaw.length === 0) {
            return [];
        }
        const inquiry = await this.decryptInquiry(inquiryRaw);
        const privKey = await this.getInquirySubmitResponsePrivKey(inquiry, inquirySubmitRaw);
        const inquirySubmitResponses = await Promise.all(
            inquirySubmitResponsesRaw.map((x) => this.decryptInquirySubmitResponseCore(x, privKey))
        );
        return inquirySubmitResponses;
    }

    async getInquirySubmitResponse(inquirySubmitResponseId: types.InquirySubmitResponseId) {
        const {
            inquirySubmitResponse: inquirySubmitResponseRaw,
            inquiry: inquiryRaw,
            inquirySubmit: inquirySubmitRaw
        } = await this.inquiryApi.getInquirySubmitResponse({
            id: inquirySubmitResponseId
        });
        const inquiry = await this.decryptInquiry(inquiryRaw);
        const privKey = await this.getInquirySubmitResponsePrivKey(inquiry, inquirySubmitRaw);
        const inquirySubmitResponse = await this.decryptInquirySubmitResponseCore(
            inquirySubmitResponseRaw,
            privKey
        );
        return inquirySubmitResponse;
    }

    async getEncryptedPublicInquirySubmitResponse(
        inquirySubmitResponseId: types.InquirySubmitResponseId
    ) {
        const { publicInquirySubmitResponse: publicInquirySubmitResponseRaw } =
            await this.inquiryApi.getPublicInquirySubmitResponse({
                id: inquirySubmitResponseId
            });
        return publicInquirySubmitResponseRaw;
    }

    async getPublicInquirySubmitResponse(
        inquirySubmitResponseId: types.InquirySubmitResponseId,
        password: string | undefined,
        userKeyHalf: string | undefined,
        receiverPrivKey: privmx.crypto.ecc.PrivateKey | undefined
    ) {
        const publicInquirySubmitResponseRaw = await this.getEncryptedPublicInquirySubmitResponse(
            inquirySubmitResponseId
        );
        const publicInquirySubmitResponse = await this.decryptPublicInquirySubmitResponse(
            publicInquirySubmitResponseRaw,
            password,
            userKeyHalf,
            receiverPrivKey
        );
        return publicInquirySubmitResponse;
    }

    private async encryptInquirySubmitResponseAdminData(
        pub: PmxApi.api.core.EccPubKey,
        adminData: types.InquirySubmitResponseAdminData
    ): Promise<PmxApi.api.inquiry.InquirySubmitResponseAdminData> {
        return this.submitResponseAdminDataEncryptor.encrypt(pub, adminData);
    }

    private async decryptInquirySubmitResponseAdminData(
        priv: PmxApi.api.core.EccWif,
        encryptedAdminData: PmxApi.api.inquiry.InquirySubmitResponseAdminData
    ): Promise<types.InquirySubmitResponseAdminData> {
        if (encryptedAdminData === '') {
            return {
                email: '',
                title: '',
                message: ''
            };
        }
        return await this.submitResponseAdminDataEncryptor.decrypt(priv, encryptedAdminData);
    }

    async decryptInquirySubmitResponse(
        inquirySubmitResponseRaw: PmxApi.api.inquiry.InquirySubmitResponse
    ) {
        const { inquiry: inquiryRaw } = await this.inquiryApi.getInquiry({
            id: inquirySubmitResponseRaw.inquiryId
        });
        const { submit: inquirySubmitRaw } = await this.inquiryApi.getInquirySubmit({
            id: inquirySubmitResponseRaw.inquirySubmitId
        });
        const inquiry = await this.decryptInquiry(inquiryRaw);
        const privKey = await this.getInquirySubmitResponsePrivKey(inquiry, inquirySubmitRaw);
        const inquirySubmitResponse: types.InquirySubmitResponse = {
            id: inquirySubmitResponseRaw.id,
            adminData: await this.decryptInquirySubmitResponseAdminData(
                privKey,
                inquirySubmitResponseRaw.adminData
            ),
            protection: inquirySubmitResponseRaw.protection,
            created: inquirySubmitResponseRaw.created,
            creator: inquirySubmitResponseRaw.creator,
            inquiryId: inquirySubmitResponseRaw.inquiryId,
            inquirySubmitId: inquirySubmitResponseRaw.inquirySubmitId
        };
        return inquirySubmitResponse;
    }

    private async decryptInquirySubmitResponseCore(
        inquirySubmitResponseRaw: PmxApi.api.inquiry.InquirySubmitResponse,
        privKey: PmxApi.api.core.EccWif
    ) {
        const inquirySubmitResponse: types.InquirySubmitResponse = {
            id: inquirySubmitResponseRaw.id,
            adminData: await this.decryptInquirySubmitResponseAdminData(
                privKey,
                inquirySubmitResponseRaw.adminData
            ),
            protection: inquirySubmitResponseRaw.protection,
            created: inquirySubmitResponseRaw.created,
            creator: inquirySubmitResponseRaw.creator,
            inquiryId: inquirySubmitResponseRaw.inquiryId,
            inquirySubmitId: inquirySubmitResponseRaw.inquirySubmitId
        };
        return inquirySubmitResponse;
    }

    async decryptPublicInquirySubmitResponse(
        publicInquirySubmitResponseRaw: PmxApi.api.inquiry.PublicInquirySubmitResponse,
        password: string | undefined,
        userKeyHalf: string | undefined,
        receiverPrivKey: privmx.crypto.ecc.PrivateKey | undefined
    ) {
        let data: types.InquirySubmitResponseData | PmxApi.api.core.Base64;
        if (publicInquirySubmitResponseRaw.protection === 'none') {
            throw new Error('Response has been sent via email');
        } else if (publicInquirySubmitResponseRaw.protection === 'password') {
            if (!password) {
                throw new Error('Missing password');
            }
            const keyStr = publicInquirySubmitResponseRaw.keyHalf + userKeyHalf;
            const key = this.inquirySubmitResponseDataKeyFromString(keyStr);
            const finalKey = await this.mixInquirySubmitResponseDataKeyWithPassword(key, password);
            data = await this.submitResponseDataEncryptorWithEncKey.decrypt(
                publicInquirySubmitResponseRaw.data,
                { id: 'finalKey' as PmxApi.api.core.KeyId, key: finalKey }
            );
        } else if (publicInquirySubmitResponseRaw.protection === 'pubkey') {
            if (!receiverPrivKey) {
                throw new Error('Missing receiverPrivKey');
            }
            data = await this.submitResponseDataEncryptorWithPubKey.decrypt(
                receiverPrivKey,
                publicInquirySubmitResponseRaw.data
            );
        } else {
            throw new Error('Invalid protection');
        }
        const inquirySubmitResponse: types.PublicInquirySubmitResponse = {
            id: publicInquirySubmitResponseRaw.id,
            protection: publicInquirySubmitResponseRaw.protection,
            created: publicInquirySubmitResponseRaw.created,
            keyHalf: publicInquirySubmitResponseRaw.keyHalf,
            data: data
        };
        return inquirySubmitResponse;
    }

    private async getInquirySubmitResponsePrivKey(
        inquiry: Inquiry,
        submitRaw: PmxApi.api.inquiry.InquirySubmit
    ) {
        const publication = inquiry.raw.publications.find((x) => x.id === submitRaw.publicationId);
        if (!publication) {
            throw new Error('Cannot find corresponding publication');
        }
        const key = await this.getKeyForInquiry(inquiry, publication.keyId);
        const privData = await this.inquiryDataEncryptor.decrypt(publication.data, key);
        return privData.priv;
    }

    private async encryptInquirySubmitResponseData(
        data: types.InquirySubmitResponseData,
        protection: types.InquirySubmitResponseProtection,
        password: string | undefined,
        receiverPubKey: privmx.crypto.ecc.PublicKey | undefined
    ): Promise<EncryptedInquirySubmitResponseDataEx> {
        if (protection === 'password') {
            if (!password) {
                throw new Error('Missing password');
            }
            const key = this.generateInquirySubmitResponseDataKey();
            const finalKey = await this.mixInquirySubmitResponseDataKeyWithPassword(key, password);
            const encryptedData = await this.submitResponseDataEncryptorWithEncKey.encrypt(data, {
                id: 'finalKey' as PmxApi.api.core.KeyId,
                key: finalKey
            });
            const keyStr = this.inquirySubmitResponseDataKeyToString(key);
            const serverKeyHalf = keyStr.substring(0, Math.floor(keyStr.length / 2));
            const userKeyHalf = keyStr.substring(serverKeyHalf.length);
            return {
                data: encryptedData,
                serverKeyHalf: serverKeyHalf as PmxApi.api.core.Base64,
                userKeyHalf: userKeyHalf as PmxApi.api.core.Base64
            };
        } else if (protection === 'pubkey') {
            if (!receiverPubKey) {
                throw new Error('Missing receiverPubKey');
            }
            const encryptedData = await this.submitResponseDataEncryptorWithPubKey.encrypt(
                receiverPubKey,
                data
            );
            return {
                data: encryptedData,
                serverKeyHalf: '' as PmxApi.api.core.Base64,
                userKeyHalf: '' as PmxApi.api.core.Base64
            };
        }
        return {
            data: '' as PmxApi.api.inquiry.InquirySubmitResponseData,
            serverKeyHalf: '' as PmxApi.api.core.Base64,
            userKeyHalf: '' as PmxApi.api.core.Base64
        };
    }

    private generateInquirySubmitResponseDataKey() {
        const fileMetaEncKey = privmx.crypto.service.randomBytes(32);
        return fileMetaEncKey;
    }

    private inquirySubmitResponseDataKeyToString(key: privmx.Buffer.Buffer): string {
        return key.toString('base64');
    }

    private inquirySubmitResponseDataKeyFromString(keyStr: string) {
        return privmx.Buffer.Buffer.from(keyStr, 'base64');
    }

    private async mixInquirySubmitResponseDataKeyWithPassword(
        key: privmx.Buffer.Buffer,
        password: string
    ) {
        return privmx.crypto.service.pbkdf2(
            Buffer.from(password, 'utf8'),
            key,
            100000,
            32,
            'sha256'
        );
    }

    private getInquirySubmitResponseEmail(
        protection: types.InquirySubmitResponseProtection,
        adminData: types.InquirySubmitResponseAdminData,
        encDataRes: EncryptedInquirySubmitResponseDataEx
    ): types.InquirySubmitResponseEmail {
        let sendEmail: types.InquirySubmitResponseEmail = undefined;
        if (protection === 'none') {
            sendEmail = {
                email: adminData.email as PmxApi.api.core.Email,
                subject: adminData.title,
                body: adminData.message,
                isHtml: false
            };
        } else if (protection === 'password' || protection === 'pubkey') {
            let url: string;
            if (protection === 'password') {
                url = this.getInquirySubmitResponseUrl(encDataRes.userKeyHalf);
            } else if (protection === 'pubkey') {
                url = this.getInquirySubmitResponseUrl();
            } else {
                url = this.getInquirySubmitResponseUrl();
            }
            sendEmail = {
                email: adminData.email as PmxApi.api.core.Email,
                subject: getInquirySubmitResponseTitle(),
                body: getInquirySubmitResponseMessageWithLink(url),
                isHtml: false
            };
        }
        return sendEmail;
    }

    getInquirySubmitResponseUrl(
        userKeyHalf?: PmxApi.api.core.Base64,
        inquirySubmitResponseId?: types.InquirySubmitResponseId
    ) {
        let urlHashPart = '';
        if (userKeyHalf) {
            const keyStr = encodeURIComponent(userKeyHalf);
            urlHashPart = `#userKeyHalf=${keyStr}`;
        }
        const url = UrlBuilder.buildUrl(
            `/formSubmitResponse/${
                inquirySubmitResponseId ?? '{{INQUIRY_SUBMIT_RESPONSE_ID}}'
            }${urlHashPart}`
        );
        return url;
    }

    async generateInquiryShortLink(model: PmxApi.api.inquiry.GenerateInquiryShortLinkModel) {
        const { inquiry } = await this.inquiryApi.generateInquiryShortLink(model);
        return this.decryptInquiry(inquiry);
    }
}

export const getInquirySubmitResponseTitle = () => t('email.inquirySubmitResponse.title');

export const getInquirySubmitResponseMessageWithLink = (url: string) =>
    t('email.inquirySubmitResponse.messageWithUrl', { url });

export const getInquirySubmitThreadInfoTitle = () => t('email.inquirySubmitThreadInfo.title');

export const getInquirySubmitThreadInfoWithLink = (
    threadId: types.ThreadId,
    title: string,
    isAnonymousUser: boolean,
    anonEncryptedPrivKey?: string,
    anonPrivKeyPreliminaryEncryptionKey?: string
) =>
    t('email.inquirySubmitThreadInfo.messageWithUrl', {
        url: api.generateFormThreadLink(
            threadId,
            title,
            isAnonymousUser,
            anonEncryptedPrivKey,
            anonPrivKeyPreliminaryEncryptionKey
        )
    });

export const getInquirySubmitThreadNewMessageTitle = () =>
    t('email.inquirySubmitThreadNewMessage.title');

export const getInquirySubmitThreadNewMessageWithLink = (
    threadId: types.ThreadId,
    title: string,
    isAnonymousUser: boolean,
    anonEncryptedPrivKey?: string,
    anonPrivKeyPreliminaryEncryptionKey?: string
) =>
    t('email.inquirySubmitThreadNewMessage.messageWithUrl', {
        url: api.generateFormThreadLink(
            threadId,
            title,
            isAnonymousUser,
            anonEncryptedPrivKey,
            anonPrivKeyPreliminaryEncryptionKey
        )
    });
