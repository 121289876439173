import {
    Avatar as AvatarImg,
    AvatarProps,
    Button,
    Group,
    HoverCard,
    Paper,
    Stack,
    Title,
    Text,
    Box,
    Divider,
    rem,
    Badge
} from '@mantine/core';
import { useMemo } from 'react';
import { useAppSelector } from '../../store';
import { selectUser } from '../../store/UsersSlice';
import { Avatar, User, Username, UsernameOrContactOrPubId } from '../../types/Types';
import { Utils } from '../../utils/Utils';
import { selectCachedCompanies, selectCashedContact } from '../../store/DataCacheSlice';
import { CompanyBadge } from '../../mantineAtoms/CompanyBadge';
import { IconExternalLink } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FilterFactory, useFilterContext } from '../../mantineAtoms/FilterSelect';
import { TagList } from '../../mantineAtoms/TagList';
import { selectCurrentUserRole } from '../../store/CurrentUserSlice';
import { PrivateContactBadge } from '../../screens/ContactsScreen/PrivateContactBadge';

export interface UserAvatarProps {
    username?: Username;
    avatarSrc?: Avatar;
    className?: string;
    size?: number;
    isAnonymousMeetingUser?: boolean;
    showTooltip?: boolean;
    loading?: boolean;
}

export interface UserAvatarXProps {
    user?: UsernameOrContactOrPubId;
    avatarSrc?: Avatar;
    className?: string;
    size?: number;
    isAnonymousMeetingUser?: boolean;
    showTooltip?: boolean;
    loading?: boolean;
}

export function UserAvatar(props: UserAvatarProps & Omit<AvatarProps, 'src'>) {
    return (
        <UserAvatarX
            user={props.username ? { type: 'user', username: props.username } : undefined}
            avatarSrc={props.avatarSrc}
            className={props.className}
            size={props.size}
            isAnonymousMeetingUser={props.isAnonymousMeetingUser}
            {...props}
        />
    );
}

export function UserAvatarX({
    avatarSrc,
    className,
    isAnonymousMeetingUser,
    size,
    user,
    color,
    showTooltip = true,
    loading,
    ...props
}: UserAvatarXProps & AvatarProps) {
    const { t } = useTranslation();
    const userAccount = useAppSelector(
        selectUser(user && user.type === 'user' ? user.username : ('' as Username))
    );
    const contactAccount = useAppSelector((state) =>
        selectCashedContact(
            state,
            user?.type === 'user'
                ? { username: user.username }
                : user?.type === 'contact'
                ? { id: user.contactId }
                : {}
        )
    );

    const currentUserRole = useAppSelector(selectCurrentUserRole);

    const userId = contactAccount?.id;

    const companies = useAppSelector(selectCachedCompanies);
    const userCompany = useMemo(
        () => companies.find((x) => x.id === contactAccount?.companyId),
        [contactAccount, companies]
    );

    const avatarSize = typeof size === 'number' ? size : 'sm';
    const colorHash = useMemo(
        () =>
            userAccount
                ? Utils.getColorHash(userAccount.username)
                : contactAccount
                ? Utils.getColorHash(contactAccount.id)
                : user?.type === 'anonymousUser'
                ? Utils.getColorHash(user.pub)
                : Utils.getColorHash(user?.type === 'user' ? user.username : user?.contactId),
        [userAccount, contactAccount, user]
    );
    const { firstLetter } = getUserInfo(userAccount, user);
    const avatar =
        avatarSrc !== undefined ? avatarSrc : userAccount ? userAccount.avatar : undefined;

    const { dispatch } = useFilterContext();

    if (loading)
        return (
            <AvatarImg
                className={className}
                sx={{ opacity: isAnonymousMeetingUser ? 0.5 : 1 }}
                tt="capitalize"
                size={avatarSize}
                src={null}
                radius={999}
                {...props}
            />
        );

    const name = (() => {
        if (userAccount) {
            if (userAccount.name === userAccount.email) {
                return userAccount.username;
            }
            return userAccount.name ? userAccount.name : userAccount.username;
        }
        if (contactAccount) {
            if ((contactAccount.name as string) === contactAccount.email) {
                return contactAccount.username;
            }
            return contactAccount.name ? contactAccount.name : contactAccount.username;
        }
    })();

    const showDetail = currentUserRole === 'staff' && userId;

    return (
        <HoverCard
            disabled={!showTooltip}
            zIndex={200}
            openDelay={200}
            position="bottom-start"
            withinPortal>
            <HoverCard.Target>
                <AvatarImg
                    className={className}
                    sx={{ opacity: isAnonymousMeetingUser ? 0.5 : 1 }}
                    tt="capitalize"
                    size={avatarSize}
                    src={avatar}
                    radius={999}
                    color={color ? color : colorHash}
                    {...props}>
                    {userAccount ? firstLetter : undefined}
                </AvatarImg>
            </HoverCard.Target>
            <HoverCard.Dropdown>
                <Paper p="xs" miw={200}>
                    <Group align="flex-start">
                        <AvatarImg
                            className={className}
                            sx={{ opacity: isAnonymousMeetingUser ? 0.5 : 1 }}
                            tt="capitalize"
                            size={54}
                            src={avatar}
                            radius={900}
                            color={color ? color : colorHash}
                            {...props}>
                            {userAccount ? firstLetter : undefined}
                        </AvatarImg>
                        <Stack spacing="xs">
                            <Box>
                                <Title order={4}>
                                    <Group spacing="sm">
                                        <Text>{name}</Text>
                                        {contactAccount?.isPrivate && <PrivateContactBadge />}
                                    </Group>
                                </Title>
                                <Text>{userAccount?.email || contactAccount?.email}</Text>
                            </Box>
                            {contactAccount && (
                                <Box maw={150}>
                                    <TagList tags={contactAccount.tags} />
                                </Box>
                            )}
                            {userAccount?.role === 'staff' && (
                                <Badge>{t('components.userAvatar.staff')}</Badge>
                            )}
                        </Stack>
                    </Group>

                    {showDetail && (
                        <>
                            <Divider my="sm" />

                            <Text size="xs">{t('entityProps.company')}</Text>
                            <Text mb="sm">
                                {userCompany ? (
                                    <CompanyBadge
                                        tooltip={t('components.userAvatar.filterByCompany')}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            dispatch({
                                                type: 'add',
                                                filter: FilterFactory.company(userCompany)
                                            });
                                        }}>
                                        {userCompany?.name}
                                    </CompanyBadge>
                                ) : (
                                    <Text color="dimmed">---</Text>
                                )}
                            </Text>

                            <Text size="xs">{t('entityProps.phone')}</Text>
                            <Text mb="md" size="xs">
                                {contactAccount?.mobilePhone || '---'}
                            </Text>

                            {userId && (
                                <Button
                                    onClick={(e) => e.stopPropagation()}
                                    component={Link}
                                    to={`/contacts/${userId}`}
                                    rightIcon={<IconExternalLink size={rem(12)} />}
                                    size="xs"
                                    variant="outline">
                                    {t('action.moreInfo')}
                                </Button>
                            )}
                        </>
                    )}
                </Paper>
            </HoverCard.Dropdown>
        </HoverCard>
    );
}

export function getUserInfo(user: User | undefined, raw: UsernameOrContactOrPubId | undefined) {
    if (user) {
        if (user.name) {
            if (user.userType === 'normal') {
                return { firstLetter: user.name[0], title: `${user.name} (${user.username})` };
            } else {
                let title: string = user.name;
                if (user.name !== user.email) {
                    title += ` (${user.email})`;
                }
                return { firstLetter: user.name[0], title: title };
            }
        }
        return { firstLetter: user.username[0], title: user.username };
    }
    if (raw) {
        if (raw.type === 'user') {
            return { firstLetter: raw.username[0], title: raw.username };
        }
    }

    return { firstLetter: '', title: '' };
}

export function hexToRgb(hex: string) {
    const r = parseInt(hex[1] + hex[2], 16);
    const g = parseInt(hex[3] + hex[4], 16);
    const b = parseInt(hex[5] + hex[6], 16);

    return [r, g, b].join(',');
}

export default UserAvatar;
