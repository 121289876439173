import { ActionIcon, Button, Grid, Group, Stack, Tabs, Text, Anchor } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import React, { useEffect, useRef } from 'react';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { newForm as newFormDefaults } from '../../../api/defaultValues';
import {
    FormContextProvider,
    isNewFormId,
    useFormCreatorContext
} from '../FormCreator/components/FormState/useFormState';
import { t } from 'i18next';
import { formApi } from '../../../api/Api';
import { emptyFormModel } from '../../../data/formTemplates';
import { useAppSelector } from '../../../store';
import { selectCurrentUser } from '../../../store/CurrentUserSlice';
import { FormModel2, FormId, UsernameOpt, EditFormModelOptions } from '../../../types/Types';
import { useUserInteraction } from '../../../hooks/useUserInteraction';
import { LoadingMessageOverlay } from '../../../mantineAtoms/LoadingMessageOverlay';
import { notifications } from '@mantine/notifications';
import { useStateView } from '../../../atoms/State';
import { Validator } from '../../../utils/Validator';

type I18nPrefixKey = 'title' | 'answer' | 'form-name';

function getI18nPrefixTranslation(prefixKey: I18nPrefixKey) {
    if (prefixKey === 'answer') {
        return t('screen.formCreator.errors.prefixes.answer');
    }
    if (prefixKey === 'title') {
        return t('screen.formCreator.errors.prefixes.title');
    }
    if (prefixKey === 'form-name') {
        return t('screen.formCreator.errors.prefixes.form-name');
    }
}

function createI18nSafeDefaultErrorMessage(prefixKey: I18nPrefixKey) {
    const invalidCharsString = Object.keys(Validator.getSafeDefaultCharsMap()).join(', ');
    const prefixTranslation = getI18nPrefixTranslation(prefixKey);
    return `${prefixTranslation} ${t('screen.formCreator.errors.cannotIncludeChars', {
        CHARS: invalidCharsString
    })}`;
}

export function FormScreen() {
    const navigate = useNavigate();
    const { formId } = useParams();

    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current === true) return;
        if (isNewFormId(formId)) {
            navigate('questions');
        } else {
            navigate('responses');
        }
        isMounted.current = true;

        return () => {};
    }, [formId, navigate]);

    return (
        <FormContextProvider formId={formId}>
            <FormScreenView />
        </FormContextProvider>
    );
}

export function useFormCreatorSave() {
    const navigate = useNavigate();
    const { formId } = useParams();

    const mode = isNewFormId(formId) ? 'creating' : 'editing';
    const {
        creatorState: state,
        formName: [formName],
        formAutoResponse: [formAutoResponse],
        formModel,
        formNameError,
        setErrors
    } = useFormCreatorContext();
    const currentUser = useAppSelector(selectCurrentUser);

    const setFormNameError = formNameError[1];

    const { revalidate } = useStateView();

    const { startInteraction: startEditInteraction, isProcessing: isEditing } = useUserInteraction({
        successTitle: 'Form edited',
        successMessage: (
            <Anchor component={Link} relative="path" to={'../share'}>
                Go to Share Tab
            </Anchor>
        ),
        async action(formOptions: Partial<EditFormModelOptions>) {
            const users = formModel.users.map((x) => ({ type: 'user' as const, username: x }));
            const autoResponseModel: FormModel2['autoResponseData'] =
                formModel.autoResponseData || emptyFormModel.autoResponseData;
            autoResponseModel.emailFieldId = formAutoResponse || '';
            const currentOptions: EditFormModelOptions = {
                id: formModel.id || ('' as FormId),
                tags: [],
                status: 'published',
                newName: formModel.name,
                newQuestions: state,
                users: users,
                managers: formModel.managers,
                updateKeysWhenAddingUsers: true,
                autoResponseData: autoResponseModel,
                captchaEnabled: formModel.captchaEnabled
            };

            const mergedOptions: EditFormModelOptions = { ...currentOptions, ...formOptions };

            await formApi.editFormModel(mergedOptions);
            revalidate();
        }
    });

    async function handleEditForm(formOptions?: Partial<EditFormModelOptions>) {
        let isValid: boolean = true;
        if (!state.length) {
            isValid = false;
            notifications.show({
                title: t('form.error.empty'),
                message: (
                    <Text>
                        Add at least one question to save the Form.
                        <br />
                        <Anchor component={Link} relative="path" to={'../questions'}>
                            Go to Questions tab
                        </Anchor>
                    </Text>
                ),
                color: 'red'
            });
        }
        setErrors([]);
        setFormNameError(null);
        for (let i = 0; i < state.length; i++) {
            const question = state[i];
            if (Validator.getErrors(question.title)) {
                setErrors((prevErr) => [
                    ...prevErr,
                    {
                        answerIndex: -1,
                        message: '',
                        questionIndex: i,
                        type: 'title',
                        titleError: createI18nSafeDefaultErrorMessage('title')
                    }
                ]);
                isValid = false;
            }
            if (question.type !== 'block' && Array.isArray(question.answer)) {
                for (let j = 0; j < question.answer.length; j++) {
                    const answer = question.answer[j];
                    if (Validator.getErrors(answer.input)) {
                        setErrors((prev) => [
                            ...prev,
                            {
                                answerIndex: j,
                                message: createI18nSafeDefaultErrorMessage('answer'),
                                questionIndex: i,
                                type: 'question'
                            }
                        ]);
                        isValid = false;
                    }
                }
            }
        }

        if (!formName) {
            isValid = false;
            notifications.show({
                title: t('form.error.titleEmpty'),
                message: 'Add a title to save the Form.',
                color: 'red'
            });
        }
        if (formName && Validator.getErrors(formName)) {
            isValid = false;
            setFormNameError(createI18nSafeDefaultErrorMessage('form-name'));
        }

        if (isValid && formName) {
            setErrors([]);
            setFormNameError(null);
            startEditInteraction({ newName: formName, ...formOptions });
        } else {
            notifications.show({
                title: 'Error while editing Form questions',
                message: (
                    <>
                        <Anchor component={Link} relative="path" to={'../questions'}>
                            Go to questions Tab
                        </Anchor>{' '}
                        or try again.
                    </>
                )
            });
        }
        return isValid;
    }

    const { startInteraction: startCreateInteraction, isProcessing: isCreating } =
        useUserInteraction({
            successTitle: 'New Form added',
            successMessage: (
                <Anchor component={Link} relative="path" to={'../share'}>
                    Go to Share Tab
                </Anchor>
            ),
            async action(name: string) {
                const users = [{ type: 'user', username: currentUser.username! } as UsernameOpt];
                const managers = [currentUser.username!];

                const autoResponseModel: FormModel2['autoResponseData'] =
                    formModel.autoResponseData || emptyFormModel.autoResponseData;
                autoResponseModel.emailFieldId = formAutoResponse || '';
                const newForm = await formApi.createFormModel(
                    name,
                    { type: 'questionModels', questionModels: state },
                    'published',
                    [],
                    users,
                    managers,
                    'inquiry',
                    autoResponseModel,
                    newFormDefaults.captchaEnabled,
                    true
                );
                navigate(`/forms/${newForm.id}/questions`);
            }
        });

    async function handleCreateNewForm() {
        let isValid: boolean = true;
        if (!state.length) {
            isValid = false;
            notifications.show({
                title: t('form.error.empty'),
                message: (
                    <Text>
                        Add at least one question to save the Form.
                        <br />
                        <Anchor component={Link} relative="path" to={'../questions'}>
                            Go to Questions tab
                        </Anchor>
                    </Text>
                ),
                color: 'red'
            });
            return;
        }
        if (!formName) {
            isValid = false;
            notifications.show({
                title: t('form.error.titleEmpty'),
                message: 'Add a title to save the Form.',
                color: 'red'
            });
            return;
        }
        if (Validator.getErrors(formName)) {
            isValid = false;
            setFormNameError(createI18nSafeDefaultErrorMessage('form-name'));
            return;
        }
        if (isValid) {
            startCreateInteraction(formName);
        } else {
            notifications.show({
                title: 'Error while saving Form questions',
                message: (
                    <>
                        <Anchor component={Link} relative="path" to={'../share'}>
                            Go to questions Tab
                        </Anchor>{' '}
                        or try again.
                    </>
                )
            });
        }
        return isValid;
    }

    async function handleSubmit() {
        if (mode === 'creating') {
            return await handleCreateNewForm();
        } else {
            return await handleEditForm();
        }
    }

    async function handleSubmitWithParams(formOptions?: Partial<EditFormModelOptions>) {
        if (mode === 'creating') {
            return await handleCreateNewForm();
        } else {
            return await handleEditForm(formOptions);
        }
    }

    const processingType = isEditing ? 'editing' : isCreating ? 'creating' : null;

    return {
        handleCreateNewForm,
        handleEditForm,
        handleSubmit,
        isProcessing: processingType,
        handleSubmitWithParams
    };
}

function FormScreenView() {
    const location = useLocation();
    const currentLocation = location.pathname.split('/');
    const navigate = useNavigate();
    const { formId } = useParams();
    const currentUser = useAppSelector(selectCurrentUser);

    const mode = isNewFormId(formId) ? 'creating' : 'editing';
    const {
        formName: [formName],
        formModel
    } = useFormCreatorContext();

    const { handleSubmit, isProcessing } = useFormCreatorSave();

    const loadingMessage =
        isProcessing && isProcessing === 'editing' ? `Editing "${formName}"` : 'Creating new Form';

    const canManangeForm =
        mode === 'creating' ||
        (currentUser.username ? formModel.managers.includes(currentUser.username) : false);

    return (
        <Stack
            pos={'relative'}
            h="100%"
            sx={(theme) => ({
                backgroundColor:
                    theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
            })}>
            <LoadingMessageOverlay visible={isProcessing !== null} message={loadingMessage} />
            <Grid p="lg" sx={{ justifyContent: 'space-between' }}>
                <Grid.Col lg={3} span={1} miw={150}>
                    <Group noWrap spacing={'sm'}>
                        <ActionIcon component={Link} to="/forms">
                            <IconArrowLeft />
                        </ActionIcon>
                        <Text fw="normal" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {formName}
                        </Text>
                    </Group>
                </Grid.Col>
                <Grid.Col span={'content'}>
                    <Tabs
                        mx="auto"
                        value={currentLocation.at(-1)}
                        onTabChange={(value) => navigate(`${value}`)}>
                        <Tabs.List position="center" w="max-content" mx="auto">
                            {mode === 'editing' && <Tabs.Tab value="responses">Responses</Tabs.Tab>}
                            {canManangeForm && <Tabs.Tab value="questions">Questions</Tabs.Tab>}
                            {mode === 'editing' && <Tabs.Tab value="share">Share</Tabs.Tab>}
                            {mode === 'editing' && <Tabs.Tab value="embed">Embed</Tabs.Tab>}
                            {canManangeForm && <Tabs.Tab value="settings">Manage</Tabs.Tab>}
                        </Tabs.List>
                    </Tabs>
                </Grid.Col>
                <Grid.Col lg={3} span={1} miw={150}>
                    {!['settings', 'responses', 'share', 'embed'].includes(
                        currentLocation.at(-1) ?? ''
                    ) && (
                        <Group noWrap position="right" spacing={'sm'}>
                            <Button onClick={handleSubmit}>Save</Button>
                        </Group>
                    )}
                </Grid.Col>
            </Grid>
            <Outlet />
        </Stack>
    );
}
