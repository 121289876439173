import {
    createStyles,
    Selectors,
    DefaultProps,
    Group,
    Stack,
    Text,
    Badge,
    Box,
    Tooltip,
    getStylesRef,
    Menu,
    ThemeIcon
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { IconArchive, IconDotsVertical, IconEdit, IconMailOpened } from '@tabler/icons-react';
import { FC, memo, MouseEventHandler, NamedExoticComponent, useEffect, useRef } from 'react';
import { UsersList } from '../../screens/ChatScreen/components/UserList';
import { Tag, UsernameOrContactId } from '../../types/Types';
import HoverContainer from '../HoverContainer';
import { useUnreadContentStyles } from '../UnreadContent';
import { useScrollTo } from '../ScreenScrollContainer';
import TooltipIcon from '../TooltipIcon';
import { IconLoader } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

const useListElementStyles = createStyles((theme) => ({
    root: {
        cursor: 'pointer',
        '&:hover': {
            [`& .${getStylesRef('action-icons')}`]: {
                visibility: 'visible'
            }
        }
    },
    content: {
        minWidth: 0,
        display: 'flex',
        gap: theme.spacing.xs,
        flexDirection: 'column'
    },
    usernames: {
        display: 'none',
        minWidth: 0,
        [`@media (min-width: ${theme.breakpoints.xl})`]: {
            display: 'flex'
        }
    },
    title: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontWeight: 600,
        minWidth: 0,
        whiteSpace: 'break-spaces'
    },
    subTitle: {},
    info: {
        flexShrink: 0,
        minWidth: 0,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[8],
        fontSize: theme.fontSizes.sm
    },
    details: {
        color: theme.colors.gray[6],
        minWidth: 0
    },
    tags: {
        minWidth: 0,
        overflow: 'hidden'
    },
    icons: {
        ref: getStylesRef('action-icons'),
        minWidth: 58,
        visibility: 'hidden',
        fontSize: `calc(${theme.fontSizes.xs} / 2)`
    }
}));

type ListElementStylesNames = Selectors<typeof useListElementStyles> | ('link' | 'linkActive');

interface Props extends DefaultProps<ListElementStylesNames> {
    actionIcons?: React.ReactNode;
    title: React.ReactNode;
    usernames?: UsernameOrContactId[];
    subTitle?: React.ReactNode;
    tags?: Tag[];
    info?: React.ReactNode;
    isUnread?: boolean;
    onClick?: Function;
    active?: boolean;
    hovered?: boolean;
}

export const _ListElement: FC<Props> = function ({
    classNames,
    styles,
    unstyled,
    actionIcons,
    title,
    usernames,
    subTitle,
    tags,
    info,
    onClick,
    active,
    isUnread,
    ...props
}: Props) {
    const { classes, cx } = useListElementStyles(undefined, {
        name: 'PrivmxListElement',
        classNames,
        styles,
        unstyled
    });

    const { classes: unread } = useUnreadContentStyles({ isUnread: isUnread ?? false });
    const { ref, width } = useElementSize<HTMLDivElement>();
    const wrapper = useRef<HTMLDivElement>(null);
    const scrollTo = useScrollTo();

    useEffect(() => {
        if (!scrollTo) return;

        if (active) {
            scrollTo({ top: (wrapper.current?.offsetTop || 200) - 200 });
        }
        if (props.hovered) {
            scrollTo({ top: (wrapper.current?.offsetTop || 200) - 200 });
        }
    }, [props.hovered, active, scrollTo]);

    return (
        <div ref={wrapper}>
            <HoverContainer
                active={active}
                onClick={(e) => onClick?.(e)}
                {...props}
                className={classes.root}
                classNames={{ link: classNames?.link, linkActive: classNames?.linkActive }}>
                <Group
                    p="sm"
                    miw={0}
                    ref={ref}
                    position="apart"
                    align={'stretch'}
                    noWrap
                    spacing={0}>
                    <div className={classes.content}>
                        <Box miw={0} sx={{ flexShrink: 1, display: 'inline-block' }}>
                            <Text
                                className={cx(classes.title, unread.content)}
                                truncate
                                size="sm"
                                lineClamp={2}>
                                {title}
                            </Text>
                            {subTitle && (
                                <Text size={'xs'} className={classes.subTitle}>
                                    {subTitle}
                                </Text>
                            )}
                        </Box>
                        <TagList maxWidth={width} tags={tags} />
                        {usernames?.length ? (
                            <UsersList className={classes.usernames} usernames={usernames} />
                        ) : null}
                    </div>
                    <Stack justify="space-between" miw={0} sx={{ alignSelf: 'stretch' }}>
                        <Box>
                            {actionIcons && (
                                <Group
                                    ref={ref}
                                    spacing={0}
                                    noWrap
                                    position="right"
                                    className={classes.icons}>
                                    <>{actionIcons}</>
                                </Group>
                            )}
                        </Box>
                        {info && <Box className={classes.info}>{info}</Box>}
                    </Stack>
                </Group>
            </HoverContainer>
        </div>
    );
};

interface ListElementIconsProps {
    editFn: MouseEventHandler;
    archiveFn?: MouseEventHandler;
    pinFn?: MouseEventHandler;
    markAsReadFn?: MouseEventHandler;

    procsesingArchive?: boolean;
    procsesingPin?: boolean;

    isPinned: boolean;
    isArchived: boolean;
}

export function ListElementIcons({
    archiveFn,
    editFn,
    pinFn,
    procsesingArchive,
    markAsReadFn,
    procsesingPin,
    isPinned,
    isArchived
}: ListElementIconsProps) {
    const { t } = useTranslation();

    return (
        <>
            {(archiveFn || pinFn) && (
                <Menu
                    styles={(theme) => ({ item: { padding: theme.spacing.xs } })}
                    transitionProps={{ transition: 'pop' }}
                    zIndex={30}
                    withArrow
                    position="bottom-end">
                    <Menu.Target>
                        <div>
                            <TooltipIcon
                                tooltipProps={{ openDelay: 500 }}
                                className="dots"
                                type="action"
                                info={t('action.more')}
                                size={22}>
                                <IconDotsVertical onClick={(e) => e.preventDefault()} />
                            </TooltipIcon>
                        </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item
                            p={4}
                            onClick={(e: any) => {
                                editFn(e);
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            icon={
                                <ThemeIcon variant="outline" sx={{ border: 0 }} color="gray.6">
                                    <IconEdit size={16} />
                                </ThemeIcon>
                            }>
                            <Text color="dimmed">{t('action.edit')}</Text>
                        </Menu.Item>
                        {markAsReadFn && (
                            <Menu.Item
                                p={4}
                                onClick={(e: any) => {
                                    markAsReadFn(e);
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                icon={
                                    <ThemeIcon variant="outline" sx={{ border: 0 }} color="gray.6">
                                        <IconMailOpened size={16} />
                                    </ThemeIcon>
                                }>
                                <Text color="dimmed">{t('action.markAsRead')}</Text>
                            </Menu.Item>
                        )}
                        {archiveFn && (
                            <Menu.Item
                                p={4}
                                onClick={archiveFn}
                                icon={
                                    <ThemeIcon variant="outline" sx={{ border: 0 }} color="gray.6">
                                        {procsesingArchive ? (
                                            <IconLoader size={16} />
                                        ) : (
                                            <IconArchive size={16} />
                                        )}
                                    </ThemeIcon>
                                }>
                                <Text color="dimmed">
                                    {isArchived ? t('action.unarchive') : t('action.archive')}
                                </Text>
                            </Menu.Item>
                        )}
                    </Menu.Dropdown>
                </Menu>
            )}
        </>
    );
}

const useTagsStyle = createStyles((theme) => ({
    details: {
        color: theme.colors.gray[6],
        minWidth: 0
    },
    tags: {
        minWidth: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
}));

export function TagList({ tags, maxWidth }: { maxWidth: number; tags: Tag[] | undefined }) {
    const { classes } = useTagsStyle();

    if (!tags?.length) return <></>;

    return (
        <Box maw={maxWidth - 120} className={classes.tags}>
            {tags.map((x) => (
                <TagElement key={x}>{x}</TagElement>
            ))}
        </Box>
    );
}

const TagElement = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <Tooltip label={children}>
                <Badge mr="xs" miw={0} size="xs" styles={{ inner: { overflow: 'visible' } }}>
                    {children}
                </Badge>
            </Tooltip>
        </>
    );
};

const MemoElement = memo(_ListElement) as NamedExoticComponent<Props> & {
    Icons: typeof ListElementIcons;
};
MemoElement.Icons = ListElementIcons;

export const ListElement = MemoElement;
