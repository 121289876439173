import { Card, Flex, createStyles } from '@mantine/core';
import * as types from '../../../types/Types';
import { FileActionsButton } from '../common/FileActionsButton';
import { FileImage } from './FileImage';
import { useCallback } from 'react';

const useFileTileStyles = createStyles((theme) => {
    return {
        tile: {
            cursor: 'pointer',
            borderRadius: theme.radius.md,
            background:
                theme.colorScheme === 'dark'
                    ? theme.colors.dark[6]
                    : theme.fn.rgba(theme.colors.gray[1], 0.5),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            img: {
                borderRadius: `${theme.radius.md} ${theme.radius.md} 0 0 `
            }
        },
        fileName: {
            flex: '1 1 auto',
            maxWidth: 'calc(100% - 30px)',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            paddingRight: 10,
            marginTop: 10
        }
    };
});

export interface FileTileProps {
    file: types.AttachmentEx;
    width: number;
    onShowFilePreview: (fileId: types.AttachmentId) => void;
}

export function FileTile(props: FileTileProps) {
    const { classes } = useFileTileStyles();

    const onShowFilePreview = props.onShowFilePreview;
    const handleTileClick = useCallback(() => {
        onShowFilePreview(props.file.id);
    }, [props.file.id, onShowFilePreview]);

    return (
        <Card
            p="sm"
            className={classes.tile}
            style={{ width: props.width }}
            onClick={handleTileClick}>
            <FileImage file={props.file} />
            <Flex style={{ alignSelf: 'stretch', alignItems: 'center', padding: '10px' }}>
                <div className={classes.fileName}>{props.file.name}</div>
                <FileActionsButton file={props.file} onShowFilePreview={props.onShowFilePreview} />
            </Flex>
        </Card>
    );
}
