import { Overlay, Center, Paper, Group, Button, Transition, Text } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { ConfirmModalState } from './types';

export function ConfirmDialog({
    controller,
    onAcceptLabel,
    onCancelLabel
}: {
    controller: ConfirmModalState;
    onCancelLabel?: string;
    onAcceptLabel?: string;
}) {
    const ref = useClickOutside(() => {
        controller.onCancel.current?.();
    });

    return (
        <Transition mounted={controller.open} transition={'slide-up'}>
            {(styles) => (
                <Overlay opacity={0} h="100%" pos="absolute">
                    <Center h="100%">
                        <Paper ref={ref} shadow="sm" p="md" style={{ ...styles }}>
                            <Text align="center" mb="sm">
                                You have unsaved data
                            </Text>
                            <Group>
                                <Button
                                    variant="light"
                                    onClick={() => controller.onCancel.current?.()}>
                                    {onCancelLabel || 'Keep editing'}
                                </Button>
                                <Button
                                    bg="brand.5"
                                    sx={(theme) => ({
                                        '&:hover': {
                                            backgroundColor: theme.colors['brand'][4]
                                        }
                                    })}
                                    onClick={() => controller.onAccept.current?.()}>
                                    {onAcceptLabel || 'Discard changes'}
                                </Button>
                            </Group>
                        </Paper>
                    </Center>
                </Overlay>
            )}
        </Transition>
    );
}
