import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faFile,
    faUser,
    faVideo,
    faCircleNotch,
    faUpRightAndDownLeftFromCenter,
    faArrowsRotate,
    faPencil,
    faAlignJustify,
    faStar,
    faPaperPlane,
    faPaperclip,
    faFileWord,
    faFileExcel,
    faFileAudio,
    faFileImage,
    faFileVideo,
    faFilePdf,
    faFileText,
    faPlus,
    faCircle,
    faFilter,
    faSquare,
    faSave,
    faCancel,
    faTrash,
    faTimes,
    faUpload,
    faSortUp,
    faSortDown,
    faThumbTack,
    faBoxArchive,
    faFolder,
    faLock,
    faLockOpen,
    faTag,
    faFaceSmile,
    faListSquares,
    faSquarePollHorizontal,
    faAnglesLeft,
    faAnglesRight,
    faEllipsisVertical,
    faTable,
    faMicrophone,
    faMicrophoneSlash,
    faVolumeUp,
    faVolumeOff,
    faDesktop,
    faThLarge,
    faPhoneSlash,
    faTriangleExclamation,
    faInfoCircle,
    faClockRotateLeft,
    faEllipsis,
    faChevronRight,
    faChevronLeft,
    faDownload,
    faDownLeftAndUpRightToCenter,
    faBold,
    faStrikethrough,
    faItalic,
    faChevronDown,
    faRightFromBracket,
    faSliders,
    faSearch,
    faUsers,
    faCheck,
    faCopy,
    faKey,
    faGlasses
} from '@fortawesome/free-solid-svg-icons';

import {
    faMessage,
    faClock,
    faFile as faRFile,
    faBuilding as faRBuilding,
    faMap as faRMap,
    faUser as faRUser,
    faClipboard as faRClipboard,
    faCircle as faRCircle,
    faFolder as faRFolder,
    faCircleQuestion as faRCircleQuestion,
    faPenToSquare as faRPenToSquare
} from '@fortawesome/free-regular-svg-icons';

import React from 'react';

export type IconDef = IconProp;
export const iconHome = faRMap as IconDef;
export const iconChat = faMessage as IconDef;
export const iconFile = faRFolder as IconDef;
export const iconEmptyFile = faRFile as IconDef;
export const iconCompany = faRBuilding as IconDef;
export const iconClient = faRUser as IconDef;
export const iconForm = faRClipboard as IconDef;
export const iconMeeting = faRCircle as IconDef;
export const iconVideo = faVideo;
export const iconSetting = faSliders;
export const iconLogout = faRightFromBracket;
export const iconQuery = faRCircleQuestion as IconDef;
export const iconLoading = faCircleNotch;
export const iconRefresh = faArrowsRotate;
export const iconGoTo = faUpRightAndDownLeftFromCenter;
export const iconAdd = faPlus;
export const iconEdit = faPencil;
export const iconCount = faAlignJustify;
export const iconFavorite = faStar;
export const iconSend = faPaperPlane;
export const iconAttach = faPaperclip;
export const iconCircle = faCircle;
export const iconSquare = faSquare;
export const iconFilter = faFilter;
export const iconSave = faSave;
export const iconCancel = faCancel;
export const iconDraft = faRPenToSquare as IconDef;
export const iconTrash = faTrash;
export const iconClose = faTimes;
export const iconArrowUp = faSortUp;
export const iconArrowDown = faSortDown;
export const iconUpload = faUpload;
export const iconPin = faThumbTack;
export const iconArchive = faBoxArchive;
export const iconGroup = faFolder;
export const iconFolder = faFolder;
export const iconProfile = faUser;
export const iconLock = faLock;
export const iconLockOpen = faLockOpen;
export const iconSmile = faFaceSmile as IconDef;
export const iconTag = faTag;
export const iconMessages = faMessage as IconDef;
export const iconTime = faClock as IconDef;
export const iconList = faListSquares;
export const iconFormEntry = faSquarePollHorizontal;
export const iconSlideLeft = faAnglesLeft;
export const iconSlideRight = faAnglesRight;
export const iconResizeHorizontal = faEllipsisVertical;
export const iconResizeVertical = faEllipsis;
export const iconTable = faTable;
export const iconMicrophone = faMicrophone;
export const iconMicrophoneSlash = faMicrophoneSlash;
export const iconVolumeUp = faVolumeUp;
export const iconVolumeOff = faVolumeOff;
export const iconPhoneSlash = faPhoneSlash;
export const iconDesktop = faDesktop;
export const iconTiles = faThLarge;
export const iconVerification = faTriangleExclamation;
export const iconWarning = faTriangleExclamation;
export const iconInfo = faInfoCircle;
export const iconHistory = faClockRotateLeft;
export const iconArrowRight = faChevronRight;
export const iconArrowLeft = faChevronLeft;
export const iconArrowDown2 = faChevronDown;
export const iconDownload = faDownload;
export const iconFullScreen = faUpRightAndDownLeftFromCenter;
export const iconMinimalizeScreen = faDownLeftAndUpRightToCenter;
export const iconBold = faBold;
export const iconItalic = faItalic;
export const iconStrikethrough = faStrikethrough;
export const iconSearch = faSearch;
export const iconMeetingLobby = faUsers;
export const iconRemoveFromList = faTimes;
export const iconSuccess = faCheck;
export const iconCopy = faCopy;
export const iconPassword = faKey;
export const iconUnread = faGlasses;
export const iconActionsDropdown = faEllipsisVertical;

function Icon(props: {
    icon: IconDef;
    spin?: boolean;
    style?: React.CSSProperties;
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
}) {
    return (
        <FontAwesomeIcon
            className={props.className}
            icon={props.icon}
            spin={props.spin}
            fixedWidth
            style={props.style}
            onClick={(e) => props.onClick?.(e)}
        />
    );
}

export function mimetypeToIcon(mimetype: string) {
    if (mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return faFileWord;
    }
    if (mimetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return faFileExcel;
    }
    if (mimetype.startsWith('audio/')) {
        return faFileAudio;
    }
    if (mimetype.startsWith('image/')) {
        return faFileImage;
    }
    if (mimetype.startsWith('video/')) {
        return faFileVideo;
    }
    if (mimetype === 'application/pdf') {
        return faFilePdf;
    }
    if (mimetype === 'text/plain') {
        return faFileText;
    }
    return faFile;
}

export default Icon;
