import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from './Store';
import { NewThreadFormData } from '../components/ChatAddPage';
import { UserSelectOptionType } from '../components/UsersSelector';

interface ChatDraft {
    title: string;
    members: { label: string; type: UserSelectOptionType['type'] }[];
    type: 'chat';
    formSnapshot: NewThreadFormData;
}

interface DraftSliceState {
    drafts?: ChatDraft;
}

export const initialState: DraftSliceState = {};

export const draftsSlice = createSlice({
    name: 'draft',
    initialState,
    reducers: {
        addDraft: (state, action: PayloadAction<Omit<ChatDraft, 'id'>>) => {
            state.drafts = action.payload;
        },
        removeDraft: (state) => {
            state.drafts = undefined;
        }
    }
});

export const { addDraft, removeDraft } = draftsSlice.actions;

const selectDraftsSlice = (state: RootState) => state.drafts;

export const selectActiveDrafts = createSelector([selectDraftsSlice], (state) => {
    return state.drafts;
});

export default draftsSlice.reducer;
