import * as privmx from 'privfs-client';

export interface KvdbSettingEntryX<T = unknown> extends privmx.types.db.KvdbEntry {
    secured: {
        key?: string;
        value: T;
    };
}
export type KvdbSettingEntry = KvdbSettingEntryX<string>;

export class KvdbUtils {
    static createKvdbSettingEntry<T>(value: T): KvdbSettingEntryX<T> {
        const entry: KvdbSettingEntryX<T> = {
            secured: {
                value: value
            }
        };
        return entry;
    }
}
