import { useRef } from 'react';
import { BaseModalData } from '../../types/Modals';
import useLoader from '../../hooks/useLoader';
import Modal from '../../atoms/Modal';
import StateView from '../../atoms/State';
import { ChatEditPage } from './index';
import { ChatId } from '../../types/Types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface ChatEditModalData extends BaseModalData {
    id: ChatId;
}

export function ChatEditModal(props: {
    onClose: () => void;
    data: ChatEditModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const ref = useRef();
    const { state, revalidate } = useLoader(
        useCallback((x) => x.loadChatForEditing(props.data.id), [props.data.id])
    );
    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            beforeClose={() => (ref.current as any)?.beforeClose()}
            height={props.data.height ?? 330}
            maw={800}
            width={props.data.width}
            title={
                props.data.title ??
                (state.type === 'done' && state.data.chat && state.data.chat.title
                    ? t('modal.chatEdit.titleWithName', { name: state.data.chat.title })
                    : t('modal.chatEdit.titleWithoutName'))
            }>
            <StateView state={state} retry={revalidate}>
                {(data) => <ChatEditPage ref={ref} chatEdit={data} handleClose={props.onClose} />}
            </StateView>
        </Modal>
    );
}
