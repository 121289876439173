import { BadgeProps, Tooltip, Badge } from '@mantine/core';
import { MouseEventHandler } from 'react';
import { useTagStyles } from './styles';

export const TagElement = ({
    children,
    label,
    onClick,
    tooltip,
    ...props
}: {
    label?: string;
    onClick?: MouseEventHandler;
    children: React.ReactNode;
    tooltip?: string;
} & BadgeProps) => {
    const { classes } = useTagStyles();

    return (
        <Tooltip disabled={!tooltip} openDelay={500} label={tooltip}>
            <Badge
                onClick={onClick}
                mr="xs"
                miw={0}
                size="xs"
                maw={200}
                classNames={{
                    inner: classes.inner,
                    root: onClick ? classes.root : undefined
                }}
                {...props}>
                {children}
            </Badge>
        </Tooltip>
    );
};
