import React from 'react';
import { QuestionModel } from '../../../types/Types';
import { answerType, FormDispatch } from '../types';
import Icon, { iconArrowDown, iconArrowUp } from '../../Icon';
import {
    ActionIcon,
    Box,
    Button,
    Card,
    Checkbox,
    Grid,
    Group,
    Select,
    Stack,
    Text,
    TextInput
} from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface BaseBlockProps {
    focused: boolean;
    children: React.ReactElement;
    index: number;
    question: QuestionModel;
    dispatch: FormDispatch;
}

export default function Block({ focused, question, children, index, dispatch }: BaseBlockProps) {
    const { t } = useTranslation();

    if (question.type === 'block') {
        return (
            <Card w="50%" shadow="lg" mb="md" p="xl" pl="md" mx="auto" sx={{ overflow: 'visible' }}>
                <Grid>
                    <Grid.Col span={1} maw={40}>
                        <Stack justify="space-between" mx="auto" h="100%">
                            <ActionIcon>
                                <Icon
                                    onClick={() =>
                                        dispatch({ type: 'move_question', index, direction: 'up' })
                                    }
                                    icon={iconArrowUp}
                                />
                            </ActionIcon>
                            <ActionIcon>
                                <Icon
                                    onClick={() =>
                                        dispatch({
                                            type: 'move_question',
                                            index,
                                            direction: 'down'
                                        })
                                    }
                                    icon={iconArrowDown}
                                />
                            </ActionIcon>
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span="auto">
                        <Text>Text block field</Text>
                    </Grid.Col>
                </Grid>
            </Card>
        );
    }

    return (
        <Card w="50%" shadow="lg" mb="md" p="xl" pl="md" mx="auto" sx={{ overflow: 'visible' }}>
            <Grid>
                <Grid.Col span={1} maw={40}>
                    <Stack justify="space-between" mx="auto" h="100%">
                        <ActionIcon>
                            <Icon
                                onClick={() =>
                                    dispatch({ type: 'move_question', index, direction: 'up' })
                                }
                                icon={iconArrowUp}
                            />
                        </ActionIcon>
                        <ActionIcon>
                            <Icon
                                onClick={() =>
                                    dispatch({ type: 'move_question', index, direction: 'down' })
                                }
                                icon={iconArrowDown}
                            />
                        </ActionIcon>
                    </Stack>
                </Grid.Col>
                <Grid.Col span="auto">
                    <Group position="apart" mb="md" w="100%" grow>
                        <TextInput
                            variant="filled"
                            sx={(them) => ({ flexGrow: 1 })}
                            onChange={(e) =>
                                dispatch({
                                    type: 'change_title',
                                    index,
                                    newValue: e.target.value
                                })
                            }
                            value={question.title}
                        />
                        <Select
                            variant="filled"
                            sx={{ flexGrow: 0 }}
                            data={answerType.map((x) => ({ value: x, label: x }))}
                            defaultValue="short"
                            value={question.type}
                            onChange={(e) =>
                                dispatch({
                                    type: 'change_questionType',
                                    index,
                                    newValue: e || ''
                                })
                            }
                        />
                    </Group>
                    <Checkbox
                        my="sm"
                        label={t('entityProps.required')}
                        checked={question.required}
                        onChange={(e) => {
                            dispatch({
                                type: 'change_required',
                                index,
                                newValue: e.target.checked
                            });
                        }}
                    />
                    <Box>{children}</Box>
                    <Group position="right">
                        <Button
                            size="xs"
                            variant="outline"
                            onClick={() => dispatch({ type: 'remove_question', index })}>
                            {t('action.delete')}
                        </Button>
                    </Group>
                </Grid.Col>
            </Grid>
        </Card>
    );
}
