import { useEffect } from 'react';

export interface NavigatableItem<TId> {
    id: TId;
}

export function usePreventClose(preventClose: boolean) {
    useEffect(() => {
        if (!preventClose) {
            return;
        }
        const handler = (event: any) => {
            event.preventDefault();
            return (event.returnValue = '');
        };
        window.addEventListener('beforeunload', handler, { capture: true });
        return () => {
            window.removeEventListener('beforeunload', handler, { capture: true });
        };
    }, [preventClose]);
}
