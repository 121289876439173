import React from 'react';
import ShareFormView from '.';
import Modal from '../../atoms/Modal';
import { BaseModalData } from '../../types/Modals';
import { useTranslation } from 'react-i18next';

export interface FormShareModalData extends BaseModalData {
    message: string;
}

export function FormShareModal(props: {
    onClose: () => void;
    data: FormShareModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            height={500}
            fullWidth
            title={t('modal.shareForm.title')}>
            <ShareFormView message={props.data.message} closeFn={props.onClose} />
        </Modal>
    );
}
