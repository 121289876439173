import { FileName, DuplicatedFilesAction } from '../types/Types';
import { ModalsInterface } from './ModalsInterface';
import { store } from '../store';
import { modalPrompt, setDuplicatedFilesAction } from '../store/ModalsSlice';
import * as types from '../types/Types';
import { modalService } from '../hooks/useModal';

export class ModalsService implements ModalsInterface {
    async askForDuplicatedFilesAction(fileNames: FileName[]): Promise<DuplicatedFilesAction> {
        const result = await modalPrompt<types.DuplicatedFilesAction>(store, {
            clearResult: () => store.dispatch(setDuplicatedFilesAction(null)),
            showModal: () => {
                modalService.openDuplicatedFilesActionModal({
                    fileNames: fileNames,
                    updateStoreState: true
                });
            },
            extractResultFromState: (state) =>
                state.modals.duplicatedFilesAction !== null
                    ? { exists: true, result: state.modals.duplicatedFilesAction }
                    : { exists: false }
        });
        return result;
    }
}
