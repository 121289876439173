import { useEffect, useRef, useState } from 'react';
import type { FileViewerProps } from '../FileViewers';
import * as pdfJs from 'pdfjs-dist';
import { api } from '../../../api/Api';
import { useResizeObserver } from '@mantine/hooks';
import { ScrollArea } from '@mantine/core';
import { UrlBuilder } from '../../../utils/UrlBuilder';

pdfJs.GlobalWorkerOptions.workerSrc = UrlBuilder.buildUrl('pdf.worker.min.js');

export function PdfFileViewer(props: FileViewerProps) {
    const [pdfDoc, setPdfDoc] = useState<pdfJs.PDFDocumentProxy | null>(null);
    const [ref, rect] = useResizeObserver();

    useEffect(() => {
        let cancelled = false;
        api.getAttachmentFromSource(props.file.id, props.file.sourceType, false).then(
            async (attachmentData) => {
                if (cancelled) {
                    return;
                }
                const buff = attachmentData.content;
                const pdfDoc = await pdfJs.getDocument(buff).promise;
                setPdfDoc(pdfDoc);
            }
        );
        return () => {
            cancelled = true;
        };
    }, [props.file.id, props.file.sourceType]);

    return (
        <ScrollArea h={'100%'} ref={ref}>
            {props.isVisible &&
                new Array(pdfDoc?.numPages ?? 0)
                    .fill(0)
                    .map((_, i) => (
                        <PdfPage key={i} pdfDoc={pdfDoc!} pageId={i} width={rect.width} />
                    ))}
        </ScrollArea>
    );
}

interface PdfPageProps {
    pdfDoc: pdfJs.PDFDocumentProxy;
    pageId: number;
    width: number;
}

function PdfPage(props: PdfPageProps) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const lastRenderPromiseRef = useRef<Promise<void> | null>(null);

    useEffect(() => {
        let cancelled = false;
        props.pdfDoc.getPage(props.pageId + 1).then(async (page) => {
            if (cancelled) {
                return;
            }
            const scale = props.width / page.getViewport({ scale: 1.0 }).width;
            if (isNaN(scale) || scale < 0.1) {
                return;
            }
            const viewport = page.getViewport({ scale });
            const canvas = canvasRef.current;
            if (!canvas) {
                return;
            }
            const context = canvas.getContext('2d')!;
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const renderContext = {
                canvasContext: context,
                viewport: viewport
            };
            if (lastRenderPromiseRef.current) {
                await lastRenderPromiseRef.current;
            }
            lastRenderPromiseRef.current = new Promise(async (resolve) => {
                try {
                    await page.render(renderContext).promise;
                } finally {
                    resolve();
                }
            });
        });
        return () => {
            cancelled = true;
        };
    }, [props.pdfDoc, props.pageId, props.width]);

    return <canvas ref={canvasRef} />;
}
