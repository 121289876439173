import { Box, Group, rem, ScrollArea, Skeleton, Stack, Text, TextInput } from '@mantine/core';
import { useCallback, useMemo } from 'react';
import useLoader from '../../hooks/useLoader';
import UserAvatar from '../../atoms/UserAvatar';
import { displayRelativeTime } from '../../utils/RelativeTimeFormat';
import { IconSearch } from '@tabler/icons-react';
import { useAdminPanelStyles } from './SettingsMemberTab';
import { useInputState } from '@mantine/hooks';
import { NoItemsInfo } from '../../atoms/NoItemsInfo';
import { useRouteContext } from './useSettingsScreenContext';

export const USER_TEXT_WIDTH = 300;
export const DATE_TEXT_WIDTH = '20%';
export const LoadingUsers = () => {
    const { classes } = useAdminPanelStyles();

    return (
        <>
            {new Array(15).fill(0).map((_, i) => (
                <Group key={i} className={classes.userElement}>
                    <UserAvatar loading />
                    <Box w={USER_TEXT_WIDTH}>
                        <Skeleton height={16} mb={'xs'} width={'55%'} />
                        <Skeleton height={16} width={'85%'} />
                    </Box>
                    <Skeleton w={DATE_TEXT_WIDTH} height={16} width={'35%'} />
                    <Skeleton w={DATE_TEXT_WIDTH} height={16} width={'35%'} />
                </Group>
            ))}
        </>
    );
};

export function ContactsList() {
    const { state } = useLoader(useCallback((x) => x.loadAccounts({}), []));
    const { classes } = useAdminPanelStyles();
    const setContext = useRouteContext()[1];

    const [filterValue, setFilterValue] = useInputState('');

    const filteredContacts = useMemo(() => {
        if (state.type !== 'done') return [];
        return state.data.accounts[1].filter(
            (x) =>
                x.email?.toLowerCase().includes(filterValue.toLowerCase()) ||
                x.username?.toLowerCase().includes(filterValue.toLowerCase())
        );
    }, [filterValue, state]);

    const contactUsers =
        state.type === 'done' ? (
            filteredContacts.map((x) => (
                <Group
                    key={x.id}
                    className={classes.userElement}
                    onClick={() => setContext({ route: 'contacts/detail', id: x.id })}>
                    <UserAvatar showTooltip={false} username={x.username} />
                    <Box className={classes.name}>
                        <Text size={'sm'} truncate>
                            {x.username}
                        </Text>
                        <Text size={'sm'} color="dimmed" truncate maw={USER_TEXT_WIDTH}>
                            {x.email}
                        </Text>
                    </Box>
                    <Text size={'sm'} color="dimmed" className={classes.date}>
                        {displayRelativeTime(x.lastLoggedIn)}
                    </Text>
                    <Text size={'sm'} color="dimmed" className={classes.date}>
                        {displayRelativeTime(x.creationDate)}
                    </Text>
                </Group>
            ))
        ) : (
            <LoadingUsers />
        );

    return (
        <Stack align="center" h={`calc(100% - ${rem(16)})`} className={classes.list} mt={'md'}>
            <Box w={'100%'}>
                <TextInput
                    onChange={setFilterValue}
                    value={filterValue}
                    w={'70%'}
                    size={'xs'}
                    placeholder={'Search...'}
                    icon={<IconSearch size={rem(12)} />}
                />
            </Box>
            <Box h={'100%'} w={'100%'}>
                <Group spacing="sm" pb="md" px={8}>
                    <Text color="dimmed" w={USER_TEXT_WIDTH + 26 + 16} size="sm">
                        Name
                    </Text>
                    <Text color="dimmed" w={DATE_TEXT_WIDTH} size="sm">
                        Last Login
                    </Text>
                    <Text color="dimmed" w={DATE_TEXT_WIDTH} size="sm">
                        Added Date
                    </Text>
                </Group>
                <ScrollArea.Autosize sx={{ flexGrow: 1 }} h={`calc(100% - ${rem(80)})`}>
                    <Stack spacing={0}>
                        {contactUsers}
                        {filteredContacts.length === 0 &&
                            state.type === 'done' &&
                            filterValue === '' && <NoItemsInfo itemsType="contacts" />}
                        {filteredContacts.length === 0 &&
                            state.type === 'done' &&
                            filterValue !== '' && <NoItemsInfo itemsType="results" />}
                    </Stack>
                </ScrollArea.Autosize>
            </Box>
        </Stack>
    );
}
