import React, { useCallback } from 'react';

export function DetailLink<T>({
    element,
    children,
    onClick
}: {
    element: T;
    children: (onClickWithPayload: (e: MouseEvent) => void | undefined) => React.ReactNode;
    onClick?: (element: T, e: MouseEvent) => void;
}) {
    const bindedOnClick = useCallback(
        (e: MouseEvent) => onClick && onClick(element, e),
        [element, onClick]
    );

    return <>{children(bindedOnClick)}</>;
}
