import { useCallback, useState, MouseEventHandler, useMemo } from 'react';
import { DetailLink } from '../../components/DetailLink';
import Icon, { iconList } from '../../components/Icon';
import Loading from '../../components/Loading';
import { FormModel2, FormId, Tag, Username, UsernameOpt } from '../../types/Types';
import Timestamp from '../../atoms/Timestamp';
import useModal from '../../hooks/useModal';
import {
    Box,
    Button,
    Center,
    Flex,
    Grid,
    Group,
    Indicator,
    Menu,
    Paper,
    Stack,
    Tabs,
    Text,
    TextInput,
    rem
} from '@mantine/core';
import FloatingAddButton from '../../atoms/FloatingAddButton';
import RouteListContainer from '../../atoms/RouteScreen/RouteListContainer';
import ListElement from '../../mantineAtoms/ListElement';
import useKeyNavigation from '../../hooks/useKeyNavigation';
import { useAreSubmitsInFormUnreadDeep } from '../../hooks/useIsUnread';
import { api } from '../../api/Api';
import { useSortForms } from '../../hooks/useSort';
import ScreenScrollContainer from '../../mantineAtoms/ScreenScrollContainer';
import useSearch from '../../mantineAtoms/SearchBar/useSearch';
import { MappedSearchData } from '../../mantineAtoms/SearchBar/types';
import SearchBar from '../../mantineAtoms/SearchBar/SearchBar';
import { useTranslation } from 'react-i18next';
import { NoItemsInfo } from '../../atoms/NoItemsInfo';
import { Link } from 'react-router-dom';
import { IconChevronDown, IconSearch, IconUsersGroup } from '@tabler/icons-react';
import { displayRelativeTime } from '../../utils/RelativeTimeFormat';
import { EmptyState } from '../../mantineAtoms/EmptyState/EmptyState';
import { useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { UsersList } from '../ChatScreen/components/UserList';
import { UserAvatarX } from '../../atoms/UserAvatar';
import { getFormTemplates } from '../../data/formTemplates';

export type TabId = 'current' | 'all' | 'pinned' | 'archived';

export function isTabId(activeTab: unknown): activeTab is TabId {
    return ['current', 'all', 'pinned', 'archived'].includes(activeTab as string);
}

export function resolveActiveTab(activeTab: unknown) {
    return isTabId(activeTab) ? activeTab : undefined;
}

interface FormsScreenViewProps {
    forms: FormModel2[];
    activeFormId?: FormId;
    onFormChoose: (form: FormModel2) => void;
    filter: string;
    onFilterSubmit: (filter: string) => void;
    activeTab: TabId;
    onTabChange: (tab: TabId) => void;
}

export default function FormsScreenView(props: FormsScreenViewProps) {
    if (props.forms.length === 0) {
        return (
            <EmptyState
                image="NO_FORMS"
                title="No Forms yet"
                subTitle="Use our templates or create your own Forms from scratch"
                button={
                    <Button component={Link} to="/forms/new">
                        New Form
                    </Button>
                }
            />
        );
    }

    return (
        <RouteListContainer>
            <Group position="apart" p="md" pb="0" mb="xs">
                <Box w={400}>
                    <TextInput
                        size={'xs'}
                        miw={400}
                        placeholder={'Search'}
                        icon={<IconSearch size={rem(16)} />}
                        styles={{
                            wrapper: {
                                '&:focus-within': {
                                    '& .mantine-Input-input': {
                                        borderColor: '#936FAD'
                                    }
                                }
                            }
                        }}
                    />
                </Box>
                <Button.Group>
                    <Button
                        sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        variant="light"
                        component={Link}
                        to="/forms/new"
                        size="xs">
                        New Form
                    </Button>
                    <Menu position="bottom-end">
                        <Menu.Target>
                            <Button
                                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                variant="light"
                                size="xs"
                                px="xs">
                                <IconChevronDown size={rem(14)} />
                            </Button>
                        </Menu.Target>
                        <Menu.Dropdown>
                            {getFormTemplates().map((tpl) => (
                                <Menu.Item
                                    key={tpl.id}
                                    component={Link}
                                    to={`/forms/new-tpl-${tpl.id}`}>
                                    {tpl.formModel.name}
                                </Menu.Item>
                            ))}
                        </Menu.Dropdown>
                    </Menu>
                </Button.Group>
            </Group>
            <FormsScreenGallery {...props} />
        </RouteListContainer>
    );
}

function FormsScreenGallery(props: FormsScreenViewProps) {
    return (
        <ScreenScrollContainer offsetScrollbars pr="sm">
            <Grid gutter={'md'} pt="sm">
                {props.forms ? (
                    <>
                        {props.forms.map((item) => (
                            <Grid.Col span={6} sm={4} lg={3} xl={2} key={item.id}>
                                <FormCard form={item}></FormCard>
                            </Grid.Col>
                        ))}
                        {props.forms.length === 0 && <NoItemsInfo itemsType="forms" />}
                    </>
                ) : (
                    <Loading />
                )}
            </Grid>
        </ScreenScrollContainer>
    );
}

function FormCard({ form }: { form: FormModel2 }) {
    const { t } = useTranslation();
    const isUnread = useAreSubmitsInFormUnreadDeep(form.id);
    const currentUser = useAppSelector(selectCurrentUser);
    const isPrivate = isPrivateForm(form, currentUser.username);
    const currentUserObj = useMemo(
        () => ({ type: 'user', username: currentUser.username! }),
        [currentUser.username]
    ) as UsernameOpt;

    return (
        <Indicator disabled={!isUnread} withBorder size={16}>
            <Paper
                sx={(theme) => ({
                    backgroundColor:
                        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                    '&:hover': {
                        backgroundColor:
                            theme.colorScheme === 'dark'
                                ? theme.colors.dark[5]
                                : theme.colors.gray[1]
                    }
                })}
                component={Link}
                to={`/forms/${form.id}/responses`}
                p="lg">
                <Group position="center" spacing={8}>
                    <Text align="center" weight={isUnread ? 'bold' : 'normal'} size="sm">
                        {form.name}
                    </Text>
                </Group>
                <Text align="center" color="dimmed" size="xs" mb="md">
                    {displayRelativeTime(form.lastSubmitDate)}
                </Text>
                <Text align="center" color="dimmed" size="xs">
                    <Flex align="center" gap="xs" justify="center">
                        <IconUsersGroup size={rem(14)} /> {form.entriesCount}
                    </Flex>
                </Text>
                {isPrivate && (
                    <Flex align="center" mt="sm" justify="center" gap="md">
                        <UserAvatarX user={currentUserObj} size={20} />
                        <Text size="sm">{t('entityProps.privateForm')}</Text>
                    </Flex>
                )}
                {!isPrivate && (
                    <Center mt="sm">
                        <UsersList
                            usernames={form.users.map((x) => ({ type: 'user', username: x }))}
                            includeSelfFirst
                            small
                        />
                    </Center>
                )}
            </Paper>
        </Indicator>
    );
}

function isPrivateForm(form: FormModel2, username: Username | null) {
    if (form.users.length !== 1 || form.managers.length !== 1) {
        return false;
    }
    if (form.users[0] !== username || form.managers[0] !== username) {
        return false;
    }
    return true;
}

function formToSearchData(contacts: FormModel2[]): MappedSearchData<FormModel2> {
    const data: MappedSearchData<FormModel2> = new Map();
    contacts.forEach((chat) => {
        data.set({ text: chat.name, tags: [], users: [] }, chat);
    });
    return data;
}

export function FormsList(props: FormsScreenViewProps) {
    const { t } = useTranslation();
    const modalService = useModal();
    const onFormChoose = props.onFormChoose;
    const onTabChange = props.onTabChange;

    const forms = useSortForms(props.forms);
    const formsSearchData = useMemo(() => formToSearchData(forms), [forms]);
    const { controller, filteredElements } = useSearch(formsSearchData);

    const { currentIndex, clicked, overrideIndex } = useKeyNavigation(
        filteredElements.length,
        useCallback((i) => onFormChoose(filteredElements[i]), [filteredElements, onFormChoose]),
        useCallback((id) => filteredElements.findIndex((x) => x.id === id), [filteredElements])
    );

    const handleElementClick = useCallback(
        (form: FormModel2) => {
            clicked.current = true;
            onFormChoose(form);
            overrideIndex(props.forms.findIndex((x) => x.id === form.id));
        },
        [onFormChoose, clicked, overrideIndex, props.forms]
    );

    const handleAddButton = useCallback(() => {
        modalService.openFormAddModal({});
    }, [modalService]);

    const handleCurrentTabClick = useCallback(() => {
        onTabChange('current');
    }, [onTabChange]);

    const handleAllTabClick = useCallback(() => {
        onTabChange('all');
    }, [onTabChange]);

    const handlePinnedTabClick = useCallback(() => {
        onTabChange('pinned');
    }, [onTabChange]);

    const handleArchivedTabClick = useCallback(() => {
        onTabChange('archived');
    }, [onTabChange]);

    return (
        <RouteListContainer>
            <Stack h={106} mih={106} justify="space-between">
                <Group m="md" mb={0}>
                    <Box sx={{ flexGrow: 1 }}>
                        <SearchBar w="90%" mx="auto" {...controller} />
                    </Box>
                    <Button component={Link} to="/forms/new">
                        Create new
                    </Button>
                </Group>
                <Tabs defaultValue="Current">
                    <Tabs.List grow position="center" px="md">
                        <Tabs.Tab value="Current" onClick={handleCurrentTabClick}>
                            {t('tab.current')}
                        </Tabs.Tab>
                        <Tabs.Tab value="All" onClick={handleAllTabClick}>
                            {t('tab.all')}
                        </Tabs.Tab>
                        <Tabs.Tab value="Pinned" onClick={handlePinnedTabClick}>
                            {t('tab.pinned')}
                        </Tabs.Tab>
                        <Tabs.Tab value="Archived" onClick={handleArchivedTabClick}>
                            {t('tab.archived')}
                        </Tabs.Tab>
                    </Tabs.List>
                </Tabs>
            </Stack>
            <ScreenScrollContainer>
                {props.forms ? (
                    <>
                        {[
                            ...filteredElements,
                            ...filteredElements,
                            ...filteredElements,
                            ...filteredElements,
                            ...filteredElements
                        ].map((item, i) => (
                            <FormLink
                                hovered={i === currentIndex}
                                key={item.id}
                                form={item}
                                active={item.id === props.activeFormId}
                                onClick={handleElementClick}
                            />
                        ))}
                        {filteredElements.length === 0 && <NoItemsInfo itemsType="forms" />}
                    </>
                ) : (
                    <Loading />
                )}
            </ScreenScrollContainer>
            <FloatingAddButton onClick={handleAddButton} />
        </RouteListContainer>
    );
}

export function FormLink(props: {
    form: FormModel2;
    active: boolean;
    onClick: (form: FormModel2, e: MouseEvent) => void;
    hovered?: boolean;
}) {
    const onClick = props.onClick;
    const isUnread = useAreSubmitsInFormUnreadDeep(props.form.id);
    const modalService = useModal();
    const [isProcessingPin, setIsProcessingPin] = useState<boolean>(false);
    const [isProcessingArchive, setIsProcessingArchive] = useState<boolean>(false);

    const editFn: MouseEventHandler = useCallback(
        (e) => {
            e.stopPropagation();
            modalService.openFormEditModal({ id: props.form.id });
        },
        [props.form.id, modalService]
    );

    const handleTogglePinnedClick = useCallback(
        async (event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            setIsProcessingPin(true);
            const newPinned = !props.form.pinned;
            try {
                await api.setFormPinned(props.form.id, newPinned);
            } finally {
                props.form.pinned = newPinned; // hack to prevent blink after loading disapear
                setIsProcessingPin(false);
            }
        },
        [props.form]
    );

    const handleToggleArchivedClick = useCallback(
        async (event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            setIsProcessingArchive(true);
            const newArchived = !props.form.archived;
            try {
                await api.setFormArchived(props.form.id, newArchived);
            } finally {
                props.form.archived = newArchived; // hack to prevent blink after loading disapear
                setIsProcessingArchive(false);
            }
        },
        [props.form]
    );

    return (
        <DetailLink element={props.form} onClick={onClick}>
            {(onClick) => (
                <ListElement
                    isUnread={isUnread}
                    hovered={props.hovered}
                    onClick={onClick}
                    title={props.form.name}
                    active={props.active}
                    tags={props.form.tags as Tag[]}
                    info={
                        <Group position="apart">
                            <div>
                                <Box mr="xs" component={Icon} icon={iconList} display="inline" />
                                {props.form?.entriesCount}
                            </div>
                            <Text color="dimmed">{<Timestamp date={Date.now()} />}</Text>
                        </Group>
                    }
                    actionIcons={
                        <ListElement.Icons
                            editFn={editFn}
                            archiveFn={handleToggleArchivedClick}
                            pinFn={handleTogglePinnedClick}
                            procsesingArchive={isProcessingArchive}
                            procsesingPin={isProcessingPin}
                            isArchived={props.form.archived}
                            isPinned={props.form.pinned}
                        />
                    }
                />
            )}
        </DetailLink>
    );
}
