import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormPublicView, SubmitedAnswer } from '../../../types/Types';
import { Flex, Stack, Title, Text } from '@mantine/core';
import { usePreventClose } from '../../../hooks/usePreventClose';
import * as FormsTypes from '../FormsTypes';
import FormFactory from './FormFactory';
import {
    convertModel,
    validateForm,
    extractAttachments,
    convertResponsesToSubmitedAnswers,
    validateFormModel
} from '../utils/FormsUtils';
import { Utils } from '../../../api/privmx/utils/Utils';
import { formApi } from '../../../api/Api';
import { useTranslation } from 'react-i18next';
import * as PmxApi from 'privmx-server-api';
import { FileChooser } from '../../../utils/FileChooser';
import { useAppSelector } from '../../../store';
import { selectFileConfig } from '../../../store/UserPreferenceSlice';
import { notifications } from '@mantine/notifications';
import { CaptchaModal } from './CaptchaModal';

interface AttachmentsErrors {
    hasErrors: boolean;
    msg?: string;
}

export default function FormAnswering({ model: formModel }: { model: FormPublicView }) {
    const { t } = useTranslation();
    const [isDirty, setDirty] = useState<boolean>(false);
    const [captchaResponse, setCaptchaResponse] = useState<
        PmxApi.api.captcha.CaptchaObj | undefined
    >(undefined);
    const [reSubmit, setReSubmit] = useState<boolean>(false);
    const [captchaToShowState, setCaptchaToShow] = useState<{
        show: boolean;
        challenge: {
            id: PmxApi.api.captcha.CaptchaId;
            url: PmxApi.api.core.Url;
            expires: PmxApi.api.core.TimestampN;
        };
    }>({
        show: false,
        challenge: {
            id: '' as PmxApi.api.captcha.CaptchaId,
            url: '' as PmxApi.api.core.Url,
            expires: -1 as PmxApi.api.core.TimestampN
        }
    });
    const [isSubmitted, setSubmitted] = useState<boolean>(false);
    const [processingForm, setProcessingForm] = useState<boolean>(false);

    const [formState, setFormState] = useState<FormsTypes.FormState>({});
    const [formAttachments, setFormAttachments] = useState<FormsTypes.SelectedAttachments>({});
    const questions: FormsTypes.QuestionModel[] = useMemo(
        () => convertModel(formModel, t),
        [formModel, t]
    );
    const isFormModelValid = useMemo(() => {
        const result = validateFormModel(formModel, t);
        if (result === true) {
            return true;
        }
        if (result.type === 'duplicated-questions') {
            console.error(
                t('screen.publicFormAnswer.errors.incompatibileFormInfo', {
                    data: result.data
                        .map((x) => `[title: '${x.title}' / id: ${x.id}]`)
                        .join(' and ')
                })
            );
        }
        return false;
    }, [formModel, t]);

    usePreventClose(isDirty && !isSubmitted);
    const updateFormState = useCallback(
        (currentState: FormsTypes.FormState, update: Partial<FormsTypes.FormState>) => {
            const cloned = Utils.simpleDeepClone<FormsTypes.FormState>(currentState);
            return { ...cloned, ...update };
        },
        []
    );

    const isFormProcessing = useCallback(() => processingForm, [processingForm]);

    const updateAttachmentsState = useCallback(
        (
            currentState: FormsTypes.SelectedAttachments,
            update: { questionId: string; files: File[] }
        ) => {
            const cloned = Object.assign({}, currentState);
            cloned[update.questionId] = update.files;
            return cloned;
        },
        []
    );

    const handleCaptchaSubmit = useCallback(
        (value: string) => {
            const captchaValue = { ...captchaToShowState };
            setCaptchaToShow({ ...captchaValue, show: false });
            setCaptchaResponse({
                id: captchaValue.challenge.id as PmxApi.api.captcha.CaptchaId,
                text: value.substring(0, 4) as PmxApi.api.captcha.CaptchaText
            });
            setReSubmit(true);
        },
        [captchaToShowState]
    );

    const getResponseFromState = useCallback(
        (questionId: string) => {
            return formState.responses && questionId in formState.responses
                ? formState.responses[questionId]
                : undefined;
        },
        [formState.responses]
    );

    const getErrorsFromState = useCallback(
        (questionId: string) => {
            return formState.errors && questionId in formState.errors
                ? formState.errors[questionId]
                : [];
        },
        [formState.errors]
    );

    const getAutoResponseEmailFromAnswers = useCallback(
        (answers: SubmitedAnswer[]) => {
            if (formModel.autoResponseData.enabled) {
                const field = answers.find((x) => x.id === formModel.autoResponseData.emailFieldId);
                if (field && field.answer[0].input.trim().length > 0) {
                    return field.answer[0].input.trim();
                }
            }
        },
        [formModel]
    );

    function isCaptchaError(e: any) {
        if ('msg' in e && typeof e.msg === 'string') {
            const msg = e.msg as string;
            return msg.toLocaleLowerCase().includes('captcha');
        }
    }

    function getAttachmentsErrors(
        attachments: File[],
        fileUploadConfig: PmxApi.api.request.RequestConfig
    ): AttachmentsErrors {
        try {
            FileChooser.checkFilesConfig(attachments, fileUploadConfig);
            return { hasErrors: false };
        } catch (error: any) {
            const result: AttachmentsErrors = { hasErrors: true };
            if ('message' in error) {
                result.msg = error.message;
            }
            return result;
        }
    }

    const fileUploadConfig = useAppSelector(selectFileConfig);

    const onCheckCaptcha = useCallback(async () => {
        const challenge = await formApi.createCaptcha();
        setCaptchaToShow({
            show: true,
            challenge: { id: challenge.id, url: challenge.url, expires: challenge.expires }
        });
    }, []);
    const onSubmit = useCallback(
        async (formState: FormsTypes.FormState) => {
            if (isSubmitted) {
                return;
            }

            const fullData: SubmitedAnswer[] = convertResponsesToSubmitedAnswers(
                questions,
                formState.responses,
                formAttachments
            );

            const attachments = extractAttachments(formState.responses);

            if (formState.errors) {
                notifications.show({
                    title: t('screen.publicFormAnswer.notifications.errorsInAnswers.title'),
                    message: t('screen.publicFormAnswer.notifications.errorsInAnswers.description'),
                    color: 'red'
                });
                return;
            }

            const attachmentsValidator = getAttachmentsErrors(attachments, fileUploadConfig);
            if (attachmentsValidator.hasErrors) {
                notifications.show({
                    title: t('screen.publicFormAnswer.notifications.uploadError.title'),
                    message: attachmentsValidator.msg,
                    color: 'red'
                });
                return;
            }
            if (formModel.captchaEnabled && !captchaResponse) {
                await onCheckCaptcha();
                return;
            }

            const autoResponseEmail = getAutoResponseEmailFromAnswers(fullData);
            const captchaResult = captchaResponse ? Object.assign({}, captchaResponse) : undefined;

            setCaptchaResponse(undefined);
            try {
                setProcessingForm(true);

                await formApi.submitFormAnswer(
                    formModel.id,
                    formModel.publicationId,
                    formModel.pubKey,
                    fullData,
                    attachments,
                    autoResponseEmail,
                    captchaResult
                );
                setProcessingForm(false);
                setSubmitted(true);
            } catch (e: any) {
                console.error('submitError', e);
                const captchaError = isCaptchaError(e);
                notifications.show({
                    title: captchaError
                        ? t('screen.publicFormAnswer.notifications.invalidCaptcha.title')
                        : t('screen.publicFormAnswer.notifications.submitError.title'),
                    message: isCaptchaError(e)
                        ? t('screen.publicFormAnswer.notifications.invalidCaptcha.description')
                        : t('screen.publicFormAnswer.notifications.submitError.description'),
                    color: 'red'
                });
            } finally {
                setCaptchaResponse(undefined);
                setProcessingForm(false);
            }
        },
        [
            captchaResponse,
            fileUploadConfig,
            formAttachments,
            formModel.captchaEnabled,
            formModel.id,
            formModel.pubKey,
            formModel.publicationId,
            getAutoResponseEmailFromAnswers,
            isSubmitted,
            onCheckCaptcha,
            questions,
            t
        ]
    );

    useEffect(() => {
        if (reSubmit) {
            setReSubmit(false);
            onSubmit(formState);
        }
    }, [reSubmit, onSubmit, formState]);

    const setAttachmentsState = useCallback(
        async (questionId: string, files: File[]) => {
            const updatedState = updateAttachmentsState(formAttachments, {
                questionId: questionId,
                files: files
            });
            setFormAttachments(updatedState);
        },
        [formAttachments, updateAttachmentsState]
    );

    const onPreSubmit = useCallback(
        async (responses: FormsTypes.Responses) => {
            if (isSubmitted) {
                return;
            }
            // hack: enrich responses with attachments from state as on second submit those extracted from inputs are empty
            for (const [questionId, value] of Object.entries(formAttachments)) {
                responses[questionId].answers[0].value = value;
            }

            const errors = validateForm(questions, responses, t);
            const updatedFormState = updateFormState(formState, { responses, errors });
            setFormState(updatedFormState);
            onSubmit(updatedFormState);
        },
        [formAttachments, formState, isSubmitted, onSubmit, questions, t, updateFormState]
    );

    const setDirtyState = useCallback((value: boolean) => {
        setDirty(value);
    }, []);
    return (
        <Stack
            py="xl"
            sx={(theme) => ({
                height: '100svh',
                overflowY: 'scroll',
                backgroundColor:
                    theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
            })}>
            <Title align="center" py="xl">
                {formModel.name}
            </Title>
            {!isFormModelValid && (
                <Text align="center" color="red">
                    {t('screen.publicFormAnswer.errors.incompatibileForm')}
                </Text>
            )}
            {!isSubmitted && !captchaToShowState.show && isFormModelValid && (
                <FormFactory
                    questions={questions}
                    onFormSubmit={onPreSubmit}
                    getResponseFromState={getResponseFromState}
                    getErrorsFromState={getErrorsFromState}
                    setDirtyState={setDirtyState}
                    setSelectedFilesState={setAttachmentsState}
                    isProcessing={isFormProcessing}
                />
            )}
            {!isSubmitted && captchaToShowState.show && isFormModelValid && (
                <CaptchaModal
                    challengeUrl={captchaToShowState.challenge.url}
                    onSubmit={handleCaptchaSubmit}
                />
            )}
            {isSubmitted && isFormModelValid && (
                <Flex w="100%" direction="column" align="center" justify="start">
                    <Text align="center" size="xl" fw="bold" py="lg">
                        {t('screen.publicFormAnswer.labels.thanksForAnswering')}
                    </Text>
                </Flex>
            )}
        </Stack>
    );
}
