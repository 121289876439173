import * as PmxApi from 'privmx-server-api';
import { EccUtils } from './EccUtils';
import { InquiryData, InquiryPublicData } from './InquiryService';

export class InquiryPublicDataEncryptor {
    encrypt(data: InquiryData) {
        const publicData: InquiryPublicData = {
            name: data.name,
            pub: EccUtils.getPublicKey(data.priv),
            questions: data.questions
        };
        const raw = Buffer.from(JSON.stringify(publicData), 'utf8');
        return Buffer.concat([Buffer.from([1]), raw]).toString(
            'base64'
        ) as PmxApi.api.inquiry.InquiryPublicData;
    }

    decrypt(data: PmxApi.api.inquiry.InquiryPublicData) {
        const buffer = Buffer.from(data, 'base64');
        const type = buffer[0];
        if (type === 1) {
            return JSON.parse(buffer.slice(1).toString('utf8')) as InquiryPublicData;
        }
        throw new Error('Cannot decrypt public data, unsupported type');
    }
}
