import { CardProps, DefaultMantineColor, createStyles, getStylesRef, rem } from '@mantine/core';
import { DefaultProps, Selectors, Box, createPolymorphicComponent } from '@mantine/core';
import { forwardRef } from 'react';

type HoverContainerVariant = 'default' | 'dark';

const colorMap: Record<
    HoverContainerVariant,
    { hovered: { darkTheme: number; lightTheme: number } }
> = {
    default: {
        hovered: {
            darkTheme: 7,
            lightTheme: 1
        }
    },
    dark: {
        hovered: {
            darkTheme: 5,
            lightTheme: 1
        }
    }
};

const useStyles = createStyles(
    (
        theme,
        {
            isUnread,
            variant = 'default',
            withBorder = true,
            hoverColor = 'brand'
        }: {
            isUnread: boolean;
            variant?: HoverContainerVariant;
            withBorder?: boolean;
            hoverColor?: DefaultMantineColor | 'brand';
        }
    ) => {
        const bgHoverColor =
            theme.colorScheme === 'dark'
                ? theme.colors.gray[colorMap[variant].hovered.darkTheme]
                : theme.fn.rgba(theme.colors[hoverColor][0], 0.6);

        return {
            link: {
                ...theme.fn.focusStyles(),
                cursor: 'pointer',
                display: 'block',
                textDecoration: 'none',
                color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                lineHeight: 1.2,
                fontSize: theme.fontSizes.sm,
                fontWeight: isUnread ? 600 : 400,
                borderBottom: withBorder
                    ? `${rem(1)} solid ${
                          theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
                      }`
                    : 'none',
                borderRadius: 0,
                '&:hover': {
                    backgroundColor: theme.other.getHoverColor(theme),
                    '.privmx-add-tag': {
                        display: 'inline-flex'
                    },
                    '.mantine-Avatar-root': {
                        borderColor: bgHoverColor
                    },
                    '.mantine-ActionIcon-root': {
                        '&:hover': {
                            backgroundColor: bgHoverColor
                        }
                    },
                    [`& .${getStylesRef('icons')}`]: {
                        display: 'flex'
                    }
                }
            },

            hovered: {
                backgroundColor: bgHoverColor,
                '.mantine-Avatar-root': {
                    borderColor: bgHoverColor
                },
                [`& .${getStylesRef('icons')}`]: {
                    display: 'flex'
                }
            },

            linkActive: {
                fontWeight: 500,
                borderLeftColor:
                    theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 6 : 7],
                color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 2 : 7],
                borderRadius: theme.radius.sm,
                borderBottomRightRadius: theme.radius.sm,
                borderBottomLeftRadius: theme.radius.sm,

                '.mantine-Badge-root': {
                    borderColor:
                        theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 2 : 7]
                },
                '.mantine-Avatar-root': {
                    borderColor:
                        theme.colorScheme === 'dark'
                            ? theme.fn.rgba(theme.colors[theme.primaryColor][9], 0.25)
                            : theme.colors[theme.primaryColor][0]
                },
                '&, &:hover': {
                    '.mantine-Avatar-root': {
                        borderColor:
                            theme.colorScheme === 'dark'
                                ? theme.fn.rgba(theme.colors[theme.primaryColor][9], 0.25)
                                : theme.colors[theme.primaryColor][0]
                    },

                    backgroundColor:
                        theme.colorScheme === 'dark'
                            ? theme.fn.rgba(theme.colors[theme.primaryColor][9], 0.25)
                            : theme.colors[theme.primaryColor][0]
                }
            }
        };
    }
);

type MyComponentStylesNames = Selectors<typeof useStyles>;

export interface PmxHoverCardProps extends DefaultProps<MyComponentStylesNames, CardProps> {
    active?: boolean;
    isUnread?: boolean;
    children?: React.ReactNode;
    hovered?: boolean;
    variant?: HoverContainerVariant;
    withBorder?: boolean;
    hoverColor?: DefaultMantineColor | 'brand';
}

const _HoverContainer = forwardRef<
    HTMLDivElement,
    PmxHoverCardProps & React.HTMLAttributes<HTMLDivElement>
>(function (
    {
        classNames,
        styles,
        unstyled,
        className,
        active = false,
        children,
        isUnread = false,
        hovered = false,
        variant,
        withBorder,
        hoverColor,
        ...others
    },
    ref
) {
    const { classes, cx } = useStyles(
        { isUnread, variant, withBorder, hoverColor },
        { name: 'PrivmxHoverCard', classNames, styles, unstyled }
    );

    return (
        <Box
            component="div"
            ref={ref}
            className={cx(classes.link, className, {
                [classes.linkActive]: active,
                [classes.hovered]: !active && hovered
            })}
            {...others}>
            {children}
        </Box>
    );
});

const HoverContainer = createPolymorphicComponent<
    'div',
    PmxHoverCardProps & React.HTMLAttributes<HTMLDivElement>
>(_HoverContainer);

export default HoverContainer;
