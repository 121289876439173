import {
    Box,
    Title,
    Anchor,
    Paper,
    Code,
    List,
    Text,
    Container,
    ScrollArea,
    ActionIcon,
    Tooltip,
    CopyButton,
    Button
} from '@mantine/core';
import { t } from 'i18next';
import React, { useCallback, useState } from 'react';
import {
    getInstructionCode,
    getInstructionCodeSimple
} from '../../../components/FormCreator/instructionCodeGenerator';
import { Question } from '../../../types/Types';
import { UrlBuilder } from '../../../utils/UrlBuilder';
import {
    isNewFormId,
    useFormCreatorContext
} from '../FormCreator/components/FormState/useFormState';
import { useParams } from 'react-router-dom';
import { api } from '../../../api/Api';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import * as privmx from 'privfs-client';

export function FormEmbed() {
    const { formId } = useParams();
    const { formModel } = useFormCreatorContext();
    const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
    const host = api.getGatewayHost();
    const apiUrl = privmx.core.PrivFsRpcManager.urlMap[`${host}:v2.0`];
    const instruction = getInstructionCodeSimple(
        apiUrl,
        formModel.id,
        formModel.questions as Question[]
    );

    const instructionAdvanced = getInstructionCode(
        apiUrl,
        formModel.id,
        formModel.questions as Question[]
    );

    const handleShowAdvanced = useCallback(() => {
        setShowAdvanced(!showAdvanced);
    }, [showAdvanced]);

    return (
        <ScrollArea>
            <Container>
                <Box mb="xl">
                    <Box mb="lg">
                        <Title order={3} fw="normal">
                            {t('screen.formShare.labels.embedForm')}
                        </Title>

                        <Text>
                            {t('form.creatorInstructions.intro.beforeLink')}{' '}
                            <Anchor
                                href={UrlBuilder.buildUrl('privmx-simple-client.js')}
                                target="_blank">
                                privmx-simple-client
                            </Anchor>{' '}
                        </Text>
                        <Text>{t('form.creatorInstructions.intro.afterLink')}:</Text>
                        {formModel.questions && !isNewFormId(formId) && (
                            <>
                                <Paper withBorder pos="relative">
                                    <CopyButton value={instruction}>
                                        {({ copied, copy }) => (
                                            <Tooltip label="Copy code snippet">
                                                <ActionIcon
                                                    color={copied ? 'teal' : undefined}
                                                    onClick={copy}
                                                    pos="absolute"
                                                    top={'1rem'}
                                                    right={'1rem'}>
                                                    {copied ? <IconCheck /> : <IconCopy />}
                                                </ActionIcon>
                                            </Tooltip>
                                        )}
                                    </CopyButton>
                                    <Code block>
                                        {showAdvanced ? instructionAdvanced : instruction}
                                    </Code>
                                </Paper>
                                {showAdvanced && (
                                    <List type="unordered" mb="md">
                                        <List.Item>
                                            {t('form.creatorInstructions.list.0')}
                                        </List.Item>
                                        <List.Item>
                                            <Code>name</Code> {t('and')} <Code>value</Code>{' '}
                                            {t('form.creatorInstructions.list.1')}
                                        </List.Item>
                                    </List>
                                )}
                            </>
                        )}
                        <Button onClick={handleShowAdvanced}>
                            {showAdvanced ? 'Basic' : 'Advanced'}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ScrollArea>
    );
}
