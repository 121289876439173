import { Link } from 'react-router-dom';
import type * as threadTypes from '../../../../api/privmx/ThreadService';
import { useAppSelector } from '../../../../store';
import { selectCachedMeeting } from '../../../../store/DataCacheSlice';
import { LastMessage, Message, Username } from '../../../../types/Types';
import { Box, Button, Flex, Stack, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import UserAvatar from '../../../../atoms/UserAvatar';
import UserDisplayedName from '../../../../atoms/UserDisplayedName';

export interface MeetingScheduledMessageProps {
    message: Message | LastMessage;
    data: threadTypes.MeetingScheduledMessage;
}

function GoToMeetingButton(props: { meetingId: string; meetingFound: boolean }) {
    const { t } = useTranslation();

    if (!props.meetingFound) {
        return (
            <Text size="sm" color="dimmed">
                {t('meeting.youNoLongerHaveAccess')}
            </Text>
        );
    }

    return (
        <Box
            className="go-to-meeting-btn"
            component={Link}
            mt="sm"
            to={`/meetings/${props.meetingId}`}>
            <Button variant="light">{t('action.goToMeeting')}</Button>
        </Box>
    );
}

export default function MeetingScheduledMessage({ data, message }: MeetingScheduledMessageProps) {
    const { t } = useTranslation();
    const meeting = useAppSelector(selectCachedMeeting(data.meetingId));
    const meetingTitle = meeting?.title || '';

    const isInsideMeetingChat = meeting ? message.threadId === meeting.id : false;

    return (
        <>
            <Stack align="center" className="meeting-scheduled-event" spacing={0}>
                <Flex align={'center'} gap={4}>
                    {message.author ? (
                        <>
                            <UserAvatar username={message.author as Username} />

                            <UserDisplayedName color="dimmed" user={message.author as Username} />
                        </>
                    ) : null}

                    <Text size="sm" color="dimmed">
                        {t('meeting.meetingSheduled')}
                    </Text>
                </Flex>
                <Title order={3}>{meetingTitle}</Title>
                {!isInsideMeetingChat && data.meetingId && (
                    <GoToMeetingButton
                        meetingFound={data.meetingId && !!meeting}
                        meetingId={data.meetingId}></GoToMeetingButton>
                )}
            </Stack>
        </>
    );
}
