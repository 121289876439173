import * as PmxApi from 'privmx-server-api';
import { Text, TextProps } from '@mantine/core';
import { useAppSelector } from '../../store';
import { selectAnonymousUser, selectUser } from '../../store/UsersSlice';
import { Contact, ContactId, User, Username } from '../../types/Types';
import { api } from '../../api/Api';
import { useTranslation } from 'react-i18next';
import { selectContactName } from '../../store/DataCacheSlice';

export interface UserDisplayedNameProps {
    user: PmxApi.api.core.Client | User;
    isAnonymousMeetingUser?: boolean;
    contact?: ContactId;
}

export function ContactDisplayedName({
    contact,
    withEmail = false,
    ...props
}: { contact: Contact; withEmail?: boolean } & TextProps) {
    const contactName = useAppSelector(selectContactName(contact.username));

    const name = (() => {
        if (contact) {
            if ((contactName as string) === contact.email) {
                return contact.username;
            }
            return contactName || contact.name || contact.username || contact.email;
        }
    })();

    return (
        <Text {...props}>
            {name}{' '}
            {withEmail && (
                <Text span weight={'lighter'}>
                    {contact.email}
                </Text>
            )}
        </Text>
    );
}

export default function UserDisplayedName({ user, ...props }: UserDisplayedNameProps & TextProps) {
    if (typeof user === 'string') {
        return <DisplayFromUsername user={user} {...props} />;
    } else if (user) {
        return <DisplayFromUserObject user={user} {...props} />;
    }

    return <Text>{user}</Text>;
}

function DisplayFromUsername({
    user,
    isAnonymousMeetingUser,
    contact,
    ...props
}: UserDisplayedNameProps & TextProps) {
    const { t } = useTranslation();
    const selectedUser = useAppSelector(
        selectUser((isAnonymousMeetingUser ? '' : user) as Username)
    );
    const anonymousUser = useAppSelector(
        selectAnonymousUser((isAnonymousMeetingUser ? user : '') as PmxApi.api.core.EccPubKey)
    );
    if (contact) {
        const apicontact = contact ? api.getContactSync(contact) : undefined;
        const company = apicontact?.companyId
            ? api.getCompanySync(apicontact?.companyId)
            : undefined;
        if (apicontact) {
            return (
                <Text>
                    {apicontact.name ?? apicontact.email}{' '}
                    {company ? (
                        <Text span weight={300}>
                            {company.name}
                        </Text>
                    ) : (
                        ''
                    )}
                </Text>
            );
        }
    }

    const name = (() => {
        if (selectedUser) {
            if (selectedUser.name === selectedUser.email) {
                return selectedUser.username;
            }
            return selectedUser.name || selectedUser.username || selectedUser.email;
        } else if (anonymousUser) {
            return anonymousUser.nickname;
        } else {
            return t('anonymousUserDisplayName');
        }
    })();

    return (
        <Text size="xs" opacity={isAnonymousMeetingUser ? 0.5 : 1} {...props}>
            {name}
        </Text>
    );
}

function DisplayFromUserObject({
    user,
    withEmail,
    ...props
}: { user: User; withEmail?: boolean } & TextProps) {
    const name = (() => {
        if (user) {
            if ((user.name as string) === user.email) {
                return user.username;
            }
            return user.name || user.username || user.email;
        }
    })();

    return (
        <Text {...props}>
            {name}{' '}
            {withEmail && (
                <Text span weight={'lighter'}>
                    {user.email}
                </Text>
            )}
        </Text>
    );
}
