import * as FormsTypes from '../FormsTypes';
import EmailInput from './EmailInput';
import LongInput from './LongInput';
import PhoneInput from './PhoneInput';
import SelectInput from './SelectInput';
import ShortInput from './ShortInput';
import SingleInput from './SingleInput';
export default function FormElement({ options }: { options: FormsTypes.RenderFormElementOptions }) {
    switch (options.model.inputType) {
        case 'select':
            return <SelectInput options={options} />;
        case 'single':
            return <SingleInput options={options} />;
        case 'short':
            return <ShortInput options={options} />;
        case 'long':
            return <LongInput options={options} />;
        case 'email':
            return <EmailInput options={options} />;
        case 'phone':
            return <PhoneInput options={options} />;
        default:
            return <></>;
    }
}
