import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '../../api/Api';
import { useTranslation } from 'react-i18next';
import {
    ActionIcon,
    Box,
    Button,
    Code,
    CopyButton,
    Divider,
    Flex,
    Group,
    Loader,
    rem,
    Skeleton,
    Stack,
    Switch,
    Text
} from '@mantine/core';
import { IconChevronLeft, IconCopy, IconEye, IconEyeOff } from '@tabler/icons-react';
import UserAvatar from '../../atoms/UserAvatar';
import { displayRelativeTime } from '../../utils/RelativeTimeFormat';
import { useAdminPanelStyles } from './SettingsMemberTab';
import useLoader from '../../hooks/useLoader';
import { notifications } from '@mantine/notifications';
import { useRouteContext } from './useSettingsScreenContext';
import { useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';

export default function UserDescriptionLoader() {
    const { classes } = useAdminPanelStyles();
    const { t } = useTranslation();
    const [routerContext, setRouterContext] = useRouteContext();

    const handleCloseDetail = useCallback(() => {
        const prevRoute = routerContext.route.split('/')[0];
        if (['contacts', 'staff'].find((x) => x === prevRoute))
            setRouterContext({ route: prevRoute as 'contacts' | 'staff' });
    }, [routerContext.route, setRouterContext]);

    return (
        <>
            <Stack h={`calc(100% - ${rem(16)})`} mt={'md'}>
                <Box className={classes.list} p="xl" w={'100%'}>
                    <Flex align="center">
                        <ActionIcon size="sm" onClick={handleCloseDetail}>
                            <IconChevronLeft />
                        </ActionIcon>
                        <Text span>User settings</Text>
                    </Flex>
                    <Divider my="xs" />
                    <Group mb="lg">
                        <UserAvatar size={65} loading showTooltip={false} />
                        <Box>
                            <Skeleton mb={4} height={24} width={120} />
                            <Skeleton height={24} width={250} />
                        </Box>
                    </Group>
                    <Box mb="md">
                        <Text color="dimmed">Added</Text>
                        <Skeleton height={24} width={100} />
                    </Box>
                    <Box mb="md">
                        <Text color="dimmed">Last login</Text>
                        <Skeleton height={24} width={100} />
                    </Box>

                    <Text color="dimmed">Actions</Text>
                    <Divider my="xs" />
                    <Flex align="center" mb="md">
                        <Text>Reset Password</Text>

                        <Skeleton height={36} width={80} ml="auto" />
                    </Flex>

                    <Flex gap={0} direction="column" py="md">
                        <Flex justify="flex-start" align="center" gap="xs" py={0}>
                            <Text color="red">
                                {t('screen.settings.userDescription.labels.blockedUser')}
                            </Text>

                            <Switch ml="auto" checked={false} disabled />
                        </Flex>
                        <Text size="xs" color="dimmed">
                            {t('screen.settings.userDescription.descriptions.blockedUser')}
                        </Text>
                    </Flex>
                </Box>
            </Stack>
        </>
    );
}

export function UserDescription() {
    const [userPassword, setUserPassword] = useState('');
    const { classes } = useAdminPanelStyles();
    const [routerContext, setRouterContext] = useRouteContext();
    const { state, revalidateQuiet } = useLoader(useCallback((x) => x.loadAccounts({}), []));
    const currentUser = useAppSelector(selectCurrentUser);

    const personForDisplay = useMemo(() => {
        if (state.type !== 'done') return;
        if (routerContext.route === `staff/detail`) {
            for (const staff of state.data.accounts[0]) {
                if (staff.id === routerContext.id) return staff;
            }
        }
        if (routerContext.route === 'contacts/detail') {
            for (const customer of state.data.accounts[1]) {
                if (customer.id === routerContext.id) return customer;
            }
        }
        return null;
    }, [routerContext.route, routerContext.id, state]);
    const isCurrentUser = currentUser.username === personForDisplay?.username;

    const displayPassword = useCallback(async () => {
        if (!personForDisplay) return;
        const encryptedAdminData = personForDisplay.adminData;
        if (!encryptedAdminData) {
            return;
        }
        const adminData = await api.getAdminData(encryptedAdminData);
        if (!adminData) {
            return;
        }
        if (!('generatedPassword' in adminData)) {
            return;
        }
        setUserPassword(adminData.generatedPassword);
    }, [personForDisplay]);

    useEffect(() => {
        displayPassword();
    }, [displayPassword]);

    const { t } = useTranslation();

    const [newGeneratedPassword, setGeneratedNewPassword] = useState<string | null>(null);
    const [changingPassword, setChangingPassword] = useState(false);
    const [processingAdminRights, setProcessingAdminRights] = useState(false);
    const [processingUserBlocked, setProcessingUserBlocked] = useState(false);

    const blockUser = useCallback(
        async (e: ChangeEvent<HTMLInputElement>) => {
            if (!personForDisplay?.username) return;
            try {
                setProcessingUserBlocked(true);
                await api.setUserBlocked(personForDisplay.username, e.currentTarget.checked);
                revalidateQuiet();
            } catch (e) {
                console.error('Error during block/unblock user', e);
                alert(t('errorMessage.couldNotBlockUnblockUser'));
            } finally {
                setProcessingUserBlocked(false);
            }
        },
        [personForDisplay?.username, t, revalidateQuiet]
    );

    const handleAdminRightsToggle = useCallback(
        async (e: ChangeEvent<HTMLInputElement>) => {
            if (!personForDisplay?.username) return;

            if (personForDisplay.role === 'staff') {
                try {
                    setProcessingAdminRights(true);
                    await api.setUserIsAdmin(personForDisplay.username, e.currentTarget.checked);
                    revalidateQuiet();
                } catch (e) {
                    console.error('Error during changing admin rights', e);
                    alert(t('errorMessage.couldNotChangeAdminRights'));
                } finally {
                    setProcessingAdminRights(false);
                }
            }
        },
        [personForDisplay?.role, personForDisplay?.username, t, revalidateQuiet]
    );

    const handleResetPasswordClick = useCallback(() => {
        (async () => {
            if (!personForDisplay?.username) return;
            try {
                setChangingPassword(true);
                const { generatedPassword } = await api.resetUserPassword(
                    personForDisplay.username,
                    personForDisplay.adminData
                );
                revalidateQuiet();
                setGeneratedNewPassword(generatedPassword);
            } catch (error) {
                console.error(error);
                notifications.show({
                    title: t('errorMessage.couldNotChangePassword'),
                    message: 'Try again later'
                });
            } finally {
                setChangingPassword(false);
            }
        })();
    }, [personForDisplay?.username, t, personForDisplay?.adminData, revalidateQuiet]);

    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

    const onActionIconClick = useCallback(async () => {
        setPasswordVisible((prev) => !prev);
    }, []);

    const handleCloseDetail = useCallback(() => {
        const prevRoute = routerContext.route.split('/')[0];
        if (['contacts', 'staff'].find((x) => x === prevRoute))
            setRouterContext({ route: prevRoute as 'contacts' | 'staff' });
    }, [routerContext.route, setRouterContext]);

    if (state.type === 'loading') {
        return <UserDescriptionLoader />;
    }

    if (!personForDisplay) return <Text align={'center'}>User not found</Text>;

    return (
        <Stack h={`calc(100% - ${rem(16)})`} mt={'md'}>
            <Box className={classes.list} p="xl" w={'100%'}>
                <Flex align="center">
                    <ActionIcon size="sm" mr="sn" onClick={handleCloseDetail}>
                        <IconChevronLeft />
                    </ActionIcon>
                    <Text span>User settings</Text>
                </Flex>
                <Divider my="xs" />
                <Group mb="lg">
                    <UserAvatar
                        size={65}
                        username={personForDisplay.username}
                        showTooltip={false}
                    />
                    <Box>
                        <Text>{personForDisplay.username}</Text>
                        <Text weight={300}>{personForDisplay.email}</Text>
                    </Box>
                </Group>
                <Box mb="md">
                    <Text color="dimmed">Added</Text>
                    <Group>
                        <Text>{displayRelativeTime(personForDisplay.creationDate)}</Text>
                        <Text>
                            {personForDisplay.origin?.createdBy &&
                                `by: ${personForDisplay.origin?.createdBy}`}
                        </Text>
                    </Group>
                </Box>
                <Box mb="md">
                    <Text color="dimmed">Last login</Text>
                    <Group>
                        <Text>{displayRelativeTime(personForDisplay.lastLoggedIn)}</Text>
                    </Group>
                </Box>
                {personForDisplay.generatedPassword && (
                    <Box>
                        <Text color="dimmed">Temporary password</Text>
                        <Flex mb="md" align="center">
                            <CopyButton value={newGeneratedPassword || userPassword}>
                                {({ copied, copy }) => (
                                    <>
                                        <Code color={copied ? 'green' : undefined}>
                                            {passwordVisible
                                                ? newGeneratedPassword || userPassword
                                                : '***-***'}
                                        </Code>
                                        <ActionIcon
                                            ml={4}
                                            size={'sm'}
                                            color={copied ? 'green' : undefined}
                                            onClick={onActionIconClick}>
                                            {passwordVisible ? (
                                                <IconEyeOff size={rem(16)} />
                                            ) : (
                                                <IconEye size={rem(16)} />
                                            )}
                                        </ActionIcon>
                                        <ActionIcon
                                            ml={'xs'}
                                            color={copied ? 'green' : undefined}
                                            onClick={copy}
                                            size={'sm'}>
                                            <IconCopy size={rem(16)} />
                                        </ActionIcon>
                                    </>
                                )}
                            </CopyButton>
                        </Flex>
                    </Box>
                )}
                {!isCurrentUser && (
                    <>
                        <Text color="dimmed">Actions</Text>
                        <Divider my="xs" />
                        <Flex align="center" mb="md">
                            <Text>Reset Password</Text>
                            {changingPassword ? (
                                <Box ml="auto" h={18} w={24}>
                                    <Loader size={rem(16)} />
                                </Box>
                            ) : (
                                <Button
                                    onClick={handleResetPasswordClick}
                                    ml="auto"
                                    size="sm"
                                    color="gray"
                                    variant="subtle"
                                    pr={0}>
                                    Reset
                                </Button>
                            )}
                        </Flex>
                        {'isAdmin' in personForDisplay && (
                            <Flex align="center" mb="md">
                                <Text>Admin permissions</Text>
                                {processingAdminRights ? (
                                    <Box ml="auto" h={18} w={24}>
                                        <Loader size={rem(16)} />
                                    </Box>
                                ) : (
                                    <Switch
                                        ml="auto"
                                        checked={personForDisplay.isAdmin}
                                        onChange={handleAdminRightsToggle}
                                    />
                                )}
                            </Flex>
                        )}
                        <Flex gap={0} direction="column" py="md">
                            <Flex justify="flex-start" align="center" gap="xs" py={0}>
                                <Text color="red">
                                    {t('screen.settings.userDescription.labels.blockedUser')}
                                </Text>
                                {processingUserBlocked ? (
                                    <Box ml="auto" h={18} w={24}>
                                        <Loader size={rem(16)} />
                                    </Box>
                                ) : (
                                    <Switch
                                        ml="auto"
                                        checked={personForDisplay.blocked}
                                        onChange={blockUser}
                                    />
                                )}
                            </Flex>
                            <Text size="xs" color="dimmed">
                                {t('screen.settings.userDescription.descriptions.blockedUser')}
                            </Text>
                        </Flex>
                    </>
                )}
            </Box>
        </Stack>
    );
}
