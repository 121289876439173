import { CopyButton, Tooltip, UnstyledButton, Code, Group, rem, CodeProps } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@mantine/hooks';
import { IconEye } from '@tabler/icons-react';

export default function TemporalPassword({
    password,
    hide,
    sx,

    ...props
}: { password: string; hide?: boolean } & Omit<CodeProps, 'children'>) {
    const { t } = useTranslation();
    const [open, { toggle }] = useDisclosure(!hide);

    return (
        <CopyButton value={password} timeout={2000}>
            {({ copied, copy }) => (
                <Tooltip
                    openDelay={1000}
                    label={copied ? t('successMessage.copied') : t('action.copy')}
                    withArrow
                    position="right">
                    <UnstyledButton
                        sx={{
                            maxWidth: '300px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'inline-block'
                        }}>
                        <Group spacing={4}>
                            <Code
                                onClick={copy}
                                c={copied ? 'teal' : undefined}
                                sx={{
                                    maxWidth: '300px',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    display: 'inline-block',
                                    ...sx
                                }}
                                {...props}>
                                {open ? password : '*** ***'}
                            </Code>
                            {hide ? <IconEye size={rem(12)} onClick={toggle} /> : null}
                        </Group>
                    </UnstyledButton>
                </Tooltip>
            )}
        </CopyButton>
    );
}
