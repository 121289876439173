import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../store';
import { User } from '../../types/Types';
import { useMemo, useState } from 'react';
import { Group, Title, Stack, ActionIcon, createStyles, Tabs, rem } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { usePreventClose } from '../../hooks/usePreventClose';
import { selectAdminSettingsInitialTab, toggleAdminSettingsModal } from '../../store/ModalsSlice';
import { StaffList } from './StaffList';
import { ContactsList } from './ContactsList';
import { UserDescription } from './UserDescription';
import { NewStaffMember } from './NewStaffMember';
import { getHoverColor } from '../../MantineConfigs';
import { PlanScreen } from './PlanScreen';
import {
    RouteContextProvider,
    RouteContextType,
    SelectableRoutes
} from './useSettingsScreenContext';
import { useTranslation } from 'react-i18next';

type FormData = Pick<User, 'name'>;

export const useSettingsStyle = createStyles((theme) => {
    return {
        element: {
            paddingTop: `${theme.spacing.sm}`,
            paddingBottom: `${theme.spacing.sm}`,
            alignItems: 'center'
        },
        hoverableElement: {
            marginInline: -8,
            paddingInline: 8,
            borderRadius: theme.radius.sm,
            '&:hover': {
                backgroundColor: getHoverColor(theme)
            }
        },
        withHover: {
            '&:hover': {
                marginInline: `calc(${theme.spacing.sm} * -1)`,
                paddingInline: `${theme.spacing.sm}`,
                borderRadius: theme.radius.sm,
                backgroundColor: getHoverColor(theme)
            }
        }
    };
});

const PANEL_HEIGHT = `calc(100% - ${rem(30 + 35 + 32)})`;

export function SettingsScreen() {
    const dispatch = useAppDispatch();

    const initialTab = useAppSelector(selectAdminSettingsInitialTab);
    const {
        formState: { isDirty }
    } = useForm<FormData>();
    usePreventClose(isDirty);

    const handleModalClose = () => {
        dispatch(toggleAdminSettingsModal({ open: false }));
    };

    const context = useState<RouteContextType>({ route: initialTab || 'staff' });

    const { t } = useTranslation();
    const tabs = useMemo(() => {
        return (
            <Tabs.List>
                <Tabs.Tab value="staff">{t('modal.adminSettings.tabs.staff')}</Tabs.Tab>
                <Tabs.Tab value="contacts">{t('modal.adminSettings.tabs.contacts')}</Tabs.Tab>
                <Tabs.Tab value="plan">{t('modal.adminSettings.tabs.plan')}</Tabs.Tab>
            </Tabs.List>
        );
    }, [t]);

    return (
        <RouteContextProvider context={context}>
            <Tabs
                h="100%"
                keepMounted={false}
                defaultValue="personal"
                value={context[0].route}
                onTabChange={(route) =>
                    context[1]({
                        route: route as SelectableRoutes
                    })
                }>
                <Stack p="md" mt="md" mx="auto" spacing={0} w="50%" h="100%" miw={900}>
                    <Group mb="sm">
                        <Title order={3} sx={{ textWeight: 'normal' }}>
                            <Group>{t('modal.adminSettings.settings')}</Group>
                        </Title>
                        <ActionIcon onClick={handleModalClose} ml="auto">
                            <IconX />
                        </ActionIcon>
                    </Group>
                    {tabs}
                    <Tabs.Panel value="staff" h={PANEL_HEIGHT}>
                        <StaffList />
                    </Tabs.Panel>
                    <Tabs.Panel value="contacts" h={PANEL_HEIGHT}>
                        <ContactsList />
                    </Tabs.Panel>
                    <Tabs.Panel value="contacts/detail" h={PANEL_HEIGHT}>
                        <UserDescription />
                    </Tabs.Panel>
                    <Tabs.Panel value="staff/detail" h={PANEL_HEIGHT}>
                        <UserDescription />
                    </Tabs.Panel>
                    <Tabs.Panel value="staff/new" h={'100%'}>
                        <NewStaffMember />
                    </Tabs.Panel>
                    <Tabs.Panel value="plan">
                        <PlanScreen />
                    </Tabs.Panel>
                </Stack>
            </Tabs>
        </RouteContextProvider>
    );
}
