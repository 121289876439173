import { Box } from '@mantine/core';
import { UserOption, Contact, UsernameOrContactId, ContactId, Username } from '../../types/Types';
import { UserUtils } from '../../utils/UserUtils';
import { UserSelectOptionType } from './types';
import * as i18next from 'i18next';
import { api } from '../../api/Api';

export function convertUserOptionToSelect(
    entries?: (UserOption | undefined)[]
): UserSelectOptionType[] {
    const mantineOptions: UserSelectOptionType[] = [];
    if (!entries) return mantineOptions;
    entries.forEach((entry) => {
        if (!entry) return;

        const contact = entry.email ? api.getContactByEmailSync(entry.email) : null;
        const isPrivateContact = contact ? contact.isPrivate : false;
        if (entry.value.type === 'contact') {
            const res = {
                label: entry.label,
                value: entry.value.contactId,
                type: 'contact' as 'contact' | 'user',
                data: entry.value.contactId,
                email: entry.email,
                isPrivateContact: isPrivateContact
            };
            mantineOptions.push(res);
            return;
        } else if (entry.value.type === 'user') {
            const res = {
                label: entry.label,
                value: entry.value.username,
                type: 'user' as 'contact' | 'user',
                data: entry.value.username,
                email: entry.email,
                isPrivateContact: isPrivateContact
            };
            mantineOptions.push(res);
            return;
        }
        throw new Error('Invalid entry type');
    });
    return mantineOptions;
}

export function querryToOption(entry: string, type: 'user' | 'contact'): UserSelectOptionType {
    return {
        label: entry,
        value: entry,
        type,
        data: entry,
        isPrivateContact: false
    };
}

// data: user.contact.id,
// label: user.contact.email,
// type: 'contact',
// value: user.contact.id,
// disabled: false,
// email: user.contact.email

export function contactToOption(contact: Contact): UserSelectOptionType {
    return {
        data: contact.id,
        label: contact.email,
        type: 'contact',
        value: contact.id,
        email: contact.email,
        disabled: false,
        isPrivateContact: contact.isPrivate
    };
}
export function toUserOption(entry: UserSelectOptionType): UsernameOrContactId {
    if (entry.type === 'contact') {
        return {
            type: 'contact',
            contactId: entry.value as ContactId
        };
    } else {
        return {
            type: 'user',
            username: entry.value as Username
        };
    }
}

export function getSelectCreateLabel(query: string, contacts: Contact[] | undefined) {
    query = query.toLocaleLowerCase();
    if (UserUtils.canCreateContact(query, contacts || [])) {
        return `+ ${i18next.t('action.createWithName', { name: query })}`;
    } else {
        return (
            <Box
                sx={{
                    '&:hover': {
                        backgroundColor: 'transparent'
                    }
                }}>
                {i18next.t('enterEmailToCreateContact')}
            </Box>
        );
    }
}
