import { useCallback, useEffect, useState } from 'react';

import {
    Box,
    BoxProps,
    Center,
    Collapse,
    ColorSchemeProvider,
    createStyles,
    Group,
    Image,
    MantineProvider,
    Navbar,
    rem,
    ScrollArea,
    Stack,
    ThemeIcon,
    UnstyledButton,
    Text,
    UnstyledButtonProps,
    Drawer,
    MediaQuery,
    ActionIcon,
    Button,
    useMantineColorScheme
} from '@mantine/core';

import {
    IconMail,
    IconVideo,
    IconUsers,
    IconAlignJustified,
    IconBookmark,
    IconBuilding,
    IconStar,
    IconArchive,
    IconSettings
} from '@tabler/icons-react';
import { SidebarCreateMessage, SidebarUserButton } from './GroupedLink';
import { useAppDispatch, useAppSelector } from '../../store';
import { toggleAdminSettingsModal, toggleNewMessageModal } from '../../store/ModalsSlice';
import { t } from 'i18next';
import { LinkProps, NavLink, NavLinkProps, useLocation, useSearchParams } from 'react-router-dom';
import { ReactNode } from 'react-markdown/lib/ast-to-react';
import { IconCloudUpload } from '@tabler/icons-react';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { selectisSidebarOpen, toggleSidebar } from '../../store/UserPreferenceSlice';
import { theme } from '../../utils/Theme/theme';
import { useDevMode } from '../../hooks/useDevMode';
import { CustomizationProvider } from '../../api/customization/CustomizationProvider';

const useSidebarStyles = createStyles((theme) => ({
    navbar: {
        paddingBottom: 0
    },

    header: {
        height: '64px',
        minHeight: '64px',
        paddingTop: 0,
        marginLeft: `calc(${theme.spacing.md} * -1)`,
        marginRight: `calc(${theme.spacing.md} * -1)`,
        color: theme.colorScheme === 'dark' ? theme.white : theme.black
    },

    links: {
        marginLeft: `calc(${theme.spacing.md} * -1)`,
        marginRight: `calc(${theme.spacing.md} * -1)`
    },

    linksInner: {
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl
    },

    linkIcon: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
        marginRight: theme.spacing.sm
    },

    footer: {
        borderTop: `1px solid ${theme.colors.dark[4]}`,
        paddingTop: theme.spacing.md
    }
}));

const ICON_SIZE = rem(16);

const useSidebarLinkStyles = createStyles((theme) => {
    return {
        root: {
            padding: `${theme.spacing.sm} ${theme.spacing.md}`,
            '&:hover': {
                backgroundColor: theme.colors.dark[6]
            },
            '&.active.matched': {
                backgroundColor: theme.fn.rgba(theme.colors.brand[8], 0.6),
                color: 'white'
            },
            '@media (min-width:48em) and (max-width:1200px)': {
                padding: theme.spacing.xs,
                paddingInline: 0
            }
        },
        content: {
            '@media (min-width:48em) and (max-width:1200px)': {
                alignContent: 'center',
                justifyContent: 'center',
                gap: 8
            }
        }
    };
});

export function SidebarButton({
    label,
    children,
    icon,
    className,
    ...props
}: { label: string; icon: ReactNode; onClick?: VoidFunction } & BoxProps) {
    const { classes, cx } = useSidebarLinkStyles();

    return (
        <Stack mb="xs" spacing={0}>
            <UnstyledButton className={cx(classes.root)} {...props}>
                <Group className={classes.content}>
                    <ThemeIcon variant="transparent" size={'sm'}>
                        {icon}
                    </ThemeIcon>
                    <MediaQuery
                        styles={{ width: '100%', textAlign: 'center', fontSize: 12 }}
                        largerThan={'sm'}
                        smallerThan={'lg'}>
                        <Text size={'sm'}>{label}</Text>
                    </MediaQuery>
                </Group>
            </UnstyledButton>
            {children && <Collapse in={true}>{children}</Collapse>}
        </Stack>
    );
}

export function SidebarLink({
    label,
    children,
    icon,
    className,
    ...props
}: { label: string; icon: ReactNode } & LinkProps & BoxProps) {
    const [params] = useSearchParams();
    const [active, setActive] = useState(false);
    const activeFilter = params.get('filter');
    const { classes, cx } = useSidebarLinkStyles();

    useEffect(() => {
        if (activeFilter) setActive(false);
        else setActive(true);
    }, [activeFilter]);

    return (
        <Stack mb="xs" spacing={0}>
            <UnstyledButton
                className={cx(classes.root, className, { matched: active })}
                component={NavLink}
                {...props}>
                <Group className={classes.content}>
                    <ThemeIcon variant="transparent" size={'sm'}>
                        {icon}
                    </ThemeIcon>
                    <MediaQuery
                        styles={{ width: '100%', textAlign: 'center', fontSize: 12 }}
                        largerThan={'sm'}
                        smallerThan={'lg'}>
                        <Text size={'sm'}>{label}</Text>
                    </MediaQuery>
                </Group>
            </UnstyledButton>
            {children && <Collapse in={true}>{children}</Collapse>}
        </Stack>
    );
}

const useSubLinkStyles = createStyles((theme) => {
    return {
        root: {
            padding: `${theme.spacing.xs} ${theme.spacing.md} ${theme.spacing.xs} 0`,
            marginLeft: `calc(${rem(32)} + ${rem(20)})`,
            display: 'block',
            borderLeft: `2px solid ${theme.colors.dark[5]}`,
            '&:hover': {
                backgroundColor: theme.colors.dark[6]
            },
            '&.active.matched': {
                borderLeft: `2px solid ${theme.colors.brand[8]}`,
                backgroundColor: theme.fn.rgba(theme.colors.brand[8], 0.6),
                color: 'white'
            }
        },
        fullScreenLink: {
            [`@media (max-width:${theme.breakpoints.lg})`]: {
                display: 'none'
            }
        }
    };
});

export function SidebarSubLink({
    label,
    filter,
    to,
    icon,
    ...props
}: { label: string; filter?: string; icon: React.ReactNode } & UnstyledButtonProps & NavLinkProps) {
    const [params] = useSearchParams();
    const [active, setActive] = useState(false);
    const activeFilter = params.get('filter');
    const { classes, cx } = useSubLinkStyles();
    useEffect(() => {
        if (filter === activeFilter) {
            setActive(true);
        } else {
            setActive(false);
        }
    }, [activeFilter, filter]);

    return (
        <>
            <UnstyledButton
                to={`${to}${filter ? `?filter=${filter}` : ''}`}
                className={cx(classes.root, classes.fullScreenLink, { matched: active })}
                component={NavLink}
                {...props}>
                <Box px={'md'}>
                    <Text size={'sm'}>{label}</Text>
                </Box>
            </UnstyledButton>
            <MediaQuery styles={{ display: 'none' }} largerThan={'lg'}>
                <UnstyledButton
                    component={NavLink}
                    to={`${to}${filter ? `?filter=${filter}` : ''}`}
                    display={'block'}
                    mx="auto"
                    className={cx(classes.root, { matched: active })}
                    sx={{
                        padding: 4,
                        margin: `calc(4px * -1)`,
                        border: '1px solid transparent',
                        borderRadius: theme.borderRadius.default
                    }}
                    {...props}>
                    <ActionIcon variant="transparent" component="div" mx="auto" size="lg">
                        {icon}
                    </ActionIcon>
                    <Text size="xs" align="center">
                        {label}
                    </Text>
                </UnstyledButton>
            </MediaQuery>
        </>
    );
}

function Sidebar() {
    const dispatch = useAppDispatch();
    const closeSidebar = useCallback(() => {
        dispatch(toggleSidebar({ newValue: false }));
    }, [dispatch]);

    const handleAddButtonClick = useCallback(() => {
        dispatch(toggleSidebar({ newValue: false }));
        dispatch(toggleNewMessageModal({ open: true }));
    }, [dispatch]);

    const { classes } = useSidebarStyles();

    const [devMode] = useDevMode();
    const currentUser = useAppSelector(selectCurrentUser);
    const hidden = useAppSelector(selectisSidebarOpen);
    const location = useLocation();
    const { colorScheme } = useMantineColorScheme();

    useEffect(() => {
        closeSidebar();
    }, [location.pathname, closeSidebar]);

    return (
        <ColorSchemeProvider colorScheme="dark" toggleColorScheme={() => {}}>
            <MantineProvider theme={{ colorScheme: 'dark' }} inherit>
                <Drawer
                    opened={hidden}
                    onClose={() => dispatch(toggleSidebar({ newValue: false }))}>
                    <Box p="sm" sx={{ height: '100%' }} mx="auto" w="90%">
                        <Image
                            height="3rem"
                            mx="auto"
                            src={CustomizationProvider.get().getSidebarLogo(colorScheme)}
                            alt="logo"
                            fit="contain"
                        />
                    </Box>
                    {currentUser.role === 'staff' && (
                        <Button
                            size="lg"
                            w="80%"
                            fz="sm"
                            mx="auto"
                            mb="lg"
                            display={'block'}
                            onClick={handleAddButtonClick}>
                            {t('action.newTopic')}
                        </Button>
                    )}
                    <SidebarLink
                        icon={<IconMail size={ICON_SIZE} />}
                        label={t('sidebar.topics')}
                        to="/chat"
                        onClick={closeSidebar}
                    />
                    <SidebarLink
                        icon={<IconVideo size={ICON_SIZE} />}
                        label={t('sidebar.meetings')}
                        to="/meetings"
                        onClick={closeSidebar}
                    />
                    <SidebarLink
                        icon={<IconBookmark size={ICON_SIZE} />}
                        label={t('sidebar.bookmarks')}
                        to="/bookmarks"
                        onClick={closeSidebar}
                    />
                    {currentUser.role === 'staff' && (
                        <SidebarLink
                            icon={<IconUsers size={ICON_SIZE} />}
                            label={t('sidebar.contacts')}
                            to="/contacts"
                            onClick={closeSidebar}
                        />
                    )}
                    {currentUser.role === 'staff' && (
                        <SidebarLink
                            icon={<IconBuilding size={ICON_SIZE} />}
                            label={t('sidebar.companies')}
                            to="/companies"
                            onClick={closeSidebar}
                        />
                    )}
                    <SidebarUserButton />
                </Drawer>
                <Navbar p="md" hiddenBreakpoint="sm" hidden={true} width={{ sm: 70, lg: 200 }}>
                    <Navbar.Section className={classes.header}>
                        <MediaQuery styles={{ display: 'none' }} smallerThan={'lg'}>
                            <Center>
                                <Box p="sm" sx={{ height: '100%' }} mx="auto">
                                    <Image
                                        height="2.5rem"
                                        mx="auto"
                                        src={CustomizationProvider.get().getSidebarLogo(
                                            colorScheme
                                        )}
                                        alt="logo"
                                        fit="contain"
                                    />
                                </Box>
                            </Center>
                        </MediaQuery>
                    </Navbar.Section>
                    <Navbar.Section grow className={classes.links} component={ScrollArea}>
                        <Box>
                            {currentUser.role === 'staff' && (
                                <SidebarCreateMessage onClick={handleAddButtonClick} />
                            )}
                            <SidebarLink
                                icon={<IconMail size={ICON_SIZE} />}
                                label={t('sidebar.topics')}
                                to="/chat">
                                <SidebarSubLink
                                    icon={<IconStar size={ICON_SIZE} />}
                                    label="Favorite"
                                    to="/chat"
                                    filter="favorite"
                                />
                                <SidebarSubLink
                                    mb="sm"
                                    icon={<IconArchive size={ICON_SIZE} />}
                                    label="Archived"
                                    to="/chat"
                                    filter="archived"
                                />
                            </SidebarLink>
                            <SidebarLink
                                icon={<IconVideo size={ICON_SIZE} />}
                                label={t('sidebar.meetings')}
                                to="/meetings"
                            />
                            {currentUser.role === 'staff' && (
                                <SidebarLink
                                    icon={<IconAlignJustified size={ICON_SIZE} />}
                                    label={t('sidebar.forms')}
                                    to="/forms"
                                />
                            )}
                            <Box className={classes.footer} py={0} mb="xs" mx="md"></Box>
                            {currentUser.role === 'staff' && (
                                <SidebarLink
                                    icon={<IconUsers size={ICON_SIZE} />}
                                    label={t('sidebar.contacts')}
                                    to="/contacts"
                                />
                            )}
                            {currentUser.role === 'staff' && (
                                <SidebarLink
                                    icon={<IconBuilding size={ICON_SIZE} />}
                                    label={t('sidebar.companies')}
                                    to="/companies"
                                />
                            )}

                            <SidebarLink
                                icon={<IconBookmark size={ICON_SIZE} />}
                                label={t('sidebar.bookmarks')}
                                to="/bookmarks"
                            />
                            {devMode && (
                                <SidebarLink
                                    icon={<IconCloudUpload size={ICON_SIZE} />}
                                    label={t('sidebar.sharedFiles')}
                                    to="/shared_files"
                                />
                            )}
                        </Box>
                    </Navbar.Section>
                    {currentUser.isAdmin && (
                        <Navbar.Section className={classes.links}>
                            <SidebarButton
                                onClick={() => {
                                    dispatch(toggleAdminSettingsModal({ open: true }));
                                }}
                                icon={<IconSettings size={ICON_SIZE} />}
                                label={t('sidebar.adminPanel')}
                            />
                        </Navbar.Section>
                    )}
                    <Navbar.Section className={classes.footer}>
                        <SidebarUserButton />
                    </Navbar.Section>
                </Navbar>
            </MantineProvider>
        </ColorSchemeProvider>
    );
}

export default Sidebar;
