import * as types from '../../../types/Types';
import { FileListElement } from './FileListElement';

export interface ListFilesViewProps {
    files: types.AttachmentEx[];
    onShowFilePreview: (fileId: types.AttachmentId) => void;
}

export function ListFilesView(props: ListFilesViewProps) {
    return (
        <div>
            {props.files.map((file) => (
                <FileListElement
                    key={file.id}
                    file={file}
                    onShowFilePreview={props.onShowFilePreview}
                />
            ))}
        </div>
    );
}
