import { ContactImportData } from './ContactImportData';
import { Csv } from './Csv';
import { VCard } from './VCard';

export interface ImportedContactsFile {
    name: string;
    content: string;
}

export class ContactsImporter {
    static parse(file: ImportedContactsFile): ContactImportData[] {
        if (file.name.endsWith('.vcf')) {
            return VCard.parse(file.content);
        } else if (file.name.endsWith('.csv')) {
            return Csv.parse(file.content);
        } else {
            throw new Error('Unsupported file type');
        }
    }
}
