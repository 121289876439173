import { Modal } from '@mantine/core';
import { BaseModalData } from '../../types/Modals';
import { AttachmentTagsEdit, AttachmentTagsEditData } from './index';

export interface AttachmentTagsEditModalData extends BaseModalData {
    options: AttachmentTagsEditData;
}

export function AttachmentTagsEditModal(props: {
    onClose: () => void;
    data: AttachmentTagsEditModalData;
}) {
    return (
        <Modal opened onClose={props.onClose}>
            <AttachmentTagsEdit
                attachmentTagsEdit={props.data.options}
                handleClose={props.onClose}
            />
        </Modal>
    );
}
