import { createStyles, rem } from '@mantine/core';

export const useTagsStyle = createStyles((theme) => ({
    details: {
        color: theme.colors.gray[6],
        minWidth: 0
    },
    tags: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    tagListElement: {
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        padding: `${rem(4)} ${rem(8)}`
    },
    hoverElement: {
        cursor: 'pointer',
        borderRadius: theme.radius.sm,
        '&:hover': {
            backgroundColor:
                theme.colorScheme === 'dark'
                    ? theme.fn.rgba(theme.colors.dark[3], 0.35)
                    : theme.colors.gray[1]
        }
    },
    addIcon: {
        '&:hover': {
            color: theme.colors['brand'][6],
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors['brand'][1]
        }
    }
}));

export const useTagStyles = createStyles((theme) => {
    return {
        inner: {
            textTransform: 'initial',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        root: {
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors['brand'][8] : theme.colors['brand'][1],

            '&:hover': {
                color:
                    theme.colorScheme === 'dark'
                        ? theme.colors['brand'][1]
                        : theme.colors['brand'][9],
                backgroundColor:
                    theme.colorScheme === 'dark'
                        ? theme.colors['brand'][7]
                        : theme.colors['brand'][2]
            }
        }
    };
});
