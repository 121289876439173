import { LoaderFunctionArgs } from 'react-router-dom';
import {
    feedApi,
    companyApi,
    chatApi,
    contactApi,
    fileApi,
    formApi,
    meetingApi,
    queryApi,
    userApi,
    api,
    favoriteApi
} from '../../api/Api';
import { TwofaResult } from '../../api/privmx/TwofaApi';
import { Utils } from '../../api/privmx/utils/Utils';
import { store } from '../../store';
import {
    AttachmentId,
    Chat,
    ChatHandler,
    ChatId,
    CompanyHandler,
    CompanyId,
    CompanyWithContacts,
    Contact,
    ContactHandler,
    ContactId,
    CustomerWithAdminData,
    Draft,
    DraftId,
    ElementHandler,
    FeedTypes,
    File,
    FormHandler,
    FormId,
    FormModel2,
    FullFavoriteMessage,
    Meeting,
    MeetingHandler,
    MeetingId,
    MessageId,
    Query,
    Username,
    UserWithAdminData
} from '../../types/Types';
import * as types from '../../types/Types';
import * as PmxApi from 'privmx-server-api';
import { emptyFormModel, getFormModel } from '../../data/formTemplates';

export interface FavoritesLoaderData {
    type: 'favorites';
    favorites: types.FullFavoriteMessage[];
}

export interface FavoriteLoaderData {
    type: 'favorite';
    favorite: types.FullFavoriteMessage;
}

export interface ChatsLoaderData {
    chats: types.Chat[];
}
export interface ChatLoaderData {
    chat: types.ChatEntryX;
    chatMessageDraft?: types.ThreadMessageDraft;
}
export interface ChatAddLoaderData {
    chatDraft?: types.ThreadDraftWithAttachments;
    users: types.UserOrContact[];
    managers: types.User[];
}

export interface ChatEditLoaderData {
    chat: types.Chat;
    users: types.UserOrContact[];
    managers: types.User[];
}

export interface CompaniesLoaderData {
    companies: types.CompanyWithContacts[];
}

export interface CompanyLoaderData {
    company: types.Company;
}

export interface CompanyEditLoaderData {
    company?: types.Company;
}

export interface ContactsLoaderData {
    contacts: types.Contact[];
    itemCountsGrouppedByUser: PmxApi.api.thread.ItemCountsGrouppedByUser;
}

export interface ContactLoaderData {
    contact: types.Contact;
    company?: types.Company;
}

export interface ContactEditLoaderData {
    contact: types.Contact | undefined;
    defaults?: Partial<types.Contact>;
    companies?: types.Company[];
}

export interface MeetingsLoaderData {
    meetings: types.Meeting[];
}

export interface MeetingLoaderData {
    meeting: types.MeetingEntryX;
    meetingMessageDraft?: types.ThreadMessageDraft;
}

export interface MeetingAddLoaderData {
    meetingDraft?: types.ThreadDraftWithAttachments;
    users: types.UserOrContact[];
    managers: types.User[];
}

export interface MeetingEditLoaderData {
    meeting?: types.Meeting;
    users: types.UserOrContact[];
    managers: types.User[];
}

export interface QueriesLoaderData {
    queries: types.Query[];
}

export interface FormsLoaderData {
    forms: types.FormModel2[];
}

export interface FormLoaderData {
    form: types.FormModelEntries;
}

export interface FormModelLoaderData {
    users: types.UserOrContact[];
    managers: types.User[];
    form: types.FormModel;
}

export interface FormPublicViewLoaderData {
    form: types.FormPublicView;
}

export interface AccountsLoaderData {
    accounts: types.Accounts;
}

export interface DraftsLoaderData {
    drafts: types.Draft[];
}

export interface DraftLoaderData {
    draft: types.Draft;
    chat?: types.Chat;
    meeting?: types.Meeting;
}

export interface FilesLoaderData {
    files: types.File[];
    chats: types.Chat[];
    meetings: types.Meeting[];
}

export interface FileLoaderData {
    file: types.File;
}

export interface FeedsLoaderData {
    feeds: types.Feed[];
}

export interface FeedLoaderData {
    feed: types.ElementHandler;
}

export interface MessageLoaderData {
    data: types.SingleChatMessageData;
}

export interface AttachmentVersionsLoaderData {
    main: types.Attachment;
    versions: types.Attachment[];
}
export interface EmailInboxAddLoaderData {
    users: types.UserOrContact[];
    managers: types.User[];
}

export interface EmailInboxEditLoaderData {
    emailInbox: types.FormModel;
    users: types.UserOrContact[];
    managers: types.User[];
}

function createLoader<T extends (...args: any[]) => any>(
    loader: T,
    forRouter: (loader: T) => (args: LoaderFunctionArgs) => ReturnType<T>
) {
    return {
        loader: loader,
        router: forRouter(loader)
    };
}
export const companyLoader = createLoader(
    async (companyId: CompanyId) => {
        const company = await companyApi.getCompany(companyId);
        if (!company) {
            throw new Error('Company not found');
        }
        const res: CompanyLoaderData = {
            company: company
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as CompanyId)
);

export const companyForAddingLoader = createLoader(
    async () => {
        const res: CompanyEditLoaderData = {
            company: undefined
        };
        return res;
    },
    (loader) => () => loader()
);

export const companyForEditingLoader = createLoader(
    async (companyId: CompanyId) => {
        const company = await contactApi.getCompany(companyId);
        if (!company) {
            throw new Error('Company not found');
        }
        const res: CompanyEditLoaderData = {
            company: company
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as CompanyId)
);

export function filterList<T>(
    list: T[],
    extractor: (x: T) => string,
    filter: string | string[] | undefined
) {
    const theFilter = Array.isArray(filter) ? filter[0] : filter;
    if (!theFilter) {
        return list;
    }
    const lowerFilter = theFilter.toLocaleLowerCase();
    return list.filter((x) => extractor(x).toLocaleLowerCase().includes(lowerFilter));
}

export function filterCompanies(companies: CompanyWithContacts[], filter: string | undefined) {
    return companies.filter((x) =>
        checkFilter(
            filter,
            [x.name, x.note, x.address, x.email, x.mobilePhone, x.phone, x.website],
            [],
            x.contacts.map((y) => y.username).filter(Utils.isDefined)
        )
    );
}

export const companiesLoader = createLoader(
    async (options: { filter?: string }) => {
        const companies = await companyApi.getCompaniesWithContacts();
        const res: CompaniesLoaderData = {
            companies: filterCompanies(companies, options.filter)
        };
        return res;
    },
    (loader) => (args) => {
        const url = new URL(args.request.url);
        return loader({ filter: url.searchParams.get('filter') ?? undefined });
    }
);

export const feedListLoader = createLoader(
    async () => {
        const feeds = await feedApi.getFeed();
        const res: FeedsLoaderData = {
            feeds: feeds
        };
        return res;
    },
    (loader) => () => loader()
);

export const feedLoader = createLoader(
    async (feedType: FeedTypes, id: string) => {
        const feed = await getElementHandler(feedType, id);
        if (!feed) {
            throw new Error('Feed not found');
        }
        const res: FeedLoaderData = {
            feed: feed
        };
        return res;
    },
    (loader) => (args) => loader(args.params.feedType as FeedTypes, args.params.id as string)
);

async function getElementHandler(
    feedType: FeedTypes,
    id: string
): Promise<ElementHandler | undefined> {
    if (feedType === 'company') {
        const company = await companyApi.getCompany(id as CompanyId);
        if (!company) {
            throw new Error('Company not found');
        }
        const res: CompanyHandler = {
            type: 'company',
            id: company.id,
            company
        };
        return res;
    }
    if (feedType === 'chat') {
        const chat = await chatApi.getChatWithMessages(id as ChatId);
        if (!chat) {
            throw new Error('Chat not found');
        }
        const draft = await chatApi.getThreadMessageDraft(chat.props.id);
        const res: ChatHandler = {
            type: 'chat',
            id: chat.props.id,
            chat,
            draft
        };
        return res;
    }
    if (feedType === 'contact') {
        const contact = await contactApi.getContact(id as ContactId);
        if (!contact) {
            throw new Error('Contact not found');
        }
        const company =
            contact && contact.companyId
                ? await contactApi.getCompany(contact.companyId)
                : undefined;
        const res: ContactHandler = {
            type: 'contact',
            id: contact.id,
            contact,
            company: company
        };
        return res;
    }
    if (feedType === 'meeting') {
        const meeting = await meetingApi.getMeetingWithMessages(id as MeetingId);
        if (!meeting) {
            throw new Error('Meeting not found');
        }
        const draft = await chatApi.getThreadMessageDraft(meeting.props.id);
        const res: MeetingHandler = {
            type: 'meeting',
            id: meeting.props.id,
            meeting,
            draft
        };
        return res;
    }
    if (feedType === 'form') {
        const form = await formApi.getFormEntry(id as FormId);
        if (!form) {
            throw new Error('Form not found');
        }
        const res: FormHandler = {
            type: 'form',
            id: form.id,
            form
        };
        return res;
    }
    return undefined;
}

export function filterChats(chats: Chat[], filter: string | undefined) {
    return chats.filter((x) => checkFilter(filter, [x.title], x.tags, x.users));
}

export const chatsLoader = createLoader(
    async (options: { filter?: string }) => {
        const chats = await chatApi.getChats();
        const res: ChatsLoaderData = {
            chats: filterChats(chats, options.filter)
        };
        return res;
    },
    (loader) => (args) => {
        const url = new URL(args.request.url);
        return loader({ filter: url.searchParams.get('filter') ?? undefined });
    }
);

export const chatLoader = createLoader(
    async (chatId: ChatId) => {
        const [chat, chatMessageDraft] = await Promise.all([
            chatApi.getChatWithMessagesX(chatId),
            chatApi.getThreadMessageDraft(chatId)
        ]);
        if (!chat) {
            throw new Error('Chat not found');
        }
        const res: ChatLoaderData = {
            chat,
            chatMessageDraft
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as ChatId)
);

export const chatForAddingLoader = createLoader(
    async (draftId?: DraftId) => {
        const [draft, users, managers] = await Promise.all([
            draftId ? contactApi.getDraftWithAttachments(draftId) : undefined,
            contactApi.getUsersAndContacts(),
            contactApi.getUsers()
        ]);
        const res: ChatAddLoaderData = {
            chatDraft:
                draft && draft.type === 'threadDraft' && draft.thread.threadType === 'chat'
                    ? draft
                    : undefined,
            users,
            managers
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as DraftId)
);

export const chatForEditingLoader = createLoader(
    async (chatId: ChatId) => {
        const [chat, users, managers] = await Promise.all([
            contactApi.getChat(chatId),
            contactApi.getUsersAndContacts(),
            contactApi.getUsers()
        ]);
        if (!chat) {
            throw new Error('Chat not found');
        }
        const res: ChatEditLoaderData = {
            chat,
            users,
            managers
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as ChatId)
);

export function filterFavorites(favorites: FullFavoriteMessage[], filter: string | undefined) {
    return favorites.filter((x) =>
        checkFilter(filter, [x.chat.title, x.message.text], x.chat.tags, x.chat.users)
    );
}

export const favoritesLoader = createLoader(
    async (options: { filter?: string }) => {
        const favorites = await favoriteApi.getFavoriteChatMessages();
        const res: FavoritesLoaderData = {
            type: 'favorites',
            favorites: filterFavorites(favorites, options.filter)
        };
        return res;
    },
    (loader) => (args) => {
        const url = new URL(args.request.url);
        return loader({ filter: url.searchParams.get('filter') ?? undefined });
    }
);

export function filterContacts(contacts: Contact[], filter: string | undefined) {
    return contacts.filter((x) =>
        checkFilter(
            filter,
            [x.name, x.address, x.email, x.mobilePhone, x.phone, x.note],
            [],
            x.username ? [x.username] : []
        )
    );
}

export const contactsLoader = createLoader(
    async (options: { filter?: string }) => {
        const [contacts, itemCountsGrouppedByUser] = await Promise.all([
            contactApi.getContacts(),
            contactApi.getItemCountsGrouppedByUsers()
        ]);
        const res: ContactsLoaderData = {
            contacts: filterContacts(contacts, options.filter),
            itemCountsGrouppedByUser
        };
        return res;
    },
    (loader) => (args) => {
        const url = new URL(args.request.url);
        return loader({ filter: url.searchParams.get('filter') ?? undefined });
    }
);

export const contactForAddingLoader = createLoader(
    async (defaults?: Partial<Contact>) => {
        const companies = await contactApi.getCompanies();
        const res: ContactEditLoaderData = {
            contact: undefined,
            companies,
            defaults
        };
        return res;
    },
    (loader) => () => loader()
);

export const contactForEditingLoader = createLoader(
    async (contactId: ContactId) => {
        const [contact, companies] = await Promise.all([
            contactApi.getContact(contactId),
            contactApi.getCompanies()
        ]);
        const res: ContactEditLoaderData = {
            contact,
            companies
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as ContactId)
);

export const contactLoader = createLoader(
    async (contactId: ContactId) => {
        const contact = await contactApi.getContact(contactId);
        if (!contact) {
            throw new Error('Contact not found');
        }
        const res: ContactLoaderData = {
            contact: contact,
            company: contact.companyId ? await contactApi.getCompany(contact.companyId) : undefined
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as ContactId)
);

export const messageLoader = createLoader(
    async (messageId: MessageId) => {
        const message = await chatApi.getMessage(messageId);
        const res: MessageLoaderData = {
            data: message
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as MessageId)
);

export const attachmentVersionsLoader = createLoader(
    async (attachmentId: AttachmentId) => {
        const versions = await api.getAttachmentHistory(attachmentId);
        const version = versions.find((x) => x.id === attachmentId);
        if (!version) {
            throw new Error('Cannot find main version');
        }
        const res: AttachmentVersionsLoaderData = {
            main: version,
            versions: versions
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as AttachmentId)
);

export function filterFiles(files: File[], filter: string | undefined) {
    return files.filter((x) => checkFilter(filter, [x.name], x.tags, x.contributors));
}

export const filesLoader = createLoader(
    async (options: { filter?: string }) => {
        const [files, chats, meetings] = await Promise.all([
            fileApi.getFiles(),
            chatApi.getChats(),
            meetingApi.getMeetings()
        ]);
        const res: FilesLoaderData = {
            files: filterFiles(files, options.filter),
            chats,
            meetings
        };
        return res;
    },
    (loader) => (args) => {
        const url = new URL(args.request.url);
        return loader({ filter: url.searchParams.get('filter') ?? undefined });
    }
);

export const fileLoader = createLoader(
    async (attachmentId: AttachmentId) => {
        const fileFull = await fileApi.getFile(attachmentId);
        if (!fileFull) {
            throw new Error('File not found');
        }
        const res: FileLoaderData = {
            file: fileFull
        };
        return res;
    },
    (loader) => (args) => loader(args.params.attachmentId as AttachmentId)
);

export const fileContentLoader = createLoader(
    async (attachmentId: AttachmentId) => {
        const file = await fileApi.getAttachment(attachmentId, false, {});
        if (!file) {
            throw new Error('File not found');
        }
        return file;
    },
    (loader) => (args) => loader(args.params.attachmentId as AttachmentId)
);

export function filterForms(forms: FormModel2[], filter: string | undefined) {
    return forms.filter((x) => checkFilter(filter, [x.name], x.tags, x.users));
}

export const formsLoader = createLoader(
    async (options: { filter?: string }) => {
        const forms = await formApi.getFormList();
        const res: FormsLoaderData = {
            forms: filterForms(forms, options.filter)
        };
        return res;
    },
    (loader) => (args) => {
        const url = new URL(args.request.url);
        return loader({ filter: url.searchParams.get('filter') ?? undefined });
    }
);

export const formLoader = createLoader(
    async (formId: FormId) => {
        const form = await formApi.getFormEntry(formId);
        if (!form) {
            throw new Error('Form not found');
        }
        const res: FormLoaderData = {
            form: form
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as FormId)
);

export const formModelLoader = createLoader(
    async (formId: FormId) => {
        const form = getFormModel(formId) ?? (await formApi.getFormEntry(formId));
        const [users, managers] = await Promise.all([
            contactApi.getUsersAndContacts(),
            contactApi.getUsers()
        ]);
        const res: FormModelLoaderData = {
            users,
            managers,
            form: form ?? emptyFormModel
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as FormId)
);

export const formPublicModelLoader = createLoader(
    async (formId: FormId) => {
        const isLoggedIn = store.getState().currentUser.id !== null;
        if (!isLoggedIn) {
            await api.ensureAnonSessionInitialized();
        }
        const model = await formApi.getPublicForm(formId);
        if (!model) {
            throw new Error('Form not found');
        }
        const res: FormPublicViewLoaderData = {
            form: model
        };
        return res;
    },
    (loader) => (args) => loader(args.params.modelId as FormId)
);

export const contactFormLoader = createLoader(
    async () => {
        const contactFormId = await api.getMainFormId();
        const contactForm = contactFormId ? await api.getPublicForm(contactFormId) : undefined;
        if (!contactForm) {
            throw new Error('Contact form not found');
        }
        const res: FormPublicViewLoaderData = {
            form: contactForm
        };
        return res;
    },
    (loader) => () => loader()
);

export function filterMeetings(meetings: Meeting[], filter: string | undefined) {
    return meetings.filter((x) => checkFilter(filter, [x.title], x.tags, x.users));
}

export const meetingsLoader = createLoader(
    async (options: { filter?: string }) => {
        const meetings = await meetingApi.getMeetings();
        const res: MeetingsLoaderData = {
            meetings: filterMeetings(meetings, options.filter)
        };
        return res;
    },
    (loader) => (args) => {
        const url = new URL(args.request.url);
        return loader({ filter: url.searchParams.get('filter') ?? undefined });
    }
);

export const meetingLoader = createLoader(
    async (meetingId: MeetingId) => {
        const [meeting, meetingMessageDraft] = await Promise.all([
            meetingApi.getMeetingWithMessagesX(meetingId),
            meetingApi.getThreadMessageDraft(meetingId)
        ]);
        if (!meeting) {
            throw new Error('Meeting not found');
        }
        const res: MeetingLoaderData = { meeting, meetingMessageDraft };
        return res;
    },
    (loader) => (args) => loader(args.params.id as MeetingId)
);

export const meetingForAddingLoader = createLoader(
    async (draftId?: DraftId) => {
        const [draft, users, managers] = await Promise.all([
            draftId ? contactApi.getDraftWithAttachments(draftId) : undefined,
            contactApi.getUsersAndContacts(),
            contactApi.getUsers()
        ]);
        const res: MeetingAddLoaderData = {
            meetingDraft:
                draft && draft.type === 'threadDraft' && draft.thread.threadType === 'meeting'
                    ? draft
                    : undefined,
            users,
            managers
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as DraftId)
);

export const meetingForEditingLoader = createLoader(
    async (meetingId: MeetingId) => {
        const [meeting, users, managers] = await Promise.all([
            contactApi.getMeeting(meetingId),
            contactApi.getUsersAndContacts(),
            contactApi.getUsers()
        ]);
        const res: MeetingEditLoaderData = {
            meeting,
            users,
            managers
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as MeetingId)
);

export function filterQueries(queries: Query[], filter: string | undefined) {
    return queries.filter((x) => checkFilter(filter, [x.name], [], []));
}

export const queriesLoader = createLoader(
    async (options: { filter?: string }) => {
        const queries = await queryApi.getQueries();
        const res: QueriesLoaderData = {
            queries: filterQueries(queries, options.filter)
        };
        return res;
    },
    (loader) => (args) => {
        const url = new URL(args.request.url);
        return loader({ filter: url.searchParams.get('filter') ?? undefined });
    }
);

export function filterDrafts(drafts: Draft[], filter: string | undefined) {
    return drafts.filter((x) =>
        checkFilter(
            filter,
            [getDraftName(x), x.message.text],
            getDraftTags(x),
            getDraftUsernames(x)
        )
    );
}

export const draftsLoader = createLoader(
    async (options: { filter?: string }) => {
        const drafts = await queryApi.getDrafts();
        const res: DraftsLoaderData = {
            drafts: filterDrafts(drafts ?? [], options.filter)
        };
        return res;
    },
    (loader) => (args) => {
        const url = new URL(args.request.url);
        return loader({ filter: url.searchParams.get('filter') ?? undefined });
    }
);

function getDraftName(draft: Draft) {
    if (draft.type === 'threadDraft') {
        return draft.thread.title;
    }
    return '';
}

function getDraftTags(draft: Draft) {
    if (draft.type === 'threadDraft') {
        return draft.thread.tags;
    }
    return [];
}

function getDraftUsernames(draft: Draft) {
    const usernames: Username[] = [];
    if (draft.type === 'threadDraft') {
        for (const el of draft.thread.users) {
            if (el.type === 'user') {
                usernames.push(el.username);
            }
        }
    }
    return usernames;
}

export const draftLoader = createLoader(
    async (draftId: DraftId) => {
        const draft = await meetingApi.getDraft(draftId);
        if (!draft) {
            throw new Error('Draft not found');
        }
        const chatOrMeeting =
            draft.type === 'threadMessageDraft'
                ? await api.getThreadAsChatOrMeeting(draft.threadId)
                : {};
        const chat =
            draft.type === 'threadMessageDraft' && chatOrMeeting.chat
                ? chatOrMeeting.chat
                : undefined;
        const meeting =
            draft.type === 'threadMessageDraft' && chatOrMeeting.meeting
                ? chatOrMeeting.meeting
                : undefined;
        if (draft.type === 'threadMessageDraft' && !chat && !meeting) {
            throw new Error('Thread associated with the draft not found');
        }
        const res: DraftLoaderData = {
            draft: draft,
            chat,
            meeting
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as DraftId)
);

export function filterUsers(users: UserWithAdminData[], filter: string | undefined) {
    return users.filter((x) => checkFilter(filter, [x.name], [], [x.username]));
}

export function filterCustomers(customers: CustomerWithAdminData[], filter: string | undefined) {
    return customers.filter((x) => checkFilter(filter, [x.name], [], [x.username]));
}

export const usersWithAdminDataLoader = createLoader(
    async (options: { filter?: string }) => {
        const [users, customers] = await Promise.all([
            userApi.getUsersWithAdminData(),
            userApi.getCustomersWithAdminData()
        ]);
        const res: AccountsLoaderData = {
            accounts: [
                filterUsers(users, options.filter),
                filterCustomers(customers, options.filter)
            ]
        };
        return res;
    },
    (loader) => (args) => {
        const url = new URL(args.request.url);
        return loader({ filter: url.searchParams.get('filter') ?? undefined });
    }
);

export interface SettingsLoaderData {
    twofaResult?: TwofaResult;
}

export const settingsLoader = createLoader(
    async () => {
        const twofaResult =
            (await api.getTwofaData()) ||
            ({ methods: [], data: { enabled: false } } as TwofaResult);
        if (!twofaResult.data) {
            twofaResult.data = { enabled: false };
        }
        return { twofaResult };
    },
    (loader) => () => loader()
);

export const emailInboxForAddingLoader = createLoader(
    async () => {
        const [users, managers] = await Promise.all([
            contactApi.getUsersAndContacts(),
            contactApi.getUsers()
        ]);
        const res: EmailInboxAddLoaderData = {
            users,
            managers
        };
        return res;
    },
    (loader) => () => loader()
);

export const emailInboxForEditingLoader = createLoader(
    async (emailInboxId: FormId) => {
        const [emailInbox, users, managers] = await Promise.all([
            contactApi.getForm(emailInboxId),
            contactApi.getUsersAndContacts(),
            contactApi.getUsers()
        ]);
        if (!emailInbox) {
            throw new Error('Email inbox not found');
        }
        const res: EmailInboxEditLoaderData = {
            emailInbox,
            users,
            managers
        };
        return res;
    },
    (loader) => (args) => loader(args.params.id as FormId)
);

export function checkFilter(
    filter: string | undefined,
    texts: string[],
    tags: string[],
    usernames: Username[]
): boolean {
    if (!filter) {
        return true;
    }
    filter = filter.trim().toLocaleLowerCase();

    if (filter[0] === '#') {
        const filterTag = filter.substring(1);
        for (const tag of tags) {
            if (tag.toLocaleLowerCase().startsWith(filterTag)) {
                return true;
            }
        }
    } else if (filter[0] === '@') {
        const filterUsername = filter.substring(1);
        for (const username of usernames) {
            if (username.toLocaleLowerCase() === filterUsername) {
                return true;
            }
        }
    } else {
        for (const text of texts) {
            if (text.toLocaleLowerCase().includes(filter)) {
                return true;
            }
        }
    }

    return false;
}

export function filterSharedFiles(sharedFiles: types.SharedFile[], filter: string | undefined) {
    return sharedFiles.filter((x) =>
        checkFilter(filter, [x.userData.title, x.userData.note], x.file.tags, [x.author])
    );
}
