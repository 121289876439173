import { useTranslation } from 'react-i18next';
import Modal from '../../atoms/Modal';
import { BaseModalData } from '../../types/Modals';
import { Attachment, InquiryAttachment, SharedFileFileAsAttachment } from '../../types/Types';
import { AttachmentPreview } from './index';

export interface AttachmentPreviewModalData extends BaseModalData {
    attachment: Attachment | InquiryAttachment | SharedFileFileAsAttachment;
}

export function AttachmentPreviewModal(props: {
    onClose: () => void;
    data: AttachmentPreviewModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();

    return (
        <Modal
            onFocus={props.onFocus}
            index={props.index}
            onClose={props.onClose}
            minWidth={0}
            styles={{ content: { minWidth: 400, maxWidth: 500, width: 'fit-content' } }}
            title={
                props.data.title ??
                (props.data.attachment.name
                    ? t('modal.attachmentPreview.titleWithFilename', {
                          filename: props.data.attachment.name
                      })
                    : t('modal.attachmentPreview.titleWithoutFilename'))
            }>
            <AttachmentPreview
                attachmentPreview={{ attachment: props.data.attachment }}
                handleClose={props.onClose}
            />
        </Modal>
    );
}
