import {
    Text,
    useMantineTheme,
    Button,
    Paper,
    Group,
    TextInput,
    Menu,
    rem,
    Center,
    Title,
    Divider,
    MediaQuery,
    createStyles
} from '@mantine/core';
import { FileWithPath, Dropzone } from '@mantine/dropzone';
import { useInputState } from '@mantine/hooks';
import { IconSearch, IconChevronDown, IconUsersPlus, IconX } from '@tabler/icons-react';
import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Outlet } from 'react-router-dom';
import RouteListContainer from '../../atoms/RouteScreen/RouteListContainer';
import {
    PostAddContactsModalData,
    PostAddContactsModal
} from '../../components/PostAddContactsModal';
import { useSortContacts } from '../../hooks/useSort';
import { EmptyState } from '../../mantineAtoms/EmptyState/EmptyState';
import {
    useFilterContext,
    DropDownElementsMap,
    FilterSelect
} from '../../mantineAtoms/FilterSelect';
import ScreenScrollContainer from '../../mantineAtoms/ScreenScrollContainer';
import { useAppSelector, useAppDispatch } from '../../store';
import { selectCachedCompaniesWithUsers } from '../../store/DataCacheSlice';
import { toggleNewContactModal } from '../../store/ModalsSlice';
import { CompanyId, Company, Tag } from '../../types/Types';
import { FileChooser } from '../../utils/FileChooser';
import { ContactListElement } from './ContactListElement/ContactListElement';
import { isDirtyContext } from './ContactsScreenView';
import { importContactsFromFiles, flattenImportResults } from './contactUtils';
import { ContactsScreenViewProps, ImprtedFileImportResultFlat } from './types';
import { ImportContactsModal } from './ImportContactsModal';
import { toggleSidebar } from '../../store/UserPreferenceSlice';
import { selectCurrentUser } from '../../store/CurrentUserSlice';

const styles = {
    root: {
        flexGrow: 1
    },
    rightSection: { width: 'fit-content' },
    wrapper: {
        '&:focus-within': {
            '& .mantine-Input-input': {
                borderColor: '#936FAD'
            }
        }
    }
};

export const useDividedViewStyles = createStyles(
    (theme, { contactId }: { contactId: undefined | string }) => {
        return {
            contactDetail: {
                width: '100%',
                minWidth: '100%',
                maxWidth: '100%',
                position: 'absolute',
                height: '100vh',
                zIndex: contactId ? 20 : -2,
                [`@media (min-width:${theme.breakpoints.md})`]: {
                    zIndex: 1,
                    position: 'static',
                    height: 'auto',
                    minWidth: 482
                },
                [`@media (min-width:${theme.breakpoints.lg})`]: {
                    width: '40%',
                    minWidth: 610,
                    backgroundColor: 'transparent'
                }
            }
        };
    }
);

export function ContactsList(props: ContactsScreenViewProps) {
    const { contactId } = useParams();
    const currentUser = useAppSelector(selectCurrentUser);

    const visibleContacts = useMemo(() => {
        return props.contacts.filter(
            (x) =>
                !x.isPrivate ||
                (currentUser.username !== null &&
                    x.privateContactVisibleForUsers.includes(currentUser.username))
        );
    }, [props.contacts, currentUser.username]);
    const contacts = useSortContacts(visibleContacts);

    const [query, setQuery] = useInputState('');
    const { contextState, filters } = useFilterContext();
    const companies = useAppSelector(selectCachedCompaniesWithUsers);

    const filteredContacts = useMemo(() => {
        let querriedContacts = contacts.filter(
            (x) =>
                x.email?.toLowerCase().includes(query?.toLowerCase()) ||
                x.username?.toLowerCase().includes(query?.toLowerCase()) ||
                x.name?.toLowerCase().includes(query?.toLowerCase())
        );

        for (const filter of filters) {
            switch (filter.type) {
                case 'company':
                    const comapny = companies.find((x) => x.name === filter.value);
                    if (comapny) {
                        querriedContacts = querriedContacts.filter((contact) =>
                            comapny.contacts.find(
                                (comapnyContact) => comapnyContact.email === contact.email
                            )
                        );
                    }

                    break;
                case 'tag':
                    querriedContacts = querriedContacts.filter((contact) =>
                        contact.tags.find((tag) => tag === filter.value)
                    );
                    break;
            }
        }

        return querriedContacts;
    }, [contacts, query, companies, filters]);

    const companyMap = useMemo(() => {
        const companies = new Map<CompanyId, Company>();
        props.companies.forEach((company) => {
            companies.set(company.id, company);
        });
        return companies;
    }, [props.companies]);

    const [isDirty, setIsDirty] = useState(false);
    const [importResults, setImportResults] = useState<ImprtedFileImportResultFlat[] | null>(null);
    const [postAddContactsModalData, setPostAddContactsModalData] =
        useState<PostAddContactsModalData | null>(null);

    const handleImportClick = useCallback(async () => {
        const files = await FileChooser.chooseFiles();
        const results = await importContactsFromFiles(files);
        setImportResults(flattenImportResults(results));
    }, []);

    const [isProcessing, setIsProcessing] = useState(false);

    const handleImportDrop = useCallback(async (droppedFiles: FileWithPath[]) => {
        setIsProcessing(true);
        const results = await importContactsFromFiles(droppedFiles);
        setImportResults(flattenImportResults(results));
        setIsProcessing(false);
    }, []);

    const handleCloseImportModal = useCallback(() => {
        setImportResults(null);
        const numAddedContacts = (importResults ?? []).filter(
            (x) => x.result === 'imported'
        ).length;
        if (numAddedContacts > 0) {
            setPostAddContactsModalData({
                numAddedContacts: numAddedContacts
            });
        }
    }, [importResults]);

    const handlePostAddContactsModalClose = useCallback(() => {
        setPostAddContactsModalData(null);
    }, []);

    const contactsTags = useMemo(() => {
        const tags = new Set<Tag>();
        for (const contact of contacts) {
            if (contact.tags.length) {
                contact.tags.forEach((tag) => {
                    tags.add(tag);
                });
            }
        }
        return Array.from(tags.values());
    }, [contacts]);

    const filterMap: DropDownElementsMap<'tag' | 'company'> = {
        company: FilterSelect.CompanyDropdown,
        tag: (props) => <FilterSelect.TagDropdown tags={contactsTags} {...props} />
    };

    const filterOptions = [
        {
            label: 'Tag',
            type: 'tag'
        },
        { label: 'Company', type: 'company' }
    ];
    const theme = useMantineTheme();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { classes } = useDividedViewStyles({ contactId });

    if (props.contacts.length === 0) {
        return (
            <RouteListContainer>
                <EmptyState
                    image="NO_USERS"
                    title="No Contacts yet"
                    subTitle="Add new Contacts."
                    button={
                        <Button
                            onClick={() => {
                                dispatch(toggleNewContactModal({ opened: true }));
                            }}>
                            New Contact
                        </Button>
                    }
                />
            </RouteListContainer>
        );
    }

    function showSidebar() {
        dispatch(toggleSidebar({}));
    }

    return (
        <RouteListContainer direction="row" pt={0}>
            <isDirtyContext.Provider
                value={{
                    isDirty,
                    set: setIsDirty
                }}>
                <Paper
                    sx={{
                        flexGrow: 1,
                        flexDirection: 'column',
                        display: 'flex',
                        borderTopRightRadius: 16,
                        borderBottomRightRadius: 16
                    }}>
                    <Group noWrap my="md" px="md" position="apart">
                        <MediaQuery largerThan={'sm'} styles={{ display: 'none' }}>
                            <Button onClick={showSidebar}>Menu</Button>
                        </MediaQuery>
                        <TextInput
                            placeholder={t('screen.contacts.placeholders.search')}
                            icon={<IconSearch size={14} />}
                            size="xs"
                            rightSection={
                                <FilterSelect
                                    dropdownElementMap={filterMap}
                                    filterOptions={filterOptions}
                                    size="xs"
                                />
                            }
                            styles={styles}
                            value={query}
                            onChange={setQuery}
                        />
                        <Button.Group>
                            <Button
                                variant="light"
                                size="xs"
                                onClick={() => dispatch(toggleNewContactModal({ opened: true }))}>
                                New Contact
                            </Button>
                            <Menu position="bottom-end" width={156}>
                                <Menu.Target>
                                    <Button variant="light" size="xs" px="xs">
                                        <IconChevronDown size={rem(14)} />
                                    </Button>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Menu.Item onClick={handleImportClick}>
                                        Import vcf/csv
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        </Button.Group>
                    </Group>
                    <Group mx="md">
                        {contextState.map((filter) => (
                            <FilterSelect.Tag key={filter} filter={filter} />
                        ))}
                    </Group>
                    <ScreenScrollContainer>
                        {filteredContacts.map((item, i) => (
                            <ContactListElement
                                active={contactId === item.id}
                                contactCompany={companyMap.get(item.companyId as any)}
                                key={item.id}
                                contact={item}
                            />
                        ))}
                    </ScreenScrollContainer>
                    {filteredContacts.length === 0 && (
                        <EmptyState
                            image="NO_MATCHES"
                            title="No matching results"
                            subTitle="Check for typos or try to adjust your filters."
                        />
                    )}
                </Paper>
                <Dropzone.FullScreen loading={isProcessing} active onDrop={handleImportDrop}>
                    <Group position="center" spacing="xl" mih={220} sx={{ pointerEvents: 'none' }}>
                        <Dropzone.Accept>
                            <IconUsersPlus
                                size="3.2rem"
                                stroke={1.5}
                                color={
                                    theme.colors[theme.primaryColor][
                                        theme.colorScheme === 'dark' ? 4 : 6
                                    ]
                                }
                            />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                            <IconX
                                size="3.2rem"
                                stroke={1.5}
                                color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                            />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                            <IconUsersPlus size="3.2rem" stroke={1.5} />
                        </Dropzone.Idle>

                        <Center h="100%">
                            <div>
                                <Title>Import contacts</Title>
                                <Text size="xl" inline>
                                    Drop .vcf files to import contacts in to Privmx Contact
                                </Text>
                            </div>
                        </Center>
                    </Group>
                </Dropzone.FullScreen>
                <Divider orientation="vertical" h="95%" my="auto" opacity={0.4} />
                <Paper className={classes.contactDetail}>
                    <Outlet />
                </Paper>
            </isDirtyContext.Provider>
            {importResults && importResults.length > 0 && (
                <ImportContactsModal
                    importResults={importResults}
                    handleClose={handleCloseImportModal}
                />
            )}
            {postAddContactsModalData && (
                <PostAddContactsModal
                    data={postAddContactsModalData}
                    index={0}
                    opened={true}
                    onClose={handlePostAddContactsModalClose}
                    onFocus={() => {}}
                />
            )}
        </RouteListContainer>
    );
}
