import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../store';
import { selectCachedCompanies } from '../../../store/DataCacheSlice';

import { Title } from '@mantine/core';

import { CompanyPageView } from './CompanyPageView';

export function CompanyPage() {
    const { companyId } = useParams();

    const companies = useAppSelector(selectCachedCompanies);

    const company = useMemo(
        () => companies.find((x) => x.id === companyId),
        [companyId, companies]
    );

    if (!company) {
        return (
            <Title color="dimmed" mx="auto">
                Company not found
            </Title>
        );
    }

    return <CompanyPageView company={company} key={company.id} />;
}
