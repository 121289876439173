import { DataEncryptor } from './DataEncryptor';
import { ServerId, ThreadData, ThreadSigned } from './ThreadService';
import * as PmxApi from 'privmx-server-api';
import { Base64 } from './utils/Base64';
import { ThreadSignatureData } from './ThreadService';
import * as privmx from 'privfs-client';

export class ThreadDataEncryptor extends DataEncryptor<ThreadData, PmxApi.api.thread.ThreadData> {
    async sign(data: ThreadSignatureData, priv: privmx.crypto.ecc.PrivateKey) {
        const buffer = Buffer.from(JSON.stringify(data), 'utf8');
        const signature = await privmx.crypto.service.signToCompactSignatureWithHash(priv, buffer);
        return {
            signatureData: Base64.from(buffer),
            signature: Base64.from(signature)
        };
    }

    decodeSignature(raw: PmxApi.api.thread.Thread, threadData: ThreadData, server: ServerId) {
        const { dataBuf, data } = (() => {
            if (threadData.signatureData) {
                const dataBuf = Base64.toBuf(threadData.signatureData);
                return {
                    dataBuf,
                    data: JSON.parse(dataBuf.toString('utf8')) as ThreadSignatureData
                };
            }
            const data: ThreadSignatureData = {
                id: raw.id,
                author: {
                    username: raw.creator,
                    pubKey: '' as PmxApi.api.core.EccPubKey,
                    server: server
                }
            };
            return { dataBuf: Buffer.alloc(0), data };
        })();
        if (
            raw.id !== data.id ||
            raw.creator !== data.author.username ||
            server !== data.author.server
        ) {
            throw new Error("Signature doesn't match to given thread");
        }
        const result: ThreadSigned = {
            server: server,
            data: data,
            dataBuf: dataBuf,
            signature: threadData.signature ? Base64.toBuf(threadData.signature) : Buffer.alloc(0)
        };
        return result;
    }
}
