import { Group, Text } from '@mantine/core';
import type * as threadTypes from '../../../../api/privmx/ThreadService';
import UserAvatar from '../../../../atoms/UserAvatar';
import { useTranslation } from 'react-i18next';
import { LastMessage, Message } from '../../../../types/Types';
import UserDisplayedName from '../../../../atoms/UserDisplayedName';

export interface UsersRemovedMessageProps {
    message: Message | LastMessage;
    data: threadTypes.UsersRemovedMessage;
}

export default function UsersRemovedMessage(props: UsersRemovedMessageProps) {
    const { t } = useTranslation();
    return (
        <>
            <Group>
                <Group>
                    <UserAvatar username={props.message.author}></UserAvatar>
                    <UserDisplayedName span color="dimmed" size="sm" user={props.message.author} />
                </Group>
                <Text color="dimmed" size="sm">
                    {t('successMessage.xRemovedFromThisConversation.beforeUser')}
                </Text>
            </Group>
            {props.data.removedUsernames.map((addedUsername) => (
                <Group key={addedUsername}>
                    <UserAvatar
                        key={addedUsername}
                        className="userAvatar"
                        username={addedUsername}
                    />
                    <UserDisplayedName span color="dimmed" size="sm" user={addedUsername} />
                </Group>
            ))}
            <Text color="dimmed" size="sm">
                {t('successMessage.xRemovedFromThisConversation.afterUser')}
            </Text>
        </>
    );
}
