import { Deferred } from '../../utils/Deferred';

interface ImageCropperUtilsOptions {
    imageUrl: string;
    previewWidth: number;
    previewHeight: number;
    targetWidth: number;
    targetHeight: number;
    setClipOverlayW: (w: number) => void;
    setClipOverlayH: (h: number) => void;
    setClipOverlayX: (x: number) => void;
    setClipOverlayY: (y: number) => void;
}

export class ImageCropperUtils {
    private imageDeferred = new Deferred<HTMLImageElement>();
    private previewImageScale: number = 1.0;
    private clipOverlayScale: number = 1.0;
    private clipOverlayW: number = 0;
    private clipOverlayH: number = 0;
    private clipOverlayX: number = 0;
    private clipOverlayY: number = 0;
    private maxClipOverlayX: number = 0;
    private maxClipOverlayY: number = 0;
    private movableDirection: 'x' | 'y' | 'none' = 'none';

    constructor(private options: ImageCropperUtilsOptions) {
        this.loadImage();
    }

    private loadImage() {
        const $el = document.createElement('img');
        $el.onload = () => {
            this.imageDeferred.resolve($el);
            this.previewImageScale = Math.min(
                this.options.previewWidth / $el.naturalWidth,
                this.options.previewHeight / $el.naturalHeight
            );
            this.clipOverlayScale = Math.min(
                $el.naturalWidth / $el.naturalHeight,
                $el.naturalHeight / $el.naturalWidth
            );
            this.clipOverlayW = this.clipOverlayScale * this.options.previewWidth;
            this.clipOverlayH = this.clipOverlayScale * this.options.previewHeight;
            const imgW = this.previewImageScale * $el.naturalWidth;
            const imgH = this.previewImageScale * $el.naturalHeight;
            this.movableDirection =
                imgW !== this.options.previewWidth
                    ? 'y'
                    : imgH !== this.options.previewHeight
                    ? 'x'
                    : 'none';
            this.maxClipOverlayX =
                this.movableDirection === 'x' ? this.options.previewWidth - this.clipOverlayW : 0;
            this.maxClipOverlayY =
                this.movableDirection === 'y' ? this.options.previewHeight - this.clipOverlayH : 0;
            this.clipOverlayX = (this.options.previewWidth - this.clipOverlayW) / 2;
            this.clipOverlayY = (this.options.previewHeight - this.clipOverlayH) / 2;
            this.options.setClipOverlayW(this.clipOverlayW);
            this.options.setClipOverlayH(this.clipOverlayH);
            this.options.setClipOverlayX(this.clipOverlayX);
            this.options.setClipOverlayY(this.clipOverlayY);
        };
        $el.src = this.options.imageUrl;
    }

    getMaxClipOverlayX() {
        return this.maxClipOverlayX;
    }

    getMaxClipOverlayY() {
        return this.maxClipOverlayY;
    }

    getMovableDirection() {
        return this.movableDirection;
    }

    getMinMaxMovableRect() {
        const direction = this.getMovableDirection();
        return {
            minX: direction === 'x' ? 0 : this.clipOverlayX,
            maxX:
                direction === 'x'
                    ? this.options.previewWidth - this.clipOverlayW
                    : this.clipOverlayX,
            minY: direction === 'y' ? 0 : this.clipOverlayY,
            maxY:
                direction === 'y'
                    ? this.options.previewHeight - this.clipOverlayH
                    : this.clipOverlayY,
            minW: this.clipOverlayW,
            maxW: this.clipOverlayW,
            minH: this.clipOverlayH,
            maxH: this.clipOverlayH
        };
    }

    async getFinalImage(clipOverlayX: number, clipOverlayY: number): Promise<string> {
        const img = document.createElement('img');
        const imgLoadPromise = new Promise<void>((resolve) => {
            img.onload = () => {
                resolve();
            };
        });
        img.src = this.options.imageUrl;
        await imgLoadPromise;

        const cnv = document.createElement('canvas');
        const ctx = cnv.getContext('2d');
        if (!ctx) {
            return this.options.imageUrl;
        }

        cnv.width = this.options.targetWidth;
        cnv.height = this.options.targetHeight;
        const scale = this.computeSrcImageScale(img.naturalWidth, img.naturalHeight);
        const srcX: number =
            this.movableDirection === 'x' ? clipOverlayX / this.previewImageScale : 0;
        const srcY: number =
            this.movableDirection === 'y' ? clipOverlayY / this.previewImageScale : 0;
        const srcW: number = this.options.targetWidth / scale;
        const srcH: number = this.options.targetHeight / scale;
        ctx.drawImage(
            img,
            Math.round(srcX),
            Math.round(srcY),
            Math.round(srcW),
            Math.round(srcH),
            0,
            0,
            this.options.targetWidth,
            this.options.targetHeight
        );

        return cnv.toDataURL('image/png', 1.0);
    }

    private computeSrcImageScale(origWidth: number, origHeight: number) {
        const underwidthRatio = this.options.targetWidth / origWidth;
        const underheightRatio = this.options.targetHeight / origHeight;
        const scale: number = Math.max(underwidthRatio, underheightRatio);
        return scale;
    }
}
