import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import {
    ContactOpt,
    FormModel,
    FormId,
    QuestionModel,
    User,
    Username,
    UsernameOrContactId,
    UserOrContact,
    Question,
    EditFormModelOptions
} from '../../types/Types';
import Block from './components/Block';
import InputFactory from './components/InputFactory';
import { api, formApi } from '../../api/Api';
import { formReducer } from './creatorReducer';
import useToast from '../../hooks/useToast';
import { useAppSelector } from '../../store';
import { selectFormTags } from '../../store/ModalsSlice';
import { useForm } from 'react-hook-form';
import { UsersSelector } from '../UsersSelector';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { useSearchParamsEx } from '../../hooks/useLoader';
import { newForm as newFormDefaults } from '../../api/defaultValues';
import {
    Anchor,
    Button,
    Checkbox,
    Code,
    Container,
    Group,
    List,
    Paper,
    ScrollArea,
    Select,
    Stack,
    Tabs,
    Text,
    TextInput,
    Textarea,
    Title,
    createStyles
} from '@mantine/core';
import TagsSelect from '../TagsSelect';
import { useElementSize } from '@mantine/hooks';
import { getInstructionCode } from './instructionCodeGenerator';
import { ImportForm } from './components/ImportForm';
import { ImportedForm } from '../../utils/FormImporter';
import * as PmxApi from 'privmx-server-api';
import { usePreventClose } from '../../hooks/usePreventClose';
import { useTranslation } from 'react-i18next';
import { UserUtils } from '../../utils/UserUtils';
import { selectUsernameToName } from '../../store/DataCacheSlice';
import { UrlBuilder } from '../../utils/UrlBuilder';
import { isNewFormId } from '../../screens/FormsScreen/FormCreator/components/FormState/useFormState';
import { Validator } from '../../utils/Validator';

interface CreatorProps {
    questions?: QuestionModel[];
    id?: FormId;
    name?: string;
    tags: string[];
    userList: UserOrContact[];
    managerList: User[];
    managers?: Username[];
    users?: Username[];
    autoResponseData?: PmxApi.api.inquiry.AutoResponseData;
    captchaEnabled?: boolean;
    onClose?: () => void;
}

interface FormData {
    users: { label: string; value: string; type: 'contact' | 'user' | 'staff' }[];
    managers: { label: string; value: string; type: 'contact' | 'staff' | 'user' }[];
}

type FormCreatorMode = 'creating' | 'editing';

export default function FormCreatorView(props: CreatorProps) {
    const { t } = useTranslation();
    const onClose = props.onClose;
    const [state, dispatch] = useReducer(formReducer, []);
    const [formName, setFormName] = useState<string>(props.name ?? t('form.newFormDefaultTitle'));
    const [tags, setTags] = useState<string[]>(props.tags ?? []);
    const [autoResponseEnabled, setAutoResponseEnabled] = useState(
        props.autoResponseData?.enabled ?? false
    );
    const [autoResponseEmailQuestion, setAutoResponseEmailQuestion] = useState<string | null>(
        props.autoResponseData?.emailFieldId ?? null
    );
    const [autoResponseTitle, setAutoResponseTitle] = useState(props.autoResponseData?.title ?? '');
    const [autoResponseMessage, setAutoResponseMessage] = useState(
        props.autoResponseData?.message ?? ''
    );
    const mode: FormCreatorMode = isNewFormId(props.id) ? 'creating' : 'editing';
    const [isProcessing, setIsProcessing] = useState(false);
    const emitToast = useToast();
    const currentUser = useAppSelector(selectCurrentUser);
    const [updateKeysWhenAddingUsers, setUpdateKeysWhenAddingUsers] = useState(true);
    const { navigateKeepingSearch } = useSearchParamsEx();
    const [isImportOpen, setIsImportOpen] = useState(false);
    const canManageForm = mode === 'creating' || props.managers?.includes(currentUser.username!);

    useEffect(() => {
        if (props.tags) {
            setTags(props.tags);
        }
    }, [props.tags]);

    const globalTags = useAppSelector(selectFormTags);
    const predefinedTags = useMemo(() => {
        if (!globalTags) return [];
        return globalTags.map((tag: string) => ({ label: tag, value: tag }));
    }, [globalTags]);

    const users = useMemo(() => {
        const res =
            props.users?.map((x) => {
                for (const entry of props.userList) {
                    if (entry.type === 'contact' && entry.contact.username === x) {
                        return {
                            type: 'contact',
                            contactId: entry.contact.id
                        } as UsernameOrContactId;
                    }
                }
                return {
                    type: 'user',
                    username: x
                } as UsernameOrContactId;
            }) || [];
        if (!res.find((x) => x.type === 'user' && x.username === currentUser.username)) {
            res.splice(0, 0, { type: 'user', username: currentUser.username! });
        }
        return res;
    }, [props.users, props.userList, currentUser.username]);

    const managers = useMemo(() => {
        const res = [...(props.managers ?? [])];
        if (!res.find((x) => x === currentUser.username)) {
            res.splice(0, 0, currentUser.username!);
        }
        return res;
    }, [props.managers, currentUser.username]);

    const contacts = useMemo(() => {
        return props.userList
            .filter((user) => user.type === 'contact')
            .map((user) => (user as ContactOpt).contact);
    }, [props.userList]);
    const displayMap = useAppSelector(selectUsernameToName);

    const {
        control,
        formState: { errors, isDirty },
        getValues,
        setError
    } = useForm<FormData>({
        defaultValues: {
            users: UserUtils.usernameOrIdToOption(users, props.userList, displayMap),
            managers: managers.map((x) => ({
                label: x,
                value: x,
                type: 'staff',
                email: props.managerList.find((u) => u.username === x)?.email
            }))
        }
    });
    usePreventClose(isDirty);

    const handleTagsChange = useCallback((value: string[]) => {
        setTags(value.map((item) => item));
    }, []);

    useEffect(() => {
        if (!isNewFormId(props.id) && props.name && props.questions) {
            dispatch({ type: 'load_form', questions: props.questions });
            setTimeout(() => {
                setAutoResponseEmailQuestion(props.autoResponseData?.emailFieldId ?? null);
            }, 100);
        }
    }, [props.id, props.name, props.questions, props.tags, props.autoResponseData?.emailFieldId]);

    const handleAddQuestion = useCallback(() => {
        dispatch({ type: 'add_question' });
    }, []);

    const handleOpenImportClick = useCallback(() => {
        setIsImportOpen(true);
    }, []);

    const handleCloseImportClick = useCallback(() => {
        setIsImportOpen(false);
    }, []);

    const [formNameError, setFormNameError] = useState<string | null>(null);

    async function handleSubmit(status?: FormModel['status']) {
        const formData = getValues();
        setIsProcessing(true);
        try {
            let isValid: boolean = true;
            if (!state.length) {
                isValid = false;
                emitToast(t('form.error.empty'), 'error');
            }
            if (!formName) {
                isValid = false;
                setFormNameError(t('form.error.titleEmpty'));
            }
            if (Validator.getErrors(formName)) {
                isValid = false;
                setFormNameError(
                    t('screen.formCreator.errors.incorectFormName', {
                        characters: Validator.getSafeDefaultChars()
                    })
                );
            }
            if (!formData.users.find((x) => x.value === currentUser.username)) {
                isValid = false;
                emitToast(t('form.error.incSelfInUsers'), 'error');
            }
            if (!formData.managers.find((x) => x.value === currentUser.username)) {
                isValid = false;
                emitToast(t('form.error.incSelfInManagers'), 'error');
            }
            let wrongUser: string = '';
            for (const user of formData.users) {
                wrongUser = user.value;
                const userErrors = Validator.getErrors(user.value, ['safeDefault', 'username']);
                if (userErrors) {
                    setError('users', { message: `Incorect user: ${wrongUser}` });
                    isValid = false;
                    break;
                }
            }

            let wrongManager: string = '';
            for (const user of formData.managers) {
                wrongManager = user.value;
                const userErrors = Validator.getErrors(user.value, ['safeDefault', 'username']);
                if (userErrors) {
                    setError('managers', { message: `Incorect user: ${wrongManager}` });
                    isValid = false;
                    break;
                }
            }

            if (isValid) return;
            if (autoResponseEnabled) {
                if (autoResponseEmailQuestion === null) {
                    isValid = false;
                    emitToast(t('form.error.autoRespEmailFieldEmpty'), 'error');
                }
                if (autoResponseTitle.trim().length === 0) {
                    isValid = false;
                    emitToast(t('form.error.autoRespTitleEmpty'), 'error');
                }
                if (autoResponseMessage.trim().length === 0) {
                    isValid = false;
                    emitToast(t('form.error.autoRespMessageEmpty'), 'error');
                }
            }
            if (isValid) {
                const users = formData.users.map((x) => ({
                    type: x.type === 'staff' ? 'user' : 'contact',
                    [x.type === 'contact' ? 'contactId' : 'username']: x.value
                }));
                const managers = formData.managers
                    .map((x) => (x.type === 'user' || x.type === 'staff' ? x.value : null))
                    .filter((x) => x !== null) as Username[];
                const autoResponseModel: PmxApi.api.inquiry.AutoResponseData = {
                    enabled: autoResponseEnabled,
                    emailFieldId: autoResponseEmailQuestion ?? '',
                    title: autoResponseTitle,
                    message: autoResponseMessage
                };
                if (mode === 'creating') {
                    const newFormModal = await formApi.createFormModel(
                        formName,
                        { type: 'questionModels', questionModels: state },
                        status || 'draft',
                        tags,
                        users as unknown as UsernameOrContactId[],
                        managers,
                        'inquiry',
                        autoResponseModel,
                        newFormDefaults.captchaEnabled,
                        true
                    );
                    navigateKeepingSearch(`./forms/${newFormModal.id}`);
                }
                if (mode === 'editing') {
                    const options: EditFormModelOptions = {
                        id: props.id || ('' as FormId),
                        tags: tags,
                        status: status,
                        newName: formName,
                        newQuestions: state,
                        users: users as unknown as UsernameOrContactId[],
                        managers: managers,
                        updateKeysWhenAddingUsers: updateKeysWhenAddingUsers,
                        autoResponseData: autoResponseModel,
                        captchaEnabled: props.captchaEnabled
                    };
                    await formApi.editFormModel(options);
                }
                emitToast(t('form.success.created'), 'success');
                onClose?.();
            }
        } catch (error) {
            console.error(error);
            emitToast(t('errorMessage.somethingWentWrong'), 'error');
        } finally {
            setIsProcessing(false);
        }
    }
    const [tab, setTab] = useState<'edit' | 'config' | 'instructions'>(
        canManageForm ? 'edit' : 'instructions'
    );
    const { ref: containerRef, height } = useElementSize();

    const handleImportForm = useCallback((form: ImportedForm) => {
        dispatch({ type: 'load_form', questions: form.questions });
        setIsImportOpen(false);
    }, []);

    useEffect(() => {
        if (
            autoResponseEmailQuestion !== null &&
            !state.some((x) => x.type === 'short' && x.id === autoResponseEmailQuestion)
        ) {
            setAutoResponseEmailQuestion(null);
        }
    }, [autoResponseEmailQuestion, state]);

    const autoResponseOptions = useMemo(() => {
        return state
            .filter((x) => x.type === 'short')
            .map((x) => ({ value: x.id, label: x.title }));
    }, [state]);

    const { classes, cx } = useFormCreatorStyles();

    return (
        <Tabs
            defaultValue="edit"
            h="100%"
            sx={{ flexGrow: 1 }}
            value={tab}
            onTabChange={(value) => setTab(value as any)}>
            <Stack h="100%" ref={containerRef}>
                <Group grow position="apart">
                    <div>
                        {canManageForm && (
                            <TextInput
                                error={formNameError}
                                maw={300}
                                variant="filled"
                                placeholder={t('entityProps.formName')}
                                value={formName}
                                onChange={(e) => setFormName(e.target.value)}
                            />
                        )}
                    </div>
                    <Tabs.List position="center" maw={280}>
                        {canManageForm && (
                            <>
                                <Tabs.Tab value="edit">{t('tab.edit')}</Tabs.Tab>
                                <Tabs.Tab value="config">{t('tab.config')}</Tabs.Tab>
                            </>
                        )}
                        <Tabs.Tab value="instructions">{t('tab.instructions')}</Tabs.Tab>
                    </Tabs.List>
                    <Group align="flex-end" sx={{ justifyContent: 'end' }}>
                        {canManageForm && (
                            <>
                                <Button onClick={() => handleSubmit()} variant="light">
                                    {t('action.save')}
                                </Button>
                                <Button onClick={() => handleSubmit('published')}>
                                    {t('action.publish')}
                                </Button>
                            </>
                        )}
                    </Group>
                </Group>
                <Tabs.Panel value="config">
                    <Container>
                        <Stack>
                            <TagsSelect
                                creatable
                                label={t('entityProps.tags')}
                                data={predefinedTags}
                                value={tags.map(
                                    (tag) =>
                                        predefinedTags.find((x) => x.label === tag)?.value ?? tag
                                )}
                                onChange={(val) => handleTagsChange(val)}
                            />
                            <UsersSelector
                                label={t('entityProps.users')}
                                control={control}
                                name="users"
                                contacts={contacts}
                                hasError={!!errors.users}
                                isDisabled={isProcessing}
                                creatable
                            />
                            <UsersSelector
                                label={t('entityProps.managers')}
                                control={control}
                                name="managers"
                                hasError={!!errors.managers}
                                isDisabled={isProcessing}
                            />
                            <Checkbox
                                checked={!updateKeysWhenAddingUsers}
                                onChange={(event) =>
                                    setUpdateKeysWhenAddingUsers(!event.currentTarget.checked)
                                }
                                disabled={isProcessing}
                                label={t('addedUsersCanSeeOldSubmits')}
                            />
                            <Title order={4}>{t('entityProps.autoResponse')}</Title>
                            <Checkbox
                                checked={autoResponseEnabled}
                                onChange={(event) =>
                                    setAutoResponseEnabled(event.currentTarget.checked)
                                }
                                name="autoResponseEnabled"
                                disabled={isProcessing}
                                label={t('entityProps.enableAutoResponse')}
                            />
                            <Select
                                value={autoResponseEmailQuestion}
                                onChange={setAutoResponseEmailQuestion}
                                data={autoResponseOptions}></Select>
                            <TextInput
                                value={autoResponseTitle}
                                onChange={(event) =>
                                    setAutoResponseTitle(event.currentTarget.value)
                                }
                                name="autoResponseTitle"
                                disabled={isProcessing}
                                label={t('entityProps.title')}
                            />
                            <Textarea
                                value={autoResponseMessage}
                                onChange={(event) =>
                                    setAutoResponseMessage(event.currentTarget.value)
                                }
                                name="autoResponseMessage"
                                disabled={isProcessing}
                                label={t('entityProps.message')}
                                minRows={7}
                            />
                        </Stack>
                    </Container>
                </Tabs.Panel>
                {tab === 'edit' && (
                    <ScrollArea w="100%" mih="calc(100% - 100px)" py="md">
                        {state.map((question, i) => (
                            <Block
                                key={i}
                                question={question}
                                dispatch={dispatch}
                                index={i}
                                focused>
                                <InputFactory question={question} index={i} dispatch={dispatch} />
                            </Block>
                        ))}
                        <Group>
                            <Button
                                ml="auto"
                                mr={mode === 'creating' ? 0 : 'auto'}
                                mt="lg"
                                display={'block'}
                                onClick={handleAddQuestion}>
                                {t('action.newQuestion')}
                            </Button>
                            {mode === 'creating' && (
                                <Button
                                    mr="auto"
                                    variant="light"
                                    mt="lg"
                                    display={'block'}
                                    onClick={handleOpenImportClick}>
                                    {t('action.import')}
                                </Button>
                            )}
                        </Group>
                        {isImportOpen && (
                            <div className={classes.cover}>
                                <div
                                    className={cx(classes.curtain, classes.cover)}
                                    onClick={handleCloseImportClick}
                                />
                                <div className={cx(classes.container)}>
                                    <Paper shadow="md" mt={100} p="xl" withBorder>
                                        <ImportForm
                                            alreadyHasQuestions={state.length > 0}
                                            onImportForm={handleImportForm}
                                        />
                                    </Paper>
                                </div>
                            </div>
                        )}
                    </ScrollArea>
                )}
                <FormCreatorInstructions
                    height={height - 40}
                    questions={props.questions}
                    id={props.id}
                />
            </Stack>
        </Tabs>
    );
}

const useFormCreatorStyles = createStyles((theme) => {
    return {
        cover: {
            position: 'absolute',
            inset: 0
        },
        curtain: {
            backdropFilter: 'blur(3px)',
            '&:after': {
                content: "''",
                display: 'block',
                background:
                    theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                opacity: 0.3,
                position: 'absolute',
                inset: 0
            }
        },
        container: {
            position: 'relative',
            width: 600,
            marginInline: 'auto'
        }
    };
});

export interface FormCreatorInstructionsProps {
    questions?: QuestionModel[];
    id?: FormId;
    height: number;
}

export function FormCreatorInstructions({ height, questions, id }: FormCreatorInstructionsProps) {
    const { t } = useTranslation();
    return (
        <Tabs.Panel value="instructions">
            <ScrollArea.Autosize mah={height}>
                <Container>
                    <Text mb="md">
                        {t('form.creatorInstructions.intro.beforeLink')}{' '}
                        <Anchor
                            href={UrlBuilder.buildUrl('privmx-simple-client.js')}
                            target="_blank">
                            privmx-simple-client
                        </Anchor>{' '}
                        {t('form.creatorInstructions.intro.afterLink')}:
                    </Text>
                    {questions && id && !isNewFormId(id) && (
                        <>
                            <Code block>
                                {getInstructionCode(
                                    api.getGatewayHost(),
                                    id,
                                    questions as Question[]
                                )}
                            </Code>
                            <List type="unordered" mt="xl" mb={100}>
                                <List.Item>{t('form.creatorInstructions.list.0')}</List.Item>
                                <List.Item>
                                    <Code>name</Code> {t('and')} <Code>value</Code>{' '}
                                    {t('form.creatorInstructions.list.1')}
                                </List.Item>
                            </List>
                        </>
                    )}
                    {(!questions || !id || isNewFormId(id)) && (
                        <Text mb="md">{t('form.creatorInstructions.willBeAvailAfterSaving')}</Text>
                    )}
                </Container>
            </ScrollArea.Autosize>
        </Tabs.Panel>
    );
}
