import {
    Modal,
    Flex,
    Box,
    Title,
    Text,
    TextInput,
    Group,
    Button,
    useMantineTheme,
    Anchor
} from '@mantine/core';
import { useInputState, useMediaQuery } from '@mantine/hooks';
import { t } from 'i18next';
import { useState, useMemo, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from '../../../store';
import { selectCachedContacts, addComapny, loadContacsAsync } from '../../../store/DataCacheSlice';
import { selectNewCompanyModalState, toggleNewComapnyModal } from '../../../store/ModalsSlice';
import {
    Contact,
    CompanyName,
    Email,
    CompanyWebsite,
    CompanyAddress,
    Phone,
    CompanyNote,
    MobilePhone
} from '../../../types/Types';
import { CompanyUsersList } from '../CompanyModal/CompanyUsersList';
import { UserSearch } from '../CompanyModal/UserSearch';
import { SearchElements } from '../CompanyModal/types';
import { api } from '../../../api/Api';
import { useUserInteraction } from '../../../hooks/useUserInteraction';
import { Link } from 'react-router-dom';
import { LoadingMessageOverlay } from '../../../mantineAtoms/LoadingMessageOverlay';
import { Validator } from '../../../utils/Validator';

export function getEmptyContactFromQuery(email: string): Contact {
    return {
        id: `new:${email}` as Contact['id'],
        avatar: 'undefined' as Contact['avatar'],
        name: '' as Contact['name'],
        email: email,
        phone: '' as Contact['phone'],
        mobilePhone: '' as Contact['mobilePhone'],
        address: 'undefined' as Contact['address'],
        note: 'undefined' as Contact['note'],
        language: 'en' as Contact['language'],
        userType: 'email' as Contact['userType'],
        tags: [] as Contact['tags'],
        pinned: false,
        archived: false,
        createdBy: 'undefined' as Contact['createdBy'],
        createdDate: 0 as Contact['createdDate'],
        lastModifiedBy: 'undefined' as Contact['lastModifiedBy'],
        lastModifiedDate: 0 as Contact['lastModifiedDate']
    } as Contact;
}

export function extractContactsToCreate(
    contacts: Contact[]
): [normalContact: Contact[], contactsToCreate: Contact[]] {
    const normalContacts = [];
    const contactsToCreate = [];

    for (const contact of contacts) {
        if (contact.id.startsWith('new:')) {
            contactsToCreate.push(contact);
        } else {
            normalContacts.push(contact);
        }
    }
    return [normalContacts, contactsToCreate];
}

export function NewCompanyModal() {
    const modalState = useAppSelector(selectNewCompanyModalState);

    const dispatch = useAppDispatch();

    const [members, setMembers] = useState<Contact[]>(modalState.initialUsers || []);
    const contacts = useAppSelector(selectCachedContacts);

    const [newContacts, setNewContacts] = useState<Contact[]>([]);

    const contactsToSearch = useMemo(() => {
        const filteredContacts: SearchElements = [];
        for (const contact of [...contacts, ...newContacts]) {
            if ((members || []).findIndex((usr) => usr.id === contact.id) === -1) {
                let name = '';
                if (contact.name && (contact.name as any) !== contact.email) {
                    name = contact.name;
                } else if (contact.username) {
                    name = contact.username;
                } else {
                    name = contact.email;
                }

                filteredContacts.push({
                    type: 'contact',
                    value: contact.id,
                    label: name ? name : '',
                    email: contact.email
                });
            }
        }
        return filteredContacts;
    }, [contacts, members, newContacts]);

    const [name, onNameChange] = useInputState(modalState.initialName);
    const [error, setError] = useState<{ type: string; message: string } | null>(null);

    const closeModal = useCallback(
        function () {
            dispatch(toggleNewComapnyModal({ opened: false, initialUsers: [] }));
        },
        [dispatch]
    );

    const { startInteraction, isProcessing } = useUserInteraction({
        successTitle: 'Company added',
        successMessage: (
            <Anchor component={Link} to="/companies">
                Go to Companies
            </Anchor>
        ),
        errorTitle: 'Error while adding new Company',
        errorMessage: 'Try reloading the app and adding the Company again.',
        async action(name: string) {
            const newCompany = {
                name: name.trim() as CompanyName,
                email: '' as Email,
                website: '' as CompanyWebsite,
                address: '' as CompanyAddress,
                phone: '' as Phone,
                note: '' as CompanyNote,
                mobilePhone: '' as MobilePhone,
                tags: [],
                pinned: false,
                archived: false
            };
            const [contacts, contactsToCreate] = extractContactsToCreate(members);
            const newContactsPromises = [];
            for (const member of contactsToCreate) {
                newContactsPromises.push(api.addContact(member));
            }
            const missingContacts = await Promise.all(newContactsPromises);
            const serverData = await api.addCompany(newCompany);
            dispatch(addComapny(serverData));
            await Promise.all([
                api.asssignCompanyToUsers(serverData.id, [...contacts, ...missingContacts] || [])
            ]);
            dispatch(loadContacsAsync());
            closeModal();
        }
    });

    const handleCreateCompany = useCallback(async () => {
        if (name.trim() === '') {
            setError({ type: 'input', message: 'Name is required' });
            return;
        }
        if (name.length >= 50) {
            setError({ type: 'input', message: 'Name cannot be longer than 50 characters' });
            return;
        }
        if (members.length === 0) {
            setError({ type: 'global', message: 'You have to add at least one user' });
            return;
        }
        try {
            const companyName = Validator.check(name);
            startInteraction(companyName);
        } catch (error) {
            setError({
                type: 'input',
                message: t('modal.companyAdd.error.incorectName', {
                    charcters: Validator.getSafeDefaultChars()
                })
            });
        }
    }, [startInteraction, members.length, name]);

    const theme = useMantineTheme();
    const isSmall = useMediaQuery(`max-width:${theme.breakpoints.md}`);

    return (
        <Modal
            keepMounted={true}
            transitionProps={{
                duration: 200,
                transition: 'slide-up',
                timingFunction: 'ease-in'
            }}
            size={isSmall ? '100%' : 'auto'}
            centered
            onClose={closeModal}
            opened={modalState.opened}
            styles={{
                content: {
                    minHeight: 510,
                    [`@media (max-width:${theme.breakpoints.sm})`]: {
                        width: '100%'
                    }
                }
            }}>
            <Flex
                p="xl"
                pt={'xs'}
                h="100%"
                pos="relative"
                w={{ base: '100%', sm: 600 }}
                maw={{ base: '100%', md: '60vw' }}
                direction={'column'}>
                <LoadingMessageOverlay visible={isProcessing} message="Adding new comapny" />
                <Box sx={{ flexGrow: 1 }} w="100%" mb="lg">
                    <Title order={2} mb="lg">
                        {t('modal.companyAdd.titleAddNewCompany')}
                    </Title>
                    <TextInput
                        error={error?.type === 'input' && error.message}
                        required
                        maxLength={50}
                        mb="sm"
                        placeholder={t('modal.companyAdd.placeholder.name')}
                        label={t('modal.companyAdd.label.name')}
                        onChange={onNameChange}
                        value={name}
                    />

                    <UserSearch
                        required
                        label={t('modal.companyAdd.label.members')}
                        placeholder={t('modal.companyAdd.placeholder.members')}
                        mb="sm"
                        onAddNewContact={(email) => {
                            const [prefix, newEmail] = email.split(':');
                            const newContact: Contact = getEmptyContactFromQuery(newEmail);
                            setNewContacts((prevContacts) => {
                                if (prefix === 'new') {
                                    return [...prevContacts, newContact];
                                }
                                return prevContacts;
                            });
                            setMembers((prevMembers) => {
                                const m = [
                                    ...(prevMembers || []),
                                    [...contacts, newContact].find((x) => x.id === email)
                                ].filter((x) => x) as Contact[];
                                return m;
                            });
                        }}
                        onChange={(id) => {
                            if (id) {
                                const [prefix] = id.split(':');
                                if (prefix !== 'new') {
                                    setMembers((prevMembers) => {
                                        return [
                                            ...(prevMembers || []),
                                            contacts.find((x) => x.id === id)
                                        ].filter((x) => x) as Contact[];
                                    });
                                }
                            }
                        }}
                        contacts={contactsToSearch}
                    />
                    {error?.type === 'global' && <Text color="red">{error.message}</Text>}
                    <Box h={200} display={'flex'} sx={{ flexGrow: 1 }}>
                        <CompanyUsersList
                            field={{ value: members }}
                            handleRemoveUser={(contactToRemove) => {
                                setMembers((prev) => {
                                    return prev.filter((x) => x.id !== contactToRemove.id);
                                });
                            }}>
                            {members && members.length === 0 && (
                                <Text align="center" color="dimmed">
                                    {t('infoMessage.noUsers')}
                                </Text>
                            )}
                        </CompanyUsersList>
                    </Box>
                </Box>
                <Group mx="auto">
                    <Button variant="light" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button onClick={handleCreateCompany}>Save</Button>
                </Group>
            </Flex>
        </Modal>
    );
}
