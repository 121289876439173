import { Box, Text, createStyles, rem } from '@mantine/core';
import { useTranslation } from 'react-i18next';

const usePrivateContactBadgeStyles = createStyles((theme) => {
    return {
        root: {
            display: 'inline-block',
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors['gray'][7] : theme.colors['gray'][5],
            color: '#fff',
            fontSize: `calc(${theme.fontSizes.xs} - 1px)`,
            padding: `${rem(1)} ${rem(6)}`,
            borderRadius: theme.radius.sm
        },
        inner: {
            textTransform: 'uppercase',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    };
});

export function PrivateContactBadge() {
    const { classes } = usePrivateContactBadgeStyles();
    const { t } = useTranslation();

    return (
        <Box className={classes.root}>
            <Text className={classes.inner}>{t('entityProps.hidden')}</Text>
        </Box>
    );
}
