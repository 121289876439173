import React, { ChangeEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { api } from '../../api/Api';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import {
    Attachment,
    AttachmentId,
    ChatId,
    MeetingId,
    Message,
    MessageId,
    Timestamp,
    Username,
    MessageClientId,
    MessageCreateModel,
    PreparedAttachment,
    DraftAttachmentEx,
    ThreadMessageDraftWithAttachments,
    ThreadId,
    DraftId
} from '../../types/Types';
import { chatApi } from '../../api/Api';
import AttachmentListItem from './AttachmentListItem';
import { clearTextEditor, selectFullEditorContent } from '../../store/FullTextEditorSlice';
import {
    ActionIcon,
    Box,
    Button,
    Checkbox,
    Group,
    Paper,
    Popover,
    Stack,
    Text,
    Indicator
} from '@mantine/core';
import { DraftUtils } from '../../utils/DraftUtils';
import {
    InputPreference as InputPreferences,
    InputPreferenceTypes
} from '../../store/UserPreferenceSlice';
import TextEditor from '../TextEditor/TextEditor';
import { useTextEditorConfig } from '../TextEditor/useTextEditorConfig';
import { IconChevronUp, IconPaperclip, IconVideo, IconX } from '@tabler/icons-react';
import { usePreventClose } from '../../hooks/usePreventClose';
import { CancelledByUserError } from '../../utils/CancelledByUserError';
import { toggleNewMeetingModal } from '../../store/ModalsSlice';
import { useTranslation } from 'react-i18next';

export interface AppMessage extends Message {
    failed: boolean;
}

export const emptyMessageCreator: (
    text: string,
    attachments: Attachment[],
    username: Username,
    id: MessageClientId,
    threadId: ChatId | MeetingId,
    mimeType: InputPreferenceTypes
) => AppMessage = (text, attachments, username, id, threadId, mimeType) => ({
    id: '' as MessageId,
    mimetype: InputPreferences[mimeType],
    msgId: id,
    author: username,
    authorIsAnonymousMeetingUser: api.isAnonymousMeetingClient(),
    date: Date.now() as Timestamp,
    text: text,
    attachments: attachments,
    emojis: [],
    edits: [],
    deleted: false,
    pending: true,
    decryptError: false,
    verified: 'verified',
    favorite: false,
    threadId: threadId,
    failed: false
});

type DraftLoadingState =
    | { state: 'not-loaded' }
    | { state: 'loading'; threadId: ThreadId }
    | { state: 'loaded'; threadId: ThreadId };

export interface SendMessageProps {
    chatId?: ChatId;
    meetingId?: MeetingId;
    chatMessageDraft?: ThreadMessageDraftWithAttachments;
    meetingMessageDraft?: ThreadMessageDraftWithAttachments;
    updatePendingMessages?: React.Dispatch<React.SetStateAction<Message[]>>;
    scrollDown: VoidFunction;
    inFullEditor?: boolean;
    onChange?: ChangeEventHandler<HTMLTextAreaElement>;
    chatName: string;
    minimalized: boolean;
    onSendEmailNotification?: () => void;
    variant?: 'default' | 'compact';
}

function SendMessageView({
    chatId,
    meetingId,
    chatMessageDraft,
    meetingMessageDraft,
    updatePendingMessages,
    scrollDown,
    onChange,
    chatName,
    minimalized,
    onSendEmailNotification,
    variant = 'default'
}: SendMessageProps) {
    const { t } = useTranslation();
    const currentUser = useAppSelector(selectCurrentUser);
    const currentUserId = api.isAnonymousMeetingClient() ? currentUser.pub : currentUser.username;
    const [isProcessing] = useState(false);
    const textEditorContent = useAppSelector((state) =>
        selectFullEditorContent(state, chatId || meetingId || '')
    ) as string;
    const [message, setMessage] = useState('');
    const draftLoadingState = useRef<DraftLoadingState>({ state: 'not-loaded' });
    const fullTextEditorSynced = useRef<boolean>(false);
    const draftUpToDate = useRef<boolean>(false);
    const editor = useTextEditorConfig({
        content: textEditorContent,
        onUpdate: ({ editor }) => {
            draftUpToDate.current = false;
            setMessage(editor.getHTML());
        }
    });

    const [attachments, setAttachments] = useState<(PreparedAttachment | DraftAttachmentEx)[]>(
        (chatMessageDraft ?? meetingMessageDraft)?.attachments ?? []
    );
    const [isEmojiDropDownVisible, setIsEmojiDropDownVisible] = useState(false);

    const textarea = useRef<HTMLTextAreaElement>(null);
    const componentWillUnmount = useRef(false);
    const [sendEmailNotificationOnSubmit, setSendEmailNotificationOnSubmit] = useState(false);
    const isDirty = attachments.length > 0 || (editor?.getText().trim() ?? '').length > 0;
    usePreventClose(isDirty);

    const dispatch = useAppDispatch();
    const fullTextEditorContent = useAppSelector((state) =>
        selectFullEditorContent(state, chatId || meetingId || '')
    );

    useEffect(() => {
        if (!editor?.commands) {
            return;
        }
        async function f() {
            const draftThreadId = chatMessageDraft?.threadId ?? meetingMessageDraft?.threadId;
            if (!draftThreadId) {
                return;
            }
            if (
                draftLoadingState.current.state === 'not-loaded' ||
                draftLoadingState.current.threadId !== draftThreadId
            ) {
                draftLoadingState.current = { state: 'loading', threadId: draftThreadId };
                const newDraft = await api.getThreadMessageDraft(draftThreadId);
                if (
                    draftLoadingState.current.state === 'loading' &&
                    draftLoadingState.current.threadId === draftThreadId
                ) {
                    if (newDraft) {
                        editor?.commands.setContent(newDraft.message.text, true);
                        setAttachments(newDraft.attachments);
                    }
                    draftLoadingState.current = { state: 'loaded', threadId: draftThreadId };
                }
                return;
            }
        }
        f();
    }, [
        editor?.commands,
        chatMessageDraft?.message.text,
        meetingMessageDraft?.message.text,
        chatMessageDraft?.threadId,
        meetingMessageDraft?.threadId
    ]);

    useEffect(() => {
        if (!fullTextEditorSynced.current && fullTextEditorContent) {
            setTimeout(() => {
                editor?.commands.setContent(fullTextEditorContent || '', true);
                fullTextEditorSynced.current = true;
            }, 1000);
            return;
        }
    }, [editor?.commands, fullTextEditorContent]);

    useEffect(() => {
        if (!isEmojiDropDownVisible) {
            return;
        }
        const handler = () => {
            setIsEmojiDropDownVisible(false);
        };
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [isEmojiDropDownVisible]);

    const handleFormSubmit = useCallback(async () => {
        const newMessageId = api.generateMessageId();
        const formatedText = textarea.current ? textarea.current.value : message;
        let cancelled = false;
        try {
            if (chatId) {
                if (updatePendingMessages)
                    updatePendingMessages((pendingMessages) => [
                        ...pendingMessages,
                        emptyMessageCreator(
                            formatedText,
                            DraftUtils.castDraftAttachmentExArrToAttachmentArr(attachments),
                            (currentUserId || '') as Username,
                            newMessageId,
                            chatId,
                            'html'
                        )
                    ]);

                await chatApi.sendMessage(
                    chatId,
                    'text/html',
                    formatedText,
                    newMessageId,
                    attachments
                );
            } else if (meetingId) {
                await chatApi.sendMeetingMessage(
                    meetingId,
                    'text/html',
                    formatedText,
                    newMessageId,
                    attachments
                );
            }
        } catch (error) {
            if (!(error instanceof CancelledByUserError)) {
                if (updatePendingMessages) {
                    updatePendingMessages((pendingMessages) =>
                        pendingMessages.map((x) => {
                            if (x.msgId === newMessageId) {
                                return { ...x, failed: true, pending: false };
                            } else {
                                return x;
                            }
                        })
                    );
                }

                throw error;
            }
            cancelled = true;
        }
        if (cancelled) {
            if (updatePendingMessages) {
                updatePendingMessages((pendingMessages) =>
                    pendingMessages.filter((x) => x.msgId !== newMessageId)
                );
            }
        } else {
            editor?.commands.setContent('');
            scrollDown();
            setMessage('');
            setAttachments([]);
            dispatch(clearTextEditor());
            if (sendEmailNotificationOnSubmit) {
                setSendEmailNotificationOnSubmit(false);
                onSendEmailNotification?.();
            }
            if (chatMessageDraft) {
                await api.deleteDraft(chatMessageDraft.id);
            }
            if (meetingMessageDraft) {
                await api.deleteDraft(meetingMessageDraft.id);
            }
        }
    }, [
        editor?.commands,
        attachments,
        chatId,
        chatMessageDraft,
        meetingId,
        meetingMessageDraft,
        currentUserId,
        updatePendingMessages,
        scrollDown,
        dispatch,
        message,
        sendEmailNotificationOnSubmit,
        onSendEmailNotification
    ]);

    useEffect(() => {
        return () => {
            componentWillUnmount.current = true;
        };
    }, []);

    const prevThreadIdRef = useRef<ThreadId | null>(null);
    const prevDraftIdRef = useRef<DraftId | null>(null);

    useEffect(() => {
        // const el = textarea.current;
        return () => {
            const didThreadIdChange =
                prevThreadIdRef.current !== null &&
                prevThreadIdRef.current !== (chatId ?? meetingId);
            const threadId = didThreadIdChange
                ? prevThreadIdRef.current
                : chatId ?? meetingId ?? null;
            const draftId = didThreadIdChange
                ? prevDraftIdRef.current
                : chatMessageDraft?.id ?? meetingMessageDraft?.id ?? null;
            prevThreadIdRef.current = chatId ?? meetingId ?? null;
            prevDraftIdRef.current = chatMessageDraft?.id ?? meetingMessageDraft?.id ?? null;
            if (
                (!componentWillUnmount.current && !didThreadIdChange) ||
                api.isAnonymousMeetingClient() ||
                draftUpToDate.current
            ) {
                return;
            }
            const text = message;
            const messageBasicModel: MessageCreateModel = {
                mimetype: 'text/html',
                text: text ?? ''
            };
            const existingAttachments = attachments.filter(
                (x) => 'draftId' in x
            ) as DraftAttachmentEx[];
            const newAttachments = attachments.filter(
                (x) => !('draftId' in x)
            ) as PreparedAttachment[];
            const draftMessage = DraftUtils.getThreadMessageCreateModel('chat', messageBasicModel);
            const f = async () => {
                if (draftId) {
                    const updatedDraft = DraftUtils.getThreadMessageDraftWithAttachments(
                        draftId,
                        threadId!,
                        draftMessage,
                        existingAttachments
                    );
                    await api.updateDraft(
                        updatedDraft,
                        newAttachments.map((x) => x.file)
                    );
                    draftUpToDate.current = true;
                } else {
                    const newDraft = DraftUtils.getThreadMessageDraftPropsForCreating(
                        threadId!,
                        draftMessage
                    );
                    const createdDraft = await api.createDraft(newDraft);
                    if (newAttachments.length > 0 && createdDraft) {
                        const updatedDraft = DraftUtils.getThreadMessageDraftWithAttachments(
                            createdDraft.id,
                            threadId!,
                            draftMessage,
                            existingAttachments
                        );
                        await api.updateDraft(
                            updatedDraft,
                            newAttachments.map((x) => x.file)
                        );
                        draftUpToDate.current = true;
                    }
                }
            };
            try {
                f();
            } catch (error) {}
        };
    }, [
        message,
        attachments,
        chatId,
        meetingId,
        chatMessageDraft?.id,
        meetingMessageDraft?.id,
        textarea
    ]);

    const handleAttachFilesClick = useCallback(async () => {
        const newAttachments = await api.chooseFilesAsAttachments();
        if (newAttachments.length > 0) {
            setAttachments((attachments) => [...attachments, ...newAttachments]);
        }
    }, [setAttachments]);

    const handleDeleteAttachmentClick = useCallback(
        (attachmentId: AttachmentId) => {
            setAttachments((prev) => {
                api.deleteAttachment(attachmentId);
                return prev.filter((attachment) => attachment.id !== attachmentId);
            });
        },
        [setAttachments]
    );

    const handleScheduleMeetingClick = useCallback(() => {
        const threadId = chatId ?? meetingId;
        if (!threadId) {
            return;
        }
        const threadUsers = api.getThreadUsernames(threadId);
        dispatch(
            toggleNewMeetingModal({
                open: true,
                payload: {
                    users: threadUsers.users,
                    managers: threadUsers.managers,
                    basedOnThreadId: threadId
                }
            })
        );
    }, [dispatch, chatId, meetingId]);

    return (
        <Paper bg="transparent" sx={{ borderTop: '1px solid rgba(125,125,125,0.3)' }} radius={0}>
            <Stack p="md">
                <div>
                    <div className="form">
                        <Box h={160}>
                            <TextEditor
                                type="small"
                                editor={editor}
                                maxHeight={110}
                                onKeyDown={(e) => {
                                    if (e.ctrlKey && e.code === 'Enter') {
                                        handleFormSubmit();
                                    }
                                }}
                            />
                        </Box>
                        <input type="hidden" name="author" value={currentUser.username!} />
                    </div>
                </div>
                <Group position="apart" noWrap>
                    <Group spacing="sm">
                        <Button
                            leftIcon={<IconPaperclip size={16} />}
                            variant="subtle"
                            onClick={handleAttachFilesClick}>
                            {t('action.attachFiles')}
                        </Button>
                        {variant === 'default' && attachments.length !== 0 ? (
                            <Group spacing="xs" sx={{ flexWrap: 'wrap', minWidth: 0, flexGrow: 1 }}>
                                {attachments.map((attachment) => (
                                    <AttachmentListItem
                                        key={attachment.id}
                                        attachment={attachment}
                                        onDeleteClick={handleDeleteAttachmentClick}
                                    />
                                ))}
                            </Group>
                        ) : (
                            <></>
                        )}

                        {variant === 'compact' && attachments.length !== 0 ? (
                            <>
                                <Popover position="top-start" offset={10} width={552} shadow="sm">
                                    <Popover.Target>
                                        <Box>
                                            <Indicator label={attachments.length} size={16}>
                                                <ActionIcon>
                                                    <IconChevronUp />
                                                </ActionIcon>
                                            </Indicator>
                                        </Box>
                                    </Popover.Target>
                                    <Popover.Dropdown ml={-16}>
                                        <Stack>
                                            {attachments.map((attachment) => (
                                                <Paper p={0}>
                                                    <Group position="apart">
                                                        <Text size="sm">{attachment.name}</Text>
                                                        <ActionIcon size="xs">
                                                            <IconX
                                                                onClick={() =>
                                                                    handleDeleteAttachmentClick(
                                                                        attachment.id
                                                                    )
                                                                }
                                                            />
                                                        </ActionIcon>
                                                    </Group>
                                                </Paper>
                                            ))}
                                        </Stack>
                                    </Popover.Dropdown>
                                </Popover>
                            </>
                        ) : (
                            <></>
                        )}
                    </Group>

                    <Group>
                        <Button type="button" disabled={isProcessing} onClick={handleFormSubmit}>
                            {t('action.send')}
                        </Button>
                        {onSendEmailNotification && (
                            <Checkbox
                                label={t('action.sendEmailNotification')}
                                checked={sendEmailNotificationOnSubmit}
                                onChange={(event) =>
                                    setSendEmailNotificationOnSubmit(event.currentTarget.checked)
                                }
                            />
                        )}
                        {!onSendEmailNotification &&
                            currentUser.role === 'staff' &&
                            variant !== 'compact' && (
                                <Button
                                    variant="light"
                                    type="button"
                                    onClick={handleScheduleMeetingClick}
                                    leftIcon={<IconVideo />}>
                                    {meetingId
                                        ? t('action.scheduleNewMeeting')
                                        : t('action.scheduleMeeting')}
                                </Button>
                            )}
                    </Group>
                </Group>
            </Stack>
        </Paper>
    );
}

export default SendMessageView;
