import { Textarea } from '@mantine/core';
import * as FormsTypes from '../FormsTypes';
import { isValueAString } from '../utils/FormsUtils';
export default function LongInput({ options }: { options: FormsTypes.RenderFormElementOptions }) {
    const { inputRef, model, updateDirty } = options;
    const refreshDirty = (e: React.FormEvent<HTMLElement>) => {
        inputRef.current.isDirty = (e.currentTarget as any).value.length > 0;
        updateDirty();
    };
    return (
        <Textarea
            id={model.id}
            label={model.label}
            defaultValue={isValueAString(model.value) ? model.value : undefined}
            autosize
            minRows={2}
            maxRows={4}
            required={model.required}
            ref={inputRef}
            onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
                refreshDirty(e);
            }}
        />
    );
}
