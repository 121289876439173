import { Meeting, MeetingId } from '../../types/Types';
import React, { useCallback, useMemo, useState } from 'react';
import { Checkbox, Modal, Button, rem, TextInput, Divider } from '@mantine/core';
import RouteListContainer from '../../atoms/RouteScreen/RouteListContainer';
import { api } from '../../api/Api';
import ScreenScrollContainer from '../../mantineAtoms/ScreenScrollContainer';
import { ThreadsListActionsBar } from '../../mantineAtoms/ThreadsListActionsBar';
import { useAppDispatch, useAppSelector } from '../../store';
import { useSortMeetings } from '../../hooks/useSort';
import MeetingEditDialog from '../../components/MeetingPage/MeetingEditDialog';
import { toggleNewMeetingModal } from '../../store/ModalsSlice';
import { useTranslation } from 'react-i18next';
import { useActionBar } from '../ChatScreen/useActionBar';
import { useInputState } from '@mantine/hooks';
import { FilterContextProvider } from '../../mantineAtoms/FilterSelect';
import { NoItemsInfo } from '../../atoms/NoItemsInfo';
import { MeetingListElement } from './MeetingListElement';
import { IconSearch } from '@tabler/icons-react';
import { EmptyState } from '../../mantineAtoms/EmptyState/EmptyState';
import { selectCurrentUserRole } from '../../store/CurrentUserSlice';

interface MeetingsScreenViewProps {
    meetings: Meeting[];
    revalidate: (switchToLoadingState?: boolean | undefined) => void;
}

export default function MeetingsScreenView(props: MeetingsScreenViewProps) {
    return (
        <FilterContextProvider>
            <MeetingsList {...props} />
        </FilterContextProvider>
    );
}

export function MeetingsList({ meetings, revalidate }: MeetingsScreenViewProps) {
    const { t } = useTranslation();
    const [query, setQuery] = useInputState('');
    const sortedMeetings = useSortMeetings(meetings);

    const searchedElements = useMemo(
        () => sortedMeetings.filter((x) => x.title.toLowerCase().includes(query.toLowerCase())),
        [sortedMeetings, query]
    );

    const {
        filteredElements,
        handleBatchMutation,
        onCheckboxChange,
        setFilterState,
        selectedThreads,
        setSelectedItems,
        threadsMembers,
        threadsTags
    } = useActionBar(
        'Meeting',
        revalidate,
        meetings.map((x) => x.id),
        searchedElements
    );

    const [incomingMeetings, doneMeetings]: [Meeting[], Meeting[]] = useMemo(() => {
        const all = filteredElements as Meeting[];

        const incoming: Meeting[] = [];
        const done: Meeting[] = [];

        const now = Date.now();
        for (const meeting of all) {
            if (meeting.startDate + meeting.duration < now || meeting.isMeetingCancelled) {
                done.push(meeting);
                continue;
            }
            incoming.push(meeting);
        }

        return [incoming, done];
    }, [filteredElements]);

    const dispatch = useAppDispatch();
    const handleAddButtonClick = useCallback(() => {
        dispatch(
            toggleNewMeetingModal({
                open: true
            })
        );
    }, [dispatch]);

    const [meetingToEdit, setMeetingToEdit] = useState<null | MeetingId>(null);

    const handleEditChat = useCallback((meeting: MeetingId, e: React.MouseEvent) => {
        setMeetingToEdit(meeting);
    }, []);

    const currentUserRole = useAppSelector(selectCurrentUserRole);

    if (meetings.length === 0) {
        if (currentUserRole === 'staff') {
            return (
                <RouteListContainer>
                    <EmptyState
                        image="NO_MEETINGS"
                        title="No scheduled Meetings"
                        subTitle="Click here to plan one."
                        button={<Button onClick={handleAddButtonClick}>Schedule a Meeting</Button>}
                    />
                </RouteListContainer>
            );
        } else {
            return (
                <RouteListContainer>
                    <EmptyState
                        image="NO_MEETINGS"
                        title="No scheduled Meetings"
                        subTitle="We will notify you when the company starts a new Meeting."
                    />
                </RouteListContainer>
            );
        }
    }

    return (
        <RouteListContainer>
            <ThreadsListActionsBar
                batchMutations={handleBatchMutation}
                tags={threadsTags}
                onCheckboxChange={onCheckboxChange}
                setFilterState={setFilterState}
                threadsCount={selectedThreads.length}
                authors={threadsMembers}
                type={'Meeting'}
                buttons={
                    api.getUserType() === 'local' ? (
                        <Button size="xs" variant="light" onClick={handleAddButtonClick}>
                            {t('action.newMeeting')}
                        </Button>
                    ) : (
                        <></>
                    )
                }
                search={(filter) => {
                    return (
                        <TextInput
                            size={'xs'}
                            value={query}
                            onChange={setQuery}
                            rightSection={filter}
                            placeholder={'Search'}
                            icon={<IconSearch size={rem(16)} />}
                            styles={{
                                root: {
                                    flexGrow: 1,
                                    '@media (min-width: 62rem)': {
                                        flexGrow: 0,
                                        minWidth: 400
                                    }
                                },
                                rightSection: {
                                    width: 70
                                },
                                wrapper: {
                                    '&:focus-within': {
                                        '& .mantine-Input-input': {
                                            borderColor: '#936FAD'
                                        }
                                    }
                                }
                            }}
                        />
                    );
                }}></ThreadsListActionsBar>
            {filteredElements.length === 0 ? (
                <EmptyState
                    image="NO_MATCHES"
                    title="No matching results"
                    subTitle="Check for typos or try to adjust your filters"
                />
            ) : (
                <ScreenScrollContainer offsetScrollbars ml="md">
                    <Checkbox.Group
                        value={selectedThreads}
                        onChange={(value) => setSelectedItems(value as MeetingId[])}>
                        {filteredElements.length !== 0 && (
                            <>
                                {incomingMeetings.length ? (
                                    incomingMeetings.map((item, i) => (
                                        <MeetingListElement
                                            onEditClick={(e) =>
                                                handleEditChat(item.id as MeetingId, e)
                                            }
                                            key={item.id}
                                            meeting={item as Meeting}
                                            active={false}
                                            data-is-last={
                                                incomingMeetings.length === i + 1 ? 'true' : 'false'
                                            }
                                        />
                                    ))
                                ) : (
                                    <NoItemsInfo itemsType="scheduledMeetings" />
                                )}
                                {doneMeetings.length ? (
                                    <>
                                        <Divider labelPosition="center" label="Finished" />
                                        {doneMeetings.map((item) => (
                                            <MeetingListElement
                                                isDone
                                                onEditClick={(e) =>
                                                    handleEditChat(item.id as MeetingId, e)
                                                }
                                                key={item.id}
                                                meeting={item as Meeting}
                                                active={false}
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </Checkbox.Group>
                    <Modal
                        opened={meetingToEdit !== null}
                        size="md"
                        onClose={() => setMeetingToEdit(null)}
                        title={t('modal.meetingEdit.titleWithoutName')}
                        overlayProps={{ opacity: 0.2, blur: 3 }}>
                        <MeetingEditDialog
                            id={meetingToEdit!}
                            onClose={() => setMeetingToEdit(null)}
                        />
                    </Modal>
                </ScreenScrollContainer>
            )}
        </RouteListContainer>
    );
}
