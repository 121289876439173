import { useEffect, useRef } from 'react';
import Loading from '../../components/Loading';
import { LoadingState } from '../../hooks/useLoader';

import { useContext, createContext } from 'react';

interface StateViewState {
    revalidate: () => void;
}

const stateViewContext = createContext<StateViewState>({ revalidate() {} });

export const useStateView = () => useContext(stateViewContext);

const StateViewProvider = stateViewContext.Provider;

export default function StateView<T>({
    retry,
    ...props
}: {
    state: LoadingState<T>;
    loading?: (...args: any) => JSX.Element;
    retry?: () => void;
    children: (data: T) => React.ReactNode;
}) {
    const LoadingFromProps = props.loading;
    const retryTries = useRef(0);

    useEffect(() => {
        if (!retry) return;

        if (props.state.type === 'error' && retryTries.current < 3) {
            retry?.();
            retryTries.current++;
        }
    }, [props.state.type, retry]);

    return (
        <StateViewProvider
            value={{
                revalidate() {
                    retry?.();
                }
            }}>
            <>
                {props.state.type === 'loading' ? (
                    LoadingFromProps ? (
                        <LoadingFromProps />
                    ) : (
                        <Loading />
                    )
                ) : props.state.type === 'error' ? (
                    <div
                        style={{
                            textAlign: 'center'
                        }}>
                        <div
                            style={{
                                textAlign: 'center',
                                fontSize: '22px',
                                margin: '30px 0 20px 0',
                                color: '#ff4444'
                            }}>
                            Error: {errorToString(props.state.error)}
                        </div>
                        {retry && <button onClick={retry}>Retry</button>}
                    </div>
                ) : props.state.type === 'done' ? (
                    props.children(props.state.data)
                ) : (
                    <div>Unknown state</div>
                )}
            </>
        </StateViewProvider>
    );
}

function errorToString(e: any) {
    if (e && e.msg && e.data && e.data && e.data.error && e.data.error.code) {
        return `${e.msg} (0x${e.data.error.code.toString(16)})`;
    }
    return e + '';
}
