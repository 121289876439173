import { FormId, ScreenParams, UrlRoute } from '../types/Types';

export type OS = 'Unknown' | 'Linux' | 'Android' | 'Windows' | 'Mac';

const colorsMantine = [
    'red',
    'pink',
    'grape',
    'violet',
    'indigo',
    'blue',
    'cyan',
    'green',
    'lime',
    'yellow',
    'orange'
] as const;

export class Utils {
    static getOperatingSystem() {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.platform;
        if (platform.toLowerCase().includes('mac') && userAgent.toLowerCase().includes('mac')) {
            return 'Mac' as OS;
        } else if (
            platform.toLowerCase().includes('win') &&
            userAgent.toLowerCase().includes('win')
        ) {
            return 'Windows' as OS;
        } else if (userAgent.toLowerCase().includes('android')) {
            return 'Android' as OS;
        } else if (
            platform.toLowerCase().includes('linux') &&
            userAgent.toLowerCase().includes('linux')
        ) {
            return 'Linux' as OS;
        }
        return 'Unknown' as OS;
    }

    protected static readonly entityMap: { [name: string]: string } = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;'
    };

    static escapeHtml(str: string | number | boolean): string {
        if (str == null) {
            return '';
        }
        const ss = typeof str == 'string' ? str : str.toString();
        return ss.replace(/[&<>"'`=/]/g, (s) => {
            return this.entityMap[s];
        });
    }

    static escapeAttributeValue(str: string | number | boolean) {
        if (str == null) {
            return '';
        }
        const ss = typeof str == 'string' ? str : '' + str;
        return ss.replace(/"/g, '&quot;');
    }

    static buildQueryString(path: string, query: { [key: string]: string | boolean | number }) {
        const q = new URLSearchParams();
        for (const key in query) {
            q.append(key, '' + query[key]);
        }
        return path + '?' + q.toString();
    }

    static readHashAsRoute(): UrlRoute {
        const hash = window.location.hash;
        if (!hash.startsWith('#/')) {
            return { route: '', params: {} };
        }
        const url = new URL(document.location.hash.substring(1), 'http://localhost');
        const params: ScreenParams = {};
        for (const [key, value] of url.searchParams) {
            params[key] = value;
        }
        return { route: url.pathname.substring(1), params: params };
    }

    static bytesSize(size: number) {
        if (size <= 0) {
            return '0B';
        }

        const base = 1024;
        const exp = Math.floor(Math.log(size) / Math.log(base));
        const result = size / Math.pow(base, exp);
        const rounded = Math.round(Math.floor(result * 100) / 100);
        return rounded + ' ' + (exp === 0 ? '' : 'KMGTPEZY'[exp - 1]) + 'B';
    }

    static extractFormId(text: string) {
        const linkIndex = text.indexOf('/form-answer/');
        if (!linkIndex) return undefined;
        const start = linkIndex + '/form-answer/'.length;
        const formId = text.substring(start, start + 19) as FormId;
        return formId || undefined;
    }

    static isDeepEqual<T>(a: T, b: T): boolean {
        if (a === b) {
            return true;
        }
        if (typeof a == 'object' && typeof b == 'object' && a != null && b != null) {
            if (Array.isArray(a) && Array.isArray(b)) {
                if (a.length !== b.length) {
                    return false;
                }
                for (let i = 0; i < a.length; i++) {
                    if (!this.isDeepEqual(a[i], b[i])) {
                        return false;
                    }
                }
                return true;
            }
            if (Array.isArray(a) || Array.isArray(b)) {
                return false;
            }
            for (const key in a) {
                if (!this.isDeepEqual(a[key], b[key])) {
                    return false;
                }
            }
            for (const key in b) {
                if (!this.isDeepEqual(a[key], b[key])) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    static extractBufferFromDatatUrl(dataUrl: string): Buffer {
        const comaIndex = dataUrl.indexOf(',');
        return Buffer.from(dataUrl.substring(comaIndex + 1), 'base64');
    }

    static getColorHash(str?: string) {
        if (!str) return colorsMantine[0];

        let hash = 0;

        for (let i = 0; i < str.length; i++) {
            let char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        }

        return colorsMantine[Math.abs(hash) % 11];
    }

    static isDefined<T>(val: T | undefined | null): boolean {
        return Boolean(val);
    }

    static getErrorMessage(error: unknown) {
        if (error instanceof Error) {
            return error.message;
        }
        if (typeof error === 'object' && error) {
            if ('msg' in error && typeof (error as any).msg === 'string') {
                return (error as any).msg;
            }
            if ('message' in error && typeof (error as any).message === 'string') {
                return (error as any).message;
            }
        }
        return `${error}`;
    }

    static errorToString(e: any) {
        if (e && e.msg && e.data && e.data && e.data.error && e.data.error.code) {
            return `${e.msg} (0x${e.data.error.code.toString(16)})`;
        }
        return e + '';
    }

    static mergeValues<T = any>(target: T, source: any, strict: boolean): T {
        if (typeof target != 'object' || target == null) {
            return source;
        }
        if (typeof source != 'object') {
            if (strict) {
                throw new Error('Cannot overwrite object with primitive type');
            }
            return source;
        }
        if (Array.isArray(target)) {
            if (!Array.isArray(source) && strict) {
                throw new Error('Cannot mix array with object');
            }
            return source;
        }
        for (const key in source) {
            try {
                target[key as keyof T] = Utils.mergeValues(
                    target[key as keyof T],
                    source[key],
                    strict
                );
            } catch (e) {
                throw new Error(
                    'OverwriteOptions ' +
                        key +
                        ': ' +
                        (e && typeof e === 'object' && 'message' in e && (e as any).message
                            ? (e as any).message
                            : '')
                );
            }
        }
        return target;
    }
}
