import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../../api/Api';
import { ChatEditLoaderData } from '../../utils/router/loaders';
import { selectChatTags, toggleAccountsAddedtModal } from '../../store/ModalsSlice';
import { ChatEditModel, ChatId, TagOption } from '../../types/Types';
import * as types from '../../types/Types';
import { useMemo } from 'react';
import { Deferred } from '../../utils/Deferred';
import { Utils } from '../../api/privmx/utils/Utils';
import { useAppDispatch, useAppSelector } from '../../store';
import { Button, Checkbox, Grid } from '@mantine/core';
import ConfirmModal from '../../atoms/ConfirmModal';
import ModalForm from '../ModalForm';
import { usePreventClose } from '../../hooks/usePreventClose';
import { toUserOption } from '../UsersSelector/helpers';
import { UserSelectOptionType } from '../UsersSelector';
import { useTranslation } from 'react-i18next';
import { UserUtils } from '../../utils/UserUtils';
import { selectUsernameToName } from '../../store/DataCacheSlice';
import { useUserInteraction } from '../../hooks/useUserInteraction';
import { Validator } from '../../utils/Validator';

interface FormData {
    title: string;
    users: UserSelectOptionType[];
    managers: UserSelectOptionType[];
    tags: TagOption[];
}

export const getChatFromData = (data: FormData, chatId: ChatId) => {
    const newChat: ChatEditModel = {
        id: chatId,
        title: data.title.trim(),
        tags: data.tags.map((x) => x.value),
        users: data.users.map((x) => toUserOption(x)),
        managers: data.managers
            .map((x) => x.value)
            .filter((x) => x !== null) as unknown as types.Username[]
    };
    return newChat;
};

export const ChatEditPage = forwardRef(
    (props: { chatEdit: ChatEditLoaderData; handleClose: () => void }, ref) => {
        const { t } = useTranslation();
        const chat = props.chatEdit.chat;
        const chatId = chat.id;
        const [confirmModalDeferred, setConfirmModalDeferred] = useState<
            Deferred<boolean> | undefined
        >(undefined);
        const displayMap = useAppSelector(selectUsernameToName);

        const form = useForm<FormData>({
            defaultValues: {
                users: UserUtils.usernameOrIdToOption(
                    chat?.users.map((x) => ({ type: 'user', username: x })) || [],
                    props.chatEdit.users,
                    displayMap
                ),
                managers: chat?.managers.map((x) => ({
                    label: x,
                    value: x,
                    type: 'staff',
                    email: (
                        props.chatEdit.users.find(
                            (u) => u.type === 'user' && u.user.username === x
                        ) as types.UserOpt | undefined
                    )?.user.email
                }))
            }
        });
        const setError = form.setError;
        const isDirty = form.formState.isDirty;
        usePreventClose(isDirty);

        const getValues = form.getValues;
        const [updateKeysWhenAddingUsers, setUpdateKeysWhenAddingUsers] = useState(true);

        const handleClose = props.handleClose;
        useImperativeHandle(ref, () => ({
            beforeClose() {
                if (!isDirty) {
                    return true;
                }
                const deferred = new Deferred<boolean>();
                setConfirmModalDeferred(deferred);
                return deferred.promise;
            }
        }));

        const dispatch = useAppDispatch();
        const { startInteraction, isProcessing } = useUserInteraction({
            successTitle: t('modal.chatEdit.topicEditedSuccesTitle'),
            successMessage: t('modal.chatEdit.topicEditedSuccesMessage'),
            errorTitle: t('modal.chatEdit.errorTitle'),
            errorMessage: t('modal.chatEdit.errorMessage'),
            async action(data: FormData) {
                const newChat = getChatFromData(data, chatId);
                const { newAccounts, privateContactsMadeVisible } = await api.updateChat(
                    newChat,
                    updateKeysWhenAddingUsers
                );
                handleClose();
                if (newAccounts.size > 0 || privateContactsMadeVisible.length > 0) {
                    dispatch(
                        toggleAccountsAddedtModal({
                            open: true,
                            title: t('modal.topicAdd.sendPasswords'),
                            addedUsers: Array.from(newAccounts.entries()),
                            privateContactsMadeVisible
                        })
                    );
                }
            }
        });

        const handleFormSubmit = useCallback(
            async (data: FormData) => {
                let hasError: boolean = false;
                const validatorErrors = Validator.getErrors(data.title.trim(), [
                    'safeDefault',
                    'shorttext'
                ]);
                if (validatorErrors !== false) {
                    if (validatorErrors.includes('size')) {
                        setError('title', {
                            type: 'value',
                            message: t('modal.chatEdit.errors.maxLengthTitle')
                        });
                        hasError = true;
                    }
                    if (validatorErrors.includes('safeDefault')) {
                        setError('title', {
                            type: 'value',
                            message: t('modal.chatEdit.errors.maxLengthTitle')
                        });
                        hasError = true;
                    }
                }
                if (data.title.trim() === '') {
                    setError('title', {
                        type: 'required',
                        message: t('modal.chatEdit.errors.titleRequired')
                    });
                    hasError = true;
                }

                let wrongUser: string = '';
                for (const user of data.users) {
                    wrongUser = user.value;
                    const userErrors = Validator.getErrors(user.value, ['safeDefault', 'username']);
                    if (userErrors !== false) {
                        if (userErrors.includes('username')) {
                            setError('users', {
                                type: 'value',
                                message: t('modal.chatEdit.errors.incorectUser', {
                                    user: wrongUser
                                })
                            });
                            hasError = true;
                        }
                        break;
                    }
                }

                const tagErrors: Record<'size' | 'tag', string[]> = {
                    size: [],
                    tag: []
                };
                for (const tag of data.tags) {
                    const tagValidatorErrors = Validator.getErrors(
                        tag.value,
                        ['tag', 'safeDefault'],
                        true
                    );
                    if (tagValidatorErrors !== false) {
                        if (tagValidatorErrors.includes('safeDefault')) {
                            tagErrors.tag.push(tag.value);

                            hasError = true;
                            break;
                        }
                        if (tagValidatorErrors.includes('size')) {
                            tagErrors.size.push(tag.value);
                            hasError = true;
                            break;
                        }
                    }
                }
                if (tagErrors.size.length || tagErrors.tag.length) {
                    let errorMessage = '';

                    if (tagErrors.size.length) {
                        if (tagErrors.size.length > 1) {
                            const tagsList = tagErrors.size.join(',');
                            errorMessage = t('modal.chatEdit.errors.maxLengthTagPlural', {
                                tags: tagsList,
                                count: Validator.constraints['tag'].maxLength
                            });
                        } else {
                            errorMessage = t('modal.chatEdit.errors.maxLengthTag', {
                                tag: tagErrors.size[0],
                                count: Validator.constraints['tag'].maxLength
                            });
                        }
                    }
                    if (tagErrors.tag.length) {
                        if (tagErrors.tag.length > 1) {
                            const tagsList = tagErrors.tag.join(',');
                            errorMessage = t('modal.chatEdit.errors.invalidTagPlural', {
                                tags: tagsList
                            });
                        } else {
                            errorMessage = t('modal.chatEdit.errors.invalidTag', {
                                tags: tagErrors.tag[0]
                            });
                        }
                    }

                    if (errorMessage !== '') {
                        setError('tags', {
                            type: 'value',
                            message: errorMessage
                        });
                    }
                }
                if (!hasError) {
                    startInteraction(data);
                }
            },
            [startInteraction, setError, t]
        );

        const handleConfirmModalSaveClick = useCallback(() => {
            confirmModalDeferred?.resolve(false);
            setConfirmModalDeferred(undefined);
            handleFormSubmit(getValues());
        }, [handleFormSubmit, confirmModalDeferred, setConfirmModalDeferred, getValues]);

        const handleConfirmModalCancelClick = useCallback(() => {
            confirmModalDeferred?.resolve(false);
            setConfirmModalDeferred(undefined);
        }, [confirmModalDeferred, setConfirmModalDeferred]);

        const handleConfirmModalRejectChangesClick = useCallback(() => {
            confirmModalDeferred?.resolve(true);
            setConfirmModalDeferred(undefined);
        }, [confirmModalDeferred, setConfirmModalDeferred]);

        const contacts = useMemo(() => {
            return props.chatEdit.users
                .filter((user) => user.type === 'contact')
                .map((user) => (user as types.ContactOpt).contact);
        }, [props.chatEdit.users]);

        const globalTags = useAppSelector(selectChatTags);
        const [newTags, setNewTags] = useState<types.Tag[]>([]);
        const tagsOptions = [...(globalTags || []), ...newTags].map((tag) => ({
            label: tag,
            value: tag
        }));

        const defaultTagsValue = useMemo(() => {
            const mapped = props.chatEdit.chat.tags.map((tag) => ({ label: tag, value: tag }));
            return mapped.filter(Utils.isDefined);
        }, [props.chatEdit.chat.tags]);

        return (
            <>
                <ModalForm
                    loadingMessage="Saving changes"
                    onSubmit={handleFormSubmit}
                    form={form}
                    isProcesing={isProcessing}
                    onClose={handleClose}>
                    <ModalForm.TextField
                        span={2}
                        name="title"
                        label={t('entityProps.title')}
                        required
                        defaultValue={chat.title ?? ''}
                    />
                    <ModalForm.UserSelectField
                        span={2}
                        name="users"
                        label={t('entityProps.users')}
                        contacts={contacts}
                        keepCurrentUser
                    />
                    <ModalForm.UserSelectField
                        span={2}
                        label={t('entityProps.management')}
                        name="managers"
                        contacts={contacts}
                        keepCurrentUser
                    />
                    <ModalForm.TagsSelectField
                        span={2}
                        name="tags"
                        label={t('entityProps.tags')}
                        addNewTag={(query) => setNewTags((prev) => [...prev, query as types.Tag])}
                        defaultValue={defaultTagsValue}
                        tagOptions={tagsOptions}
                    />

                    <Grid.Col span={2}>
                        <Checkbox
                            checked={!updateKeysWhenAddingUsers}
                            onChange={(event) =>
                                setUpdateKeysWhenAddingUsers(!event.currentTarget.checked)
                            }
                            disabled={isProcessing}
                            label={t('addedUsersCanSeeOldStuff')}
                        />
                    </Grid.Col>

                    <>
                        <ConfirmModal
                            title={t('warningMessage.unsavedChangesWhatToDo')}
                            centered
                            opened={Boolean(confirmModalDeferred)}
                            onClose={handleConfirmModalCancelClick}>
                            <>
                                <Button type="button" onClick={handleConfirmModalCancelClick}>
                                    {t('action.cancel')}
                                </Button>
                                <Button
                                    type="button"
                                    onClick={handleConfirmModalRejectChangesClick}>
                                    {t('action.rejectChanges')}
                                </Button>
                                <Button type="button" onClick={handleConfirmModalSaveClick}>
                                    {t('action.save')}
                                </Button>
                            </>
                        </ConfirmModal>
                    </>
                </ModalForm>
            </>
        );
    }
);

export default ChatEditPage;
