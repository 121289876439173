import {
    Container,
    Image,
    Title,
    Text,
    Center,
    Group,
    Flex,
    Box,
    Accordion,
    Button,
    CopyButton,
    Stack
} from '@mantine/core';
import React from 'react';
import { useRouteError } from 'react-router';
import Error from '../../assets/Error.svg';
import { Link } from 'react-router-dom';

export function ErrorScreen() {
    const error = useRouteError() as Error;
    return (
        <Center w="100%" h="100svh">
            <Container>
                <Flex direction={'row'} justify={'space-between'} align={'center'}>
                    <Stack w="55%">
                        <Title>Something went wrong...</Title>
                        <Text>
                            There was a problem while using the application. To continue log in
                            again. Also you can send us an error code to help us prevent such
                            situation in the future
                        </Text>
                        <Group>
                            <Button color="dark" component={Link} to="/chat">
                                Go back
                            </Button>
                            <CopyButton
                                value={`[${
                                    error && error.name
                                        ? error.name.toUpperCase()
                                        : '<unknown-error>'
                                }] ${error.cause}:${error.stack}`}>
                                {({ copied, copy }) => {
                                    return copied ? (
                                        <Button variant="light" color="green">
                                            Error copied, thank you.
                                        </Button>
                                    ) : (
                                        <Button color="dark" variant="light" onClick={copy}>
                                            Copy error
                                        </Button>
                                    );
                                }}
                            </CopyButton>
                        </Group>
                        <Accordion variant="filled">
                            <Accordion.Item value="customization">
                                <Accordion.Control>Error detail</Accordion.Control>
                                <Accordion.Panel>
                                    <Title fz="lg">
                                        {error && error.name ? error.name : '<unknown-error>'}
                                    </Title>
                                    <Text>{error?.message || ''}</Text>
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </Stack>
                    <Box w="40%">
                        <Image src={Error} />
                    </Box>
                </Flex>
            </Container>
        </Center>
    );
}
