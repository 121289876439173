import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { api } from '../../api/Api';
import { ContactEditLoaderData } from '../../utils/router/loaders';
import {
    Avatar,
    CompanyAddress,
    CompanyId,
    CompanyName,
    CompanyNote,
    CompanyWebsite,
    Contact,
    ContactAddress,
    ContactId,
    ContactName,
    ContactNote,
    Email,
    Language,
    MobilePhone,
    Phone,
    Tag,
    Timestamp,
    Username
} from '../../types/Types';
import useModal from '../../hooks/useModal';
import ModalForm from '../ModalForm';
import {
    ActionIcon,
    Alert,
    Anchor,
    Box,
    Button,
    Divider,
    Flex,
    Group,
    Select,
    Stack,
    Text,
    Transition
} from '@mantine/core';
import useToast from '../../hooks/useToast';
import { useSearchParamsEx } from '../../hooks/useLoader';
import { usePreventClose } from '../../hooks/usePreventClose';
import { useTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import { useAppSelector } from '../../store';
import { selectCachedCompanies, selectUsedTags } from '../../store/DataCacheSlice';
import { Utils } from '../../utils/Utils';
import UserAvatar from '../../atoms/UserAvatar';
import { IconAlertCircle, IconChevronLeft, IconX } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useUserInteraction } from '../../hooks/useUserInteraction';

type FormData = Pick<
    Contact,
    | 'name'
    | 'email'
    | 'address'
    | 'mobilePhone'
    | 'phone'
    | 'companyId'
    | 'note'
    | 'username'
    | 'isPrivate'
> & { tags: { label: string; value: string }[] };

interface CompanyOption {
    label: string;
    value: string;
}

export function ContactEditPage(props: {
    contactEdit: ContactEditLoaderData;
    handleClose: () => void;
}) {
    const companies = useAppSelector(selectCachedCompanies);
    const { t } = useTranslation();
    const defaults = props.contactEdit.defaults;
    const contact =
        props.contactEdit.contact && typeof props.contactEdit.contact === 'object'
            ? props.contactEdit.contact
            : null;
    const origContact = contact;
    const contactId = contact?.id;
    const pinned = contact?.pinned ?? false;
    const archived = contact?.archived ?? false;
    // const [isProcessing, setIsProcessing] = useState(false);
    const { navigateKeepingSearch } = useSearchParamsEx();

    const globalTags = useAppSelector(selectUsedTags);

    const [newTags, setNewTags] = useState<Tag[]>([]);
    const tagsOptions = [...(globalTags || []), ...newTags].map((tag) => ({
        label: tag,
        value: tag
    }));

    const defaultTags = useMemo(() => {
        const tagsList = contact?.tags ? contact.tags : [];
        const mapped = tagsList.map((x) => ({ label: x, value: x }));
        return mapped.filter(Utils.isDefined);
    }, [contact?.tags]);

    const form = useForm<FormData>({
        defaultValues: {
            ...contact,
            ...defaults,
            tags: defaultTags
        }
    });
    usePreventClose(form.formState.isDirty);
    const setValue = form.setValue;
    const setError = form.setError;
    const control = form.control;
    const isDirty = form.formState.isDirty;

    const modalService = useModal();
    const toast = useToast();

    const handleCompanySelectChange = useCallback(
        async (newValue: string) => {
            try {
                const company = await api.addCompany({
                    createdBy: '' as Username,
                    createdDate: 0 as Timestamp,
                    lastModifiedBy: '' as Username,
                    lastModifiedDate: 0 as Timestamp,
                    ...origContact,
                    name: (newValue || i18next.t('entityProps.company')) as CompanyName,
                    address: '' as CompanyAddress,
                    email: '' as Email,
                    note: '' as CompanyNote,
                    phone: '' as Phone,
                    mobilePhone: '' as MobilePhone,
                    website: '' as CompanyWebsite,
                    tags: [],
                    pinned: false,
                    archived: false
                });
                setValue('companyId', company.id);
                toast(i18next.t('successMessage.companyCreated'), 'success');
            } catch {
                toast(i18next.t('errorMessage.couldNotCreateCompany'), 'error');
            }
        },
        [setValue, toast, origContact]
    );

    const handleEditCompanyClick = useCallback(
        (id?: CompanyId) => {
            if (!id) return;
            modalService.openCompanyEditModal({ id: id });
        },
        [modalService]
    );

    const companyOptions = useMemo(() => {
        const options = companies.map((company) => ({
            label: company.name,
            value: company.id
        })) as CompanyOption[];
        return options;
    }, [companies]);

    const handleClose = props.handleClose;

    const isNewContact = !contactId;

    const { startInteraction, isProcessing } = useUserInteraction({
        successTitle: isNewContact
            ? t('errorMessage.couldNotCreateContact')
            : t('errorMessage.couldNotUpdateContact'),
        successMessage: <Anchor></Anchor>,
        errorTitle: isNewContact
            ? t('successMessage.contactCreated')
            : t('successMessage.contactUpdated'),
        async action(data: FormData) {
            const users = api.getUsersSync();
            const userWithSameEmail = users.find((x) => x.email === (data.email.trim() as Email));
            const currentUserUsername = api.getCurrentUserUsername();
            const privateContactVisibleForUsers =
                data.isPrivate && contact ? [...contact.privateContactVisibleForUsers] : [];
            if (
                data.isPrivate &&
                currentUserUsername !== undefined &&
                !privateContactVisibleForUsers.includes(currentUserUsername)
            ) {
                privateContactVisibleForUsers.push(currentUserUsername);
            }
            // const privateContactVisibleForUsers = data.isPrivate && contact?.privateContactVisibleForUsers ? contact.privateContactVisibleForUsers : [];
            const newContact: Contact = {
                id: contactId || ('' as ContactId),
                address: data.address.trim() as ContactAddress,
                companyId: (data.companyId ? data.companyId : '') as CompanyId,
                email: data.email.trim() as Email,
                mobilePhone: (data.mobilePhone || '').trim() as MobilePhone,
                name: data.name.trim() as ContactName,
                note: data.note.trim() as ContactNote,
                phone: (data.phone || '').trim() as Phone,
                userType: 'email',
                avatar: (props.contactEdit.contact?.avatar.trim() || '') as Avatar,
                language: (contact && contact.language) || ('en' as Language),
                username: (contact && contact.username) || userWithSameEmail?.username || undefined,
                pinned: pinned,
                archived: archived,
                tags: data.tags.map((x) => x.value) as Tag[],
                createdBy: '' as Username,
                createdDate: 0 as Timestamp,
                lastModifiedBy: '' as Username,
                lastModifiedDate: 0 as Timestamp,
                isPrivate: privateContactVisibleForUsers.length > 0,
                privateContactVisibleForUsers: privateContactVisibleForUsers
            };
            if (isNewContact) {
                const serverContact = await api.addContact(newContact);
                navigateKeepingSearch(`/contacts/${serverContact.id}`);
            } else {
                await api.updateContact(newContact);
                navigateKeepingSearch(`/contacts/${contact.id}`);
            }
            handleClose();
        }
    });

    const handleFormSubmit = useCallback(
        async (data: FormData) => {
            if (data.email.trim() === '' || !checkIsEmailValid(data.email)) {
                setError('email', {});
                return;
            }
            startInteraction(data);
            // setIsProcessing(true);
            // const isNewContact = !contactId;
            // let success = false;
            // try {
            //     const users = api.getUsersSync();
            //     const userWithSameEmail = users.find(
            //         (x) => x.email === (data.email.trim() as Email)
            //     );
            //     const newContact: Contact = {
            //         id: contactId || ('' as ContactId),
            //         address: data.address.trim() as ContactAddress,
            //         companyId: (data.companyId ? data.companyId : '') as CompanyId,
            //         email: data.email.trim() as Email,
            //         mobilePhone: (data.mobilePhone || '').trim() as MobilePhone,
            //         name: data.name.trim() as ContactName,
            //         note: data.note.trim() as ContactNote,
            //         phone: (data.phone || '').trim() as Phone,
            //         userType: 'email',
            //         avatar: (props.contactEdit.contact?.avatar.trim() || '') as Avatar,
            //         language: (contact && contact.language) || ('en' as Language),
            //         username:
            //             (contact && contact.username) || userWithSameEmail?.username || undefined,
            //         pinned: pinned,
            //         archived: archived,
            //         tags: data.tags.map((x) => x.value) as Tag[],
            //         createdBy: '' as Username,
            //         createdDate: 0 as Timestamp,
            //         lastModifiedBy: '' as Username,
            //         lastModifiedDate: 0 as Timestamp
            //     };
            //     if (isNewContact) {
            //         const serverContact = await api.addContact(newContact);
            //         navigateKeepingSearch(`/contacts/${serverContact.id}`);
            //     } else {
            //         await api.updateContact(newContact);
            //         navigateKeepingSearch(`/contacts/${contact.id}`);
            //     }
            //     success = true;
            // } catch (error: any) {
            //     if (typeof error.msg === 'string') {
            //         toast(error.msg, 'error');
            //     } else {
            //         if (isNewContact) {
            //             toast(i18next.t('errorMessage.couldNotCreateContact'), 'error');
            //         } else {
            //             toast(i18next.t('errorMessage.couldNotUpdateContact'), 'error');
            //         }
            //     }
            // } finally {
            //     setIsProcessing(false);
            // }
            // if (success) {
            //     if (isNewContact) {
            //         toast(i18next.t('successMessage.contactCreated'), 'success');
            //     } else {
            //         toast(i18next.t('successMessage.contactUpdated'), 'success');
            //     }
            //     handleClose();
            // }
        },
        [startInteraction, setError]
    );

    const [companySearch, setCompanySearch] = useState('');

    return (
        <Stack p="md" justify="space-between" h="100%">
            <Box>
                <Group position="apart">
                    <Group>
                        <ActionIcon component={Link} to={`/contacts/${contact?.id}`}>
                            <IconChevronLeft />
                        </ActionIcon>
                        <Text>General</Text>
                    </Group>
                    {handleClose ? (
                        <ActionIcon onClick={handleClose}>
                            <IconX size={20} />
                        </ActionIcon>
                    ) : (
                        <></>
                    )}
                </Group>
                <Divider my="md" />
                <Flex align="flex-start" gap="md" mb="lg">
                    <UserAvatar showTooltip={false} size={110} />
                    <Box sx={{ flexGrow: 1 }}>
                        <Text mt="sm">{contact?.username || ''}</Text>
                        <Text mb="sm">{contact?.email || ''}</Text>
                    </Box>
                </Flex>
                <ModalForm
                    buttons={<></>}
                    onSubmit={handleFormSubmit}
                    form={form}
                    isProcesing={isProcessing}
                    loadingMessage={isNewContact ? 'Creating new Contact' : 'Updating Contact'}
                    onClose={props.handleClose}>
                    <ModalForm.TextField
                        span={1}
                        name="telephone"
                        label={t('entityProps.telephone')}
                    />
                    <ModalForm.TextField span={1} name="address" label={t('entityProps.address')} />
                    <ModalForm.Field span={2}>
                        <Controller
                            control={control}
                            name="companyId"
                            render={({ field }) => (
                                <>
                                    <Select
                                        searchable
                                        searchValue={companySearch}
                                        onSearchChange={setCompanySearch}
                                        creatable
                                        getCreateLabel={(query) =>
                                            t('action.createCompanyWithName', { name: query })
                                        }
                                        onCreate={(querry) => {
                                            handleCompanySelectChange(querry);
                                            return { value: querry, label: 'fdsfasdf' };
                                        }}
                                        clearable
                                        label={t('entityProps.company')}
                                        data={companyOptions}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    {field.value && (
                                        <Anchor
                                            size="sm"
                                            onClick={() => handleEditCompanyClick(field.value)}>
                                            {t('action.editCompany')}
                                        </Anchor>
                                    )}
                                </>
                            )}
                        />
                    </ModalForm.Field>

                    <ModalForm.TextareaField
                        styles={(theme) => ({
                            input: {
                                backgroundColor: theme.colors.gray[0],
                                minHeight: 120
                            }
                        })}
                        name="note"
                        span={2}
                        label={t('entityProps.note')}
                    />
                    <ModalForm.TagsSelectField
                        span={2}
                        name="tags"
                        label={t('entityProps.tags')}
                        addNewTag={(query) => setNewTags((prev) => [...prev, query as Tag])}
                        defaultValue={defaultTags}
                        tagOptions={tagsOptions}
                    />
                </ModalForm>
            </Box>
            <>
                <Transition mounted={isDirty} keepMounted={false} transition="fade">
                    {(styles) => (
                        <Alert
                            style={styles}
                            styles={{
                                label: { width: '100%' },
                                title: {
                                    marginBottom: 0
                                },
                                wrapper: {
                                    gap: 8,
                                    alignItems: 'center'
                                },
                                icon: {
                                    justifyContent: 'center',
                                    marginRight: 0
                                }
                            }}
                            icon={<IconAlertCircle size="1rem" />}
                            title={
                                <Group position="apart" w="100%" sx={{ flexGrow: 1 }}>
                                    <Text>You have unsaved data</Text>
                                    <Button
                                        size="sm"
                                        variant="light"
                                        onClick={() => form.handleSubmit(handleFormSubmit)()}>
                                        Save
                                    </Button>
                                </Group>
                            }
                            variant="light"
                            color="brand">
                            <></>
                        </Alert>
                    )}
                </Transition>
            </>
        </Stack>
    );
}

function checkIsEmailValid(email: string) {
    return /^[a-z0-9_.+-]+@[a-z0-9_.-]+$/.test(email);
}
