import {
    Box,
    Flex,
    Title,
    Text,
    Modal,
    Image,
    Button,
    Space,
    TextInput,
    Center
} from '@mantine/core';
import { useCallback, useState } from 'react';
import { t } from 'i18next';

export function CaptchaModal({
    challengeUrl,
    onSubmit
}: {
    challengeUrl: string;
    onSubmit: (value: string) => void;
}) {
    const [value, setValue] = useState<string>('');
    const handleSendForm = useCallback(() => {
        onSubmit(value);
    }, [onSubmit, value]);

    return (
        <Modal
            keepMounted={true}
            withCloseButton={false}
            transitionProps={{
                duration: 200,
                transition: 'slide-up',
                timingFunction: 'ease-in'
            }}
            size="auto"
            centered
            onClose={() => {}}
            opened={true}>
            <Flex
                p="xl"
                pt={'xs'}
                h="100%"
                pos="relative"
                miw={300}
                maw={'30vw'}
                direction={'column'}>
                <Box sx={{ flexGrow: 1 }} w="100%" mb="lg">
                    <Title order={2} mb="xs">
                        {t('modal.captcha.title')}
                    </Title>
                    <Text color="dimmed">{t('modal.captcha.info')}</Text>

                    <Image radius="md" src={challengeUrl} fit="scale-down" />
                    <TextInput
                        value={value}
                        onChange={(event) => setValue(event.currentTarget.value)}
                    />
                    <Space h="md"></Space>
                    <Center>
                        <Button variant="light" onClick={handleSendForm}>
                            {t('modal.captcha.confirm')}
                        </Button>
                    </Center>
                </Box>
            </Flex>
        </Modal>
    );
}
