import { AdminDataV2Decryptor } from './AdminDataV2Decryptor';
import { AdminDataInner } from './userCreation/Types';
import * as PmxApi from 'privmx-server-api';

export class AdminDataDecryptorService {
    constructor(private adminDataV2Decryptor: AdminDataV2Decryptor) {}

    async decrypt(adminData: PmxApi.api.core.Base64): Promise<AdminDataInner | null> {
        try {
            return await this.adminDataV2Decryptor.decrypt(adminData);
        } catch (err) {
            console.error(err);
        }
        return null;
    }
}
