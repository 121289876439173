import React, { useMemo } from 'react';
import { AttachmentEx } from '../../../types/Types';
import { createStyles } from '@mantine/core';
import { FilesView } from '../../FilesView/FilesView';
import { useFilterContext, isFileCategory, mimetypeMap } from '../../../mantineAtoms/FilterSelect';
import { DAY_MILLISECONDS } from '../ThreadAttachments';

const useStyles = createStyles((theme) => ({
    messageList: {
        flexGrow: 1,
        padding: theme.spacing.md,
        paddingBottom: 0
    }
}));

export default function FilesSidebar({
    loadingMessages,
    attachments,
    hasAccess,
    viewMode
}: {
    loadingMessages: boolean;
    attachments: AttachmentEx[];
    hasAccess: boolean;
    viewMode: 'list' | 'tiles';
}) {
    const allFiles = useMemo(() => {
        return attachments.map((att) => ({
            file: att,
            date: new Date(att.date),
            author: att.author,
            modificationDates: att.modificationDates.map((x) => new Date(x)),
            contributors: [...att.contributors]
        }));
    }, [attachments]);

    const { filters } = useFilterContext();

    const filteredFiles = useMemo(() => {
        let filteredElements = [...allFiles];
        for (const filter of filters) {
            if (filter.type === 'type' && isFileCategory(filter.value)) {
                filteredElements = filteredElements.filter(
                    (x) => mimetypeMap.get(x.file.contentType) === filter.value
                );
            } else if (filter.type === 'author') {
                filteredElements = filteredElements.filter((x) => x.author === filter.value);
            } else if (filter.type === 'date') {
                const [start, end] = filter.value.split('-');
                if (end) {
                    filteredElements = filteredElements.filter(
                        (x) =>
                            x.date.getTime() < parseInt(end) + DAY_MILLISECONDS &&
                            x.date.getTime() > parseInt(start)
                    );
                } else {
                    filteredElements = filteredElements.filter(
                        (x) =>
                            x.date.getTime() < parseInt(start) + DAY_MILLISECONDS &&
                            x.date.getTime() > parseInt(start)
                    );
                }
            }
        }
        return filteredElements;
    }, [filters, allFiles]);

    const { classes } = useStyles();

    if (loadingMessages) return <></>;
    return (
        <FilesView
            className={classes.messageList}
            files={filteredFiles.map((x) => x.file)}
            hasAccess={hasAccess}
            loadingMessages={loadingMessages}
            mode={viewMode}
        />
    );
}
