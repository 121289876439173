import * as privmx from 'privfs-client';
import { Username } from '../../types/Types';

export class CredentialsHolder {
    private username?: Username;
    private passwordEncKey?: Buffer;
    private passwordCipher?: Buffer;

    async store(username: Username, password: string) {
        this.username = username;
        const raw = Buffer.from(password, 'utf8');
        this.passwordEncKey = privmx.crypto.service.randomBytes(32);
        this.passwordCipher = await privmx.crypto.service.privmxEncrypt(
            privmx.crypto.service.privmxOptAesWithSignature(),
            raw,
            this.passwordEncKey
        );
    }

    async getPassword() {
        if (!this.passwordCipher || !this.passwordEncKey) {
            return undefined;
        }
        const decrypted = await privmx.crypto.service.privmxDecrypt(
            privmx.crypto.service.privmxOptSignedCipher(),
            this.passwordCipher,
            this.passwordEncKey
        );
        return decrypted.toString('utf8');
    }

    getUsername() {
        return this.username;
    }
}
