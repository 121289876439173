import { Chat, ChatId } from '../../types/Types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Checkbox, Modal, rem, TextInput } from '@mantine/core';
import RouteListContainer from '../../atoms/RouteScreen/RouteListContainer';
import { useSortChats } from '../../hooks/useSort';
import ScreenScrollContainer from '../../mantineAtoms/ScreenScrollContainer';
import ChatEditDialog from '../../components/ChatEditPage/ChatEditDialog';
import { ThreadsListActionsBar } from '../../mantineAtoms/ThreadsListActionsBar';
import { useTranslation } from 'react-i18next';
import { useActionBar } from './useActionBar';
import { useInputState } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import { FilterContextProvider } from '../../mantineAtoms/FilterSelect';
import { ChatListElement } from './components/ChatListElement';
import { useAppDispatch, useAppSelector } from '../../store';
import { toggleNewMessageModal } from '../../store/ModalsSlice';
import { EmptyState } from '../../mantineAtoms/EmptyState/EmptyState';
import { Link, useSearchParams } from 'react-router-dom';
import { selectCurrentUserRole } from '../../store/CurrentUserSlice';

export interface ChatsScreenViewProps {
    chats: Chat[];
    onChatChoose: (chat: Chat) => void;
    revalidate: (switchToLoadingState?: boolean | undefined) => void;
}

export default function ChatsScreenView(props: ChatsScreenViewProps) {
    return (
        <FilterContextProvider>
            <ChatsList {...props} />
        </FilterContextProvider>
    );
}

function ChatsList({ chats, onChatChoose, revalidate }: ChatsScreenViewProps) {
    const { t } = useTranslation();
    const clicked = useRef(true);
    const [query, setQuery] = useInputState('');

    const sortedChats = useSortChats(chats);
    const searchedElements = useMemo(
        () => sortedChats.filter((x) => x.title.toLowerCase().includes(query.toLowerCase())),
        [sortedChats, query]
    );

    const {
        filteredElements,
        handleBatchMutation,
        onCheckboxChange,
        setFilterState,
        selectedThreads,
        setSelectedItems,
        threadsMembers,
        threadsTags
    } = useActionBar(
        'Chat',
        revalidate,
        chats.map((x) => x.id),
        searchedElements
    );

    const handleElementClick = useCallback(
        (chat: Chat) => {
            clicked.current = true;
            onChatChoose(chat);
        },
        [onChatChoose]
    );

    const [threadToEdit, setThreadToEdit] = useState<null | ChatId>(null);

    const handleEditChat = useCallback((chat: Chat) => {
        setThreadToEdit(chat.id);
    }, []);

    const searchParams = useSearchParams();
    const currentFilter = searchParams[0].get('filter');

    const dispatch = useAppDispatch();

    const currentUserRole = useAppSelector(selectCurrentUserRole);

    if (chats.length === 0) {
        switch (currentFilter) {
            case 'favorite':
                return (
                    <RouteListContainer>
                        <EmptyState
                            image="NO_TOPICS"
                            title="No favorite Topics yet"
                            subTitle="Add the Topics you want to have at hand."
                            button={
                                <Button component={Link} to="/chat">
                                    Go to Topics
                                </Button>
                            }
                        />
                    </RouteListContainer>
                );
            case 'archived':
                return (
                    <RouteListContainer>
                        <EmptyState
                            image="NO_TOPICS"
                            title="No archived Topics yet"
                            button={
                                <Button component={Link} to="/chat">
                                    Go to Topics
                                </Button>
                            }
                        />
                    </RouteListContainer>
                );
            default:
                if (currentUserRole === 'staff') {
                    return (
                        <RouteListContainer>
                            <EmptyState
                                image="NO_TOPICS"
                                title="No Topics yet"
                                subTitle="Click here to start a Topic."
                                button={
                                    <Button
                                        onClick={() => {
                                            dispatch(toggleNewMessageModal({ open: true }));
                                        }}>
                                        New Topic
                                    </Button>
                                }
                            />
                        </RouteListContainer>
                    );
                } else {
                    return (
                        <RouteListContainer>
                            <EmptyState
                                image="NO_TOPICS"
                                title="No Topics yet"
                                subTitle="We will notify you when the company starts a new Meeting."
                            />
                        </RouteListContainer>
                    );
                }
        }
    }

    return (
        <RouteListContainer>
            <ThreadsListActionsBar
                tags={threadsTags}
                authors={threadsMembers}
                threadsCount={selectedThreads.length}
                onCheckboxChange={onCheckboxChange}
                batchMutations={handleBatchMutation}
                setFilterState={setFilterState}
                type={'Chat'}
                buttons={
                    <Button
                        variant="light"
                        size="xs"
                        disabled={currentUserRole !== 'staff'}
                        onClick={() => {
                            dispatch(toggleNewMessageModal({ open: true }));
                        }}>
                        New Topic
                    </Button>
                }
                search={(filter) => {
                    return (
                        <TextInput
                            size={'xs'}
                            value={query}
                            onChange={setQuery}
                            rightSection={filter}
                            placeholder={t('screen.topics.placeholders.actionsBar.search')}
                            icon={<IconSearch size={rem(16)} />}
                            styles={{
                                root: {
                                    flexGrow: 1,
                                    '@media (min-width: 62rem)': {
                                        flexGrow: 0,
                                        minWidth: 400
                                    }
                                },
                                rightSection: {
                                    width: 70
                                },
                                wrapper: {
                                    '&:focus-within': {
                                        '& .mantine-Input-input': {
                                            borderColor: '#936FAD'
                                        }
                                    }
                                }
                            }}
                        />
                    );
                }}></ThreadsListActionsBar>

            {filteredElements.length === 0 ? (
                <EmptyState
                    image="NO_MATCHES"
                    title="No matching results"
                    subTitle="Check for typos or try to adjust your filters."
                />
            ) : (
                <ScreenScrollContainer offsetScrollbars>
                    <Checkbox.Group
                        value={selectedThreads}
                        onChange={(value) => setSelectedItems(value as any)}>
                        {filteredElements.map((item) => (
                            <ChatDetailLink
                                key={item.id}
                                item={item as Chat}
                                onClick={handleElementClick}
                                onEditClick={handleEditChat}
                                hovered={false}
                            />
                        ))}
                    </Checkbox.Group>
                </ScreenScrollContainer>
            )}
            <Modal
                opened={threadToEdit !== null}
                size="md"
                onClose={() => setThreadToEdit(null)}
                title={t('modal.chatEdit.titleWithoutName')}
                overlayProps={{ opacity: 0.2, blur: 3 }}>
                <ChatEditDialog id={threadToEdit!} onClose={() => setThreadToEdit(null)} />
            </Modal>
        </RouteListContainer>
    );
}

function ChatDetailLink({
    item,
    onClick,
    onEditClick,
    ...rest
}: {
    item: Chat;
    onClick: (chat: Chat, e: React.MouseEvent) => void;
    onEditClick: (chat: Chat) => void;
    hovered?: boolean;
}) {
    const bindedOnEditClick = useCallback(() => onEditClick(item), [item, onEditClick]);

    return <ChatListElement active={false} chat={item} onEditClick={bindedOnEditClick} {...rest} />;
}
