import {
    Badge,
    Box,
    Group,
    Menu,
    rem,
    SegmentedControl,
    Stack,
    Text,
    TextInput
} from '@mantine/core';
import { AttachmentEx, ChatId, Username } from '../../types/Types';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { displayRelativeTime } from '../../utils/RelativeTimeFormat';
import { IconSearch } from '@tabler/icons-react';
import {
    DropDownElementsMap,
    ExtendedFilterOption,
    FilterSelect,
    documentTypes,
    isFileCategory,
    MimetypeCategory,
    mimetypeMap,
    FilterContextProvider,
    useFilterContext
} from '../../mantineAtoms/FilterSelect';
import { useAppSelector } from '../../store';
import { selectCachedChat } from '../../store/DataCacheSlice';
import { useLocation } from 'react-router-dom';
import { useInputState } from '@mantine/hooks';

function filterByDocumentType(
    files: AttachmentFile[],
    filterType: MimetypeCategory['category']
): AttachmentFile[] {
    return files.filter((x) => mimetypeMap.get(x.file.contentType) === filterType);
}

interface AttachmentFile {
    file: AttachmentEx;
    date: Date;
    author: Username;
    modificationDates: Date[];
    contributors: Username[];
}

export const DAY_MILLISECONDS = 1000 * 60 * 60 * 24;

function FilesList({
    query,
    setQuery,
    ...props
}: {
    allFiles: Array<AttachmentFile>;
    query: string;
    setQuery: (value: string | ChangeEvent<any> | null | undefined) => void;
}) {
    const { state } = useLocation();

    const { contextState, dispatch, filters } = useFilterContext();

    useEffect(() => {
        if (state?.filters) {
            dispatch({ type: 'sync', filters: state.filters });
        }
    }, [dispatch, state?.filters]);

    useEffect(() => {
        if (state?.query) {
            setQuery(state?.query);
        }
    }, [state?.query, setQuery]);

    const filteredFiles = useMemo(() => {
        let filteredElements = [...props.allFiles];
        for (const filter of filters) {
            if (filter.type === 'type' && isFileCategory(filter.value)) {
                filteredElements = filterByDocumentType(filteredElements, filter.value);
            } else if (filter.type === 'author') {
                filteredElements = filteredElements.filter((x) => x.author === filter.value);
            } else if (filter.type === 'date') {
                const [start, end] = filter.value.split('-');
                if (end) {
                    filteredElements = filteredElements.filter(
                        (x) =>
                            x.date.getTime() < parseInt(end) + DAY_MILLISECONDS &&
                            x.date.getTime() > parseInt(start)
                    );
                } else {
                    filteredElements = filteredElements.filter(
                        (x) =>
                            x.date.getTime() < parseInt(start) + DAY_MILLISECONDS &&
                            x.date.getTime() > parseInt(start)
                    );
                }
            }
        }
        return query
            ? filteredElements.filter((x) => x.file.name.includes(query))
            : filteredElements;
    }, [filters, props.allFiles, query]);

    return (
        <Box>
            <Group py={'md'} spacing={'sm'}>
                {contextState && (
                    <Badge onClick={() => dispatch({ type: 'clear' })} tt={'unset'} pr={4}>
                        Clear Filters
                    </Badge>
                )}
                {contextState.map((filter) => (
                    <FilterSelect.Tag key={filter} filter={filter} />
                ))}
            </Group>
            <Stack>
                {filteredFiles.map((file) => (
                    <Box py={'md'} key={file.file.id}>
                        <Group>
                            <Text>{file.file.name}</Text>
                            <Text>{file.file.contributors}</Text>
                            <Text>{displayRelativeTime(file.date.getTime())}</Text>
                        </Group>
                    </Box>
                ))}
            </Stack>
        </Box>
    );
}

type ThreadAttachmentFilterOption = ExtendedFilterOption<'type'>;

export const ThreadAttachments = ({
    attachments,
    chatId
}: {
    attachments: AttachmentEx[];
    chatId: ChatId;
}) => {
    const selectChat = useAppSelector(selectCachedChat(chatId));

    const allFiles = useMemo(() => {
        return attachments.map((att) => ({
            file: att,
            date: new Date(att.date),
            author: att.author,
            modificationDates: att.modificationDates.map((x) => new Date(x)),
            contributors: [...att.contributors]
        }));
    }, [attachments]);

    const filterMap: DropDownElementsMap<ThreadAttachmentFilterOption['type']> = {
        author: (props) => (
            <FilterSelect.AuthorDropdown authors={selectChat?.users || []} {...props} />
        ),
        date: FilterSelect.DateDropdown,
        type: ({ onSelect }) => (
            <Menu.Dropdown>
                {documentTypes.map((x) => (
                    <Menu.Item
                        key={x.type}
                        onClick={() => onSelect(`type:${x.type}`)}
                        icon={<x.icon size={rem(12)} />}>
                        {x.type}
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        )
    };

    const filterOptions: ThreadAttachmentFilterOption[] = [{ label: 'Type', type: 'type' }];

    const [query, setQuery] = useInputState('');

    return (
        <FilterContextProvider>
            <Box sx={{ flexGrow: 1 }} p={'md'}>
                <Group>
                    <SegmentedControl w={150} size={'xs'} data={['Messages', 'Files']} />
                    <TextInput
                        value={query}
                        onChange={setQuery}
                        maw={350}
                        sx={{ flexGrow: 1 }}
                        placeholder={'Search'}
                        icon={<IconSearch size={rem(10)} />}
                        size={'xs'}
                    />
                    <FilterSelect
                        dropdownElementMap={filterMap}
                        size={'xs'}
                        filterOptions={filterOptions}
                    />
                </Group>
                <FilesList query={query} setQuery={setQuery} allFiles={allFiles} />
            </Box>
        </FilterContextProvider>
    );
};
