import { Menu, TextInput, rem, ScrollArea, Text } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { IconSearch, IconUser } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useAppSelector } from '../../../store';
import { selectUsernamesToEmail, selectUsernameToName } from '../../../store/DataCacheSlice';
import { Chat } from '../../../types/Types';
import { ICON_SIZE } from '../FilterSelect';
import { FilterDropdownComponent, FilterFactory } from '..';

export const FilterSelectUserDropdown: FilterDropdownComponent<{
    authors: Chat['users'];
}> = ({ onSelect, authors }) => {
    const [filter, setFilter] = useInputState('');
    const emailMap = useAppSelector(selectUsernamesToEmail);
    const nameMap = useAppSelector(selectUsernameToName);

    const filteredUsers = useMemo(() => {
        return authors.filter(
            (x) =>
                x?.toLowerCase().includes(filter?.toLowerCase()) ||
                emailMap.get(x)?.toLowerCase().includes(filter?.toLowerCase())
        );
    }, [filter, authors, emailMap]);

    return (
        <Menu.Dropdown style={{ width: 'min-content' }}>
            <TextInput
                icon={<IconSearch size={rem(12)} />}
                placeholder={'Search by name...'}
                value={filter}
                size={'xs'}
                onChange={setFilter}
                styles={{
                    input: { border: 0 }
                }}
            />
            <Menu.Divider mb={'xs'} />
            <ScrollArea.Autosize h={140}>
                {filteredUsers.map((x) => {
                    const name = nameMap.get(x);
                    const email = emailMap.get(x);

                    return (
                        <Menu.Item
                            key={x}
                            onClick={(event: React.MouseEvent) => {
                                event.stopPropagation();
                                onSelect(FilterFactory.user(x));
                            }}
                            icon={<IconUser size={rem(ICON_SIZE)} />}>
                            <Text size="xs">{name === email ? x : name}</Text>
                            <Text size="xs">{email}</Text>
                        </Menu.Item>
                    );
                })}
            </ScrollArea.Autosize>
        </Menu.Dropdown>
    );
};
