import { useOs } from '@mantine/hooks';

type Keys = 'mod';

interface KeyMapType {
    macos: Record<Keys, string>;
    other: Record<Keys, string>;
}

const keyMap: KeyMapType = {
    macos: {
        mod: '⌘'
    },
    other: {
        mod: 'Ctrl'
    }
};

export default function useOsHotkeys() {
    const os = useOs();

    if (os === 'macos') {
        return keyMap.macos;
    }

    return keyMap.other;
}
