import { createStyles } from '@mantine/core';

export const useCompanyBadgeStyles = createStyles((theme) => {
    return {
        root: {
            cursor: 'pointer',
            color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
            width: 'max-content',
            backgroundColor: 'transparent',
            borderRadius: 5,
            borderColor: 'inherit',
            '&:hover': {
                color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
                backgroundColor: theme.other.getHoverColor(theme)
            },
            textTransform: 'unset'
        },
        removeButton: {
            '&:hover': {
                color: theme.colorScheme === 'light' ? theme.colors.gray[7] : undefined,
                backgroundColor:
                    theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
            }
        }
    };
});
