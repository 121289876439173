import * as privmx from 'privfs-client';
import * as PmxApi from 'privmx-server-api';
import { BaseApi } from './BaseApi';

export class SharedFileApi extends BaseApi implements PmxApi.api.sharedFile.ISharedFileApi {
    createSharedFile(
        model: PmxApi.api.sharedFile.CreateSharedFileModel
    ): Promise<{ sharedFile: PmxApi.api.sharedFile.SharedFile }> {
        return this.request('createSharedFile', model);
    }

    deleteSharedFile(
        model: PmxApi.api.sharedFile.DeleteSharedFileModel
    ): Promise<PmxApi.api.core.OK> {
        return this.request('deleteSharedFile', model);
    }

    updateSharedFile(
        model: PmxApi.api.sharedFile.UpdateSharedFileModel
    ): Promise<{ sharedFile: PmxApi.api.sharedFile.SharedFile }> {
        return this.request('updateSharedFile', model);
    }

    getSharedFile(
        model: PmxApi.api.sharedFile.GetSharedFileModel
    ): Promise<{ sharedFile: PmxApi.api.sharedFile.SharedFile }> {
        return this.request('getSharedFile', model);
    }

    getSharedFiles(): Promise<{ sharedFiles: PmxApi.api.sharedFile.SharedFile[] }> {
        return this.request('getSharedFiles', {});
    }

    getSharedFileData(
        model: PmxApi.api.sharedFile.GetSharedFileDataModel
    ): Promise<{ data: privmx.Buffer.Buffer }> {
        return this.request('getSharedFileData', model);
    }

    getPublicSharedFile(
        model: PmxApi.api.sharedFile.GetPublicSharedFileModel
    ): Promise<{ publicSharedFile: PmxApi.api.sharedFile.PublicSharedFile }> {
        return this.request('getPublicSharedFile', model);
    }

    getPublicSharedFileData(
        model: PmxApi.api.sharedFile.GetPublicSharedFileDataModel
    ): Promise<{ data: privmx.Buffer.Buffer }> {
        return this.request('getPublicSharedFileData', model);
    }
}
