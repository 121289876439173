import type * as threadTypes from '../../../../api/privmx/ThreadService';
import { LastMessage, Message } from '../../../../types/Types';
import MeetingScheduledMessage from './MeetingScheduledMessage';
import UsersAddedMessage from './UsersAddedMessage';
import UsersRemovedMessage from './UsersRemovedMessage';
import { useTranslation } from 'react-i18next';
import { Text, Group, GroupProps, createStyles, rem } from '@mantine/core';
import UserAvatar from '../../../../atoms/UserAvatar';
import UserDisplayedName from '../../../../atoms/UserDisplayedName';
import {
    displayFormatedTimeOfDay,
    displayFullFormatedTime,
    displayformatedTime
} from '../../../../utils/RelativeTimeFormat';

export interface JsonMessageProps {
    message: Message | LastMessage;
    withBorder?: boolean;
    withButton?: boolean;
    isLastMessage?: boolean;
}

const useJsonStyles = createStyles(
    (
        theme,
        { withBorder = true, isLastMessage }: { withBorder?: boolean; isLastMessage?: boolean }
    ) => {
        const avatarSize = rem(20);

        return {
            root: {
                marginInline: 'auto',
                border: withBorder
                    ? `1px solid ${
                          theme.colorScheme === 'dark' ? theme.black[4] : theme.colors.gray[4]
                      }`
                    : 0,
                width: 'clamp(400px,50%,600px)',
                padding: withBorder ? theme.spacing.xs : 0,
                paddingInline: withBorder ? theme.spacing.md : 0,
                borderRadius: theme.radius.sm,
                gap: 4,
                '& .mantine-Text-root': {
                    fontSize: 12
                },
                '& .mantine-Group-root': {
                    gap: 4,
                    alignContent: 'baseline'
                },
                '& .mantine-Avatar-root': {
                    opacity: 0.6,
                    width: avatarSize,
                    minWidth: avatarSize,
                    height: avatarSize,
                    fontSize: `calc(${avatarSize} / 2.5)`
                },
                [`@media (max-width: ${theme.breakpoints.md})`]: {
                    width: '100%',
                    marginInline: 'auto'
                }
            },
            content: {}
        };
    }
);

function JsonMessageContainer({
    children,
    className,
    withBorder,
    isLastMessage,
    ...rest
}: { children: React.ReactNode; withBorder?: boolean; isLastMessage: boolean } & GroupProps) {
    const { classes, cx } = useJsonStyles({ withBorder, isLastMessage });
    return (
        <Group position="center" className={cx(classes.root, className)} {...rest}>
            {children}
        </Group>
    );
}

export function JsonMessage({ message, withBorder, isLastMessage = false }: JsonMessageProps) {
    const { t } = useTranslation();
    let msgData: { type: string };
    try {
        msgData = JSON.parse(message.text);
        if (msgData.type === 'usersAdded') {
            return (
                <JsonMessageContainer isLastMessage={isLastMessage} withBorder={withBorder}>
                    <UsersAddedMessage
                        message={message}
                        data={msgData as threadTypes.UsersAddedMessage}
                    />
                </JsonMessageContainer>
            );
        }
        if (msgData.type === 'usersRemoved') {
            return (
                <JsonMessageContainer isLastMessage={isLastMessage} withBorder={withBorder}>
                    <UsersRemovedMessage
                        message={message}
                        data={msgData as threadTypes.UsersRemovedMessage}
                    />
                </JsonMessageContainer>
            );
        }
        if (msgData.type === 'meetingScheduled') {
            return (
                <JsonMessageContainer isLastMessage={isLastMessage} withBorder={withBorder}>
                    <MeetingScheduledMessage
                        message={message}
                        data={msgData as threadTypes.MeetingScheduledMessage}
                    />
                </JsonMessageContainer>
            );
        }
        if (msgData.type === 'meetingStarted') {
            return (
                <JsonMessageContainer isLastMessage={isLastMessage} withBorder={withBorder}>
                    <Group spacing="sm">
                        <Group spacing={8}>
                            <UserAvatar size={30} username={message.author}></UserAvatar>
                            <UserDisplayedName color="dimmed" size="sm" user={message.author} />
                        </Group>
                        <Text color="dimmed" size="sm">
                            {t('successMessage.xStartedMeeting')}
                        </Text>
                    </Group>
                </JsonMessageContainer>
            );
        }
        if (msgData.type === 'firstMessage') {
            return (
                <JsonMessageContainer isLastMessage={isLastMessage} withBorder={withBorder}>
                    <Group spacing={4} position="center">
                        <UserAvatar
                            size={16}
                            sx={{ display: 'inline-block', lineHeight: '16' }}
                            username={message.author}></UserAvatar>
                        <UserDisplayedName color="dimmed" size="sm" user={message.author} />
                        <Text color="dimmed" size="sm" align="center">
                            {t('components.chatEvents.firstMessage.createdTopic')}
                        </Text>
                    </Group>
                </JsonMessageContainer>
            );
        }
        if (msgData.type === 'meetingEdit') {
            const data = msgData as {
                type: 'meetingEdit';
                prevStartDate: number;
                currentStartDate: number;
                prevDuration: number;
                currentDuration: number;
            };

            return (
                <JsonMessageContainer isLastMessage={isLastMessage} withBorder={withBorder}>
                    <Group spacing={4} position="center">
                        <UserAvatar
                            size={16}
                            sx={{ display: 'inline-block', lineHeight: '16' }}
                            username={message.author}></UserAvatar>
                        <UserDisplayedName color="dimmed" size="sm" user={message.author} />
                        <Text color="dimmed" size="sm" align="center">
                            moved the meeting from
                        </Text>
                        <Text color="dimmed" size="sm">
                            {getFormatedDuration(
                                data.prevStartDate,
                                data.prevStartDate + data.prevDuration
                            )}
                        </Text>
                        <Text color="dimmed" size="sm">
                            to
                        </Text>
                        <Text color="dimmed" size="sm">
                            {getFormatedDuration(
                                data.currentStartDate,
                                data.currentStartDate + data.currentDuration
                            )}
                        </Text>
                    </Group>
                </JsonMessageContainer>
            );
        }
    } catch {}
    return (
        <Text align="center" color="dimmed">
            {t('errorMessage.unableToReadMessage')}
        </Text>
    );
}

function getFormatedDuration(start: number, end: number) {
    const eventStartDate = displayformatedTime(start);
    const eventEndDate = displayformatedTime(end);

    if (eventEndDate === eventStartDate) {
        const startTime = displayFormatedTimeOfDay(start);
        const endTime = displayFormatedTimeOfDay(end);
        return `${startTime} - ${endTime}, ${eventStartDate}`;
    } else {
        return `${displayFullFormatedTime(start)} - ${displayFullFormatedTime(end)}`;
    }
}
