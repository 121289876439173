import { useMemo, useState } from 'react';
import * as types from '../../../types/Types';
import { FileTile } from './FileTile';
import { Center, Flex, Pagination } from '@mantine/core';
import { useDebouncedState, useResizeObserver } from '@mantine/hooks';

export interface TileFilesViewProps {
    files: types.AttachmentEx[];
    onShowFilePreview: (fileId: types.AttachmentId) => void;
}

const minTilesCountPerPage = 40;
const tileDesiredWidth = 335;

export function TileFilesView(props: TileFilesViewProps) {
    const [firstFileIdxToShow, setFirstFileIdxToShow] = useState(0);
    const [tileWidth, setTileWidth] = useDebouncedState(tileDesiredWidth, 200, { leading: true });
    const [tilesCountPerPage, setTilesCountPerPage] = useDebouncedState(minTilesCountPerPage, 200, {
        leading: true
    });

    const numPages = Math.ceil(props.files.length / tilesCountPerPage);
    const currentPageId = Math.floor(firstFileIdxToShow / tilesCountPerPage);

    const files = useMemo(() => {
        return props.files.slice(firstFileIdxToShow, firstFileIdxToShow + tilesCountPerPage);
    }, [props.files, firstFileIdxToShow, tilesCountPerPage]);

    const [ref, rect] = useResizeObserver();
    const numTilesPerRow = Math.floor(rect.width / tileDesiredWidth);
    const filledTilesCountPerPage =
        Math.ceil(minTilesCountPerPage / numTilesPerRow) * numTilesPerRow;
    if (tilesCountPerPage !== filledTilesCountPerPage) {
        setTilesCountPerPage(filledTilesCountPerPage);
    }
    const newTileWidth = (rect.width - 20 * numTilesPerRow) / numTilesPerRow;
    if (!isNaN(newTileWidth) && newTileWidth !== tileWidth) {
        setTileWidth(newTileWidth);
    }

    return (
        <div>
            <Flex ref={ref} rowGap="40px" columnGap="xl" wrap="wrap">
                {files.map((file) => (
                    <FileTile
                        key={file.id}
                        file={file}
                        width={tileWidth}
                        onShowFilePreview={props.onShowFilePreview}
                    />
                ))}
            </Flex>
            {numPages > 1 && (
                <Center my="xl">
                    <Pagination
                        total={numPages}
                        value={currentPageId + 1}
                        onChange={(newPageId) =>
                            setFirstFileIdxToShow(tilesCountPerPage * (newPageId - 1))
                        }
                    />
                </Center>
            )}
        </div>
    );
}
