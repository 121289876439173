import * as types from '../types/Types';

export interface ImportedForm {
    id: string;
    name: string;
    questions: types.QuestionModel[];
}

export class FormImporter {
    import(html: string): ImportedForm[] {
        const formElements = this.getFormElements(html);
        const importedForms: ImportedForm[] = formElements.map((formElement) => {
            const formTagHtml = formElement.outerHTML.split(formElement.innerHTML)[0];
            const questions = this.getFormQuestions(formElement);
            return {
                id: Math.random().toString(36).substr(2) + '-' + Date.now(),
                name: formTagHtml,
                questions: questions
            };
        });
        return importedForms.filter((x) => x.questions.length > 0);
    }

    private getFormElements(html: string): HTMLFormElement[] {
        const tpl = document.createElement('template');

        tpl.innerHTML = html;
        const formElements = [...tpl.content.querySelectorAll('form')];
        if (formElements.length > 0) {
            return formElements;
        }

        tpl.innerHTML = `<form>${html}</form>`;
        return [...tpl.content.querySelectorAll('form')];
    }

    private getFormQuestions(formElement: HTMLFormElement): types.QuestionModel[] {
        const shortInputTypes = [
            'color',
            'date',
            'datetime-local',
            'email',
            'month',
            'number',
            'range',
            'search',
            'tel',
            'text',
            'time',
            'url',
            'week'
        ];
        const shortInputSelectors = shortInputTypes.map((x) => `input[type="${x}"]`);
        const shortInputSelectorsStr = shortInputSelectors.join(', ');
        const shortInputs = [
            ...formElement.querySelectorAll(`${shortInputSelectorsStr}, input:not([type])`)
        ].filter((x) => !!x.getAttribute('name'));
        const longInputs = [...formElement.querySelectorAll('textarea[name]')];
        const checkboxes = this.groupInputsByName([
            ...formElement.querySelectorAll("input[type='checkbox'][name]")
        ] as HTMLInputElement[]);
        const radios = this.groupInputsByName([
            ...formElement.querySelectorAll("input[type='radio'][name]")
        ] as HTMLInputElement[]);
        const singleSelects = [
            ...formElement.querySelectorAll('select[name]:not([multiple])')
        ] as HTMLSelectElement[];
        const multiSelects = [
            ...formElement.querySelectorAll('select[multiple][name]')
        ] as HTMLSelectElement[];

        return [
            ...shortInputs.map((x) => {
                const q: types.SingleAnswerQuestionModel<'short'> = {
                    type: 'short',
                    id: x.getAttribute('name')!,
                    title: x.getAttribute('name')!,
                    required: x.hasAttribute('required'),
                    answer: {
                        type: 'short',
                        input: '',
                        id: x.getAttribute('name')! + '-ans'
                    }
                };
                return q;
            }),
            ...longInputs.map((x) => {
                const q: types.SingleAnswerQuestionModel<'long'> = {
                    type: 'long',
                    id: x.getAttribute('name')!,
                    title: x.getAttribute('name')!,
                    required: x.hasAttribute('required'),
                    answer: {
                        type: 'long',
                        input: '',
                        id: x.getAttribute('name')! + '-ans'
                    }
                };
                return q;
            }),
            ...singleSelects.map((x) => {
                const options = this.getSelectOptions(x);
                const q: types.MultipleAnswerQuestionModel<'single'> = {
                    type: 'single',
                    id: x.getAttribute('name')!,
                    title: x.getAttribute('name')!,
                    required: x.hasAttribute('required'),
                    answer: options.map((opt) => ({
                        type: 'single',
                        input: opt.text,
                        id: opt.value
                    }))
                };
                return q;
            }),
            ...multiSelects.map((x) => {
                const options = this.getSelectOptions(x);
                const q: types.MultipleAnswerQuestionModel<'select'> = {
                    type: 'select',
                    id: x.getAttribute('name')!,
                    title: x.getAttribute('name')!,
                    required: x.hasAttribute('required'),
                    answer: options.map((opt) => ({
                        type: 'single',
                        input: opt.text,
                        id: opt.value
                    }))
                };
                return q;
            }),
            ...radios.map((x) => {
                const q: types.MultipleAnswerQuestionModel<'single'> = {
                    type: 'single',
                    id: x[0].getAttribute('name')!,
                    title: x[0].getAttribute('name')!,
                    required: x.some((y) => y.hasAttribute('required')),
                    answer: x.map((opt) => ({
                        type: 'single',
                        input: opt.value,
                        id: opt.value
                    }))
                };
                return q;
            }),
            ...checkboxes.map((x) => {
                const q: types.MultipleAnswerQuestionModel<'select'> = {
                    type: 'select',
                    id: x[0].getAttribute('name')!,
                    title: x[0].getAttribute('name')!,
                    required: false,
                    answer: x.map((opt) => ({
                        type: 'single',
                        input: opt.value,
                        id: opt.value
                    }))
                };
                return q;
            })
        ];
    }

    private getSelectOptions(selectElement: HTMLSelectElement) {
        const optionElements = [...selectElement.querySelectorAll('option')];
        const options = optionElements.map((x) => ({
            value: x.getAttribute('value')!,
            text: x.innerText
        }));
        return options;
    }

    private groupInputsByName(inputs: HTMLInputElement[]) {
        const map: { [name: string]: HTMLInputElement[] } = {};
        for (const input of inputs) {
            const inputName = input.getAttribute('name')!;
            if (!map[inputName]) {
                map[inputName] = [];
            }
            map[inputName].push(input);
        }
        return Object.values(map);
    }
}
