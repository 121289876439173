import { AlertProps, Alert, Group, Button, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { MouseEventHandler } from 'react';

export default function FormAlert({
    onClick,
    label,
    onClear,
    ...props
}: Omit<AlertProps, 'onClick' | 'children'> & {
    onClick: MouseEventHandler;
    onClear?: Function;
    label: string;
}) {
    return (
        <Alert
            styles={{
                label: { width: '100%' },
                title: {
                    marginBottom: 0
                },
                wrapper: {
                    gap: 8,
                    alignItems: 'center'
                },
                icon: {
                    justifyContent: 'center',
                    marginRight: 0
                }
            }}
            icon={<IconAlertCircle size="1rem" />}
            title={
                <Group position="apart" w="100%" sx={{ flexGrow: 1 }}>
                    <Text>{label}</Text>
                    <Group>
                        {onClear && (
                            <Button variant="light" onClick={() => onClear()}>
                                Clear
                            </Button>
                        )}
                        <Button size="sm" variant="filled" onClick={onClick}>
                            Save
                        </Button>
                    </Group>
                </Group>
            }
            variant="light"
            color="brand"
            {...props}>
            {/* Must contain children */}
            <></>
        </Alert>
    );
}
