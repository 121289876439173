import { api } from '../../api/Api';
import { Limits } from '../../api/Limits';
import { FileChooser } from '../../utils/FileChooser';
import { ContactImportData } from '../../utils/contactImporters/ContactImportData';
import {
    ImportedContactsFile,
    ContactsImporter
} from '../../utils/contactImporters/ContactsImporter';
import { ImportedFileImportResult, ImprtedFileImportResultFlat } from './types';
import { Utils } from '../../utils/Utils';
import { t } from 'i18next';

export function flattenImportResults(
    results: ImportedFileImportResult[]
): ImprtedFileImportResultFlat[] {
    const flatResults: ImprtedFileImportResultFlat[] = [];
    for (const r of results) {
        if (r.result === 'fileOk') {
            for (const cr of r.contactResults) {
                flatResults.push({
                    result: cr.result,
                    message: r.message,
                    importedFile: r.importedFile,
                    contactName: cr.name
                });
            }
        } else {
            flatResults.push({
                result: r.result,
                message: r.message,
                importedFile: r.importedFile
            });
        }
    }
    return flatResults;
}

export async function importContactsFromFiles(files: File[]) {
    const tooLargeFiles = files.filter((x) => x.size > Limits.MAX_IMPORTED_CONTACTS_FILE_SIZE_B);
    const okSizeFiles = files.filter((x) => x.size <= Limits.MAX_IMPORTED_CONTACTS_FILE_SIZE_B);

    const notImportedResults: ImportedFileImportResult[] = [];
    for (const file of tooLargeFiles) {
        notImportedResults.push({
            importedFile: {
                name: file.name,
                content: ''
            },
            result: 'fileError',
            message: t('errorMessage.maxFileSize', {
                maxFileSize: Utils.bytesSize(Limits.MAX_IMPORTED_CONTACTS_FILE_SIZE_B)
            }),
            contactResults: []
        });
    }
    const toImport = await Promise.all(
        okSizeFiles.map(async (x) => ({
            name: x.name,
            content: await FileChooser.readBlobAsText(x)
        }))
    );
    const importResult = await importContacts(toImport);
    return [...importResult, ...notImportedResults];
}

export async function importContacts(
    importedFiles: ImportedContactsFile[]
): Promise<ImportedFileImportResult[]> {
    const results: ImportedFileImportResult[] = [];
    await Promise.all(
        importedFiles.map(async (importedFile) => {
            if (importedFile.content === '') {
                results.push({
                    importedFile: importedFile,
                    result: 'fileError',
                    contactResults: []
                });
                return;
            }

            let dataArr: ContactImportData[] = [];
            try {
                dataArr = ContactsImporter.parse(importedFile);
            } catch {
                results.push({
                    importedFile: importedFile,
                    result: 'fileError',
                    contactResults: []
                });
                return;
            }

            let result: ImportedFileImportResult = {
                importedFile: importedFile,
                result: 'fileOk',
                contactResults: []
            };
            for (const data of dataArr) {
                try {
                    await api.importContacts(data);
                    result.contactResults.push({ name: data.name, result: 'imported' });
                } catch (err: any) {
                    console.error('Error while importing contact:', err);
                    let msg: string = '';
                    if (err && typeof err.msg === 'string') {
                        msg = err.msg;
                    } else if (err && typeof err.toString === 'function') {
                        msg = err.toString();
                    } else if (typeof err === 'string') {
                        msg = err;
                    }
                    if (msg.includes('already exists')) {
                        result.contactResults.push({ name: data.name, result: 'alreadyExists' });
                    } else if (msg.includes('Email is required')) {
                        result.contactResults.push({ name: data.name, result: 'emailRequired' });
                    } else {
                        result.contactResults.push({ name: data.name, result: 'unknownError' });
                    }
                }
            }
            results.push(result);
        })
    );
    return results;
}
