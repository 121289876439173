import { Outlet, RouteObject, createBrowserRouter } from 'react-router-dom';
import App from '../../App';
import PublicFormAnswer from '../../components/FormPage/PublicFormAnswer';
import { OnlyStaff, OnlyLogged, OnlyGuest, OnlyValidMeetingUser } from '../../components/OnlyStaff';
import ChatScreen from '../../screens/ChatScreen';
import ContactFormScreen from '../../screens/ContactFormScreen';
import { ContactPage, ContactScreenInitial, ContactsScreen } from '../../screens/ContactsScreen';
import LoginScreen from '../../screens/LoginScreen';
import MeetingScreen from '../../screens/MeetingsScreen';
import FavoritesScreen from '../../screens/FavoritesScreen';
import MeetingLobbyWaitingPage from '../../components/MeetingLobbyWaitingPage';
import MeetingRoomPage from '../../components/MeetingRoomPage';
import PublicFormSubmitResponsePage from '../../components/PublicFormSubmitResponsePage';
import ActivateFirstAccountScreen from '../../screens/ActivateFirstAccountScreen';
import ChatPage from '../../components/ChatPage';
import ChatLayout from '../../screens/ChatScreen/ChatLayout';
import MeetingLayout from '../../screens/MeetingsScreen/MeetingLayout';
import { MeetingLobbyPage } from '../../components/MeetingPage/MeetingLobbyView';
import AccountActivated from '../../screens/ActivateFirstAccountScreen/AccountActivated';
import FirstSteps from '../../screens/ActivateFirstAccountScreen/FirstSteps';
import { CompaniesScreen, CompanyPage, CompanyScreenInitial } from '../../screens/CompaniesScreen';
import { ErrorScreen } from '../../screens/ErrorScreen';
import SharedFilesScreen from '../../screens/SharedFilesScreen';
import PublicSharedFilePage from '../../components/PublicSharedFilePage';
import { BrowserNotSupportedScreen } from '../../screens/BrowserNotSupportedScreen/BrowserNotSupportedScreen';
import { BrowserDetector } from '../browserDetector/BrowserDetector';
import {
    FormCreator,
    FormEmbed,
    FormResponses,
    FormScreen,
    FormSettings,
    FormShare,
    FormsScreen
} from '../../screens/FormsScreen';
import WelcomeScreen from '../../screens/WelcomeScreen';
import Mnemonic from '../../screens/ActivateFirstAccountScreen/Mnemonic';

const routes = [
    {
        path: '/',
        element: BrowserDetector.isBrowserSupported() ? (
            <LoginScreen />
        ) : (
            <BrowserNotSupportedScreen />
        )
    },
    {
        path: 'activateFirstAccount/:token',
        element: <ActivateFirstAccountScreen />,
        children: [
            { path: 'mnemonic', element: <Mnemonic /> },
            { path: 'activated', element: <AccountActivated /> },
            { path: 'firstSteps', element: <FirstSteps /> }
        ]
    },
    {
        path: '/:meetingData',
        element: <LoginScreen />
    },
    {
        path: 'staffLogin/:meetingData',
        element: <LoginScreen userType="staff" />
    },
    {
        path: 'clientLogin/:meetingData',
        element: <LoginScreen userType="client" />
    },
    {
        path: 'welcome/:step',
        element: <WelcomeScreen />
    },
    {
        path: 'joinMeeting/:meetingData',
        element: <LoginScreen userType="anonymous" />
    },
    {
        path: 'formConversation/:meetingData',
        element: <LoginScreen userType="inquirySubmitter" />
    },
    {
        path: 'contactForm',
        element: (
            <OnlyGuest>
                <ContactFormScreen />
            </OnlyGuest>
        )
    },
    {
        path: 'formSubmitResponse/:responseId',
        element: <PublicFormSubmitResponsePage />
    },

    {
        path: 'chat',
        element: (
            <OnlyLogged>
                <ChatLayout />
            </OnlyLogged>
        ),
        children: [
            {
                path: '',
                element: (
                    <OnlyLogged>
                        <ChatScreen />
                    </OnlyLogged>
                )
            },
            {
                path: ':chatId',
                element: (
                    <OnlyLogged>
                        <ChatPage />
                    </OnlyLogged>
                )
            }
        ]
    },
    {
        path: 'meetings',
        element: (
            <OnlyLogged>
                <MeetingLayout />
            </OnlyLogged>
        ),
        children: [
            {
                path: '',
                element: (
                    <OnlyLogged>
                        <MeetingScreen />
                    </OnlyLogged>
                )
            },
            {
                path: ':meetingId',
                element: (
                    <OnlyLogged>
                        <MeetingLobbyPage />
                    </OnlyLogged>
                )
            }
        ]
    },
    {
        path: 'contacts',
        element: (
            <OnlyStaff>
                <ContactsScreen />
            </OnlyStaff>
        ),
        children: [
            {
                path: '',
                element: (
                    <OnlyLogged>
                        <ContactScreenInitial />
                    </OnlyLogged>
                )
            },
            {
                path: ':contactId',
                element: (
                    <OnlyLogged>
                        <ContactPage />
                    </OnlyLogged>
                )
            }
        ]
    },
    {
        path: 'companies',
        element: (
            <OnlyStaff>
                <CompaniesScreen />
            </OnlyStaff>
        ),
        children: [
            {
                path: '',
                element: (
                    <OnlyLogged>
                        <CompanyScreenInitial />
                    </OnlyLogged>
                )
            },
            {
                path: ':companyId',
                element: (
                    <OnlyLogged>
                        <CompanyPage />
                    </OnlyLogged>
                )
            }
        ]
    },
    {
        path: 'form-answer/:formId',
        element: <PublicFormAnswer />
    },
    {
        path: 'forms',
        element: (
            <>
                <Outlet />
            </>
        ),
        children: [
            {
                path: '',
                element: (
                    <OnlyStaff>
                        <FormsScreen />
                    </OnlyStaff>
                )
            },
            {
                path: ':formId',
                element: (
                    <OnlyStaff>
                        <FormScreen />
                    </OnlyStaff>
                ),
                children: [
                    {
                        path: 'questions',
                        index: true,
                        element: (
                            <OnlyStaff>
                                <FormCreator />
                            </OnlyStaff>
                        )
                    },
                    {
                        path: 'share',
                        element: (
                            <OnlyStaff>
                                <FormShare />
                            </OnlyStaff>
                        )
                    },
                    {
                        path: 'embed',
                        element: (
                            <OnlyStaff>
                                <FormEmbed />
                            </OnlyStaff>
                        )
                    },
                    {
                        path: 'responses',
                        element: (
                            <OnlyStaff>
                                <FormResponses />
                            </OnlyStaff>
                        )
                    },
                    {
                        path: 'settings',
                        element: (
                            <OnlyStaff>
                                <FormSettings />
                            </OnlyStaff>
                        )
                    }
                ]
            }
        ]
    },

    {
        path: 'bookmarks',
        element: (
            <OnlyLogged>
                <FavoritesScreen />
            </OnlyLogged>
        ),
        children: [
            {
                path: ':chatId/:messageId',
                element: <></>
            }
        ]
    },
    {
        path: 'shared_files',
        element: (
            <OnlyStaff>
                <SharedFilesScreen />
            </OnlyStaff>
        ),
        children: [
            {
                path: ':sharedFile',
                element: <></>
            }
        ]
    },
    {
        path: 'share/:sharedFileId',
        element: <PublicSharedFilePage />
    },

    {
        path: 'meetingLobby/:meetingData',
        element: (
            <OnlyValidMeetingUser>
                <MeetingLobbyWaitingPage />
            </OnlyValidMeetingUser>
        )
    },
    {
        path: 'meetingRoom/:meetingData',
        element: (
            <OnlyValidMeetingUser>
                <MeetingRoomPage />
            </OnlyValidMeetingUser>
        )
    }
] as RouteObject[];

export type RouterPaths = (typeof routes)[number]['path'];

export const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <App />,
            children: routes,
            errorElement: <ErrorScreen />
        }
    ],
    { basename: process.env.REACT_APP_CONTEXT_PATH || '/' }
);
