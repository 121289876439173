import { JobManager } from './JobManager';
import { KeyValueStorage } from './KeyValueStorage';
import { ThreadInfo, ThreadSigned } from './ThreadService';
import * as privmx from 'privfs-client';
import { EccUtils } from './EccUtils';
import { PkiService } from './PkiService';

export type VerifyThreadResult =
    | 'verified'
    | 'mismatch'
    | 'missing_seed'
    | 'no-signature'
    | 'invalid';
export interface EventDispatcher {
    dispatchEvent<T extends { type: string }>(event: T): void;
}
export interface ThreadVerifiedStatusChangedCoreEvent {
    type: 'threadverifiedstatuschangedcore';
    thread: ThreadInfo;
    verified: VerifyThreadResult;
}

export class ThreadVerifier {
    private keyValueStorage: KeyValueStorage;
    private jobManager = new JobManager();

    constructor(
        private pkiService: PkiService,
        private eventDispatcher: EventDispatcher,
        private cachePrefix: string
    ) {
        this.keyValueStorage = new KeyValueStorage(this.cachePrefix);
    }

    getVerifyStatus(thread: ThreadInfo) {
        return this.keyValueStorage.get<VerifyThreadResult>(this.getStorageKey(thread));
    }

    verifyThread(thread: ThreadInfo, startJustNow?: boolean) {
        return this.jobManager.startJob(
            `verify-thread-${thread.signature.server}-${thread.thread.id}`,
            async () => {
                const storageKey = this.getStorageKey(thread);
                const result = this.keyValueStorage.get<VerifyThreadResult>(storageKey);
                if (result != null) {
                    return result;
                }
                const res =
                    thread.threadIdStatus === 'verified'
                        ? await this.verifyThreadCore(thread.signature)
                        : thread.threadIdStatus;
                this.keyValueStorage.set(storageKey, res);
                this.eventDispatcher.dispatchEvent<ThreadVerifiedStatusChangedCoreEvent>({
                    type: 'threadverifiedstatuschangedcore',
                    thread: thread,
                    verified: res
                });
                return res;
            },
            startJustNow
        );
    }

    private getStorageKey(thread: ThreadInfo) {
        return `threadverified:${thread.signature.server}/${thread.thread.id}`;
    }

    private async verifyThreadCore(thread: ThreadSigned): Promise<VerifyThreadResult> {
        if (thread.signature.length === 0) {
            return 'no-signature';
        }
        try {
            const pubKey = await this.pkiService.getUserPubKeyUsingCache(
                thread.data.author.username,
                thread.data.author.server
            );
            if (!EccUtils.publicKeysAreEqual(pubKey.getBase58Der(), thread.data.author.pubKey)) {
                return 'invalid';
            }
            const result = await privmx.crypto.service.verifyCompactSignatureWithHash(
                pubKey.getPublicKey(),
                thread.dataBuf,
                thread.signature
            );
            return result ? 'verified' : 'invalid';
        } catch (e) {
            console.error('Error during thread verification', e);
            return 'invalid';
        }
    }
}
