import * as privmx from 'privfs-client';
import { RequestApi } from './RequestApi';
import { ThreadApi } from './ThreadApi';
import * as PmxApi from 'privmx-server-api';
import { StickerService } from './StickerService';
import { DataEncryptor } from './DataEncryptor';
import { EncKey, KeyProvider } from './KeyProvider';
import { EccUtils } from './EccUtils';
import { Result, Utils } from './utils/Utils';
import { MessageDataEncryptor } from './MessageDataEncryptor';
import {
    AttachmentMeta,
    AttachmentsToCopy,
    AttachmentUtils,
    DownloadOptions,
    ExistingAttachmentsProvider,
    PreparedFile
} from './AttachmentUtils';
import { MessageClientId, Mimetype, FileName, FileSize, Tag, MeetingId } from '../../types/Types';
import * as types from '../../types/Types';
import { UserService } from './UserService';
import { Inquiry, InquiryService, InquirySubmit } from './InquiryService';
import { TagService } from './TagService';
import { StateUpdaterInterface } from '../StateUpdaterInterface';
import { DataCacheInterface } from '../DataCacheInterface';
import { ModalsInterface } from '../ModalsInterface';
import { Base64 } from './utils/Base64';
import { ThreadDataEncryptor } from './ThreadDataEncryptor';

export interface ThreadData {
    v: 1;
    threadKey: PmxApi.api.core.Base64;
    title: string;
    props: ThreadProps;
    threadIdSeed: PmxApi.api.core.Base64;
    signature: PmxApi.api.core.Base64;
    signatureData: PmxApi.api.core.Base64;
}

export interface ThreadSignatureData {
    id: PmxApi.api.thread.ThreadId;
    author: {
        server: ServerId;
        username: PmxApi.api.core.Username;
        pubKey: PmxApi.api.core.EccPubKey;
    };
}

export type ThreadProps = Record<string, unknown>;

export type ThreadIdStatus = 'verified' | 'mismatch' | 'missing_seed';

export interface ThreadInfo {
    thread: PmxApi.api.thread.Thread;
    server: ServerId;
    dataEntry: PmxApi.api.thread.ThreadDataEntry;
    data: ThreadData;
    threadKey: Buffer;
    keys: EncKey[];
    currentKey: EncKey;
    tags: Tag[];
    lastMsg: types.LastMessage | undefined;
    threadIdStatus: ThreadIdStatus;
    signature: ThreadSigned;
}

export interface ThreadSigned {
    server: ServerId;
    data: ThreadSignatureData;
    dataBuf: Buffer;
    signature: Buffer;
}

export type ServerId = string & { __serverId: never };

export interface MessageData {
    v: 2;
    msgId: MessageClientId;
    type: Mimetype;
    text: string;
    date: number;
    deleted?: boolean;
    author: {
        server: ServerId;
        username: PmxApi.api.core.Username;
        pubKey: PmxApi.api.core.EccPubKey;
    };
    destination: {
        server: ServerId;
        threadId: PmxApi.api.thread.ThreadId;
    };
    attachments: MessageAttachment[];
}

export interface MessageAttachment {
    hmac: string; // base64
    name: FileName;
    mimetype: Mimetype;
    size: FileSize;
    hasThumb: boolean;
}

export interface MessageAttachmentWithId {
    id: PmxApi.api.attachment.AttachmentId;
    attachment: MessageAttachment;
}

export interface MessageDataSigned {
    server: ServerId;
    data: MessageData;
    dataBuf: Buffer;
    signature: Buffer;
}

export interface MessageFull {
    msg: PmxApi.api.thread.Message;
    sig: Result<MessageDataSigned>;
}
export interface MessageFullX {
    msg: PmxApi.api.thread.Message;
    sig: MessageDataSigned;
}

export interface PmxAttachment {
    threadId: PmxApi.api.thread.ThreadId;
    group: PmxApi.api.attachment.AttachmentGroup;
    tags: Tag[];
    attachmentId: PmxApi.api.attachment.AttachmentId;
    messageId: PmxApi.api.thread.MessageId;
    author: PmxApi.api.core.Username;
    date: PmxApi.api.core.TimestampN;
    meta: AttachmentMeta;
    hasThumb: boolean;
}

export interface PmxAttachmentEx extends PmxAttachment {
    createdDate: PmxApi.api.core.TimestampN;
    creator: PmxApi.api.core.Username;
    versions: number;
    contributors: PmxApi.api.core.Username[];
    modificationDates: PmxApi.api.core.TimestampN[];
}

export interface UsersAddedMessage {
    type: 'usersAdded';
    addedUsernames: PmxApi.api.core.Username[];
    allUsernamesWithAccess: PmxApi.api.core.Username[];
}

export interface UsersRemovedMessage {
    type: 'usersRemoved';
    removedUsernames: PmxApi.api.core.Username[];
    allUsernamesWithAccess: PmxApi.api.core.Username[];
}

export interface MeetingScheduledMessage {
    type: 'meetingScheduled';
    meetingId?: MeetingId;
}

export interface MeetingStartedMessage {
    type: 'meetingStarted';
    meetingId: MeetingId;
}

export interface TopicAdded {
    type: 'firstMessage';
}

export function generateMessageId() {
    return privmx.crypto.serviceSync.randomBytes(16).toString('hex') as MessageClientId;
}

export class ThreadService {
    private requestApi: RequestApi;
    private threadApi: ThreadApi;
    private threadDataEncryptor = new ThreadDataEncryptor();
    private messageDataEncryptor = new MessageDataEncryptor();
    private attachmentMetaEncryptor = new DataEncryptor<
        AttachmentMeta,
        PmxApi.api.attachment.AttachmentMeta
    >();
    private attachmentUtils: AttachmentUtils<MessageAttachment, EncKey>;
    private cachedInquiryPrivKeys: Record<
        PmxApi.api.inquiry.InquiryId,
        privmx.crypto.ecc.PrivateKey
    > = {};

    constructor(
        private gateway: privmx.gateway.RpcGateway,
        private identity: privmx.identity.Identity,
        private keyProvider: KeyProvider,
        private inquiryService: InquiryService | undefined,
        private stickerService: StickerService,
        private userService: UserService,
        private tagService: TagService,
        existingAttachmentsProvider: ExistingAttachmentsProvider,
        private threadLinkDataProvider: () => types.ThreadLinkData | null,
        private formThreadPasswordProvider: () => string | null,
        private stateUpdater: StateUpdaterInterface,
        private cacheService: DataCacheInterface,
        private modalsService: ModalsInterface
    ) {
        this.requestApi = new RequestApi(this.gateway);
        this.threadApi = new ThreadApi(this.gateway);
        this.attachmentUtils = new AttachmentUtils(
            this.requestApi,
            this.attachmentMetaEncryptor,
            (preparedFile, hasThumb) =>
                this.preparedFileToAttachmentConverter(preparedFile, hasThumb),
            existingAttachmentsProvider,
            this.cacheService,
            this.modalsService
        );
    }

    private getServerId() {
        return this.identity.host as ServerId;
    }

    private getMe() {
        return {
            serverId: this.getServerId(),
            username: this.identity.user as PmxApi.api.core.Username,
            priv: this.identity.priv
        };
    }

    async getThreads(threadType: PmxApi.api.thread.ThreadType) {
        const { threads } = await this.threadApi.getThreads({ type: threadType });
        return threads;
    }

    async getThreadsOfCompany(
        threadType: PmxApi.api.thread.ThreadType,
        companyId: PmxApi.api.company.CompanyId
    ) {
        const { threads } = await this.threadApi.getThreadsOfCompany({
            type: threadType,
            companyId
        });
        return threads;
    }

    async getThreadsOfUser(
        threadType: PmxApi.api.thread.ThreadType,
        user: PmxApi.api.core.Username
    ) {
        const { threads } = await this.threadApi.getThreadsOfUser({ type: threadType, user });
        return threads;
    }

    async getThread(threadId: PmxApi.api.thread.ThreadId) {
        const { thread } = await this.threadApi.getThread({ id: threadId });
        return thread;
    }

    async createThread(
        threadType: PmxApi.api.thread.ThreadType,
        title: string,
        props: ThreadProps,
        tags: string[],
        users: PmxApi.api.core.Username[],
        managers: PmxApi.api.core.Username[]
    ) {
        const id = await this.generateThreadId();
        const allUsers = Utils.unique(users.concat(managers));
        const newThreadKey = this.keyProvider.generateKey();
        const author = this.getMe();
        const threadSignatureData: ThreadSignatureData = {
            id: id.threadId,
            author: {
                username: author.username,
                pubKey: EccUtils.getPublicKey(author.priv),
                server: author.serverId
            }
        };
        const sig = await this.threadDataEncryptor.sign(threadSignatureData, author.priv);
        const newThreadData: ThreadData = {
            v: 1,
            threadKey: Base64.from(privmx.crypto.service.randomBytes(32)),
            title: title,
            props: props,
            signature: sig.signature,
            signatureData: sig.signatureData,
            threadIdSeed: id.threadIdSeed
        };
        const { thread } = await this.threadApi.createThread({
            id: id.threadId,
            keyId: newThreadKey.id,
            data: await this.threadDataEncryptor.encrypt(newThreadData, newThreadKey),
            type: threadType,
            keys: await this.keyProvider.prepareKeysList(allUsers, [], newThreadKey),
            users: users,
            managers: managers,
            tags: await this.tagService.getEncryptedTagsIfSharedScope(tags as types.Tag[])
        });
        await this.tagService.setTagsIfPrivateScope('thread', thread.id, tags as types.Tag[]);
        return thread;
    }

    private async generateThreadId() {
        const threadIdSeedBuffer = privmx.crypto.service.randomBytes(32);
        return this.getThreadId(threadIdSeedBuffer);
    }

    private async getThreadId(threadIdSeedBuffer: Buffer) {
        const threadIdBuffer = (await privmx.crypto.service.sha256(threadIdSeedBuffer)).slice(
            0,
            25
        );
        return {
            threadId: threadIdBuffer.toString('hex') as PmxApi.api.thread.ThreadId,
            threadIdSeed: Base64.from(threadIdSeedBuffer),
            threadIdBuffer: threadIdBuffer,
            threadIdSeedBuffer: threadIdSeedBuffer
        };
    }

    async createInquirySubmitThread(
        inquiry: Inquiry,
        inquirySubmit: InquirySubmit,
        title: string,
        props: ThreadProps,
        tags: string[],
        inquirySubmitAuthor: PmxApi.api.core.Username | null,
        anonPubKey?: privmx.crypto.ecc.PublicKey
    ) {
        const id = await this.generateThreadId();
        const author = this.getMe();
        const currentUser = author.username;
        const users: PmxApi.api.core.Username[] =
            inquirySubmitAuthor === null ? [currentUser] : [currentUser, inquirySubmitAuthor];
        const managers: PmxApi.api.core.Username[] = [currentUser];
        const threadType = 'inquirySubmit' as PmxApi.api.thread.ThreadType;
        const newThreadKey = this.keyProvider.generateKey();
        const threadSignatureData: ThreadSignatureData = {
            id: id.threadId,
            author: {
                username: author.username,
                pubKey: EccUtils.getPublicKey(author.priv),
                server: author.serverId
            }
        };
        const sig = await this.threadDataEncryptor.sign(threadSignatureData, author.priv);
        const newThreadData: ThreadData = {
            v: 1,
            threadKey: Base64.from(privmx.crypto.service.randomBytes(32)),
            title: title,
            props: props,
            signature: sig.signature,
            signatureData: sig.signatureData,
            threadIdSeed: id.threadIdSeed
        };
        const model: PmxApi.api.thread.CreateThreadModel = {
            id: id.threadId,
            keyId: newThreadKey.id,
            data: await this.threadDataEncryptor.encrypt(newThreadData, newThreadKey),
            type: threadType,
            keys: await this.keyProvider.prepareKeysListForInquiryThread(
                inquirySubmitAuthor,
                inquiry,
                newThreadKey,
                anonPubKey
            ),
            users: users,
            managers: managers,
            tags: await this.tagService.getEncryptedTagsIfSharedScope(tags as types.Tag[]),
            inquirySubmitId: inquirySubmit.raw.id
        };
        const { thread } = await this.threadApi.createThread(model);
        await this.tagService.setTagsIfPrivateScope('thread', thread.id, tags as types.Tag[]);
        return thread;
    }

    async decryptThread(thread: PmxApi.api.thread.Thread) {
        const threadDataEntry = thread.data[thread.data.length - 1];
        if (!threadDataEntry) {
            throw new Error('No thread data');
        }
        const inquiryId = thread.type === 'inquirySubmit' ? thread.inquiryId : undefined;
        const username = this.getMe().username;
        const hasAccess = thread.users.includes(username);
        const isManager = thread.managers.includes(username);
        const isRegularThreadUser = hasAccess && !isManager;
        const inquiryPrivKey =
            inquiryId && !isRegularThreadUser ? await this.getInquiryPrivKey(inquiryId) : undefined;
        const threadLinkData = this.threadLinkDataProvider();
        const customPrivKey =
            inquiryPrivKey ??
            (threadLinkData && threadLinkData.type === 'formThread'
                ? await this.getFormThreadLinkDataKey(threadLinkData)
                : undefined);
        const currentKey = await this.keyProvider.getKey(
            thread.keys,
            threadDataEntry.keyId,
            customPrivKey
        );
        const threadData = await this.threadDataEncryptor.decrypt(threadDataEntry.data, currentKey);
        const threadIdStatus = await (async (): Promise<ThreadIdStatus> => {
            if (!threadData.threadIdSeed) {
                return 'missing_seed';
            }
            const id = await this.getThreadId(Base64.toBuf(threadData.threadIdSeed));
            return id.threadId === thread.id ? 'verified' : 'mismatch';
        })();
        const threadKey = Buffer.from(threadData.threadKey, 'base64');
        const tags = await this.tagService.getTags('thread', thread.id, 'all', thread);
        const serverId = this.getServerId();
        const info: ThreadInfo = {
            thread: thread,
            server: serverId,
            dataEntry: threadDataEntry,
            data: threadData,
            threadKey: threadKey,
            keys: [currentKey],
            currentKey: currentKey,
            tags: tags,
            lastMsg: undefined,
            signature: this.threadDataEncryptor.decodeSignature(thread, threadData, serverId),
            threadIdStatus
        };
        if (thread.lastMsg) {
            const lastMsgSig = await Utils.tryPromise(() =>
                this.decryptMessage(info, thread.lastMsg)
            );
            if (lastMsgSig.success) {
                info.lastMsg = {
                    id: thread.lastMsg.id,
                    msgId: lastMsgSig.result.data.msgId,
                    mimetype: lastMsgSig.result.data.type,
                    text: lastMsgSig.result.data.text,
                    author: thread.lastMsg.author,
                    authorIsAnonymousMeetingUser:
                        (thread.lastMsg.author as string) ===
                        (lastMsgSig.result.data.author.pubKey as string),
                    date: thread.lastMsg.createDate,
                    deleted: !!lastMsgSig.result.data.deleted,
                    decryptError: false,
                    threadId: thread.id as types.ChatId
                };
            }
        }
        this.refreshAnonymousUsersFromThreadMeeting(thread);
        return info;
    }

    private async getFormThreadLinkDataKey(data: types.FormThreadLinkData) {
        const password = this.formThreadPasswordProvider();
        if (!data.anonEncryptedPrivKey || !data.anonPrivKeyPreliminaryEncryptionKey || !password) {
            return undefined;
        }
        const key = await this.decryptAnonPrivKey(
            data.anonEncryptedPrivKey,
            password,
            data.anonPrivKeyPreliminaryEncryptionKey
        );
        return key;
    }

    private async getInquiryPrivKey(inquiryId: PmxApi.api.inquiry.InquiryId) {
        if (this.cachedInquiryPrivKeys[inquiryId]) {
            return this.cachedInquiryPrivKeys[inquiryId];
        }
        const inquiry = await this.inquiryService?.getInquiry(inquiryId);
        const inquiryPrivKey = inquiry?.data.priv
            ? privmx.crypto.ecc.PrivateKey.fromWIF(inquiry.data.priv)
            : undefined;
        if (inquiryPrivKey) {
            this.cachedInquiryPrivKeys[inquiryId] = inquiryPrivKey;
        }
        return inquiryPrivKey;
    }

    private refreshAnonymousUsersFromThreadMeeting(thread: PmxApi.api.thread.Thread) {
        for (const pub in thread.meetingState.anonymousUsers) {
            const nickname = thread.meetingState.anonymousUsers[pub].nickname;
            this.stateUpdater.dispatch(
                this.stateUpdater.upsertAnonymousUser({
                    pub: pub as PmxApi.api.core.EccPubKey,
                    nickname: nickname
                } as any)
            );
        }
        for (const pub in thread.meetingState.removedAnonymousUsers) {
            const nickname = thread.meetingState.removedAnonymousUsers[pub].nickname;
            this.stateUpdater.dispatch(
                this.stateUpdater.upsertAnonymousUser({
                    pub: pub as PmxApi.api.core.EccPubKey,
                    nickname: nickname
                })
            );
        }
        for (const lobbyUser of thread.meetingState.lobbyUsers) {
            if (lobbyUser.type === 'regular') {
                continue;
            }
            this.stateUpdater.dispatch(
                this.stateUpdater.upsertAnonymousUser({
                    pub: lobbyUser.pub,
                    nickname: lobbyUser.nickname
                })
            );
        }
    }

    async toggleThreadTag(threadId: PmxApi.api.thread.ThreadId, tag: string, enabled: boolean) {
        const threadRaw = await this.getThread(threadId);
        const thread = await this.decryptThread(threadRaw);
        const newTags = await this.tagService.toggleTag(
            'thread',
            threadId,
            tag as types.Tag,
            thread,
            enabled
        );
        if (!this.tagService.isPrivateScope()) {
            const newThread = await this.updateThread(
                thread,
                thread.data.title,
                thread.data.props,
                newTags,
                thread.thread.users,
                thread.thread.managers,
                true,
                thread.thread.isMeetingCancelled
            );
            return newThread;
        }
        return threadRaw;
    }

    async setSticker(msgId: PmxApi.api.thread.MessageId, emojiIconName: string | null) {
        const sticker =
            emojiIconName === null ? null : await this.stickerService.encryptSticker(emojiIconName);
        await this.threadApi.setMessageSticker({
            messageId: msgId,
            sticker: sticker
        });
    }

    async updateThread(
        threadInfo: ThreadInfo,
        title: string,
        props: ThreadProps,
        tags: string[],
        users: PmxApi.api.core.Username[],
        managers: PmxApi.api.core.Username[],
        updateKeysWhenAddingUsers: boolean,
        isMeetingCancelled: boolean
    ) {
        const newThreadData: ThreadData = {
            v: 1,
            threadKey: Base64.from(threadInfo.threadKey),
            title: title,
            props: props,
            threadIdSeed: threadInfo.data.threadIdSeed,
            signature: threadInfo.data.signature,
            signatureData: threadInfo.data.signatureData
        };
        const anonymousUsers = this.getMeetingAnonymousUsers(threadInfo);
        const { keys, currentThreadKey, addedAnyUsers, removedAnyUsers, usersDiff, newUsers } =
            await this.computeThreadKeysForUpdateWithUsersDiff(
                threadInfo,
                users,
                managers,
                threadInfo.thread.meetingState.regularUsers,
                anonymousUsers,
                updateKeysWhenAddingUsers
            );
        if (removedAnyUsers) {
            await this.sendUsersRemovedMessage(
                threadInfo,
                this.getRegularUsersFromClients(usersDiff.removed, anonymousUsers),
                this.getRegularUsersFromClients(newUsers, anonymousUsers)
            );
        }
        const updateResult = await this.threadApi.updateThread({
            id: threadInfo.thread.id,
            keyId: currentThreadKey.id,
            data: this.threadDataTheSame(threadInfo, currentThreadKey, newThreadData)
                ? threadInfo.dataEntry.data
                : await this.threadDataEncryptor.encrypt(newThreadData, currentThreadKey),
            keys: keys,
            users: users,
            managers: managers,
            tags: await this.tagService.setTagsOrGetEncrypted(
                'thread',
                threadInfo.thread.id,
                tags as types.Tag[]
            ),
            isMeetingCancelled: isMeetingCancelled,
            version: threadInfo.thread.version,
            force: true
        });
        if (addedAnyUsers) {
            const newThreadInfo = await this.decryptThread(updateResult.thread);
            await this.sendUsersAddedMessage(
                newThreadInfo,
                this.getRegularUsersFromClients(usersDiff.newOnes, anonymousUsers),
                this.getRegularUsersFromClients(newUsers, anonymousUsers)
            );
        }
        return updateResult.thread;
    }

    async setThreadTags(threadId: types.ThreadId, tags: types.Tag[]) {
        const threadRaw = await this.getThread(threadId);
        const newTags = await this.tagService.setTagsOrGetEncrypted('thread', threadId, tags);
        if (!this.tagService.isPrivateScope()) {
            const thread = await this.decryptThread(threadRaw);
            const newThread = await this.updateThread(
                thread,
                thread.data.title,
                thread.data.props,
                newTags,
                thread.thread.users,
                thread.thread.managers,
                true,
                thread.thread.isMeetingCancelled
            );
            return newThread;
        }
        return threadRaw;
    }

    async getAllThreadsTags() {
        return this.threadApi.getThreadsTags();
    }

    private async computeThreadKeysForUpdateWithUsersDiff(
        threadInfo: ThreadInfo,
        users: PmxApi.api.core.Username[],
        managers: PmxApi.api.core.Username[],
        meetingRegularUsers: PmxApi.api.core.Username[],
        meetingAnonymousUsers: PmxApi.api.core.EccPubKey[],
        updateKeysWhenAddingUsers: boolean
    ) {
        const oldUsers = Utils.unique([
            ...threadInfo.thread.users,
            ...threadInfo.thread.managers,
            ...threadInfo.thread.meetingState.regularUsers,
            ...this.getMeetingAnonymousUsers(threadInfo)
        ]) as PmxApi.api.core.Client[];
        const newRegularUsers = Utils.unique([...users, ...managers, ...meetingRegularUsers]);
        const newAnonymousUsers = Utils.unique([...meetingAnonymousUsers]);
        const newUsers = Utils.unique([
            ...newRegularUsers,
            ...newAnonymousUsers
        ]) as PmxApi.api.core.Client[];
        const usersDiff = Utils.calcDiff(oldUsers, newUsers);
        const keys = updateKeysWhenAddingUsers
            ? []
            : await this.prepareKeysForNewUsers(threadInfo, usersDiff.newOnes, newAnonymousUsers);
        const removedAnyUsers = usersDiff.removed.length > 0;
        const addedAnyUsers = usersDiff.newOnes.length > 0;
        const currentThreadKey = await (async () => {
            const needsNewKey = removedAnyUsers || (addedAnyUsers && updateKeysWhenAddingUsers);
            if (!needsNewKey) {
                return threadInfo.currentKey;
            }
            const newThreadKey = this.keyProvider.generateKey();
            Utils.addMany(
                keys,
                await this.keyProvider.prepareKeysList(
                    newRegularUsers,
                    newAnonymousUsers,
                    newThreadKey
                )
            );
            return newThreadKey;
        })();
        return {
            keys,
            currentThreadKey,
            oldUsers,
            newUsers,
            usersDiff,
            removedAnyUsers,
            addedAnyUsers
        };
    }

    private async prepareKeysForNewUsers(
        threadInfo: ThreadInfo,
        newUsers: PmxApi.api.core.Client[],
        knownAnonymousUsers: PmxApi.api.core.EccPubKey[]
    ) {
        const res: PmxApi.api.core.KeyEntrySet[] = [];
        for (const keyEntry of threadInfo.thread.keys) {
            const key = await this.getKeyForThread(threadInfo, keyEntry.keyId);
            const regularNewUsers = newUsers.filter(
                (x) => !knownAnonymousUsers.includes(x as string as PmxApi.api.core.EccPubKey)
            ) as PmxApi.api.core.Username[];
            const anonymousNewUsers = newUsers.filter((x) =>
                knownAnonymousUsers.includes(x as string as PmxApi.api.core.EccPubKey)
            ) as PmxApi.api.core.EccPubKey[];
            Utils.addMany(
                res,
                await this.keyProvider.prepareKeysList(regularNewUsers, anonymousNewUsers, key)
            );
        }
        return res;
    }

    private threadDataTheSame(threadInfo: ThreadInfo, currentKey: EncKey, currentData: ThreadData) {
        return (
            threadInfo.currentKey.id === currentKey.id &&
            JSON.stringify(threadInfo.data) === JSON.stringify(currentData)
        );
    }

    async sendUsersAddedMessage(
        threadInfo: ThreadInfo,
        addedUsernames: PmxApi.api.core.Username[],
        allUsernamesWithAccess: PmxApi.api.core.Username[]
    ) {
        const msgObj: UsersAddedMessage = {
            type: 'usersAdded',
            addedUsernames: addedUsernames,
            allUsernamesWithAccess: allUsernamesWithAccess
        };
        const msgText = JSON.stringify(msgObj);
        await this.sendMessage(
            threadInfo,
            generateMessageId(),
            'application/json' as Mimetype,
            msgText,
            []
        );
    }

    async sendUsersRemovedMessage(
        threadInfo: ThreadInfo,
        removedUsernames: PmxApi.api.core.Username[],
        allUsernamesWithAccess: PmxApi.api.core.Username[]
    ) {
        const msgObj: UsersRemovedMessage = {
            type: 'usersRemoved',
            removedUsernames: removedUsernames,
            allUsernamesWithAccess: allUsernamesWithAccess
        };
        const msgText = JSON.stringify(msgObj);
        await this.sendMessage(
            threadInfo,
            generateMessageId(),
            'application/json' as Mimetype,
            msgText,
            []
        );
    }

    async sendMeetingScheduledMessage(threadInfo: ThreadInfo, meetingId: MeetingId) {
        const msgObj: MeetingScheduledMessage = {
            type: 'meetingScheduled',
            meetingId: meetingId
        };
        const msgText = JSON.stringify(msgObj);
        await this.sendMessage(
            threadInfo,
            generateMessageId(),
            'application/json' as Mimetype,
            msgText,
            []
        );
    }

    async sendMeetingStartedMessage(threadInfo: ThreadInfo) {
        const msgObj: MeetingStartedMessage = {
            type: 'meetingStarted',
            meetingId: threadInfo.thread.id as MeetingId
        };
        const msgText = JSON.stringify(msgObj);
        await this.sendMessage(
            threadInfo,
            generateMessageId(),
            'application/json' as Mimetype,
            msgText,
            []
        );
    }

    async sendMessage(
        threadInfo: ThreadInfo,
        msgId: MessageClientId,
        mimetype: Mimetype,
        text: string,
        files: File[],
        attachmentsToCopy: AttachmentsToCopy = []
    ) {
        const msgKey = threadInfo.currentKey;
        const attInfo = await this.attachmentUtils.prepareAttachments(
            msgKey,
            threadInfo.threadKey,
            threadInfo.thread.id as types.ThreadId,
            files,
            attachmentsToCopy
        );
        const author = this.getMe();
        const messageData: MessageData = {
            v: 2,
            msgId: msgId,
            author: {
                username: author.username,
                pubKey: EccUtils.getPublicKey(author.priv),
                server: author.serverId
            },
            destination: {
                threadId: threadInfo.thread.id,
                server: threadInfo.server
            },
            type: mimetype,
            text: text,
            date: Date.now(),
            attachments: attInfo.attachments
        };
        await this.threadApi.sendMessage({
            threadId: threadInfo.thread.id,
            data: await this.messageDataEncryptor.signAndEcrypt(messageData, author.priv, msgKey),
            keyId: msgKey.id,
            attachments: attInfo.request,
            attachmentsToCopy: attInfo.attachmentsToCopy,
            mentions: this.getMentions(messageData)
        });
    }

    private getMentions(messageData: MessageData) {
        if (messageData.deleted) {
            return [];
        }
        const currentUsername = this.identity.user as PmxApi.api.core.Username;
        const availableUsernames = this.userService
            .getUsers()
            .map((x) => x.raw.username)
            .filter((x) => x !== currentUsername);
        const maybeMentionedUsernames = Utils.unique(
            messageData.text.match(/\B@([a-z0-9]+([._-]?[a-z0-9]+)*)/gi) ?? []
        ).map((x) => x.substring(1).toLowerCase());
        const mentions = maybeMentionedUsernames.filter((x) =>
            availableUsernames.includes(x as PmxApi.api.core.Username)
        ) as PmxApi.api.core.Username[];
        return mentions;
    }

    async updateMessage(
        messageId: PmxApi.api.thread.MessageId,
        mimetype: Mimetype,
        text: string,
        files: (File | PmxApi.api.attachment.AttachmentId)[]
    ) {
        return this.updateMessageCore(messageId, mimetype, text, false, files);
    }

    async deleteMessage(messageId: PmxApi.api.thread.MessageId) {
        return this.updateMessageCore(messageId, 'text/plain', '', true, []);
    }

    private async updateMessageCore(
        messageId: PmxApi.api.thread.MessageId,
        mimetype: Mimetype,
        text: string,
        deleted: boolean,
        files: (File | PmxApi.api.attachment.AttachmentId)[]
    ) {
        const { message: rawMessage } = await this.threadApi.getMessage({
            messageId: messageId
        });
        const thread = await this.getThread(rawMessage.threadId);
        const threadInfo = await this.decryptThread(thread);
        const messageRes = await Utils.tryPromise(() =>
            this.decryptMessage(threadInfo, rawMessage)
        );
        const theFiles = files.map((x) => {
            if (x instanceof File) {
                return x;
            }
            const attIndex = rawMessage.attachments.findIndex((y) => y.id === x);
            if (attIndex === -1 || messageRes.success === false) {
                throw new Error('Invalid attachment id');
            }
            return { id: x, attachment: messageRes.result.data.attachments[attIndex] };
        });
        const msgKey = await this.getKeyForThread(threadInfo, rawMessage.keyId);
        if (!msgKey) {
            throw new Error('Cannot find corresponding key for given message');
        }
        const attInfo = await this.prepareAttachmentsForUpdate(
            msgKey,
            threadInfo.threadKey,
            theFiles,
            threadInfo.thread.id as types.ThreadId
        );
        const author = this.getMe();
        const messageData: MessageData = {
            v: 2,
            msgId:
                (messageRes.success === true && messageRes.result.data.msgId) ||
                generateMessageId(),
            author: {
                username: author.username,
                pubKey: EccUtils.getPublicKey(author.priv),
                server: author.serverId
            },
            destination: {
                threadId: threadInfo.thread.id,
                server: threadInfo.server
            },
            type: mimetype,
            text: text,
            date: Date.now(),
            attachments: attInfo.attachments
        };
        if (deleted) {
            messageData.deleted = deleted;
        }
        await this.threadApi.updateMessage({
            messageId: rawMessage.id,
            data: await this.messageDataEncryptor.signAndEcrypt(messageData, author.priv, msgKey),
            keyId: msgKey.id,
            attachments: attInfo.request,
            mentions: this.getMentions(messageData)
        });
    }

    private async prepareAttachmentsForUpdate(
        key: EncKey,
        threadKey: Buffer,
        files: (File | MessageAttachmentWithId)[],
        threadId: types.ThreadId
    ) {
        if (files.length === 0) {
            return { attachments: [], request: null };
        }
        const attsRes = await this.attachmentUtils.prepareAttachments(
            key,
            threadKey,
            threadId,
            files.filter((x) => x instanceof File) as File[]
        );
        const reqList = attsRes.request ? attsRes.request.list : [];
        const attachments: MessageAttachment[] = [];
        const request: PmxApi.api.attachment.UpdatedAttachments = {
            requestId: attsRes.request ? attsRes.request.requestId : null,
            list: []
        };
        let attIndex = 0;
        for (const file of files) {
            if (file instanceof File) {
                attachments.push(attsRes.attachments[attIndex]);
                request.list.push(reqList[attIndex]);
                attIndex++;
            } else {
                attachments.push(file.attachment);
                request.list.push(file.id);
            }
        }
        return { attachments, request };
    }

    async getMessage(threadInfo: ThreadInfo, messageId: PmxApi.api.thread.MessageId) {
        const { message } = await this.threadApi.getMessage({
            messageId: messageId
        });
        const res: MessageFull = {
            msg: message,
            sig: await Utils.tryPromise(() => this.decryptMessage(threadInfo, message))
        };
        return res;
    }

    async getMessage2(messageId: PmxApi.api.thread.MessageId) {
        const { message } = await this.threadApi.getMessage({
            messageId: messageId
        });
        const thread = await this.getThread(message.threadId);
        const threadInfo = await this.decryptThread(thread);
        return {
            msg: message,
            sig: await Utils.tryPromise(() => this.decryptMessage(threadInfo, message)),
            threadInfo: threadInfo
        };
    }

    async getMessages(threadInfo: ThreadInfo) {
        const { messages } = await this.threadApi.getMessages({ threadId: threadInfo.thread.id });
        return Promise.all(
            messages.map(async (x) => {
                const res: MessageFull = {
                    msg: x,
                    sig: await Utils.tryPromise(() => this.decryptMessage(threadInfo, x))
                };
                return res;
            })
        );
    }

    async getMessagesWithThreads(messageIds: PmxApi.api.thread.MessageId[]) {
        const { messages: messagesRaw, threads: threadsRaw } =
            await this.threadApi.getMessagesWithThreads({ messageIds: messageIds });
        const threads = await Promise.all(threadsRaw.map((x) => this.decryptThread(x)));
        const messages = await Promise.all(
            messagesRaw.map(async (x) => {
                const res: MessageFull = {
                    msg: x,
                    sig: await Utils.tryPromise(() =>
                        this.decryptMessage(threads.find((t) => t.thread.id === x.threadId)!, x)
                    )
                };
                return res;
            })
        );
        return { messages, threads };
    }

    async decryptMessage(threadInfo: ThreadInfo, msg: PmxApi.api.thread.Message) {
        const key = await this.getKeyForThread(threadInfo, msg.keyId);
        return this.messageDataEncryptor.decrypt(msg.data, key, {
            threadId: threadInfo.thread.id,
            server: threadInfo.server
        });
    }

    async getItemCountsGrouppedByUsers() {
        const { itemCountsGrouppedByUser } = await this.threadApi.getItemCountsGrouppedByUsers();
        return itemCountsGrouppedByUser;
    }

    async getThreadUsersWithAccessibleTimeRanges(threadId: types.ThreadId) {
        const { users } = await this.threadApi.getThreadUsersWithAccessibleTimeRanges({
            threadId: threadId
        });
        return users;
    }

    async getThreadAttachments(threadInfo: ThreadInfo) {
        const { attachments } = await this.threadApi.getThreadAttachments({
            threadId: threadInfo.thread.id
        });
        const groupMap = new Map<
            PmxApi.api.attachment.AttachmentGroup,
            PmxApi.api.thread.ThreadAttachment[]
        >();
        for (const attachment of attachments) {
            const list = groupMap.get(attachment.group);
            if (list) {
                list.push(attachment);
            } else {
                groupMap.set(attachment.group, [attachment]);
            }
        }
        return Promise.all(
            [...groupMap.values()].map((x) => this.decryptAttachmentGroup(threadInfo, x))
        );
    }

    async getAttachmentCore(attachmentId: PmxApi.api.attachment.AttachmentId) {
        const { attachment, thread } = await this.threadApi.getThreadAttachment({ attachmentId });
        const threadInfo = await this.decryptThread(thread);
        const key = await this.getKeyForThread(threadInfo, attachment.keyId);
        const meta = await this.attachmentMetaEncryptor.decrypt(attachment.meta, key);
        return {
            attachment: attachment,
            meta: meta
        };
    }

    async getAttachment(id: PmxApi.api.attachment.AttachmentId) {
        const { attachment, meta } = await this.getAttachmentCore(id);
        return this.convertAttachment(attachment, meta);
    }

    async convertAttachment(attachment: PmxApi.api.thread.ThreadAttachment, meta: AttachmentMeta) {
        const tags = await this.tagService.getTags(
            'threadAttachment',
            attachment.group,
            'all',
            attachment
        );
        const res: PmxAttachment = {
            threadId: attachment.threadId,
            group: attachment.group,
            tags: tags,
            attachmentId: attachment.id,
            messageId: attachment.messageId,
            author: attachment.author,
            date: attachment.created,
            meta: meta,
            hasThumb: !!attachment.thumb
        };
        return res;
    }

    async getAttachmentGroup(attachmentId: PmxApi.api.attachment.AttachmentId) {
        const { attachments, thread } = await this.threadApi.getThreadAttachmentGroup({
            attachmentId
        });
        const threadInfo = await this.decryptThread(thread);
        return this.decryptAttachmentGroup(threadInfo, attachments);
    }

    async getAttachmentGroupList(attachmentId: PmxApi.api.attachment.AttachmentId) {
        const { attachments, thread } = await this.threadApi.getThreadAttachmentGroup({
            attachmentId
        });
        const threadInfo = await this.decryptThread(thread);
        return Promise.all(
            attachments.map(async (attachment) => {
                const key = await this.getKeyForThread(threadInfo, attachment.keyId);
                return this.convertAttachment(
                    attachment,
                    await this.attachmentMetaEncryptor.decrypt(attachment.meta, key)
                );
            })
        );
    }

    async getAttachments() {
        const { attachments, threads } = await this.threadApi.getAttachments({});
        const threadMap = new Map<
            PmxApi.api.thread.ThreadId,
            {
                thread: PmxApi.api.thread.Thread;
                groupMap: Map<
                    PmxApi.api.attachment.AttachmentGroup,
                    PmxApi.api.thread.ThreadAttachment[]
                >;
            }
        >();
        for (const att of attachments) {
            const entry = threadMap.get(att.threadId);
            if (entry) {
                const list = entry.groupMap.get(att.group);
                if (list) {
                    list.push(att);
                } else {
                    entry.groupMap.set(att.group, [att]);
                }
            } else {
                const thread = threads.find((x) => x.id === att.threadId);
                if (thread) {
                    threadMap.set(thread.id, { thread, groupMap: new Map([[att.group, [att]]]) });
                } else {
                    console.warn('Warning: There is attachment without thread ' + att.id);
                }
            }
        }
        return (
            await Promise.all(
                [...threadMap.values()].map(async (entry) => {
                    const threadInfo = await this.decryptThread(entry.thread);
                    return Promise.all(
                        [...entry.groupMap.values()].map((x) =>
                            this.decryptAttachmentGroup(threadInfo, x)
                        )
                    );
                })
            )
        ).flat();
    }

    async getAttachmentsOfUser(user: PmxApi.api.core.Username) {
        const { attachments, threads } = await this.threadApi.getAttachmentsOfUser({ user });
        const threadMap = new Map<
            PmxApi.api.thread.ThreadId,
            {
                thread: PmxApi.api.thread.Thread;
                groupMap: Map<
                    PmxApi.api.attachment.AttachmentGroup,
                    PmxApi.api.thread.ThreadAttachment[]
                >;
            }
        >();
        for (const att of attachments) {
            const entry = threadMap.get(att.threadId);
            if (entry) {
                const list = entry.groupMap.get(att.group);
                if (list) {
                    list.push(att);
                } else {
                    entry.groupMap.set(att.group, [att]);
                }
            } else {
                const thread = threads.find((x) => x.id === att.threadId);
                if (thread) {
                    threadMap.set(thread.id, { thread, groupMap: new Map([[att.group, [att]]]) });
                } else {
                    console.warn('Warning: There is attachment without thread ' + att.id);
                }
            }
        }
        const result = (
            await Promise.all(
                [...threadMap.values()].map(async (entry) => {
                    const threadInfo = await this.decryptThread(entry.thread);
                    return Promise.all(
                        [...entry.groupMap.values()].map((x) =>
                            this.decryptAttachmentGroup(threadInfo, x)
                        )
                    );
                })
            )
        ).flat();

        return result;
    }

    async getAttachmentsOfCompany(companyId: PmxApi.api.company.CompanyId) {
        const { attachments, threads } = await this.threadApi.getAttachmentsOfCompany({
            companyId
        });
        const threadMap = new Map<
            PmxApi.api.thread.ThreadId,
            {
                thread: PmxApi.api.thread.Thread;
                groupMap: Map<
                    PmxApi.api.attachment.AttachmentGroup,
                    PmxApi.api.thread.ThreadAttachment[]
                >;
            }
        >();
        for (const att of attachments) {
            const entry = threadMap.get(att.threadId);
            if (entry) {
                const list = entry.groupMap.get(att.group);
                if (list) {
                    list.push(att);
                } else {
                    entry.groupMap.set(att.group, [att]);
                }
            } else {
                const thread = threads.find((x) => x.id === att.threadId);
                if (thread) {
                    threadMap.set(thread.id, { thread, groupMap: new Map([[att.group, [att]]]) });
                } else {
                    console.warn('Warning: There is attachment without thread ' + att.id);
                }
            }
        }
        const result = (
            await Promise.all(
                [...threadMap.values()].map(async (entry) => {
                    const threadInfo = await this.decryptThread(entry.thread);
                    return Promise.all(
                        [...entry.groupMap.values()].map((x) =>
                            this.decryptAttachmentGroup(threadInfo, x)
                        )
                    );
                })
            )
        ).flat();

        return result;
    }

    async decryptAttachmentGroup(
        threadInfo: ThreadInfo,
        attachments: PmxApi.api.thread.ThreadAttachment[]
    ) {
        return Utils.tryPromise(() => this.decryptAttachmentGroupCore(threadInfo, attachments));
    }

    async decryptAttachmentGroupCore(
        threadInfo: ThreadInfo,
        attachments: PmxApi.api.thread.ThreadAttachment[]
    ): Promise<PmxAttachmentEx> {
        const first = attachments.reduce((a, b) => (a.created < b.created ? a : b));
        const attachment = attachments.reduce((a, b) => (a.created > b.created ? a : b));
        const key = await this.getKeyForThread(threadInfo, attachment.keyId);
        const meta = await this.attachmentMetaEncryptor.decrypt(attachment.meta, key);
        const tags = await this.tagService.getTags(
            'threadAttachment',
            attachment.group,
            'all',
            attachment
        );
        return {
            threadId: attachment.threadId,
            group: attachment.group,
            tags: tags,
            versions: attachments.length,
            contributors: [...new Set(attachments.map((x) => x.author))],
            modificationDates: [...new Set(attachments.map((x) => x.created))],
            attachmentId: attachment.id,
            messageId: attachment.messageId,
            author: attachment.author,
            date: attachment.created,
            meta: meta,
            createdDate: first.created,
            creator: first.author,
            hasThumb: !!attachment.thumb
        };
    }

    private async getKeyForThread(threadInfo: ThreadInfo, keyId: PmxApi.api.core.KeyId) {
        const alreadyDecrypted = threadInfo.keys.find((x) => x.id === keyId);
        if (alreadyDecrypted) {
            return alreadyDecrypted;
        }
        const key = await this.keyProvider.getKey(threadInfo.thread.keys, keyId);
        threadInfo.keys.push(key);
        return key;
    }

    async getVideoEncryptionKey(thread: PmxApi.api.thread.Thread) {
        const threadInfo = await this.decryptThread(thread);
        const videoKey = await privmx.crypto.service.sha256(
            Buffer.concat([Buffer.from('videoKey:', 'utf8'), threadInfo.threadKey])
        );
        return videoKey;
    }

    async readAttachment(
        attachment: PmxApi.api.thread.ThreadAttachment,
        meta: AttachmentMeta,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const [currentMeta] = (() => {
            if (thumb) {
                if (!attachment.thumb || !meta.thumb) {
                    throw new Error('Attachment has no thumbnail');
                }
                return [meta.thumb, attachment.thumb.size];
            }
            return [meta, attachment.size];
        })();
        return AttachmentUtils.downloadAttachment(
            currentMeta,
            (range) =>
                this.threadApi.getAttachmentData({
                    attachmentId: attachment.id,
                    range: range,
                    thumb: thumb
                }),
            options
        );
    }

    async updateAttachmentGroupTags(
        threadId: PmxApi.api.thread.ThreadId,
        group: PmxApi.api.attachment.AttachmentGroup,
        tags: string[]
    ) {
        await this.threadApi.setAttachmentGroupTags({
            threadId: threadId,
            group: group,
            tags: await this.tagService.setTagsOrGetEncrypted(
                'threadAttachment',
                group,
                tags as types.Tag[]
            )
        });
    }

    private preparedFileToAttachmentConverter(
        preparedFile: PreparedFile,
        hasThumb: boolean
    ): MessageAttachment {
        const res: MessageAttachment = {
            hmac: preparedFile.hmac.toString('base64'),
            name: preparedFile.file.name as FileName,
            size: preparedFile.file.size as FileSize,
            mimetype: preparedFile.file.type as Mimetype,
            hasThumb: hasThumb
        };
        return res;
    }

    async addUserToThreadMeeting(
        threadId: PmxApi.api.thread.ThreadId,
        username: PmxApi.api.core.Username,
        updateKeysWhenAddingUsers: boolean
    ) {
        const threadInfo = await this.getThreadInfoForMeetingUserListChange(threadId);
        const keyUpdateProps = await this.getThreadMeetingKeyUpdateProps(
            threadInfo,
            Utils.unique([...threadInfo.thread.meetingState.regularUsers, username]),
            this.getMeetingAnonymousUsers(threadInfo),
            updateKeysWhenAddingUsers
        );
        await this.threadApi.addUserToThreadMeeting({
            threadId: threadId,
            username: username,
            keyUpdateProps: keyUpdateProps
        });
    }

    async removeUserFromThreadMeeting(
        threadId: PmxApi.api.thread.ThreadId,
        username: PmxApi.api.core.Username
    ) {
        const threadInfo = await this.getThreadInfoForMeetingUserListChange(threadId);
        const keyUpdateProps = await this.getThreadMeetingKeyUpdateProps(
            threadInfo,
            threadInfo.thread.meetingState.regularUsers.filter((x) => x !== username),
            this.getMeetingAnonymousUsers(threadInfo),
            true
        );
        await this.threadApi.removeUserFromThreadMeeting({
            threadId: threadId,
            username: username,
            keyUpdateProps: keyUpdateProps
        });
    }

    async addAnonymousUserToThreadMeeting(
        threadId: PmxApi.api.thread.ThreadId,
        pub: PmxApi.api.core.EccPubKey,
        updateKeysWhenAddingUsers: boolean
    ) {
        const threadInfo = await this.getThreadInfoForMeetingUserListChange(threadId);
        const keyUpdateProps = await this.getThreadMeetingKeyUpdateProps(
            threadInfo,
            threadInfo.thread.meetingState.regularUsers,
            Utils.unique([...this.getMeetingAnonymousUsers(threadInfo), pub]),
            updateKeysWhenAddingUsers
        );
        await this.threadApi.addAnonymousUserToThreadMeeting({
            threadId: threadId,
            pub: pub,
            keyUpdateProps: keyUpdateProps
        });
    }

    async removeAnonymousUserFromThreadMeeting(
        threadId: PmxApi.api.thread.ThreadId,
        pub: PmxApi.api.core.EccPubKey
    ) {
        const threadInfo = await this.getThreadInfoForMeetingUserListChange(threadId);
        const keyUpdateProps = await this.getThreadMeetingKeyUpdateProps(
            threadInfo,
            threadInfo.thread.meetingState.regularUsers,
            this.getMeetingAnonymousUsers(threadInfo).filter((x) => x !== pub),
            true
        );
        await this.threadApi.removeAnonymousUserFromThreadMeeting({
            threadId: threadId,
            pub: pub,
            keyUpdateProps: keyUpdateProps
        });
    }

    async removeUserFromThreadLobby(
        threadId: PmxApi.api.thread.ThreadId,
        username: PmxApi.api.core.Username
    ) {
        await this.threadApi.removeUserFromThreadLobby({
            threadId: threadId,
            username: username
        });
    }

    async removeAnonymousFromThreadLobby(
        threadId: PmxApi.api.thread.ThreadId,
        pub: PmxApi.api.core.EccPubKey
    ) {
        await this.threadApi.removeAnonymousFromThreadLobby({
            threadId: threadId,
            pub: pub
        });
    }

    async joinToThreadMeetingLobby(
        threadId: PmxApi.api.thread.ThreadId,
        nickname: PmxApi.api.thread.MeetingNickname
    ) {
        const { result } = await this.threadApi.joinToThreadMeetingLobby({
            threadId: threadId,
            nickname: nickname
        });
        return result;
    }

    async leaveThreadMeetingLobby(threadId: PmxApi.api.thread.ThreadId) {
        await this.threadApi.leaveThreadMeetingLobby({
            threadId: threadId
        });
    }

    async getThreadMeetingLobby(threadId: PmxApi.api.thread.ThreadId) {
        const lobby = await this.threadApi.getThreadMeetingLobby({
            threadId: threadId
        });
        return lobby;
    }

    async setThreadMeetingNickname(
        threadId: PmxApi.api.thread.ThreadId,
        nickname: PmxApi.api.thread.MeetingNickname
    ) {
        await this.threadApi.setThreadMeetingNickname({
            threadId: threadId,
            nickname: nickname
        });
    }

    async commitPresenceInThreadLobby(threadId: PmxApi.api.thread.ThreadId) {
        await this.threadApi.commitPresenceInThreadLobby({
            threadId: threadId
        });
    }

    private async getThreadInfoForMeetingUserListChange(threadId: PmxApi.api.thread.ThreadId) {
        const thread = await this.getThread(threadId);
        if (thread.type !== 'meeting') {
            throw new Error(`Threads of type "${thread.type}" don't have meetings`);
        }
        const threadInfo = await this.decryptThread(thread);
        return threadInfo;
    }

    private async getThreadMeetingKeyUpdateProps(
        threadInfo: ThreadInfo,
        meetingRegularUsers: PmxApi.api.core.Username[],
        meetingAnonymousUsers: PmxApi.api.core.EccPubKey[],
        updateKeysWhenAddingUsers: boolean
    ): Promise<PmxApi.api.thread.ThreadMeetingKeyUpdateProps> {
        const { keys, currentThreadKey } = await this.computeThreadKeysForUpdateWithUsersDiff(
            threadInfo,
            threadInfo.thread.users,
            threadInfo.thread.managers,
            meetingRegularUsers,
            meetingAnonymousUsers,
            updateKeysWhenAddingUsers
        );
        const data = this.threadDataTheSame(threadInfo, currentThreadKey, threadInfo.data)
            ? threadInfo.dataEntry.data
            : await this.threadDataEncryptor.encrypt(threadInfo.data, currentThreadKey);
        return {
            keys: keys,
            keyId: currentThreadKey.id,
            data: data
        };
    }

    private getMeetingAnonymousUsers(threadInfo: ThreadInfo) {
        return Object.keys(
            threadInfo.thread.meetingState.anonymousUsers
        ) as PmxApi.api.core.EccPubKey[];
    }

    private getRegularUsersFromClients(
        clients: PmxApi.api.core.Client[],
        allAnonymousUsers: PmxApi.api.core.EccPubKey[]
    ) {
        return clients.filter(
            (x) => !allAnonymousUsers.includes(x as PmxApi.api.core.EccPubKey)
        ) as PmxApi.api.core.Username[];
    }

    getThreadMeetingLobbyUserLifespanBeforeExpiration() {
        return 2 * 60 * 1000;
    }

    isThreadMeetingLobbyUserExpired(lobbyUser: PmxApi.api.thread.MeetingLobbyUser) {
        const now = Date.now();
        const expiresAt =
            lobbyUser.lastPresenceDate + this.getThreadMeetingLobbyUserLifespanBeforeExpiration();
        return now >= expiresAt;
    }

    getPresentThreadMeetingLobbyUsers(lobbyUsers: PmxApi.api.thread.MeetingLobbyUser[]) {
        return lobbyUsers.filter((x) => !this.isThreadMeetingLobbyUserExpired(x));
    }

    getPresentThreadMeetingLobbyUsersCount(lobbyUsers: PmxApi.api.thread.MeetingLobbyUser[]) {
        return this.getPresentThreadMeetingLobbyUsers(lobbyUsers).length;
    }

    getNextThreadMeetingLobbyUserExpirationTime(lobbyUsers: PmxApi.api.thread.MeetingLobbyUser[]) {
        let oldestPresentUserLastPresence = Number.MAX_SAFE_INTEGER;
        for (const lobbyUser of lobbyUsers) {
            if (!this.isThreadMeetingLobbyUserExpired(lobbyUser)) {
                oldestPresentUserLastPresence = Math.min(
                    oldestPresentUserLastPresence,
                    lobbyUser.lastPresenceDate
                );
            }
        }
        if (oldestPresentUserLastPresence < Number.MAX_SAFE_INTEGER) {
            return (
                oldestPresentUserLastPresence +
                this.getThreadMeetingLobbyUserLifespanBeforeExpiration()
            );
        }
        return null;
    }

    private async decryptAnonPrivKey(
        anonEncryptedPrivKeyHex: string,
        password: string,
        anonPrivKeyPreliminaryEncryptionKeyHex: string
    ) {
        const salt = privmx.Buffer.Buffer.from(password, 'utf-8');
        const anonPrivKeyPreliminaryEncryptionKey = privmx.Buffer.Buffer.from(
            anonPrivKeyPreliminaryEncryptionKeyHex,
            'hex'
        );
        const anonPrivKeyEncryptionKey = await privmx.crypto.service.pbkdf2(
            anonPrivKeyPreliminaryEncryptionKey,
            salt,
            100000,
            32,
            'sha256'
        );
        const anonEncryptedPrivKeyBuff = await privmx.crypto.service.aes256EcbDecrypt(
            privmx.Buffer.Buffer.from(anonEncryptedPrivKeyHex, 'hex'),
            anonPrivKeyEncryptionKey
        );
        const anonEncryptedPrivKey =
            privmx.crypto.ecc.PrivateKey.generateFromBuffer(anonEncryptedPrivKeyBuff);
        return anonEncryptedPrivKey;
    }
}
