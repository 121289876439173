import * as PmxApi from 'privmx-server-api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Center, Loader, Stack, TextInput, Title } from '@mantine/core';
import { api } from '../../api/Api';
import { EncKey } from '../../api/privmx/KeyProvider';
import PageDetailsHeader from '../../atoms/DetailPage/PageDetailsHeader';
import StateView from '../../atoms/State';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import * as types from '../../types/Types';
import AttachmentPreview from '../AttachmentPreview';
import { useTranslation } from 'react-i18next';

export function PublicSharedFilePage() {
    const [encKey, setEncKey] = useState<EncKey | null>(null);
    useEffect(() => {
        const key = new URLSearchParams(document.location.hash.replace('#', '?')).get('key');
        api.getPublicSharedFileEncKeyFromString(key ?? '').then((x) => setEncKey(x));
    }, []);
    const routeParams = useParams();
    const sharedFileId = routeParams.sharedFileId as types.SharedFileId;
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForEncryptedPublicSharedFile(sharedFileId), [sharedFileId])
    );
    return (
        <StateView state={state} retry={revalidate}>
            {(data) => {
                return (
                    <EncryptedPublicSharedFilePageView
                        encKey={encKey}
                        encryptedPublicSharedFile={data.encryptedPublicSharedFile}
                        onRefresh={revalidate}
                    />
                );
            }}
        </StateView>
    );
}

export interface EncryptedPublicSharedFilePageViewProps {
    encryptedPublicSharedFile: PmxApi.api.sharedFile.PublicSharedFile | null;
    encKey: EncKey | null;
    onRefresh: () => void;
}

export function EncryptedPublicSharedFilePageView(props: EncryptedPublicSharedFilePageViewProps) {
    const { t } = useTranslation();
    const [publicSharedFile, setPublicSharedFile] = useState<types.PublicSharedFile | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [hasPasswordError, setHasPasswordError] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const autoDecryptionStarted = useRef<boolean>(false);

    const decryptFile = useCallback(
        async (
            encryptedPublicSharedFile: PmxApi.api.sharedFile.PublicSharedFile,
            encKey: EncKey
        ) => {
            const pwd = passwordInputRef.current?.value ?? '';
            setIsProcessing(true);
            try {
                const decryptedPublicSharedFile = await api.decryptPublicSharedFile(
                    encryptedPublicSharedFile,
                    encKey,
                    pwd
                );
                setPassword(pwd);
                setPublicSharedFile(decryptedPublicSharedFile);
            } catch (err) {
                console.error(err);
                setHasPasswordError(true);
            } finally {
                setIsProcessing(false);
            }
        },
        []
    );

    useEffect(() => {
        if (autoDecryptionStarted.current) {
            return;
        }
        if (
            props.encryptedPublicSharedFile &&
            props.encKey &&
            props.encryptedPublicSharedFile.permission === 'no'
        ) {
            autoDecryptionStarted.current = true;
            decryptFile(props.encryptedPublicSharedFile, props.encKey);
        }
    }, [decryptFile, props.encryptedPublicSharedFile, props.encKey]);

    const handleShowFileSubmit = useCallback(async () => {
        if (props.encryptedPublicSharedFile && props.encKey) {
            decryptFile(props.encryptedPublicSharedFile, props.encKey);
        }
    }, [decryptFile, props.encryptedPublicSharedFile, props.encKey]);

    if (props.encryptedPublicSharedFile === null) {
        return (
            <Center p="xl" w="100%">
                {t('errorMessage.fileDoesNotExist')}
            </Center>
        );
    }

    if (publicSharedFile !== null) {
        return (
            <PublicSharedFilePageView
                publicSharedFile={publicSharedFile}
                onRefresh={props.onRefresh}
                encKey={props.encKey!}
                password={password ?? ''}
            />
        );
    }

    const isPasswordRequired = props.encryptedPublicSharedFile.permission === 'password';
    const showLoader = isProcessing || !props.encKey;
    const showPasswordPrompt = !showLoader && isPasswordRequired && password === null;

    return (
        <Center p="xl" w="100%">
            {showPasswordPrompt && (
                <Stack>
                    <form onSubmit={handleShowFileSubmit}>
                        <TextInput
                            label="Password"
                            ref={passwordInputRef}
                            error={hasPasswordError}
                        />
                        <Button type="submit">{t('action.showFile')}</Button>
                    </form>
                </Stack>
            )}
            {showLoader && <Loader />}
        </Center>
    );
}

export interface PublicSharedFilePageViewProps {
    publicSharedFile: types.PublicSharedFile;
    encKey: EncKey;
    password: string;
    onRefresh: () => void;
}

export function PublicSharedFilePageView(props: PublicSharedFilePageViewProps) {
    const att: types.SharedFileFileAsAttachment | null = {
        author: '' as types.Username,
        contentType: props.publicSharedFile.file.meta.mimetype,
        date: props.publicSharedFile.file.date,
        group: props.publicSharedFile.file.group,
        hasThumb: props.publicSharedFile.file.hasThumb,
        id: props.publicSharedFile.id as any,
        name: '' as types.FileName,
        sharedFileId: props.publicSharedFile.id,
        size: props.publicSharedFile.file.meta.size,
        sourceType: 'sharedFile',
        tags: []
    };

    return (
        <Center w="100%">
            <Stack w="100%" maw="1200px" p="xl" sx={(theme) => ({ borderRadius: theme.radius.md })}>
                <PageDetailsHeader
                    name={
                        <>
                            <Title fz={{ base: 'md', xl: 'lg' }}>File:{att.name}</Title>
                        </>
                    }></PageDetailsHeader>
                <Box mt="xl" mb="xl" mx="auto">
                    <AttachmentPreview
                        handleClose={() => {}}
                        attachmentPreview={{ attachment: att }}
                        fileEncKey={props.encKey}
                        filePassword={props.password}
                        hideTags
                        hideShareButton
                    />
                </Box>
            </Stack>
        </Center>
    );
}

export default PublicSharedFilePage;
