import {
    ActionIcon,
    Box,
    Button,
    Flex,
    Group,
    Input,
    Stack,
    Switch,
    Text,
    Title,
    useMantineColorScheme
} from '@mantine/core';
import { IconChevronRight, IconChevronDown, IconEye, IconX } from '@tabler/icons-react';
import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { PasswordChangeForm } from '../../../mantineAtoms/PasswordChangeInput';
import { TwofaSettings } from '../../../screens/SettingsScreen/TwofaSettings';
import { UserInfoConfig } from '../../../screens/SettingsScreen/UserInfoConfig';
import {
    isAutoMarkAsReadSettingEnabled,
    selectAutoMarkAsRead,
    setAutoMarkAsRead
} from '../../../store/UserPreferenceSlice';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDevMode } from '../../../hooks/useDevMode';
import useLoader from '../../../hooks/useLoader';
import { usePreventClose } from '../../../hooks/usePreventClose';
import useToast from '../../../hooks/useToast';
import { useAppDispatch, useAppSelector } from '../../../store';
import { toggleUserSettingsModal } from '../../../store/ModalsSlice';
import { PasswordChangePromptData } from '../../PasswordChangePrompt';
import { api } from '../../../api/Api';
import StateView from '../../../atoms/State';
import { UserSettingsLoader } from '../../../screens/SettingsScreen/SettingsLoading';
import { useSettingsStyle } from '../../../screens/SettingsScreen/SettingsScreen';

export default function UserSettingsPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { state, revalidate } = useLoader(useCallback((x) => x.loadSettings(), []));
    const toast = useToast();

    const autoMarkAsRead = useAppSelector(selectAutoMarkAsRead);
    const {
        formState: { isDirty }
    } = useForm<FormData>();
    usePreventClose(isDirty);

    const handleModalClose = () => {
        dispatch(toggleUserSettingsModal({ open: false }));
    };

    const handleAutoMarkAsReadChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const autoMarkAsRead = event.target.checked;
            dispatch(setAutoMarkAsRead(autoMarkAsRead));
        },
        [dispatch]
    );

    const [userCredentials, setUserCredentials] = useState<PasswordChangePromptData | null>(null);

    const handleChangePasswordClick = useCallback(async () => {
        if (userCredentials !== null) {
            setUserCredentials(null);
            return;
        }
        const { username, password } = await api.getCurrentUserCredentials();
        const options: PasswordChangePromptData = {
            login: username,
            currentPassword: password,
            isGeneratedPassword: false,
            saveCallback: async (newPassword, mnemonic) => {
                const isMnemonic = mnemonic !== null;
                await api.changePassword(isMnemonic ? mnemonic : password, newPassword, isMnemonic);
            }
        };
        setUserCredentials(options);
    }, [userCredentials]);

    const [isMnemonicOpen, setIsMnemonicOpen] = useState(false);
    const toggleIsMnemonicOpen = useCallback(() => {
        setIsMnemonicOpen((x) => !x);
    }, []);
    const [mnemonic, setMnemonic] = useState<string | null>(null);
    const [isLoadingMnemonic, setIsLoadingMnemonic] = useState<boolean>(false);
    const mnemonicPasswordRef = useRef<HTMLInputElement>(null);
    const handleShowMnemonic = useCallback(async () => {
        if (!mnemonicPasswordRef.current) {
            return;
        }
        try {
            setIsLoadingMnemonic(true);
            const mnemonic = await api.getMnemonic(mnemonicPasswordRef.current.value);
            setMnemonic(mnemonic);
        } catch (err) {
            toast(t('errorMessage.couldNotGetMnemonic'), 'error');
            console.error(err);
        } finally {
            setIsLoadingMnemonic(false);
        }
    }, [t, toast]);

    const { colorScheme, toggleColorScheme } = useMantineColorScheme();

    const [devMode] = useDevMode();

    const { classes, cx } = useSettingsStyle();

    return (
        <StateView state={state} retry={revalidate} loading={UserSettingsLoader}>
            {(loaderData) => (
                <Stack p="md" mt="md" mx="auto" spacing={0} w="50%" h="100%" miw={900}>
                    <Group mb="sm">
                        <Title order={3} sx={{ textWeight: 'normal' }}>
                            <Group>{t('screen.settings.title')}</Group>
                        </Title>
                        <ActionIcon onClick={handleModalClose} ml="auto">
                            <IconX />
                        </ActionIcon>
                    </Group>
                    <UserInfoConfig />

                    <Flex mb="xs" className={cx(classes.element, classes.withHover)}>
                        <Text weight={300}>{t('screen.settings.darkMode')}</Text>
                        <Switch
                            size="sm"
                            ml="auto"
                            checked={colorScheme === 'dark'}
                            onChange={(e) => toggleColorScheme(e.target.checked ? 'dark' : 'light')}
                        />
                    </Flex>
                    {isAutoMarkAsReadSettingEnabled && (
                        <Flex mb="xs" className={classes.element}>
                            <Text weight={300}>{t('screen.settings.autoMarkAsRead')}</Text>
                            <Switch
                                ml="auto"
                                checked={autoMarkAsRead}
                                onChange={handleAutoMarkAsReadChange}
                                name="autoMarkAsRead"></Switch>
                        </Flex>
                    )}
                    <TwofaSettings
                        mb="xs"
                        className={classes.withHover}
                        twofaResult={loaderData.twofaResult!}
                    />
                    <Box
                        className={cx(classes.element, classes.withHover)}
                        onClick={handleChangePasswordClick}>
                        <Flex align="center">
                            <Text weight={300} size="md">
                                {t('screen.settings.changePassword')}
                            </Text>
                            <ActionIcon ml="auto">
                                {userCredentials === null ? (
                                    <IconChevronRight />
                                ) : (
                                    <IconChevronDown />
                                )}
                            </ActionIcon>
                        </Flex>
                        {userCredentials !== null && (
                            <Box onClick={(e: React.MouseEvent) => e.stopPropagation()}>
                                <PasswordChangeForm
                                    passwordChangePrompt={userCredentials}
                                    handleClose={() => setUserCredentials(null)}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box
                        className={cx(classes.element, classes.withHover)}
                        onClick={toggleIsMnemonicOpen}>
                        <Flex align="center">
                            <Text weight={300} size="md">
                                {t('screen.settings.mnemonic.title')}
                            </Text>
                            <ActionIcon ml="auto">
                                {isMnemonicOpen ? <IconChevronDown /> : <IconChevronRight />}
                            </ActionIcon>
                        </Flex>
                        {isMnemonicOpen && (
                            <Box onClick={(e: React.MouseEvent) => e.stopPropagation()}>
                                <Text size="sm">{t('screen.settings.mnemonic.info')}</Text>
                                {!mnemonic && (
                                    <Group mt="sm" mb="lg">
                                        <Input
                                            type="password"
                                            placeholder={t(
                                                'screen.settings.mnemonic.currentPassword'
                                            )}
                                            ref={mnemonicPasswordRef}
                                        />
                                        <Button
                                            leftIcon={<IconEye />}
                                            onClick={handleShowMnemonic}
                                            variant="light"
                                            loading={isLoadingMnemonic}>
                                            {t('screen.settings.mnemonic.showMnemonic')}
                                        </Button>
                                    </Group>
                                )}
                                {mnemonic && (
                                    <Box mt="sm" mb="lg">
                                        <Text size="lg">
                                            {t('screen.settings.mnemonic.yourMnemonic')}
                                        </Text>
                                        <code>{mnemonic ?? ''}</code>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                    {devMode && (
                        <Button component={Link} to="welcome/theme">
                            Open onboarding
                        </Button>
                    )}
                    {devMode && (
                        <Button component={Link} to="activateFirstAccount/923ur98rc2d08r09283rdc">
                            Open activate first account
                        </Button>
                    )}
                </Stack>
            )}
        </StateView>
    );
}
