import { ScrollArea, Paper, Group, ActionIcon, Text, Tooltip } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { UserAvatarX } from '../../../atoms/UserAvatar';
import { ContactDisplayedName } from '../../../atoms/UserDisplayedName';
import { Contact } from '../../../types/Types';

export function CompanyUsersList({
    field,
    children,
    handleRemoveUser
}: {
    handleRemoveUser: (id: Contact) => void;
    children: React.ReactNode;
    field: { value: Contact[] | null };
}) {
    if (!field.value) {
        return (
            <Text align="center" color="dimmed">
                Loading company users
            </Text>
        );
    }

    return (
        <ScrollArea sx={{ flexGrow: 1 }}>
            {field.value.map((x) => (
                <Paper p="sm" key={x.id}>
                    <Group>
                        <UserAvatarX
                            showTooltip={false}
                            user={
                                x.username
                                    ? {
                                          type: 'user',
                                          username: x.username
                                      }
                                    : {
                                          type: 'contact',
                                          contactId: x.id
                                      }
                            }
                        />
                        <ContactDisplayedName contact={x} size={'sm'} withEmail />
                        <Tooltip label="Remove user from the company">
                            <ActionIcon ml="auto" onClick={() => handleRemoveUser(x)}>
                                <IconX />
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                </Paper>
            ))}
            {children}
        </ScrollArea>
    );
}
