import { Box, Image, LoadingOverlay, createStyles } from '@mantine/core';
import * as types from '../../../types/Types';
import { useCallback, useLayoutEffect, useState } from 'react';
import { api } from '../../../api/Api';
import Icon, { mimetypeToIcon } from '../../Icon';
import { setObjectUrl } from '../../../utils/ReactUtils';
import { Limits } from '../../../api/Limits';

export const imageFullSizeWidth = '65vw';
export const imageFullSizeHeight = '80svh';
export const imageThumbSize = 300;

const useFileImageStyles = (imageWidth: number | string, imageHeight: number | string) =>
    createStyles((theme) => {
        return {
            root: {
                width: imageWidth,
                height: imageHeight,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            img: {
                borderRadius: theme.radius.md
            },
            iconContainer: {
                width: imageWidth,
                height: imageHeight,
                background: theme.fn.rgba(theme.colors.gray[7], 0.82),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: theme.radius.md
            },
            icon: {
                fontSize: 100,
                color: theme.white
            }
        };
    });

export interface FileImageProps {
    file: types.AttachmentEx | types.InquiryAttachmentEx;
    fullSize?: boolean;
}

export function FileImage(props: FileImageProps) {
    const imageWidth = props.fullSize ? imageFullSizeWidth : imageThumbSize;
    const imageHeight = props.fullSize ? imageFullSizeHeight : imageThumbSize;
    const { classes } = useFileImageStyles(imageWidth, imageHeight)();
    const icon = mimetypeToIcon(props.file.contentType);
    const showThumb = !props.fullSize;
    // Show preview: 1) ThumbMode - only if has thumb. 2) FullMode - only if file is smaller than Limits.MAX_PREVIEWABLE_FILE_SIZE_B.
    const showPreview = showThumb
        ? props.file.hasThumb
        : props.file.size < Limits.MAX_PREVIEWABLE_FILE_SIZE_B;

    const [thumb, setThumb] = useState<string | null>(null);
    const [isContainFit, setIsContainFit] = useState<boolean>(false);

    useLayoutEffect(() => {
        setObjectUrl(setThumb, null);
        setIsContainFit(false);
        if (props.file.id === null) {
            return;
        }
        if (!props.file.contentType.startsWith('image/')) {
            return;
        }
        let cancelled = false;
        if (showPreview) {
            api.getAttachmentFromSource(props.file.id, props.file.sourceType, showThumb).then(
                (attachmentData) => {
                    if (cancelled) {
                        return;
                    }
                    setObjectUrl(setThumb, {
                        content: attachmentData.content,
                        mimetype: attachmentData.contentType
                    });
                }
            );
        }
        return () => {
            cancelled = true;
            setObjectUrl(setThumb, null);
            setIsContainFit(false);
        };
    }, [
        props.file.id,
        props.file.sourceType,
        props.file.contentType,
        props.file.hasThumb,
        showThumb,
        showPreview
    ]);

    const handleOnImageLoad = useCallback(
        (e: React.SyntheticEvent<HTMLDivElement>) => {
            if (props.fullSize) {
                return;
            }
            const img = e.target;
            if (img instanceof HTMLImageElement) {
                const w = img.naturalWidth;
                const h = img.naturalHeight;
                const cw = img.width;
                const ch = img.height;
                setIsContainFit(Math.max(cw / w, ch / h) > 1.5);
            }
        },
        [props.fullSize]
    );

    return (
        <div className={classes.root}>
            {showPreview ? (
                <Image
                    className={classes.img}
                    src={thumb}
                    maw="100%"
                    height={imageHeight}
                    mx="auto"
                    withPlaceholder
                    onLoad={handleOnImageLoad}
                    placeholder={<LoadingOverlay visible={!thumb} />}
                    alt={props.file.name}
                    fit={props.fullSize ? 'scale-down' : isContainFit ? 'contain' : 'cover'}
                />
            ) : (
                <Box className={classes.iconContainer}>
                    <Icon icon={icon} className={classes.icon} />
                </Box>
            )}
        </div>
    );
}
