import {
    Button,
    Group,
    MediaQuery,
    Paper,
    Stack,
    Text,
    ThemeIcon,
    Title,
    rem
} from '@mantine/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import RouteListContainer from '../../atoms/RouteScreen/RouteListContainer';
import {
    Attachment,
    AttachmentEx,
    AttachmentId,
    Chat,
    ChatId,
    FavoriteMessage,
    FullFavoriteMessage
} from '../../types/Types';
import { FavoriteListElement } from './FavoriteListElement';
import ScreenScrollContainer from '../../mantineAtoms/ScreenScrollContainer';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectCachedChats } from '../../store/DataCacheSlice';
import { Link } from 'react-router-dom';
import { IconBookmarkFilled, IconExternalLink } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { NoItemsInfo } from '../../atoms/NoItemsInfo';
import { Gallery } from '../../components/FilesView/Gallery';
import { flushSync } from 'react-dom';
import { EmptyState } from '../../mantineAtoms/EmptyState/EmptyState';
import { toggleSidebar } from '../../store/UserPreferenceSlice';

export interface FavoritesScreenViewProps {
    favoriteMessages: FullFavoriteMessage[];
    activeFavoriteMessage?: FavoriteMessage;
    onFavoriteMessageChoose: (favoriteMessage: FullFavoriteMessage) => void;
    filter: string;
    onFilterSubmit: (filter: string) => void;
}

export default function FavoritesScreenView(props: FavoritesScreenViewProps) {
    const chats = useAppSelector(selectCachedChats);

    const [handleShowFilePreview, setHandleShowFilePreview] = useState<
        (fileId: AttachmentId) => void
    >(() => () => {});
    const handleGetShowFilePreview = useCallback((cb: (fileId: AttachmentId) => void) => {
        setHandleShowFilePreview(() => cb);
    }, []);

    const groupedMessages = useMemo(() => {
        const chatsWithMessages = new Map<
            ChatId,
            { messages: FullFavoriteMessage[]; files: Attachment[] }
        >();
        const sortedMessages = props.favoriteMessages.sort((a, b) => {
            return b.message.date - a.message.date;
        });
        for (const message of sortedMessages) {
            if (chatsWithMessages.has(message.chat.id)) {
                const prevMessages = chatsWithMessages.get(message.chat.id);
                chatsWithMessages.set(message.chat.id, {
                    messages: [...(prevMessages?.messages || []), message],
                    files: [...(prevMessages?.files || []), ...message.message.attachments]
                });
                continue;
            }
            chatsWithMessages.set(message.chat.id, {
                messages: [message],
                files: [...message.message.attachments]
            });
        }

        const groups = new Map<Chat, { messages: FullFavoriteMessage[]; files: Attachment[] }>();
        for (const chat of chats) {
            if (chatsWithMessages.has(chat.id)) {
                const chatAggrgatedData = chatsWithMessages.get(chat.id);
                if (chatAggrgatedData) groups.set(chat, chatAggrgatedData);
            }
        }
        const groupsArray = Array.from(groups.entries());
        const sortedGroups = groupsArray.sort((a, b) => {
            return b[1].messages[0].message.date - a[1].messages[0].message.date;
        });
        return sortedGroups;
    }, [props.favoriteMessages, chats]);

    const { t } = useTranslation();

    const [chatAttachments, setChatAttachments] = useState<Attachment[]>([]);
    const [initialId, setInitialId] = useState<AttachmentId>();

    useEffect(() => {
        if (chatAttachments && initialId) {
            handleShowFilePreview(initialId);
        }
    }, [initialId, chatAttachments, handleShowFilePreview]);
    const dispatch = useAppDispatch();

    if (props.favoriteMessages.length === 0) {
        return (
            <RouteListContainer>
                <EmptyState
                    image="NO_BOOKMARKS"
                    title="No Bookmarks yet"
                    subTitle="Add messages you want save for later."
                    button={
                        <Button component={Link} to="/chat">
                            Go to Topics
                        </Button>
                    }
                />
            </RouteListContainer>
        );
    }

    function showSidebar() {
        dispatch(toggleSidebar({}));
    }

    return (
        <RouteListContainer>
            <Group m="md" spacing="xs">
                <MediaQuery smallerThan="sm" styles={{ display: 'block' }}>
                    <Button ml="sm" onClick={showSidebar} display={'none'}>
                        Menu
                    </Button>
                </MediaQuery>
                <ThemeIcon variant="transparent" color="dark">
                    <IconBookmarkFilled />
                </ThemeIcon>
                <Title order={2}>{t('sidebar.bookmarks')}</Title>
            </Group>
            <ScreenScrollContainer mb="xl">
                {groupedMessages &&
                    groupedMessages.map(([chat, messages]) => (
                        <Paper
                            key={chat.id}
                            withBorder
                            sx={(theme) => ({ borderRadius: theme.radius.md })}
                            mx="md"
                            mb="xl">
                            <Group align="center" p={'xs'} pb={0} pl="md">
                                <Text size="sm">
                                    From:{' '}
                                    <Text span weight={500}>
                                        {chat.title}
                                    </Text>
                                </Text>
                                <Button
                                    variant="subtle"
                                    rightIcon={<IconExternalLink size={rem(12)} />}
                                    component={Link}
                                    size="xs"
                                    to={`/chat/${chat.id}`}>
                                    Go to Topic
                                </Button>
                            </Group>

                            <Stack spacing="xs">
                                {messages.messages.map((item) => (
                                    <FavoriteListElement
                                        onFileClick={(id) => {
                                            const files = groupedMessages.find(
                                                (x) => x[0] === chat
                                            )?.[1].files;
                                            flushSync(() => {
                                                setChatAttachments(files || []);
                                                setInitialId(id);
                                            });
                                        }}
                                        key={item.message.id}
                                        favoriteMessage={item}
                                    />
                                ))}
                            </Stack>
                        </Paper>
                    ))}
                {groupedMessages.length === 0 && <NoItemsInfo itemsType="bookmarks" />}
            </ScreenScrollContainer>
            <Gallery
                files={chatAttachments as AttachmentEx[]}
                getShowFilePreview={handleGetShowFilePreview}
            />
        </RouteListContainer>
    );
}
