import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translations/en.json';
import plTranslation from './translations/pl.json';
import { FakeTranslationsGenerator } from './FakeTranslationsGenerator';

export function getAvailableLangs(): Array<{ id: string; name: string }> {
    return [
        { id: 'en', name: enTranslation['langName'] },
        { id: 'pl', name: plTranslation['langName'] }
    ];
}

export const defaultLangId = 'en';

const defaultNS = 'translation';

const resources = {
    en: {
        translation: enTranslation
    },
    pl: {
        translation: plTranslation
    }
} as const;

declare module 'i18next' {
    interface CustomTypeOptions {
        defaultNS: typeof defaultNS;
        resources: (typeof resources)['en'];
    }
}

const tPromise = i18next.use(initReactI18next).init({
    ns: ['translation'],
    defaultNS,
    resources,
    fallbackLng: defaultLangId,
    interpolation: {
        escapeValue: false
    }
});

async function updatePageTitle() {
    const t = await tPromise;
    document.title = t('appTitle');
}

i18next.on('languageChanged', () => {
    updatePageTitle();
});
updatePageTitle();

export const i18nInitPromise = tPromise;

export function applyFakeTranslations() {
    const fake = new FakeTranslationsGenerator();
    i18next.addResourceBundle('fake', defaultNS, fake.generate());
    i18next.changeLanguage('fake');
}

// applyFakeTranslations();
