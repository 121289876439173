import { Center, Stack, Text, Button, Group } from '@mantine/core';
import { useCallback } from 'react';
import TemporalPassword from '../../mantineAtoms/TemporalPassword';
import { useTranslation } from 'react-i18next';

export interface CreatedUserDataObj {
    login: string;
    password: string;
}

export interface CreatedUserDataProps {
    createdUserData: CreatedUserDataObj;
    handleClose: () => void;
}

export default function CreatedUserData(props: CreatedUserDataProps) {
    const { t } = useTranslation();
    const handleClose = props.handleClose;
    const handleCloseClick = useCallback(() => {
        handleClose();
    }, [handleClose]);

    return (
        <Stack>
            <Text align="center">{t('modal.createdUserData.message')}:</Text>
            <Center>
                <Stack>
                    <div>
                        <Text span mr="md">
                            {t('entityProps.login')}:
                        </Text>
                        <Text span>{props.createdUserData.login}</Text>
                    </div>
                    <div>
                        <Text span mr="md">
                            {t('entityProps.tmpPassword')}:
                        </Text>
                        <Group display="inline-flex" spacing={2}>
                            <TemporalPassword password={props.createdUserData.password} />
                        </Group>
                    </div>
                </Stack>
            </Center>
            <Center>
                <Button onClick={handleCloseClick}>Done</Button>
            </Center>
        </Stack>
    );
}
