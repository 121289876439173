import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import currentUserReducer, { resetCurrentUserState } from './CurrentUserSlice';
import dataCacheReducer, { resetDataCacheState } from './DataCacheSlice';
import modalsReducer, { resetModalsState } from './ModalsSlice';
import userPreferenceReducer, { resetUserPreferenceState } from './UserPreferenceSlice';
import videoReducer, { resetVideoState } from './VideoSlice';
import fullTextEditorReducer, { resetFullTextEditorState } from './FullTextEditorSlice';
import usersReducer, { resetUsersState } from './UsersSlice';
import draftsReducer from './DraftSlice';
import meetingUserIdsReducer, { resetMeetingUserIdsState } from './MeetingUserIds';
import acceptedDocumentsReducer, { resetAcceptedDocuments } from './AcceptedDocumentsSlice';

export const store = configureStore({
    reducer: {
        currentUser: currentUserReducer,
        dataCache: dataCacheReducer,
        modals: modalsReducer,
        video: videoReducer,
        userPreferences: userPreferenceReducer,
        fullTextEditor: fullTextEditorReducer,
        users: usersReducer,
        drafts: draftsReducer,
        meetingUserIds: meetingUserIdsReducer,
        acceptedDocuments: acceptedDocumentsReducer
    }
});

export function resetStoreOnLogout() {
    store.dispatch(resetCurrentUserState());
    store.dispatch(resetDataCacheState());
    store.dispatch(resetModalsState());
    store.dispatch(resetUserPreferenceState());
    store.dispatch(resetVideoState());
    store.dispatch(resetFullTextEditorState());
    store.dispatch(resetUsersState());
    store.dispatch(resetMeetingUserIdsState());
    store.dispatch(resetAcceptedDocuments());
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
