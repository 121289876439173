import {
    Flex,
    LoadingOverlay,
    Group,
    Avatar,
    Stack,
    TextInput,
    rem,
    SimpleGrid,
    Box,
    Textarea,
    Indicator,
    Transition,
    Text,
    Button
} from '@mantine/core';
import { IconBuilding } from '@tabler/icons-react';
import { useState, useEffect, useLayoutEffect, useCallback, useMemo, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useConfirmDialog, ConfirmDialog } from '../../mantineAtoms/ConfirmDialog';
import { EditableField } from '../../mantineAtoms/EditableField';
import FormAlert from '../../mantineAtoms/FormAlert';
import { useAppDispatch, useAppSelector } from '../../store';
import {
    selectCachedContacts,
    addComapny,
    loadContacsAsync,
    mutateCompany
} from '../../store/DataCacheSlice';
import {
    Company,
    Contact,
    CompanyName,
    Email,
    CompanyWebsite,
    CompanyAddress,
    Phone,
    CompanyNote,
    MobilePhone,
    Username,
    Timestamp
} from '../../types/Types';
import { CompanyUsersList } from '../ModalsOutlet/CompanyModal/CompanyUsersList';
import { UserSearch } from '../ModalsOutlet/CompanyModal/UserSearch';
import { CompanyEditForm, SearchElements } from '../ModalsOutlet/CompanyModal/types';
import { api } from '../../api/Api';
import LinkWithFilter from '../../mantineAtoms/SearchBar/LinkWithFilter';

export function CompanyDescription({
    company,
    newCompanyContacts,
    isNew
}: {
    isNew?: boolean;
    company: Company;
    newCompanyContacts?: Contact[] | null;
}) {
    const { t } = useTranslation();

    const initialContacts = useRef<Contact[] | null>();
    const [companyContacts, setCompanyContacts] = useState<Contact[] | null>(
        newCompanyContacts || null
    );

    useEffect(() => {
        if (!company || newCompanyContacts) return;
        setCompanyContacts(null);
        const f = async () => {
            const contacts = await api.getContactsOfCompany(company.id);
            setCompanyContacts(contacts);
            initialContacts.current = contacts;
        };
        f();
    }, [company, newCompanyContacts]);

    const { watch, reset, setValue, formState, control, register, handleSubmit } =
        useForm<CompanyEditForm>(
            !isNew
                ? {
                      defaultValues: {
                          ...company,
                          users: companyContacts
                      }
                  }
                : {}
        );
    const { dirtyFields, isSubmitting, isSubmitSuccessful, isSubmitted } = formState;
    const isAnythingDirty = JSON.stringify(dirtyFields) !== '{}';
    const dispatch = useAppDispatch();

    const contacts = useAppSelector(selectCachedContacts);

    const { controller } = useConfirmDialog();

    useEffect(() => {
        if (isNew) {
            setValue('name', company.name, { shouldDirty: true });
            setValue('users', companyContacts, { shouldDirty: true });
        }
    }, [setValue, isNew, company.name, companyContacts]);

    useLayoutEffect(() => {
        setValue('users', companyContacts);
    }, [companyContacts, setValue]);

    const users = watch('users');

    const [removedUsers, setRemovedUsers] = useState<Contact[]>([]);

    const removeUserFromCompany = useCallback((contact: Contact) => {
        setRemovedUsers((prev) => [...prev, contact]);
    }, []);

    const handleCreateCompany = useCallback(
        async (formData: CompanyEditForm) => {
            try {
                const newCompany = {
                    name: formData.name.trim() as CompanyName,
                    email: formData.email?.trim() as Email,
                    website: formData.email?.trim() as CompanyWebsite,
                    address: formData.address?.trim() as CompanyAddress,
                    phone: formData.phone?.trim() as Phone,
                    note: formData.note?.trim() as CompanyNote,
                    mobilePhone: '' as MobilePhone,
                    tags: [],
                    pinned: false,
                    archived: false
                };
                const serverData = await api.addCompany(newCompany);
                dispatch(addComapny(serverData));

                await Promise.all([
                    api.asssignCompanyToUsers(serverData.id, formData.users || []),
                    api.removeCompanyFormUser(removedUsers)
                ]);
                dispatch(loadContacsAsync);
                reset({
                    name: serverData.name,
                    email: serverData.email,
                    website: serverData.email,
                    address: serverData.address,
                    phone: serverData.phone,
                    note: serverData.note,
                    users: formData.users
                });
            } catch (e) {
                console.error(e);
            }
        },
        [dispatch, removedUsers, reset]
    );

    const handleSaveData = useCallback(
        async (formData: CompanyEditForm) => {
            const formUsers = formData.users || [];
            const newCompany: Company = {
                createdBy: '' as Username,
                createdDate: 0 as Timestamp,
                lastModifiedBy: '' as Username,
                lastModifiedDate: 0 as Timestamp,
                tags: [],
                pinned: false,
                archived: false,
                id: company.id,
                name: formData.name.trim() as CompanyName,
                email: formData.email?.trim() as Email,
                website: formData.email?.trim() as CompanyWebsite,
                address: formData.address?.trim() as CompanyAddress,
                phone: formData.phone?.trim() as Phone,
                note: formData.note?.trim() as CompanyNote,
                mobilePhone: '' as MobilePhone
            };
            try {
                const serverData = await api.updateCompany(newCompany);
                dispatch(mutateCompany(serverData));
                const newUsers = formUsers.filter(
                    (formUser) => !(companyContacts || []).find((x) => x.id === formUser.id)
                );
                await Promise.all([
                    api.asssignCompanyToUsers(company.id, newUsers),
                    api.removeCompanyFormUser(removedUsers)
                ]);

                reset({
                    name: serverData.name,
                    email: serverData.email,
                    website: serverData.email,
                    address: serverData.address,
                    phone: serverData.phone,
                    note: serverData.note,
                    users: formUsers
                });
            } catch (e) {
                console.error(e);
            }
        },
        [company, dispatch, reset, companyContacts, removedUsers]
    );
    const [currentEditing, setCurrentEditing] = useState<
        'none' | 'phone' | 'addres' | 'website' | 'name' | 'email'
    >('none');

    const newPhoneNumber = watch('phone');
    const newAdres = watch('address');
    const newEmail = watch('email');
    const newName = watch('name');
    const newWebiste = watch('website');

    const contactsToSearch = useMemo(() => {
        const filteredContacts: SearchElements = [];
        for (const contact of contacts) {
            if ((users || []).findIndex((usr) => usr.id === contact.id) === -1) {
                const name =
                    (contact.name as string) === contact.email
                        ? contact.username
                        : contact.name || contact.username;
                filteredContacts.push({
                    type: 'contact',
                    value: contact.id,
                    label: name ? name : '',
                    email: contact.email
                });
            }
        }
        return filteredContacts;
    }, [contacts, users]);

    const relationsButtons = [
        { name: t('screen.companies.showThreads'), to: 'chat' },
        { name: t('screen.companies.showMeetings'), to: 'meetings' }
    ] as const;

    return (
        <Flex
            p={'md'}
            sx={{
                flexGrow: 1
            }}
            pos="relative"
            justify="space-between"
            h="100%"
            direction="column">
            <LoadingOverlay visible={isSubmitting} />
            <ConfirmDialog controller={controller} />
            <Flex
                direction="column"
                w="100%"
                h="100%"
                sx={{
                    flexGrow: 1
                }}>
                <Group
                    align="flex-start"
                    mb="lg"
                    sx={{
                        '&:hover': {
                            '& .privmx-add-tag': {
                                display: 'inline-block'
                            }
                        }
                    }}>
                    <Avatar size={90}>
                        <IconBuilding />
                    </Avatar>
                    <Stack
                        spacing={'xs'}
                        sx={{
                            flexGrow: 1
                        }}>
                        <EditableField
                            disabled={!dirtyFields.name}
                            label={t('entityProps.name')}
                            input={
                                <TextInput
                                    {...register('name')}
                                    size="xs"
                                    onBlurCapture={() => setCurrentEditing('none')}
                                />
                            }
                            value={newName || company?.name || '---'}
                            isInEditMode={currentEditing === 'name'}
                            toggleEdit={(isEditing: boolean) => {
                                setCurrentEditing(isEditing ? 'name' : 'none');
                            }}
                            textProps={{
                                weight: 'bolder',
                                h: rem(30)
                            }}
                        />
                        <EditableField
                            disabled={!dirtyFields.email}
                            label={t('entityProps.email')}
                            input={
                                <TextInput
                                    {...register('email')}
                                    size="xs"
                                    onBlurCapture={() => setCurrentEditing('none')}
                                />
                            }
                            value={newEmail || company?.email || '---'}
                            isInEditMode={currentEditing === 'email'}
                            toggleEdit={(isEditing: boolean) => {
                                setCurrentEditing(isEditing ? 'email' : 'none');
                            }}
                        />
                    </Stack>
                    <SimpleGrid cols={3} w={'100%'} h={55} ml="auto">
                        <EditableField
                            disabled={!dirtyFields.phone}
                            label={t('entityProps.phone')}
                            input={
                                <TextInput
                                    {...register('phone')}
                                    size="xs"
                                    onBlurCapture={() => setCurrentEditing('none')}
                                />
                            }
                            value={newPhoneNumber || company?.phone || '---'}
                            isInEditMode={currentEditing === 'phone'}
                            toggleEdit={(isEditing: boolean) => {
                                setCurrentEditing(isEditing ? 'phone' : 'none');
                            }}
                        />
                        <EditableField
                            disabled={!dirtyFields.address}
                            label={t('entityProps.address')}
                            input={
                                <TextInput
                                    {...register('address')}
                                    size="xs"
                                    onBlurCapture={() => setCurrentEditing('none')}
                                />
                            }
                            value={newAdres || company?.address || '---'}
                            isInEditMode={currentEditing === 'addres'}
                            toggleEdit={(isEditing: boolean) => {
                                setCurrentEditing(isEditing ? 'addres' : 'none');
                            }}
                        />
                        <EditableField
                            disabled={!dirtyFields.website}
                            label={t('entityProps.website')}
                            input={
                                <TextInput
                                    {...register('website')}
                                    size="xs"
                                    onBlurCapture={() => setCurrentEditing('none')}
                                />
                            }
                            value={newWebiste || company?.website || '---'}
                            isInEditMode={currentEditing === 'website'}
                            toggleEdit={(isEditing: boolean) => {
                                setCurrentEditing(isEditing ? 'website' : 'none');
                            }}
                        />
                    </SimpleGrid>
                </Group>
                <Box mb="md">
                    <Text size="sm" weight="lighter">
                        {t('entityProps.note')}
                    </Text>
                    <Controller
                        control={control}
                        name="note"
                        render={({ field }) => (
                            <Textarea
                                minRows={3}
                                maxRows={3}
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                </Box>
                <Box>
                    <Indicator
                        size={6}
                        inline
                        styles={{
                            indicator: {
                                top: 5
                            },
                            common: {
                                top: 3,
                                right: -3
                            }
                        }}
                        disabled={!dirtyFields.users}>
                        <Text w="max-content" size="sm" display="block" weight="lighter">
                            {t('entityProps.employees')}
                        </Text>
                    </Indicator>
                </Box>
                <Box h={250} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Controller
                        control={control}
                        name="users"
                        render={({ field }) => {
                            return (
                                <>
                                    <UserSearch
                                        placeholder={`${t('action.addUser')}...`}
                                        mb="sm"
                                        onChange={(id) => {
                                            setRemovedUsers((prevUsers) =>
                                                prevUsers.filter((x) => x.id !== id)
                                            );
                                            field.onChange([
                                                ...(field.value || []),
                                                contacts.find((x) => x.id === id)
                                            ]);
                                        }}
                                        contacts={contactsToSearch}
                                    />
                                    <CompanyUsersList
                                        field={field}
                                        handleRemoveUser={(contactToRemove) => {
                                            field.onChange(
                                                (field.value || []).filter(
                                                    (prevUsers) =>
                                                        prevUsers.id !== contactToRemove.id
                                                )
                                            );
                                            removeUserFromCompany(contactToRemove);
                                        }}>
                                        {companyContacts && companyContacts.length === 0 && (
                                            <Text align="center">{t('infoMessage.noUsers')}</Text>
                                        )}
                                    </CompanyUsersList>
                                </>
                            );
                        }}
                    />
                </Box>

                <Group align="flex-start" mb="sm">
                    {relationsButtons.map((button) => (
                        <LinkWithFilter
                            key={button.to}
                            to={button.to}
                            filter={{ company: [company.name] }}>
                            <Button key={button.name} variant="outline">
                                {button.name}
                            </Button>
                        </LinkWithFilter>
                    ))}
                </Group>
                <Box mt="auto">
                    <Transition mounted={isAnythingDirty} keepMounted={false} transition="fade">
                        {(styles) => (
                            <FormAlert
                                onClear={() => {
                                    reset(
                                        {
                                            address: company.address,
                                            email: company.email,
                                            name: company.name,
                                            note: company.note,
                                            phone: company.phone,
                                            website: company.phone,
                                            users: initialContacts.current
                                        },
                                        { keepDirty: false, keepDirtyValues: false }
                                    );
                                }}
                                label={t('infoMessage.unsavedData')}
                                style={styles}
                                onClick={
                                    isNew
                                        ? handleSubmit(handleCreateCompany)
                                        : handleSubmit(handleSaveData)
                                }
                            />
                        )}
                    </Transition>
                    <Transition mounted={!isSubmitSuccessful && isSubmitted} transition="fade">
                        {(styles) => (
                            <FormAlert
                                style={styles}
                                label={t('errorMessage.somethingWentWrong')}
                                onClick={handleSubmit(handleSaveData)}
                                color="red"
                            />
                        )}
                    </Transition>
                </Box>
            </Flex>
        </Flex>
    );
}
