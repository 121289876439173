import { Box, Input, InputProps, Menu, rem, Text } from '@mantine/core';
import {
    IconBuilding,
    IconCalendarDue,
    IconFile,
    IconFileUnknown,
    IconPlus,
    IconTag,
    IconUser,
    TablerIconsProps
} from '@tabler/icons-react';
import { useCallback, useState } from 'react';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { useFilterContext } from './FilterContext';
import { BaseFilterOption, FilterSelectProps, DropDownElementsMap } from './types';
import { FilterTag } from './FilterTag';
import { FilterSelectDateDropdown } from './FilterSelectDateDropdown';
import { FilterSelectUserDropdown } from './FilterSelectUserDropdown';
import { FilterSelectCompanyDropdown } from './FilterSelectCompanyDropdown';
import { FilterSelectTagDropdown } from './FilterSelectTagDropDown';

export const baseFilterOptions: BaseFilterOption[] = [
    { label: 'User', type: 'author' },
    { label: 'Date', type: 'date' }
];

const typeToIconMap: Record<
    BaseFilterOption['type'] | 'type' | 'file' | 'tag',
    (props: TablerIconsProps) => JSX.Element
> = {
    type: IconFileUnknown,
    date: IconCalendarDue,
    author: IconUser,
    file: IconFile,
    tag: IconTag,
    company: IconBuilding
};

export const ICON_SIZE = 14;

export function FilterSelect<T extends BaseFilterOption>({
    styles,
    filterOptions,
    dropdownElementMap,
    ...props
}: InputProps & FilterSelectProps<T>) {
    const [path, setPath] = useState('/');
    const { dispatch } = useFilterContext();
    const ElementMap: DropDownElementsMap = {
        '/': ({ onGoTo }) => (
            <Menu.Dropdown>
                {[...(filterOptions || [])].map((option) => {
                    const Icon = typeToIconMap[option.type];
                    return (
                        <Menu.Item
                            key={option.label}
                            icon={<Icon size={rem(ICON_SIZE)} />}
                            tt={'capitalize'}
                            onClick={() => {
                                onGoTo(option.type);
                            }}>
                            {option.label}
                        </Menu.Item>
                    );
                })}
            </Menu.Dropdown>
        ),
        ...dropdownElementMap
    };

    const [openDropdown, { open, close }] = useDisclosure();

    const handleClose = useCallback(() => {
        close();
        setPath('/');
    }, [close]);

    const button = useClickOutside<HTMLDivElement>(handleClose);

    const handleSelect = useCallback(
        (value: string) => {
            handleClose();
            dispatch({ type: 'add', filter: value });
        },
        [handleClose, dispatch]
    );

    const Dropdown = ElementMap[path];
    return (
        <Box ref={button}>
            <Menu
                opened={openDropdown}
                onClose={handleClose}
                closeOnItemClick={false}
                styles={{ dropdown: { width: 'min-content' } }}
                position={'bottom-start'}>
                <Menu.Target>
                    <Input
                        onClick={open}
                        value={'Filter'}
                        tt={'capitalize'}
                        component="button"
                        sx={{ cursor: 'pointer' }}
                        icon={<IconPlus size={rem(ICON_SIZE)} />}
                        styles={{
                            input: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
                        }}
                        {...props}>
                        <Text>Filter</Text>
                    </Input>
                </Menu.Target>
                <Dropdown onSelect={handleSelect} onGoTo={setPath} />
            </Menu>
        </Box>
    );
}

FilterSelect.Tag = FilterTag;
FilterSelect.AuthorDropdown = FilterSelectUserDropdown;
FilterSelect.DateDropdown = FilterSelectDateDropdown;
FilterSelect.CompanyDropdown = FilterSelectCompanyDropdown;
FilterSelect.TagDropdown = FilterSelectTagDropdown;
