import { Box, Indicator, Text, TextProps } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import React from 'react';

export interface EditableFieldProps {
    label: string;
    disabled: boolean;
    input: React.ReactNode;
    value: React.ReactNode | undefined;
    isInEditMode: boolean;
    toggleEdit: (isEditing: boolean) => void;
    textProps?: TextProps;
}

export function EditableField({
    label,
    disabled,
    value,
    input,
    isInEditMode,
    toggleEdit,
    textProps
}: EditableFieldProps) {
    const ref = useClickOutside(() => {
        toggleEdit(false);
    });

    return (
        <Box>
            <Indicator
                size={6}
                styles={{
                    indicator: { top: 5 },
                    common: { top: 3, right: -3 }
                }}
                inline
                disabled={disabled}>
                <Text size="sm" weight="lighter">
                    {label}
                </Text>
            </Indicator>
            {isInEditMode ? (
                <Box ref={ref}>{input}</Box>
            ) : (
                <Text
                    p={4}
                    color={value === undefined ? 'dimmed' : undefined}
                    sx={(theme) => ({
                        paddingInline: 4,
                        '&:hover': {
                            backgroundColor: theme.other.getHoverColor(theme),
                            cursor: 'pointer',
                            borderRadius: theme.radius.sm
                        }
                    })}
                    onClick={() => toggleEdit(true)}
                    size="sm"
                    {...textProps}>
                    {value}
                </Text>
            )}
        </Box>
    );
}
