import React from 'react';
import { useAppSelector } from '../../../store';
import { selectNewCompanyModalState } from '../../../store/ModalsSlice';
import { NewCompanyModal } from './NewCompanyModal';

export function NewCompanyModalOutlet() {
    const newCompanyModalState = useAppSelector(selectNewCompanyModalState);

    return <>{newCompanyModalState.opened && <NewCompanyModal />}</>;
}
