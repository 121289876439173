import { useCallback, useEffect, useState } from 'react';
import { Anchor, Button, Center, Group, Modal, Stack, Title } from '@mantine/core';
import { Text } from '@mantine/core';
import { Trans, useTranslation } from 'react-i18next';
import { ApiErrorEvent, BaseApi } from '../../api/privmx/BaseApi';
import { useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';

export const handledErrors = {
    MAX_USERS_COUNT_EXCEEDED: 0x003c,
    STORAGE_LIMIT_EXCEEDED: 0x6107,
    VIDEO_LIMIT_EXCEEDED: 0x0097
};
const handledErrorCodes = Object.values(handledErrors);
type HandledErrorCode = (typeof handledErrorCodes)[number];

export default function ApiError() {
    const { t } = useTranslation();
    const [errorCode, setErrorCode] = useState<HandledErrorCode | null>(null);
    const currentUser = useAppSelector(selectCurrentUser);
    const isAdmin = !!currentUser.isAdmin;

    useEffect(() => {
        const listener = (evt: ApiErrorEvent) => {
            if (handledErrorCodes.includes(evt.errorCode)) {
                setErrorCode(evt.errorCode);
            }
        };
        BaseApi.addEventListener('error', listener);
        return () => {
            BaseApi.removeEventListener('error', listener);
        };
    }, []);

    const handleClose = useCallback(() => {
        setErrorCode(null);
    }, []);

    if (errorCode === null) {
        return <></>;
    }

    return (
        <Modal
            withCloseButton={true}
            centered
            zIndex={999999}
            opened
            onClose={handleClose}
            size={900}>
            <Center h={400} w={'100%'}>
                <Stack align="center">
                    <Title order={1}>
                        {errorCode === handledErrors.MAX_USERS_COUNT_EXCEEDED &&
                            t('modal.apiError.errors.maxUsersCountExceeded.title')}
                        {errorCode === handledErrors.STORAGE_LIMIT_EXCEEDED &&
                            t('modal.apiError.errors.storageLimitExceeded.title')}
                        {errorCode === handledErrors.VIDEO_LIMIT_EXCEEDED &&
                            t('modal.apiError.errors.videoLimitExceeded.title')}
                    </Title>
                    <Text my="xl">
                        {errorCode === handledErrors.MAX_USERS_COUNT_EXCEEDED &&
                            t('modal.apiError.errors.maxUsersCountExceeded.message')}
                        {errorCode === handledErrors.STORAGE_LIMIT_EXCEEDED && (
                            <Trans i18nKey="modal.apiError.errors.storageLimitExceeded.message">
                                <strong></strong>
                            </Trans>
                        )}
                        {errorCode === handledErrors.VIDEO_LIMIT_EXCEEDED &&
                            t('modal.apiError.errors.videoLimitExceeded.message')}{' '}
                        {isAdmin
                            ? t('modal.apiError.errorMessageSufix.forAdmins')
                            : t('modal.apiError.errorMessageSufix.forNonAdmins')}
                    </Text>
                    <Group>
                        {isAdmin && (
                            <Anchor
                                href="https://privmx.link/community-customize-your-plan"
                                target="_blank">
                                <Button>{t('action.contactUs')}</Button>
                            </Anchor>
                        )}
                        <Button variant="outline" onClick={handleClose}>
                            {t('action.close')}
                        </Button>
                    </Group>
                </Stack>
            </Center>
        </Modal>
    );
}
