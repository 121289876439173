import create from 'zustand';
import { Attachment } from '../../types/Types';

type Store = {
    editing: boolean;
    initialText: string;
    messageId: string;
    attachments: Attachment[];
};

type StoreActions = {
    startEditing: (initalText: string, messageId: string, attachment: Attachment[]) => void;
    stopEditing: () => void;
};

const initialState: Store = {
    editing: false,
    initialText: '',
    messageId: '',
    attachments: []
};

export const useEditingStore = create<Store & StoreActions>((set) => ({
    ...initialState,
    startEditing: (initialText, messageId, attachments) => {
        set(() => ({ editing: true, initialText, messageId, attachments }));
    },
    stopEditing: () => {
        set(initialState);
    }
}));
