import { Alert, Box, Group, Progress, Stack, Text, Title } from '@mantine/core';
import { useAppSelector } from '../../store';
import { selectPlanSummary } from '../../store/UserPreferenceSlice';
import { Utils } from '../../utils/Utils';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { milisecondsToTime } from '../../utils/Formatters';
import { api } from 'privmx-server-api';

interface PlanScreenProgressProps {
    minMax: [string, string];
    percentage: number;
    value: string;
    name: string;
    closeToLimitWarning: React.ReactNode;
    exeededLimitWarning: React.ReactNode;
}

function PlanScreenProgress({
    minMax,
    name,
    percentage,
    value,
    closeToLimitWarning,
    exeededLimitWarning
}: PlanScreenProgressProps) {
    const { t } = useTranslation();

    const warning = useMemo(() => {
        if (percentage >= 100) {
            return (
                <Alert mt="sm" color="red">
                    <Group position="apart">{exeededLimitWarning}</Group>
                </Alert>
            );
        } else if (percentage >= 90) {
            return (
                <Alert mt="sm" color="red">
                    <Group position="apart">{closeToLimitWarning}</Group>
                </Alert>
            );
        } else {
            return <></>;
        }
    }, [closeToLimitWarning, exeededLimitWarning, percentage]);

    return (
        <Box>
            <Title order={4}>{name}</Title>
            <Text>
                <Text mr={4} span weight={'bolder'}>
                    {value}
                </Text>
                {t('screen.settings.planScreen.progress.outOf')}
                <Text mx={4} span weight={'bolder'}>
                    {minMax[1]}
                </Text>
                {t('screen.settings.planScreen.progress.available')}
            </Text>
            <Progress h={28} value={percentage} mb="xs" color="gray" size={'xl'} />
            <Group position="apart">
                <Text size={'sm'} color="dimmed">
                    {minMax[0]}
                </Text>
                <Text size={'sm'} color="dimmed">
                    {minMax[1]}
                </Text>
            </Group>
            {warning}
        </Box>
    );
}

type Plan = api.admin.PlanSummary;

function StaffCountLimit(props: { staffAccounts: Plan['staffAccounts'] }) {
    const { t } = useTranslation();

    if (props.staffAccounts.limit === -1) {
        return (
            <Box>
                <Title order={4}>{t('screen.settings.planScreen.users')}</Title>
                <Text>{t('screen.settings.planScreen.unlimitedUsersInfo')}</Text>
                <Progress
                    h={28}
                    value={100}
                    mb="xs"
                    color="gray"
                    size={'xl'}
                    sections={[
                        {
                            value: 100,
                            color: 'gray',
                            label: 'Unlimited',
                            tooltip: t('screen.settings.planScreen.unlimitedUsersInfo')
                        }
                    ]}
                />
                <Group position="apart">
                    <Text size={'sm'} color="dimmed">
                        0
                    </Text>
                    <Text size={'sm'} color="dimmed">
                        {t('screen.settings.planScreen.unlimited')}
                    </Text>
                </Group>
            </Box>
        );
    }

    return (
        <PlanScreenProgress
            name={t('screen.settings.planScreen.users')}
            minMax={['0', `${props.staffAccounts.limit}`]}
            value={`${Math.max(0, props.staffAccounts.limit - props.staffAccounts.used)}`}
            percentage={(props.staffAccounts.used / props.staffAccounts.limit) * 100}
            closeToLimitWarning={
                <Text>{t('screen.settings.planScreen.progress.usersWarning')}</Text>
            }
            exeededLimitWarning={<Text>{t('screen.settings.planScreen.progress.usersError')}</Text>}
        />
    );
}

function VideoLimit(props: { video: Plan['video'] }) {
    const { t } = useTranslation();

    if (props.video.limit === false) {
        return (
            <Box>
                <Title order={4}>{t('screen.settings.planScreen.videoUsage')}</Title>
                <Text>{t('screen.settings.planScreen.unlimitedVideoInfo')}</Text>
                <Progress
                    h={28}
                    value={100}
                    mb="xs"
                    color="gray"
                    size={'xl'}
                    sections={[
                        {
                            value: 100,
                            color: 'gray',
                            label: 'Unlimited',
                            tooltip: t('screen.settings.planScreen.unlimitedVideoInfo')
                        }
                    ]}
                />
                <Group position="apart">
                    <Text size={'sm'} color="dimmed">
                        0h
                    </Text>
                    <Text size={'sm'} color="dimmed">
                        {t('screen.settings.planScreen.unlimited')}
                    </Text>
                </Group>
            </Box>
        );
    }

    return (
        <PlanScreenProgress
            name={t('screen.settings.planScreen.videoUsage')}
            minMax={['0', `${milisecondsToTime(props.video.limit)}`]}
            value={`${milisecondsToTime(
                props.video.usageLeft === true ? 0 : props.video.usageLeft
            )}`}
            percentage={(props.video.usage / props.video.limit) * 100}
            closeToLimitWarning={
                <Text>{t('screen.settings.planScreen.progress.videoWarning')}</Text>
            }
            exeededLimitWarning={<Text>{t('screen.settings.planScreen.progress.videoError')}</Text>}
        />
    );
}

function StorageLimit(props: { storage: Plan['storage'] }) {
    const { t } = useTranslation();

    if (props.storage.limit === -1) {
        return (
            <Box>
                <Title order={4}>{t('screen.settings.planScreen.spaceUsage')}</Title>
                <Text>{t('screen.settings.planScreen.unlimitedStorageInfo')}</Text>
                <Progress
                    h={28}
                    value={100}
                    mb="xs"
                    color="gray"
                    size={'xl'}
                    sections={[
                        {
                            value: 100,
                            color: 'gray',
                            label: 'Unlimited',
                            tooltip: t('screen.settings.planScreen.unlimitedStorageInfo')
                        }
                    ]}
                />
                <Group position="apart">
                    <Text size={'sm'} color="dimmed">
                        0GB
                    </Text>
                    <Text size={'sm'} color="dimmed">
                        {t('screen.settings.planScreen.unlimited')}
                    </Text>
                </Group>
            </Box>
        );
    }

    return (
        <PlanScreenProgress
            name={t('screen.settings.planScreen.spaceUsage')}
            minMax={['0GB', Utils.bytesSize(props.storage.limit)]}
            value={Utils.bytesSize(props.storage.limit - props.storage.used)}
            percentage={(props.storage.used / props.storage.limit) * 100}
            closeToLimitWarning={
                <Text>{t('screen.settings.planScreen.progress.storageWarning')}</Text>
            }
            exeededLimitWarning={
                <Text>{t('screen.settings.planScreen.progress.storageError')}</Text>
            }
        />
    );
}

export function PlanScreen() {
    const plan = useAppSelector(selectPlanSummary);

    return (
        <Stack mt={'lg'}>
            <StorageLimit storage={plan.storage} />
            <StaffCountLimit staffAccounts={plan.staffAccounts} />
            <VideoLimit video={plan.video} />
        </Stack>
    );
}
