import { useAdminPanelStyles } from './SettingsMemberTab';
import { useInputState } from '@mantine/hooks';
import { Button, Flex, Group, Paper, Switch, Text, Textarea, Title } from '@mantine/core';
import { ChangeEvent, useCallback, useState } from 'react';
import { api } from '../../api/Api';
import { Email, Language } from '../../types/Types';
import ThreadAdded from '../../atoms/Modals/ThreadCreated';
import CenteredModal from '../../components/ModalsOutlet/CenteredModal';
import { useTranslation } from 'react-i18next';
import { CreateManagableUserResult } from '../../api/privmx/admin/userCreation/ManagableUserCreator';
import { useUserInteraction } from '../../hooks/useUserInteraction';
import { LoadingMessageOverlay } from '../../mantineAtoms/LoadingMessageOverlay';
import { Validator } from '../../utils/Validator';
import { useRouteContext } from './useSettingsScreenContext';

export type ParseResult =
    | {
          status: 'success';
          correctValues: string[];
      }
    | {
          status: 'error';
          correctValues: string[];
          wrongValues: string[];
          wrongLengths: string[];
      };

export function parseAndValidateEnteredEmails(inputString: string): ParseResult {
    const filteredEmails = inputString
        .trim()
        .toLowerCase()
        .replaceAll(/[\n, ]/g, ';')
        .replaceAll(/;;/g, ';')
        .split(';')
        .map((splitted) => splitted.trim());

    const validationResults = filteredEmails.map((x) => {
        return { value: x, hasErrors: Validator.getErrors(x, ['email'], true) };
    });

    const correct = validationResults.filter((x) => !x.hasErrors).map((x) => x.value);
    const wrongValues = validationResults
        .filter((x) => x.hasErrors && x.hasErrors.includes('email'))
        .map((x) => x.value);
    const wrongLengths = validationResults
        .filter((x) => x.hasErrors && x.hasErrors.includes('size'))
        .map((x) => x.value);

    return {
        status: wrongValues.length > 0 || wrongLengths.length > 0 ? 'error' : 'success',
        correctValues: correct,
        wrongValues: wrongValues.length > 0 ? wrongValues : [],
        wrongLengths: wrongLengths.length > 0 ? wrongLengths : []
    };
}

function trailingDotsSubstring(text: string, maxLength: number) {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.substring(0, maxLength) + '...';
    }
}

export function NewStaffMember() {
    const { classes } = useAdminPanelStyles();
    const [context, setRoute] = useRouteContext();

    const [textValue, setTextAreaValue] = useInputState('');
    const [texareaError, setTexareaError] = useState<React.ReactNode | null>(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [internalUsers, setInternalUsers] = useState<null | Array<
        Awaited<ReturnType<typeof api.createInternalUser>>
    >>();
    const { t } = useTranslation();
    const onClose = useCallback(() => {
        setRoute({ route: 'staff' });
        setInternalUsers(null);
        setTextAreaValue('');
    }, [setRoute, setTextAreaValue]);

    const createUsers = useCallback(
        async (emails: string[]) => {
            const internalUsers: CreateManagableUserResult[] = [];

            for (const email of emails) {
                const creationResult = await api.createInternalUser(
                    email as Email,
                    'en' as Language,
                    isAdmin,
                    { type: 'manuallyViaAdminPanel' }
                );
                internalUsers.push(creationResult);
            }
            if (context.route === 'staff/new') {
                context.revalidate?.();
            }
            setInternalUsers(internalUsers);
        },
        [context, isAdmin]
    );

    const { startInteraction, isProcessing } = useUserInteraction({
        successTitle: t('modal.addNewStaff.usersAdded'),
        successMessage: '',
        action: createUsers,
        errorTitle: t('modal.addNewStaff.unableToAddMembersTitle'),
        errorMessage: t('modal.addNewStaff.unableToAddMembersMessage')
    });

    const handleCreateMembers = useCallback(async () => {
        const validationResult = parseAndValidateEnteredEmails(textValue);
        const errors: Record<'email' | 'size', string[]> = {
            email: [],
            size: []
        };
        if (validationResult.status === 'error') {
            errors.email = errors.email.concat(validationResult.wrongValues);
            errors.size = errors.size.concat(validationResult.wrongLengths);
        }

        if (errors.email.length !== 0 || errors.size.length !== 0) {
            let subTitle: React.ReactNode;
            if (errors.email.length) {
                const maxLength = Validator.constraints['email'].maxLength ?? 50;
                if (errors.email.length >= 2) {
                    const emailsList = errors.email
                        .map((e) => trailingDotsSubstring(e, maxLength))
                        .join(', ');
                    const txt = t('errorMessage.specificInvalidEmails', { emails: emailsList });
                    subTitle = <Text>{txt}</Text>;
                } else {
                    subTitle = <Text>Email: {errors.email[0]} must be valid emails</Text>;
                }
            }
            if (errors.size.length) {
                const maxLength = Validator.constraints['email'].maxLength ?? 50;
                if (errors.size.length >= 2) {
                    subTitle = (
                        <>
                            {subTitle}
                            <Text>
                                Emails:{' '}
                                {errors.size
                                    .map((e) => trailingDotsSubstring(e, maxLength))
                                    .join(', ')}{' '}
                                cannot be longer than {maxLength}
                            </Text>
                        </>
                    );
                } else {
                    subTitle = (
                        <>
                            {subTitle}
                            <Text>
                                Email: {trailingDotsSubstring(errors.size[0], maxLength)} cannot be
                                longer than {maxLength} characters
                            </Text>
                        </>
                    );
                }
            }
            if (subTitle) {
                setTexareaError(subTitle);
                return;
            }
        } else {
            startInteraction(validationResult.correctValues);
        }
    }, [textValue, startInteraction, t]);

    const onAdminToggle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setIsAdmin(event.currentTarget.checked);
    }, []);

    const mappedUsers = internalUsers
        ? new Map(
              internalUsers.map((x) => [
                  x.username,
                  { email: x.params.email, password: x.password }
              ])
          )
        : undefined;

    return (
        <>
            <Paper className={classes.list} h="fit-content" mt="md">
                <LoadingMessageOverlay visible={isProcessing} message="Adding new staff members" />
                <Textarea
                    label={t('screen.settings.staff.labels.addNewStaffMember')}
                    mb={'sm'}
                    description={t('screen.settings.staff.descriptions.addNewStaffMember')}
                    minRows={3}
                    maxRows={5}
                    placeholder={'mail@example.com'}
                    value={textValue}
                    error={texareaError}
                    onChange={setTextAreaValue}
                />
                <Flex gap={0} direction="column" py="md">
                    <Flex justify="flex-start" align="center" gap="xs" py={0}>
                        <Title size={14} py={0}>
                            {t('screen.settings.staff.labels.grantAdminPermissions')}
                        </Title>
                        <Switch checked={isAdmin} onChange={onAdminToggle} />
                    </Flex>
                    <Text size="xs" color="dimmed">
                        {t('screen.settings.staff.descriptions.grantAdminPermissions')}
                    </Text>
                </Flex>

                <Group position={'center'} py="md">
                    <Button onClick={onClose} variant={'light'}>
                        Dismiss
                    </Button>
                    <Button onClick={handleCreateMembers}>Add staff</Button>
                </Group>
            </Paper>
            {mappedUsers && (
                <CenteredModal
                    opened={true}
                    onClose={onClose}
                    closeOnClickOutside={false}
                    centered
                    zIndex={300}
                    size="70%"
                    height="600px"
                    title="New Staff members">
                    <ThreadAdded
                        comunicateConfig={{
                            title: 'Temporary passwords',
                            subTitle:
                                'Send temporary passwords to your colleagues, so they can log in and set their own.'
                        }}
                        onClose={onClose}
                        state={{
                            state: 'created',
                            payload: {
                                title: '',
                                createdUsers: mappedUsers
                            }
                        }}
                    />
                </CenteredModal>
            )}
        </>
    );
}
