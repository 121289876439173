export class Template<T extends HTMLElement> {
    
    private template: HTMLTemplateElement;
    
    constructor(html: string, i18n: (key: string, ...args: string[]) => string) {
        this.template = document.createElement("template");
        this.template.innerHTML = html.replace(/{{ i18n\(('|")(.*?)('|")\) }}/g, (...args: string[]) => {
            return i18n(args[2]!);
        });
    }
    
    // @ts-ignore
    render(model: object): T {
        return (this.template.content.cloneNode(true) as DocumentFragment).children[0] as T;
    }
    
}