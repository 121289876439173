import { Modal } from '@mantine/core';
import { BaseModalData } from '../../types/Modals';
import TwofaPrompt, { TwofaPromptData } from './index';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store';
import { setTwofaResult } from '../../store/ModalsSlice';

export interface TwofaPromptModalData extends BaseModalData {
    options: TwofaPromptData;
}

export function TwofaPromptModal(props: {
    onClose: () => void;
    data: TwofaPromptModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const dispatch = useAppDispatch();
    const onClose = () => {
        props.onClose();
        dispatch(setTwofaResult({ cancelled: true }));
    };
    const { t } = useTranslation();
    return (
        <Modal
            closeOnClickOutside={false}
            opened
            centered
            onFocus={props.onFocus}
            onClose={onClose}
            title={props.data.title ?? t('modal.twofaPrompt.title')}>
            <TwofaPrompt
                onResend={() => {}}
                onSave={() => {}}
                twofaPrompt={props.data.options}
                handleClose={props.onClose}
            />
        </Modal>
    );
}
