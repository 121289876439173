import { KvdbCollection } from './KvdbCollection';
import * as privmx from 'privfs-client';
import { IWhenable } from '../utils/async';

export interface KvdbEntry<T> extends privmx.types.db.KvdbEntry {
    secured: {
        key?: string;
        value: T | null;
    };
}

export class KvdbMap<T> {
    constructor(public kvdb: KvdbCollection<KvdbEntry<T>>) {}

    get dbId() {
        return this.kvdb.dbId;
    }

    get extKey() {
        return this.kvdb.extKey;
    }

    get newlyCreated() {
        return this.kvdb.newlyCreated;
    }

    has(key: string): boolean {
        return this.get(key) != null;
    }

    get(key: string): T | null {
        const entry = this.kvdb.getSync(key);
        return entry && entry.secured ? entry.secured.value : null;
    }

    getEntries() {
        const map: { [key: string]: T } = {};
        this.kvdb.collection.forEach((x) => {
            if (x.secured && x.secured.value && x.secured.key) {
                map[x.secured.key] = x.secured.value;
            }
        });
        return map;
    }

    getValues() {
        const res: T[] = [];
        this.kvdb.collection.forEach((x) => {
            if (x.secured && x.secured.value) {
                res.push(x.secured.value);
            }
        });
        return res;
    }

    set(key: string, value: T): Promise<void> {
        return this.kvdb.set(key, { secured: { value: value } }, false);
    }

    remove(key: string): Promise<void> {
        return this.kvdb.set(key, { secured: { value: null } }, false);
    }

    setWithLock(
        key: string,
        func: (content: T | null | false) => IWhenable<T | null>
    ): Promise<void> {
        return this.kvdb.withLock(key, async (entry) => {
            const oldValue: T | null | false =
                entry === false || !entry || !entry.secured ? false : entry.secured.value;
            const value = await func(oldValue);
            if (!value) {
                return null;
            }
            return { secured: { value: value } };
        });
    }

    async setMany(map: { [key: string]: T }) {
        const promises: Promise<void>[] = [];
        for (const key in map) {
            promises.push(this.set(key, map[key]));
        }
        await Promise.all(promises);
        return true;
    }

    async removeMany(keys: string[]): Promise<void> {
        await Promise.all(keys.map((x) => this.remove(x)));
    }

    forEach(func: (key: string, value: T) => void) {
        this.kvdb.collection.forEach((x) => {
            if (x.secured && x.secured.value && x.secured.key) {
                func(x.secured.key, x.secured.value);
            }
        });
    }
}
