import * as privfs from 'privfs-client';

export class AdminKeyHolder {
    private adminKey: privfs.crypto.ecc.ExtKey | null = null;

    constructor(
        private authData: privfs.types.core.UserDataEx,
        private srpSecure: privfs.core.PrivFsSrpSecure
    ) {}

    hasAdminKey(): boolean {
        return this.adminKey != null;
    }

    getAdminKey() {
        if (!this.adminKey) {
            throw new Error('Admin key not present');
        }
        return this.adminKey;
    }

    setAdminKey(adminKey: privfs.crypto.ecc.ExtKey) {
        this.adminKey = adminKey;
    }

    async saveAdminKey(extKey: privfs.crypto.ecc.ExtKey): Promise<void> {
        if (this.adminKey) {
            return;
        }
        this.authData.masterRecordLevel2.data.adminKey = extKey.getPrivatePartAsBase58();
        await this.srpSecure.setMasterRecordLevel2(
            this.authData.masterRecordLevel2.key,
            this.authData.masterRecordLevel2.data
        );
        this.setAdminKey(extKey);
    }
}
