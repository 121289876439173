import * as privmx from 'privfs-client';
import { RequestApi } from './RequestApi';
import * as PmxApi from 'privmx-server-api';
import { Utils } from './utils/Utils';
import { ThumbnailGenerator } from '../../utils/ThumbnailGenerator';
import * as types from '../../types/Types';
import { CancelledByUserError } from '../../utils/CancelledByUserError';
import { Base64 } from './utils/Base64';
import { DataCacheInterface } from '../DataCacheInterface';
import { ModalsInterface } from '../ModalsInterface';
import { ChunkStream } from './ChunkStream';

export interface AttachmentMeta {
    ver: 1;
    name: types.FileName;
    mimetype: types.Mimetype;
    size: types.FileSize;
    cipherType: types.CipherType;
    chunkSize: types.ChunkSize;
    key: PmxApi.api.core.Base64;
    hmac: PmxApi.api.core.Base64;
    thumb?: {
        mimetype: types.Mimetype;
        size: types.FileSize;
        cipherType: types.CipherType;
        chunkSize: types.ChunkSize;
        key: PmxApi.api.core.Base64;
        hmac: PmxApi.api.core.Base64;
    };
}

export interface BaseAttachmentMeta {
    mimetype: types.Mimetype;
    size: types.FileSize;
    cipherType: types.CipherType;
    chunkSize: types.ChunkSize;
    key: PmxApi.api.core.Base64;
    hmac: PmxApi.api.core.Base64;
}

export interface PreparedFile {
    file: File;
    cipherType: types.CipherType;
    key: Buffer;
    hmac: Buffer;
    chunkSize: types.ChunkSize;
    thumb?: {
        file: File;
        cipherType: types.CipherType;
        chunkSize: types.ChunkSize;
        index: number;
        hmac: Buffer;
        key: Buffer;
    };
}

export interface AttachmentMetaEncryptor<TEncryptionKey> {
    encrypt(
        data: AttachmentMeta,
        pub: TEncryptionKey
    ): Promise<PmxApi.api.attachment.AttachmentMeta>;
}

export type ExistingAttachmentProvider = (
    attachmentId: PmxApi.api.attachment.AttachmentId,
    thumb?: boolean
) => Promise<{
    id: PmxApi.api.attachment.AttachmentId;
    name: types.FileName;
    contentType: types.Mimetype;
    size: types.FileSize;
    content: privmx.Buffer.Buffer;
    meta: AttachmentMeta;
}>;

export interface ExistingAttachmentsProvider {
    getThreadAttachment: ExistingAttachmentProvider;
    getDraftAttachment: ExistingAttachmentProvider;
    getInquiryAttachment: ExistingAttachmentProvider;
}

export type AttachmentToCopy =
    | types.AttachmentEx
    | types.DraftAttachmentEx
    | types.InquiryAttachmentEx;
export type AttachmentsToCopy = AttachmentToCopy[];

interface PreparedFileForCopy {
    file: PreparedFile;
    attachmentId: PmxApi.api.attachment.AttachmentId;
    source: PmxApi.api.attachment.AttachmentCopySource;
}

export interface Progress {
    file: File;
    sent: number;
    percentage: number;
    startedAt: number;
    currentTime: number;
    elapsedTime: number;
    /** Speed in B/s */
    speed: number;
}

export interface DownloadProgress {
    size: number;
    downloaded: number;
    percentage: number;
    startedAt: number;
    currentTime: number;
    elapsedTime: number;
    /** Speed in B/s */
    speed: number;
}

export type DownloadProgressCallback = (progress: DownloadProgress) => void;

export interface OutputStream {
    write(data: Buffer): Promise<void>;
    close(): Promise<void>;
}
export interface DownloadOptions {
    outputStreamFactory?: (size: number) => Promise<OutputStream>;
    onProgress?: DownloadProgressCallback;
}
export class AttachmentUtils<TAttachment, TMetaEncryptionKey> {
    private static readonly REPORT_PROGRESS =
        localStorage.getItem('report-file-sending-progress') === 'true';

    constructor(
        private requestApi: RequestApi,
        private attachmentMetaEncryptor: AttachmentMetaEncryptor<TMetaEncryptionKey>,
        private preparedFileToAttachmentConverter: (
            preparedFile: PreparedFile,
            hasThumb: boolean
        ) => TAttachment,
        private existingAttachmentsProvider: ExistingAttachmentsProvider,
        private dataCacheService: DataCacheInterface,
        private modalsService: ModalsInterface
    ) {}

    async prepareAttachments(
        key: TMetaEncryptionKey,
        groupKey: Buffer,
        threadId: types.ThreadId | null,
        files: File[],
        attachmentsToCopy: AttachmentsToCopy = []
    ): Promise<{
        attachments: TAttachment[];
        request: PmxApi.api.attachment.Attachments | null;
        attachmentsToCopy: PmxApi.api.attachment.AttachmentsToCopy;
    }> {
        if (threadId !== null) {
            await this.checkForDuplicatedFiles(threadId, files, attachmentsToCopy);
        }
        let thumbId = 0;
        const filesWithThumbs = (
            await Promise.all(
                files.map(async (file) => {
                    try {
                        if (!ThumbnailGenerator.canGenerate(file)) {
                            return;
                        }
                        const thumbFile = await ThumbnailGenerator.generate(file);
                        return { file, thumbFile, idx: thumbId++ };
                    } catch {
                        return;
                    }
                })
            )
        ).filter(Utils.isDefined);
        const chunkSize = (128 * 1024) as types.ChunkSize;
        const chunksCountInBulk = 3 * 8; // it gives 3MB in single request to server
        const { id: requestId } =
            files.length === 0
                ? { id: null }
                : await this.requestApi.createRequest({
                      files: [
                          ...files.map((x) => this.getFileSize(x.size, chunkSize)),
                          ...filesWithThumbs.map((x) =>
                              this.getFileSize(x.thumbFile.size, chunkSize)
                          )
                      ]
                  });
        const newFiles: PreparedFile[] = [];
        if (requestId !== null) {
            for (const [i, file] of files.entries()) {
                const mainRes = await this.sendFile(
                    this.requestApi,
                    requestId,
                    i,
                    file,
                    chunkSize,
                    chunksCountInBulk
                );
                const fileWithThumb = filesWithThumbs.find((x) => x.file === file);
                const thumb = fileWithThumb
                    ? await (async () => {
                          const index = files.length + fileWithThumb.idx;
                          const res = await this.sendFile(
                              this.requestApi,
                              requestId,
                              index,
                              fileWithThumb.thumbFile,
                              chunkSize,
                              chunksCountInBulk
                          );
                          return {
                              file: fileWithThumb.thumbFile,
                              index,
                              hmac: res.hmac,
                              key: res.key,
                              cipherType: res.cipherType,
                              chunkSize: res.chunkSize
                          };
                      })()
                    : undefined;
                newFiles.push({
                    file: file,
                    cipherType: mainRes.cipherType,
                    key: mainRes.key,
                    hmac: mainRes.hmac,
                    thumb: thumb,
                    chunkSize: mainRes.chunkSize
                });
            }
        }
        const copied = await this.useExistingFiles(attachmentsToCopy);
        const attachments = [
            ...newFiles.map((x) =>
                this.preparedFileToAttachmentConverter(
                    x,
                    !!filesWithThumbs.find((y) => y.file === x.file)
                )
            ),
            ...copied.map((x) => this.preparedFileToAttachmentConverter(x.file, !!x.file.thumb))
        ];

        return {
            attachments: attachments,
            request:
                requestId === null
                    ? null
                    : {
                          requestId: requestId,
                          list: await Promise.all(
                              newFiles.map(async (x, i) => {
                                  const meta: AttachmentMeta = {
                                      ver: 1,
                                      name: x.file.name as types.FileName,
                                      mimetype: x.file.type as types.Mimetype,
                                      size: x.file.size as types.FileSize,
                                      cipherType: x.cipherType,
                                      chunkSize: x.chunkSize,
                                      key: Base64.from(x.key),
                                      hmac: Base64.from(x.hmac),
                                      thumb: x.thumb
                                          ? {
                                                mimetype: x.thumb.file.type as types.Mimetype,
                                                size: x.thumb.file.size as types.FileSize,
                                                cipherType: x.thumb.cipherType,
                                                chunkSize: x.thumb.chunkSize,
                                                key: Base64.from(x.thumb.key),
                                                hmac: Base64.from(x.thumb.hmac)
                                            }
                                          : undefined
                                  };
                                  const group = await privmx.crypto.service.hmacSha256(
                                      groupKey,
                                      Buffer.from(x.file.name, 'utf8')
                                  );
                                  return {
                                      fileIndex: i,
                                      group: group.toString(
                                          'hex'
                                      ) as PmxApi.api.attachment.AttachmentGroup,
                                      meta: await this.attachmentMetaEncryptor.encrypt(meta, key),
                                      thumbIndex: x.thumb ? x.thumb.index : undefined
                                  };
                              })
                          )
                      },
            attachmentsToCopy: await Promise.all(
                copied.map(async (x) => {
                    const meta: AttachmentMeta = {
                        ver: 1,
                        name: x.file.file.name as types.FileName,
                        mimetype: x.file.file.type as types.Mimetype,
                        size: x.file.file.size as types.FileSize,
                        cipherType: x.file.cipherType,
                        chunkSize: x.file.chunkSize,
                        key: Base64.from(x.file.key),
                        hmac: Base64.from(x.file.hmac),
                        thumb: x.file.thumb
                            ? {
                                  mimetype: x.file.thumb.file.type as types.Mimetype,
                                  size: x.file.thumb.file.size as types.FileSize,
                                  cipherType: x.file.thumb.cipherType,
                                  chunkSize: x.file.thumb.chunkSize,
                                  key: Base64.from(x.file.thumb.key),
                                  hmac: Base64.from(x.file.thumb.hmac)
                              }
                            : undefined
                    };
                    const group = await privmx.crypto.service.hmacSha256(
                        groupKey,
                        Buffer.from(x.file.file.name, 'utf8')
                    );
                    return {
                        group: group.toString('hex') as PmxApi.api.attachment.AttachmentGroup,
                        meta: await this.attachmentMetaEncryptor.encrypt(meta, key),
                        attachmentId: x.attachmentId,
                        source: x.source
                    };
                })
            )
        };
    }

    private async sendFile(
        requestApi: RequestApi,
        requestId: PmxApi.api.request.RequestId,
        fileIndex: number,
        file: File,
        chunkSize: types.ChunkSize,
        chunksCountInBulk: number,
        onProgress?: (progress: Progress) => void
    ) {
        if (AttachmentUtils.REPORT_PROGRESS && !onProgress) {
            onProgress = (x) =>
                console.log(
                    'Progress',
                    x.file.name,
                    x.percentage.toFixed(2) + '%',
                    (x.sent / 1000000).toFixed(2) + '/' + (x.file.size / 1000000).toFixed(2) + 'MB',
                    (x.speed / 1000 / 1000).toFixed(2) + 'MB/s',
                    (x.elapsedTime / 1000).toFixed(0) + 's'
                );
        }
        const stream = new ChunkStream(file, chunkSize);
        let mySeq = 0;
        let chunks: Buffer[] = [];
        let prevSending: Promise<unknown> | null = null;
        const start = Date.now();
        let sent = 0;
        while (true) {
            const result = await stream.next();
            if (result === false) {
                throw new Error('End of stream');
            }
            const { done, chunk } = result;
            chunks.push(chunk);
            if (done || chunks.length >= chunksCountInBulk) {
                if (prevSending) {
                    await prevSending;
                }
                const buf = privmx.rpc.RpcUtils.concatBuffers(chunks);
                sent += buf.length;
                const theSent = Math.min(sent, file.size);
                prevSending = requestApi
                    .sendChunk({
                        requestId,
                        fileIndex: fileIndex,
                        seq: mySeq,
                        data: buf
                    })
                    .then(() => {
                        if (onProgress) {
                            const now = Date.now();
                            const elapsedTime = now - start;
                            onProgress({
                                file: file,
                                sent: theSent,
                                startedAt: start,
                                currentTime: now,
                                elapsedTime: elapsedTime,
                                percentage: (theSent / file.size) * 100,
                                speed: theSent / (elapsedTime / 1000)
                            });
                        }
                    });
                mySeq++;
                chunks = [];
            }
            if (done) {
                if (prevSending) {
                    await prevSending;
                }
                const key = stream.getKey();
                const checksum = stream.getChecksums();
                await requestApi.commitFile({
                    requestId,
                    fileIndex: fileIndex,
                    seq: mySeq,
                    checksum: checksum
                });
                const fileHmac = await privmx.crypto.service.hmacSha256(key, checksum);
                return {
                    file: file,
                    cipherType: 1 as types.CipherType,
                    key: key,
                    hmac: fileHmac,
                    chunkSize: chunkSize
                };
            }
        }
    }

    private async prepareChunk(key: Buffer, data: Buffer) {
        const iv = privmx.crypto.service.randomBytes(16);
        const cipher = await privmx.crypto.service.aes256CbcPkcs7Encrypt(data, key, iv);
        const hmac = await privmx.crypto.service.hmacSha256(key, Buffer.concat([iv, cipher]));
        return { hmac: hmac, cipher: Buffer.concat([hmac, iv, cipher]) };
    }

    private getFileSize(fileSize: number, chunkSize: types.ChunkSize) {
        const parts = Math.ceil(fileSize / chunkSize);
        const size = parts * (chunkSize + 64);
        const checksumSize = parts * 32;
        return { size, checksumSize };
    }

    private async useExistingFiles(attachmentsToCopy: AttachmentsToCopy) {
        const files: PreparedFileForCopy[] = [];
        for (const attachmentToCopy of attachmentsToCopy) {
            if ('chatId' in attachmentToCopy) {
                files.push({
                    attachmentId: attachmentToCopy.id,
                    source: {
                        type: 'thread',
                        threadId: attachmentToCopy.chatId
                    },
                    file: await this.getPreparedFile(
                        attachmentToCopy,
                        this.existingAttachmentsProvider.getThreadAttachment
                    )
                });
            } else if ('draftId' in attachmentToCopy) {
                files.push({
                    attachmentId: attachmentToCopy.id,
                    source: { type: 'draft', draftId: attachmentToCopy.draftId },
                    file: await this.getPreparedFile(
                        attachmentToCopy,
                        this.existingAttachmentsProvider.getDraftAttachment
                    )
                });
            } else if ('inquiryId' in attachmentToCopy) {
                files.push({
                    attachmentId: attachmentToCopy.id,
                    source: {
                        type: 'inquirySubmit',
                        inquiryId: attachmentToCopy.inquiryId,
                        inquirySubmitId: attachmentToCopy.inquirySubmitId
                    },
                    file: await this.getPreparedFile(
                        attachmentToCopy,
                        this.existingAttachmentsProvider.getInquiryAttachment
                    )
                });
            }
        }
        return files;
    }

    private async getPreparedFile(
        attachmentToCopy: AttachmentToCopy,
        provider: ExistingAttachmentProvider
    ) {
        const attachment = await provider(attachmentToCopy.id);
        const file: File = new File([attachment.content], attachment.name, {
            type: attachment.contentType
        });
        let thumb: PreparedFile['thumb'] = undefined;
        if (attachmentToCopy.hasThumb) {
            const attachmentThumb = await provider(attachmentToCopy.id, true);
            const fileThumb: File = new File([attachmentThumb.content], attachmentThumb.name, {
                type: attachmentThumb.contentType
            });
            thumb = {
                file: fileThumb,
                cipherType: attachmentThumb.meta.thumb!.cipherType,
                key: Buffer.from(attachmentThumb.meta.thumb!.key, 'base64'),
                hmac: Buffer.from(attachmentThumb.meta.thumb!.hmac, 'base64'),
                chunkSize: attachmentThumb.meta.thumb!.chunkSize,
                index: -1
            };
        }
        const preparedFile: PreparedFile = {
            file: file,
            cipherType: attachment.meta.cipherType,
            key: Buffer.from(attachment.meta.key, 'base64'),
            hmac: Buffer.from(attachment.meta.hmac, 'base64'),
            chunkSize: attachment.meta.chunkSize,
            thumb: thumb
        };
        return preparedFile;
    }

    private async checkForDuplicatedFiles(
        threadId: types.ThreadId,
        files: File[],
        attachmentsToCopy: AttachmentsToCopy
    ): Promise<void> {
        const existingFileNames = this.dataCacheService
            .selectCachedAttachmentsByThreadId(threadId)
            .map((x) => x.name);
        const newFileNames = [
            ...files.map((x) => x.name as types.FileName),
            ...attachmentsToCopy.map((x) => x.name)
        ];
        if (Utils.unique(newFileNames).length !== newFileNames.length) {
            throw new Error('Uploading multiple files with the same name is not allowed');
        }
        const duplicatedFileNames: types.FileName[] = [];
        for (const newFileName of newFileNames) {
            if (existingFileNames.includes(newFileName)) {
                duplicatedFileNames.push(newFileName);
            }
        }
        if (duplicatedFileNames.length === 0) {
            return;
        }
        // const action = await this.askForDuplicatedFilesAction(duplicatedFileNames);
        const action = await this.modalsService.askForDuplicatedFilesAction(duplicatedFileNames);

        if (action === 'cancel') {
            throw new CancelledByUserError();
        } else if (action === 'renameNewFile') {
            for (let i = 0; i < files.length; ++i) {
                const file = files[i];
                if (duplicatedFileNames.includes(file.name as types.FileName)) {
                    const newFileName = this.generateNewFileName(
                        file.name as types.FileName,
                        existingFileNames
                    );
                    const newFile = new File([file.slice()], newFileName, { type: file.type });
                    files[i] = newFile;
                    existingFileNames.push(newFileName);
                }
            }
            for (const file of attachmentsToCopy) {
                if (duplicatedFileNames.includes(file.name as types.FileName)) {
                    const newFileName = this.generateNewFileName(
                        file.name as types.FileName,
                        existingFileNames
                    );
                    file.name = newFileName;
                    existingFileNames.push(newFileName);
                }
            }
        }
    }

    private generateNewFileName(
        oldFileName: types.FileName,
        existingFileNames: types.FileName[]
    ): types.FileName {
        const extPos = oldFileName.lastIndexOf('.');
        const oldFileNameWithoutExtension =
            extPos >= 0 ? oldFileName.substring(0, extPos) : oldFileName;
        const ext = extPos >= 0 ? oldFileName.substring(extPos) : '';
        for (let i = 1; i < 10000; ++i) {
            const candidateFileName =
                `${oldFileNameWithoutExtension}(${i})${ext}` as types.FileName;
            if (!existingFileNames.includes(candidateFileName)) {
                return candidateFileName;
            }
        }
        throw new Error('Could not generate a unique file name');
    }

    static async downloadAttachment(
        currentMeta: BaseAttachmentMeta,
        getData: (range: PmxApi.api.thread.BufferReadRange) => Promise<{ data: Buffer }>,
        options: DownloadOptions
    ) {
        const downloader = new AttachmentDownloader(currentMeta, getData, options);
        return downloader.download();
    }
}

export class AttachmentDownloader {
    private chunkSize: number;
    private cipheredChunkSize: number;
    private ivSize: number;
    private hmacSize: number;
    private fullChunkSize: number;
    private maxChunksToRead: number;
    private fileKey: Buffer;

    constructor(
        private currentMeta: BaseAttachmentMeta,
        private getData: (range: PmxApi.api.thread.BufferReadRange) => Promise<{ data: Buffer }>,
        private options: DownloadOptions
    ) {
        if (this.currentMeta.cipherType !== 1) {
            throw new Error('Unsupported cipher type');
        }
        this.chunkSize = this.currentMeta.chunkSize;
        this.cipheredChunkSize = this.chunkSize + 16;
        this.ivSize = 16;
        this.hmacSize = 32;
        this.fullChunkSize = this.hmacSize + this.ivSize + this.cipheredChunkSize;
        this.maxChunksToRead = Math.floor(5000000 / this.fullChunkSize);
        this.fileKey = Buffer.from(this.currentMeta.key, 'base64');
    }

    async download() {
        const checksums = await this.validateChecksum();
        const chunkKeyProvider = new ChunkKeyProvider(this.fileKey);
        const result = this.options.outputStreamFactory
            ? await this.options.outputStreamFactory(this.currentMeta.size)
            : new BufferOutputStream(this.currentMeta.size);
        const chunksCount = checksums.getChunksCount();
        const start = Date.now();
        let downloaded = 0;

        let chunkIndex = 0;
        while (chunkIndex < chunksCount) {
            const chunksToRead =
                chunkIndex + this.maxChunksToRead < chunksCount
                    ? this.maxChunksToRead
                    : chunksCount - chunkIndex;
            const res2 = await this.getData({
                type: 'slice',
                from: chunkIndex * this.fullChunkSize,
                to: (chunkIndex + chunksToRead) * this.fullChunkSize
            });
            const fullCipher = new ByteStream(this.getBuffer(res2.data));
            downloaded += fullCipher.size();
            if (this.options.onProgress) {
                const now = Date.now();
                const elapsedTime = now - start;
                const theDownloaded = Math.min(downloaded, this.currentMeta.size);
                this.options.onProgress({
                    size: this.currentMeta.size,
                    downloaded: downloaded,
                    startedAt: start,
                    currentTime: now,
                    elapsedTime: elapsedTime,
                    percentage: (theDownloaded / this.currentMeta.size) * 100,
                    speed: theDownloaded / (elapsedTime / 1000)
                });
            }
            while (true) {
                const chunkKey = await chunkKeyProvider.getChunkKey(chunkIndex);
                const chunkChecksum = checksums.getChunkChecksum(chunkIndex);
                const chunk = await this.readChunk(chunkKey, chunkChecksum, fullCipher);
                await result.write(chunk);
                chunkIndex++;
                if (fullCipher.isEnd()) {
                    break;
                }
            }
        }
        await result.close();
        return {
            data: result instanceof BufferOutputStream ? result.getBufer() : Buffer.alloc(0),
            size: this.currentMeta.size,
            mimetype: this.currentMeta.mimetype
        };
    }

    private async validateChecksum() {
        const attData = await this.getData({
            type: 'checksum'
        });
        const checkSums = this.getBuffer(attData.data);
        const fileHmac = await privmx.crypto.service.hmacSha256(this.fileKey, checkSums);
        const originalHmac = Buffer.from(this.currentMeta.hmac, 'base64');
        if (!fileHmac.equals(originalHmac)) {
            throw new Error('File has invalid checksum');
        }
        return new Checksums(this.hmacSize, checkSums);
    }

    private getBuffer(buffer: Buffer) {
        return Buffer.from((buffer as unknown as { toArrayBuffer(): ArrayBuffer }).toArrayBuffer());
    }

    private async readChunk(chunkKey: Buffer, chunkChecksum: Buffer, cipher: ByteStream) {
        // read hmac and compare it with given one
        const hmac = cipher.read(this.hmacSize);
        if (!chunkChecksum.equals(hmac)) {
            throw new Error('File chunk has invalid checksum');
        }

        // read iv with chunkData, calculate hmac from them and compare it with given one
        const ivWithCipher = cipher.readWithoutMoving(this.ivSize + this.cipheredChunkSize);
        const cipherHmac = await privmx.crypto.service.hmacSha256(chunkKey, ivWithCipher);
        if (!chunkChecksum.equals(cipherHmac)) {
            throw new Error('File chunk has invalid cipher checksum');
        }

        // read iv and chunkData and then decrypt it
        const iv = cipher.read(this.ivSize);
        const chunkCipher = cipher.read(this.cipheredChunkSize);
        return privmx.crypto.service.aes256CbcPkcs7Decrypt(chunkCipher, chunkKey, iv);
    }
}

export class ChunkKeyProvider {
    private seq = new Int32();

    constructor(private fileKey: Buffer) {}

    getChunkKey(chunkIndex: number) {
        return privmx.crypto.service.sha256(
            Buffer.concat([this.fileKey, this.seq.getUInt32BEBuffer(chunkIndex)])
        );
    }
}

export class Checksums {
    constructor(private hmacSize: number, private checksums: Buffer) {}

    getChunkChecksum(chunkIndex: number) {
        return this.checksums.subarray(
            chunkIndex * this.hmacSize,
            (chunkIndex + 1) * this.hmacSize
        );
    }

    getChunksCount() {
        const count = this.checksums.length / this.hmacSize;
        if (!Number.isInteger(count)) {
            throw new Error('Invalid checksum length');
        }
        return count;
    }
}

export class Int32 {
    private buffer = Buffer.alloc(4);

    /** Returns reusable buffer so you have to consume this value immediately */
    getUInt32BEBuffer(value: number) {
        this.buffer.writeUInt32BE(value, 0);
        return this.buffer;
    }
}

export class ByteStream {
    private pos: number;

    constructor(public buffer: Buffer) {
        this.pos = 0;
    }

    readWithoutMoving(length: number) {
        return this.buffer.subarray(this.pos, this.pos + length);
    }

    read(length: number) {
        const newPos = this.pos + length;
        const chunk = this.buffer.subarray(this.pos, newPos);
        this.pos = newPos;
        return chunk;
    }

    isEnd() {
        return this.pos >= this.buffer.length;
    }

    size() {
        return this.buffer.length;
    }
}

export class BufferOutputStream implements OutputStream {
    private buffer: Buffer;
    private pos: number;

    constructor(size: number) {
        this.buffer = Buffer.alloc(size);
        this.pos = 0;
    }

    async write(buffer: Buffer) {
        this.buffer.set(buffer, this.pos);
        this.pos += buffer.length;
    }

    async close() {
        // do nothing
    }

    getBufer() {
        return this.buffer;
    }
}
