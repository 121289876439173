import Modal from '../../atoms/Modal';
import StateView from '../../atoms/State';
import useLoader from '../../hooks/useLoader';
import { BaseModalData } from '../../types/Modals';
import { CompanyEditPage } from './index';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface CompanyAddModalData extends BaseModalData {}

const initialStyle = {
    content: {
        minHeight: 405
    }
};

export function CompanyAddModal(props: {
    onClose: () => void;
    data: CompanyAddModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useLoader(useCallback((x) => x.loadCompanyForAdding(), []));
    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            styles={initialStyle}
            width={props.data.width}
            maw={800}
            title={props.data.title ?? t('modal.companyAdd.title')}>
            <StateView state={state} retry={revalidate}>
                {(data) => <CompanyEditPage companyEdit={data} handleClose={props.onClose} />}
            </StateView>
        </Modal>
    );
}
