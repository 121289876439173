import { Anchor, Box, Card, Code, List, ScrollArea, Stack, Tabs, Text, Title } from '@mantine/core';
import * as types from '../../types/Types';

export function EmailInboxInstructionsPage(props: { emailInbox: types.FormModel }) {
    return (
        <Box h="100%">
            <Tabs defaultValue="instructions" h="100%">
                <Stack h="100%">
                    <Tabs.List>
                        <Tabs.Tab value="instructions">Instructions</Tabs.Tab>
                        <Tabs.Tab value="configuration">Configuration</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="instructions" mih={0}>
                        <ScrollArea h="100%" p="md">
                            <Box maw="95%">
                                <Text>
                                    You can setup PrivMX Bot to automatically create entries in
                                    email inboxes from emails.
                                </Text>
                                <List type="ordered">
                                    <List.Item>
                                        Obtain IMAP configuration data from your email provider. You
                                        may also need to enable IMAP access in your email account
                                        configuration.
                                    </List.Item>
                                    <List.Item>
                                        Create and activate a PrivMX account for your bot.
                                    </List.Item>
                                    <List.Item>
                                        Follow{' '}
                                        <Anchor
                                            href="https://dev.privmx.com/internal/privmx-bot"
                                            target="_blank"
                                            rel="noreferrer">
                                            PrivMX Bot instructions
                                        </Anchor>{' '}
                                        to install the bot.
                                    </List.Item>
                                    <List.Item>
                                        Configure the bot. You can find ID of this email inbox
                                        below. Go to the "Configuration" tab to view an example
                                        configuration and option descriptions.
                                    </List.Item>
                                    <List.Item>Start the bot.</List.Item>
                                </List>
                                <Card withBorder mt="xl">
                                    ID of this email inbox ({props.emailInbox.name}):{' '}
                                    <Code>{props.emailInbox.id}</Code>
                                </Card>
                            </Box>
                        </ScrollArea>
                    </Tabs.Panel>
                    <Tabs.Panel value="configuration" mih={0}>
                        <ScrollArea h="100%" p="md">
                            <Box maw="95%">
                                <Title order={4}>config.json example:</Title>
                                <ScrollArea h={300} p="md">
                                    <Code block>{getExampleConfigJson(props.emailInbox)}</Code>
                                </ScrollArea>
                                <Title order={4}>Options:</Title>
                                <List type="unordered">
                                    <List.Item>
                                        <Code>connections</Code> array of PrivMX bots
                                    </List.Item>
                                    <List.Item>
                                        <Code>connections[].type</Code> for email inboxes it has to
                                        be "srp"
                                    </List.Item>
                                    <List.Item>
                                        <Code>connections[].host</Code> host of your PrivMX server
                                    </List.Item>
                                    <List.Item>
                                        <Code>connections[].username</Code> and{' '}
                                        <Code>connections[].password</Code> credentials that you
                                        used to create and activate the PrivMX account for the bot
                                    </List.Item>
                                    <List.Item>
                                        <Code>connections[].sections</Code> for email inboxes it can
                                        be an empty array
                                    </List.Item>
                                    <List.Item>
                                        <Code>connections[].inquiries</Code> array of email inboxes
                                    </List.Item>
                                    <List.Item>
                                        <Code>connections[].inquiries[].inquiryId</Code> ID of the
                                        target inquiry (you can find it in the "Instructions" tab)
                                    </List.Item>
                                    <List.Item>
                                        <Code>connections[].inquiries[].inquiryChannelName</Code>{' '}
                                        pick a name you will use in other configuration options to
                                        refer to this email inbox
                                    </List.Item>
                                    <List.Item>
                                        <Code>plugins.imap.list</Code> array of IMAP configurations
                                    </List.Item>
                                    <List.Item>
                                        <Code>plugins.imap.list[].name</Code> pick a name for this
                                        inbox
                                    </List.Item>
                                    <List.Item>
                                        <Code>plugins.imap.list[].connection</Code> IMAP connection
                                        configuration - you can obtain it from your email provider
                                    </List.Item>
                                    <List.Item>
                                        <Code>plugins.imap.list[].boxName</Code> name of the email
                                        box to read from (usually "INBOX")
                                    </List.Item>
                                    <List.Item>
                                        <Code>plugins.imap.list[].inquiryChannelName</Code> use the
                                        same name you used in{' '}
                                        <Code>connections[].inquiries[].inquiryChannelName</Code>
                                    </List.Item>
                                </List>
                            </Box>
                        </ScrollArea>
                    </Tabs.Panel>
                </Stack>
            </Tabs>
        </Box>
    );
}

const getExampleConfigJson = (emailInbox: types.FormModel) => `{
    "connections": [
        {
            "type": "srp",
            "host": "${window.location.hostname}",
            "username": "bot's PrivMX username",
            "password": "bot's PrivMX password",
            "sections": [],
            "inquiries": [
                {
                    "inquiryId": "${emailInbox.id}",
                    "inquiryChannelName": "mainInquiry"
                }
            ]
        }
    ],
    "plugins": {
        "core": {},
        "imap": {
            "list": [
                {
                    "name": "myInbox",
                    "connection": {
                        "user": "your@email",
                        "password": "password to your email account",
                        "host": "imap host",
                        "port": 993,
                        "tls": true
                    },
                    "boxName": "INBOX",
                    "inquiryChannelName": "mainInquiry"
                }
            ]
        }
    }
}
`;

export default EmailInboxInstructionsPage;
