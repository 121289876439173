import * as privmx from 'privfs-client';
import { KvdbCollection } from './KvdbCollection';
import { KvdbMap, KvdbEntry } from './KvdbMap';
import { KvdbCache } from './KvdbCache';
import { KvdbStateService } from './KvdbStateService';
import * as PmxApi from 'privmx-server-api';
import { runAsync } from '../utils/async';

export class KvdbCollectionManager {
    private map: { [dbId: string]: KvdbCollection<any> };
    private collections: KvdbCollection<any>[];
    private pollTimeout: NodeJS.Timer | null = null;
    private polling: boolean = false;
    private pollingEnabled: boolean;

    constructor(
        private manager: privmx.db.KeyValueDbManager,
        private cache: KvdbCache,
        private kvdbStateService: KvdbStateService
    ) {
        this.map = {};
        this.collections = [];
        this.pollingEnabled = true;
    }

    async getByKvdb<T extends privmx.types.db.KvdbEntry>(
        kvdb: privmx.db.KeyValueDb<T>
    ): Promise<KvdbCollection<T>> {
        if (kvdb.dbId in this.map) {
            return this.map[kvdb.dbId];
        }
        const x = await KvdbCollection.createAndInit(kvdb, this.cache, this.kvdbStateService);
        this.map[x.kvdb.dbId] = x;
        this.collections.push(x);
        return x;
    }

    async createByManager(
        extKey: privmx.crypto.ecc.ExtKey,
        options: privmx.types.db.KeyValueDbOptions
    ) {
        return this.manager.createByExtKey(extKey, options);
    }

    async getFromManager<T extends privmx.types.db.KvdbEntry>(
        extKey: privmx.crypto.ecc.ExtKey,
        options: privmx.types.db.KeyValueDbOptions
    ) {
        const dbId = privmx.db.KeyValueDb.getDbId(extKey) as PmxApi.api.kvdb.KvdbId;
        if (this.kvdbStateService.exists(dbId)) {
            return new privmx.db.KeyValueDb<T>(this.manager.client.gateway, extKey, options, false);
        }
        return this.manager.getOrCreateByExtKey(extKey, options);
    }

    async getOrCreateByIndex<T extends privmx.types.db.KvdbEntry>(
        index: number,
        options: privmx.types.db.KeyValueDbOptions
    ): Promise<KvdbCollection<T>> {
        const extKey = await this.manager.client.deriveKey(index);
        return this.getOrCreateByExtKey(extKey, options);
    }

    async getOrCreateByExtKey<T extends privmx.types.db.KvdbEntry>(
        extKey: privmx.crypto.ecc.ExtKey,
        options: privmx.types.db.KeyValueDbOptions
    ): Promise<KvdbCollection<T>> {
        const dbId = privmx.db.KeyValueDb.getDbId(extKey) as PmxApi.api.kvdb.KvdbId;
        if (dbId in this.map) {
            return this.map[dbId];
        }
        const kvdb = this.kvdbStateService.exists(dbId)
            ? new privmx.db.KeyValueDb<T>(this.manager.client.gateway, extKey, options, false)
            : await this.manager.getOrCreateByExtKey<T>(extKey, options);
        return this.getByKvdb(kvdb);
    }

    async getByExtKey<T extends privmx.types.db.KvdbEntry>(
        extKey: privmx.crypto.ecc.ExtKey
    ): Promise<KvdbCollection<T>> {
        const dbId = privmx.db.KeyValueDb.getDbId(extKey) as PmxApi.api.kvdb.KvdbId;
        if (dbId in this.map) {
            return this.map[dbId];
        }
        const kvdb = this.kvdbStateService.exists(dbId)
            ? new privmx.db.KeyValueDb<T>(this.manager.client.gateway, extKey, {}, false)
            : await this.manager.getByExtKey<T>(extKey);
        return this.getByKvdb(kvdb);
    }

    async getOrCreateMapByIndex<T>(
        index: number,
        options: privmx.types.db.KeyValueDbOptions
    ): Promise<KvdbMap<T>> {
        const kvdb = await this.getOrCreateByIndex<KvdbEntry<T>>(index, options);
        return new KvdbMap(kvdb);
    }

    forcePoll() {
        if (this.pollTimeout !== null) {
            clearTimeout(this.pollTimeout);
            this.pollTimeout = null;
        }
        this.poll();
    }

    poll() {
        if (this.pollTimeout || this.polling || !this.pollingEnabled) {
            return;
        }
        this.polling = true;
        runAsync(async () => {
            try {
                const result =
                    this.collections.length === 0
                        ? ({} as privmx.types.db.KeyValueDbPollResult<any>)
                        : await this.manager.waitCursors(this.collections);
                for (const dbId in result) {
                    const kvdbColl = this.map[dbId];
                    if (!kvdbColl) {
                        continue;
                    }
                    kvdbColl.processUpdate(result[dbId]);
                }
            } catch (e) {
                if (!this.isDisconnectError(e)) {
                    console.error('Error during polling Kvdb', e);
                }
            } finally {
                this.polling = false;
                if (this.pollingEnabled) {
                    this.pollTimeout = setTimeout(() => {
                        if (this.pollTimeout !== null) {
                            clearTimeout(this.pollTimeout);
                            this.pollTimeout = null;
                        }
                        this.poll();
                    }, 1);
                }
            }
        });
    }

    private isDisconnectError(e: any) {
        return (
            e instanceof privmx.rpc.ConnectionError && e.cause && e.cause.type === 'disconnected'
        );
    }

    stopPolling(): void {
        this.pollingEnabled = false;
        if (this.pollTimeout !== null) {
            clearTimeout(this.pollTimeout);
            this.pollTimeout = null;
        }
    }

    startPolling(): void {
        this.pollingEnabled = true;
        this.forcePoll();
    }
}
