import React from 'react';
import { useAppSelector } from '../../../store';
import { selectNewContactModalState } from '../../../store/ModalsSlice';
import { NewContactModal } from './NewContactModal';

export function NewContactModalOutlet() {
    const newContactState = useAppSelector(selectNewContactModalState);

    return <>{newContactState.opened && <NewContactModal />}</>;
}
