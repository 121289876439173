import * as PmxApi from 'privmx-server-api';
import { ImageTypeDetector } from './ImageTypeDetector';
import { UserPollApi } from './UserPollApi';
import * as types from '../../types/Types';
import { upsertUser, setUsers } from '../../store/UsersSlice';
import { store } from '../../store';

export interface UserEntry {
    avatar: { blob: Blob; url: string } | null;
    raw: PmxApi.api.user.UsernameEx;
}
export interface EventDispatcher {
    dispatchEvent<T extends { type: string }>(event: T): void;
}
export interface StoreUserConverter {
    convertUser(userEntry: UserEntry): types.User;
}
export interface UserChangedCoreEvent {
    type: 'userchangedcore';
    user: UserEntry;
}

export class UserService {
    private users = new Map<PmxApi.api.core.Username, UserEntry>();

    constructor(
        private userPollApi: UserPollApi,
        private eventDispatcher: EventDispatcher,
        private storeUserConverter: StoreUserConverter
    ) {}

    getUser(user: PmxApi.api.core.Username) {
        return this.users.get(user);
    }

    getUsers() {
        return [...this.users.values()];
    }

    async refresh() {
        const users = await this.userPollApi.getUsernamesEx({
            addDeletedUsers: false,
            addPkiInfo: true
        });
        for (const user of this.users.values()) {
            if (user.avatar) {
                URL.revokeObjectURL(user.avatar.url);
            }
        }
        this.users = new Map(users.map((x) => [x.username, this.convertUser(x)]));
        store.dispatch(
            setUsers(this.getUsers().map((x) => this.storeUserConverter.convertUser(x)))
        );
    }

    async onUserChange(data: PmxApi.api.event.UserPkiRevisionEventData) {
        const user = await this.userPollApi.getUsernameEx({
            username: data.username,
            addDeletedUsers: false,
            addPkiInfo: true
        });
        const converted = (() => {
            const oldUserEntry = this.users.get(data.username);
            if (
                oldUserEntry &&
                oldUserEntry.raw.cachedPkiEntry?.image === user.cachedPkiEntry?.image
            ) {
                return { avatar: oldUserEntry.avatar, raw: user };
            }
            if (oldUserEntry && oldUserEntry.avatar) {
                URL.revokeObjectURL(oldUserEntry.avatar.url);
            }
            return this.convertUser(user);
        })();
        this.users.set(data.username, converted);
        this.eventDispatcher.dispatchEvent<UserChangedCoreEvent>({
            type: 'userchangedcore',
            user: converted
        });
        store.dispatch(upsertUser(this.storeUserConverter.convertUser(converted)));
    }

    async onNewUser(user: PmxApi.api.event.NewUserEvent['data']) {
        const converted = this.convertUser(user);
        this.users.set(user.username, converted);
        this.eventDispatcher.dispatchEvent<UserChangedCoreEvent>({
            type: 'userchangedcore',
            user: converted
        });
        store.dispatch(upsertUser(this.storeUserConverter.convertUser(converted)));
    }

    private convertUser(raw: PmxApi.api.user.UsernameEx) {
        const res: UserEntry = {
            avatar: this.getAvatar(raw),
            raw: raw
        };
        return res;
    }

    private getAvatar(raw: PmxApi.api.user.UsernameEx) {
        if (!raw.cachedPkiEntry || !raw.cachedPkiEntry.image) {
            return null;
        }
        const data = Buffer.from(raw.cachedPkiEntry.image, 'base64');
        const mimetype = ImageTypeDetector.detect(data);
        if (!mimetype) {
            return null;
        }
        const blob = new Blob([data], { type: mimetype.mime });
        return { blob: blob, url: URL.createObjectURL(blob) };
    }

    async getUsernamesRaw() {
        return this.userPollApi.getUsernamesEx({
            addDeletedUsers: false,
            addPkiInfo: true
        });
    }
}
