import { Paper, Group, ActionIcon, rem, Text } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectActiveDrafts, removeDraft } from '../../../store/DraftSlice';
import { toggleNewMessageModal } from '../../../store/ModalsSlice';
import { UserSelectValue } from '../../UsersSelector';
import { useDraftElementStyle } from './DraftsOutlet.styles';

export function DraftsOutlet() {
    const draft = useAppSelector(selectActiveDrafts);
    const dispatch = useAppDispatch();
    const { classes } = useDraftElementStyle();

    const handleDraftSelect = () => {
        if (!draft) return;

        dispatch(
            toggleNewMessageModal({
                open: true,
                payload: {
                    formSnapshot: draft.formSnapshot
                }
            })
        );
        dispatch(removeDraft());
    };
    return (
        <div className={classes.container}>
            {draft && (
                <Paper onClick={handleDraftSelect} className={classes.root} shadow="lg">
                    <Group position="apart">
                        <Text size={'sm'} color="dimmed">
                            Draft
                        </Text>
                        <ActionIcon
                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                e.stopPropagation();
                                dispatch(removeDraft());
                            }}>
                            <IconX size={rem(16)} />
                        </ActionIcon>
                    </Group>
                    {draft.title ? (
                        <Text mb="sm" truncate>
                            {draft.title}
                        </Text>
                    ) : (
                        <Text mb="sm" truncate color="dimmed">
                            (no title)
                        </Text>
                    )}
                    <Group spacing={'sm'}>
                        <Text size="sm">To:</Text>
                        {draft.formSnapshot.users.map((member) => (
                            <UserSelectValue
                                key={member.value}
                                data={member.data}
                                value={member.value}
                                size=""
                                variant=""
                                radius={4}
                                disabled={false}
                                readOnly
                                type={member.type}
                                label={member.label}
                                isPrivateContact={member.isPrivateContact}
                            />
                        ))}
                    </Group>
                </Paper>
            )}
        </div>
    );
}
