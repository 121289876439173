import { Center, Container, Title, Text, Button, ThemeIcon } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconCircleCheck } from '@tabler/icons-react';

export default function AccountActivated() {
    const { t } = useTranslation();
    return (
        <Center h="100%" w="100%">
            <Container mx="auto" miw={500} size={'lg'}>
                <ThemeIcon
                    radius={999}
                    color="teal"
                    size={100}
                    variant="outline"
                    sx={{ border: 0 }}
                    mx="auto"
                    display={'flex'}>
                    <IconCircleCheck size={90} />
                </ThemeIcon>
                <Title align="center" mb="lg" size={60}>
                    {t('screens.activateFirstAccount.accountActivated.header')}
                </Title>
                <Text align="center" mb="xl" mx="auto">
                    {t('screens.activateFirstAccount.accountActivated.body1')}
                </Text>
                <Button
                    component={Link}
                    to="/"
                    state={{ firstAccount: true }}
                    w={300}
                    mx="auto"
                    display={'block'}>
                    {t('screens.activateFirstAccount.accountActivated.startButton')}
                </Button>
            </Container>
        </Center>
    );
}
