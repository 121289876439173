import React, { useCallback } from 'react';
import DuplicatedFilesAction from '.';
import { BaseModalData } from '../../types/Modals';
import * as types from '../../types/Types';
import { Modal } from '@mantine/core';
import { store } from '../../store';
import { setDuplicatedFilesAction } from '../../store/ModalsSlice';

export interface DuplicatedFilesActionModalData extends BaseModalData {
    fileNames: types.FileName[];
    updateStoreState: boolean;
}

export function DuplicatedFilesActionModal(props: {
    onClose: () => void;
    data: DuplicatedFilesActionModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const onClose = props.onClose;
    const handleClose = useCallback(
        (action: types.DuplicatedFilesAction = 'cancel') => {
            if (props.data.updateStoreState) {
                store.dispatch(setDuplicatedFilesAction(action));
            }
            onClose();
        },
        [onClose, props.data.updateStoreState]
    );
    return (
        <Modal size="xl" withCloseButton={false} centered opened={true} onClose={handleClose}>
            <DuplicatedFilesAction fileNames={props.data.fileNames} closeFn={handleClose} />
        </Modal>
    );
}
