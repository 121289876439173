import * as PmxApi from 'privmx-server-api';

export interface Doc {
    name: 'DPA' | 'PRIVACY' | 'TERMS';
    file: string;
    model: PmxApi.api.user.AcceptDocumentModel;
}

export const docs: Doc[] = [
    {
        name: 'TERMS',
        file: require('./TERMS.md'),
        model: {
            documentId:
                '162160f489d18e79a647a00f4557da25f124b9b595b74b9315f523c14a726f5e' as PmxApi.api.system.SystemDocumentId,
            appId: 'contact' as PmxApi.api.system.ApplicationId,
            name: 'TERMS' as PmxApi.api.system.SystenDocumentName,
            version: 'rev30092023' as PmxApi.api.system.SystemDocumentVersion
        }
    },
    {
        name: 'PRIVACY',
        file: require('./PRIVACY.md'),
        model: {
            documentId:
                'f89504047271d724d4d991e10228fc8d28870486c5335d9243cb3c421405c708' as PmxApi.api.system.SystemDocumentId,
            appId: 'contact' as PmxApi.api.system.ApplicationId,
            name: 'PRIVACY' as PmxApi.api.system.SystenDocumentName,
            version: 'rev30092023' as PmxApi.api.system.SystemDocumentVersion
        }
    },
    {
        name: 'DPA',
        file: require('./DPA.md'),
        model: {
            documentId:
                '9ec540b8a2ac34233aaa6c7a2dd4763101008dc8da2885e1cbdb380c731e3774' as PmxApi.api.system.SystemDocumentId,
            appId: 'contact' as PmxApi.api.system.ApplicationId,
            name: 'DPA' as PmxApi.api.system.SystenDocumentName,
            version: 'rev30092023' as PmxApi.api.system.SystemDocumentVersion
        }
    }
];
