import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import * as privmxVideoConferences from 'privmx-video-conferences';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import {
    createVideoConferenceManager,
    ExternalAvatar,
    ExternalIcon,
    VideoConferenceManagerProps
} from './createVideoConferenceManager';
import { defaultVideoConferenceSettings } from './defaultVideoConferenceSettings';
import '../../../node_modules/privmx-video-conferences/out/privmx-video-conferences.min.css';
import { createPortal } from 'react-dom';
import Icon from '../Icon';
import UserAvatar from '../../atoms/UserAvatar';
import { api } from '../../api/Api';
import { MeetingId } from '../../types/Types';
import * as PmxApi from 'privmx-server-api';
import { createStyles } from '@mantine/core';
import { selectAnonymousUsers, selectUsers } from '../../store/UsersSlice';
import { setMeetingUserIds } from '../../store/MeetingUserIds';

window.JitsiMeetJS = require('../../../node_modules/privmx-video-conferences/out/lib-jitsi-meet.js');
(window as any).$ = require('../../../node_modules/jquery/dist/jquery.min.js'); // jQuery is required by lib-jitsi-meet

export interface VideoConferenceData {
    meetingId: MeetingId;
    meetingTitle: string;
    thread: PmxApi.api.thread.Thread;
    creating: boolean;
}

export interface VideoConferenceProps {
    videoConference: VideoConferenceData;
}

function VideoConference(props: VideoConferenceProps) {
    const meetingId = props.videoConference.meetingId;
    const thread = props.videoConference.thread;
    const creating = props.videoConference.creating;
    const videoContainerRef = useRef<HTMLDivElement>(null);
    const currentUser = useAppSelector(selectCurrentUser);
    const initialCurrentUsernameRef = useRef((currentUser.username ?? currentUser.pub)!);
    const initialMeetingTitleRef = useRef(props.videoConference.meetingTitle);
    const [externalIcons, setExternalIcons] = useState<ExternalIcon[]>([]);
    const [externalAvatars, setExternalAvatars] = useState<ExternalAvatar[]>([]);
    const { classes } = useVideoConferenceStyles();
    const regularUsers = useAppSelector(selectUsers);
    const anonymousUsers = useAppSelector(selectAnonymousUsers);
    const dispatch = useAppDispatch();
    const videoConferenceManagerProps = useRef<VideoConferenceManagerProps>({
        thread,
        anonymousUsers,
        regularUsers
    });
    videoConferenceManagerProps.current = { thread, anonymousUsers, regularUsers };
    useEffect(() => {
        const roomMetadata: privmxVideoConferences.core.RoomMetadata = {
            creatorHashmail: initialCurrentUsernameRef.current,
            title: initialMeetingTitleRef.current
        };
        const videoConferenceManager = createVideoConferenceManager(
            meetingId,
            videoContainerRef.current!,
            setExternalIcons,
            setExternalAvatars,
            (userIds) => {
                dispatch(setMeetingUserIds({ meetingId, userIds }));
            },
            videoConferenceManagerProps
        );
        (async () => {
            await videoConferenceManager.videoConference.init({
                roomMetadata: roomMetadata,
                videoConferenceSettings: defaultVideoConferenceSettings
            });
            await videoConferenceManager.connect(roomMetadata, roomMetadata.creatorHashmail);
            if (creating) {
                api.sendMeetingStartedMessage(meetingId);
            }
        })();

        return () => {
            videoConferenceManager.destroy();
        };
    }, [creating, meetingId, dispatch]);

    return (
        <div className={classes.container}>
            <div ref={videoContainerRef} />
            {externalIcons.map((externalIcon) =>
                createPortal(
                    <Icon icon={externalIcon.icon} spin={externalIcon.spin} />,
                    externalIcon.element
                )
            )}
            {externalAvatars.map((externalAvatar) =>
                createPortal(
                    <UserAvatar username={externalAvatar.username} size={externalAvatar.size} />,
                    externalAvatar.element
                )
            )}
        </div>
    );
}

export default VideoConference;

const useVideoConferenceStyles = createStyles((theme) => {
    return {
        container: {
            position: 'absolute',
            inset: 0,
            '& .video-conference-main': {
                backgroundColor: 'transparent',
                color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
                '& .controls-container': {
                    ...theme.fn.fontStyles(),

                    '& .controls-left , .controls-right': {
                        color:
                            theme.colorScheme === 'dark'
                                ? theme.colors.dark[0]
                                : theme.colors.gray[9],

                        position: 'absolute',
                        bottom: theme.spacing.xs,

                        '& i': {
                            color:
                                theme.colorScheme === 'dark'
                                    ? theme.colors.dark[0]
                                    : theme.colors.gray[9]
                        }
                    }
                },
                '& button': {
                    border: 0,
                    outline: 0,
                    cursor: 'pointer',

                    "& [data-action='disconnect'] i.fa:after": {
                        display: 'none'
                    },
                    "&[data-action='toggle-extra-settings'],&[data-action='videoconference-gong']":
                        {
                            display: 'none'
                        },
                    "&[data-action='disconnect']": {
                        width: 'auto'
                    }
                },
                '& .user-container': {
                    '& .participant-name': {
                        padding: `${theme.spacing.sm} 0`
                    },
                    '& .user-avatar-container': {
                        marginTop: theme.spacing.xs
                    }
                },
                'button[data-action=disconnect] i.fa:after': {
                    display: 'none'
                }
            }
        }
    };
});
