import { ContactImportData } from './ContactImportData';

const vCardParser = require('vcard-parser');

export class VCard {
    static parse(str: string): ContactImportData[] {
        const card = vCardParser.parse(str);
        const dataArr: ContactImportData[] = [];
        for (let i = 0; i < card.fn.length; ++i) {
            const data: ContactImportData = {
                name: this.readValue(card.fn, i)!,
                phone: this.readValue(card.tel, i),
                email: this.readValue(card.email, i),
                address: this.readValue(card.adr, i),
                note: this.readValue(card.note, i)
            };
            dataArr.push(data);
        }
        return dataArr;
    }

    static readValue(obj: any, idx: number): string | undefined {
        let val = obj?.[idx]?.value;
        if (typeof val === 'string') {
            return val.trim();
        }
        if (Array.isArray(val)) {
            val = val
                .filter((x) => typeof x === 'string' && x.trim().length > 0)
                .map((x) => x.trim())
                .join(' ');
            return val.trim();
        }
        return undefined;
    }
}
