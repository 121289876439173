import { useEffect, useMemo, useRef, Fragment } from 'react';
import { api } from '../../api/Api';
import { useAppSelector } from '../../store';
import { selectAutoMarkAsRead } from '../../store/UserPreferenceSlice';
import { ChatId, MeetingId, Message, MessageId } from '../../types/Types';
import Empty from '../Empty';
import Loading from '../Loading';
import MessageView from './MessageView';
import { useTranslation } from 'react-i18next';
import { useGetFirstUnreadMessageId } from '../../hooks/useIsUnread';
import { createStyles } from '@mantine/core';
import { MessageGroupping } from './MessageGroupping';
import { selectCurrentUserUsername } from '../../store/CurrentUserSlice';

export default function MessagesView(props: {
    loadingMessages: boolean;
    messages: Message[];
    hasAccess: boolean;
    chatId?: ChatId;
    meetingId?: MeetingId;
    highlightedMessageIds?: MessageId[];
    scrollToMessageId?: MessageId;
}) {
    const currentUser = useAppSelector(selectCurrentUserUsername);
    const { t } = useTranslation();
    const autoMarkAsRead = useAppSelector(selectAutoMarkAsRead);
    const firstUnreadId = useGetFirstUnreadMessageId(props.messages);
    const unreadMarkerRef = useRef<HTMLDivElement>(null);
    const wasScrolled = useRef<boolean>(false);
    useEffect(() => {
        if (api.isAnonymousMeetingClient()) {
            return;
        }
        setTimeout(() => {
            if (!autoMarkAsRead) {
                return;
            }
            if (props.chatId) {
                api.getUnreadService()?.markAllMessagesInThreadAsRead(props.chatId);
            }
            if (props.meetingId) {
                api.getUnreadService()?.markAllMessagesInThreadAsRead(props.meetingId);
            }
        }, 2500);
    }, [props.chatId, props.meetingId, props.messages.length, autoMarkAsRead]);
    useEffect(() => {
        setTimeout(() => {
            if (wasScrolled.current) {
                return;
            }
            wasScrolled.current = true;
            unreadMarkerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 0);
    }, []);
    const [subsequentGrouppedMessageIds, lastGrouppedMessageIds] = useMemo(
        () => MessageGroupping.getSubsequentGrouppedMessageIds(props.messages),
        [props.messages]
    );

    const lastUserMessageId = useMemo(() => {
        let lastMessage: Message | undefined = undefined;

        for (let i = props.messages.length; i >= 0; i--) {
            const message = props.messages[i - 1];

            if (message?.author === currentUser) {
                lastMessage = { ...message };
                break;
            }
        }

        if (lastMessage) {
            return lastMessage.id;
        } else {
            return '';
        }
    }, [currentUser, props.messages]);

    return props.loadingMessages ? (
        <Loading info={t('progress.loadingMessages')} />
    ) : (
        <>
            {!props.hasAccess && <Empty info={t('errorMessage.noThreadAccess')} />}
            {props.hasAccess && props.messages.length === 0 && (
                <Empty info={t('infoMessage.noMessages')} />
            )}
            {props.hasAccess &&
                props.messages.length > 0 &&
                props.messages.map((x) => (
                    <Fragment key={x.msgId || x.id}>
                        {x.id === firstUnreadId && (
                            <div ref={unreadMarkerRef}>
                                <UnreadMarker />
                            </div>
                        )}
                        <MessageView
                            message={x}
                            chatId={props.chatId}
                            meetingId={props.meetingId}
                            canBeEdited={x.id === lastUserMessageId}
                            highlighted={
                                props.highlightedMessageIds &&
                                props.highlightedMessageIds.includes(x.msgId as string as MessageId)
                            }
                            scrollIntoView={
                                props.scrollToMessageId &&
                                props.scrollToMessageId === (x.msgId as string as MessageId)
                            }
                            isSubsequentGrouppedMessage={subsequentGrouppedMessageIds.includes(
                                x.msgId
                            )}
                            isLast={lastGrouppedMessageIds.includes(x.msgId)}
                        />
                    </Fragment>
                ))}
        </>
    );
}

const useUnreadMarkerStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    line: {
        height: '2px',
        background: theme.colors['brand'][4],
        flex: '1 1 auto'
    },
    text: {
        flex: '0 0 auto',
        textTransform: 'uppercase',
        fontSize: theme.fontSizes.xs,
        fontWeight: 500,
        padding: `0 ${theme.spacing.xs}`,
        color: theme.colors['brand'][6]
    }
}));

function UnreadMarker() {
    const { t } = useTranslation();
    const { classes } = useUnreadMarkerStyles();
    return (
        <div className={`${classes.container} first-unread-message-marker`}>
            <div className={classes.line}></div>
            <div className={classes.text}>{t('entityProps.new')}</div>
            <div className={classes.line}></div>
        </div>
    );
}
