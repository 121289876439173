import * as privmx from 'privfs-client';
import { ChallengeModel, TwofaApi, TwofaEnableData } from './TwofaApi';
import * as base32 from 'thirty-two';
import { PrivmxConst } from './PrivmxConst';
import { t } from 'i18next';

export class TwofaService {
    private twofaApi = new TwofaApi(this.gateway);

    constructor(private gateway: privmx.gateway.RpcGateway) {}

    getData() {
        return this.twofaApi.getData();
    }

    disable() {
        return this.twofaApi.disableWithChallenge();
    }

    enable(data: TwofaEnableData) {
        return this.twofaApi.enable(data);
    }

    challenge(model: ChallengeModel) {
        return this.twofaApi.challenge(model);
    }

    resendCode(): Promise<void> {
        return this.twofaApi.resendCode();
    }

    generateGoogleAuthenticatorKey() {
        const key = privmx.crypto.service.randomBytes(20);
        const encoded = base32.encode(key);
        return this.formatGoogleAuthenticatorKey(encoded.toString('utf8').replace(/=/g, ''));
    }

    formatGoogleAuthenticatorKey(key: string): string {
        return key
            .toLowerCase()
            .replace(/\W+/g, '')
            .replace(/=/g, '')
            .replace(/(\w{4})/g, '$1 ')
            .trim();
    }

    getGoogleAuthenticatorKeyUri(host: string, username: string, key: string) {
        const encodedKey = key.toUpperCase().replace(/\W+/g, '');
        return (
            'otpauth://totp/' +
            host +
            ':' +
            username +
            '?secret=' +
            encodedKey +
            '&issuer=' +
            host +
            '&algorithm=SHA1&digits=6&period=30'
        );
    }

    static getTwofaErrorMessage(error: any) {
        const errorCode = error?.data?.error?.code;
        if (errorCode === PrivmxConst.TWOFA_INVALID_CODE) {
            return t('twofa.error.invalidCode');
        } else if (errorCode === PrivmxConst.TWOFA_CODE_ALREADY_USED) {
            return t('twofa.error.codeAlreadyUsed');
        } else if (errorCode === PrivmxConst.TWOFA_VERIFICATION_FAILED) {
            return t('twofa.error.verificationFailed');
        } else if (error.msg) {
            return error.msg;
        } else {
            return t('twofa.error.unexpectedError');
        }
    }
}
