import {
    Checkbox,
    Flex,
    Grid,
    Group,
    MediaQuery,
    ScrollArea,
    Skeleton,
    createStyles
} from '@mantine/core';
import RouteListContainer from '../../atoms/RouteScreen/RouteListContainer';
import HoverContainer from '../../mantineAtoms/HoverContainer';
import { ThreadsListActionsBarLoader } from '../../mantineAtoms/ThreadsListActionsBar';
import ScreenScrollContainer from '../../mantineAtoms/ScreenScrollContainer';

const useLoadingStyles = createStyles((theme) => {
    return {
        listElement: {
            margin: 0,
            padding: theme.spacing.xs,
            borderBottom: `1px solid ${
                theme.colorScheme === 'dark'
                    ? theme.fn.rgba(theme.colors.dark[4], 0.5)
                    : theme.fn.rgba(theme.colors.gray[4], 0.5)
            }`
        },
        scroll: {
            flexGrow: 1
        }
    };
});

export default function LoadingThreadsList() {
    const { classes } = useLoadingStyles();

    return (
        <RouteListContainer>
            <ThreadsListActionsBarLoader withIcons buttons={<Skeleton h={30} w={102} />} />
            <ScreenScrollContainer className={classes.scroll}>
                {new Array(20).fill('').map((_, i) => (
                    <HoverContainer key={i} withBorder={false} className={classes.listElement}>
                        <Grid align="center" gutter={'sm'} miw={0} sx={{ flexShrink: 1 }}>
                            <Grid.Col span={8} md={6} lg={3} sx={{ overflow: 'hidden' }}>
                                <Group h={24} noWrap>
                                    <Checkbox
                                        styles={{
                                            inner: {
                                                width: 16,
                                                height: 16
                                            },
                                            input: {
                                                width: 16,
                                                height: 16
                                            }
                                        }}
                                        disabled
                                    />
                                    <Skeleton height={13} width={180}></Skeleton>
                                </Group>
                            </Grid.Col>
                            <MediaQuery largerThan={'lg'} styles={{ display: 'block' }}>
                                <Grid.Col span={4} md={4} display={'none'}>
                                    <Skeleton bg="gray.1" height="16px" width="100%" radius="sm" />
                                </Grid.Col>
                            </MediaQuery>
                            <MediaQuery largerThan={'md'} styles={{ display: 'block' }}>
                                <Grid.Col span={1} display={'none'}>
                                    <Group noWrap spacing={4}>
                                        {new Array(3).fill('').map((_, i) => (
                                            <Skeleton circle height={18} key={i} />
                                        ))}
                                    </Group>
                                </Grid.Col>
                            </MediaQuery>
                            <MediaQuery largerThan={'md'} styles={{ display: 'block' }}>
                                <Grid.Col span={2} display={'none'}>
                                    <Group noWrap spacing={4}>
                                        {new Array(3).fill('').map((_, i) => (
                                            <Skeleton visible height={14} w={40} key={i}></Skeleton>
                                        ))}
                                    </Group>
                                </Grid.Col>
                            </MediaQuery>
                            <MediaQuery largerThan={'md'} styles={{ display: 'block' }}>
                                <Grid.Col span={4} md={3} lg={2} display={'block'}>
                                    <Flex align="center" justify={'flex-end'} gap="xs">
                                        <Skeleton height={14} width={90} />
                                    </Flex>
                                </Grid.Col>
                            </MediaQuery>
                        </Grid>
                    </HoverContainer>
                ))}
            </ScreenScrollContainer>
        </RouteListContainer>
    );
}

export function LoadingMeetingList() {
    const { classes } = useLoadingStyles();

    return (
        <RouteListContainer>
            <ThreadsListActionsBarLoader buttons={<Skeleton h={30} w={102} />} />
            <ScreenScrollContainer sx={{ flexGrow: 1 }} pl="md" mt="xs">
                {new Array(20).fill('').map((_, i) => (
                    <HoverContainer key={i} withBorder={false} className={classes.listElement}>
                        <Grid align="center" gutter={'sm'} miw={0} sx={{ flexShrink: 1 }}>
                            <Grid.Col span={6} md={4} lg={3} sx={{ overflow: 'hidden' }}>
                                <Group h={24} noWrap>
                                    <Skeleton height={13} width={180}></Skeleton>
                                </Group>
                            </Grid.Col>
                            <MediaQuery largerThan={'lg'} styles={{ display: 'block' }}>
                                <Grid.Col span={6} md={4} display={'block'}>
                                    <Skeleton bg="gray.1" height="16px" width="100%" radius="sm" />
                                </Grid.Col>
                            </MediaQuery>
                            <MediaQuery largerThan={'md'} styles={{ display: 'block' }}>
                                <Grid.Col span={1} display={'none'}>
                                    <Group noWrap spacing={4}>
                                        {new Array(3).fill('').map((_, i) => (
                                            <Skeleton circle height={18} key={i} />
                                        ))}
                                    </Group>
                                </Grid.Col>
                            </MediaQuery>
                            <MediaQuery largerThan={'lg'} styles={{ display: 'block' }}>
                                <Grid.Col span={2} display={'none'}>
                                    <Group noWrap spacing={4}>
                                        {new Array(3).fill('').map((_, i) => (
                                            <Skeleton visible height={14} w={40} key={i}></Skeleton>
                                        ))}
                                    </Group>
                                </Grid.Col>
                            </MediaQuery>
                            <MediaQuery largerThan={'md'} styles={{ display: 'block' }}>
                                <Grid.Col span={4} md={3} lg={2} display={'none'}>
                                    <Flex align="center" justify={'flex-end'} gap="xs">
                                        <Skeleton height={14} width={90} />
                                    </Flex>
                                </Grid.Col>
                            </MediaQuery>
                        </Grid>
                    </HoverContainer>
                ))}
            </ScreenScrollContainer>
        </RouteListContainer>
    );
}

export function LoadingBookmarksList() {
    return (
        <RouteListContainer>
            <Skeleton height={40} width={160} mt={20} mb={15} ml={18} p={'xs'} />
            <ScrollArea sx={{ flexGrow: 1 }}>
                {new Array(5).fill('').map((_, i) => (
                    <HoverContainer key={i} withBorder={false} m={0} p={'xs'} mx={6}>
                        <Skeleton height={120} />
                    </HoverContainer>
                ))}
            </ScrollArea>
        </RouteListContainer>
    );
}
