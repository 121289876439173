import { Group, Text } from '@mantine/core';
import type * as threadTypes from '../../../../api/privmx/ThreadService';
import UserAvatar from '../../../../atoms/UserAvatar';
import { useTranslation } from 'react-i18next';
import { LastMessage, Message } from '../../../../types/Types';
import UserDisplayedName from '../../../../atoms/UserDisplayedName';

export interface UsersAddedMessageProps {
    message: Message | LastMessage;
    data: threadTypes.UsersAddedMessage;
}

export default function UsersAddedMessage(props: UsersAddedMessageProps) {
    const { t } = useTranslation();
    return (
        <>
            <Group spacing={4}>
                <Group spacing={4}>
                    <UserAvatar username={props.message.author}></UserAvatar>
                    <UserDisplayedName user={props.message.author} span color="dimmed" size="sm" />
                </Group>
                <Text color="dimmed" size="sm">
                    {t('successMessage.xGaveAccessToConversation.beforeUser')}
                </Text>
            </Group>
            {props.data.addedUsernames.map((addedUsername) => (
                <Group spacing={4} key={addedUsername}>
                    <UserAvatar
                        key={addedUsername}
                        className="userAvatar"
                        username={addedUsername}
                    />
                    <UserDisplayedName user={addedUsername} span color="dimmed" size="sm" />
                </Group>
            ))}
            <Text color="dimmed" size="sm">
                {t('successMessage.xGaveAccessToConversation.afterUser')}
            </Text>
        </>
    );
}
