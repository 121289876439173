import { Center, Container, Title, Text, Button } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { ViewMnemonic } from '../../components/ViewMnemonic';

export default function Mnemonic() {
    return (
        <Center h="100%" w="100%">
            <Container mx="auto" miw={500} size={'lg'}>
                <Title align="center" mb="md" size={60}>
                    MNEMONIC
                </Title>
                <Text align="center" mb="xl" mx="auto">
                    Keep your personal mnemonic somewhere safe, as it's necessary to{' '}
                    <strong>recover your account</strong> in case you lose your password.
                </Text>
                <ViewMnemonic />
                <Button component={Link} to="../activated" w={300} mx="auto" display={'block'}>
                    Next
                </Button>
            </Container>
        </Center>
    );
}
