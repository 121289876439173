import {
    Box,
    Checkbox,
    Grid,
    Group,
    Text,
    createStyles,
    getStylesRef,
    rem,
    Flex,
    Skeleton,
    MediaQuery
} from '@mantine/core';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import HoverContainer, { PmxHoverCardProps } from '../HoverContainer';
import { UsersList } from '../../screens/ChatScreen/components/UserList';
import { Tag, UsernameOrContactId } from '../../types/Types';
import { displayRelativeTime } from '../../utils/RelativeTimeFormat';
import { getHoverColor } from '../../MantineConfigs';
import { TagList } from '../TagList';
import { useLocalStorage } from '@mantine/hooks';

interface Props {
    title: React.ReactNode;
    usernames: UsernameOrContactId[];
    tags: Tag[];
    lastActive: number;
    description?: React.ReactNode;
    actionIcons: React.ReactNode;
    starIcon: React.ReactNode;
    value: string;
    archived: boolean;
    filePreview?: React.ReactNode;
    onTagSelect?: (newTags: string[]) => void;
    withCheckbox?: boolean;
    hasProblem?: false | string;
    type: 'meeting' | 'topic';
}

const useListStyles = createStyles((theme) => {
    return {
        root: {
            margin: 0,
            borderRadius: theme.radius.md,
            padding: `calc(${rem(theme.spacing.xs)})`,
            '&:hover': {
                backgroundColor: getHoverColor(theme),
                [`& .${getStylesRef('star')} .star`]: {
                    display: 'block'
                },
                [`& .${getStylesRef('icons')}, .${getStylesRef('icons')} .dots`]: {
                    display: 'flex',
                    width: 114,
                    justifyContent: 'flex-end'
                },
                [`& .${getStylesRef('date')}`]: {
                    display: 'none'
                }
            }
        },
        icons: {
            ref: getStylesRef('icons'),
            display: 'none',
            alignItems: 'center',
            marginRight: -4,
            '& .dots': {
                marginLeft: theme.spacing.xs,
                display: 'none'
            }
        },
        star: {
            ref: getStylesRef('star'),
            '& .star': {
                display: 'none'
            }
        },
        date: {
            ref: getStylesRef('date')
        }
    };
});

export default function ListElement({
    title,
    usernames,
    tags,
    lastActive,
    description,
    actionIcons,
    value,
    starIcon,
    archived,
    filePreview,
    onTagSelect,
    withCheckbox,
    hasProblem,
    type,
    ...props
}: Props & PmxHoverCardProps & React.HTMLAttributes<HTMLDivElement>) {
    const { classes } = useListStyles();

    const [disableWarnings] = useLocalStorage({ key: 'disable-warnings' });

    function responsiveColSpan(span: number) {
        if (type === 'meeting') {
            return span - 2;
        } else {
            return span;
        }
    }

    const isIconDisabled = disableWarnings === undefined ? true : disableWarnings; // loading from local storage takes time - setting default value

    return (
        <HoverContainer className={classes.root} withBorder={false} {...props}>
            <Box sx={{ flexShrink: 1, minWidth: 0 }}>
                <Grid align="center" gutter={'sm'} miw={0} sx={{ flexShrink: 1 }}>
                    <Grid.Col
                        span={responsiveColSpan(8)}
                        md={responsiveColSpan(6)}
                        lg={3}
                        sx={{ overflow: 'hidden' }}>
                        <Group h={24} noWrap>
                            {withCheckbox && (
                                <Checkbox
                                    styles={{
                                        inner: {
                                            width: 16,
                                            height: 16
                                        },
                                        input: {
                                            width: 16,
                                            height: 16
                                        }
                                    }}
                                    value={value}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                            )}

                            <Text size={13} maw={200} truncate span opacity={archived ? 0.5 : 1}>
                                {hasProblem && !isIconDisabled && (
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            padding: '0 8px',
                                            marginRight: '8px',
                                            backgroundColor: 'red',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            borderRadius: '15px'
                                        }}
                                        title={hasProblem}>
                                        !
                                    </span>
                                )}
                                {title}
                            </Text>
                        </Group>
                    </Grid.Col>
                    <MediaQuery largerThan={'lg'} styles={{ display: 'block' }}>
                        <Grid.Col
                            span={type === 'meeting' ? 6 : 4}
                            md={4}
                            display={type === 'topic' ? 'none' : 'block'}>
                            <Text size={13} lineClamp={1} opacity={archived ? 0.5 : 1}>
                                {description}
                            </Text>
                        </Grid.Col>
                    </MediaQuery>
                    <MediaQuery largerThan={'md'} styles={{ display: 'block' }}>
                        <Grid.Col span={1} opacity={archived ? 0.5 : 1} display={'none'}>
                            <UsersList usernames={usernames} />
                        </Grid.Col>
                    </MediaQuery>
                    <MediaQuery
                        largerThan={type === 'meeting' ? 'lg' : 'md'}
                        styles={{ display: 'block' }}>
                        <Grid.Col span={2} opacity={archived ? 0.5 : 1} display={'none'}>
                            <TagList tags={tags} maxWidth={100} onTagSelect={onTagSelect} />
                        </Grid.Col>
                    </MediaQuery>
                    <MediaQuery largerThan={'md'} styles={{ display: 'block' }}>
                        <Grid.Col
                            pr={0}
                            span={4}
                            md={3}
                            lg={2}
                            display={type === 'topic' ? 'block' : 'none'}>
                            <Flex align="center" justify={'flex-end'} gap="xs">
                                {lastActive && (
                                    <Text
                                        size={13}
                                        color="dimmed"
                                        className={classes.date}
                                        align="right"
                                        sx={{ textWrap: 'nowrap' }}>
                                        {displayRelativeTime(lastActive)}
                                    </Text>
                                )}
                                <Box h={22} className={classes.icons}>
                                    {actionIcons}
                                </Box>
                                <div className={classes.star}>{starIcon}</div>
                            </Flex>
                        </Grid.Col>
                    </MediaQuery>
                </Grid>
                {filePreview && <MountWithLoader>{filePreview}</MountWithLoader>}
            </Box>
        </HoverContainer>
    );
}

function MountWithLoader({ children }: { children?: React.ReactNode }) {
    const [loaderVisible, setLoaderVisible] = useState(true);

    useLayoutEffect(() => {
        setTimeout(() => {
            setLoaderVisible(false);
        }, 500);
    }, [children]);

    useEffect(() => {
        return () => {
            setLoaderVisible(true);
        };
    }, []);

    if (!children) return <></>;

    return (
        <Grid mt={4} maw={`calc(100vw - ${rem(250)} - ${rem(32)})`} gutter={'md'} align="center">
            <Grid.Col pos="relative" span={4} offset={3}>
                {loaderVisible && (
                    <Box
                        display={'flex'}
                        pos="absolute"
                        inset={0}
                        sx={{ zIndex: 10, alignItems: 'center' }}>
                        <Skeleton width={'100%'} height="60%" />
                    </Box>
                )}
                {children}
            </Grid.Col>
        </Grid>
    );
}
