import * as PmxApi from 'privmx-server-api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Meeting, MeetingId, PubIdOpt, Username, UsernameOpt } from '../../types/Types';
import StateView from '../../atoms/State';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { Box, Button, Card, Flex, Group, ScrollArea, Stack, Text } from '@mantine/core';
import { api } from '../../api/Api';
import Icon, { iconRemoveFromList, iconSlideRight } from '../Icon';
import Loading from '../Loading';
import { UserAvatarX } from '../../atoms/UserAvatar';
import useToast from '../../hooks/useToast';
import { useTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import { useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { selectMeetingUserIds } from '../../store/MeetingUserIds';

interface RegularUserModel {
    type: 'regular';
    id: Username;
    displayedName: string;
}

interface AnonymousUserModel {
    type: 'anonymous';
    id: PmxApi.api.core.EccPubKey;
    displayedName: PmxApi.api.thread.MeetingNickname;
}

export type UserModel = RegularUserModel | AnonymousUserModel;

export function getUserModels(
    meetingState: Pick<PmxApi.api.thread.MeetingState, 'regularUsers' | 'anonymousUsers'>,
    lobby: PmxApi.api.thread.MeetingLobby,
    userIds: string[]
) {
    const users = api.getUsersSync();
    const regularUserIds = userIds.filter((x) => users.some((y) => y.username === x));

    const lobbyRegularUsers = lobby.lobbyUsers.filter(
        (x) => x.type === 'regular' && !api.isThreadMeetingLobbyUserExpired(x)
    ) as PmxApi.api.thread.MeetingRegularLobbyUser[];
    const lobbyAnonymousUsers = lobby.lobbyUsers.filter(
        (x) => x.type === 'anonymous' && !api.isThreadMeetingLobbyUserExpired(x)
    ) as PmxApi.api.thread.MeetingAnonymousLobbyUser[];
    const meetingRegularUsers = [...meetingState.regularUsers];
    const meetingAnonymousUsers = meetingState.anonymousUsers;
    for (const userId of regularUserIds) {
        if (!meetingRegularUsers.includes(userId as any)) {
            meetingRegularUsers.push(userId as any);
        }
    }

    const lobbyRegularUserModels: RegularUserModel[] = lobbyRegularUsers.map((x) => ({
        type: 'regular',
        id: x.username,
        displayedName: users.find((u) => u.username === x.username)?.name ?? x.username
    }));
    const lobbyAnonymousUserModels: AnonymousUserModel[] = lobbyAnonymousUsers.map((x) => ({
        type: 'anonymous',
        id: x.pub,
        displayedName: x.nickname
    }));
    const meetingRegularUserModels: RegularUserModel[] = meetingRegularUsers.map((x) => ({
        type: 'regular',
        id: x,
        displayedName: users.find((u) => u.username === x)?.name ?? x
    }));
    const meetingAnonymousUserModels: AnonymousUserModel[] = Object.entries(
        meetingAnonymousUsers
    ).map((x) => ({
        type: 'anonymous',
        id: x[0] as PmxApi.api.core.EccPubKey,
        displayedName: x[1].nickname
    }));

    return {
        lobby: {
            regular: lobbyRegularUserModels,
            anonymous: lobbyAnonymousUserModels
        },
        meeting: {
            regular: meetingRegularUserModels,
            anonymous: meetingAnonymousUserModels
        }
    };
}

export function MeetingLobbyManagementPage(props: { meetingId: MeetingId }) {
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForThreadMeetingWithLobby(props.meetingId), [props.meetingId])
    );
    return (
        <StateView state={state} retry={revalidate}>
            {(data) => <MeetingLobbyManagementPageView meeting={data.meeting} lobby={data.lobby} />}
        </StateView>
    );
}

export function MeetingLobbyManagementPageView(props: {
    meeting: Meeting;
    lobby: PmxApi.api.thread.MeetingLobby;
}) {
    const { t } = useTranslation();
    const userIds = useAppSelector(selectMeetingUserIds(props.meeting.id));
    const users = useMemo(
        () => getUserModels(props.meeting.meetingState, props.lobby, userIds),
        [props.meeting.meetingState, props.lobby, userIds]
    );

    const [isProcessing, setIsProcessing] = useState(false);
    const emitToast = useToast();
    const [, setForcedUpdateId] = useState(0);
    const forceUpdate = useCallback(() => setForcedUpdateId((x) => x + 1), []);

    useEffect(() => {
        const expiresAt = api.getNextThreadMeetingLobbyUserExpirationTime(props.lobby.lobbyUsers);
        if (expiresAt !== null) {
            const timeLeft = Date.now() - expiresAt;
            const timeoutId = window.setTimeout(() => {
                forceUpdate();
            }, timeLeft + 100);
            return () => {
                window.clearTimeout(timeoutId);
            };
        }
    }, [props.lobby, forceUpdate]);

    const handleAddUserToMeeting = useCallback(
        async (user: UserModel) => {
            setIsProcessing(true);
            try {
                if (user.type === 'regular') {
                    await api.addUserToThreadMeeting(props.meeting.id, user.id, false);
                } else {
                    await api.addAnonymousUserToThreadMeeting(props.meeting.id, user.id, false);
                }
                emitToast(i18next.t('successMessage.userAddedToMeeting'), 'success');
            } catch (e) {
                console.error(e);
                emitToast(i18next.t('errorMessage.couldNotAddUserToMeeting'), 'error');
            } finally {
                setIsProcessing(false);
            }
        },
        [emitToast, props.meeting.id]
    );

    const handleRemoveUserFromMeeting = useCallback(
        async (user: UserModel) => {
            setIsProcessing(true);
            try {
                if (user.type === 'regular') {
                    await api.removeUserFromThreadMeeting(props.meeting.id, user.id);
                } else {
                    await api.removeAnonymousUserFromThreadMeeting(props.meeting.id, user.id);
                }
                emitToast(i18next.t('successMessage.userRemovedFromMeeting'), 'success');
            } catch (e) {
                console.error(e);
                emitToast(i18next.t('errorMessage.couldNotRemoveUserFromMeeting'), 'error');
            } finally {
                setIsProcessing(false);
            }
        },
        [emitToast, props.meeting.id]
    );

    const handleRemoveUserFromLobby = useCallback(
        async (user: UserModel) => {
            setIsProcessing(true);
            try {
                if (user.type === 'regular') {
                    await api.removeUserFromThreadLobby(props.meeting.id, user.id);
                } else {
                    await api.removeAnonymousFromThreadLobby(props.meeting.id, user.id);
                }
                emitToast(i18next.t('successMessage.userRemovedFromLobby'), 'success');
            } catch (e) {
                console.error(e);
                emitToast(i18next.t('errorMessage.couldNotRemoveUserFromLobby'), 'error');
            } finally {
                setIsProcessing(false);
            }
        },
        [emitToast, props.meeting.id]
    );

    return (
        <Flex
            direction="column"
            maw={800}
            p="md"
            sx={{ height: '100%', flexGrow: 1 }}
            justify="space-between">
            <Stack>
                <UsersList
                    type="lobby"
                    title={`${t('entityProps.waitingInTheLobby')}:`}
                    regular={users.lobby.regular}
                    anonymous={users.lobby.anonymous}
                    isProcessing={isProcessing}
                    onAddUserToMeeting={handleAddUserToMeeting}
                    onRemoveUserFromLobby={handleRemoveUserFromLobby}
                />
                <UsersList
                    type="meeting"
                    title={`${t('entityProps.alreadyAtTheMeeting')}:`}
                    regular={users.meeting.regular}
                    anonymous={users.meeting.anonymous}
                    isProcessing={isProcessing}
                    onRemoveUserFromMeeting={handleRemoveUserFromMeeting}
                />
            </Stack>

            {isProcessing && (
                <Box pos="absolute" left={0} right={0} top="30%">
                    <Loading />
                </Box>
            )}
        </Flex>
    );
}

interface UsersListProps extends React.PropsWithChildren {
    type: 'lobby' | 'meeting';
    title: string;
    regular: RegularUserModel[];
    anonymous: AnonymousUserModel[];
    isProcessing: boolean;
    onAddUserToMeeting?: (user: UserModel) => void;
    onRemoveUserFromMeeting?: (user: UserModel) => void;
    onRemoveUserFromLobby?: (user: UserModel) => void;
}

export function UsersList(props: UsersListProps) {
    const { t } = useTranslation();
    return (
        <Card withBorder h="100%">
            <ScrollArea.Autosize h={'100%'}>
                <Text size="md">{props.title}</Text>
                {props.children && <Card>{props.children}</Card>}
                <Card>
                    <Text size="sm">{t('entityProps.registeredUsers')}:</Text>
                    {props.regular.length === 0 && (
                        <Text color="dimmed" size="sm">
                            ({t('noneLowercase')})
                        </Text>
                    )}
                    {props.regular.map((x) => (
                        <UserListElement
                            key={x.id}
                            user={x}
                            state={props.type}
                            isProcessing={props.isProcessing}
                            onAddUserToMeeting={props.onAddUserToMeeting}
                            onRemoveUserFromMeeting={props.onRemoveUserFromMeeting}
                            onRemoveUserFromLobby={props.onRemoveUserFromLobby}
                        />
                    ))}
                </Card>
                <Card>
                    <Text size="sm">{t('entityProps.unregisteredUsers')}:</Text>
                    {props.anonymous.length === 0 && (
                        <Text color="dimmed" size="sm">
                            ({t('noneLowercase')})
                        </Text>
                    )}
                    {props.anonymous.map((x) => (
                        <UserListElement
                            key={x.id}
                            user={x}
                            state={props.type}
                            isProcessing={props.isProcessing}
                            onAddUserToMeeting={props.onAddUserToMeeting}
                            onRemoveUserFromMeeting={props.onRemoveUserFromMeeting}
                            onRemoveUserFromLobby={props.onRemoveUserFromLobby}
                        />
                    ))}
                </Card>
            </ScrollArea.Autosize>
        </Card>
    );
}

interface UserListElementProps {
    user: UserModel;
    state: 'lobby' | 'meeting';
    isProcessing: boolean;
    onAddUserToMeeting?: (user: UserModel) => void;
    onRemoveUserFromMeeting?: (user: UserModel) => void;
    onRemoveUserFromLobby?: (user: UserModel) => void;
}

function UserListElement(props: UserListElementProps) {
    const { t } = useTranslation();
    const onAddUserToMeeting = props.onAddUserToMeeting;
    const onRemoveUserFromMeeting = props.onRemoveUserFromMeeting;
    const onRemoveUserFromLobby = props.onRemoveUserFromLobby;
    const currentUser = useAppSelector(selectCurrentUser);

    const handleAddUserToMeetingClick = useCallback(() => {
        if (onAddUserToMeeting) {
            onAddUserToMeeting(props.user);
        }
    }, [onAddUserToMeeting, props.user]);

    const handleRemoveUserFromMeetingClick = useCallback(() => {
        if (onRemoveUserFromMeeting) {
            onRemoveUserFromMeeting(props.user);
        }
    }, [onRemoveUserFromMeeting, props.user]);

    const handleRemoveUserFromLobbyClick = useCallback(() => {
        if (onRemoveUserFromLobby) {
            onRemoveUserFromLobby(props.user);
        }
    }, [onRemoveUserFromLobby, props.user]);

    const userId = useMemo(
        () =>
            props.user.type === 'regular'
                ? ({ type: 'user', username: props.user.id } as UsernameOpt)
                : ({ type: 'anonymousUser', pub: props.user.id } as PubIdOpt),
        [props.user.type, props.user.id]
    );
    const isCurrentUser =
        (props.user.type === 'anonymous' && props.user.id === currentUser.pub) ||
        (props.user.type === 'regular' && props.user.id === currentUser.username);

    return (
        <div>
            <Flex my="md" gap="xs" align={'center'}>
                {props.state === 'lobby' && onRemoveUserFromLobby && (
                    <Button
                        size="xs"
                        p="0px 8px"
                        title={t('action.removeUserFromLobby')}
                        onClick={handleRemoveUserFromLobbyClick}
                        disabled={props.isProcessing}>
                        <Icon icon={iconRemoveFromList} />
                    </Button>
                )}
                {props.state === 'meeting' && onRemoveUserFromMeeting && (
                    <Button
                        size="xs"
                        p="0px 8px"
                        title={t('action.removeUserFromMeteting')}
                        onClick={handleRemoveUserFromMeetingClick}
                        disabled={props.isProcessing}>
                        <Icon icon={iconRemoveFromList} />
                    </Button>
                )}
                <Group sx={{ flex: '1 1 auto', flexWrap: 'nowrap' }} miw={0} spacing="xs">
                    <UserAvatarX user={userId} />
                    <Text truncate title={props.user.displayedName}>
                        {props.user.displayedName} {isCurrentUser && t('meeting.youIndicator')}
                    </Text>
                </Group>
                {props.state === 'lobby' && onAddUserToMeeting && (
                    <Button
                        size="xs"
                        p="0px 8px"
                        title={t('action.addUserToMeeting')}
                        onClick={handleAddUserToMeetingClick}
                        disabled={props.isProcessing}>
                        <Icon icon={iconSlideRight} />
                        <Text ml="xs">{t('action.letIn')}</Text>
                    </Button>
                )}
            </Flex>
        </div>
    );
}

export default MeetingLobbyManagementPage;
