import { BadgeProps, Badge, Box, rem, ActionIcon, Tooltip } from '@mantine/core';
import { IconPlus, IconX } from '@tabler/icons-react';
import { MouseEventHandler, useCallback } from 'react';
import { Company } from '../../types/Types';
import { useNavigate } from 'react-router-dom';
import { CompanyPopoverSelect } from '.';
import { useCompanyBadgeStyles } from './styles';

export function CompanyBadge({
    onDelete,
    children,
    companyId,
    onSelect,
    onCreate,
    className,
    onClick,
    tooltip,
    ...props
}: BadgeProps & {
    onDelete?: MouseEventHandler<HTMLButtonElement>;
    companyId?: Company['id'];
    onSelect?: (id: string) => void;
    onCreate?: (query: string) => void;
    onClick?: MouseEventHandler<HTMLDivElement>;
    tooltip?: string;
}) {
    const navigate = useNavigate();
    const { classes, cx } = useCompanyBadgeStyles();
    const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            if (!companyId) return;
            e.stopPropagation();
            navigate(`/companies/${companyId}`);
        },
        [companyId, navigate]
    );

    if (onSelect && onCreate) {
        return (
            <CompanyPopoverSelect
                onCompanyCreate={onCreate}
                onCompanySelect={onSelect}
                target={(onClick) => (
                    <Badge
                        pl={8}
                        leftSection={<Box component={IconPlus} display={'block'} size={rem(10)} />}
                        radius={'xs'}
                        variant="outline"
                        classNames={{ root: cx(classes.root, className) }}
                        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                            e.stopPropagation();
                            onClick();
                        }}
                        {...props}>
                        Add company
                    </Badge>
                )}
            />
        );
    }

    return (
        <Tooltip openDelay={500} label={tooltip} disabled={!tooltip}>
            <Badge
                radius={'xs'}
                variant="outline"
                pr={onDelete ? 3 : undefined}
                onClick={onClick || handleClick}
                classNames={{ root: cx(classes.root, className) }}
                rightSection={
                    onDelete ? (
                        <ActionIcon
                            size="xs"
                            radius="xl"
                            variant="transparent"
                            className={classes.removeButton}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                onDelete(e);
                            }}>
                            <IconX size={rem(10)} />
                        </ActionIcon>
                    ) : undefined
                }
                {...props}>
                {children}
            </Badge>
        </Tooltip>
    );
}
