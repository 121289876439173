import { t } from 'i18next';

export function resolveLoginErrorMessage(error: unknown) {
    const msg = typeof (error as any)?.message === 'string' ? (error as any)?.message : '';
    if (msg.includes('User blocked')) {
        return t('loginError.accountBlocked');
    } else {
        return t('errorMessage.incorrectLoginData');
    }
}
