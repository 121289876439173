import { api } from '../../../api/Api';
import { useCallback, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useAppSelector } from '../../../store';
import { selectCurrentUser } from '../../../store/CurrentUserSlice';
import { FavoritesUpdatedEvent, Message } from '../../../types/Types';
import { Deferred } from '../../../utils/Deferred';
import Icon, { iconUnread } from '../../Icon';
import { getVerificationInfo } from '../MessageView';
import { ActionIcon, Group, Loader, rem, ThemeIcon, Tooltip } from '@mantine/core';
import { selectAutoMarkAsRead } from '../../../store/UserPreferenceSlice';
import { useTranslation } from 'react-i18next';
import { IconBookmark, IconBookmarkFilled, IconPencil, IconTrash } from '@tabler/icons-react';
import { useStateView } from '../../../atoms/State';
import { useEditingStore } from '../../SendMessageView/editingStore';

interface MessageControlsProps {
    message: Message;
    setDeleteModal: Dispatch<SetStateAction<Deferred<boolean> | undefined>>;
    canBeEdited: boolean;
}

export default function MessageControls({
    message,
    setDeleteModal,
    canBeEdited
}: MessageControlsProps) {
    const { t } = useTranslation();
    const currentUser = useAppSelector(selectCurrentUser);
    const autoMarkAsRead = useAppSelector(selectAutoMarkAsRead);
    const startEditing = useEditingStore((state) => state.startEditing);

    const { revalidate } = useStateView();

    const verificationInfo = getVerificationInfo(message.verified);

    const [favorites, setFavorites] = useState(message.favorite);
    useEffect(() => {
        setFavorites(message.favorite);
    }, [message.favorite]);
    useEffect(() => {
        const listener = (event: FavoritesUpdatedEvent) => {
            setFavorites(event.favorites.some((y) => y.messageId === message.id));
        };
        api.addEventListener('favoritesupdated', listener);
        return () => {
            api.removeEventListener('favoritesupdated', listener);
        };
    }, [message.id]);

    const handleEditMessageButtonClick = useCallback(() => {
        startEditing(message.text, message.id, message.attachments);
    }, [message, startEditing]);

    const handleDeleteMessageButtonClick = useCallback(() => {
        const deferred = new Deferred<boolean>();
        setDeleteModal(deferred);
    }, [setDeleteModal]);

    const handleFavoriteClick = useCallback(() => {
        if (message.id !== undefined) {
            if (favorites) {
                api.removeFavorite({ messageId: message.id });
            } else {
                api.addFavorite({ messageId: message.id });
            }
        }
        setFavorites(!favorites);
        api.getThreadMessages(message.threadId);
        revalidate();
        api.getFavoriteChatMessages();
    }, [message.id, favorites, message.threadId, revalidate]);

    const handleToggleMessageReadClick = useCallback(() => {
        const unreadService = api.getUnreadService();
        if (!unreadService) {
            return;
        }
        if (unreadService.isThreadMessageRead(message)) {
            unreadService.markMessageAsUnread(message);
        } else {
            unreadService.markMessageAsRead(message);
        }
    }, [message]);

    const showEditIcon = !message.deleted && message.author === currentUser.username && canBeEdited;

    return (
        <>
            <Group spacing={'sm'}>
                {!message.deleted && (
                    <>
                        {!message.deleted && message.author === currentUser.username && (
                            <ActionIcon
                                color="red"
                                size={18}
                                onClick={handleDeleteMessageButtonClick}>
                                <IconTrash size={rem(18)} />
                            </ActionIcon>
                        )}
                        {showEditIcon && (
                            <>
                                <ActionIcon size={18} onClick={handleEditMessageButtonClick}>
                                    <IconPencil size={rem(18)} />
                                </ActionIcon>
                            </>
                        )}

                        {!autoMarkAsRead && message.author !== currentUser.username && (
                            <ActionIcon
                                size={18}
                                title={t('action.toggleRead')}
                                onClick={handleToggleMessageReadClick}>
                                <Icon icon={iconUnread} />
                            </ActionIcon>
                        )}

                        <ActionIcon
                            onClick={handleFavoriteClick}
                            size={18}
                            display="flex"
                            color={favorites ? 'yellow.5' : 'gray.6'}
                            data-favorite={favorites}>
                            {favorites ? (
                                <IconBookmarkFilled size={rem(18)} />
                            ) : (
                                <IconBookmark size={rem(18)} />
                            )}
                        </ActionIcon>
                    </>
                )}
                {message.verified !== 'verified' && (
                    <div className="verification-info">
                        <Tooltip label={verificationInfo.info}>
                            <ThemeIcon variant="transparent">
                                <Loader size={12} />
                            </ThemeIcon>
                        </Tooltip>
                    </div>
                )}
            </Group>
        </>
    );
}
