import { api } from 'privmx-server-api';
import { Utils } from './Utils';
import { t } from 'i18next';

export class MaxFileSizeError extends Error {
    constructor(public maxFileSizeB: number, public fileSizeB: number) {
        super(t('errorMessage.maxFileSize', { maxFileSize: Utils.bytesSize(maxFileSizeB) }));
    }
}

export class FileChooser {
    static async readBlobAsText(blob: Blob) {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsText(blob, 'UTF-8');
            reader.onload = (readerEvent) => {
                const text = reader.result;
                if (typeof text === 'string') {
                    resolve(text);
                } else {
                    reject(new Error('Invalid reader result type'));
                }
            };
            reader.onerror = (e) => {
                reject(e);
            };
        });
    }

    static async chooseFiles(fileType?: string): Promise<File[]> {
        return new Promise<File[]>((resolve) => {
            const input = document.createElement('input');
            if (fileType) {
                input.setAttribute('accept', fileType);
            }
            input.type = 'file';
            input.multiple = true;
            setTimeout(() => {
                // Hack for Safari - input.onchange sometimes wasn't called
                // Seems like keeping a reference to the input for a moment solves the problem
                if (input.files?.length === 237474) {
                    console.log(input);
                }
            }, 1000);
            input.onchange = async () => {
                resolve([...(input.files || [])]);
            };
            input.click();
        });
    }

    static async chooseFile(fileType?: string, maxFileSizeB?: number): Promise<File | null> {
        return new Promise((resolve, reject) => {
            const input = document.createElement('input');
            if (fileType) {
                input.setAttribute('accept', fileType);
            }
            input.type = 'file';
            input.onchange = (e: any) => {
                const file = input.files ? input.files[0] : null;
                if (typeof maxFileSizeB === 'number') {
                    if (file && file.size > maxFileSizeB) {
                        reject(new MaxFileSizeError(maxFileSizeB, file.size));
                        return;
                    }
                }
                resolve(file ? file : null);
            };
            input.click();
        });
    }

    static checkFilesConfig<T extends { size: number }>(
        filesData: T[],
        config: api.request.RequestConfig
    ) {
        if (filesData.length > config.maxFilesCount) {
            throw new Error(`Max. number of attachments ${config.maxFilesCount} exceeded.`);
        }

        for (const attachment of filesData) {
            if (attachment.size > config.maxFileSize) {
                throw new Error(`Max. file size ${Utils.bytesSize(config.maxFileSize)} exceeded.`);
            }
        }

        const filesSize: number = filesData.reduce((reducedSize, file) => {
            return reducedSize + file.size;
        }, 0);

        if (filesSize > config.maxRequestSize) {
            throw new Error(
                `Total max. attachment size ${Utils.bytesSize(config.maxRequestSize)} exceeded.`
            );
        }
    }
}
