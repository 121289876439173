import * as privmx from 'privfs-client';
import { EncKey } from './KeyProvider';

export class DataEncryptor<DataType, Serialized extends string, TKey extends EncKey = EncKey> {
    async encrypt(data: DataType, key: TKey) {
        const cipher = await privmx.crypto.service.privmxEncrypt(
            privmx.crypto.service.privmxOptAesWithSignature(),
            Buffer.from(JSON.stringify(data), 'utf8'),
            key.key
        );
        return cipher.toString('base64') as Serialized;
    }

    async decrypt(data: Serialized, key: TKey) {
        return JSON.parse(
            (
                await privmx.crypto.service.privmxDecrypt(
                    privmx.crypto.service.privmxOptSignedCipher(),
                    Buffer.from(data, 'base64'),
                    key.key
                )
            ).toString('utf8')
        ) as DataType;
    }
}
