import {
    Box,
    Badge,
    rem,
    Paper,
    ScrollArea,
    Table,
    Text,
    Button,
    Group,
    ActionIcon,
    Sx,
    Tooltip
} from '@mantine/core';
import { IconFile, IconTrash, IconUserPlus } from '@tabler/icons-react';
import { useState, useMemo, useCallback } from 'react';
import { Contact, Email, FormModelEntries, FormRow } from '../../../types/Types';
import { FormResponseDetail } from './FormResponseDetail';
import { useFormTableStyles } from './styles';
import { useFormCreatorContext } from '../FormCreator/components/FormState/useFormState';
import { EmptyState } from '../../../mantineAtoms/EmptyState/EmptyState';
import { Link } from 'react-router-dom';
import { IconMail } from '@tabler/icons-react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectCurrentUser } from '../../../store/CurrentUserSlice';
import { UserUtils } from '../../../utils/UserUtils';
import { api } from '../../../api/Api';
import { toggleNewMessageModal } from '../../../store/ModalsSlice';
import { loadContacsAsync } from '../../../store/DataCacheSlice';
import { AnswerableBlock } from '../FormCreator/types';
import { selectUsers } from '../../../store/UsersSlice';
import {
    canCreateContactFromAnswer,
    getFirstMessage,
    handleCreateContact,
    createTopicFromResponse
} from '.';
import { useTranslation } from 'react-i18next';

const responseActionIconSx: Sx = (theme) => {
    return {
        border: 0,
        '&:hover': {
            color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[7],
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }
    };
};

export function FormResponseView({
    form,
    contacts
}: {
    form: FormModelEntries;
    contacts: Contact[];
}) {
    const { theme } = useFormTableStyles();
    const { formModel } = useFormCreatorContext();
    const users = useAppSelector(selectUsers);
    const currentUser = useAppSelector(selectCurrentUser);
    const accountEmails = useMemo(() => {
        const emails = users.map((x) => x.email);
        return emails;
    }, [users]);

    const [previewedResponse, setPreviewedResponse] = useState<string | null>(null);
    const selectedResposne = useMemo(() => {
        if (previewedResponse === null) return null;
        return form.answers.find((x) => x.id === previewedResponse) || null;
    }, [previewedResponse, form.answers]);

    const canCreateContacts = currentUser.role === 'staff';
    const canCreateTopics = currentUser.role === 'staff';
    const canDeleteResponses =
        currentUser.username !== null && form.managers.includes(currentUser.username);

    if (selectedResposne !== null) {
        return (
            <FormResponseDetail
                response={selectedResposne}
                form={form}
                closeDetail={() => setPreviewedResponse(null)}
            />
        );
    }

    const rows = form.answers.map((responseRow) => {
        return (
            <FormResponseRow
                key={responseRow.id}
                responseRow={responseRow}
                formModel={formModel}
                contacts={contacts}
                accountEmails={accountEmails}
                canCreateContacts={canCreateContacts}
                canCreateTopics={canCreateTopics}
                canDeleteResponses={canDeleteResponses}
                setPreviewedResponse={setPreviewedResponse}
            />
        );
    });

    const formQuestionsWithAnswers = form.questions.filter(
        (x) => x.type !== 'block'
    ) as AnswerableBlock[];

    if (form.answers.length === 0) {
        return (
            <Paper h="calc(100% - 130px)" m="lg" p="md" radius="md" pos="relative">
                <EmptyState
                    image="NO_FORM_RESPONSES"
                    title="No answers yet"
                    subTitle="Share the Form with a link or embed it on your website"
                    button={
                        <Button component={Link} to="../share">
                            Share Form
                        </Button>
                    }
                />
            </Paper>
        );
    }

    return (
        <>
            <Paper h="calc(100% - 130px)" m="lg" p="md" radius="md" pos="relative">
                <ScrollArea w="100%" h="100%" offsetScrollbars>
                    <Box pos="sticky" top={0} bg={theme.colorScheme === 'dark' ? 'dark' : 'white'}>
                        <Table highlightOnHover>
                            <thead>
                                <tr>
                                    {formQuestionsWithAnswers.map((question) => (
                                        <Box
                                            key={question.id}
                                            component="th"
                                            w="min-content"
                                            sx={{ textWrap: 'nowrap' }}>
                                            {question.title}
                                        </Box>
                                    ))}
                                    <Box
                                        component="th"
                                        w="min-content"
                                        sx={{ textWrap: 'nowrap' }}></Box>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    </Box>
                </ScrollArea>
            </Paper>
        </>
    );
}

interface FormResponseRowProps {
    responseRow: FormRow;
    formModel: ReturnType<typeof useFormCreatorContext>['formModel'];
    contacts: Contact[];
    accountEmails: Email[];
    canCreateContacts: boolean;
    canCreateTopics: boolean;
    canDeleteResponses: boolean;
    setPreviewedResponse: (response: string | null) => void;
}

function FormResponseRow(props: FormResponseRowProps) {
    const [isDeleting, setIsDeleting] = useState(false);

    const setPreviewedResponse = props.setPreviewedResponse;
    const handleClick = useCallback(() => {
        setPreviewedResponse(props.responseRow.id);
    }, [props.responseRow.id, setPreviewedResponse]);

    const handleDeleteClick = useCallback(
        async (evt: React.MouseEvent) => {
            evt.preventDefault();
            evt.stopPropagation();
            setIsDeleting(true);
            try {
                await api.removeFormAnswer(props.responseRow.formId, [props.responseRow.id]);
            } finally {
                setIsDeleting(false);
            }
        },
        [props.responseRow.id, props.responseRow.formId]
    );

    const formQuestionsWithAnswers = props.formModel.questions.filter(
        (x) => x.type !== 'block'
    ) as AnswerableBlock[];

    return (
        <Box
            component="tr"
            sx={{ cursor: 'pointer' }}
            key={props.responseRow.id}
            onClick={handleClick}>
            {formQuestionsWithAnswers.map((modelQuestion) => {
                return (
                    <FormResponseCell
                        key={modelQuestion.id}
                        modelQuestion={modelQuestion}
                        responseRow={props.responseRow}
                        contacts={props.contacts}
                        accountEmails={props.accountEmails}
                        canCreateContacts={props.canCreateContacts}
                        canCreateTopics={props.canCreateTopics}
                        canDeleteResponses={props.canDeleteResponses}
                    />
                );
            })}
            <td>
                <Group spacing={0} className="dots" position="right" align="center">
                    {props.canDeleteResponses && (
                        <Tooltip label="Delete">
                            <ActionIcon
                                component="div"
                                onClick={handleDeleteClick}
                                variant="outline"
                                loading={isDeleting}
                                sx={responseActionIconSx}>
                                <IconTrash size={16} />
                            </ActionIcon>
                        </Tooltip>
                    )}
                </Group>
            </td>
        </Box>
    );
}

interface FormResponseCellProps {
    responseRow: FormRow;
    modelQuestion: AnswerableBlock;
    contacts: Contact[];
    accountEmails: Email[];
    canCreateContacts: boolean;
    canCreateTopics: boolean;
    canDeleteResponses: boolean;
}

function FormResponseCell(props: FormResponseCellProps) {
    const dispatch = useAppDispatch();
    const { classes } = useFormTableStyles();
    const { formModel } = useFormCreatorContext();

    const submitedAnswer = props.responseRow.answers.find(
        (answer) => answer.id === props.modelQuestion.id
    );
    const { t } = useTranslation();

    const [isCreatingContact, setIsCreatingContact] = useState(false);
    const [isCreatingTopic, setIsCreatingTopic] = useState(false);

    const handleCreateContactClick = useCallback(
        async (evt: React.MouseEvent) => {
            setIsCreatingContact(true);
            await handleCreateContact(evt, submitedAnswer, props.contacts, props.accountEmails);
            setIsCreatingContact(false);
        },
        [submitedAnswer, props.contacts, props.accountEmails]
    );

    const staffMembers = useAppSelector(selectUsers);

    const handleCreateTopicClick = useCallback(
        async (evt: React.MouseEvent) => {
            setIsCreatingTopic(true);

            const contactForTopic = await createTopicFromResponse(
                evt,
                submitedAnswer,
                props.contacts,
                staffMembers
            );
            if (contactForTopic) {
                await dispatch(loadContacsAsync());
                dispatch(
                    toggleNewMessageModal({
                        open: true,
                        payload: {
                            users: [UserUtils.userUnionToUsernameOrContactId(contactForTopic)],
                            firstMessage: getFirstMessage(
                                props.responseRow.answers,
                                formModel.questions
                            )
                        }
                    })
                );
            }
            setIsCreatingTopic(false);
        },
        [
            dispatch,
            props.contacts,
            submitedAnswer,
            staffMembers,
            props.responseRow.answers,
            formModel.questions
        ]
    );

    const canCreateAccount =
        submitedAnswer &&
        props.canCreateContacts &&
        canCreateContactFromAnswer(submitedAnswer, props.contacts, props.accountEmails);

    if (!submitedAnswer || submitedAnswer.answer.length === 0) {
        return (
            <Box component="td" key={props.modelQuestion.id}>
                <Text color="dimmed" size="sm">
                    No answer
                </Text>
            </Box>
        );
    }

    if (submitedAnswer.type === 'select' || submitedAnswer.type === 'single') {
        return (
            <Box component="td" className={classes.tableCell} key={submitedAnswer.id}>
                {submitedAnswer.answer.map((x) => (
                    <Badge
                        size="xs"
                        variant="outline"
                        color={'gray'}
                        opacity={0.9}
                        mr="xs"
                        key={x.id + x.input}>
                        {x.input}
                    </Badge>
                ))}
            </Box>
        );
    } else if (submitedAnswer.type === 'file') {
        return (
            <Box component="td" className={classes.tableCell} key={submitedAnswer.id}>
                {submitedAnswer.answer.map((x) => (
                    <Badge
                        leftSection={<IconFile size={rem(8)} />}
                        size="xs"
                        variant="outline"
                        color={'gray'}
                        opacity={0.9}
                        mr="xs"
                        key={x.id + x.input}>
                        {x.input}
                    </Badge>
                ))}
            </Box>
        );
    } else {
        return (
            <td key={submitedAnswer.id}>
                <Group spacing={0}>
                    <Text
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                        truncate="end"
                        maw={400}
                        lineClamp={2}>
                        {submitedAnswer.answer.map((x) => x.input)}
                    </Text>
                    {(submitedAnswer.type === 'short' || submitedAnswer.type === 'email') && (
                        <>
                            {canCreateAccount && (
                                <Tooltip label={t('screen.forms.formResponses.createContact')}>
                                    <ActionIcon
                                        component="div"
                                        onClick={handleCreateContactClick}
                                        variant="outline"
                                        loading={isCreatingContact}
                                        sx={responseActionIconSx}>
                                        <IconUserPlus size={16} />
                                    </ActionIcon>
                                </Tooltip>
                            )}
                            {props.canCreateTopics &&
                                canCreateContactFromAnswer(submitedAnswer, [], []) && (
                                    <Tooltip
                                        label={
                                            canCreateAccount
                                                ? t(
                                                      'screen.forms.formResponses.createContactAndTopic'
                                                  )
                                                : t('screen.forms.formResponses.createTopic')
                                        }>
                                        <ActionIcon
                                            component="div"
                                            onClick={handleCreateTopicClick}
                                            variant="outline"
                                            loading={isCreatingTopic}
                                            sx={responseActionIconSx}>
                                            <IconMail size={16} />
                                        </ActionIcon>
                                    </Tooltip>
                                )}
                        </>
                    )}
                </Group>
            </td>
        );
    }
}
