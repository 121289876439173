import { ActionIcon, Box, Group, Text, TextInput, ThemeIcon, Tooltip, rem } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { QuestionModel, TextBlock } from '../../../../types/Types';
import { FormDispatch } from '../types';
import { IconCircle, IconSquare, IconX } from '@tabler/icons-react';
import { FullTextEditor } from '../../../../components/TextEditor/TextEditor';
import { useEffect, useRef } from 'react';
import { useTextEditorConfig } from '../../../../components/TextEditor/useTextEditorConfig';
import { useFormCreatorContext } from './FormState/useFormState';

export default function InputFactory(props: {
    question: QuestionModel;
    dispatch: FormDispatch;
    index: number;
}): JSX.Element {
    const { t } = useTranslation();

    const { errors } = useFormCreatorContext();

    const currentQuestionErrors = errors.filter((x) => x.questionIndex === props.index);

    switch (props.question.type) {
        case 'single':
        case 'select':
            const icon = (
                <ThemeIcon variant="outline" color="dark" sx={{ border: 0 }} size="xs" c="gray">
                    {props.question.type === 'single' ? (
                        <IconCircle size={rem(14)} />
                    ) : (
                        <IconSquare size={rem(14)} />
                    )}
                </ThemeIcon>
            );

            return (
                <>
                    {props.question.answer.map((x, i) => {
                        const answerErrors = currentQuestionErrors.find((x) => x.answerIndex === i);
                        return (
                            <Group key={i} mb="sm">
                                {icon}
                                <TextInput
                                    error={answerErrors?.message}
                                    styles={{
                                        input: {
                                            borderTop: 0,
                                            borderRight: 0,
                                            borderLeft: 0
                                        }
                                    }}
                                    radius={0}
                                    size="xs"
                                    onChange={(e) =>
                                        props.dispatch({
                                            type: 'change_option',
                                            index: props.index,
                                            optionIndex: i,
                                            newValue: e.target.value
                                        })
                                    }
                                    value={x.input}
                                    placeholder={t('screen.formCreator.placeholders.option')}
                                />
                                <Tooltip label="Delete this answer" openDelay={500}>
                                    <ActionIcon
                                        color="red"
                                        variant="subtle"
                                        onClick={() =>
                                            props.dispatch({
                                                type: 'remove_option',
                                                index: props.index,
                                                optionIndex: i
                                            })
                                        }>
                                        <IconX size={rem(14)} />
                                    </ActionIcon>
                                </Tooltip>
                            </Group>
                        );
                    })}
                    <Group
                        key={props.index}
                        w="40%"
                        sx={(theme) => ({
                            padding: 4,
                            margin: -4,
                            '&:hover': {
                                cursor: 'pointer',
                                backgroundColor: theme.other.getHoverColor(theme),
                                '& .mantine-Text-root': {
                                    color:
                                        theme.colorScheme === 'dark'
                                            ? theme.colors.dark[1]
                                            : theme.colors.gray[9]
                                }
                            }
                        })}>
                        {icon}
                        <Text
                            size="sm"
                            color="dimmed"
                            onClick={() =>
                                props.dispatch({ type: 'add_option', index: props.index })
                            }>
                            {t('action.addNewOption')}
                        </Text>
                    </Group>
                </>
            );
        case 'short':
            return (
                <Text color="dimmed" size={'sm'}>
                    {t('form.shortAnswer')}
                </Text>
            );
        case 'long':
            return (
                <Text color="dimmed" size={'sm'}>
                    {t('form.longAnswer')}
                </Text>
            );
        case 'file':
            return (
                <Text color="dimmed" size="sm">
                    File answer
                </Text>
            );
        case 'block':
            return (
                <TextBlockInput
                    dispatch={props.dispatch}
                    index={props.index}
                    question={props.question}
                />
            );
        case 'email':
            return (
                <Text color="dimmed" size={'sm'}>
                    {t('form.emailAnswer')}
                </Text>
            );
        case 'phone':
            return (
                <Text color="dimmed" size={'sm'}>
                    {t('form.phoneAnswer')}
                </Text>
            );
    }
}

function TextBlockInput(props: { question: TextBlock; dispatch: FormDispatch; index: number }) {
    const editor = useTextEditorConfig({
        content: props.question.content,
        placeholder: 'Type your message...',
        onUpdate: ({ editor }) => {
            props.dispatch({
                type: 'change_content',
                index: props.index,
                newValue: editor.getHTML()
            });
        }
    });

    const synced = useRef<boolean>(false);

    useEffect(() => {
        if (!synced.current) {
            editor?.commands.setContent(props.question.content);
            synced.current = true;
        }
    }, [props, editor?.commands]);
    return (
        <Box h={150}>
            <FullTextEditor maxHeight={100} editor={editor} />
        </Box>
    );
}
