import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectUserSettingsOpen, toggleUserSettingsModal } from '../../../store/ModalsSlice';
import CenteredModal from '../CenteredModal';
import { Box } from '@mantine/core';
import UserSettingsPage from './UserSettingsPage';

export default function UserSettingsModalOutlet() {
    const dispatch = useAppDispatch();
    const modalOpen = useAppSelector(selectUserSettingsOpen);
    const handleClose = () => {
        dispatch(toggleUserSettingsModal({ open: false }));
    };

    return (
        <CenteredModal opened={modalOpen} onClose={handleClose}>
            <Box p={'md'} h={'100%'}>
                <UserSettingsPage />
            </Box>
        </CenteredModal>
    );
}
