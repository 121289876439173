import * as PmxApi from 'privmx-server-api';
import { AdminDataInner, AdminPubKeyProvider } from './userCreation/Types';
import { EciesEncryptor } from '../EciesEncryptor';

export class AdminDataV2Encryptor {
    constructor(private adminPubKeyProvider: AdminPubKeyProvider) {}

    encrypt(adminData: AdminDataInner): Promise<PmxApi.api.core.Base64> {
        return EciesEncryptor.encryptObjectToBase64(
            this.adminPubKeyProvider.getAdminPubKey(),
            adminData
        );
    }
}
