import {
    Box,
    Checkbox,
    Popover,
    Tooltip,
    rem,
    TextInput,
    Divider,
    ScrollArea,
    Text
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { IconPlus, IconSearch } from '@tabler/icons-react';
import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OverflowList } from 'react-overflow-list';
import { useAppSelector } from '../../store';
import { selectUsedTags } from '../../store/DataCacheSlice';
import { useFilterContext, FilterFactory } from '../FilterSelect';
import { useTagsStyle } from './styles';
import { TagElement } from '.';
import { Validator } from '../../utils/Validator';

function AddTagButton({
    tags,
    onTagSelect
}: {
    onTagSelect: (tag: string[]) => void;
    tags: string[];
}) {
    const { classes, cx } = useTagsStyle();
    const globalTags = useAppSelector(selectUsedTags);
    const [_tagSearch, setTagSearch] = useInputState('');
    const tagSearch = useMemo(() => _tagSearch.trim(), [_tagSearch]);
    const [isOpen, setIsOpen] = useState(false);
    const foundTags = globalTags.filter((x) => x.includes(tagSearch));

    const [tagInputError, setTagInputError] = useState<null | string>(null);

    const { t } = useTranslation();

    const handleClosePopover = useCallback(() => {
        setIsOpen(false);
        setTagSearch('');
        setTagInputError(null);
    }, [setTagSearch, setIsOpen]);

    const tagElements = foundTags.map((x) => (
        <Box
            key={x}
            maw={290}
            className={classes.tagListElement}
            onClick={(e: any) => e.stopPropagation()}>
            <Checkbox
                styles={{ label: { overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 260 } }}
                onClick={(e) => e.stopPropagation()}
                size={'xs'}
                value={x}
                label={x}
            />
        </Box>
    ));

    const handleSelectTag = useCallback(() => {
        try {
            const newTag = Validator.check(tagSearch);
            onTagSelect?.([...(tags || []), newTag]);
            return true;
        } catch (error) {
            setTagInputError(
                t('entityProps.incorectTags', { characters: Validator.getSafeDefaultChars() })
            );
            return false;
        }
    }, [onTagSelect, tagSearch, tags, t]);

    return (
        <Popover
            opened={isOpen}
            keepMounted={false}
            trapFocus
            onChange={setIsOpen}
            zIndex={199}
            position="bottom-start">
            <Popover.Target>
                <Box
                    className="privmx-add-tag"
                    display="none"
                    sx={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                        setIsOpen((o) => !o);
                        e.stopPropagation();
                        e.preventDefault();
                    }}>
                    <Tooltip label="Add tag" openDelay={300} closeDelay={500}>
                        <div>
                            <TagElement
                                className={classes.addIcon}
                                variant="outline"
                                label={t('action.addTag')}>
                                <Box component={IconPlus} display={'flex'} size={rem(10)} />
                            </TagElement>
                        </div>
                    </Tooltip>
                </Box>
            </Popover.Target>
            <Popover.Dropdown
                onMouseLeave={handleClosePopover}
                sx={{ zIndex: 100, maxWidth: 300, padding: 4 }}>
                <TextInput
                    error={tagInputError}
                    value={_tagSearch}
                    onChange={setTagSearch}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if (handleSelectTag()) {
                                setTagSearch('');
                                setTagInputError(null);
                            }
                        }
                    }}
                    placeholder={t('action.addTag')}
                    size="xs"
                    icon={<IconSearch size={rem(12)} />}
                    styles={{ input: { border: 0 } }}
                    onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                />
                <Divider opacity={0.5} my={4} px={4}></Divider>

                {foundTags.length > 0 && (
                    <Text size="xs" color="dimmed" px={8} weight={400}>
                        {t('entityProps.tags')}
                    </Text>
                )}
                <Checkbox.Group value={tags} onChange={(newTags) => onTagSelect?.(newTags)}>
                    <ScrollArea.Autosize h={140} scrollbarSize={8}>
                        {foundTags.length > 0 ? (
                            tagElements
                        ) : (
                            <Text
                                maw={280}
                                className={cx(classes.tagListElement, {
                                    [classes.hoverElement]: tagSearch.length
                                })}
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    if (handleSelectTag()) {
                                        handleClosePopover();
                                    }
                                }}>
                                {tagSearch.length
                                    ? `+ ${t('action.addTag')}: ${tagSearch}`
                                    : 'Start typing to add tag'}
                            </Text>
                        )}
                    </ScrollArea.Autosize>
                </Checkbox.Group>
            </Popover.Dropdown>
        </Popover>
    );
}

export function TagList({
    tags,
    maxWidth,
    onTagSelect,
    className
}: {
    className?: string;
    onTagSelect?: (tag: string[]) => void;
    maxWidth?: number;
    tags: string[] | undefined;
}) {
    const { classes, cx } = useTagsStyle();

    const { dispatch } = useFilterContext();

    const hasFilterContext = dispatch.toString() !== 'dispatch() {}';

    function handleApplyFilter(newFilter: string) {
        dispatch({ type: 'add', filter: FilterFactory.tag(newFilter) });
    }

    if (tags && !tags.length && onTagSelect)
        return (
            <Box className={cx(classes.tags, className)}>
                <AddTagButton tags={tags} onTagSelect={onTagSelect} />
            </Box>
        );
    if (!tags?.length) return <></>;

    return (
        <>
            <OverflowList
                className={cx(classes.tags, className)}
                minVisibleItems={0}
                items={tags}
                alwaysRenderOverflow
                itemRenderer={(item) => (
                    <TagElement
                        tooltip={
                            hasFilterContext ? `Filter by tag: ${getSubstring(item)}` : undefined
                        }
                        onClick={
                            hasFilterContext
                                ? (e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleApplyFilter(item);
                                  }
                                : undefined
                        }
                        key={item}>
                        {item}
                    </TagElement>
                )}
                overflowRenderer={(items) => (
                    <>
                        {items.length > 0 ? <TagElement>+{items.length}</TagElement> : null}
                        {onTagSelect && <AddTagButton tags={tags} onTagSelect={onTagSelect} />}
                    </>
                )}
            />
        </>
    );
}

function getSubstring(text: string) {
    const trimmedText = text.slice(0, 70);
    return trimmedText.length === text.length ? trimmedText : `${trimmedText}...`;
}
