import {
    Group,
    ThemeIcon,
    Text,
    createStyles,
    Selectors,
    DefaultProps,
    GroupProps,
    GroupPosition
} from '@mantine/core';
import Icon, { iconEmptyFile, iconMessages } from '../components/Icon';

type Sizes = 'sm' | 'md';

const useIconsStyle = createStyles((theme, { size = 'md' }: { size: Sizes }) => ({
    wrapper: {
        gap: size === 'sm' ? 8 : 10
    },
    icon: {
        height: size === 'sm' ? 10 : 12,
        maxHeight: size === 'sm' ? 10 : 12,
        minHeight: size === 'sm' ? 10 : 12,
        border: 0,
        width: 'fit-content',
        maxWidth: 12,
        minWidth: 'auto',
        color: theme.colors.gray[6],
        margin: 0,
        marginRight: size === 'sm' ? 4 : 6
    },
    count: {
        fontSize: size === 'sm' ? 12 : 14,
        lineHeight: 1,
        color: theme.colors.gray[6]
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center'
    }
}));

type MyComponentStylesNames = Selectors<typeof useIconsStyle>;

export interface PmxSummaryProps extends DefaultProps<MyComponentStylesNames, GroupProps> {
    filesCount: number;
    mesagesCount: number;
    size?: Sizes;
    position?: GroupPosition;
}

export default function FileAndMessageSummary({
    filesCount,
    mesagesCount,
    className,
    unstyled,
    classNames,
    styles,
    size = 'md',
    position = 'left',
    ...props
}: PmxSummaryProps) {
    const { classes } = useIconsStyle(
        { size },
        { name: 'PrivmxHoverCardSummary', classNames, styles, unstyled }
    );

    return (
        <Group
            className={classes.wrapper}
            color="dimmed"
            align={'center'}
            position={position}
            noWrap
            {...props}>
            {filesCount >= 0 && (
                <div className={classes.iconContainer}>
                    <ThemeIcon className={classes.icon} variant="outline">
                        <Icon icon={iconEmptyFile} />
                    </ThemeIcon>
                    <Text className={classes.count}>{filesCount}</Text>
                </div>
            )}
            {mesagesCount >= 0 && (
                <div className={classes.iconContainer}>
                    <ThemeIcon className={classes.icon} variant="outline">
                        <Icon icon={iconMessages} />
                    </ThemeIcon>
                    <Text className={classes.count}>{mesagesCount}</Text>
                </div>
            )}
        </Group>
    );
}
