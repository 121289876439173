import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    getModalModeFromLocalStorage,
    ModalMode,
    setModalModeInLocalStorage
} from '../utils/Theme/modal';
import { getThemeFromLocalStorage, setThemeInLocalStorage, ThemeName } from '../utils/Theme/theme';
import { RootState } from './Store';
import { api as ApiTypes } from 'privmx-server-api';
import { api } from '../api/Api';

export const InputPreference = {
    plain: 'text/plain',
    markdown: 'text/markdown',
    html: 'text/html'
} as const;

export type InputPreferenceTypes = keyof typeof InputPreference;
export type FilesViewMode = 'list' | 'tiles';

export interface UserPreferences {
    theme: ThemeName;
    modalMode: ModalMode;
    inputMode: InputPreferenceTypes;
    autoMarkAsRead: boolean;
    filesViewMode: FilesViewMode;
    isSidebarHidden: boolean;
    filesUploadConfig: ApiTypes.request.RequestConfig;
    planSummary: ApiTypes.admin.PlanSummary;
    shouldSyncSummary: boolean;
}

function saveInputPreference(mode: InputPreferenceTypes) {
    localStorage.setItem('input-preference', mode);
}

function getInputPreferenceLocalStorage(): InputPreferenceTypes {
    const item = localStorage.getItem('input-preference');
    if (!item) return 'html';
    return Object.keys(InputPreference).includes(item) ? (item as InputPreferenceTypes) : 'html';
}

function saveAutoMarkAsRead(autoMarkAsRead: boolean) {
    localStorage.setItem('auto-mark-as-read', autoMarkAsRead ? 'true' : 'false');
}

function getAutoMarkAsReadFromLocalStorage(): boolean {
    const item = localStorage.getItem('auto-mark-as-read');
    if (!item) return true;
    return item === 'true';
}

function saveFilesViewMode(filesViewMode: FilesViewMode) {
    localStorage.setItem('files-view-mode', filesViewMode);
}

function getFilesViewModeFromLocalStorage(): FilesViewMode {
    const item = localStorage.getItem('files-view-mode');
    return item === 'tiles' ? 'tiles' : 'list';
}

export const loadPlanSummaryAsync = createAsyncThunk(
    'userPreferences/loadPlanSummary',
    async (_) => {
        const planSummary = await api.getPlanSummary();
        return planSummary;
    }
);

export const isAutoMarkAsReadSettingEnabled = false;

const defaultConfig = { maxFilesCount: 30, maxFileSize: 200, maxRequestSize: 500 };
const defautPlan: UserPreferences['planSummary'] = {
    planName: 'basic',
    storage: {
        used: 0 as unknown as ApiTypes.admin.PlanSummary['storage']['used'],
        limit: 10737418240 as ApiTypes.admin.PlanSummary['storage']['used']
    },
    staffAccounts: {
        used: 0,
        limit: 100
    },
    video: {
        start: 1698148144584 as any,
        end: 1698191344583 as any,
        exceeded: false as any,
        limit: 36000000 as any,
        usage: 0 as any,
        usageLeft: 36000000 as any,
        usersUsageTime: 0 as any
    }
};

const initialState: UserPreferences = {
    theme: getThemeFromLocalStorage(),
    modalMode: getModalModeFromLocalStorage(),
    inputMode: getInputPreferenceLocalStorage(),
    autoMarkAsRead: isAutoMarkAsReadSettingEnabled ? getAutoMarkAsReadFromLocalStorage() : true,
    filesViewMode: getFilesViewModeFromLocalStorage(),
    isSidebarHidden: true,
    filesUploadConfig: defaultConfig,
    planSummary: defautPlan,
    shouldSyncSummary: true
};

export const userPreferencesSlice = createSlice({
    name: 'userPreferences',
    initialState,
    reducers: {
        loadFilesConfig: (state) => {
            try {
                const config = api.getRequestConfig();
                state.filesUploadConfig = config;
            } catch (error) {
                state.filesUploadConfig = defaultConfig;
            }
        },
        toggleSidebar: (state, action: PayloadAction<{ newValue?: boolean }>) => {
            if (action.payload.newValue === undefined) {
                state.isSidebarHidden = !state.isSidebarHidden;
            } else {
                state.isSidebarHidden = action.payload.newValue;
            }
        },
        setThemeName: (state, action: PayloadAction<ThemeName>) => {
            setThemeInLocalStorage(action.payload);
            state.theme = action.payload;
        },
        setModalMode: (state, action: PayloadAction<ModalMode>) => {
            setModalModeInLocalStorage(action.payload);
            state.modalMode = action.payload;
        },
        setInputPreference: (state, action: PayloadAction<InputPreferenceTypes>) => {
            saveInputPreference(action.payload);
            state.inputMode = action.payload;
        },
        setAutoMarkAsRead: (state, action: PayloadAction<boolean>) => {
            saveAutoMarkAsRead(action.payload);
            state.autoMarkAsRead = action.payload;
        },
        setFilesViewMode: (state, action: PayloadAction<FilesViewMode>) => {
            saveFilesViewMode(action.payload);
            state.filesViewMode = action.payload;
        },
        resetUserPreferenceState: () => {
            return initialState;
        }
    },
    extraReducers(builder) {
        builder.addCase(loadPlanSummaryAsync.fulfilled, (state, action) => {
            state.planSummary = action.payload;
        });
    }
});

export const {
    toggleSidebar,
    setThemeName,
    setModalMode,
    setInputPreference,
    setAutoMarkAsRead,
    setFilesViewMode,
    resetUserPreferenceState,
    loadFilesConfig
} = userPreferencesSlice.actions;

export const selectUserPreferences = (state: RootState) => state.userPreferences;

export const selectThemeName = createSelector(
    [selectUserPreferences],
    (userPreferences) => userPreferences.theme
);

export const selectFileConfig = createSelector(
    [selectUserPreferences],
    (userPreferences) => userPreferences.filesUploadConfig
);

export const selectisSidebarOpen = createSelector(
    [selectUserPreferences],
    (userPreferences) => userPreferences.isSidebarHidden
);

export const selectShouldSyncSummary = createSelector(
    [selectUserPreferences],
    (userPreferences) => userPreferences.shouldSyncSummary
);

export const selectPlanSummary = createSelector(
    [selectUserPreferences],
    (userPreferences) => userPreferences.planSummary
);

export const selectAutoMarkAsRead = createSelector(
    [selectUserPreferences],
    (userPreferences) => userPreferences.autoMarkAsRead
);
export const selectFilesViewMode = createSelector(
    [selectUserPreferences],
    (userPreferences) => userPreferences.filesViewMode
);

export default userPreferencesSlice.reducer;
