import { Sx, MantineTheme, Button, ButtonProps } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { MouseEventHandler } from 'react';

const sxTransform: Sx = (theme: MantineTheme) => ({
    transform: 'translateX(-50%)',
    position: 'sticky',
    left: '50%',
    bottom: theme.spacing.md,
    marginTop: theme.spacing.md,
    '&:active': {
        transform: 'translateX(-50%)'
    }
});

type Props = {
    onClick: MouseEventHandler;
    children: React.ReactNode;
} & ButtonProps;

export default function AddButton({ onClick, children, ...props }: Props) {
    return (
        <Button onClick={onClick} variant="light" leftIcon={<IconPlus />} {...props}>
            {children}
        </Button>
    );
}

export const StickyAddButton = (props: Props) => <AddButton {...props} sx={sxTransform} />;
