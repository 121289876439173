import * as privfs from 'privfs-client';
import { KvdbSettingEntry } from '../../kvdb/KvdbUtils';
import { PrivmxConst } from '../../PrivmxConst';

export class KvdbCreator {
    constructor(private extKey: privfs.crypto.ecc.ExtKey) {}

    createSettingsKvdb() {
        return this.createKvdb(PrivmxConst.SETTINGS_KVDB_INDEX);
    }

    createTagsProviderKvdb() {
        return this.createKvdb(PrivmxConst.TAG_PROVIDER_KVDB_INDEX);
    }

    createMailFilterKvdb() {
        return this.createKvdb(PrivmxConst.MAIL_FILTER_KVDB_INDEX);
    }

    createPkiCacheKvdb() {
        return this.createKvdb(PrivmxConst.PKI_CACHE_KVDB_INDEX);
    }

    createContactsKvdb() {
        return this.createKvdb(PrivmxConst.CONTACTS_KVDB_INDEX);
    }

    private async createKvdb(index: number) {
        const extKey = await this.derive(index);
        return new privfs.db.KeyValueDb<KvdbSettingEntry>(
            null as unknown as privfs.gateway.RpcGateway,
            extKey,
            {},
            true
        );
    }

    private async derive(index: number): Promise<privfs.crypto.ecc.ExtKey> {
        return await privfs.core.CryptoUtils.deriveHardened(this.extKey, index);
    }
}
