import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './Store';

export type MeetingUserIdsState = {
    userIdsByMeeting: { [meetingId: string]: string[] };
};

export const initialState: MeetingUserIdsState = {
    userIdsByMeeting: {}
};

export const meetingUserIdsSlice = createSlice({
    name: 'meetingUserIds',
    initialState,
    reducers: {
        setMeetingUserIds: (
            state,
            action: PayloadAction<{ meetingId: string; userIds: string[] }>
        ) => {
            return {
                userIdsByMeeting: {
                    ...state.userIdsByMeeting,
                    [action.payload.meetingId]: action.payload.userIds
                }
            };
        },
        resetMeetingUserIdsState: () => {
            return initialState;
        }
    }
});

export const { setMeetingUserIds, resetMeetingUserIdsState } = meetingUserIdsSlice.actions;

export const selectMeetingUserIdsState = (state: RootState) => state.meetingUserIds;

const _selectMeetingUserIds = createSelector(
    [selectMeetingUserIdsState, (_rootState: RootState, meetingId: string) => meetingId],
    (state, meetingId) => {
        return state.userIdsByMeeting[meetingId] ?? [];
    }
);

export function selectMeetingUserIds(meetingId: string) {
    return (state: RootState) => _selectMeetingUserIds(state, meetingId);
}

export default meetingUserIdsSlice.reducer;
