import * as PmxApi from 'privmx-server-api';
import { UserCreationService } from './UserCreationService';
import { AdminRightService } from '../AdminRightService';
import { UserCreationContext, UserOriginDetails, UserProfileData } from './Types';
import * as privfs from 'privfs-client';
import { PasswordGenerator } from './PasswordGenerator';
import { Username } from '../../../../types/Types';

export interface CreateNormalUserParams {
    creator: privfs.identity.Identity;
    username: PmxApi.api.core.Username;
    host: PmxApi.api.core.Host;
    language: PmxApi.api.core.Language;
    email: PmxApi.api.core.Email;
    description: PmxApi.api.user.UserDescription;
    admin: boolean;
    shareCommonKvdb: boolean;
    originDetails: UserOriginDetails;
    profile?: UserProfileData;
}

export interface CreateBasicUserParams {
    creator: privfs.identity.Identity;
    username: PmxApi.api.core.Username;
    host: PmxApi.api.core.Host;
    language: PmxApi.api.core.Language;
    email: PmxApi.api.core.Email;
    description: PmxApi.api.user.UserDescription;
    privateSectionAllowed: boolean;
    originDetails: UserOriginDetails;
    profile?: UserProfileData;
}

export interface CreateManagableUserParams {
    creator: privfs.identity.Identity;
    username: PmxApi.api.core.Username;
    host: PmxApi.api.core.Host;
    login?: string;
    language: PmxApi.api.core.Language;
    email: PmxApi.api.core.Email;
    description: PmxApi.api.user.UserDescription;
    privateSectionAllowed: boolean;
    type: PmxApi.api.admin.AddUser;
    shareCommonKvdb: boolean;
    originDetails: UserOriginDetails;
    profile?: UserProfileData;
}

export interface CreateManagableUserResult extends UserCreationContext {
    language: PmxApi.api.core.Language;
    username: PmxApi.api.core.Username;
    login: string;
    password: string;
    host: PmxApi.api.core.Host;
}

export class ManagableUserCreator {
    constructor(
        private userCreationService: UserCreationService,
        private adminRightService: AdminRightService
    ) {}

    async createNormalUser(params: CreateNormalUserParams) {
        const context = await this.createManagableUser({
            creator: params.creator,
            username: params.username,
            host: params.host,
            language: params.language,
            email: params.email,
            description: params.description,
            privateSectionAllowed: true,
            type: {
                type: 'normal'
            },
            shareCommonKvdb: params.shareCommonKvdb,
            originDetails: params.originDetails,
            profile: params.profile
        });
        if (params.admin) {
            await this.adminRightService.grantAdminRights(context.username);
        }
        return context;
    }

    async createNormalEmailUser(params: CreateNormalUserParams) {
        const context = await this.createManagableUser({
            creator: params.creator,
            username: params.username,
            host: params.host,
            login: params.email,
            language: params.language,
            email: params.email,
            description: params.description,
            privateSectionAllowed: true,
            type: {
                type: 'normal-email'
            },
            shareCommonKvdb: params.shareCommonKvdb,
            originDetails: params.originDetails,
            profile: params.profile
        });
        if (params.admin) {
            await this.adminRightService.grantAdminRights(context.username);
        }
        return context;
    }

    async createBasicUser(params: CreateBasicUserParams) {
        return this.createManagableUser({
            creator: params.creator,
            username: params.username,
            host: params.host,
            language: params.language,
            email: params.email,
            description: params.description,
            privateSectionAllowed: params.privateSectionAllowed,
            type: {
                type: 'basic'
            },
            shareCommonKvdb: false,
            originDetails: params.originDetails,
            profile: params.profile
        });
    }

    async createEmailUser(params: CreateBasicUserParams) {
        return this.createManagableUser({
            creator: params.creator,
            username: params.username,
            host: params.host,
            login: params.email,
            language: params.language,
            email: params.email,
            description: params.description,
            privateSectionAllowed: params.privateSectionAllowed,
            type: {
                type: 'email'
            },
            shareCommonKvdb: false,
            originDetails: params.originDetails,
            profile: params.profile
        });
    }

    private async createManagableUser(
        params: CreateManagableUserParams
    ): Promise<CreateManagableUserResult> {
        const password = PasswordGenerator.generatedTemporaryPassword();

        const context = await this.userCreationService.createUser({
            creator: params.creator,
            username: params.username,
            host: params.host,
            password: password,
            email: params.email,
            login: params.login,
            language: params.language,
            description: params.description,
            notificationEnabled: true,
            privateSectionAllowed: params.privateSectionAllowed,
            type: params.type,
            generatedPassword: true,
            shareKvdb: params.shareCommonKvdb,
            weakPassword: false,
            createSection: true,
            originDetails: params.originDetails,
            profile: params.profile
        });
        const result: CreateManagableUserResult = {
            ...context,
            language: params.language,
            username: params.username,
            login: params.login || params.username,
            password: password,
            host: params.host
        };
        return result;
    }

    async updateUsersOriginDetails(usernames: Username[], originDetails: UserOriginDetails) {
        await this.userCreationService.updateUsersOriginDetails(usernames, originDetails);
    }
}
