import { FirstLoginTokenData } from '../../types/Types';
import * as privmx from 'privfs-client';

export class CommonLoginUtils {
    static readonly TOKEN_PREFIX: string = '#token=';
    static getPassableLocationHash(): string {
        const hash = window.location.hash ?? '';
        if (hash.startsWith('#login=')) {
            return hash;
        }
        return '';
    }

    static getLoginFromLocationHash(): string {
        const hash = window.location.hash ?? '';
        if (hash.startsWith('#login=')) {
            return hash.substring('#login='.length);
        }
        return '';
    }

    static isRegistrationTokenPresent(): boolean {
        const hash = window.location.hash ?? '';
        return hash.startsWith(CommonLoginUtils.TOKEN_PREFIX);
    }

    static readRegistrationToken(): FirstLoginTokenData | null {
        if (!CommonLoginUtils.isRegistrationTokenPresent()) {
            return null;
        }
        const hash = window.location.hash ?? '';
        if (hash.startsWith(CommonLoginUtils.TOKEN_PREFIX)) {
            const data = hash.substring(CommonLoginUtils.TOKEN_PREFIX.length);
            try {
                const json = privmx.Buffer.Buffer.from(privmx.bs58.decode(data)).toString('utf-8');
                const decoded = JSON.parse(json) as FirstLoginTokenData;
                if (!decoded.__type || decoded.__type !== 'FLTD') {
                    throw Error('Invalid token data');
                }
                if (!decoded.userLogin || decoded.userLogin.length === 0) {
                    throw Error('Invalid token data');
                }
                return decoded;
            } catch (e) {
                return null;
            }
        }
        return null;
    }

    static encode(data: FirstLoginTokenData): string {
        const json = JSON.stringify(data);
        const buffer = privmx.Buffer.Buffer.from(json);
        return privmx.bs58.encode(buffer);
    }
}
