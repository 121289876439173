import * as PmxApi from 'privmx-server-api';
import { EciesEncryptor } from './EciesEncryptor';
import * as privmx from 'privfs-client';
import { InquirySubmitResponseAdminData } from '../../types/Types';

export class InquirySubmitResponseAdminDataEncryptor {
    async encrypt(
        pub: privmx.crypto.ecc.PublicKey | PmxApi.api.core.EccPubKey,
        data: InquirySubmitResponseAdminData
    ) {
        const pubKey =
            typeof pub === 'string' ? privmx.crypto.ecc.PublicKey.fromBase58DER(pub) : pub;
        return (await EciesEncryptor.encryptObjectToBase64(
            pubKey,
            data
        )) as PmxApi.api.inquiry.InquirySubmitResponseAdminData;
    }

    async decrypt(
        priv: privmx.crypto.ecc.PrivateKey | PmxApi.api.core.EccWif,
        data: PmxApi.api.inquiry.InquirySubmitResponseAdminData
    ) {
        const privKey =
            typeof priv === 'string' ? privmx.crypto.ecc.PrivateKey.fromWIF(priv) : priv;
        return (await EciesEncryptor.decryptObjectFromBase64(
            privKey,
            data
        )) as InquirySubmitResponseAdminData;
    }
}
