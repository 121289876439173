import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Title } from '@mantine/core';
import { ContactPageView } from './ContactPageView';
import { useDataSubscribion } from '../../../hooks/useDataSubscribion';
import StateView from '../../../atoms/State';

export function ContactPage() {
    const { contactId } = useParams();
    const { state: contactsState, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForContacts(), [])
    );

    return (
        <StateView state={contactsState} retry={revalidate}>
            {(contacts) => {
                const contact = contacts.find((x) => x.id === contactId);
                if (!contact) {
                    return (
                        <Title color="dimmed" mx="auto">
                            Contact not found
                        </Title>
                    );
                }
                return <ContactPageView contact={contact} key={contactId} />;
            }}
        </StateView>
    );
}
