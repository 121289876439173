import * as PmxApi from 'privmx-server-api';
import * as privmx from 'privfs-client';
import { EciesEncryptor } from './EciesEncryptor';
import { PkiService } from './PkiService';
import { Inquiry } from './InquiryService';

export interface EncKey {
    id: PmxApi.api.core.KeyId;
    key: Buffer;
}

export interface SerializableEncKey {
    id: EncKey['id'];
    key: string;
}

export class KeyProvider {
    constructor(private identity: privmx.identity.Identity, private pkiService: PkiService) {}

    generateKey() {
        const res: EncKey = {
            id: privmx.crypto.service.randomBytes(16).toString('hex') as PmxApi.api.core.KeyId,
            key: privmx.crypto.service.randomBytes(32)
        };
        return res;
    }

    async getCurrentKey(entry: { keyId: PmxApi.api.core.KeyId; keys: PmxApi.api.core.KeyEntry[] }) {
        return this.getKey(entry.keys, entry.keyId);
    }

    async getKey(
        keys: PmxApi.api.core.KeyEntry[],
        keyId: PmxApi.api.core.KeyId,
        customPrivKey?: privmx.crypto.ecc.PrivateKey
    ) {
        const userEntry = keys.find((x) => x.keyId === keyId);
        if (!userEntry) {
            throw new Error('No user entry for given keyId');
        }
        const key: EncKey = {
            id: keyId,
            key: await EciesEncryptor.decryptFromBase64(
                customPrivKey ?? this.identity.priv,
                userEntry.data
            )
        };
        return key;
    }

    async hasKey(keys: PmxApi.api.core.KeyEntry[], keyId: PmxApi.api.core.KeyId) {
        const userEntry = keys.find((x) => x.keyId === keyId);
        return !!userEntry;
    }

    async prepareKeysList(
        users: PmxApi.api.core.Username[],
        anonymousUsers: PmxApi.api.core.EccPubKey[],
        key: EncKey
    ) {
        const allUsers = [
            ...users.map((x) => ({ type: 'regular' as const, username: x })),
            ...anonymousUsers.map((x) => ({ type: 'anonymous' as const, pub: x }))
        ];
        return Promise.all(
            allUsers.map(async (x) => {
                const res: PmxApi.api.core.KeyEntrySet = {
                    user: x.type === 'regular' ? x.username : x.pub,
                    keyId: key.id,
                    data: (await EciesEncryptor.encryptToBase64(
                        x.type === 'regular'
                            ? await this.pkiService.getUserPubKey(x.username, this.identity.host)
                            : privmx.crypto.ecc.PublicKey.fromBase58DER(x.pub),
                        key.key
                    )) as PmxApi.api.core.UserKeyData
                };
                return res;
            })
        );
    }

    async prepareKeysListForInquiryThread(
        customerUser: PmxApi.api.core.Username | null,
        inquiry: Inquiry,
        key: EncKey,
        anonPubKey?: privmx.crypto.ecc.PublicKey
    ) {
        const inquiryPrivKey = privmx.crypto.ecc.PrivateKey.fromWIF(inquiry.data.priv);
        const inquiryPubKey = inquiryPrivKey.getPublicKey();
        const res: PmxApi.api.core.KeyEntrySet[] = await Promise.all([
            ...(customerUser === null
                ? []
                : [
                      {
                          user: customerUser,
                          keyId: key.id,
                          data: (await EciesEncryptor.encryptToBase64(
                              await this.pkiService.getUserPubKey(customerUser, this.identity.host),
                              key.key
                          )) as PmxApi.api.core.UserKeyData
                      }
                  ]),
            {
                user: '' as PmxApi.api.core.Username,
                keyId: key.id,
                data: (await EciesEncryptor.encryptToBase64(
                    inquiryPubKey,
                    key.key
                )) as PmxApi.api.core.UserKeyData
            },
            ...(anonPubKey
                ? [
                      {
                          user: '##anon##' as PmxApi.api.core.Username,
                          keyId: key.id,
                          data: (await EciesEncryptor.encryptToBase64(
                              anonPubKey,
                              key.key
                          )) as PmxApi.api.core.UserKeyData
                      }
                  ]
                : [])
        ]);
        return res;
    }
}
