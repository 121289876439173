import {
    Paper,
    Group,
    ActionIcon,
    Title,
    ScrollArea,
    Stack,
    Box,
    Badge,
    rem,
    Text,
    Tooltip,
    Sx
} from '@mantine/core';
import { IconArrowLeft, IconFile, IconMail, IconUserPlus } from '@tabler/icons-react';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { flushSync } from 'react-dom';
import { Gallery } from '../../../components/FilesView/Gallery';
import {
    FormRow,
    FormModelEntries,
    AttachmentId,
    InquiryAttachmentEx,
    SubmitedAnswer
} from '../../../types/Types';
import { useFormTableStyles } from './styles';
import parse from 'html-react-parser';
import {
    canCreateContactFromAnswer,
    createTopicFromResponse,
    getFirstMessage,
    handleCreateContact
} from '.';
import { useAppDispatch, useAppSelector } from '../../../store';
import { loadContacsAsync, selectCachedContacts } from '../../../store/DataCacheSlice';
import { selectUsers } from '../../../store/UsersSlice';
import { toggleNewMessageModal } from '../../../store/ModalsSlice';
import { UserUtils } from '../../../utils/UserUtils';
import { useTranslation } from 'react-i18next';

export function FormResponseDetail({
    response,
    form,
    closeDetail
}: {
    closeDetail: VoidFunction;
    response: FormRow;
    form: FormModelEntries;
}) {
    const [handleShowFilePreview, setHandleShowFilePreview] = useState<
        (fileId: AttachmentId) => void
    >(() => () => {});
    const handleGetShowFilePreview = useCallback((cb: (fileId: AttachmentId) => void) => {
        setHandleShowFilePreview(() => cb);
    }, []);

    const [chatAttachments, setChatAttachments] = useState<InquiryAttachmentEx[]>([]);
    const [initialId, setInitialId] = useState<AttachmentId>();

    useEffect(() => {
        if (initialId) {
            handleShowFilePreview(initialId);
        }
    }, [initialId, handleShowFilePreview]);

    const { classes, theme } = useFormTableStyles();

    const questionBg = theme.colorScheme === 'dark' ? 'dark.6' : 'gray.0';

    const contacts = useAppSelector(selectCachedContacts);
    const users = useAppSelector(selectUsers);
    const accountEmails = useMemo(() => {
        const emails = users.map((x) => x.email);
        return emails;
    }, [users]);
    const [isCreatingContact, setIsCreatingContact] = useState(false);
    const [isCreatingTopic, setIsCreatingTopic] = useState(false);
    const staffMembers = useAppSelector(selectUsers);

    const dispatch = useAppDispatch();

    const handleCreateContactClick = useCallback(
        async (evt: React.MouseEvent, answer: SubmitedAnswer) => {
            setIsCreatingContact(true);
            await handleCreateContact(evt, answer, contacts, accountEmails);
            setIsCreatingContact(false);
        },
        [contacts, accountEmails]
    );

    const handleCreateTopicClick = useCallback(
        async (evt: React.MouseEvent, answer: SubmitedAnswer) => {
            setIsCreatingTopic(true);

            const contactForTopic = await createTopicFromResponse(
                evt,
                answer,
                contacts,
                staffMembers
            );
            if (contactForTopic) {
                await dispatch(loadContacsAsync());
                dispatch(
                    toggleNewMessageModal({
                        open: true,
                        payload: {
                            users: [UserUtils.userUnionToUsernameOrContactId(contactForTopic)],
                            firstMessage: getFirstMessage(response?.answers, form.questions)
                        }
                    })
                );
            }
            setIsCreatingTopic(false);
        },
        [dispatch, contacts, staffMembers, form.questions, response.answers]
    );

    const { t } = useTranslation();

    const canCreateAccount = (answer: SubmitedAnswer) =>
        canCreateContactFromAnswer(answer, contacts, accountEmails);

    return (
        <>
            <Gallery files={chatAttachments} getShowFilePreview={handleGetShowFilePreview} />
            <Paper withBorder h="calc(100% - 130px)" m="lg" p="md" radius="md" pos="relative">
                <Group mx="auto" w="50%" my="md">
                    <ActionIcon onClick={closeDetail}>
                        <IconArrowLeft />
                    </ActionIcon>
                    <Title fw="normal" order={3}>
                        {t('screen.forms.formResponses.title')}
                    </Title>
                </Group>
                <ScrollArea w="100%" h="100%" offsetScrollbars type="always">
                    <Stack mx="auto" w="50%">
                        {form.questions.map((question, i) => {
                            const answer = response?.answers.find((x) => x.id === question.id);
                            if (question.type === 'file') {
                                const answerFiles = (answer?.answer || [])
                                    .map((x) => {
                                        const file = response?.attachments.find(
                                            (att) => att.name === x.input
                                        );
                                        if (file) {
                                            return file;
                                        } else {
                                            return null;
                                        }
                                    })
                                    .filter((x) => x !== null) as InquiryAttachmentEx[];

                                return (
                                    <React.Fragment key={question.id}>
                                        <Paper p="lg" bg={questionBg} radius={'md'}>
                                            <Text mb="xs">
                                                {question.title}
                                                {question.title ? ':' : ''}
                                            </Text>

                                            <Box className={classes.tableCell}>
                                                {answerFiles.map((x) => (
                                                    <Badge
                                                        key={x.id}
                                                        sx={{
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    theme.colorScheme === 'dark'
                                                                        ? theme.colors.dark[4]
                                                                        : theme.colors.gray[1]
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            flushSync(() => {
                                                                setChatAttachments(
                                                                    answerFiles || []
                                                                );
                                                                setInitialId(x.id);
                                                            });
                                                        }}
                                                        leftSection={<IconFile size={rem(8)} />}
                                                        size="xs"
                                                        variant="outline"
                                                        color={
                                                            theme.colorScheme === 'dark'
                                                                ? 'dark'
                                                                : 'gray'
                                                        }
                                                        mr="xs">
                                                        {x.name}
                                                    </Badge>
                                                ))}
                                            </Box>
                                        </Paper>
                                    </React.Fragment>
                                );
                            } else if (question.type === 'block') {
                                return (
                                    <Paper p="lg" bg={questionBg} radius={'md'} key={question.id}>
                                        <Text mb="xs">
                                            {question.title}
                                            {question.title ? ':' : ''}
                                        </Text>
                                        {parse(question.content)}
                                    </Paper>
                                );
                            } else if (Array.isArray(question.answer)) {
                                return (
                                    <Paper p="lg" bg={questionBg} radius={'md'} key={question.id}>
                                        <Text mb="xs">
                                            {question.title}
                                            {question.title ? ':' : ''}
                                        </Text>

                                        <Group spacing="sm">
                                            {question.answer
                                                .map((a) => ({
                                                    value: a.input,
                                                    correct: Boolean(
                                                        answer?.answer.find((x) => x.id === a.id)
                                                    )
                                                }))
                                                ?.map(({ value, correct }, i) => (
                                                    <Badge
                                                        key={i}
                                                        variant="outline"
                                                        color={correct ? 'green' : 'gray'}
                                                        opacity={0.9}>
                                                        {value}
                                                    </Badge>
                                                ))}
                                        </Group>
                                    </Paper>
                                );
                            } else {
                                return (
                                    <Paper p="lg" bg={questionBg} radius={'md'} key={question.id}>
                                        <Group>
                                            <Text>
                                                {question.title}
                                                {question.title ? ':' : ''}
                                            </Text>
                                            {answer && (
                                                <>
                                                    {canCreateAccount(answer) && (
                                                        <Tooltip
                                                            label={t(
                                                                'screen.forms.formResponses.createContact'
                                                            )}>
                                                            <ActionIcon
                                                                component="div"
                                                                onClick={(e) =>
                                                                    handleCreateContactClick(
                                                                        e,
                                                                        answer
                                                                    )
                                                                }
                                                                variant="outline"
                                                                loading={isCreatingContact}
                                                                sx={actionIconStyle}>
                                                                <IconUserPlus size={16} />
                                                            </ActionIcon>
                                                        </Tooltip>
                                                    )}

                                                    {canCreateContactFromAnswer(answer, [], []) && (
                                                        <Tooltip
                                                            label={
                                                                canCreateAccount(answer)
                                                                    ? t(
                                                                          'screen.forms.formResponses.createContactAndTopic'
                                                                      )
                                                                    : t(
                                                                          'screen.forms.formResponses.createTopic'
                                                                      )
                                                            }>
                                                            <ActionIcon
                                                                component="div"
                                                                onClick={(e) =>
                                                                    handleCreateTopicClick(
                                                                        e,
                                                                        answer
                                                                    )
                                                                }
                                                                variant="outline"
                                                                loading={isCreatingTopic}
                                                                sx={actionIconStyle}>
                                                                <IconMail size={16} />
                                                            </ActionIcon>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            )}
                                        </Group>
                                        <div>{answer?.answer.map((x) => x.input).join(', ')}</div>
                                    </Paper>
                                );
                            }
                        })}
                    </Stack>
                </ScrollArea>
            </Paper>
        </>
    );
}
const actionIconStyle: Sx = (theme) => {
    return {
        border: 0,
        '&:hover': {
            color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[7],
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }
    };
};
