import * as PmxApi from 'privmx-server-api';
import { BaseApi } from './BaseApi';

export class UserPollApi extends BaseApi implements PmxApi.api.user.IUserPollApi {
    getUsernames(): Promise<PmxApi.api.core.Username[]> {
        return this.request('getUsernames', {});
    }

    getUsernameEx(model: {
        username: PmxApi.api.core.Username;
        addDeletedUsers?: boolean;
        addPkiInfo?: boolean;
    }): Promise<PmxApi.api.user.UsernameEx> {
        return this.request('getUsernameEx', model);
    }

    getUsernamesEx(model: {
        addDeletedUsers?: boolean;
        addPkiInfo?: boolean;
    }): Promise<PmxApi.api.user.UsernameEx[]> {
        return this.request('getUsernamesEx', model);
    }

    userPoll(model: PmxApi.api.user.UserPollModel): Promise<PmxApi.api.user.UsernameEx[]> {
        return this.request('userPoll', model);
    }
}
