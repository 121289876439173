import * as PmxApi from 'privmx-server-api';
import { BaseApi } from './BaseApi';

export class ContactApi extends BaseApi implements PmxApi.api.contact.IContactApi {
    getContact(
        model: PmxApi.api.contact.GetContactModel
    ): Promise<{ contact: PmxApi.api.contact.Contact }> {
        return this.request('getContact', model);
    }

    getContacts(): Promise<{ contacts: PmxApi.api.contact.Contact[] }> {
        return this.request('getContacts', {});
    }

    getCompanyContacts(
        model: PmxApi.api.contact.GetCompanyContactsModel
    ): Promise<{ contacts: PmxApi.api.contact.Contact[] }> {
        return this.request('getCompanyContacts', model);
    }

    createContact(
        model: PmxApi.api.contact.CreateContactModel
    ): Promise<{ contact: PmxApi.api.contact.Contact }> {
        return this.request('createContact', model);
    }

    updateContact(
        model: PmxApi.api.contact.UpdateContactModel
    ): Promise<{ contact: PmxApi.api.contact.Contact }> {
        return this.request('updateContact', model);
    }

    deleteContact(model: PmxApi.api.contact.DeleteContactModel): Promise<PmxApi.api.core.OK> {
        return this.request('deleteContact', model);
    }

    setContactTags(
        model: PmxApi.api.contact.SetContactTagsModel
    ): Promise<{ contact: PmxApi.api.contact.Contact }> {
        return this.request('setContactTags', model);
    }
}
