export type IWhenable<T> = PromiseLike<T> | T;

export function runAsync(func: () => PromiseLike<unknown> | void, errorName?: string) {
    void (async () => {
        try {
            await func();
        } catch (e) {
            console.error('Error during calling async', errorName, e);
        }
    })();
}

export function runInNextTick(func: () => unknown) {
    runAsync(() => Promise.resolve().then(func), 'runInNextTick');
}

export function asyncAsCallback(func: () => Promise<void>, errorName?: string): () => void;
export function asyncAsCallback<A>(
    func: (a: A) => Promise<void>,
    errorName?: string
): (a: A) => void;
export function asyncAsCallback<A, B>(
    func: (a: A, b: B) => Promise<void>,
    errorName?: string
): (a: A, b: B) => void;
export function asyncAsCallback(
    func: (...args: unknown[]) => Promise<void>,
    errorName?: string
): (...args: unknown[]) => void {
    return (...args) => runAsync(() => func(...args), errorName);
}

export function setTimeoutAsync(func: () => Promise<void>, delay: number) {
    return setTimeout(() => runAsync(func, 'setTimeout'), delay);
}

export function setIntervalAsync(func: () => Promise<void>, delay: number) {
    return setInterval(() => runAsync(func, 'setInterval'), delay);
}

export function isPromiseLike<T = unknown>(result: unknown): result is PromiseLike<T> {
    return (
        typeof result === 'object' &&
        !!result &&
        typeof (result as PromiseLike<T>).then === 'function'
    );
}
