import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import StateView from '../../atoms/State';
import useLoader from '../../hooks/useLoader';
import { FormId } from '../../types/Types';
// eslint-disable-next-line
import FormAnswering from '../FormCreator/components/FormAnswering';
import FormAnswering2 from '../FormCreator/components/FormAnswering2';

export default function PublicFormAnswer() {
    const routeParams = useParams();
    const { state, revalidate } = useLoader(
        useCallback((x) => x.loadPublicForm(routeParams.formId as FormId), [routeParams.formId])
    );
    return (
        <StateView state={state} retry={revalidate}>
            {(data) => <FormAnswering2 model={data.form} />}
        </StateView>
    );
}
