import Modal from '../../atoms/Modal';
import { BaseModalData } from '../../types/Modals';
import StateView from '../../atoms/State';
import { SharedFileEditPage } from './index';
import { SharedFileSetFileModel } from '../../types/Types';
import { useCallback } from 'react';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { useTranslation } from 'react-i18next';

export interface SharedFileAddModalData extends BaseModalData {
    sharedFileSetFileModel?: SharedFileSetFileModel;
}

export function SharedFileAddModal(props: {
    onClose: () => void;
    data: SharedFileAddModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForNothing(), [])
    );

    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            height={props.data.height ?? 490}
            minHeight={440}
            width={props.data.width}
            title={props.data.title ?? t('modal.sharedFileAdd.title')}>
            <StateView state={state} retry={revalidate}>
                {(data) => (
                    <SharedFileEditPage
                        sharedFileSetFileModel={props.data.sharedFileSetFileModel}
                        handleClose={props.onClose}
                    />
                )}
            </StateView>
        </Modal>
    );
}
