import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnonymousUser, User, Username } from '../types/Types';
import { RootState } from './Store';
import * as PmxApi from 'privmx-server-api';
import { Utils } from '../utils/Utils';

export type UsersState = {
    users: User[];
    anonymousUsers: AnonymousUser[];
};

export const initialState: UsersState = {
    users: [],
    anonymousUsers: []
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<User[]>) => {
            if (!Utils.isDeepEqual(state.users, action.payload)) {
                state.users = action.payload;
            }
        },
        upsertUser: (state, action: PayloadAction<User>) => {
            const idx = state.users.findIndex((x) => x.username === action.payload.username);
            if (idx >= 0) {
                if (!Utils.isDeepEqual(state.users[idx], action.payload)) {
                    state.users[idx] = action.payload;
                }
            } else {
                state.users.push(action.payload);
            }
        },
        removeUser: (state, action: PayloadAction<Username>) => {
            const idx = state.users.findIndex((x) => x.username === action.payload);
            if (idx >= 0) {
                state.users.splice(idx, 1);
            }
        },
        mutateCurrentUser: (
            state,
            action: PayloadAction<{ currentUser: Username; newUser: Partial<User> }>
        ) => {
            state.users = state.users.map((x) => {
                if (x.username === action.payload.currentUser) {
                    return { ...x, ...action.payload.newUser };
                } else {
                    return x;
                }
            });
        },
        setAnonymousUsers: (state, action: PayloadAction<AnonymousUser[]>) => {
            state.anonymousUsers = action.payload;
        },
        upsertAnonymousUser: (state, action: PayloadAction<AnonymousUser>) => {
            const idx = state.anonymousUsers.findIndex((x) => x.pub === action.payload.pub);
            if (idx >= 0) {
                if (!Utils.isDeepEqual(state.anonymousUsers[idx], action.payload)) {
                    state.anonymousUsers[idx] = action.payload;
                }
            } else {
                state.anonymousUsers.push(action.payload);
            }
        },
        removeAnonymousUser: (state, action: PayloadAction<PmxApi.api.core.EccPubKey>) => {
            const idx = state.anonymousUsers.findIndex((x) => x.pub === action.payload);
            if (idx >= 0) {
                state.anonymousUsers.splice(idx, 1);
            }
        },
        resetUsersState: () => {
            return initialState;
        }
    }
});

export const {
    setUsers,
    upsertUser,
    removeUser,
    setAnonymousUsers,
    upsertAnonymousUser,
    removeAnonymousUser,
    mutateCurrentUser,
    resetUsersState
} = usersSlice.actions;

export const selectUsersState = (state: RootState) => state.users;
export const selectUsers = createSelector([selectUsersState], (state) => state.users);

const _selectUser = createSelector(
    [selectUsers, (rootState: RootState, username: Username) => username],
    (users, username) => {
        return users.find((x) => x.username === username);
    }
);

export function selectUser(username: Username) {
    return (state: RootState) => _selectUser(state, username);
}

export const selectAnonymousUsers = (state: RootState) => state.users.anonymousUsers;
export const selectAnonymousUser = (pub: PmxApi.api.core.EccPubKey) => (state: RootState) =>
    state.users.anonymousUsers.find((x) => x.pub === pub);

export default usersSlice.reducer;
