import { useCallback } from 'react';
import StateView from '../../atoms/State';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { MeetingId } from '../../types/Types';
import { MeetingLobbyManagementPageView } from '../MeetingLobbyManagementPage';

export function MeetingLobbyManagement(props: { meetingId: MeetingId }) {
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForThreadMeetingWithLobby(props.meetingId), [props.meetingId])
    );
    return (
        <StateView state={state} retry={revalidate}>
            {(data) => <MeetingLobbyManagementPageView meeting={data.meeting} lobby={data.lobby} />}
        </StateView>
    );
}
