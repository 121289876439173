import { SelectProps, Select } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { useState } from 'react';
import { Validator } from '../../../utils/Validator';
import { useTranslation } from 'react-i18next';

export function CompanySelect({ onCreate, ...props }: SelectProps) {
    const { t } = useTranslation();

    const [companySearchInput, setCompanySearchInput] = useInputState('');
    const [companyInputError, setCompanyInputError] = useState<React.ReactNode>(undefined);

    function checkNewCompanyName(query: string) {
        const result = Validator.getErrors(query, ['safeDefault']);
        if (result !== false) {
            if (result.includes('safeDefault')) {
                setCompanyInputError(
                    t('modal.createAccount.companySelect.incorectName', {
                        characters: Validator.getSafeDefaultChars()
                    })
                );
            }
            return false;
        } else if (query.trim().length >= 50) {
            setCompanyInputError(
                t('modal.createAccount.companySelect.maxLength', {
                    count: 50
                })
            );
            return false;
        } else {
            return true;
        }
    }

    return (
        <Select
            {...props}
            withinPortal
            clearable
            searchable
            label={t('modal.createAccount.companySelect.label')}
            placeholder={t('modal.createAccount.companySelect.placeholder')}
            creatable
            getCreateLabel={(query) =>
                t('modal.createAccount.companySelect.createNewCompanyLabel', { query })
            }
            searchValue={companySearchInput}
            onSearchChange={setCompanySearchInput}
            error={companyInputError}
            onCreate={(query: string) => {
                if (checkNewCompanyName(query)) {
                    setCompanyInputError(undefined);
                    return onCreate?.(query);
                } else {
                    return undefined;
                }
            }}
        />
    );
}
