import { Badge, ActionIcon, rem, Group, Text, createStyles } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { displayformatedTime } from '../../../utils/RelativeTimeFormat';
import { useFilterContext } from '../FilterContext';

function displayTimeRange(value: string) {
    if (value.trim() === '') return '';

    const [start, end] = value.split('-');
    const formatedStart = displayformatedTime(parseInt(start)).split(' ')[0];

    if (end) {
        const formatedEnd = displayformatedTime(parseInt(end)).split(' ')[0];
        return `${formatedStart} - ${formatedEnd}`;
    } else {
        return formatedStart;
    }
}
const useFilterTagStyle = createStyles((theme) => ({
    icon: {
        color: 'inherit',
        '&:hover': {
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[3],
            color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[7]
        }
    },
    root: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2],
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6]
    }
}));

export const FilterTag = ({
    filter,
    ...props
}: { filter: string } & React.HTMLAttributes<HTMLDivElement>) => {
    const { contextState, dispatch } = useFilterContext();

    if (!contextState) throw new Error('Filter Tag used outside of FilterContext');

    const [type, value] = filter.split(':');
    const formattedValue = type === 'date' ? displayTimeRange(value) : value;

    const { classes } = useFilterTagStyle();

    return (
        <Badge
            tt={'unset'}
            key={filter}
            pr={4}
            rightSection={
                <ActionIcon
                    className={classes.icon}
                    size={'xs'}
                    onClick={() => dispatch({ type: 'remove', filter })}>
                    <IconX size={rem(12)} />
                </ActionIcon>
            }
            variant={'light'}
            color={'gray'}
            className={classes.root}
            radius={'sm'}
            size={'md'}
            {...props}>
            <Group spacing={4}>
                <Text tt={'capitalize'}>{type} |</Text>
                <Text>{formattedValue}</Text>
            </Group>
        </Badge>
    );
};
