import { Grid } from '@mantine/core';
import React, { useCallback } from 'react';

export interface FormProps extends React.PropsWithChildren {
    onSubmit?: () => void;
    className?: string;
}

function Form(props: FormProps) {
    const onSubmit = props.onSubmit;
    const handleSubmit = useCallback(
        (event: React.FormEvent) => {
            event.preventDefault();
            onSubmit?.();
        },
        [onSubmit]
    );

    return (
        <form onSubmit={handleSubmit} className={props.className}>
            <Grid columns={2} h="100%">
                {props.children}
            </Grid>
        </form>
    );
}

export default Form;
