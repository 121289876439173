import { useEffect, useReducer, useRef } from 'react';
import useLoader from '../../hooks/useLoader';
import { BaseModalData } from '../../types/Modals';
import { ChatAddPage, NewThreadFormData } from './index';
import { ChatId, Contact, DraftId, Email, User, UsernameOrContactId } from '../../types/Types';
import { useCallback } from 'react';
import { Box, Modal, Transition, Text } from '@mantine/core';

import ThreadAdded from '../../atoms/Modals/ThreadCreated';
import ThreadAddModalLoader from '../../atoms/Modals/ThreadAddLoader';
import { useNewThreadStyles } from '../../atoms/Modals/newThreadStyles';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../store';
import { toggleNewMessageModal } from '../../store/ModalsSlice';
import { useTranslation } from 'react-i18next';
import StateView from '../../atoms/State';
import { setUsers } from '../../store/UsersSlice';
import { useMediaQuery } from '@mantine/hooks';

export interface ChatAddModalData extends BaseModalData {
    users?: UsernameOrContactId[];
    draftId?: DraftId;
    formSnapshot?: NewThreadFormData;
    firstMessage?: string;
}

export interface FailedUser {
    email: Email;
    reason: 'alreadyExists' | 'unknown';
}

export interface CreationProcessType {
    state: 'form' | 'created';
    payload?: {
        createdUsers?: Map<User['username'], { email: User['email']; password: string }>;
        privateContactsMadeVisible?: Contact[];
        failedUsers?: FailedUser[];
        title: string;
        id?: ChatId;
    };
}

export type CreationMessageAction =
    | { type: 'setState'; payload: CreationProcessType['state'] }
    | { type: 'setPayload'; payload: CreationProcessType['payload'] }
    | { type: 'reset' };

function reducer(state: CreationProcessType, action: CreationMessageAction) {
    const newState = { ...state };

    switch (action.type) {
        case 'setState':
            newState.state = action.payload;
            break;
        case 'setPayload':
            newState.payload = action.payload;
            break;
        case 'reset':
            newState.state = 'form';
            newState.payload = { title: '', id: '' as any };
    }

    return newState;
}

export function ChatAddModal(props: {
    onClose: () => void;
    opened: boolean;
    data: ChatAddModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const ref = useRef();
    const { state, revalidate } = useLoader(
        useCallback((x) => x.loadChatForAdding(props.data.draftId), [props.data.draftId])
    );

    const dispatch = useAppDispatch();

    const { classes, theme } = useNewThreadStyles();

    useEffect(() => {
        if (state.type === 'done') dispatch(setUsers(state.data.managers));
    }, [state, dispatch]);

    const [creationState, dispatchCreationState] = useReducer(reducer, { state: 'form' });
    const handleClose = useCallback(() => {
        props.onClose();
        dispatchCreationState({ type: 'reset' });
    }, [props]);
    const navigate = useNavigate();

    const isLarge = useMediaQuery(`(min-width:${theme.breakpoints.md})`);

    return (
        <Modal
            title={t('modal.topicAdd.title')}
            radius="md"
            size={isLarge ? '70%' : '100%'}
            opened={props.opened}
            keepMounted={false}
            transitionProps={{
                duration: 200,
                transition: 'slide-up',
                timingFunction: 'ease-in'
            }}
            onClose={handleClose}
            overlayProps={{
                opacity: 0.2,
                blur: '8px'
            }}
            styles={{
                body: {
                    height: '90vh',
                    [`@media (min-width:${theme.breakpoints.md})`]: {
                        height: 'calc(100% - 60px)'
                    }
                },
                content: {
                    height: '90vh',
                    [`@media (min-width:${theme.breakpoints.md})`]: {
                        height: '70vh'
                    }
                }
            }}
            classNames={{ content: classes.content, body: classes.body, inner: classes.inner }}>
            <StateView state={state} loading={ThreadAddModalLoader} retry={revalidate}>
                {(loadedData) => (
                    <>
                        <Transition
                            transition={'slide-right'}
                            mounted={creationState.state === 'form'}>
                            {(styles) => (
                                <Box style={styles} display="flex" h="100%">
                                    <ChatAddPage
                                        dispatchCreationAction={dispatchCreationState}
                                        ref={ref}
                                        chatAdd={loadedData as any}
                                        handleClose={props.onClose}
                                        initialUsers={props.data.users}
                                        initialMessage={props.data.firstMessage}
                                        initialFormData={props.data.formSnapshot}
                                    />
                                </Box>
                            )}
                        </Transition>
                        <ThreadAdded
                            comunicateConfig={{
                                goToButton: t('action.goToThread'),
                                title:
                                    (creationState.payload?.createdUsers?.size ?? 0) > 0
                                        ? t('modal.topicAdd.sendPasswords')
                                        : t('modal.topicAdd.topicCreated'),
                                subTitle: (
                                    <>
                                        {t('youCanAccess')}{' '}
                                        <Text span weight="bolder">
                                            {creationState.payload?.title}
                                        </Text>{' '}
                                        {t('inTopicsSection')}
                                    </>
                                )
                            }}
                            onClose={() => dispatch(toggleNewMessageModal({ open: false }))}
                            onGoToThread={() => navigate(`./chat/${creationState.payload?.id}`)}
                            creationDispatch={dispatchCreationState}
                            state={creationState}
                        />
                    </>
                )}
            </StateView>
        </Modal>
    );
}
