import { Question } from '../../types/Types';
import { UrlBuilder } from '../../utils/UrlBuilder';
import { Utils } from '../../utils/Utils';
export const getInstructionCode = (
    apiUrl: string,
    inquiryId: string,
    fields: Question[]
) => `<!DOCTYPE html>
<html>
    <head>
        <title>privmx-simple-client demo</title>
        <meta charset="utf-8" />
        <script type="text/javascript" src="${UrlBuilder.buildUrl(
            'privmx-simple-client.js'
        )}"></script>
        <script type="text/javascript">
            async function getCaptcha(client) {
                const challenge = await client.createCaptcha();
                return new Promise((resolve) => {
                    const ele = document.createElement('div');
                    ele.setAttribute(
                        'style',
                        'position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: #00000088;'
                    );
                    ele.innerHTML = \`<div style="position: absolute; top: 100px; left: 50%; margin-left: -200px; width: 400px; padding: 20px; background-color: #fff; border: 1px solid #ddd;">
                    <div>Type 4 characters from image</div>
                    <div><img src="\${challenge.url}"/></div>
                    <div><input type="text" /></div>
                    <div style="margin-top: 10px; text-align: center;"><button>Commit</button></div>
                </div> \`;
                    document.body.append(ele);
                    ele.querySelector('button')?.addEventListener('click', () => {
                        const text = ele.querySelector('input')?.value;
                        const res = {
                            id: challenge.id,
                            text: text
                        };
                        ele.remove();
                        resolve(res);
                    });
                });
            
            }
        
            window.addEventListener("DOMContentLoaded", () => {
                const apiUrl = "${apiUrl}";
                const inquiryId = "${inquiryId}";
                const client = new window.PrivMxSimpleClient.Client({
                    apiUrl: apiUrl,
                });
                const initPromise = client.init();
                
                const form = document.getElementById("demo-form");
                const extractor = new window.PrivMxSimpleClient.utils.FormEntriesExtractor(form);
                form.addEventListener("submit", async evt => {
                    evt.preventDefault();
                    const inquiryService = await client.getInquiryService();
                    const publicInquiry = await inquiryService.getInquiryPublicView(inquiryId);
                    const entries = extractor.extractFormEntries();
                    // You can perform some extra validation of entries here
                    initPromise.then(async () => {
                        let success = false;
                        try {
                            if (publicInquiry.raw.captchaEnabled) {
                                const captchaResult = await getCaptcha(client);
                                success = await inquiryService.submitInquiryFromFormEntries(inquiryId, entries, captchaResult);
                            }
                            else {
                                success = await inquiryService.submitInquiryFromFormEntries(inquiryId, entries);
                            }
                        }
                        catch (err) {
                            console.error(err);
                        }
                        if (success) {
                            alert("Form submitted");
                        }
                        else {
                            alert("Could not submit the form");
                        }
                    });
                });
            });
        </script>
    </head>
    <body>
        <form id="demo-form">${fields.map((x) => getFieldCode(x)).join('')}
            <button type="submit" class="btn btn-primary">Send</button>
        </form>
    </body>
</html>`;

export const getInstructionCodeSimple = (
    apiUrl: string,
    inquiryId: string,
    fields: Question[]
) => `<!DOCTYPE html>
<html>
    <head>
        <title>privmx-simple-client demo</title>
        <meta charset="utf-8" />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
        <script type="text/javascript" src="${UrlBuilder.buildUrl('formsRenderer.js')}"></script>
        <script type="text/javascript">
            PrivMXContactForms.initForm({inquiryId: "${inquiryId}", apiUrl: "${apiUrl}", targetSelector: "#form",
            onSuccess: () => {
                console.log("callback on success")
            },
            onError: (error) => {
                console.log("error", error)
            }
        })
        </script>
    </head>
    <body>
        <div id="form"></div>
    </body>
</html>
`;

function getFieldCode(field: Question) {
    if (field.type === 'short') {
        return getShortTextInputCode(field.id, field.title, field.required);
    } else if (field.type === 'long') {
        return getLongTextInputCode(field.id, field.title, field.required);
    } else if (field.type === 'phone') {
        return getPhoneInputCode(field.id, field.title, field.required);
    } else if (field.type === 'email') {
        return getEmailInputCode(field.id, field.title, field.required);
    } else if (field.type === 'single' || field.type === 'select') {
        const options = field.answer.map((x) => ({
            id: x.id,
            title: x.input as string
        }));
        return getSelectCode(
            field.id,
            field.title,
            field.type === 'select',
            field.required,
            options
        );
    } else if (field.type === 'block') {
        return getTextBlockCode(field.title, field.content);
    }
}

const getShortTextInputCode = (id: string, title: string, required: boolean) => {
    const escapedId = Utils.escapeAttributeValue(id);
    const escapedTitle = Utils.escapeHtml(title);
    return `
            <div class="form-group">
                <label for="form-field-${escapedId}">${escapedTitle}</label>
                <input id="form-field-${escapedId}" type="text" name="${escapedId}"${
        required ? ' required' : ''
    } />
            </div>`;
};

const getLongTextInputCode = (id: string, title: string, required: boolean) => {
    const escapedId = Utils.escapeAttributeValue(id);
    const escapedTitle = Utils.escapeHtml(title);
    return `
            <div class="form-group">
                <label for="form-field-${Utils.escapeAttributeValue(id)}">${escapedTitle}</label>
                <textarea id="form-field-${escapedId}" name="${escapedId}"${
        required ? ' required' : ''
    }></textarea>
            </div>`;
};

const getTextBlockCode = (title: string, content: string) => {
    const escapedTitle = Utils.escapeHtml(title);
    return `
        <div class="form-group">
            <div class="text-block label">${escapedTitle}</div>
            <div class="text-block content">${content}</div>
        </div>`;
};

const getPhoneInputCode = (id: string, title: string, required: boolean) => {
    const escapedId = Utils.escapeAttributeValue(id);
    const escapedTitle = Utils.escapeHtml(title);
    return `
            <div class="form-group">
                <label for="form-field-${escapedId}">${escapedTitle}</label>
                <input id="form-field-${escapedId}" type="text" aria-field-type="phone" name="${escapedId}"${
        required ? ' required' : ''
    } />
            </div>`;
};

const getEmailInputCode = (id: string, title: string, required: boolean) => {
    const escapedId = Utils.escapeAttributeValue(id);
    const escapedTitle = Utils.escapeHtml(title);
    return `
            <div class="form-group">
                <label for="form-field-${escapedId}">${escapedTitle}</label>
                <input id="form-field-${escapedId}" type="text" aria-field-type="email" name="${escapedId}"${
        required ? ' required' : ''
    } />
            </div>`;
};

const getSelectCode = (
    id: string,
    title: string,
    multiple: boolean,
    required: boolean,
    options: Array<{ id: string; title: string }>
) => {
    const escapedId = Utils.escapeAttributeValue(id);
    const escapedTitle = Utils.escapeHtml(title);
    return `
            <div class="form-group">
                <label for="form-field-${escapedId}">${escapedTitle}</label>
                <select id="form-field-${escapedId}" name="${escapedId}"${
        multiple ? ' multiple' : ''
    }${required ? ' required' : ''}>${options.map((x) => getSelectOptionCode(x.id, x.title))}
                </select>
            </div>`;
};

const getSelectOptionCode = (id: string, title: string) => `
                    <option value="${Utils.escapeAttributeValue(id)}">${Utils.escapeHtml(
    title
)}</option>`;
