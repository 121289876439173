import Modal from '../../atoms/Modal';
import StateView from '../../atoms/State';
import { BaseModalData } from '../../types/Modals';
import { SharedFilePageView } from './index';
import { useCallback } from 'react';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { SharedFileId } from '../../types/Types';
import { useTranslation } from 'react-i18next';

export interface SharedFileModalData extends BaseModalData {
    id: SharedFileId;
}

export function SharedFileModal(props: {
    onClose: () => void;
    data: SharedFileModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForSharedFile(props.data.id), [props.data.id])
    );
    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            height={props.data.height ?? 800}
            width={props.data.width}
            title={
                props.data.title ??
                (state.type === 'done' &&
                state.data.sharedFile &&
                state.data.sharedFile.userData.title
                    ? t('modal.sharedFile.titleWithName', {
                          name: state.data.sharedFile.userData.title
                      })
                    : t('modal.sharedFile.titleWithoutName'))
            }>
            <StateView state={state} retry={revalidate}>
                {(data) =>
                    data.sharedFile ? (
                        <SharedFilePageView sharedFile={data.sharedFile} onRefresh={revalidate} />
                    ) : (
                        <div>{t('errorMessage.fileDoesNotExist')}</div>
                    )
                }
            </StateView>
        </Modal>
    );
}
