import { JobManager } from './JobManager';
import { KeyValueStorage } from './KeyValueStorage';
import { MessageFullX, MessageDataSigned } from './ThreadService';
import * as privmx from 'privfs-client';
import { EccUtils } from './EccUtils';
import { EccPubKey, PkiService } from './PkiService';
import * as PmxApi from 'privmx-server-api';

export type VerifyMessageResult = 'verified' | 'no-signature' | 'invalid';
export interface EventDispatcher {
    dispatchEvent<T extends { type: string }>(event: T): void;
}
export interface MessageVerifiedStatusChangedCoreEvent {
    type: 'messageverifiedstatuschangedcore';
    message: MessageFullX;
    verified: VerifyMessageResult;
}

export class MessageVerifier {
    private keyValueStorage: KeyValueStorage;
    private jobManager = new JobManager();

    constructor(
        private pkiService: PkiService,
        private eventDispatcher: EventDispatcher,
        private cachePrefix: string
    ) {
        this.keyValueStorage = new KeyValueStorage(this.cachePrefix);
    }

    getVerifyStatus(message: MessageFullX) {
        return this.keyValueStorage.get<VerifyMessageResult>(this.getStorageKey(message));
    }

    verifyMessage(message: MessageFullX, startJustNow?: boolean) {
        return this.jobManager.startJob(
            `verify-message-${message.sig.server}-${message.msg.id}`,
            async () => {
                const storageKey = this.getStorageKey(message);
                const result = this.keyValueStorage.get<VerifyMessageResult>(storageKey);
                if (result != null) {
                    return result;
                }
                const res = await this.verifyMessageCore(message.sig);
                this.keyValueStorage.set(storageKey, res);
                this.eventDispatcher.dispatchEvent<MessageVerifiedStatusChangedCoreEvent>({
                    type: 'messageverifiedstatuschangedcore',
                    message: message,
                    verified: res
                });
                return res;
            },
            startJustNow
        );
    }

    private getStorageKey(message: MessageFullX) {
        return `msgverified:${message.sig.server}/${message.msg.threadId}/${message.msg.id}/${message.msg.edits.length}`;
    }

    private async verifyMessageCore(message: MessageDataSigned): Promise<VerifyMessageResult> {
        if (message.signature.length === 0) {
            return 'no-signature';
        }
        try {
            const isMessageByAnonymousUser = this.isMessageByAnonymousUser(message);
            const pubKey = isMessageByAnonymousUser
                ? this.getAnonymousAuthorPubKey(message)
                : await this.pkiService.getUserPubKeyUsingCache(
                      message.data.author.username,
                      message.data.author.server
                  );
            if (!EccUtils.publicKeysAreEqual(pubKey.getBase58Der(), message.data.author.pubKey)) {
                return 'invalid';
            }
            const result = await privmx.crypto.service.verifyCompactSignatureWithHash(
                pubKey.getPublicKey(),
                message.dataBuf,
                message.signature
            );
            return result ? 'verified' : 'invalid';
        } catch (e) {
            console.error('Error during message verification', e);
            return 'invalid';
        }
    }

    private isMessageByAnonymousUser(message: MessageDataSigned) {
        return (message.data.author.pubKey as string) === (message.data.author.username as string);
    }

    private getAnonymousAuthorPubKey(message: MessageDataSigned) {
        const b58 = message.data.author.username as string as PmxApi.api.core.EccPubKey;
        return new EccPubKey(b58, privmx.crypto.ecc.PublicKey.fromBase58DER(b58));
    }
}
