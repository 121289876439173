import React from 'react';
import { useAppSelector } from '../../../store';
import { selectPostAddContactsModalState } from '../../../store/ModalsSlice';
import { PostAddContactsModal } from './PostAddContactsModal';

export function PostAddContactsModalOutlet() {
    const postAddContactsState = useAppSelector(selectPostAddContactsModalState);

    return <> {postAddContactsState.opened && <PostAddContactsModal />}</>;
}
