import * as privmx from 'privfs-client';
import * as PmxApi from 'privmx-server-api';
import { MessageClientId, Mimetype } from '../../types/Types';
import { EncKey } from './KeyProvider';
import { MessageAttachment, MessageData, MessageDataSigned, ServerId } from './ThreadService';

export class MessageDataEncryptor {
    async signAndEcrypt(data: MessageData, priv: privmx.crypto.ecc.PrivateKey, key: EncKey) {
        const buffer = Buffer.from(JSON.stringify(data), 'utf8');
        const signature = await privmx.crypto.service.signToCompactSignatureWithHash(priv, buffer);
        const plain = Buffer.concat([
            Buffer.from([1]),
            Buffer.from([signature.length]),
            signature,
            buffer
        ]);
        const cipher = await privmx.crypto.service.privmxEncrypt(
            privmx.crypto.service.privmxOptAesWithSignature(),
            plain,
            key.key
        );
        return cipher.toString('base64') as PmxApi.api.thread.MessageData;
    }

    async decrypt(
        data: PmxApi.api.thread.MessageData,
        key: EncKey,
        info: { threadId: PmxApi.api.thread.ThreadId; server: ServerId }
    ): Promise<MessageDataSigned> {
        const plain = await privmx.crypto.service.privmxDecrypt(
            privmx.crypto.service.privmxOptSignedCipher(),
            Buffer.from(data, 'base64'),
            key.key
        );
        if (plain[0] === 1) {
            const signatureLength = plain[1];
            const signature = plain.slice(2, 2 + signatureLength);
            const dataBuf = plain.slice(2 + signatureLength);
            const data = JSON.parse(dataBuf.toString('utf8')) as MessageData;
            return { server: info.server, signature, dataBuf, data };
        }
        if (plain[0] === 123) {
            const data = JSON.parse(plain.toString('utf8')) as {
                v: 1;
                msgId: MessageClientId;
                type: Mimetype;
                text: string;
                date: number;
                deleted?: boolean;
                author: PmxApi.api.core.Username;
                attachments: MessageAttachment[];
            };
            return {
                server: info.server,
                signature: Buffer.alloc(0),
                dataBuf: Buffer.alloc(0),
                data: {
                    v: 2,
                    msgId: data.msgId,
                    type: data.type,
                    text: data.text,
                    date: data.date,
                    deleted: data.deleted,
                    author: {
                        server: info.server,
                        username: data.author,
                        pubKey: '' as PmxApi.api.core.EccPubKey
                    },
                    destination: info,
                    attachments: data.attachments
                }
            };
        }
        throw new Error('Unsupported message type');
    }
}
