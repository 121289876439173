import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
    selectAccountAddedModalState,
    toggleAccountsAddedtModal
} from '../../../store/ModalsSlice';
import { AccountsAdded } from './AccountsAdded';

export function AccountsAddedOutlet() {
    const { addedUsers, privateContactsMadeVisible, open, onClose } = useAppSelector(
        selectAccountAddedModalState
    );
    const dispatch = useAppDispatch();
    const handleClose = useCallback(() => {
        dispatch(
            toggleAccountsAddedtModal({ open: false, addedUsers: [], subTitle: '', title: '' })
        );
        onClose?.();
    }, [dispatch, onClose]);

    return (
        <AccountsAdded
            key={addedUsers.at(0)?.[0]}
            visible={open}
            addedUsers={addedUsers}
            privateContactsMadeVisible={privateContactsMadeVisible}
            onClose={handleClose}
        />
    );
}
