import * as privmx from 'privfs-client';
import * as PmxApi from 'privmx-server-api';
import * as privmxVideoConferences from 'privmx-video-conferences';
import { redirect } from 'react-router-dom';
import { showVideoConferenceToast } from '../atoms/VideoConferenceToast';
import { EmojiIconName } from '../components/EmojiIcon/emojiIcons';
import { TwofaPromptModalData } from '../components/TwofaPrompt/TwofaPromptModal';
import { subscriptionRegistry } from '../hooks/useDataSubscribion';
import { ModalController, modalService } from '../hooks/useModal';
import { resetStoreOnLogout, store } from '../store';
import { initialState as emptyCurrentUserState, setCurrentUser } from '../store/CurrentUserSlice';
import {
    loadAttachmentsAsync,
    loadChatsAsync,
    loadFormsAsync,
    loadFormsWithSubmitsThatHaveChatsAsync,
    loadInquirySubmitThreadsAsync,
    loadMeetingsAsync,
    removeAttachment,
    removeFormSubmit,
    upsertAttachment,
    upsertChat,
    upsertForm,
    upsertFormSubmit,
    upsertInquirySubmitThread,
    upsertMeeting
} from '../store/DataCacheSlice';
import { modalPrompt, setTwofaResult, SingleChatMessage, TwofaResult } from '../store/ModalsSlice';
import { upsertAnonymousUser } from '../store/UsersSlice';
import { setAvailableRoom, VideoRoomInfo } from '../store/VideoSlice';
import * as types from '../types/Types';
import { Base64 as Base64Str } from '../utils/Base64';
import { CancelledByUserError } from '../utils/CancelledByUserError';
import { Deferred } from '../utils/Deferred';
import { FileChooser } from '../utils/FileChooser';
import { ThumbnailGenerator } from '../utils/ThumbnailGenerator';
import { Utils as Utils2 } from '../utils/Utils';
import { apiDelay } from './ApiUtils';
import { ConnectionChecker } from './connection/ConnectionChecker';
import { CredentialsHolder } from './connection/CredentialsHolder';
import { NetworkStatusService } from './connection/NetworkStatusService';
import { ReconnectService } from './connection/ReconnectService';
import { db, dbGenerator, getAvatarForUser } from './Db';
import { AdminDataDecryptorService } from './privmx/admin/AdminDataDecryptorService';
import { AdminDataV2Decryptor } from './privmx/admin/AdminDataV2Decryptor';
import { AdminDataV2Encryptor } from './privmx/admin/AdminDataV2Encryptor';
import { AdminKeyChecker } from './privmx/admin/AdminKeyChecker';
import { AdminKeyHolder } from './privmx/admin/AdminKeyHolder';
import { AdminKeySender } from './privmx/admin/AdminKeySender';
import { AdminRightService } from './privmx/admin/AdminRightService';
import { ResetPasswordService } from './privmx/admin/ResetPasswordService';
import { AddUserModelBuilder } from './privmx/admin/userCreation/api/AddUserModelBuilder';
import { ApiSerializer } from './privmx/admin/userCreation/api/ApiSerializer';
import { ManagableUserCreator } from './privmx/admin/userCreation/ManagableUserCreator';
import {
    AdminDataInner,
    AdminDataManagable,
    UserOriginContactFormDetails,
    UserOriginDetails
} from './privmx/admin/userCreation/Types';
import { UserCreationContextBuilder } from './privmx/admin/userCreation/UserCreationContextBuilder';
import { UserCreationService } from './privmx/admin/userCreation/UserCreationService';
import { AdminApi } from './privmx/AdminApi';
import { AnonInquiryService } from './privmx/AnonInquiryService';
import {
    AttachmentsToCopy,
    BufferOutputStream,
    DownloadOptions,
    DownloadProgressCallback,
    ExistingAttachmentsProvider,
    OutputStream
} from './privmx/AttachmentUtils';
import { CompanyApi } from './privmx/CompanyApi';
import { CompanyProps, CompanyService, PmxCompany } from './privmx/CompanyService';
import { ContactApi } from './privmx/ContactApi';
import { ContactService, PmxContact } from './privmx/ContactService';
import { DataEncryptor } from './privmx/DataEncryptor';
import {
    Draft,
    DraftService,
    PmxAttachment as PmxDraftAttachment,
    PmxAttachmentEx as PmxDraftAttachmentEx
} from './privmx/DraftService';
import { FeedApi } from './privmx/FeedApi';
import { HashmailResolver } from './privmx/HashmailResolver';
import { ImageTypeDetector } from './privmx/ImageTypeDetector';
import {
    Inquiry,
    InquiryService,
    InquirySubmit,
    PmxAttachment as PmxInquiryAttachment,
    PmxAttachmentEx as PmxInquiryAttachmentEx
} from './privmx/InquiryService';
import { EncKey, KeyProvider } from './privmx/KeyProvider';
import { InMemoryCache, KvdbCache } from './privmx/kvdb/KvdbCache';
import { KvdbCollection } from './privmx/kvdb/KvdbCollection';
import { KvdbCollectionManager } from './privmx/kvdb/KvdbCollectionManager';
import { KvdbStateService } from './privmx/kvdb/KvdbStateService';
import { KvdbSettingEntry, KvdbSettingEntryX, KvdbUtils } from './privmx/kvdb/KvdbUtils';
import { MessageService } from './privmx/MessageService';
import { MessageVerifiedStatusChangedCoreEvent, MessageVerifier } from './privmx/MessageVerifier';
import { PkiService } from './privmx/PkiService';
import { PrivmxConst } from './privmx/PrivmxConst';
import { SharedFileService } from './privmx/SharedFileService.ts';
import { StickerService, ThreadSticker } from './privmx/StickerService';
import { TagEncryptionKeys, TagEncryptionService } from './privmx/TagEncryptionService';
import {
    KvdbEntryType as TagsKvdbEntryType,
    MockUserSettingsKvdbForTagService,
    TagService
} from './privmx/TagService';
import {
    generateMessageId,
    MessageFull,
    MessageFullX,
    PmxAttachment,
    PmxAttachmentEx,
    ThreadInfo,
    ThreadService
} from './privmx/ThreadService';
import {
    ChallengeModel,
    ChallengeModelSerializable,
    TwofaEnableData,
    TwofaMethod
} from './privmx/TwofaApi';
import { TwofaService } from './privmx/TwofaService';
import { UnreadService } from './privmx/UnreadService';
import { Profile, UserPreferences } from './privmx/UserPreferences';
import { UserChangedCoreEvent, UserEntry, UserService } from './privmx/UserService';
import { Base64 } from './privmx/utils/Base64';
import { Result, Utils } from './privmx/utils/Utils';
import { VideoService } from './privmx/VideoService';
import { t } from 'i18next';
import { UserPollApi } from './privmx/UserPollApi';
import { UrlBuilder } from '../utils/UrlBuilder';
import { StateUpdater } from './StateUpdater';
import { DataCacheService } from './DataCacheService';
import { ModalsService } from './ModalsService';
import { ContactImportData } from '../utils/contactImporters/ContactImportData';
import { SharedDbChecker } from './privmx/admin/SharedDbChecker';
import { CosignersChecker } from './privmx/admin/CosignersChecker';
import { ThreadVerifiedStatusChangedCoreEvent, ThreadVerifier } from './privmx/ThreadVerifier';
import { RequestApi } from './privmx/RequestApi';
import { notifications } from '@mantine/notifications';
import { CaptchaApi } from './privmx/CaptchaApi';
import * as Version from '../Version';
import { UserApi } from './privmx/UserApi';
import { ConfigApi } from './privmx/ConfigApi';
import { CustomizationApi } from './privmx/CustomizationApi';

export interface Session {
    onboardingPassed: boolean;
    userData: privmx.types.core.UserDataEx;
    threadService: ThreadService;
    videoService: VideoService;
    inquiryService: InquiryService;
    draftService: DraftService;
    messageVerifier: MessageVerifier;
    threadVerifier: ThreadVerifier;
    userSettingsKvdb: KvdbCollection<KvdbSettingEntryX>;
    sharedKvdb: KvdbCollection<KvdbSettingEntryX>;
    userCreationService: UserCreationService;
    managableUserCreator: ManagableUserCreator;
    resetPasswordService: ResetPasswordService;
    adminRightService: AdminRightService;
    pkiService: PkiService;
    userService: UserService;
    tagEncryptionService: TagEncryptionService;
    tagService: TagService;
    stickerService: StickerService;
    connectionChecker: ConnectionChecker;
    credentialsHolder: CredentialsHolder;
    reconnectService: ReconnectService;
    networkStatusService: NetworkStatusService;
    adminDataDecryptorService: AdminDataDecryptorService;
    twofaService: TwofaService;
    unreadService: UnreadService;
    contactService: ContactService;
    companyService: CompanyService;
    companiesCache: Map<types.CompanyId, types.Company>;
    contactsCache: Map<types.ContactId, types.Contact>;
    sharedFileService: SharedFileService;
    requestConfig: PmxApi.api.request.RequestConfig;
    serverConfig: PmxApi.api.config.ServerConfig;
}

export interface AnonSession {
    gateway: privmx.gateway.RpcGateway;
    identity: privmx.identity.Identity;
    pub: PmxApi.api.core.EccPubKey;
    anonInquiryService: AnonInquiryService;
    threadService: ThreadService;
    videoService: VideoService;
    messageVerifier: MessageVerifier;
    threadVerifier: ThreadVerifier;
    connectionChecker: ConnectionChecker;
    reconnectService: ReconnectService;
    networkStatusService: NetworkStatusService;
    tagEncryptionService: TagEncryptionService;
    tagService: TagService;
    stickerService: StickerService;
    userService: UserService;
    sharedFileService: SharedFileService;
    nickname?: PmxApi.api.thread.MeetingNickname;
    threadLinkData: types.ThreadLinkData | null;
    formThreadPassword: string | null;
    requestConfig: PmxApi.api.request.RequestConfig;
}

interface TagKeySerialized {
    iv: PmxApi.api.core.Base64;
    hmacKey: PmxApi.api.core.Base64;
    key: PmxApi.api.core.Base64;
}

function getGatewayHost() {
    const host = process.env.REACT_APP_PRIVMX_SERVER_DOMAIN || document.location.hostname;
    return host;
}

window.addEventListener('privmx-client-script-loaded', () => {
    const apiHost = process.env.REACT_APP_PRIVMX_API_HOST;
    if (!apiHost) {
        return;
    }
    const protocol = process.env.REACT_APP_PRIVMX_API_PROTOCOL || window.location.protocol;
    const host = getGatewayHost();
    privmx.core.PrivFsRpcManager.urlMap[
        `${host}:v2.0`
    ] = `${protocol}//${apiHost}/d/${host}/api/v2.0`;
    privmx.core.PrivFsRpcManager.rpcConfigEnhancer = (config) => {
        if (!config.websocketOptions) {
            config.websocketOptions = {};
        }
        config.websocketOptions.url = `${
            protocol === 'https:' ? 'wss:' : 'ws:'
        }//${apiHost}/ws/${host}/`;
        return config;
    };
});

export class Api {
    private session: Session | null = null;
    private anonSession: AnonSession | null = null;
    private anonSessionCreationPromise: Promise<AnonSession> | null = null;
    private privmxScriptLoadedDeferred: Deferred<void> | null = null;
    private listeners = new Map<string, ((event: any) => void)[]>();

    constructor() {
        this.addEventListener('privatetagschange', async (e) => {
            if (e.targetType === 'thread') {
                const anySession = this.anonSession ? this.getAnonSession() : this.getSession();
                const threadService = anySession.threadService;

                const thread = await threadService.getThread(
                    e.targetId as PmxApi.api.thread.ThreadId
                );
                const threadInfo = await threadService.decryptThread(thread);
                if (threadInfo.thread.type === 'chat') {
                    store.dispatch(upsertChat(this.convertThreadToChat(threadInfo)));
                } else if (threadInfo.thread.type === 'meeting') {
                    store.dispatch(upsertMeeting(this.convertThreadToMeeting(threadInfo)));
                } else if (threadInfo.thread.type === 'inquirySubmit') {
                    store.dispatch(upsertInquirySubmitThread(this.convertThreadToChat(threadInfo)));
                }
            }
        });
    }

    getVersion() {
        return Version.version;
    }

    getRequestConfig() {
        if (this.session) {
            return this.session.requestConfig;
        }
        if (this.anonSession) {
            return this.anonSession.requestConfig;
        }
        throw new Error('Session is not established yet');
    }

    generateMessageId() {
        return generateMessageId();
    }

    async activateFirstAccount(token: string, email: string, password: string) {
        try {
            const host = this.getGatewayHost();
            const srp = await privmx.core.PrivFsRpcManager.getSrpRegisterService({
                identityIndex: PrivmxConst.IDENTITY_INDEX,
                host: host,
                rpcConfigEnhancer: (cfg) => {
                    cfg.websocket = false;
                    return cfg;
                }
            });
            const login = email;
            const username = this.generateUsernameFromContactCore({ email: email, name: 'admin' });
            await srp.registerEx({
                username: username,
                host: host,
                login: login,
                password: password,
                email: email,
                language: 'en',
                token: token,
                weakPassword: password.length < 8
            });
        } catch (e) {
            console.error('Error during first login', e);
        }
    }

    async addFavorite(favoriteMessage: types.FavoriteMessage) {
        await this.insertFavoritesInPrivmx(favoriteMessage);
    }

    async removeFavorite(favoriteMessage: types.FavoriteMessage) {
        await this.deleteFavoritesFromPrivmx(favoriteMessage);
    }

    async getFavorites() {
        const favorites = await this.getFavoritesFromPrivmx();
        return favorites;
    }

    private async insertFavoritesInPrivmx(favorite: types.FavoriteMessage) {
        const { userSettingsKvdb } = this.getSession();
        await userSettingsKvdb.withLock(PrivmxConst.FAVORITE_KEY, (content) => {
            const list =
                content === false ? [] : (content.secured.value as types.FavoriteMessage[]).slice();
            if (list.findIndex((x) => x.messageId === favorite.messageId) === -1) {
                list.push(favorite);
            } else {
                return null;
            }
            return KvdbUtils.createKvdbSettingEntry(list);
        });
    }

    private async deleteFavoritesFromPrivmx(favorite: types.FavoriteMessage) {
        const { userSettingsKvdb } = this.getSession();
        await userSettingsKvdb.withLock(PrivmxConst.FAVORITE_KEY, (content) => {
            if (content === false) return null;
            const list = (content.secured.value as types.FavoriteMessage[]).slice();
            const index = list.findIndex((x) => x.messageId === favorite.messageId);
            if (index === -1) return null;
            list.splice(index, 1);
            return KvdbUtils.createKvdbSettingEntry(list);
        });
    }

    private async getFavoritesFromPrivmx() {
        if (this.anonSession) {
            return [];
        }
        const { userSettingsKvdb } = this.getSession();
        const res = await userSettingsKvdb.get(PrivmxConst.FAVORITE_KEY);
        return res ? (res.secured.value as types.FavoriteMessage[]).slice() : [];
    }

    getMessagesWithThreadsRaw(messageIds: PmxApi.api.thread.MessageId[]) {
        if (this.anonSession) {
            return { messages: [], threads: [] };
        }
        const { threadService } = this.getSession();
        return threadService.getMessagesWithThreads(messageIds);
    }

    async getMessagesWithChats(messageIds: PmxApi.api.thread.MessageId[]) {
        if (this.anonSession) {
            return { messages: [], chats: [] };
        }
        const { tagEncryptionService, stickerService, messageVerifier } = this.getSession();
        const favorites = await this.getFavorites();
        const { messages: messagesRaw, threads: threadsRaw } = await this.getMessagesWithThreadsRaw(
            messageIds
        );
        const messages: types.Message[] = await Promise.all(
            messagesRaw.map((message) =>
                this.convertMessage(
                    message,
                    tagEncryptionService,
                    stickerService,
                    messageVerifier,
                    favorites
                )
            )
        );
        const chats: types.Chat[] = threadsRaw.map((x) => this.convertThreadToChat(x));
        return { messages, chats };
    }

    async getFavoriteChatMessages() {
        if (this.anonSession) {
            return [];
        }
        const { tagEncryptionService, stickerService, messageVerifier } = this.getSession();
        const favorites = await this.getFavorites();
        const { messages, threads } = await this.getMessagesWithThreadsRaw(
            favorites.map((x) => x.messageId)
        );
        const fullFavoriteMessages: types.FullFavoriteMessage[] = [];
        for (const fav of favorites) {
            const message = messages.find((x) => x.msg.id === fav.messageId);
            const thread = threads.find((x) => x.thread.id === message?.msg.threadId);
            if (message && thread) {
                fullFavoriteMessages.push({
                    chat: this.convertThreadToChat(thread),
                    message: await this.convertMessage(
                        message,
                        tagEncryptionService,
                        stickerService,
                        messageVerifier,
                        favorites
                    )
                });
            }
        }
        return fullFavoriteMessages;
    }

    async addChat(
        chatModel: types.ChatCreateModel,
        msgId: types.MessageClientId,
        firstMessage: types.MessageCreateModelAtt
    ) {
        const {
            allUsernames: users,
            newUsernames,
            newAccounts
        } = await api.createMissingInternalUsersFromContacts(chatModel.users, {
            type: 'chatEditor',
            chatId: '' as types.ChatId
        });
        const { privateContactsMadeVisible } =
            await this.ensurePrivateContactsAreVisibleToCurrentUser(chatModel.users);
        const { managableUserCreator, threadService } = this.getSession();
        const thread = await threadService.createThread(
            'chat' as PmxApi.api.thread.ThreadType,
            chatModel.title,
            {},
            chatModel.tags,
            users,
            chatModel.managers
        );
        const threadInfo = await threadService.decryptThread(thread);
        if (newUsernames.length > 0) {
            await managableUserCreator.updateUsersOriginDetails(newUsernames, {
                type: 'chatEditor',
                chatId: thread.id as types.ChatId
            });
        }
        const existingDraftAttachments = firstMessage.attachments.filter(
            (x) => 'draftId' in x
        ) as types.DraftAttachmentEx[];
        const newAttachments = firstMessage.attachments.filter(
            (x) => !('draftId' in x)
        ) as types.PreparedAttachment[];
        const attachments = newAttachments.map((x) => x.file);
        await this.sendPrivmxMessage(
            threadService,
            threadInfo,
            firstMessage.mimetype,
            firstMessage.text,
            msgId,
            attachments,
            existingDraftAttachments
        );
        return {
            newChat: this.convertThreadToChat(threadInfo),
            newAccounts,
            privateContactsMadeVisible
        };
    }

    async addCompany(
        company: CompanyProps & { tags: types.Tag[]; archived: boolean; pinned: boolean }
    ) {
        const { companyService, tagService } = this.getSession();
        const tags = tagService.readAllTagsFromTaggableEntity(company);
        const info = await companyService.createCompany(
            {
                name: company.name,
                phone: company.phone,
                mobilePhone: company.mobilePhone,
                address: company.address,
                note: company.note,
                email: company.email,
                website: company.website
            },
            tags
        );
        return this.convertPmxCompany(info);
    }

    async asssignCompanyToUsers(companyId: types.Company['id'], users: types.Contact[]) {
        return await Promise.all(users.map((user) => this.updateContact({ ...user, companyId })));
    }

    async removeCompanyFormUser(users: types.Contact[]) {
        return await Promise.all(
            users.map((user) => this.updateContact({ ...user, companyId: undefined }))
        );
    }

    private convertPmxCompany(company: PmxCompany) {
        const { tagService } = this.getAnySession();
        const res: types.Company = {
            id: company.raw.id,
            name: company.props.name,
            email: company.props.email,
            phone: company.props.phone,
            mobilePhone: company.props.mobilePhone,
            address: company.props.address,
            note: company.props.note,
            website: company.props.website,
            createdBy: company.raw.creator,
            createdDate: company.raw.created,
            lastModifiedBy: company.raw.lastModifier,
            lastModifiedDate: company.raw.lastModified,
            ...tagService.mapTagsToTaggableEntity(company.tags)
        };
        return res;
    }

    async updateCompany(company: types.Company) {
        const { companyService, tagService } = this.getSession();
        const tags = tagService.readAllTagsFromTaggableEntity(company);
        const info = await companyService.updateCompany(
            company.id,
            {
                name: company.name,
                phone: company.phone,
                mobilePhone: company.mobilePhone,
                address: company.address,
                note: company.note,
                email: company.email,
                website: company.website
            },
            tags
        );
        return this.convertPmxCompany(info);
    }

    async setCompanyPinned(companyId: types.CompanyId, pinned: boolean) {
        const { companyService } = this.getSession();
        await companyService.toggleCompanyTag(companyId, TagService.SYSTEM_TAG_PINNED, pinned);
    }

    async setCompanyArchived(companyId: types.CompanyId, archived: boolean) {
        const { companyService } = this.getSession();
        await companyService.toggleCompanyTag(companyId, TagService.SYSTEM_TAG_ARCHIVED, archived);
    }

    setThreadLinkData(data: types.ThreadLinkData | null) {
        if (this.anonSession) {
            this.anonSession.threadLinkData = data;
        }
    }

    setFormThreadPassword(password: string | null) {
        if (this.anonSession) {
            this.anonSession.formThreadPassword = password;
        }
    }

    async addMeeting(
        meetingModel: types.MeetingCreateModel,
        msgId: types.MessageClientId,
        firstMessage:
            | types.MessageCreateModelAtt
            | ((meetingId: types.MeetingId) => types.MessageCreateModelAtt)
    ) {
        const {
            allUsernames: users,
            newUsernames,
            newAccounts
        } = await api.createMissingInternalUsersFromContacts(meetingModel.users, {
            type: 'meetingEditor',
            meetingId: '' as types.MeetingId
        });
        const { privateContactsMadeVisible } =
            await this.ensurePrivateContactsAreVisibleToCurrentUser(meetingModel.users);
        const { managableUserCreator, threadService } = this.getSession();
        const thread = await threadService.createThread(
            'meeting' as PmxApi.api.thread.ThreadType,
            meetingModel.title,
            {
                startDate: meetingModel.startDate,
                duration: meetingModel.duration
            },
            meetingModel.tags,
            users,
            meetingModel.managers
        );
        if (typeof firstMessage === 'function') {
            firstMessage = firstMessage(thread.id as types.MeetingId);
        }
        const threadInfo = await threadService.decryptThread(thread);
        if (newUsernames.length > 0) {
            await managableUserCreator.updateUsersOriginDetails(newUsernames, {
                type: 'meetingEditor',
                meetingId: thread.id as types.MeetingId
            });
        }
        const attachments = (firstMessage.attachments as types.PreparedAttachment[]).map(
            (x) => x.file
        );
        await this.sendPrivmxMessage(
            threadService,
            threadInfo,
            firstMessage.mimetype,
            firstMessage.text,
            msgId,
            attachments
        );
        return {
            newMeeting: this.convertThreadToChat(threadInfo),
            newAccounts,
            privateContactsMadeVisible
        };
    }

    async deleteAttachment(_attachmentId: types.AttachmentId): Promise<void> {
        await apiDelay();
    }

    async getChats(): Promise<types.Chat[]> {
        return this.getPrivmxChats();
    }

    private async getPrivmxChats(): Promise<types.Chat[]> {
        const { threadService } = this.getSession();
        const threads = await threadService.getThreads('chat' as PmxApi.api.thread.ThreadType);
        return Promise.all(
            threads.map(async (x) => {
                const thread = await threadService.decryptThread(x);
                return this.convertThreadToChat(thread);
            })
        );
    }

    async getChatsOfUser(user: types.Username) {
        const { threadService } = this.getSession();
        const threads = await threadService.getThreadsOfUser(
            'chat' as PmxApi.api.thread.ThreadType,
            user
        );
        return Promise.all(
            threads.map(async (x) => {
                const thread = await threadService.decryptThread(x);
                return this.convertThreadToChat(thread);
            })
        );
    }

    async getChatsOfComapny(companyId: types.CompanyId) {
        const { threadService } = this.getSession();
        const threads = await threadService.getThreadsOfCompany(
            'chat' as PmxApi.api.thread.ThreadType,
            companyId
        );
        return Promise.all(
            threads.map(async (x) => {
                const thread = await threadService.decryptThread(x);
                return this.convertThreadToChat(thread);
            })
        );
    }

    async getMeetingsOfUser(user: types.Username) {
        const { threadService } = this.getSession();
        const threads = await threadService.getThreadsOfUser(
            'meeting' as PmxApi.api.thread.ThreadType,
            user
        );
        return Promise.all(
            threads.map(async (x) => {
                const thread = await threadService.decryptThread(x);
                return this.convertThreadToMeeting(thread);
            })
        );
    }

    async getMeetingsOfComapny(comapnyId: types.CompanyId) {
        const { threadService } = this.getSession();
        const threads = await threadService.getThreadsOfCompany(
            'meeting' as PmxApi.api.thread.ThreadType,
            comapnyId
        );
        return Promise.all(
            threads.map(async (x) => {
                const thread = await threadService.decryptThread(x);
                return this.convertThreadToMeeting(thread);
            })
        );
    }

    getThreadUsernames(threadId: types.ThreadId) {
        const state = store.getState();
        const threads = [
            ...state.dataCache.chats,
            ...state.dataCache.meetings,
            ...state.dataCache.inquirySubmitThreads
        ];
        const usernames = threads.find((x) => x.id === threadId)?.users ?? [];
        const users = usernames.map((x) => {
            const contact = this.getContactByUsernameSync(x);
            if (contact) {
                const contactIdOpt: types.ContactIdOpt = { type: 'contact', contactId: contact.id };
                return contactIdOpt;
            } else {
                const usernameOpt: types.UsernameOpt = { type: 'user', username: x };
                return usernameOpt;
            }
        });
        const managers = threads.find((x) => x.id === threadId)?.managers ?? [];
        return { users, managers };
    }

    async getInquirySubmitThreads(): Promise<types.Chat[]> {
        return this.getPrivmxInquirySubmitThreads();
    }

    private async getPrivmxInquirySubmitThreads(): Promise<types.Chat[]> {
        const { threadService } = this.getSession();
        const threads = await threadService.getThreads(
            'inquirySubmit' as PmxApi.api.thread.ThreadType
        );
        return Promise.all(
            threads.map(async (x) => {
                const thread = await threadService.decryptThread(x);
                return this.convertThreadToChat(thread);
            })
        );
    }

    async getPrivmxInquirySubmitsAndAttachments(inquiryId: PmxApi.api.inquiry.InquiryId) {
        const { inquiryService } = this.getSession();
        const inquiry = await inquiryService.getInquiry(inquiryId);
        const submits = await inquiryService.getSubmits(inquiry);
        const attachments = await inquiryService.getInquiryAttachments(inquiry);
        return {
            inquiry: inquiry,
            submits: submits,
            attachments: attachments
                .map((x) => (x.success === true ? this.convertInquiryAttEx(x.result) : undefined))
                .filter((x) => !!x) as types.InquiryAttachmentEx[]
        };
    }

    async getFormRow(formRowId: types.FormRowId) {
        const { attachments, inquiry, inquirySubmit } =
            await this.getPrivmxInquirySubmitAttachmentsEx(formRowId);
        return this.convertInquirySubmitToFormRow(inquirySubmit, inquiry, attachments);
    }

    async getPrivmxInquirySubmitAttachments(inquirySubmitId: PmxApi.api.inquiry.InquirySubmitId) {
        return (await this.getPrivmxInquirySubmitAttachmentsEx(inquirySubmitId)).attachments;
    }

    async getPrivmxInquirySubmitAttachmentsEx(inquirySubmitId: PmxApi.api.inquiry.InquirySubmitId) {
        const { inquiryService } = this.getSession();
        const { attachmentsRes, inquiry, inquirySubmit } =
            await inquiryService.getInquirySubmitAttachmentsEx(inquirySubmitId);
        const attachments = attachmentsRes
            .map((x) => (x.success === true ? this.convertInquiryAttEx(x.result) : undefined))
            .filter((x) => !!x) as types.InquiryAttachmentEx[];
        return { attachments, inquiry, inquirySubmit };
    }

    async getInquiryAttachment(
        attachmentId: types.AttachmentId,
        thumb: boolean = false,
        options: DownloadOptions
    ) {
        const res = await this.getInquiryAttachmentWithMeta(attachmentId, thumb, options);
        return {
            id: res.id,
            name: res.name,
            contentType: res.contentType,
            size: res.size,
            content: res.content
        };
    }

    private async getInquiryAttachmentWithMeta(
        attachmentId: types.AttachmentId,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const { inquiryService } = this.getSession();
        const res = await inquiryService.getAttachmentCore(
            attachmentId as PmxApi.api.attachment.AttachmentId
        );
        const att = await inquiryService.readAttachment(res.attachment, res.meta, thumb, options);
        return {
            id: res.attachment.id as types.AttachmentId,
            name: res.meta.name as types.FileName,
            contentType: att.mimetype,
            size: att.size as types.FileSize,
            content: att.data,
            meta: res.meta
        };
    }

    async sendInquirySubmitResponse(
        inquiryId: types.FormId,
        inquirySubmitId: types.FormRowId,
        protection: types.InquirySubmitResponseProtection,
        email: string,
        title: string,
        message: string,
        password: string,
        receiverPubKey: privmx.crypto.ecc.PublicKey | undefined
    ) {
        const { inquiryService } = this.getSession();
        return inquiryService.sendInquirySubmitResponse(
            inquiryId,
            inquirySubmitId,
            protection,
            email,
            title,
            message,
            password,
            receiverPubKey
        );
    }

    async getInquirySubmitResponses(inquirySubmitId: types.FormRowId) {
        const { inquiryService } = this.getSession();
        return inquiryService.getInquirySubmitResponses(inquirySubmitId);
    }

    async getInquirySubmitResponse(inquirySubmitResponseId: types.InquirySubmitResponseId) {
        const { inquiryService } = this.getSession();
        return inquiryService.getInquirySubmitResponse(inquirySubmitResponseId);
    }

    async getPublicInquirySubmitResponse(
        inquirySubmitResponseId: types.InquirySubmitResponseId,
        password: string | undefined,
        userKeyHalf: string | undefined,
        receiverPrivKey: privmx.crypto.ecc.PrivateKey | undefined
    ) {
        const inquiryService = this.anonSession
            ? this.getAnonSession().anonInquiryService
            : this.getSession().inquiryService;
        return inquiryService.getPublicInquirySubmitResponse(
            inquirySubmitResponseId,
            password,
            userKeyHalf,
            receiverPrivKey
        );
    }

    async getEncryptedPublicInquirySubmitResponse(
        inquirySubmitResponseId: types.InquirySubmitResponseId
    ) {
        if (!this.anonSession && !this.session) {
            await this.ensurePrivmxClientScriptLoaded();
            await this.ensureAnonSessionInitialized();
        }
        const inquiryService = this.anonSession
            ? this.getAnonSession().anonInquiryService
            : this.getSession().inquiryService;
        return inquiryService.getEncryptedPublicInquirySubmitResponse(inquirySubmitResponseId);
    }

    async decryptPublicInquirySubmitResponse(
        publicInquirySubmitResponseRaw: PmxApi.api.inquiry.PublicInquirySubmitResponse,
        password: string | undefined,
        userKeyHalf: string | undefined,
        receiverPrivKey: privmx.crypto.ecc.PrivateKey | undefined
    ) {
        const inquiryService = this.anonSession
            ? this.getAnonSession().anonInquiryService
            : this.getSession().inquiryService;
        return inquiryService.decryptPublicInquirySubmitResponse(
            publicInquirySubmitResponseRaw,
            password,
            userKeyHalf,
            receiverPrivKey
        );
    }

    getInquirySubmitResponseUrl(
        inquirySubmitResponseId: types.InquirySubmitResponseId,
        userKeyHalf?: PmxApi.api.core.Base64
    ) {
        const { inquiryService } = this.getSession();
        return inquiryService.getInquirySubmitResponseUrl(userKeyHalf, inquirySubmitResponseId);
    }

    getEmailInboxQuestions() {
        const { inquiryService } = this.getSession();
        return inquiryService.getEmailInboxQuestions();
    }

    private getSession() {
        if (!this.session) {
            throw new Error('Session not established');
        }
        return this.session;
    }

    private getAnonSession() {
        if (!this.anonSession) {
            throw new Error('AnonSession not established');
        }
        return this.anonSession;
    }

    private getAnySession() {
        if (this.session) {
            return this.getSession();
        }
        if (this.anonSession) {
            return this.getAnonSession();
        }
        return this.getSession();
    }

    isAnonymousMeetingClient() {
        return !!this.anonSession;
    }

    async ensurePrivmxClientScriptLoaded(): Promise<void> {
        if ((window as any).privmx.privmx) {
            return;
        }
        if (this.privmxScriptLoadedDeferred) {
            return this.privmxScriptLoadedDeferred.promise;
        }
        this.privmxScriptLoadedDeferred = new Deferred();
        const handler = () => {
            this.privmxScriptLoadedDeferred?.resolve();
            window.removeEventListener('privmx-client-script-loaded', handler);
        };
        window.addEventListener('privmx-client-script-loaded', handler);
        await this.privmxScriptLoadedDeferred.promise;
    }

    private convertThreadToChat(thread: ThreadInfo) {
        const { tagService, threadVerifier } = this.getAnySession();
        const verified = threadVerifier.getVerifyStatus(thread);
        if (verified === null) {
            threadVerifier.verifyThread(thread, true);
        }
        const res: types.Chat = {
            id: thread.thread.id as types.ChatId,
            title: thread.data.title,
            mesagesCount: thread.thread.messages,
            filesCount: thread.thread.attachments,
            lastMsgDate: thread.thread.lastMsgDate,
            lastMsg: thread.lastMsg,
            users: thread.thread.users,
            managers: thread.thread.managers,
            type: thread.thread.type as types.ThreadType,
            props: thread.data.props,
            verified: verified ? verified : 'processing',
            ...tagService.mapTagsToTaggableEntity(thread.tags)
        };
        return res;
    }

    async getChat(chatId: types.ChatId): Promise<types.Chat | undefined> {
        return this.getPrivmxChat(chatId);
    }

    private async getPrivmxChat(threadId: PmxApi.api.thread.ThreadId): Promise<types.Chat> {
        const { threadService } = this.getSession();
        const rawThread = await threadService.getThread(threadId);
        const thread = await threadService.decryptThread(rawThread);
        return this.convertThreadToChat(thread);
    }

    async getChatMessages(chatId: types.ChatId): Promise<types.Message[]> {
        return this.getPrivmxChatMessages(chatId);
    }

    async getThreadMessages(threadId: PmxApi.api.thread.ThreadId): Promise<types.Message[]> {
        return this.getPrivmxChatMessages(threadId);
    }

    private async getPrivmxChatMessages(
        threadId: PmxApi.api.thread.ThreadId
    ): Promise<types.Message[]> {
        const { tagEncryptionService, stickerService, threadService, messageVerifier } = this
            .anonSession
            ? this.getAnonSession()
            : this.getSession();
        const rawThread = await threadService.getThread(threadId);
        const thread = await threadService.decryptThread(rawThread);
        const messages = await threadService.getMessages(thread);
        const favorites = await this.getFavorites();

        return Promise.all(
            messages.map((x) =>
                this.convertMessage(
                    x,
                    tagEncryptionService,
                    stickerService,
                    messageVerifier,
                    favorites
                )
            )
        );
    }

    private async getPrivmxChatMessagesAndAttachments(threadId: PmxApi.api.thread.ThreadId) {
        const { tagEncryptionService, stickerService, threadService, messageVerifier } =
            this.getSession();
        const rawThread = await threadService.getThread(threadId);
        const thread = await threadService.decryptThread(rawThread);
        const messages = await threadService.getMessages(thread);
        const attachments = await threadService.getThreadAttachments(thread);
        const favorites = await this.getFavorites();
        return {
            messages: await Promise.all(
                messages.map((x) =>
                    this.convertMessage(
                        x,
                        tagEncryptionService,
                        stickerService,
                        messageVerifier,
                        favorites
                    )
                )
            ),
            attachments: attachments
                .map((x) => (x.success === true ? this.convertAttEx(x.result) : undefined))
                .filter(Utils.isDefined)
        };
    }

    getThreadAttachments(threadId: PmxApi.api.thread.ThreadId) {
        return this.getPrivmxThreadAttachments(threadId);
    }

    async getAttachmentsOfUser(user: types.Username) {
        const { threadService } = this.getSession();
        const attachments = await threadService.getAttachmentsOfUser(user);
        return attachments
            .map((x) => (x.success ? this.convertAttEx(x.result) : undefined))
            .filter(Utils.isDefined);
    }

    async getAttachmentsOfCompany(companyId: types.CompanyId) {
        const { threadService } = this.getSession();
        const attachments = await threadService.getAttachmentsOfCompany(companyId);
        return attachments
            .map((x) => (x.success ? this.convertAttEx(x.result) : undefined))
            .filter(Utils.isDefined);
    }

    private async getPrivmxThreadAttachments(threadId: PmxApi.api.thread.ThreadId) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        const rawThread = await threadService.getThread(threadId);
        const thread = await threadService.decryptThread(rawThread);
        const attachments = await threadService.getThreadAttachments(thread);
        return attachments
            .map((x) => (x.success === true ? this.convertAttEx(x.result) : undefined))
            .filter(Utils.isDefined);
    }

    async getItemCountsGrouppedByUsers() {
        if (this.anonSession) {
            return {};
        }
        const { threadService } = this.getSession();
        const itemCountsGrouppedByUser = await threadService.getItemCountsGrouppedByUsers();
        return itemCountsGrouppedByUser;
    }

    async getThreadUsersWithAccessibleTimeRanges(threadId: types.ThreadId) {
        const { threadService } = this.getSession();
        const users = await threadService.getThreadUsersWithAccessibleTimeRanges(threadId);
        return users;
    }

    async updateItemCountsGrouppedByUsers() {
        const itemCountsGrouppedByUser = await this.getItemCountsGrouppedByUsers();
        this.dispatchEvent<types.ItemCountsGrouppedByUsersChangedEvent>({
            type: 'itemcountsgrouppedbyuserschanged',
            itemCountsGrouppedByUser: itemCountsGrouppedByUser
        });
    }

    private convertAttEx(x: PmxAttachmentEx) {
        const res: types.AttachmentEx = {
            id: x.attachmentId,
            chatId: x.threadId as types.ChatId,
            messageId: x.messageId,
            name: x.meta.name,
            contentType: x.meta.mimetype,
            size: x.meta.size,
            group: x.group,
            tags: x.tags,
            author: x.author,
            date: x.date,
            versions: x.versions,
            contributors: x.contributors,
            modificationDates: x.modificationDates,
            createdDate: x.createdDate,
            creator: x.creator,
            hasThumb: x.hasThumb,
            sourceType: 'thread'
        };
        return res;
    }

    private convertAtt(x: PmxAttachment) {
        const res: types.Attachment = {
            id: x.attachmentId,
            chatId: x.threadId as types.ChatId,
            messageId: x.messageId,
            name: x.meta.name,
            contentType: x.meta.mimetype,
            size: x.meta.size,
            group: x.group,
            tags: x.tags,
            author: x.author,
            date: x.date,
            hasThumb: x.hasThumb,
            sourceType: 'thread'
        };
        return res;
    }

    private convertInquiryAttEx(x: PmxInquiryAttachmentEx) {
        const res: types.InquiryAttachmentEx = {
            id: x.attachmentId,
            inquiryId: x.inquiryId,
            inquirySubmitId: x.inquirySubmitId,
            name: x.meta.name,
            contentType: x.meta.mimetype,
            size: x.meta.size,
            group: x.group,
            tags: x.tags as types.Tag[],
            author: x.author,
            date: x.date,
            versions: x.versions,
            contributors: x.contributors,
            modificationDates: x.modificationDates,
            createdDate: x.createdDate,
            creator: x.creator,
            hasThumb: x.hasThumb,
            sourceType: 'inquiry'
        };
        return res;
    }

    private convertInquiryAtt(x: PmxInquiryAttachment) {
        const res: types.InquiryAttachment = {
            id: x.attachmentId,
            inquiryId: x.inquiryId,
            inquirySubmitId: x.inquirySubmitId,
            name: x.meta.name as types.FileName,
            contentType: x.meta.mimetype as types.Mimetype,
            size: x.meta.size as types.FileSize,
            group: x.group,
            tags: x.tags as types.Tag[],
            author: x.author,
            date: x.date,
            hasThumb: x.hasThumb,
            sourceType: 'inquiry'
        };
        return res;
    }

    private async convertMessage(
        msg: MessageFull,
        tagEncryptionService: TagEncryptionService,
        stickerService: StickerService,
        messageVerifier: MessageVerifier,
        favorites: types.FavoriteMessage[]
    ) {
        const { tagService } = this.getAnySession();
        if (msg.sig.success === true) {
            const x: MessageFullX = { msg: msg.msg, sig: msg.sig.result };
            const verified = messageVerifier.getVerifyStatus(x);
            if (verified === null) {
                messageVerifier.verifyMessage(x, true);
            }
            const res: types.Message = {
                id: x.msg.id,
                msgId: x.sig.data.msgId,
                mimetype: x.sig.data.type,
                text: x.sig.data.text,
                author: x.msg.author,
                authorIsAnonymousMeetingUser:
                    (x.msg.author as string) === (x.sig.data.author.pubKey as string),
                date: x.msg.createDate,
                attachments: await Promise.all(
                    x.sig.data.attachments.map(async (a, ai) => {
                        const aRaw = x.msg.attachments[ai];
                        const aRes: types.Attachment = {
                            id: aRaw.id,
                            chatId: x.msg.threadId as types.ChatId,
                            messageId: x.msg.id,
                            name: a.name,
                            group: aRaw.group,
                            contentType: a.mimetype,
                            size: a.size,
                            tags: await tagService.getTags(
                                'threadAttachment',
                                aRaw.id,
                                'all',
                                aRaw
                            ),
                            author: x.sig.data.author.username,
                            date: x.msg.createDate,
                            hasThumb: a.hasThumb,
                            sourceType: 'thread'
                        };
                        return aRes;
                    })
                ),
                emojis: this.readEmojis(await stickerService.decryptStickers(x.msg.stickers)),
                deleted: !!x.sig.data.deleted,
                edits: x.msg.edits,
                decryptError: false,
                verified: verified ? verified : 'processing',
                favorite: favorites.some((y) => y.messageId === x.msg.id) ? true : false,
                threadId: x.msg.threadId as types.ChatId
            };
            return res;
        } else {
            console.error('Error during reading message', msg.msg.id, msg.sig.error);
            const res: types.Message = {
                id: msg.msg.id,
                msgId: msg.msg.id as string as types.MessageClientId,
                mimetype: 'text/plain',
                text: '',
                author: msg.msg.author,
                authorIsAnonymousMeetingUser: false,
                date: msg.msg.createDate,
                attachments: [],
                emojis: this.readEmojis(await stickerService.decryptStickers(msg.msg.stickers)),
                deleted: false,
                edits: msg.msg.edits,
                decryptError: true,
                verified: 'invalid',
                favorite: false,
                threadId: '' as types.ChatId
            };
            return res;
        }
    }

    private readEmojis(stickers: ThreadSticker[]) {
        const emojiMap = new Map<EmojiIconName, types.MessageEmojiIcon>();
        for (const sticker of stickers) {
            const icon = sticker.s as EmojiIconName;
            const emoji = emojiMap.get(icon);
            if (emoji) {
                emoji.users.push(sticker.u);
            } else {
                emojiMap.set(icon, { icon: icon, users: [sticker.u] });
            }
        }
        return [...emojiMap.values()];
    }

    async getChatWithMessages(chatId: types.ChatId) {
        const res = await this.getChatWithMessagesX(chatId);
        if (res && !('messages' in res)) {
            throw new Error('Access denied');
        }
        return res;
    }

    async getChatWithMessagesX(chatId: types.ChatId) {
        const { userData } = this.getSession();
        const threadId = chatId;
        const chat = await this.getPrivmxChat(threadId);
        if (!chat) {
            return undefined;
        }
        const res: types.ChatEntryX = {
            props: chat
        };
        if (chat.users.includes(userData.identity.user as PmxApi.api.core.Username)) {
            const msgRes = await this.getPrivmxChatMessagesAndAttachments(threadId);
            (res as types.ChatEntry).messages = msgRes.messages;
            (res as types.ChatEntry).attachments = msgRes.attachments;
        }
        return res as types.ChatEntry;
    }

    async getCompaniesWithContacts() {
        const [companies, contacts] = await Promise.all([this.getCompanies(), this.getContacts()]);
        return companies.map((x) => {
            const res: types.CompanyWithContacts = {
                ...x,
                contacts: contacts.filter((c) => c.companyId === x.id)
            };
            return res;
        });
    }

    async getCompanyWithContacts(companyId: types.CompanyId) {
        const [company, contacts] = await Promise.all([
            this.getCompany(companyId),
            this.getContacts()
        ]);
        if (!company) {
            return undefined;
        }
        const res: types.CompanyWithContacts = {
            ...company,
            contacts: contacts.filter((c) => c.companyId === company.id)
        };
        return res;
    }

    async getCompanies() {
        const { companyService } = this.getSession();
        if (!companyService) {
            return [];
        }
        const companies = await companyService.getCompanies();
        return companies.map((x) => this.convertPmxCompany(x));
    }

    async getCompany(companyId: types.CompanyId) {
        const { companyService } = this.getSession();
        const company = await companyService.getCompany(companyId);
        return this.convertPmxCompany(company);
    }

    getCompanySync(companyId: types.CompanyId) {
        const { companiesCache } = this.getSession();
        return companiesCache.get(companyId);
    }

    getUser(username: types.Username) {
        const { userService } = this.getSession();
        const user = userService.getUser(username);
        return user && this.convertUser(user);
    }

    async userExists(username: types.Username) {
        const { userService } = this.getSession();
        return (
            (await userService.getUsernamesRaw()).filter((x) => x.username === username).length > 0
        );
    }

    getUsersSync() {
        const { userService } = this.getAnySession();
        const users = userService.getUsers();
        return users.map((user) => this.convertUser(user));
    }

    getUsernamesSync() {
        const { userService } = this.getAnySession();
        const users = userService.getUsers();
        return users.map((user) => user.raw.username);
    }

    async refreshUsersListIfUsernamesAreMissing(usernames: types.Username[]) {
        const existing = this.getUsernamesSync();
        const missing = usernames.filter((x) => !existing.includes(x));
        if (missing.length > 0) {
            const { userService } = this.getAnySession();
            await userService.refresh();
        }
    }

    getContactOrUser(entry: types.UsernameOrContactId): types.UserOrContact | undefined {
        const { userService } = this.anonSession ? this.getAnonSession() : this.getSession();
        if (entry.type === 'contact') {
            const contact = this.getContactSync(entry.contactId);
            return contact ? { type: 'contact', contact: contact } : undefined;
        }
        if (entry.type === 'user') {
            const contact = this.getContactByUsernameSync(entry.username);
            if (contact) {
                return { type: 'contact', contact: contact };
            }
            const user = userService.getUser(entry.username);
            return user ? { type: 'user', user: this.convertUser(user) } : undefined;
        }
        return undefined;
    }

    async getUsersAndContacts() {
        const [users, contacts] = await Promise.all([this.getUsers(), this.getContacts()]);
        const result: types.UserOrContact[] = [];
        for (const user of users) {
            if (contacts.every((x) => x.username !== user.username)) {
                result.push({ type: 'user', user: user });
            }
        }
        for (const contact of contacts) {
            result.push({ type: 'contact', contact: contact });
        }
        return result;
    }

    async getContacts() {
        const { contactService, contactsCache } = this.getSession();
        if (!contactService) {
            return [];
        }
        const entries = await contactService.getContacts();
        const contacts = entries.map((x) => this.convertPmxContact(x));
        contactsCache.clear();
        for (const contact of contacts) {
            contactsCache.set(contact.id, contact);
        }
        return contacts;
    }

    async getContactsOfCompany(comapnyId: types.CompanyId) {
        const { contactService } = this.getSession();
        const contacts = await contactService.getCompanyContacts(comapnyId);
        return contacts.map((x) => this.convertPmxContact(x));
    }

    async getContact(contactId: types.ContactId) {
        const { contactService } = this.getSession();
        if (!contactService) {
            return undefined;
        }
        try {
            const entry = await contactService.getContact(contactId);
            return this.convertPmxContact(entry);
        } catch (error) {
            console.error('api.getContact():', error);
            return undefined;
        }
    }

    getContactSync(contactId: types.ContactId) {
        const { contactsCache } = this.getSession();
        return contactsCache.get(contactId);
    }

    getContactsSync() {
        const { contactsCache } = this.getSession();
        return [...contactsCache.values()];
    }

    getContactByUsernameSync(username: types.Username) {
        if (this.anonSession) {
            return undefined;
        }
        const { contactsCache } = this.getSession();
        for (const contact of contactsCache.values()) {
            if (contact.username === username) {
                return contact;
            }
        }
        return undefined;
    }

    getContactByEmailSync(email: types.Email) {
        if (this.anonSession) {
            return undefined;
        }
        const { contactsCache } = this.getSession();
        for (const contact of contactsCache.values()) {
            if (contact.email === email) {
                return contact;
            }
        }
        return undefined;
    }

    async getMessage(messageId: types.MessageId): Promise<SingleChatMessage> {
        const { threadService, tagEncryptionService, stickerService, messageVerifier } =
            this.getSession();
        const info = await threadService.getMessage2(messageId);
        const favorites = await this.getFavorites();
        if (info.threadInfo.thread.type === 'chat') {
            const res: SingleChatMessage = {
                message: await this.convertMessage(
                    info,
                    tagEncryptionService,
                    stickerService,
                    messageVerifier,
                    favorites
                ),
                chatId: info.threadInfo.thread.id as types.ChatId,
                chatOrMeetingTitle: info.threadInfo.data.title
            };
            return res;
        } else if (info.threadInfo.thread.type === 'meeting') {
            const res: SingleChatMessage = {
                message: await this.convertMessage(
                    info,
                    tagEncryptionService,
                    stickerService,
                    messageVerifier,
                    favorites
                ),
                meetingId: info.threadInfo.thread.id as types.MeetingId,
                chatOrMeetingTitle: info.threadInfo.data.title
            };
            return res;
        }
        throw new Error('Unknown thread type');
    }

    async getFeed() {
        const { userData, companyService, contactService, inquiryService, threadService } =
            this.getSession();
        const feedApi = new FeedApi(userData.srpSecure.gateway);
        const { list } = await feedApi.getFeedListX({ count: 100, skip: 0 });
        const resList: types.Feed[] = [];
        for (const entry of list) {
            try {
                if (entry.type === 'company') {
                    const company = await companyService.decryptCompany(entry.company);
                    const res: types.CompanyFeed = {
                        id: entry.id,
                        read: entry.read,
                        type: entry.type,
                        company: {
                            id: company.raw.id,
                            name: company.props.name
                        }
                    };
                    resList.push(res);
                } else if (entry.type === 'contact') {
                    const contact = await contactService.decryptContact(entry.contact);
                    const res: types.ContactFeed = {
                        id: entry.id,
                        read: entry.read,
                        type: entry.type,
                        contact: {
                            id: contact.raw.id,
                            email: contact.raw.email,
                            name: contact.props.name
                        }
                    };
                    resList.push(res);
                } else if (entry.type === 'thread') {
                    const thread = await threadService.decryptThread(entry.thread);
                    if (thread.thread.type === 'chat' || thread.thread.type === 'inquirySubmit') {
                        const res: types.ChatFeed = {
                            id: entry.id,
                            read: entry.read,
                            type: 'chat',
                            newMsgCount: entry.newMsgCount,
                            newFilesCount: entry.newAttachmentsCount,
                            thread: {
                                id: thread.thread.id as types.ChatId,
                                title: thread.data.title,
                                users: thread.thread.users
                            }
                        };
                        resList.push(res);
                    } else if (thread.thread.type === 'meeting') {
                        const res: types.MeetingFeed = {
                            id: entry.id,
                            read: entry.read,
                            type: 'meeting',
                            newMsgCount: entry.newMsgCount,
                            newFilesCount: entry.newAttachmentsCount,
                            meeting: {
                                id: thread.thread.id as types.MeetingId,
                                title: thread.data.title
                            }
                        };
                        resList.push(res);
                    }
                } else if (entry.type === 'inquiry') {
                    const inquiry = await inquiryService.decryptInquiry(entry.inquiry);
                    const res: types.FormFeed = {
                        id: entry.id,
                        read: entry.read,
                        type: 'form',
                        newRecordsCount: entry.newSubmitsCount,
                        form: {
                            id: inquiry.raw.id,
                            name: inquiry.data.name
                        }
                    };
                    resList.push(res);
                } else if (entry.type === 'mention') {
                    const thread = await threadService.decryptThread(entry.thread);
                    const message = await threadService.decryptMessage(thread, entry.message);
                    const res: types.MentionFeed = {
                        id: entry.id,
                        read: entry.read,
                        type: 'mention',
                        thread: {
                            id: thread.thread.id as types.ChatId,
                            type: thread.thread.type,
                            title: thread.data.title,
                            users: thread.thread.users
                        },
                        message: {
                            id: message.data.msgId as string as types.MessageId,
                            text: message.data.text,
                            author: message.data.author.username
                        }
                    };
                    resList.push(res);
                }
            } catch (e) {
                console.error('Error during converting feed', e);
            }
        }
        return resList;
    }

    async setFeedReadState(feedId: types.FeedId, read: boolean) {
        const { userData } = this.getSession();
        const feedApi = new FeedApi(userData.srpSecure.gateway);
        await feedApi.setFeedReadFlag({ id: feedId, read: read });
        this.dispatchEvent<types.FeedReadChangedEvent>({
            type: 'feedreadchanged',
            feedId: feedId,
            read: read
        });
    }

    async getFiles() {
        const { threadService } = this.getSession();
        const attachments = await threadService.getAttachments();
        return attachments
            .map((x) => (x.success === true ? this.convertAttEx(x.result) : undefined))
            .filter(Utils.isDefined);
    }

    async getFile(fileId: types.AttachmentId) {
        const { threadService } = this.getSession();
        const attachment = await threadService.getAttachmentGroup(fileId);
        if (attachment.success === false) {
            throw new Error('File does not exists');
        }
        return this.convertAttEx(attachment.result);
    }

    async setFormAsMainOne(formId: types.FormId) {
        const { inquiryService } = this.getSession();
        await inquiryService.setMainInquiryId(formId);
    }

    async getFormList() {
        const { inquiryService } = this.getSession();
        const inquiries = await inquiryService.getInquires();
        const mainInquiryId = await inquiryService.getMainInquiryId();
        return inquiries.map((x) => this.convertInquiryToFormModel2(x, mainInquiryId));
    }

    async getEmailInboxesList() {
        const { inquiryService } = this.getSession();
        const inquiries = await inquiryService.getEmailInboxes();
        const mainInquiryId = await inquiryService.getMainInquiryId();
        return inquiries.map((x) => this.convertInquiryToFormModel2(x, mainInquiryId));
    }

    async getUserForms(user: types.Username) {
        const { inquiryService } = this.getSession();
        const inquiries = await inquiryService.getInquiresOfUser(user);
        return inquiries.map((x) =>
            this.convertInquiryToFormModel2(x, '' as PmxApi.api.inquiry.InquiryId)
        );
    }

    async getCompanyForms(comapnyId: types.CompanyId) {
        const { inquiryService } = this.getSession();
        const inquiries = await inquiryService.getInquiresOfCompany(comapnyId);
        return inquiries.map((x) =>
            this.convertInquiryToFormModel2(x, '' as PmxApi.api.inquiry.InquiryId)
        );
    }

    async getFormsWithSubmitsAndCachedAttachmentsByThreadIds(threadIds: types.ThreadId[]) {
        const { inquiryService } = this.getSession();
        const { inquiries, inquirySubmits } =
            await inquiryService.getInquiriesWithSubmitsByThreadIds(threadIds);
        const forms = inquiries.map((x) =>
            this.convertInquiryToFormModel2(x, '' as PmxApi.api.inquiry.InquiryId)
        );
        const allAttachments = store.getState().dataCache.attachments;
        const submits = inquirySubmits
            .map((submit) => {
                const inquiry = inquiries.find((y) => y.raw.id === submit.raw.inquiryId);
                if (!inquiry) {
                    return null;
                }
                const attachments = allAttachments
                    .filter((att) => submit.raw.attachments.some((att2) => att.id === att2.id))
                    .map((x) => ({
                        ...x,
                        inquiryId: inquiry.raw.id,
                        inquirySubmitId: submit.raw.id
                    })) as types.InquiryAttachmentEx[];
                return this.convertInquirySubmitToFormRow(submit, inquiry, attachments);
            })
            .filter((x) => !!x) as types.FormRow[];
        return {
            forms: forms,
            submits: submits
        };
    }

    private convertInquiryToFormModel2(
        inquiry: Inquiry,
        mainInquiryId: PmxApi.api.inquiry.InquiryId | null
    ) {
        const { tagService } = this.getAnySession();
        const res: types.FormModel2 = {
            id: inquiry.raw.id,
            type: inquiry.raw.type,
            main: inquiry.raw.id === mainInquiryId,
            name: inquiry.data.name,
            entriesCount: inquiry.raw.submits,
            users: inquiry.raw.users,
            managers: inquiry.raw.managers,
            published: inquiry.raw.currentPublication !== null,
            status: inquiry.raw.currentPublication ? 'published' : 'draft',
            lastSubmitDate: inquiry.raw.lastSubmitDate,
            questions: inquiry.data.questions,
            createdBy: inquiry.raw.creator,
            createdDate: inquiry.raw.created,
            lastModifiedBy: inquiry.raw.lastModifier,
            lastModifiedDate: inquiry.raw.lastModified,
            autoResponseData: inquiry.data.autoResponseData,
            captchaEnabled: inquiry.raw.captchaEnabled,
            shortLink: inquiry.raw.shortLink,
            ...tagService.mapTagsToTaggableEntity(inquiry.tags as types.Tag[])
        };
        return res;
    }

    async getForm(formId: types.FormId) {
        const { inquiryService, tagService } = this.getSession();
        const inquiry = await inquiryService.getInquiry(formId);
        if (!inquiry) {
            return undefined;
        }
        const res: types.FormModel = {
            id: inquiry.raw.id,
            type: inquiry.raw.type,
            entriesCount: inquiry.raw.submits,
            name: inquiry.data.name,
            status: inquiry.raw.currentPublication ? 'published' : 'draft',
            managers: inquiry.raw.managers,
            users: inquiry.raw.users,
            questions: inquiry.data.questions,
            lastSubmitDate: inquiry.raw.lastSubmitDate,
            createdBy: inquiry.raw.creator,
            createdDate: inquiry.raw.created,
            lastModifiedBy: inquiry.raw.lastModifier,
            lastModifiedDate: inquiry.raw.lastModified,
            autoResponseData: inquiry.data.autoResponseData,
            captchaEnabled: inquiry.raw.captchaEnabled,
            shortLink: inquiry.raw.shortLink,
            ...tagService.mapTagsToTaggableEntity(inquiry.tags as types.Tag[])
        };
        return res;
    }

    async getFormWithSubmits(formId: types.FormId) {
        const inquiryId = formId;
        const { userData } = this.getSession();
        const form = await this.getForm(formId);
        if (!form) {
            return undefined;
        }
        const res: types.FormWithSubmits = {
            form: form,
            attachments: [],
            submits: []
        };
        if (form.users.includes(userData.identity.user as PmxApi.api.core.Username)) {
            const submitsRes = await this.getPrivmxInquirySubmitsAndAttachments(inquiryId);
            res.submits = submitsRes.submits;
            res.attachments = submitsRes.attachments;
        }
        return res;
    }

    async getMainFormId() {
        const inquiryService = this.session
            ? this.getSession().inquiryService
            : (await this.ensureAnonSessionInitialized()).anonInquiryService;
        return inquiryService.getMainInquiryId();
    }

    async getPublicForm(formId: types.FormId) {
        const inquiryService = this.session
            ? this.getSession().inquiryService
            : (await this.ensureAnonSessionInitialized()).anonInquiryService;
        const inquiry = await inquiryService.getInquiryPublicView(formId);
        const res: types.FormPublicView = {
            id: inquiry.raw.id,
            publicationId: inquiry.raw.publicationId,
            pubKey: inquiry.data.pub,
            name: inquiry.data.name,
            questions: inquiry.data.questions,
            autoResponseData: inquiry.raw.autoResponseData,
            captchaEnabled: inquiry.raw.captchaEnabled
        };
        return res;
    }

    async getFormEntry(formId: types.FormId) {
        const { inquiryService, tagService } = this.getSession();
        const mainInquiryId = await inquiryService.getMainInquiryId();
        const { inquiry, submits, attachments } = await this.getPrivmxInquirySubmitsAndAttachments(
            formId
        );
        const res: types.FormModelEntries = {
            id: inquiry.raw.id,
            type: inquiry.raw.type,
            main: inquiry.raw.id === mainInquiryId,
            entriesCount: inquiry.raw.submits,
            name: inquiry.data.name,
            status: inquiry.raw.currentPublication ? 'published' : 'draft',
            questions: inquiry.data.questions,
            managers: inquiry.raw.managers,
            users: inquiry.raw.users,
            answers: submits.map((x) =>
                this.convertInquirySubmitToFormRow(x, inquiry, attachments)
            ),
            lastSubmitDate: inquiry.raw.lastSubmitDate,
            createdBy: inquiry.raw.creator,
            createdDate: inquiry.raw.created,
            lastModifiedBy: inquiry.raw.lastModifier,
            lastModifiedDate: inquiry.raw.lastModified,
            autoResponseData: inquiry.data.autoResponseData,
            shortLink: inquiry.raw.shortLink,
            captchaEnabled: inquiry.raw.captchaEnabled,
            ...tagService.mapTagsToTaggableEntity(inquiry.tags as types.Tag[])
        };
        return res;
    }

    async getFormSubmits(formId: types.FormId) {
        const entry = await this.getFormEntry(formId);
        return entry.answers;
    }

    async setFormPinned(formId: types.FormId, pinned: boolean) {
        const { inquiryService } = this.getSession();
        await inquiryService.toggleInquiryTag(formId, TagService.SYSTEM_TAG_PINNED, pinned);
    }

    async setFormArchived(formId: types.FormId, archived: boolean) {
        const { inquiryService } = this.getSession();
        await inquiryService.toggleInquiryTag(formId, TagService.SYSTEM_TAG_ARCHIVED, archived);
    }

    private convertInquirySubmitToFormRow(
        submit: InquirySubmit,
        inquiry: Inquiry,
        attachments: types.InquiryAttachmentEx[]
    ) {
        const { tagService } = this.getAnySession();
        const row: types.FormRow = {
            id: submit.raw.id,
            formId: inquiry.raw.id,
            chatId: submit.raw.threadId as types.ChatId,
            date: submit.raw.created,
            answers: submit.data.answers,
            filledBy: submit.raw.author,
            adminData: submit.adminData,
            attachments: submit.attachments
                .map((att) => attachments.find((attEx) => attEx.name === att.name))
                .filter((x) => Utils.isDefined(x)) as types.InquiryAttachmentEx[],
            ...tagService.mapTagsToTaggableEntity(inquiry.tags as types.Tag[])
        };
        return row;
    }

    async createChatInSubmit(
        formName: string,
        formId: types.FormId,
        submitId: types.FormRowId,
        email: string | null,
        firstMessage: string,
        partialOriginDetails: Omit<UserOriginContactFormDetails, 'chatId'>,
        threadPassword: string | null
    ) {
        const { inquiryService, userData, threadService, managableUserCreator } = this.getSession();
        const originDetails: UserOriginContactFormDetails = {
            ...partialOriginDetails,
            chatId: '' as types.ChatId
        };
        const submitAttachments = await this.getPrivmxInquirySubmitAttachments(
            submitId as PmxApi.api.inquiry.InquirySubmitId
        );
        const inquiry = await inquiryService.getInquiry(formId);
        const inquirySubmit = (await inquiryService.getSubmits(inquiry)).find(
            (x) => x.raw.id === submitId
        );
        if (!inquiry) {
            throw new Error("Inquiry doesn't exist");
        }
        if (!inquirySubmit) {
            throw new Error("InquirySubmit doesn't exist");
        }
        const context =
            email === null
                ? null
                : await managableUserCreator.createEmailUser({
                      creator: userData.identity,
                      username: await this.generateUsernameFromContact({ email }),
                      host: userData.identity.host as PmxApi.api.core.Host,
                      language: 'en' as PmxApi.api.core.Language,
                      email: email as PmxApi.api.core.Email,
                      description: '' as PmxApi.api.user.UserDescription,
                      privateSectionAllowed: false,
                      originDetails: originDetails,
                      profile: {}
                  });
        const anonPrvKey = threadPassword
            ? await privmx.crypto.service.eccGenerateKey('raw')
            : null;
        const anonPubKey = anonPrvKey ? anonPrvKey.getPublicKey() : null;
        const salt = threadPassword ? privmx.Buffer.Buffer.from(threadPassword, 'utf-8') : null;
        const anonPrivKeyPreliminaryEncryptionKey = threadPassword
            ? privmx.crypto.service.randomBytes(32)
            : null;
        const anonPrivKeyEncryptionKey =
            salt && anonPrivKeyPreliminaryEncryptionKey
                ? await privmx.crypto.service.pbkdf2(
                      anonPrivKeyPreliminaryEncryptionKey,
                      salt,
                      100000,
                      32,
                      'sha256'
                  )
                : null;
        const anonEncryptedPrivKey =
            anonPrivKeyEncryptionKey && anonPrvKey
                ? await privmx.crypto.service.aes256EcbEncrypt(
                      anonPrvKey.getPrivateEncKey(),
                      anonPrivKeyEncryptionKey
                  )
                : null;
        const thread = await threadService.createInquirySubmitThread(
            inquiry,
            inquirySubmit,
            `#${submitId.substring(0, 6)} ${formName} ${email}`,
            {
                formThreadPassword: threadPassword,
                anonEncryptedPrivKey: anonEncryptedPrivKey
                    ? anonEncryptedPrivKey.toString('hex')
                    : undefined,
                anonPrivKeyPreliminaryEncryptionKey: anonPrivKeyPreliminaryEncryptionKey
                    ? anonPrivKeyPreliminaryEncryptionKey.toString('hex')
                    : undefined
            },
            [],
            context ? context.username : null,
            anonPubKey ?? undefined
        );
        const threadInfo = await threadService.decryptThread(thread);
        originDetails.chatId = thread.id as types.ChatId;
        if (context) {
            await managableUserCreator.updateUsersOriginDetails([context.username], originDetails);
        }
        await this.sendPrivmxMessage(
            threadService,
            threadInfo,
            'text/plain',
            firstMessage,
            this.generateMessageId(),
            [],
            submitAttachments
        );
        return { threadInfo, context, chatId: thread.id as types.ChatId };
    }

    async editFormModel(options: types.EditFormModelOptions) {
        const {
            id,
            tags,
            status,
            newName,
            newQuestions,
            users,
            managers,
            updateKeysWhenAddingUsers,
            autoResponseData,
            captchaEnabled
        } = options;
        const editedQuestions = newQuestions
            ? newQuestions.map((question, i) => {
                  if (question.type === 'block') {
                      return question;
                  } else {
                      const editedAnswers = Array.isArray(question.answer)
                          ? question.answer.map((a) =>
                                a?.id ? { ...a } : { ...a, id: dbGenerator.generateId() }
                            )
                          : question.answer.id
                          ? { ...question.answer }
                          : { ...question.answer, id: dbGenerator.generateId() };

                      return { ...question, answer: editedAnswers } as types.Question;
                  }
              })
            : null;
        const { inquiryService, tagService } = this.getSession();
        const inquiry = await inquiryService.getInquiry(id);
        const usernames = users
            ? (
                  await api.createMissingInternalUsersFromContacts(users, {
                      type: 'formEditor',
                      formId: id
                  })
              ).allUsernames
            : inquiry.raw.users;
        const publish = status ? status === 'published' : !!inquiry.raw.currentPublication;
        const newTags = tagService.readAllTagsFromTaggableEntity({
            pinned: inquiry.raw.tags.includes('system:pinned' as types.Tag),
            archived: inquiry.raw.tags.includes('system:archived' as types.Tag),
            tags: tags as types.Tag[]
        });
        await inquiryService.updateInquiry(
            inquiry,
            newName || inquiry.data.name,
            editedQuestions || inquiry.data.questions,
            usernames,
            managers || inquiry.raw.managers,
            newTags,
            publish,
            updateKeysWhenAddingUsers,
            autoResponseData,
            captchaEnabled
        );
    }

    async createFormModel(
        name: string,
        questions:
            | { type: 'questions'; questions: types.Question[] }
            | { type: 'questionModels'; questionModels: types.QuestionModel[] },
        status: types.FormModel['status'],
        tags: string[],
        users: types.UsernameOrContactId[],
        managers: types.Username[],
        type: PmxApi.api.inquiry.InquiryType,
        autoResponseData: PmxApi.api.inquiry.AutoResponseData,
        captchaEnabled?: boolean,
        createShortLink?: boolean
    ) {
        const questionsList: types.Question[] =
            questions.type === 'questionModels'
                ? (questions.questionModels.map((q) => {
                      let answer: unknown;

                      if (q.type === 'block') {
                          answer = undefined;
                      } else if (Array.isArray(q.answer)) {
                          answer = q.answer.map((a) => ({
                              ...a,
                              id: dbGenerator.generateId()
                          }));
                      } else {
                          answer = { ...q.answer, id: q.answer.id ?? dbGenerator.generateId() };
                      }

                      return {
                          ...q,
                          answer
                      };
                  }) as any)
                : questions.questions;
        const { allUsernames: usernames, newUsernames } =
            await api.createMissingInternalUsersFromContacts(users, {
                type: 'formEditor',
                formId: '' as types.FormId
            });
        const { inquiryService, managableUserCreator } = this.getSession();
        const inquiry = await inquiryService.createInquiry(
            name,
            questionsList,
            usernames,
            managers,
            tags,
            status === 'published',
            type,
            autoResponseData,
            captchaEnabled
        );
        if (newUsernames.length > 0) {
            await managableUserCreator.updateUsersOriginDetails(newUsernames, {
                type: 'formEditor',
                formId: inquiry.raw.id
            });
        }
        const newForm = this.convertInquiryToFormModel2(inquiry, null);
        if (createShortLink) await formApi.generateShortLinkForForm(newForm.id);
        return newForm;
    }

    async generateShortLinkForForm(formId: types.FormId, slug?: string) {
        const { inquiryService } = this.getSession();
        const inquiry = await inquiryService.generateInquiryShortLink({
            inquiryId: formId,
            slug: slug
        });
        return this.convertInquiryToFormModel2(inquiry, null);
    }

    async submitFormAnswer(
        formId: types.FormId,
        publicationId: PmxApi.api.inquiry.InquiryPublicationId,
        pubKey: PmxApi.api.core.EccPubKey,
        answers: types.SubmitedAnswer[],
        files: File[],
        autoResponseEmail: string | undefined,
        captcha: PmxApi.api.captcha.CaptchaObj | undefined
    ) {
        const inquiryService = this.session
            ? this.getSession().inquiryService
            : (await this.ensureAnonSessionInitialized()).anonInquiryService;

        await inquiryService.createSubmit(
            formId,
            publicationId,
            pubKey,
            { answers },
            files,
            autoResponseEmail,
            captcha
        );
    }

    async removeFormAnswer(formId: types.FormId, rowId: types.FormRowId[]) {
        const { inquiryService } = this.getSession();
        await Promise.all(rowId.map((x) => inquiryService.deleteSubmit(x)));
    }

    async updateFormRow(
        formRowId: types.FormRowId,
        formId: types.FormId,
        adminData: types.FormRowAdminData,
        tags: PmxApi.api.tag.Tag[]
    ) {
        const { inquiryService } = this.getSession();
        inquiryService.updateSubmit(formRowId, formId, adminData, tags);
    }

    async setSubmitTags(id: PmxApi.api.inquiry.InquirySubmitId, tags: PmxApi.api.tag.Tag[]) {
        const { inquiryService } = this.getSession();
        inquiryService.setSubmitTags(id, tags);
    }

    async getMeetings(): Promise<types.Meeting[]> {
        return this.getPrivmxMeetings();
    }

    private async getPrivmxMeetings(): Promise<types.Meeting[]> {
        const { threadService } = this.getSession();
        const threads = await threadService.getThreads('meeting' as PmxApi.api.thread.ThreadType);
        return Promise.all(
            threads.map(async (x) => {
                const thread = await threadService.decryptThread(x);
                return this.convertThreadToMeeting(thread);
            })
        );
    }

    private convertThreadToMeeting(thread: ThreadInfo) {
        const { tagService, threadVerifier } = this.getAnySession();
        const verified = threadVerifier.getVerifyStatus(thread);
        if (verified === null) {
            threadVerifier.verifyThread(thread, true);
        }
        const res: types.Meeting = {
            id: thread.thread.id as types.MeetingId,
            title: thread.data.title,
            mesagesCount: thread.thread.messages,
            filesCount: thread.thread.attachments,
            lastMsgDate: thread.thread.lastMsgDate,
            users: thread.thread.users,
            managers: thread.thread.managers,
            duration: thread.data.props.duration as types.Timespan,
            startDate: thread.data.props.startDate as types.Timestamp,
            meetingState: thread.thread.meetingState,
            type: thread.thread.type as types.ThreadType,
            ...tagService.mapTagsToTaggableEntity(thread.tags),
            lastMsg: thread.lastMsg,
            verified: verified ? verified : 'processing',
            isMeetingCancelled: thread.thread.isMeetingCancelled
        };
        return res;
    }

    async getMeeting(meetingId: types.MeetingId): Promise<types.Meeting | undefined> {
        return this.getPrivmxMeeting(meetingId);
    }

    private async getPrivmxMeeting(threadId: PmxApi.api.thread.ThreadId): Promise<types.Meeting> {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        const rawThread = await threadService.getThread(threadId);
        const thread = await threadService.decryptThread(rawThread);
        return this.convertThreadToMeeting(thread);
    }

    async getMeetingMessages(meetingId: types.MeetingId): Promise<types.Message[]> {
        return this.getPrivmxChatMessages(meetingId);
    }

    async getMeetingWithMessages(meetingId: types.MeetingId) {
        const res = await this.getMeetingWithMessagesX(meetingId);
        if (res && !('messages' in res)) {
            throw new Error('Access denied');
        }
        return res;
    }

    async getMeetingWithMessagesX(
        meetingId: types.MeetingId
    ): Promise<types.MeetingEntryX | undefined> {
        const username = this.anonSession
            ? this.getAnonSession().identity.user
            : this.getSession().userData.identity.user;
        const threadId = meetingId;
        const meeting = await this.getPrivmxMeeting(threadId);
        if (!meeting) {
            return undefined;
        }
        const res: types.MeetingEntryX = {
            props: meeting
        };
        if (meeting.users.includes(username as PmxApi.api.core.Username)) {
            const msgRes = await this.getPrivmxChatMessagesAndAttachments(threadId);
            (res as types.MeetingEntry).messages = msgRes.messages;
            (res as types.MeetingEntry).attachments = msgRes.attachments;
        }
        return res;
    }

    async getQueries() {
        await apiDelay();
        return db.queries.slice();
    }

    async getQuery(queryId: types.QueryId) {
        await apiDelay();
        return db.queries.find((x) => x.id === queryId);
    }

    async login(
        username: types.Username,
        password: types.UserPassword,
        isMnemonic: boolean = false
    ): Promise<
        | { success: true; userWithCredentials: types.UserWithCredentials }
        | { success: false; error: unknown }
    > {
        try {
            const res = await userApi.loginPrivMX(username, password, isMnemonic);
            return { success: true, userWithCredentials: res };
        } catch (e) {
            console.error('Error during login through privmx', e);
            return { success: false, error: e };
        }
    }

    async enterMeetingLobbyAsAnonymous(
        threadId: PmxApi.api.thread.ThreadId,
        nickname: PmxApi.api.thread.MeetingNickname
    ) {
        await this.ensureAnonSessionInitialized(nickname);
        const joinResult = await this.joinToThreadMeetingLobby(threadId);
        return joinResult;
    }

    async getUsers() {
        return store.getState().users.users;
    }

    async getUsersWithAdminData() {
        const { userData } = this.getSession();
        const adminApi = new AdminApi(userData.srpSecure.gateway);
        const users = await adminApi.getUsers();
        return users
            .filter((x) => x.type === 'local')
            .map((x) => {
                const originDetailsParseResult = Utils.try(() =>
                    x.origin && x.origin.details ? JSON.parse(x.origin.details) : undefined
                );
                const res: types.UserWithAdminData = {
                    id: x.username as string as types.UserId,
                    avatar: (x.cachedPkiEntry && x.cachedPkiEntry.image
                        ? ImageTypeDetector.createDataUrlFromBase64(x.cachedPkiEntry.image)
                        : getAvatarForUser(x.username)) as types.Avatar,
                    isAdmin: x.isAdmin,
                    email: x.email,
                    userType: x.subType === 'email' ? 'email' : 'normal',
                    name:
                        x.cachedPkiEntry && x.cachedPkiEntry.name
                            ? x.cachedPkiEntry.name
                            : x.username,
                    password: '',
                    role: 'staff',
                    username: x.username,
                    blocked: x.blocked,
                    creationDate: parseInt(x.registrationDate, 10) as PmxApi.api.core.TimestampN,
                    lastLoggedIn: (x.lastLoginDate
                        ? parseInt(x.lastLoginDate, 10)
                        : -1) as PmxApi.api.core.TimestampN,
                    generatedPassword: x.generatedPassword,
                    adminData: x.adminData,
                    origin: x.origin
                        ? {
                              createdBy: x.origin.createdBy,
                              createdDate: x.origin.createdDate,
                              details:
                                  originDetailsParseResult.success === true
                                      ? originDetailsParseResult.result
                                      : undefined
                          }
                        : undefined
                };
                return res;
            });
    }

    async getCustomersWithAdminData() {
        const { userData } = this.getSession();
        const adminApi = new AdminApi(userData.srpSecure.gateway);
        const users = await adminApi.getUsers();
        return users
            .filter((x) => x.type === 'basic')
            .map((x) => {
                const originDetailsParseResult = Utils.try(() =>
                    x.origin && x.origin.details ? JSON.parse(x.origin.details) : undefined
                );
                const res: types.CustomerWithAdminData = {
                    id: x.username as string as types.UserId,
                    avatar: (x.cachedPkiEntry && x.cachedPkiEntry.image
                        ? ImageTypeDetector.createDataUrlFromBase64(x.cachedPkiEntry.image)
                        : getAvatarForUser(x.username)) as types.Avatar,
                    email: x.email,
                    userType: x.subType === 'email' ? 'email' : 'normal',
                    name:
                        x.cachedPkiEntry && x.cachedPkiEntry.name
                            ? x.cachedPkiEntry.name
                            : x.username,
                    password: '',
                    role: 'client',
                    username: x.username,
                    creationDate: parseInt(x.registrationDate, 10) as PmxApi.api.core.TimestampN,
                    lastLoggedIn: (x.lastLoginDate
                        ? parseInt(x.lastLoginDate, 10)
                        : -1) as PmxApi.api.core.TimestampN,
                    blocked: x.blocked,
                    generatedPassword: x.generatedPassword,
                    adminData: x.adminData,
                    origin: x.origin
                        ? {
                              createdBy: x.origin.createdBy,
                              createdDate: x.origin.createdDate,
                              details:
                                  originDetailsParseResult.success === true
                                      ? originDetailsParseResult.result
                                      : undefined
                          }
                        : undefined
                };
                return res;
            });
    }

    async getAdminData(adminData: PmxApi.api.core.Base64): Promise<AdminDataInner | null> {
        const { adminDataDecryptorService } = this.getSession();
        return await adminDataDecryptorService.decrypt(adminData);
    }

    async createMissingInternalUsersFromContacts(
        contacts: types.UsernameOrContactId[],
        origin: UserOriginDetails
    ) {
        const newUsernames: types.Username[] = [];
        const newAccounts: Map<types.Username, { email: types.Email; password: string }> =
            new Map();
        const newUsersWithPasswords: Array<{
            username: types.Username;
            password: types.UserPassword;
        }> = [];
        const allUsernames: types.Username[] = [];

        for (const entry of contacts) {
            if (entry.type === 'user') {
                allUsernames.push(entry.username);
                continue;
            }
            const contact = await this.getContact(entry.contactId);
            if (!contact) {
                throw new Error('Contact does not exist');
            }
            const user = contact.username ? this.getUser(contact.username) : null;
            if (user) {
                allUsernames.push(user.username);
                continue;
            }
            const newUser = await this.createInternalUserFromContact(contact, origin);
            newUsernames.push(newUser.username);
            if ('password' in newUser) {
                newUsersWithPasswords.push({
                    username: newUser.username,
                    password: newUser.password as types.UserPassword
                });
                newAccounts.set(newUser.username, {
                    email: contact.email,
                    password: newUser.password
                });
            }
            allUsernames.push(newUser.username);
        }

        if (newUsersWithPasswords.length > 0) {
            await this.updateTemporaryPasswordsStore(newUsersWithPasswords);
        }
        return { newUsernames, allUsernames, newAccounts };
    }

    async createInternalUserFromContact(contact: types.Contact, originDetails: UserOriginDetails) {
        const { managableUserCreator, userData, userService } = this.getSession();
        const users = userService.getUsers();
        const user = users.find((x) => x.raw.subType === 'email' && x.raw.name === contact.email);
        if (user) {
            await this.updateContact({ ...contact, username: user.raw.username });
            return { username: user.raw.username };
        } else {
            const context = await managableUserCreator.createEmailUser({
                creator: userData.identity,
                username: await this.generateUsernameFromContact(contact),
                host: userData.identity.host as PmxApi.api.core.Host,
                language: contact.language || ('en' as PmxApi.api.core.Language),
                email: contact.email,
                description: '' as PmxApi.api.user.UserDescription,
                privateSectionAllowed: false,
                originDetails: originDetails,
                profile: {
                    name: contact.name || undefined
                }
            });
            await this.updateContact({ ...contact, username: context.username });
            return context;
        }
    }

    async resetUserPassword(
        username: PmxApi.api.core.Username,
        adminDataBase64: PmxApi.api.core.Base64
    ) {
        const { adminDataDecryptorService, resetPasswordService } = this.getSession();
        const oldAdminData = await adminDataDecryptorService.decrypt(adminDataBase64);
        if (!oldAdminData) {
            throw new Error('Could not decrypt adminData');
        }
        if (!('recovery' in oldAdminData)) {
            throw new Error('AdminData not managable');
        }
        return resetPasswordService.resetUserPassword(username, oldAdminData);
    }

    private async ensurePrivateContactsAreVisibleToCurrentUser(
        contactsOrIds: types.UsernameOrContactId[]
    ) {
        const currentUserUsername = this.getCurrentUserUsername();
        const contacts: types.Contact[] = contactsOrIds
            .map((x) => {
                if (x.type === 'contact') {
                    return this.getContactSync(x.contactId);
                }
                return this.getContactByUsernameSync(x.username);
            })
            .filter((x) => x !== undefined) as types.Contact[];
        const contactsToMakeVisible: types.Contact[] = contacts.filter(
            (x) => x.isPrivate && !x.privateContactVisibleForUsers.includes(currentUserUsername)
        );
        const otherContacts: types.Contact[] = contacts.filter(
            (x) => !contactsToMakeVisible.includes(x)
        );

        const privateContactsMadeVisible: types.Contact[] = [];
        for (const contact of contactsToMakeVisible) {
            const updatedContact = await this.updateContact({
                ...contact,
                privateContactVisibleForUsers: [
                    ...contact.privateContactVisibleForUsers,
                    currentUserUsername
                ]
            });
            privateContactsMadeVisible.push(updatedContact);
        }

        return {
            privateContactsMadeVisible,
            otherContacts
        };
    }

    private async generateUsernameFromContact(contact: {
        username?: string;
        email?: string;
        name?: string;
    }) {
        const username = this.generateUsernameFromContactCore(contact);
        let index = 0;
        while (true) {
            const newUsername = (username + (index === 0 ? '' : index)) as types.Username;
            const userExists = await this.userExists(newUsername);
            if (!userExists) {
                return newUsername;
            }
            index++;
        }
    }

    private generateUsernameFromContactCore(contact: {
        username?: string;
        email?: string;
        name?: string;
    }) {
        if (contact.username && this.usernameIsValid(contact.username)) {
            return contact.username as types.Username;
        }
        const fromEmail = contact.email ? this.normalize(contact.email.split('@')[0]) : '';
        if (this.usernameIsValid(fromEmail)) {
            return fromEmail as types.Username;
        }

        const fromEmailWithPostfix = contact.email
            ? this.normalize(contact.email.split('@')[0] + '_user')
            : '';

        if (this.usernameIsValid(fromEmailWithPostfix)) {
            return fromEmailWithPostfix as types.Username;
        }

        const fromName = contact.name ? this.normalize(contact.name.split('@')[0]) : '';
        if (this.usernameIsValid(fromName)) {
            return fromName as types.Username;
        }
        return this.generateUsername();
    }

    private generateUsername() {
        return privmx.crypto.service.randomBytes(10).toString('hex') as types.Username;
    }

    private usernameIsValid(username: string) {
        const usernameRegex = /^[a-z0-9]+([._-]?[a-z0-9]+)*$/;
        return username.length >= 3 && username.length <= 50 && usernameRegex.test(username);
    }

    private normalize(str: string) {
        return str
            .toLocaleLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/ł/g, 'l')
            .replaceAll('+', '_');
    }

    async createInternalUser(
        email: PmxApi.api.core.Email,
        language: PmxApi.api.core.Language,
        isAdmin: boolean,
        originDetails: UserOriginDetails
    ) {
        const { managableUserCreator, userData } = this.getSession();
        const context = await managableUserCreator.createNormalEmailUser({
            creator: userData.identity,
            username: await this.generateUsernameFromContact({ email: email }),
            host: userData.identity.host as PmxApi.api.core.Host,
            language: language,
            email: email,
            description: '' as PmxApi.api.user.UserDescription,
            admin: isAdmin,
            shareCommonKvdb: true,
            originDetails: originDetails,
            profile: {}
        });
        return context;
    }

    async setUserIsAdmin(username: PmxApi.api.core.Username, isAdmin: boolean) {
        const { adminRightService } = this.getSession();
        if (isAdmin) {
            await adminRightService.grantAdminRights(username);
        } else {
            await adminRightService.revokeAdminRights(username);
        }
    }

    async setUserBlocked(username: PmxApi.api.core.Username, blocked: boolean) {
        const { userData } = this.getSession();
        const adminApi = new AdminApi(userData.srpSecure.gateway);
        await adminApi.setUserBlocked({ username, blocked });
    }

    getGatewayHost(): string {
        return getGatewayHost();
    }

    private generateDeviceId() {
        const array = new Uint8Array(10);
        if (window.crypto && window.crypto.getRandomValues) {
            window.crypto.getRandomValues(array);
        } else {
            for (let i = 0; i < array.length; i++) {
                array[i] = Math.floor(Math.random() * 256);
            }
        }
        let str = '';
        for (const byte of array) {
            str += byte.toString(16);
        }
        return str;
    }

    async loginPrivMX(
        username: string,
        password: string,
        isMnemonic: boolean = false
    ): Promise<types.UserWithCredentials> {
        await this.logout(false);
        await this.ensurePrivmxClientScriptLoaded();
        const host = this.getGatewayHost();
        let deviceId = window.localStorage.getItem('privmx-device-id');
        if (!deviceId) {
            deviceId = this.generateDeviceId();
            window.localStorage.setItem('privmx-device-id', deviceId);
        }
        privmx.core.PrivFsRpcManager.setGatewayProperties({
            deviceId: deviceId
        });
        const additionalLoginStepCallback: privmx.types.core.AdditionalLoginStepCallback = async (
            context,
            data
        ) => {
            const twofaMethod = (data?.type as TwofaMethod) ?? 'googleAuthenticator';
            const webauthnLogin = data?.webauthnLogin;
            let errorMessage: string | undefined = undefined;
            const maxNumAttempts = 3;
            let twofaModal: ModalController<TwofaPromptModalData> | undefined;
            for (let attemptsLeft = maxNumAttempts; attemptsLeft > 0; --attemptsLeft) {
                const currentErrorMessage =
                    (errorMessage ?? '') +
                    (attemptsLeft === maxNumAttempts
                        ? ''
                        : ` (${attemptsLeft} attempt${attemptsLeft === 1 ? '' : 's'} Left)`);
                const twofaResult = await modalPrompt<TwofaResult>(store, {
                    clearResult: () => store.dispatch(setTwofaResult(null)),
                    // eslint-disable-next-line no-loop-func
                    showModal: () => {
                        const data: TwofaPromptModalData = {
                            options: {
                                method: twofaMethod,
                                errorMessage: currentErrorMessage,
                                uf2Login: webauthnLogin
                            }
                        };
                        if (twofaModal) {
                            twofaModal.update(data);
                        } else {
                            twofaModal = modalService.openTwofaPromptModal(data);
                        }
                    },
                    extractResultFromState: (state) =>
                        state.modals.twofaResult
                            ? { exists: true, result: state.modals.twofaResult }
                            : { exists: false }
                });
                if ('error' in twofaResult) {
                    errorMessage = twofaResult.error;
                    store.dispatch(setTwofaResult(null));
                    if (attemptsLeft === 1) {
                        context.service.reject(PrivmxConst.TWOFA_VERIFICATION_FAILED);
                        if (twofaModal) {
                            twofaModal.close();
                        }
                        return;
                    }
                    continue;
                }
                if (twofaResult.cancelled) {
                    context.service.reject('Cancelled by user');
                    return;
                }
                if ('resendCode' in twofaResult) {
                    attemptsLeft++;
                    try {
                        await context.service.resendCode();
                        notifications.show({
                            message: t('twofa.message.codeSent'),
                            autoClose: 5000
                        });
                    } catch (e) {
                        notifications.show({
                            title: 'Something went wrong',
                            message: t('twofa.error.couldNotSendCode'),
                            autoClose: 5000,
                            color: 'red'
                        });
                    }
                    continue;
                }
                const challengeModel = this.deserializeTwofaChallengeModel(
                    twofaResult.challengeModel
                );
                store.dispatch(setTwofaResult(null));
                try {
                    await context.service.confirm(challengeModel);
                    if (twofaModal) {
                        twofaModal.close();
                    }
                    return;
                } catch (e: any) {
                    console.error('2FA Error', e);
                    errorMessage = TwofaService.getTwofaErrorMessage(e);
                    store.dispatch(setTwofaResult(null));
                    if (attemptsLeft === 1) {
                        context.service.reject(e);
                        if (twofaModal) {
                            twofaModal.close();
                        }
                        return;
                    }
                }
            }
        };
        const loginService = await (isMnemonic
            ? privmx.core.PrivFsRpcManager.getBip39LoginService({
                  host: host,
                  identityIndex: PrivmxConst.IDENTITY_INDEX,
                  additionalLoginStepCallback: additionalLoginStepCallback
              })
            : privmx.core.PrivFsRpcManager.getSrpLoginService({
                  host: host,
                  identityIndex: PrivmxConst.IDENTITY_INDEX,
                  additionalLoginStepCallback: additionalLoginStepCallback
              }));
        const loginStep1 = await (loginService instanceof privmx.core.Bip39LoginService
            ? loginService.loginWithoutProcessingMasterRecord(password, true)
            : loginService.loginWithoutProcessingMasterRecord(username, password, true));
        const res = await privmx.core.LoginUtils.loginLastStep(
            loginStep1.srpSecure,
            loginStep1.decryptedMasterRecord.masterRecord,
            PrivmxConst.IDENTITY_INDEX
        );
        const credentialsHolder = new CredentialsHolder();
        await credentialsHolder.store(username as types.Username, password);
        if (res.myData.raw.generatedPassword) {
            const changedPasswordDeferred = new Deferred<void>();
            modalService.openPasswordChangePromptModal({
                options: {
                    login: username,
                    currentPassword: password,
                    isGeneratedPassword: true,
                    saveCallback: async (newPassword, mnemonic) => {
                        if (mnemonic === null) {
                            await res.srpSecure.changePasswordByOldPassword(
                                res.myData.raw.login,
                                password,
                                newPassword,
                                newPassword.length < 8
                            );
                        } else {
                            await res.srpSecure.changePasswordByRecovery(
                                mnemonic,
                                res.myData.raw.login,
                                newPassword,
                                newPassword.length < 8
                            );
                        }
                        await credentialsHolder.store(username as types.Username, newPassword);
                        changedPasswordDeferred.resolve();
                    },
                    cancelCallback: () => {
                        changedPasswordDeferred.reject('Cancelled by user');
                    }
                }
            });
            await changedPasswordDeferred.promise;
        }
        const gateway = res.srpSecure.gateway;
        const requestConfigPromise = new RequestApi(gateway).getRequestConfig();
        const serverConfigPromise = new ConfigApi(res.srpSecure.gateway).getConfig();
        const networkStatusService = new NetworkStatusService();
        const reconnectService = new ReconnectService(networkStatusService);
        const connectionChecker = new ConnectionChecker(
            gateway,
            credentialsHolder,
            reconnectService,
            () => this.logout()
        );
        const client = new privmx.core.Client(gateway, res.identity, res.masterExtKey);
        await client.init();
        const sinkEncryptor = privmx.crypto.service.getObjectEncryptor(
            (await client.deriveKey(PrivmxConst.SINK_ENCRYPTOR_INDEX)).getChainCode()
        );
        const adminKeyHolder = new AdminKeyHolder(res, res.srpSecure);
        const modalsService = new ModalsService();
        const cacheService = new DataCacheService();
        const stateUpdater = new StateUpdater();

        const adminKeyChecker = new AdminKeyChecker(
            res,
            res.srpSecure,
            adminKeyHolder,
            client.getMessageManager(),
            sinkEncryptor
        );
        const checkAdminKeyResult = await adminKeyChecker.checkAdminKey();
        const cosignersChecker = new CosignersChecker(res, res.srpSecure);
        await cosignersChecker.check();
        const adminExtKey = res.myData.raw.isAdmin
            ? await privmx.core.CryptoUtils.deriveHardened(
                  adminKeyHolder.getAdminKey(),
                  PrivmxConst.ADMIN_KVDB_INDEX
              )
            : undefined;
        const kvdbCollectionManager = new KvdbCollectionManager(
            new privmx.db.KeyValueDbManager(client),
            new KvdbCache(new InMemoryCache()),
            new KvdbStateService()
        );
        async function getExtKeyFromKvdb(kvdb: KvdbCollection<KvdbSettingEntryX>, key: string) {
            const settingEntry = await kvdb.get(key);
            return settingEntry && typeof settingEntry.secured.value === 'string'
                ? privmx.crypto.ecc.ExtKey.fromBase58(settingEntry.secured.value)
                : null;
        }
        async function getKvdbFromParent<T = unknown>(
            kvdb: KvdbCollection<KvdbSettingEntryX>,
            key: string,
            onlyAdminWrite?: boolean
        ) {
            const extKey = await getExtKeyFromKvdb(kvdb, key);
            if (!extKey) {
                if (!res.myData.raw.isAdmin) {
                    throw new Error('No access to kvdb');
                }
                const extKey = privmx.crypto.serviceSync.eccExtRandom();
                await kvdb.set(
                    key,
                    KvdbUtils.createKvdbSettingEntry(extKey.getPrivatePartAsBase58())
                );
                const newKvdb = await kvdbCollectionManager.getOrCreateByExtKey<
                    KvdbSettingEntryX<T>
                >(extKey, {
                    acl: {
                        manage: '<admin>',
                        write: onlyAdminWrite ? '<admin>' : '<local>',
                        read: '<local>'
                    }
                });
                return newKvdb;
            }
            return kvdbCollectionManager.getByExtKey<KvdbSettingEntryX<T>>(extKey);
        }
        const userSettingsKvdb = await kvdbCollectionManager.getOrCreateByIndex<KvdbSettingEntryX>(
            PrivmxConst.SETTINGS_KVDB_INDEX,
            {}
        );
        userSettingsKvdb.onChange((x) => {
            if (x.secured.key === PrivmxConst.FAVORITE_KEY) {
                this.dispatchEvent<types.FavoritesUpdatedEvent>({
                    type: 'favoritesupdated',
                    favorites: (x.secured.value as types.FavoriteMessage[]).slice()
                });
            }
        });
        const sharedKvdb =
            res.myData.raw.type === 'local'
                ? await getKvdbFromParent(userSettingsKvdb, PrivmxConst.SHARED_KVDB_KEY, true)
                : undefined;
        async function getExtKeyFromParent(kvdb: KvdbCollection<KvdbSettingEntryX>, key: string) {
            const extKey = await getExtKeyFromKvdb(kvdb, key);
            if (!extKey) {
                if (!res.myData.raw.isAdmin) {
                    throw new Error('No access to kvdb');
                }
                const extKey = privmx.crypto.serviceSync.eccExtRandom();
                await kvdb.set(
                    key,
                    KvdbUtils.createKvdbSettingEntry(extKey.getPrivatePartAsBase58())
                );
                return { created: true, extKey };
            }
            return { created: false, extKey };
        }
        const oldCompaniesMap = new Map<types.CompanyId, PmxApi.api.company.CompanyId>();
        async function createCompanyService() {
            if (!sharedKvdb) {
                return undefined;
            }
            const { created, extKey } = await getExtKeyFromParent(
                sharedKvdb,
                PrivmxConst.COMPANY_EXT_KEY
            );
            const companySrv = new CompanyService(
                new CompanyApi(gateway),
                { id: 'companyKey' as PmxApi.api.core.KeyId, key: extKey.getChainCode() },
                new DataEncryptor(),
                tagService
            );
            if (created) {
                // migrate old companies
                const companyKvdb = await getKvdbFromParent<types.Company>(
                    sharedKvdb,
                    PrivmxConst.COMPANY_KVDB_KEY
                );
                const all = await companyKvdb.getAll();

                for (const x of all) {
                    const oldCompany = x.secured.value;
                    try {
                        const company = await companySrv.createCompany(
                            {
                                name: oldCompany.name,
                                address: oldCompany.address,
                                email: oldCompany.email,
                                mobilePhone: oldCompany.mobilePhone,
                                note: oldCompany.note,
                                phone: oldCompany.phone,
                                website: oldCompany.website
                            },
                            oldCompany.tags ?? []
                        );
                        oldCompaniesMap.set(oldCompany.id, company.raw.id);
                    } catch (e) {
                        console.error('Error migrating company', e);
                    }
                }
            }
            return companySrv;
        }
        const existingAttachmentsProvider: ExistingAttachmentsProvider = {
            getThreadAttachment: (attachmentId, thumb) =>
                this.getThreadAttachmentWithMeta(attachmentId, !!thumb, {}),
            getDraftAttachment: (attachmentId, thumb) =>
                this.getDraftAttachmentWithMeta(attachmentId, !!thumb, {}),
            getInquiryAttachment: (attachmentId, thumb) =>
                this.getInquiryAttachmentWithMeta(attachmentId, !!thumb, {})
        };
        async function createDraftService() {
            if (!sharedKvdb) {
                return undefined;
            }
            const { extKey } = await getExtKeyFromParent(sharedKvdb, PrivmxConst.DRAFT_EXT_KEY);

            const draftSrv = new DraftService(
                gateway,
                { id: 'draftKey' as PmxApi.api.core.KeyId, key: extKey.getChainCode() },
                tagService,
                existingAttachmentsProvider,
                cacheService,
                modalsService
            );
            return draftSrv;
        }
        async function createSharedFileService() {
            if (!sharedKvdb) {
                return new SharedFileService(
                    gateway,
                    keyProvider,
                    null,
                    tagService,
                    existingAttachmentsProvider,
                    cacheService,
                    modalsService
                );
            }
            const { extKey } = await getExtKeyFromParent(
                sharedKvdb,
                PrivmxConst.SHARED_FILE_USER_DATA_EXT_KEY
            );
            return new SharedFileService(
                gateway,
                keyProvider,
                {
                    id: 'sharedFileUserDataExtKey' as PmxApi.api.core.KeyId,
                    key: extKey.getChainCode()
                },
                tagService,
                existingAttachmentsProvider,
                cacheService,
                modalsService
            );
        }
        async function createContactService() {
            if (!sharedKvdb) {
                return undefined;
            }
            const { created, extKey } = await getExtKeyFromParent(
                sharedKvdb,
                PrivmxConst.CONTACT_EXT_KEY
            );
            const contactSrv = new ContactService(
                new ContactApi(gateway),
                { id: 'contactKey' as PmxApi.api.core.KeyId, key: extKey.getChainCode() },
                new DataEncryptor(),
                tagService
            );
            if (created) {
                // migrate old contactss
                const contactsKvdb = await getKvdbFromParent<types.Contact>(
                    sharedKvdb,
                    PrivmxConst.CONTACT_KVDB_KEY
                );
                const all = await contactsKvdb.getAll();

                for (const x of all) {
                    const oldContact = x.secured.value;
                    try {
                        await contactSrv.createContact({
                            email: oldContact.email,
                            username: null,
                            company:
                                (oldContact.companyId &&
                                    oldCompaniesMap.get(oldContact.companyId)) ||
                                null,
                            props: {
                                address: oldContact.address,
                                avatar: oldContact.avatar,
                                language: oldContact.language,
                                mobilePhone: oldContact.mobilePhone,
                                name: oldContact.name,
                                note: oldContact.note,
                                phone: oldContact.phone,
                                isPrivate: !!oldContact.isPrivate,
                                privateContactVisibleForUsers:
                                    oldContact.privateContactVisibleForUsers ?? []
                            },
                            tags: oldContact.tags ?? []
                        });
                    } catch (e) {
                        console.error('Error migrating contact', e);
                    }
                }
            }
            return contactSrv;
        }
        async function readTagKeyFromKvdb(kvdb: KvdbCollection<KvdbSettingEntryX>, key: string) {
            const settingEntry = await kvdb.get(key);
            if (
                !settingEntry ||
                !settingEntry.secured.value ||
                typeof settingEntry.secured.value !== 'object'
            ) {
                return null;
            }
            const serialized = settingEntry.secured.value as TagKeySerialized;
            const res: TagEncryptionKeys = {
                iv: Base64.toBuf(serialized.iv),
                key: Base64.toBuf(serialized.key),
                hmacKey: Base64.toBuf(serialized.hmacKey)
            };
            return res;
        }
        async function getTagKeyFromKvdb(kvdb: KvdbCollection<KvdbSettingEntryX>, key: string) {
            const tagKey = await readTagKeyFromKvdb(kvdb, key);
            if (!tagKey) {
                if (!res.myData.raw.isAdmin) {
                    throw new Error('No access to kvdb');
                }
                const newTagKeys: TagEncryptionKeys = {
                    iv: privmx.crypto.service.randomBytes(16),
                    key: privmx.crypto.service.randomBytes(32),
                    hmacKey: privmx.crypto.service.randomBytes(32)
                };
                const serialized: TagKeySerialized = {
                    iv: Base64.from(newTagKeys.iv),
                    key: Base64.from(newTagKeys.key),
                    hmacKey: Base64.from(newTagKeys.hmacKey)
                };
                await kvdb.set(key, KvdbUtils.createKvdbSettingEntry(serialized));
                return { created: true, tagKey: newTagKeys };
            }
            return { created: false, tagKey };
        }
        async function createTagEncryptionService() {
            if (!sharedKvdb) {
                return new TagEncryptionService(null);
            }
            const { tagKey } = await getTagKeyFromKvdb(sharedKvdb, PrivmxConst.TAG_KEY);
            return new TagEncryptionService(tagKey);
        }
        const tagEncryptionService = await createTagEncryptionService();
        const tagService = new TagService(
            userSettingsKvdb as KvdbCollection<TagsKvdbEntryType>,
            this,
            tagEncryptionService
        );
        const stickerService = new StickerService(tagEncryptionService);
        const serverConfig = await serverConfigPromise;
        const cachePrefix = `${serverConfig.serverDataId}/${res.identity.user}/`;
        const pkiService = new PkiService(res.srpSecure, cachePrefix);
        const keyProvider = new KeyProvider(res.identity, pkiService);
        const userService = new UserService(
            new UserPollApi(gateway),
            {
                dispatchEvent: async (evt: { type: string }) => {
                    const e = evt as UserChangedCoreEvent;
                    if (e.type === 'userchangedcore') {
                        this.dispatchEvent<types.UserChangedEvent>({
                            type: 'userchanged',
                            user: this.convertUser(e.user)
                        });
                    }
                }
            },
            {
                convertUser: (userEntry) => this.convertUser(userEntry)
            }
        );
        const inquiryService = new InquiryService(
            gateway,
            keyProvider,
            tagService,
            existingAttachmentsProvider,
            cacheService,
            modalsService
        );

        const threadService = new ThreadService(
            gateway,
            res.identity,
            keyProvider,
            inquiryService,
            stickerService,
            userService,
            tagService,
            existingAttachmentsProvider,
            () => null,
            () => null,
            stateUpdater,
            cacheService,
            modalsService
        );
        const videoService = new VideoService(gateway, threadService);
        const sharedFileService = await createSharedFileService();
        const messageVerifier = new MessageVerifier(
            pkiService,
            {
                dispatchEvent: async (evt: { type: string }) => {
                    const e = evt as MessageVerifiedStatusChangedCoreEvent;
                    if (e.type === 'messageverifiedstatuschangedcore') {
                        this.dispatchEvent<types.MessageVerifiedStatusChangedEvent>({
                            type: 'messageverifiedstatuschanged',
                            threadId: e.message.msg.threadId as types.ChatId,
                            message: await this.convertMessage(
                                { msg: e.message.msg, sig: Utils.resultSuccess(e.message.sig) },
                                tagEncryptionService,
                                stickerService,
                                messageVerifier,
                                await this.getFavorites()
                            )
                        });
                    }
                }
            },
            cachePrefix
        );
        const threadVerifier = new ThreadVerifier(
            pkiService,
            {
                dispatchEvent: async (evt: { type: string }) => {
                    const e = evt as ThreadVerifiedStatusChangedCoreEvent;
                    if (e.type === 'threadverifiedstatuschangedcore') {
                        if (e.thread.thread.type === 'meeting') {
                            this.dispatchEvent<types.MeetingVerifiedStatusChangedEvent>({
                                type: 'meetingverifiedstatuschanged',
                                meeting: this.convertThreadToMeeting(e.thread)
                            });
                        } else {
                            this.dispatchEvent<types.ChatVerifiedStatusChangedEvent>({
                                type: 'chatverifiedstatuschanged',
                                chat: this.convertThreadToChat(e.thread)
                            });
                        }
                    }
                }
            },
            cachePrefix
        );
        const companyService = (await createCompanyService()) as CompanyService;
        const contactService = (await createContactService()) as ContactService;
        const draftService = (await createDraftService()) as DraftService;
        const adminKvdb = adminExtKey
            ? await kvdbCollectionManager.getOrCreateByExtKey(adminExtKey, {
                  acl: {
                      manage: '<admin>',
                      read: '<admin>',
                      write: '<admin>'
                  }
              })
            : undefined;
        if (checkAdminKeyResult) {
            if (res.myData.raw.isAdmin && sharedKvdb) {
                await new SharedDbChecker(
                    sharedKvdb as KvdbCollection<KvdbSettingEntry>,
                    adminKvdb as KvdbCollection<KvdbSettingEntry>,
                    client.getMessageManager()
                ).checkSharedDb();
            }
        }
        const adminDataV2Decryptor = adminKvdb
            ? new AdminDataV2Decryptor({
                  getAdminPrivKey: () => {
                      const sinkWif = adminKvdb.getSync(PrivmxConst.ADMIN_SINK_KEY);
                      if (!sinkWif || !sinkWif.secured) {
                          throw new Error('Cannot read admin priv key from admin kvdb');
                      }
                      const secured = sinkWif.secured as { value: string };
                      const adminSink = privmx.message.MessageSinkPriv.fromSerialized(
                          secured.value,
                          '',
                          '',
                          'public',
                          { type: 'admin-sink' },
                          {}
                      );
                      return adminSink.priv;
                  }
              })
            : undefined;
        const adminDataV2Encryptor = sharedKvdb
            ? new AdminDataV2Encryptor({
                  getAdminPubKey: () => {
                      const pub58 = sharedKvdb.getSync(PrivmxConst.ADMIN_SINK_KEY);
                      if (!pub58) {
                          throw new Error('Cannot read admin pub key from shared kvdb');
                      }
                      return privmx.crypto.ecc.PublicKey.fromBase58DER(
                          pub58.secured.value as string
                      );
                  }
              })
            : undefined;
        const adminDataDecryptorService = adminKvdb
            ? new AdminDataDecryptorService(adminDataV2Decryptor!)
            : undefined;
        const userCreationService = sharedKvdb
            ? new UserCreationService(
                  new UserCreationContextBuilder(
                      gateway,
                      res.srpSecure.privmxPKI,
                      adminDataV2Encryptor!,
                      {
                          getSharedExtKey: () => {
                              const ext = userSettingsKvdb.getSync(PrivmxConst.SHARED_KVDB_KEY);
                              if (!ext) {
                                  throw new Error('Cannot read shared ext key from settings kvdb');
                              }
                              return privmx.crypto.ecc.ExtKey.fromBase58(
                                  ext.secured.value as string
                              );
                          }
                      }
                  ),
                  new AddUserModelBuilder(new ApiSerializer()),
                  new AdminApi(gateway),
                  userService
              )
            : undefined;
        const resetPasswordService = sharedKvdb
            ? new ResetPasswordService(gateway, adminDataV2Encryptor!)
            : undefined;
        const adminRightService = new AdminRightService(
            res.srpSecure,
            new AdminKeySender(
                new MessageService(
                    client.getMessageManager(),
                    res.identity,
                    new HashmailResolver(res.srpSecure)
                ),
                adminKeyHolder,
                res.identity
            ),
            res.identityKeypair
        );
        const managableUserCreator = userCreationService
            ? new ManagableUserCreator(userCreationService, adminRightService)
            : undefined;
        const twofaService = new TwofaService(gateway);
        const unreadService = new UnreadService(userSettingsKvdb, this);
        const session: Session = {
            onboardingPassed: userSettingsKvdb.collection.some(
                (x) => x.secured.key === 'onboardingPassed'
            ),
            userCreationService: userCreationService as UserCreationService,
            managableUserCreator: managableUserCreator as ManagableUserCreator,
            resetPasswordService: resetPasswordService as ResetPasswordService,
            adminRightService,
            pkiService,
            userService,
            userData: res,
            threadService,
            videoService,
            inquiryService,
            draftService,
            messageVerifier,
            threadVerifier,
            userSettingsKvdb,
            sharedKvdb: sharedKvdb as KvdbCollection<KvdbSettingEntryX<unknown>>,
            tagEncryptionService,
            tagService,
            stickerService,
            connectionChecker,
            credentialsHolder,
            networkStatusService,
            reconnectService,
            adminDataDecryptorService: adminDataDecryptorService as AdminDataDecryptorService,
            twofaService,
            unreadService,
            contactService,
            companyService,
            companiesCache: new Map(),
            contactsCache: new Map(),
            requestConfig: await requestConfigPromise,
            serverConfig: serverConfig,
            sharedFileService: sharedFileService
        };
        this.session = session;
        void this.loadDataOnLogin();
        this.addWebSocketListeners();
        await userService.refresh();
        videoService.updateVideoRoomsState();

        async function checkAdminDataForMyself() {
            if (!res.myData.raw.isAdmin || !adminDataV2Decryptor || !adminDataV2Encryptor) {
                return;
            }
            const username = res.identity.user as PmxApi.api.core.Username;
            const l1 = loginStep1.decryptedMasterRecord.masterRecord.l1;
            const adminApi = new AdminApi(res.srpSecure.gateway);
            const myself = await adminApi.getUser({ username });
            if (myself.adminData) {
                return;
            }
            const adminData: AdminDataManagable = {
                generatedPassword: '',
                masterSeed: l1.masterSeed,
                recovery: l1.recovery
            };
            await adminApi.modifyUser({
                username: username,
                adminDataV2: true,
                properties: {
                    adminData: await adminDataV2Encryptor.encrypt(adminData)
                }
            });
        }
        await checkAdminDataForMyself();

        const avatar = res.myData.userInfo.profile.image
            ? (ImageTypeDetector.createDataUrlFromBuffer(
                  res.myData.userInfo.profile.image
              ) as types.Avatar)
            : dbGenerator.randomAvatar();
        const userType =
            res.myData.raw.login && res.myData.raw.login.includes('@') ? 'email' : 'normal';
        const newUser: types.User = {
            id: this.getUserId(res.identity.user),
            avatar: avatar,
            userType: userType,
            name:
                res.myData.userInfo.profile.name ||
                (userType === 'normal' ? res.identity.user : res.myData.raw.login.split('@')[0]),
            role:
                res.myData.raw.type === 'basic'
                    ? ('client' as string as types.User['role'])
                    : 'staff',
            username: res.identity.user as types.Username,
            email: res.myData.raw.notificationsEntry?.email as types.Email,
            password: '',
            isAdmin: res.myData.raw.isAdmin,
            blocked: false,
            creationDate: -1 as types.Timestamp,
            lastLoggedIn: -1 as types.Timestamp,
            origin: undefined
        };
        networkStatusService.registerAndStartService({
            serviceName: 'kvdbPolling',
            restore: () => kvdbCollectionManager.startPolling(),
            pause: () => kvdbCollectionManager.stopPolling()
        });
        networkStatusService.registerService({
            serviceName: 'subscriptionRegistry',
            restore: () => subscriptionRegistry.revalidateAll(),
            pause: () => {}
        });
        await this.getAcceptedDocuments();
        this.dispatchEvent({ type: 'loggedin' });
        return { user: newUser, credentials: credentialsHolder };
    }

    async matchOnboardingAsPassed() {
        const { userSettingsKvdb } = this.getSession();
        await userSettingsKvdb.set('onboardingPassed', { secured: { value: 'passed' } });
    }

    getIsOnboardingPassed() {
        const { onboardingPassed } = this.getSession();
        return onboardingPassed;
    }

    async getMnemonic(password: string): Promise<string | null> {
        const session = this.anonSession ? null : this.getSession();
        if (!session) {
            return null;
        }
        return this.getMnemonicCore(
            session.userData.srpSecure,
            session.userData.myData.raw.login,
            password
        );
    }

    private async getMnemonicCore(
        srpSecure: privmx.core.PrivFsSrpSecure,
        login: string,
        password: string
    ): Promise<string | null> {
        const recoveryInfo = await srpSecure.getRecoveryInfo(login, password);
        return recoveryInfo.mnemonic;
    }

    private async loadDataOnLogin() {
        const tasks: [string, () => Promise<unknown>][] = [
            ['loading chats', () => store.dispatch(loadChatsAsync(this))],
            ['loading meetings', () => store.dispatch(loadMeetingsAsync(this))],
            ['loading inquiry threads', () => store.dispatch(loadInquirySubmitThreadsAsync(this))],
            ['loading forms', () => store.dispatch(loadFormsAsync(this))],
            ['loading attachments', () => store.dispatch(loadAttachmentsAsync(this))],
            [
                'loading forms with submits with threads',
                () =>
                    store.dispatch(
                        loadFormsWithSubmitsThatHaveChatsAsync({
                            api: this,
                            threadIds: store
                                .getState()
                                .dataCache.inquirySubmitThreads.map((x) => x.id)
                        })
                    )
            ],
            ['loading contacts', () => this.getContacts()]
        ];
        for (const [, task] of tasks) {
            if (this.session === null) {
                return;
            }
            await task();
        }
    }

    getUserType(): types.UserType | null {
        if (!this.session) {
            return this.anonSession ? 'anonymous' : null;
        }
        const { userData } = this.getSession();
        if (userData.myData.raw.type === 'local') {
            return 'local';
        } else if (userData.myData.raw.type === 'basic') {
            return 'basic';
        } else {
            throw new Error(`Unknown user type: "${userData.myData.raw.type}".`);
        }
    }

    private addWebSocketListeners() {
        const session = this.anonSession ? null : this.getSession();
        const anonSession = this.anonSession ? this.getAnonSession() : null;
        const anySession = this.anonSession ? this.getAnonSession() : this.getSession();

        const gateway = session ? session.userData.srpSecure.gateway : anonSession!.gateway;
        const threadService = anySession.threadService;
        const tagEncryptionService = anySession.tagEncryptionService;
        const stickerService = anySession.stickerService;
        const messageVerifier = anySession.messageVerifier;
        const userService = anySession.userService;
        const companyService = session ? session.companyService : null;
        const contactService = session ? session.contactService : null;
        const inquiryService = session ? session.inquiryService : null;
        const draftService = session ? session.draftService : null;
        const unreadService = session ? session.unreadService : null;
        const currentUserId = session
            ? (session.userData.identity.user as types.Username)
            : anonSession!.pub;
        const sharedFileService = anySession.sharedFileService;

        gateway.addEventListener('notification', async (e) => {
            const evt = { type: e.notificationType, data: e.data } as
                | PmxApi.api.event.ThreadNewMessageEvent
                | PmxApi.api.event.ThreadUpdatedMessageEvent
                | PmxApi.api.event.ThreadNewStateEvent
                | PmxApi.api.event.ThreadUpdatedEvent
                | PmxApi.api.event.ThreadUsersWithAccessibleTimeRangesUpdated
                | PmxApi.api.event.ThreadCreatedEvent
                | PmxApi.api.event.ThreadNewAttachmentsEvent
                | PmxApi.api.event.ThreadDeletedAttachmentsEvent
                | PmxApi.api.event.VideoRoomStateV2Event
                | PmxApi.api.event.CompanyCreatedEvent
                | PmxApi.api.event.CompanyUpdatedEvent
                | PmxApi.api.event.CompanyDeletedEvent
                | PmxApi.api.event.ContactCreatedEvent
                | PmxApi.api.event.ContactUpdatedEvent
                | PmxApi.api.event.ContactDeletedEvent
                | PmxApi.api.event.UserPkiRevisionEvent
                | PmxApi.api.event.NewUserEvent
                | PmxApi.api.event.InquiryNewStateEvent
                | PmxApi.api.event.InquiryCreatedEvent
                | PmxApi.api.event.InquiryUpdatedEvent
                | PmxApi.api.event.InquiryNewSubmitEvent
                | PmxApi.api.event.InquirySubmitUpdatedEvent
                | PmxApi.api.event.InquiryDeletedSubmitEvent
                | PmxApi.api.event.InquirySubmitResponseCreatedEvent
                | PmxApi.api.event.ThreadMeetingLobbyChangeEvent
                | PmxApi.api.event.ThreadMeetingMeetingChangeEvent
                | PmxApi.api.event.DraftCreatedEvent
                | PmxApi.api.event.DraftUpdatedEvent
                | PmxApi.api.event.DraftDeletedEvent
                | PmxApi.api.event.SharedFileCreatedEvent
                | PmxApi.api.event.SharedFileUpdatedEvent
                | PmxApi.api.event.SharedFileDeletedEvent;
            if (evt.type === 'threadNewMessage' || evt.type === 'threadUpdatedMessage') {
                const thread = await threadService.getThread(evt.data.threadId);
                const threadInfo = await threadService.decryptThread(thread);
                const msgFull = await Utils.tryPromise(() =>
                    threadService.decryptMessage(threadInfo, evt.data)
                );
                const favorites = await this.getFavorites();
                this.dispatchEvent<types.NewChatMessageEvent>({
                    type: 'newchatmessage',
                    threadId: evt.data.threadId as types.ChatId,
                    message: await this.convertMessage(
                        { msg: evt.data, sig: msgFull },
                        tagEncryptionService,
                        stickerService,
                        messageVerifier,
                        favorites
                    )
                });
                if (threadInfo.thread.type === 'chat') {
                    store.dispatch(upsertChat(this.convertThreadToChat(threadInfo)));
                } else if (threadInfo.thread.type === 'meeting') {
                    store.dispatch(upsertMeeting(this.convertThreadToMeeting(threadInfo)));
                } else if (threadInfo.thread.type === 'inquirySubmit') {
                    store.dispatch(upsertInquirySubmitThread(this.convertThreadToChat(threadInfo)));
                }
                if (evt.type === 'threadNewMessage') {
                    if (evt.data.author === currentUserId && unreadService) {
                        unreadService.markAllMessagesInThreadAsRead(
                            evt.data.threadId as types.ChatId
                        );
                    }
                    this.updateItemCountsGrouppedByUsers();
                }
                if (unreadService) {
                    unreadService.dispatchUnreadStateChangedEvent();
                }
            } else if (evt.type === 'threadCreated' || evt.type === 'threadUpdated') {
                const threadInfo = await threadService.decryptThread(evt.data);
                if (threadInfo.thread.type === 'chat') {
                    this.dispatchEvent<types.ChatUpdatedEvent>({
                        type: 'chatupdated',
                        chat: this.convertThreadToChat(threadInfo)
                    });
                } else if (threadInfo.thread.type === 'meeting') {
                    this.dispatchEvent<types.MeetingUpdatedEvent>({
                        type: 'meetingupdated',
                        meeting: this.convertThreadToMeeting(threadInfo)
                    });
                }
                if (threadInfo.thread.type === 'chat') {
                    store.dispatch(upsertChat(this.convertThreadToChat(threadInfo)));
                    if (unreadService) {
                        unreadService.dispatchUnreadStateChangedEvent();
                    }
                } else if (threadInfo.thread.type === 'meeting') {
                    store.dispatch(upsertMeeting(this.convertThreadToMeeting(threadInfo)));
                    if (unreadService) {
                        unreadService.dispatchUnreadStateChangedEvent();
                    }
                } else if (threadInfo.thread.type === 'inquirySubmit') {
                    store.dispatch(upsertInquirySubmitThread(this.convertThreadToChat(threadInfo)));
                    if (unreadService) {
                        unreadService.dispatchUnreadStateChangedEvent();
                    }
                }
                if (this.getUserType() === 'basic') {
                    const usernames = threadInfo.thread.users;
                    this.refreshUsersListIfUsernamesAreMissing(usernames);
                }
            } else if (evt.type === 'threadNewState') {
                const thread = await threadService.getThread(evt.data.id);
                const threadInfo = await threadService.decryptThread(thread);
                if (threadInfo.thread.type === 'chat') {
                    this.dispatchEvent<types.ChatUpdatedEvent>({
                        type: 'chatupdated',
                        chat: this.convertThreadToChat(threadInfo)
                    });
                } else if (threadInfo.thread.type === 'meeting') {
                    this.dispatchEvent<types.MeetingUpdatedEvent>({
                        type: 'meetingupdated',
                        meeting: this.convertThreadToMeeting(threadInfo)
                    });
                }
                if (threadInfo.thread.type === 'chat') {
                    store.dispatch(upsertChat(this.convertThreadToChat(threadInfo)));
                    if (unreadService) {
                        unreadService.dispatchUnreadStateChangedEvent();
                    }
                } else if (threadInfo.thread.type === 'meeting') {
                    store.dispatch(upsertMeeting(this.convertThreadToMeeting(threadInfo)));
                    if (unreadService) {
                        unreadService.dispatchUnreadStateChangedEvent();
                    }
                } else if (threadInfo.thread.type === 'inquirySubmit') {
                    store.dispatch(upsertInquirySubmitThread(this.convertThreadToChat(threadInfo)));
                    if (unreadService) {
                        unreadService.dispatchUnreadStateChangedEvent();
                    }
                }
            } else if (evt.type === 'threadUsersWithAccessibleTimeRangesUpdated') {
                const { threadId, users } = evt.data;
                this.dispatchEvent<types.ThreadUsersWithAccessibleTimeRangesUpdatedEvent>({
                    type: 'threaduserswithaccessibletimerangesupdatedevent',
                    threadId,
                    users
                });
            } else if (evt.type === 'videoRoomStateV2') {
                const numRoomsBefore = store.getState().video.availableRooms.length;
                store.dispatch(setAvailableRoom(evt.data as VideoRoomInfo));
                const numRoomsAfter = store.getState().video.availableRooms.length;
                const isNewRoom = numRoomsAfter === numRoomsBefore + 1;
                const isUserInTheRoom = this.session
                    ? evt.data.users.includes(this.session.userData.identity.user as types.Username)
                    : false;
                if (isNewRoom && !isUserInTheRoom) {
                    showVideoConferenceToast(evt.data.resourceId.id as string as types.MeetingId);
                }
            } else if (evt.type === 'userPkiRevision') {
                userService.onUserChange(evt.data);
            } else if (evt.type === 'newUser') {
                userService.onNewUser(evt.data);
            } else if (evt.type === 'companyCreated') {
                if (companyService && session) {
                    const company = this.convertPmxCompany(
                        await companyService.decryptCompany(evt.data.company)
                    );
                    session.companiesCache.set(company.id, company);
                    this.dispatchEvent<types.NewCompanyEvent>({
                        type: 'newcompany',
                        company: company
                    });
                }
            } else if (evt.type === 'companyUpdated') {
                if (companyService && session) {
                    const company = this.convertPmxCompany(
                        await companyService.decryptCompany(evt.data.company)
                    );
                    session.companiesCache.set(company.id, company);
                    this.dispatchEvent<types.CompanyChangedEvent>({
                        type: 'companychanged',
                        company: company
                    });
                }
            } else if (evt.type === 'companyDeleted') {
                if (companyService && session) {
                    session.companiesCache.delete(evt.data.id);
                }
            } else if (evt.type === 'contactCreated') {
                if (contactService && session) {
                    const contact = this.convertPmxContact(
                        await contactService.decryptContact(evt.data.contact)
                    );
                    session.contactsCache.set(contact.id, contact);
                    this.dispatchEvent<types.NewContactEvent>({
                        type: 'newcontact',
                        contact: contact
                    });
                }
            } else if (evt.type === 'contactUpdated') {
                if (contactService && session) {
                    const contact = this.convertPmxContact(
                        await contactService.decryptContact(evt.data.contact)
                    );
                    session.contactsCache.set(contact.id, contact);
                    this.dispatchEvent<types.ContactChangedEvent>({
                        type: 'contactchanged',
                        contact: contact
                    });
                }
            } else if (evt.type === 'contactDeleted') {
                if (contactService && session) {
                    session.contactsCache.delete(evt.data.id);
                }
            } else if (evt.type === 'threadNewAttachments') {
                const attachments = await this.getPrivmxThreadAttachments(evt.data.threadId);
                const addedAttachments = attachments.filter((x) =>
                    evt.data.attachmentIds.includes(x.id)
                );
                for (const attachment of addedAttachments) {
                    store.dispatch(upsertAttachment(attachment));
                }
                this.dispatchEvent<types.NewThreadAttachmentsEvent>({
                    type: 'newthreadattachments',
                    threadId: evt.data.threadId,
                    attachments: addedAttachments
                });
                this.updateItemCountsGrouppedByUsers();
            } else if (evt.type === 'threadDeletedAttachments') {
                for (const attachmentId of evt.data.attachmentIds) {
                    store.dispatch(removeAttachment(attachmentId));
                }
                this.dispatchEvent<types.DeletedThreadAttachmentsEvent>({
                    type: 'deletedthreadattachments',
                    threadId: evt.data.threadId,
                    attachmentIds: evt.data.attachmentIds
                });
                this.updateItemCountsGrouppedByUsers();
            } else if (evt.type === 'inquiryCreated') {
                const form = await this.getForm(evt.data.id);
                const mainFormId = await this.getMainFormId();
                if (form) {
                    this.dispatchEvent<types.NewFormEvent>({
                        type: 'newform',
                        form: form,
                        isMain: form.id === mainFormId
                    });
                    store.dispatch(
                        upsertForm({
                            ...form,
                            main: form.id === mainFormId,
                            published: form.status === 'published'
                        })
                    );
                }
            } else if (evt.type === 'inquiryUpdated' || evt.type === 'inquiryNewState') {
                const form = await this.getForm(evt.data.id);
                const mainFormId = await this.getMainFormId();
                if (form) {
                    this.dispatchEvent<types.FormChangedEvent>({
                        type: 'formchanged',
                        form: form,
                        isMain: form.id === mainFormId
                    });
                    store.dispatch(
                        upsertForm({
                            ...form,
                            main: form.id === mainFormId,
                            published: form.status === 'published'
                        })
                    );
                }
            } else if (evt.type === 'inquiryNewSubmit') {
                if (inquiryService) {
                    const submit = evt.data;
                    const { attachments, inquiry } = await this.getPrivmxInquirySubmitAttachmentsEx(
                        submit.id
                    );
                    const decryptedSubmit = await inquiryService.decryptSubmit(inquiry, submit);
                    const formRow = this.convertInquirySubmitToFormRow(
                        decryptedSubmit,
                        inquiry,
                        attachments
                    );
                    this.dispatchEvent<types.NewFormSubmitEvent>({
                        type: 'newformsubmit',
                        formId: submit.inquiryId,
                        submit: formRow
                    });
                    store.dispatch(
                        upsertFormSubmit({
                            ...formRow
                        })
                    );
                }
            } else if (evt.type === 'inquirySubmitUpdated') {
                if (inquiryService) {
                    const submit = evt.data;
                    const { attachments, inquiry } = await this.getPrivmxInquirySubmitAttachmentsEx(
                        submit.id
                    );
                    const decryptedSubmit = await inquiryService.decryptSubmit(inquiry, submit);
                    const formRow = this.convertInquirySubmitToFormRow(
                        decryptedSubmit,
                        inquiry,
                        attachments
                    );
                    this.dispatchEvent<types.FormSubmitChangedEvent>({
                        type: 'formsubmitchanged',
                        formId: submit.inquiryId,
                        submit: formRow
                    });
                    store.dispatch(
                        upsertFormSubmit({
                            ...formRow
                        })
                    );
                }
            } else if (evt.type === 'inquiryDeletedSubmit') {
                this.dispatchEvent<types.FormSubmitDeletedEvent>({
                    type: 'formsubmitdeleted',
                    formSubmitId: evt.data.submitId,
                    formId: evt.data.inquiryId
                });
                store.dispatch(removeFormSubmit(evt.data.submitId));
            } else if (evt.type === 'inquirySubmitResponseCreated') {
                if (inquiryService) {
                    const responseRaw = evt.data;
                    const response = await inquiryService.decryptInquirySubmitResponse(responseRaw);
                    this.dispatchEvent<types.NewFormSubmitResponseEvent>({
                        type: 'newformsubmitresponse',
                        formId: responseRaw.inquiryId,
                        formRowId: responseRaw.inquirySubmitId,
                        response: response
                    });
                }
            } else if (evt.type === 'threadMeetingChange') {
                this.dispatchEvent<types.ThreadMeetingMeetingChangeEvent>({
                    type: 'threadmeetingchange',
                    threadId: evt.data.threadId,
                    regularUsers: evt.data.regularUsers,
                    anonymousUsers: evt.data.anonymousUsers
                });
                for (const pub in evt.data.anonymousUsers) {
                    const nickname = evt.data.anonymousUsers[pub].nickname;
                    store.dispatch(
                        upsertAnonymousUser({
                            pub: pub as PmxApi.api.core.EccPubKey,
                            nickname: nickname
                        })
                    );
                }
            } else if (evt.type === 'threadLobbyChange') {
                this.dispatchEvent<types.ThreadMeetingLobbyChangeEvent>({
                    type: 'threadlobbychange',
                    threadId: evt.data.threadId,
                    lobbyUsers: evt.data.lobbyUsers
                });
                for (const lobbyUser of evt.data.lobbyUsers) {
                    if (lobbyUser.type === 'regular') {
                        continue;
                    }
                    store.dispatch(
                        upsertAnonymousUser({ pub: lobbyUser.pub, nickname: lobbyUser.nickname })
                    );
                }
            } else if (evt.type === 'draftCreated') {
                if (draftService) {
                    const draft = await draftService.decryptDraft(evt.data);
                    const attachments = await draftService.getDraftAttachments(evt.data.id);
                    this.dispatchEvent<types.NewDraftEvent>({
                        type: 'newdraft',
                        draft: this.convertDraftWithAttachments(draft, attachments)
                    });
                }
            } else if (evt.type === 'draftUpdated') {
                if (draftService) {
                    const draft = await draftService.decryptDraft(evt.data);
                    const attachments = await draftService.getDraftAttachments(evt.data.id);
                    this.dispatchEvent<types.DraftChangedEvent>({
                        type: 'draftchanged',
                        draft: this.convertDraftWithAttachments(draft, attachments)
                    });
                }
            } else if (evt.type === 'draftDeleted') {
                if (draftService) {
                    this.dispatchEvent<types.DraftDeletedEvent>({
                        type: 'draftdeleted',
                        draftId: evt.data.draftId
                    });
                }
            } else if (evt.type === 'sharedFileCreated') {
                const sharedFile = await sharedFileService.decryptSharedFile(evt.data);
                this.dispatchEvent<types.NewSharedFileEvent>({
                    type: 'newsharedfile',
                    sharedFile: sharedFile
                });
            } else if (evt.type === 'sharedFileUpdated') {
                const sharedFile = await sharedFileService.decryptSharedFile(evt.data);
                this.dispatchEvent<types.SharedFileChangedEvent>({
                    type: 'sharedfilechanged',
                    sharedFile: sharedFile
                });
            } else if (evt.type === 'sharedFileDeleted') {
                this.dispatchEvent<types.SharedFileDeletedEvent>({
                    type: 'sharedfiledeleted',
                    sharedFileId: evt.data.sharedFileId
                });
            }
            // @todo store.dispatch(removeChat(types.ChatId)); (as soon as ws evt becomes available)
            // @todo store.dispatch(removeMeeting(types.MeetingId)); (as soon as ws evt becomes available)
            // @todo store.dispatch(removeInquirySubmitThread(types.ChatId)); (as soon as ws evt becomes available)
            // @todo store.dispatch(removeForm(types.FormId)); (as soon as ws evt becomes available)
        });
    }

    ensureAnonSessionInitialized(nickname?: PmxApi.api.thread.MeetingNickname) {
        if (this.anonSessionCreationPromise) {
            return this.anonSessionCreationPromise;
        }
        return (this.anonSessionCreationPromise = (async () => {
            await this.ensurePrivmxClientScriptLoaded();
            const host = this.getGatewayHost();
            const priv = privmx.crypto.serviceSync.eccPrivRandom();
            await this.ensurePrivmxClientScriptLoaded();
            const gateway = await privmx.core.PrivFsRpcManager.getEcdheGateway({
                host,
                key: priv
            });
            const info = gateway.getInfo();
            if (info?.type !== 'ecdhe') {
                throw new Error('Invalid connection info');
            }
            const serverConfigPromise = new ConfigApi(gateway).getConfig();
            const requestConfigPromise = new RequestApi(gateway).getRequestConfig();
            const existingAttachmentsProvider: ExistingAttachmentsProvider = {
                getThreadAttachment: (attachmentId, thumb) =>
                    this.getThreadAttachmentWithMeta(attachmentId, !!thumb, {}),
                getDraftAttachment: (attachmentId, thumb) =>
                    this.getDraftAttachmentWithMeta(attachmentId, !!thumb, {}),
                getInquiryAttachment: (attachmentId, thumb) =>
                    this.getInquiryAttachmentWithMeta(attachmentId, !!thumb, {})
            };
            const pub = info.key.toBase58DER() as PmxApi.api.core.EccPubKey;
            const identity = new privmx.identity.Identity({ user: pub, host }, priv);
            const srpSecure = privmx.core.PrivFsSrpSecure.create(gateway);
            const cacheService = new DataCacheService();
            const modalsService = new ModalsService();
            const stateUpdater = new StateUpdater();

            const anonInquiryService = new AnonInquiryService(
                gateway,
                existingAttachmentsProvider,
                cacheService,
                modalsService
            );
            const tagEncryptionService = new TagEncryptionService(null);
            const tagService = new TagService(
                new MockUserSettingsKvdbForTagService(),
                this,
                tagEncryptionService
            );
            const stickerService = new StickerService(tagEncryptionService);
            const serverConfig = await serverConfigPromise;
            const cachePrefix = `${serverConfig.serverDataId}/${pub}/`;
            const pkiService = new PkiService(srpSecure, cachePrefix);
            const keyProvider = new KeyProvider(identity, pkiService);
            const userService = new UserService(
                new UserPollApi(gateway),
                {
                    dispatchEvent: async (evt: { type: string }) => {
                        const e = evt as UserChangedCoreEvent;
                        if (e.type === 'userchangedcore') {
                            this.dispatchEvent<types.UserChangedEvent>({
                                type: 'userchanged',
                                user: this.convertUser(e.user)
                            });
                        }
                    }
                },
                {
                    convertUser: (userEntry) => this.convertUser(userEntry)
                }
            );

            const threadService = new ThreadService(
                gateway,
                identity,
                keyProvider,
                undefined,
                stickerService,
                userService,
                tagService,
                existingAttachmentsProvider,
                () => this.anonSession?.threadLinkData ?? null,
                () => this.anonSession?.formThreadPassword ?? null,
                stateUpdater,
                cacheService,
                modalsService
            );
            const videoService = new VideoService(gateway, threadService);
            const sharedFileService = new SharedFileService(
                gateway,
                keyProvider,
                null,
                tagService,
                existingAttachmentsProvider,
                cacheService,
                modalsService
            );
            const messageVerifier = new MessageVerifier(
                pkiService,
                {
                    dispatchEvent: async (evt: { type: string }) => {
                        const e = evt as MessageVerifiedStatusChangedCoreEvent;
                        if (e.type === 'messageverifiedstatuschangedcore') {
                            this.dispatchEvent<types.MessageVerifiedStatusChangedEvent>({
                                type: 'messageverifiedstatuschanged',
                                threadId: e.message.msg.threadId as types.ChatId,
                                message: await this.convertMessage(
                                    { msg: e.message.msg, sig: Utils.resultSuccess(e.message.sig) },
                                    tagEncryptionService,
                                    stickerService,
                                    messageVerifier,
                                    await this.getFavorites()
                                )
                            });
                        }
                    }
                },
                cachePrefix
            );
            const threadVerifier = new ThreadVerifier(
                pkiService,
                {
                    dispatchEvent: async (evt: { type: string }) => {
                        const e = evt as ThreadVerifiedStatusChangedCoreEvent;
                        if (e.type === 'threadverifiedstatuschangedcore') {
                            if (e.thread.thread.type === 'meeting') {
                                this.dispatchEvent<types.MeetingVerifiedStatusChangedEvent>({
                                    type: 'meetingverifiedstatuschanged',
                                    meeting: this.convertThreadToMeeting(e.thread)
                                });
                            } else {
                                this.dispatchEvent<types.ChatVerifiedStatusChangedEvent>({
                                    type: 'chatverifiedstatuschanged',
                                    chat: this.convertThreadToChat(e.thread)
                                });
                            }
                        }
                    }
                },
                cachePrefix
            );
            const credentialsHolder = new CredentialsHolder();
            const networkStatusService = new NetworkStatusService();
            const reconnectService = new ReconnectService(networkStatusService);
            const connectionChecker = new ConnectionChecker(
                gateway,
                credentialsHolder,
                reconnectService,
                () => this.logout()
            );

            this.anonSession = {
                gateway,
                identity,
                pub: pub,
                nickname: nickname,
                anonInquiryService,
                threadService,
                videoService: videoService,
                messageVerifier: messageVerifier,
                threadVerifier: threadVerifier,
                connectionChecker: connectionChecker,
                reconnectService: reconnectService,
                networkStatusService: networkStatusService,
                tagEncryptionService: tagEncryptionService,
                tagService: tagService,
                stickerService: stickerService,
                userService: userService,
                sharedFileService: sharedFileService,
                requestConfig: await requestConfigPromise,
                threadLinkData: null,
                formThreadPassword: null
            };
            this.addWebSocketListeners();
            store.dispatch(setCurrentUser({ ...emptyCurrentUserState, pub: pub }));
            return this.anonSession;
        })());
    }

    async getCurrentUserCredentials(): Promise<{ username: types.Username; password: string }> {
        const { credentialsHolder } = this.getSession();
        const username = credentialsHolder.getUsername()!;
        const password = (await credentialsHolder.getPassword())!;
        return { username, password };
    }

    getCurrentUserUsername() {
        const { userData } = this.getSession();
        return userData.myData.raw.username as types.Username;
    }

    async changePassword(secret: string, newPassword: string, isMnemonic: boolean) {
        const { credentialsHolder, userData } = this.getSession();
        if (isMnemonic) {
            await userData.srpSecure.changePasswordByRecovery(
                secret,
                userData.myData.raw.login,
                newPassword,
                newPassword.length < 8
            );
        } else {
            await userData.srpSecure.changePasswordByOldPassword(
                userData.myData.raw.login,
                secret,
                newPassword,
                newPassword.length < 8
            );
        }
        await credentialsHolder.store(userData.myData.raw.login as types.Username, newPassword);
    }

    async updateCurrentUserProfile(profile: Partial<Profile>, resetAvatar?: boolean) {
        const { userData, userSettingsKvdb } = this.getSession();
        const userType =
            userData.myData.raw.login && userData.myData.raw.login.includes('@')
                ? 'email'
                : 'normal';
        if (userType === 'normal') {
            await UserPreferences.updateProfile(userSettingsKvdb.kvdb, (currentProfile) => ({
                ...currentProfile,
                ...profile
            }));
        }
        const userInfoProfile: privmx.types.core.UserInfoProfile = {
            ...userData.myData.userInfo.profile
        };
        if ('name' in profile) {
            userInfoProfile.name = profile.name;
        }
        if ('description' in profile) {
            userInfoProfile.description = profile.description;
        }
        if ('image' in profile) {
            userInfoProfile.image =
                typeof profile.image === 'string'
                    ? Utils2.extractBufferFromDatatUrl(profile.image)
                    : profile.image;
        }
        if (resetAvatar) {
            userInfoProfile.image = undefined;
        }

        if (
            ('name' in userInfoProfile && typeof userInfoProfile.name !== 'string') ||
            userInfoProfile.name === ''
        ) {
            delete userInfoProfile.name;
        }
        if ('description' in userInfoProfile && typeof userInfoProfile.description !== 'string') {
            delete userInfoProfile.description;
        }
        await userData.srpSecure.setUserInfo(userData.identityKeypair, userInfoProfile);
        userData.myData.userInfo.profile = userInfoProfile;
    }

    async updateTemporaryPasswordsStore(
        usersWithPasswords: Array<{ username: types.Username; password?: types.UserPassword }>
    ) {
        const { userSettingsKvdb } = this.getSession();
        await UserPreferences.updateTemporaryPasswordsStore(
            userSettingsKvdb.kvdb,
            usersWithPasswords
        );
    }

    async getTemporaryPasswordForUser(username: types.Username | undefined) {
        const { userSettingsKvdb } = this.getSession();
        return username
            ? UserPreferences.getTemporaryPasswordForUser(userSettingsKvdb.kvdb, username)
            : undefined;
    }

    private getUserId(username: string) {
        return ('user-' + username) as types.UserId;
    }

    private convertUser(data: UserEntry) {
        const userType = data.raw.subType === 'email' ? 'email' : 'normal';
        const user: types.User = {
            id: this.getUserId(data.raw.username),
            username: data.raw.username,
            userType: userType,
            name:
                data.raw.cachedPkiEntry?.name ||
                (userType === 'normal' ? data.raw.username : data.raw.email),
            avatar: (data.avatar ? data.avatar.url : '') as types.Avatar,
            email: data.raw.email,
            isAdmin: data.raw.isAdmin,
            password: '',
            role: data.raw.type === 'basic' ? ('client' as string as types.User['role']) : 'staff',
            blocked: false, // TODO: data.raw.blocked,
            creationDate: -1 as types.Timestamp, // TODO data.raw.created,
            lastLoggedIn: (data.raw.lastLoginDate
                ? parseInt(data.raw.lastLoginDate, 10)
                : -1) as types.Timestamp
        };
        return user;
    }

    async logout(withRedirect: boolean = true) {
        this.dispatchEvent({ type: 'beforelogout' });
        try {
            await Promise.all(
                [
                    async () => {
                        await this.terminateSessions();
                    },
                    async () => subscriptionRegistry.unregisterAll(),
                    async () => resetStoreOnLogout(),
                    async () => (withRedirect ? redirect('/') : null)
                ].map((fn) => fn())
            );
        } catch (e) {
            console.error('Error during logout', e);
        } finally {
            this.session = null;
            this.anonSession = null;
            this.dispatchEvent({ type: 'loggedout' });
        }
    }

    private async terminateSessions() {
        if (this.session) {
            this.session.networkStatusService.pauseNetworkActivity();
            await this.session.connectionChecker.disconnectAndDestroy();
        }
        if (this.anonSession) {
            this.anonSession.networkStatusService.pauseNetworkActivity();
            await this.anonSession.connectionChecker.disconnectAndDestroy();
        }
    }

    isLoggedIn() {
        return this.session !== null;
    }

    hasSession() {
        return this.session !== null || this.anonSession !== null;
    }

    async chooseFilesAsAttachments() {
        const files = await FileChooser.chooseFiles();
        return files.map((x) => api.prepareAttachment(x));
    }

    prepareAttachment(
        file: File,
        sourceType: types.AttachmentSourceType = 'thread'
    ): types.PreparedAttachment {
        return {
            id: Math.random().toString(36).substring(2) as types.AttachmentId,
            chatId: '' as types.ChatId,
            messageId: dbGenerator.generateId() as types.MessageId,
            contentType: file.type as types.Mimetype,
            group: file.name as types.FileGroup,
            name: file.name as types.FileName,
            size: file.size as types.FileSize,
            file: file,
            tags: [],
            author: '' as types.Username,
            date: 0 as types.Timestamp,
            hasThumb: ThumbnailGenerator.canGenerate(file),
            sourceType: sourceType
        };
    }

    async updateAttachmentTags(attachment: types.Attachment, tags: types.Tag[]): Promise<void> {
        const { threadService } = this.getSession();
        await threadService.updateAttachmentGroupTags(attachment.chatId, attachment.group, tags);
    }

    async addContact(contact: types.Contact) {
        const { contactService, tagService } = this.getSession();
        const tags = tagService.readAllTagsFromTaggableEntity(contact);
        const info = await contactService.createContact({
            email: contact.email,
            company: contact.companyId || null,
            username: contact.username || null,
            props: {
                avatar: contact.avatar,
                name: contact.name,
                phone: contact.phone,
                mobilePhone: contact.mobilePhone,
                address: contact.address,
                note: contact.note,
                language: contact.language,
                isPrivate: contact.isPrivate,
                privateContactVisibleForUsers: contact.privateContactVisibleForUsers
            },
            tags: tags
        });
        return this.convertPmxContact(info);
    }

    importContacts(data: ContactImportData) {
        if (!data.email) {
            throw new Error('Email is required');
        }
        const currentUserUsername = this.getCurrentUserUsername();
        return this.addContact({
            id: '' as types.ContactId,
            userType: 'email',
            name: (data.name ?? '') as types.ContactName,
            email: data.email as types.Email,
            address: (data.address ?? '') as types.ContactAddress,
            mobilePhone: '' as types.MobilePhone,
            phone: (data.phone ?? '') as types.Phone,
            companyId: '' as types.CompanyId,
            note: (data.note ?? '') as types.ContactNote,
            avatar: '' as types.Avatar,
            language: 'en' as types.Language,
            tags: [] as types.Tag[],
            pinned: false,
            archived: false,
            createdBy: '' as types.Username,
            createdDate: 0 as types.Timestamp,
            lastModifiedBy: '' as types.Username,
            lastModifiedDate: 0 as types.Timestamp,
            isPrivate: currentUserUsername !== undefined,
            privateContactVisibleForUsers:
                currentUserUsername !== undefined ? [currentUserUsername] : []
        });
    }

    async addMultipleContacts(emails: types.Email[]) {
        const currentUserUsername = this.getCurrentUserUsername();
        const createdContacts = await Promise.all(
            emails.map((email) =>
                this.addContact({
                    id: '' as types.ContactId,
                    userType: 'email',
                    name: '' as types.ContactName,
                    email: email.trim() as types.Email,
                    address: '' as types.ContactAddress,
                    mobilePhone: '' as types.MobilePhone,
                    phone: '' as types.Phone,
                    companyId: '' as types.CompanyId,
                    note: '' as types.ContactNote,
                    avatar: '' as types.Avatar,
                    language: 'en' as types.Language,
                    tags: [] as types.Tag[],
                    pinned: false,
                    archived: false,
                    createdBy: '' as types.Username,
                    createdDate: 0 as types.Timestamp,
                    lastModifiedBy: '' as types.Username,
                    lastModifiedDate: 0 as types.Timestamp,
                    isPrivate: currentUserUsername !== undefined,
                    privateContactVisibleForUsers:
                        currentUserUsername !== undefined ? [currentUserUsername] : []
                })
            )
        );
        return createdContacts;
    }

    private convertPmxContact(contact: PmxContact) {
        const { tagService } = this.getAnySession();
        const res: types.Contact = {
            id: contact.raw.id,
            username: contact.raw.username || undefined,
            userType: contact.raw.username ? 'email' : 'normal',
            avatar: contact.props.avatar,
            name: contact.props.name,
            email: contact.raw.email,
            phone: contact.props.phone,
            mobilePhone: contact.props.mobilePhone,
            address: contact.props.address,
            note: contact.props.note,
            companyId: contact.raw.company || undefined,
            language: contact.props.language,
            createdBy: contact.raw.creator,
            createdDate: contact.raw.created,
            lastModifiedBy: contact.raw.lastModifier,
            lastModifiedDate: contact.raw.lastModified,
            isPrivate: contact.props.isPrivate,
            privateContactVisibleForUsers: contact.props.privateContactVisibleForUsers,
            ...tagService.mapTagsToTaggableEntity(contact.tags)
        };
        return res;
    }

    async updateContact(contact: types.Contact) {
        const { contactService, tagService } = this.getSession();
        const tags = tagService.readAllTagsFromTaggableEntity(contact);
        const info = await contactService.updateContact({
            id: contact.id,
            email: contact.email,
            company: contact.companyId || null,
            username: contact.username || null,
            props: {
                avatar: contact.avatar,
                name: contact.name,
                phone: contact.phone,
                mobilePhone: contact.mobilePhone,
                address: contact.address,
                note: contact.note,
                language: contact.language,
                isPrivate: contact.isPrivate,
                privateContactVisibleForUsers: contact.privateContactVisibleForUsers
            },
            tags: tags
        });
        return this.convertPmxContact(info);
    }

    async makeContactPublic(contact: types.Contact) {
        return this.updateContact({
            ...contact,
            isPrivate: false,
            privateContactVisibleForUsers: []
        });
    }

    async addCurrentUserToPrivateContact(contact: types.Contact) {
        if (!contact.isPrivate) {
            return;
        }
        const currentUserUsername = this.getCurrentUserUsername();
        if (currentUserUsername === undefined) {
            return;
        }
        if (contact.privateContactVisibleForUsers.includes(currentUserUsername)) {
            return;
        }
        return this.updateContact({
            ...contact,
            privateContactVisibleForUsers: [
                ...contact.privateContactVisibleForUsers,
                currentUserUsername
            ]
        });
    }

    isContactVisibleForCurrentUser(contactOrEmail: types.Contact | types.Email): boolean {
        const contact =
            typeof contactOrEmail === 'string'
                ? this.getContactByEmailSync(contactOrEmail)
                : contactOrEmail;
        if (!contact) {
            return false;
        }
        if (!contact.isPrivate) {
            return true;
        }
        const currentUserUsername = this.getCurrentUserUsername();
        if (currentUserUsername === undefined) {
            return false;
        }
        return contact.privateContactVisibleForUsers.includes(currentUserUsername);
    }

    async setContactPinned(contactId: types.ContactId, pinned: boolean) {
        const { contactService } = this.getSession();
        await contactService.toggleContactTag(contactId, TagService.SYSTEM_TAG_PINNED, pinned);
    }

    async setContactArchived(contactId: types.ContactId, archived: boolean) {
        const { contactService } = this.getSession();
        await contactService.toggleContactTag(contactId, TagService.SYSTEM_TAG_ARCHIVED, archived);
    }

    async updateChat(chatModel: types.ChatEditModel, updateKeysWhenAddingUsers: boolean) {
        const { allUsernames: users, newAccounts } =
            await api.createMissingInternalUsersFromContacts(chatModel.users, {
                type: 'chatEditor',
                chatId: chatModel.id
            });
        const { privateContactsMadeVisible } =
            await this.ensurePrivateContactsAreVisibleToCurrentUser(chatModel.users);
        const { threadService } = this.getSession();
        const thread = await threadService.getThread(chatModel.id);
        if (thread.type !== 'chat') {
            throw new Error(`Cannot update ${thread.type} by chat update method`);
        }
        const threadInfo = await threadService.decryptThread(thread);
        const tags = chatModel.tags.slice();
        for (const tag of threadInfo.tags) {
            if (tag.startsWith(TagService.SYSTEM_TAG_PREFIX)) {
                tags.push(tag);
            }
        }
        const newThread = await threadService.updateThread(
            threadInfo,
            chatModel.title,
            {},
            tags,
            users,
            chatModel.managers,
            updateKeysWhenAddingUsers,
            false
        );
        const newThreadInfo = await threadService.decryptThread(newThread);
        return {
            newChat: this.convertThreadToChat(newThreadInfo),
            newAccounts,
            privateContactsMadeVisible
        };
    }

    async setThreadTags(threadId: types.ThreadId, tags: types.Tag[]) {
        const { threadService } = this.getSession();
        await threadService.setThreadTags(threadId, tags);
    }

    async setChatMessageEmoji(
        chatId: types.ChatId,
        msgId: types.MessageId,
        username: types.Username,
        emojiIconName: EmojiIconName
    ) {
        return this.setPrivmxMessageEmoji(msgId, emojiIconName);
    }

    async setMeetingMessageEmoji(
        meetingId: types.MeetingId,
        msgId: types.MessageId,
        username: types.Username,
        emojiIconName: EmojiIconName
    ) {
        return this.setPrivmxMessageEmoji(msgId, emojiIconName);
    }

    private setMessageEmoji(
        msg: types.Message,
        username: types.Username,
        emojiIconName: EmojiIconName
    ) {
        const prevEmoji = msg.emojis.find((emoji) => emoji.users.includes(username));
        if (prevEmoji) {
            const idx = prevEmoji.users.indexOf(username);
            if (idx >= 0) {
                prevEmoji.users.splice(idx, 1);
                if (prevEmoji.users.length === 0) {
                    const idx2 = msg.emojis.indexOf(prevEmoji);
                    if (idx2 >= 0) {
                        msg.emojis.splice(idx2, 1);
                    }
                }
            }
        }
        const existingEmoji = msg.emojis.find((emoji) => emoji.icon === emojiIconName);
        if (existingEmoji) {
            existingEmoji.users.push(username);
        } else {
            msg.emojis.push({
                icon: emojiIconName,
                users: [username]
            });
        }
        msg.emojis = [...msg.emojis];
    }

    async setPrivmxMessageEmoji(msgId: types.MessageId, emojiIconName: EmojiIconName) {
        const { threadService } = this.getSession();
        await threadService.setSticker(msgId, emojiIconName);
    }

    canAddEmojis() {
        const { tagEncryptionService } = this.getAnySession();
        return tagEncryptionService.hasKeys();
    }

    async setChatPinned(chatId: types.ChatId, pinned: boolean) {
        const { threadService } = this.getSession();
        await threadService.toggleThreadTag(chatId, TagService.SYSTEM_TAG_PINNED, pinned);
    }

    async setChatArchived(chatId: types.ChatId, archived: boolean) {
        const { threadService } = this.getSession();
        await threadService.toggleThreadTag(chatId, TagService.SYSTEM_TAG_ARCHIVED, archived);
    }

    async setMeetingPinned(meetingId: types.MeetingId, pinned: boolean) {
        const { threadService } = this.getSession();
        await threadService.toggleThreadTag(meetingId, TagService.SYSTEM_TAG_PINNED, pinned);
    }

    async setMeetingArchived(meetingId: types.MeetingId, archived: boolean) {
        const { threadService } = this.getSession();
        await threadService.toggleThreadTag(meetingId, TagService.SYSTEM_TAG_ARCHIVED, archived);
    }

    async updateMeeting(meetingModel: types.MeetingEditModel, updateKeysWhenAddingUsers: boolean) {
        const { allUsernames: users, newAccounts } =
            await api.createMissingInternalUsersFromContacts(meetingModel.users, {
                type: 'meetingEditor',
                meetingId: meetingModel.id
            });
        const { privateContactsMadeVisible } =
            await this.ensurePrivateContactsAreVisibleToCurrentUser(meetingModel.users);
        const { threadService } = this.getSession();
        const thread = await threadService.getThread(meetingModel.id);
        if (thread.type !== 'meeting') {
            throw new Error(`Cannot update ${thread.type} by meeting update method`);
        }
        const threadInfo = await threadService.decryptThread(thread);
        const tags = meetingModel.tags.slice();
        for (const tag of threadInfo.tags) {
            if (tag.startsWith(TagService.SYSTEM_TAG_PREFIX)) {
                tags.push(tag);
            }
        }
        const newThread = await threadService.updateThread(
            threadInfo,
            meetingModel.title,
            {
                startDate: meetingModel.startDate,
                duration: meetingModel.duration
            },
            tags,
            users,
            meetingModel.managers,
            updateKeysWhenAddingUsers,
            meetingModel.isMeetingCancelled
        );
        const newThreadInfo = await threadService.decryptThread(newThread);
        return {
            newMeeting: this.convertThreadToChat(newThreadInfo),
            newAccounts,
            privateContactsMadeVisible
        };
    }

    private async sendPrivmxMessage(
        threadService: ThreadService,
        thread: ThreadInfo,
        mimetype: types.Mimetype,
        text: string,
        msgId: types.MessageClientId,
        attachments: File[],
        attachmentsToCopy: AttachmentsToCopy = []
    ) {
        await threadService.sendMessage(
            thread,
            msgId,
            mimetype,
            text,
            attachments,
            attachmentsToCopy
        );
    }

    async sendBatchMessage(
        chatsId: types.ChatId[],
        mimetype: types.Mimetype,
        text: string,
        attachments: (types.Attachment | types.DraftAttachmentEx)[]
    ) {
        for (const chatId of chatsId) {
            const newMsgId = this.generateMessageId();
            try {
                await this.sendMessage(chatId, mimetype, text, newMsgId, attachments);
            } catch (error) {
                if (!(error instanceof CancelledByUserError)) {
                    throw error;
                }
            }
        }
    }

    async sendMessage(
        chatId: types.ChatId,
        mimetype: types.Mimetype,
        text: string,
        msgId: types.MessageClientId,
        attachments: (types.Attachment | types.DraftAttachmentEx)[]
    ) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        const threadRaw = await threadService.getThread(chatId);
        const thread = await threadService.decryptThread(threadRaw);
        const existingDraftAttachments = attachments.filter(
            (x) => 'draftId' in x
        ) as types.DraftAttachmentEx[];
        const newAttachments = attachments.filter(
            (x) => !('draftId' in x)
        ) as types.PreparedAttachment[];
        const attachmentFiles = newAttachments.map((x) => x.file);
        return this.sendPrivmxMessage(
            threadService,
            thread,
            mimetype,
            text,
            msgId,
            attachmentFiles,
            existingDraftAttachments
        );
    }

    async sendMeetingMessage(
        meetingId: types.MeetingId,
        mimetype: types.Mimetype,
        text: string,
        msgId: types.MessageClientId,
        attachments: (types.Attachment | types.DraftAttachmentEx)[]
    ) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        const threadRaw = await threadService.getThread(meetingId);
        const thread = await threadService.decryptThread(threadRaw);
        const existingDraftAttachments = attachments.filter(
            (x) => 'draftId' in x
        ) as types.DraftAttachmentEx[];
        const newAttachments = attachments.filter(
            (x) => !('draftId' in x)
        ) as types.PreparedAttachment[];
        const attachmentFiles = newAttachments.map((x) => x.file);
        return this.sendPrivmxMessage(
            threadService,
            thread,
            mimetype,
            text,
            msgId,
            attachmentFiles,
            existingDraftAttachments
        );
    }

    async sendMeetingStartedMessage(meetingId: types.MeetingId) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        const threadRaw = await threadService.getThread(meetingId);
        const thread = await threadService.decryptThread(threadRaw);
        await threadService.sendMeetingStartedMessage(thread);
    }

    async sendMeetingScheduledMessage(
        originalThreadId: types.ThreadId,
        scheduledMeetingId: types.MeetingId
    ) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        const threadRaw = await threadService.getThread(originalThreadId);
        const thread = await threadService.decryptThread(threadRaw);
        await threadService.sendMeetingScheduledMessage(thread, scheduledMeetingId);
    }

    async deleteMessage(messageId: types.MessageId) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        await threadService.deleteMessage(messageId);
    }

    async editMessage(
        messageId: types.MessageId,
        mimetype: types.Mimetype,
        text: string,
        attachments: (types.Attachment | File)[]
    ) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        await threadService.updateMessage(
            messageId,
            mimetype,
            text,
            attachments.map((x) => (x instanceof File ? x : x.id))
        );
    }

    async getAttachment(
        attachmentId: types.AttachmentId,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        const res = await threadService.getAttachmentCore(attachmentId);
        const att = await threadService.readAttachment(res.attachment, res.meta, thumb, options);
        return {
            id: res.attachment.id,
            name: res.meta.name,
            contentType: att.mimetype,
            size: att.size,
            content: att.data
        };
    }

    private async getThreadAttachmentWithMeta(
        attachmentId: types.AttachmentId,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const { threadService } = this.getSession();
        const res = await threadService.getAttachmentCore(attachmentId);
        const att = await threadService.readAttachment(res.attachment, res.meta, thumb, options);
        return {
            id: res.attachment.id as types.AttachmentId,
            name: res.meta.name as types.FileName,
            contentType: att.mimetype,
            size: att.size as types.FileSize,
            content: att.data,
            meta: res.meta
        };
    }

    async getAttachmentHistory(attachmentId: types.AttachmentId) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        const res = await threadService.getAttachmentGroupList(attachmentId);
        return res.map((x) => this.convertAtt(x));
    }

    async downloadAttachment(options: {
        fileName: string;
        attachmentId: types.AttachmentId;
        attachmentSourceType: types.AttachmentSourceType;
        thumb: boolean;
        encKey?: EncKey;
        password?: string;
        onProgress?: DownloadProgressCallback;
    }) {
        let saved = false;
        const data = await this.getAttachmentFromSource(
            options.attachmentId,
            options.attachmentSourceType,
            options.thumb,
            options.encKey,
            options.password,
            {
                async outputStreamFactory(size) {
                    if (size > 512 * 1000 * 1000 && (window as any).showSaveFilePicker) {
                        saved = true;
                        const fileHandle = await (window as any).showSaveFilePicker({
                            suggestedName: options.fileName
                        });
                        const writable = await fileHandle.createWritable();
                        const stream: OutputStream = {
                            write: (data) => writable.write(data),
                            close: () => writable.close()
                        };
                        return stream;
                    }
                    try {
                        return new BufferOutputStream(size);
                    } catch (e) {
                        modalService.openAlertModal({
                            title: 'Download Alert',
                            message:
                                'The selected file cannot be downloaded, it is too large and your browser does not support downloading large files. To download this file, you must use Chrome or similar browser.'
                        });
                        throw new Error('File too big');
                    }
                },
                onProgress: options.onProgress
            }
        );
        if (!saved) {
            const blob = new Blob([data.content], { type: data.contentType });
            const objectUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.download = options.fileName;
            a.href = objectUrl;
            a.click();
            setTimeout(() => URL.revokeObjectURL(objectUrl), 1000);
        }
    }

    async getAttachmentFromSource(
        attachmentId: types.AttachmentId,
        attachmentSourceType: types.AttachmentSourceType,
        thumb: boolean = false,
        encKey?: EncKey,
        password?: string,
        opt?: DownloadOptions
    ) {
        const options = opt || {};
        if (attachmentSourceType === 'thread') {
            return this.getAttachment(attachmentId, thumb, options);
        }
        if (attachmentSourceType === 'inquiry') {
            return this.getInquiryAttachment(attachmentId, thumb, options);
        }
        if (attachmentSourceType === 'draft') {
            return this.getDraftAttachment(attachmentId, thumb, options);
        }
        if (attachmentSourceType === 'sharedFile') {
            if (this.anonSession && !encKey) {
                throw new Error('Public shared files require an EncKey');
            }
            const res = this.anonSession
                ? await this.getPublicSharedFileData(
                      attachmentId as any,
                      encKey!,
                      password,
                      thumb,
                      options
                  )
                : await this.getSharedFileData(attachmentId as any, thumb, options);
            return {
                id: attachmentId,
                name: attachmentId as any,
                contentType: res.mimetype,
                size: res.size,
                content: res.data
            };
        }
        throw new Error(`Unknown attachment source type "${attachmentSourceType}"`);
    }

    joinToVideoRoom(meetingId: types.MeetingId) {
        const { videoService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return videoService.joinToVideoRoom(meetingId);
    }

    switchVideoRoomState(
        meetingId: types.MeetingId,
        token: string,
        roomPassword: string,
        roomUrl: string
    ) {
        const { videoService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return videoService.switchVideoRoomState(meetingId, token, roomPassword, roomUrl);
    }

    cancelVideoRoomCreation(meetingId: types.MeetingId, token: string) {
        const { videoService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return videoService.cancelVideoRoomCreation(meetingId, token);
    }

    commitVideoRoomAccess(meetingId: types.MeetingId, videoRoomId: string) {
        const { videoService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return videoService.commitVideoRoomAccess(meetingId, videoRoomId);
    }

    disconnectFromVideoRoom(meetingId: types.MeetingId, videoRoomId: string) {
        const { videoService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return videoService.disconnectFromVideoRoom(meetingId, videoRoomId);
    }

    getVideoRoomsState() {
        const { videoService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return videoService.getVideoRoomsState();
    }

    async getRandomBytesHex(numBytes: number): Promise<string> {
        return privmx.crypto.service.randomBytes(numBytes).toString('hex');
    }

    encryptWithSectionKey(
        roomSecretData: privmxVideoConferences.core.RoomSecretData,
        thread: PmxApi.api.thread.Thread
    ): Promise<string> {
        const { videoService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return videoService.encryptWithThreadKey(roomSecretData, thread);
    }

    decryptWithSectionKey(
        roomSecretDataStr: string,
        thread: PmxApi.api.thread.Thread
    ): Promise<privmxVideoConferences.core.RoomSecretData> {
        const { videoService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return videoService.decryptWithThreadKey(roomSecretDataStr, thread);
    }

    getThread(threadId: PmxApi.api.thread.ThreadId) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return threadService.getThread(threadId);
    }

    async getDecryptedThread(threadId: PmxApi.api.thread.ThreadId) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        const rawThread = await threadService.getThread(threadId);
        const thread = await threadService.decryptThread(rawThread);
        return thread;
    }

    async getThreadAsChatOrMeeting(
        threadId: PmxApi.api.thread.ThreadId
    ): Promise<{ chat?: types.Chat; meeting?: types.Meeting }> {
        const thread = await this.getDecryptedThread(threadId);
        if (thread && thread.thread.type === 'chat') {
            return { chat: this.convertThreadToChat(thread) };
        }
        if (thread && thread.thread.type === 'meeting') {
            return { meeting: this.convertThreadToMeeting(thread) };
        }
        return {};
    }

    addUserToThreadMeeting(
        threadId: PmxApi.api.thread.ThreadId,
        username: PmxApi.api.core.Username,
        updateKeysWhenAddingUsers: boolean
    ) {
        const { threadService } = this.getSession();
        return threadService.addUserToThreadMeeting(threadId, username, updateKeysWhenAddingUsers);
    }

    removeUserFromThreadMeeting(
        threadId: PmxApi.api.thread.ThreadId,
        username: PmxApi.api.core.Username
    ) {
        const { threadService } = this.getSession();
        return threadService.removeUserFromThreadMeeting(threadId, username);
    }

    addAnonymousUserToThreadMeeting(
        threadId: PmxApi.api.thread.ThreadId,
        pub: PmxApi.api.core.EccPubKey,
        updateKeysWhenAddingUsers: boolean
    ) {
        const { threadService } = this.getSession();
        return threadService.addAnonymousUserToThreadMeeting(
            threadId,
            pub,
            updateKeysWhenAddingUsers
        );
    }

    removeAnonymousUserFromThreadMeeting(
        threadId: PmxApi.api.thread.ThreadId,
        pub: PmxApi.api.core.EccPubKey
    ) {
        const { threadService } = this.getSession();
        return threadService.removeAnonymousUserFromThreadMeeting(threadId, pub);
    }

    removeUserFromThreadLobby(
        threadId: PmxApi.api.thread.ThreadId,
        username: PmxApi.api.core.Username
    ) {
        const { threadService } = this.getSession();
        return threadService.removeUserFromThreadLobby(threadId, username);
    }

    removeAnonymousFromThreadLobby(
        threadId: PmxApi.api.thread.ThreadId,
        pub: PmxApi.api.core.EccPubKey
    ) {
        const { threadService } = this.getSession();
        return threadService.removeAnonymousFromThreadLobby(threadId, pub);
    }

    joinToThreadMeetingLobby(threadId: PmxApi.api.thread.ThreadId) {
        let nickname: PmxApi.api.thread.MeetingNickname | undefined = undefined;
        if (this.anonSession) {
            nickname = this.getAnonSession().nickname;
        } else {
            const identity = this.getSession().userData.identity;
            nickname = (identity.name ?? identity.user) as PmxApi.api.thread.MeetingNickname;
        }
        if (!nickname) {
            throw new Error("Can't join a meeting without a nickname");
        }
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return threadService.joinToThreadMeetingLobby(threadId, nickname);
    }

    leaveThreadMeetingLobby(threadId: PmxApi.api.thread.ThreadId) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return threadService.leaveThreadMeetingLobby(threadId);
    }

    getThreadMeetingLobby(threadId: PmxApi.api.thread.ThreadId) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return threadService.getThreadMeetingLobby(threadId);
    }

    setThreadMeetingNickname(
        threadId: PmxApi.api.thread.ThreadId,
        nickname: PmxApi.api.thread.MeetingNickname
    ) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return threadService.setThreadMeetingNickname(threadId, nickname);
    }

    commitPresenceInThreadLobby(threadId: PmxApi.api.thread.ThreadId) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return threadService.commitPresenceInThreadLobby(threadId);
    }

    getThreadMeetingLobbyUserLifespanBeforeExpiration() {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return threadService.getThreadMeetingLobbyUserLifespanBeforeExpiration();
    }

    isThreadMeetingLobbyUserExpired(lobbyUser: PmxApi.api.thread.MeetingLobbyUser) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return threadService.isThreadMeetingLobbyUserExpired(lobbyUser);
    }

    getPresentThreadMeetingLobbyUsers(lobbyUsers: PmxApi.api.thread.MeetingLobbyUser[]) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return threadService.getPresentThreadMeetingLobbyUsers(lobbyUsers);
    }

    getPresentThreadMeetingLobbyUsersCount(lobbyUsers: PmxApi.api.thread.MeetingLobbyUser[]) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return threadService.getPresentThreadMeetingLobbyUsersCount(lobbyUsers);
    }

    getNextThreadMeetingLobbyUserExpirationTime(lobbyUsers: PmxApi.api.thread.MeetingLobbyUser[]) {
        const { threadService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return threadService.getNextThreadMeetingLobbyUserExpirationTime(lobbyUsers);
    }

    generateThreadMeetingLink(meeting: types.Meeting) {
        const data: types.ThreadMeetingLinkData = {
            type: 'threadMeeting',
            threadId: meeting.id,
            title: meeting.title
        };
        const dataStr = encodeURIComponent(Base64Str.utf8ToBase64(JSON.stringify(data)));
        const url = UrlBuilder.buildUrl(`/joinMeeting/${dataStr}`);
        return url;
    }

    generateFormThreadLink(
        threadId: types.ThreadId,
        title: string,
        isAnonymousUser: boolean,
        anonEncryptedPrivKey?: string,
        anonPrivKeyPreliminaryEncryptionKey?: string
    ) {
        const data: types.FormThreadLinkData = {
            type: 'formThread',
            threadId: threadId,
            title: title,
            loginMethod: isAnonymousUser ? 'password' : 'emailWithPassword',
            anonEncryptedPrivKey: anonEncryptedPrivKey,
            anonPrivKeyPreliminaryEncryptionKey: anonPrivKeyPreliminaryEncryptionKey
        };
        const dataStr = encodeURIComponent(Base64Str.utf8ToBase64(JSON.stringify(data)));
        const url = UrlBuilder.buildUrl(`/formConversation/${dataStr}`);
        return url;
    }

    parseThreadLinkStringData(dataStr: string) {
        try {
            const data: types.ThreadLinkData = JSON.parse(Base64Str.base64ToUtf8(dataStr));
            return data;
        } catch {}
        return undefined;
    }

    async enableTwofa(data: TwofaEnableData) {
        const { twofaService } = this.getSession();
        return twofaService.enable(data);
    }

    async disableTwofa() {
        const { twofaService } = this.getSession();
        return twofaService.disable();
    }

    async getTwofaData() {
        const { twofaService } = this.getSession();
        return twofaService.getData();
    }

    async twofaChallenge(model: ChallengeModel) {
        const { twofaService } = this.getSession();
        return twofaService.challenge(model);
    }

    async resendTwofaCode() {
        const { twofaService } = this.getSession();
        return twofaService.resendCode();
    }

    generateGoogleAuthenticatorKey() {
        const { twofaService } = this.getSession();
        return twofaService.generateGoogleAuthenticatorKey();
    }

    formatGoogleAuthenticatorKey(key: string) {
        const { twofaService } = this.getSession();
        return twofaService.formatGoogleAuthenticatorKey(key);
    }

    getGoogleAuthenticatorKeyUri(key: string) {
        const { credentialsHolder, twofaService } = this.getSession();
        const host = this.getGatewayHost();
        const username = credentialsHolder.getUsername();
        return twofaService.getGoogleAuthenticatorKeyUri(host, username ?? '', key);
    }

    deserializeTwofaChallengeModel(x: ChallengeModelSerializable) {
        const res: ChallengeModel = {
            code: x.code,
            rememberDeviceId: x.rememberDeviceId,
            u2fLogin: x.u2fLogin && {
                type: x.u2fLogin.type,
                id: x.u2fLogin.id,
                rawId: privmx.Buffer.Buffer.from(x.u2fLogin.rawId),
                response: {
                    authenticatorData: privmx.Buffer.Buffer.from(
                        x.u2fLogin.response.authenticatorData
                    ),
                    clientDataJSON: privmx.Buffer.Buffer.from(x.u2fLogin.response.clientDataJSON),
                    signature: privmx.Buffer.Buffer.from(x.u2fLogin.response.signature),
                    userHandle:
                        x.u2fLogin.response.userHandle &&
                        privmx.Buffer.Buffer.from(x.u2fLogin.response.userHandle)
                }
            },
            u2fRegister: x.u2fRegister && {
                type: x.u2fRegister.type,
                id: x.u2fRegister.id,
                rawId: privmx.Buffer.Buffer.from(x.u2fRegister.rawId),
                response: {
                    attestationObject: privmx.Buffer.Buffer.from(
                        x.u2fRegister.response.attestationObject
                    ),
                    clientDataJSON: privmx.Buffer.Buffer.from(x.u2fRegister.response.clientDataJSON)
                }
            }
        };
        return res;
    }

    getUnreadService(): UnreadService | null {
        if (this.isAnonymousMeetingClient()) {
            return null;
        }
        return this.getSession().unreadService;
    }

    async getDrafts() {
        const { draftService } = this.getSession();
        if (!draftService) {
            return undefined;
        }
        const draftsRaw = await draftService.getDrafts();
        const drafts = draftsRaw.map((x) => this.convertDraft(x));
        return drafts;
    }

    async getDraft(id: types.DraftId) {
        const { draftService } = this.getSession();
        if (!draftService) {
            return undefined;
        }
        const draftRaw = await draftService.getDraft(id);
        const draft = this.convertDraft(draftRaw);
        return draft;
    }

    async getThreadMessageDraft(threadId: types.ThreadId) {
        if (this.isAnonymousMeetingClient()) {
            return;
        }
        const { draftService } = this.getSession();
        if (!draftService) {
            return undefined;
        }
        const draftRaw = await draftService.getThreadMessageDraft(threadId);
        const attachments = draftRaw ? await draftService.getDraftAttachments(draftRaw.raw.id) : [];
        const draft = draftRaw
            ? (this.convertDraftWithAttachments(
                  draftRaw,
                  attachments
              ) as types.ThreadMessageDraftWithAttachments)
            : undefined;
        return draft;
    }

    async createDraft(props: types.DraftPropsForCreating) {
        const { draftService } = this.getSession();
        if (!draftService) {
            return undefined;
        }
        const draftRaw = await draftService.createDraft(props);
        const draft = this.convertDraft(draftRaw);
        return draft;
    }

    async updateDraft(draft: types.DraftWithAttachments, files: File[]) {
        const { draftService } = this.getSession();
        if (!draftService) {
            return undefined;
        }
        const draftRaw = await draftService.updateDraft(draft, files);
        const newDraft = this.convertDraft(draftRaw);
        return newDraft;
    }

    async deleteDraft(id: types.DraftId) {
        const { draftService } = this.getSession();
        if (!draftService) {
            return;
        }
        await draftService.deleteDraft(id);
    }

    async getDraftAttachment(
        attachmentId: types.AttachmentId,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const res = await this.getDraftAttachmentWithMeta(attachmentId, thumb, options);
        return {
            id: res.id,
            name: res.name,
            contentType: res.contentType,
            size: res.size,
            content: res.content
        };
    }

    private async getDraftAttachmentWithMeta(
        attachmentId: types.AttachmentId,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const { draftService } = this.getSession();
        const res = await draftService.getAttachmentCore(attachmentId);
        const att = await draftService.readAttachment(res.attachment, res.meta, thumb, options);
        return {
            id: res.attachment.id as types.AttachmentId,
            name: res.meta.name as types.FileName,
            contentType: att.mimetype,
            size: att.size as types.FileSize,
            content: att.data,
            meta: res.meta
        };
    }

    async getDraftAttachments(draftId: PmxApi.api.draft.DraftId) {
        const { draftService } = this.getSession();
        const draftAttachments = await draftService.getDraftAttachments(draftId);
        return draftAttachments
            .map((x) => (x.success === true ? this.convertDraftAttachmentEx(x.result) : undefined))
            .filter((x) => !!x) as types.DraftAttachmentEx[];
    }

    async getDraftWithAttachments(draftId: PmxApi.api.draft.DraftId) {
        const [draft, attachments] = await Promise.all([
            this.getDraft(draftId),
            this.getDraftAttachments(draftId)
        ]);
        if (!draft) {
            return undefined;
        }
        const res: types.DraftWithAttachments = {
            ...draft,
            attachments
        };
        return res;
    }

    private convertDraft(draft: Draft) {
        if (draft.data.type === 'threadDraft') {
            return this.convertThreadDraft(draft);
        } else if (draft.data.type === 'threadMessageDraft') {
            return this.convertThreadMessageDraft(draft);
        }
        throw new Error('Invalid Draft');
    }

    private convertThreadDraft(draft: Draft) {
        if (draft.data.type !== 'threadDraft') {
            throw new Error('Not a ThreadDraft');
        }
        const res: types.ThreadDraft = {
            type: 'threadDraft',
            id: draft.raw.id,
            thread: draft.data.thread,
            message: draft.data.message
        };
        return res;
    }

    private convertThreadMessageDraft(draft: Draft) {
        if (draft.data.type !== 'threadMessageDraft') {
            throw new Error('Not a ThreadMessageDraft');
        }
        const res: types.ThreadMessageDraft = {
            type: 'threadMessageDraft',
            id: draft.raw.id,
            threadId: draft.data.threadId,
            message: draft.data.message
        };
        return res;
    }

    private convertDraftWithAttachments(draft: Draft, attachments: Result<PmxDraftAttachmentEx>[]) {
        if (draft.data.type === 'threadDraft') {
            return this.convertThreadDraftWithAttachments(draft, attachments);
        } else if (draft.data.type === 'threadMessageDraft') {
            return this.convertThreadMessageDraftWithAttachments(draft, attachments);
        }
        throw new Error('Invalid Draft');
    }

    private convertThreadDraftWithAttachments(
        draft: Draft,
        attachments: Result<PmxDraftAttachmentEx>[]
    ) {
        if (draft.data.type !== 'threadDraft') {
            throw new Error('Not a ThreadDraft');
        }
        const res: types.ThreadDraftWithAttachments = {
            type: 'threadDraft',
            id: draft.raw.id,
            thread: draft.data.thread,
            message: draft.data.message,
            attachments: attachments
                .map((x) =>
                    x.success === true ? this.convertDraftAttachmentEx(x.result) : undefined
                )
                .filter((x) => !!x) as types.DraftAttachmentEx[]
        };
        return res;
    }

    private convertThreadMessageDraftWithAttachments(
        draft: Draft,
        attachments: Result<PmxDraftAttachmentEx>[]
    ) {
        if (draft.data.type !== 'threadMessageDraft') {
            throw new Error('Not a ThreadMessageDraft');
        }
        const res: types.ThreadMessageDraftWithAttachments = {
            type: 'threadMessageDraft',
            id: draft.raw.id,
            threadId: draft.data.threadId,
            message: draft.data.message,
            attachments: attachments
                .map((x) =>
                    x.success === true ? this.convertDraftAttachmentEx(x.result) : undefined
                )
                .filter((x) => !!x) as types.DraftAttachmentEx[]
        };
        return res;
    }

    private convertDraftAttachment(x: PmxDraftAttachment) {
        const res: types.DraftAttachment = {
            id: x.attachmentId,
            draftId: x.draftId,
            name: x.meta.name as types.FileName,
            contentType: x.meta.mimetype as types.Mimetype,
            size: x.meta.size as types.FileSize,
            group: x.group,
            tags: x.tags as types.Tag[],
            author: x.author,
            date: x.date,
            hasThumb: x.hasThumb,
            sourceType: 'draft'
        };
        return res;
    }

    private convertDraftAttachmentEx(x: PmxDraftAttachmentEx) {
        const res: types.DraftAttachmentEx = {
            id: x.attachmentId,
            draftId: x.draftId,
            name: x.meta.name,
            contentType: x.meta.mimetype,
            size: x.meta.size,
            group: x.group,
            tags: x.tags as types.Tag[],
            author: x.author,
            date: x.date,
            versions: x.versions,
            contributors: x.contributors,
            modificationDates: x.modificationDates,
            createdDate: x.createdDate,
            creator: x.creator,
            hasThumb: x.hasThumb,
            sourceType: 'draft'
        };
        return res;
    }

    canManageSharedFiles() {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.canManageSharedFiles();
    }

    createSharedFile(
        permission: PmxApi.api.sharedFile.SharedFilePermission,
        userData: types.SharedFileUserDataForCreate,
        setFileModel: types.SharedFileSetFileModel
    ) {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.createSharedFile(permission, userData, setFileModel);
    }

    async deleteSharedFile(id: types.SharedFileId) {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.deleteSharedFile(id);
    }

    async updateSharedFile(
        oldSharedFile: types.SharedFile,
        permission: PmxApi.api.sharedFile.SharedFilePermission,
        userData: types.SharedFileUserData,
        setFileModel?: types.SharedFileSetFileModel
    ) {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.updateSharedFile(
            oldSharedFile,
            permission,
            userData,
            setFileModel
        );
    }

    async getSharedFile(id: types.SharedFileId) {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.getSharedFile(id);
    }

    async getSharedFiles() {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.getSharedFiles();
    }

    async getSharedFileData(id: types.SharedFileId, thumb: boolean, options: DownloadOptions) {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.getSharedFileData(id, thumb, options);
    }

    async getSharedFileWithData(id: types.SharedFileId, thumb: boolean, options: DownloadOptions) {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.getSharedFileWithData(id, thumb, options);
    }

    async getSharedFileDataCore(
        sharedFile: types.SharedFile,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.getSharedFileDataCore(sharedFile, thumb, options);
    }

    async getPublicSharedFile(id: types.SharedFileId, fileMetaEncKey: EncKey, password?: string) {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.getPublicSharedFile(id, fileMetaEncKey, password);
    }

    async getEncryptedPublicSharedFile(id: types.SharedFileId) {
        if (!this.anonSession && !this.session) {
            await this.ensurePrivmxClientScriptLoaded();
            await this.ensureAnonSessionInitialized();
        }
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.getEncryptedPublicSharedFile(id);
    }

    async decryptPublicSharedFile(
        encryptedPublicSharedFile: PmxApi.api.sharedFile.PublicSharedFile,
        fileMetaEncKey: EncKey,
        password?: string
    ) {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.decryptPublicSharedFile(
            encryptedPublicSharedFile,
            fileMetaEncKey,
            password
        );
    }

    async getPublicSharedFileData(
        id: types.SharedFileId,
        fileMetaEncKey: EncKey,
        password: string | undefined,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.getPublicSharedFileData(
            id,
            fileMetaEncKey,
            password,
            thumb,
            options
        );
    }

    async getPublicSharedFileWithData(
        id: types.SharedFileId,
        fileMetaEncKey: EncKey,
        password: string | undefined,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.getPublicSharedFileWithData(
            id,
            fileMetaEncKey,
            password,
            thumb,
            options
        );
    }

    async getPublicSharedFileDataCore(
        publicSharedFile: types.PublicSharedFile,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.getPublicSharedFileDataCore(publicSharedFile, thumb, options);
    }

    async getPublicSharedFileEncKeyFromString(encKeyStr: string) {
        if (!this.anonSession && !this.session) {
            await this.ensurePrivmxClientScriptLoaded();
            await this.ensureAnonSessionInitialized();
        }
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.getPublicSharedFileEncKeyFromString(encKeyStr);
    }

    generateSharedFilePublicUrl(sharedFile: types.SharedFile) {
        const { sharedFileService } = this.anonSession ? this.getAnonSession() : this.getSession();
        return sharedFileService.generateSharedFilePublicUrl(sharedFile);
    }

    getPlanSummary() {
        const { userData } = this.getSession();
        const adminApi = new AdminApi(userData.srpSecure.gateway);
        return adminApi.getPlanSummary();
    }

    addEventListener(
        type: types.FeedChangedEvent['type'],
        listener: (event: types.FeedChangedEvent) => void
    ): void;
    addEventListener(
        type: types.FeedReadChangedEvent['type'],
        listener: (event: types.FeedReadChangedEvent) => void
    ): void;
    addEventListener(
        type: types.NewChatMessageEvent['type'],
        listener: (event: types.NewChatMessageEvent) => void
    ): void;
    addEventListener(
        type: types.ChatUpdatedEvent['type'],
        listener: (event: types.ChatUpdatedEvent) => void
    ): void;
    addEventListener(
        type: types.MeetingUpdatedEvent['type'],
        listener: (event: types.MeetingUpdatedEvent) => void
    ): void;
    addEventListener(
        type: types.ThreadUsersWithAccessibleTimeRangesUpdatedEvent['type'],
        listener: (event: types.ThreadUsersWithAccessibleTimeRangesUpdatedEvent) => void
    ): void;
    addEventListener(
        type: types.NewThreadAttachmentsEvent['type'],
        listener: (event: types.NewThreadAttachmentsEvent) => void
    ): void;
    addEventListener(
        type: types.DeletedThreadAttachmentsEvent['type'],
        listener: (event: types.DeletedThreadAttachmentsEvent) => void
    ): void;
    addEventListener(
        type: types.MessageVerifiedStatusChangedEvent['type'],
        listener: (event: types.MessageVerifiedStatusChangedEvent) => void
    ): void;
    addEventListener(
        type: types.NewCompanyEvent['type'],
        listener: (event: types.NewCompanyEvent) => void
    ): void;
    addEventListener(
        type: types.CompanyChangedEvent['type'],
        listener: (event: types.CompanyChangedEvent) => void
    ): void;
    addEventListener(
        type: types.NewContactEvent['type'],
        listener: (event: types.NewContactEvent) => void
    ): void;
    addEventListener(
        type: types.ContactChangedEvent['type'],
        listener: (event: types.ContactChangedEvent) => void
    ): void;
    addEventListener(
        type: types.NewFormEvent['type'],
        listener: (event: types.NewFormEvent) => void
    ): void;
    addEventListener(
        type: types.FormChangedEvent['type'],
        listener: (event: types.FormChangedEvent) => void
    ): void;
    addEventListener(
        type: types.NewFormSubmitEvent['type'],
        listener: (event: types.NewFormSubmitEvent) => void
    ): void;
    addEventListener(
        type: types.FormSubmitChangedEvent['type'],
        listener: (event: types.FormSubmitChangedEvent) => void
    ): void;
    addEventListener(
        type: types.FormSubmitDeletedEvent['type'],
        listener: (event: types.FormSubmitDeletedEvent) => void
    ): void;
    addEventListener(
        type: types.NewFormSubmitResponseEvent['type'],
        listener: (event: types.NewFormSubmitResponseEvent) => void
    ): void;
    addEventListener(
        type: types.NewDraftEvent['type'],
        listener: (event: types.NewDraftEvent) => void
    ): void;
    addEventListener(
        type: types.DraftChangedEvent['type'],
        listener: (event: types.DraftChangedEvent) => void
    ): void;
    addEventListener(
        type: types.DraftDeletedEvent['type'],
        listener: (event: types.DraftDeletedEvent) => void
    ): void;
    addEventListener(
        type: types.UnreadStateChangedEvent['type'],
        listener: (event: types.UnreadStateChangedEvent) => void
    ): void;
    addEventListener(
        type: types.FavoritesUpdatedEvent['type'],
        listener: (event: types.FavoritesUpdatedEvent) => void
    ): void;
    addEventListener(
        type: types.ItemCountsGrouppedByUsersChangedEvent['type'],
        listener: (event: types.ItemCountsGrouppedByUsersChangedEvent) => void
    ): void;
    addEventListener(
        type: types.ThreadMeetingMeetingChangeEvent['type'],
        listener: (event: types.ThreadMeetingMeetingChangeEvent) => void
    ): void;
    addEventListener(
        type: types.ThreadMeetingLobbyChangeEvent['type'],
        listener: (event: types.ThreadMeetingLobbyChangeEvent) => void
    ): void;
    addEventListener(
        type: types.NewSharedFileEvent['type'],
        listener: (event: types.NewSharedFileEvent) => void
    ): void;
    addEventListener(
        type: types.SharedFileChangedEvent['type'],
        listener: (event: types.SharedFileChangedEvent) => void
    ): void;
    addEventListener(
        type: types.SharedFileDeletedEvent['type'],
        listener: (event: types.SharedFileDeletedEvent) => void
    ): void;
    addEventListener(
        type: types.PrivateTagsChangeEvent['type'],
        listener: (event: types.PrivateTagsChangeEvent) => void
    ): void;
    addEventListener(
        type: types.BeforeLogOutEvent['type'],
        listener: (event: types.BeforeLogOutEvent) => void
    ): void;
    addEventListener(
        type: types.LoggedInEvent['type'],
        listener: (event: types.LoggedInEvent) => void
    ): void;
    addEventListener(
        type: types.LoggedOutEvent['type'],
        listener: (event: types.LoggedOutEvent) => void
    ): void;
    addEventListener(
        type: types.GotAcceptedDocumentsEvent['type'],
        listener: (event: types.GotAcceptedDocumentsEvent) => void
    ): void;
    addEventListener(
        type: types.MeetingVerifiedStatusChangedEvent['type'],
        listener: (event: types.MeetingVerifiedStatusChangedEvent) => void
    ): void;
    addEventListener(
        type: types.ChatVerifiedStatusChangedEvent['type'],
        listener: (event: types.ChatVerifiedStatusChangedEvent) => void
    ): void;
    addEventListener<T extends { type: string }>(type: T['type'], listener: (event: T) => void) {
        const listeners = this.listeners.get(type);
        if (listeners) {
            listeners.push(listener);
        } else {
            this.listeners.set(type, [listener]);
        }
    }

    removeEventListener<T extends { type: string }>(type: T['type'], listener: (event: T) => void) {
        const listeners = this.listeners.get(type);
        if (listeners) {
            const index = listeners.indexOf(listener);
            if (index !== -1) {
                listeners.splice(index, 1);
            }
        }
    }

    dispatchEvent<T extends { type: string }>(event: T) {
        const listeners = this.listeners.get(event.type);
        if (!listeners) {
            return;
        }
        for (const listener of listeners) {
            try {
                listener(event);
            } catch (e) {
                console.error('Error during calling event listener', e);
            }
        }
    }

    convertUserOrContact(entry: types.UserOrContact): types.UsernameOrContactId {
        if (entry.type === 'contact') {
            const res: types.ContactIdOpt = {
                type: 'contact',
                contactId: entry.contact.id
            };
            return res;
        } else if (entry.type === 'user') {
            const res: types.UsernameOpt = {
                type: 'user',
                username: entry.user.username
            };
            return res;
        }
        throw new Error('Invalid entry type', entry);
    }

    convertUserOrContactToOption(entry: types.UserOrContact): types.UserOption {
        if (entry.type === 'contact') {
            const res: types.UserOption = {
                label: entry.contact.name || entry.contact.email,
                value: {
                    type: 'contact',
                    contactId: entry.contact.id
                },
                email: entry.contact.email
            };
            return res;
        } else if (entry.type === 'user') {
            const res: types.UserOption = {
                label: entry.user.name || entry.user.email,
                value: {
                    type: 'user',
                    username: entry.user.username
                },
                email: entry.user.email
            };
            return res;
        }
        throw new Error('Invalid entry type', entry);
    }

    async detectThreadType(chatId: types.ChatId) {
        const { threadService } = this.getSession();
        const thread = await threadService.getThread(chatId);
        return thread.type;
    }

    // TODO: Remove code below after deploy to developers and test environments
    async insertWellKnownTagKeys() {
        const { sharedKvdb } = this.getSession();
        const newTagKeys: TagEncryptionKeys = {
            iv: Buffer.from('ABCDEF0123456789ABCDEF0123456789', 'hex'),
            hmacKey: Buffer.from(
                '0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF',
                'hex'
            ),
            key: Buffer.from(
                '123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0',
                'hex'
            )
        };
        const serialized: TagKeySerialized = {
            iv: Base64.from(newTagKeys.iv),
            key: Base64.from(newTagKeys.key),
            hmacKey: Base64.from(newTagKeys.hmacKey)
        };
        await sharedKvdb.set(PrivmxConst.TAG_KEY, KvdbUtils.createKvdbSettingEntry(serialized));
    }

    async createCaptcha() {
        const gateway = this.anonSession
            ? this.getAnonSession().gateway
            : this.getSession().userData.srpSecure.gateway;
        const captchaApi = new CaptchaApi(gateway);
        return captchaApi.createCaptcha();
    }

    async getAllThreadsTags() {
        const { threadService } = this.getSession();
        return threadService.getAllThreadsTags();
    }

    async getAcceptedDocuments() {
        const { userData } = this.getSession();
        const userApi = new UserApi(userData.srpSecure.gateway);
        const res = await userApi.getAcceptedDocuments();
        const evt: types.GotAcceptedDocumentsEvent = { type: 'gotaccepteddocuments', data: res };
        this.dispatchEvent(evt);
        return res;
    }

    async acceptDocument(model: PmxApi.api.user.AcceptDocumentModel) {
        const { userData } = this.getSession();
        const userApi = new UserApi(userData.srpSecure.gateway);
        return userApi.acceptDocument(model);
    }

    getLinkShortenerConfig() {
        const { serverConfig } = this.getSession();
        return {
            urlPrefix: serverConfig.linkShortenerUrlPrefix,
            customUrlPrefix: serverConfig.linkShortenerCustomUrlPrefix
        };
    }

    async getCustomization() {
        await this.ensurePrivmxClientScriptLoaded();
        const host = this.getGatewayHost();
        const options = await privmx.core.PrivFsRpcManager.resolveOptions({ host: host });
        const plain = privmx.rpc.rpc.createPlainConnection(options);
        const customizationApi = new CustomizationApi({ request: (m, p) => plain.call(m, p) });
        return customizationApi.getCustomization2();
    }
}

export const api = new Api();
export const companyApi = api;
export const feedApi = api;
export const contactApi = api;
export const chatApi = api;
export const fileApi = api;
export const formApi = api;
export const meetingApi = api;
export const userApi = api;
export const queryApi = api;
export const favoriteApi = api;

if (localStorage.getItem('is-dev')) {
    (window as any).api = api;
}
