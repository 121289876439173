import { Box, Skeleton, Paper, ScrollArea, Table } from '@mantine/core';
import { useFormTableStyles } from './styles';

export function FormResponsesSkeleton() {
    const { theme } = useFormTableStyles();

    const rows = new Array(3).fill({ type: 'short' }).map((_, idx) => (
        <tr key={idx}>
            <Box component="td" colSpan={5}>
                <Skeleton w="100%" h={24} />
            </Box>
        </tr>
    ));

    return (
        <Paper h="calc(100% - 130px)" m="lg" p="md" radius="md" pos="relative">
            <ScrollArea w="100%" h="100%" offsetScrollbars>
                <Table>
                    <Box
                        component="thead"
                        pos="sticky"
                        top={0}
                        bg={theme.colorScheme === 'dark' ? 'dark' : 'white'}>
                        <tr>
                            {new Array(5).fill('').map((_, idx) => (
                                <Box
                                    component="th"
                                    w="min-content"
                                    sx={{ textWrap: 'nowrap' }}
                                    key={idx}>
                                    <Skeleton h={24} />
                                </Box>
                            ))}
                        </tr>
                    </Box>
                    <tbody>{rows}</tbody>
                </Table>
            </ScrollArea>
        </Paper>
    );
}
