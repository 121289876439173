import { KvdbSettingEntry, KvdbUtils } from './kvdb/KvdbUtils';
import * as privfs from 'privfs-client';
import { PrivmxConst } from './PrivmxConst';

export class UserSettingsService {
    static getInsertValue(sharedKvdbExtKey: privfs.crypto.ecc.ExtKey): KvdbSettingEntry {
        return KvdbUtils.createKvdbSettingEntry(sharedKvdbExtKey.getPrivatePartAsBase58());
    }

    static createInsertion(
        userSettingsKvdb: privfs.db.KeyValueDb<KvdbSettingEntry>,
        sharedKvdbExtKey: privfs.crypto.ecc.ExtKey
    ) {
        return userSettingsKvdb.createSetData(
            PrivmxConst.SHARED_KVDB_KEY,
            UserSettingsService.getInsertValue(sharedKvdbExtKey)
        );
    }
}
