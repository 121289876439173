import * as PmxApi from 'privmx-server-api';
import * as privmxVideoConferences from 'privmx-video-conferences';
import { api } from '../../api/Api';
import { Utils } from '../../utils/Utils';
import '../../../node_modules/privmx-video-conferences/out/privmx-video-conferences.min.css';
import {
    iconClient,
    IconDef,
    iconDesktop,
    iconLoading,
    iconMeeting,
    iconMicrophone,
    iconMicrophoneSlash,
    iconPhoneSlash,
    iconTiles,
    iconVolumeOff,
    iconVolumeUp
} from '../Icon';
import { AnonymousUser, MeetingId, User, Username } from '../../types/Types';
import { translations } from './translations';
import { UserAvatarProps } from '../../atoms/UserAvatar';
import { VideoConferenceModal } from './VideoConferenceModal';
import { modalStore } from '../../hooks/useModal';
import { MutableRefObject } from 'react';
import { store } from '../../store';
import { leaveMeetingLobby } from '../../store/VideoSlice';

export interface ExternalIcon {
    icon: IconDef;
    spin: boolean;
    element: HTMLElement;
}

export interface ExternalAvatar {
    username: Username;
    element: HTMLElement;
    size: UserAvatarProps['size'];
}

export interface VideoConferenceManagerProps {
    thread: PmxApi.api.thread.Thread;
    regularUsers: User[];
    anonymousUsers: AnonymousUser[];
}

type ExternalIconsSetter = (icons: ExternalIcon[]) => void;

type ExternalAvatarsSetter = (cb: (prev: ExternalAvatar[]) => ExternalAvatar[]) => void;

type UserIdsSetter = (userIds: string[]) => void;

export function createVideoConferenceManager(
    meetingId: MeetingId,
    container: HTMLDivElement,
    setExternalIcons: ExternalIconsSetter,
    setExternalAvatars: ExternalAvatarsSetter,
    setUserIds: UserIdsSetter,
    props: MutableRefObject<VideoConferenceManagerProps>
) {
    container.innerHTML = '';
    const host = process.env.REACT_APP_PRIVMX_SERVER_DOMAIN || document.location.hostname;
    const hostHash = btoa(host);

    const videoConferencesService: privmxVideoConferences.core.IVideoConferencesService = {
        joinToVideoRoom: async () => ({
            ...(await api.joinToVideoRoom(meetingId)),
            script: null as any
        }),
        switchVideoRoomState: (_, token, roomPassword, roomUrl) =>
            api.switchVideoRoomState(meetingId, token, roomPassword, roomUrl),
        cancelVideoRoomCreation: (_, token) => api.cancelVideoRoomCreation(meetingId, token),
        disconnectFromVideoRoom: (_, videoRoomId) =>
            api.disconnectFromVideoRoom(meetingId, videoRoomId),
        commitVideoRoomAccess: (_, videoRoomId) =>
            api.commitVideoRoomAccess(meetingId, videoRoomId),
        getVideoRoomsState: () => api.getVideoRoomsState(),
        registerVideoConferencesDomain: async (_domain) => {},
        encryptWithSectionKey: (roomSecretData) =>
            api.encryptWithSectionKey(roomSecretData, props.current.thread),
        decryptWithSectionKey: (roomSecretDataStr) =>
            api.decryptWithSectionKey(roomSecretDataStr, props.current.thread),
        getRandomBytesHex: (numBytes) => api.getRandomBytesHex(numBytes),
        onConfirmActivity: async () => {},
        onInAnyVideoConferenceStateChanged: async () => {},
        onJoinedConference: async () => {},
        onLeftConference: async () => {
            const currModal = modalStore
                .getModals()
                .find((x) => x?.component === VideoConferenceModal);
            const currMeetingId = currModal?.data.videoConference.meetingId;
            store.dispatch(leaveMeetingLobby());
            if (currMeetingId && currMeetingId === meetingId) {
                currModal.onClose();
            }
        },
        sendVideoConferenceStartMessage: async () => {},
        sendVideoConferenceEndMessage: async () => {},
        sendVideoConferenceGongMessage: async () => {}
    };

    const videoConferenceManager = new privmxVideoConferences.core.VideoConferenceManager({
        host: host,
        hostHash: hostHash,
        sectionId: meetingId,
        service: videoConferencesService,
        hideWelcomeMessage: true,
        $container: container,
        providers: {
            escapeHtml: (text) => {
                return Utils.escapeHtml(text);
            },
            getGongMessage: async () => '',
            getMediaDevices: (types, showDeviceSelector) =>
                getMediaDevices(types, showDeviceSelector),
            getPersonName: (hashmailOrPub) => {
                const anonymousUser = props.current.anonymousUsers.find(
                    (x) => x.pub === hashmailOrPub
                );
                if (anonymousUser) {
                    return anonymousUser.nickname;
                }
                const regularUser = props.current.regularUsers.find(
                    (x) => x.username === hashmailOrPub.split('#')[0]
                );
                if (regularUser) {
                    return regularUser.name ?? regularUser.username;
                }
                return hashmailOrPub;
            },
            getScreenObtainerResult: async () => null as any,
            i18n: (key: string, ..._args: unknown[]) => {
                return key in translations ? (translations as any)[key]() : key;
            },
            renderAvatars: () => {
                const users = [...container.querySelectorAll('.user-container[data-hashmail]')]
                    .map((x) => x.getAttribute('data-hashmail') ?? '')
                    .filter((x) => x.length > 0);
                setUserIds(users);
                renderAvatars(container, setExternalAvatars);
            },
            settingChangedCallback: () => {},
            hideTalkingWhenMutedNotification: () => {},
            showTalkingWhenMutedNotification: () => {},

            onAvailableResolutionsChanged: () => {},
            onParticipantConnectionStatsUpdated: () => {},
            onUnsupportedBrowser: () => {}
        }
    });

    renderExternalIcons(container, setExternalIcons);

    return videoConferenceManager;
}

function renderExternalIcons(
    videoContainer: HTMLDivElement,
    setExternalIcons: ExternalIconsSetter
) {
    const icons = [...videoContainer.querySelectorAll('i.privmx-icon, i.fa, i[class*="ico-"]')];
    const externalIcons: ExternalIcon[] = [];
    for (const iconEl of icons) {
        const iconSpin: boolean = iconEl.classList.contains('fa-spin');
        let iconClass: string = '';
        if (iconEl.classList.contains('__react-processed')) {
            continue;
        }
        iconEl.classList.add('__react-processed');
        iconEl.classList.forEach((cls) => {
            if (
                cls.startsWith('privmx-icon-') ||
                (cls.startsWith('fa-') && cls !== 'fa-spin') ||
                cls.startsWith('ico-')
            ) {
                iconClass = cls;
            }
        });
        let icon: IconDef | null = null;
        if (iconClass === 'privmx-icon-videocall') {
            icon = iconMeeting;
        } else if (iconClass === 'fa-microphone') {
            icon = iconMicrophone;
        } else if (iconClass === 'fa-microphone-slash') {
            icon = iconMicrophoneSlash;
        } else if (iconClass === 'fa-volume-up') {
            icon = iconVolumeUp;
        } else if (iconClass === 'fa-volume-off') {
            icon = iconVolumeOff;
        } else if (iconClass === 'fa-phone') {
            icon = iconPhoneSlash;
        } else if (iconClass === 'fa-desktop') {
            icon = iconDesktop;
        } else if (iconClass === 'fa-th-large') {
            icon = iconTiles;
        } else if (iconClass === 'fa-user-o') {
            icon = iconClient;
        } else if (iconClass === 'fa-circle-o-notch') {
            icon = iconLoading;
        }
        if (icon) {
            externalIcons.push({
                icon: icon,
                spin: iconSpin,
                element: iconEl as HTMLElement
            });
        }
    }
    setExternalIcons(externalIcons);
}

function renderAvatars(videoContainer: HTMLDivElement, setExternalAvatars: ExternalAvatarsSetter) {
    const avatars = [
        ...videoContainer.querySelectorAll('canvas.not-rendered[data-hashmail-image]')
    ] as HTMLCanvasElement[];
    const newExternalAvatars: ExternalAvatar[] = [];
    for (const avatarEl of avatars) {
        const username = avatarEl.dataset.dataHashmailImage ?? '';
        const avatarContainer = document.createElement('div');
        avatarContainer.classList.add('user-avatar-container');
        avatarEl.replaceWith(avatarContainer);
        const size = parseInt(avatarEl.dataset.width ?? '30');
        newExternalAvatars.push({
            username: username as Username,
            element: avatarContainer,
            size: isNaN(size) ? 30 : size
        });
    }
    if (newExternalAvatars.length > 0) {
        setExternalAvatars((prev) => [...prev, ...newExternalAvatars]);
    }
}

async function getMediaDevices(
    _types: { videoInput?: boolean; audioInput?: boolean; audioOutput?: boolean },
    _showDeviceSelector: boolean
) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const defaultVideoInputDevice = devices.filter((device) => device.kind === 'videoinput')[0];
    const defaultAudioInputDevice = devices.filter((device) => device.kind === 'audioinput')[0];
    const defaultAudioOutputDevice = devices.filter((device) => device.kind === 'audiooutput')[0];
    const defaultVideoInput = defaultVideoInputDevice
        ? defaultVideoInputDevice.deviceId
        : undefined;
    const defaultAudioInput = defaultAudioInputDevice
        ? defaultAudioInputDevice.deviceId
        : undefined;
    const defaultAudioOutput = defaultAudioOutputDevice
        ? defaultAudioOutputDevice.deviceId
        : undefined;

    const ret: {
        videoInput?: string | undefined;
        audioInput?: string | undefined;
        audioOutput?: string | undefined;
        rawResult?: {
            selected: boolean;
            videoInput?: string | false | undefined;
            audioInput?: string | false | undefined;
            audioOutput?: string | false | undefined;
        };
    } = {
        videoInput: defaultVideoInput,
        audioInput: defaultAudioInput,
        audioOutput: defaultAudioOutput,
        rawResult: {
            selected: true,
            videoInput: defaultVideoInput,
            audioInput: defaultAudioInput,
            audioOutput: defaultAudioOutput
        }
    };
    return ret;
}
