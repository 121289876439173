import Modal from '../../atoms/Modal';
import { BaseModalData } from '../../types/Modals';
import StateView from '../../atoms/State';
import { SharedFileEditPage } from './index';
import { SharedFileId } from '../../types/Types';
import { useCallback } from 'react';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { useTranslation } from 'react-i18next';

export interface SharedFileEditModalData extends BaseModalData {
    id: SharedFileId;
}

export function SharedFileEditModal(props: {
    onClose: () => void;
    data: SharedFileEditModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForSharedFile(props.data.id), [props.data.id])
    );

    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            height={props.data.height ?? 490}
            minHeight={440}
            width={props.data.width}
            title={
                props.data.title ??
                (state.type === 'done' && state.data.sharedFile?.userData.title
                    ? t('modal.sharedFileEdit.titleWithName', {
                          name: state.data.sharedFile.userData.title
                      })
                    : t('modal.sharedFileEdit.titleWithoutName'))
            }>
            <StateView state={state} retry={revalidate}>
                {(data) => (
                    <SharedFileEditPage
                        sharedFile={data.sharedFile ?? undefined}
                        handleClose={props.onClose}
                    />
                )}
            </StateView>
        </Modal>
    );
}
