import { api } from '../../api/Api';
import { Meeting } from '../../types/Types';
import { useCallback, useState } from 'react';
import { ListElementIcons } from '../../mantineAtoms/ListElement/ListElement';

interface MeetingIconsProps {
    meeting: Meeting;
    setIsAboutToDisappear?: (value: boolean) => void;
    editFn: (e: any) => void;
}

export default function MeetingIcons(props: MeetingIconsProps) {
    const [isProcessingPin, setIsProcessingPin] = useState<boolean>(false);
    const [isProcessingArchive, setIsProcessingArchive] = useState<boolean>(false);

    const handleTogglePinnedClick = useCallback(
        async (event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            setIsProcessingPin(true);
            const newPinned = !props.meeting.pinned;
            try {
                await api.setMeetingPinned(props.meeting.id, newPinned);
            } finally {
                setIsProcessingPin(false);
            }
        },
        [props.meeting]
    );

    const handleToggleArchivedClick = useCallback(
        async (event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            setIsProcessingArchive(true);
            const newArchived = !props.meeting.archived;
            try {
                await api.setMeetingArchived(props.meeting.id, newArchived);
            } finally {
                setIsProcessingArchive(false);
            }
        },
        [props.meeting]
    );

    return (
        <ListElementIcons
            editFn={(e) => props.editFn(e)}
            archiveFn={handleToggleArchivedClick}
            pinFn={handleTogglePinnedClick}
            procsesingArchive={isProcessingArchive}
            procsesingPin={isProcessingPin}
            isArchived={props.meeting.archived}
            isPinned={props.meeting.pinned}
        />
    );
}
