import * as core from "../../core";
import * as template from "./template";
import { VideoConferenceLayoutCalculator } from "./VideoConferenceLayoutCalculator";
import { JitsiMeetJS } from "../../core/jitsi/lib-jitsi-meet";
import { CameraConfiguration } from "../../core/utils";
const Olm = require("olm");

export interface Model {
    roomMetadata: core.RoomMetadata;
    videoConferenceSettings: Required<core.VideoConferenceSettings>;
}

export interface VideoConferenceOptions {
    $container: HTMLDivElement;
    hideWelcomeMessage: boolean;
    providers: core.Providers;
    manager: core.VideoConferenceManager;
}

export class VideoConference {
    
    // HTML elements
    private $container: HTMLDivElement;
    private $main: HTMLDivElement;
    private $titleContainer: HTMLDivElement;
    private $controlsContainer: HTMLDivElement;
    private $disconnectButton: HTMLButtonElement;
    private $disableDesktopSharingButton: HTMLButtonElement;
    private $enableDesktopSharingButton: HTMLButtonElement;
    private $disableLocalAudioOutputButton: HTMLButtonElement;
    private $enableLocalAudioOutputButton: HTMLButtonElement;
    private $disableLocalAudioInputButton: HTMLButtonElement;
    private $enableLocalAudioInputButton: HTMLButtonElement;
    private $disableLocalVideoInputButton: HTMLButtonElement;
    private $enableLocalVideoInputButton: HTMLButtonElement;
    private $toggleExtraSettingsButton: HTMLButtonElement;
    private $resolutionNativeSelect: HTMLSelectElement;
    private $switchModeToTilesButton: HTMLButtonElement;
    private $switchModeToSingleSpeakerButton: HTMLButtonElement;
    private $showLocalParticipantButton: HTMLButtonElement;
    private $hideLocalParticipantButton: HTMLButtonElement;
    private $changeAudioOutputSelect: HTMLSelectElement;
    private $changeAudioInputSelect: HTMLSelectElement;
    private $changeVideoInputSelect: HTMLSelectElement;
    private $gongButton: HTMLButtonElement;
    private $audiosContainer: HTMLDivElement;
    private $videosContainer: HTMLDivElement;
    private $localAudioContainer: HTMLDivElement;
    private $localVideoContainer: HTMLDivElement;
    private $remoteAudiosContainer: HTMLDivElement;
    private $remoteVideosContainer: HTMLDivElement;
    private $curtain: HTMLDivElement;
    
    // Video conference
    private videoConference: core.VideoConference;
    private userContainerByParticipantId: { [participantId: string]: HTMLDivElement } = {};
    private audioByParticipantId: { [participantId: string]: HTMLAudioElement } = {};
    private videoByParticipantId: { [participantId: string]: HTMLVideoElement } = {};
    private participantNotTalkingTimeoutByParticipantId: { [participantId: string]: number } = {};
    private isTalkingWhenMutedNotificationVisible: boolean = false;
    private showTalkingWhenMutedNotificationTimeout: number | null = null;
    private lastShowTalkingWhenMutedNotificationCallTime: number | null = null;
    
    // Display mode
    private containersDisplayMode: "tiles" | "single-speaker" = "tiles";
    private singleSpeakerModeForcedParticipantId: string | null = null;
    
    // Misc
    private gongButtonStopAnimationTimeout: number | null = null;
    private isDeviceSelectorOpen: boolean = false;
    private model: Model | null = null;
    private hideWelcomeMessage: boolean;
    private providers: core.Providers;
    private manager: core.VideoConferenceManager;
    
    // Templates
    private mainTemplate;
    private localParticipantAudioTemplate;
    private remoteParticipantAudioTemplate;
    private userContainerTemplate;
    
    constructor(options: VideoConferenceOptions) {
        this.$container = options.$container;
        this.hideWelcomeMessage = options.hideWelcomeMessage;
        this.providers = options.providers;
        this.manager = options.manager;
        this.mainTemplate = new template.MainTemplate(this.i18n.bind(this));
        this.localParticipantAudioTemplate = new template.LocalParticipantAudioTemplate(this.i18n.bind(this));
        this.remoteParticipantAudioTemplate = new template.RemoteParticipantAudioTemplate(this.i18n.bind(this));
        this.userContainerTemplate = new template.UserContainerTemplate(this.i18n.bind(this));
        
        this.$main = this.renderMainTemplate();
        this.$container.append(this.$main);
        
        // HTML Elements
        this.$titleContainer = this.$main.querySelector(".title-container")!;
        this.$controlsContainer = this.$main.querySelector(".controls-container")!;
        this.$disconnectButton = this.$controlsContainer.querySelector("[data-action='disconnect']")!;
        this.$disableDesktopSharingButton = this.$controlsContainer.querySelector("[data-action='disable-desktop-sharing']")!;
        this.$enableDesktopSharingButton = this.$controlsContainer.querySelector("[data-action='enable-desktop-sharing']")!;
        this.$disableLocalAudioOutputButton = this.$controlsContainer.querySelector("[data-action='disable-local-audio-output']")!;
        this.$enableLocalAudioOutputButton = this.$controlsContainer.querySelector("[data-action='enable-local-audio-output']")!;
        this.$disableLocalAudioInputButton = this.$controlsContainer.querySelector("[data-action='disable-local-audio-input']")!;
        this.$enableLocalAudioInputButton = this.$controlsContainer.querySelector("[data-action='enable-local-audio-input']")!;
        this.$disableLocalVideoInputButton = this.$controlsContainer.querySelector("[data-action='disable-local-video-input']")!;
        this.$enableLocalVideoInputButton = this.$controlsContainer.querySelector("[data-action='enable-local-video-input']")!;
        this.$toggleExtraSettingsButton = this.$controlsContainer.querySelector("[data-action='toggle-extra-settings']")!;
        this.$resolutionNativeSelect = this.$controlsContainer.querySelector(".resolution-native-select")!;
        this.$switchModeToTilesButton = this.$controlsContainer.querySelector("[data-action='switch-mode-to-tiles']")!;
        this.$switchModeToSingleSpeakerButton = this.$controlsContainer.querySelector("[data-action='switch-mode-to-single-speaker']")!;
        this.$showLocalParticipantButton = this.$controlsContainer.querySelector("[data-action='show-local-participant']")!;
        this.$hideLocalParticipantButton = this.$controlsContainer.querySelector("[data-action='hide-local-participant']")!;
        this.$changeAudioOutputSelect = this.$controlsContainer.querySelector("[data-action='change-audio-output']")!;
        this.$changeAudioInputSelect = this.$controlsContainer.querySelector("[data-action='change-audio-input']")!;
        this.$changeVideoInputSelect = this.$controlsContainer.querySelector("[data-action='change-video-input']")!;
        this.$gongButton = this.$controlsContainer.querySelector("[data-action='videoconference-gong']")!;
        this.$audiosContainer = this.$main.querySelector(".audios-container")!;
        this.$videosContainer = this.$main.querySelector(".videos-container")!;
        this.$localAudioContainer = this.$audiosContainer.querySelector(".local-audio-container")!;
        this.$localVideoContainer = this.$videosContainer.querySelector(".local-video-container")!;
        this.$remoteAudiosContainer = this.$audiosContainer.querySelector(".remote-audios-container")!;
        this.$remoteVideosContainer = this.$videosContainer.querySelector(".remote-videos-container")!;
        this.$curtain = this.$main.querySelector(".curtain")!;
            
        // Events
        this.$disconnectButton.addEventListener("click", this.onDisconnectButtonClick.bind(this));
        this.$disableDesktopSharingButton.addEventListener("click", this.onDisableDesktopSharingButtonClick.bind(this));
        this.$enableDesktopSharingButton.addEventListener("click", this.onEnableDesktopSharingButtonClick.bind(this));
        this.$enableLocalAudioOutputButton.addEventListener("click", this.onEnableLocalAudioOutputButtonClick.bind(this));
        this.$disableLocalAudioOutputButton.addEventListener("click", this.onDisableLocalAudioOutputButtonClick.bind(this));
        this.$enableLocalAudioInputButton.addEventListener("click", this.onEnableLocalAudioInputButtonClick.bind(this));
        this.$disableLocalAudioInputButton.addEventListener("click", this.onDisableLocalAudioInputButtonClick.bind(this));
        this.$enableLocalVideoInputButton.addEventListener("click", this.onEnableLocalVideoInputButtonClick.bind(this));
        this.$disableLocalVideoInputButton.addEventListener("click", this.onDisableLocalVideoInputButtonClick.bind(this));
        this.$toggleExtraSettingsButton.addEventListener("click", this.onToggleExtraSettingsButtonClick.bind(this));
        this.$switchModeToTilesButton.addEventListener("click", this.onSwitchModeToTilesButtonClick.bind(this));
        this.$switchModeToSingleSpeakerButton.addEventListener("click", this.onSwitchModeToSingleSpeakerButtonClick.bind(this));
        this.$showLocalParticipantButton.addEventListener("click", this.onShowLocalParticipantClick.bind(this));
        this.$hideLocalParticipantButton.addEventListener("click", this.onHideLocalParticipantClick.bind(this));
        this.$changeAudioOutputSelect.addEventListener("change", this.onChangeAudioOutputSelectChange.bind(this));
        this.$changeAudioInputSelect.addEventListener("change", this.onChangeAudioInputSelectChange.bind(this));
        this.$changeVideoInputSelect.addEventListener("change", this.onChangeVideoInputSelectChange.bind(this));
        this.$gongButton.addEventListener("click", this.onGongButtonClick.bind(this));
        this.$resolutionNativeSelect.addEventListener("change", () => {
            this.setResolutionFromString(this.$resolutionNativeSelect.value);
        });
        this.$main.addEventListener("click", e => {
            const target = e.target as HTMLElement;
            
            const messageBoxOkButton = target.closest("button[data-action='message-box-ok']");
            if (messageBoxOkButton !== null) {
                this.hideMessageOverlay();
            }
            
            const $userContainer = target.closest<HTMLDivElement>(".user-container") ;
            if ($userContainer !== null) {
                this.onUserContainerClick($userContainer);
            }
        });
        
        this.videoConference = new core.JitsiVideoConference({
            providers: this.providers,
            onDevicesListChanged: this.onDevicesListChanged.bind(this),
            onConnectionLost: this.onConnectionLost.bind(this),
            onUserJoined: this.onUserJoined.bind(this),
            onUserLeft: this.onUserLeft.bind(this),
            onCustomDominantSpeakerChanged: this.onCustomDominantSpeakerChanged.bind(this),
            onDominantSpeakerChanged: this.onDominantSpeakerChanged.bind(this),
            onDesktopSharingEnabled: this.onDesktopSharingEnabled.bind(this),
            onLocalAudioTrackCreated: this.onLocalAudioTrackCreated.bind(this),
            onLocalVideoTrackCreated: this.onLocalVideoTrackCreated.bind(this),
            onRemoteAudioTrackCreated: this.onRemoteAudioTrackCreated.bind(this),
            onRemoteVideoTrackCreated: this.onRemoteVideoTrackCreated.bind(this),
            onRemoteAudioTrackDeleted: this.onRemoteAudioTrackDeleted.bind(this),
            onRemoteVideoTrackDeleted: this.onRemoteVideoTrackDeleted.bind(this),
            onDesktopSharingDisabled: this.onDesktopSharingDisabled.bind(this),
            onLocalAudioOutputEnabled: this.onLocalAudioOutputEnabled.bind(this),
            onLocalAudioOutputDisabled: this.onLocalAudioOutputDisabled.bind(this),
            onLocalAudioInputEnabled: this.onLocalAudioInputEnabled.bind(this),
            onLocalAudioInputDisabled: this.onLocalAudioInputDisabled.bind(this),
            onLocalVideoInputEnabled: this.onLocalVideoInputEnabled.bind(this),
            onLocalVideoInputDisabled: this.onLocalVideoInputDisabled.bind(this),
            onTrackMutedStatusChanged: this.onTrackMutedStatusChanged.bind(this),
            onTrackAudioLevelChanged: this.onTrackAudioLevelChanged.bind(this),
            onParticipantConnectionStatsUpdated: this.onParticipantConnectionStatsUpdated.bind(this),
            requestShowMessage: this.showMessage.bind(this),
        });
    }
    
    afterScriptLoaded(version: string): void {
        this.videoConference.afterScriptLoaded(version);
    }
    
    async destroy(): Promise<void> {
        if (this.$main) {
            this.$main.remove();
        }
    }
    
    async init(model: Model): Promise<void> {
        this.model = model;
        
        if (model.videoConferenceSettings.videoResolution) {
            const { width, height } = model.videoConferenceSettings.videoResolution;
            const defaultResolution = core.VideoResolutions.AVAILABLE_RESOLUTIONS.find(resolution => resolution.width == width && resolution.height == height);
            if (defaultResolution) {
                core.VideoResolutions.DEFAULT_RESOLUTION = defaultResolution;
            }
        }
        
        const resizeObserver = new ResizeObserver(() => {
            this.onContainerSizeChanged();
        });
        resizeObserver.observe(this.$container);
    }
    
    async disconnect(): Promise<void> {
        if (this.videoConference.getState() == core.VideoConferenceState.DISCONNECTED) {
            this.onConnectionLost("connectingFailed", "VideoConferenceView.disconnect()");
        }
        else {
            return this.videoConference.disconnect();
        }
    }
    
    async connect(connectionOptionsStr: string): Promise<boolean> {
        try {
            if (!this.model) {
                throw new Error("Missing model");
            }
            const connectionOptions: core.VideoConferenceConnectionOptions = JSON.parse(connectionOptionsStr);
            const { configuration, tmpUserName, tmpUserPassword, options } = connectionOptions;
            
            this.model.roomMetadata.title = options.title ?? "";
            this.model.roomMetadata.disableEncryption = options.disableEncryption;
            this.model.roomMetadata.experimentalH264 = options.experimentalH264;
            this.model.roomMetadata.experimentalDominantSpeaker = options.experimentalDominantSpeaker;
            this.videoConference.updateE2EEEnabled(options);
            this.updateMainTemplate();
            
            this.userContainerByParticipantId = {};
            this.audioByParticipantId = {};
            this.videoByParticipantId = {};
            if (!(<any>window).privmxOlmInitialized) {
                this.initJitsiMeetScreenObtainer();
                await Olm.init();
            }
            (<any>window).privmxOlmInitialized = true;
            if (configuration.conferenceEncryptionKey) {
                await this.videoConference.setEncryptionKey(configuration.conferenceEncryptionKey);
            }
            if (configuration.conferenceEncryptionIV) {
                this.videoConference.setEncryptionIV(configuration.conferenceEncryptionIV);
            }
            let doConnect = tmpUserName && tmpUserPassword ? true : await this.chooseDevices();
            if (doConnect) {
                await this.videoConference.connect(connectionOptions);
                this.updateMainTemplate();
                if (tmpUserName && tmpUserPassword) {
                    doConnect = await this.chooseDevices();
                    if (doConnect) {
                        await this.videoConference.createLocalTracks()
                        this.createLocalParticipantUserContainer();
                    }
                    else {
                        this.manager.disconnect();
                    }
                }
                else {
                    this.createLocalParticipantUserContainer();
                }
                this.onAfterInitialUserContainerCreated();
            }
            else {
                this.manager.disconnect();
            }
            return doConnect;
        }
        catch (e) {
            if (e instanceof Error && e.message === core.VideoConference.ERROR_E2EE_NOT_SUPPORTED) {
                this.providers.onUnsupportedBrowser();
            }
            console.error("VideoConferenceView.connect():", e);
            throw e;
        }
    }
    
    private onAfterInitialUserContainerCreated(): void {
        this.hideLoadingOverlay();
        if (!this.model) {
            throw new Error("Missing model");
        }
        if (this.model.videoConferenceSettings.containersDisplayMode === "single-speaker") {
            this.switchModeToSingleSpeaker();
        }
        if (!this.model.videoConferenceSettings.isVideoInputEnabled) {
            this.disableLocalVideoInput();
        }
        if (!this.model.videoConferenceSettings.isAudioInputEnabled) {
            this.disableLocalAudioInput();
        }
        if (!this.model.videoConferenceSettings.isAudioOutputEnabled) {
            this.disableLocalAudioOutput();
        }
        if (!this.model.videoConferenceSettings.showLocalParticipant) {
            this.toggleShowLocalParticipant(this.model.videoConferenceSettings.showLocalParticipant);
        }
    }
    
    async connectAndCreateConference(connectionOptionsStr: string): Promise<string> {
        try {
            const generatedEncryptionKey = await this.videoConference.generateEncryptionKey();
            if (generatedEncryptionKey) {
                await this.videoConference.setEncryptionKey(generatedEncryptionKey);
            }
            const generatedEncryptionIV = this.videoConference.generateEncryptionIv();
            this.videoConference.setEncryptionIV(generatedEncryptionIV);
            const doConnect = await this.connect(connectionOptionsStr);
            if (!doConnect) {
                return JSON.stringify({
                    status: "cancelled",
                });
            }
            return JSON.stringify({
                status: "ok",
                data: {
                    key: generatedEncryptionKey,
                    iv: generatedEncryptionIV,
                },
            });
        }
        catch (e) {
            console.error(e);
            return JSON.stringify({
                status: "error",
                errorStr: `${e}`,
            });
        }
    }
    
    
    
    
    
    /*****************************************
    ***** VideoConference event handlers *****
    *****************************************/
    private onDevicesListChanged(devices: MediaDeviceInfo[]): void {
        const audioOutputDevices = devices.filter(device => device.kind == "audiooutput");
        const audioInputDevices = devices.filter(device => device.kind == "audioinput");
        const videoInputDevices = devices.filter(device => device.kind == "videoinput");
        this.fillDevicesHtmlSelect(this.$changeAudioOutputSelect, audioOutputDevices, this.videoConference.getLocalAudioOutputDeviceId() ?? undefined);
        this.fillDevicesHtmlSelect(this.$changeAudioInputSelect, audioInputDevices, this.videoConference.getLocalAudioInputDeviceId() ?? undefined);
        this.fillDevicesHtmlSelect(this.$changeVideoInputSelect, videoInputDevices, this.videoConference.getLocalVideoInputDeviceId() ?? undefined);
    }
    
    // @ts-ignore
    private onConnectionLost(reason: core.VideoConferenceConnectionLostReason, extraInfo: string): void {
        this.userContainerByParticipantId = {};
        this.audioByParticipantId = {};
        this.videoByParticipantId = {};
        this.$remoteAudiosContainer.innerHTML = "";
        this.$remoteVideosContainer.innerHTML = "";
        this.$localAudioContainer.innerHTML = "";
        this.$localVideoContainer.innerHTML = "";
        this.updateRemoteParticipantsCount();
    }
    
    private onUserJoined(participantId: string): void {
        this.createUserContainer(participantId);
        this.onCustomDominantSpeakerChanged();
    }
    
    private onUserLeft(participantId: string): void {
        if (this.singleSpeakerModeForcedParticipantId == participantId) {
            this.switchModeToSingleSpeaker();
        }
        this.removeUserContainer(participantId);
        this.onCustomDominantSpeakerChanged();
        this.onParticipantConnectionStatsUpdated(participantId, null);
    }
    
    private getCustomDominantSpeaker(): core.VideoConferenceParticipant<any> | null {
        if (this.containersDisplayMode == "single-speaker" && this.singleSpeakerModeForcedParticipantId) {
            const participant = this.videoConference.getParticipant(this.singleSpeakerModeForcedParticipantId);
            if (participant) {
                return participant;
            }
        }
        return this.videoConference.getCustomDominantSpeaker();
    }
    
    private getDominantSpeaker(): core.VideoConferenceParticipant<any> | null {
        return this.videoConference.getDominantSpeaker();
    }
    
    private onCustomDominantSpeakerChanged(): void {
        this.toggleDominantSpeakerCssClass(true);
    }
    
    private onDominantSpeakerChanged(): void {
        this.toggleDominantSpeakerCssClass(false);
    }
    
    private toggleDominantSpeakerCssClass(isCustom: boolean): void {
        const dominantSpeaker = isCustom ? this.getCustomDominantSpeaker() : this.getDominantSpeaker();
        const cssClassName = isCustom ? "custom-dominant-speaker" : "dominant-speaker";
        const $userContainers = this.$remoteVideosContainer.querySelectorAll(".user-container");
        const userContainers = Array.from($userContainers);
        if (dominantSpeaker) {
            const participantId = dominantSpeaker.id;
            userContainers
                .filter(userContainer => userContainer.matches(`.user-container:not([data-participant-id='${participantId}'])`))
                .forEach(userContainer => userContainer.classList.remove(cssClassName));
            this.getUserContainer(dominantSpeaker.id)?.classList.add(cssClassName);
        }
        else {
            userContainers.forEach(userContainer => {
                userContainer.querySelectorAll(".user-container").forEach(innerUserContainer => innerUserContainer.classList.remove(cssClassName));
            });
        }
        const localParticipant = this.videoConference.getLocalParticipant();
        const dominantSpeakerUserContainers = userContainers.filter(userContainer => userContainer.matches(`.${cssClassName}`));
        if (dominantSpeakerUserContainers.length == 0 && localParticipant) {
            userContainers
                .filter(userContainer => userContainer.matches(`.user-container[data-participant-id='${localParticipant.id}']`))
                .forEach(userContainer => userContainer.classList.add(cssClassName));
        }
    }
    
    private onDesktopSharingEnabled(): void {
        this.setHtmlElementData(this.$main, "desktop-sharing-enabled", "true");
        const $video = this.getLocalUserVideoElement();
        if ($video) {
            this.videoConference.getLocalDesktopTrack()?.attach($video);
        }
        this.refreshTrackAvailability(this.videoConference.getLocalParticipantId());
    }
    
    private onLocalAudioTrackCreated(): void {
        this.$localAudioContainer.querySelector("audio")?.remove();
        const $audio = this.localParticipantAudioTemplate.render({});
        this.$localAudioContainer.append($audio);
        this.videoConference.getLocalAudioTrack()?.attach($audio);
        this.audioByParticipantId[this.videoConference.getLocalParticipantId()] = $audio;
    }
    
    private onLocalVideoTrackCreated(): void {
        this.hideLocalVideo();
        this.updateAvailableResolutions(true)
            .then(() => { this.showLocalVideo(); })
            .catch(() => { this.showLocalVideo(); });
        const localParticipantId = this.videoConference.getLocalParticipantId();
        if (localParticipantId) {
            const $userContainer = this.getUserContainer(localParticipantId);
            if ($userContainer) {
                const $video = this.getLocalUserVideoElement();
                const track = this.videoConference.getVideoTrack(localParticipantId);
                if ($video && track) {
                    track.attach($video);
                }
                return;
            }
        }
        this.createLocalParticipantUserContainer();
    }
    
    private onRemoteAudioTrackCreated(participantId: string): void {
        this.$remoteAudiosContainer.querySelector(`audio[data-participant-id='${participantId}']`)?.remove();
        const $audio = this.remoteParticipantAudioTemplate.render({
            participantId: participantId,
        });
        $audio.dataset["isMuted"] = "true";
        this.$remoteAudiosContainer.append($audio);
        const track = this.videoConference.getRemoteAudioTrack(participantId);
        this.audioByParticipantId[participantId] = $audio;
        if (track) {
            track.attach($audio);
            this.updateRemoteParticipantAudioLevel(participantId, track.audioLevel);
        }
        this.refreshTrackAvailability(participantId);
    }
    
    private onRemoteVideoTrackCreated(participantId: string): void {
        const $userContainer = this.getOrCreateUserContainer(participantId);
        const $video = $userContainer.querySelector<HTMLVideoElement>("video");
        const track = this.videoConference.getVideoTrack(participantId);
        if (track && $video) {
            track.attach($video);
        }
        this.refreshTrackAvailability(participantId);
    }
    
    private onRemoteAudioTrackDeleted(participantId: string): void {
        const $audio = this.$remoteAudiosContainer.querySelector<HTMLAudioElement>(`audio[data-participant-id='${participantId}']`);
        const track = this.videoConference.getAudioTrack(participantId);
        if (track && $audio) {
            track.detach($audio);
        }
        $audio?.remove();
        delete this.audioByParticipantId[participantId];
        const userContainer = this.getUserContainer(participantId);
        if (userContainer) {
            userContainer.classList.remove("is-talking");
        }
        this.refreshTrackAvailability(participantId);
    }
    
    private onRemoteVideoTrackDeleted(participantId: string): void {
        const $userContainer = this.getOrCreateUserContainer(participantId);
        const $video = $userContainer.querySelector<HTMLVideoElement>("video");
        const track = this.videoConference.getRemoteVideoTrack(participantId);
        if (track && $video) {
            track.detach($video);
        }
        this.refreshTrackAvailability(participantId);
        delete this.videoByParticipantId[participantId];
    }
    
    private onDesktopSharingDisabled(): void {
        this.setHtmlElementData(this.$main, "desktop-sharing-enabled", "false");
        if (this.videoConference.getIsLocalVideoInputEnabled()) {
            this.videoConference.getLocalVideoTrack()?.attach(this.getLocalUserVideoElement()!);
        }
        this.refreshTrackAvailability(this.videoConference.getLocalParticipantId());
    }
    
    private onLocalAudioOutputEnabled(): void {
        this.setVideoConferenceSetting("isAudioOutputEnabled", true);
        this.setHtmlElementData(this.$main, "local-audio-output-enabled", "true");
        const $audios = this.$remoteAudiosContainer.querySelectorAll("audio") as NodeListOf<HTMLAudioElement>;
        $audios.forEach(audio => {
            audio.muted = !this.videoConference.isParticipantAudible(audio.dataset["participantId"]!);
        });
        if (this.videoConference.getLocalParticipant()) {
            this.refreshTrackAvailability(this.videoConference.getLocalParticipantId());
        }
    }
    
    private onLocalAudioOutputDisabled(): void {
        this.setVideoConferenceSetting("isAudioOutputEnabled", false);
        this.setHtmlElementData(this.$main, "local-audio-output-enabled", "false");
        const $audios = this.$remoteAudiosContainer.querySelectorAll("audio") as NodeListOf<HTMLAudioElement>;
        $audios.forEach(audio => {
            audio.muted = !this.videoConference.isParticipantAudible(audio.dataset["participantId"]!);
        });
        if (this.videoConference.getLocalParticipant()) {
            this.refreshTrackAvailability(this.videoConference.getLocalParticipantId());
        }
    }
    
    private onLocalAudioInputEnabled(): void {
        this.setVideoConferenceSetting("isAudioInputEnabled", true);
        this.setHtmlElementData(this.$main, "local-audio-input-enabled", "true");
        this.hideTalkingWhenMutedNotification(false);
        if (this.videoConference.getLocalParticipant()) {
            this.refreshTrackAvailability(this.videoConference.getLocalParticipantId());
        }
    }
    
    private onLocalAudioInputDisabled(): void {
        this.setVideoConferenceSetting("isAudioInputEnabled", false);
        this.setHtmlElementData(this.$main, "local-audio-input-enabled", "false");
        if (this.videoConference.getLocalParticipant()) {
            this.refreshTrackAvailability(this.videoConference.getLocalParticipantId());
        }
    }
    
    private onLocalVideoInputEnabled(): void {
        this.setVideoConferenceSetting("isVideoInputEnabled", true);
        this.updateAvailableResolutions();
        this.setHtmlElementData(this.$main, "local-video-input-enabled", "true");
        if (this.videoConference.getLocalParticipant()) {
            const localParticipantId = this.videoConference.getLocalParticipant()?.id;
            if (localParticipantId) {
                this.refreshTrackAvailability(localParticipantId);
                const track = this.videoConference.getVideoTrack(localParticipantId);
                if (track && !this.videoConference.isLocalParticipantSharingDesktop()) {
                    this.onRemoteVideoTrackCreated(localParticipantId);
                }
            }
        }
    }
    
    private onLocalVideoInputDisabled(): void {
        this.setVideoConferenceSetting("isVideoInputEnabled", false);
        this.updateAvailableResolutions();
        this.setHtmlElementData(this.$main, "local-video-input-enabled", "false");
        const localParticipant = this.videoConference.getLocalParticipant();
        if (localParticipant) {
            this.refreshTrackAvailability(localParticipant.id);
        }
    }
    
    private onTrackMutedStatusChanged(track: core.VideoConferenceTrack): void {
        const participantId = track.getParticipantId();
        if (participantId) {
            this.refreshTrackAvailability(participantId);
        }
    }
    
    private onTrackAudioLevelChanged(participantId: string, audioLevel: number): void {
        if (this.videoConference.getLocalParticipant()?.id == participantId) {
            this.updateLocalParticipantAudioLevel(audioLevel);
        }
        else {
            this.updateRemoteParticipantAudioLevel(participantId, audioLevel);
        }
    }
    
    private updateLocalParticipantAudioLevel(audioLevel: number): void {
        const isTalking = audioLevel > core.VideoConference.PARTICIPANT_TALKING_AUDIO_LEVEL_THRESHOLD;
        if (isTalking && !this.videoConference.getIsLocalAudioInputEnabled()) {
            this.showTalkingWhenMutedNotification();
        }
        else {
            this.hideTalkingWhenMutedNotification();
        }
        if (this.videoConference.getIsLocalAudioInputEnabled()) {
            const localParticipant = this.videoConference.getLocalParticipant();
            const userContainer = localParticipant ? this.getUserContainer(localParticipant.id) : null;
            const audioElement = localParticipant ? this.audioByParticipantId[localParticipant.id] : null;
            if (userContainer && audioElement && localParticipant) {
                this.updateParticipantAudioLevel(localParticipant.id, !isTalking, audioElement, userContainer);
            }
        }
    }
    
    private onParticipantConnectionStatsUpdated(participantId: string, stats: JitsiMeetJS.ConferenceStats | null): void {
        this.providers.onParticipantConnectionStatsUpdated(participantId, stats);
    }
    
    
    
    
    
    /*****************************************
    ***************** Tracks *****************
    *****************************************/
    private disableDesktopSharing(): void {
        this.videoConference.disableSharingDesktop();
    }
    
    private enableDesktopSharing(): void {
        this.videoConference.enableSharingDesktop();
    }
    
    private enableLocalAudioOutput(): void {
        this.videoConference.enableLocalAudioOutput();
    }
    
    private disableLocalAudioOutput(): void {
        this.videoConference.disableLocalAudioOutput();
    }
    
    private enableLocalAudioInput(): void {
        this.videoConference.enableLocalAudioInput();
    }
    
    private disableLocalAudioInput(): void {
        this.videoConference.disableLocalAudioInput();
    }
    
    private enableLocalVideoInput(): void {
        this.videoConference.enableLocalVideoInput();
    }
    
    private disableLocalVideoInput(): void {
        this.videoConference.disableLocalVideoInput();
    }
    
    
    
    
    
    /*****************************************
    *********** HTML event handlers **********
    *****************************************/
    private onDisconnectButtonClick(): void {
        this.manager.disconnect();
    }
    
    private onDisableDesktopSharingButtonClick(): void {
        this.disableDesktopSharing();
    }
    
    private onEnableDesktopSharingButtonClick(): void {
        this.enableDesktopSharing();
    }
    
    private onEnableLocalAudioOutputButtonClick(): void {
        this.enableLocalAudioOutput();
    }
    
    private onDisableLocalAudioOutputButtonClick(): void {
        this.disableLocalAudioOutput();
    }
    
    private onEnableLocalAudioInputButtonClick(): void {
        this.enableLocalAudioInput();
    }
    
    private onDisableLocalAudioInputButtonClick(): void {
        this.disableLocalAudioInput();
    }
    
    private onEnableLocalVideoInputButtonClick(): void {
        this.enableLocalVideoInput();
    }
    
    private onDisableLocalVideoInputButtonClick(): void {
        this.disableLocalVideoInput();
    }
    
    private async onToggleExtraSettingsButtonClick(): Promise<void> {
        if (this.isDeviceSelectorOpen) {
            return;
        }
        try {
            this.isDeviceSelectorOpen = true;
            const mediaDevices = await this.providers.getMediaDevices({
                videoInput: true,
                audioInput: true,
                audioOutput: true,
            }, true);
            if (!mediaDevices.rawResult || !mediaDevices.rawResult.selected) {
                this.isDeviceSelectorOpen = false;
                return;
            }
            if (mediaDevices.videoInput) {
                this.videoConference.setVideoInputDeviceId(mediaDevices.videoInput);
            }
            if (mediaDevices.audioInput) {
                this.videoConference.setAudioInputDeviceId(mediaDevices.audioInput);
            }
            if (mediaDevices.audioOutput) {
                this.videoConference.setAudioOutputDeviceId(mediaDevices.audioOutput);
            }
        }
        finally {
            this.isDeviceSelectorOpen = false;
        }
    }
    
    private onSwitchModeToTilesButtonClick(): void {
        this.switchModeToTiles();
    }
    
    private onSwitchModeToSingleSpeakerButtonClick(): void {
        this.switchModeToSingleSpeaker();
    }
    
    private onShowLocalParticipantClick(): void {
        this.toggleShowLocalParticipant(true);
    }
    
    private onHideLocalParticipantClick(): void {
        this.toggleShowLocalParticipant(false);
    }
    
    private onChangeAudioOutputSelectChange(): void {
        const deviceId = this.$changeAudioOutputSelect.value;
        this.videoConference.setAudioOutputDeviceId(deviceId);
    }
    
    private onChangeAudioInputSelectChange(): void {
        const deviceId = this.$changeAudioInputSelect.value;
        this.videoConference.setAudioInputDeviceId(deviceId);
    }
    
    private onChangeVideoInputSelectChange(): void {
        const deviceId = this.$changeVideoInputSelect.value;
        this.videoConference.setVideoInputDeviceId(deviceId);
    }
    
    private async onGongButtonClick(): Promise<void> {
        const message = await this.askForGongMessage();
        if (message === false) {
            return;
        }
        this.$gongButton.classList.add("ringing");
        if (this.gongButtonStopAnimationTimeout !== null) {
            clearTimeout(this.gongButtonStopAnimationTimeout);
            this.gongButtonStopAnimationTimeout = null;
        }
        this.gongButtonStopAnimationTimeout = window.setTimeout(() => {
            this.$gongButton.classList.remove("ringing");
            this.gongButtonStopAnimationTimeout = null;
        }, 2500);
        this.manager.gong(message);
    }
    
    private async askForGongMessage(): Promise<string|false> {
        return this.providers.getGongMessage(this.$gongButton);
    }
    
    private onUserContainerClick($userContainer: HTMLDivElement): void {
        const participantId = $userContainer.dataset["participant-id"];
        if (this.containersDisplayMode == "tiles") {
            this.switchModeToSingleSpeaker(participantId);
        }
        else {
            this.switchModeToTiles();
        }
    }
    
    
    
    
    
    /*****************************************
    ****************** Users *****************
    *****************************************/
    refreshPeopleNames(): void {
        const participantIds = Object.keys(this.videoConference.getParticipants());
        participantIds.push(this.videoConference.getLocalParticipantId());
        for (const participantId of participantIds) {
            const userContainer = this.getUserContainer(participantId);
            if (userContainer) {
                const nameContainer = userContainer.querySelector<HTMLSpanElement>(".user-name .participant-name");
                if (nameContainer) {
                    nameContainer.textContent = this.getParticipantName(participantId);
                }
            }
        }
    }
    
    private getParticipantName(participantId: string, escapeHtml: boolean = true): string {
        let participantName = this.providers.getPersonName(this.getParticipantHashmail(participantId))
        if (!participantName) {
            return "";
        }
        if (escapeHtml) {
            participantName = this.providers.escapeHtml(participantName);
        }
        return participantName;
    }
    
    private getParticipantHashmail(participantId: string, escapeHtml: boolean = true): string {
        const participant = this.videoConference.getParticipant(participantId);
        let participantHashmail = participant ? participant.hashmail : "";
        if (escapeHtml) {
            participantHashmail = this.providers.escapeHtml(participantHashmail);
        }
        return participantHashmail;
    }
    
    private createUserContainer(participantId: string, isLocal?: boolean): HTMLDivElement {
        const localParticipantId = this.videoConference.getLocalParticipant()?.id;
        const isLocalParticipant = participantId == localParticipantId;
        const participantHashmail = this.getParticipantHashmail(participantId);
        const participantName: string = this.getParticipantName(participantId);
        const dominantSpeaker = this.videoConference.getDominantSpeaker();
        const isDominantSpeaker = dominantSpeaker && dominantSpeaker.id  == participantId;
        
        this.removeUserContainer(participantId);
        if (participantHashmail) {
            const stalledParticipantIds = this.getParticipantIdsByHashmail(participantHashmail);
            for (const stalledParticipantId of stalledParticipantIds) {
                this.removeUserContainer(stalledParticipantId);
            }
        }
        
        const localParticipantClass: string = isLocalParticipant ? "user-container--local-participant" : "user-container--remote-participant";
        const dominantSpeakerClass: string = isDominantSpeaker ? "dominant-speaker" : "";
        const $userContainer: HTMLDivElement = this.userContainerTemplate.render({
            participantId,
            participantHashmail,
            participantName,
            localParticipantClass,
            dominantSpeakerClass,
        });
        this.userContainerByParticipantId[participantId] = $userContainer;
        this.videoByParticipantId[participantId] = $userContainer.querySelector<HTMLVideoElement>("video")!;
        this.$remoteVideosContainer.append($userContainer);
        if (isLocal) {
            this.videoByParticipantId[participantId]?.addEventListener("suspend", () => {
                if (this.videoConference.isLocalParticipantSharingDesktop()) {
                    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                    if (!isSafari) {
                        this.disableDesktopSharing();
                    }
                }
            });
        }
        this.refreshAvatars();
        this.refreshTrackAvailability(participantId);
        this.updateRemoteParticipantsCount();
        this.arrangeUserContainers();
        return $userContainer;
    }
    
    private createLocalParticipantUserContainer(): void {
        const localParticipantId = this.videoConference.getLocalParticipant()?.id;
        if (!localParticipantId) {
            return;
        }
        const $userContainer = this.getUserContainer(localParticipantId);
        if ($userContainer) {
            return;
        }
        const $audio = this.audioByParticipantId[localParticipantId];
        this.createUserContainer(localParticipantId, true);
        this.audioByParticipantId[localParticipantId] = $audio!;
        this.onRemoteVideoTrackCreated(localParticipantId);
        this.updateRemoteParticipantsCount();
        this.arrangeUserContainers();
    }
    
    private removeUserContainer(participantId: string): void {
        delete this.userContainerByParticipantId[participantId];
        delete this.audioByParticipantId[participantId];
        delete this.videoByParticipantId[participantId];
        const $container = this.getUserContainer(participantId);
        if ($container) {
            $container.remove();
            this.updateRemoteParticipantsCount();
            this.arrangeUserContainers();
        }
    }
    
    private getParticipantIdsByHashmail(hashmail: string): string[] {
        const $userContainers = this.getUserContainersByHashmail(hashmail);
        return $userContainers.map($userContainer => $userContainer.getAttribute("data-participant-id") ?? "").filter(participantId => participantId !== "")!;
    }
    
    private getUserContainer(participantId: string): HTMLDivElement | null {
        return this.$remoteVideosContainer.querySelector<HTMLDivElement>(`.user-container[data-participant-id='${participantId}']`) ?? null;
    }
    
    private getUserContainersByHashmail(hashmail: string): HTMLDivElement[] {
        const $userContainers = this.$remoteVideosContainer.querySelectorAll<HTMLDivElement>(`.user-container[data-hashmail='${hashmail}']`);
        const userContainers: HTMLDivElement[] = [];
        for (let i = 0; i < $userContainers.length; ++i) {
            const $userContainer = $userContainers[i]!;
            userContainers.push($userContainer);
        }
        return userContainers;
    }
    
    private getOrCreateUserContainer(participantId: string): HTMLDivElement {
        const $userContainer = this.$remoteVideosContainer.querySelector<HTMLDivElement>(`.user-container[data-participant-id='${participantId}']`);
        if ($userContainer) {
            return $userContainer;
        }
        return this.createUserContainer(participantId);
    }
    
    private getLocalUserVideoElement(): HTMLDivElement | null {
        const localParticipantId = this.videoConference.getLocalParticipantId();
        return this.$remoteVideosContainer.querySelector<HTMLDivElement>(`.user-container[data-participant-id='${localParticipantId}'] video`) ?? null;
    }
    
    private hideLocalVideo(): void {
        const $video = this.getLocalUserVideoElement();
        if ($video) {
            $video.style.opacity = "0";
        }
    }
    
    private showLocalVideo(): void {
        const $video = this.getLocalUserVideoElement();
        if ($video) {
            $video.style.opacity = "1";
        }
    }
    
    private refreshTrackAvailability(participantId: string): void {
        const $video = this.$videosContainer.querySelector(`video[data-participant-id="${participantId}"]`);
        const $userContainer = $video?.closest(".user-container");
        const participant = this.videoConference.getParticipant(participantId);
        const isLocal = this.videoConference.getLocalParticipant() == participant;
        const isLocalAndDesktopSharing = isLocal ? this.videoConference.isLocalParticipantSharingDesktop() : false;
        const videoTrack = isLocalAndDesktopSharing ? this.videoConference.getLocalDesktopTrack() : this.videoConference.getVideoTrack(participantId);
        $userContainer?.classList.toggle("no-video", !videoTrack || videoTrack.isMuted() || !participant || (isLocal ? (!this.videoConference.getIsLocalVideoInputEnabled() && !isLocalAndDesktopSharing) : participant._participant._tracks.indexOf(videoTrack) < 0));
        window.setTimeout(() => {
            const audioTrack = this.videoConference.getAudioTrack(participantId);
            $userContainer?.classList.toggle("no-audio", !audioTrack || !participant || audioTrack?.isMuted());
        }, 0);
    }
    
    private refreshAvatars(): void {
        this.providers.renderAvatars();
    }
    
    private updateRemoteParticipantAudioLevel(participantId: string, audioLevel: number): void {
        const audioElement = this.audioByParticipantId[participantId];
        const userContainer = this.userContainerByParticipantId[participantId];
        if (audioElement && userContainer) {
            const newIsMuted = audioLevel <= core.VideoConference.PARTICIPANT_TALKING_AUDIO_LEVEL_THRESHOLD;
            if (!newIsMuted) {
                this.stopParticipantNotTalkingTimeout(participantId);
            }
            this.updateParticipantAudioLevel(participantId, newIsMuted, audioElement, userContainer);
        }
    }
    
    private updateParticipantAudioLevel(participantId: string, newIsMuted: boolean, audioElement: HTMLAudioElement, userContainer: HTMLDivElement): void {
        const prevIsMuted = audioElement.dataset["isMuted"] == "true";
        if (prevIsMuted != newIsMuted) {
            if (newIsMuted) {
                if (!(participantId in this.participantNotTalkingTimeoutByParticipantId)) {
                    this.onParticipantStopTalking(participantId, audioElement, userContainer);
                }
            }
            else {
                this.onParticipantStartTalking(participantId, audioElement, userContainer);
            }
        }
    }
    
    private onParticipantStartTalking(participantId: string, audio: HTMLAudioElement, userContainer: HTMLDivElement): void {
        if (participantId != this.videoConference.getLocalParticipantId()) {
            audio.muted = !this.videoConference.isParticipantAudible(participantId);
        }
        audio.dataset["isMuted"] = "false";
        userContainer.classList.add("is-talking");
    }
    
    private onParticipantStopTalking(participantId: string, audio: HTMLAudioElement, userContainer: HTMLDivElement): void {
        this.stopParticipantNotTalkingTimeout(participantId);
        this.participantNotTalkingTimeoutByParticipantId[participantId] = window.setTimeout(() => {
            delete this.participantNotTalkingTimeoutByParticipantId[participantId];
            this.onParticipantStopTalkingTimedOut(participantId, audio, userContainer);
        }, core.VideoConference.PARTICIPANT_NOT_TALKING_DELAY);
    }
    
    private onParticipantStopTalkingTimedOut(participantId: string, audio: HTMLAudioElement, userContainer: HTMLDivElement): void {
        if (participantId != this.videoConference.getLocalParticipantId()) {
            audio.muted = !this.videoConference.isParticipantAudible(audio.dataset["participantId"]!);
        }
        audio.dataset["isMuted"] = "true";
        userContainer.classList.remove("is-talking");
    }
    
    private stopParticipantNotTalkingTimeout(participantId: string): void {
        if (participantId in this.participantNotTalkingTimeoutByParticipantId) {
            clearTimeout(this.participantNotTalkingTimeoutByParticipantId[participantId]);
            delete this.participantNotTalkingTimeoutByParticipantId[participantId];
        }
    }
    
    private showTalkingWhenMutedNotification(): void {
        const now = new Date().getTime();
        this.lastShowTalkingWhenMutedNotificationCallTime = now;
        if (this.showTalkingWhenMutedNotificationTimeout !== null) {
            return;
        }
        this.showTalkingWhenMutedNotificationTimeout = window.setTimeout(() => {
            this.showTalkingWhenMutedNotificationTimeout = null;
            this.showTalkingWhenMutedNotificationTimedOut();
        }, 300);
    }
    
    private hideTalkingWhenMutedNotification(delayed: boolean = true): void {
        const now = new Date().getTime();
        const lastShowTalkingWhenMutedNotificationCallTime = this.lastShowTalkingWhenMutedNotificationCallTime ?? 0;
        if (delayed && (now - lastShowTalkingWhenMutedNotificationCallTime <= 750)) {
            return;
        }
        if (this.showTalkingWhenMutedNotificationTimeout !== null) {
            clearTimeout(this.showTalkingWhenMutedNotificationTimeout);
            this.showTalkingWhenMutedNotificationTimeout = null;
        }
        this.hideTalkingWhenMutedNotificationTimedOut();
    }
    
    private showTalkingWhenMutedNotificationTimedOut(): void {
        if (this.isTalkingWhenMutedNotificationVisible) {
            return;
        }
        this.isTalkingWhenMutedNotificationVisible = true;
        this.providers.showTalkingWhenMutedNotification();
    }
    
    private hideTalkingWhenMutedNotificationTimedOut(): void {
        if (!this.isTalkingWhenMutedNotificationVisible) {
            return;
        }
        this.isTalkingWhenMutedNotificationVisible = false;
        this.providers.hideTalkingWhenMutedNotification();
    }
    
    private arrangeUserContainers(): void {
        const spacing = 8;
        const columnSpacing = spacing;
        const rowSpacing = spacing;
        const availableWidth = this.$remoteVideosContainer.clientWidth;
        const availableHeight = this.$remoteVideosContainer.clientHeight;
        let userContainersSelector = ".user-container[data-participant-id]";
        if (this.model && this.model.videoConferenceSettings && !this.model.videoConferenceSettings.showLocalParticipant) {
            userContainersSelector += ":not(.user-container--local-participant)";
        }
        const $userContainers = this.$remoteVideosContainer.querySelectorAll(userContainersSelector) as NodeListOf<HTMLElement>;
        const nPeople = $userContainers.length;
        if (nPeople == 0) {
            return;
        }
        const { personWidth, personHeight } = VideoConferenceLayoutCalculator.calculate(nPeople, availableWidth, availableHeight, columnSpacing, rowSpacing);
        $userContainers.forEach($userContainer => {
            $userContainer.style.width = `${personWidth}px`;
            $userContainer.style.height = `${personHeight}px`;
        })
    }
    
    private switchModeToTiles(): void {
        this.setVideoConferenceSetting("containersDisplayMode", "tiles");
        this.singleSpeakerModeForcedParticipantId = null;
        this.containersDisplayMode = "tiles";
        this.setHtmlElementData(this.$main, "user-containers-display-mode", "tiles");
    }
    
    private switchModeToSingleSpeaker(singleSpeakerModeForcedParticipantId: string | null = null): void {
        this.setVideoConferenceSetting("containersDisplayMode", "single-speaker");
        this.singleSpeakerModeForcedParticipantId = singleSpeakerModeForcedParticipantId;
        this.containersDisplayMode = "single-speaker";
        this.setHtmlElementData(this.$main, "user-containers-display-mode", "single-speaker");
        this.onCustomDominantSpeakerChanged();
    }
    
    private toggleShowLocalParticipant(showLocalParticipant: boolean): void {
        if (this.model && this.model.videoConferenceSettings) {
            this.model.videoConferenceSettings.showLocalParticipant = showLocalParticipant;
        }
        if (!showLocalParticipant && this.singleSpeakerModeForcedParticipantId === this.videoConference.getLocalParticipantId()) {
            this.switchModeToSingleSpeaker();
        }
        this.setHtmlElementData(this.$main, "show-local-participant", showLocalParticipant ? "true" : "false");
        this.setVideoConferenceSetting("showLocalParticipant", showLocalParticipant);
        this.arrangeUserContainers();
    }
    
    private updateRemoteParticipantsCount(): void {
        const totalUserContainersCount = this.$remoteVideosContainer.childElementCount;
        const remoteUserContainersCount = totalUserContainersCount - 1;
        this.setHtmlElementData(this.$main, "remote-participants-count", remoteUserContainersCount.toString());
    }
    
    
    
    
    
    /*****************************************
    **************** Overlays ****************
    *****************************************/
    showLoadingOverlay(): void {
        this.$main.classList.toggle("with-loading-overlay", true);
    }
    
    hideLoadingOverlay(): void {
        this.$main.classList.toggle("with-loading-overlay", false);
    }
    
    private showMessageOverlay(text: string, type: string): void {
        const $messageText = this.$main.querySelector<HTMLElement>(".message-overlay .message-text");
        if ($messageText) {
            $messageText.innerText = text;
            this.setHtmlElementData($messageText, "type", type);
        }
        this.$main.classList.toggle("with-message-overlay", true);
    }
    
    private hideMessageOverlay(): void {
        this.$main.classList.toggle("with-message-overlay", false);
    }
    
    
    
    
    
    /*****************************************
    ************* Device chooser *************
    *****************************************/
    private async chooseDevices(): Promise<boolean> {
        const mediaDevices = await this.providers.getMediaDevices({
            videoInput: true,
            audioInput: true,
            audioOutput: true,
        }, false);
        const audioOutput: string | false | undefined = mediaDevices.rawResult && mediaDevices.rawResult.audioOutput === false ? false : mediaDevices.audioOutput;
        const audioInput: string | false | undefined = mediaDevices.rawResult && mediaDevices.rawResult.audioInput === false ? false : mediaDevices.audioInput;
        const videoInput: string | false | undefined = mediaDevices.rawResult && mediaDevices.rawResult.videoInput === false ? false : mediaDevices.videoInput;
        this.videoConference.configureInitialDevices(audioOutput, audioInput, videoInput);
        if (audioOutput === false || !this.videoConference.getIsLocalAudioOutputEnabled()) {
            this.onLocalAudioOutputDisabled();
        }
        else {
            this.onLocalAudioOutputEnabled();
        }
        if (audioInput === false || !this.videoConference.getIsLocalAudioInputEnabled()) {
            this.onLocalAudioInputDisabled();
        }
        else {
            this.onLocalAudioInputEnabled();
        }
        if (videoInput === false || !this.videoConference.getIsLocalVideoInputEnabled()) {
            this.onLocalVideoInputDisabled();
        }
        else {
            this.onLocalVideoInputEnabled();
        }
        return true;
    }
    
    
    
    
    
    /*****************************************
    **************** Messages ****************
    *****************************************/
    private showMessage(i18nKey: string, type: core.MessageType): void {
        const message = this.i18n(i18nKey);
        this.showMessageOverlay(message, type);
    }
    
    private i18n(key: string, ...args: any[]): string {
        return this.providers.i18n(key, args);
    }
    
    
    
    
    
    /*****************************************
    ****************** Misc ******************
    *****************************************/
    private setHtmlElementData($element: HTMLElement, dataKey: string, dataValue: string): void {
        $element.setAttribute(`data-${dataKey}`, dataValue);
    }
    
    private fillDevicesHtmlSelect($element: HTMLSelectElement, devices: MediaDeviceInfo[], selectedDeviceId?: string, withDisabledOption: boolean = false): void {
        let html: string = "";
        if (!selectedDeviceId) {
            selectedDeviceId = devices[0] ? devices[0].deviceId : undefined;
        }
        if (withDisabledOption) {
            selectedDeviceId = devices.length > 0 ? devices[0]!.deviceId : "disabled";
        }
        if (withDisabledOption) {
            html += `<option value="disabled" selected>${this.i18n(core.I18N_KEYS.deviceSelect_disabled)}</option>`;
        }
        for (const device of devices) {
            const isSelected = selectedDeviceId == device.deviceId;
            html += `<option value="${device.deviceId}" ${isSelected ? "selected" : ""}>${device.label}</option>`;
        }
        $element.innerHTML = html;
    }
    
    private onContainerSizeChanged(): void {
        this.updateControlsContainerMiniState();
        this.arrangeUserContainers();
    }
    
    private initJitsiMeetScreenObtainer(): void {
        if ((<any>window).JitsiMeetScreenObtainer) {
            return;
        }
        (<any>window).JitsiMeetScreenObtainer = {
            openDesktopPicker: this.openDesktopPicker.bind(this),
        };
    }
    
    private toggleCurtain(isVisible: boolean): void {
        this.$curtain.classList.toggle("visible", isVisible);
    }
    
    private showCurtain(): void {
        this.toggleCurtain(true);
    }
    
    private hideCurtain(): void {
        this.toggleCurtain(false);
    }
    
    updateControlsContainerMiniState(): void {
        const isMini = this.$container.clientWidth < 600;
        const isWithoutAdvancedControls = this.$container.clientWidth < 400;
        this.$titleContainer.classList.toggle("mini", isMini);
        this.$controlsContainer.classList.toggle("mini", isMini);
        this.$controlsContainer.classList.toggle("mini--without-advanced-controls", isWithoutAdvancedControls);
    }
    
    supportsScriptVersion(version: string): boolean {
        return this.videoConference.supportsScriptVersion(version);
    }
    
    
    
    
    
    /*****************************************
    ************* Desktop picker *************
    *****************************************/
    // @ts-ignore
    private async openDesktopPicker(options: core.utils.ScreenObtainerOptions, callback: core.utils.ScreenObtainerCallback): Promise<void> {
        try {
            this.showCurtain();
            const result: core.utils.ScreenObtainerResult = await this.providers.getScreenObtainerResult();
            if (result) {
                callback(result.sourceId, result.sourceType, result.screenShareAudio);
            }
            else {
                this.disableDesktopSharing();
            }
        }
        catch (e) {
            console.error("Error while calling callback:", e);
            this.disableDesktopSharing();
        }
        finally {
            this.hideCurtain();
        }
    }
    
    
    
    
    
    /*****************************************
    ********** Camera configuration **********
    *****************************************/
    private async updateAvailableResolutions(resetCameraConfig: boolean = false): Promise<void> {
        if (resetCameraConfig) {
            await this.videoConference.clearCameraConfiguration();
        }
        const resolutions: core.VideoResolution[] = await this.getAvailableResolutions();
        this.updateResolutionsNativeSelect(resolutions);
        
        this.providers.onAvailableResolutionsChanged(resolutions);
    }
    
    private updateResolutionsNativeSelect(resolutions: core.VideoResolution[]): void {
        const horizontalResolutions = resolutions.filter(resolution => resolution.width >= resolution.height);
        const verticalResolutions = resolutions.filter(resolution => resolution.width < resolution.height);
        
        const $horizontalGroup = this.createResolutionsOptgroup(core.I18N_KEYS.resolutionsGroup_horizontal, horizontalResolutions);
        const $verticalGroup = this.createResolutionsOptgroup(core.I18N_KEYS.resolutionsGroup_vertical, verticalResolutions);
        
        this.$resolutionNativeSelect.innerHTML = "";
        this.$resolutionNativeSelect.appendChild($horizontalGroup);
        this.$resolutionNativeSelect.appendChild($verticalGroup);
    }
    
    private createResolutionsOptgroup(i18nLabelKey: string, resolutions: core.VideoResolution[]): HTMLOptGroupElement {
        const $optgroup = document.createElement("optgroup");
        $optgroup.label = this.i18n(i18nLabelKey);
        for (const resolution of resolutions) {
            const $option = document.createElement("option");
            $option.value = `${resolution.width}x${resolution.height}`;
            $option.innerText = `${resolution.width} x ${resolution.height}`;
            $optgroup.appendChild($option);
        }
        return $optgroup;
    }
    
    async setResolutionFromString(resolutionStr: string): Promise<void> {
        if (!resolutionStr) {
            return;
        }
        const [width, height] = resolutionStr.split("x").map(x => parseInt(x)) as [number, number];
        await this.setResolution({ width, height });
    }
    
    private getAvailableResolutions(): Promise<core.VideoResolution[]> {
        return this.videoConference.getAvailableResolutions();
    }
    
    private async setResolution(resolution: core.VideoResolution): Promise<void> {
        this.setVideoConferenceSetting("videoResolution", { width: resolution.width, height: resolution.height });
        await this.videoConference.setResolution(resolution);
    }
    
    
    
    
    
    /*****************************************
    ******** Video conference settings *******
    *****************************************/
    private setVideoConferenceSetting(settingName: keyof core.VideoConferenceSettings, value: any): void {
        this.providers.settingChangedCallback(settingName, value);
    }
    
    
    
    
    
    /*****************************************
    ************** Main template *************
    *****************************************/
    private renderMainTemplate(): HTMLDivElement {
        return this.mainTemplate.render({
            isE2EEEnabled: this.videoConference?.isE2EEEnabled(),
            title: this.model?.roomMetadata?.title,
            hideWelcomeMessage: this.hideWelcomeMessage,
            isResolutionSwitchEnabled: CameraConfiguration.IS_RESOLUTION_SWITCH_ENABLED,
        });
    }
    
    private updateMainTemplate(): void {
        const $newTemplate = this.renderMainTemplate();
        
        const $oldTitleContainer = this.$container.querySelector(".title-container");
        const $newTitleContainer = $newTemplate.querySelector(".title-container");
        $oldTitleContainer!.innerHTML = $newTitleContainer!.innerHTML;
        
        if (this.model && this.model.roomMetadata) {
            const dominantSpeakerMode = this.model.roomMetadata.experimentalDominantSpeaker ? "native" : "custom";
            this.setHtmlElementData(this.$main, "dominant-speaker-mode", dominantSpeakerMode);
        }
    }
    
}
