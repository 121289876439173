import { Modal } from '@mantine/core';
import { PasswordChangeForm } from '../../mantineAtoms/PasswordChangeInput';
import { BaseModalData } from '../../types/Modals';
import { PasswordChangePromptData } from './index';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export interface PasswordChangePromptModalData extends BaseModalData {
    options: PasswordChangePromptData;
}

export function PasswordChangePromptModal(props: {
    onClose: () => void;
    data: PasswordChangePromptModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const onCloseFromProps = props.onClose;
    const cancelCallback = props.data.options.cancelCallback;

    const onClose = useCallback(() => {
        onCloseFromProps();
        if (cancelCallback) {
            cancelCallback();
        }
    }, [onCloseFromProps, cancelCallback]);
    return (
        <Modal
            onFocus={props.onFocus}
            onClose={onClose}
            opened
            centered
            closeOnClickOutside={false}
            overlayProps={{ opacity: 0.05 }}
            title={props.data.title ?? t('modal.passwordChange.title')}>
            <PasswordChangeForm
                passwordChangePrompt={props.data.options}
                handleClose={props.onClose}
            />
        </Modal>
    );
}
