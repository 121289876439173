import { Image, Loader } from '@mantine/core';
import CONTACT_LOGO from '../../assets/contact_logo.svg';

export default function HostVerifier(props: { verificationResult: string }) {
    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: '#fff',
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <div>
                <div style={{ marginBottom: 30 }}>
                    <Image width={360} alt="logo" src={CONTACT_LOGO} />
                </div>
                <div>
                    {props.verificationResult ? (
                        <h3>{props.verificationResult}</h3>
                    ) : (
                        <Loader color="#936fad" />
                    )}
                </div>
            </div>
        </div>
    );
}
