import { Button, Group } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface PageHeaderProps extends React.PropsWithChildren {
    onRefresh: () => void;
    goToBtn: boolean;
    onGoTo?: (e: any) => void;
    onEditClick?: (e: any) => void;
}

function PageHeader(props: PageHeaderProps) {
    const { t } = useTranslation();
    return (
        <Group>
            {props.children}
            {props.onEditClick && (
                <Button variant="light" onClick={props.onEditClick}>
                    {t('action.edit')}
                </Button>
            )}
            {props.goToBtn && (
                <Button variant="light" onClick={props.onGoTo}>
                    {t('action.open')}
                </Button>
            )}
        </Group>
    );
}

export default PageHeader;
