import { useCallback } from 'react';
import { useNavigate, useOutlet, useParams } from 'react-router-dom';
import { api } from '../../api/Api';
import {
    Button,
    Center,
    Container,
    PasswordInput,
    Stack,
    TextInput,
    Title,
    Text,
    Box
} from '@mantine/core';
import { useForm } from 'react-hook-form';
import { PASSWORD_REQUIREMENTS, PasswordStrength } from '../../utils/PasswordStrength';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useLocalStorage } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

function getMessageFromError(e: any) {
    if (e && e.message) {
        return e.message;
    }
    if (e && e.msg) {
        return e.msg;
    }
    return '<unknown>';
}

interface FormData {
    email: string;
    password: string;
    password2: string;
    root: string;
}

export default function ActivateFirstAccountScreen() {
    const routeParams = useParams();
    const token = routeParams.token;
    const [isDev] = useLocalStorage({ key: 'is-dev' });

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting },
        watch
    } = useForm<FormData>();

    const navigate = useNavigate();
    const { t } = useTranslation();

    const onActivate = useCallback(
        async (data: FormData) => {
            if (!token) {
                return alert(t('screens.activateFirstAccount.token.required'));
            }
            const passwordStrengthResult = PasswordStrength.validate(data.password);
            if (!isDev && passwordStrengthResult.status === 'error') {
                setError('password', { message: passwordStrengthResult.message });
                return;
            }
            if (data.password !== data.password2) {
                setError('password2', {
                    message: t('screens.activateFirstAccount.password.mismatch')
                });
                return;
            }

            try {
                await api.activateFirstAccount(token, data.email, data.password);
                navigate('activated', { relative: 'route' });
            } catch (e) {
                console.error('Error during activate first account', e);
                setError('root', { message: 'Error: ' + getMessageFromError(e) });
            }
        },
        [token, setError, navigate, isDev, t]
    );
    const Outlet = useOutlet();

    if (!token) {
        return <Title>{t('screens.activateFirstAccount.token.missing')}</Title>;
    }

    if (Outlet) {
        return Outlet;
    }

    const newPassword = watch('password');
    const checks = PASSWORD_REQUIREMENTS().map((requirement, index) => (
        <PasswordRequirement
            key={index}
            label={requirement.label}
            meets={requirement.re.test(newPassword || '')}
        />
    ));

    return (
        <Center h="100%" w="100%">
            <Container mx="auto" miw={500} size={'lg'}>
                <>
                    <Title order={1} align="center" mb="xl">
                        {t('screens.activateFirstAccount.title')}
                    </Title>
                    <form onSubmit={handleSubmit(onActivate)}>
                        <Stack mb="lg">
                            <TextInput
                                label={t('screens.activateFirstAccount.email.label')}
                                placeholder={t('screens.activateFirstAccount.email.placeholder')}
                                {...register('email', {
                                    required: t('screens.activateFirstAccount.email.required')
                                })}
                                error={errors.email ? errors.email.message : undefined}
                                disabled={isSubmitting}
                            />
                            <PasswordInput
                                placeholder={t('screens.activateFirstAccount.password.placeholder')}
                                label={t('screens.activateFirstAccount.password.label')}
                                {...register('password', {
                                    required: t('screens.activateFirstAccount.password.required')
                                })}
                                error={errors.password ? !!errors.password : undefined}
                                disabled={isSubmitting}
                            />
                            <PasswordInput
                                placeholder={t(
                                    'screens.activateFirstAccount.password.repeatPlaceholder'
                                )}
                                label={t('screens.activateFirstAccount.password.repeatLabel')}
                                {...register('password2', {
                                    required: t(
                                        'screens.activateFirstAccount.password.repeatRequired'
                                    )
                                })}
                                error={errors.password2 ? errors.password2.message : undefined}
                                disabled={isSubmitting}
                            />
                            {errors.root && (
                                <Text align="center" color="red">
                                    {errors.root.message}
                                </Text>
                            )}
                        </Stack>
                        <Button type="submit" display="block" w="100%" loading={isSubmitting}>
                            {isSubmitting
                                ? t('screens.activateFirstAccount.submitButton.isSubmitingTrue')
                                : t('screens.activateFirstAccount.submitButton.isSubmitingFalse')}
                        </Button>
                        <Box
                            mt="lg"
                            opacity={newPassword ? 1 : 0}
                            style={{ pointerEvents: 'none' }}>
                            {checks}
                        </Box>
                    </form>
                </>
            </Container>
        </Center>
    );
}

function PasswordRequirement({ meets, label }: { meets: boolean; label: string }) {
    return (
        <Text color={meets ? 'teal' : 'red'} mt={5} size="sm">
            <Center inline>
                {meets ? (
                    <IconCheck size="0.9rem" stroke={1.5} />
                ) : (
                    <IconX size="0.9rem" stroke={1.5} />
                )}
                <Box ml={7}>{label}</Box>
            </Center>
        </Text>
    );
}
