import * as privfs from 'privfs-client';
import { SinkApi } from '../SinkApi';
import { AdminKeyHolder } from './AdminKeyHolder';
import * as PmxApi from 'privmx-server-api';

export class AdminKeyChecker {
    constructor(
        private authData: privfs.types.core.UserDataEx,
        private srpSecure: privfs.core.PrivFsSrpSecure,
        private adminKeyHolder: AdminKeyHolder,
        private messageManager: privfs.message.MessageManager,
        private sinkEncryptor: privfs.crypto.utils.ObjectEncryptor
    ) {}

    async checkAdminKey(): Promise<boolean> {
        if (!this.authData.myData.raw.isAdmin) {
            return false;
        }
        if (this.authData.masterRecordLevel2.data.adminKey) {
            this.adminKeyHolder.setAdminKey(
                privfs.crypto.ecc.ExtKey.fromBase58(this.authData.masterRecordLevel2.data.adminKey)
            );
            return true;
        }
        const res = await this.srpSecure.request<boolean>('amIAdminKeyOwner', {});
        if (res) {
            await this.adminKeyHolder.saveAdminKey(privfs.crypto.serviceSync.eccExtRandom());
            return true;
        } else {
            const key = await this.findAdminKeyInMessages();
            await this.adminKeyHolder.saveAdminKey(key);
            return true;
        }
    }

    private async findAdminKeyInMessages() {
        const firstInbox = this.authData.myData.userInfo.profile?.sinks?.[0];
        if (!firstInbox) {
            throw new Error('There is no inbox');
        }
        const sinks = await this.messageManager.sinkGetAllMy(this.sinkEncryptor);
        const privSink = sinks.find((x) => x.id === firstInbox.id);
        if (!privSink) {
            throw new Error('There is no inbox');
        }
        const sinkApi = new SinkApi(this.srpSecure.gateway);
        const info = await sinkApi.sinkInfo({
            sid: privSink.id as PmxApi.api.sink.Sid,
            addMidList: true
        });
        const messages = (await this.messageManager.messageGet(
            privSink,
            info.mids as PmxApi.api.message.Mid[],
            'DATA_AND_META',
            false
        )) as privfs.types.message.MessageEntryParsed[];
        for (const message of messages.reverse()) {
            try {
                if (message.data.source.data.type !== 'admin-msg') {
                    continue;
                }
                const msgFromAdmin = await this.srpSecure.isAdminKey(
                    privfs.crypto.ecc.PublicKey.fromBase58DER(message.data.source.data.sender.pub58)
                );
                if (!msgFromAdmin) {
                    continue;
                }
                const event = JSON.parse(message.data.source.data.text);
                if (event.type === 'grant-admin-key') {
                    return privfs.crypto.ecc.ExtKey.fromBase58(event.extKey);
                }
            } catch (e) {
                console.error('Error during searching for admin key message', e);
            }
        }
        throw new Error('No message with admin key');
    }
}
