export class DataEncoder {
    static encode<T>(obj: T): T {
        if (typeof obj === 'object') {
            if (obj === null) {
                return obj;
            }
            if (Array.isArray(obj)) {
                const res: any[] = [];
                for (const x of obj) {
                    res.push(DataEncoder.encode(x));
                }
                return res as any;
            }
            if (
                obj.constructor.name === 'Uint8Array' ||
                obj.toString() === '[object ArrayBuffer]'
            ) {
                return Buffer.from(obj as any) as any;
            }
            const res: any = {};
            for (const name in obj) {
                res[name] = DataEncoder.encode(obj[name]);
            }
            return res;
        }
        return obj;
    }

    static decode<T>(obj: T): T {
        if (typeof obj === 'object') {
            if (obj === null) {
                return obj;
            }
            if (Array.isArray(obj)) {
                const res: any[] = [];
                for (const x of obj) {
                    res.push(DataEncoder.decode(x));
                }
                return res as any;
            }
            if (typeof (obj as any).toArrayBuffer === 'function') {
                return new Uint8Array((obj as any).toArrayBuffer()) as any;
            }
            const res: any = {};
            for (const name in obj) {
                res[name] = DataEncoder.decode(obj[name]);
            }
            return res;
        }
        return obj;
    }
}
