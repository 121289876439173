import { Checkbox, Radio, Stack, Textarea, TextInput } from '@mantine/core';
import React from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types';
import { Question } from '../../../types/Types';
import { FormValues } from './FormAnswering';

interface Props {
    question: Question;
    questionIndex: number;
    register: UseFormRegister<FormValues[]>;
    errorMessage?: string;
}

export default function AnswerInputFactory({
    question,
    questionIndex,
    register,
    errorMessage
}: Props) {
    if (question.type === 'select') {
        return (
            <>
                <Checkbox.Group>
                    <Stack>
                        {question.answer.map((answer, i) => (
                            <Checkbox
                                key={answer.id}
                                {...register(`${questionIndex}.answers`, {
                                    required: question.required
                                })}
                                error={errorMessage}
                                id={answer.id}
                                value={`${answer.id}`}
                                label={answer.input}
                            />
                        ))}
                    </Stack>
                </Checkbox.Group>
            </>
        );
    } else if (question.type === 'single') {
        return (
            <>
                <Radio.Group>
                    <Stack>
                        {question.answer.map((answer, i) => (
                            <Radio
                                key={answer.id}
                                type="radio"
                                {...register(`${questionIndex}.answers.0`, {
                                    required: question.required
                                })}
                                error={errorMessage}
                                value={answer.id}
                                label={answer.input}
                            />
                        ))}
                    </Stack>
                </Radio.Group>
            </>
        );
    } else if (question.type === 'short') {
        return (
            <TextInput
                size="xs"
                {...register(`${questionIndex}.answers`, { required: question.required })}
                error={errorMessage}
                // required={question.required}
            />
        );
    } else if (question.type === 'long') {
        return (
            <Textarea
                autosize
                minRows={2}
                maxRows={4}
                {...register(`${questionIndex}.answers`)}
                error={errorMessage}
                required={question.required}
            />
        );
    } else {
        return <></>;
    }
}
