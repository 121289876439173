import { Paper, Box, Skeleton, Flex, Divider, MediaQuery } from '@mantine/core';
import { useCallback, useEffect, useRef } from 'react';
import RouteListContainer from '../../atoms/RouteScreen/RouteListContainer';
import StateView from '../../atoms/State';
import UserAvatar from '../../atoms/UserAvatar';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import ScreenScrollContainer from '../../mantineAtoms/ScreenScrollContainer';
import { useAppDispatch } from '../../store';
import { setCompanies, setContacts } from '../../store/DataCacheSlice';
import ContactsScreenView from './ContactsScreenView';
import { api } from '../../api/Api';
import { ContactScreenInitial } from '.';

export function ContactSceenLoader() {
    return (
        <RouteListContainer direction="row">
            <Paper
                sx={{
                    flexGrow: 1,
                    flexDirection: 'column',
                    display: 'flex',
                    borderTopRightRadius: 16,
                    borderBottomRightRadius: 16
                }}>
                <Box px="md" my="md">
                    <Skeleton height={36} />
                </Box>
                <ScreenScrollContainer px="md">
                    {new Array(20).fill('').map((_, i) => (
                        <Paper key={i} w="100%" p="sm">
                            <Flex align="center" justify="flex-start" gap="md" w="100%">
                                <UserAvatar size={24} showTooltip={false} loading />
                                <Skeleton display={'block'} height={21} />
                            </Flex>
                        </Paper>
                    ))}
                </ScreenScrollContainer>
            </Paper>
            <Divider orientation="vertical" h="95%" my="auto" opacity={0.4} />
            <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                <Paper w="40%" miw={700} bg="transparent">
                    <ContactScreenInitial />
                </Paper>
            </MediaQuery>
        </RouteListContainer>
    );
}

export function ContactsScreen() {
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForCompaniesWithContacts(), [])
    );
    const dispatch = useAppDispatch();

    const synced = useRef<boolean>(false);

    useEffect(() => {
        if (state.type === 'done' && synced.current === false) {
            synced.current = true;
            dispatch(setCompanies(state.data.companies));
            dispatch(setContacts(state.data.contacts));
        }
    }, [dispatch, state.type, state]);

    useEffect(() => {
        function listener() {
            revalidate(false);
            synced.current = false;
        }
        api.addEventListener('contactchanged', () => listener);
        api.addEventListener('newcontact', listener);
        return () => {
            api.removeEventListener('contactchanged', listener);
            api.removeEventListener('newcontact', listener);
        };
    }, [revalidate]);

    return (
        <StateView state={state} retry={revalidate} loading={ContactSceenLoader}>
            {(data) => <ContactsScreenView contacts={data.contacts} companies={data.companies} />}
        </StateView>
    );
}
