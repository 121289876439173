interface Speaker {
    id: string;
    isLocal: boolean;
}

type OnUpdateHandler = (dominantSpeakerId: string | null) => void;

export class DominantSpeakerService {
    
    private onUpdateHandlers: OnUpdateHandler[] = [];
    private speakers: { [speakerId: string]: Speaker } = {};
    private dominantSpeakerId: string | null = null;
    
    constructor() {
    }
    
    getDominantSpeakerId(): string | null {
        return this.dominantSpeakerId;
    }
    
    addOnUpdateHandler(handler: OnUpdateHandler): void {
        this.onUpdateHandlers.push(handler);
    }
    
    removeOnUpdateHandler(handler: OnUpdateHandler): void {
        const index = this.onUpdateHandlers.indexOf(handler);
        if (index >= 0) {
            this.onUpdateHandlers.splice(index, 1);
        }
    }
    
    setSpeaker(speaker: Speaker): void {
        this.speakers[speaker.id] = speaker;
    }
    
    removeSpeaker(speakerId: string): void {
        if (speakerId in this.speakers) {
            delete this.speakers[speakerId];
            if (this.dominantSpeakerId === speakerId) {
                this.setDominantSpeaker(null);
            }
        }
    }
    
    setDominantSpeaker(speakerId: string | null): void {
        if (speakerId !== null && !this.speakers[speakerId]) {
            speakerId = null;
        }
        if (this.dominantSpeakerId === speakerId) {
            return;
        }
        this.dominantSpeakerId = speakerId;
        this.callOnUpdateHandlers();
    }
    
    private callOnUpdateHandlers(): void {
        for (const handler of this.onUpdateHandlers) {
            handler(this.dominantSpeakerId);
        }
    }
    
}
