import * as PmxApi from 'privmx-server-api';
import { Container, LoadingOverlay, Paper, TextInput, Text, Button } from '@mantine/core';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { api, userApi } from '../../api/Api';
import { useTranslation } from 'react-i18next';

export default function AnonymousLoginScreen() {
    const { t } = useTranslation();
    const routeParams = useParams();
    const meetingData = useMemo(
        () => api.parseThreadLinkStringData(routeParams.meetingData ?? '')!,
        [routeParams]
    );
    const [isProcessing, setIsProcessing] = useState(false);
    const [nickname, setNickname] = useState('' as PmxApi.api.thread.MeetingNickname);
    const [hasNicknameError, setHasNicknameError] = useState(false);
    const [loginError, setLoginError] = useState<string | null>(null);
    const navigate = useNavigate();

    const validateNickname = useCallback((value: PmxApi.api.thread.MeetingNickname) => {
        const isOk = value !== '';
        setHasNicknameError(!isOk);
        return isOk;
    }, []);

    const handleNicknameChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value.trim() as PmxApi.api.thread.MeetingNickname;
            setNickname(value);
            validateNickname(value);
        },
        [validateNickname]
    );

    const handleJoinClick = useCallback(async () => {
        let isFormOk = true;
        isFormOk = validateNickname(nickname) && isFormOk;
        if (!isFormOk) {
            return;
        }
        setIsProcessing(true);
        try {
            const result = await userApi.enterMeetingLobbyAsAnonymous(
                meetingData.threadId,
                nickname
            );
            if (result === 'already-at-the-meeting') {
                navigate(`/meetingRoom/${routeParams.meetingData}`);
            } else {
                navigate(`/meetingLobby/${routeParams.meetingData}`);
            }
        } catch (error) {
            console.error(error);
            setLoginError(t('errorMessage.incorrectLoginData'));
        } finally {
            setIsProcessing(false);
        }
    }, [nickname, navigate, validateNickname, routeParams.meetingData, meetingData.threadId, t]);

    return (
        <Container w={450} my={40} pos="relative">
            <LoadingOverlay visible={isProcessing} />
            {loginError && (
                <Text align="center" c="red.5">
                    {loginError}
                </Text>
            )}
            <Text align="center" size="lg">
                {meetingData.type === 'threadMeeting'
                    ? t('screen.login.joinMeetingWithTitle', { title: meetingData.title })
                    : t('screen.login.joinConversation')}
            </Text>
            <Paper withBorder shadow="md" p={30} mt={30} radius="sm">
                <TextInput
                    label={t('entityProps.nickname')}
                    placeholder={t('entityProps.nickname')}
                    value={nickname}
                    onChange={handleNicknameChange}
                    error={hasNicknameError}
                    required
                />
                <Button fullWidth mt="xl" onClick={handleJoinClick}>
                    {t('action.join')}
                </Button>
            </Paper>
            <Text align="center" size="md" my={'md'}>
                {t('screen.login.haveAccountQuestion')}
                <br />
                <Link to={`/${routeParams.meetingData}`}>
                    {t('screen.login.joinWithUsernameAndPwd')}
                </Link>
            </Text>
        </Container>
    );
}
