import * as types from '../types/Types';

export class DraftUtils {
    static getThreadDraftPropsForCreating(
        thread: types.ThreadCreateModel,
        message: types.ThreadMessageCreateModel
    ) {
        const res: types.ThreadDraftPropsForCreating = {
            type: 'threadDraft',
            thread: thread,
            message: message
        };
        return res;
    }

    static getThreadMessageDraftPropsForCreating(
        threadId: types.ThreadId,
        message: types.ThreadMessageCreateModel
    ) {
        const res: types.ThreadMessageDraftPropsForCreating = {
            type: 'threadMessageDraft',
            threadId: threadId,
            message: message
        };
        return res;
    }

    static getThreadDraftWithAttachments(
        draftId: types.DraftId,
        thread: types.ThreadCreateModel,
        message: types.ThreadMessageCreateModel,
        attachments: types.DraftAttachmentEx[]
    ) {
        const res: types.ThreadDraftWithAttachments = {
            type: 'threadDraft',
            id: draftId,
            thread: thread,
            message: message,
            attachments: attachments
        };
        return res;
    }

    static getThreadMessageDraftWithAttachments(
        draftId: types.DraftId,
        threadId: types.ThreadId,
        message: types.ThreadMessageCreateModel,
        attachments: types.DraftAttachmentEx[]
    ) {
        const res: types.ThreadMessageDraftWithAttachments = {
            type: 'threadMessageDraft',
            id: draftId,
            threadId: threadId,
            message: message,
            attachments: attachments
        };
        return res;
    }

    static getThreadCreateModel(
        threadType: 'chat' | 'meeting',
        model:
            | types.ChatCreateModel
            | types.ChatEditModel
            | types.MeetingCreateModel
            | types.MeetingEditModel
    ) {
        const res: types.ThreadCreateModel = {
            threadType: threadType,
            title: model.title,
            users: model.users,
            managers: model.managers,
            tags: model.tags,
            duration: 'duration' in model ? model.duration : undefined,
            startDate: 'startDate' in model ? model.startDate : undefined
        };
        return res;
    }

    static getThreadMessageCreateModel(
        threadType: 'chat' | 'meeting',
        model: types.MessageCreateModel
    ) {
        const res: types.ThreadMessageCreateModel = {
            messageThreadType: threadType,
            text: model.text,
            mimetype: model.mimetype
        };
        return res;
    }

    static castDraftAttachmentExArrToAttachmentArr(
        atts: (types.DraftAttachmentEx | types.Attachment)[]
    ) {
        const res: types.Attachment[] = [];
        for (const att of atts) {
            if ('draftId' in att) {
                res.push(this.castDraftAttachmentExToAttachment(att));
            } else {
                res.push(att);
            }
        }
        return res;
    }

    static castDraftAttachmentExToAttachment(drAttEx: types.DraftAttachmentEx) {
        const att: types.Attachment = {
            ...drAttEx,
            chatId: '' as types.ChatId,
            messageId: '' as types.MessageId
        };
        return att;
    }
}
