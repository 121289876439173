import { dbGenerator } from '../../../../../api/Db';
import {
    QuestionModel,
    Answer,
    MultipleAnswerQuestionModel,
    TextBlock
} from '../../../../../types/Types';
import { AnswerableBlock, FormAction, isAnswerType } from '../../types';

const EMPTY_QUESTION: QuestionModel = {
    title: '',
    type: 'short',
    answer: { input: '', type: 'short' },
    id: '',
    required: false
};

function createEmptyQuestion(type: QuestionModel['type']): QuestionModel {
    if (type === 'single' || type === 'select') {
        return {
            ...EMPTY_QUESTION,
            type: type,
            answer: [{ ...EMPTY_MULTIPLEANSWERS(type) }, { ...EMPTY_MULTIPLEANSWERS(type) }]
        };
    } else if (type === 'long' || type === 'short' || type === 'email' || type === 'phone') {
        return {
            ...EMPTY_QUESTION,
            type: type,
            answer: {
                ...EMPTY_SINGLEANSWER(type)
            }
        };
    } else if (type === 'file') {
        return {
            ...EMPTY_QUESTION,
            type: 'file',
            answer: [{ ...EMPTY_FILEANSWER() }]
        } as AnswerableBlock;
    } else {
        return {
            type: 'block',
            content: '',
            id: '',
            required: false,
            title: ''
        };
    }
}

const EMPTY_FILEANSWER: () => Omit<Answer, 'id'> = () => ({
    input: '',
    type: 'file'
});

const EMPTY_SINGLEANSWER: (type: 'short' | 'long' | 'email' | 'phone') => Omit<Answer, 'id'> = (
    type
) => ({
    input: '',
    type
});

const EMPTY_MULTIPLEANSWERS: (type: 'select' | 'single') => Omit<Answer, 'id'> = (type) => ({
    input: '',
    type
});

export function formReducer(state: QuestionModel[], action: FormAction): QuestionModel[] {
    switch (action.type) {
        case 'load_form':
            return JSON.parse(JSON.stringify(action.questions));
        case 'add_question':
            const newState = state.slice();
            if (action.questionType) {
                newState.push({
                    ...createEmptyQuestion(action.questionType),
                    id: dbGenerator.generateId()
                });
            } else {
                newState.push({
                    ...createEmptyQuestion('short'),
                    id: dbGenerator.generateId()
                });
            }
            return newState;
        case 'move_question':
            const movedState = state.slice();
            if (action.direction === 'up') {
                if (action.index === 0) return movedState;
                const movedQuesion = movedState.at(action.index);
                const nextQuestion = movedState.at(action.index - 1);
                if (!movedQuesion || !nextQuestion) return movedState;

                movedState[action.index] = nextQuestion;
                movedState[action.index - 1] = movedQuesion;
                return movedState;
            } else {
                if (action.index === state.length - 1) return movedState;

                const movedQuesion = movedState.at(action.index);
                const nextQuestion = movedState.at(action.index + 1);
                if (!movedQuesion || !nextQuestion) return movedState;

                movedState[action.index] = nextQuestion;
                movedState[action.index + 1] = movedQuesion;
                return movedState;
            }
        case 'change_title':
            const newStateTitle = state.slice();
            newStateTitle[action.index].title = action.newValue;
            return newStateTitle;
        case 'change_content':
            const newStateContent = state.slice();
            if (newStateContent[action.index].type === 'block') {
                const editedBlock = newStateContent[action.index] as TextBlock;
                editedBlock.content = action.newValue;
            }
            return newStateContent;
        case 'change_required':
            const newStateRequired = state.slice();
            newStateRequired[action.index].required = action.newValue;
            return newStateRequired;
        case 'change_questionType':
            const newStateQType = state.slice();
            if (action.newValue === 'block') {
                newStateQType[action.index] = {
                    type: 'block',
                    content: '',
                    id: '',
                    required: false,
                    title: ''
                };
            } else if (isAnswerType(action.newValue)) {
                if (action.newValue === 'select' || action.newValue === 'single') {
                    if (
                        newStateQType[action.index].type === 'select' ||
                        newStateQType[action.index].type === 'single'
                    ) {
                        const editedBlock = newStateQType[action.index] as
                            | MultipleAnswerQuestionModel<'select'>
                            | MultipleAnswerQuestionModel<'single'>;

                        editedBlock.answer = (editedBlock.answer as Omit<Answer, 'id'>[]).map(
                            (answer) => {
                                return { ...answer, type: action.newValue as 'select' | 'single' };
                            }
                        );
                        newStateQType[action.index] = editedBlock;
                    } else if (newStateQType[action.index].type === 'block') {
                        newStateQType[action.index] = {
                            ...EMPTY_QUESTION,
                            type: action.newValue,
                            answer: [
                                { ...EMPTY_MULTIPLEANSWERS(action.newValue) },
                                { ...EMPTY_MULTIPLEANSWERS(action.newValue) }
                            ]
                        };
                    } else {
                        const editedBlock = newStateQType[action.index] as Exclude<
                            QuestionModel,
                            { type: 'block' }
                        >;

                        editedBlock.type = action.newValue;
                        editedBlock.answer = [
                            { ...EMPTY_MULTIPLEANSWERS(action.newValue) },
                            { ...EMPTY_MULTIPLEANSWERS(action.newValue) }
                        ];
                        newStateQType[action.index] = editedBlock;
                    }
                    newStateQType[action.index].type = action.newValue;
                } else if (
                    action.newValue === 'short' ||
                    action.newValue === 'long' ||
                    action.newValue === 'email' ||
                    action.newValue === 'phone'
                ) {
                    newStateQType[action.index].type = action.newValue;
                    if (newStateQType[action.index].type === 'block') {
                        newStateQType[action.index] = {
                            ...EMPTY_QUESTION,
                            type: action.newValue,
                            answer: {
                                ...EMPTY_SINGLEANSWER(action.newValue)
                            }
                        };
                    } else {
                        const editedBlock = newStateQType[action.index] as Exclude<
                            QuestionModel,
                            { type: 'block' }
                        >;

                        editedBlock.answer = {
                            ...EMPTY_SINGLEANSWER(action.newValue)
                        };
                        newStateQType[action.index] = editedBlock;
                    }
                } else if (action.newValue === 'file') {
                    newStateQType[action.index].type = action.newValue;
                    if (newStateQType[action.index].type === 'block') {
                        const newBlock = {
                            ...EMPTY_QUESTION,
                            type: action.newValue,
                            answer: [{ ...EMPTY_FILEANSWER() }]
                        } as AnswerableBlock;

                        newStateQType[action.index] = newBlock;
                    } else {
                        const editedBlock = newStateQType[action.index] as Exclude<
                            QuestionModel,
                            { type: 'block' }
                        >;

                        editedBlock.answer = {
                            ...EMPTY_FILEANSWER()
                        };
                        newStateQType[action.index] = editedBlock;
                    }
                } else {
                    console.error('form answert type not supported', action.newValue);
                }
            }
            return newStateQType;
        case 'add_option':
            const newStateOption = state.slice();
            if (Array.isArray((newStateOption[action.index] as AnswerableBlock).answer)) {
                ((newStateOption[action.index] as AnswerableBlock).answer as any[]).push({
                    input: '',
                    type: newStateOption[action.index].type
                });
            }
            return newStateOption;
        case 'change_option':
            const newStateO = state.slice();
            if (Array.isArray((newStateO[action.index] as AnswerableBlock).answer)) {
                ((newStateO[action.index] as AnswerableBlock).answer as Omit<Answer, 'id'>[])[
                    action.optionIndex
                ].input = action.newValue;
            }
            return newStateO;
        case 'remove_option':
            const newStateR = state.slice();
            if (Array.isArray((newStateR[action.index] as AnswerableBlock).answer)) {
                (
                    (newStateR[action.index] as AnswerableBlock).answer as Omit<Answer, 'id'>[]
                ).splice(action.optionIndex, 1);
            }
            return newStateR;
        case 'remove_question':
            const reducedState = state.slice();
            reducedState.splice(action.index, 1);
            return reducedState;
        default:
            return state;
    }
}
