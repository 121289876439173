import * as types from '../types/Types';
import { t } from 'i18next';

export interface FormTemplate {
    id: string;
    formModel: types.FormModel;
}

export const emptyFormModel: types.FormModel = {
    id: 'new' as types.FormId,
    type: 'inquiry',
    name: '',
    entriesCount: 0,
    questions: [],
    tags: [],
    managers: [],
    users: [],
    lastSubmitDate: 0 as types.Timestamp,
    status: 'published',
    pinned: false,
    archived: false,
    createdBy: '' as types.Username,
    createdDate: 0 as types.Timestamp,
    lastModifiedBy: '' as types.Username,
    lastModifiedDate: 0 as types.Timestamp,
    captchaEnabled: false,
    shortLink: undefined,
    autoResponseData: { emailFieldId: '', enabled: false, message: '', title: '' }
};

export const getFormTemplates = (): FormTemplate[] => {
    return [
        {
            id: 'empty',
            formModel: {
                ...emptyFormModel,
                id: 'new-tpl-empty' as types.FormId,
                name: t('formTemplates.empty.name')
            }
        },
        {
            id: 'newsletter',
            formModel: {
                ...emptyFormModel,
                id: 'new-tpl-newsletter' as types.FormId,
                name: t('formTemplates.newsletter.name'),
                questions: [
                    {
                        title: t('formTemplates.newsletter.fields.name'),
                        type: 'short',
                        answer: {
                            input: '',
                            type: 'short',
                            id: 'difppnelpijwudeudhyb'
                        },
                        id: 'fgsccombqynqamdshcva',
                        required: true
                    },
                    {
                        title: t('formTemplates.newsletter.fields.email'),
                        type: 'short',
                        answer: {
                            input: '',
                            type: 'short',
                            id: 'xglqgidhcjuaeonfvosw'
                        },
                        id: 'gnflutwcxfybfcekhlqx',
                        required: true
                    },
                    {
                        title: t('formTemplates.newsletter.fields.termsAndConditions'),
                        type: 'single',
                        answer: [
                            {
                                input: t('formTemplates.newsletter.fields.termsAndConditionsAgree'),
                                type: 'single',
                                id: 'gjtbcswywcifpyfjwjox'
                            }
                        ],
                        id: 'nbpldxntefyqojuyqdks',
                        required: true
                    }
                ]
            }
        },
        {
            id: 'fileUpload',
            formModel: {
                ...emptyFormModel,
                id: 'new-tpl-fileUpload' as types.FormId,
                name: t('formTemplates.fileUpload.name'),
                questions: [
                    {
                        title: t('formTemplates.fileUpload.fields.name'),
                        type: 'short',
                        answer: {
                            input: '',
                            type: 'short',
                            id: 'fpbgkhuvhybpyehgvmgn'
                        },
                        id: 'tuiclaswmcggcsyxffig',
                        required: true
                    },
                    {
                        title: t('formTemplates.fileUpload.fields.email'),
                        type: 'short',
                        answer: {
                            input: '',
                            type: 'short',
                            id: 'xvngnsuqirybarfyiffn'
                        },
                        id: 'uvstbewxquhowjhnpbwj',
                        required: true
                    },
                    {
                        title: t('formTemplates.fileUpload.fields.file'),
                        type: 'file',
                        answer: [
                            {
                                input: '',
                                type: 'file',
                                id: 'iommbaihbfmdxncwpshe'
                            }
                        ],
                        id: 'suxtbkaltcmkffjolnxp',
                        required: true
                    }
                ]
            }
        },
        {
            id: 'contactForm',
            formModel: {
                ...emptyFormModel,
                id: 'new-tpl-contactForm' as types.FormId,
                name: t('formTemplates.contactForm.name'),
                questions: [
                    {
                        title: t('formTemplates.contactForm.fields.name'),
                        type: 'short',
                        answer: {
                            input: '',
                            type: 'short',
                            id: 'ijdsecahgatareaqwenr'
                        },
                        id: 'cfpjlilnhkgfwarfruvx',
                        required: true
                    },
                    {
                        title: t('formTemplates.contactForm.fields.email'),
                        type: 'short',
                        answer: {
                            input: '',
                            type: 'short',
                            id: 'paprkgliaquvribsifkl'
                        },
                        id: 'rpkjlxniscjvwwvrwnsb',
                        required: true
                    },
                    {
                        title: t('formTemplates.contactForm.fields.subject'),
                        type: 'short',
                        answer: {
                            input: '',
                            type: 'short',
                            id: 'pombqgftphmoaalaehgg'
                        },
                        id: 'ajpdppdhgahdkjqihrqa',
                        required: true
                    },
                    {
                        title: t('formTemplates.contactForm.fields.text'),
                        type: 'long',
                        answer: {
                            input: '',
                            type: 'long',
                            id: 'slkvpotkioyuowdjgxra'
                        },
                        id: 'wtqetfcximmrjaorlrny',
                        required: true
                    }
                ]
            }
        }
    ];
};

export function getFormModel(formId: string): types.FormModel | null {
    if (formId === 'new') {
        return emptyFormModel;
    }
    const template = getFormTemplates().find((template) => `new-tpl-${template.id}` === formId);
    if (template) {
        return template.formModel;
    }
    return null;
}
