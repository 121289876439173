import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../../api/Api';
import * as types from '../../types/Types';
import ModalForm from '../ModalForm';
import { useSearchParamsEx } from '../../hooks/useLoader';
import { useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { Utils } from '../../api/privmx/utils/Utils';
import { selectChatTags } from '../../store/ModalsSlice';
import { Checkbox } from '@mantine/core';
import { usePreventClose } from '../../hooks/usePreventClose';
import { UserSelectOptionType } from '../UsersSelector';
import { toUserOption } from '../UsersSelector/helpers';
import { useTranslation } from 'react-i18next';

interface FormData {
    name: string;
    users: UserSelectOptionType[];
    managers: UserSelectOptionType[];
    tags: types.TagOption[];
}

export function EmailInboxEditPage(props: {
    emailInbox?: types.FormModel;
    handleClose: () => void;
    availableUsers: types.UserOrContact[];
    availableManagers: types.User[];
}) {
    const { t } = useTranslation();
    const currentUser = useAppSelector(selectCurrentUser);
    const globalTags = useAppSelector(selectChatTags);
    const emailInbox = props.emailInbox;
    const emailInboxId = emailInbox?.id;
    const form = useForm<FormData>();
    const setError = form.setError;
    const handleClose = props.handleClose;
    const { navigateKeepingSearch } = useSearchParamsEx();
    const [updateKeysWhenAddingUsers, setUpdateKeysWhenAddingUsers] = useState(true);
    usePreventClose(form.formState.isDirty);

    const handleFormSubmit = useCallback(
        async (data: FormData) => {
            if (data.name.trim() === '') {
                setError('name', {});
                return;
            }
            if (!data.managers.find((x) => x.type === 'user' && x.value === currentUser.username)) {
                setError('managers', {});
                return;
            }
            try {
                const users = data.users.map((x) => toUserOption(x));
                const managers = data.managers
                    .map((x) => x.value)
                    .filter((x) => x !== null) as unknown as types.Username[];
                const tags = data.tags.map((x) => x.value);
                if (emailInboxId) {
                    await api.editFormModel({
                        id: emailInboxId,
                        tags: tags,
                        status: 'published',
                        newName: data.name.trim(),
                        newQuestions: emailInbox.questions,
                        users: users,
                        managers: managers,
                        updateKeysWhenAddingUsers: updateKeysWhenAddingUsers,
                        autoResponseData: {
                            enabled: false,
                            emailFieldId: '',
                            message: '',
                            title: ''
                        }
                    });
                } else {
                    const newEmailInbox = await api.createFormModel(
                        data.name.trim(),
                        { type: 'questions', questions: api.getEmailInboxQuestions() },
                        'published',
                        tags,
                        users,
                        managers,
                        'emailInbox',
                        { enabled: false, emailFieldId: '', message: '', title: '' },
                        true
                    );
                    navigateKeepingSearch(`./emailInboxes/${newEmailInbox.id}`);
                }
            } catch (e) {}
            handleClose();
        },
        [
            emailInboxId,
            emailInbox?.questions,
            handleClose,
            setError,
            navigateKeepingSearch,
            currentUser.username,
            updateKeysWhenAddingUsers
        ]
    );

    const contacts = useMemo(() => {
        return props.availableUsers
            .filter((user) => user.type === 'contact')
            .map((user) => (user as types.ContactOpt).contact);
    }, [props.availableUsers]);

    const tags = useMemo(() => emailInbox?.tags || [], [emailInbox?.tags]);
    const [newTags, setNewTags] = useState<types.Tag[]>([]);
    const tagsOptions = [...(globalTags || []), ...newTags].map((tag) => ({
        label: tag,
        value: tag
    }));
    const defaultTagsValue = useMemo(
        () => tags.map((x) => tagsOptions.find((o) => o.value && x)).filter(Utils.isDefined),
        [tagsOptions, tags]
    );

    return (
        <ModalForm onSubmit={handleFormSubmit} form={form} onClose={handleClose}>
            <ModalForm.TextField
                name="name"
                required
                defaultValue={emailInbox?.name}
                label={t('entityProps.name')}
            />
            <ModalForm.TagsSelectField
                name="tags"
                label={t('entityProps.tags')}
                addNewTag={(query) => setNewTags((prev) => [...prev, query as types.Tag])}
                defaultValue={defaultTagsValue}
                tagOptions={tagsOptions}
            />
            <ModalForm.UserSelectField
                name="users"
                label={t('entityProps.users')}
                // usersOrContacts={props.availableUsers}
                // defaultUsernamesOrContactIds={users}
                contacts={contacts}
            />
            <ModalForm.UserSelectField
                name="managers"
                label={t('entityProps.managers')}
                // usersOrContacts={props.availableManagers}
                // defaultUsernamesOrContactIds={managers}
                contacts={contacts}
                keepCurrentUser
            />
            {emailInboxId ? (
                <ModalForm.Field>
                    <Checkbox
                        checked={!updateKeysWhenAddingUsers}
                        onChange={(event) =>
                            setUpdateKeysWhenAddingUsers(!event.currentTarget.checked)
                        }
                        label={t('addedUsersCanSeeOldSubmits')}
                    />
                </ModalForm.Field>
            ) : (
                <></>
            )}
        </ModalForm>
    );
}

export default EmailInboxEditPage;
