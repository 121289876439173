import { QuestionModel } from '../../../types/Types';
import Icon, { iconCircle, iconClose, iconSquare } from '../../Icon';
import { FormDispatch } from '../types';
import { ActionIcon, Box, Group, Text, TextInput, ThemeIcon } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export default function InputFactory(props: {
    question: QuestionModel;
    dispatch: FormDispatch;
    index: number;
}) {
    const { t } = useTranslation();
    switch (props.question.type) {
        case 'single':
        case 'select':
            return (
                <>
                    {props.question.answer.map((x, i) => (
                        <Group key={i} mb="sm">
                            <ThemeIcon
                                variant="outline"
                                color="dark"
                                sx={{ border: 0 }}
                                size="xs"
                                c="gray">
                                <Box
                                    display="inline-block"
                                    component={Icon}
                                    icon={
                                        props.question.type === 'single' ? iconCircle : iconSquare
                                    }
                                />
                            </ThemeIcon>
                            <TextInput
                                styles={{
                                    input: {
                                        borderTop: 0,
                                        borderRight: 0,
                                        borderLeft: 0
                                    }
                                }}
                                radius={0}
                                size="xs"
                                onChange={(e) =>
                                    props.dispatch({
                                        type: 'change_option',
                                        index: props.index,
                                        optionIndex: i,
                                        newValue: e.target.value
                                    })
                                }
                                value={x.input}
                            />
                            <ActionIcon
                                onClick={() =>
                                    props.dispatch({
                                        type: 'remove_option',
                                        index: props.index,
                                        optionIndex: i
                                    })
                                }>
                                <Icon icon={iconClose} />
                            </ActionIcon>
                        </Group>
                    ))}

                    <Text
                        color="dimmed"
                        onClick={() => props.dispatch({ type: 'add_option', index: props.index })}>
                        {t('action.addNewOption')}
                    </Text>
                </>
            );
        case 'short':
            return <Text>{t('form.shortAnswer')}</Text>;
        case 'long':
            return <Text>{t('form.longAnswer')}</Text>;
        case 'file':
            return <Text>{t('form.longAnswer')}</Text>;
        case 'block':
            return <Text>Text block</Text>;
        case 'phone':
            return <Text>{t('form.phoneAnswer')}</Text>;
        case 'email':
            return <Text>{t('form.emailAnswer')}</Text>;
    }
}
