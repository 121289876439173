import { useEffect, useState } from 'react';
import { Center, Loader, Overlay, Paper, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export interface ConnectionScreenCoverConnectedState {
    state: 'connected';
}

export interface ConnectionScreenCoverCheckingConnectionStatusState {
    state: 'checkingConnectionStatus';
}

export interface ConnectionScreenCoverReloggingState {
    state: 'relogging';
}

export interface ConnectionScreenCoverReloginFailedState {
    state: 'reloginFailed';
    errorMessage: string;
}

export type ConnectionScreenCoverState =
    | ConnectionScreenCoverConnectedState
    | ConnectionScreenCoverCheckingConnectionStatusState
    | ConnectionScreenCoverReloggingState
    | ConnectionScreenCoverReloginFailedState;

export const setConnectionScreenCoverStateEventName = 'set-connection-screen-cover-state';

type setConnectionScreenCoverStateEventDetail = ConnectionScreenCoverState;

type SetConnectionScreenCoverStateEvent = CustomEvent<setConnectionScreenCoverStateEventDetail>;

export default function ConnectionScreenCover() {
    const { t } = useTranslation();
    const [state, setState] = useState<ConnectionScreenCoverState>({ state: 'connected' });

    useEffect(() => {
        const handler = (event: SetConnectionScreenCoverStateEvent) => {
            setState(event.detail);
        };
        window.addEventListener(setConnectionScreenCoverStateEventName, handler as EventListener);
        return () => {
            window.removeEventListener(
                setConnectionScreenCoverStateEventName,
                handler as EventListener
            );
        };
    }, []);

    if (state.state === 'connected') return <></>;

    return (
        <>
            <Overlay fixed blur={15} center>
                {state.state === 'checkingConnectionStatus' && (
                    <Paper p="md">
                        <>
                            <Title order={3} mb="sm">
                                {t('modal.connectionScreenCover.checkingConnectionStatus')}
                            </Title>
                            <Center>
                                <Loader />
                            </Center>
                        </>
                    </Paper>
                )}
                {state.state === 'relogging' && (
                    <Paper p="md">
                        <>
                            <Title order={3} mb="sm">
                                {t('modal.connectionScreenCover.tryingToRelogin')}
                            </Title>
                            <Center>
                                <Loader />
                            </Center>
                        </>
                    </Paper>
                )}
                {state.state === 'reloginFailed' && (
                    <Paper p="md">
                        <Title order={3}>
                            {t('modal.connectionScreenCover.reloginFailedWithMessage', {
                                message: state.errorMessage
                            })}
                        </Title>
                    </Paper>
                )}
            </Overlay>
        </>
    );
}
