import React, {
    createContext,
    useContext,
    Children,
    cloneElement,
    useCallback,
    useRef
} from 'react';
import {
    Controller,
    FieldValues,
    Control,
    UseFormRegister,
    Path,
    UnPackAsyncDefaultValues,
    FieldErrorsImpl,
    DeepRequired,
    UseFormReturn,
    FieldPath,
    UseFormWatch,
    UseFormGetValues,
    RegisterOptions
} from 'react-hook-form';
import {
    Grid,
    TextInput,
    TextInputProps,
    Center,
    Group,
    Button,
    Paper,
    createStyles,
    TextareaProps,
    Textarea,
    Box,
    Input,
    rem,
    Text,
    InputWrapperProps,
    FileInput,
    FileInputProps,
    Flex,
    Select,
    ColProps,
    PasswordInput,
    useMantineTheme,
    Title,
    Selectors,
    DefaultProps
} from '@mantine/core';
import { UsersSelector, BaseUsersSelectorProps } from '../UsersSelector';
import { Attachment, Contact, Tag, TagOption } from '../../types/Types';
import TagsSelect from '../TagsSelect';
import Icon, { iconAttach, iconUpload } from '../Icon';
import { FileBadge } from '../../atoms/FileBadge';
import { TimeInput } from '@mantine/dates';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store';
import { addNewChatTag } from '../../store/ModalsSlice';
import TextEditor from '../TextEditor/TextEditor';
import { Dropzone, FileWithPath } from '@mantine/dropzone';
import { IconUpload, IconX } from '@tabler/icons-react';
import { useElementSize } from '@mantine/hooks';
import { LoadingMessageOverlay } from '../../mantineAtoms/LoadingMessageOverlay';

interface ModalFormProps<T extends FieldValues> {
    onSubmit: (data: T) => void | Promise<void>;
    children?: React.ReactElement[];
    form: UseFormReturn<T>;
    isProcesing?: boolean;
    buttons?: React.ReactNode;
    onClose?: React.MouseEventHandler;
    loadingMessage?: string;
}

interface ModalContextType<FormType extends FieldValues> {
    control: Control<FormType>;
    register: UseFormRegister<FormType>;
    errors: Partial<FieldErrorsImpl<DeepRequired<UnPackAsyncDefaultValues<FormType>>>>;
    watch: UseFormWatch<FormType>;
    getData: UseFormGetValues<FormType>;
}

const ModalContext = createContext<ModalContextType<any>>(null as any);

function ModalContextProvider<FormType extends FieldValues>({
    children,
    ...values
}: React.PropsWithChildren<ModalContextType<FormType>>) {
    return <ModalContext.Provider value={{ ...values }}>{children}</ModalContext.Provider>;
}

function useModalFormContext<FormType extends FieldValues>() {
    const context = useContext<ModalContextType<FormType>>(ModalContext);
    if (!context) {
        throw new Error(
            'Modal Form Element must be used inside ModalForm component ( used outside form context )'
        );
    }
    return context;
}

interface ModalFieldProps<T> {
    name: Path<UnPackAsyncDefaultValues<T>>;
    label: string;
    required?: boolean;
    span?: 1 | 2;
    variant?: 'default' | 'password';
    registerOptions?: RegisterOptions<any, any>;
}

const fieldVariantElements = {
    default: TextInput,
    password: PasswordInput
} as const;

type FormTextFieldProps<T> = ModalFieldProps<T> & TextInputProps;
function FormTextField<T extends FieldValues>({
    name,
    required,
    span = 1,
    maxLength = 120,
    variant = 'default',
    registerOptions,
    ...props
}: FormTextFieldProps<T>) {
    const context = useModalFormContext<T>();
    const Input = fieldVariantElements[variant];
    return (
        <Grid.Col span={span} sx={{ height: 'fit-content' }}>
            <Input
                required={required}
                tt="capitalize"
                error={
                    context.errors[name] &&
                    (context.errors[name]?.message ? (
                        <>{context.errors[name]?.message}</>
                    ) : (
                        <>{context.errors[name]?.type}</>
                    ))
                }
                styles={{
                    label: { textTransform: 'capitalize' },
                    error: { textTransform: 'none' }
                }}
                maxLength={maxLength}
                {...props}
                {...context.register(name, { required, ...registerOptions })}
            />
        </Grid.Col>
    );
}

type FormTextareaFieldProps<T> = ModalFieldProps<T> & TextareaProps & { span?: 1 | 2 };
function FormTextareaField<T extends FieldValues>({
    name,
    span = 1,
    required,
    ...props
}: FormTextareaFieldProps<T>) {
    const context = useModalFormContext<T>();

    return (
        <Grid.Col span={span}>
            <Textarea
                required={required}
                tt="capitalize"
                {...props}
                {...context.register(name, { required })}
            />
        </Grid.Col>
    );
}

const useMessageStyles = createStyles((theme) => ({
    input: {
        borderRadius: `${rem(theme.radius.sm)} ${rem(theme.radius.sm)} 0 0`,
        borderBottom: 0,
        minHeight: 150
    },
    attachemnts: {
        borderRadius: `0 0 ${rem(theme.radius.sm)} ${rem(theme.radius.sm)}`,
        backgroundColor: 'transparent',
        '&[data-with-border]': {
            borderTop: 0
        }
    },
    dropzoneRoot: {
        flexGrow: 1,
        display: 'block',
        border: 0,
        backgroundColor: 'transparent',
        padding: 0,
        width: '100%',
        position: 'absolute',
        inset: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        },
        '&[data-accept]': {
            color: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[5],
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            flexGrow: 1,
            border: `4px dashed currentColor`
        },
        '&[data-idle]': {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        }
    },
    dropzoneInner: {
        height: '100%',
        display: 'flex'
    }
}));

type FormMessageFieldProps<T> = ModalFieldProps<T> & {
    onFileDrop?: (files: FileWithPath[]) => void;
} & (
        | {
              defaultValue?: string;
              children?: React.ReactNode;
              attachFileClick: () => void;
              attachments: Omit<Attachment, 'chatId' | 'messageId'>[];
              deleteAttachment: (id: Attachment['id']) => void;
              label?: string;
              grow?: boolean;
          }
        | {
              children?: React.ReactNode;
              defaultValue?: string;
              attachFileClick?: never;
              attachments?: never;
              deleteAttachment?: never;
              label?: string;
              grow?: boolean;
          }
    );

function FormMessageField<T extends FieldValues>({
    name,
    defaultValue,
    attachFileClick,
    attachments,
    deleteAttachment,
    label,
    required,
    children,
    grow,
    onFileDrop,
    registerOptions
}: FormMessageFieldProps<T>) {
    const { t } = useTranslation();
    const context = useModalFormContext<T>();
    const { classes } = useMessageStyles();
    const openRef = useRef<() => void>(null);
    const { ref, height } = useElementSize();

    const theme = useMantineTheme();
    return (
        <Controller
            name={name}
            control={context.control}
            rules={{ required, ...registerOptions }}
            render={({ field, fieldState }) => (
                <Input.Wrapper
                    inputWrapperOrder={['label', 'description', 'error', 'input']}
                    required={required}
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative'
                    }}
                    label={label || name}
                    error={fieldState.error?.message || fieldState.error?.type}>
                    <Box ref={ref} sx={{ flexGrow: 1, height: '100%' }}>
                        <TextEditor
                            maxHeight={height - 50}
                            type="full"
                            defaultValue={field.value}
                            value={field.value}
                            onChange={(e: string) => field.onChange(e)}
                        />
                    </Box>

                    {children && <>{children}</>}
                    {attachments && (
                        <Paper className={classes.attachemnts}>
                            <Group>
                                <Button
                                    type="button"
                                    variant="subtle"
                                    onClick={attachFileClick}
                                    disabled={false}
                                    leftIcon={<Icon icon={iconAttach} />}>
                                    {t('action.attachFiles')}
                                </Button>
                                <Group>
                                    {attachments.map((att) => (
                                        <FileBadge
                                            key={att.id}
                                            mimetype={att.contentType}
                                            attachmentId={att.id}
                                            name={att.name}
                                            onDelete={() => deleteAttachment(att.id)}
                                        />
                                    ))}
                                </Group>
                            </Group>
                        </Paper>
                    )}
                    <Dropzone.FullScreen
                        withinPortal={false}
                        openRef={openRef}
                        activateOnClick={false}
                        classNames={{
                            root: classes.dropzoneRoot,
                            inner: classes.dropzoneInner
                        }}
                        onDrop={onFileDrop || (() => {})}>
                        <Flex direction="column" sx={{ pointerEvents: 'none', flexGrow: 1 }}>
                            <Dropzone.Accept>
                                <Center h="100%">
                                    <Group>
                                        <IconUpload size="3.2rem" stroke={1.5} />
                                        <Title>Drop files here</Title>
                                    </Group>
                                </Center>
                            </Dropzone.Accept>
                            <Dropzone.Reject>
                                <IconX
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Reject>
                            <Box
                                component={Dropzone.Idle}
                                display={'flex'}
                                sx={{ flexGrow: 1, flexDirection: 'column' }}></Box>
                        </Flex>
                    </Dropzone.FullScreen>
                </Input.Wrapper>
            )}
        />
    );
}

type FormUserSelectProps<T extends FieldValues> = ModalFieldProps<T> &
    Omit<BaseUsersSelectorProps<T, FieldPath<T>>, 'control'> & {
        contacts: Contact[];
        description?: string;
    };
function FormUserSelect<T extends FieldValues>({
    name,
    required,
    span = 1,
    ...props
}: FormUserSelectProps<T>) {
    const context = useModalFormContext<T>();

    return (
        <Grid.Col span={span} sx={{ height: 'fit-content' }}>
            <UsersSelector
                keepCurrentUser={'hidden'}
                required={required}
                name={name}
                hasError={!!context.errors[name]}
                isDisabled={false}
                {...props}
                creatable
                contacts={props.contacts}
                control={context.control}
            />
        </Grid.Col>
    );
}

interface ButtonRenderProps<T> {
    buttons: ((data: T) => React.ReactNode) | React.ReactNode;
    onClose?: never;
}

interface ButtonDefaultProps {
    buttons?: never;
    onClose: React.MouseEventHandler;
}

type ButtonProps<T> = ButtonRenderProps<T> | ButtonDefaultProps;

function FormButtons<T extends FieldValues>({ buttons, onClose }: ButtonProps<T>) {
    const { t } = useTranslation();
    const context = useModalFormContext<T>();
    if (onClose) {
        return (
            <Grid.Col span={2}>
                <Center>
                    <Group>
                        <Button type="button" onClick={onClose} variant="light">
                            {t('action.cancel')}
                        </Button>
                        <Button type="submit">{t('action.save')}</Button>
                    </Group>
                </Center>
            </Grid.Col>
        );
    }

    return (
        <Grid.Col span={2}>
            <Center>
                <Group>
                    {typeof buttons === 'function' ? buttons(context.getData()) : buttons}
                </Group>
            </Center>
        </Grid.Col>
    );
}

type FormTagsProps<T extends FieldValues> = ModalFieldProps<T> & {
    defaultValue: TagOption[];
    addNewTag: (prev: string) => void;
    tagOptions: { label: string; value: string }[];
    label: string;
};
function FormTagsSelect<T extends FieldValues>({
    name,
    defaultValue,
    addNewTag,
    tagOptions,
    required,
    span,
    label
}: FormTagsProps<T>) {
    const context = useModalFormContext<T>();
    const dispatch = useAppDispatch();

    const handleCreate = useCallback(
        (query: string) => {
            dispatch(addNewChatTag([query as Tag]));
            addNewTag(query);
            return { value: query, label: query };
        },
        [dispatch, addNewTag]
    );

    return (
        <Grid.Col span={span} sx={{ height: 'fit-content' }}>
            <Controller
                control={context.control}
                name={name}
                rules={{ required }}
                defaultValue={defaultValue as any}
                render={({ field, fieldState }) => (
                    <TagsSelect
                        required={required}
                        label={label}
                        error={fieldState.error?.message ?? fieldState.error?.type}
                        value={field.value.map((v: { value: string; label: string }) => v.value)}
                        onChange={(val) => {
                            field.onChange(val.map((v) => ({ label: v, value: v })));
                        }}
                        data={tagOptions}
                        onCreate={handleCreate}
                    />
                )}
            />
        </Grid.Col>
    );
}

type FormSelectProps<T extends FieldValues> = ModalFieldProps<T> & {
    defaultValue?: string;
    options: Array<{ label: string; value: string }>;
};
function FormSelect<T extends FieldValues>({
    name,
    defaultValue,
    options,
    required,
    label
}: FormSelectProps<T>) {
    const context = useModalFormContext<T>();

    return (
        <Grid.Col span={1} sx={{ height: 'fit-content' }}>
            <Controller
                control={context.control}
                name={name}
                rules={{ required }}
                defaultValue={defaultValue as any}
                render={({ field, fieldState }) => (
                    <Select
                        required={required}
                        label={label}
                        styles={{ label: { textTransform: 'capitalize' } }}
                        error={fieldState.error?.type}
                        value={options.find((x) => x.value === field.value)?.value}
                        onChange={(val) => {
                            field.onChange(val);
                        }}
                        data={options}
                    />
                )}
            />
        </Grid.Col>
    );
}

type FormFileFieldProps<T> = ModalFieldProps<T> & FileInputProps;
function FormFileField<T extends FieldValues>({ name, ...props }: FormFileFieldProps<T>) {
    const context = useModalFormContext<T>();

    return (
        <Grid.Col span={1}>
            <Controller
                control={context.control}
                name={name}
                rules={{ required: props.required }}
                render={({ field }) => (
                    <FileInput
                        label={props.label ?? name}
                        styles={{ label: { textTransform: 'capitalize' } }}
                        error={props.error}
                        value={field.value}
                        icon={<Icon icon={iconUpload} />}
                        required={props.required}
                        onChange={(val) => {
                            field.onChange(val);
                            props.onChange?.(Array.isArray(val) ? val[0] : val);
                        }}
                    />
                )}
            />
        </Grid.Col>
    );
}

const useInputStyle = createStyles((theme) => ({
    timeRangeWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 5
    },
    timeRange: {
        height: rem(18),
        minHeight: 0,
        padding: 0,
        border: 0,
        borderRadius: 0,
        flexGrow: 0,
        flexBasis: 'min-content',
        '&::-webkit-calendar-picker-indicator': {
            display: 'none'
        }
    }
}));

type TimeSpanProps<T> = ModalFieldProps<T> & { defaultValue?: [string, string] } & Omit<
        InputWrapperProps,
        'children'
    >;

function FormTimeSpanSelect<T extends FieldValues>({
    name,
    required,
    defaultValue,
    label,
    ...rest
}: TimeSpanProps<T>) {
    const context = useModalFormContext<T>();
    const { classes } = useInputStyle();
    return (
        <FormModalField>
            <Controller
                control={context.control}
                name={name}
                rules={{ required }}
                defaultValue={defaultValue as any}
                render={({ field, fieldState }) => (
                    <Input.Wrapper
                        required={required}
                        label={label ?? name}
                        tt="capitalize"
                        error={fieldState.error?.type}
                        {...rest}>
                        <Input
                            component={'div'}
                            classNames={{ input: classes.timeRangeWrapper }}
                            error={fieldState.error?.type}>
                            <TimeInput
                                value={field?.value?.[0]}
                                classNames={{ input: classes.timeRange }}
                                required={required}
                                onChange={(value) => {
                                    field.onChange([value.target.value, field.value[1]]);
                                }}
                            />
                            <Text>-</Text>
                            <TimeInput
                                classNames={{ input: classes.timeRange }}
                                required={required}
                                value={field?.value?.[1]}
                                onChange={(value) => {
                                    field.onChange([field.value[0], value.target.value]);
                                }}
                            />
                        </Input>
                    </Input.Wrapper>
                )}
            />
        </FormModalField>
    );
}

function FormModalField({
    children,
    span = 1,
    ...props
}: { children: React.ReactNode; span?: 1 | 2 } & ColProps) {
    return (
        <Grid.Col span={span} {...props}>
            {children}
        </Grid.Col>
    );
}

const useFormStyles = createStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    column: {
        height: '100%'
    },
    content: {},
    messageInput: {
        flexGrow: 1,
        minHeight: 200,
        marginTop: theme.spacing.xs
    }
}));

type FormStylesNames = Selectors<typeof useFormStyles>;
interface FormStylesProps extends DefaultProps<FormStylesNames> {}

export default function ModalForm<FormType extends FieldValues>({
    children,
    onSubmit,
    form,
    isProcesing,
    buttons,
    onClose,
    loadingMessage,
    classNames,
    styles,
    unstyled,
    ...others
}: ModalFormProps<FormType> & FormStylesProps) {
    const _children =
        children &&
        Children.map(children, (child) => {
            return typeof child === 'string' ? child : cloneElement<FormType>(child);
        });

    const close = onClose || (() => {});

    const _messageInput = _children?.findIndex((x) => x.props['grow'] === true);
    const _input = _children?.[_messageInput || -1];
    const _content = _children?.filter((x) => !x.props['grow']);

    const { classes } = useFormStyles(undefined, {
        name: 'ModalForm',
        classNames,
        styles,
        unstyled
    });

    const handleSubmit = useCallback(
        (event: React.FormEvent) => {
            event.preventDefault();
            form.handleSubmit(onSubmit)();
        },
        [onSubmit, form]
    );
    return (
        <ModalContextProvider errors={form.formState.errors} getData={form.getValues} {...form}>
            <LoadingMessageOverlay visible={isProcesing || false} message={loadingMessage} />
            <Box component="form" className={classes.root} onSubmit={handleSubmit} {...others}>
                <Flex direction="column" className={classes.column} justify="space-between">
                    <Grid columns={2} className={classes.content}>
                        {_content}
                    </Grid>
                    {(_messageInput || -1) > 0 && (
                        <Flex className={classes.messageInput}>{_input}</Flex>
                    )}
                    {buttons === null ? null : (
                        <Grid columns={2} mt="md">
                            {buttons ? buttons : <FormButtons onClose={(e) => close(e)} />}
                        </Grid>
                    )}
                </Flex>
            </Box>
        </ModalContextProvider>
    );
}

ModalForm.Field = FormModalField;
ModalForm.Buttons = FormButtons;
ModalForm.TextField = FormTextField;
ModalForm.TextareaField = FormTextareaField;
ModalForm.MessageFormField = FormMessageField;
ModalForm.UserSelectField = FormUserSelect;
ModalForm.TagsSelectField = FormTagsSelect;
ModalForm.FormFileField = FormFileField;
ModalForm.TimeSpanField = FormTimeSpanSelect;
ModalForm.FormSelect = FormSelect;
