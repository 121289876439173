import { createStyles } from '@mantine/core';

export const useFileBadgeStyles = createStyles((theme) => {
    return {
        hoverableRoot: {
            cursor: 'pointer',
            '&:hover': {
                color: theme.colors['brand'][6],
                borderColor: 'inherit',
                backgroundColor:
                    theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]
            }
        },
        inner: {
            textTransform: 'initial'
        },
        badgeLeftSection: {
            marginRight: 2
        },
        actionIcon: {
            '&:hover': {
                backgroundColor:
                    theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[3]
            }
        }
    };
});
