import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextEditor from '../TextEditor/TextEditor';
import { useTextEditorConfig } from '../TextEditor/useTextEditorConfig';
import { Paper, Stack, Box, Group, Text, useMantineTheme } from '@mantine/core';
import { emptyMessageCreator } from '.';
import { api, chatApi } from '../../api/Api';
import { usePreventClose } from '../../hooks/usePreventClose';
import { useAppSelector, useAppDispatch } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { selectFullEditorContent, clearTextEditor } from '../../store/FullTextEditorSlice';
import { toggleNewMeetingModal } from '../../store/ModalsSlice';
import {
    PreparedAttachment,
    DraftAttachmentEx,
    Username,
    ThreadId,
    DraftId,
    MessageCreateModel,
    AttachmentId,
    ChatId,
    MeetingId,
    Message,
    ThreadMessageDraftWithAttachments,
    MessageId
} from '../../types/Types';
import { CancelledByUserError } from '../../utils/CancelledByUserError';
import { DraftUtils } from '../../utils/DraftUtils';
import AttachmentListItem from './AttachmentListItem';
import { Dropzone, FileWithPath } from '@mantine/dropzone';
import { IconPhoto, IconUpload, IconX } from '@tabler/icons-react';
import { useClickOutside } from '@mantine/hooks';
import { useEditingStore } from './editingStore';
import { useTranslation } from 'react-i18next';
import useToast from '../../hooks/useToast';
import { Utils } from '../../utils/Utils';
import { selectFileConfig } from '../../store/UserPreferenceSlice';
import { notifications } from '@mantine/notifications';
import { FileChooser } from '../../utils/FileChooser';
import { Validator } from '../../utils/Validator';

type DraftLoadingState =
    | { state: 'not-loaded' }
    | { state: 'loading'; threadId: ThreadId }
    | { state: 'loaded'; threadId: ThreadId };

interface SendMessageProps {
    chatId?: ChatId;
    meetingId?: MeetingId;
    chatMessageDraft?: ThreadMessageDraftWithAttachments;
    meetingMessageDraft?: ThreadMessageDraftWithAttachments;
    updatePendingMessages?: React.Dispatch<React.SetStateAction<Message[]>>;
    scrollDown: VoidFunction;
    inFullEditor?: boolean;
    onSendEmailNotification?: () => void;
}

export default function MessageInput({
    chatId,
    meetingId,
    chatMessageDraft,
    meetingMessageDraft,
    updatePendingMessages,
    scrollDown,
    onSendEmailNotification
}: SendMessageProps) {
    const currentUser = useAppSelector(selectCurrentUser);
    const currentUserId = api.isAnonymousMeetingClient() ? currentUser.pub : currentUser.username;
    const [isProcessing, setIsProcessing] = useState(false);
    const [, setProcessingType] = useState<'saveDraft' | 'save' | 'addAttachment' | null>(null);
    const textEditorContent = useAppSelector((state) =>
        selectFullEditorContent(state, chatId || meetingId || '')
    ) as string;
    const [message, setMessage] = useState('');
    const { t } = useTranslation();
    const toast = useToast();
    const draftLoadingState = useRef<DraftLoadingState>({ state: 'not-loaded' });
    const draftUpToDate = useRef<boolean>(false);
    const editor = useTextEditorConfig({
        content: textEditorContent,
        placeholder: t('placeholder.newMessage'),
        onUpdate: ({ editor }) => {
            draftUpToDate.current = false;
            setMessage(editor.getHTML());
        }
    });

    const {
        initialText,
        editing: isEditing,
        messageId: editedMessageId,
        attachments: editedMessageAttachments,
        stopEditing
    } = useEditingStore();

    const editSynced = useRef(false);

    const ref = useClickOutside(() => {
        if (isEditing) {
            stopEditing();
            editor?.commands.setContent('');
            editSynced.current = false;
        }
    });

    useEffect(() => {
        if (editSynced.current) return;
        if (editor && editor.commands && isEditing) {
            editor.commands.setContent(initialText);
            editor.commands.focus();
            editSynced.current = true;
        }
    }, [initialText, editor, isEditing, message]);

    const [attachments, setAttachments] = useState<(PreparedAttachment | DraftAttachmentEx)[]>(
        (chatMessageDraft ?? meetingMessageDraft)?.attachments ?? []
    );

    const componentWillUnmount = useRef(false);
    const [sendEmailNotificationOnSubmit, setSendEmailNotificationOnSubmit] = useState(false);
    const isDirty = attachments.length > 0 || (editor?.getText().trim() ?? '').length > 0;
    usePreventClose(isDirty);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!editor?.commands) {
            return;
        }
        async function f() {
            const draftThreadId = chatMessageDraft?.threadId ?? meetingMessageDraft?.threadId;
            if (!draftThreadId) {
                return;
            }
            if (
                draftLoadingState.current.state === 'not-loaded' ||
                draftLoadingState.current.threadId !== draftThreadId
            ) {
                draftLoadingState.current = { state: 'loading', threadId: draftThreadId };
                const newDraft = await api.getThreadMessageDraft(draftThreadId);
                if (
                    draftLoadingState.current.state === 'loading' &&
                    draftLoadingState.current.threadId === draftThreadId
                ) {
                    if (newDraft) {
                        editor?.commands.setContent(newDraft.message.text, true);
                        setAttachments(newDraft.attachments);
                    }
                    draftLoadingState.current = { state: 'loaded', threadId: draftThreadId };
                }
                return;
            }
        }
        f();
    }, [
        editor?.commands,
        chatMessageDraft?.message.text,
        meetingMessageDraft?.message.text,
        chatMessageDraft?.threadId,
        meetingMessageDraft?.threadId
    ]);

    const [inputError, setInputError] = useState<React.ReactNode>(null);

    const handleFormSubmit = useCallback(async () => {
        const newMessageId = api.generateMessageId();
        const formatedText = message === '<p></p>' ? '' : message;
        const sentAttachments = attachments;
        const validationResult = Validator.getErrors(
            formatedText,
            attachments.length > 0 ? ['richtext'] : ['required', 'richtext'],
            true
        );
        if (validationResult !== false) {
            if (validationResult.includes('size')) {
                setInputError(
                    t('errorMessage.messageMaxLength', {
                        count: Validator.constraints['richtext'].maxLength
                    })
                );
                return;
            }
            if (validationResult.includes('required')) {
                return;
            }
        }

        editor?.commands.clearContent();
        setAttachments([]);
        let cancelled = false;
        let err: any = null;
        try {
            if (chatId) {
                setInputError(null);
                if (updatePendingMessages)
                    updatePendingMessages((pendingMessages) => [
                        ...pendingMessages,
                        emptyMessageCreator(
                            formatedText,
                            DraftUtils.castDraftAttachmentExArrToAttachmentArr(attachments),
                            (currentUserId || '') as Username,
                            newMessageId,
                            chatId,
                            'html'
                        )
                    ]);
                await chatApi.sendMessage(
                    chatId,
                    'text/html',
                    formatedText,
                    newMessageId,
                    sentAttachments
                );
            } else if (meetingId) {
                await chatApi.sendMeetingMessage(
                    meetingId,
                    'text/html',
                    formatedText,
                    newMessageId,
                    sentAttachments
                );
            }
        } catch (error) {
            if (error instanceof CancelledByUserError) {
                cancelled = true;
            } else {
                err = error;
            }
        }
        if (err) {
            if (updatePendingMessages) {
                updatePendingMessages((pendingMessages) =>
                    pendingMessages.map((x) => {
                        if (x.msgId === newMessageId) {
                            return { ...x, failed: true, pending: false };
                        } else {
                            return x;
                        }
                    })
                );
            }
            console.error('Error during sending message', err);
            toast('Cannot send message, ' + Utils.errorToString(err), 'error');
        } else if (cancelled) {
            if (updatePendingMessages) {
                updatePendingMessages((pendingMessages) =>
                    pendingMessages.filter((x) => x.msgId !== newMessageId)
                );
            }
        } else {
            editor?.commands.setContent('');
            scrollDown();
            setMessage('');
            setAttachments([]);
            dispatch(clearTextEditor());
            if (sendEmailNotificationOnSubmit) {
                setSendEmailNotificationOnSubmit(false);
                onSendEmailNotification?.();
            }
            if (chatMessageDraft) {
                await api.deleteDraft(chatMessageDraft.id);
            }
            if (meetingMessageDraft) {
                await api.deleteDraft(meetingMessageDraft.id);
            }
        }
    }, [
        t,
        editor?.commands,
        attachments,
        chatId,
        chatMessageDraft,
        meetingId,
        meetingMessageDraft,
        currentUserId,
        updatePendingMessages,
        scrollDown,
        dispatch,
        toast,
        message,
        sendEmailNotificationOnSubmit,
        onSendEmailNotification
    ]);

    useEffect(() => {
        return () => {
            componentWillUnmount.current = true;
        };
    }, []);

    const prevThreadIdRef = useRef<ThreadId | null>(null);
    const prevDraftIdRef = useRef<DraftId | null>(null);

    useEffect(() => {
        return () => {
            const didThreadIdChange =
                prevThreadIdRef.current !== null &&
                prevThreadIdRef.current !== (chatId ?? meetingId);
            const threadId = didThreadIdChange
                ? prevThreadIdRef.current
                : chatId ?? meetingId ?? null;
            const draftId = didThreadIdChange
                ? prevDraftIdRef.current
                : chatMessageDraft?.id ?? meetingMessageDraft?.id ?? null;
            prevThreadIdRef.current = chatId ?? meetingId ?? null;
            prevDraftIdRef.current = chatMessageDraft?.id ?? meetingMessageDraft?.id ?? null;
            if (
                (!componentWillUnmount.current && !didThreadIdChange) ||
                api.isAnonymousMeetingClient() ||
                draftUpToDate.current
            ) {
                return;
            }
            const text = message;
            const messageBasicModel: MessageCreateModel = {
                mimetype: 'text/html',
                text: text ?? ''
            };
            const existingAttachments = attachments.filter(
                (x) => 'draftId' in x
            ) as DraftAttachmentEx[];
            const newAttachments = attachments.filter(
                (x) => !('draftId' in x)
            ) as PreparedAttachment[];
            const draftMessage = DraftUtils.getThreadMessageCreateModel('chat', messageBasicModel);
            const f = async () => {
                if (draftId) {
                    const updatedDraft = DraftUtils.getThreadMessageDraftWithAttachments(
                        draftId,
                        threadId!,
                        draftMessage,
                        existingAttachments
                    );
                    await api.updateDraft(
                        updatedDraft,
                        newAttachments.map((x) => x.file)
                    );
                    draftUpToDate.current = true;
                } else {
                    const newDraft = DraftUtils.getThreadMessageDraftPropsForCreating(
                        threadId!,
                        draftMessage
                    );
                    const createdDraft = await api.createDraft(newDraft);
                    if (newAttachments.length > 0 && createdDraft) {
                        const updatedDraft = DraftUtils.getThreadMessageDraftWithAttachments(
                            createdDraft.id,
                            threadId!,
                            draftMessage,
                            existingAttachments
                        );
                        await api.updateDraft(
                            updatedDraft,
                            newAttachments.map((x) => x.file)
                        );
                        draftUpToDate.current = true;
                    }
                }
            };
            try {
                f();
            } catch (error) {}
        };
    }, [message, attachments, chatId, meetingId, chatMessageDraft?.id, meetingMessageDraft?.id]);

    const filesUploadConfig = useAppSelector(selectFileConfig);

    const handleAttachFilesClick = useCallback(
        async (droppedFiles?: FileWithPath[]) => {
            try {
                const newAttachments = droppedFiles
                    ? droppedFiles.map((file) => api.prepareAttachment(file))
                    : await api.chooseFilesAsAttachments();
                FileChooser.checkFilesConfig(newAttachments, filesUploadConfig);
                if (newAttachments.length > 0) {
                    setAttachments((attachments) => [...attachments, ...newAttachments]);
                }
            } catch (error: any) {
                if ('message' in error) {
                    notifications.show({
                        title: 'Error while uploading files',
                        message: error.message,
                        color: 'red'
                    });
                }
            }
        },
        [setAttachments, filesUploadConfig]
    );

    const handleDeleteAttachmentClick = useCallback(
        (attachmentId: AttachmentId) => {
            setAttachments((prev) => {
                api.deleteAttachment(attachmentId);
                return prev.filter((attachment) => attachment.id !== attachmentId);
            });
        },
        [setAttachments]
    );

    const handleScheduleMeetingClick = useCallback(() => {
        const threadId = chatId ?? meetingId;
        if (!threadId) {
            return;
        }
        const threadUsers = api.getThreadUsernames(threadId);
        dispatch(
            toggleNewMeetingModal({
                open: true,
                payload: {
                    users: threadUsers.users.filter((x) =>
                        x.type === 'user' ? x.username !== currentUser.username : true
                    ),
                    managers: threadUsers.managers.filter((x) => x !== currentUser.username),
                    basedOnThreadId: threadId
                }
            })
        );
    }, [dispatch, chatId, meetingId, currentUser.username]);

    const attachmentsList = attachments.length
        ? attachments.map((attachment) => (
              <AttachmentListItem
                  key={attachment.id}
                  attachment={attachment}
                  onDeleteClick={handleDeleteAttachmentClick}
              />
          ))
        : undefined;

    const theme = useMantineTheme();

    const handleMessageEdit = useCallback(
        async (event?: React.FormEvent) => {
            event?.stopPropagation();
            event?.preventDefault();
            setIsProcessing(true);
            setProcessingType('save');
            try {
                let modified: boolean = false;
                modified = initialText.trim() !== message;
                if (modified) {
                    await chatApi.editMessage(
                        editedMessageId as MessageId,
                        'text/html',
                        message,
                        editedMessageAttachments
                    );
                }
                stopEditing();
                editor?.commands.setContent('');
                editSynced.current = false;
            } catch (error) {
                if (!(error instanceof CancelledByUserError)) {
                    throw error;
                }
            } finally {
                setIsProcessing(false);
            }
        },
        [
            message,
            initialText,
            editedMessageId,
            editedMessageAttachments,
            stopEditing,
            editor?.commands
        ]
    );

    const onSubmit = isEditing ? handleMessageEdit : handleFormSubmit;

    return (
        <Paper bg="transparent" mb="md" radius={0}>
            <Stack>
                <div className="form">
                    <Dropzone.FullScreen
                        loading={isProcessing}
                        active={!!chatId}
                        onDrop={handleAttachFilesClick}>
                        <Group
                            position="center"
                            spacing="xl"
                            mih={220}
                            sx={{ pointerEvents: 'none' }}>
                            <Dropzone.Accept>
                                <IconUpload
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={
                                        theme.colors[theme.primaryColor][
                                            theme.colorScheme === 'dark' ? 4 : 6
                                        ]
                                    }
                                />
                            </Dropzone.Accept>
                            <Dropzone.Reject>
                                <IconX
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Reject>
                            <Dropzone.Idle>
                                <IconPhoto size="3.2rem" stroke={1.5} />
                            </Dropzone.Idle>

                            <div>
                                <Text size="xl" inline>
                                    Drag Files
                                </Text>
                            </div>
                        </Group>
                    </Dropzone.FullScreen>
                    <Box ref={ref}>
                        <TextEditor
                            error={inputError}
                            isEditing={isEditing}
                            type="small"
                            onAttach={meetingId ? undefined : handleAttachFilesClick}
                            attachments={attachmentsList}
                            editor={editor}
                            scheduleVideo={meetingId ? undefined : handleScheduleMeetingClick}
                            onSubmit={onSubmit}
                            onKeyDown={(e) => {
                                if (e.ctrlKey && e.code === 'Enter') {
                                    onSubmit();
                                }
                            }}
                        />
                    </Box>
                    <input
                        type="hidden"
                        name="author"
                        value={currentUser.username ?? currentUser.pub ?? ''}
                    />
                </div>
            </Stack>
        </Paper>
    );
}
