import Modal from '../../atoms/Modal';
import StateView from '../../atoms/State';
import { BaseModalData } from '../../types/Modals';
import { FormId } from '../../types/Types';
import { EmailInboxEditPage } from './index';
import { useCallback } from 'react';
import useLoader from '../../hooks/useLoader';
import { useTranslation } from 'react-i18next';

export interface EmailInboxEditModalData extends BaseModalData {
    id: FormId;
}

export function EmailInboxEditModal(props: {
    onClose: () => void;
    data: EmailInboxEditModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useLoader(
        useCallback((x) => x.loadEmailInboxForEditing(props.data.id), [props.data.id])
    );
    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            height={props.data.height ?? 250}
            minHeight={250}
            width={props.data.width}
            title={
                props.data.title ??
                (state.type === 'done' && state.data.emailInbox?.name
                    ? t('modal.emailInboxEdit.titleWithName', { name: state.data.emailInbox.name })
                    : t('modal.emailInboxEdit.titleWithoutName'))
            }>
            <StateView state={state} retry={revalidate}>
                {(data) => (
                    <EmailInboxEditPage
                        emailInbox={data.emailInbox}
                        availableUsers={data.users}
                        availableManagers={data.managers}
                        handleClose={props.onClose}
                    />
                )}
            </StateView>
        </Modal>
    );
}
