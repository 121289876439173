import { createStyles } from '@mantine/core';
import { useCallback, useMemo, useState, MutableRefObject, useRef } from 'react';
import { ImageCropperUtils } from './ImageCropperUtils';
import { useDragMoveResize } from '../../hooks/useDragMoveResize';

export interface ImageCropperProps {
    imageUrl: string;
    viewportWidth: number;
    viewportHeight: number;
    viewportShape: 'circle' | 'rectangle';
    targetWidth: number;
    targetHeight: number;
    resultRef: MutableRefObject<(() => Promise<string>) | null>;
}

const useStyles = (props: ImageCropperProps) =>
    createStyles((theme) => ({
        root: {
            width: props.viewportWidth,
            height: props.viewportHeight,
            background: theme.colors.gray[6],
            margin: '0 auto',
            position: 'relative'
        },
        img: {
            width: props.viewportWidth,
            height: props.viewportHeight,
            objectFit: 'contain',
            position: 'absolute'
        },
        clipOverlayContainer: {
            width: props.targetWidth,
            height: props.targetHeight,
            overflow: 'hidden',
            position: 'absolute'
        },
        clipOverlay: {
            position: 'relative',
            background: 'transparent',
            boxShadow: '0 0 0 10000px rgba(0, 0, 0, 0.6)',
            borderRadius: props.viewportShape === 'circle' ? 1000 : '0',
            cursor: 'grab'
        }
    }));

export function ImageCropper(props: ImageCropperProps) {
    const [clipOverlayW, setClipOverlayW] = useState(props.viewportWidth);
    const [clipOverlayH, setClipOverlayH] = useState(props.viewportHeight);
    const [clipOverlayX, setClipOverlayX] = useState(0);
    const [clipOverlayY, setClipOverlayY] = useState(0);
    const utils = useMemo(() => {
        return new ImageCropperUtils({
            imageUrl: props.imageUrl,
            previewWidth: props.viewportWidth,
            previewHeight: props.viewportHeight,
            targetWidth: props.targetWidth,
            targetHeight: props.targetHeight,
            setClipOverlayW: setClipOverlayW,
            setClipOverlayH: setClipOverlayH,
            setClipOverlayX: setClipOverlayX,
            setClipOverlayY: setClipOverlayY
        });
    }, [
        props.imageUrl,
        props.viewportWidth,
        props.viewportHeight,
        props.targetWidth,
        props.targetHeight
    ]);
    const { classes } = useStyles(props)();
    const draggableRef = useRef(null);

    const getMinMaxRect = useCallback(() => {
        return utils.getMinMaxMovableRect();
    }, [utils]);

    const getCurrentRect = useCallback(() => {
        return getElementCurrentRect(draggableRef.current);
    }, []);

    const onChangeRect = useCallback((x: number, y: number) => {
        setClipOverlayX(x);
        setClipOverlayY(y);
    }, []);

    props.resultRef.current = async () => {
        return utils.getFinalImage(clipOverlayX, clipOverlayY);
    };

    useDragMoveResize('move', draggableRef, getMinMaxRect, getCurrentRect, onChangeRect, false);

    return (
        <div className={classes.root}>
            <img src={props.imageUrl} className={classes.img} alt="" />
            <div className={classes.clipOverlayContainer}>
                <div
                    ref={draggableRef}
                    className={classes.clipOverlay}
                    style={{
                        width: clipOverlayW,
                        height: clipOverlayH,
                        left: clipOverlayX,
                        top: clipOverlayY
                    }}></div>
            </div>
        </div>
    );
}

function getElementCurrentRect(el: HTMLDivElement | null) {
    const rect = { x: 0, y: 0, w: 0, h: 0 };
    if (el) {
        const style = window.getComputedStyle(el);
        const left = parseFloat(style.left);
        const top = parseFloat(style.top);
        rect.x = isNaN(left) ? 0 : left;
        rect.y = isNaN(top) ? 0 : top;
        rect.w = el.clientWidth;
        rect.h = el.clientHeight;
    }
    return rect;
}
