import * as FormsTypes from '../FormsTypes';
import FileUploadInput from './FileUploadInput';
import FormElement from './FormElement';
export default function FormGroupElements({
    options
}: {
    options: FormsTypes.RenderFormGroupOptions;
}) {
    const { question, registerInputRef } = options;
    return (
        <>
            {question.answers.map((answerModel) => {
                const _answerModel: FormsTypes.AnswerInputModel = { ...answerModel };
                if (options.responseState) {
                    const answersMap: { [k: string]: FormsTypes.Answer } = Object.assign(
                        {},
                        ...options.responseState.answers.map((answer) => ({
                            [answer.answerModelId]: answer
                        }))
                    );
                    _answerModel.value = answersMap[_answerModel.id]?.value;
                }

                const ref = registerInputRef(_answerModel);
                if (_answerModel.inputType === 'file') {
                    return (
                        <FileUploadInput
                            key={answerModel.id}
                            options={{
                                inputRef: ref,
                                model: _answerModel,
                                updateDirty: options.updateDirty,
                                updateSelectedFiles: options.updateSelectedFiles
                            }}
                        />
                    );
                }
                return (
                    <FormElement
                        key={answerModel.id}
                        options={{
                            inputRef: ref,
                            model: _answerModel,
                            updateDirty: options.updateDirty
                        }}
                    />
                );
            })}
        </>
    );
}
