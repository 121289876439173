import { Flex, createStyles } from '@mantine/core';
import * as types from '../../../types/Types';
import { FileActionsButton } from '../common/FileActionsButton';
import Icon, { mimetypeToIcon } from '../../Icon';
import { useCallback } from 'react';

const useFileListElementStyles = createStyles((theme) => {
    return {
        row: {
            cursor: 'pointer',
            '&:hover': {
                background:
                    theme.colorScheme === 'dark'
                        ? theme.colors.dark[6]
                        : theme.fn.rgba(theme.colors.gray[1], 0.5)
            }
        },
        fileName: {
            flex: '1 1 auto'
        }
    };
});

export interface FileListElementProps {
    file: types.AttachmentEx;
    onShowFilePreview: (fileId: types.AttachmentId) => void;
}

export function FileListElement(props: FileListElementProps) {
    const icon = mimetypeToIcon(props.file.contentType);
    const { classes } = useFileListElementStyles();

    const onShowFilePreview = props.onShowFilePreview;
    const handleRowClick = useCallback(() => {
        onShowFilePreview(props.file.id);
    }, [props.file.id, onShowFilePreview]);

    return (
        <Flex
            py="xs"
            px="sm"
            align="center"
            gap="sm"
            className={classes.row}
            onClick={handleRowClick}>
            <Icon icon={icon} />
            <div className={classes.fileName}>{props.file.name}</div>
            <FileActionsButton file={props.file} onShowFilePreview={props.onShowFilePreview} />
        </Flex>
    );
}
