import * as PmxApi from 'privmx-server-api';
import { pki } from 'privmx-pki2/out/ApiTypes';
import { BaseApi } from './BaseApi';

export class AdminApi extends BaseApi implements PmxApi.api.admin.IAdminApi {
    getUsers(): Promise<PmxApi.api.admin.UserDb[]> {
        return this.request('getUsers', {});
    }

    getUser(model: { username: PmxApi.api.core.Username }): Promise<PmxApi.api.admin.UserDb> {
        return this.request('getUser', model);
    }

    removeUser(model: { username: PmxApi.api.core.Username }): Promise<PmxApi.api.core.OK> {
        return this.request('removeUser', model);
    }

    addUser(model: {
        username: PmxApi.api.core.Username;
        pin: PmxApi.api.user.Pin;
        privacyLevel: PmxApi.api.user.PrivacyLevel;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('addUser', model);
    }

    addUserWithToken(
        model: PmxApi.api.admin.AddUserWithTokenModel
    ): Promise<PmxApi.api.admin.AddUserResult> {
        return this.request('addUserWithToken', model);
    }

    addPrivateUser(
        model: PmxApi.api.admin.AddPrivateUserModel
    ): Promise<PmxApi.api.admin.AddUserResult> {
        return this.request('addPrivateUser', model);
    }

    addExternalUser(
        model: PmxApi.api.admin.AddExternalUserModel
    ): Promise<{ token: PmxApi.api.user.UserToken }> {
        return this.request('addExternalUser', model);
    }

    addExternalPrivmxUser(
        model: PmxApi.api.admin.AddExternalPrivmxUserModel
    ): Promise<{ token: PmxApi.api.user.UserToken }> {
        return this.request('addExternalPrivmxUser', model);
    }

    addBasicUser(
        model: PmxApi.api.admin.AddExternalUserModel
    ): Promise<{ token: PmxApi.api.user.UserToken }> {
        return this.request('addBasicUser', model);
    }

    addUserEx(model: PmxApi.api.admin.AddUserExModel): Promise<PmxApi.api.admin.CreateUserResult> {
        return this.request('addUserEx', model);
    }

    updateUsersOriginDetails(
        model: PmxApi.api.admin.UpdateUsersOriginDetailsModel
    ): Promise<PmxApi.api.core.OK> {
        return this.request('updateUsersOriginDetails', model);
    }

    changePin(model: {
        username: PmxApi.api.core.Username;
        pin: PmxApi.api.user.Pin;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('changePin', model);
    }

    changeEmail(model: {
        username: PmxApi.api.core.Username;
        email: PmxApi.api.core.Email;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('changeEmail', model);
    }

    changeDescription(model: {
        username: PmxApi.api.core.Username;
        description: PmxApi.api.user.UserDescription;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('changeDescription', model);
    }

    changeContactFormEnabled(model: {
        username: PmxApi.api.core.Username;
        enabled: boolean;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('changeContactFormEnabled', model);
    }

    changeSecureFormsEnabled(model: {
        username: PmxApi.api.core.Username;
        enabled: boolean;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('changeSecureFormsEnabled', model);
    }

    changeUserData(model: {
        username: PmxApi.api.core.Username;
        data: PmxApi.api.admin.UserDataChange;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('changeUserData', model);
    }

    modifyUser(model: {
        username: PmxApi.api.core.Username;
        adminDataV2?: boolean;
        properties: PmxApi.api.admin.UserProperties;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('modifyUser', model);
    }

    amIAdminKeyOwner(): Promise<boolean> {
        return this.request('amIAdminKeyOwner', {});
    }

    generateInvitations(
        model: PmxApi.api.admin.InvitationModel
    ): Promise<{ token: PmxApi.api.user.UserToken; link: PmxApi.api.core.Url }[]> {
        return this.request('generateInvitations', model);
    }

    getFullInitData(model: {
        key?: PmxApi.api.user.InitDataKey;
    }): Promise<PmxApi.api.admin.FullInitData> {
        return this.request('getFullInitData', model);
    }

    setInitData(model: {
        data: PmxApi.api.admin.FullInitData;
        key?: PmxApi.api.user.InitDataKey;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('setInitData', model);
    }

    getNotifierConfig(): Promise<PmxApi.api.admin.NotifierConfig> {
        return this.request('getNotifierConfig', {});
    }

    setNotifierConfig(model: {
        config: PmxApi.api.admin.NotifierConfig;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('setNotifierConfig', model);
    }

    getInvitationMailConfig(): Promise<PmxApi.api.admin.NotifierConfig> {
        return this.request('getInvitationMailConfig', {});
    }

    setInvitationMailConfig(model: {
        config: PmxApi.api.admin.NotifierConfig;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('setInvitationMailConfig', model);
    }

    changeIsAdmin(model: {
        username: PmxApi.api.core.Username;
        isAdmin: boolean;
        data: PmxApi.api.pki.PkiDocument;
        kis: PmxApi.api.pki.PkiSignature;
    }): Promise<pki.KeyStoreMessage> {
        return this.request('changeIsAdmin', model);
    }

    getForbiddenUsernames(): Promise<PmxApi.api.core.Username[]> {
        return this.request('getForbiddenUsernames', {});
    }

    setUserLbk(model: {
        username: PmxApi.api.core.Username;
        pub: PmxApi.api.core.EccPubKey;
        data: PmxApi.api.core.Base64;
        loginByProxy: PmxApi.api.core.Host;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('setUserLbk', model);
    }

    disableUserTwofa(model: { username: PmxApi.api.core.Username }): Promise<PmxApi.api.core.OK> {
        return this.request('disableUserTwofa', model);
    }

    setUserBlocked(model: {
        username: PmxApi.api.core.Username;
        blocked: boolean;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('setUserBlocked', model);
    }

    adminGetUserSrp(model: {
        username: PmxApi.api.core.Username;
    }): Promise<PmxApi.api.admin.UserSrp> {
        return this.request('adminGetUserSrp', model);
    }

    adminSetUserSrp(model: PmxApi.api.admin.SetUserSrpModel): Promise<PmxApi.api.core.OK> {
        return this.request('adminSetUserSrp', model);
    }

    adminSwitchUserToNormal(model: {
        username: PmxApi.api.core.Username;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('adminSwitchUserToNormal', model);
    }

    adminSwitchUserToBasic(model: {
        username: PmxApi.api.core.Username;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('adminSwitchUserToBasic', model);
    }

    getPlanSummary(): Promise<PmxApi.api.admin.PlanSummary> {
        return this.request('getPlanSummary', {});
    }
}
