export class Deferred<T> {
    private _promise: Promise<T>;
    // @ts-expect-error
    private _resolve: (value: T) => void;
    // @ts-expect-error
    private _reject: (error?: any) => void;

    get promise(): Promise<T> {
        return this._promise;
    }

    constructor() {
        this._promise = new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }

    resolve(value: T): void {
        this._resolve(value);
    }

    reject(error?: any): void {
        this._reject(error);
    }
}
