import * as privmx from 'privfs-client';

export interface IStorage {
    get<T = unknown>(key: string): Promise<T | undefined>;
    set<T = unknown>(key: string, item: T): Promise<void>;
}

export interface SerializedKvdb<T extends privmx.types.db.KvdbEntry> {
    dbId: string;
    seq: number;
    entries: T[];
}

export class InMemoryCache implements IStorage {
    private map = new Map<string, any>();

    async get<T = unknown>(key: string): Promise<T | undefined> {
        return this.map.get(key);
    }

    async set<T = unknown>(key: string, item: T): Promise<void> {
        this.map.set(key, item);
    }
}

export class KvdbCache {
    constructor(private storage: IStorage) {}

    getKvdb<T extends privmx.types.db.KvdbEntry = privmx.types.db.KvdbEntry>(
        dbId: string
    ): Promise<SerializedKvdb<T> | undefined> {
        return this.getJson(dbId);
    }

    setKvdb<T extends privmx.types.db.KvdbEntry = privmx.types.db.KvdbEntry>(
        kvdb: SerializedKvdb<T>
    ): Promise<void> {
        return this.setJson(kvdb.dbId, kvdb);
    }

    private async getJson<T = unknown>(id: string): Promise<T | undefined> {
        return await this.storage.get<T>(id);
    }

    private async setJson(id: string, data: unknown): Promise<void> {
        return await this.storage.set(id, data);
    }
}
