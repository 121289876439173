import { useReducer, useRef } from 'react';
import StateView from '../../../../atoms/State';
import useLoader from '../../../../hooks/useLoader';
import { BaseModalData } from '../../../../types/Modals';
import { DraftId, ThreadId, Username, UsernameOrContactId } from '../../../../types/Types';
import { useCallback } from 'react';
import { useNewThreadStyles } from '../../../../atoms/Modals/newThreadStyles';
import { CreationMessageAction, CreationProcessType } from '../../../ChatAddPage/ChatAddModal';
import ThreadAddModalLoader from '../../../../atoms/Modals/ThreadAddLoader';
import { Box, Modal, Transition, Text } from '@mantine/core';
import ThreadAdded from '../../../../atoms/Modals/ThreadCreated';
import { useNavigate } from 'react-router-dom';
import { toggleNewMeetingModal } from '../../../../store/ModalsSlice';
import { useAppDispatch } from '../../../../store';
import MeetingAddPage from '.';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';

export interface MeetingAddModalData extends BaseModalData {
    draftId?: DraftId;
    users?: UsernameOrContactId[];
    managers?: Username[];
    basedOnThreadId?: ThreadId;
}

function reducer(state: CreationProcessType, action: CreationMessageAction) {
    const newState = { ...state };

    switch (action.type) {
        case 'setState':
            newState.state = action.payload;
            break;
        case 'setPayload':
            newState.payload = action.payload;
            break;
        case 'reset':
            newState.state = 'form';
            newState.payload = { title: '', id: '' as any };
    }

    return newState;
}

export function MeetingAddModal(props: {
    onClose: () => void;
    opened: boolean;
    data: MeetingAddModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const ref = useRef();
    const { state, revalidate } = useLoader(
        useCallback((x) => x.loadMeetingForAdding(props.data.draftId), [props.data.draftId])
    );
    const [creationState, dispatchCreationState] = useReducer(reducer, { state: 'form' });
    const { classes, theme } = useNewThreadStyles();
    const handleClose = useCallback(() => {
        props.onClose();
        dispatchCreationState({ type: 'reset' });
    }, [props]);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const isLarge = useMediaQuery(`(min-width:${theme.breakpoints.md})`);

    return (
        <Modal
            title={t('modal.meetingAdd.title')}
            radius="md"
            opened={props.opened}
            keepMounted={false}
            transitionProps={{
                duration: 200,
                transition: 'slide-up',
                timingFunction: 'ease-in'
            }}
            onClose={handleClose}
            overlayProps={{
                opacity: 0.2,
                blur: '8px'
            }}
            size={isLarge ? '70%' : '100%'}
            h={props.opened ? '30vh' : '0'}
            styles={{
                content: {
                    // minWidth: 600,
                    maxWidth: '60vw',
                    height: '100%'
                }
            }}
            classNames={{ body: classes.body, inner: classes.inner, content: classes.content }}>
            <StateView
                state={state}
                loading={() => <ThreadAddModalLoader isMeeting />}
                retry={revalidate}>
                {(loadedData) => (
                    <>
                        <Transition
                            transition={'slide-right'}
                            mounted={creationState.state === 'form'}>
                            {(styles) => (
                                <Box style={styles} display="flex" h="100%">
                                    <MeetingAddPage
                                        dispatchCreationAction={dispatchCreationState}
                                        ref={ref}
                                        meetingAdd={loadedData}
                                        handleClose={props.onClose}
                                        users={props.data.users}
                                        managers={props.data.managers}
                                        basedOnThreadId={props.data.basedOnThreadId}
                                    />
                                </Box>
                            )}
                        </Transition>
                        <ThreadAdded
                            comunicateConfig={{
                                title: t('successMessage.meetingScheduled'),
                                goToButton: t('action.goToMeeting'),
                                subTitle: (
                                    <>
                                        {t('youCanAccess')}{' '}
                                        <Text span weight="bolder">
                                            {creationState.payload?.title}
                                        </Text>{' '}
                                        {t('inTopicsSection')}
                                    </>
                                )
                            }}
                            onClose={() => dispatch(toggleNewMeetingModal({ open: false }))}
                            onGoToThread={() => navigate(`./meetings/${creationState.payload?.id}`)}
                            creationDispatch={dispatchCreationState}
                            state={creationState}
                        />
                    </>
                )}
            </StateView>
        </Modal>
    );
}
