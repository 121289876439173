import * as privfs from 'privfs-client';
import { MessageService } from '../MessageService';
import { AdminKeyHolder } from './AdminKeyHolder';
import { GrantAdminKeyMessageData } from './userCreation/Types';

export class AdminKeySender {
    constructor(
        private messageService: MessageService,
        private adminKeyHolder: AdminKeyHolder,
        private identity: privfs.identity.Identity
    ) {}

    async sendAdminKey(username: string): Promise<privfs.types.message.ReceiverData[]> {
        if (!this.adminKeyHolder.hasAdminKey()) {
            throw new Error('Admin key is not present');
        }
        const data: GrantAdminKeyMessageData = {
            type: 'grant-admin-key',
            extKey: this.adminKeyHolder.getAdminKey().getPrivatePartAsBase58()
        };
        return this.messageService.sendSimpleMessage2(
            username + '#' + this.identity.host,
            'You granted access to admin group',
            JSON.stringify(data),
            'admin-msg'
        );
    }
}
