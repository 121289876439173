import * as PmxApi from "privmx-server-api";
import { VideoConference } from "../components/videoConference";
import { IVideoConferencesService } from "./IVideoConferencesService";
import * as Types from "./Types";

export interface VideoConferenceManagerOptions {
    service: IVideoConferencesService;
    host: string;
    hostHash: string;
    sectionId: string;
    hideWelcomeMessage: boolean;
    $container: HTMLDivElement;
    providers: Types.Providers;
}

export class VideoConferenceManager {
    
    private static readonly ACTIVITY_CONFIRMATION_INTERVAL: number = 10 * 1000;
    private static readonly MIN_TIME_BETWEEN_GONGS: number = 5 * 1000;
    
    videoConference: VideoConference;
    activityConfirmationTimer?: number;
    private conferenceInfo?: Types.ConferenceInfo;
    private connected: boolean = false;
    private host: string;
    private hostHash: string;
    private sectionId: string;
    private service: IVideoConferencesService;
    private lastGongTime: number = 0;
    
    constructor(options: VideoConferenceManagerOptions) {
        this.service = options.service;
        this.host = options.host;
        this.hostHash = options.hostHash;
        this.sectionId = options.sectionId;
        this.videoConference = new VideoConference({
            $container: options.$container,
            hideWelcomeMessage: options.hideWelcomeMessage,
            providers: options.providers,
            manager: this,
        });
    }
    
    async destroy(): Promise<void> {
        this.stopActivityConfirmationTimer();
        if (this.connected) {
            await this.disconnect();
        }
        if (this.videoConference) {
            await this.videoConference.destroy();
        }
    }
    
    async connect(roomMetadata: Types.RoomMetadata, hashmail: string): Promise<void> {
        try {
            await this.connectCore(roomMetadata, hashmail);
        }
        catch (e) {
            try {
                await this.disconnect();
            }
            catch {}
            throw e;
        }
    }
    
    async disconnect(): Promise<void> {
        await this.leaveConference();
    }
    
    async gong(message: string): Promise<void> {
        const now = Date.now();
        if (now - this.lastGongTime >= VideoConferenceManager.MIN_TIME_BETWEEN_GONGS) {
            this.service.sendVideoConferenceGongMessage(this.hostHash, this.sectionId, this.conferenceInfo!.id, message);
            this.lastGongTime = now;
        }
    }
    
    async refreshPeopleNames(): Promise<void> {
        if (this.videoConference) {
            this.videoConference.refreshPeopleNames();
        }
    }
    
    private async connectCore(roomMetadata: Types.RoomMetadata, hashmail: string): Promise<void> {
        if (this.connected) {
            return;
        }
        this.videoConference.showLoadingOverlay();
        try {
            const options = await this.obtainConnectionOptions(roomMetadata, hashmail);
            if (!this.connected) {
                await this.videoConference.connect(JSON.stringify(options));
                this.connected = true;
            }
        }
        catch (e) {
            throw e;
        };
    }
    
    private async obtainConnectionOptions(roomMetadata: Types.RoomMetadata, hashmail: string): Promise<Types.VideoConferenceConnectionOptions> {
        const conference = await this.joinConference(data => this.connectAndCreateConference(data, roomMetadata, hashmail));
        this.conferenceInfo = conference;
        return this.createVideoConferenceConnectionOptions(conference, conference.roomMetadata, hashmail);
    }
    
    private async connectAndCreateConference(data: Types.CreatorData, roomMetadata: Types.RoomMetadata, hashmail: string): Promise<Types.ConferenceCreatorResult> {
        const connectionOptions: Types.VideoConferenceConnectionOptions = this.createVideoConferenceConnectionOptions(data, roomMetadata, hashmail);
        const resultStr = await this.videoConference.connectAndCreateConference(JSON.stringify(connectionOptions));
        const result: {
            status: "ok" | "error" | "cancelled";
            data?: {
                key: string;
                iv: string;
            };
            errorStr?: string;
        } = JSON.parse(resultStr);
        if (result.status == "error") {
            throw `could not connect: ${result.errorStr}`;
        }
        if (result.status == "cancelled") {
            throw "cancelled by user";
        }
        this.connected = result.status == "ok";
        if (result.status == "ok") {
            return {
                encryptionKey: result.data?.key,
                encryptionIV: result.data?.iv,
                roomMetadata: roomMetadata,
            };
        }
        return {
            roomMetadata: roomMetadata,
        };
    }
    
    private convertCreatorDataToConnectionParameters(creatorData: Types.CreatorData): Types.ConnectionParameters {
        return {
            domain: creatorData.domain,
            tmpUserName: creatorData.tmpUser.username,
            tmpUserPass: creatorData.tmpUser.password,
            conferenceId: creatorData.roomName,
            conferencePassword: creatorData.conferencePassword,
        };
    }
    
    private convertConferenceInfoToConnectionParameters(conferenceInfo: Types.ConferenceInfo): Types.ConnectionParameters {
        return {
            domain: conferenceInfo.jitsiDomain,
            conferenceId: conferenceInfo.id,
            conferencePassword: conferenceInfo.password,
            encryptionKey: conferenceInfo.encryptionKey,
            encryptionIV: conferenceInfo.encryptionIV,
        };
    }
    
    private createVideoConferenceConnectionOptions(data: Types.CreatorData | Types.ConferenceInfo, roomMetadata: Types.RoomMetadata, hashmail: string): Types.VideoConferenceConnectionOptions {
        let params: Types.ConnectionParameters | null = null;
        if ("jitsiDomain" in data) {
            params = this.convertConferenceInfoToConnectionParameters(data);
        }
        else {
            params = this.convertCreatorDataToConnectionParameters(data);
        }
        return {
            configuration: {
                domain: params.domain,
                appId: null,
                token: null,
                
                hashmail: hashmail,
                
                conferenceId: params.conferenceId,
                conferencePassword: params.conferencePassword,
                conferenceEncryptionKey: params.encryptionKey,
                conferenceEncryptionIV: params.encryptionIV,
            },
            tmpUserName: params.tmpUserName,
            tmpUserPassword: params.tmpUserPass,
            options: {
                title: roomMetadata.title,
                disableEncryption: roomMetadata.disableEncryption,
                experimentalH264: roomMetadata.experimentalH264,
                experimentalDominantSpeaker: roomMetadata.experimentalDominantSpeaker,
            },
        };
    }
    
    private async joinConference(conferenceCreator: Types.ConferenceCreator): Promise<Types.ConferenceInfo> {
        if (this.conferenceInfo) {
            return this.conferenceInfo;
        }
        try {
            const result = await this.joinToVideoRoom(this.sectionId);
            let conferenceInfo: Types.ConferenceInfo;
            if (result.action == Types.VideoRoomAction.CREATE) {
                conferenceInfo = await this.createConference(result, conferenceCreator);
            }
            else if (result.action == Types.VideoRoomAction.JOIN) {
                conferenceInfo = await this.joinToExistingConference(result);
            }
            else {
                throw new Error("Unsupported action " + (<Types.JoinResult>result).action);
            }
            this.conferenceInfo = conferenceInfo;
            this.onInAnyVideoConferenceStateChanged();
            this.onJoinedConference(conferenceInfo.id);
            return conferenceInfo;
        }
        catch (e) {
            this.disconnect();
            this.onInAnyVideoConferenceStateChanged();
            throw e;
        }
    }
    
    private async createConference(result: Types.JoinCreateResult, conferenceCreator: Types.ConferenceCreator): Promise<Types.ConferenceInfo> {
        await this.service.registerVideoConferencesDomain(result.domain);
        const roomSecretData = await this.invokeConferenceCreator(result, conferenceCreator);
        const roomSecret = await this.service.encryptWithSectionKey(roomSecretData, this.hostHash, this.sectionId);
        const switchResult = await this.switchVideoRoomState(this.sectionId, roomSecret, result.token);
        const conferenceInfo = this.createConferenceInfo(roomSecretData, switchResult.tsVideoRoomId);
        this.conferenceInfo = conferenceInfo;
        this.sendVideoConferenceStartMessage(roomSecretData.metadata.title);
        this.startActivityConfirmationTimer();
        return conferenceInfo;
    }
    
    private async invokeConferenceCreator(joinResult: Types.JoinCreateResult, conferenceCreator: Types.ConferenceCreator): Promise<Types.RoomSecretData> {
        const roomPassword = await this.generateConferencePassword();
        const roomName = await this.generateRoomName();
        const creatorResult = await conferenceCreator({
            domain: joinResult.domain,
            conferencePassword: roomPassword,
            tmpUser: joinResult.tmpUser,
            roomName: roomName,
            token: joinResult.token
        });
        const roomSecretData: Types.RoomSecretData = {
            domain: joinResult.domain,
            encryptionIV: creatorResult.encryptionIV!,
            encryptionKey: creatorResult.encryptionKey!,
            roomName: roomName,
            roomPassword: roomPassword,
            metadata: creatorResult.roomMetadata,
        };
        return roomSecretData;
    }
    
    private generateRoomName(): Promise<string> {
        return this.service.getRandomBytesHex(64);
    }
    
    private generateConferencePassword(): Promise<string> {
        return this.service.getRandomBytesHex(64);
    }
    
    private async joinToExistingConference(result: Types.JoinGetResult): Promise<Types.ConferenceInfo> {
        const roomSecretData = await this.service.decryptWithSectionKey(result.roomSecret, this.hostHash, this.sectionId);
        await this.service.registerVideoConferencesDomain(roomSecretData.domain);
        const conferenceInfo = this.createConferenceInfo(roomSecretData, result.tsVideoRoomId);
        this.conferenceInfo = conferenceInfo;
        this.startActivityConfirmationTimer();
        return conferenceInfo;
    }
    
    private createConferenceInfo(roomSecretData: Types.RoomSecretData, tsVideoRoomId: string): Types.ConferenceInfo {
        const conferenceInfo: Types.ConferenceInfo = {
            jitsiDomain: roomSecretData.domain,
            id: roomSecretData.roomName,
            password: roomSecretData.roomPassword,
            encryptionKey: roomSecretData.encryptionKey,
            encryptionIV: roomSecretData.encryptionIV,
            tsVideoRoomId: tsVideoRoomId,
            roomMetadata: roomSecretData.metadata,
        };
        return conferenceInfo;
    }
    
    private async leaveConference(): Promise<void> {
        if (!this.videoConference || !this.connected || !this.conferenceInfo) {
            this.onInAnyVideoConferenceStateChanged();
            return;
        }
        this.stopActivityConfirmationTimer();
        this.connected = false;
        const conference = this.conferenceInfo;
        const usersCount = await this.getConferenceUsersCount();
        const isLastUser = usersCount == 1;
        
        await this.videoConference.disconnect();
        const disconnectResult = await this.disconnectFromVideoRoom(this.sectionId, conference.tsVideoRoomId);
        const newUsersCount = await this.getConferenceUsersCount();
        if (disconnectResult ? disconnectResult.videoRoomDestoyed : isLastUser && newUsersCount == 0) {
            this.sendVideoConferenceEndMessage();
        }
        
        this.onInAnyVideoConferenceStateChanged();
        this.onLeftConference(conference.id);
    }
    
    private async getConferenceUsersCount(): Promise<number> {
        const roomStates = await this.getVideoRoomsState();
        const roomState = roomStates?.find(roomState => roomState.sectionId === this.sectionId);
        return roomState ? roomState.users.length : 0;
    }
    
    private async startActivityConfirmationTimer(): Promise<void> {
        if (this.activityConfirmationTimer !== undefined) {
            clearTimeout(this.activityConfirmationTimer);
        }
        const conference = this.conferenceInfo;
        if (!conference) {
            return;
        }
        await this.confirmActivity();
        this.activityConfirmationTimer = window.setTimeout(() => {
            if (this.activityConfirmationTimer !== undefined) {
                this.startActivityConfirmationTimer();
            }
        }, VideoConferenceManager.ACTIVITY_CONFIRMATION_INTERVAL);
    }
    
    private stopActivityConfirmationTimer(): void {
        if (this.activityConfirmationTimer !== undefined) {
            clearTimeout(this.activityConfirmationTimer);
            this.activityConfirmationTimer = undefined;
        }
    }
    
    private async confirmActivity(): Promise<void> {
        await this.service.onConfirmActivity();
        const conference = this.conferenceInfo;
        await this.commitVideoRoomAccess(this.sectionId, conference!.tsVideoRoomId).catch(() => {});
    }
    
    private onInAnyVideoConferenceStateChanged(): Promise<void> {
        return this.service.onInAnyVideoConferenceStateChanged();
    }
    
    private onJoinedConference(conferenceId: string): Promise<void> {
        return this.service.onJoinedConference(conferenceId);
    }
    
    private onLeftConference(conferenceId: string): Promise<void> {
        return this.service.onLeftConference(conferenceId);
    }
    
    private sendVideoConferenceStartMessage(conferenceTitle: string): Promise<void> {
        return this.service.sendVideoConferenceStartMessage(this.hostHash, this.sectionId, this.conferenceInfo!.id, conferenceTitle);
    }
    
    private sendVideoConferenceEndMessage(): Promise<void> {
        return this.service.sendVideoConferenceEndMessage(this.hostHash, this.sectionId, this.conferenceInfo!.id);
    }
    
    private async joinToVideoRoom(sectionId: string): Promise<Types.JoinResult> {
        const result = await this.service.joinToVideoRoom(sectionId);
        if (result.script && result.script.text) {
            const script = document.createElement("script");
            script.type="text/javascript";
            script.setAttribute("data-jitsi-script-version", result.script.version);
            script.innerHTML = result.script.text;
            document.head.appendChild(script);
        }
        if (result.script && result.script.version) {
            if (!this.videoConference.supportsScriptVersion(result.script.version)) {
                throw "Unsupported Jitsi script version";
            }
        }
        this.videoConference.afterScriptLoaded(result.script ? result.script.version : "1.0.26");
        if (result.type == "create") {
            const domain = this.convertRoomUrlToJitsiDomain(result.videoUrl);
            const res: Types.JoinCreateResult = {
                action: Types.VideoRoomAction.CREATE,
                domain: domain,
                tmpUser: {
                    username: `${result.credentials.user}@${domain}`,
                    password: result.credentials.password,
                },
                token: result.token,
            };
            return res;
        }
        else if (result.type == "room") {
            const res: Types.JoinGetResult = {
                action: Types.VideoRoomAction.JOIN,
                roomSecret: result.roomPassword,
                tsVideoRoomId: result.videoRoomId
            };
            return res;
        }
        throw new Error("Unsupported type " + (<PmxApi.api.video.JoinResult>result).type);
    }
    
    private async switchVideoRoomState(sectionId: string, roomSecret: string, token: string): Promise<Types.SwitchVideoRoomStateResult> {
        const result = await this.service.switchVideoRoomState(sectionId, token, roomSecret, "");
        return {
            tsVideoRoomId: result.videoRoomId,
        };
    }
    
    private async disconnectFromVideoRoom(sectionId: string, tsVideoRoomId: string): Promise<PmxApi.api.video.DisconnectResult> {
        return await this.service.disconnectFromVideoRoom(sectionId, tsVideoRoomId);
    }
    
    private async commitVideoRoomAccess(sectionId: string, tsVideoRoomId: string): Promise<void> {
        await this.service.commitVideoRoomAccess(sectionId, tsVideoRoomId);
    }
    
    private async getVideoRoomsState(): Promise<Types.ConferenceData[]> {
        const result = await this.service.getVideoRoomsState();
        return result.map(roomInfo => {
            return {
                id: null,
                sectionId: roomInfo.resourceId.id,
                users: this.convertUserNamesToHashmails(roomInfo.users),
            };
        });
    }
    
    private convertUserNamesToHashmails(userNames: string[]): string[] {
        return userNames.map(userName => `${userName}#${this.host}`)
    }
    
    // @ts-ignore
    private getRoomUrl(domain: string, roomName: string): string {
        return `https://${domain}/${roomName}`;
    }
    
    private convertRoomUrlToJitsiDomain(roomUrl: string): string {
        if (roomUrl.indexOf("http://") == 0) {
            roomUrl = roomUrl.substr("http://".length);
        }
        if (roomUrl.indexOf("https://") == 0) {
            roomUrl = roomUrl.substr("https://".length);
        }
        if (roomUrl.indexOf("www.") == 0) {
            roomUrl = roomUrl.substr("www.".length);
        }
        
        roomUrl = roomUrl.split("/")[0]!;
        
        return roomUrl;
    }
    
    // @ts-ignore
    private convertRoomUrlToRoomId(roomUrl: string): string {
        if (roomUrl.indexOf("http://") == 0) {
            roomUrl = roomUrl.substr("http://".length);
        }
        if (roomUrl.indexOf("https://") == 0) {
            roomUrl = roomUrl.substr("https://".length);
        }
        if (roomUrl.indexOf("www.") == 0) {
            roomUrl = roomUrl.substr("www.".length);
        }
        
        const roomId = roomUrl.split("/").splice(1).join("/");
        
        return roomId;
    }
    
    supportsScriptVersion(version: string): boolean {
        return this.videoConference.supportsScriptVersion(version);
    }
    
}
