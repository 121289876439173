import * as PmxApi from 'privmx-server-api';
import { BaseApi } from './BaseApi';

export class VideoApi extends BaseApi implements PmxApi.api.videoV2.IVideoApiV2 {
    private requestApiV2<T>(method: string, params: unknown): Promise<T> {
        return this.request('videoApi.v2.' + method, params);
    }

    joinToVideoRoom<T extends PmxApi.api.videoV2.ResourceId>(
        model: PmxApi.api.videoV2.JoinVideoRoomModel<T>
    ): Promise<PmxApi.api.videoV2.JoinResult<T>> {
        return this.requestApiV2('joinToVideoRoom', model);
    }

    switchVideoRoomState<T extends PmxApi.api.videoV2.ResourceId>(
        model: PmxApi.api.videoV2.SwitchVideoRoomModel<T>
    ): Promise<PmxApi.api.videoV2.RoomResult<T>> {
        return this.requestApiV2('switchVideoRoomState', model);
    }

    cancelVideoRoomCreation(
        model: PmxApi.api.videoV2.CancelVideoRoomCreationModel
    ): Promise<PmxApi.api.core.OK> {
        return this.requestApiV2('cancelVideoRoomCreation', model);
    }

    commitVideoRoomAccess(model: PmxApi.api.videoV2.VideoRoomHandler): Promise<PmxApi.api.core.OK> {
        return this.requestApiV2('commitVideoRoomAccess', model);
    }

    disconnectFromVideoRoom(
        model: PmxApi.api.videoV2.VideoRoomHandler
    ): Promise<PmxApi.api.video.DisconnectResult> {
        return this.requestApiV2('disconnectFromVideoRoom', model);
    }

    getVideoRoomsState<T extends PmxApi.api.videoV2.ResourceId>(): Promise<
        PmxApi.api.videoV2.RoomInfo<T>[]
    > {
        return this.requestApiV2('getVideoRoomsState', { type: 'thread' });
    }

    getCurrentVideoUsage(): Promise<PmxApi.api.video.VideoUsageFull> {
        return this.requestApiV2('getCurrentVideoUsage', {});
    }
}
