import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './Store';
import * as PmxApi from 'privmx-server-api';
import { docs } from '../data/docs';

export type AcceptedDocumentsState = {
    documents: PmxApi.api.system.AcceptedDocument[] | null;
};

export const initialState: AcceptedDocumentsState = {
    documents: null
};

export const acceptedDocumentsSlice = createSlice({
    name: 'acceptedDocuments',
    initialState,
    reducers: {
        setAcceptedDocuments: (_state, action: PayloadAction<AcceptedDocumentsState>) => {
            return action.payload;
        },
        resetAcceptedDocuments: () => {
            return initialState;
        }
    }
});

export const { setAcceptedDocuments, resetAcceptedDocuments } = acceptedDocumentsSlice.actions;

export const selectAcceptedDocuments = (state: RootState) => state.acceptedDocuments;

export const selectDocumentsToAccept = createSelector(
    [selectAcceptedDocuments],
    (acceptedDocumentsState) => {
        const acceptedDocuments = acceptedDocumentsState.documents;
        if (acceptedDocuments === null) {
            return null;
        }
        return docs.filter((doc) => {
            return !acceptedDocuments.some(
                (acceptedDoc) =>
                    acceptedDoc.documentId === doc.model.documentId &&
                    acceptedDoc.version === doc.model.version &&
                    acceptedDoc.appId === doc.model.appId
            );
        });
    }
);

export default acceptedDocumentsSlice.reducer;
