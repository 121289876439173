import { Modal } from '@mantine/core';
import { BaseModalData } from '../../types/Modals';
import CreateAccountView from './index';
import { useTranslation } from 'react-i18next';

export interface CreateAccountModalData extends BaseModalData {}

export function CreateAccountModal(props: { onClose: () => void; data: CreateAccountModalData }) {
    const { t } = useTranslation();
    return (
        <Modal
            centered
            opened
            onClose={props.onClose}
            title={props.data.title ?? t('modal.createAccount.title')}>
            <CreateAccountView handleClose={props.onClose} />
        </Modal>
    );
}
