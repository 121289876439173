import * as types from '../../../types/Types';
import { ActionIcon, Menu } from '@mantine/core';
import Icon, { iconActionsDropdown } from '../../Icon';
import { DropDownItem } from '../../../atoms/DropDown';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { api } from '../../../api/Api';

export interface FileActionsButtonProps {
    file: types.AttachmentEx;
    onShowFilePreview: (fileId: types.AttachmentId) => void;
}

export function FileActionsButton(props: FileActionsButtonProps) {
    const { t } = useTranslation();
    const [downloading, setDownloading] = useState(false);
    const onShowFilePreview = props.onShowFilePreview;
    const handlePreviewClick = useCallback(() => {
        onShowFilePreview(props.file.id);
    }, [props.file.id, onShowFilePreview]);

    const handleDownloadClick = useCallback(async () => {
        if (downloading) {
            return;
        }
        try {
            await api.downloadAttachment({
                fileName: props.file.name,
                attachmentId: props.file.id,
                attachmentSourceType: props.file.sourceType,
                thumb: false
            });
        } catch (e) {
            console.log('Download error', e);
        } finally {
            setDownloading(false);
        }
    }, [props.file.id, props.file.name, props.file.sourceType, downloading]);

    return (
        <div onClick={(e: React.MouseEvent) => e.stopPropagation()}>
            <Menu>
                <Menu.Target>
                    <ActionIcon>
                        <Icon icon={iconActionsDropdown} />
                    </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    <DropDownItem>
                        <span onClick={handlePreviewClick}>{t('action.preview')}</span>
                    </DropDownItem>
                    <DropDownItem>
                        <span onClick={handleDownloadClick}>{t('action.download')}</span>
                    </DropDownItem>
                </Menu.Dropdown>
            </Menu>
        </div>
    );
}
