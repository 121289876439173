import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Attachment, Tag } from '../../types/Types';
import { MultiValue } from 'react-select';
import Icon, { iconCancel, iconLoading, iconSave } from '../Icon';
import { api } from '../../api/Api';
import CustomSelect from '../TagsSelect/Select';
import { Button, Group } from '@mantine/core';

export interface AttachmentTagsEditData {
    attachment: Attachment;
    predefinedTags: string[];
}

export interface AttachmentTagsEditProps {
    attachmentTagsEdit: AttachmentTagsEditData;
    handleClose: () => void;
}

export function AttachmentTagsEdit(props: AttachmentTagsEditProps) {
    const [isProcessing, setIsProcessing] = useState(false);
    const [tags, setTags] = useState<Tag[]>(props.attachmentTagsEdit.attachment.tags);
    const handleClose = props.handleClose;
    const selectRef = useRef(null);

    const predefinedTags = useMemo(() => {
        return props.attachmentTagsEdit.predefinedTags.map((tag) => ({ label: tag, value: tag }));
    }, [props.attachmentTagsEdit.predefinedTags]);

    const handleTagsChange = useCallback((value: MultiValue<{ label: string; value: string }>) => {
        setTags(value.map((item) => item.value as Tag));
    }, []);

    const handleCancelClick = useCallback(() => {
        handleClose();
    }, [handleClose]);

    const handleSaveClick = useCallback(async () => {
        setIsProcessing(true);
        try {
            await api.updateAttachmentTags(props.attachmentTagsEdit.attachment, tags);
            handleClose();
        } finally {
            setIsProcessing(false);
        }
    }, [handleClose, props.attachmentTagsEdit.attachment, tags]);

    useEffect(() => {
        const select = selectRef.current as any;
        if (select) {
            select.focus();
        }
    }, []);

    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClose();
            }
        };
        document.body.addEventListener('keydown', handler);
        return () => {
            document.body.removeEventListener('keydown', handler);
        };
    }, [handleClose]);

    return (
        <div>
            <CustomSelect
                creatable
                ref={selectRef}
                menuPosition="fixed"
                isDisabled={isProcessing}
                isMulti={true}
                options={predefinedTags}
                value={tags.map(
                    (tag) =>
                        predefinedTags.find((x) => x.label === tag) ?? { label: tag, value: tag }
                )}
                onChange={handleTagsChange}
                formatOptionLabel={(option) => (
                    <div>
                        <span>{option.label}</span>
                    </div>
                )}
            />
            <Group>
                <Button onClick={handleCancelClick} disabled={isProcessing}>
                    <Icon icon={iconCancel} />
                    Cancel
                </Button>
                <Button onClick={handleSaveClick} disabled={isProcessing}>
                    <Icon icon={isProcessing ? iconLoading : iconSave} spin={isProcessing} />
                    Save
                </Button>
            </Group>
        </div>
    );
}

export default AttachmentTagsEdit;
