import { Menu, Group, rem, Text } from '@mantine/core';
import { DatesRangeValue, DatePicker } from '@mantine/dates';
import { IconInfoCircle } from '@tabler/icons-react';
import { useState, useCallback } from 'react';
import { FilterDropdownComponent } from '..';

export const FilterSelectDateDropdown: FilterDropdownComponent = ({ onSelect }) => {
    const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);

    const onDatePickerChange = useCallback(
        (newValue: DatesRangeValue) => {
            setValue(newValue);
            if (newValue && newValue[0] && newValue[1]) {
                if (newValue[0]?.getTime() === newValue[1]?.getTime()) {
                    onSelect(`date:${newValue[0]?.getTime()}`);
                } else {
                    onSelect(`date:${newValue.map((x) => x?.getTime()).join('-')}`);
                }
            }
        },
        [onSelect]
    );
    return (
        <Menu.Dropdown>
            <DatePicker
                type="range"
                allowSingleDateInRange
                size={'xs'}
                value={value}
                onChange={onDatePickerChange}
            />
            <Group spacing={2} ml={4}>
                <IconInfoCircle size={rem(10)} />
                <Text size={10} color={'dimmed'}>
                    Double click to select specific day
                </Text>
            </Group>
        </Menu.Dropdown>
    );
};
