import Modal from '../../atoms/Modal';
import StateView from '../../atoms/State';
import { BaseModalData } from '../../types/Modals';
import { EmailInboxEditPage } from './index';
import { useCallback } from 'react';
import useLoader from '../../hooks/useLoader';
import { useTranslation } from 'react-i18next';

export interface EmailInboxAddModalData extends BaseModalData {}

export function EmailInboxAddModal(props: {
    onClose: () => void;
    data: EmailInboxAddModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useLoader(useCallback((x) => x.loadEmailInboxForAdding(), []));
    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            height={props.data.height ?? 250}
            minHeight={250}
            width={props.data.width}
            title={props.data.title ?? t('modal.emailInboxAdd.title')}>
            <StateView state={state} retry={revalidate}>
                {(data) => (
                    <EmailInboxEditPage
                        availableUsers={data.users}
                        availableManagers={data.managers}
                        handleClose={props.onClose}
                    />
                )}
            </StateView>
        </Modal>
    );
}
