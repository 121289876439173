import { t } from 'i18next';

export const translations = {
    'error.createLocalAudioTrackFailed': () =>
        t('videoConference.error.createLocalAudioTrackFailed'),
    'error.createLocalVideoTrackFailed': () =>
        t('videoConference.error.createLocalVideoTrackFailed'),
    'error.createLocalDesktopTrackFailed': () =>
        t('videoConference.error.createLocalDesktopTrackFailed'),
    'error.unsupportedBrowser': () => t('videoConference.error.unsupportedBrowser'),
    'deviceSelect.disabled': () => t('videoConference.deviceSelect.disabled'),
    talkingWhenMutedNotification: () => t('videoConference.talkingWhenMutedNotification'),
    'button.disableDesktopSharing.title': () =>
        t('videoConference.button.disableDesktopSharing.title'),
    'button.enableDesktopSharing.title': () =>
        t('videoConference.button.enableDesktopSharing.title'),
    'button.disableLocalAudioInput.title': () =>
        t('videoConference.button.disableLocalAudioInput.title'),
    'button.enableLocalAudioInput.title': () =>
        t('videoConference.button.enableLocalAudioInput.title'),
    'button.disableLocalVideoInput.title': () =>
        t('videoConference.button.disableLocalVideoInput.title'),
    'button.enableLocalVideoInput.title': () =>
        t('videoConference.button.enableLocalVideoInput.title'),
    'button.disconnect.title': () => t('videoConference.button.disconnect.title'),
    'button.disableLocalAudioOutput.title': () =>
        t('videoConference.button.disableLocalAudioOutput.title'),
    'button.enableLocalAudioOutput.title': () =>
        t('videoConference.button.enableLocalAudioOutput.title'),
    'button.toggleExtraSettings.title': () => t('videoConference.button.toggleExtraSettings.title'),
    'button.switchModeToTiles.title': () => t('videoConference.button.switchModeToTiles.title'),
    'button.switchModeToSingleSpeaker.title': () =>
        t('videoConference.button.switchModeToSingleSpeaker.title'),
    'button.showLocalParticipant.title': () =>
        t('videoConference.button.showLocalParticipant.title'),
    'button.hideLocalParticipant.title': () =>
        t('videoConference.button.hideLocalParticipant.title'),
    'button.gong.title': () => t('videoConference.button.gong.title'),
    'button.enableAlwaysOnTop.title': () => t('videoConference.button.enableAlwaysOnTop.title'),
    'button.disableAlwaysOnTop.title': () => t('videoConference.button.disableAlwaysOnTop.title'),
    'audioOutput.label': () => t('videoConference.audioOutput.label'),
    'audioInput.label': () => t('videoConference.audioInput.label'),
    'videoInput.label': () => t('videoConference.videoInput.label'),
    'button.ok.text': () => t('videoConference.button.ok.text'),
    'button.join.text': () => t('videoConference.button.join.text'),
    'button.cancel.text': () => t('videoConference.button.cancel.text'),
    'e2eeEnabled.title': () => t('videoConference.e2eeEnabled.title'),
    'e2eeDisabled.title': () => t('videoConference.e2eeDisabled.title'),
    you: () => t('videoConference.you'),
    'unsupportedScriptVersionMsgBox.title': () =>
        t('videoConference.unsupportedScriptVersionMsgBox.title'),
    'unsupportedScriptVersionMsgBox.message': () =>
        t('videoConference.unsupportedScriptVersionMsgBox.message')
};
