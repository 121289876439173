import { ContactApi } from './ContactApi';
import * as types from '../../types/Types';
import * as PmxApi from 'privmx-server-api';
import { DataEncryptor } from './DataEncryptor';
import { EncKey } from './KeyProvider';
import { TagService } from './TagService';

export interface ContactProps {
    avatar: types.Avatar;
    name: types.ContactName;
    phone: types.Phone;
    mobilePhone: types.MobilePhone;
    address: types.ContactAddress;
    note: types.ContactNote;
    language: types.Language;
    isPrivate: boolean;
    privateContactVisibleForUsers: types.Username[];
}

export interface PmxContact {
    props: ContactProps;
    raw: PmxApi.api.contact.Contact;
    tags: types.Tag[];
}

export class ContactService {
    constructor(
        private contactApi: ContactApi,
        private encKey: EncKey,
        private propsEncryptor: DataEncryptor<ContactProps, PmxApi.api.contact.ContactProps>,
        private tagService: TagService
    ) {}

    async getContact(id: PmxApi.api.contact.ContactId) {
        const { contact } = await this.contactApi.getContact({ id });
        return this.decryptContact(contact);
    }

    async getContacts() {
        const { contacts } = await this.contactApi.getContacts();
        return Promise.all(contacts.map((x) => this.decryptContact(x)));
    }

    async createContact(model: {
        email: PmxApi.api.core.Email;
        username: PmxApi.api.core.Username | null;
        company: PmxApi.api.company.CompanyId | null;
        props: ContactProps;
        tags: string[];
    }) {
        const { contact } = await this.contactApi.createContact({
            company: model.company,
            email: model.email,
            username: model.username,
            props: await this.encryptContactProps(model.props),
            tags: await this.tagService.getEncryptedTagsIfSharedScope(model.tags as types.Tag[])
        });
        await this.tagService.setTagsIfPrivateScope(
            'contact',
            contact.id,
            model.tags as types.Tag[]
        );
        return this.decryptContact(contact);
    }

    async updateContact(model: {
        id: PmxApi.api.contact.ContactId;
        email: PmxApi.api.core.Email;
        username: PmxApi.api.core.Username | null;
        company: PmxApi.api.company.CompanyId | null;
        props: ContactProps;
        tags: string[];
    }) {
        const { contact } = await this.contactApi.updateContact({
            id: model.id,
            company: model.company,
            email: model.email,
            username: model.username,
            props: await this.encryptContactProps(model.props),
            tags: await this.tagService.setTagsOrGetEncrypted(
                'contact',
                model.id,
                model.tags as types.Tag[]
            )
        });
        return this.decryptContact(contact);
    }

    async deleteContact(id: PmxApi.api.contact.ContactId) {
        await this.contactApi.deleteContact({ id });
    }

    async decryptContact(contact: PmxApi.api.contact.Contact) {
        const tags = await this.tagService.getTags('contact', contact.id, 'all', contact);
        const res: PmxContact = {
            props: await this.decryptContactProps(contact.props),
            raw: contact,
            tags: tags
        };
        return res;
    }

    async getCompanyContacts(companyId: PmxApi.api.company.CompanyId) {
        const { contacts } = await this.contactApi.getCompanyContacts({ companyId });
        return Promise.all(contacts.map((x) => this.decryptContact(x)));
    }

    async toggleContactTag(contactId: PmxApi.api.contact.ContactId, tag: string, enabled: boolean) {
        const contact = await this.getContact(contactId);
        const newTags = await this.tagService.toggleTag(
            'contact',
            contactId,
            tag as types.Tag,
            contact,
            enabled
        );
        const newContact = await this.updateContact({
            id: contactId,
            email: contact.raw.email,
            username: contact.raw.username,
            company: contact.raw.company,
            props: contact.props,
            tags: newTags
        });
        return newContact;
    }

    private async encryptContactProps(props: ContactProps) {
        return this.propsEncryptor.encrypt(props, this.encKey);
    }

    private async decryptContactProps(props: PmxApi.api.contact.ContactProps) {
        return this.ensureContactPropsBackCompatibility(
            await this.propsEncryptor.decrypt(props, this.encKey)
        );
    }

    private ensureContactPropsBackCompatibility(props: ContactProps): ContactProps {
        if (
            typeof props.isPrivate === 'undefined' ||
            typeof props.privateContactVisibleForUsers === 'undefined'
        ) {
            props.isPrivate = false;
            props.privateContactVisibleForUsers = [];
        }
        return props;
    }
}
