import { Text } from '@mantine/core';

function Empty(props: { info: string }) {
    return (
        <Text mt={200} color="dimmed" align="center">
            {props.info}
        </Text>
    );
}

export default Empty;
