import {
    Box,
    Group,
    Overlay,
    ActionIcon,
    rem,
    Flex,
    Button,
    TextInput,
    Transition,
    Text,
    createStyles
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { IconPencil, IconEdit, IconDeviceFloppy, IconX, IconReload } from '@tabler/icons-react';
import { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Profile } from '../../api/privmx/UserPreferences';
import UserAvatar from '../../atoms/UserAvatar';
import { ImageCropper } from '../../components/ImageCropper/ImageCropper';
import CenteredModal from '../../components/ModalsOutlet/CenteredModal';
import { useAppSelector, useAppDispatch } from '../../store';
import { selectCurrentUser, setCurrentUser } from '../../store/CurrentUserSlice';
import { FileChooser, MaxFileSizeError } from '../../utils/FileChooser';
import { Avatar, Username } from '../../types/Types';
import { api } from '../../api/Api';
import { mutateCurrentUser } from '../../store/UsersSlice';
import { notifications } from '@mantine/notifications';
import { Limits } from '../../api/Limits';

const useUserInfoStyles = createStyles(() => {
    return {
        positionRelative: {
            position: 'relative'
        },
        avatarContainer: {
            '&:hover': {
                '& .edit-overlay': {
                    display: 'flex',
                    borderRadius: 999,
                    placeContent: 'center',
                    alignItems: 'center'
                },
                '& .indicator': {
                    display: 'none'
                }
            }
        },
        nameInput: {
            border: '1px solid rgba(0,0,0,0)',
            fontWeight: 'bolder',
            cursor: 'pointer',
            '&:hover': {
                border: '1px solid rgba(0,0,0,0.3)'
            },
            '&:focus': {
                border: '1px solid black'
            }
        },
        iconsWrapper: {
            overflow: 'hidden',
            width: 'max-content'
        }
    };
});

export function UserInfoConfig() {
    const currentUser = useAppSelector(selectCurrentUser);
    const dispatch = useAppDispatch();
    const { classes, cx } = useUserInfoStyles();
    const initialName = useRef(currentUser.name || currentUser.username || '');
    const [newName, setNewName] = useInputState(currentUser.name || currentUser.username || '');
    const [isNameDirty, setIsNameDirty] = useState<boolean>(false);
    const [settingName, setSettingName] = useState<boolean>(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (initialName.current !== newName) {
            setIsNameDirty(true);
        } else {
            setIsNameDirty(false);
        }
    }, [newName]);

    async function setNewUserName() {
        try {
            setSettingName(true);
            await api.updateCurrentUserProfile({ name: newName });
            initialName.current = newName;
            setIsNameDirty(false);
            dispatch(setCurrentUser({ ...currentUser, name: newName }));
        } catch (error) {
            console.error(error);
        }
        setSettingName(false);
    }

    async function clearNewUserName() {
        setNewName(initialName.current);
    }

    const [newAvatar, setNewAvatar] = useState<Avatar | undefined>(undefined);
    const [tmpNewAvatar, setTmpNewAvatar] = useState<string | undefined>(undefined);
    const setTmpNewAvatarEx = useCallback((newValue: File | undefined) => {
        setTmpNewAvatar((oldValue) => {
            if (oldValue) {
                URL.revokeObjectURL(oldValue);
            }
            if (newValue) {
                const objectUrl = URL.createObjectURL(newValue);
                return objectUrl;
            }
            return undefined;
        });
    }, []);
    const handleUploadAvatarClick = useCallback(async () => {
        try {
            const file = await FileChooser.chooseFile(
                'image/png, image/jpeg, .jpg',
                Limits.MAX_AVATAR_FILE_SIZE_B
            );
            if (file) {
                setTmpNewAvatarEx(file);
            }
        } catch (err) {
            if (err instanceof MaxFileSizeError) {
                notifications.show({
                    message: err.message,
                    color: 'red'
                });
            }
        } finally {
        }
    }, [setTmpNewAvatarEx]);

    const handleResetNewAvatar = async () => {
        const userWithUpdatedAvatar: Partial<Profile> = {
            image: undefined
        };
        const fallbackUser = { ...currentUser };
        dispatch(
            setCurrentUser({
                ...currentUser,
                ...userWithUpdatedAvatar
            })
        );

        try {
            await api.updateCurrentUserProfile(userWithUpdatedAvatar, true);
            dispatch(
                mutateCurrentUser({
                    currentUser: currentUser.username || ('' as Username),
                    newUser: { avatar: undefined }
                })
            );
        } catch (error) {
            notifications.show({
                title: 'Unable to change avatar',
                message: 'Try again later',
                color: 'red'
            });
            dispatch(setCurrentUser({ ...fallbackUser }));
            console.error(error);
        } finally {
            setNewAvatar(undefined);
        }
    };

    const handleCancelEditing = useCallback(() => {
        setNewAvatar(undefined);
    }, []);

    const handleSaveAvatar = async () => {
        if (!newAvatar) return;

        const userWithUpdatedAvatar: Partial<Profile> = {
            image: newAvatar
        };
        const fallbackUser = { ...currentUser };
        dispatch(
            setCurrentUser({
                ...currentUser,
                ...userWithUpdatedAvatar
            })
        );
        try {
            await api.updateCurrentUserProfile(userWithUpdatedAvatar);
            dispatch(
                mutateCurrentUser({
                    currentUser: currentUser.username || ('' as Username),
                    newUser: { avatar: newAvatar }
                })
            );
        } catch (error) {
            notifications.show({
                title: 'Unable to change avatar',
                message: 'Try again later',
                color: 'red'
            });
            dispatch(setCurrentUser({ ...fallbackUser }));
            console.error(error);
        } finally {
            setNewAvatar(undefined);
        }
    };

    const croppedNewAvatarRef = useRef<(() => Promise<string>) | null>(null);
    const handleAvatarPreviewModalClose = useCallback(async () => {
        const fileData = croppedNewAvatarRef.current
            ? ((await croppedNewAvatarRef.current()) as Avatar)
            : undefined;
        if (fileData && fileData.length > 1048576) {
            notifications.show({
                title: t('errorMessage.avatarFileTooLarge'),
                message: 'Please upload smaller resolution photo',
                color: 'red'
            });
            setNewAvatar(undefined);
        } else {
            setNewAvatar(fileData);
        }
        setTmpNewAvatarEx(undefined);
    }, [t, setTmpNewAvatarEx]);
    const handleAvatarPreviewModalCancel = useCallback(() => {
        setNewAvatar(undefined);
        setTmpNewAvatarEx(undefined);
    }, [setTmpNewAvatarEx]);

    return (
        <Box my="xl">
            <Group mb="xs">
                {currentUser.username && (
                    <div className={cx(classes.avatarContainer, classes.positionRelative)}>
                        <Overlay
                            opacity={0.5}
                            onClick={handleUploadAvatarClick}
                            className="edit-overlay"
                            display="none">
                            <ActionIcon variant="transparent">
                                <IconPencil />
                            </ActionIcon>
                        </Overlay>
                        <div className={classes.positionRelative}>
                            <UserAvatar
                                avatarSrc={newAvatar}
                                showTooltip={false}
                                size={60}
                                username={currentUser.username}
                            />
                            <ActionIcon
                                className="indicator"
                                opacity={0.6}
                                variant="filled"
                                size={'xs'}
                                radius={999}
                                pos="absolute"
                                bottom={0}
                                right={0}>
                                <IconEdit size={rem(12)} />
                            </ActionIcon>
                        </div>
                    </div>
                )}
                {currentUser.username && tmpNewAvatar && (
                    <CenteredModal
                        onClose={handleAvatarPreviewModalClose}
                        opened={true}
                        size={500}
                        height={400}
                        centered
                        zIndex={300}
                        withCloseButton
                        title="Avatar preview and cropping">
                        <Flex direction="column" justify="space-around" h="92%">
                            <ImageCropper
                                resultRef={croppedNewAvatarRef}
                                imageUrl={tmpNewAvatar}
                                viewportWidth={200}
                                viewportHeight={200}
                                viewportShape="circle"
                                targetWidth={200}
                                targetHeight={200}
                            />
                            <Flex justify="space-around">
                                <Button
                                    variant="light"
                                    size="xs"
                                    onClick={handleAvatarPreviewModalClose}>
                                    Use image
                                </Button>
                                <Button
                                    variant="outline"
                                    size="xs"
                                    onClick={handleAvatarPreviewModalCancel}>
                                    Cancel
                                </Button>
                            </Flex>
                        </Flex>
                    </CenteredModal>
                )}
                <div>
                    <Group>
                        <TextInput
                            value={newName}
                            onChange={setNewName}
                            size="xs"
                            classNames={{
                                input: classes.nameInput
                            }}
                        />
                        <div className={classes.iconsWrapper}>
                            <Transition mounted={isNameDirty} transition="slide-right">
                                {(styles) => (
                                    <Group spacing={4} style={styles}>
                                        <ActionIcon
                                            loading={settingName}
                                            onClick={setNewUserName}
                                            color="green"
                                            size="sm">
                                            <IconDeviceFloppy size={rem(14)} />
                                        </ActionIcon>
                                        <ActionIcon
                                            onClick={clearNewUserName}
                                            color="red"
                                            size="sm">
                                            <IconX size={rem(14)} />
                                        </ActionIcon>
                                    </Group>
                                )}
                            </Transition>
                        </div>
                    </Group>
                    <Text size="xs" ml="xs">
                        {currentUser.email}
                    </Text>
                </div>
                {newAvatar && (
                    <>
                        <Button
                            leftIcon={<IconDeviceFloppy size={rem(14)} />}
                            variant="light"
                            size="xs"
                            onClick={handleSaveAvatar}>
                            Save avatar
                        </Button>
                        <Button
                            leftIcon={<IconX size={rem(14)} />}
                            variant="outline"
                            size="xs"
                            onClick={handleCancelEditing}>
                            Cancel
                        </Button>
                        <Button
                            leftIcon={<IconReload size={rem(14)} />}
                            variant="outline"
                            size="xs"
                            onClick={handleResetNewAvatar}>
                            Reset avatar
                        </Button>
                    </>
                )}
            </Group>
        </Box>
    );
}
