import Icon, { IconDef } from '../../components/Icon';
import { Text, Tooltip } from '@mantine/core';
import {
    displayFormatedTimeOfDay,
    displayRelativeTime,
    displayformatedTime
} from '../../utils/RelativeTimeFormat';

export default function Timestamp(props: {
    date: number;
    icon?: IconDef;
    className?: string;
    displayTimeOfDay?: boolean;
}) {
    return (
        <Tooltip label={displayformatedTime(props.date)}>
            <Text component="span" className={props.className}>
                {props.icon && <Icon icon={props.icon} />}
                {props.displayTimeOfDay
                    ? displayFormatedTimeOfDay(props.date)
                    : displayRelativeTime(props.date)}
            </Text>
        </Tooltip>
    );
}
