import { CompanyId, CompanyWithContacts } from '../../types/Types';
import { useEffect, useMemo, useRef } from 'react';
import {
    Box,
    Divider,
    Flex,
    Group,
    Paper,
    TextInput,
    Text,
    Button,
    MediaQuery
} from '@mantine/core';
import RouteListContainer from '../../atoms/RouteScreen/RouteListContainer';
import { useSortCompanies } from '../../hooks/useSort';
import ScreenScrollContainer from '../../mantineAtoms/ScreenScrollContainer';
import { IconSearch } from '@tabler/icons-react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { NoItemsInfo } from '../../atoms/NoItemsInfo';
import { useInputState } from '@mantine/hooks';
import { useContactStyleElement } from '../ContactsScreen/ContactListElement/ContactListElementStyles.styles';
import { UsersList } from '../ChatScreen/components/UserList';
import { useAppDispatch, useAppSelector } from '../../store';
import { toggleNewComapnyModal } from '../../store/ModalsSlice';
import { EmptyState } from '../../mantineAtoms/EmptyState/EmptyState';
import { useTranslation } from 'react-i18next';
import { useDividedViewStyles } from '../ContactsScreen/ContactsList';
import { toggleSidebar } from '../../store/UserPreferenceSlice';
import { selectCurrentUser } from '../../store/CurrentUserSlice';

export type TabId = 'current' | 'all' | 'pinned' | 'archived';

export function isTabId(activeTab: unknown): activeTab is TabId {
    return ['current', 'all', 'pinned', 'archived'].includes(activeTab as string);
}

export function resolveActiveTab(activeTab: unknown) {
    return isTabId(activeTab) ? activeTab : undefined;
}

interface CompaniesScreenViewProps {
    companies: CompanyWithContacts[];
    activeCompanyId?: CompanyId;
    onCompanyChoose: (company: CompanyWithContacts) => void;
    filter: string;
    onFilterSubmit: (filter: string) => void;
    activeTab: TabId;
    onTabChange: (tab: TabId) => void;
}

export default function CompaniesScreenView(props: CompaniesScreenViewProps) {
    return <CompaniesList {...props} />;
}

function CompaniesList(props: CompaniesScreenViewProps) {
    const companies = useSortCompanies(props.companies);
    const { t } = useTranslation();
    const [query, setQuery] = useInputState('');

    const filteredCompanies = useMemo(() => {
        return companies.filter((x) => x.name.toLowerCase().includes(query.toLowerCase()));
    }, [companies, query]);

    const dispatch = useAppDispatch();
    const { companyId } = useParams();

    const { classes } = useDividedViewStyles({ contactId: companyId });

    if (props.companies.length === 0) {
        return (
            <RouteListContainer>
                <EmptyState
                    image="NO_USERS"
                    title={'screen.companies.emptyState.noCompaniesYet'}
                    subTitle={'screen.companies.emptyState.subTitle'}
                    button={
                        <Button
                            onClick={() => {
                                dispatch(toggleNewComapnyModal({ initialUsers: [], opened: true }));
                            }}>
                            {'screen.companies.actions.newCompany'}
                        </Button>
                    }
                />
            </RouteListContainer>
        );
    }
    function showSidebar() {
        dispatch(toggleSidebar({}));
    }
    return (
        <RouteListContainer direction="row">
            <Paper
                sx={{
                    flexGrow: 1,
                    flexDirection: 'column',
                    display: 'flex',
                    borderTopRightRadius: 16,
                    borderBottomRightRadius: 16
                }}>
                <Group noWrap my="md" px="md" position="apart">
                    <MediaQuery largerThan={'sm'} styles={{ display: 'none' }}>
                        <Button onClick={showSidebar}>{'screen.companies.menu'}</Button>
                    </MediaQuery>
                    <TextInput
                        placeholder={t('placeholder.searchCompany')}
                        icon={<IconSearch size={14} />}
                        value={query}
                        onChange={setQuery}
                        size="xs"
                        sx={{ flexGrow: 1 }}
                    />
                    <Button
                        variant="light"
                        size="xs"
                        onClick={() =>
                            dispatch(toggleNewComapnyModal({ initialUsers: [], opened: true }))
                        }>
                        {t('screen.companies.actions.newCompany')}
                    </Button>
                </Group>
                <ScreenScrollContainer>
                    {filteredCompanies.map((item, i) => (
                        <CompanyListElement
                            active={companyId === item.id}
                            key={item.id}
                            company={item}
                        />
                    ))}
                    {filteredCompanies.length === 0 && <NoItemsInfo itemsType="contacts" />}
                </ScreenScrollContainer>
            </Paper>
            <Divider orientation="vertical" h="95%" my="auto" opacity={0.4} />
            <Paper className={classes.contactDetail}>
                <Outlet />
            </Paper>
        </RouteListContainer>
    );
}

export function CompanyListElement({
    company,
    onClick,
    active,
    hovered
}: {
    hovered?: boolean;
    onClick?: (company: CompanyWithContacts, e: MouseEvent) => void;
    active?: boolean;
    company: CompanyWithContacts;
}) {
    const { classes, cx } = useContactStyleElement();

    const navigate = useNavigate();

    function handleNavigate() {
        navigate(`${company.id}${document.location.search}`);
    }

    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!active) {
            return;
        }
        setTimeout(() => {
            containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 0);
    }, [active]);

    const currentUser = useAppSelector(selectCurrentUser);
    const filteredContacts = useMemo(() => {
        return company.contacts.filter(
            (x) =>
                !x.isPrivate ||
                (currentUser.username !== null &&
                    x.privateContactVisibleForUsers.includes(currentUser.username))
        );
    }, [company.contacts, currentUser]);

    return (
        <Box onClick={handleNavigate} className={cx(classes.root, { [classes.active]: active })}>
            <Flex align="center" justify="space-between">
                <Group spacing={'xs'} noWrap sx={{ flexGrow: 1, flexBasis: '40%', flexShrink: 1 }}>
                    <Box>
                        <Text truncate="end" size={13}>
                            {company.name}
                        </Text>
                    </Box>
                </Group>
                <Flex
                    align={'center'}
                    justify={'flex-end'}
                    sx={{ flexGrow: 1, flexBasis: 'auto' }}
                    ml="auto">
                    <UsersList
                        maxCount={'auto'}
                        className={classes.tags}
                        usernames={filteredContacts.map((x) =>
                            x.username
                                ? { type: 'user', username: x.username }
                                : { type: 'contact', contactId: x.id }
                        )}
                    />
                </Flex>
            </Flex>
        </Box>
    );
}
