import { detect, BrowserInfo } from 'detect-browser';

type Requirement = Pick<BrowserInfo, 'version'>;

export class BrowserDetector {
    private static ourRequirements: { [browserName: string]: Requirement } = {
        chrome: { version: '94' },
        safari: { version: '16.4' },
        'edge-chromium': { version: '94' },
        firefox: { version: '93' },
        opera: { version: '80' },
        ios: { version: '16.0.0' } // safari mobile
    };

    public static isBrowserSupported(): boolean {
        const browser = detect();
        if (browser && browser.name in this.ourRequirements) {
            const actual: Requirement = browser as BrowserInfo;
            const required = this.ourRequirements[browser.name];
            if (this.doesVersionMeetRequirements(required.version, actual.version)) {
                return true;
            }
        }
        console.warn('Browser not supported. ', browser);
        return false;
    }

    private static doesVersionMeetRequirements(requiredString: string, actualString: string) {
        const required = requiredString
            .split('.')
            .map((x) => (!Number.isNaN(x) ? Number.parseInt(x) : 0));
        const actual = actualString
            .split('.')
            .map((x) => (!Number.isNaN(x) ? Number.parseInt(x) : 0));

        const versionLength = Math.max(actual.length, required.length);
        for (let i = 0; i < versionLength; ++i) {
            if (i === actual.length) {
                return false;
            }
            if (i === required.length) {
                return true;
            }
            if (actual[i] > required[i]) {
                return true;
            }
            if (actual[i] === required[i]) {
                continue;
            }
            if (actual[i] < required[i]) {
                return false;
            }
        }
        return true;
    }
}
