import { Box, Button, Flex, Input } from '@mantine/core';
import { useCallback, useMemo, useRef } from 'react';
import * as FormsTypes from '../FormsTypes';
import { collectResponses } from '../utils/FormsUtils';
import { useTranslation } from 'react-i18next';
import FormBox from '../../../mantineAtoms/FormBox';
import FormGroup from './FormGroup';
import { useMediaQuery } from '@mantine/hooks';
import Icon, { iconLoading } from '../../Icon';

export default function FormFactory({
    questions,
    onFormSubmit,
    getResponseFromState,
    getErrorsFromState,
    setDirtyState,
    setSelectedFilesState,
    isProcessing
}: {
    questions: FormsTypes.QuestionModel[];
    onFormSubmit: (responses: FormsTypes.Responses) => Promise<void>;
    getResponseFromState: (questionId: string) => FormsTypes.QuestionResponse | undefined;
    getErrorsFromState: (questionId: string) => FormsTypes.InputError[];
    setDirtyState: (state: boolean) => void;
    setSelectedFilesState: (questionId: string, files: File[]) => void;
    isProcessing: () => boolean;
}) {
    const { t } = useTranslation();
    const mobileScreen = useMediaQuery('(max-width: 40em)');
    const [refs, RegisterInputRef] = useMemo(() => {
        const refs = {} as FormsTypes.RefsMap;
        const RegisterInputRef = (model: FormsTypes.AnswerInputModel) => {
            refs[model.id] = useRef<HTMLInputElement>();
            return refs[model.id];
        };
        return [refs, RegisterInputRef];
    }, []);

    const handleSubmit = useCallback(
        async (e: any) => {
            e.preventDefault();
            const responses = collectResponses(questions, refs);
            await onFormSubmit(responses);
        },
        [onFormSubmit, questions, refs]
    );

    const isDirty = useCallback(() => {
        return Object.values(refs).filter((x) => x.current.isDirty).length > 0;
    }, [refs]);

    const updateDirty = useCallback(() => {
        setDirtyState(isDirty());
    }, [isDirty, setDirtyState]);

    const getQuestionResponseFromState = useCallback(
        (questionId: string) => {
            return getResponseFromState(questionId as FormsTypes.QuestionId);
        },
        [getResponseFromState]
    );

    return (
        <Box component={'form'} onSubmit={handleSubmit}>
            <Flex w="100%" direction="column" align="center" justify="start">
                {questions.map((question) => (
                    <FormBox key={question.id} w={mobileScreen ? '100%' : '40em'}>
                        {question.text && (
                            <Box mb="md">
                                <Input.Label required={question.validation?.required}>
                                    {question.text}
                                </Input.Label>
                                <Input.Error>
                                    {getErrorsFromState(question.id).map(
                                        (errorItem) => errorItem.msg + '\n'
                                    )}
                                </Input.Error>
                            </Box>
                        )}
                        <FormGroup
                            question={question}
                            responseState={getQuestionResponseFromState(question.id)}
                            registerInputRef={RegisterInputRef}
                            updateDirty={updateDirty}
                            updateSelectedFiles={(files: File[]) => {
                                setSelectedFilesState(question.id, files);
                            }}
                        />
                    </FormBox>
                ))}
            </Flex>
            {isProcessing() ? (
                <Button
                    mx="auto"
                    display="block"
                    type="submit"
                    disabled
                    leftIcon={<Icon icon={iconLoading} spin={true} />}>
                    {t('action.send')}
                </Button>
            ) : (
                <Button mx="auto" display="block" type="submit">
                    {t('action.send')}
                </Button>
            )}
        </Box>
    );
}
