import { PasswordGenerator } from './userCreation/PasswordGenerator';
import * as privmx from 'privfs-client';
import * as PmxApi from 'privmx-server-api';
import { AdminDataManagable } from './userCreation/Types';
import { AdminApi } from '../AdminApi';
import { AdminDataV2Encryptor } from './AdminDataV2Encryptor';

export class ResetPasswordService {
    private resetPasswordApi: AdminApi;

    constructor(
        private gateway: privmx.gateway.RpcGateway,
        private adminDataV2Encryptor: AdminDataV2Encryptor
    ) {
        this.resetPasswordApi = new AdminApi(this.gateway);
    }

    async resetUserPassword(
        username: PmxApi.api.core.Username,
        adminData: AdminDataManagable
    ): Promise<{ generatedPassword: string }> {
        const model = await this.resetPasswordApi.adminGetUserSrp({ username });
        const login = this.detectLogin(model);
        const generatedPassword = PasswordGenerator.generatedTemporaryPassword();
        const recovery = await this.parseRecovery(adminData.recovery);
        const srpResult = await this.prepareSrp(recovery, model, login, generatedPassword);
        const adminDataBase64 = await this.preapreNewAdminData(adminData, generatedPassword);
        await this.sendRequest(username, login, srpResult, adminDataBase64);
        return { generatedPassword };
    }

    private detectLogin(model: PmxApi.api.admin.UserSrp) {
        if (model.srpLogins.length === 0) {
            throw new Error('No srp login is avaialable');
        }
        return model.srpLogins[0];
    }

    private parseRecovery(recovery: string) {
        const buffer = Buffer.from(recovery, 'base64');
        return privmx.core.LoginByKeyUtils.getBip39(buffer);
    }

    private async prepareSrp(
        bip39: privmx.crypto.crypto.Interfaces.Bip39,
        model: PmxApi.api.admin.UserSrp,
        login: PmxApi.api.user.UserLogin,
        generatedPassword: string
    ) {
        const masterRecordKey = privmx.core.LoginByKeyUtils.getMasterRecordKeyFromBip39(bip39);
        const decryptedMasterRecord = await privmx.core.MasterRecordUtils.decrypt(
            this.convertModel(bip39, model),
            masterRecordKey,
            privmx.types.core.LoginMode.LBK
        );
        if (decryptedMasterRecord.version !== '2.0') {
            throw new Error('Unsupported privaDataInfo version');
        }
        return privmx.core.SrpUtils.generateSrpParamsAndEncryptL1Key(
            this.convertSrpInfo(model.srpInfo),
            login,
            generatedPassword,
            decryptedMasterRecord.l1Key
        );
    }

    private convertModel(
        bip39: privmx.crypto.crypto.Interfaces.Bip39,
        model: PmxApi.api.admin.UserSrp
    ): privmx.types.core.MasterRecordApiModel {
        const pub = bip39.extKey.getPublicKey().toBase58DER();
        const lbk = model.lbkData.find((x) => x.pub === pub);
        if (lbk === undefined) {
            throw new Error('There is no lbk data matching the given recovery');
        }
        return {
            version: model.version,
            privData: model.privData,
            privDataL2: model.privDataL2,
            recoveryData: null as any,
            srpData: null as any,
            lbkData: lbk.data,
            serverKey: null as any
        };
    }

    private convertSrpInfo(srpInfo: PmxApi.api.user.SrpInfo): privmx.types.core.SrpInfo {
        return {
            N: Buffer.from(srpInfo.N, 'hex'),
            g: Buffer.from(srpInfo.g, 'hex')
        };
    }

    private async preapreNewAdminData(adminData: AdminDataManagable, generatedPassword: string) {
        const newAdminData: AdminDataManagable = {
            masterSeed: adminData.masterSeed,
            recovery: adminData.recovery,
            generatedPassword: generatedPassword
        };
        const encrypted = await this.adminDataV2Encryptor.encrypt(newAdminData);
        return encrypted;
    }

    private sendRequest(
        username: PmxApi.api.core.Username,
        login: PmxApi.api.user.UserLogin,
        srpResult: privmx.types.core.SrpParamsWithEncryptedMasterRecordL1Key,
        adminDataBase64: PmxApi.api.core.Base64
    ) {
        return this.resetPasswordApi.adminSetUserSrp({
            username: username,
            srp: {
                login: login,
                salt: srpResult.salt.toString('hex') as PmxApi.api.core.Hex,
                verifier: srpResult.verifier.toString('hex') as PmxApi.api.core.Hex,
                params: srpResult.params as PmxApi.api.user.SrpParams,
                data: srpResult.encryptedL1.toString('base64') as PmxApi.api.core.Base64,
                weakPassword: false
            },
            disable2FA: true,
            adminData: adminDataBase64,
            adminDataV2: false,
            generatedPassword: true
        });
    }
}
