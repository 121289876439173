import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import {
    selectCompanyModalOpen,
    selectCompanyModalPayload,
    selectCompanyModalState
} from '../../store/ModalsSlice';
import { Company } from '../../types/Types';
import { loadCompaniesAsync, selectCachedCompanies } from '../../store/DataCacheSlice';
import { CompanyModal } from './CompanyModal';

export default function CompanyModalOutlet() {
    const companyOpen = useAppSelector(selectCompanyModalOpen);
    const newCompanyPayload = useAppSelector(selectCompanyModalPayload);
    const modalState = useAppSelector(selectCompanyModalState);

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(loadCompaniesAsync);
    }, [dispatch]);

    const companies = useAppSelector(selectCachedCompanies);
    const company: Company | undefined = useMemo(() => {
        if (modalState === 'new') {
            const newCompany: Company = {
                id: '' as Company['id'],
                name: newCompanyPayload.newCompany?.name as Company['name'],
                email: '' as Company['email'],
                website: '' as Company['website'],
                address: '' as Company['address'],
                phone: '' as Company['phone'],
                mobilePhone: '' as Company['mobilePhone'],
                note: '' as Company['note'],
                tags: [] as Company['tags'],
                pinned: false,
                archived: false,
                createdBy: '' as Company['createdBy'],
                createdDate: 0 as Company['createdDate'],
                lastModifiedBy: '' as Company['lastModifiedBy'],
                lastModifiedDate: 0 as Company['lastModifiedDate']
            };
            return newCompany;
        }
        return companies.find((x) => x.id === newCompanyPayload.company);
    }, [companies, newCompanyPayload.company, modalState, newCompanyPayload.newCompany]);

    if (!company) return <></>;
    if (modalState === 'new') {
        return (
            <CompanyModal
                isNew
                newCompanyContacts={newCompanyPayload.newCompany?.contacts || []}
                company={company}
                opened={companyOpen}
                allCompanies={companies}
            />
        );
    }

    return (
        <CompanyModal
            key={company.id}
            company={company}
            opened={companyOpen}
            allCompanies={companies}
        />
    );
}
