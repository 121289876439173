import React, { useCallback, useState } from 'react';
import { Company, Contact, ContactId, Username } from '../../types/Types';
import Loading from '../Loading';
import PageHeader from '../PageHeader';
import Field from '../../atoms/Field';
import Icon, { iconAdd } from '../Icon';
import { api } from '../../api/Api';
import useModal from '../../hooks/useModal';
import StateView from '../../atoms/State';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import {
    Group,
    Title,
    Stack,
    Flex,
    SimpleGrid,
    Card,
    Text,
    Tabs,
    ScrollArea,
    Button,
    Box
} from '@mantine/core';
import FileList from '../../screens/FilesScreen/FIleList';
import { FileListElement } from '../../screens/FilesScreen/FileListElement';
import { useNavigate } from 'react-router-dom';
import MeetingDetailLink from '../../screens/MeetingsScreen/MeetingDetailLink';
import { FormLink } from '../../screens/FormsScreen/FormsScreenView';
import { ElementAnchor } from '../../atoms/ElementAnchor/ElementAnchor';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import { StickyAddButton } from '../../mantineAtoms/AddButton';
import { useSortChats, useSortForms, useSortMeetings } from '../../hooks/useSort';
import LinkWithFilter from '../../mantineAtoms/SearchBar/LinkWithFilter';
import { toggleNewMeetingModal, toggleNewMessageModal } from '../../store/ModalsSlice';
import { useTranslation } from 'react-i18next';
import { ChatListElement } from '../../screens/ChatScreen/components/ChatListElement';
import { notifications } from '@mantine/notifications';

export default function ContactPage(props: { id: ContactId }) {
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForContactWithCompany(props.id), [props.id])
    );
    return (
        <StateView state={state} retry={revalidate}>
            {(data) => (
                <Box h="100svh">
                    <ContactPageView
                        contact={data.contact}
                        company={data.company}
                        onRefresh={revalidate}
                        goToBtn={true}
                    />
                </Box>
            )}
        </StateView>
    );
}

export function ContactPageView(props: {
    contact: Contact;
    company?: Company;
    onRefresh: () => void;
    onGoTo?: () => void;
    goToBtn: boolean;
}) {
    const { t } = useTranslation();
    const currentUser = useAppSelector(selectCurrentUser);
    const [isCreatingUser, setIsCreatingUser] = useState<boolean>(false);
    const user = props.contact.username ? api.getUser(props.contact.username) : undefined;
    const { state } = useDataSubscribion((x) =>
        x.subscribeForUserData(props.contact.username || ('' as Username))
    );
    const navigate = useNavigate();
    const modalService = useModal();
    const [activeTab, setActiveTab] = useState<string | null>('Messages');
    const onGoTo = props.onGoTo;

    const sortedChats = useSortChats(state.type === 'done' ? state.data.chats : null);
    const sortedMeetings = useSortMeetings(state.type === 'done' ? state.data.meetings : null);
    const sortedForms = useSortForms(state.type === 'done' ? state.data.forms : null);

    const handleGoToClick = useCallback(() => {
        if (onGoTo) {
            onGoTo();
        } else {
            modalService.openContactModal({ id: props.contact.id });
        }
    }, [onGoTo, props.contact.id, modalService]);

    const handleCreateUserClick = useCallback(async () => {
        try {
            setIsCreatingUser(true);
            await api.createInternalUserFromContact(props.contact, { type: 'manuallyFromContact' });
            notifications.show({ message: t('successMessage.userCreated'), autoClose: true });
        } catch {
            notifications.show({
                message: t('errorMessage.couldNotCreateUser'),
                color: 'red',
                autoClose: true
            });
        } finally {
            setIsCreatingUser(false);
        }
    }, [props.contact, t]);
    const dispatch = useAppDispatch();
    const handleCreateChatClick = useCallback(() => {
        if (currentUser.username === null) {
            return;
        }
        dispatch(
            toggleNewMessageModal({
                open: true,
                payload: {
                    users: [
                        { type: 'user', username: currentUser.username },
                        { type: 'contact', contactId: props.contact.id }
                    ]
                }
            })
        );
    }, [dispatch, props.contact.id, currentUser.username]);

    const handleCreateMeetingClick = useCallback(() => {
        if (currentUser.username === null) {
            return;
        }
        dispatch(
            toggleNewMeetingModal({
                open: true,
                payload: {
                    users: [
                        { type: 'user', username: currentUser.username },
                        { type: 'contact', contactId: props.contact.id }
                    ]
                }
            })
        );
    }, [dispatch, props.contact.id, currentUser.username]);

    if (props.contact.id === '') {
        return <Loading />;
    }

    if (state.type === 'error') {
        return <Text>Error</Text>;
    }

    return (
        <Tabs
            h="100%"
            defaultValue="Messages"
            value={activeTab}
            onTabChange={(value) => setActiveTab(value)}>
            <Flex direction="column" h="100%">
                <Stack p="md">
                    <Group position="apart" mb="md">
                        <Group>
                            <Title fz={{ base: 'md', xl: 'lg' }}>
                                {props.contact.name || props.contact.email.split('@')[0] || ''}
                            </Title>
                        </Group>
                        <PageHeader
                            onRefresh={props.onRefresh}
                            goToBtn={props.goToBtn}
                            onGoTo={handleGoToClick}>
                            {!user && (
                                <Button
                                    onClick={handleCreateUserClick}
                                    loading={isCreatingUser}
                                    leftIcon={<Icon icon={iconAdd} />}>
                                    Create user
                                </Button>
                            )}
                        </PageHeader>
                    </Group>
                    <SimpleGrid cols={2}>
                        <Field label={t('entityProps.email')} value={props.contact.email} />
                        <Field label={t('entityProps.address')} value={props.contact.address} />
                        <Field label={t('entityProps.telephone')} value={props.contact.phone} />
                        <Field
                            label={t('entityProps.company')}
                            value={props.company ? props.company.name : '-'}
                        />
                        <div>
                            <Text color="dimmed" size="xs" mb="xs">
                                Note
                            </Text>
                            <Card
                                component={ScrollArea.Autosize}
                                p={0}
                                mah={100}
                                withBorder
                                maw={700}>
                                <Text p="sm">{props.contact.note}</Text>
                            </Card>
                        </div>
                    </SimpleGrid>
                </Stack>
                <Data show={Boolean(props.contact.username)}>
                    <Tabs.List position="center">
                        <Tabs.Tab value="Messages">{t('tab.messages')}</Tabs.Tab>
                        <Tabs.Tab value="Files">{t('tab.files')}</Tabs.Tab>
                        <Tabs.Tab value="Meetings">{t('tab.meetings')}</Tabs.Tab>
                        <Tabs.Tab value="Forms">{t('tab.forms')}</Tabs.Tab>
                    </Tabs.List>
                    {user?.username ? (
                        <LinkWithFilter to={'chat'} filter={{ users: [user.username] }}>
                            {t('action.goToUserThreads')}
                        </LinkWithFilter>
                    ) : (
                        <></>
                    )}
                    <ScrollArea sx={{ flexGrow: 1 }}>
                        {state.type === 'loading' ? (
                            <Loading />
                        ) : (
                            <>
                                <Tabs.Panel value="Messages" pos="relative">
                                    {sortedChats.map((item) => (
                                        <ChatListElement key={item.id} active={false} chat={item} />
                                    ))}

                                    <StickyAddButton onClick={handleCreateChatClick}>
                                        {t('action.createThread')}
                                    </StickyAddButton>
                                </Tabs.Panel>
                                <Tabs.Panel value="Files">
                                    <FileList
                                        attachments={state.data.attachments}
                                        element={(file) => (
                                            <FileListElement
                                                active={false}
                                                file={file}
                                                onClick={() => {
                                                    navigate(`/files/${file.id}`);
                                                }}
                                            />
                                        )}
                                    />
                                </Tabs.Panel>
                                <Tabs.Panel value="Meetings">
                                    {sortedMeetings.map((meeting) => (
                                        <ElementAnchor
                                            key={meeting.id}
                                            to={`/meetings/${meeting.id}`}>
                                            <MeetingDetailLink
                                                meeting={meeting}
                                                editFn={() => {}}
                                                onClick={() => {}}
                                                active={false}
                                            />
                                        </ElementAnchor>
                                    ))}
                                    <StickyAddButton onClick={handleCreateMeetingClick}>
                                        {t('action.createMeeting')}
                                    </StickyAddButton>
                                </Tabs.Panel>
                                <Tabs.Panel value="Forms">
                                    {sortedForms.map((form) => (
                                        <ElementAnchor key={form.id} to={`/forms/${form.id}`}>
                                            <FormLink
                                                form={form}
                                                active={false}
                                                onClick={() => {}}
                                            />
                                        </ElementAnchor>
                                    ))}
                                </Tabs.Panel>
                            </>
                        )}
                    </ScrollArea>
                </Data>
            </Flex>
        </Tabs>
    );
}

function Data(props: { children: React.ReactNode; show: boolean }) {
    if (!props.show) return <div></div>;

    return <>{props.children}</>;
}
