import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { api } from '../api/Api';
import { useAppSelector } from '../store';
import {
    selectCachedAttachments,
    selectCachedChats,
    selectCachedForms,
    selectCachedMeetings
} from '../store/DataCacheSlice';
import * as types from '../types/Types';

interface AreMessagesInThreadUnreadQuery {
    type: 'messagesInThread';
    threadId: types.ChatId | types.MeetingId;
}

interface IsThreadMessageUnreadQuery {
    type: 'threadMessage';
    message: types.Message;
}

interface AreSubmitsInFormUnreadQuery {
    type: 'submitsInForm';
    formId: types.FormId;
}

interface IsFormSubmitUnreadQuery {
    type: 'formSubmit';
    formSubmit: types.FormRow;
}

interface AreSubmitsInFormUnreadDeepQuery {
    type: 'submitsInFormDeep';
    formId: types.FormId;
}

interface IsFormSubmitUnreadDeepQuery {
    type: 'formSubmitDeep';
    formSubmit: types.FormRow;
}

interface IsAttachmentUnreadQuery {
    type: 'attachment';
    attachmentId: types.AttachmentId;
}

interface AreMessagesInAnyChatUnreadQuery {
    type: 'messagesInAnyChat';
    chatIds: types.ChatId[];
}

interface AreMessagesInAnyMeetingUnreadQuery {
    type: 'messagesInAnyMeeting';
    meetingIds: types.MeetingId[];
}

interface AreSubmitsInAnyFormUnreadQuery {
    type: 'submitsInAnyForm';
    formIds: types.FormId[];
}

interface AreSubmitsInAnyFormUnreadDeepQuery {
    type: 'submitsInAnyFormDeep';
    formIds: types.FormId[];
}

interface IsAnyAttachmentUnreadQuery {
    type: 'anyAttachment';
    attachmentIds: types.AttachmentId[];
}

interface IsAnythingUnreadQuery {
    type: 'anything';
    chatIdsForMessagesCheck: types.ChatId[];
    meetingIdsForMessagesCheck: types.MeetingId[];
    chatIdsForAttachmentsCheck: types.ChatId[];
    meetingIdsForAttachmentsCheck: types.MeetingId[];
    formIds: types.FormId[];
    formIdsDeep: types.FormId[];
}

type IsUnreadQuery =
    | AreMessagesInThreadUnreadQuery
    | IsThreadMessageUnreadQuery
    | AreSubmitsInFormUnreadQuery
    | IsFormSubmitUnreadQuery
    | AreSubmitsInFormUnreadDeepQuery
    | IsFormSubmitUnreadDeepQuery
    | IsAttachmentUnreadQuery
    | AreMessagesInAnyChatUnreadQuery
    | AreMessagesInAnyMeetingUnreadQuery
    | AreSubmitsInAnyFormUnreadQuery
    | AreSubmitsInAnyFormUnreadDeepQuery
    | IsAnyAttachmentUnreadQuery
    | IsAnythingUnreadQuery;

function useIsUnread(query: IsUnreadQuery | null) {
    const checkIsUnread = useCallback(() => {
        if (!query) {
            return false;
        }
        const unreadService = api.getUnreadService();
        if (!unreadService) {
            return false;
        }
        let isRead: boolean = true;
        if (query.type === 'messagesInThread') {
            isRead = unreadService.areAllMessagesInThreadRead(query.threadId);
        } else if (query.type === 'threadMessage') {
            isRead = unreadService.isThreadMessageRead(query.message);
        } else if (query.type === 'submitsInForm') {
            isRead = unreadService.areAllSubmitsInFormRead(query.formId);
        } else if (query.type === 'formSubmit') {
            isRead = unreadService.isFormSubmitRead(query.formSubmit);
        } else if (query.type === 'submitsInFormDeep') {
            isRead = unreadService.areAllSubmitsInFormReadDeep(query.formId);
        } else if (query.type === 'formSubmitDeep') {
            isRead = unreadService.isFormSubmitReadDeep(query.formSubmit);
        } else if (query.type === 'attachment') {
            isRead = unreadService.isAttachmentRead(query.attachmentId);
        } else if (query.type === 'messagesInAnyChat') {
            isRead = unreadService.areAllMessagesInThreadsRead(query.chatIds);
        } else if (query.type === 'messagesInAnyMeeting') {
            isRead = unreadService.areAllMessagesInThreadsRead(query.meetingIds);
        } else if (query.type === 'submitsInAnyForm') {
            isRead = unreadService.areAllSubmitsInFormsRead(query.formIds);
        } else if (query.type === 'submitsInAnyFormDeep') {
            isRead = unreadService.areAllSubmitsInFormsReadDeep(query.formIds);
        } else if (query.type === 'anyAttachment') {
            isRead = unreadService.areAllAttachmentsRead(query.attachmentIds);
        } else if (query.type === 'anything') {
            isRead = unreadService.isEverythingRead(
                [...query.chatIdsForMessagesCheck, ...query.meetingIdsForMessagesCheck],
                [...query.chatIdsForAttachmentsCheck, ...query.meetingIdsForAttachmentsCheck],
                query.formIds,
                query.formIdsDeep
            );
        }
        return !isRead;
    }, [query]);

    const [isUnread, setIsUnread] = useState<boolean>(() => checkIsUnread());

    useEffect(() => {
        const updateIsUnread = () => {
            setIsUnread(checkIsUnread());
        };
        updateIsUnread();
        api.addEventListener('unreadstatechanged', updateIsUnread);
        return () => {
            api.removeEventListener('unreadstatechanged', updateIsUnread);
        };
    }, [checkIsUnread]);

    return isUnread;
}

export function useGetUnreadThreadIds(chatIds: types.ThreadId[]): types.ThreadId[] {
    const unreadService = useRef(api.getUnreadService());
    const [unreadIds, setUnreadIds] = useState<types.ThreadId[]>([]);
    const updateUnreadIds = useCallback(() => {
        if (!unreadService.current) {
            return [];
        }
        setUnreadIds(chatIds.filter((x) => !unreadService.current!.areAllMessagesInThreadRead(x)));
    }, [chatIds]);

    const [areFiltered, setAreFiltered] = useState<boolean>(false);
    useEffect(() => {
        if (!areFiltered) {
            updateUnreadIds();
            setAreFiltered(true);
        }
    }, [areFiltered, updateUnreadIds]);

    useEffect(() => {
        const updateIsUnread = () => {
            updateUnreadIds();
        };
        api.addEventListener('unreadstatechanged', updateIsUnread);
        return () => {
            api.removeEventListener('unreadstatechanged', updateIsUnread);
        };
    }, [updateUnreadIds]);

    return unreadIds;
}

export function useGetFirstUnreadMessageId(messages: types.Message[]): types.MessageId | null {
    const unreadService = useRef(api.getUnreadService());
    const [firstUnreadId, setFirstUnreadId] = useState<types.MessageId | null>(null);
    const updateFirstUnreadId = useCallback(() => {
        if (!unreadService.current) {
            return [];
        }
        setFirstUnreadId(
            messages.find((x) => !unreadService.current!.isThreadMessageRead(x))?.id ?? null
        );
    }, [messages]);

    useEffect(() => {
        const updateIsUnread = () => {
            updateFirstUnreadId();
        };
        updateFirstUnreadId();
        api.addEventListener('unreadstatechanged', updateIsUnread);
        return () => {
            api.removeEventListener('unreadstatechanged', updateIsUnread);
        };
    }, [updateFirstUnreadId]);

    return firstUnreadId;
}

export function useAreMessagesInThreadUnread(threadId: types.ChatId | types.MeetingId | null) {
    const query: AreMessagesInThreadUnreadQuery | null = useMemo(() => {
        if (threadId === null) {
            return null;
        }
        return { type: 'messagesInThread', threadId };
    }, [threadId]);
    return useIsUnread(query);
}

export function useIsThreadMessageUnread(message: types.Message | null) {
    const query: IsThreadMessageUnreadQuery | null = useMemo(() => {
        if (message === null) {
            return null;
        }
        return { type: 'threadMessage', message };
    }, [message]);
    return useIsUnread(query);
}

export function useAreSubmitsInFormUnread(formId: types.FormId | null) {
    const query: AreSubmitsInFormUnreadQuery | null = useMemo(() => {
        if (formId === null) {
            return null;
        }
        return { type: 'submitsInForm', formId };
    }, [formId]);
    return useIsUnread(query);
}

export function useIsFormSubmitUnread(formSubmit: types.FormRow | null) {
    const query: IsFormSubmitUnreadQuery | null = useMemo(() => {
        if (formSubmit === null) {
            return null;
        }
        return { type: 'formSubmit', formSubmit };
    }, [formSubmit]);
    return useIsUnread(query);
}

export function useAreSubmitsInFormUnreadDeep(formId: types.FormId | null) {
    const query: AreSubmitsInFormUnreadDeepQuery | null = useMemo(() => {
        if (formId === null) {
            return null;
        }
        return { type: 'submitsInFormDeep', formId };
    }, [formId]);
    return useIsUnread(query);
}

export function useIsFormSubmitUnreadDeep(formSubmit: types.FormRow | null) {
    const query: IsFormSubmitUnreadDeepQuery | null = useMemo(() => {
        if (formSubmit === null) {
            return null;
        }
        return { type: 'formSubmitDeep', formSubmit };
    }, [formSubmit]);
    return useIsUnread(query);
}

export function useIsAttachmentUnread(attachmentId: types.AttachmentId | null) {
    const query: IsAttachmentUnreadQuery | null = useMemo(() => {
        if (attachmentId === null) {
            return null;
        }
        return { type: 'attachment', attachmentId };
    }, [attachmentId]);
    return useIsUnread(query);
}

export function useAreMessagesInAnyChatUnread(onlyChatIds?: types.ChatId[] | null) {
    const cachedChatIds = useAppSelector(selectCachedChats).map((x) => x.id);
    const chatIds = onlyChatIds === undefined ? cachedChatIds : onlyChatIds;
    const query: AreMessagesInAnyChatUnreadQuery | null = useMemo(() => {
        if (chatIds === null) {
            return null;
        }
        return { type: 'messagesInAnyChat', chatIds };
    }, [chatIds]);
    return useIsUnread(query);
}

export function useAreMessagesInAnyMeetingUnread(onlyMeetingIds?: types.MeetingId[] | null) {
    const cachedMeetingIds = useAppSelector(selectCachedMeetings).map((x) => x.id);
    const meetingIds = onlyMeetingIds === undefined ? cachedMeetingIds : onlyMeetingIds;
    const query: AreMessagesInAnyMeetingUnreadQuery | null = useMemo(() => {
        if (meetingIds === null) {
            return null;
        }
        return { type: 'messagesInAnyMeeting', meetingIds };
    }, [meetingIds]);
    return useIsUnread(query);
}

export function useAreSubmitsInAnyFormUnread(onlyFormIds?: types.FormId[] | null) {
    const cachedFormIds = useAppSelector(selectCachedForms).map((x) => x.id);
    const formIds = onlyFormIds === undefined ? cachedFormIds : onlyFormIds;
    const query: AreSubmitsInAnyFormUnreadQuery | null = useMemo(() => {
        if (formIds === null) {
            return null;
        }
        return { type: 'submitsInAnyForm', formIds };
    }, [formIds]);
    return useIsUnread(query);
}

export function useAreSubmitsInAnyFormUnreadDeep(onlyFormIds?: types.FormId[] | null) {
    const cachedFormIds = useAppSelector(selectCachedForms).map((x) => x.id);
    const formIds = onlyFormIds === undefined ? cachedFormIds : onlyFormIds;
    const query: AreSubmitsInAnyFormUnreadDeepQuery | null = useMemo(() => {
        if (formIds === null) {
            return null;
        }
        return { type: 'submitsInAnyFormDeep', formIds };
    }, [formIds]);
    return useIsUnread(query);
}

export function useIsAnyAttachmentUnread(onlyAttachmentIds?: types.AttachmentId[] | null) {
    const cachedAttachmentIds = useAppSelector(selectCachedAttachments).map((x) => x.id);
    const attachmentIds = onlyAttachmentIds === undefined ? cachedAttachmentIds : onlyAttachmentIds;
    const query: IsAnyAttachmentUnreadQuery | null = useMemo(() => {
        if (attachmentIds === null) {
            return null;
        }
        return { type: 'anyAttachment', attachmentIds };
    }, [attachmentIds]);
    return useIsUnread(query);
}

export function useIsAnythingUnread(
    onlyChatIds?: types.ChatId[] | null,
    onlyMeetingIds?: types.MeetingId[] | null,
    onlyFormIds?: types.FormId[] | null,
    deepFormsChecking: boolean = true
) {
    const cachedChatIds = useAppSelector(selectCachedChats).map((x) => x.id);
    const cachedMeetingIds = useAppSelector(selectCachedMeetings).map((x) => x.id);
    const cachedFormIds = useAppSelector(selectCachedForms).map((x) => x.id);
    const chatIds = onlyChatIds === undefined ? cachedChatIds : onlyChatIds;
    const meetingIds = onlyMeetingIds === undefined ? cachedMeetingIds : onlyMeetingIds;
    const formIds = onlyFormIds === undefined ? cachedFormIds : onlyFormIds;
    const query: IsAnythingUnreadQuery | null = useMemo(() => {
        if (chatIds === null || meetingIds === null || formIds === null) {
            return null;
        }
        return {
            type: 'anything',
            chatIdsForMessagesCheck: chatIds,
            meetingIdsForMessagesCheck: meetingIds,
            chatIdsForAttachmentsCheck: chatIds,
            meetingIdsForAttachmentsCheck: meetingIds,
            formIds: deepFormsChecking ? [] : formIds,
            formIdsDeep: deepFormsChecking ? formIds : []
        };
    }, [chatIds, meetingIds, formIds, deepFormsChecking]);
    return useIsUnread(query);
}
