import {
    Tabs,
    Stack,
    Group,
    Title,
    ActionIcon,
    Box,
    Skeleton,
    Flex,
    Switch,
    Checkbox,
    Text
} from '@mantine/core';
import { IconX, IconChevronRight } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import UserAvatar from '../../atoms/UserAvatar';
import { useSettingsStyle } from './SettingsScreen';

export function UserSettingsLoader() {
    const { classes, cx } = useSettingsStyle();
    const { t } = useTranslation();

    return (
        <Tabs h="100%" defaultValue="personal">
            <Stack p="md" mt="md" mx="auto" spacing={0} w="50%" h="100%" miw={900}>
                <Group mb="sm">
                    <Title order={3}>{t('screen.settings.title')}</Title>
                    <ActionIcon disabled ml="auto">
                        <IconX />
                    </ActionIcon>
                </Group>
                <Tabs.Panel value="personal">
                    <Group my="xl">
                        <UserAvatar showTooltip={false} size={60} loading />
                        <Box>
                            <Skeleton h={16} mb="xs" w={150}></Skeleton>
                            <Skeleton h={16} w={180}></Skeleton>
                        </Box>
                    </Group>

                    <Flex className={classes.element}>
                        <Text weight={300}>Dark Mode</Text>
                        <Switch size="sm" ml="auto" value={'dark'} disabled />
                    </Flex>
                    <Flex className={classes.element}>
                        <Text weight={300}>{t('screen.settings.autoMarkAsRead')}</Text>
                        <Switch ml="auto" checked={false} disabled name="autoMarkAsRead"></Switch>
                    </Flex>
                    <Flex className={classes.element}>
                        <Text weight={300}>{t('screen.settings.twofa')}</Text>
                        <Checkbox
                            ml="auto"
                            checked={false}
                            disabled
                            name="autoMarkAsRead"></Checkbox>
                    </Flex>
                    <Box className={cx(classes.element)}>
                        <Flex align="center">
                            <Text weight={300} size="md">
                                {t('screen.settings.changePassword')}
                            </Text>
                            <ActionIcon ml="auto">
                                <IconChevronRight />
                            </ActionIcon>
                        </Flex>
                    </Box>
                </Tabs.Panel>
            </Stack>
        </Tabs>
    );
}
