const scriptUrl = (process.env.REACT_APP_CONTEXT_PATH || '') + '/lib/privmx/privmx-client-2.7.2.js';
const script = document.createElement('script');
script.setAttribute('id', 'privmx-script');
script.setAttribute('type', 'text/javascript');
script.setAttribute('integrity', 'sha256-CQDQ3c4tnzR/Zow710Mh8eYwJkOBYYFuGUs+UXOFfW8=');
script.setAttribute('src', scriptUrl);
script.addEventListener('load', () => {
    Object.assign(module.exports, window.privmx.privmx);
    window.Buffer = window.privmx.privmx.Buffer.Buffer;
    window.privmx.privmx.crypto.service.init(scriptUrl);
    if (localStorage.getItem('is-dev')) {
        console.log('PrivMX script loaded', module.exports);
    }
    window.privmx.privmx.core.PrivFsRpcManager.setServiceDiscoveryJsonMode(
        (process.env.REACT_APP_PRIVMX_API_PROTOCOL || window.location.protocol) === 'https:'
            ? window.privmx.privmx.serviceDiscovery.JsonMode.HTTPS_ONLY
            : window.privmx.privmx.serviceDiscovery.JsonMode.HTTP_ONLY
    );
    window.dispatchEvent(new CustomEvent('privmx-client-script-loaded'));
});
document.body.append(script);
module.exports = {};
