import { useMemo } from 'react';
import * as types from '../types/Types';

export function useSortChats(chats: types.Chat[] | null) {
    const sortedChats = useMemo(() => {
        if (chats === null) {
            return [];
        }
        return [...chats].sort(chatsComparator);
    }, [chats]);
    return sortedChats;
}

export function useSortMeetings(meetings: types.Meeting[] | null) {
    const sortedMeetings = useMemo(() => {
        if (meetings === null) {
            return [];
        }
        return [...meetings].sort(meetingsComparator);
    }, [meetings]);
    return sortedMeetings;
}

export function useSortFiles(files: types.AttachmentEx[] | null) {
    const sortedFiles = useMemo(() => {
        if (files === null) {
            return [];
        }
        return [...files].sort(filesComparator);
    }, [files]);
    return sortedFiles;
}

export function useSortSharedFiles(files: types.SharedFile[] | null) {
    const sortedFiles = useMemo(() => {
        if (files === null) {
            return [];
        }
        return [...files].sort(sharedFilesComparator);
    }, [files]);
    return sortedFiles;
}

export function useSortCompanies<T extends types.Company | types.CompanyWithContacts>(
    companies: T[] | null
) {
    const sortedCompanies = useMemo(() => {
        if (companies === null) {
            return [];
        }
        return [...companies].sort(companiesComparator);
    }, [companies]);
    return sortedCompanies;
}

export function useSortContacts(contacts: types.Contact[] | null) {
    const sortedContacts = useMemo(() => {
        if (contacts === null) {
            return [];
        }
        return [...contacts].sort(contactsComparator);
    }, [contacts]);
    return sortedContacts;
}

export function useSortForms<T extends types.FormModel | types.FormModel2>(forms: T[] | null) {
    const sortedForms = useMemo(() => {
        if (forms === null) {
            return [];
        }
        return [...forms].sort(formsComparator);
    }, [forms]);
    return sortedForms;
}

export function useSortAccounts<T extends types.User | types.Customer>(accounts: T[] | null) {
    const sortedAccounts = useMemo(() => {
        if (accounts === null) {
            return [];
        }
        return [...accounts].sort(accountsComparator);
    }, [accounts]);
    return sortedAccounts;
}

export function chatsComparator(a: types.Chat, b: types.Chat): number {
    return b.lastMsgDate - a.lastMsgDate;
}

export function meetingsComparator(a: types.Meeting, b: types.Meeting): number {
    return a.startDate - b.startDate;
}

export function filesComparator(a: types.AttachmentEx, b: types.AttachmentEx): number {
    return b.date - a.date;
}

export function sharedFilesComparator(a: types.SharedFile, b: types.SharedFile): number {
    return b.lastModDate - a.lastModDate;
}

export function companiesComparator(
    a: types.Company | types.CompanyWithContacts,
    b: types.Company | types.CompanyWithContacts
): number {
    return b.lastModifiedDate - a.lastModifiedDate;
}

export function contactsComparator(a: types.Contact, b: types.Contact): number {
    return b.lastModifiedDate - a.lastModifiedDate;
}

export function formsComparator(
    a: types.FormModel | types.FormModel2,
    b: types.FormModel | types.FormModel2
): number {
    return (
        Math.max(b.lastModifiedDate, b.lastSubmitDate ?? 0) -
        Math.max(a.lastModifiedDate, a.lastSubmitDate ?? 0)
    );
}

export function accountsComparator(
    a: types.User | types.Customer,
    b: types.User | types.Customer
): number {
    return b.creationDate - a.creationDate;
}

export function getFirstSortedElement<T>(
    collection: T[],
    comparator: (a: T, b: T) => number
): T | null {
    let candidate: T = collection[0];
    if (!candidate) {
        return null;
    }
    if (collection.length === 1) {
        return candidate;
    }
    for (let i = 1; i < collection.length; ++i) {
        const cmpResult = comparator(candidate, collection[i]);
        if (cmpResult > 0) {
            candidate = collection[i];
        }
    }
    return candidate;
}
