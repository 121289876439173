import * as privmx from 'privfs-client';
import * as PmxApi from 'privmx-server-api';

export class EccUtils {
    static generatePrivateKey() {
        return privmx.crypto.serviceSync.eccPrivRandom().toWIF() as PmxApi.api.core.EccWif;
    }

    static getPublicKey(priv: PmxApi.api.core.EccWif | privmx.crypto.ecc.PrivateKey) {
        const privKey =
            typeof priv === 'string' ? privmx.crypto.ecc.PrivateKey.fromWIF(priv) : priv;
        return privKey.getPublicKey().toBase58DER() as PmxApi.api.core.EccPubKey;
    }

    static publicKeysAreEqual(
        a: privmx.crypto.ecc.PublicKey | PmxApi.api.core.EccPubKey,
        b: privmx.crypto.ecc.PublicKey | PmxApi.api.core.EccPubKey
    ) {
        const aSer = typeof a === 'string' ? a : a.toBase58DER();
        const bSer = typeof b === 'string' ? b : b.toBase58DER();
        return aSer === bSer;
    }
}
