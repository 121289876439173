import { Message, MessageClientId } from '../../types/Types';

export class MessageGroupping {
    static groupMessages(messages: Message[]): Message[][] {
        const groups: Message[][] = [];
        let currentGroup: Message[] = [];
        let prevMsgDateStr: string = '';
        let prevMsgAuthor: string = '';
        for (const msg of messages) {
            if (msg.mimetype === 'application/json') continue;

            const msgDateStr = this.getMessageDateStr(msg);
            if (msgDateStr === prevMsgDateStr && prevMsgAuthor === msg.author) {
                currentGroup.push(msg);
            } else {
                if (currentGroup.length > 0) {
                    groups.push(currentGroup);
                    currentGroup = [];
                }
                currentGroup.push(msg);
                prevMsgDateStr = msgDateStr;
                prevMsgAuthor = msg.author;
            }
        }
        if (currentGroup.length > 0) {
            groups.push(currentGroup);
        }
        return groups;
    }

    static getSubsequentGrouppedMessageIds(messages: Message[]) {
        const groups = this.groupMessages(messages);
        const res: MessageClientId[] = [];
        const lastMessages: MessageClientId[] = [];

        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];
            for (let i = 1; i < group.length; ++i) {
                res.push(group[i].msgId);
            }
            const lastmessage = group.at(-1);
            if (lastmessage) {
                lastMessages.push(lastmessage.msgId);
            }
        }
        return [res, lastMessages] as const;
    }

    //TODO isLast function

    private static getMessageDateStr(message: Message): string {
        const dt = new Date(message.date);
        return `${dt.getDate()}_${dt.getMonth()}_${dt.getFullYear()}`;
    }
}
