import {
    ScrollArea,
    Paper,
    Group,
    ActionIcon,
    Text,
    Tooltip,
    rem,
    useMantineTheme
} from '@mantine/core';
import { IconExternalLink, IconX } from '@tabler/icons-react';
import { UserAvatarX } from '../../../atoms/UserAvatar';
import { ContactDisplayedName } from '../../../atoms/UserDisplayedName';
import { Contact } from '../../../types/Types';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { useAppSelector } from '../../../store';
import { selectCurrentUser } from '../../../store/CurrentUserSlice';

export function CompanyMembersList({
    contacts,
    children,
    handleRemoveUser
}: {
    handleRemoveUser: (id: Contact) => void;
    children: React.ReactNode;
    contacts: Contact[] | null;
}) {
    const theme = useMantineTheme();
    const currentUser = useAppSelector(selectCurrentUser);
    const filteredContacts = useMemo(() => {
        if (!contacts) {
            return null;
        }
        return contacts.filter(
            (x) =>
                !x.isPrivate ||
                (currentUser.username !== null &&
                    x.privateContactVisibleForUsers.includes(currentUser.username))
        );
    }, [contacts, currentUser]);
    if (!filteredContacts) {
        return (
            <Text align="center" color="dimmed">
                Loading company users
            </Text>
        );
    }

    return (
        <ScrollArea.Autosize mah={400} ml="auto" sx={{ flexGrow: 1, width: '100%' }}>
            {filteredContacts.map((x) => (
                <Paper
                    p="md"
                    key={x.id}
                    color={
                        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.dark[6]
                    }
                    radius={'md'}>
                    <Group>
                        <UserAvatarX
                            showTooltip={false}
                            user={
                                x.username
                                    ? {
                                          type: 'user',
                                          username: x.username
                                      }
                                    : {
                                          type: 'contact',
                                          contactId: x.id
                                      }
                            }
                        />
                        <ContactDisplayedName contact={x} size={'sm'} withEmail />
                        <Tooltip withinPortal label="More Info" openDelay={300}>
                            <Link to={`/contacts/${x.id}`}>
                                <ActionIcon ml="auto">
                                    <IconExternalLink size={rem(14)} />
                                </ActionIcon>
                            </Link>
                        </Tooltip>
                        <Tooltip withinPortal label="Remove user from the company" openDelay={300}>
                            <ActionIcon ml="auto" color="red" onClick={() => handleRemoveUser(x)}>
                                <IconX size={rem(14)} />
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                </Paper>
            ))}
            {children}
        </ScrollArea.Autosize>
    );
}
