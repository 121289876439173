import { Button, Flex, Modal, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export interface PostAddContactsModalData {
    numAddedContacts: number;
}
export interface PostAddContactsModalProps {
    onClose: () => void;
    opened: boolean;
    index: number;
    onFocus: VoidFunction;
    data: PostAddContactsModalData;
}

export function PostAddContactsModal(props: PostAddContactsModalProps) {
    const { t } = useTranslation();
    const isSingular = props.data.numAddedContacts < 2;

    return (
        <Modal
            title={
                isSingular
                    ? t('modal.postAddContacts.titleSingular')
                    : t('modal.postAddContacts.titlePlural')
            }
            radius="md"
            size={500}
            opened={props.opened}
            keepMounted={false}
            transitionProps={{
                duration: 200,
                transition: 'slide-up',
                timingFunction: 'ease-in'
            }}
            onClose={props.onClose}
            overlayProps={{
                opacity: 0.2,
                blur: '8px'
            }}>
            <Flex align="center" justify="center" gap="xl" direction="column" my="xl">
                <Text align="center">
                    {isSingular
                        ? t('modal.postAddContacts.messageSingular')
                        : t('modal.postAddContacts.messagePlural')}
                </Text>
                <Button onClick={props.onClose}>{t('action.close')}</Button>
            </Flex>
        </Modal>
    );
}
