import * as PmxApi from 'privmx-server-api';
import { BaseApi } from './BaseApi';

export class ThreadApi extends BaseApi implements PmxApi.api.thread.IThreadApi {
    getThread(
        model: PmxApi.api.thread.GetThreadModel
    ): Promise<{ thread: PmxApi.api.thread.Thread }> {
        return this.request('getThread', model);
    }

    getThreads(
        model: PmxApi.api.thread.GetThreadsModel
    ): Promise<{ threads: PmxApi.api.thread.Thread[] }> {
        return this.request('getThreads', model);
    }

    getThreadsOfCompany(
        model: PmxApi.api.thread.GetThreadsOfCompanyModel
    ): Promise<{ threads: PmxApi.api.thread.Thread[] }> {
        return this.request('getThreadsOfCompany', model);
    }

    getThreadsOfUser(
        model: PmxApi.api.thread.GetThreadsOfUserModel
    ): Promise<{ threads: PmxApi.api.thread.Thread[] }> {
        return this.request('getThreadsOfUser', model);
    }

    getAttachmentsOfCompany(model: PmxApi.api.thread.GetAttachmentsOfCompanyModel): Promise<{
        attachments: PmxApi.api.thread.ThreadAttachment[];
        threads: PmxApi.api.thread.Thread[];
    }> {
        return this.request('getAttachmentsOfCompany', model);
    }

    getAttachmentsOfUser(model: PmxApi.api.thread.GetAttachmentsOfUserModel): Promise<{
        attachments: PmxApi.api.thread.ThreadAttachment[];
        threads: PmxApi.api.thread.Thread[];
    }> {
        return this.request('getAttachmentsOfUser', model);
    }

    createThread(
        model: PmxApi.api.thread.CreateThreadModel
    ): Promise<{ thread: PmxApi.api.thread.Thread }> {
        return this.request('createThread', model);
    }

    updateThread(
        model: PmxApi.api.thread.UpdateThreadModel
    ): Promise<{ thread: PmxApi.api.thread.Thread }> {
        return this.request('updateThread', model);
    }

    setThreadTags(
        model: PmxApi.api.thread.SetThreadTagsModel
    ): Promise<{ thread: PmxApi.api.thread.Thread }> {
        return this.request('setThreadTags', model);
    }

    getThreadsTags(): Promise<{ tags: PmxApi.api.tag.Tag[] }> {
        return this.request('getThreadsTags', {});
    }

    sendMessage(model: PmxApi.api.thread.SendMessageModel): Promise<PmxApi.api.core.OK> {
        return this.request('sendMessage', model);
    }

    getMessages(
        model: PmxApi.api.thread.GetMessagesModel
    ): Promise<{ messages: PmxApi.api.thread.Message[] }> {
        return this.request('getMessages', model);
    }

    getMessage(
        model: PmxApi.api.thread.GetMessageModel
    ): Promise<{ message: PmxApi.api.thread.Message }> {
        return this.request('getMessage', model);
    }

    getMessagesWithThreads(
        model: PmxApi.api.thread.GetMessagesWithThreadsModel
    ): Promise<{ messages: PmxApi.api.thread.Message[]; threads: PmxApi.api.thread.Thread[] }> {
        return this.request('getMessagesWithThreads', model);
    }

    getAttachmentData(model: PmxApi.api.thread.GetAttachmentDataModel): Promise<{ data: Buffer }> {
        return this.request('getAttachmentData', model);
    }

    setAttachmentGroupTags(
        model: PmxApi.api.thread.SetAttachmentGroupTagsModel
    ): Promise<PmxApi.api.core.OK> {
        return this.request('setAttachmentGroupTags', model);
    }

    getAttachments(model: PmxApi.api.thread.GetAttachmentsModel): Promise<{
        attachments: PmxApi.api.thread.ThreadAttachment[];
        threads: PmxApi.api.thread.Thread[];
    }> {
        return this.request('getAttachments', model);
    }

    getThreadAttachment(model: PmxApi.api.thread.GetThreadAttachmentModel): Promise<{
        attachment: PmxApi.api.thread.ThreadAttachment;
        thread: PmxApi.api.thread.Thread;
    }> {
        return this.request('getThreadAttachment', model);
    }

    getThreadAttachments(model: PmxApi.api.thread.GetThreadAttachmentsModel): Promise<{
        attachments: PmxApi.api.thread.ThreadAttachment[];
        thread: PmxApi.api.thread.Thread;
    }> {
        return this.request('getThreadAttachments', model);
    }

    getThreadAttachmentGroup(model: PmxApi.api.thread.GetThreadAttachmentGroupModel): Promise<{
        attachments: PmxApi.api.thread.ThreadAttachment[];
        thread: PmxApi.api.thread.Thread;
    }> {
        return this.request('getThreadAttachmentGroup', model);
    }

    setMessageSticker(
        model: PmxApi.api.thread.SetMessageStickerModel
    ): Promise<PmxApi.api.core.OK> {
        return this.request('setMessageSticker', model);
    }

    updateMessage(model: PmxApi.api.thread.UpdateMessageModel): Promise<PmxApi.api.core.OK> {
        return this.request('updateMessage', model);
    }

    getItemCountsGrouppedByUsers(): Promise<{
        itemCountsGrouppedByUser: PmxApi.api.thread.ItemCountsGrouppedByUser;
    }> {
        return this.request('getItemCountsGrouppedByUsers', {});
    }

    getThreadUsersWithAccessibleTimeRanges(
        model: PmxApi.api.thread.GetThreadUsersWithAccessibleTimeRangesModel
    ): Promise<{
        users: PmxApi.api.thread.UserWithAccessibleTimeRanges[];
        thread: PmxApi.api.thread.Thread;
    }> {
        return this.request('getThreadUsersWithAccessibleTimeRanges', model);
    }

    addUserToThreadMeeting(
        model: PmxApi.api.thread.AddUserToThreadMeetingModel
    ): Promise<PmxApi.api.core.OK> {
        return this.request('addUserToThreadMeeting', model);
    }

    removeUserFromThreadMeeting(
        model: PmxApi.api.thread.RemoveUserFromThreadMeetingModel
    ): Promise<PmxApi.api.core.OK> {
        return this.request('removeUserFromThreadMeeting', model);
    }

    addAnonymousUserToThreadMeeting(
        model: PmxApi.api.thread.AddAnonymousUserToThreadMeetingModel
    ): Promise<PmxApi.api.core.OK> {
        return this.request('addAnonymousUserToThreadMeeting', model);
    }

    removeAnonymousUserFromThreadMeeting(
        model: PmxApi.api.thread.RemoveAnonymousUserFromThreadMeetingModel
    ): Promise<PmxApi.api.core.OK> {
        return this.request('removeAnonymousUserFromThreadMeeting', model);
    }

    removeUserFromThreadLobby(
        model: PmxApi.api.thread.RemoveUserFromThreadLobbyModel
    ): Promise<PmxApi.api.core.OK> {
        return this.request('removeUserFromThreadLobby', model);
    }

    removeAnonymousFromThreadLobby(
        model: PmxApi.api.thread.RemoveAnonymousFromThreadLobbyModel
    ): Promise<PmxApi.api.core.OK> {
        return this.request('removeAnonymousFromThreadLobby', model);
    }

    joinToThreadMeetingLobby(
        model: PmxApi.api.thread.JoinToThreadMeetingLobbyModel
    ): Promise<{ result: PmxApi.api.thread.JoinToLobbyResult }> {
        return this.request('joinToThreadMeetingLobby', model);
    }

    leaveThreadMeetingLobby(
        model: PmxApi.api.thread.LeaveThreadMeetingLobbyModel
    ): Promise<PmxApi.api.core.OK> {
        return this.request('leaveThreadMeetingLobby', model);
    }

    getThreadMeetingLobby(
        model: PmxApi.api.thread.GetThreadMeetingLobbyModel
    ): Promise<PmxApi.api.thread.MeetingLobby> {
        return this.request('getThreadMeetingLobby', model);
    }

    setThreadMeetingNickname(
        model: PmxApi.api.thread.SetThreadMeetingNicknameModel
    ): Promise<PmxApi.api.core.OK> {
        return this.request('setThreadMeetingNickname', model);
    }

    commitPresenceInThreadLobby(
        model: PmxApi.api.thread.CommitPresenceInThreadLobbyModel
    ): Promise<PmxApi.api.core.OK> {
        return this.request('commitPresenceInThreadLobby', model);
    }
}
