import { Container, Group, ThemeIcon, Title } from '@mantine/core';
import { SingleChatMessageData } from '../../types/Types';
import Icon, { iconChat, iconMeeting } from '../Icon';
import MessageView from '../MessageView/MessageView';

export interface SingleChatMessageProps {
    singleChatMessage: SingleChatMessageData;
    handleClose: () => void;
}

export function SingleChatMessage(props: SingleChatMessageProps) {
    const message = props.singleChatMessage.message;
    const chatId = props.singleChatMessage.chatId;
    const meetingId = props.singleChatMessage.meetingId;
    const chatOrMeetingTitle = props.singleChatMessage.chatOrMeetingTitle;

    return (
        <>
            <Container>
                <Group my="md">
                    <ThemeIcon>
                        <Icon icon={chatId ? iconChat : iconMeeting} />
                    </ThemeIcon>
                    <Title>{chatOrMeetingTitle}</Title>
                </Group>
                <MessageView message={message} chatId={chatId} meetingId={meetingId} />
            </Container>
        </>
    );
}

export default SingleChatMessage;
