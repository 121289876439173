import * as PmxApi from 'privmx-server-api';
import { AdminDataInner, AdminPrivKeyProvider } from './userCreation/Types';
import { EciesEncryptor } from '../EciesEncryptor';

export class AdminDataV2Decryptor {
    constructor(private adminPrivKeyProvider: AdminPrivKeyProvider) {}

    decrypt(adminData: PmxApi.api.core.Base64): Promise<AdminDataInner> {
        return EciesEncryptor.decryptObjectFromBase64(
            this.adminPrivKeyProvider.getAdminPrivKey(),
            adminData
        );
    }
}
