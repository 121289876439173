import { Text } from '@mantine/core';

function Field(props: { label: string; value: string; className?: string }) {
    return (
        <div className={props.className || ''}>
            <Text color="dimmed" size="xs">
                {props.label}
            </Text>
            {props.value ? <Text size="md">{props.value}</Text> : <Text color="dimmed">---</Text>}
        </div>
    );
}

export default Field;
