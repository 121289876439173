import {
    Box,
    CloseButton,
    createStyles,
    DefaultProps,
    Flex,
    FlexProps,
    Paper,
    rem,
    Selectors,
    Text
} from '@mantine/core';
import { useCallback, useState, useRef, createContext, useEffect, useMemo } from 'react';
import {
    getCursorByArea,
    getResizerHorizontalPosition,
    getResizerVerticalPosition,
    ResizerArea,
    ResizerHorizontalPosition,
    ResizerVerticalPosition,
    useDragMoveResize
} from '../../hooks/useDragMoveResize';

export const ModalContext = createContext<{ height: number }>({ height: 0 });

interface ContainerProps {
    height: number;
    width: number;
}

const useStyles = createStyles((theme) => ({
    root: {
        position: 'absolute',
        padding: 0,
        maxHeight: '95svh',
        maxWidth: '60svw'
    },
    modal: {
        height: '100%'
    },
    titleBar: {
        cursor: 'grab',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    },
    content: {
        minHeight: 300,
        padding: theme.spacing.md,
        width: '100%',
        height: `calc(100% - ${rem(24)})`,
        display: 'flex'
    }
}));

type ModalStylesNames = Selectors<typeof useStyles>;
type ModalStylesParams = ContainerProps;

function getOffset(index: number): [number, number] {
    const xOffset = Math.tan(index) + 30;
    const yOffset = Math.cosh(index) + 10;

    const flip = Math.random() > 0.5 ? 1 : -1;

    return [((xOffset * 100) % 70) * flip, ((yOffset * 100) % 20) * flip];
}

interface ModalProps extends DefaultProps<ModalStylesNames, ModalStylesParams> {
    onClose: () => void;
    fullWidth?: boolean;
    children: React.ReactNode;
    width?: number;
    height?: number;
    beforeClose?: () => Whenable<boolean>;
    className?: string;
    resizable?: boolean;
    movable?: boolean;
    title: string;
    minWidth?: number;
    minHeight?: number;
    index: number;
    onFocus: VoidFunction;
    noOffset?: boolean;
}

function Modal({
    children,
    onClose,
    beforeClose,
    fullWidth,
    width,
    height,
    resizable: resizableProp,
    movable: movableProp,
    title,
    minWidth,
    minHeight = 380,
    className,
    classNames,
    styles,
    unstyled,
    index,
    onFocus,
    noOffset,
    ...rest
}: ModalProps) {
    const offset = noOffset ? [0, 0] : getOffset(index);
    const resizable = typeof resizableProp === 'boolean' ? resizableProp : false;
    const movable = typeof movableProp === 'boolean' ? movableProp : true;

    const modalRef = useRef<HTMLDivElement>(null);
    const rect = document.body.getBoundingClientRect();
    const widthX = width ?? 850;
    const heightX = height ?? 850;
    const [modalWidth, setModalWidth] = useState(
        widthX ?? Math.min(window.innerWidth * 0.2, (rect.width - widthX) / 2)
    );
    const [modalHeight, setModalHeight] = useState(heightX);
    const [modalX, setModalX] = useState((window.innerWidth - modalWidth) / 2 + offset[0]);
    const [modalY, setModalY] = useState((window.innerHeight - modalHeight) / 4 + offset[1]);
    const handleCloseClick = useCallback(async () => {
        if (beforeClose) {
            const canClose = await beforeClose();
            if (canClose === false) {
                return;
            }
        }
        onClose();
    }, [onClose, beforeClose]);

    useEffect(() => {
        if (modalY < 0) setModalY(0);
    }, [modalY]);

    const { classes, cx } = useStyles(undefined, {
        name: 'PrivmxModal',
        classNames,
        styles,
        unstyled
    });

    const getModalStyle = useMemo(
        () => ({
            height: resizable ? modalHeight : 'auto',
            width: resizable ? modalWidth : 'max-content',
            left: modalX,
            top: modalY,
            zIndex: 450 + index
        }),
        [index, modalHeight, modalWidth, modalX, modalY, resizable]
    );

    return (
        <Box
            ref={modalRef}
            className={cx(classes.root, className)}
            miw={minWidth ?? 800}
            style={getModalStyle}
            {...rest}>
            <Paper p={0} onMouseDown={onFocus} shadow="xl" className={classes.modal} withBorder>
                <TitleBar
                    focusFn={onFocus}
                    onMouseDown={onFocus}
                    onClose={handleCloseClick}
                    draggable={movable}
                    title={title}
                    modalRef={modalRef}
                    setModalX={setModalX}
                    setModalY={setModalY}
                    minW={minWidth}
                    minH={minHeight}
                    className={classes.titleBar}
                />
                <div className={classes.content}>{children}</div>
                {resizable && (
                    <>
                        <Resizer
                            onMouseDown={onFocus}
                            area="top"
                            modalRef={modalRef}
                            setModalX={setModalX}
                            setModalY={setModalY}
                            setModalHeight={setModalHeight}
                            setModalWidth={setModalWidth}
                            minW={minWidth}
                            minH={minHeight}
                        />
                        <Resizer
                            onMouseDown={onFocus}
                            area="bottom"
                            modalRef={modalRef}
                            setModalX={setModalX}
                            setModalY={setModalY}
                            setModalHeight={setModalHeight}
                            setModalWidth={setModalWidth}
                            minW={minWidth}
                            minH={minHeight}
                        />
                        <Resizer
                            onMouseDown={onFocus}
                            area="left"
                            modalRef={modalRef}
                            setModalX={setModalX}
                            setModalY={setModalY}
                            setModalHeight={setModalHeight}
                            setModalWidth={setModalWidth}
                            minW={minWidth}
                            minH={minHeight}
                        />
                        <Resizer
                            onMouseDown={onFocus}
                            area="right"
                            modalRef={modalRef}
                            setModalX={setModalX}
                            setModalY={setModalY}
                            setModalHeight={setModalHeight}
                            setModalWidth={setModalWidth}
                            minW={minWidth}
                            minH={minHeight}
                        />
                        <Resizer
                            onMouseDown={onFocus}
                            area="top-left"
                            modalRef={modalRef}
                            setModalX={setModalX}
                            setModalY={setModalY}
                            setModalHeight={setModalHeight}
                            setModalWidth={setModalWidth}
                            minW={minWidth}
                            minH={minHeight}
                        />
                        <Resizer
                            onMouseDown={onFocus}
                            area="top-right"
                            modalRef={modalRef}
                            setModalX={setModalX}
                            setModalY={setModalY}
                            setModalHeight={setModalHeight}
                            setModalWidth={setModalWidth}
                            minW={minWidth}
                            minH={minHeight}
                        />
                        <Resizer
                            onMouseDown={onFocus}
                            area="bottom-left"
                            modalRef={modalRef}
                            setModalX={setModalX}
                            setModalY={setModalY}
                            setModalHeight={setModalHeight}
                            setModalWidth={setModalWidth}
                            minW={minWidth}
                            minH={minHeight}
                        />
                        <Resizer
                            onMouseDown={onFocus}
                            area="bottom-right"
                            modalRef={modalRef}
                            setModalX={setModalX}
                            setModalY={setModalY}
                            setModalHeight={setModalHeight}
                            setModalWidth={setModalWidth}
                            minW={minWidth}
                            minH={minHeight}
                        />
                    </>
                )}
            </Paper>
        </Box>
    );
}

interface TitleBarProps {
    onClose: () => void;
    draggable?: boolean;
    title?: string;
    modalRef: React.RefObject<HTMLDivElement>;
    setModalX: (x: number) => void;
    setModalY: (y: number) => void;
    minW?: number;
    minH?: number;
    className?: string;
    focusFn: VoidFunction;
}

const TitleBar = ({
    modalRef,
    setModalX,
    onClose,
    setModalY,
    minW,
    minH,
    title,
    focusFn,
    ...props
}: TitleBarProps & FlexProps) => {
    const titleBarRef = useRef(null);

    const getMinMaxRect = useCallback(() => {
        return getModalMinMaxRect(modalRef.current, minW, minH);
    }, [modalRef, minW, minH]);

    const getCurrentRect = useCallback(() => {
        return getModalCurrentRect(modalRef.current);
    }, [modalRef]);

    const onChangeRect = useCallback(
        (x: number, y: number) => {
            setModalX(x);
            setModalY(y);
        },
        [setModalX, setModalY]
    );

    useDragMoveResize('move', titleBarRef, getMinMaxRect, getCurrentRect, onChangeRect);

    return (
        <Flex align="center" justify="center" onClick={focusFn} ref={titleBarRef} {...props}>
            <Text mx="auto">{title ?? ''}</Text>
            <CloseButton variant="transparent" onClick={onClose} />
        </Flex>
    );
};

interface ResizerProps {
    area: ResizerArea;
    modalRef: React.RefObject<HTMLDivElement>;
    setModalX: (x: number) => void;
    setModalY: (y: number) => void;
    setModalWidth: (width: number) => void;
    setModalHeight: (height: number) => void;
    minW?: number;
    minH?: number;
    onMouseDown: VoidFunction;
}

const Resizer = (props: ResizerProps) => {
    const resizerRef = useRef(null);
    const modalRef = props.modalRef;
    const setModalX = props.setModalX;
    const setModalY = props.setModalY;
    const setModalWidth = props.setModalWidth;
    const setModalHeight = props.setModalHeight;

    const getMinMaxRect = useCallback(() => {
        return getModalMinMaxRect(modalRef.current, props.minW, props.minH);
    }, [modalRef, props.minW, props.minH]);

    const getCurrentRect = useCallback(() => {
        return getModalCurrentRect(modalRef.current);
    }, [modalRef]);

    const onChangeRect = useCallback(
        (x: number, y: number, w: number, h: number) => {
            setModalX(x);
            setModalWidth(w);
            setModalY(y);
            setModalHeight(h);
        },
        [setModalX, setModalY, setModalWidth, setModalHeight]
    );

    useDragMoveResize(props.area, resizerRef, getMinMaxRect, getCurrentRect, onChangeRect);
    const { classes, cx } = useResizerElementStyles({
        cursor: getCursorByArea(props.area),
        horizontal: getResizerHorizontalPosition(props.area),
        vertical: getResizerVerticalPosition(props.area)
    });
    return (
        <div
            onClick={() => props.onMouseDown()}
            ref={resizerRef}
            className={cx(classes.position, classes.root)}
        />
    );
};

const useResizerElementStyles = createStyles(
    (
        theme,
        {
            horizontal,
            cursor,
            vertical
        }: {
            cursor: string;
            horizontal: ResizerHorizontalPosition;
            vertical: ResizerVerticalPosition;
        }
    ) => {
        return {
            root: {
                position: 'absolute',
                cursor: cursor,
                minWidth: 5,
                minHeight: 5
            },
            position: {
                left: horizontal !== 'right' ? '-4px' : undefined,
                right: horizontal !== 'left' ? '-4px;' : undefined,
                top: vertical !== 'bottom' ? '-4px' : undefined,
                bottom: vertical !== 'top' ? '-4px' : undefined
            }
        };
    }
);

function getModalMinMaxRect(el: HTMLDivElement | null, minW?: number, minH?: number) {
    const pos = {
        minX: -Number.MAX_SAFE_INTEGER,
        maxX: Number.MAX_SAFE_INTEGER,
        minY: -Number.MAX_SAFE_INTEGER,
        maxY: Number.MAX_SAFE_INTEGER,
        minW: minW ?? 300,
        maxW: Number.MAX_SAFE_INTEGER,
        minH: minH ?? 200,
        maxH: Number.MAX_SAFE_INTEGER
    };
    if (el) {
        const modalWidth = el.clientWidth;
        const availWidth = document.body.clientWidth;
        const availHeight = document.body.clientHeight;
        pos.minX = 100 - modalWidth;
        pos.minY = 0;
        pos.maxX = availWidth - 100;
        pos.maxY = availHeight - 100;
        pos.maxW = availWidth - 100;
        pos.maxH = availHeight - 100;
    }
    return pos;
}

function getModalCurrentRect(el: HTMLDivElement | null) {
    const rect = { x: 0, y: 0, w: 0, h: 0 };
    if (el) {
        const style = window.getComputedStyle(el);
        const left = parseFloat(style.left);
        const top = parseFloat(style.top);
        rect.x = isNaN(left) ? 0 : left;
        rect.y = isNaN(top) ? 0 : top;
        rect.w = el.clientWidth;
        rect.h = el.clientHeight;
    }
    return rect;
}

export default Modal;
