import { VideoConferenceConfiguration, VideoConferenceState, VideoConferenceConnectionLostReason, VideoConferenceTrack, VideoConferenceParticipant, VideoConferenceConnectionOptions } from "./Types";
import * as Types from "./Types";
import { AudioLevelObserver } from "./utils/AudioLevelObserver";
import { JitsiMeetJS } from "./jitsi/lib-jitsi-meet";
import { CameraConfiguration } from "./utils";
import { VideoEffect } from ".";

export type MessageType = "error" | "warning" | "success" | "info";

export interface AvailableDevices {
    audioOutput: AvailableDevice[];
    audioInput: AvailableDevice[];
    videoInput: AvailableDevice[];
}

export interface AvailableDevice {
    id: string;
    name: string;
    mediaDeviceInfo: MediaDeviceInfo;
}

export interface VideoConferenceInitOptions {
    providers: Types.Providers;
    onDevicesListChanged: (devices: MediaDeviceInfo[]) => void;
    onConnectionLost: (reason: VideoConferenceConnectionLostReason, extraInfo: string) => void;
    onUserJoined: (participantId: string) => void;
    onUserLeft: (participantId: string) => void;
    onCustomDominantSpeakerChanged: () => void;
    onDominantSpeakerChanged: () => void;
    onDesktopSharingEnabled: () => void;
    onLocalAudioTrackCreated: () => void;
    onLocalVideoTrackCreated: () => void;
    onRemoteAudioTrackCreated: (participantId: string) => void;
    onRemoteVideoTrackCreated: (participantId: string) => void;
    onRemoteAudioTrackDeleted: (participantId: string) => void;
    onRemoteVideoTrackDeleted: (participantId: string) => void;
    onDesktopSharingDisabled: () => void;
    onLocalAudioOutputEnabled: () => void;
    onLocalAudioOutputDisabled: () => void;
    onLocalAudioInputEnabled: () => void;
    onLocalAudioInputDisabled: () => void;
    onLocalVideoInputEnabled: () => void;
    onLocalVideoInputDisabled: () => void;
    onTrackMutedStatusChanged: (track: VideoConferenceTrack) => void;
    onTrackAudioLevelChanged: (participantId: string, audioLevel: number) => void;
    onParticipantConnectionStatsUpdated: (participantId: string, stats: JitsiMeetJS.ConferenceStats) => void;
    requestShowMessage: (i18nKey: string, type: MessageType) => void;
}


export abstract class VideoConference {
    
    static readonly ENCRYPTION_ALGORITHM_KEYGEN = {
        name: "AES-GCM",
        length: 256,
    };
    static readonly ENCRYPTION_USAGES: KeyUsage[] = ["encrypt", "decrypt"];
    static readonly PARTICIPANT_TALKING_AUDIO_LEVEL_THRESHOLD: number = 0.008;
    static readonly PARTICIPANT_NOT_TALKING_DELAY: number = 1000;
    static readonly ERROR_E2EE_NOT_SUPPORTED: string = "E2EE is not supported";
    
    protected conference: any;
    protected configuration: VideoConferenceConfiguration | null = null;
    protected state: VideoConferenceState = VideoConferenceState.DISCONNECTED;
    protected localVideoTrack: VideoConferenceTrack | null = null;
    protected localAudioTrack: VideoConferenceTrack | null = null;
    protected remoteVideoTracks: { [participantId: string]: VideoConferenceTrack } = {};
    protected remoteAudioTracks: { [participantId: string]: VideoConferenceTrack } = {};
    protected onDevicesListChangedCallback: (devices: MediaDeviceInfo[]) => void = () => {};
    protected onConnectionLostCallback: (reason: VideoConferenceConnectionLostReason, extraInfo: string) => void = () => {};
    protected onUserJoinedCallback: (participantId: string) => void = () => {};
    protected onUserLeftCallback: (participantId: string) => void = () => {};
    protected onCustomDominantSpeakerChangedCallback?: () => void = () => {};
    protected onDominantSpeakerChangedCallback?: () => void = () => {};
    protected onDesktopSharingEnabledCallback: () => void = () => {};
    protected onLocalAudioTrackCreatedCallback: () => void = () => {};
    protected onLocalVideoTrackCreatedCallback: () => void = () => {};
    protected onRemoteAudioTrackCreatedCallback: (participantId: string) => void = () => {};
    protected onRemoteVideoTrackCreatedCallback: (participantId: string) => void = () => {};
    protected onRemoteAudioTrackDeletedCallback: (participantId: string) => void = () => {};
    protected onRemoteVideoTrackDeletedCallback: (participantId: string) => void = () => {};
    protected onDesktopSharingDisabledCallback: () => void = () => {};
    protected onLocalAudioOutputEnabledCallback?: () => void = () => {};
    protected onLocalAudioOutputDisabledCallback?: () => void = () => {};
    protected onLocalAudioInputEnabledCallback?: () => void = () => {};
    protected onLocalAudioInputDisabledCallback?: () => void = () => {};
    protected onLocalVideoInputEnabledCallback?: () => void = () => {};
    protected onLocalVideoInputDisabledCallback?: () => void = () => {};
    protected onTrackMutedStatusChangedCallback?: (track: VideoConferenceTrack) => void = () => {};
    protected onTrackAudioLevelChangedCallback?: (participantId: string, audioLevel: number) => void = () => {};
    protected onParticipantConnectionStatsUpdatedCallback: (participantId: string, stats: JitsiMeetJS.ConferenceStats) => void = () => {};
    protected isDesktopSharingEnabled: boolean = false;
    protected isLocalAudioOutputEnabled: boolean = true;
    protected isLocalAudioInputEnabled: boolean = true;
    protected isLocalVideoInputEnabled: boolean = true;
    protected localAudioOutputDeviceId: string | null = null;
    protected localAudioInputDeviceId: string | null = null;
    protected localVideoInputDeviceId: string | null = null;
    protected localParticipant: VideoConferenceParticipant<null> | null = null;
    protected participants: { [participantId: string]: VideoConferenceParticipant<any> } = {};
    protected requestShowMessage: (i18nKey: string, type: MessageType) => void = () => {};
    protected encryptionKey: CryptoKey | null = null;
    protected encryptionIV: Uint8Array | null = null;
    protected localAudioLevelObserver: AudioLevelObserver | null = null;
    protected scriptVersion?: string = undefined;
    
    
    constructor(options: VideoConferenceInitOptions) {
        this.onDevicesListChangedCallback = options.onDevicesListChanged;
        this.onConnectionLostCallback = options.onConnectionLost;
        this.onUserJoinedCallback = options.onUserJoined;
        this.onUserLeftCallback = options.onUserLeft;
        this.onCustomDominantSpeakerChangedCallback = options.onCustomDominantSpeakerChanged;
        this.onDominantSpeakerChangedCallback = options.onDominantSpeakerChanged;
        this.onDesktopSharingEnabledCallback = options.onDesktopSharingEnabled;
        this.onLocalAudioTrackCreatedCallback = options.onLocalAudioTrackCreated;
        this.onLocalVideoTrackCreatedCallback = options.onLocalVideoTrackCreated;
        this.onRemoteAudioTrackCreatedCallback = options.onRemoteAudioTrackCreated;
        this.onRemoteVideoTrackCreatedCallback = options.onRemoteVideoTrackCreated;
        this.onRemoteAudioTrackDeletedCallback = options.onRemoteAudioTrackDeleted;
        this.onRemoteVideoTrackDeletedCallback = options.onRemoteVideoTrackDeleted;
        this.onDesktopSharingDisabledCallback = options.onDesktopSharingDisabled;
        this.onLocalAudioOutputEnabledCallback = options.onLocalAudioOutputEnabled;
        this.onLocalAudioOutputDisabledCallback = options.onLocalAudioOutputDisabled;
        this.onLocalAudioInputEnabledCallback = options.onLocalAudioInputEnabled;
        this.onLocalAudioInputDisabledCallback = options.onLocalAudioInputDisabled;
        this.onLocalVideoInputEnabledCallback = options.onLocalVideoInputEnabled;
        this.onLocalVideoInputDisabledCallback = options.onLocalVideoInputDisabled;
        this.onTrackMutedStatusChangedCallback = options.onTrackMutedStatusChanged;
        this.onTrackAudioLevelChangedCallback = options.onTrackAudioLevelChanged;
        this.onParticipantConnectionStatsUpdatedCallback = options.onParticipantConnectionStatsUpdated;
        this.requestShowMessage = options.requestShowMessage;
    }
    
    abstract isE2EEEnabled(): boolean;
    
    abstract updateE2EEEnabled(options: Types.VideoConferenceOptions): void;
    
    afterScriptLoaded(version: string): void {
        this.scriptVersion = version;
    }
    
    
    
    
    
    /*****************************************
    ****************** State *****************
    *****************************************/
    getState(): VideoConferenceState {
        return this.state;
    }
    
    protected setState(newState: VideoConferenceState): void {
        this.state = newState;
    }
    
    
    
    
    
    
    /*****************************************
    *************** Connection ***************
    *****************************************/
    abstract connect(connectionOptions: VideoConferenceConnectionOptions): Promise<void>;
    abstract disconnect(): Promise<void>;
    
    
    
    
    
    /*****************************************
    ***************** Tracks *****************
    *****************************************/
    abstract createLocalTracks(audioDeviceId?: string, videoDeviceId?: string): Promise<void>;
    
    getLocalAudioTrack(): VideoConferenceTrack | null {
        return this.localAudioTrack;
    }
    
    getLocalVideoTrack(): VideoConferenceTrack | null {
        return this.localVideoTrack;
    }
    
    getRemoteAudioTrack(participantId: string): VideoConferenceTrack | null {
        return this.remoteAudioTracks[participantId] ?? null;
    }
    
    getRemoteVideoTrack(participantId: string): VideoConferenceTrack | null {
        return this.remoteVideoTracks[participantId] ?? null;
    }
    
    getAudioTrack(participantId: string): VideoConferenceTrack | null {
        const localParticipantId = this.getLocalParticipant()?.id;
        return participantId == localParticipantId ? this.getLocalAudioTrack() : this.getRemoteAudioTrack(participantId);
    }
    
    getVideoTrack(participantId: string): VideoConferenceTrack | null {
        const localParticipantId = this.getLocalParticipant()?.id;
        return participantId == localParticipantId ? this.getLocalVideoTrack() : this.getRemoteVideoTrack(participantId);
    }
    
    
    
    
    
    /*****************************************
    ********** Audio, video, devices *********
    *****************************************/
    abstract enableLocalAudioOutput(): void;
    abstract disableLocalAudioOutput(): void;
    abstract enableLocalAudioInput(): Promise<void>;
    abstract disableLocalAudioInput(): void;
    abstract enableLocalVideoInput(): Promise<void>;
    abstract disableLocalVideoInput(): void;
    abstract setAudioOutputDeviceId(deviceId: string): void;
    abstract setAudioInputDeviceId(deviceId: string): void;
    abstract setVideoInputDeviceId(deviceId: string): void;
    abstract getAvailableDevices(): Promise<AvailableDevices>;
    protected abstract updateIsParticipantTalking(participantId: string, newAudioLevel?: number): void;
    
    getIsLocalAudioOutputEnabled(): boolean {
        return this.isLocalAudioOutputEnabled;
    }
    
    getIsLocalAudioInputEnabled(): boolean {
        return this.isLocalAudioInputEnabled;
    }
    
    getIsLocalVideoInputEnabled(): boolean {
        return this.isLocalVideoInputEnabled;
    }
    
    getLocalAudioOutputDeviceId(): string | null {
        return this.localAudioOutputDeviceId;
    }
    
    getLocalAudioInputDeviceId(): string | null {
        return this.localAudioInputDeviceId;
    }
    
    getLocalVideoInputDeviceId(): string | null {
        return this.localVideoInputDeviceId;
    }
    
    configureInitialDevices(audioOutput: string | false | undefined, audioInput: string | false | undefined, videoInput: string | false | undefined): void {
        this.isLocalAudioOutputEnabled = !!audioOutput;
        this.isLocalAudioInputEnabled = audioInput !== false;
        this.isLocalVideoInputEnabled = videoInput !== false;
        this.localAudioOutputDeviceId = audioOutput ? audioOutput : null;
        this.localAudioInputDeviceId = audioInput ? audioInput : null;
        this.localVideoInputDeviceId = videoInput ? videoInput : null;
    }
    
    protected startLocalAudioLevelObserver(): void {
        if (!this.localAudioInputDeviceId) {
            return;
        }
        if (this.localAudioLevelObserver) {
            this.localAudioLevelObserver.dispose();
        }
        this.localAudioLevelObserver = new AudioLevelObserver(this.localAudioInputDeviceId, audioLevel => {
            if (this.localParticipant && this.conference) {
                this.updateIsParticipantTalking(this.localParticipant.id, audioLevel);
                if (this.onTrackAudioLevelChangedCallback) {
                    this.onTrackAudioLevelChangedCallback(this.localParticipant.id, audioLevel);
                }
            }
        });
    }
    
    protected stopLocalAudioLevelObserver(): void {
        if (this.localAudioLevelObserver) {
            this.localAudioLevelObserver.dispose();
            this.localAudioLevelObserver = null;
        }
    }
    
    
    
    
    
    /*****************************************
    ************* Desktop sharing ************
    *****************************************/
    abstract disableSharingDesktop(): void;
    abstract enableSharingDesktop(): void;
    abstract getLocalDesktopTrack(): VideoConferenceTrack | null;
    isLocalParticipantSharingDesktop(): boolean {
        return this.isDesktopSharingEnabled;
    }
    
    
    
    
    
    /*****************************************
    ************** Participants **************
    *****************************************/
    getLocalParticipant(): VideoConferenceParticipant<null> | null {
        return this.localParticipant;
    }
    
    abstract getLocalParticipantId(): string;
    
    getParticipants(): { [participantId: string]: VideoConferenceParticipant<any> } {
        return this.participants;
    }
    
    getParticipant(participantId: string): VideoConferenceParticipant<any> | null {
        return this.participants[participantId] ?? null;
    }
    
    buf2hex(buffer: ArrayBuffer): string {
        return [...new Uint8Array(buffer)].map(x => x.toString(16).padStart(2, "0")).join("");
    }
    
    hex2buf(hex: string): ArrayBuffer {
        return new Uint8Array(hex.match(/[\da-f]{2}/gi)!.map(function (h) {
            return parseInt(h, 16)
        })).buffer;
    }
    
    hex2bufBackCompat(hex: string): ArrayBuffer {
        return new Uint8Array(hex.match(/[\da-f]{1}/gi)!.map(function (h) {
            return h.charCodeAt(0);
        })).buffer;
    }
    
    buf2str(buffer: ArrayBuffer): string {
        return [...new Uint8Array(buffer)].map(x => String.fromCharCode(x)).join("");
    }
    
    protected async encryptParticipantName(participantName: string): Promise<string> {
        if (this.encryptionKey === null || this.encryptionIV === null) {
            throw new Error("Unable to encrypt participant name: missing encryption key/IV");
        }
        const arrBuff = await crypto.subtle.encrypt(
            {
                name: "AES-GCM",
                iv: this.encryptionIV,
                tagLength: 128,
            },
            this.encryptionKey,
            new TextEncoder().encode(participantName),
        );
        return this.buf2hex(arrBuff);
    }
    
    protected async decryptParticipantName(participantName: string): Promise<string> {
        if (this.encryptionKey === null || this.encryptionIV === null) {
            throw new Error("Unable to decrypt participant name: missing encryption key/IV");
        }
        const arrBuff = await crypto.subtle.decrypt(
            {
                name: "AES-GCM",
                iv: this.encryptionIV,
                tagLength: 128,
            },
            this.encryptionKey,
            this.hex2buf(participantName)
        );
        return this.buf2str(arrBuff);
    }
    
    async generateEncryptionKey(): Promise<string> {
        const key = await crypto.subtle.generateKey(
            VideoConference.ENCRYPTION_ALGORITHM_KEYGEN,
            true,
            VideoConference.ENCRYPTION_USAGES
        );
        const exportedKey = await crypto.subtle.exportKey("raw", key);
        return this.buf2hex(exportedKey);
    }
    
    generateEncryptionIv(): string {
        return this.buf2hex(crypto.getRandomValues(new Uint8Array(12)));
    }
    
    async setEncryptionKey(key: string): Promise<void> {
        const importedKey = await crypto.subtle.importKey(
            "raw",
            this.hex2buf(key),
            VideoConference.ENCRYPTION_ALGORITHM_KEYGEN,
            true,
            VideoConference.ENCRYPTION_USAGES
        );
        this.encryptionKey = importedKey;
    }
    
    setEncryptionIV(iv: string): void {
        this.encryptionIV = new Uint8Array(this.hex2bufBackCompat(iv));
    }
    
    isParticipantAudible(participantId: string): boolean {
        const participant = this.getParticipant(participantId);
        if (!participant) {
            return false;
        }
        if (participant == this.localParticipant) {
            return this.isLocalAudioInputEnabled && participant && participant.isTalking;
        }
        else {
            return this.isLocalAudioOutputEnabled && participant && participant.isTalking;
        }
    }
    
    abstract getCustomDominantSpeaker(): VideoConferenceParticipant<any> | null;
    
    abstract getDominantSpeaker(): VideoConferenceParticipant<any> | null;
    
    
    
    
    
    
    /*****************************************
    **************** Messages ****************
    *****************************************/
    protected showErrorMessage(i18nKey: string): void {
        return this.showMessage(i18nKey, "error");
    }
    
    protected showWarningMessage(i18nKey: string): void {
        return this.showMessage(i18nKey, "warning");
    }
    
    protected showSuccessMessage(i18nKey: string): void {
        return this.showMessage(i18nKey, "success");
    }
    
    protected showInfoMessage(i18nKey: string): void {
        return this.showMessage(i18nKey, "info");
    }
    
    protected showMessage(i18nKey: string, type: MessageType): void {
        this.requestShowMessage(i18nKey, type);
    }
    
    
    
    
    
    /*****************************************
    ********** Camera configuration **********
    *****************************************/
    protected cameraConfiguration: CameraConfiguration | null = null;
    protected previouslySetResolution:Types.VideoResolution | null = null;
    
    protected abstract trySetupCameraConfiguration(): Promise<void>;
    
    async getAvailableResolutions(): Promise<Types.VideoResolution[]> {
        if (!this.isLocalVideoInputEnabled) {
            return [];
        }
        if (!this.cameraConfiguration) {
            await this.trySetupCameraConfiguration();
        }
        return this.cameraConfiguration ? this.cameraConfiguration.getAvailableResolutions() : [];
    }
    
    async setResolution(resolution: Types.VideoResolution): Promise<void> {
        this.previouslySetResolution = JSON.parse(JSON.stringify(resolution));
        if (!this.cameraConfiguration) {
            await this.trySetupCameraConfiguration();
        }
        if (this.cameraConfiguration) {
            this.cameraConfiguration.setResolution(resolution);
        }
    }
    
    async clearCameraConfiguration(): Promise<void> {
        this.cameraConfiguration = null;
    }
    
    
    
    
    
    /*****************************************
    ************** Video effects *************
    *****************************************/
    protected localVideoEffect: VideoEffect | null = null;
    
    protected abstract applyLocalVideoEffect(): Promise<boolean>;
    
    async setLocalVideoEffect(localVideoEffect: VideoEffect): Promise<boolean> {
        this.localVideoEffect = localVideoEffect;
        return this.applyLocalVideoEffect();
    }
    
    getLocalVideoEffect(): VideoEffect | null {
        return this.localVideoEffect;
    }
    
    /*****************************************
    ****************** Misc ******************
    *****************************************/
    abstract supportsScriptVersion(version: string): boolean;
    
    /**
    * a < b    => -1;
    * a == b   => 0;
    * a > b    => 1;
    */
    protected compareVersions(a: string, b: string): -1 | 0 | 1 {
        const [a1, a2, a3] = a.split(".").map(x => parseInt(x)) as [number, number, number];
        const [b1, b2, b3] = b.split(".").map(x => parseInt(x)) as [number, number, number];
        if (a1 > b1) { return 1; }
        if (a1 < b1) { return -1; }
        if (a2 > b2) { return 1; }
        if (a2 < b2) { return -1; }
        if (a3 > b3) { return 1; }
        if (a3 < b3) { return -1; }
        return 0;
    }
    
}
