import { createStyles, rem } from '@mantine/core';

export const useDraftElementStyle = createStyles((theme) => {
    return {
        root: {
            minWidth: 250,
            transition: `all 0.3s ${theme.transitionTimingFunction}`,
            padding: theme.spacing.md,
            paddingBottom: `calc(${theme.spacing.md} + ${rem(20)})`,
            transform: `translateY(${theme.spacing.md})`,
            cursor: 'pointer',
            '&:hover': {
                transform: `translateY(0)`,
                transition: `all 0.3s ${theme.transitionTimingFunction}`
            }
        },
        container: {
            position: 'fixed',
            bottom: 0,
            right: theme.spacing.md
        }
    };
});
