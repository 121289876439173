import { Controller, useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { Email } from '../../types/Types';
import { api } from '../../api/Api';
import * as PmxApi from 'privmx-server-api';
import FormButtons from '../../atoms/FormButtons';
import { iconLoading, iconSave } from '../Icon';
import useModal from '../../hooks/useModal';
import { Center, Select, Stack, TextInput } from '@mantine/core';
import { usePreventClose } from '../../hooks/usePreventClose';
import { useTranslation } from 'react-i18next';

export interface CreateUserProps {
    handleClose: () => void;
}

interface FormData {
    email: Email;
    isAdmin: boolean;
}

export default function CreateUser(props: CreateUserProps) {
    const { t } = useTranslation();
    const handleClose = props.handleClose;
    const [isProcessing, setIsProcessing] = useState(false);
    const modalService = useModal();

    const {
        control,
        register,
        handleSubmit,
        setError,
        formState: { errors, isDirty }
    } = useForm<FormData>();
    usePreventClose(isDirty);

    const handleFormSubmit = useCallback(
        async (data: FormData) => {
            if (data.email.trim() === '') {
                setError('email', {});
                return;
            }
            setIsProcessing(true);
            try {
                const res = await api.createInternalUser(
                    data.email,
                    'en' as PmxApi.api.core.Language,
                    data.isAdmin,
                    { type: 'manuallyViaAdminPanel' }
                );
                modalService.openCreatedUserDataModal({
                    options: {
                        login: res.login,
                        password: res.password
                    }
                });
                handleClose();
            } finally {
                setIsProcessing(false);
            }
        },
        [modalService, handleClose, setError]
    );

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack>
                <TextInput
                    required
                    label={t('entityProps.email')}
                    defaultValue={''}
                    {...register('email', { required: false, maxLength: 100 })}
                    disabled={isProcessing}
                    error={!!errors.email}
                />
                <Controller
                    control={control}
                    name="isAdmin"
                    defaultValue={false}
                    render={({ field, fieldState }) => (
                        <Select
                            error={fieldState.error?.type}
                            label={t('entityProps.role')}
                            data={[
                                { label: t('entityProps.role.admin'), value: 'true' },
                                { label: t('entityProps.role.regular'), value: 'false' }
                            ]}
                            value={`${field.value}`}
                            onChange={(value) =>
                                value === 'true' ? field.onChange(true) : field.onChange(false)
                            }
                        />
                    )}
                />
                <Center>
                    <FormButtons
                        onCancel={props.handleClose}
                        isSaving={isProcessing}
                        saveIcon={isProcessing ? iconLoading : iconSave}
                        spinSaveIcon={isProcessing}
                    />
                </Center>
            </Stack>
        </form>
    );
}
