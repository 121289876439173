export interface BuildFormatLongFnArgs<DefaultMatchWidth extends any> {
    formats: Partial<{ [format in any]: string }> & { [format in any]: string };
    defaultWidth: DefaultMatchWidth;
}

export function buildFormatLongFn<DefaultMatchWidth>(
    args: BuildFormatLongFnArgs<DefaultMatchWidth>
) {
    return (options: any = {}) => {
        const width = options.width ? String(options.width) : args.defaultWidth;
        const format = (args.formats as any)[width] || (args.formats as any)[args.defaultWidth];
        return format;
    };
}

const rtf = new Intl.RelativeTimeFormat(undefined, {
    localeMatcher: 'best fit',
    numeric: 'always',
    style: 'long'
});

function formatToNow(timestamp: number) {
    const diffInSeconds = Math.floor((Date.now() - timestamp) / 1000);

    if (diffInSeconds < 60) {
        return rtf.format(-diffInSeconds, 'second');
    } else if (diffInSeconds < 3600) {
        return rtf.format(-Math.floor(diffInSeconds / 60), 'minute');
    } else if (diffInSeconds < 86400) {
        return rtf.format(-Math.floor(diffInSeconds / 3600), 'hour');
    } else if (diffInSeconds < 2592000) {
        return rtf.format(-Math.floor(diffInSeconds / 86400), 'day');
    } else if (diffInSeconds < 31536000) {
        return rtf.format(-Math.floor(diffInSeconds / 2592000), 'month');
    } else {
        return rtf.format(-Math.floor(diffInSeconds / 31536000), 'year');
    }
}

export function displayRelativeTime(time: number) {
    if (!time || time === -1) return 'never';

    if (Math.abs(Date.now() - time) > 518_400_000) {
        return displayformatedTime(time);
    }
    return formatToNow(time);
}

export function displayformatedTime(time: number) {
    return new Date(time).toLocaleDateString([], {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });
}

export function displayFormatedTimeOfDay(time: number) {
    return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}

export function displayFullFormatedTime(time: number) {
    return new Date(time).toLocaleString([], {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
}
