import { createStyles } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const useUnreadContentStyles = createStyles((_, { isUnread }: { isUnread: boolean }) => ({
    content: {
        fontWeight: isUnread ? 'bold' : 'initial',
        '.mantine-Text-root': {
            fontWeight: isUnread ? 'bold' : 'initial'
        },
        '*[data-no-unread], *[data-no-unread] *': {
            fontWeight: 'normal'
        }
    }
}));

export default function UnreadContent() {
    const { t } = useTranslation();
    return <div>{t('unreadContent')}</div>;
}
