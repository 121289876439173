export interface VideoConferenceSettings {
    containersDisplayMode?: "tiles" | "single-speaker";
    isVideoInputEnabled?: boolean;
    isAudioInputEnabled?: boolean;
    isAudioOutputEnabled?: boolean;
    showLocalParticipant?: boolean;
    videoResolution?: {
        width: number;
        height: number;
    };
}

export const defaultVideoConferenceSettings: Required<VideoConferenceSettings> = {
    containersDisplayMode: "tiles",
    isVideoInputEnabled: true,
    isAudioInputEnabled: true,
    isAudioOutputEnabled: true,
    showLocalParticipant: true,
    videoResolution: {
        width: 640,
        height: 360,
    },
};
