import * as types from '../../types/Types';
import * as privmx from 'privfs-client';

export class ChunkStream {
    private key = privmx.crypto.service.randomBytes(32);
    private cursor = 0;
    private seq = 0;
    private checkSums: Buffer[] = [];

    constructor(private blob: Blob, private chunkSize: types.ChunkSize) {}

    async next() {
        const info = this.getNextChunkInfo();
        if (info === false) {
            return false;
        }
        const iKey = await this.prepareKey(info.seq);
        const rawChunk = this.blob.slice(info.start, info.end);
        const { hmac, cipher } = await this.prepareChunk(
            iKey,
            Buffer.from(await rawChunk.arrayBuffer())
        );
        this.checkSums.push(hmac);
        return { done: info.reachEnd, seq: info.seq, chunk: cipher };
    }

    getKey() {
        return this.key;
    }

    getChecksums() {
        return Buffer.concat(this.checkSums);
    }

    private getNextChunkInfo() {
        if (this.cursor >= this.blob.size) {
            return false;
        }
        const seq = this.seq;
        this.seq++;

        const start = this.cursor;
        const newCursor = this.cursor + this.chunkSize;
        const reachEnd = newCursor >= this.blob.size;
        const end = reachEnd ? this.blob.size : newCursor;
        this.cursor = end;
        return {
            seq,
            start,
            end,
            reachEnd
        };
    }

    private async prepareKey(seq: number) {
        const seqBuffer = Buffer.alloc(4);
        seqBuffer.writeUInt32BE(seq, 0);
        return privmx.crypto.service.sha256(Buffer.concat([this.key, seqBuffer]));
    }

    private async prepareChunk(key: Buffer, data: Buffer) {
        const iv = privmx.crypto.service.randomBytes(16);
        const cipher = await privmx.crypto.service.aes256CbcPkcs7Encrypt(data, key, iv);
        const hmac = await privmx.crypto.service.hmacSha256(key, Buffer.concat([iv, cipher]));
        return { hmac: hmac, cipher: Buffer.concat([hmac, iv, cipher]) };
    }
}
