import { Button, Center, Container, Title, Text, Group, Stack } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const XL = 34;

export default function FirstSteps() {
    const { t } = useTranslation();
    return (
        <Center h="100%" w="100%">
            <Container mx="auto" miw={500} size={'lg'}>
                <Title align="center" mb={XL} size={60}>
                    {t('screens.activateFirstAccount.firstSteps.goodToGo')}
                </Title>

                <Group position="apart" mb={XL} w="80%" mx="auto">
                    <Stack w="40%" spacing={0}>
                        <Title color="dimmed" order={4}>
                            1
                        </Title>
                        <Title order={4} mb="sm">
                            {t('screens.activateFirstAccount.firstSteps.addStaff')}
                        </Title>
                        <Text color="dimmed">
                            {t('screens.activateFirstAccount.firstSteps.createAccForColeaguesText')}
                        </Text>
                    </Stack>
                    <Stack w="40%" spacing={0}>
                        <Title color="dimmed" order={4}>
                            2
                        </Title>
                        <Title order={4} mb="sm">
                            {t('screens.activateFirstAccount.firstSteps.createFirstMessage')}
                        </Title>
                        <Text color="dimmed">
                            {t('screens.activateFirstAccount.firstSteps.setUpTopicsText')}
                        </Text>
                    </Stack>
                </Group>
                <Button component={Link} to="/" w={200} mx="auto" display={'block'}>
                    {t('commons.logIn')}
                </Button>
            </Container>
        </Center>
    );
}
