import { useAppDispatch, useAppSelector } from '../../../store';
import { selectNewContactModalState, toggleNewContactModal } from '../../../store/ModalsSlice';
import {
    Box,
    Flex,
    TextInput,
    Title,
    Text,
    Modal,
    Group,
    Button,
    useMantineTheme,
    Anchor,
    Checkbox,
    Tooltip,
    Stack,
    Center
} from '@mantine/core';
import { useCallback, useMemo, useState } from 'react';
import {
    Company,
    CompanyId,
    Contact,
    ContactAddress,
    ContactId,
    ContactName,
    ContactNote,
    Email,
    Language,
    MobilePhone,
    Phone,
    Tag,
    Timestamp,
    Avatar,
    Username
} from '../../../types/Types';
import { loadContacsAsync, selectCachedCompanies } from '../../../store/DataCacheSlice';
import { useInputState, useMediaQuery } from '@mantine/hooks';
import { api } from '../../../api/Api';
import { Validator } from '../../../utils/Validator';
import { Link } from 'react-router-dom';
import { useUserInteraction } from '../../../hooks/useUserInteraction';
import { useTranslation } from 'react-i18next';
import { LoadingMessageOverlay } from '../../../mantineAtoms/LoadingMessageOverlay';
import { getCompanyFromQuery, getCompanyId } from './utils';
import { CompanySelect } from './CompanySelect';
import { Deferred } from '../../../utils/Deferred';
import { HelpIcon } from '../../../atoms/HelpIcon';

interface ExistingHiddenContactNameQuestion {
    currentName: ContactName;
    enteredName: ContactName;
    nameDeferred: Deferred<ContactName>;
}

export function NewContactModal() {
    const modalState = useAppSelector(selectNewContactModalState);

    const dispatch = useAppDispatch();
    const [email, onEmailChange] = useInputState('');
    const [contactName, onContactNameChange] = useInputState('');
    const [isPrivate, onIsPrivateChange] = useInputState(true);
    const [contactEmailError, setContactEmailError] = useState<{ message: string } | null>(null);
    const [contactNameError, setContactNameError] = useState<{ message: string } | null>(null);
    const [
        existingHiddenContactNameQuestionDeferred,
        setExistingHiddenContactNameQuestionDeferred
    ] = useState<ExistingHiddenContactNameQuestion | null>(null);

    const allCompanies = useAppSelector(selectCachedCompanies);
    const [newCompany, setNewCompany] = useState<Company | undefined>();
    const companyOptions = useMemo(() => {
        if (newCompany) {
            return [...allCompanies, newCompany].map((company) => ({
                label: company.name,
                value: company.id
            }));
        } else {
            return allCompanies.map((company) => ({
                label: company.name,
                value: company.id
            }));
        }
    }, [allCompanies, newCompany]);

    const [company, setCompany] = useState<Company | undefined>();

    const closeModal = useCallback(
        function () {
            dispatch(toggleNewContactModal({ opened: false }));
        },
        [dispatch]
    );
    const { t } = useTranslation();

    const { startInteraction, isProcessing } = useUserInteraction({
        successTitle: t('modal.postAddContacts.titleSingular'),
        successMessage: (
            <>
                {t('modal.postAddContacts.messageSingular')}
                <Anchor ml={4} component={Link} to="/contacts">
                    {t('modal.createAccount.goToContacts')}
                </Anchor>
            </>
        ),
        errorTitle: t('modal.createAccount.error.errorTitle'),
        errorMessage: t('modal.createAccount.error.errorMessage'),
        async action() {
            const currentUserUsername = api.getCurrentUserUsername();
            const existingContact = api.getContactByEmailSync(email.trim() as Email);
            const comapnyId = await getCompanyId(company);
            if (existingContact === undefined) {
                const newContact: Contact = {
                    id: '' as ContactId,
                    address: '' as ContactAddress,
                    companyId: comapnyId,
                    email: email.trim() as Email,
                    mobilePhone: '' as MobilePhone,
                    name: contactName.trim() as ContactName,
                    note: '' as ContactNote,
                    phone: '' as Phone,
                    userType: 'email',
                    avatar: '' as Avatar,
                    language: 'en' as Language,
                    username: undefined,
                    pinned: false,
                    archived: false,
                    tags: [] as Tag[],
                    createdBy: '' as Username,
                    createdDate: 0 as Timestamp,
                    lastModifiedBy: '' as Username,
                    lastModifiedDate: 0 as Timestamp,
                    isPrivate: isPrivate && currentUserUsername !== undefined,
                    privateContactVisibleForUsers:
                        isPrivate && currentUserUsername !== undefined ? [currentUserUsername] : []
                };
                await api.addContact(newContact);
            } else {
                const isExistingContactVisibleForCurrentUser = api.isContactVisibleForCurrentUser(
                    email.trim() as Email
                );
                if (isExistingContactVisibleForCurrentUser) {
                    setContactEmailError({ message: t('errorMessage.contactAlreadyExists') });
                    return;
                } else {
                    const privateContactVisibleForUsers = isPrivate
                        ? [...existingContact.privateContactVisibleForUsers]
                        : [];
                    if (
                        isPrivate &&
                        currentUserUsername !== undefined &&
                        !privateContactVisibleForUsers.includes(currentUserUsername)
                    ) {
                        privateContactVisibleForUsers.push(currentUserUsername);
                    }
                    let newContactName = contactName.trim() as ContactName;
                    if (contactName.trim() !== existingContact.name) {
                        const existingHiddenContactNameQuestion: ExistingHiddenContactNameQuestion =
                            {
                                currentName: existingContact.name,
                                enteredName: contactName.trim() as ContactName,
                                nameDeferred: new Deferred<ContactName>()
                            };
                        setExistingHiddenContactNameQuestionDeferred(
                            existingHiddenContactNameQuestion
                        );
                        newContactName = await existingHiddenContactNameQuestion.nameDeferred
                            .promise;
                    }
                    await api.updateContact({
                        ...existingContact,
                        companyId: company?.id as CompanyId,
                        name: newContactName,
                        isPrivate: privateContactVisibleForUsers.length > 0,
                        privateContactVisibleForUsers: privateContactVisibleForUsers
                    });
                }
            }
            dispatch(loadContacsAsync());
            closeModal();
        }
    });

    const handleCreateContact = useCallback(async () => {
        let hasSubmitErrors = false;

        const validationResult = Validator.getErrors(email, ['email', 'required'], true);
        if (validationResult !== false) {
            if (validationResult.includes('size')) {
                const errorMsg = t('errorMessage.emailMaxLengthRequired', {
                    count: Validator.constraints['email'].maxLength
                });
                setContactEmailError({ message: errorMsg });
                hasSubmitErrors = true;
            }
            if (validationResult.includes('email')) {
                setContactEmailError({ message: t('errorMessage.invalidEmail') });
                hasSubmitErrors = true;
            }
            if (validationResult.includes('required')) {
                setContactEmailError({ message: t('errorMessage.emailRequired') });
                hasSubmitErrors = true;
            }
        }

        const nameResult = Validator.getErrors(contactName, ['safeDefault']);
        if (nameResult !== false) {
            if (nameResult.includes('safeDefault')) {
                setContactNameError({ message: 'Invalid Contact name' });
                hasSubmitErrors = true;
            }
        }
        const existingContact = api.getContactByEmailSync(email.trim() as Email);
        if (existingContact) {
            const isExistingContactVisibleForCurrentUser = api.isContactVisibleForCurrentUser(
                email.trim() as Email
            );
            if (isExistingContactVisibleForCurrentUser) {
                setContactEmailError({ message: t('errorMessage.contactAlreadyExists') });
                hasSubmitErrors = true;
            }
        }

        if (hasSubmitErrors) {
            return;
        }

        startInteraction();
    }, [startInteraction, contactName, email, t]);

    const theme = useMantineTheme();
    const isSmall = useMediaQuery(`max-width:${theme.breakpoints.md}`);

    const handleUseEnteredNameClick = useCallback(() => {
        existingHiddenContactNameQuestionDeferred?.nameDeferred.resolve(
            existingHiddenContactNameQuestionDeferred?.enteredName
        );
        setExistingHiddenContactNameQuestionDeferred(null);
    }, [existingHiddenContactNameQuestionDeferred]);

    const handleUseCurrentNameClick = useCallback(() => {
        existingHiddenContactNameQuestionDeferred?.nameDeferred.resolve(
            existingHiddenContactNameQuestionDeferred?.currentName
        );
        setExistingHiddenContactNameQuestionDeferred(null);
    }, [existingHiddenContactNameQuestionDeferred]);

    return (
        <Modal
            keepMounted={true}
            transitionProps={{
                duration: 200,
                transition: 'slide-up',
                timingFunction: 'ease-in'
            }}
            size={isSmall ? '100%' : 'auto'}
            centered
            onClose={() => closeModal()}
            opened={modalState.opened}
            styles={{
                content: {
                    minHeight: 530
                }
            }}>
            <Flex
                p="xl"
                pt={'xs'}
                h="100%"
                pos="relative"
                w={{ base: '100%', sm: 600 }}
                maw={{ base: '100%', md: '60vw' }}
                direction={'column'}>
                <LoadingMessageOverlay
                    visible={isProcessing && existingHiddenContactNameQuestionDeferred === null}
                    message={t('modal.contactAdd.addingNewContact')}
                />
                <Box sx={{ flexGrow: 1 }} w="100%" mb="lg" className="asdsadas">
                    <Title order={2} mb="sm">
                        {t('modal.contactAdd.addNewContact')}
                    </Title>
                    {existingHiddenContactNameQuestionDeferred === null && (
                        <>
                            <Text w={{ base: '100%', md: 550 }} mb="lg">
                                {t('modal.contactAdd.description')}
                            </Text>
                            <TextInput
                                error={contactEmailError && contactEmailError.message}
                                required
                                mb="sm"
                                placeholder="name@domain.com"
                                label={t('entityProps.email')}
                                onChange={onEmailChange}
                                value={email}
                            />
                            <TextInput
                                error={contactNameError && contactNameError.message}
                                mb="sm"
                                label={t('entityProps.name')}
                                placeholder={t('entityProps.contactName')}
                                onChange={onContactNameChange}
                                value={contactName}
                            />
                            <CompanySelect
                                value={company?.id}
                                onCreate={(query) => {
                                    const newCompany = getCompanyFromQuery(query);
                                    setCompany(newCompany);
                                    setNewCompany(newCompany);
                                    return `new:${query}`;
                                }}
                                onChange={(newCompany) => {
                                    if (newCompany) {
                                        if (!newCompany.startsWith('new:')) {
                                            setCompany(
                                                allCompanies.find(
                                                    (companyEntry) => companyEntry.id === newCompany
                                                )
                                            );
                                        }
                                    } else {
                                        setCompany(undefined);
                                    }
                                }}
                                data={companyOptions}
                            />
                            <Checkbox
                                mt="lg"
                                label={
                                    <Group spacing="xs">
                                        <Text>{t('modal.contactAdd.isPrivate')}</Text>
                                        <Tooltip
                                            label={t('modal.contactAdd.isPrivateTooltip')}
                                            withinPortal>
                                            <Text>
                                                <HelpIcon />
                                            </Text>
                                        </Tooltip>
                                    </Group>
                                }
                                checked={isPrivate}
                                onChange={onIsPrivateChange}
                            />
                        </>
                    )}
                    {existingHiddenContactNameQuestionDeferred !== null && (
                        <>
                            <Text w={{ base: '100%', md: 550 }} mb="lg">
                                {t('modal.contactAdd.nameConflict.question')}
                            </Text>
                        </>
                    )}
                </Box>
                {existingHiddenContactNameQuestionDeferred === null && (
                    <>
                        <Group mx="auto">
                            <Button variant="light" onClick={() => closeModal()}>
                                {t('action.cancel')}
                            </Button>
                            <Button onClick={handleCreateContact}>{t('action.save')}</Button>
                        </Group>
                    </>
                )}
                {existingHiddenContactNameQuestionDeferred !== null && (
                    <Center>
                        <Stack mt={200}>
                            <Button variant="light" onClick={handleUseEnteredNameClick}>
                                {t('modal.contactAdd.nameConflict.changeName', {
                                    name: existingHiddenContactNameQuestionDeferred.enteredName
                                })}
                            </Button>
                            <Button variant="filled" onClick={handleUseCurrentNameClick}>
                                {t('modal.contactAdd.nameConflict.keepName', {
                                    name: existingHiddenContactNameQuestionDeferred.currentName
                                })}
                            </Button>
                        </Stack>
                    </Center>
                )}
            </Flex>
        </Modal>
    );
}
