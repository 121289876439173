import { useRef, useCallback, useEffect, useLayoutEffect } from 'react';

export default function useScrolledContainer(watch: any) {
    const messageContainer = useRef<HTMLDivElement>(null);
    const scrolled = useRef<boolean>(true);

    const scrollToBottom = useCallback(() => {
        if (!messageContainer.current) return;
        messageContainer.current.scrollTop = messageContainer.current?.scrollHeight;
        scrolled.current = true;
    }, []);

    useEffect(() => {
        const update = () => {
            if (messageContainer.current)
                scrolled.current =
                    Math.abs(
                        messageContainer.current.scrollHeight -
                            messageContainer.current.clientHeight -
                            messageContainer.current.scrollTop
                    ) < 1;
        };
        let ref: HTMLDivElement | undefined = undefined;
        if (messageContainer.current) {
            ref = messageContainer.current;
            ref.addEventListener('scroll', update);
        }

        return () => {
            if (ref) ref.removeEventListener('scroll', update);
        };
    }, []);

    useLayoutEffect(() => {
        if (messageContainer.current && scrolled) {
            scrollToBottom();
        }
    }, [scrollToBottom, watch]);

    return { messageContainer, scrollToBottom };
}
