import { useCallback } from 'react';
import { api } from '../../api/Api';
import Icon, { iconRemoveFromList, iconSlideRight } from '../../components/Icon';
import { MeetingId, UsernameOrPubId } from '../../types/Types';
import { Button, Group } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { notifications } from '@mantine/notifications';

export interface VideoConferenceJoinedToastProps {
    toastId: string;
    meetingId: MeetingId;
    meetingTitle: string;
    usernameOrPubId: UsernameOrPubId;
    userDisplayName: string;
}

export default function VideoConferenceJoinedToast(props: VideoConferenceJoinedToastProps) {
    const { t } = useTranslation();

    const handleAddUserToMeetingClick = useCallback(() => {
        notifications.hide(props.toastId);
        if (props.usernameOrPubId.type === 'anonymousUser') {
            api.addAnonymousUserToThreadMeeting(props.meetingId, props.usernameOrPubId.pub, false);
        } else {
            api.addUserToThreadMeeting(props.meetingId, props.usernameOrPubId.username, false);
        }
    }, [props.toastId, props.meetingId, props.usernameOrPubId]);

    const handleRemoveUserFromLobbyClick = useCallback(() => {
        notifications.hide(props.toastId);
        if (props.usernameOrPubId.type === 'anonymousUser') {
            api.removeAnonymousFromThreadLobby(props.meetingId, props.usernameOrPubId.pub);
        } else {
            api.removeUserFromThreadLobby(props.meetingId, props.usernameOrPubId.username);
        }
    }, [props.toastId, props.usernameOrPubId, props.meetingId]);

    const handleCloseToastClick = useCallback(() => {
        notifications.hide(props.toastId);
    }, [props.toastId]);

    return (
        <Group noWrap>
            <Button
                onClick={handleAddUserToMeetingClick}
                leftIcon={<Icon icon={iconSlideRight} />}
                size="sm">
                {t('action.letIn')}
            </Button>
            <Button
                variant="light"
                leftIcon={<Icon icon={iconRemoveFromList} />}
                onClick={handleRemoveUserFromLobbyClick}
                size="sm">
                {t('action.denyAccess')}
            </Button>
            <Button variant="light" onClick={handleCloseToastClick} size="sm">
                {t('action.close')}
            </Button>
        </Group>
    );
}

export async function showVideoConferenceJoinedToast(
    meetingId: MeetingId,
    usernameOrPubId: UsernameOrPubId,
    userDisplayName: string
) {
    const meeting = await api.getMeeting(meetingId);
    if (!meeting) {
        return;
    }
    const meetingTitle = meeting.title;
    notifications.show({
        id: `meeting-${meetingId}`,
        title: `${meetingTitle} Meeting has just started`,
        withCloseButton: true,
        autoClose: false,
        message: (
            <VideoConferenceJoinedToast
                toastId={`meeting-${meetingId}`}
                meetingId={meetingId}
                meetingTitle={meetingTitle}
                usernameOrPubId={usernameOrPubId}
                userDisplayName={userDisplayName}
            />
        )
    });
}
