import { useRef } from 'react';
import { BaseModalData } from '../../../../types/Modals';
import useLoader from '../../../../hooks/useLoader';
import Modal from '../../../../atoms/Modal';
import StateView from '../../../../atoms/State';
import { MeetingEditPage } from './index';
import { MeetingId } from '../../../../types/Types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface MeetingEditModalData extends BaseModalData {
    id: MeetingId;
}

export function MeetingEditModal(props: {
    onClose: () => void;
    data: MeetingEditModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const ref = useRef();
    const { state, revalidate } = useLoader(
        useCallback((x) => x.loadMeetingForEditing(props.data.id), [props.data.id])
    );
    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            beforeClose={() => (ref.current as any)?.beforeClose()}
            height={props.data.height ?? 390}
            width={props.data.width}
            maw={800}
            title={
                props.data.title ??
                (state.type === 'done' && state.data.meeting && state.data.meeting.title
                    ? t('modal.meetingEdit.titleWithName', { name: state.data.meeting.title })
                    : t('modal.meetingEdit.titleWithoutName'))
            }>
            <StateView state={state} retry={revalidate}>
                {(data) => (
                    <MeetingEditPage ref={ref} meetingEdit={data} handleClose={props.onClose} />
                )}
            </StateView>
        </Modal>
    );
}
