import * as privfs from 'privfs-client';
import { AdminKeySender } from './AdminKeySender';

export class AdminRightService {
    constructor(
        private srpSecure: privfs.core.PrivFsSrpSecure,
        private adminKeySender: AdminKeySender,
        private identityKeypair: privfs.types.identity.IdentityKeypair
    ) {}

    async grantAdminRights(username: string): Promise<void> {
        await this.srpSecure.changeIsAdmin(this.identityKeypair, username, true);
        await this.adminKeySender.sendAdminKey(username);
    }

    async revokeAdminRights(username: string): Promise<void> {
        await this.srpSecure.changeIsAdmin(this.identityKeypair, username, false);
    }
}
