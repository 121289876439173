import { Utils } from './Utils';

export type ValidationRule =
    | 'email'
    | 'phone'
    | 'website'
    | 'password'
    | 'username'
    | 'safeDefault'
    | 'date'
    | 'richtext'
    | 'no_sanitize'
    | 'shorttext'
    | 'tag'
    | 'required'
    | 'slug';

export type ErrorType = ValidationRule | 'size';
export type ValidationResult = Array<ValidationRule>;
export interface Constraint {
    minLength: number;
    maxLength: number;
}

export class Validator {
    public static constraints: { [rule: string]: Constraint } = {
        email: {
            minLength: 0,
            maxLength: 50
        },
        tag: {
            maxLength: 30,
            minLength: 2
        },
        phone: {
            minLength: 0,
            maxLength: 20
        },
        password: {
            minLength: 8,
            maxLength: 50
        },
        username: {
            minLength: 0,
            maxLength: 40
        },
        website: {
            minLength: 0,
            maxLength: 100
        },
        shorttext: {
            minLength: 0,
            maxLength: 100
        },
        richtext: {
            minLength: 0,
            maxLength: 1000
        },
        slug: {
            minLength: 3,
            maxLength: 20
        }
    };
    private static hasUnicode = new RegExp(`[^\u0000-\u007F]+`, 'u');
    private static safeDefaultCharsMap: { [name: string]: string } = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '/': '&#x2F;'
    };
    public static getSafeDefaultChars = () => Object.keys(this.safeDefaultCharsMap).join(', ');

    private static predefinedSafeDefaultRegex = new RegExp(
        '[' + Object.keys(this.safeDefaultCharsMap).join('') + ']'
    );

    public static executor: { [rule: string]: (value: any) => boolean } = {
        email: (value: string) => {
            return (
                !Validator.hasUnicode.test(value) &&
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi.test(
                    value
                )
            );
        },
        phone: (value: string) => {
            return (
                !Validator.hasUnicode.test(value) &&
                /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g.test(value)
            );
        },
        password: (value: string) => {
            return (
                !Validator.hasUnicode.test(value) &&
                /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])+/.test(value)
            );
        },
        username: (value: string) => {
            return !Validator.hasUnicode.test(value) && /[a-zA-Z0-9]/gi.test(value);
        },
        website: (value: string) => {
            return (
                !Validator.hasUnicode.test(value) &&
                (/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/gi.test(
                    value
                ) ||
                    /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/gi.test(
                        value
                    ))
            ); //&& Validator.unicodeRegex.test(value)
        },
        tag(val: string) {
            return true;
        },
        safeDefault: (value: string) => {
            return !this.predefinedSafeDefaultRegex.test(value);
        },
        date: (value: string) => {
            const parsed = parseInt(value);
            return (
                Boolean(parsed) &&
                typeof parsed === 'number' &&
                new Date(parsed).toDateString() !== 'Invalid Date'
            );
        },
        no_sanitize: (_val: string) => {
            return true;
        },
        shorttext(val: string) {
            return true;
        },
        required(val: string) {
            return val.length > 0;
        },
        richtext(val: string) {
            return true;
        },
        slug(val: string) {
            return !Validator.hasUnicode.test(val) && /^([a-zA-Z0-9-_])+$/.test(val);
        }
    };

    public static sizeCheck: Record<ValidationRule, (val: string) => boolean> = {
        email(value: string) {
            return Validator.checkMaxLength(value, 'email');
        },
        phone(value: string) {
            return Validator.checkMaxLength(value, 'phone');
        },
        username(value: string) {
            return Validator.checkMaxLength(value, 'username');
        },
        website(value: string) {
            return Validator.checkMaxLength(value, 'website');
        },
        richtext(value: string) {
            return Validator.checkMaxLength(value, 'richtext');
        },
        password(value: string) {
            return (
                Validator.checkMaxLength(value, 'password') &&
                Validator.checkMinLength(value, 'password')
            );
        },
        tag(value: string) {
            return Validator.checkMaxLength(value, 'tag') && Validator.checkMinLength(value, 'tag');
        },
        safeDefault(value: string) {
            return true;
        },
        date(val: string) {
            return !!new Date(val);
        },
        no_sanitize(_val: string) {
            return true;
        },
        required(val: string) {
            return true;
        },
        shorttext(val: string) {
            return Validator.checkMaxLength(val, 'shorttext');
        },
        slug(val: string) {
            return Validator.checkMinLength(val, 'slug') && Validator.checkMaxLength(val, 'slug');
        }
    };

    private static checkMaxLength(value: string, rule: ValidationRule) {
        const expected = Validator.constraints[rule].maxLength;
        return expected ? value.length <= expected : true;
    }
    private static checkMinLength(value: string, rule: ValidationRule) {
        const expected = Validator.constraints[rule].minLength;
        return expected ? value.length >= expected : true;
    }
    static sanitizeValue(value: string): string {
        return Utils.escapeHtml(value);
    }

    public static check(value: string, rules: ValidationRule[] = ['safeDefault']): string {
        const sanitize = !rules.includes('no_sanitize');

        if (!rules.includes('required') && value.length === 0) {
            return value;
        }

        const errors = Validator._getErrors(value, rules);
        if (errors) {
            throw Error(
                'Invalid input value. Rules: [' +
                    (errors as ValidationResult).join(',') +
                    '] not met'
            );
        }
        return sanitize ? Validator.sanitizeValue(value) : value;
    }

    public static isValid(value: string, rules: ValidationRule[] = ['safeDefault']): boolean {
        rules = rules.includes('no_sanitize') ? rules : [...rules, 'no_sanitize'];
        try {
            Validator.check(value, rules);
            return true;
        } catch {
            return false;
        }
    }

    public static getErrors(
        value: string,
        rules: ValidationRule[] = ['safeDefault'],
        withSizeCheck?: boolean
    ): false | ErrorType[] {
        return Validator._getErrors(value, rules, withSizeCheck);
    }

    private static _getErrors(
        value: string,
        rules: ValidationRule[] = ['safeDefault'],
        withSizeCheck?: boolean
    ): false | ErrorType[] {
        if (!rules.includes('required') && value.length === 0) {
            return false;
        }
        const validationResult: ErrorType[] = [];
        for (const rule of rules) {
            const isValid = Validator.executor[rule];
            if (!isValid(value)) {
                validationResult.push(rule);
            }

            if (withSizeCheck && !Validator.sizeCheck[rule](value)) {
                validationResult.push('size');
            }
        }
        if (validationResult.length > 0) {
            return validationResult;
        }
        return false;
    }

    public static getSafeDefaultCharsMap() {
        return this.safeDefaultCharsMap;
    }
}
