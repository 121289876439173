const amen = require('./images/amen.svg').default;
const ave1 = require('./images/ave1.svg').default;
const avelapka1 = require('./images/avelapka1.svg').default;
const beka = require('./images/beka.svg').default;
const blush1 = require('./images/blush1.svg').default;
const brawo1 = require('./images/brawo1.svg').default;
const cacuszko1 = require('./images/cacuszko1.svg').default;
const call1 = require('./images/call1.svg').default;
const damyrade1 = require('./images/damyrade1.svg').default;
const devil1 = require('./images/devil1.svg').default;
const devil2 = require('./images/devil2.svg').default;
const dolar = require('./images/dolar.svg').default;
const exactly = require('./images/exactly.svg').default;
const facepalm = require('./images/facepalm.svg').default;
const fajnoooo = require('./images/fajnoooo.svg').default;
const fuck = require('./images/fuck.svg').default;
const hmmmm = require('./images/hmmmm.svg').default;
const kciuki1 = require('./images/kciuki1.svg').default;
const lezka1 = require('./images/lezka1.svg').default;
const lovitserce1 = require('./images/lovitserce1.svg').default;
const machanie1 = require('./images/machanie1.svg').default;
const minus1 = require('./images/minus1.svg').default;
const muskuly1 = require('./images/muskuly1.svg').default;
const nadloniach1 = require('./images/nadloniach1.svg').default;
const nazimno = require('./images/nazimno.svg').default;
const nieok1 = require('./images/nieok1.svg').default;
const ok1 = require('./images/ok1.svg').default;
const okularnikChil1 = require('./images/okularnik chil1.svg').default;
const okularnik1 = require('./images/okularnik1.svg').default;
const ooo1 = require('./images/ooo1.svg').default;
const pisze1 = require('./images/pisze1.svg').default;
const plus1 = require('./images/plus1.svg').default;
const przymroz1 = require('./images/przymroz1.svg').default;
const przymrozsmiech1 = require('./images/przymrozsmiech1.svg').default;
const serce = require('./images/serce.svg').default;
const smile1 = require('./images/smile1.svg').default;
const smuta1 = require('./images/smuta1.svg').default;
const spocksalut1 = require('./images/spocksalut1.svg').default;
const uscisk1 = require('./images/uscisk1.svg').default;
const victory = require('./images/victory.svg').default;
const wniebie1 = require('./images/wniebie1.svg').default;
const wrrr1 = require('./images/wrrr1.svg').default;
const wskazujacy1 = require('./images/wskazujacy1.svg').default;
const wskazujewbok1 = require('./images/wskazujewbok1.svg').default;
const wskazujewbok2 = require('./images/wskazujewbok2.svg').default;
const wtf1 = require('./images/wtf1.svg').default;
const youRocks = require('./images/you-rocks.svg').default;
const zgon1 = require('./images/zgon1.svg').default;
const zolwik1 = require('./images/zolwik1.svg').default;
const zolwik2 = require('./images/zolwik2.svg').default;

export type Icon =
    | typeof amen
    | typeof ave1
    | typeof avelapka1
    | typeof beka
    | typeof blush1
    | typeof brawo1
    | typeof cacuszko1
    | typeof call1
    | typeof damyrade1
    | typeof devil1
    | typeof devil2
    | typeof dolar
    | typeof exactly
    | typeof facepalm
    | typeof fajnoooo
    | typeof fuck
    | typeof hmmmm
    | typeof kciuki1
    | typeof lezka1
    | typeof lovitserce1
    | typeof machanie1
    | typeof minus1
    | typeof muskuly1
    | typeof nadloniach1
    | typeof nazimno
    | typeof nieok1
    | typeof ok1
    | typeof okularnikChil1
    | typeof okularnik1
    | typeof ooo1
    | typeof pisze1
    | typeof plus1
    | typeof przymroz1
    | typeof przymrozsmiech1
    | typeof serce
    | typeof smile1
    | typeof smuta1
    | typeof spocksalut1
    | typeof uscisk1
    | typeof victory
    | typeof wniebie1
    | typeof wrrr1
    | typeof wskazujacy1
    | typeof wskazujewbok1
    | typeof wskazujewbok2
    | typeof wtf1
    | typeof youRocks
    | typeof zgon1
    | typeof zolwik1
    | typeof zolwik2;

export const emojiIcons = {
    amen,
    ave1,
    avelapka1,
    beka,
    blush1,
    brawo1,
    cacuszko1,
    call1,
    damyrade1,
    devil1,
    devil2,
    dolar,
    exactly,
    facepalm,
    fajnoooo,
    fuck,
    hmmmm,
    kciuki1,
    lezka1,
    lovitserce1,
    machanie1,
    minus1,
    muskuly1,
    nadloniach1,
    nazimno,
    nieok1,
    ok1,
    okularnikChil1,
    okularnik1,
    ooo1,
    pisze1,
    plus1,
    przymroz1,
    przymrozsmiech1,
    serce,
    smile1,
    smuta1,
    spocksalut1,
    uscisk1,
    victory,
    wniebie1,
    wrrr1,
    wskazujacy1,
    wskazujewbok1,
    wskazujewbok2,
    wtf1,
    youRocks,
    zgon1,
    zolwik1,
    zolwik2
};

export type EmojiIconName = keyof typeof emojiIcons;

export const emojiIconsWithNames = [
    { name: 'amen', icon: amen },
    { name: 'ave1', icon: ave1 },
    { name: 'avelapka1', icon: avelapka1 },
    { name: 'beka', icon: beka },
    { name: 'blush1', icon: blush1 },
    { name: 'brawo1', icon: brawo1 },
    { name: 'cacuszko1', icon: cacuszko1 },
    { name: 'call1', icon: call1 },
    { name: 'damyrade1', icon: damyrade1 },
    { name: 'devil1', icon: devil1 },
    { name: 'devil2', icon: devil2 },
    { name: 'dolar', icon: dolar },
    { name: 'exactly', icon: exactly },
    { name: 'facepalm', icon: facepalm },
    { name: 'fajnoooo', icon: fajnoooo },
    { name: 'fuck', icon: fuck },
    { name: 'hmmmm', icon: hmmmm },
    { name: 'kciuki1', icon: kciuki1 },
    { name: 'lezka1', icon: lezka1 },
    { name: 'lovitserce1', icon: lovitserce1 },
    { name: 'machanie1', icon: machanie1 },
    { name: 'minus1', icon: minus1 },
    { name: 'muskuly1', icon: muskuly1 },
    { name: 'nadloniach1', icon: nadloniach1 },
    { name: 'nazimno', icon: nazimno },
    { name: 'nieok1', icon: nieok1 },
    { name: 'ok1', icon: ok1 },
    { name: 'okularnikChil1', icon: okularnikChil1 },
    { name: 'okularnik1', icon: okularnik1 },
    { name: 'ooo1', icon: ooo1 },
    { name: 'pisze1', icon: pisze1 },
    { name: 'plus1', icon: plus1 },
    { name: 'przymroz1', icon: przymroz1 },
    { name: 'przymrozsmiech1', icon: przymrozsmiech1 },
    { name: 'serce', icon: serce },
    { name: 'smile1', icon: smile1 },
    { name: 'smuta1', icon: smuta1 },
    { name: 'spocksalut1', icon: spocksalut1 },
    { name: 'uscisk1', icon: uscisk1 },
    { name: 'victory', icon: victory },
    { name: 'wniebie1', icon: wniebie1 },
    { name: 'wrrr1', icon: wrrr1 },
    { name: 'wskazujacy1', icon: wskazujacy1 },
    { name: 'wskazujewbok1', icon: wskazujewbok1 },
    { name: 'wskazujewbok2', icon: wskazujewbok2 },
    { name: 'wtf1', icon: wtf1 },
    { name: 'youRocks', icon: youRocks },
    { name: 'zgon1', icon: zgon1 },
    { name: 'zolwik1', icon: zolwik1 },
    { name: 'zolwik2', icon: zolwik2 }
];

export const hands: EmojiIconName[] = [
    'ok1',
    'cacuszko1',
    'uscisk1',
    'muskuly1',
    'brawo1',
    'ave1',
    'kciuki1',
    'victory',
    'devil2',
    'nieok1',
    'fuck',
    'amen',
    'exactly',
    'youRocks',
    'serce'
];

export const faces: EmojiIconName[] = [
    'fajnoooo',
    'smile1',
    'nazimno',
    'smuta1',
    'lezka1',
    'ooo1',
    'przymroz1',
    'lovitserce1',
    'zgon1',
    'beka',
    'hmmmm',
    'facepalm',
    'blush1',
    'plus1',
    'minus1'
];
