import {
    Avatar,
    Feed,
    FeedId,
    FormModelEntries,
    FormRowId,
    Query,
    QueryId,
    Timestamp,
    UserId,
    Username
} from '../types/Types';
import { map } from './ApiUtils';
import { db } from './Db';

const formId = 'oiqwceiquweqcebqoih' as FormModelEntries['id'];
export const newForm: FormModelEntries = {
    id: formId,
    type: 'inquiry',
    name: 'New form',
    entriesCount: 2,
    status: 'draft',
    tags: [],
    managers: [],
    users: [],
    main: false,
    lastSubmitDate: 0 as Timestamp,
    pinned: false,
    archived: false,
    createdBy: '' as Username,
    createdDate: 0 as Timestamp,
    lastModifiedBy: '' as Username,
    lastModifiedDate: 0 as Timestamp,
    autoResponseData: { emailFieldId: '', enabled: false, message: '', title: '' },
    shortLink: undefined,
    captchaEnabled: true,
    questions: [
        {
            title: 'Short Answer Question',
            type: 'short',
            answer: {
                input: '',
                type: 'short',
                id: 'myvibsmeagsjqxhbfppi'
            },
            id: 'qdljsmnqhaskikehpkpc',
            required: false
        },
        {
            title: 'Select Question',
            type: 'select',
            answer: [
                {
                    input: 'answer1',
                    type: 'select',
                    id: 'launfswkoiopojkyeobo'
                },
                {
                    input: 'answer1',
                    type: 'select',
                    id: 'kehjqijyxmurwociqobc'
                }
            ],
            id: 'edyrhtiipejirwdjcjho',
            required: false
        }
    ],
    answers: [
        {
            id: 'eeohwxwnptxayqjfwlyi' as FormRowId,
            formId: formId,
            date: 1671705553361 as Timestamp,
            adminData: { note: '' },
            tags: [],
            attachments: [],
            answers: [
                {
                    id: 'qdljsmnqhaskikehpkpc',
                    type: 'short',
                    answer: [
                        {
                            type: 'short',
                            id: 'qdljsmnqhaskikehpkpc',
                            input: 'dfbdsfb'
                        }
                    ]
                },
                {
                    id: 'edyrhtiipejirwdjcjho',
                    type: 'select',
                    answer: [
                        {
                            input: 'answer1',
                            type: 'select',
                            id: 'launfswkoiopojkyeobo'
                        },
                        {
                            input: 'answer1',
                            type: 'select',
                            id: 'kehjqijyxmurwociqobc'
                        }
                    ]
                }
            ]
        },
        {
            id: 'qdljsmnqhaskikehpkpc' as FormRowId,
            formId: formId,
            date: 1671705589313 as Timestamp,
            adminData: { note: '' },
            tags: [],
            attachments: [],
            answers: [
                {
                    id: 'qdljsmnqhaskikehpkpc',
                    type: 'short',
                    answer: [
                        {
                            type: 'short',
                            id: 'qdljsmnqhaskikehpkpc',
                            input: ' fgdfgngfhn'
                        }
                    ]
                },
                {
                    id: 'edyrhtiipejirwdjcjho',
                    type: 'select',
                    answer: [
                        {
                            input: 'answer1',
                            type: 'select',
                            id: 'launfswkoiopojkyeobo'
                        }
                    ]
                }
            ]
        }
    ]
};

export const user = {
    id: 'st1' as UserId,
    userRole: 'staff',
    username: 'staff1' as Username,
    name: 'abc-st1',
    avatar: '' as Avatar,
    isAdmin: true,
    password: 'qwerty'
};

export const customer = {
    id: 'cl1' as UserId,
    userRole: 'client',
    username: 'client1' as Username,
    name: 'def-cl1',
    avatar: '' as Avatar,
    isAdmin: true,
    password: 'qwerty'
};

export const queries: Query[] = [
    {
        id: 'query-1' as QueryId,
        name: 'Rozmowy o umowach',
        screen: 'chat',
        filter: 'umowa'
    },
    {
        id: 'query-2' as QueryId,
        name: 'Spółki z ograniczoną odpowiedzialnością',
        screen: 'companies',
        filter: 'sp. z o.o.'
    },
    {
        id: 'query-3' as QueryId,
        name: 'Formularze rekrutacyjne',
        screen: 'forms',
        filter: 'recruitment'
    }
];

export const feeds: Feed[] = [
    {
        id: '1' as FeedId,
        type: 'chat',
        read: false,
        newFilesCount: 0,
        newMsgCount: 5,
        thread: map(db.chats[0], (x) => ({
            id: x.props.id,
            title: x.props.title,
            users: x.props.users
        }))
    },
    {
        id: '2' as FeedId,
        type: 'meeting',
        read: false,
        newFilesCount: 1,
        newMsgCount: 2,
        meeting: map(db.meetings[0], (x) => ({
            id: x.props.id,
            title: x.props.title
        }))
    },
    {
        id: '3' as FeedId,
        type: 'form',
        read: false,
        newRecordsCount: 1,
        form: map(db.forms[0], (x) => ({
            id: x.id,
            name: x.name
        }))
    },
    {
        id: '4' as FeedId,
        type: 'contact',
        read: false,
        contact: map(db.contacts[0], (x) => ({
            id: x.id,
            email: x.email,
            name: x.name
        }))
    },
    {
        id: '5' as FeedId,
        type: 'company',
        read: false,
        company: map(db.companies[0], (x) => ({
            id: x.id,
            name: x.name
        }))
    },
    {
        id: '6' as FeedId,
        type: 'contact',
        read: false,
        contact: map(db.contacts[1], (x) => ({
            id: x.id,
            email: x.email,
            name: x.name
        }))
    },
    {
        id: '7' as FeedId,
        type: 'company',
        read: false,
        company: map(db.companies[1], (x) => ({
            id: x.id,
            name: x.name
        }))
    }
];
