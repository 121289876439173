export type Result<T> = ResultSuccess<T> | ResultFail;
export interface ResultSuccess<T> {
    success: true;
    result: T;
}
export interface ResultFail {
    success: false;
    error: unknown;
}

export class Utils {
    static unique<T>(array: T[]): T[] {
        return [...new Set(array)];
    }

    static addMany<T>(destination: T[], toAdd: T[]) {
        for (const x of toAdd) {
            destination.push(x);
        }
    }

    static calcDiff<T>(oldList: T[], newList: T[]) {
        const removed: T[] = [];
        const newOnes: T[] = [];
        const theSame: T[] = [];
        for (const x of oldList) {
            if (newList.includes(x)) {
                theSame.push(x);
            } else {
                removed.push(x);
            }
        }
        for (const x of newList) {
            if (!oldList.includes(x)) {
                newOnes.push(x);
            }
        }
        return { removed, newOnes, theSame };
    }

    static updateObject<T>(dest: T, src: T): { value: T; changed: boolean } {
        if (typeof dest !== typeof src || Array.isArray(dest) !== Array.isArray(src)) {
            return { value: src, changed: true };
        }
        if (dest == null || src == null || typeof src != 'object' || typeof dest != 'object') {
            return { value: src, changed: dest !== src };
        }
        if (Array.isArray(dest) && Array.isArray(src)) {
            let changed = dest.length !== src.length;
            const newArray: any[] = [];
            for (let i = 0; i < src.length; i++) {
                const res = this.updateObject(dest[i], src[i]);
                changed = changed || res.changed;
                newArray.push(res.value);
            }
            return { changed: changed, value: changed ? (newArray as unknown as T) : dest };
        }
        let changed = false;
        const newObj: any = {};
        for (const key in src) {
            const res = this.updateObject(dest[key], src[key]);
            changed = changed || res.changed;
            newObj[key] = res.value;
        }
        if (!changed) {
            for (const key in dest) {
                if (!(key in src)) {
                    changed = true;
                    break;
                }
            }
        }
        return { changed: changed, value: changed ? (newObj as T) : dest };
    }

    static try<T>(func: () => T): Result<T> {
        try {
            return { success: true, result: func() };
        } catch (e) {
            return { success: false, error: e };
        }
    }

    static async tryPromise<T>(func: () => Promise<T>): Promise<Result<T>> {
        try {
            return { success: true, result: await func() };
        } catch (e) {
            return { success: false, error: e };
        }
    }

    static resultSuccess<T>(result: T): ResultSuccess<T> {
        return { success: true, result: result };
    }

    static resultFail(error: unknown): ResultFail {
        return { success: false, error: error };
    }

    static randomTemporaryPassword(nCharacters: number) {
        const characters = []; // ["!", "#", "$", "%", "?"];
        for (let i = 48; i <= 57; i++) {
            characters.push(String.fromCharCode(i));
        }
        let password = '';
        for (let i = 0; i < nCharacters; i++) {
            password += characters[Math.floor(Math.random() * characters.length)];
        }
        return nCharacters < 6 ? password : password.replace(/\B(?=(\d{3})+(?!\d))/g, '-');
    }

    static simpleDeepClone<T>(simpleObject: T): T {
        return JSON.parse(JSON.stringify(simpleObject));
    }

    static isDefined<T>(ele: T | null | undefined): ele is T {
        return ele !== null && ele !== undefined;
    }

    static isJsonRpcError(e: any): e is { msg: string; data: { error: { code: number } } } {
        return e && e.msg && e.data && e.data && e.data.error && e.data.error.code;
    }

    static async tryMultipleTimes<T>(options: {
        func: () => Promise<T>;
        isErrorRecoverable: (e: unknown) => boolean;
        maxTimes?: number;
    }) {
        let i = 0;
        const maxTimes = typeof options.maxTimes === 'undefined' ? -1 : options.maxTimes;
        while (true) {
            try {
                return await options.func();
            } catch (e) {
                if ((maxTimes === -1 || i < maxTimes) && options.isErrorRecoverable(e)) {
                    i++;
                    continue;
                }
                throw e;
            }
        }
    }
}
