import { Box, Stack, Title, Group, Button, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export function PasswordSendReminder(props: { onBack: VoidFunction; onProceed: VoidFunction }) {
    const { t } = useTranslation();

    return (
        <Box h="100%" mx="auto" w={500}>
            <Stack h="100%" justify="center" align="center">
                <Title align="center" order={2}>
                    {t('modal.accountsAdded.reminder.title')}
                </Title>
                <Text align="center" color="dimmed" mb={-12}>
                    {t('modal.accountsAdded.reminder.usersNeedPasswords')}
                </Text>
                <Text align="center" color="dimmed" mb="xl">
                    {t('modal.accountsAdded.reminder.onlyAdminCanReset')}
                </Text>
                <Group>
                    <Button variant="light" onClick={props.onBack}>
                        {t('modal.accountsAdded.reminder.temporaryPasswords')}
                    </Button>
                    <Button onClick={props.onProceed}>
                        {t('modal.accountsAdded.reminder.done')}
                    </Button>
                </Group>
            </Stack>
        </Box>
    );
}
