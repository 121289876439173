export class PrivmxConst {
    static readonly ADMIN_KVDB_INDEX = 1;
    static readonly IDENTITY_INDEX = 2;
    static readonly SETTINGS_KVDB_INDEX = 10;
    static readonly TAG_PROVIDER_KVDB_INDEX = 13;
    static readonly MAIL_FILTER_KVDB_INDEX = 14;
    static readonly PKI_CACHE_KVDB_INDEX = 15;
    static readonly CONTACTS_KVDB_INDEX = 17;
    static readonly SINK_ENCRYPTOR_INDEX = 25;
    static readonly USER_PREFERENCES_KVDB_KEY = 'user-preferences';
    static readonly SHARED_KVDB_KEY = 'sharedKvdb';
    static readonly COMPANY_KVDB_KEY = 'contactCompanyKvdb';
    static readonly CONTACT_KVDB_KEY = 'contactContactKvdb';
    static readonly COMPANY_EXT_KEY = 'contactCompanyExtKey';
    static readonly CONTACT_EXT_KEY = 'contactContactExtKey';
    static readonly DRAFT_EXT_KEY = 'contactDraftExtKey';
    static readonly SHARED_FILE_USER_DATA_EXT_KEY = 'contactSharedFileUserDataExtKey';
    static readonly TAG_KEY = 'contactTagKey';
    static readonly ADMIN_SINK_KEY = 'adminSink';
    static readonly FAVORITE_KEY = 'favoritesKey';
    static readonly READ_ITEMS_KEY = 'readItemsKey_v2';

    static readonly TWOFA_INVALID_CODE = 0x7007;
    static readonly TWOFA_VERIFICATION_FAILED = 0x7008;
    static readonly TWOFA_CODE_ALREADY_USED = 0x7011;
}
