import Modal from '../../atoms/Modal';
import { BaseModalData } from '../../types/Modals';
import CreatedUserData, { CreatedUserDataObj } from './index';
import { useTranslation } from 'react-i18next';

export interface CreatedUserDataModalData extends BaseModalData {
    options: CreatedUserDataObj;
}

export function CreatedUserDataModal(props: {
    onClose: () => void;
    data: CreatedUserDataModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            height={props.data.height ?? 250}
            width={props.data.width}
            title={props.data.title ?? t('modal.createdUserData.title')}>
            <CreatedUserData createdUserData={props.data.options} handleClose={props.onClose} />
        </Modal>
    );
}
