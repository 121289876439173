import {
    ActionIcon,
    Box,
    Button,
    Code,
    Group,
    Input,
    Skeleton,
    Tooltip,
    createStyles,
    rem
} from '@mantine/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { api } from '../../api/Api';
import useToast from '../../hooks/useToast';
import { useTranslation } from 'react-i18next';
import { IconCopy, IconEye } from '@tabler/icons-react';
import { CopyButton } from '@mantine/core';
import { useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';

export const useStyles = createStyles((theme) => {
    return {
        element: {
            padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
            borderRadius: theme.radius.sm,
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0]
        }
    };
});

export function ViewMnemonic({ initialPassword }: { initialPassword?: string }) {
    const { t } = useTranslation();
    const toast = useToast();
    const { classes } = useStyles();
    const [mnemonic, setMnemonic] = useState<string | null>(null);
    const [isLoadingMnemonic, setIsLoadingMnemonic] = useState<boolean>(false);
    const mnemonicPasswordRef = useRef<HTMLInputElement>(null);
    const handleShowMnemonic = useCallback(async () => {
        if (!mnemonicPasswordRef.current) {
            return;
        }
        try {
            setIsLoadingMnemonic(true);
            const mnemonic = await api.getMnemonic(mnemonicPasswordRef.current.value);
            setMnemonic(mnemonic);
        } catch (err) {
            toast(t('errorMessage.couldNotGetMnemonic'), 'error');
            console.error(err);
        } finally {
            setIsLoadingMnemonic(false);
        }
    }, [t, toast]);

    const currentUser = useAppSelector(selectCurrentUser);

    useEffect(() => {
        if (!initialPassword) return;

        const f = async () => {
            if (initialPassword) {
                const mnemonic = await api.getMnemonic(initialPassword);
                setMnemonic(mnemonic);
                setIsLoadingMnemonic(false);
            }
        };

        try {
            setIsLoadingMnemonic(true);
            if (currentUser.id) {
                f();
            }
        } catch (error) {
            toast(t('errorMessage.couldNotGetMnemonic'), 'error');
            console.error(error);
            setIsLoadingMnemonic(false);
        }
    }, [t, toast, initialPassword, currentUser.id]);

    return (
        <Box className={classes.element} my="md" maw={600}>
            {!mnemonic && !initialPassword && (
                <Group mt="sm" mb="lg">
                    <Input
                        type="password"
                        placeholder={t('screen.settings.mnemonic.currentPassword')}
                        ref={mnemonicPasswordRef}
                    />
                    <Button
                        leftIcon={<IconEye />}
                        onClick={handleShowMnemonic}
                        variant="light"
                        loading={isLoadingMnemonic}>
                        {t('screen.settings.mnemonic.showMnemonic')}
                    </Button>
                </Group>
            )}
            {isLoadingMnemonic && !mnemonic && (
                <Box mt="sm" mb="lg" pos="relative">
                    {new Array(20).fill('').map((_x, i) => (
                        <Skeleton
                            key={i}
                            display={'inline-block'}
                            mr="xs"
                            h={20}
                            w={80 - Math.random() * 20}></Skeleton>
                    ))}
                </Box>
            )}
            {mnemonic && (
                <Box mt="sm" mb="lg" pos="relative">
                    <CopyButton value={mnemonic}>
                        {({ copied, copy }) => {
                            return (
                                <Tooltip
                                    label={copied ? t('successMessage.copied') : t('action.copy')}>
                                    <ActionIcon
                                        pos="absolute"
                                        top={rem(-16)}
                                        right={rem(-14)}
                                        size={'lg'}
                                        onClick={copy}
                                        color={copied ? 'teal' : 'brand'}>
                                        <IconCopy />
                                    </ActionIcon>
                                </Tooltip>
                            );
                        }}
                    </CopyButton>
                    <Code block maw={600} sx={{ whiteSpace: 'break-spaces' }}>
                        {mnemonic ?? ''}
                    </Code>
                </Box>
            )}
        </Box>
    );
}
