import * as types from '../types/Types';
import { DataCacheInterface } from './DataCacheInterface';
import { store } from '../store';
import { selectCachedAttachmentsByThreadId as _selectCachedAttachmentsByThreadId } from '../store/DataCacheSlice';

export class DataCacheService implements DataCacheInterface {
    selectCachedAttachmentsByThreadId(threadId: unknown): any {
        return _selectCachedAttachmentsByThreadId(threadId as types.ChatId)(store.getState());
    }
}
