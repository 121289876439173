import { createStyles } from '@mantine/core';

export const useCompanyModalStyles = createStyles((theme) => {
    return {
        companyListElement: {
            borderRadius: theme.radius.md,
            padding: theme.spacing.sm,
            fontSize: theme.fontSizes.sm,
            cursor: 'pointer',

            '&:hover': {
                backgroundColor:
                    theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]
            }
        },
        companiesList: {
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
            padding: theme.spacing['sm'],
            borderTopRightRadius: theme.radius.md,
            borderBottomRightRadius: theme.radius.md,
            display: 'flex',
            flexDirection: 'column'
        }
    };
});
