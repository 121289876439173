import { ActionIcon, Group, Stack, Text, rem } from '@mantine/core';
import UserAvatar from '../../atoms/UserAvatar';
import { AttachmentId, FullFavoriteMessage } from '../../types/Types';
import parse from 'html-react-parser';
import { displayRelativeTime } from '../../utils/RelativeTimeFormat';
import HoverContainer from '../../mantineAtoms/HoverContainer';
import MessageAttachment from '../../components/MessageView/MessageAttachment';
import { useCallback } from 'react';
import { api } from '../../api/Api';
import { IconBookmarkMinus } from '@tabler/icons-react';

interface FileElementProps {
    favoriteMessage: FullFavoriteMessage;
    onFileClick: (id: AttachmentId) => void;
}

export function FavoriteListElement({ favoriteMessage, onFileClick }: FileElementProps) {
    const { message } = favoriteMessage;
    const content = favoriteMessage.message.text ? parse(favoriteMessage.message.text) : '';

    const handleFavoriteClick = useCallback(() => {
        if (message.id !== undefined) {
            api.removeFavorite({ messageId: message.id });
        }
        api.getFavoriteChatMessages();
    }, [message.id]);

    return (
        <HoverContainer withBorder={false} p="md" hovered={false}>
            <Stack sx={(theme) => ({ borderRadius: theme.radius.md })} spacing={'xs'}>
                <Group spacing="xs">
                    <UserAvatar username={favoriteMessage.message.author} />
                    <Text size="xs">{favoriteMessage.message.author}</Text>
                    <Text size="xs" color="dimmed">
                        {displayRelativeTime(favoriteMessage.message.date)}
                    </Text>
                    <ActionIcon ml="auto" onClick={handleFavoriteClick}>
                        <IconBookmarkMinus size={rem(16)} />
                    </ActionIcon>
                </Group>
                {content ? (
                    <Text ml={`calc(${rem(26)} + ${rem(12)} )`} size="xs">
                        {content}
                    </Text>
                ) : null}
                {favoriteMessage.message.attachments.length > 0 && (
                    <Group align="start">
                        {favoriteMessage.message.attachments.map((x) => (
                            <MessageAttachment
                                key={x.id}
                                attachment={x}
                                pending={false}
                                onOpenPreview={onFileClick}
                            />
                        ))}
                    </Group>
                )}
            </Stack>
        </HoverContainer>
    );
}
