import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ChallengeModelSerializable, TwofaMethod } from '../../api/privmx/TwofaApi';
import { useAppDispatch } from '../../store';
import { setTwofaResult } from '../../store/ModalsSlice';
import * as webauthn from 'webauthn-js';
import { DataEncoder } from '../../utils/DataEncoder';
import { Box, Button, Checkbox, Group, PinInput } from '@mantine/core';
import { usePreventClose } from '../../hooks/usePreventClose';
import * as i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';

export interface TwofaPromptData {
    method: TwofaMethod;
    showRememberDevice?: boolean;
    errorMessage?: string;
    noForm?: boolean;
    uf2Login?: webauthn.Types.PublicKeyCredentialRequestOptions;
    uf2Register?: webauthn.Types.PublicKeyCredentialCreationOptions;
}
export interface CreatedUserDataProps {
    twofaPrompt: TwofaPromptData;
    handleClose: () => void;
    onSave: (model: ChallengeModelSerializable) => void;
    onResend: () => void;
}

interface FormData {
    twofaCode: string;
    rememberDevice: boolean;
}

function arrBuffToArray(arrBuff: ArrayBuffer) {
    return [...new Uint8Array(arrBuff)];
}

export default function TwofaPrompt(props: CreatedUserDataProps) {
    const { t } = useTranslation();
    const handleClose = props.handleClose;
    const onSave = props.onSave;
    const onResend = props.onResend;
    const [isProcessing, setIsProcessing] = useState(false);
    const dispatch = useAppDispatch();
    const resultDispatched = useRef<boolean>(false);
    const twofaCode1InputRef = useRef<HTMLInputElement | null>(null);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        getValues,
        control
    } = useForm<FormData>();
    usePreventClose(isDirty);

    const handleCloseClick = useCallback(() => {
        resultDispatched.current = true;
        dispatch(setTwofaResult({ cancelled: true }));
        handleClose();
    }, [dispatch, handleClose]);

    const startU2F = useCallback(() => {
        if (props.twofaPrompt.method !== 'u2f') {
            return;
        }
        if (props.twofaPrompt.uf2Login) {
            navigator.credentials
                .get({ publicKey: DataEncoder.decode(props.twofaPrompt.uf2Login) })
                .then((res: any) => {
                    const challengeModel: ChallengeModelSerializable = {
                        u2fLogin: {
                            id: res.id,
                            rawId: arrBuffToArray(res.rawId),
                            type: res.type,
                            response: {
                                authenticatorData: arrBuffToArray(res.response.authenticatorData),
                                clientDataJSON: arrBuffToArray(res.response.clientDataJSON),
                                signature: arrBuffToArray(res.response.signature),
                                userHandle: res.response.userHandle
                                    ? arrBuffToArray(res.response.userHandle)
                                    : res.response.userHandle
                            }
                        },
                        rememberDeviceId: getValues().rememberDevice
                    };
                    resultDispatched.current = true;
                    dispatch(setTwofaResult({ cancelled: false, challengeModel: challengeModel }));
                })
                .catch((e: any) => {
                    dispatch(
                        setTwofaResult({
                            error: i18next.t('errorMessage.couldNotAuthenticateWithU2F')
                        })
                    );
                    console.error(e);
                });
        } else if (props.twofaPrompt.uf2Register) {
            navigator.credentials
                .create({ publicKey: DataEncoder.decode(props.twofaPrompt.uf2Register) })
                .then((res: any) => {
                    const challengeModel: ChallengeModelSerializable = {
                        u2fRegister: {
                            id: res.id,
                            rawId: arrBuffToArray(res.rawId),
                            type: res.type,
                            response: {
                                attestationObject: arrBuffToArray(res.response.attestationObject),
                                clientDataJSON: arrBuffToArray(res.response.clientDataJSON)
                            }
                        },
                        rememberDeviceId: getValues().rememberDevice
                    };
                    resultDispatched.current = true;
                    // dispatch(setTwofaResult({ cancelled: false, challengeModel: challengeModel }));
                    onSave(challengeModel);
                })
                .catch((e: any) => {
                    dispatch(
                        setTwofaResult({
                            error: i18next.t('errorMessage.couldNotAuthenticateWithU2F')
                        })
                    );
                    console.error(e);
                });
        }
    }, [
        props.twofaPrompt.method,
        props.twofaPrompt.uf2Login,
        props.twofaPrompt.uf2Register,
        getValues,
        onSave,
        dispatch
    ]);

    const handleFormSubmit = useCallback(
        async (data: FormData) => {
            setIsProcessing(true);
            try {
                if (props.twofaPrompt.method === 'u2f') {
                    startU2F();
                } else {
                    resultDispatched.current = true;
                    const twofaCode = data.twofaCode;
                    const rememberDevice = data.rememberDevice;
                    const challengeModel: ChallengeModelSerializable = {
                        code: twofaCode,
                        rememberDeviceId: rememberDevice
                    };
                    dispatch(setTwofaResult({ cancelled: false, challengeModel: challengeModel }));
                    onSave(challengeModel);
                }
            } finally {
                setIsProcessing(false);
            }
        },
        [startU2F, props.twofaPrompt.method, dispatch, onSave]
    );

    useEffect(() => {
        return () => {
            if (!resultDispatched.current) {
                dispatch(setTwofaResult({ cancelled: true }));
            }
        };
    }, [dispatch]);

    const handleResendCodeClick = useCallback(async () => {
        dispatch(setTwofaResult({ cancelled: false, resendCode: true }));
        onResend();
    }, [onResend, dispatch]);

    useEffect(() => {
        twofaCode1InputRef.current?.focus();
    }, []);

    const canResendCode =
        props.twofaPrompt.method === 'email' || props.twofaPrompt.method === 'sms';
    const twofaCodeLength = props.twofaPrompt.method === 'googleAuthenticator' ? 6 : 4;

    return (
        <Box>
            {props.twofaPrompt.method !== 'u2f' && (
                <Text align="center">{t('twofa.verificationCode')}</Text>
            )}
            {props.twofaPrompt.method === 'u2f' && (
                <Text align="center">{t('twofa.u2fVerification')}</Text>
            )}
            {props.twofaPrompt.method === 'email' && (
                <Text align="center">{t('twofa.enterCodeFromEmail')}:</Text>
            )}
            {props.twofaPrompt.method === 'googleAuthenticator' && (
                <Text align="center">{t('twofa.enterCodeFromDevice')}:</Text>
            )}
            {props.twofaPrompt.method === 'sms' && (
                <Text align="center">{t('twofa.enterCodeFromSms')}:</Text>
            )}
            {props.twofaPrompt.method === 'u2f' && (
                <Text align="center">{t('twofa.plugU2FIn')}</Text>
            )}
            <Box h={40}>
                {props.twofaPrompt.errorMessage && (
                    <Text align="center" color="red">
                        {props.twofaPrompt.errorMessage}
                    </Text>
                )}
            </Box>
            {props.twofaPrompt.noForm && <Button onClick={handleCloseClick}>Close</Button>}
            {!props.twofaPrompt.noForm && (
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    {props.twofaPrompt.method !== 'u2f' && (
                        <Controller
                            control={control}
                            name="twofaCode"
                            render={({ field }) => (
                                <PinInput
                                    error={!!errors.twofaCode?.message}
                                    sx={{ justifyContent: 'center' }}
                                    mx="auto"
                                    mb="sm"
                                    value={field.value}
                                    onChange={(newPin) => field.onChange(newPin)}
                                    length={twofaCodeLength}
                                    type="number"
                                />
                            )}
                        />
                    )}
                    {props.twofaPrompt.showRememberDevice !== false && (
                        <Checkbox
                            mb="sm"
                            styles={{
                                body: {
                                    justifyContent: 'center'
                                }
                            }}
                            label={t('twofa.rememberDevice')}
                            {...register('rememberDevice', {})}
                        />
                    )}

                    <Group position="center">
                        <Button size="sm" onClick={handleClose} variant="light">
                            Cancel
                        </Button>
                        {canResendCode && (
                            <Button size="sm" onClick={handleResendCodeClick} variant="light">
                                {t('twofa.resendCode')}
                            </Button>
                        )}
                        <Button size="sm" type="submit" onClick={startU2F} loading={isProcessing}>
                            Continue
                        </Button>
                    </Group>
                </form>
            )}
        </Box>
    );
}
