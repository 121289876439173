import { createStyles } from '@mantine/core';

export const useFormTableStyles = createStyles((theme) => {
    return {
        table: {
            tableLayout: 'fixed',
            width: '100%'
        },
        tableCell: {
            textWrap: 'nowrap'
        }
    };
});
