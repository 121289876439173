import * as PmxApi from 'privmx-server-api';
import { api } from '../api/Api';
import { showVideoConferenceJoinedToast } from '../atoms/VideoConferenceJoinedToast';
import { MeetingId, ThreadMeetingLobbyChangeEvent, UsernameOrPubId } from '../types/Types';
import { store } from '../store';

export class MeetingLobbyObserver {
    private lobbyUsers: PmxApi.api.thread.MeetingLobbyUser[] = [];
    private onThreadLobbyChangeBound = this.onThreadLobbyChange.bind(this);
    private resetBound = this.reset.bind(this);

    constructor() {
        api.addEventListener('threadlobbychange', this.onThreadLobbyChangeBound);
        api.addEventListener('loggedout', this.resetBound);
    }

    reset() {
        this.lobbyUsers = [];
    }

    private onThreadLobbyChange(evt: ThreadMeetingLobbyChangeEvent) {
        if (!api.isLoggedIn()) {
            return;
        }
        const newLobbyUsers = evt.lobbyUsers.filter((newUser) => {
            if (newUser.type === 'anonymous') {
                return !this.lobbyUsers.some(
                    (oldUser) => oldUser.type === 'anonymous' && oldUser.pub === newUser.pub
                );
            } else {
                return !this.lobbyUsers.some(
                    (oldUser) => oldUser.type === 'regular' && oldUser.username === newUser.username
                );
            }
        });
        this.lobbyUsers = evt.lobbyUsers;
        const state = store.getState();
        const currentUser = state.currentUser;
        const meeting = state.dataCache.meetings.find((x) => x.id === evt.threadId);
        const canManageMeeting =
            currentUser.username && meeting && meeting.managers.includes(currentUser.username);
        if (!canManageMeeting) {
            return;
        }
        for (const user of newLobbyUsers) {
            const usernameOrPubId: UsernameOrPubId =
                user.type === 'anonymous'
                    ? { type: 'anonymousUser', pub: user.pub }
                    : { type: 'user', username: user.username };
            const userDisplayName =
                user.type === 'anonymous'
                    ? user.nickname
                    : api.getUser(user.username)?.name ?? user.username;
            showVideoConferenceJoinedToast(
                evt.threadId as MeetingId,
                usernameOrPubId,
                userDisplayName
            );
        }
    }

    destroy() {
        api.removeEventListener('threadlobbychange', this.onThreadLobbyChangeBound);
        api.removeEventListener('loggedout', this.resetBound);
    }
}
