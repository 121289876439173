import { ActionIcon, Badge, BadgeProps, ThemeIcon, rem } from '@mantine/core';
import {
    IconFile,
    IconFileMusic,
    IconFileSpreadsheet,
    IconFileText,
    IconFileTypeDoc,
    IconFileTypePdf,
    IconMovie,
    IconPhoto,
    IconX
} from '@tabler/icons-react';
import React from 'react';
import { useFileBadgeStyles } from './FileBadge.styles';

export interface FileBadgeProps<T> {
    name: string;
    mimetype: string;
    iconSize?: number;
    attachmentId?: T;
    onDelete?: (attachmentId: T) => void;
}

function getMimetypeIcon(mimetype: string, iconSize?: number) {
    const ICON_SIZE = rem(iconSize ?? 12);

    if (mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return <IconFileTypeDoc size={ICON_SIZE} />;
    }
    if (mimetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return <IconFileSpreadsheet size={ICON_SIZE} />;
    }
    if (mimetype.startsWith('audio/')) {
        return <IconFileMusic size={ICON_SIZE} />;
    }
    if (mimetype.startsWith('image/')) {
        return <IconPhoto size={ICON_SIZE} />;
    }
    if (mimetype.startsWith('video/')) {
        return <IconMovie size={ICON_SIZE} />;
    }
    if (mimetype === 'application/pdf') {
        return <IconFileTypePdf size={ICON_SIZE} />;
    }
    if (mimetype === 'text/plain') {
        return <IconFileText size={ICON_SIZE} />;
    }
    return <IconFile size={ICON_SIZE} />;
}

export function FileBadge<T>({
    attachmentId,
    onDelete,
    onClick,
    name,
    iconSize,
    ...props
}: FileBadgeProps<T> & BadgeProps & React.HTMLAttributes<HTMLDivElement>) {
    const { classes, cx } = useFileBadgeStyles();

    const removeAttachmentButton =
        onDelete && attachmentId ? (
            <ActionIcon
                component="div"
                size="xs"
                radius="xl"
                variant="transparent"
                className={classes.actionIcon}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    onDelete?.(attachmentId);
                }}>
                <IconX size={rem(10)} />
            </ActionIcon>
        ) : undefined;

    const leftSection = (
        <ThemeIcon variant="transparent">{getMimetypeIcon(props.mimetype, iconSize)}</ThemeIcon>
    );

    return (
        <Badge
            className={cx({ [classes.hoverableRoot]: !!onClick })}
            pr={onDelete ? 4 : undefined}
            pl={4}
            maw={250}
            classNames={{
                inner: classes.inner,
                leftSection: classes.badgeLeftSection
            }}
            variant="outline"
            leftSection={leftSection}
            rightSection={removeAttachmentButton}
            onClick={onClick}
            {...props}>
            {name}
        </Badge>
    );
}
