import { Box, Button, Group, Text, useMantineTheme } from '@mantine/core';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectPlanSummary } from '../../store/UserPreferenceSlice';
import { useTranslation } from 'react-i18next';
import { toggleAdminSettingsModal } from '../../store/ModalsSlice';
import { selectCurrentUser } from '../../store/CurrentUserSlice';

export function SummaryBar() {
    const theme = useMantineTheme();

    const summary = useAppSelector(selectPlanSummary);
    const currentUser = useAppSelector(selectCurrentUser);
    const { t } = useTranslation();

    function getBarColor() {
        const userSummary = summary.staffAccounts.used / summary.staffAccounts.limit;
        const storage = summary.storage.used / summary.storage.limit;
        const video = summary.video.limit ? summary.video.usage / summary.video.limit : 0;

        if (userSummary >= 0.9 || storage >= 0.9 || video) {
            return theme.colorScheme === 'dark' ? 'pink.9' : 'red.3';
        } else if (userSummary >= 0.7 || storage >= 0.7 || video) {
            return theme.colorScheme === 'dark' ? 'dark.3' : 'gray.3';
        }
    }

    function getBarContent() {
        const userSummary = summary.staffAccounts.used / summary.staffAccounts.limit;
        const storage = summary.storage.used / summary.storage.limit;
        const video = summary.video.limit ? summary.video.usage / summary.video.limit : 0;

        let info: string = '';
        let warning: string = '';

        if (userSummary >= 0.9) {
            warning = t('planSummary.warningUsers');
        } else if (userSummary >= 0.7) {
            info = t('planSummary.infoUsers');
        }
        if (storage >= 0.9) {
            warning = t('planSummary.warningStorage');
        } else if (storage >= 0.7) {
            info = t('planSummary.infoStorage');
        }

        if (video >= 0.9) {
            warning = t('planSummary.warningStorage');
        } else if (video >= 0.7) {
            info = t('planSummary.infoStorage');
        }

        return warning || info;
    }
    const dispatch = useAppDispatch();

    if (!getBarContent()) {
        return <></>;
    }

    return (
        <Box ta="center" sx={{ zIndex: 20 }} bg={getBarColor()} pos="fixed" top={0} w="100%">
            <Group position="center" mx="auto">
                <Text size={'xs'}>{getBarContent()} </Text>
                {currentUser.isAdmin && (
                    <Button
                        size="xs"
                        color={theme.colorScheme === 'dark' ? 'gray.5' : 'dark.4'}
                        compact
                        variant="outline"
                        onClick={() =>
                            dispatch(toggleAdminSettingsModal({ open: true, initialTab: 'plan' }))
                        }>
                        {t('planSummary.settings')}
                    </Button>
                )}
            </Group>
        </Box>
    );
}
