import { Dispatch, forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { api, meetingApi } from '../../../../api/Api';
import { useAppSelector } from '../../../../store';
import { selectCurrentUser } from '../../../../store/CurrentUserSlice';
import * as types from '../../../../types/Types';
import { Deferred } from '../../../../utils/Deferred';
import { MeetingAddLoaderData } from '../../../../utils/router/loaders';
import ModalForm from '../../../ModalForm';
import { Anchor, Button, Select, useMantineTheme } from '@mantine/core';
import {
    getMeetingFromData,
    getMessageFromData,
    getMessageFromDataAtt,
    MeetingFormData,
    useAttachemnts
} from '../utils';
import { DraftUtils } from '../../../../utils/DraftUtils';
import ConfirmModal from '../../../../atoms/ConfirmModal';
import { DateTimePicker } from '@mantine/dates';
import { usePreventClose } from '../../../../hooks/usePreventClose';
import { CreationMessageAction } from '../../../ChatAddPage/ChatAddModal';
import { useTranslation } from 'react-i18next';
import { UserUtils } from '../../../../utils/UserUtils';
import { selectUsernameToName } from '../../../../store/DataCacheSlice';
import { useMediaQuery } from '@mantine/hooks';
import { useUserInteraction } from '../../../../hooks/useUserInteraction';
import { notifications } from '@mantine/notifications';
import { Link } from 'react-router-dom';
import { Validator } from '../../../../utils/Validator';

function getClosestQuarter() {
    const timeToReturn = new Date();
    timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
    timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
    timeToReturn.setMinutes(Math.ceil(timeToReturn.getMinutes() / 15) * 15);
    return timeToReturn.getTime();
}

export const meetingDurations = [
    {
        label: '15 min',
        value: '900000'
    },
    {
        label: '30 min',
        value: '1800000'
    },
    {
        label: '45 min',
        value: '2700000'
    },
    {
        label: '60 min',
        value: '3600000'
    },
    {
        label: '1h 15min',
        value: '4500000'
    },
    {
        label: '1h 30min',
        value: '5400000'
    },
    {
        label: '1h 45min',
        value: '6300000'
    },
    {
        label: '2h',
        value: '7200000'
    }
];

export const MeetingAddPage = forwardRef(
    (
        props: {
            dispatchCreationAction: Dispatch<CreationMessageAction>;
            meetingAdd: MeetingAddLoaderData;
            handleClose: () => void;
            users?: types.UsernameOrContactId[];
            managers?: types.Username[];
            basedOnThreadId?: types.ThreadId;
        },
        ref
    ) => {
        const { t } = useTranslation();
        const draft =
            props.meetingAdd.meetingDraft && typeof props.meetingAdd.meetingDraft === 'object'
                ? props.meetingAdd.meetingDraft
                : null;
        const [confirmModalDeferred, setConfirmModalDeferred] = useState<
            Deferred<boolean> | undefined
        >(undefined);

        const { attachments } = useAttachemnts(props.meetingAdd.meetingDraft?.attachments ?? []);
        const currentUser = useAppSelector(selectCurrentUser);
        const handleClose = props.handleClose;
        const displayMap = useAppSelector(selectUsernameToName);

        const form = useForm<MeetingFormData>({
            defaultValues: {
                startDate: getClosestQuarter(),
                duration: 900000,
                tags: [],
                managers: [{ label: currentUser.name || '', value: currentUser.username || '' }],
                users: UserUtils.usernameOrIdToOption(
                    props.users || [],
                    props.meetingAdd.users,
                    displayMap
                )
            }
        });

        const getValues = form.getValues;
        const setError = form.setError;
        const isDirty = form.formState.isDirty;
        usePreventClose(isDirty);
        useImperativeHandle(ref, () => ({
            beforeClose() {
                if (!isDirty) {
                    return true;
                }
                const deferred = new Deferred<boolean>();
                setConfirmModalDeferred(deferred);
                return deferred.promise;
            }
        }));
        const dispatchCreationAction = props.dispatchCreationAction;

        const { startInteraction, isProcessing } = useUserInteraction({
            successTitle: 'New Meeting added',
            emitSuccessToast: false,
            errorTitle: 'Error while adding new Meeting',
            errorMessage: 'Try reloading the app and adding the Meeting again.',
            async action(data: MeetingFormData) {
                const newMeeting = getMeetingFromData(data);
                newMeeting.users.push({
                    type: 'user',
                    username: currentUser?.username || ('' as any)
                });
                const message = (meetingId: types.MeetingId) =>
                    getMessageFromDataAtt(data, attachments, 'html', meetingId);
                const msgId = meetingApi.generateMessageId();
                const {
                    newMeeting: serverMeeting,
                    newAccounts,
                    privateContactsMadeVisible
                } = await api.addMeeting(newMeeting, msgId, message);
                if (newAccounts.size || privateContactsMadeVisible.length > 0) {
                    dispatchCreationAction({
                        type: 'setState',
                        payload: 'created'
                    });
                    dispatchCreationAction({
                        type: 'setPayload',
                        payload: {
                            createdUsers: newAccounts,
                            privateContactsMadeVisible,
                            title: serverMeeting.title,
                            id: serverMeeting.id
                        }
                    });
                } else {
                    handleClose();
                }
                notifications.show({
                    id: 'meeting-added',
                    withCloseButton: true,
                    title: 'New Meeting added',
                    message: (
                        <Anchor component={Link} to={`/chat/${serverMeeting.id}`}>
                            Go to thread
                        </Anchor>
                    )
                });
                if (props.basedOnThreadId) {
                    await api.sendMeetingScheduledMessage(
                        props.basedOnThreadId,
                        serverMeeting.id as types.ThreadId as types.MeetingId
                    );
                }
                if (props.meetingAdd.meetingDraft?.id) {
                    await api.deleteDraft(props.meetingAdd.meetingDraft.id);
                }
            }
        });

        const handleFormSubmit = useCallback(
            async (data: MeetingFormData) => {
                let hasError: boolean = false;
                if (data.title.trim() === '') {
                    setError('title', { type: 'required' });
                    hasError = true;
                }
                const titleErrors = Validator.getErrors(data.title);
                if (titleErrors) {
                    setError('title', {
                        type: 'value',
                        message: t('modal.meetingAdd.error.incorectTitle', {
                            characters: Validator.getSafeDefaultChars()
                        })
                    });
                    hasError = true;
                }
                let wrongUser: string = '';
                if (Validator.getErrors(data.startDate.toString(), ['date'])) {
                    setError('startDate', {
                        type: 'value',
                        message: 'Incorect date format'
                    });
                    hasError = true;
                }
                if (Validator.getErrors((data.startDate + data.duration).toString(), ['date'])) {
                    setError('duration', {
                        type: 'value',
                        message: 'Incorect duration'
                    });
                    hasError = true;
                }
                for (const user of data.users) {
                    wrongUser = user.value;
                    const userErrors = Validator.getErrors(user.value, ['safeDefault', 'username']);
                    if (userErrors) {
                        setError('users', {
                            type: 'value',
                            message: `Incorect user: ${wrongUser}`
                        });
                        hasError = true;
                        break;
                    }
                }

                if (hasError) return;
                startInteraction(data);
            },
            [startInteraction, setError, t]
        );

        const handleSaveDraftClick = useCallback(async () => {
            try {
                const data = getValues();
                const existingAttachments = attachments.filter(
                    (x) => 'draftId' in x
                ) as types.DraftAttachmentEx[];
                const newAttachments = attachments.filter(
                    (x) => !('draftId' in x)
                ) as types.PreparedAttachment[];
                const meetingData = DraftUtils.getThreadCreateModel(
                    'chat',
                    getMeetingFromData(data)
                );
                const message = DraftUtils.getThreadMessageCreateModel(
                    'chat',
                    getMessageFromData(data)
                );
                if (props.meetingAdd.meetingDraft?.id) {
                    const updatedDraft = DraftUtils.getThreadDraftWithAttachments(
                        props.meetingAdd.meetingDraft.id,
                        meetingData,
                        message,
                        existingAttachments
                    );
                    await api.updateDraft(
                        updatedDraft,
                        newAttachments.map((x) => x.file)
                    );
                } else {
                    const newDraft = DraftUtils.getThreadDraftPropsForCreating(
                        meetingData,
                        message
                    );
                    const createdDraft = await api.createDraft(newDraft);
                    if (newAttachments.length > 0 && createdDraft) {
                        const updatedDraft = DraftUtils.getThreadDraftWithAttachments(
                            createdDraft.id,
                            meetingData,
                            message,
                            existingAttachments
                        );
                        await api.updateDraft(
                            updatedDraft,
                            newAttachments.map((x) => x.file)
                        );
                    }
                }
                handleClose();
            } finally {
            }
        }, [getValues, props.meetingAdd.meetingDraft?.id, handleClose, attachments]);

        const handleConfirmModalSaveDraftClick = useCallback(() => {
            confirmModalDeferred?.resolve(false);
            setConfirmModalDeferred(undefined);
            handleSaveDraftClick();
        }, [handleSaveDraftClick, confirmModalDeferred, setConfirmModalDeferred]);

        const handleConfirmModalCancelClick = useCallback(() => {
            confirmModalDeferred?.resolve(false);
            setConfirmModalDeferred(undefined);
        }, [confirmModalDeferred, setConfirmModalDeferred]);

        const handleConfirmModalRejectChangesClick = useCallback(() => {
            confirmModalDeferred?.resolve(true);
            setConfirmModalDeferred(undefined);
        }, [confirmModalDeferred, setConfirmModalDeferred]);

        const contacts = useMemo(() => {
            return props.meetingAdd.users
                .filter((user) => user.type === 'contact')
                .map((user) => (user as types.ContactOpt).contact);
        }, [props.meetingAdd.users]);

        const theme = useMantineTheme();
        const isLarge = useMediaQuery(`(min-width:${theme.breakpoints.sm})`);

        return (
            <ModalForm
                isProcesing={isProcessing}
                loadingMessage="Adding new meeting"
                onSubmit={handleFormSubmit}
                onClose={handleClose}
                form={form}
                buttons={<></>}>
                <ModalForm.UserSelectField
                    placeholder="Contact or mail@example.com"
                    required={false}
                    span={2}
                    label={t('entityProps.with')}
                    name="users"
                    contacts={contacts}
                />
                <ModalForm.TextField
                    placeholder="Meeting title"
                    required
                    span={2}
                    name="title"
                    label={t('entityProps.title')}
                    registerOptions={{
                        maxLength: {
                            message: 'Title cannot be longer than 100 characters',
                            value: 100
                        }
                    }}
                />
                <ModalForm.Field span={isLarge ? 1 : 2}>
                    <Controller
                        control={form.control}
                        name="startDate"
                        render={({ field, fieldState }) => {
                            return (
                                <DateTimePicker
                                    popoverProps={{
                                        withinPortal: true,
                                        middlewares: { flip: true, shift: true }
                                    }}
                                    error={fieldState.error?.message ?? fieldState.error?.type}
                                    locale={''}
                                    minDate={new Date()}
                                    value={new Date(field.value)}
                                    onChange={(selectedDate) =>
                                        field.onChange(selectedDate?.getTime())
                                    }
                                    label="Pick date and time"
                                    placeholder="Pick date and time"
                                />
                            );
                        }}
                    />
                </ModalForm.Field>
                <ModalForm.Field span={isLarge ? 1 : 2}>
                    <Controller
                        control={form.control}
                        name="duration"
                        render={({ field, fieldState }) => {
                            return (
                                <Select
                                    error={fieldState.error?.message ?? fieldState.error?.type}
                                    data={meetingDurations}
                                    value={field.value.toString()}
                                    onChange={(selectedDuration) =>
                                        field.onChange(parseInt(selectedDuration || ''))
                                    }
                                    label="Select duration"
                                    placeholder="Pick date and time"
                                />
                            );
                        }}
                    />
                </ModalForm.Field>
                <ModalForm.MessageFormField
                    grow
                    name="messageText"
                    label={t('entityProps.message')}
                    defaultValue={draft?.message?.text || ''}
                    registerOptions={{
                        maxLength: {
                            message: 'First message cannot be longer than 1000 characters',
                            value: 1000
                        }
                    }}>
                    <Button ml="auto" mt="md" type="submit">
                        {t('action.save')}
                    </Button>
                </ModalForm.MessageFormField>
                <>
                    <ConfirmModal
                        opened={Boolean(confirmModalDeferred)}
                        onClose={handleConfirmModalCancelClick}
                        withCloseButton={false}
                        centered
                        title={t('warningMessage.unsavedChangesWhatToDo')}>
                        <>
                            <Button type="button" onClick={handleConfirmModalSaveDraftClick}>
                                {t('action.save')}
                            </Button>
                            <Button type="button" onClick={handleConfirmModalRejectChangesClick}>
                                {t('action.rejectChanges')}
                            </Button>
                            <Button type="button" onClick={handleConfirmModalCancelClick}>
                                {t('action.cancel')}
                            </Button>
                        </>
                    </ConfirmModal>
                </>
            </ModalForm>
        );
    }
);

export default MeetingAddPage;
