import * as privmx from 'privfs-client';
import { VideoApi } from './VideoApi';
import * as PmxApi from 'privmx-server-api';
import * as PrivmxVideoConferences from 'privmx-video-conferences';
import * as types from '../../types/Types';
import { ThreadService } from './ThreadService';
import { setAvailableRooms, VideoRoomInfo } from '../../store/VideoSlice';
import { store } from '../../store';

export class VideoService {
    public videoApi: VideoApi;

    constructor(private gateway: privmx.gateway.RpcGateway, private threadService: ThreadService) {
        this.videoApi = new VideoApi(this.gateway);
    }

    joinToVideoRoom(meetingId: types.MeetingId) {
        return this.videoApi.joinToVideoRoom({
            resourceId: { type: 'thread', id: meetingId }
        });
    }

    switchVideoRoomState(
        meetingId: types.MeetingId,
        token: string,
        roomPassword: string,
        roomUrl: string
    ) {
        return this.videoApi.switchVideoRoomState({
            resourceId: { type: 'thread', id: meetingId },
            token: token as PmxApi.api.video.VideoRoomCreateToken,
            roomPassword: roomPassword as PmxApi.api.video.RoomPassword,
            roomUrl: roomUrl as PmxApi.api.core.Url
        });
    }

    cancelVideoRoomCreation(meetingId: types.MeetingId, token: string) {
        return this.videoApi.cancelVideoRoomCreation({
            resourceId: { type: 'thread', id: meetingId },
            token: token as PmxApi.api.video.VideoRoomCreateToken
        });
    }

    commitVideoRoomAccess(meetingId: types.MeetingId, videoRoomId: string) {
        return this.videoApi.commitVideoRoomAccess({
            resourceId: { type: 'thread', id: meetingId },
            videoRoomId: videoRoomId as PmxApi.api.video.VideoRoomId
        });
    }

    disconnectFromVideoRoom(meetingId: types.MeetingId, videoRoomId: string) {
        return this.videoApi.disconnectFromVideoRoom({
            resourceId: { type: 'thread', id: meetingId },
            videoRoomId: videoRoomId as PmxApi.api.video.VideoRoomId
        });
    }

    getVideoRoomsState() {
        return this.videoApi.getVideoRoomsState();
    }

    async updateVideoRoomsState() {
        const state = await this.getVideoRoomsState();
        store.dispatch(setAvailableRooms(state as VideoRoomInfo[]));
    }

    async encryptWithThreadKey(
        data: PrivmxVideoConferences.core.RoomSecretData,
        thread: PmxApi.api.thread.Thread
    ): Promise<string> {
        const key = await this.threadService.getVideoEncryptionKey(thread);
        return this.encrypt(data, key);
    }

    async decryptWithThreadKey(
        roomSecret: string,
        thread: PmxApi.api.thread.Thread
    ): Promise<PrivmxVideoConferences.core.RoomSecretData> {
        const key = await this.threadService.getVideoEncryptionKey(thread);
        return this.decrypt(roomSecret, key);
    }

    private async encrypt(
        data: PrivmxVideoConferences.core.RoomSecretData,
        key: Buffer
    ): Promise<string> {
        const raw = Buffer.from(JSON.stringify(data), 'utf8');
        const cipher = await privmx.crypto.service.privmxEncrypt(
            privmx.crypto.service.privmxOptAesWithSignature(),
            raw,
            key
        );
        return cipher.toString('base64');
    }

    private async decrypt(
        roomSecret: string,
        key: Buffer
    ): Promise<PrivmxVideoConferences.core.RoomSecretData> {
        const raw = Buffer.from(roomSecret, 'base64');
        const data = await privmx.crypto.service.privmxDecrypt(
            privmx.crypto.service.privmxOptSignedCipher(),
            raw,
            key
        );
        return JSON.parse(data.toString('utf8'));
    }
}
