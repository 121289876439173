import * as PmxApi from 'privmx-server-api';
import { BaseApi } from './BaseApi';

export class CompanyApi extends BaseApi implements PmxApi.api.company.ICompanyApi {
    getCompany(
        model: PmxApi.api.company.GetCompanyModel
    ): Promise<{ company: PmxApi.api.company.Company }> {
        return this.request('getCompany', model);
    }

    getCompanies(): Promise<{ companies: PmxApi.api.company.Company[] }> {
        return this.request('getCompanies', {});
    }

    createCompany(
        model: PmxApi.api.company.CreateCompanyModel
    ): Promise<{ company: PmxApi.api.company.Company }> {
        return this.request('createCompany', model);
    }

    updateCompany(
        model: PmxApi.api.company.UpdateCompanyModel
    ): Promise<{ company: PmxApi.api.company.Company }> {
        return this.request('updateCompany', model);
    }

    deleteCompany(model: PmxApi.api.company.DeleteCompanyModel): Promise<PmxApi.api.core.OK> {
        return this.request('deleteCompany', model);
    }

    setCompanyTags(
        model: PmxApi.api.company.SetCompanyTagsModel
    ): Promise<{ company: PmxApi.api.company.Company }> {
        return this.request('setCompanyTags', model);
    }
}
