import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types';
import { formApi } from '../../../api/Api';
import { FormPublicView, SubmitedAnswer } from '../../../types/Types';
import AnswerInputFactory from './AnswerInputFactory';
import { Box, Button, FileInput, Flex, Input, Stack, Title, rem, Text } from '@mantine/core';
import FormBox from '../../../mantineAtoms/FormBox';
import { usePreventClose } from '../../../hooks/usePreventClose';
import { useTranslation } from 'react-i18next';
import { IconUpload } from '@tabler/icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { useUserInteraction } from '../../../hooks/useUserInteraction';
import { FileChooser } from '../../../utils/FileChooser';
import { useAppSelector } from '../../../store';
import { selectFileConfig } from '../../../store/UserPreferenceSlice';
import { notifications } from '@mantine/notifications';
import { LoadingMessageOverlay } from '../../../mantineAtoms/LoadingMessageOverlay';

export type FormValues = {
    id: string;
    answers: string[] | string;
};

const errorText = {
    required: ' This field is required'
};

export default function FormAnswering({ model: formModel }: { model: FormPublicView }) {
    const { t } = useTranslation();
    const [isSubmitted, setSubmitted] = useState<boolean>(false);
    const { register, handleSubmit, formState, control } = useForm<FormValues[]>({
        defaultValues: []
    });
    usePreventClose(formState.isDirty);
    const [attachments, setAttachments] = useState<File[]>([]);

    const { startInteraction, isProcessing } = useUserInteraction({
        successTitle: 'Form submited',
        successMessage: 'Thank you.',
        errorTitle: 'Error while submitting form',
        errorMessage: 'Reload the page or try again later.',
        async action(fullData: SubmitedAnswer[]) {
            let autoResponseEmail: string | undefined = undefined;
            if (formModel.autoResponseData.enabled) {
                const field = fullData.find(
                    (x) => x.id === formModel.autoResponseData.emailFieldId
                );
                if (
                    field &&
                    field.type === 'short' &&
                    field.answer[0]?.type === 'short' &&
                    field.answer[0].input.trim().length > 0
                ) {
                    autoResponseEmail = field.answer[0].input.trim();
                }
            }

            await formApi.submitFormAnswer(
                formModel.id,
                formModel.publicationId,
                formModel.pubKey,
                fullData,
                attachments,
                autoResponseEmail,
                undefined
            );
            setSubmitted(true);
        }
    });

    const fileUploadConfig = useAppSelector(selectFileConfig);
    const onSubmit: SubmitHandler<FormValues[]> = async (data) => {
        const fullData: SubmitedAnswer[] = [];
        let allDataOk = true;
        formModel.questions.forEach((modelQuestion, index) => {
            if (modelQuestion.type === 'block') {
                return;
            }
            const answerQuestion = data[index];
            const newSubmitedAnswer: SubmitedAnswer = {
                id: modelQuestion.id,
                type: modelQuestion.type,
                answer: []
            };
            if (Array.isArray(modelQuestion.answer)) {
                // multiselect inputs

                if (!Array.isArray(answerQuestion.answers)) return;
                if (modelQuestion.type === 'file') {
                    newSubmitedAnswer.answer = answerQuestion.answers.map((name) => ({
                        id: modelQuestion.answer[0].id,
                        type: 'file',
                        input: name
                    }));
                } else {
                    for (const answerId of answerQuestion.answers) {
                        const answerModel = modelQuestion.answer.find((mq) => mq.id === answerId);
                        if (!answerModel) continue;
                        newSubmitedAnswer.answer.push(answerModel);
                    }
                }
            } else {
                if (answerQuestion.answers) {
                    newSubmitedAnswer.answer.push({
                        ...modelQuestion,
                        input: answerQuestion.answers as string
                    });
                }
            }
            fullData.push(newSubmitedAnswer);
        });
        try {
            FileChooser.checkFilesConfig(attachments, fileUploadConfig);
        } catch (error: any) {
            if ('message' in error) {
                notifications.show({
                    title: 'Error while uploading answer files',
                    message: error.message,
                    color: 'red'
                });
            }
            allDataOk = false;
        }

        if (!allDataOk) {
            return;
        }

        startInteraction(fullData);
    };
    const mobileScreen = useMediaQuery('(max-width: 40em)');
    return (
        <Stack
            py="xl"
            sx={(theme) => ({
                height: '100svh',
                overflowY: 'scroll',
                backgroundColor:
                    theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
            })}>
            <LoadingMessageOverlay visible={isProcessing} message="Uploading form answer" />
            <Title align="center" py="xl">
                {formModel.name}
            </Title>
            {!isSubmitted && (
                <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                    <Flex w="100%" direction="column" align="center" justify="start">
                        {formModel.questions.map((question, i) => (
                            <FormBox key={question.id} w={mobileScreen ? '100%' : '40em'}>
                                <Box mb="md">
                                    {question.type !== 'block' && (
                                        <Input.Label size="lg" required={question.required}>
                                            {question.title}
                                        </Input.Label>
                                    )}
                                    <Input.Error>
                                        {(formState.errors[i]?.answers as any)?.[0]?.type ===
                                        'required'
                                            ? errorText.required
                                            : (formState.errors[i]?.answers as any)?.[0]?.message}
                                    </Input.Error>
                                    <Input.Error>
                                        {formState.errors[i]?.answers?.type === 'required'
                                            ? errorText.required
                                            : (formState.errors[i]?.answers as any)?.[0]?.message}
                                    </Input.Error>
                                </Box>
                                {question.type === 'file' ? (
                                    <Controller
                                        name={`${i}.answers`}
                                        control={control}
                                        render={({ field }) => (
                                            <FileInput
                                                multiple
                                                icon={<IconUpload size={rem(14)} />}
                                                onChange={(files) => {
                                                    setAttachments(files);
                                                    field.onChange(files.map((x) => x.name));
                                                }}
                                            />
                                        )}
                                    />
                                ) : (
                                    <AnswerInputFactory
                                        errorMessage={formState.errors[i]?.answers?.message}
                                        question={question}
                                        questionIndex={i}
                                        register={register}
                                    />
                                )}
                            </FormBox>
                        ))}
                    </Flex>
                    <Button mx="auto" display="block" type="submit">
                        {t('action.send')}
                    </Button>
                </Box>
            )}
            {isSubmitted && (
                <Flex w="100%" direction="column" align="center" justify="start">
                    <Text align="center" size="xl" fw="bold" py="lg">
                        {t('screen.publicFormAnswer.labels.thanksForAnswering')}
                    </Text>
                </Flex>
            )}
        </Stack>
    );
}
