import * as PmxApi from 'privmx-server-api';
import { BaseApi } from './BaseApi';

export class FeedApi extends BaseApi implements PmxApi.api.feed.IFeedApi {
    getFeedList(
        model: PmxApi.api.feed.GetFeedListModel
    ): Promise<{ list: PmxApi.api.feed.Feed[] }> {
        return this.request('getFeedList', model);
    }
    getFeedListX(
        model: PmxApi.api.feed.GetFeedListModel
    ): Promise<{ list: PmxApi.api.feed.FeedX[] }> {
        return this.request('getFeedListX', model);
    }
    setFeedReadFlag(model: PmxApi.api.feed.SetFeedReadFlagModel): Promise<PmxApi.api.core.OK> {
        return this.request('setFeedReadFlag', model);
    }
}
