export class KeyValueStorage {
    private cache = new Map<string, string>();

    constructor(private prefix: string) {}

    get<T extends string = string>(key: string) {
        const res = this.cache.get(key);
        if (res !== undefined) {
            return res as T;
        }
        const r = localStorage.getItem(this.prefix + key);
        if (r !== null) {
            this.cache.set(key, r);
            return r as T;
        }
        return null;
    }

    set(key: string, value: string) {
        this.cache.set(key, value);
        localStorage.setItem(this.prefix + key, value);
    }

    delete(key: string) {
        this.cache.delete(key);
        localStorage.removeItem(this.prefix + key);
    }
}
