import * as privmx from 'privfs-client';
import { InquiryApi } from './InquiryApi';
import * as PmxApi from 'privmx-server-api';
import { InquiryPublicDataEncryptor } from './InquiryPublicDataEncryptor';
import { InquirySubmitDataEncryptor } from './InquirySubmitDataEncryptor';
import { InquirySubmitAttachment, SubmitData } from './InquiryService';
import { InquiryAttachmentMetaEncryptor } from './InquiryAttachmentMetaEncryptor';
import { AttachmentUtils, ExistingAttachmentsProvider, PreparedFile } from './AttachmentUtils';
import { RequestApi } from './RequestApi';
import * as types from '../../types/Types';
import { InquirySubmitResponseDataEncryptorWithPubKey } from './InquirySubmitResponseDataEncryptorWithPubKey';
import { DataEncryptor } from './DataEncryptor';
import { EncKey } from './KeyProvider';
import { DataCacheInterface } from '../DataCacheInterface';
import { ModalsInterface } from '../ModalsInterface';

export class AnonInquiryService {
    public inquiryApi: InquiryApi;
    private requestApi: RequestApi;
    private submitDataEncryptor = new InquirySubmitDataEncryptor();
    private inquiryPublicDataEncryptor = new InquiryPublicDataEncryptor();
    private attachmentMetaEncryptor = new InquiryAttachmentMetaEncryptor();
    private submitResponseDataEncryptorWithPubKey =
        new InquirySubmitResponseDataEncryptorWithPubKey();
    private submitResponseDataEncryptorWithEncKey = new DataEncryptor<
        types.InquirySubmitResponseData,
        PmxApi.api.inquiry.InquirySubmitResponseData,
        EncKey
    >();
    private attachmentUtils: AttachmentUtils<
        InquirySubmitAttachment,
        privmx.crypto.ecc.PublicKey | PmxApi.api.core.EccPubKey
    >;

    constructor(
        private gateway: privmx.gateway.RpcGateway,
        existingAttachmentsProvider: ExistingAttachmentsProvider,
        private cacheService: DataCacheInterface,
        private modalsService: ModalsInterface
    ) {
        this.inquiryApi = new InquiryApi(this.gateway);
        this.requestApi = new RequestApi(this.gateway);
        this.attachmentUtils = new AttachmentUtils(
            this.requestApi,
            this.attachmentMetaEncryptor,
            (preparedFile, hasThumb) =>
                this.preparedFileToAttachmentConverter(preparedFile, hasThumb),
            existingAttachmentsProvider,
            this.cacheService,
            this.modalsService
        );
    }

    async getMainInquiryId() {
        const { id } = await this.inquiryApi.getMainInquiry();
        return id;
    }

    async getInquiryPublicView(id: PmxApi.api.inquiry.InquiryId) {
        const { inquiry } = await this.inquiryApi.getInquiryPublicView({ id: id });
        const data = this.inquiryPublicDataEncryptor.decrypt(inquiry.data);
        return { raw: inquiry, data: data };
    }

    async createSubmit(
        id: PmxApi.api.inquiry.InquiryId,
        publicationId: PmxApi.api.inquiry.InquiryPublicationId,
        pubKey: PmxApi.api.core.EccPubKey,
        data: SubmitData,
        files: File[],
        autoResponseEmail: string | undefined,
        captcha: PmxApi.api.captcha.CaptchaObj | undefined
    ) {
        const pubKeyBuff = Buffer.from(pubKey);
        const attInfo = await this.attachmentUtils.prepareAttachments(
            pubKey,
            pubKeyBuff,
            null,
            files
        );

        await this.inquiryApi.submitInquiry({
            id: id,
            publicationId: publicationId,
            data: await this.submitDataEncryptor.encrypt(pubKey, data),
            attachments: attInfo.request,
            autoResponseEmail: autoResponseEmail,
            captcha: captcha
        });
    }

    private preparedFileToAttachmentConverter(
        preparedFile: PreparedFile,
        hasThumb: boolean
    ): InquirySubmitAttachment {
        const res: InquirySubmitAttachment = {
            hmac: preparedFile.hmac.toString('base64'),
            name: preparedFile.file.name,
            size: preparedFile.file.size,
            mimetype: preparedFile.file.type as types.Mimetype,
            hasThumb: hasThumb
        };
        return res;
    }

    async getEncryptedPublicInquirySubmitResponse(
        inquirySubmitResponseId: types.InquirySubmitResponseId
    ) {
        const { publicInquirySubmitResponse: publicInquirySubmitResponseRaw } =
            await this.inquiryApi.getPublicInquirySubmitResponse({
                id: inquirySubmitResponseId
            });
        return publicInquirySubmitResponseRaw;
    }

    async getPublicInquirySubmitResponse(
        inquirySubmitResponseId: types.InquirySubmitResponseId,
        password: string | undefined,
        userKeyHalf: string | undefined,
        receiverPrivKey: privmx.crypto.ecc.PrivateKey | undefined
    ) {
        const publicInquirySubmitResponseRaw = await this.getEncryptedPublicInquirySubmitResponse(
            inquirySubmitResponseId
        );
        const publicInquirySubmitResponse = await this.decryptPublicInquirySubmitResponse(
            publicInquirySubmitResponseRaw,
            password,
            userKeyHalf,
            receiverPrivKey
        );
        return publicInquirySubmitResponse;
    }

    async decryptPublicInquirySubmitResponse(
        publicInquirySubmitResponseRaw: PmxApi.api.inquiry.PublicInquirySubmitResponse,
        password: string | undefined,
        userKeyHalf: string | undefined,
        receiverPrivKey: privmx.crypto.ecc.PrivateKey | undefined
    ) {
        let data: types.InquirySubmitResponseData | PmxApi.api.core.Base64;
        if (publicInquirySubmitResponseRaw.protection === 'none') {
            throw new Error('Response has been sent via email');
        } else if (publicInquirySubmitResponseRaw.protection === 'password') {
            if (!password) {
                throw new Error('Missing password');
            }
            const keyStr = publicInquirySubmitResponseRaw.keyHalf + userKeyHalf;
            const key = this.inquirySubmitResponseDataKeyFromString(keyStr);
            const finalKey = await this.mixInquirySubmitResponseDataKeyWithPassword(key, password);
            data = await this.submitResponseDataEncryptorWithEncKey.decrypt(
                publicInquirySubmitResponseRaw.data,
                { id: 'finalKey' as PmxApi.api.core.KeyId, key: finalKey }
            );
        } else if (publicInquirySubmitResponseRaw.protection === 'pubkey') {
            if (!receiverPrivKey) {
                throw new Error('Missing receiverPrivKey');
            }
            data = await this.submitResponseDataEncryptorWithPubKey.decrypt(
                receiverPrivKey,
                publicInquirySubmitResponseRaw.data
            );
        } else {
            throw new Error('Invalid protection');
        }
        const inquirySubmitResponse: types.PublicInquirySubmitResponse = {
            id: publicInquirySubmitResponseRaw.id,
            protection: publicInquirySubmitResponseRaw.protection,
            created: publicInquirySubmitResponseRaw.created,
            keyHalf: publicInquirySubmitResponseRaw.keyHalf,
            data: data
        };
        return inquirySubmitResponse;
    }

    private inquirySubmitResponseDataKeyFromString(keyStr: string) {
        return privmx.Buffer.Buffer.from(keyStr, 'base64');
    }

    private async mixInquirySubmitResponseDataKeyWithPassword(
        key: privmx.Buffer.Buffer,
        password: string
    ) {
        return privmx.crypto.service.pbkdf2(
            Buffer.from(password, 'utf8'),
            key,
            100000,
            32,
            'sha256'
        );
    }
}
