import {
    createStyles,
    rem,
    Flex,
    HoverCard,
    Box,
    ActionIcon,
    ScrollArea,
    Group,
    Text
} from '@mantine/core';
import { useCallback } from 'react';
import { OverflowList } from 'react-overflow-list';
import { useNavigate } from 'react-router-dom';
import { useFilterContext } from '../../../../mantineAtoms/FilterSelect';
import { AttachmentEx, ChatId } from '../../../../types/Types';
import { FileBadge } from '../../../../atoms/FileBadge';

const useFilePreviewStyles = createStyles((theme) => {
    return {
        wrapper: {
            flexGrow: 1,
            width: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            flexWrap: 'nowrap',
            '.attachments-group': {
                flexGrow: 1,
                gap: 4,
                alignItems: 'center',
                overflow: 'hidden'
            }
        },
        badge: {
            textOverflow: 'unset',
            overflow: 'visible'
        },
        overflowListElement: {
            padding: `${rem(4)} ${rem(8)}`,
            '&:hover': {
                backgroundColor:
                    theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
            }
        }
    };
});

export function FilePreview({
    attachments,
    chatId
}: {
    attachments: AttachmentEx[];
    chatId: ChatId;
}) {
    const navigate = useNavigate();
    const { contextState, filters } = useFilterContext();

    const handleNavigateToAttachments = useCallback(() => {
        let filterQuerries;

        const fileFilterState = filters
            .filter((x) => {
                if (x.type === 'file') {
                    filterQuerries ||= x.value;
                    return false;
                }
                if (x.type !== 'tag') {
                    return true;
                }
                return false;
            })
            .map((x) => `${x.type}:${x.value}`);

        navigate(`/chat/${chatId}`, {
            state: {
                tab: 'attachments',
                filters: fileFilterState,
                query: filterQuerries,
                originState: contextState
            }
        });
    }, [navigate, chatId, filters, contextState]);

    const { classes } = useFilePreviewStyles();

    return (
        <Flex w="100%" bg="transparent">
            <Flex gap={'xs'} className={classes.wrapper}>
                <OverflowList
                    className="attachments-group"
                    collapseFrom="end"
                    minVisibleItems={0}
                    items={attachments}
                    itemRenderer={(item, index) => (
                        <FileBadge
                            mimetype={item.contentType}
                            attachmentId={item.id}
                            name={item.name}
                            className={classes.badge}
                            key={index}
                            onClick={handleNavigateToAttachments}
                        />
                    )}
                    overflowRenderer={(items) => (
                        <HoverCard keepMounted position="top-end">
                            <HoverCard.Target>
                                <Box>
                                    <ActionIcon
                                        ml="auto"
                                        radius={999}
                                        size={20}
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            handleNavigateToAttachments();
                                        }}>
                                        <Text h={18} size={11}>
                                            +{items.length}
                                        </Text>
                                    </ActionIcon>
                                </Box>
                            </HoverCard.Target>
                            <HoverCard.Dropdown p={4}>
                                <ScrollArea.Autosize mah={200}>
                                    {items.map((x) => (
                                        <Group
                                            key={x.id}
                                            spacing={2}
                                            className={classes.overflowListElement}
                                            position="apart"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleNavigateToAttachments();
                                            }}>
                                            <Text py={4} truncate maw={300} size={'sm'}>
                                                {x.name}
                                            </Text>
                                        </Group>
                                    ))}
                                </ScrollArea.Autosize>
                            </HoverCard.Dropdown>
                        </HoverCard>
                    )}
                />
            </Flex>
        </Flex>
    );
}
