export class FeatureDetection {
    private static _supportsVideoConferences: boolean | undefined = undefined;

    static supportsVideoConferences(): boolean {
        if (this._supportsVideoConferences === undefined) {
            this._supportsVideoConferences = this.isChrome() && this.supportsE2eeVideo();
        }
        return this._supportsVideoConferences;
    }

    // temporarly disable video for browsers other than Chrome/Chromium
    private static isChrome() {
        let isChromium = (window as any).chrome;
        let winNav = window.navigator;
        let vendorName = winNav.vendor;
        let isOpera = typeof (window as any).opr !== 'undefined';
        let isIEedge = winNav.userAgent.includes('Edg');
        let isIOSChrome = winNav.userAgent.match('CriOS');

        if (isIOSChrome) {
            return true;
        } else if (
            isChromium !== null &&
            typeof isChromium !== 'undefined' &&
            vendorName === 'Google Inc.' &&
            isOpera === false &&
            isIEedge === false
        ) {
            return true;
        } else {
            return false;
        }
    }

    private static supportsE2eeVideo(): boolean {
        return this.supportsEncodedTransform() || this.supportsInsertableStreams();
    }

    private static supportsInsertableStreams(): boolean {
        const anyWindow: any = window;

        if (!anyWindow.RTCRtpSender) {
            return false;
        }

        const RTCRtpSenderPrototype = anyWindow.RTCRtpSender.prototype;
        if (
            !RTCRtpSenderPrototype.createEncodedStreams &&
            !RTCRtpSenderPrototype.createEncodedVideoStreams
        ) {
            return false;
        }

        const readableStream = new ReadableStream();
        try {
            window.postMessage(readableStream, '*', [readableStream as unknown as Transferable]);
            return true;
        } catch {
            return false;
        }
    }

    private static supportsEncodedTransform(): boolean {
        return !!(window as any).RTCRtpScriptTransform;
    }
}
