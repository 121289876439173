import { useCallback, useMemo } from 'react';
import useLoader from '../../hooks/useLoader';
import { Box, Button, Group, rem, ScrollArea, Stack, Text, TextInput } from '@mantine/core';
import UserAvatar from '../../atoms/UserAvatar';
import { DATE_TEXT_WIDTH, LoadingUsers, USER_TEXT_WIDTH } from './ContactsList';
import { displayRelativeTime } from '../../utils/RelativeTimeFormat';
import { IconPlus, IconSearch } from '@tabler/icons-react';
import { useInputState } from '@mantine/hooks';
import { useAdminPanelStyles } from './SettingsMemberTab';
import UserDisplayedName from '../../atoms/UserDisplayedName';
import { NoItemsInfo } from '../../atoms/NoItemsInfo';
import { EmptyState } from '../../mantineAtoms/EmptyState/EmptyState';
import { useRouteContext } from './useSettingsScreenContext';

export function StaffList() {
    const { classes } = useAdminPanelStyles();
    const { state, revalidate } = useLoader(useCallback((x) => x.loadAccounts({}), []));
    const [filterValue, setFilterValue] = useInputState('');
    const setRouteContext = useRouteContext()[1];

    const filteredStaff = useMemo(() => {
        if (state.type !== 'done') return [];
        return state.data.accounts[0].filter(
            (x) =>
                x.email?.toLowerCase().includes(filterValue.toLowerCase()) ||
                x.username.toLowerCase().includes(filterValue.toLowerCase())
        );
    }, [filterValue, state]);

    const handleNewStaffMember = useCallback(() => {
        setRouteContext({ route: 'staff/new', revalidate });
    }, [setRouteContext, revalidate]);

    const staffUsers =
        state.type === 'done' ? (
            filteredStaff.map((x) => (
                <Group
                    key={x.id}
                    className={classes.userElement}
                    onClick={() => setRouteContext({ route: 'staff/detail', id: x.id })}>
                    <UserAvatar showTooltip={false} username={x.username} />
                    <Box className={classes.name}>
                        <UserDisplayedName user={x} />
                        <Text size={'sm'} color="dimmed" truncate maw={USER_TEXT_WIDTH}>
                            {x.email}
                        </Text>
                    </Box>
                    <Text size={'sm'} color="dimmed" className={classes.date}>
                        {displayRelativeTime(x.lastLoggedIn)}
                    </Text>
                    <Text size={'sm'} color="dimmed" className={classes.date}>
                        {displayRelativeTime(x.creationDate)}
                    </Text>
                </Group>
            ))
        ) : (
            <LoadingUsers />
        );

    if (state.type === 'done' && state.data.accounts[0].length === 0) {
        return (
            <EmptyState
                image="NO_USERS"
                title="No Staff members"
                subTitle="Create accounts for your colleagues."
                button={<Button onClick={() => handleNewStaffMember()}>New Member</Button>}
            />
        );
    }

    return (
        <Stack align="center" h={`calc(100% - ${rem(16)})`} className={classes.list} mt={'md'}>
            <Box w={'100%'}>
                <Group position={'apart'}>
                    <TextInput
                        onChange={setFilterValue}
                        value={filterValue}
                        w={'70%'}
                        size={'xs'}
                        placeholder={'Search...'}
                        icon={<IconSearch size={rem(12)} />}
                    />
                    <Button
                        variant={'light'}
                        size={'xs'}
                        leftIcon={<IconPlus size={rem(12)} />}
                        onClick={handleNewStaffMember}>
                        New Member
                    </Button>
                </Group>
            </Box>
            <Box h={'100%'} w={'100%'}>
                <Group spacing="sm" pb="md" px={8}>
                    <Text color="dimmed" w={USER_TEXT_WIDTH + 26 + 16} size="sm">
                        Name
                    </Text>
                    <Text color="dimmed" w={DATE_TEXT_WIDTH} size="sm">
                        Last Login
                    </Text>
                    <Text color="dimmed" w={DATE_TEXT_WIDTH} size="sm">
                        Added Date
                    </Text>
                </Group>
                <ScrollArea.Autosize sx={{ flexGrow: 1 }} h={`calc(100% - ${rem(80)})`}>
                    <Stack spacing={0}>
                        {staffUsers}
                        {filteredStaff.length === 0 && state.type === 'done' && (
                            <NoItemsInfo itemsType="results" />
                        )}
                    </Stack>
                </ScrollArea.Autosize>
            </Box>
        </Stack>
    );
}
