import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StateView from '../../atoms/State';
import LoadingThreadsList from '../../components/Loading/LoadingSection';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { SharedFile, SharedFileId } from '../../types/Types';
import SharedFilesScreenView from './SharedFilesScreenView';

export default function SharedFilesScreen() {
    const { state, revalidate, revalidateQuiet } = useDataSubscribion(
        useCallback((x) => x.subscribeForSharedFiles(), [])
    );

    const navigate = useNavigate();
    const handleSharedFileChoose = useCallback(
        (sharedFile: SharedFile) => navigate(`./${sharedFile.id}`),
        [navigate]
    );

    const { sharedFile } = useParams();

    return (
        <StateView state={state} retry={revalidate} loading={LoadingThreadsList}>
            {(sharedFiles) => {
                return (
                    <SharedFilesScreenView
                        sharedFiles={sharedFiles.sharedFiles}
                        activeSharedFileId={sharedFile as SharedFileId}
                        onSharedFileChoose={handleSharedFileChoose}
                        filter={''}
                        onRefresh={revalidateQuiet}
                    />
                );
            }}
        </StateView>
    );
}
