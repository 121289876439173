import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    AttachmentEx,
    Chat,
    ChatId,
    Message,
    MessageId,
    Username,
    ThreadMessageDraftWithAttachments
} from '../../types/Types';
import { FullMessagesView } from '../MessageView';
import PageDetailsHeader from '../../atoms/DetailPage/PageDetailsHeader';
import StateView from '../../atoms/State';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Group,
    Loader,
    MediaQuery,
    Menu,
    Modal,
    SegmentedControl,
    Skeleton,
    Stack,
    Sx,
    TextInput,
    Title,
    Tooltip,
    rem
} from '@mantine/core';
import FileAndMessageSummary from '../../mantineAtoms/FileAndMessageSummary';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { Link, useLocation, useOutlet, useParams, useSearchParams } from 'react-router-dom';
import { IconArrowLeft, IconSearch } from '@tabler/icons-react';
import { UsersList } from '../../screens/ChatScreen/components/UserList';
import Icon, { iconList, iconTiles } from '../Icon';
import { useDisclosure, useInputState } from '@mantine/hooks';
import ChatEditDialog from '../ChatEditPage/ChatEditDialog';
import { useTranslation } from 'react-i18next';
import {
    FilterContextProvider,
    useFilterContext,
    DropDownElementsMap,
    ExtendedFilterOption,
    FilterSelect,
    documentTypes
} from '../../mantineAtoms/FilterSelect';
import { selectFilesViewMode, setFilesViewMode } from '../../store/UserPreferenceSlice';
import { useEditingStore } from '../SendMessageView/editingStore';
import { TagList } from '../../mantineAtoms/TagList';

const pageSx: Sx = (theme) => ({
    height: '100%',
    flexGrow: 1,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
});

export function ChatPageLoader() {
    const { state } = useLocation();
    const [params] = useSearchParams();
    const filterParam = params.get('filter');

    return (
        <Box h="100svh">
            <Flex direction="column" sx={pageSx} justify="space-between" h="100%">
                <Stack justify="space-between" m="md" mb={0} spacing="xs">
                    <Group position="apart">
                        <Group align="center">
                            <Title order={2} size={20} weight={'bolder'}>
                                <Anchor
                                    state={{
                                        originState: state ? state?.originState : undefined
                                    }}
                                    component={Link}
                                    unstyled
                                    to={`/chat${filterParam ? `?filter=${filterParam}` : ''}`}>
                                    <ActionIcon size="md">
                                        <IconArrowLeft size={rem(16)} />
                                    </ActionIcon>
                                </Anchor>
                            </Title>
                            <Group align="center">
                                <Skeleton h={28} w={300}></Skeleton>
                                <MediaQuery styles={{ display: 'none' }} smallerThan={'sm'}>
                                    <Group spacing={-1}>
                                        {new Array(4).fill('').map((x, i) => (
                                            <Skeleton key={i} circle height={20}></Skeleton>
                                        ))}
                                    </Group>
                                </MediaQuery>
                                <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                                    <Group spacing={4}>
                                        {new Array(3).fill('').map((x, i) => (
                                            <Skeleton key={i} height={16} width={80}></Skeleton>
                                        ))}
                                    </Group>
                                </MediaQuery>
                            </Group>
                        </Group>
                    </Group>
                    <SegmentedControl
                        value="Messages"
                        w={150}
                        size={'xs'}
                        data={['Messages', 'Files']}
                    />
                    <Divider mt="sm" />
                </Stack>

                <Box sx={{ flexGrow: 1 }}>
                    <Stack h="100%" p="md">
                        <Center sx={{ flexGrow: 1 }}>
                            <Loader />
                        </Center>
                        <Box>
                            <Skeleton height={35} width="100%"></Skeleton>
                        </Box>
                    </Stack>
                </Box>
            </Flex>
        </Box>
    );
}

export function ChatPage(props: { id?: ChatId; height?: string }) {
    const { chatId: pathId } = useParams();
    const chatId = props.id || pathId || ('' as any);

    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForChatWithMessagesAndAttachments(chatId, true), [chatId])
    );

    return (
        <StateView
            state={state}
            retry={() => {
                revalidate(false);
            }}
            loading={ChatPageLoader}>
            {(data) => (
                <FilterContextProvider>
                    <Box h={props.height || '100svh'}>
                        <ChatPageView
                            chat={data.chat}
                            chatMessageDraft={data.chatMessageDraft}
                            onRefresh={revalidate}
                            refreshBtn={false}
                            goToBtn={true}
                            loadingMessages={!data}
                            messages={data.messages}
                            hasAccess={data.hasAccessToThreadContent}
                            attachments={data.attachments}
                        />
                    </Box>
                </FilterContextProvider>
            )}
        </StateView>
    );
}

type TabId = 'messages' | 'attachments' | 'users';
type ThreadAttachmentFilterOption = ExtendedFilterOption<'type'>;

export function ChatPageView(props: {
    chat: Chat;
    chatMessageDraft?: ThreadMessageDraftWithAttachments;
    onRefresh: () => void;
    onGoTo?: () => void;
    refreshBtn?: boolean;
    goToBtn: boolean;
    loadingMessages: boolean;
    messages: Message[];
    hasAccess: boolean;
    attachments: AttachmentEx[];
    highlightedMessageIds?: MessageId[];
    scrollToMessageId?: MessageId;
    onSendEmailNotification?: () => void;
}) {
    const { t } = useTranslation();
    const [activeTabId, setActiveTabId] = useState<TabId>('messages');
    const currentUser = useAppSelector(selectCurrentUser);

    const Outlet = useOutlet();
    const [editingChat, { open: openEdit, close: closeEdit }] = useDisclosure();

    const { state } = useLocation();

    useEffect(() => {
        if (state?.tab && state?.tab === 'attachments') {
            setActiveTabId('attachments');
        }
    }, [state?.tab]);

    const [query, setQuery] = useInputState('');
    const filterMap: DropDownElementsMap<ThreadAttachmentFilterOption['type']> = {
        author: (dropdownProps) => (
            <FilterSelect.AuthorDropdown authors={props.chat.users || []} {...dropdownProps} />
        ),
        date: FilterSelect.DateDropdown,
        type: ({ onSelect }) => (
            <Menu.Dropdown>
                {documentTypes.map((x) => (
                    <Menu.Item
                        key={x.type}
                        onClick={() => onSelect(`type:${x.type}`)}
                        icon={<x.icon size={rem(12)} />}>
                        {x.type}
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        )
    };

    const filterOptions: ThreadAttachmentFilterOption[] = [
        { label: 'Type', type: 'type' },
        { label: 'Author', type: 'author' },
        { label: 'Date', type: 'date' }
    ];

    const filteredAttachments = useMemo(() => {
        return query ? props.attachments.filter((x) => x.name.includes(query)) : props.attachments;
    }, [props.attachments, query]);

    const { contextState, dispatch, hasFilters } = useFilterContext();
    const appDispatch = useAppDispatch();

    useEffect(() => {
        if (state?.filters) {
            dispatch({ type: 'sync', filters: state?.filters });
        }
    }, [dispatch, state?.filters]);

    useEffect(() => {
        if (state?.query) {
            setQuery(state?.query);
        }
    }, [state?.query, setQuery]);

    const initialViewMode = useAppSelector(selectFilesViewMode);
    const [viewMode, setViewMode] = useState<'list' | 'tiles'>(initialViewMode);
    const toggleViewMode = useCallback(() => {
        setViewMode((prev) => {
            const newViewMode = prev === 'tiles' ? 'list' : 'tiles';
            appDispatch(setFilesViewMode(newViewMode));
            return newViewMode;
        });
    }, [appDispatch]);

    const canManageChat =
        currentUser.role === 'staff' &&
        currentUser.username &&
        props.chat.managers.includes(currentUser.username);

    const [params] = useSearchParams();
    const filterParam = params.get('filter');
    const stopEditing = useEditingStore((state) => state.stopEditing);
    useEffect(() => {
        return () => {
            stopEditing();
        };
    }, [stopEditing]);

    return (
        <Flex direction="column" sx={pageSx} justify="space-between">
            <Stack justify="space-between" m="md" mb={0} spacing={4}>
                <Group position="apart" mb={4}>
                    <Group align="center" spacing={'xs'}>
                        <Anchor
                            state={{
                                originState: state ? state?.originState : undefined
                            }}
                            component={Link}
                            unstyled
                            to={`/chat${filterParam ? `?filter=${filterParam}` : ''}`}>
                            <ActionIcon size="md">
                                <IconArrowLeft size={rem(16)} />
                            </ActionIcon>
                        </Anchor>
                        <Group align="center">
                            <Title order={2} size={20} weight={'bolder'}>
                                {props.chat.title}
                            </Title>
                            <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                                <UsersList
                                    usernames={props.chat.users.map((x) => ({
                                        type: 'user',
                                        username: x
                                    }))}
                                />
                            </MediaQuery>
                            {props.chat.tags.length !== 0 && (
                                <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                                    <TagList tags={props.chat.tags} />
                                </MediaQuery>
                            )}
                        </Group>
                    </Group>
                    <Group>
                        {canManageChat && (
                            <Button variant="light" onClick={openEdit}>
                                {t('action.edit')}
                            </Button>
                        )}
                        <Modal
                            opened={editingChat}
                            size="md"
                            onClose={closeEdit}
                            title={t('modal.editChat.title')}
                            overlayProps={{ opacity: 0.2, blur: 3 }}>
                            <ChatEditDialog id={props.chat.id} onClose={closeEdit} />
                        </Modal>
                    </Group>
                </Group>
                {props.hasAccess && (
                    <Group>
                        <SegmentedControl
                            value={activeTabId}
                            onChange={(value) => setActiveTabId(value as TabId)}
                            w={150}
                            size={'xs'}
                            data={[
                                { label: 'Messages', value: 'messages' },
                                { label: 'Files', value: 'attachments' }
                            ]}
                        />
                        {activeTabId === 'attachments' && (
                            <>
                                <TextInput
                                    value={query}
                                    onChange={setQuery}
                                    maw={400}
                                    sx={{ flexGrow: 1 }}
                                    placeholder={'Search'}
                                    icon={<IconSearch size={rem(10)} />}
                                    size={'xs'}
                                    styles={{
                                        wrapper: {
                                            '&:focus-within': {
                                                '& .mantine-Input-input': {
                                                    borderColor: '#936FAD'
                                                }
                                            }
                                        }
                                    }}
                                    rightSection={
                                        <FilterSelect
                                            dropdownElementMap={filterMap}
                                            size={'xs'}
                                            filterOptions={filterOptions}
                                        />
                                    }
                                />
                                <Group spacing={'sm'} ml="md">
                                    {contextState.map((filter) => (
                                        <FilterSelect.Tag key={filter} filter={filter} />
                                    ))}
                                    {hasFilters && (
                                        <Button
                                            variant="subtle"
                                            component="button"
                                            color="gray"
                                            compact
                                            size="xs"
                                            onClick={() => dispatch({ type: 'clear' })}
                                            tt={'unset'}>
                                            Clear Filters
                                        </Button>
                                    )}
                                </Group>
                                <Tooltip
                                    label={viewMode === 'tiles' ? t('listView') : t('tilesView')}>
                                    <ActionIcon ml="auto" onClick={toggleViewMode} variant="light">
                                        <Icon icon={viewMode === 'tiles' ? iconList : iconTiles} />
                                    </ActionIcon>
                                </Tooltip>
                            </>
                        )}
                    </Group>
                )}
                {/* <Divider mt="sm" /> */}
            </Stack>

            {Outlet ? (
                <Box sx={{ flexGrow: 1 }}>{Outlet}</Box>
            ) : (
                <FullMessagesView
                    attachmentsViewMode={viewMode}
                    activeTabId={activeTabId}
                    chatName={props.chat.title}
                    chatId={props.chat.id}
                    loadingMessages={props.loadingMessages}
                    messages={props.messages}
                    hasAccess={props.hasAccess}
                    attachments={filteredAttachments}
                    chatMessageDraft={props.chatMessageDraft}
                    highlightedMessageIds={props.highlightedMessageIds}
                    scrollToMessageId={props.scrollToMessageId}
                    onSendEmailNotification={props.onSendEmailNotification}
                />
            )}
        </Flex>
    );
}

export interface ChatHeaderProps {
    title: string;
    users: Username[];
    mesagesCount: number;
    filesCount: number;
    hasEditBtn: boolean;
    hasOpenBtn: boolean;
    hasRefreshBtn: boolean;
    editBtnText?: string;
    onEditClick?: () => void;
    onOpenClick?: () => void;
    onRefreshClick?: () => void;
}

export function ChatHeader(props: ChatHeaderProps) {
    const { t } = useTranslation();
    return (
        <Box>
            <PageDetailsHeader
                name={
                    <>
                        <Anchor component={Link} to="/chat">
                            <ActionIcon>
                                <IconArrowLeft />
                            </ActionIcon>
                        </Anchor>
                        <Title order={3}>{props.title}</Title>
                    </>
                }
                usernames={props.users}
                button={
                    <Group spacing="sm">
                        {props.hasRefreshBtn && (
                            <Button variant="light" onClick={props.onRefreshClick}>
                                {t('action.refresh')}
                            </Button>
                        )}
                        {props.hasEditBtn && (
                            <Button onClick={props.onEditClick} variant="light">
                                {props.editBtnText ?? t('action.edit')}
                            </Button>
                        )}
                        {props.hasOpenBtn && (
                            <Button onClick={props.onOpenClick} variant="light">
                                {t('action.open')}
                            </Button>
                        )}
                    </Group>
                }>
                <FileAndMessageSummary
                    filesCount={props.filesCount}
                    mesagesCount={props.mesagesCount}
                />
            </PageDetailsHeader>
        </Box>
    );
}

export default ChatPage;
