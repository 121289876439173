import { createStyles } from '@mantine/core';

export const useContactViewStyles = createStyles((theme) => {
    const hoverStyle = {
        margin: `calc(-1 * ${theme.spacing.xs})`,
        borderRadius: theme.radius.sm,
        padding: theme.spacing.xs,
        backgroundColor: theme.other.getActiveColor(theme),
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7]
    };

    const questionBg = theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1];

    return {
        tagList: {
            justifyContent: 'flex-end',
            '& .privmx-add-tag': {
                display: 'block'
            }
        },
        fieldValue: {
            float: 'right',
            overflowWrap: 'anywhere'
        },
        placeholder: {},
        fieldGroup: {
            backgroundColor: questionBg,
            borderRadius: theme.radius.md,
            width: '97%',
            marginInline: 'auto',
            padding: `calc(${theme.spacing.md} * 2)`,
            maxWidth: 700,
            [`@media (max-width:${theme.breakpoints.md})`]: {
                padding: theme.spacing.md
            }
        },
        extraInfoFieldGroup: {
            backgroundColor:
                theme.colorScheme === 'dark'
                    ? theme.colors[theme.primaryColor][6]
                    : theme.colors[theme.primaryColor][1],
            color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[9],
            padding: theme.spacing.md
        },
        hover: {
            cursor: 'pointer',
            '&:hover': hoverStyle
        },
        hovered: hoverStyle,
        fieldContent: {
            width: '70%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        link: {
            color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 8 : 7]
        }
    };
});
