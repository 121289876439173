import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './Store';
import * as types from '../types/Types';
import { api, Api } from '../api/Api';
import { AttachmentEx, ThreadId } from '../types/Types';
import { selectUsers } from './UsersSlice';
import { Utils } from '../api/privmx/utils/Utils';
import { setChatTags, setMeetingTags } from './ModalsSlice';

export interface FormsWithSubmits {
    forms: types.FormModel2[];
    submits: types.FormRow[];
}

export interface DataCacheState {
    companies: types.Company[];
    contacs: types.Contact[];
    chats: types.Chat[];
    meetings: types.Meeting[];
    inquirySubmitThreads: types.Chat[];
    forms: types.FormModel2[];
    attachments: types.AttachmentEx[];
    formsWithSubmitsThatHaveChats: FormsWithSubmits;
}

const initialState: DataCacheState = {
    companies: [],
    contacs: [],
    chats: [],
    meetings: [],
    inquirySubmitThreads: [],
    forms: [],
    attachments: [],
    formsWithSubmitsThatHaveChats: { forms: [], submits: [] }
};

export const loadChatsAsync = createAsyncThunk(
    'dataCache/loadChatsAsync',
    async (api: Api, { dispatch }) => {
        const chats = await api.getChats();
        const allTags = Utils.unique(chats.map((x) => x.tags).flat());
        dispatch(setChatTags(allTags));
        return chats;
    }
);

export const loadContacsAsync = createAsyncThunk('dataCache/loadContacsAsync', async () => {
    const contacts = await api.getContacts();
    return contacts;
});

export const loadMeetingsAsync = createAsyncThunk(
    'dataCache/loadMeetingsAsync',
    async (api: Api, { dispatch }) => {
        const meetings = await api.getMeetings();
        const allTags = Utils.unique(meetings.map((x) => x.tags).flat());
        dispatch(setMeetingTags(allTags));
        return meetings;
    }
);

export const loadInquirySubmitThreadsAsync = createAsyncThunk(
    'dataCache/loadInquirySubmitThreadsAsync',
    async (api: Api) => {
        const meetings = await api.getInquirySubmitThreads();
        return meetings;
    }
);

export const loadFormsAsync = createAsyncThunk('dataCache/loadFormsAsync', async (api: Api) => {
    const forms = await api.getFormList();
    return forms;
});

export const loadAttachmentsAsync = createAsyncThunk(
    'dataCache/loadAttachmentsAsync',
    async (api: Api) => {
        const attachments = await api.getFiles();
        return attachments;
    }
);

export const loadCompaniesAsync = createAsyncThunk('dataCache/loadCompaniesAsync', async () => {
    const companies = await api.getCompanies();
    return companies;
});

export const loadFormsWithSubmitsThatHaveChatsAsync = createAsyncThunk(
    'dataCache/loadFormsWithSubmitsThatHaveChatsAsync',
    async ({ api, threadIds }: { api: Api; threadIds: types.ThreadId[] }) => {
        const formsWithSubmitsThatHaveChats =
            await api.getFormsWithSubmitsAndCachedAttachmentsByThreadIds(threadIds);
        return formsWithSubmitsThatHaveChats;
    }
);

export const dataCacheSlice = createSlice({
    name: 'dataCache',
    initialState,
    reducers: {
        setContacts: (state, action: PayloadAction<types.Contact[]>) => {
            state.contacs = action.payload;
        },
        setCompanies: (state, action: PayloadAction<types.Company[]>) => {
            state.companies = action.payload;
        },
        addComapny: (state, action: PayloadAction<types.Company>) => {
            state.companies = [...state.companies, action.payload];
        },
        setChats: (state, action: PayloadAction<types.Chat[]>) => {
            state.chats = action.payload;
        },
        setMeetings: (state, action: PayloadAction<types.Meeting[]>) => {
            state.meetings = action.payload;
        },
        setInquirySubmitThreads: (state, action: PayloadAction<types.Chat[]>) => {
            state.inquirySubmitThreads = action.payload;
        },
        setForms: (state, action: PayloadAction<types.FormModel2[]>) => {
            state.forms = action.payload;
        },
        setAttachments: (state, action: PayloadAction<types.AttachmentEx[]>) => {
            state.attachments = action.payload;
        },
        setFormsWithSubmitsThatHaveChats: (state, action: PayloadAction<FormsWithSubmits>) => {
            state.formsWithSubmitsThatHaveChats = action.payload;
        },
        mutateContact: (state, action: PayloadAction<types.Contact>) => {
            state.contacs = state.contacs.map((x) => {
                if (x.id === action.payload.id) {
                    return action.payload;
                } else {
                    return { ...x };
                }
            });
        },
        mutateCompany: (state, action: PayloadAction<types.Company>) => {
            state.companies = state.companies.map((x) => {
                if (x.id === action.payload.id) {
                    return action.payload;
                } else {
                    return { ...x };
                }
            });
        },
        upsertChat: (state, action: PayloadAction<types.Chat>) => {
            const idx = state.chats.findIndex((x) => x.id === action.payload.id);
            if (idx >= 0) {
                state.chats[idx] = action.payload;
            } else {
                state.chats.push(action.payload);
            }
        },
        upsertMeeting: (state, action: PayloadAction<types.Meeting>) => {
            const idx = state.meetings.findIndex((x) => x.id === action.payload.id);
            if (idx >= 0) {
                state.meetings[idx] = action.payload;
            } else {
                state.meetings.push(action.payload);
            }
        },
        upsertInquirySubmitThread: (state, action: PayloadAction<types.Chat>) => {
            const idx = state.inquirySubmitThreads.findIndex((x) => x.id === action.payload.id);
            if (idx >= 0) {
                state.inquirySubmitThreads[idx] = action.payload;
            } else {
                state.inquirySubmitThreads.push(action.payload);
            }
        },
        upsertForm: (state, action: PayloadAction<types.FormModel2>) => {
            const idx = state.forms.findIndex((x) => x.id === action.payload.id);
            if (idx >= 0) {
                state.forms[idx] = action.payload;
            } else {
                state.forms.push(action.payload);
            }

            const idx2 = state.formsWithSubmitsThatHaveChats.forms.findIndex(
                (x) => x.id === action.payload.id
            );
            if (idx2 >= 0) {
                state.formsWithSubmitsThatHaveChats.forms[idx2] = action.payload;
            } else {
                state.formsWithSubmitsThatHaveChats.forms.push(action.payload);
            }
        },
        upsertFormSubmit: (state, action: PayloadAction<types.FormRow>) => {
            const idx = state.formsWithSubmitsThatHaveChats.submits.findIndex(
                (x) => x.id === action.payload.id
            );
            if (idx >= 0) {
                state.formsWithSubmitsThatHaveChats.submits[idx] = action.payload;
            } else {
                state.formsWithSubmitsThatHaveChats.submits.push(action.payload);
            }
        },
        upsertAttachment: (state, action: PayloadAction<types.AttachmentEx>) => {
            const idx = state.attachments.findIndex((x) => x.id === action.payload.id);
            if (idx >= 0) {
                state.attachments[idx] = action.payload;
            } else {
                state.attachments.push(action.payload);
            }
        },
        removeChat: (state, action: PayloadAction<types.ChatId>) => {
            const idx = state.chats.findIndex((x) => x.id === action.payload);
            if (idx >= 0) {
                state.chats.splice(idx);
            }
        },
        removeMeeting: (state, action: PayloadAction<types.MeetingId>) => {
            const idx = state.meetings.findIndex((x) => x.id === action.payload);
            if (idx >= 0) {
                state.meetings.splice(idx);
            }
        },
        removeInquirySubmitThread: (state, action: PayloadAction<types.ChatId>) => {
            const idx = state.inquirySubmitThreads.findIndex((x) => x.id === action.payload);
            if (idx >= 0) {
                state.inquirySubmitThreads.splice(idx);
            }
        },
        removeForm: (state, action: PayloadAction<types.FormId>) => {
            const idx = state.forms.findIndex((x) => x.id === action.payload);
            if (idx >= 0) {
                state.forms.splice(idx);
            }

            const idx2 = state.formsWithSubmitsThatHaveChats.forms.findIndex(
                (x) => x.id === action.payload
            );
            if (idx2 >= 0) {
                state.formsWithSubmitsThatHaveChats.forms.splice(idx2);
            }
        },
        removeFormSubmit: (state, action: PayloadAction<types.FormRowId>) => {
            const idx = state.formsWithSubmitsThatHaveChats.submits.findIndex(
                (x) => x.id === action.payload
            );
            if (idx >= 0) {
                state.formsWithSubmitsThatHaveChats.submits.splice(idx);
            }
        },
        removeAttachment: (state, action: PayloadAction<types.AttachmentId>) => {
            const idx = state.attachments.findIndex((x) => x.id === action.payload);
            if (idx >= 0) {
                state.attachments.splice(idx);
            }
        },
        resetDataCacheState: () => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadCompaniesAsync.fulfilled, (state, action) => {
                state.companies = action.payload;
            })
            .addCase(loadContacsAsync.fulfilled, (state, action) => {
                state.contacs = action.payload;
            })
            .addCase(loadChatsAsync.fulfilled, (state, action) => {
                state.chats = action.payload;
            })
            .addCase(loadMeetingsAsync.fulfilled, (state, action) => {
                state.meetings = action.payload;
            })
            .addCase(loadInquirySubmitThreadsAsync.fulfilled, (state, action) => {
                state.inquirySubmitThreads = action.payload;
            })
            .addCase(loadFormsAsync.fulfilled, (state, action) => {
                state.forms = action.payload;
            })
            .addCase(loadAttachmentsAsync.fulfilled, (state, action) => {
                state.attachments = action.payload;
            })
            .addCase(loadFormsWithSubmitsThatHaveChatsAsync.fulfilled, (state, action) => {
                state.formsWithSubmitsThatHaveChats = action.payload;
            });
    }
});

export const {
    addComapny,
    mutateCompany,
    mutateContact,
    setContacts,
    setCompanies,
    setChats,
    setMeetings,
    setInquirySubmitThreads,
    setForms,
    setAttachments,
    setFormsWithSubmitsThatHaveChats,
    upsertChat,
    upsertMeeting,
    upsertInquirySubmitThread,
    upsertForm,
    upsertFormSubmit,
    upsertAttachment,
    removeChat,
    removeMeeting,
    removeInquirySubmitThread,
    removeForm,
    removeFormSubmit,
    removeAttachment,
    resetDataCacheState
} = dataCacheSlice.actions;

export const selectDataCache = (state: RootState) => state.dataCache;

export const selectCachedChats = (state: RootState) => state.dataCache.chats;
export const selectCachedMeetings = (state: RootState) => state.dataCache.meetings;
export const selectCachedInquirySubmitThreads = (state: RootState) =>
    state.dataCache.inquirySubmitThreads;
export const selectCachedThreads = (state: RootState) => [
    ...state.dataCache.chats,
    ...state.dataCache.meetings,
    ...state.dataCache.inquirySubmitThreads
];
export const selectCachedForms = (state: RootState) => state.dataCache.forms;
export const selectCachedAttachments = (state: RootState) => state.dataCache.attachments;
export const selectCachedChat = (chatId: types.ChatId) => (state: RootState) =>
    state.dataCache.chats.find((x) => x.id === chatId);
export const selectCachedMeeting = (meetingId?: types.MeetingId) => (state: RootState) =>
    state.dataCache.meetings.find((x) => x.id === meetingId);
export const selectCachedInquirySubmitThread =
    (inquirySubmitThreadId: types.ChatId) => (state: RootState) =>
        state.dataCache.inquirySubmitThreads.find((x) => x.id === inquirySubmitThreadId);
export const selectCachedThread =
    (threadId: types.ChatId | types.MeetingId) => (state: RootState) =>
        [
            ...state.dataCache.chats,
            ...state.dataCache.meetings,
            ...state.dataCache.inquirySubmitThreads
        ].find((x) => x.id === threadId);
export const selectCachedForm = (formId: types.FormId) => (state: RootState) =>
    state.dataCache.forms.find((x) => x.id === formId);
export const selectCachedAttachment = (attachmentId: types.AttachmentId) => (state: RootState) =>
    state.dataCache.attachments.find((x) => x.id === attachmentId);
export const selectCachedAttachmentsByThreadId =
    (threadId: types.ChatId | types.MeetingId) => (state: RootState) =>
        state.dataCache.attachments.filter((x) => x.chatId === threadId);

export const selectSubmitsThatHaveChatsByFormId = (formId: types.FormId) => (state: RootState) =>
    state.dataCache.formsWithSubmitsThatHaveChats.submits.filter(
        (x) => x.chatId && x.formId === formId
    );

const selectUserEmail = (
    state: RootState,
    data: { username?: types.Username; id?: types.Contact['id'] }
) => data;

export const selectCachedCompanies = createSelector([selectDataCache], (data) => data.companies);
export const selectCachedContacts = createSelector([selectDataCache], (data) => data.contacs);

const _selectCachedContactCompany = createSelector(
    [selectCachedCompanies, (state: RootState, companyId?: types.CompanyId) => companyId],
    (companies, companyId) => companies.find((company) => company.id === companyId)
);

export function selectCachedContactCompany(companyId?: types.CompanyId) {
    return (state: RootState) => _selectCachedContactCompany(state, companyId);
}

export const selectCachedContactsAndUsers = createSelector(
    [selectCachedContacts, selectUsers],
    (contacts, users) => {
        const allContacts = new Map<types.Email, types.UserOrContact>();

        for (const user of users) {
            if (allContacts.has(user.email)) {
                continue;
            }
            allContacts.set(user.email, { type: 'user', user });
        }

        for (const contact of contacts) {
            if (allContacts.has(contact.email)) {
                continue;
            }
            allContacts.set(contact.email, { type: 'contact', contact: contact });
        }

        return Array.from(allContacts.values()) as types.UserOrContact[];
    }
);

export const selectCashedContact = createSelector(
    [selectCachedContacts, selectUserEmail],
    (data, { id, username }) => {
        const user = data.find((contact) => {
            if (username) {
                return contact.username === username;
            } else {
                return contact.id === id;
            }
        });
        return user;
    }
);

export const selectCachedCompaniesWithUsers = createSelector(
    [selectCachedCompanies, selectCachedContacts],
    (companies, contacts) => {
        const companyMap: Map<types.CompanyId, types.Contact[]> = new Map();

        for (const contact of contacts) {
            if (contact.companyId && companyMap.has(contact.companyId)) {
                const companyContacts = companyMap.get(contact.companyId);
                companyMap.set(contact.companyId, [...(companyContacts || []), contact]);
            } else if (contact.companyId) {
                companyMap.set(contact.companyId, [contact]);
            }
        }

        return companies.map((company) => ({
            ...company,
            contacts: companyMap.get(company.id) || []
        })) as types.CompanyWithContacts[];
    }
);

export const selectUsedTags = createSelector(
    [selectCachedChats, selectCachedMeetings],
    (chats, meetings) => {
        const containers = [chats, meetings].flat();
        const tags = new Set<string>();
        for (const container of containers) {
            for (const tag of container.tags) {
                tags.add(tag);
            }
        }
        return [...tags] as types.Tag[];
    }
);

export const selectChatsWithFiles = createSelector([selectCachedAttachments], (attachments) => {
    const map = new Map<ThreadId, AttachmentEx[]>();

    attachments.forEach((file) => {
        if (map.has(file.chatId)) {
            map.set(file.chatId, [...(map.get(file.chatId) || []), file]);
        } else {
            map.set(file.chatId, [file]);
        }
    });

    return map;
});

function selectThreadId(state: RootState, threadId: ThreadId) {
    return threadId;
}

export const selectChatFiles = createSelector(
    [selectChatsWithFiles, selectThreadId],
    (chats, id) => {
        return chats.get(id);
    }
);

export const selectUsernamesToEmail = createSelector(
    [selectCachedContacts, selectUsers],
    (contacs, users) => {
        const map = [...contacs, ...users]
            .map((x) => (x.username ? [x.username, x.email] : null))
            .filter((x) => x !== null) as any as Array<[types.Username, types.Email]>;

        return new Map(map);
    }
);

const _selectEmail = createSelector(
    [selectUsernamesToEmail, (state: RootState, username?: types.Username) => username],
    (usernameMap, username) => {
        return usernameMap.get(username || ('' as types.Username));
    }
);

export function selectEmail(username?: types.Username) {
    return (state: RootState) => _selectEmail(state, username);
}

export const selectUsernameToName = createSelector(
    [selectCachedContacts, selectUsers],
    (contacts, users) => {
        const map = [...contacts, ...users]
            .map((x) => (x.username || x.name === x.email ? [x.username, x.name] : null))
            .filter((x) => x !== null) as Array<[types.Username, types.Contact['name']]>;

        return new Map<types.Username, types.Contact['name']>(map);
    }
);

const _selectName = createSelector(
    [selectUsernameToName, (state: RootState, username?: types.Username) => username],
    (usernameMap, username) => {
        return usernameMap.get(username || ('' as types.Username));
    }
);

export function selectContactName(username: types.Username | undefined) {
    return (state: RootState) => _selectName(state, username);
}

export default dataCacheSlice.reducer;
