import * as privfs from 'privfs-client';
import { UserProfileData } from './admin/userCreation/Types';
import { ImageTypeDetector } from './ImageTypeDetector';
import { KvdbSettingEntryX, KvdbUtils } from './kvdb/KvdbUtils';
import { PrivmxConst } from './PrivmxConst';
import { Utils } from './utils/Utils';
import { UserPassword, Username } from '../../types/Types';

export interface Profile {
    name?: string;
    image?: string;
    description?: string;
}

export interface Presence {
    autoPresenceAfterLogin: boolean;
    dontAskAboutPresenceType: boolean;
    type: string;
    whitelist: string[];
}

export interface Notifications {
    [name: string]: any;
    enabled: boolean;
    email: string;
}

export interface GlobalModulesNotifications {
    [name: string]: boolean | undefined;
}

export enum UnreadBadgeClickAction {
    ASK = 'ask',
    IGNORE = 'ignore',
    MARK_AS_READ = 'mark-as-read'
}

export enum PasteAsFileAction {
    ASK = 'ask',
    PASTE_AS_TEXT = 'paste-as-text',
    PASTE_AS_FILE = 'paste-as-file'
}

export enum SystemClipboardIntegration {
    ASK = 'ask',
    DISABLED = 'disabled',
    ENABLED = 'enabled'
}

export enum TasksInvisibleListWarning {
    DISABLED = 'disabled',
    ENABLED = 'enabled'
}

export interface ContentEditableEditorSettings {
    isAutoTaskPickerEnabled?: boolean;
    isAutoFilePickerEnabled?: boolean;
}

export type ContactsTemporaryPaswordsStore = { [userId: string]: string };

export interface UI {
    windowTitleBarButtonsPosition: string;
    audio: boolean;
    notifications: boolean;
    globalModulesNotifications: string;
    notificationsVolume: number;
    seenFirstLoginInfo: boolean;
    lang?: string;
    spellchecker2: boolean;
    spellCheckerLanguages: string | null;
    showEncryptedText: boolean;
    showAllSections: boolean;

    contextSwitchWithoutShift: boolean;

    unreadBadgeClickAction: UnreadBadgeClickAction;
    unreadBadgeUseDoubleClick: boolean;
    playBubblePopSound: boolean;
    pasteAsFileAction: PasteAsFileAction;
    appSilentMode: boolean;
    onlineFirst: boolean;
    systemClipboardIntegration: SystemClipboardIntegration;
    tasksInvisibleListWarning: TasksInvisibleListWarning;
    pinnedSectionIdsStr: string;
    autoMarkAsRead: boolean;
    inactivityOverlay: boolean;
    showMoreToolsInNavbar: boolean;
    autostartEnabled?: boolean;
    errorsLoggingEnabled?: boolean;
    selectedDevices?: string | null;
    videoConferenceSettings?: string;
    customSuccessColor?: string;
    customBackgroundColor?: string;

    shownMsgBoxes?: string;
    enterSends?: string;
}

export interface Mail {
    signature?: string;
}

export interface Player {
    loop?: boolean;
    random?: boolean;
    playlist?: string[];
    collapsed?: boolean;
    muted?: boolean;
    volume?: number;
}

export interface Actions {
    initDataProcessed?: boolean;
}

export interface Sounds {
    [key: string]: string;
}

export interface CustomSectionNames {
    [key: string]: string;
}

export interface Preferences {
    version?: number;
    ui?: UI;
    profile?: Profile;
    notifications?: Notifications;
    presence?: Presence;
    mail?: Mail;
    player?: Player;
    actions?: Actions;
    sounds?: Sounds;
    customSectionNames?: CustomSectionNames;
    contentEditableEditor?: ContentEditableEditorSettings;
    contactsTemporaryPaswordsStore?: ContactsTemporaryPaswordsStore;
}

export class UserPreferences {
    static DEFAULTS: Preferences = {
        version: 1,
        ui: {
            windowTitleBarButtonsPosition: 'right',
            audio: false,
            notifications: true,
            globalModulesNotifications: '{}',
            notificationsVolume: 1.0,
            seenFirstLoginInfo: false,
            spellchecker2: false,
            spellCheckerLanguages: null,
            showEncryptedText: true,
            showAllSections: true,
            contextSwitchWithoutShift: true,
            unreadBadgeClickAction: UnreadBadgeClickAction.ASK,
            unreadBadgeUseDoubleClick: false,
            playBubblePopSound: true,
            pasteAsFileAction: PasteAsFileAction.ASK,
            appSilentMode: false,
            onlineFirst: false,
            systemClipboardIntegration: SystemClipboardIntegration.ASK,
            tasksInvisibleListWarning: TasksInvisibleListWarning.ENABLED,
            pinnedSectionIdsStr: '[]',
            autoMarkAsRead: true,
            inactivityOverlay: true,
            showMoreToolsInNavbar: false,
            selectedDevices: null,
            customSuccessColor: 'default',
            customBackgroundColor: 'hsla(0, 0%, 18%, 1.0)',
            shownMsgBoxes: '[]',
            enterSends: '{}'
        },
        profile: {
            name: '',
            image: '',
            description: ''
        },
        notifications: {
            enabled: false,
            email: ''
        },
        presence: {
            autoPresenceAfterLogin: false,
            dontAskAboutPresenceType: false,
            type: 'noone',
            whitelist: []
        },
        player: {
            loop: true,
            random: false,
            playlist: [],
            collapsed: false,
            muted: false,
            volume: 1.0
        },
        actions: {
            initDataProcessed: false
        },
        sounds: {},
        customSectionNames: {},
        contentEditableEditor: {
            isAutoFilePickerEnabled: true,
            isAutoTaskPickerEnabled: true
        },
        contactsTemporaryPaswordsStore: {}
    };

    static createInsertion(userSettingsKvdb: privfs.db.KeyValueDb<any>, profile: UserProfileData) {
        const preferences = Utils.simpleDeepClone(UserPreferences.DEFAULTS);
        if (!preferences.profile) {
            preferences.profile = {};
        }
        if (profile && profile.name) {
            preferences.profile.name = profile.name;
        }
        if (profile && profile.description) {
            preferences.profile.description = profile.description;
        }
        if (profile && profile.image) {
            preferences.profile.image = ImageTypeDetector.createDataUrlFromBuffer(profile.image);
        }
        return userSettingsKvdb.createSetData(PrivmxConst.USER_PREFERENCES_KVDB_KEY, {
            secured: { value: preferences }
        });
    }

    static async updateTemporaryPasswordsStore(
        userSettingsKvdb: privfs.db.KeyValueDb<any>,
        usersWithPasswords: Array<{ username: Username; password?: UserPassword }>
    ) {
        await (userSettingsKvdb as privfs.db.KeyValueDb<KvdbSettingEntryX>).withLock(
            PrivmxConst.USER_PREFERENCES_KVDB_KEY,
            (content) => {
                const newPreferences =
                    content === false
                        ? ({} as Preferences)
                        : Utils.simpleDeepClone(content.secured.value as Preferences);
                if (!newPreferences.contactsTemporaryPaswordsStore) {
                    newPreferences.contactsTemporaryPaswordsStore = {};
                }
                for (const { username, password } of usersWithPasswords) {
                    if (password) {
                        newPreferences.contactsTemporaryPaswordsStore[username] = password;
                    } else {
                        if (username in newPreferences.contactsTemporaryPaswordsStore) {
                            delete newPreferences.contactsTemporaryPaswordsStore[username];
                        }
                    }
                }

                return KvdbUtils.createKvdbSettingEntry(newPreferences);
            }
        );
    }

    static async getTemporaryPasswordForUser(
        userSettingsKvdb: privfs.db.KeyValueDb<any>,
        username: string
    ) {
        const prefs = (await userSettingsKvdb.get(PrivmxConst.USER_PREFERENCES_KVDB_KEY)).secured
            .value as Preferences;
        if (
            prefs.contactsTemporaryPaswordsStore &&
            username in prefs.contactsTemporaryPaswordsStore
        ) {
            return prefs.contactsTemporaryPaswordsStore[username];
        }
        return undefined;
    }

    static async updateProfile(
        userSettingsKvdb: privfs.db.KeyValueDb<any>,
        updater: (currentProfile: Profile) => Profile
    ) {
        await (userSettingsKvdb as privfs.db.KeyValueDb<KvdbSettingEntryX>).withLock(
            PrivmxConst.USER_PREFERENCES_KVDB_KEY,
            (content) => {
                const newPreferences =
                    content === false
                        ? ({} as Preferences)
                        : Utils.simpleDeepClone(content.secured.value as Preferences);
                newPreferences.profile = updater(newPreferences.profile ?? this.DEFAULTS.profile!);
                return KvdbUtils.createKvdbSettingEntry(newPreferences);
            }
        );
    }
}
