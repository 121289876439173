import {
    Stack,
    Title,
    Text,
    Group,
    Box,
    Center,
    Button,
    Paper,
    Flex,
    Switch,
    Textarea,
    Modal,
    LoadingOverlay,
    ScrollArea,
    useMantineColorScheme
} from '@mantine/core';
import { useAppSelector } from '../../store';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { parseAndValidateEnteredEmails } from '../SettingsScreen/NewStaffMember';
import { useDisclosure, useInputState } from '@mantine/hooks';
import useToast from '../../hooks/useToast';
import { api } from '../../api/Api';
import { CreateManagableUserResult } from '../../api/privmx/admin/userCreation/ManagableUserCreator';
import { Contact, Email, Language, Tag } from '../../types/Types';
import ThreadAdded from '../../atoms/Modals/ThreadCreated';
import UserAvatar from '../../atoms/UserAvatar';
import { FileChooser } from '../../utils/FileChooser';
import {
    importContactsFromFiles,
    flattenImportResults,
    ImprtedFileImportResultFlat
} from '../ContactsScreen';
import { ViewMnemonic } from '../../components/ViewMnemonic';
import { useLocation } from 'react-router-dom';
import { Utils } from '../../utils/Utils';
import { FailedUser } from '../../components/ChatAddPage/ChatAddModal';
import { Grid } from '@mantine/core';

function getContactModel(email: string): Contact {
    const currentUserUsername = api.getCurrentUserUsername();
    return {
        id: '' as Contact['id'],
        address: '' as Contact['address'],
        companyId: '' as Contact['companyId'],
        email: email.trim() as Contact['email'],
        mobilePhone: '' as Contact['mobilePhone'],
        name: '' as Contact['name'],
        note: '' as Contact['note'],
        phone: '' as Contact['phone'],
        userType: 'email',
        avatar: '' as Contact['avatar'],
        language: 'en' as Contact['language'],
        username: undefined,
        pinned: false,
        archived: false,
        tags: [] as Tag[],
        createdBy: '' as Contact['createdBy'],
        createdDate: 0 as Contact['createdDate'],
        lastModifiedBy: '' as Contact['lastModifiedBy'],
        lastModifiedDate: 0 as Contact['lastModifiedDate'],
        isPrivate: currentUserUsername !== undefined,
        privateContactVisibleForUsers:
            currentUserUsername !== undefined ? [currentUserUsername] : []
    };
}

const STEPS = ['theme', 'mnemonic', 'add-staff', 'add-contacts', 'end-screen'] as const;
type StepType = (typeof STEPS)[number];
function isStepType(val: string): val is StepType {
    return STEPS.find((step) => val === step) !== undefined;
}

function ThemeStep() {
    const [selected, setSelected] = useState<'light' | 'dark'>('light');
    const currentUser = useAppSelector(selectCurrentUser);
    const navigate = useNavigate();

    const { toggleColorScheme } = useMantineColorScheme();

    function handleNextStep() {
        toggleColorScheme(selected);
        window.localStorage.setItem(`color-scheme-${currentUser.id}`, selected);

        if (currentUser.role === 'client') {
            navigate('/welcome/end-screen');
        } else {
            if (currentUser.isAdmin) {
                navigate('/welcome/add-staff');
            } else {
                navigate('/welcome/add-contacts');
            }
        }
    }

    const { t } = useTranslation();

    return (
        <Center h="100%">
            <Box h="100%">
                <Stack align="center" justify="center" h="100%">
                    <Title align="center">{t('screen.welcome.theme.title')}</Title>
                    <Text mb={32} align="center" color="dimmed">
                        {t('screen.welcome.theme.subTitle')}
                    </Text>
                    <Group spacing={64} mb={32}>
                        <Paper
                            onClick={() => setSelected('light')}
                            shadow={selected === 'light' ? 'lg' : undefined}
                            w={200}
                            h={200}
                            bg="gray.2"
                            display={'grid'}
                            sx={{ placeContent: 'center', cursor: 'pointer' }}>
                            <Text align="center" size={'xl'}>
                                {t('screen.welcome.theme.light')}
                            </Text>
                        </Paper>
                        <Paper
                            onClick={() => setSelected('dark')}
                            shadow={selected === 'dark' ? 'lg' : undefined}
                            w={200}
                            h={200}
                            bg="dark"
                            color="gray"
                            display={'grid'}
                            sx={{ placeContent: 'center', cursor: 'pointer' }}>
                            <Text color="gray.0" align="center" size={'xl'}>
                                {t('screen.welcome.theme.dark')}
                            </Text>
                        </Paper>
                    </Group>

                    <Button onClick={handleNextStep}> {t('screen.welcome.theme.next')}</Button>
                </Stack>
            </Box>
        </Center>
    );
}

function MnemonicScreenStep() {
    const navigate = useNavigate();

    const [isOnboardingPassed, setIsOnboardingPassed] = useState(false);
    const currentUser = useAppSelector(selectCurrentUser);

    useEffect(() => {
        if (currentUser.id) {
            setIsOnboardingPassed(api.getIsOnboardingPassed());
        }
    }, [currentUser.id]);

    function handleNextStep() {
        if (isOnboardingPassed) {
            navigate('/chat');
        } else {
            navigate('/welcome/theme');
        }
    }

    const location = useLocation();

    const { t } = useTranslation();

    return (
        <Center h="100%">
            <Box h="100%">
                <Stack align="center" justify="center" h="100%">
                    <Title align="center" size={42}>
                        {t('screen.welcome.mnemonic.title')}
                    </Title>
                    <Text align="center" color="dimmed" w={600}>
                        {t('screen.welcome.mnemonic.subTitle')}
                    </Text>
                    <ViewMnemonic initialPassword={location.state?.password} />
                    <Group position={'center'} py="md">
                        <Button onClick={handleNextStep}>
                            {isOnboardingPassed
                                ? t('screen.welcome.mnemonic.done')
                                : t('screen.welcome.mnemonic.next')}
                        </Button>
                    </Group>
                </Stack>
            </Box>
        </Center>
    );
}

function AddStaffStep() {
    const [textValue, setTextAreaValue] = useInputState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, { open: indicateLoading, close: indicateNotLoading }] = useDisclosure();

    const [internalUsers, setInternalUsers] = useState<null | Array<
        Awaited<ReturnType<typeof api.createInternalUser>>
    >>();
    const [failedInternalUsers, setFailedInternalUsers] = useState<FailedUser[] | null>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNextStep = useCallback(() => {
        navigate('/welcome/add-contacts');
    }, [navigate]);

    const onClose = useCallback(() => {
        setInternalUsers(null);
        setFailedInternalUsers(null);
        setTextAreaValue('');
        handleNextStep();
    }, [setTextAreaValue, handleNextStep]);

    const emitToast = useToast();

    const onAdminToggle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setIsAdmin(event.currentTarget.checked);
    }, []);

    const handleCreateMembers = useCallback(async () => {
        try {
            const validationResult = parseAndValidateEnteredEmails(textValue);
            if (validationResult.status === 'error') {
                emitToast('You have entered incorrect email/emails', 'error');
                return;
            }
            indicateLoading();

            const internalUsers: CreateManagableUserResult[] = [];
            const failedInternalUsers: FailedUser[] = [];
            for (const email of validationResult.correctValues) {
                try {
                    const creationResult = await api.createInternalUser(
                        email as Email,
                        'en' as Language,
                        isAdmin,
                        { type: 'manuallyViaAdminPanel' }
                    );
                    internalUsers.push(creationResult);
                } catch (err) {
                    const msg = Utils.getErrorMessage(err);
                    if (msg.toLocaleLowerCase().includes('with given email already exists')) {
                        failedInternalUsers.push({
                            email: email as Email,
                            reason: 'alreadyExists'
                        });
                    } else {
                        failedInternalUsers.push({ email: email as Email, reason: 'unknown' });
                    }
                }
            }

            setInternalUsers(internalUsers);
            setFailedInternalUsers(failedInternalUsers);
        } catch (error) {
            console.error(error);
            emitToast('Unable to create new member', 'error');
        } finally {
            indicateNotLoading();
        }
    }, [isAdmin, textValue, indicateLoading, emitToast, indicateNotLoading]);

    const mappedUsers = internalUsers
        ? new Map(
              internalUsers.map((x) => [
                  x.username,
                  { email: x.params.email, password: x.password }
              ])
          )
        : undefined;

    return (
        <Center h="100%">
            <LoadingOverlay visible={loading} />

            <Box h="100%">
                <Stack align="center" justify="center" h="100%">
                    <Title align="center">{t('screen.welcome.addStaff.title')}</Title>
                    <Text mb={32} align="center" color="dimmed" w={600}>
                        {t('screen.welcome.addStaff.subTitle')}
                    </Text>
                    <Box w="100%" h={200}>
                        <Textarea
                            w="100%"
                            inputWrapperOrder={['label', 'input', 'description', 'error']}
                            description={t('screen.settings.staff.descriptions.addNewStaffMember')}
                            minRows={3}
                            maxRows={5}
                            placeholder={'mail@example.com'}
                            value={textValue}
                            onChange={setTextAreaValue}
                        />
                        <Flex gap={0} direction="column" py="md">
                            <Flex justify="space-between" align="center" gap="xs" py={0}>
                                <Title size={14} py={0}>
                                    {t('screen.settings.staff.labels.grantAdminPermissions')}
                                </Title>
                                <Switch checked={isAdmin} onChange={onAdminToggle} />
                            </Flex>
                            <Text size="xs" color="dimmed" maw={400}>
                                {t('screen.settings.staff.descriptions.grantAdminPermissions')}
                            </Text>
                        </Flex>
                    </Box>

                    <Group position={'center'} py="md">
                        <Button onClick={onClose} variant={'light'}>
                            {t('screen.welcome.skip')}
                        </Button>
                        <Button onClick={handleCreateMembers}>
                            {t('screen.welcome.addStaff.addStaff')}
                        </Button>
                    </Group>
                </Stack>
            </Box>
            {(mappedUsers || failedInternalUsers) && (
                <Modal
                    opened={true}
                    onClose={onClose}
                    centered
                    zIndex={300}
                    size="auto"
                    title="New Staff members"
                    withCloseButton>
                    <Box p="lg">
                        <ThreadAdded
                            comunicateConfig={{
                                title: 'Temporary passwords',
                                subTitle:
                                    'Send temporary passwords to your colleagues, so they can log in and set their own.'
                            }}
                            onClose={onClose}
                            state={{
                                state: 'created',
                                payload: {
                                    title: '',
                                    createdUsers: mappedUsers,
                                    failedUsers: failedInternalUsers ?? []
                                }
                            }}
                        />
                    </Box>
                </Modal>
            )}
        </Center>
    );
}

function AddContactsStep() {
    const [textValue, setTextAreaValue] = useInputState('');
    const [loading, { open: indicateLoading, close: indicateNotLoading }] = useDisclosure();

    const [internalUsers, setInternalUsers] = useState<null | Array<Contact>>(null);
    const [failedInternalUsers, setFailedInternalUsers] = useState<FailedUser[] | null>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNextStep = useCallback(() => {
        navigate('/welcome/end-screen');
    }, [navigate]);

    const onClose = useCallback(() => {
        setInternalUsers(null);
        setFailedInternalUsers(null);
        setTextAreaValue('');
        handleNextStep();
    }, [setTextAreaValue, handleNextStep]);

    const emitToast = useToast();

    const handleCreateMembers = useCallback(async () => {
        try {
            const validationResult = parseAndValidateEnteredEmails(textValue);
            if (validationResult.status === 'error') {
                emitToast('You have entered incorrect email/emails', 'error');
                return;
            }
            indicateLoading();
            const newUsers: Array<Contact> = [];
            const failedInternalUsers: FailedUser[] = [];
            for (const email of validationResult.correctValues) {
                try {
                    const creationResult = await api.addContact(getContactModel(email));
                    newUsers.push(creationResult);
                } catch (err) {
                    const msg = Utils.getErrorMessage(err);
                    if (msg.toLocaleLowerCase().includes('with given email already exists')) {
                        failedInternalUsers.push({
                            email: email as Email,
                            reason: 'alreadyExists'
                        });
                    } else {
                        failedInternalUsers.push({ email: email as Email, reason: 'unknown' });
                    }
                }
            }
            setInternalUsers(newUsers);
            setFailedInternalUsers(failedInternalUsers);
        } catch (error) {
            console.error(error);
            emitToast('Unable to create new member', 'error');
        } finally {
            indicateNotLoading();
        }
    }, [textValue, indicateLoading, emitToast, indicateNotLoading]);

    const [importResults, setImportResults] = useState<ImprtedFileImportResultFlat[] | null>(null);

    const handleImportClick = useCallback(async () => {
        const files = await FileChooser.chooseFiles();
        const results = await importContactsFromFiles(files);
        setImportResults(flattenImportResults(results));
    }, []);

    const newContacts: Array<{ email: string }> | null = useMemo(() => {
        if (internalUsers) {
            return internalUsers.map((x) => ({ email: x.email }));
        } else if (importResults) {
            return importResults
                .filter((x) => x.result === 'imported')
                .map((x) => ({ email: x.contactName || '' }));
        } else {
            return null;
        }
    }, [importResults, internalUsers]);

    return (
        <Center h="100%">
            <LoadingOverlay visible={loading} />

            <Box h="100%">
                <Stack align="center" justify="center" h="100%">
                    <Title align="center"> {t('screen.welcome.addContacts.title')}</Title>
                    <Text mb={32} align="center" color="dimmed" w={600}>
                        {t('screen.welcome.addContacts.subTitle')}
                    </Text>
                    <Box w="100%" h={200}>
                        <Textarea
                            w="100%"
                            inputWrapperOrder={['label', 'input', 'description', 'error']}
                            description={
                                <Group position="apart">
                                    {t('screen.settings.staff.descriptions.addNewStaffMember')}
                                    <Button variant="subtle" onClick={handleImportClick}>
                                        {t('screen.welcome.addContacts.importContacts')}
                                    </Button>
                                </Group>
                            }
                            minRows={3}
                            maxRows={5}
                            placeholder={'mail@example.com'}
                            value={textValue}
                            onChange={setTextAreaValue}
                        />
                    </Box>

                    <Group position={'center'} py="md">
                        <Button onClick={onClose} variant={'light'}>
                            {t('screen.welcome.skip')}
                        </Button>
                        <Button onClick={handleCreateMembers}>
                            {t('screen.welcome.addContacts.addContacts')}
                        </Button>
                    </Group>
                </Stack>
            </Box>

            {(newContacts && newContacts.length) || failedInternalUsers ? (
                <Modal
                    opened={true}
                    onClose={onClose}
                    centered
                    zIndex={300}
                    size="lg"
                    title={t('screen.welcome.addContacts.newStaffMembers')}
                    withCloseButton>
                    <Box p="lg" display="flex" h="100%">
                        <Center h="100%" sx={{ flexGrow: 1, width: '100%' }}>
                            <Flex direction="column" gap={4}>
                                <Title order={1} align="center">
                                    {t('screen.welcome.addContacts.contactsAdded')}
                                </Title>

                                <ScrollArea.Autosize mah={220}>
                                    <Stack spacing={'xs'} h={220}>
                                        {newContacts &&
                                            newContacts.map((newContact) => (
                                                <Box
                                                    key={newContact.email}
                                                    p="xs"
                                                    display={'flex'}
                                                    sx={{
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                    <Group spacing="sm">
                                                        <UserAvatar />
                                                        <Text>{newContact.email}</Text>
                                                    </Group>
                                                </Box>
                                            ))}
                                        {failedInternalUsers &&
                                            failedInternalUsers.map((failedUser) => (
                                                <Grid columns={2} key={failedUser.email}>
                                                    <Grid.Col span={1}>
                                                        <Text size="sm">{failedUser.email}</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={1}>
                                                        <Text color="red.5" size="sm">
                                                            {failedUser.reason === 'unknown' &&
                                                                t(
                                                                    'errorMessage.couldNotCreateContact'
                                                                )}
                                                            {failedUser.reason ===
                                                                'alreadyExists' &&
                                                                t(
                                                                    'errorMessage.contactAlreadyExists'
                                                                )}
                                                        </Text>
                                                    </Grid.Col>
                                                </Grid>
                                            ))}
                                    </Stack>
                                </ScrollArea.Autosize>

                                <Group position="center" w="100%">
                                    <Button variant="light" onClick={() => onClose()}>
                                        {t('action.done')}
                                    </Button>
                                </Group>
                            </Flex>
                        </Center>
                    </Box>
                </Modal>
            ) : null}
        </Center>
    );
}

function EndScreenStep() {
    const navigate = useNavigate();

    function onClose() {
        navigate('/chat');
        api.matchOnboardingAsPassed();
    }

    const { t } = useTranslation();

    return (
        <Center h="100%">
            <Box h="100%">
                <Stack align="center" justify="center" h="100%">
                    <Title align="center" size={42}>
                        {t('screen.welcome.endScreen.welcome')}
                    </Title>
                    <Text align="center" color="dimmed" w={600}>
                        {t('screen.welcome.endScreen.subTitle')}
                    </Text>

                    <Group position={'center'} py="md">
                        <Button onClick={onClose} size="lg" w={200}>
                            {t('screen.welcome.endScreen.start')}
                        </Button>
                    </Group>
                </Stack>
            </Box>
        </Center>
    );
}

const StepsRouter: Record<StepType, React.ReactNode> = {
    mnemonic: <MnemonicScreenStep />,
    theme: <ThemeStep />,
    'add-contacts': <AddContactsStep />,
    'add-staff': <AddStaffStep />,
    'end-screen': <EndScreenStep />
};

function useStepParam(): StepType | null {
    const { step } = useParams();
    if (!step) return null;

    if (isStepType(step)) {
        return step;
    } else {
        return null;
    }
}

export default function WelcomeScreen() {
    const step = useStepParam();
    const navigate = useNavigate();

    if (step === null) {
        navigate('/');
        return <div></div>;
    }

    return <>{StepsRouter[step]}</>;
}
