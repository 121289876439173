import { createStyles, Paper, PaperProps } from '@mantine/core';
import React from 'react';

const useFormBoxStyles = createStyles((theme) => ({
    box: {
        width: '40%',
        marginBottom: theme.spacing.md,
        padding: theme.spacing.xl
    }
}));

export default function FormBox({ children, className, ...props }: PaperProps) {
    const { classes, cx } = useFormBoxStyles();

    return (
        <Paper {...props} className={cx(classes.box, className)}>
            {children}
        </Paper>
    );
}
