import { useCallback, useMemo } from 'react';
import { Contact, UsernameOrContactId } from '../../../types/Types';
import { UserAvatarX } from '../../../atoms/UserAvatar';
import Icon, { iconAdd } from '../../../components/Icon';
import useModal from '../../../hooks/useModal';
import { Avatar, Button, HoverCard, createStyles, Text, ScrollArea, Flex } from '@mantine/core';
import { useAppSelector } from '../../../store';
import { selectCurrentUser } from '../../../store/CurrentUserSlice';
import { useResizeObserver } from '@mantine/hooks';
import { selectUsernameToName } from '../../../store/DataCacheSlice';
import { AvatarGroupProps } from '@mantine/core/lib/Avatar/AvatarGroup/AvatarGroup';
import { useTranslation } from 'react-i18next';

const useUsersListStyles = createStyles(() => ({
    autoSizeRoot: {
        flexWrap: 'wrap',
        maxHeight: 26,
        overflow: 'hidden'
    },
    hiddenCountText: {
        fontSize: '0.7rem',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'default'
    }
}));

export interface UsersListProps {
    usernames: UsernameOrContactId[];
    className?: string;
    withAddUserButton?: boolean;
    addUserDefaults?: Partial<Contact>;
    includeSelfFirst?: boolean;
    maxCount?: number | 'auto';
    small?: boolean;
}

export function UsersList({
    usernames,
    addUserDefaults,
    className,
    includeSelfFirst,
    maxCount: pMaxCount,
    small,
    withAddUserButton,
    ...props
}: UsersListProps & Omit<AvatarGroupProps, 'children'>) {
    const { classes } = useUsersListStyles();
    const modalService = useModal();
    const handleAddUserClick = useCallback(async () => {
        modalService.openContactAddModal({ defaults: addUserDefaults });
    }, [modalService, addUserDefaults]);
    const currentUser = useAppSelector(selectCurrentUser);
    const filteredUsers = useMemo(() => {
        const arr = usernames.filter((username) =>
            username.type === 'user' ? username.username !== currentUser.username : true
        );
        const self = usernames.find(
            (username) => username.type === 'user' && username.username === currentUser.username
        );
        if (includeSelfFirst && self) {
            return [self, ...arr];
        }
        return arr;
    }, [usernames, includeSelfFirst, currentUser.username]);
    const [usersListRef, usersListRect] = useResizeObserver();
    const maxCount =
        pMaxCount === undefined
            ? 5
            : pMaxCount === 'auto'
            ? getMaxCount(usersListRect.width)
            : pMaxCount;

    const nameMap = useAppSelector(selectUsernameToName);
    const { t } = useTranslation();

    return (
        <Avatar.Group
            className={`${className} ${pMaxCount === 'auto' ? classes.autoSizeRoot : ''}`}
            ref={usersListRef}
            {...props}>
            {filteredUsers.slice(0, maxCount).map((username, i) => (
                <UserAvatarX key={i} user={username} size={small ? 20 : undefined} />
            ))}
            {filteredUsers.length > maxCount ? (
                <HoverCard position="bottom-start">
                    <HoverCard.Target>
                        <span
                            onClick={(e) => e.stopPropagation()}
                            className={classes.hiddenCountText}>
                            +{filteredUsers.length - maxCount}
                        </span>
                    </HoverCard.Target>
                    <HoverCard.Dropdown p={'xs'}>
                        <ScrollArea.Autosize
                            onClick={(e) => e.stopPropagation()}
                            mah={150}
                            type="always">
                            {filteredUsers.slice(maxCount).map((username, i) => (
                                <Flex key={i} direction={'row'} align={'center'} mb={8}>
                                    <UserAvatarX
                                        ml={0}
                                        mr={'xs'}
                                        user={username}
                                        showTooltip={false}
                                    />
                                    <Text onClick={(e: any) => e.stopPropagation()}>
                                        {username.type === 'contact'
                                            ? username.contactId
                                            : nameMap.get(username.username)}
                                    </Text>
                                </Flex>
                            ))}
                        </ScrollArea.Autosize>
                    </HoverCard.Dropdown>
                </HoverCard>
            ) : null}
            {withAddUserButton && (
                <Button onClick={handleAddUserClick}>
                    <Icon icon={iconAdd} />
                    {t('components.userList.createUser')}
                </Button>
            )}
        </Avatar.Group>
    );
}

function getMaxCount(width: number) {
    width -= 20;
    const firstItemWidth = 26;
    const subsequentItemWith = firstItemWidth - 12;
    if (width < firstItemWidth) {
        return 0;
    }
    return Math.floor((width - firstItemWidth) / subsequentItemWith) + 1;
}
