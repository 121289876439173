import * as privfs from 'privfs-client';

export class MessageCreator {
    constructor(private identity: privfs.identity.Identity) {}

    createMessage(
        oneOrMoreReceivers: privfs.message.MessageReceiver | privfs.message.MessageReceiver[],
        subject: string,
        text: string
    ): privfs.message.Message {
        return MessageCreator.createMessage(this.identity, oneOrMoreReceivers, subject, text);
    }

    static createMessage(
        sender: privfs.identity.Identity,
        oneOrMoreReceivers: privfs.message.MessageReceiver | privfs.message.MessageReceiver[],
        subject: string,
        text: string
    ): privfs.message.Message {
        const receivers = Array.isArray(oneOrMoreReceivers)
            ? oneOrMoreReceivers
            : [oneOrMoreReceivers];
        const message = new privfs.message.Message();
        message.setSender(sender);
        for (const receiver of receivers) {
            message.addReceiver(receiver);
        }
        message.title = subject;
        message.text = text;
        return message;
    }
}
