import { useCallback, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AttachmentEx, AttachmentId, Message } from '../../../types/Types';
import MessageAttachment from '../MessageAttachment';
import remarkGfm from 'remark-gfm';
import { Box, Group, Text, createStyles } from '@mantine/core';
import Timestamp from '../../../atoms/Timestamp';
import { JsonMessage } from './jsonMessages/JsonMessage';
import { useTranslation } from 'react-i18next';
import { UrlBuilder } from '../../../utils/UrlBuilder';
import { Gallery } from '../../FilesView/Gallery';
import ReactMarkdown from 'react-markdown';
import { LinkDetector, LinkMetadata } from '../../../utils/LinkDetector';
import { MessageLink } from './MessageLink';

export default function MessageContent({ message }: { message: Message }) {
    const { t } = useTranslation();
    const lastEdit = message.edits[message.edits.length - 1];
    const links = useMemo(() => {
        return message.decryptError ? [] : LinkDetector.detectLinks(message.text);
    }, [message.decryptError, message.text]);
    const [handleShowFilePreview, setHandleShowFilePreview] = useState<
        (fileId: AttachmentId) => void
    >(() => () => {});
    const handleGetShowFilePreview = useCallback((cb: (fileId: AttachmentId) => void) => {
        setHandleShowFilePreview(() => cb);
    }, []);
    return (
        <>
            <Text>
                {message.decryptError ? (
                    <div className="decrypt-error">{t('errorMessage.cannotDecryptMessage')}</div>
                ) : (
                    // MESSAGE CONTENT
                    <>
                        <MessageContentCore message={message} links={links} />
                    </>
                )}
            </Text>
            {message.attachments.length > 0 && (
                <Group align="start" my={4}>
                    {message.attachments.map((x) => (
                        <MessageAttachment
                            key={x.id}
                            attachment={x}
                            pending={!!message.pending}
                            onOpenPreview={handleShowFilePreview}
                        />
                    ))}
                </Group>
            )}
            {lastEdit && (
                <Text data-no-unread color="dimmed" size="xs" mt="xs">
                    {t('editedTimesLastWithCount', { count: message?.edits.length })}{' '}
                    <Timestamp date={lastEdit.t} />
                </Text>
            )}
            <Gallery
                files={message.attachments as AttachmentEx[]}
                getShowFilePreview={handleGetShowFilePreview}
            />
        </>
    );
}

const useContentStyle = createStyles((theme) => ({
    html: {
        wordBreak: 'break-word',
        '& *': {
            margin: 0,
            marginBottom: theme.spacing.sm,
            paddingBottom: 0
        },
        '& *:last-child': {
            marginBottom: 0
        },
        '& a[data-handled="true"]': {
            display: 'none'
        }
    },
    markdownDisplay: {
        '& p,h1,h2,h3,h4': {
            margin: 0,
            marginBottom: 0.5,
            wordBreak: 'break-all'
        }
    }
}));

export function MessageContentCore({
    message,
    links
}: {
    message: Message;
    links: LinkMetadata[];
}) {
    const { classes, cx } = useContentStyle();
    const navigate = useNavigate();
    if (message.mimetype === 'text/plain') {
        return <>{message.text}</>;
    }
    if (message.mimetype === 'text/markdown') {
        return (
            <div ref={(x) => x && modAllLinks(x, navigate)}>
                <ReactMarkdown
                    className={cx('markdown', classes.markdownDisplay)}
                    remarkPlugins={[remarkGfm]}
                    children={message.text}
                    linkTarget={(x) => (x.startsWith(UrlBuilder.baseUrl) ? '' : '_blank')}
                />
            </div>
        );
    }
    if (message.mimetype === 'application/json') {
        return <JsonMessage message={message} />;
    }
    if (message.mimetype === 'text/html') {
        return <HtmlMessageContentCore message={message} links={links} />;
    }
    return <>{message.text}</>;
}

function HtmlMessageContentCore({ message, links }: { message: Message; links: LinkMetadata[] }) {
    const { classes } = useContentStyle();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const sortedLinks = useMemo(() => [...links].sort((a, b) => b.start - a.start), [links]);
    const html = useMemo(() => {
        if (message.mimetype !== 'text/html') {
            return message.text;
        }
        let html = message.text;
        for (const link of sortedLinks) {
            const start = link.start;
            const end = link.end;
            let before = html.substring(0, start);
            const after = html.substring(end);
            const text = html.substring(start, end);
            if (before.endsWith(' href="')) {
                before = before.substring(0, before.length - ' href="'.length);
                before += ` data-handled="true" data-link-id="${getLinkId(
                    message.id,
                    link
                )}" href="`;
            }
            html = before + text + after;
        }
        return html;
    }, [message.mimetype, message.text, message.id, sortedLinks]);
    const modLinks = useCallback(
        (container: HTMLElement | null) => {
            if (!container) {
                return;
            }
            for (const el of container.querySelectorAll('a[data-link-id]')) {
                const linkId = el.getAttribute('data-link-id');
                const link = sortedLinks.find((x) => getLinkId(message.id, x) === linkId);
                if (!link) {
                    continue;
                }
                const el2 = document.createElement('span');
                el2.setAttribute('data-link-id', linkId ?? '');
                el.replaceWith(el2);
            }
        },
        [message.id, sortedLinks]
    );
    if (message.mimetype !== 'text/html') {
        return <>{message.text}</>;
    }
    return (
        <Box ref={containerRef}>
            <Box
                ref={modLinks}
                className={classes.html}
                dangerouslySetInnerHTML={{ __html: html }}></Box>
            {sortedLinks.map((x, idx) => {
                const linkId = getLinkId(message.id, x);
                const el = containerRef.current?.querySelector(`[data-link-id="${linkId}"]`);
                if (!el) {
                    return <></>;
                }
                return createPortal(<MessageLink key={idx} link={x} />, el);
            })}
        </Box>
    );
}

function modAllLinks(x: HTMLElement, navigate: NavigateFunction) {
    if (x.tagName === 'A' && x.getAttribute('href')?.startsWith(UrlBuilder.baseUrl)) {
        if (!(x as any).__modOnClick) {
            (x as any).__modOnClick = true;
            x.addEventListener('click', (e) => {
                const href = x.getAttribute('href');
                if (href && href.startsWith(UrlBuilder.origin)) {
                    e.preventDefault();
                    const relativeHref = href.substring(UrlBuilder.origin.length);
                    navigate(relativeHref);
                }
            });
        }
    }
    for (const child of x.children) {
        modAllLinks(child as HTMLElement, navigate);
    }
}

function getLinkId(msgId: string, link: LinkMetadata) {
    return `${msgId}-${link.start}-${link.end}`;
}
