import * as privmx from 'privfs-client';
import * as PmxApi from 'privmx-server-api';
import * as types from '../../types/Types';
import {
    AttachmentMeta,
    AttachmentUtils,
    DownloadOptions,
    ExistingAttachmentsProvider,
    PreparedFile
} from './AttachmentUtils';
import { DraftApi } from './DraftApi';
import { RequestApi } from './RequestApi';
import { DataEncryptor } from './DataEncryptor';
import { EncKey } from './KeyProvider';
import { Utils } from './utils/Utils';
import { TagService } from './TagService';
import { DataCacheInterface } from '../DataCacheInterface';
import { ModalsInterface } from '../ModalsInterface';

export interface Draft {
    raw: PmxApi.api.draft.Draft;
    data: DraftData;
    attachments: DraftAttachment[];
}

export interface DraftAttachment {
    hmac: string; // base64
    name: string;
    mimetype: types.Mimetype;
    size: number;
    hasThumb: boolean;
}

export interface DraftAttachmentWithId {
    id: PmxApi.api.attachment.AttachmentId;
    attachment: DraftAttachment;
}

export interface PmxAttachment {
    draftId: PmxApi.api.draft.DraftId;
    group: PmxApi.api.attachment.AttachmentGroup;
    tags: string[];
    attachmentId: PmxApi.api.attachment.AttachmentId;
    author: PmxApi.api.core.Username;
    date: PmxApi.api.core.TimestampN;
    meta: AttachmentMeta;
    hasThumb: boolean;
}

export interface PmxAttachmentEx extends PmxAttachment {
    createdDate: PmxApi.api.core.TimestampN;
    creator: PmxApi.api.core.Username;
    versions: number;
    contributors: PmxApi.api.core.Username[];
    modificationDates: PmxApi.api.core.TimestampN[];
}

interface ThreadDraftData extends Omit<types.ThreadDraft, 'id' | 'message'> {
    type: 'threadDraft';
    thread: ThreadCreateModelData;
    message: ThreadMessageCreateModelData;
}

interface ThreadMessageDraftData extends Omit<types.ThreadMessageDraft, 'id' | 'message'> {
    type: 'threadMessageDraft';
    threadId: types.ThreadId;
    message: ThreadMessageCreateModelData;
}

type DraftData = ThreadDraftData | ThreadMessageDraftData;

interface ThreadCreateModelData extends types.ThreadCreateModel {}

interface ThreadMessageCreateModelData extends Omit<types.ThreadMessageCreateModel, 'attachments'> {
    attachments: DraftAttachment[];
}

export class DraftService {
    public draftApi: DraftApi;
    private requestApi: RequestApi;
    private threadModelEncryptor = new DataEncryptor<ThreadCreateModelData, string>();
    private threadMessageModelEncryptor = new DataEncryptor<ThreadMessageCreateModelData, string>();
    private attachmentMetaEncryptor = new DataEncryptor<
        AttachmentMeta,
        PmxApi.api.attachment.AttachmentMeta
    >();
    private attachmentUtils: AttachmentUtils<DraftAttachment, EncKey>;

    constructor(
        private gateway: privmx.gateway.RpcGateway,
        private encKey: EncKey,
        private tagService: TagService,
        existingAttachmentsProvider: ExistingAttachmentsProvider,
        private cacheService: DataCacheInterface,
        private modalsService: ModalsInterface
    ) {
        this.draftApi = new DraftApi(this.gateway);
        this.requestApi = new RequestApi(this.gateway);
        this.attachmentUtils = new AttachmentUtils(
            this.requestApi,
            this.attachmentMetaEncryptor,
            (preparedFile, hasThumb) =>
                this.preparedFileToAttachmentConverter(preparedFile, hasThumb),
            existingAttachmentsProvider,
            this.cacheService,
            this.modalsService
        );
    }

    async getDrafts() {
        const rawDrafts = (await this.draftApi.getDrafts()).drafts;
        const drafts = await Promise.all(rawDrafts.map((x) => this.decryptDraft(x)));
        return drafts;
    }

    async getDraft(id: types.DraftId) {
        const rawDraft = (await this.draftApi.getDraft({ id: id })).draft;
        const draft = await this.decryptDraft(rawDraft);
        return draft;
    }

    async getThreadMessageDraft(threadId: types.ThreadId) {
        const rawDraft = (await this.draftApi.getThreadMessageDraft({ threadId: threadId })).draft;
        const draft = rawDraft ? await this.decryptDraft(rawDraft) : null;
        return draft;
    }

    async createDraft(props: types.DraftPropsForCreating) {
        const draftData = this.convertToDraftData(props, []);
        const data: PmxApi.api.draft.DraftData = await this.encryptDraftData(draftData);
        const rawDraft = (await this.draftApi.createDraft({ data: data })).draft;
        if (props.type === 'threadDraft') {
            await this.tagService.setTagsIfPrivateScope('draft', rawDraft.id, props.thread.tags);
        }
        const draft = await this.decryptDraft(rawDraft);
        return draft;
    }

    async updateDraft(draft: types.DraftWithAttachments, files: File[]) {
        const rawOldDraft = (await this.draftApi.getDraft({ id: draft.id })).draft;
        const oldAttachments = await Promise.all(
            rawOldDraft.attachments.map(async (att) => {
                const meta = await this.attachmentMetaEncryptor.decrypt(att.meta, this.encKey);
                return { att, meta };
            })
        );
        const theFiles = [
            ...files,
            ...draft.attachments.map((x) => {
                const att = oldAttachments.find((att) => att.att.id === x.id);
                if (!att) {
                    throw new Error('Invalid attachment id');
                }
                return { id: x.id, attachment: { ...att.meta, hasThumb: !!att.att.thumb } };
            })
        ];
        const attInfo = await this.prepareAttachmentsForUpdate(theFiles);
        const draftData: DraftData = this.convertToDraftData(draft, attInfo.attachments);
        const data: PmxApi.api.draft.DraftData = await this.encryptDraftData(draftData);
        const rawDraft = (
            await this.draftApi.updateDraft({
                id: draft.id,
                data: data,
                attachments: attInfo.request
            })
        ).draft;
        if (draft.type === 'threadDraft') {
            await this.tagService.setTagsIfPrivateScope('draft', rawDraft.id, draft.thread.tags);
        }
        const newDraft = await this.decryptDraft(rawDraft);
        return newDraft;
    }

    private convertToDraftData(
        draft: types.DraftPropsForCreating | types.Draft,
        attachments: DraftAttachment[]
    ) {
        let draftData: DraftData;
        const messageData: ThreadMessageCreateModelData = {
            messageThreadType: draft.message.messageThreadType,
            text: draft.message.text,
            mimetype: draft.message.mimetype,
            attachments: attachments
        };
        if (draft.type === 'threadDraft') {
            draftData = {
                type: 'threadDraft',
                thread: {
                    threadType: draft.thread.threadType,
                    title: draft.thread.title,
                    users: draft.thread.users,
                    managers: draft.thread.managers,
                    tags: TagService.TAG_SCOPE === 'shared' ? draft.thread.tags : [],
                    startDate: draft.thread.startDate,
                    duration: draft.thread.duration
                },
                message: messageData
            };
        } else {
            draftData = {
                type: 'threadMessageDraft',
                threadId: draft.threadId,
                message: messageData
            };
        }
        return draftData;
    }

    async deleteDraft(id: types.DraftId) {
        await this.draftApi.deleteDraft({ id: id });
    }

    async readAttachment(
        attachment: PmxApi.api.draft.DraftAttachment,
        meta: AttachmentMeta,
        thumb: boolean,
        options: DownloadOptions
    ) {
        const [currentMeta] = (() => {
            if (thumb) {
                if (!attachment.thumb || !meta.thumb) {
                    throw new Error('Attachment has no thumbnail');
                }
                return [meta.thumb, attachment.thumb.size];
            }
            return [meta, attachment.size];
        })();
        return AttachmentUtils.downloadAttachment(
            currentMeta,
            (range) =>
                this.draftApi.getDraftAttachmentData({
                    attachmentId: attachment.id,
                    range: range,
                    thumb: thumb
                }),
            options
        );
    }

    async decryptDraft(rawDraft: PmxApi.api.draft.Draft) {
        const attachments = await Promise.all(
            rawDraft.attachments.map(async (attachment) => {
                const meta = await this.attachmentMetaEncryptor.decrypt(
                    attachment.meta,
                    this.encKey
                );
                return {
                    hmac: meta.hmac,
                    name: meta.name,
                    size: meta.size,
                    mimetype: meta.mimetype,
                    hasThumb: !!meta.thumb
                };
            })
        );
        const draft: Draft = {
            raw: rawDraft,
            data: await this.decryptDraftData(rawDraft.data),
            attachments: attachments
        };

        return draft;
    }

    async getDraftAttachments(draftId: PmxApi.api.draft.DraftId) {
        const { attachments } = await this.draftApi.getDraftAttachments({ draftId: draftId });
        const groupMap = new Map<
            PmxApi.api.attachment.AttachmentGroup,
            PmxApi.api.draft.DraftAttachmentEx[]
        >();
        for (const attachment of attachments) {
            const list = groupMap.get(attachment.group);
            if (list) {
                list.push(attachment);
            } else {
                groupMap.set(attachment.group, [attachment]);
            }
        }
        return await Promise.all([...groupMap.values()].map((x) => this.decryptAttachmentGroup(x)));
    }

    async getAttachmentCore(attachmentId: PmxApi.api.attachment.AttachmentId) {
        const { attachment } = await this.draftApi.getDraftAttachment({ attachmentId });
        const meta = await this.attachmentMetaEncryptor.decrypt(attachment.meta, this.encKey);
        return {
            attachment: attachment,
            meta: meta
        };
    }

    private encryptDraftData(props: DraftData) {
        if (props.type === 'threadDraft') {
            return this.encryptThreadDraftData(props);
        }
        if (props.type === 'threadMessageDraft') {
            return this.encryptThreadMessageDraftData(props);
        }
        throw new Error('Unknown draft type');
    }

    private async encryptThreadDraftData(props: ThreadDraftData) {
        const res: PmxApi.api.draft.ThreadDraftData = {
            type: 'threadDraft',
            threadData: await this.encryptThreadDraftSecuredData(props.thread),
            messageData: await this.encryptMessageDraftSecuredData(props.message)
        };
        return res;
    }

    private async encryptThreadMessageDraftData(props: ThreadMessageDraftData) {
        const res: PmxApi.api.draft.ThreadMessageDraftData = {
            type: 'threadMessageDraft',
            threadId: props.threadId,
            messageData: await this.encryptMessageDraftSecuredData(props.message)
        };
        return res;
    }

    private async encryptThreadDraftSecuredData(threadModel: ThreadCreateModelData) {
        const res = (await this.threadModelEncryptor.encrypt(
            threadModel,
            this.encKey
        )) as PmxApi.api.draft.ThreadDraftSecuredData;
        return res;
    }

    private async encryptMessageDraftSecuredData(messageModel: ThreadMessageCreateModelData) {
        const res = (await this.threadMessageModelEncryptor.encrypt(
            messageModel,
            this.encKey
        )) as PmxApi.api.draft.MessageDraftSecuredData;
        return res;
    }

    private decryptDraftData(data: PmxApi.api.draft.DraftData) {
        if (data.type === 'threadDraft') {
            return this.decryptThreadDraftData(data);
        }
        if (data.type === 'threadMessageDraft') {
            return this.decryptThreadMessageDraftData(data);
        }
        throw new Error('Unknown draft type');
    }

    private async decryptThreadDraftData(props: PmxApi.api.draft.ThreadDraftData) {
        const res: ThreadDraftData = {
            type: 'threadDraft',
            thread: await this.decryptThreadDraftSecuredData(props.threadData),
            message: await this.decryptMessageDraftSecuredData(props.messageData)
        };
        return res;
    }

    private async decryptThreadMessageDraftData(props: PmxApi.api.draft.ThreadMessageDraftData) {
        const res: ThreadMessageDraftData = {
            type: 'threadMessageDraft',
            threadId: props.threadId as types.ThreadId,
            message: await this.decryptMessageDraftSecuredData(props.messageData)
        };
        return res;
    }

    private async decryptThreadDraftSecuredData(
        encryptedThreadModel: PmxApi.api.draft.ThreadDraftSecuredData
    ) {
        const res = await this.threadModelEncryptor.decrypt(encryptedThreadModel, this.encKey);
        return res;
    }

    private async decryptMessageDraftSecuredData(
        encryptedMessageModel: PmxApi.api.draft.MessageDraftSecuredData
    ) {
        const res = await this.threadMessageModelEncryptor.decrypt(
            encryptedMessageModel,
            this.encKey
        );
        return res;
    }

    private preparedFileToAttachmentConverter(
        preparedFile: PreparedFile,
        hasThumb: boolean
    ): DraftAttachment {
        const res: DraftAttachment = {
            hmac: preparedFile.hmac.toString('base64'),
            name: preparedFile.file.name,
            size: preparedFile.file.size,
            mimetype: preparedFile.file.type as types.Mimetype,
            hasThumb: hasThumb
        };
        return res;
    }

    private async convertAttachment(
        attachment: PmxApi.api.draft.DraftAttachmentEx,
        meta: AttachmentMeta
    ) {
        const tags = await this.tagService.getTags(
            'draftAttachment',
            attachment.id,
            'all',
            attachment
        );
        const res: PmxAttachment = {
            draftId: attachment.draftId,
            group: attachment.group,
            tags: tags,
            attachmentId: attachment.id,
            author: attachment.author,
            date: attachment.created,
            meta: meta,
            hasThumb: !!attachment.thumb
        };
        return res;
    }

    private async decryptAttachmentGroup(attachments: PmxApi.api.draft.DraftAttachmentEx[]) {
        return Utils.tryPromise(() => this.decryptAttachmentGroupCore(attachments));
    }

    private async decryptAttachmentGroupCore(
        attachments: PmxApi.api.draft.DraftAttachmentEx[]
    ): Promise<PmxAttachmentEx> {
        const first = attachments.reduce((a, b) => (a.created < b.created ? a : b));
        const attachment = attachments.reduce((a, b) => (a.created > b.created ? a : b));
        const meta = await this.attachmentMetaEncryptor.decrypt(attachment.meta, this.encKey);
        const tags = await this.tagService.getTags(
            'draftAttachment',
            attachment.id,
            'all',
            attachment
        );
        return {
            draftId: attachment.draftId,
            group: attachment.group,
            tags: tags,
            versions: attachments.length,
            contributors: [...new Set(attachments.map((x) => x.author))],
            modificationDates: [...new Set(attachments.map((x) => x.created))],
            attachmentId: attachment.id,
            author: attachment.author,
            date: attachment.created,
            meta: meta,
            createdDate: first.created,
            creator: first.author,
            hasThumb: !!attachment.thumb
        };
    }

    private async prepareAttachmentsForUpdate(files: (File | DraftAttachmentWithId)[]) {
        if (files.length === 0) {
            return { attachments: [], request: null };
        }
        const attsRes = await this.attachmentUtils.prepareAttachments(
            this.encKey,
            this.encKey.key,
            null,
            files.filter((x) => x instanceof File) as File[]
        );
        const reqList = attsRes.request ? attsRes.request.list : [];
        const attachments: DraftAttachment[] = [];
        const request: PmxApi.api.attachment.UpdatedAttachments = {
            requestId: attsRes.request ? attsRes.request.requestId : null,
            list: []
        };
        let attIndex = 0;
        for (const file of files) {
            if (file instanceof File) {
                attachments.push(attsRes.attachments[attIndex]);
                request.list.push(reqList[attIndex]);
                attIndex++;
            } else {
                attachments.push(file.attachment);
                request.list.push(file.id);
            }
        }
        return { attachments, request };
    }
}
