import { Utils } from '../Utils';

export type ModalMode = 'std' | 'mac';
export const modalModes: ModalMode[] = ['std', 'mac'];
const localStorageModalModeKey = 'modalMode';

export function isModalMode(modalMode: string): modalMode is ModalMode {
    return modalModes.includes(modalMode as ModalMode);
}

export function getModalModeFromLocalStorage() {
    let value = localStorage.getItem(localStorageModalModeKey);
    if (value === null) {
        const os = Utils.getOperatingSystem();
        if (os === 'Mac') {
            value = 'mac';
        } else {
            value = 'std';
        }
    }
    return value as ModalMode;
}
export function setModalModeInLocalStorage(modalMode: ModalMode) {
    localStorage.setItem(localStorageModalModeKey, modalMode);
}
