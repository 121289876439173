import { CompanyApi } from './CompanyApi';
import * as types from '../../types/Types';
import * as PmxApi from 'privmx-server-api';
import { DataEncryptor } from './DataEncryptor';
import { EncKey } from './KeyProvider';
import { TagService } from './TagService';

export interface CompanyProps {
    name: types.CompanyName;
    email: types.Email;
    website: types.CompanyWebsite;
    address: types.CompanyAddress;
    phone: types.Phone;
    mobilePhone: types.MobilePhone;
    note: types.CompanyNote;
}

export interface PmxCompany {
    props: CompanyProps;
    raw: PmxApi.api.company.Company;
    tags: types.Tag[];
}

export class CompanyService {
    constructor(
        private companyApi: CompanyApi,
        private encKey: EncKey,
        private propsEncryptor: DataEncryptor<CompanyProps, PmxApi.api.company.CompanyProps>,
        private tagService: TagService
    ) {}

    async getCompany(id: PmxApi.api.company.CompanyId) {
        const { company } = await this.companyApi.getCompany({ id });
        return this.decryptCompany(company);
    }

    async getCompanies() {
        const { companies } = await this.companyApi.getCompanies();
        return Promise.all(companies.map((x) => this.decryptCompany(x)));
    }

    async createCompany(props: CompanyProps, tags: string[]) {
        const { company } = await this.companyApi.createCompany({
            props: await this.encryptCompanyProps(props),
            tags: await this.tagService.getEncryptedTagsIfSharedScope(tags as types.Tag[])
        });
        await this.tagService.setTagsIfPrivateScope('company', company.id, tags as types.Tag[]);
        return this.decryptCompany(company);
    }

    async updateCompany(id: PmxApi.api.company.CompanyId, props: CompanyProps, tags: string[]) {
        const { company } = await this.companyApi.updateCompany({
            id,
            props: await this.encryptCompanyProps(props),
            tags: await this.tagService.setTagsOrGetEncrypted('company', id, tags as types.Tag[])
        });
        return this.decryptCompany(company);
    }

    async deleteCompany(id: PmxApi.api.company.CompanyId, unpinAllClients: boolean) {
        await this.companyApi.deleteCompany({ id, unpinAllClients });
    }

    async decryptCompany(company: PmxApi.api.company.Company) {
        const tags = await this.tagService.getTags('company', company.id, 'all', company);
        const res: PmxCompany = {
            props: await this.decryptCompanyProps(company.props),
            raw: company,
            tags: tags
        };
        return res;
    }

    async toggleCompanyTag(companyId: PmxApi.api.company.CompanyId, tag: string, enabled: boolean) {
        const company = await this.getCompany(companyId);
        const newTags = await this.tagService.toggleTag(
            'company',
            companyId,
            tag as types.Tag,
            company,
            enabled
        );
        const newCompany = await this.updateCompany(companyId, company.props, newTags);
        return newCompany;
    }

    private async encryptCompanyProps(props: CompanyProps) {
        return this.propsEncryptor.encrypt(props, this.encKey);
    }

    private async decryptCompanyProps(props: PmxApi.api.company.CompanyProps) {
        return this.propsEncryptor.decrypt(props, this.encKey);
    }
}
