import Modal from '../../atoms/Modal';
import StateView from '../../atoms/State';
import { BaseModalData } from '../../types/Modals';
import { CompanyId } from '../../types/Types';
import { useCallback } from 'react';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { CompanyDescription } from './CompanyDescription';

export interface CompanyModalData extends BaseModalData {
    id: CompanyId;
}

export function CompanyModal(props: {
    onClose: () => void;
    data: CompanyModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForCompany(props.data.id), [props.data.id])
    );
    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            height={props.data.height ?? 800}
            width={props.data.width}
            styles={{ content: { padding: 0 } }}
            resizable
            title={
                props.data.title ??
                (state.type === 'done' && state.data.company.name
                    ? t('modal.company.titleWithName', { name: state.data.company.name })
                    : t('modal.company.titleWithoutName'))
            }>
            <StateView state={state} retry={revalidate}>
                {(data) => (
                    <Box sx={{ flexGrow: 1 }}>
                        <CompanyDescription company={data.company} />
                    </Box>
                )}
            </StateView>
        </Modal>
    );
}
