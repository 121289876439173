import React, { useEffect } from 'react';

export type ResizerArea =
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right';
export type DragMoveResizeArea = ResizerArea | 'move';
export type ResizerHorizontalPosition = 'stretch' | 'left' | 'right';
export type ResizerVerticalPosition = 'stretch' | 'top' | 'bottom';

export function useDragMoveResize(
    area: DragMoveResizeArea,
    draggableRef: React.MutableRefObject<HTMLElement | null>,
    getMinMax: () => {
        minX: number;
        maxX: number;
        minY: number;
        maxY: number;
        minW: number;
        maxW: number;
        minH: number;
        maxH: number;
    },
    getCurrentRect: () => { x: number; y: number; w: number; h: number },
    onChangeRect: (x: number, y: number, w: number, h: number) => void,
    withExtraDelHor: boolean = true
) {
    useEffect(() => {
        if (!draggableRef.current) {
            return;
        }
        const resizerElement = draggableRef.current;

        const { x: multX, y: multY, w: multW, h: multH } = getAreaMultipliers(area);
        let dragStartPageX = 0;
        let dragStartPageY = 0;
        let initialX = 0;
        let initialY = 0;
        let initialW = 0;
        let initialH = 0;
        let constraints = {
            minX: 0,
            minY: 0,
            maxX: 0,
            maxY: 0,
            minW: 0,
            maxW: 0,
            minH: 0,
            maxH: 0
        };
        const mouseDownHandler = (event: MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            window.addEventListener('mouseup', mouseUpHandler);
            window.addEventListener('mousemove', mouseMoveHandler);
            dragStartPageX = event.pageX;
            dragStartPageY = event.pageY;
            const initialPos = getCurrentRect();
            initialX = initialPos.x;
            initialY = initialPos.y;
            initialW = initialPos.w;
            initialH = initialPos.h;
            constraints = getMinMax();
        };
        const mouseUpHandler = () => {
            window.removeEventListener('mouseup', mouseUpHandler);
            window.removeEventListener('mousemove', mouseMoveHandler);
        };
        const mouseMoveHandler = (event: MouseEvent) => {
            const deltaX = event.pageX - dragStartPageX;
            const deltaY = event.pageY - dragStartPageY;
            const prelimX = Math.min(
                constraints.maxX,
                Math.max(constraints.minX, initialX + deltaX * multX)
            );
            const prelimY = Math.min(
                constraints.maxY,
                Math.max(constraints.minY, initialY + deltaY * multY)
            );
            const prelimW = Math.min(
                constraints.maxW,
                Math.max(constraints.minW, initialW + deltaX * multW)
            );
            const prelimH = Math.min(
                constraints.maxH,
                Math.max(constraints.minH, initialH + deltaY * multH)
            );

            const effDeltaX = multX === 0 ? null : (prelimX - initialX) / multX;
            const effDeltaY = multY === 0 ? null : (prelimY - initialY) / multY;
            const effDeltaW = multW === 0 ? null : (prelimW - initialW) / multW;
            const effDeltaH = multH === 0 ? null : (prelimH - initialH) / multH;
            const effDeltaHor = (deltaX > 0 ? min : max)(effDeltaX, effDeltaW, deltaX);
            const effDeltaVer = (deltaY > 0 ? min : max)(effDeltaY, effDeltaH, deltaY);

            const extraDelHor = withExtraDelHor
                ? Math.max(
                      0,
                      100 - (initialX + effDeltaHor * multX + initialW + effDeltaHor * multW)
                  )
                : 0;
            const finalDeltaHor = effDeltaHor + extraDelHor;
            const finalDeltaVer = effDeltaVer;

            const x = initialX + finalDeltaHor * multX;
            const y = initialY + finalDeltaVer * multY;
            const w = initialW + finalDeltaHor * multW;
            const h = initialH + finalDeltaVer * multH;

            onChangeRect(x, y, w, h);
        };

        resizerElement.addEventListener('mousedown', mouseDownHandler);
        return () => {
            if (resizerElement) {
                resizerElement.removeEventListener('mousedown', mouseDownHandler);
            }
            window.removeEventListener('mouseup', mouseUpHandler);
            window.removeEventListener('mousemove', mouseMoveHandler);
        };
    }, [area, draggableRef, getCurrentRect, getMinMax, onChangeRect, withExtraDelHor]);

    return {};
}

function min(...numbers: (number | null)[]): number {
    return Math.min(...(numbers.filter((x) => x !== null) as number[]));
}

function max(...numbers: (number | null)[]): number {
    return Math.max(...(numbers.filter((x) => x !== null) as number[]));
}

export function getCursorByArea(area: ResizerArea): string {
    if (area === 'top' || area === 'bottom') {
        return 'ns-resize';
    }
    if (area === 'left' || area === 'right') {
        return 'ew-resize';
    }
    if (area === 'top-left' || area === 'bottom-right') {
        return 'nwse-resize';
    }
    if (area === 'top-right' || area === 'bottom-left') {
        return 'nesw-resize';
    }
    return 'default';
}

export function getResizerHorizontalPosition(area: ResizerArea): ResizerHorizontalPosition {
    if (area === 'top' || area === 'bottom') {
        return 'stretch';
    }
    if (area.includes('left')) {
        return 'left';
    }
    if (area.includes('right')) {
        return 'right';
    }
    return 'stretch';
}

export function getResizerVerticalPosition(area: ResizerArea): ResizerVerticalPosition {
    if (area === 'left' || area === 'right') {
        return 'stretch';
    }
    if (area.includes('top')) {
        return 'top';
    }
    if (area.includes('bottom')) {
        return 'bottom';
    }
    return 'stretch';
}

export function getAreaMultipliers(area: DragMoveResizeArea) {
    if (area === 'bottom') {
        return { x: 0, y: 0, w: 0, h: 1 };
    } else if (area === 'bottom-left') {
        return { x: 1, y: 0, w: -1, h: 1 };
    } else if (area === 'bottom-right') {
        return { x: 0, y: 0, w: 1, h: 1 };
    } else if (area === 'left') {
        return { x: 1, y: 0, w: -1, h: 0 };
    } else if (area === 'move') {
        return { x: 1, y: 1, w: 0, h: 0 };
    } else if (area === 'right') {
        return { x: 0, y: 0, w: 1, h: 0 };
    } else if (area === 'top') {
        return { x: 0, y: 1, w: 0, h: -1 };
    } else if (area === 'top-left') {
        return { x: 1, y: 1, w: -1, h: -1 };
    } else if (area === 'top-right') {
        return { x: 0, y: 1, w: 1, h: -1 };
    } else {
        return { x: 0, y: 0, w: 0, h: 0 };
    }
}
