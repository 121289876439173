import {
    Dispatch,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import {
    Accordion,
    AccordionStylesNames,
    AccordionStylesParams,
    ActionIcon,
    Anchor,
    Box,
    Button,
    Flex,
    Group,
    HoverCard,
    Indicator,
    Input,
    MantineTheme,
    Paper,
    rem,
    ScrollArea,
    Styles,
    Text
} from '@mantine/core';
import { api, chatApi } from '../../api/Api';
import ConfirmModal from '../../atoms/ConfirmModal';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectCurrentUser } from '../../store/CurrentUserSlice';
import { getChatFromData } from '../ChatEditPage';
import ModalForm from '../ModalForm';
import { DraftUtils } from '../../utils/DraftUtils';
import {
    InputPreference,
    InputPreferenceTypes,
    selectFileConfig
} from '../../store/UserPreferenceSlice';
import { usePreventClose } from '../../hooks/usePreventClose';
import { FileBadge } from '../../atoms/FileBadge';
import Icon, { iconAttach } from '../Icon';
import { CreationMessageAction } from './ChatAddModal';
import { UserSelectOptionType } from '../UsersSelector';
import { ChatId } from '../../types/Types';
import * as types from '../../types/Types';
import { ChatAddLoaderData } from '../../utils/router/loaders';
import { Deferred } from '../../utils/Deferred';
import { useTranslation } from 'react-i18next';
import { loadContacsAsync, selectUsernameToName } from '../../store/DataCacheSlice';
import { OverflowList } from 'react-overflow-list';
import { IconDots, IconX } from '@tabler/icons-react';
import { FileWithPath } from '@mantine/dropzone';
import { addDraft } from '../../store/DraftSlice';
import { UserUtils } from '../../utils/UserUtils';
import { useUserInteraction } from '../../hooks/useUserInteraction';
import { Link } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { Validator } from '../../utils/Validator';
import { FileChooser } from '../../utils/FileChooser';

export interface NewThreadFormData {
    title: string;
    users: UserSelectOptionType[];
    managers: UserSelectOptionType[];
    messageText: string;
    tags: types.TagOption[];
}

const getMessageFromData = (data: NewThreadFormData) => {
    const message: types.MessageCreateModel = {
        text: data.messageText,
        mimetype: 'text/markdown'
    };
    return message;
};

const getMessageFromDataAtt = (
    data: NewThreadFormData,
    attachments: (types.PreparedAttachment | types.DraftAttachmentEx)[],
    mimetype: InputPreferenceTypes
) => {
    const message: types.MessageCreateModelAtt = {
        text: data.messageText,
        mimetype: InputPreference[mimetype],
        attachments: attachments
    };
    return message;
};

const accordionStyle: Styles<AccordionStylesNames, AccordionStylesParams> = (
    theme: MantineTheme
) => ({
    label: { padding: 0, overflow: 'visible' },
    item: {
        '.attachments-group': {
            flexGrow: 1,
            alignItems: 'center',
            overflow: 'visible'
        },
        '&[data-active]': {
            color: 'red',
            '& .attachments-group': {
                visibility: 'hidden'
            }
        }
    },
    content: {
        paddingBottom: `calc(${theme.spacing.sm} / 2)`
    }
});

export const ChatAddPage = forwardRef(
    (
        props: {
            initialFormData?: NewThreadFormData;
            chatAdd: ChatAddLoaderData;
            handleClose: () => void;
            initialUsers?: types.UsernameOrContactId[];
            dispatchCreationAction: Dispatch<CreationMessageAction>;
            initialMessage?: string;
        },
        ref
    ) => {
        const { t } = useTranslation();
        const draft =
            props.chatAdd.chatDraft && typeof props.chatAdd.chatDraft === 'object'
                ? props.chatAdd.chatDraft
                : null;
        const [confirmModalDeferred, setConfirmModalDeferred] = useState<
            Deferred<boolean> | undefined
        >(undefined);

        const [attachments, setAttachments] = useState<
            (types.PreparedAttachment | types.DraftAttachmentEx)[]
        >(props.chatAdd.chatDraft?.attachments ?? []);
        const currentUser = useAppSelector(selectCurrentUser);
        const dispatch = useAppDispatch();

        const initialUsernamesOrIds = useMemo(() => {
            if (props.initialFormData) {
                return props.initialFormData.users.map((x) =>
                    x.type === 'contact'
                        ? { type: 'contact', contactId: x.value }
                        : { type: 'user', username: x.value }
                ) as types.UsernameOrContactId[];
            } else if (props.initialUsers) {
                return [...props.initialUsers];
            }
            return (
                draft?.thread.users ??
                (currentUser.username
                    ? [{ type: 'user', username: currentUser.username } as types.UsernameOpt]
                    : [])
            );
        }, [draft, currentUser, props.initialUsers, props.initialFormData]);
        const displayMap = useAppSelector(selectUsernameToName);

        const managers = useMemo(
            () => draft?.thread.managers || (currentUser.username ? [currentUser.username] : []),
            [draft, currentUser]
        );

        const form = useForm<NewThreadFormData>({
            defaultValues: props.initialFormData
                ? {
                      ...props.initialFormData,
                      users: props.initialFormData?.users.map((x) => ({ ...x, disabled: false })),
                      messageText: props.initialMessage
                  }
                : {
                      ...props.chatAdd,
                      messageText: props.initialMessage,
                      tags: [],
                      managers: managers.map((x) => ({
                          label: x,
                          value: x,
                          type: 'staff',
                          email: (
                              props.chatAdd.users.find(
                                  (u) => u.type === 'user' && u.user.username === x
                              ) as types.UserOpt | undefined
                          )?.user.email
                      })),
                      users: UserUtils.usernameOrIdToOption(
                          initialUsernamesOrIds.filter((x) =>
                              x.type === 'user' && x.username === currentUser.username
                                  ? false
                                  : true
                          ),
                          props.chatAdd.users,
                          displayMap
                      )
                  }
        });
        const isDirty = form.formState.isDirty;
        const isSubmitted = useRef<boolean>(false);
        const setError = form.setError;
        const getValues = form.getValues;

        usePreventClose(isDirty);

        useEffect(() => {
            const submited = isSubmitted;
            return () => {
                if (!isDirty || submited.current) return;
                const formData = form.getValues();
                dispatch(
                    addDraft({
                        members: formData.users.map((x) => ({ label: x?.label, type: x?.type })),
                        title: formData.title,
                        type: 'chat',
                        formSnapshot: {
                            ...formData,
                            users: formData.users.filter((x) => !!x),
                            managers: formData.managers.filter((x) => !!x)
                        }
                    })
                );
            };
        }, [dispatch, form, isDirty, isSubmitted]);

        const handleClose = props.handleClose;

        useImperativeHandle(ref, () => ({
            beforeClose() {
                if (!isDirty) {
                    return true;
                }
                const deferred = new Deferred<boolean>();
                setConfirmModalDeferred(deferred);
                return deferred.promise;
            }
        }));

        const dispatchCreationAction = props.dispatchCreationAction;

        const { startInteraction, isProcessing } = useUserInteraction({
            successTitle: t('modal.topicAdd.interaction.successTitle'),
            emitSuccessToast: false,
            errorTitle: t('modal.topicAdd.interaction.errorTitle'),
            errorMessage: t('modal.topicAdd.interaction.errorMessage'),
            async action(data: NewThreadFormData) {
                const newChat = getChatFromData(data, '' as ChatId);
                newChat.users.push({
                    type: 'user',
                    username: currentUser?.username || ('' as any)
                });
                let message: types.MessageCreateModelAtt;
                const msgId = chatApi.generateMessageId();

                if (
                    (data.messageText === '' ||
                        data.messageText === '<p></p>' ||
                        data.messageText === undefined) &&
                    attachments.length === 0
                ) {
                    const content = {
                        type: 'firstMessage'
                    };
                    message = {
                        attachments: [],
                        mimetype: 'application/json',
                        text: JSON.stringify(content)
                    };
                } else {
                    message = getMessageFromDataAtt(data, attachments, 'html');
                }
                const {
                    newChat: serverChat,
                    newAccounts,
                    privateContactsMadeVisible
                } = await api.addChat(newChat, msgId, message);
                isSubmitted.current = true;
                if (newAccounts.size > 0 || privateContactsMadeVisible.length > 0) {
                    dispatchCreationAction({
                        type: 'setState',
                        payload: 'created'
                    });
                    dispatchCreationAction({
                        type: 'setPayload',
                        payload: {
                            createdUsers: newAccounts,
                            privateContactsMadeVisible,
                            title: serverChat.title,
                            id: serverChat.id
                        }
                    });
                    dispatch(loadContacsAsync);
                } else {
                    props.handleClose();
                }
                notifications.show({
                    id: 'chat-added',
                    withCloseButton: true,
                    autoClose: 8000,
                    title: t('modal.topicAdd.interaction.successTitle'),
                    message: (
                        <Anchor component={Link} to={`/chat/${serverChat.id}`}>
                            Go to thread
                        </Anchor>
                    )
                });
                if (props.chatAdd.chatDraft?.id) {
                    await api.deleteDraft(props.chatAdd.chatDraft.id);
                }
            }
        });

        const handleFormSubmit = useCallback(
            async (data: NewThreadFormData) => {
                let hasError: boolean = false;
                if (data.title.trim() === '') {
                    setError('title', { type: 'required' });
                    hasError = true;
                }
                const titleErrors = Validator.getErrors(data.title);
                if (titleErrors) {
                    setError('title', {
                        type: 'value',
                        message: t('modal.topicAdd.incorectTitle', {
                            characters: Validator.getSafeDefaultChars()
                        })
                    });
                    hasError = true;
                }
                if (data.users.length === 0) {
                    hasError = true;
                    setError('users', {
                        type: 'required',
                        message: t('modal.topicAdd.error.usersRequired')
                    });
                } else {
                    let wrongUser: string = '';
                    for (const user of data.users) {
                        wrongUser = user.value;
                        const userErrors = Validator.getErrors(user.value, [
                            'safeDefault',
                            'username'
                        ]);
                        if (userErrors) {
                            setError('users', {
                                type: 'value',
                                message: `Incorect user: ${wrongUser}`
                            });
                            hasError = true;
                            break;
                        }
                    }
                }

                if (hasError) return;
                startInteraction(data);
            },
            [startInteraction, setError, t]
        );

        const handleSaveDraftClick = useCallback(async () => {
            const data = getValues();
            const existingAttachments = attachments.filter(
                (x) => 'draftId' in x
            ) as types.DraftAttachmentEx[];
            const newAttachments = attachments.filter(
                (x) => !('draftId' in x)
            ) as types.PreparedAttachment[];
            const chatData = DraftUtils.getThreadCreateModel(
                'chat',
                getChatFromData(data, '' as types.ChatId)
            );
            const message = DraftUtils.getThreadMessageCreateModel(
                'chat',
                getMessageFromData(data)
            );
            if (props.chatAdd.chatDraft?.id) {
                const updatedDraft = DraftUtils.getThreadDraftWithAttachments(
                    props.chatAdd.chatDraft.id,
                    chatData,
                    message,
                    existingAttachments
                );
                await api.updateDraft(
                    updatedDraft,
                    newAttachments.map((x) => x.file)
                );
            } else {
                const newDraft = DraftUtils.getThreadDraftPropsForCreating(chatData, message);
                const createdDraft = await api.createDraft(newDraft);
                if (newAttachments.length > 0 && createdDraft) {
                    const updatedDraft = DraftUtils.getThreadDraftWithAttachments(
                        createdDraft.id,
                        chatData,
                        message,
                        existingAttachments
                    );
                    await api.updateDraft(
                        updatedDraft,
                        newAttachments.map((x) => x.file)
                    );
                }
            }
            handleClose();
        }, [getValues, props.chatAdd.chatDraft?.id, handleClose, attachments]);

        const fileConfig = useAppSelector(selectFileConfig);

        const handleAttachFilesClick = useCallback(
            async (droppedFiles?: FileWithPath[]) => {
                try {
                    const newAttachments = droppedFiles
                        ? droppedFiles.map((file) => api.prepareAttachment(file))
                        : await api.chooseFilesAsAttachments();
                    FileChooser.checkFilesConfig(newAttachments, fileConfig);

                    if (newAttachments.length > 0) {
                        setAttachments((attachments) => {
                            const uniqeAttachments = [...attachments];
                            newAttachments.forEach((attach) => {
                                const isUniqe =
                                    uniqeAttachments.findIndex((x) => x.name === attach.name) ===
                                    -1;
                                if (isUniqe) {
                                    uniqeAttachments.push(attach);
                                } else {
                                    form.setError('messageText', {
                                        type: 'pattern',
                                        message: t('modal.topicAdd.duplicateFilesSkiped')
                                    });
                                }
                            });
                            return uniqeAttachments;
                        });
                    }
                } catch (error: any) {
                    if ('message' in error) {
                        setError('messageText', { type: 'value', message: error.message });
                    }
                }
            },
            [setAttachments, form, fileConfig, setError, t]
        );

        const handleDeleteAttachmentClick = useCallback(
            (attachmentId: string) => {
                setAttachments((prev) => {
                    api.deleteAttachment(attachmentId as types.AttachmentId);
                    return prev.filter((attachment) => attachment.id !== attachmentId);
                });
            },
            [setAttachments]
        );

        const handleConfirmModalSaveDraftClick = useCallback(() => {
            confirmModalDeferred?.resolve(false);
            setConfirmModalDeferred(undefined);
            handleSaveDraftClick();
        }, [handleSaveDraftClick, confirmModalDeferred, setConfirmModalDeferred]);

        const handleConfirmModalCancelClick = useCallback(() => {
            confirmModalDeferred?.resolve(false);
            setConfirmModalDeferred(undefined);
        }, [confirmModalDeferred, setConfirmModalDeferred]);

        const handleConfirmModalRejectChangesClick = useCallback(() => {
            confirmModalDeferred?.resolve(true);
            setConfirmModalDeferred(undefined);
        }, [confirmModalDeferred, setConfirmModalDeferred]);

        const contacts = useMemo(() => {
            return (
                props.chatAdd.users
                    ?.filter((user) => user.type === 'contact')
                    .map((user) => (user as types.ContactOpt).contact) || []
            );
        }, [props.chatAdd.users]);

        return (
            <ModalForm
                styles={{
                    root: {
                        width: '100%',
                        flexGrow: 0
                    },
                    messageInput: {
                        width: '100%',
                        flexGrow: 1
                    }
                }}
                onSubmit={handleFormSubmit}
                form={form}
                isProcesing={isProcessing}
                loadingMessage={t('modal.topicAdd.loadingMessages')}
                buttons={null}
                onClose={handleClose}>
                <ModalForm.Field
                    sx={(theme) => ({
                        borderBottom: `${rem(1)} solid ${
                            theme.colorScheme === 'dark'
                                ? theme.colors.dark[4]
                                : theme.colors.gray[3]
                        }`
                    })}
                    span={2}>
                    <Input.Label mr="sm">{t('entityProps.from')}</Input.Label>
                    <Text span color="dimmed">
                        {currentUser.name} {currentUser.email ? `<${currentUser.email}>` : ''}
                    </Text>
                </ModalForm.Field>

                <ModalForm.UserSelectField
                    placeholder="Contact or mail@example.com"
                    span={2}
                    name="users"
                    label={t('entityProps.to')}
                    contacts={contacts}
                />

                <ModalForm.TextField
                    placeholder="Topic title"
                    required
                    name="title"
                    span={2}
                    label={t('entityProps.title')}
                    registerOptions={{
                        maxLength: {
                            message: t('modal.topicAdd.error.maxLengthTitle', {
                                count: Validator.constraints['shorttext'].maxLength
                            }),
                            value: Validator.constraints['shorttext'].maxLength
                        }
                    }}
                />

                <ModalForm.MessageFormField
                    onFileDrop={handleAttachFilesClick}
                    grow
                    name="messageText"
                    label={t('entityProps.message')}
                    defaultValue={draft?.message?.text || ''}
                    registerOptions={{
                        maxLength: {
                            message: t('modal.topicAdd.error.maxLengthMessage', {
                                count: Validator.constraints['richtext'].maxLength
                            }),
                            value: Validator.constraints['richtext'].maxLength
                        }
                    }}>
                    <Paper mt="sm">
                        <Flex gap="sm" align="start" justify="center">
                            <Button
                                tabIndex={1}
                                component="div"
                                type="button"
                                variant="light"
                                onClick={(e) => {
                                    handleAttachFilesClick();
                                    e.stopPropagation();
                                }}
                                disabled={false}
                                styles={(theme) => ({
                                    label: {
                                        [`@media (max-width:${theme.breakpoints.md})`]: {
                                            display: 'none'
                                        }
                                    },
                                    leftIcon: {
                                        [`@media (max-width:${theme.breakpoints.md})`]: {
                                            margin: 0
                                        }
                                    },
                                    root: {
                                        [`@media (max-width:${theme.breakpoints.md})`]: {
                                            paddingInline: theme.spacing.sm
                                        }
                                    }
                                })}
                                leftIcon={<Icon icon={iconAttach} />}>
                                {t('action.attachFiles')}
                            </Button>
                            <Accordion
                                chevron={attachments.length === 0 ? <></> : undefined}
                                styles={accordionStyle}
                                sx={{ flexGrow: 1, placeSelf: 'center' }}
                                variant="filled">
                                <Accordion.Item p={0} value="items">
                                    <Accordion.Control
                                        disabled={attachments.length === 0}
                                        icon={<></>}
                                        p={0}>
                                        <Group noWrap>
                                            <Group
                                                w={{ base: 200, md: 500 }}
                                                spacing={'xs'}
                                                sx={{ flexGrow: 1 }}>
                                                <AttachmentsList
                                                    attachments={attachments}
                                                    onClick={handleDeleteAttachmentClick}
                                                />
                                            </Group>
                                        </Group>
                                    </Accordion.Control>
                                    <Accordion.Panel>
                                        <ScrollArea.Autosize mah={150}>
                                            <Group sx={{ flexGrow: 1 }}>
                                                {attachments.map((att, i) => (
                                                    <FileBadge
                                                        mimetype={att.contentType}
                                                        key={att.id + i}
                                                        attachmentId={att.id}
                                                        name={att.name}
                                                        onDelete={() =>
                                                            handleDeleteAttachmentClick(att.id)
                                                        }
                                                    />
                                                ))}
                                            </Group>
                                        </ScrollArea.Autosize>
                                    </Accordion.Panel>
                                </Accordion.Item>
                            </Accordion>
                            <Button type="submit" tabIndex={1}>
                                {t('action.send')}
                            </Button>
                        </Flex>
                        <Text size="xs" color="dimmed">
                            {t('modal.topicAdd.maxNumberOfAttachments', {
                                maxFilesCount: fileConfig.maxFilesCount
                            })}
                        </Text>
                    </Paper>
                </ModalForm.MessageFormField>
                <ConfirmModal
                    opened={!!confirmModalDeferred}
                    onClose={handleConfirmModalCancelClick}
                    title={t('warningMessage.unsavedChangesWhatToDo')}>
                    <Button type="button" onClick={handleConfirmModalSaveDraftClick}>
                        {t('action.saveDraft')}
                    </Button>
                    <Button type="button" onClick={handleConfirmModalCancelClick}>
                        {t('action.cancel')}
                    </Button>
                    <Button type="button" onClick={handleConfirmModalRejectChangesClick}>
                        {t('action.rejectChanges')}
                    </Button>
                </ConfirmModal>
            </ModalForm>
        );
    }
);

export default ChatAddPage;

export function AttachmentsList<
    T extends { id: string; name: string; contentType: types.Mimetype }
>({ attachments, onClick }: { attachments: T[]; onClick: (id: string) => void }) {
    return (
        <OverflowList
            className="attachments-group"
            collapseFrom="end"
            minVisibleItems={0}
            items={attachments}
            itemRenderer={(item, index) => (
                <FileBadge
                    mimetype={item.contentType}
                    attachmentId={item.id}
                    name={item.name}
                    sx={{
                        textOverflow: 'unset',
                        overflow: 'visible'
                    }}
                    mr="sm"
                    key={index}
                    onDelete={() => onClick(item.id)}
                />
            )}
            overflowRenderer={(items) => (
                <HoverCard keepMounted position="top-end">
                    <HoverCard.Target>
                        <Box>
                            <Indicator size={'sm'} color="gray" label={items.length}>
                                <ActionIcon ml="auto" onClick={(e: any) => e.stopPropagation()}>
                                    <IconDots size={rem(16)} />
                                </ActionIcon>
                            </Indicator>
                        </Box>
                    </HoverCard.Target>
                    <HoverCard.Dropdown p={4}>
                        <ScrollArea.Autosize mah={200}>
                            {items.map((x) => (
                                <Group
                                    key={x.id}
                                    spacing={2}
                                    p={8}
                                    py={4}
                                    sx={(theme) => ({
                                        '&:hover': {
                                            backgroundColor:
                                                theme.colorScheme === 'dark'
                                                    ? theme.colors.dark[8]
                                                    : theme.colors.gray[0]
                                        }
                                    })}
                                    position="apart"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onClick(x.id);
                                    }}>
                                    <Text truncate maw={300} size={'sm'}>
                                        {x.name}
                                    </Text>
                                    <ActionIcon>
                                        <IconX size={rem(14)} />
                                    </ActionIcon>
                                </Group>
                            ))}
                        </ScrollArea.Autosize>
                    </HoverCard.Dropdown>
                </HoverCard>
            )}
        />
    );
}
