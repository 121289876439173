import * as webauthn from 'webauthn-js';
import { BaseApi } from './BaseApi';

export interface GoogleAuthenticatorTwofaEnableData {
    type: 'googleAuthenticator';
    googleAuthenticatorKey: string;
}

export interface EmailTwofaEnableData {
    type: 'email';
    email: string;
}

export interface SmsTwofaEnableData {
    type: 'sms';
    mobile: string;
}

export interface U2fTwofaEnableData {
    type: 'u2f';
}

export type TwofaEnableData =
    | GoogleAuthenticatorTwofaEnableData
    | EmailTwofaEnableData
    | SmsTwofaEnableData
    | U2fTwofaEnableData;

interface EnabledState {
    enabled: boolean;
}

export type GoogleAuthenticatorTwofaData = GoogleAuthenticatorTwofaEnableData & EnabledState;
export type EmailTwofaData = EmailTwofaEnableData & EnabledState;
export type SmsTwofaData = SmsTwofaEnableData & EnabledState;
export type U2fTwofaData = U2fTwofaEnableData & EnabledState;

export type TwofaData =
    | GoogleAuthenticatorTwofaData
    | EmailTwofaData
    | SmsTwofaData
    | U2fTwofaData
    | { enabled: false };

export type TwofaMethod = 'googleAuthenticator' | 'email' | 'sms' | 'u2f';

export interface TwofaResult {
    methods: string[];
    data: TwofaData;
}

export interface EnableResult {
    attempts: number;
    webauthnRegister?: webauthn.Types.PublicKeyCredentialCreationOptions;
}

export interface DisableResult {
    type: TwofaMethod;
    attempts: number;
    webauthnLogin?: webauthn.Types.PublicKeyCredentialRequestOptions;
}

export interface ChallengeModel {
    code?: string;
    u2fRegister?: webauthn.Types.PublicKeyCredentialAttestation;
    u2fLogin?: webauthn.Types.PublicKeyCredentialAssertion;
    rememberDeviceId?: boolean;
}

export interface ChallengeModelSerializable {
    code?: string;
    u2fRegister?: U2FPublicKeyCredentialAttestationSerializable;
    u2fLogin?: U2FPublicKeyCredentialAssertionSerializable;
    rememberDeviceId?: boolean;
}
export interface U2FCredentialSerializable {
    id: string;
    type: string;
}
export interface U2FPublicKeyCredentialSerializable<T extends U2FAuthenticatorResponseSerializable>
    extends U2FCredentialSerializable {
    type: 'public-key';
    rawId: number[];
    response: T;
}
export interface U2FPublicKeyCredentialAttestationSerializable
    extends U2FPublicKeyCredentialSerializable<U2FAuthenticatorAttestationResponseSerializable> {}
export interface U2FPublicKeyCredentialAssertionSerializable
    extends U2FPublicKeyCredentialSerializable<U2FAuthenticatorAssertionResponseSerializable> {}
export interface U2FAuthenticatorResponseSerializable {
    clientDataJSON: number[];
}
export interface U2FAuthenticatorAttestationResponseSerializable
    extends U2FAuthenticatorResponseSerializable {
    attestationObject: number[];
}
export interface U2FAuthenticatorAssertionResponseSerializable
    extends U2FAuthenticatorResponseSerializable {
    authenticatorData: number[];
    signature: number[];
    userHandle?: number[];
}

export class TwofaApi extends BaseApi {
    static readonly TWOFA_NOT_ENABLED = 0x7001;
    static readonly TWOFA_INVALID_TYPE = 0x7002;
    static readonly TWOFA_CODE_ALREADY_RESEND = 0x7003;
    static readonly TWOFA_INVALID_GOOGLE_AUTHENTICATOR_SECRET = 0x7004;
    static readonly TWOFA_EMAIL_REQUIRED = 0x7005;
    static readonly TWOFA_MOBILE_REQUIRED = 0x7006;
    static readonly TWOFA_INVALID_CODE = 0x7007;
    static readonly TWOFA_VERIFICATION_FAILED = 0x7008;
    static readonly TWOFA_CODE_ALREADY_USED = 0x7011;

    static readonly TWOFA_METHOD_GOOGLE_AUTHENTICATOR = 'googleAuthenticator';
    static readonly TWOFA_METHOD_EMAIL = 'email';
    static readonly TWOFA_METHOD_SMS = 'sms';
    static readonly TWOFA_METHOD_U2F = 'u2f';

    getData(): Promise<TwofaResult> {
        return this.request('twofaGetData', {});
    }

    disable(): Promise<TwofaData> {
        return this.request('twofaDisable', {});
    }

    disableWithChallenge(): Promise<DisableResult> {
        return this.request('twofaDisableWithChallenge', {});
    }

    enable(data: TwofaEnableData): Promise<EnableResult> {
        return this.request('twofaEnable', { data: data });
    }

    challenge(model: ChallengeModel): Promise<void> {
        return this.request('twofaChallenge', model);
    }

    resendCode(): Promise<void> {
        return this.request('twofaResendCode', {});
    }
}
