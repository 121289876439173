import { Event } from './Event';

export interface Entry<T> {
    index: number;
    obj: T;
}
export type CollectionEvent<T> =
    | CollectionAddEvent<T>
    | CollectionRemoveEvent<T>
    | CollectionUpdateEvent<T>
    | CollectionClearEvent
    | CollectionRebuildEvent
    | CollectionReorganizeEvent
    | CollectionMoveEvent;

export interface CollectionAddEvent<T> {
    type: 'add';
    changeId: number;
    index: number;
    element: T;
}
export interface CollectionRemoveEvent<T> {
    type: 'remove';
    changeId: number;
    index: number;
    element: T;
}
export interface CollectionUpdateEvent<T> {
    type: 'update';
    changeId: number;
    index: number;
    element: T;
}
export interface CollectionClearEvent {
    type: 'clear';
    changeId: number;
}
export interface CollectionRebuildEvent {
    type: 'rebuild';
    changeId: number;
}
export interface CollectionReorganizeEvent {
    type: 'reorganize';
    changeId: number;
    indicies: number[];
}
export interface CollectionMoveEvent {
    type: 'move';
    changeId: number;
    oldIndex: number;
    newIndex: number;
}

export type CollectionEventArgs<T> = [CollectionEvent<T>, unknown, unknown];

export class BaseCollection<T> {
    list: T[];
    changeEvent: Event<CollectionEvent<T>, unknown, unknown>;
    changeId: number;

    constructor(list?: T[]) {
        this.list = list ? list.slice() : [];
        this.changeEvent = new Event();
        this.changeId = 0;
    }

    get(index: number): T {
        return this.list[index];
    }

    getFirst(): T | undefined {
        return this.list.length > 0 ? this.list[0] : undefined;
    }

    getLast(): T | undefined {
        return this.list.length > 0 ? this.list[this.list.length - 1] : undefined;
    }

    getBy(propertyName: keyof T, value: unknown): T | undefined {
        for (const entry of this.list) {
            if (entry[propertyName] === value) {
                return entry;
            }
        }
        return undefined;
    }

    getAllBy(propertyName: keyof T, value: unknown): T[] {
        const result: T[] = [];
        for (const entry of this.list) {
            if (entry[propertyName] === value) {
                result.push(entry);
            }
        }
        return result;
    }

    find(func: (v: T, i: number) => boolean): T | undefined {
        return this.list.find(func);
    }

    findAll(func: (v: T, i: number) => boolean): T[] {
        return this.list.filter(func);
    }

    some(func: (v: T, i: number) => boolean): boolean {
        return this.list.some(func);
    }

    countBy(propertyName: keyof T, value: unknown): number {
        return this.getAllBy(propertyName, value).length;
    }

    indexOf(element: T): number {
        return this.list.indexOf(element);
    }

    indexOfBy(func: (v: T, i: number) => boolean): number {
        return this.list.findIndex(func);
    }

    contains(element: T): boolean {
        return this.list.indexOf(element) !== -1;
    }

    getEnumerable(): T[] {
        return this.list;
    }

    forEach(func: (v: T, i: number) => void): void {
        this.list.forEach(func);
    }

    size(): number {
        return this.list.length;
    }

    isEmpty(): boolean {
        return this.list.length === 0;
    }

    getListCopy(): T[] {
        return this.list.slice(0, this.list.length);
    }

    reductEvents(): void {
        const events = this.changeEvent.getLockedEvnts() as CollectionEventArgs<T>[];
        if (events.length <= 1) {
            return;
        }
        let tmp: { created: boolean; event: CollectionEventArgs<T> }[] = [];
        let newList: [CollectionEvent<T>, unknown, unknown][] = [];
        for (const eventArgs of events) {
            const event = eventArgs[0];
            if (event.type === 'add') {
                if (tmp.length < event.index) {
                    tmp.length = event.index;
                }
                tmp.splice(event.index, 0, {
                    created: true,
                    event: eventArgs
                });
            } else if (event.type === 'remove') {
                if (event.index < tmp.length) {
                    const removed = tmp.splice(event.index, 1);
                    if (removed[0] && !removed[0].created) {
                        const index = newList.indexOf(removed[0].event);
                        if (index !== -1) {
                            newList.splice(index, 1);
                        }
                    }
                }
            } else if (event.type === 'update') {
                if (tmp[event.index]) {
                    continue;
                } else {
                    tmp[event.index] = {
                        created: false,
                        event: eventArgs
                    };
                }
            } else if (event.type === 'clear') {
                newList = [];
                tmp = [];
            } else if (event.type === 'reorganize') {
                const oldList = tmp;
                tmp = [];
                for (let i = 0; i < event.indicies.length; i++) {
                    tmp[i] = oldList[event.indicies[i]];
                }
            } else if (event.type === 'move') {
                if (tmp.length < event.oldIndex) {
                    tmp.length = event.oldIndex;
                }
                if (tmp.length < event.newIndex) {
                    tmp.length = event.newIndex;
                }
                const removed = tmp.splice(event.oldIndex, 1);
                tmp.splice(event.newIndex, 0, removed[0]);
            } else if (event.type === 'rebuild') {
                newList = [];
                tmp = [];
            }
            newList.push(eventArgs);
        }
        this.changeEvent.setLockedEvnts(newList);
    }

    destroy(): void {
        this.changeEvent.clear();
    }

    triggerUpdateElement(element: T): void {
        const idx = this.indexOf(element);
        if (idx !== -1) {
            this.triggerUpdateAt(idx);
        }
    }

    triggerUpdateAt(index: number): void {
        if (index >= 0 && index < this.list.length) {
            this.changeEvent.trigger({
                type: 'update',
                changeId: ++this.changeId,
                index: index,
                element: this.list[index]
            });
        }
    }
}
