import { BaseModalData } from '../../types/Modals';
import Modal from '../../atoms/Modal';
import StateView from '../../atoms/State';
import useLoader from '../../hooks/useLoader';
import FormCreatorView from './FormCreatorView';
import { FormId } from '../../types/Types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface FormEditModalData extends BaseModalData {
    id: FormId;
}

export function FormEditModal(props: {
    onClose: () => void;
    data: FormEditModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useLoader(
        useCallback((x) => x.loadFormModel(props.data.id), [props.data.id])
    );
    return (
        <Modal
            noOffset
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            width={props.data.width ?? window.innerWidth * 0.8}
            height={props.data.height ?? window.innerHeight * 0.85}
            fullWidth
            resizable
            title={
                props.data.title ??
                (state.type === 'done' && state.data.form.name
                    ? t('modal.formEdit.titleWithName', { name: state.data.form.name })
                    : t('modal.formEdit.titleWithoutName'))
            }>
            <StateView state={state} retry={revalidate}>
                {(data) => (
                    <FormCreatorView
                        id={data.form.id}
                        name={data.form.name}
                        questions={data.form.questions}
                        tags={data.form.tags}
                        managers={data.form.managers}
                        users={data.form.users}
                        managerList={data.managers}
                        userList={data.users}
                        autoResponseData={data.form.autoResponseData}
                        captchaEnabled={data.form.captchaEnabled}
                        onClose={props.onClose}
                    />
                )}
            </StateView>
        </Modal>
    );
}
