export interface NetworkService {
    serviceName: string;
    pause(): void;
    restore(): void;
}

export class NetworkStatusService {
    private services: NetworkService[];

    constructor() {
        this.services = [];
    }

    registerService(service: NetworkService) {
        this.services.push(service);
    }

    registerAndStartService(service: NetworkService) {
        this.registerService(service);
        service.restore();
    }

    pauseNetworkActivity(): void {
        for (const service of this.services) {
            try {
                service.pause();
            } catch (e) {
                console.error('Error during pausing service', service.serviceName, e);
            }
        }
    }

    restoreNetworkActivity(): void {
        for (const service of this.services) {
            try {
                service.restore();
            } catch (e) {
                console.error('Error during restoring service', service.serviceName, e);
            }
        }
    }
}
