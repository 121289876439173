import { useAppDispatch, useAppSelector } from '../../../store';
import { selectAdminSettingsOpen, toggleAdminSettingsModal } from '../../../store/ModalsSlice';
import CenteredModal from '../CenteredModal';
import { SettingsScreen } from '../../../screens/SettingsScreen';
import { Box } from '@mantine/core';

export default function AdminPanelModal() {
    const dispatch = useAppDispatch();
    const modalOpen = useAppSelector(selectAdminSettingsOpen);
    const handleClose = () => {
        dispatch(toggleAdminSettingsModal({ open: false }));
    };

    return (
        <CenteredModal opened={modalOpen} onClose={handleClose}>
            <Box p={'md'} h={'100%'}>
                <SettingsScreen />
            </Box>
        </CenteredModal>
    );
}
