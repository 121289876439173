import * as privfs from 'privfs-client';

export class ConversationId {
    private hashmails: string[];

    constructor(hashmails?: string[]) {
        this.hashmails = hashmails || [];
    }

    add(hashmail: string) {
        if (!this.hashmails.includes(hashmail)) {
            this.hashmails.push(hashmail);
        }
    }

    toString(): string {
        this.hashmails.sort();
        return this.hashmails.join(',');
    }

    static createFromMessage(msg: privfs.types.message.Message): ConversationId {
        const convId = new ConversationId();
        for (const receiver of msg.receivers) {
            convId.add(receiver.user.hashmail);
        }
        convId.add(msg.sender.hashmail);
        return convId;
    }
}

export class MessageTagsFactory {
    static getMessageTypeTag(type: string): string {
        return 'type:' + type;
    }

    static getMessageTagsEx(message: privfs.types.message.Message, addTypeTag: boolean): string[] {
        const tags: string[] = ['cnv:' + ConversationId.createFromMessage(message)];
        if (addTypeTag) {
            tags.push(MessageTagsFactory.getMessageTypeTag(message.type || 'mail'));
        }
        return tags;
    }
}
