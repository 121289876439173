import { ScrollArea } from '@mantine/core';
import * as types from '../../types/Types';
import { ListFilesView } from './list/ListFilesView';
import { TileFilesView } from './tiles/TileFilesView';
import { useCallback, useState } from 'react';
import { Gallery } from './Gallery';
import { FilesViewMode } from '../../store/UserPreferenceSlice';
import { NoItemsInfo } from '../../atoms/NoItemsInfo';

export interface FilesViewProps {
    mode: FilesViewMode;
    hasAccess: boolean;
    loadingMessages: boolean;
    files: types.AttachmentEx[];
    className?: string;
}

export function FilesView(props: FilesViewProps) {
    const [handleShowFilePreview, setHandleShowFilePreview] = useState<
        (fileId: types.AttachmentId) => void
    >(() => () => {});
    const handleGetShowFilePreview = useCallback((cb: (fileId: types.AttachmentId) => void) => {
        setHandleShowFilePreview(() => cb);
    }, []);
    if (props.loadingMessages) return <></>;
    return (
        <ScrollArea mt="lg" mb="xl" mx="md" className={props.className}>
            {props.mode === 'list' && (
                <ListFilesView files={props.files} onShowFilePreview={handleShowFilePreview} />
            )}
            {props.mode === 'tiles' && (
                <TileFilesView files={props.files} onShowFilePreview={handleShowFilePreview} />
            )}
            <Gallery files={props.files} getShowFilePreview={handleGetShowFilePreview} />
            {props.files.length === 0 && <NoItemsInfo itemsType="files" />}
        </ScrollArea>
    );
}
