import { createStyles } from '@mantine/core';
import { useTranslation } from 'react-i18next';

type ItemsType =
    | 'topics'
    | 'meetings'
    | 'scheduledMeetings'
    | 'files'
    | 'forms'
    | 'formRows'
    | 'contacts'
    | 'bookmarks'
    | 'results';

export interface NoItemsInfoProps {
    itemsType: ItemsType;
}

const useStyles = createStyles((theme) => ({
    root: {
        color: theme.colors.gray[6],
        fontSize: theme.headings.sizes.h3.fontSize,
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
        textAlign: 'center'
    }
}));

export function NoItemsInfo(props: NoItemsInfoProps) {
    const { t } = useTranslation();
    const { classes } = useStyles();

    return <div className={classes.root}>{t(`noItemsMessage.${props.itemsType}`)}</div>;
}
