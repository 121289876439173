import { Anchor } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Link } from 'react-router-dom';
import { SubmitedAnswer, Contact, Email, Question, User } from '../../../types/Types';
import { UserUtils } from '../../../utils/UserUtils';
import { api } from '../../../api/Api';
import { Utils } from '../../../utils/Utils';

export const createTopicFromResponse = async (
    evt: React.MouseEvent,
    submitedAnswer: SubmitedAnswer | undefined,
    contacts: Contact[],
    staffMembers: User[]
) => {
    evt.preventDefault();
    evt.stopPropagation();
    try {
        if (submitedAnswer === undefined) {
            return;
        }
        const email = getShortAnswerLowerCaseText(submitedAnswer) ?? '';
        let contact = contacts.find((x) => x.email === email);
        let staff = staffMembers.find((x) => x.email === email);
        const user = contact && contact.username ? api.getUser(contact.username) : undefined;
        let userOrContact = user ?? contact ?? staff;
        if (!userOrContact) {
            contact = await api.addContact(UserUtils.getNewContactModel(email as Email));
            const user = contact && contact.username ? api.getUser(contact.username) : undefined;
            return user ?? contact;
        }
        return userOrContact;
    } catch (err) {
        console.error(err);
        const msg = Utils.getErrorMessage(err);
        if (msg.toLocaleLowerCase().includes('with given email already exists')) {
            notifications.show({
                title: 'Contact with given email already exists',
                message: '',
                color: 'red'
            });
        } else {
            notifications.show({
                title: 'Error while adding new Topic',
                message: 'Try reloading the app and adding the Topic again.',
                color: 'red'
            });
        }
    }
};

export const handleCreateContact = async (
    evt: React.MouseEvent,
    submitedAnswer: SubmitedAnswer | undefined,
    contacts: Contact[],
    accountEmails: Email[]
) => {
    evt.preventDefault();
    evt.stopPropagation();
    try {
        if (submitedAnswer === undefined) {
            return;
        }
        const email = getShortAnswerLowerCaseText(submitedAnswer) ?? '';
        if (!UserUtils.canCreateContact(email, contacts, accountEmails)) {
            if (contacts.some((x) => x.email === email) || accountEmails.some((x) => x === email)) {
                notifications.show({
                    title: 'Contact with given email already exists',
                    message: '',
                    color: 'red'
                });
            } else {
                notifications.show({
                    title: 'Invalid email',
                    message: '',
                    color: 'red'
                });
            }
        }
        const newContact = await api.addContact(UserUtils.getNewContactModel(email as Email));
        notifications.show({
            title: 'Contact created',
            message: <Anchor component={Link} to={`/contacts/${newContact.id}`}></Anchor>
        });
    } catch (err) {
        console.error(err);
        const msg = Utils.getErrorMessage(err);
        if (msg.toLocaleLowerCase().includes('with given email already exists')) {
            notifications.show({
                title: 'Contact with given email already exists',
                message: '',
                color: 'red'
            });
        } else {
            notifications.show({
                title: 'Error while adding new Contacts',
                message: 'Try reloading the app and adding the Contacts again.',
                color: 'red'
            });
        }
    }
};

export function getFirstMessage(questionsWithAnswers: SubmitedAnswer[], questions: Question[]) {
    const formMessage: string[] = [];

    for (const question of questions) {
        const answer = questionsWithAnswers.find((answer) => answer.id === question.id);
        if (!answer) {
            formMessage.push(`<b>${question?.title}:</b><br/><em>No answer</em>`);
        } else if (answer.type === 'select' || answer.type === 'single') {
            const answers: string[] = [];
            for (const answerSelected of answer.answer) {
                answers.push(`<p>${answerSelected.input}</p>`);
            }
            formMessage.push(`<b>${question?.title}:</b>${answers.join('')}`);
        } else if (answer.type === 'file') {
            const answers: string[] = [];
            for (const answerSelected of answer.answer) {
                answers.push(`<p>${answerSelected.input}</p>`);
            }
            formMessage.push(`<b>${question?.title}:</b>${answers.join('')}`);
        } else {
            formMessage.push(
                `<b>${question?.title}:</b><p>${answer.answer.map((x) => x.input).join('')}</p>`
            );
        }
    }

    return formMessage.join('');
}

function getShortAnswerLowerCaseText(answer: SubmitedAnswer) {
    if (answer.type !== 'short' && answer.type !== 'email') {
        return null;
    }
    const text = `${answer.answer[0]?.input ?? ''}`;
    return text.toLocaleLowerCase();
}

export function canCreateContactFromAnswer(
    answer: SubmitedAnswer,
    contacts: Contact[],
    accountEmails: Email[]
) {
    if (answer.type !== 'short' && answer.type !== 'email') {
        return false;
    }
    const text = getShortAnswerLowerCaseText(answer);
    if (text === null) {
        return false;
    }
    return UserUtils.canCreateContact(text, contacts, accountEmails);
}
