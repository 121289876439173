import { ConfirmModalState } from './types';
import create from 'zustand';

type Store = {
    controller: ConfirmModalState;
    dialogOpen: boolean;
    openDialog: ({
        onCancelCallback,
        onAcceptCallback
    }: {
        onCancelCallback?: Function;
        onAcceptCallback?: Function;
    }) => void;
};

const CLOSED_CONTROLLER = {
    onAccept: { current: undefined },
    onCancel: { current: undefined },
    open: false
};

export const useConfirmDialog = create<Store>((set) => ({
    dialogOpen: false,
    controller: {
        open: false,
        onAccept: { current: () => {} },
        onCancel: { current: () => {} }
    },
    openDialog({ onAcceptCallback, onCancelCallback }) {
        set((store) => ({
            controller: {
                onAccept: {
                    current() {
                        onAcceptCallback?.();
                        set({
                            controller: { ...CLOSED_CONTROLLER },
                            dialogOpen: false
                        });
                    }
                },
                onCancel: {
                    current() {
                        onCancelCallback?.();
                        set({
                            controller: { ...CLOSED_CONTROLLER },
                            dialogOpen: false
                        });
                    }
                },
                open: true
            },
            dialogOpen: true
        }));
    }
}));
