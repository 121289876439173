import {
    Box,
    PasswordInput,
    Stack,
    Text,
    Center,
    Group,
    Button,
    LoadingOverlay,
    Textarea,
    createStyles
} from '@mantine/core';
import React, { useCallback, useState } from 'react';
import { useLocalStorage } from '@mantine/hooks';
import { useForm } from 'react-hook-form';
import { usePreventClose } from '../../hooks/usePreventClose';
import { PASSWORD_REQUIREMENTS, PasswordStrength } from '../../utils/PasswordStrength';
import { IconCheck, IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';

export interface PasswordChangePromptData {
    login: string;
    currentPassword: string;
    isGeneratedPassword: boolean;
    mnemonic?: string | null;
    saveCallback: (newPassword: string, mnemonic: string | null) => Promise<void>;
    cancelCallback?: () => void;
}

export interface PasswordChangePromptProps {
    passwordChangePrompt: PasswordChangePromptData;
    handleClose: () => void;
    onPasswordChange?: (newPassword: string) => void;
}

interface FormData {
    currentPassword: string;
    mnemonic: string;
    newPassword1: string;
    newPassword2: string;
}

function PasswordRequirement({ meets, label }: { meets: boolean; label: string }) {
    return (
        <Text color={meets ? 'teal' : 'red'} mt={5} size="sm">
            <Center inline>
                {meets ? (
                    <IconCheck size="0.9rem" stroke={1.5} />
                ) : (
                    <IconX size="0.9rem" stroke={1.5} />
                )}
                <Box ml={7}>{label}</Box>
            </Center>
        </Text>
    );
}

const usePasswordChangeFormStyles = createStyles((theme) => ({
    useMnemonic: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));

export function PasswordChangeForm(props: PasswordChangePromptProps) {
    const { classes } = usePasswordChangeFormStyles();
    const saveCallback = props.passwordChangePrompt.saveCallback;
    const cancelCallback = props.passwordChangePrompt.cancelCallback;
    const handleClose = props.handleClose;
    const [isProcessing, setIsProcessing] = useState(false);
    const [isDev] = useLocalStorage({ key: 'is-dev' });
    const [loginMethod, setLoginMethod] = useState<'password' | 'mnemonic'>('password');

    const { t } = useTranslation();
    const { register, setError, formState, handleSubmit, watch } = useForm<FormData>();
    usePreventClose(formState.isDirty);

    const handleFormSubmit = useCallback(
        async (data: FormData) => {
            const isMnemonic = loginMethod === 'mnemonic';
            if (isMnemonic) {
                if (data.mnemonic.trim() === '') {
                    setError('mnemonic', {});
                    return;
                }
            } else {
                if (data.currentPassword.trim() !== props.passwordChangePrompt.currentPassword) {
                    setError('currentPassword', {});
                    return;
                }
            }
            if (data.newPassword1.trim() === '') {
                setError('newPassword1', {});
                return;
            }
            const passwordStrengthResult = PasswordStrength.validate(data.newPassword1);
            if (!isDev && passwordStrengthResult.status === 'error') {
                setError('newPassword1', { message: passwordStrengthResult.message });
                return;
            }
            if (data.newPassword1.trim() !== data.newPassword2.trim()) {
                setError('newPassword2', { message: 'Passwords are not equal' });
                return;
            }
            setIsProcessing(true);
            try {
                await saveCallback(
                    data.newPassword1.trim(),
                    isMnemonic ? data.mnemonic.trim() : null
                );
                notifications.show({
                    message: t('successMessage.passwordChanged')
                });
                props.onPasswordChange?.(data.newPassword1);
                handleClose();
            } catch (err) {
                console.error(err);
                notifications.show({
                    title: 'Something went wrong',
                    message: t('errorMessage.couldNotChangePassword'),
                    color: 'red'
                });
            } finally {
                setIsProcessing(false);
            }
        },
        [props, handleClose, setError, saveCallback, isDev, t, loginMethod]
    );

    const newPassword = watch('newPassword1');

    const checks = PASSWORD_REQUIREMENTS().map((requirement, index) => (
        <PasswordRequirement
            key={index}
            label={requirement.label}
            meets={requirement.re.test(newPassword || '')}
        />
    ));

    const handleToggleMnemonicClick = useCallback(() => {
        setLoginMethod((loginMethod) => (loginMethod === 'password' ? 'mnemonic' : 'password'));
    }, []);

    return (
        <Box
            mx="auto"
            maw={500}
            component="form"
            onSubmit={handleSubmit(handleFormSubmit)}
            onReset={() => {
                cancelCallback?.();
                handleClose();
            }}>
            <LoadingOverlay visible={isProcessing} />
            <Stack spacing={'xs'}>
                <div>
                    {loginMethod === 'password' && (
                        <PasswordInput
                            mt="xs"
                            label="Current password"
                            {...register('currentPassword')}
                            error={!!formState.errors.currentPassword}
                        />
                    )}
                    {loginMethod === 'mnemonic' && (
                        <Textarea
                            mt="xs"
                            label={t('entityProps.mnemonic')}
                            {...register('mnemonic')}
                            error={!!formState.errors.mnemonic}
                        />
                    )}
                    {!props.passwordChangePrompt.mnemonic && (
                        <Text
                            size="xs"
                            className={classes.useMnemonic}
                            onClick={handleToggleMnemonicClick}>
                            {loginMethod === 'password'
                                ? t('action.useMnemonic')
                                : t('action.usePassword')}
                        </Text>
                    )}
                </div>
                <PasswordInput
                    label="New password"
                    {...register('newPassword1')}
                    error={formState.errors.newPassword1?.message ? true : false}
                />
                <PasswordInput
                    label="Repeat new password"
                    {...register('newPassword2')}
                    error={formState.errors.newPassword2?.message}
                />
                {props.passwordChangePrompt.mnemonic && (
                    <Box>
                        <Text weight="bold" size="sm">
                            {t('screen.settings.mnemonic.yourMnemonic')}
                        </Text>
                        <code>{props.passwordChangePrompt.mnemonic}</code>
                        <Text size="sm">{t('screen.settings.mnemonic.info')}</Text>
                    </Box>
                )}
                <Group sx={{ placeSelf: 'center' }} mt="md">
                    <Button variant="light" type="reset">
                        Cancel
                    </Button>
                    <Button type="submit">Save</Button>
                </Group>
                {newPassword && checks}
            </Stack>
        </Box>
    );
}
