import { createStyles, getStylesRef } from '@mantine/core';

export const useContactStyleElement = createStyles((theme) => {
    return {
        root: {
            cursor: 'pointer',
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            '& .addCompany': {
                display: 'none'
            },
            '&:hover': {
                backgroundColor: theme.other.getHoverColor(theme),
                [`& .${getStylesRef('icons')}`]: {
                    display: 'flex'
                },
                [`& .addCompany`]: {
                    display: 'flex'
                }
            }
        },
        tags: {
            width: '100%',

            justifyContent: 'flex-end'
            // /    flexGrow: 1
        },
        active: {
            backgroundColor: theme.other.getActiveColor(theme),
            '&:hover': {
                backgroundColor: theme.other.getActiveColor(theme)
            }
        },
        icons: {
            ref: getStylesRef('icons'),
            display: 'none'
        },
        addCompany: {
            display: 'none'
        }
    };
});
