import { VideoResolution } from "./Types";

export class VideoResolutions {
    
    static readonly AVAILABLE_RESOLUTIONS: VideoResolution[] = [
        { width: 1280, height: 720 },
        { width: 640, height: 480 },
        { width: 640, height: 360 },
        { width: 320, height: 240 },
        { width: 320, height: 180 },
        { width: 240, height: 180 },
        { width: 160, height: 120 },
        { width: 480, height: 640 },
        { width: 360, height: 640 },
        { width: 240, height: 320 },
        { width: 180, height: 320 },
        { width: 180, height: 240 },
        { width: 120, height: 160 },
    ];
    
    static MIN_RESOLUTION: VideoResolution = VideoResolutions.getMinResolution();
    
    static MAX_RESOLUTION: VideoResolution = VideoResolutions.getMaxResolution();
    
    static DEFAULT_RESOLUTION: VideoResolution = VideoResolutions.getDefaultResolution();
    
    private static getMinResolution(): VideoResolution {
        return VideoResolutions.AVAILABLE_RESOLUTIONS
            .map(resolution => ({ area: resolution.width * resolution.height, resolution: resolution }))
            .sort((a, b) => a.area - b.area)[0]?.resolution ?? this.getDefaultResolution();
    }
    
    private static getMaxResolution(): VideoResolution {
        return this.AVAILABLE_RESOLUTIONS
            .map(resolution => ({ area: resolution.width * resolution.height, resolution: resolution }))
            .sort((a, b) => b.area - a.area)[0]?.resolution ?? this.getDefaultResolution();
    }
    
    private static getDefaultResolution(): VideoResolution {
        return this.AVAILABLE_RESOLUTIONS
            .filter(resolution => resolution.width == 1280 && resolution.height == 720)[0]!;
    }
    
}
