import Modal from '../../atoms/Modal';
import StateView from '../../atoms/State';
import useLoader from '../../hooks/useLoader';
import { BaseModalData } from '../../types/Modals';
import { AttachmentId } from '../../types/Types';
import { AttachmentVersions } from './index';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface AttachmentVersionsModalData extends BaseModalData {
    attachmentId: AttachmentId;
}

export function AttachmentVersionsModal(props: {
    onClose: () => void;
    data: AttachmentVersionsModalData;
    index: number;
    onFocus: VoidFunction;
}) {
    const { t } = useTranslation();

    const { state, revalidate } = useLoader(
        useCallback(
            (x) => x.loadAttachmentVersions(props.data.attachmentId),
            [props.data.attachmentId]
        )
    );
    return (
        <Modal
            index={props.index}
            onFocus={props.onFocus}
            onClose={props.onClose}
            height={props.data.height}
            width={props.data.width}
            title={
                props.data.title ??
                (state.type === 'done' && state.data.main.name
                    ? t('modal.attachmentVersions.titleWithFilename', {
                          name: state.data.main.name
                      })
                    : t('modal.attachmentVersions.titleWithoutFilename'))
            }>
            <StateView state={state} retry={revalidate}>
                {(data) => <AttachmentVersions versions={data.versions} />}
            </StateView>
        </Modal>
    );
}
