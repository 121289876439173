import { Anchor, Box, Group, Text, Tooltip, createStyles } from '@mantine/core';
import { LinkMetadata, LinkType } from '../../../utils/LinkDetector';
import { IconAlignJustified, IconVideo, IconWorld, IconExternalLink } from '@tabler/icons-react';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../store';
import { selectCachedForm, selectCachedMeeting } from '../../../store/DataCacheSlice';
import { FormId, MeetingId } from '../../../types/Types';

const useStyles = createStyles((theme) => ({
    anchor: {
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? '#fff' : theme.colors['brand'][5],
        display: 'inline-block',
        background: theme.colorScheme === 'dark' ? theme.colors['brand'][7] : 'transparent',
        borderColor:
            theme.colorScheme === 'dark' ? theme.colors['brand'][5] : theme.colors['brand'][5],
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 30,
        padding: `calc(${theme.spacing.xs} / 2) ${theme.spacing.sm}`,
        maxWidth: '90%',
        margin: `${theme.spacing.xs} 0 !important`
    },
    group: {
        '> *': {
            marginBottom: 0
        }
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
}));

export interface MessageLinkProps {
    link: LinkMetadata;
}

export function MessageLink(props: MessageLinkProps) {
    const { t } = useTranslation();
    const form = useAppSelector(
        selectCachedForm((props.link.type === 'form' ? props.link.formId : '') as FormId)
    );
    const meeting = useAppSelector(
        selectCachedMeeting(
            (props.link.type === 'meeting' ? props.link.meetingId : '') as MeetingId
        )
    );
    const { classes } = useStyles();

    return (
        <Box>
            <Anchor
                href={props.link.link}
                target="_blank"
                rel="noreferrer"
                className={classes.anchor}>
                <Group spacing="xs" noWrap maw="100%" className={classes.group}>
                    <MessageLinkIcon type={props.link.type} />
                    <Tooltip label={props.link.link} disabled={props.link.type !== 'external'}>
                        <Text mr="xl" className={classes.text} size="sm">
                            {props.link.type === 'form' && (
                                <Trans
                                    i18nKey="messageLink.form"
                                    values={{
                                        text: (form?.name ?? props.link.formId).substring(0, 100)
                                    }}>
                                    <em></em>
                                </Trans>
                            )}
                            {props.link.type === 'meeting' && (
                                <Trans
                                    i18nKey="messageLink.meeting"
                                    values={{
                                        text:
                                            meeting?.title ?? props.link.meetingId.substring(0, 100)
                                    }}>
                                    <em></em>
                                </Trans>
                            )}
                            {props.link.type === 'external' && (
                                <Trans
                                    i18nKey="messageLink.external"
                                    values={{ text: props.link.domain.substring(0, 100) }}>
                                    <em></em>
                                </Trans>
                            )}
                        </Text>
                    </Tooltip>
                    <IconExternalLink opacity={0.6} size={15} />
                    <Text size="xs" ml={-5} opacity={0.6}>
                        {props.link.type === 'form' && t('messageLink.actionDescription.form')}
                        {props.link.type === 'meeting' &&
                            t('messageLink.actionDescription.meeting')}
                        {props.link.type === 'external' &&
                            t('messageLink.actionDescription.external')}
                    </Text>
                </Group>
            </Anchor>
        </Box>
    );
}

interface MessageLinkIconProps {
    type: LinkType;
}

function MessageLinkIcon(props: MessageLinkIconProps) {
    if (props.type === 'form') {
        return <IconAlignJustified size={18} />;
    } else if (props.type === 'meeting') {
        return <IconVideo size={18} />;
    } else {
        return <IconWorld size={18} />;
    }
}
