import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { api } from '../../api/Api';
import Form from '../../atoms/Form';
import FormButtons from '../../atoms/FormButtons';
import { selectChatTags } from '../../store/ModalsSlice';
import * as types from '../../types/Types';
import { useMemo } from 'react';
import { iconLoading, iconSend } from '../Icon';
import { Deferred } from '../../utils/Deferred';
import { Utils } from '../../api/privmx/utils/Utils';
import { useAppSelector } from '../../store';
import { Button, Center, Grid, Group, Modal, Stack, Textarea } from '@mantine/core';
import TagsSelect from '../TagsSelect';
import { usePreventClose } from '../../hooks/usePreventClose';
import { useTranslation } from 'react-i18next';

interface FormData {
    note: string;
    tags: types.TagOption[];
}

export const FormRowEditPage = forwardRef(
    (props: { formRow: types.FormRow | null; handleClose: () => void }, ref) => {
        const [isProcessing, setIsProcessing] = useState(false);
        const [confirmModalDeferred, setConfirmModalDeferred] = useState<
            Deferred<boolean> | undefined
        >(undefined);
        const { t } = useTranslation();
        const {
            control,
            register,
            handleSubmit,
            formState: { isDirty },
            getValues
        } = useForm<FormData>();
        usePreventClose(isDirty);

        const handleClose = props.handleClose;
        useImperativeHandle(ref, () => ({
            beforeClose() {
                if (!isDirty) {
                    return true;
                }
                const deferred = new Deferred<boolean>();
                setConfirmModalDeferred(deferred);
                return deferred.promise;
            }
        }));

        const handleFormSubmit = useCallback(
            async (data: FormData) => {
                if (!props.formRow) {
                    return;
                }
                setIsProcessing(true);
                try {
                    const adminData: types.FormRowAdminData = {
                        note: data.note.trim()
                    };
                    const tags = data.tags.map((x) => x.value);
                    await api.updateFormRow(
                        props.formRow.id,
                        props.formRow.formId,
                        adminData,
                        tags
                    );
                } finally {
                    setIsProcessing(false);
                }
                handleClose();
            },
            [handleClose, props.formRow]
        );

        const handleConfirmModalSaveClick = useCallback(() => {
            confirmModalDeferred?.resolve(false);
            setConfirmModalDeferred(undefined);
            handleFormSubmit(getValues());
        }, [handleFormSubmit, confirmModalDeferred, setConfirmModalDeferred, getValues]);

        const handleConfirmModalCancelClick = useCallback(() => {
            confirmModalDeferred?.resolve(false);
            setConfirmModalDeferred(undefined);
        }, [confirmModalDeferred, setConfirmModalDeferred]);

        const handleConfirmModalRejectChangesClick = useCallback(() => {
            confirmModalDeferred?.resolve(true);
            setConfirmModalDeferred(undefined);
        }, [confirmModalDeferred, setConfirmModalDeferred]);

        const globalTags = useAppSelector(selectChatTags);
        const [newTags, setNewTags] = useState<types.Tag[]>([]);
        const tagsOptions = [...(globalTags || []), ...newTags, ...(props.formRow?.tags ?? [])].map(
            (tag) => ({
                label: tag,
                value: tag
            })
        );

        const defaultTagsValue = useMemo(() => {
            const mapped = props.formRow?.tags.map((tag) => ({ label: tag, value: tag })) ?? [];
            return mapped.filter(Utils.isDefined);
        }, [props.formRow?.tags]);

        return (
            <Stack>
                <Form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Grid.Col span={1}>
                        <Controller
                            control={control}
                            name="tags"
                            defaultValue={defaultTagsValue}
                            render={({ field }) => (
                                <TagsSelect
                                    label={t('entityProps.tags')}
                                    value={field.value.map((v) => v.value)}
                                    onChange={(val) => {
                                        field.onChange(val.map((v) => ({ label: v, value: v })));
                                    }}
                                    data={tagsOptions}
                                    onCreate={(query) => {
                                        setNewTags((prev) => [...prev, query as types.Tag]);
                                        return { value: query, label: query };
                                    }}
                                />
                            )}
                        />
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <Textarea
                            minRows={6}
                            label={t('entityProps.note')}
                            defaultValue={props.formRow?.adminData.note ?? ''}
                            {...register('note', { required: true, maxLength: 1000 })}
                            disabled={isProcessing}
                        />
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Center>
                            <FormButtons
                                isSaving={isProcessing}
                                saveIcon={isProcessing ? iconLoading : iconSend}
                                spinSaveIcon={isProcessing}
                                onCancel={handleClose}
                            />
                        </Center>
                    </Grid.Col>
                </Form>
                {confirmModalDeferred && (
                    <Modal
                        title={t('warningMessage.unsavedChangesWhatToDo')}
                        centered
                        opened={Boolean(confirmModalDeferred)}
                        onClose={handleConfirmModalCancelClick}>
                        <Center>
                            <Group position="apart">
                                <Button type="button" onClick={handleConfirmModalCancelClick}>
                                    {t('action.cancel')}
                                </Button>
                                <Button
                                    type="button"
                                    onClick={handleConfirmModalRejectChangesClick}>
                                    {t('action.rejectChanges')}
                                </Button>
                                <Button type="button" onClick={handleConfirmModalSaveClick}>
                                    {t('action.save')}
                                </Button>
                            </Group>
                        </Center>
                    </Modal>
                )}
            </Stack>
        );
    }
);

export default FormRowEditPage;
