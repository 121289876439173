import { Anchor, Center, Stack, TextInput } from '@mantine/core';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { api, userApi } from '../../api/Api';
import FormButtons from '../../atoms/FormButtons';
import * as types from '../../types/Types';
import { iconLoading } from '../Icon';
import { usePreventClose } from '../../hooks/usePreventClose';
import { useTranslation } from 'react-i18next';
import { notifications } from '@mantine/notifications';
import { Link } from 'react-router-dom';

interface FormAccount {
    name: types.ContactName;
    email: types.Email;
}

export default function CreateAccountView(props: { handleClose: () => void }) {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors },
        handleSubmit,
        formState
    } = useForm<FormAccount>();
    usePreventClose(formState.isDirty);

    const onSubmit = useCallback(
        async (submitedData: FormAccount) => {
            try {
                const currentUserUsername = api.getCurrentUserUsername();
                const newContact: types.Contact = {
                    id: '' as types.ContactId,
                    userType: 'email',
                    name: submitedData.name as types.ContactName,
                    email: submitedData.email as types.Email,
                    address: '' as types.ContactAddress,
                    mobilePhone: '' as types.MobilePhone,
                    phone: '' as types.Phone,
                    companyId: '' as types.CompanyId,
                    note: '' as types.ContactNote,
                    avatar: '' as types.Avatar,
                    language: 'en' as types.Language,
                    tags: [],
                    pinned: false,
                    archived: false,
                    createdBy: '' as types.Username,
                    createdDate: 0 as types.Timestamp,
                    lastModifiedBy: '' as types.Username,
                    lastModifiedDate: 0 as types.Timestamp,
                    isPrivate: currentUserUsername !== undefined,
                    privateContactVisibleForUsers:
                        currentUserUsername !== undefined ? [currentUserUsername] : []
                };
                let createdContact: types.Contact;
                try {
                    createdContact = await userApi.addContact(newContact);
                } catch (err) {
                    if (userApi.getContactByEmailSync(newContact.email)) {
                        notifications.show({
                            title: 'Error while adding new Contacts',
                            message: "'Contact with given email already exists'",
                            color: 'red'
                        });
                    } else {
                        notifications.show({
                            title: 'Error while adding new Contacts',
                            message: 'Try reloading the app and adding the Contacts again.',
                            color: 'red'
                        });
                    }
                    throw err;
                }
                await userApi.createInternalUserFromContact(createdContact, {
                    type: 'manuallyViaAdminPanel'
                });
                notifications.show({
                    title: t('successMessage.accountCreated'),
                    message: (
                        <Anchor component={Link} to={`/contacts/${createdContact.id}`}>
                            Go to Contacts List
                        </Anchor>
                    )
                });
                props.handleClose();
            } catch (error) {
                notifications.show({
                    title: 'Something went wrong',
                    message: 'Try reloading the app and adding the Contacts again.',
                    color: 'red'
                });
                console.error('error', error);
            }
        },
        [props, t]
    );

    const onCancel = useCallback(() => {
        props.handleClose();
    }, [props]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
                <TextInput
                    required
                    error={!!errors.email}
                    label={t('entityProps.email')}
                    {...register('email', { required: true, maxLength: 100 })}
                />
                <TextInput
                    error={!!errors.name}
                    label={t('entityProps.name')}
                    {...register('name', { maxLength: 100 })}
                />
                <Center>
                    <FormButtons
                        onCancel={onCancel}
                        saveIcon={formState.isSubmitting ? iconLoading : undefined}
                        spinSaveIcon={formState.isSubmitting}
                        isSaving={formState.isSubmitting}
                    />
                </Center>
            </Stack>
        </form>
    );
}
