import React from 'react';
import { answerOptions, answerType, FormDispatch } from '../types';
import {
    ActionIcon,
    Box,
    Card,
    Grid,
    Group,
    rem,
    Select,
    Stack,
    Switch,
    TextInput
} from '@mantine/core';
import { QuestionModel } from '../../../../types/Types';
import Icon, { iconArrowDown, iconArrowUp } from '../../../../components/Icon';
import { useFormCreatorStyles } from '../FormCreator';
import { IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useFormCreatorContext } from './FormState/useFormState';

interface BaseBlockProps {
    children: React.ReactElement;
    index: number;
    autoResponseFieldId: string;
    question: QuestionModel;
    dispatch: FormDispatch;
    onAutoResponseChange: (newId: string) => void;
}

export default function Block({
    autoResponseFieldId,
    question,
    children,
    index,
    dispatch,
    onAutoResponseChange
}: BaseBlockProps) {
    const { classes } = useFormCreatorStyles();
    const { t } = useTranslation();

    const { errors } = useFormCreatorContext();
    const currentQuestionErrors = errors.find((x) => x.questionIndex === index);
    return (
        <Card className={classes.blockRoot} radius={'md'}>
            <Grid>
                <Grid.Col span={1} maw={40}>
                    <Stack justify="space-between" mx="auto" h="100%">
                        <ActionIcon>
                            <Icon
                                onClick={() =>
                                    dispatch({ type: 'move_question', index, direction: 'up' })
                                }
                                icon={iconArrowUp}
                            />
                        </ActionIcon>
                        <ActionIcon>
                            <Icon
                                onClick={() =>
                                    dispatch({ type: 'move_question', index, direction: 'down' })
                                }
                                icon={iconArrowDown}
                            />
                        </ActionIcon>
                    </Stack>
                </Grid.Col>
                <Grid.Col span="auto" pb={0}>
                    <Group position="apart" mb="md" w="100%" grow>
                        <TextInput
                            error={currentQuestionErrors?.titleError}
                            placeholder={
                                question.type === 'block'
                                    ? t('screen.formCreator.placeholders.block')
                                    : t('screen.formCreator.placeholders.question')
                            }
                            sx={(them) => ({ flexGrow: 1 })}
                            onChange={(e) =>
                                dispatch({
                                    type: 'change_title',
                                    index,
                                    newValue: e.target.value
                                })
                            }
                            value={question.title}
                        />
                        <Select
                            sx={{ flexGrow: 0 }}
                            data={answerType.map((x) => ({ value: x, label: answerOptions[x] }))}
                            defaultValue="short"
                            value={question.type}
                            onChange={(e) =>
                                dispatch({
                                    type: 'change_questionType',
                                    index,
                                    newValue: e || ''
                                })
                            }
                        />
                    </Group>
                    <Box mb="sm">{children}</Box>
                    <Group position="apart">
                        {question.type === 'block' ? (
                            <Box></Box>
                        ) : (
                            <>
                                <Switch
                                    size="xs"
                                    my="sm"
                                    label={t('entityProps.required')}
                                    checked={question.required}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'change_required',
                                            index,
                                            newValue: e.target.checked
                                        });
                                    }}
                                />
                                {question.type === 'email' && (
                                    <Switch
                                        size="xs"
                                        my="sm"
                                        label={t('entityProps.useWithAutoResponse')}
                                        checked={question.id === autoResponseFieldId}
                                        onChange={(e) => {
                                            onAutoResponseChange(
                                                e.target.checked ? question.id : ''
                                            );
                                        }}
                                    />
                                )}
                            </>
                        )}
                        <ActionIcon
                            color="red"
                            size="md"
                            onClick={() => dispatch({ type: 'remove_question', index })}>
                            <IconTrash size={rem(16)} />
                        </ActionIcon>
                    </Group>
                </Grid.Col>
            </Grid>
        </Card>
    );
}
