import { useCallback } from 'react';
import useModal from '../../hooks/useModal';
import { Attachment } from '../../types/Types';
import { Utils } from '../../utils/Utils';
import Icon, { iconChat, mimetypeToIcon } from '../Icon';
import { Box, Button, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export function AttachmentVersions(props: { versions: Attachment[] }) {
    const { t } = useTranslation();
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Title order={2}>
                <Icon icon={mimetypeToIcon(props.versions[0].contentType)} />
                {props.versions[0].name}
            </Title>
            <Box component="table" w="100%">
                <thead>
                    <tr>
                        <th>{t('entityProps.date')}</th>
                        <th>{t('entityProps.user')}</th>
                        <th>{t('entityProps.size')}</th>
                        <th>{t('entityProps.actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.versions.map((attachment) => (
                        <AttachmentVersionRow
                            key={`${attachment.author}/${attachment.date}`}
                            attachment={attachment}
                        />
                    ))}
                </tbody>
            </Box>
        </Box>
    );
}

interface AttachmentVersionRowProps {
    attachment: Attachment;
}

function AttachmentVersionRow(props: AttachmentVersionRowProps) {
    const { t } = useTranslation();
    const attachment = props.attachment;
    const modalService = useModal();

    const handlePreviewClick = useCallback(() => {
        modalService.openAttachmentPreviewModal({ attachment: attachment });
    }, [attachment, modalService]);

    const handleShowMessageClick = useCallback(async () => {
        modalService.openSingleChatMessageModal({ messageId: attachment.messageId });
    }, [modalService, attachment]);

    return (
        <tr>
            <td>{new Date(attachment.date).toLocaleString()}</td>
            <td>{attachment.author}</td>
            <td>{Utils.bytesSize(attachment.size)}</td>
            <td>
                <Box w="100%">
                    <Button.Group mx="auto">
                        <Button onClick={handlePreviewClick}>
                            <Icon icon={mimetypeToIcon(attachment.contentType)} />{' '}
                            {t('action.preview')}
                        </Button>
                        <Button onClick={handleShowMessageClick}>
                            <Icon icon={iconChat} /> {t('action.showMessage')}
                        </Button>
                    </Button.Group>
                </Box>
            </td>
        </tr>
    );
}

export default AttachmentVersions;
