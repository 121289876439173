import * as PmxApi from 'privmx-server-api';

export class Base64 {
    static EMPTY = '' as PmxApi.api.core.Base64;

    static from(buffer: Buffer): PmxApi.api.core.Base64 {
        return buffer.toString('base64') as PmxApi.api.core.Base64;
    }

    static toBuf(base64: PmxApi.api.core.Base64): Buffer {
        return Buffer.from(base64, 'base64');
    }
}
