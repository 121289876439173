import { ActionIcon, Badge } from '@mantine/core';
import { useCallback } from 'react';
import { Attachment, AttachmentId } from '../../types/Types';
import { IconX } from '@tabler/icons-react';

interface AttachmentListItemProps {
    attachment: Pick<Attachment, 'id' | 'name'>;
    onDeleteClick?: (attachmentId: AttachmentId) => void;
}

export default function AttachmentListItem(props: AttachmentListItemProps) {
    const onDeleteClick = props.onDeleteClick;

    const handleDeleteClick = useCallback(() => {
        onDeleteClick?.(props.attachment.id);
    }, [onDeleteClick, props.attachment.id]);

    const icon = (
        <ActionIcon size="xs">
            <IconX size={14} onClick={handleDeleteClick} />
        </ActionIcon>
    );
    return (
        <Badge rightSection={props.onDeleteClick ? icon : undefined}>{props.attachment.name}</Badge>
    );
}
