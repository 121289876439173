import { Button, CopyButton, Flex, TextInput } from '@mantine/core';
import Icon, { iconCopy, iconSuccess } from '../../components/Icon';
import { useCallback, useRef } from 'react';

export interface TextWithCopyButtonProps {
    text: string;
}

export default function TextWithCopyButton(props: TextWithCopyButtonProps) {
    const textInputRef = useRef<HTMLInputElement>(null);

    const selectText = useCallback(() => {
        textInputRef.current?.select();
    }, []);

    return (
        <Flex>
            <TextInput
                type="text"
                readOnly
                value={props.text}
                sx={{ flex: '1 1 auto' }}
                onFocus={selectText}
                onClick={selectText}
                ref={textInputRef}
                styles={{ input: { border: 0, paddingLeft: 0 } }}
            />
            <CopyButton value={props.text}>
                {({ copied, copy }) => (
                    <Button onClick={copy}>
                        {copied ? <Icon icon={iconSuccess} /> : <Icon icon={iconCopy} />}
                    </Button>
                )}
            </CopyButton>
        </Flex>
    );
}
