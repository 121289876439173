import { Checkbox } from '@mantine/core';
import * as FormsTypes from '../FormsTypes';
import { isValueABoolean } from '../utils/FormsUtils';
export default function SelectInput({ options }: { options: FormsTypes.RenderFormElementOptions }) {
    const { inputRef, model } = options;

    return (
        <Checkbox
            required={model.required}
            id={model.id}
            name={model.id}
            defaultChecked={isValueABoolean(model.value) ? model.value : undefined}
            label={model.label}
            ref={inputRef}
        />
    );
}
