export const faces = [
    '\u{1F642}',
    '\u{1F600}',
    '\u{1F603}',
    '\u{1F604}',
    '\u{1F601}',
    '\u{1F605}',
    '\u{1F606}',
    '\u{1F923}',
    '\u{1F602}',
    '\u{1F643}',
    '\u{1F609}',
    '\u{1F60A}',
    '\u{1F607}',
    '\u{1F60E}',
    '\u{1F913}',
    '\u{1F9D0}',
    '\u{1F973}',
    '\u{1F970}',
    '\u{1F60D}',
    '\u{1F618}',
    '\u{1F617}',
    '\u{1F60B}',
    '\u{1F61B}',
    '\u{1F61C}',
    '\u{1F92A}',
    '\u{1F61D}',
    '\u{1F911}',
    '\u{1F917}',
    '\u{1F92D}',
    '\u{1F92B}',
    '\u{1F914}',
    '\u{1F610}',
    '\u{1F910}',
    '\u{1F928}',
    '\u{1F611}',
    '\u{1F636}',
    '\u{1F60F}',
    '\u{1F612}',
    '\u{1F644}',
    '\u{1F62C}',
    '\u{1F925}',
    '\u{1F634}',
    '\u{1F60C}',
    '\u{1F922}',
    '\u{1F975}',
    '\u{1F976}',
    '\u{1F974}',
    '\u{1F615}',
    '\u{1F61F}',
    '\u{1F641}',
    '\u{1F62E}',
    '\u{1F632}',
    '\u{1F633}',
    '\u{1F97A}',
    '\u{1F616}',
    '\u{1F631}',
    '\u{1F623}',
    '\u{1F61E}',
    '\u{1F62B}',
    '\u{1F971}',
    '\u{1F621}',
    '\u{1F620}',
    '\u{1F92C}',
    '\u{1F608}',
    '\u{1F47F}',
    '\u{1F480}'
];

export const hands = [
    '\u{1F590}',
    '\u{1F44B}',
    '\u{1F91A}',
    '\u{270B}',
    '\u{1F596}',
    '\u{1F44C}',
    '\u{1F90F}',
    '\u{270C}',
    '\u{1F91E}',
    '\u{1F91F}',
    '\u{1F918}',
    '\u{1F919}',
    '\u{1F448}',
    '\u{1F449}',
    '\u{1F446}',
    '\u{1F447}',
    '\u{1F595}',
    '\u{261D}',
    '\u{1F44D}',
    '\u{1F44E}',
    '\u{270A}',
    '\u{1F44A}',
    '\u{1F91B}',
    '\u{1F91C}',
    '\u{1F44F}',
    '\u{1F64C}',
    '\u{1F450}',
    '\u{1F932}',
    '\u{1F91D}',
    '\u{1F64F}',
    '\u{270D}',
    '\u{1F485}',
    '\u{1F933}',
    '\u{1F4AA}',
    '\u{1F9BE}',
    '\u{1F9B5}',
    '\u{1F9BF}',
    '\u{1F9B6}',
    '\u{1F442}',
    '\u{1F9BB}',
    '\u{1F443}',
    '\u{1F9E0}',
    '\u{1F463}',
    '\u{1F9B7}',
    '\u{1F440}',
    '\u{1F441}',
    '\u{1F445}',
    '\u{1F444}'
];

export const nature = [
    '\u{1F415}',
    '\u{1F435}',
    '\u{1F412}',
    '\u{1F98D}',
    '\u{1F9A7}',
    '\u{1F436}',
    '\u{1F9AE}',
    '\u{1F429}',
    '\u{1F43A}',
    '\u{1F98A}',
    '\u{1F99D}',
    '\u{1F431}',
    '\u{1F408}',
    '\u{1F981}',
    '\u{1F42F}',
    '\u{1F405}',
    '\u{1F406}',
    '\u{1F434}',
    '\u{1F40E}',
    '\u{1F984}',
    '\u{1F993}',
    '\u{1F98C}',
    '\u{1F42E}',
    '\u{1F404}',
    '\u{1F402}',
    '\u{1F437}',
    '\u{1F416}',
    '\u{1F417}',
    '\u{1F43D}',
    '\u{1F40F}',
    '\u{1F411}',
    '\u{1F410}',
    '\u{1F42A}',
    '\u{1F42B}',
    '\u{1F999}',
    '\u{1F992}',
    '\u{1F418}',
    '\u{1F98F}',
    '\u{1F99B}',
    '\u{1F42D}',
    '\u{1F401}',
    '\u{1F400}',
    '\u{1F439}',
    '\u{1F430}',
    '\u{1F407}',
    '\u{1F43F}',
    '\u{1F994}',
    '\u{1F987}',
    '\u{1F43B}',
    '\u{1F428}',
    '\u{1F43C}',
    '\u{1F9A5}',
    '\u{1F9A6}',
    '\u{1F9A8}',
    '\u{1F998}',
    '\u{1F9A1}',
    '\u{1F43E}',
    '\u{1F983}',
    '\u{1F414}',
    '\u{1F413}',
    '\u{1F423}',
    '\u{1F424}',
    '\u{1F425}',
    '\u{1F426}',
    '\u{1F427}',
    '\u{1F54A}',
    '\u{1F985}',
    '\u{1F986}',
    '\u{1F9A2}',
    '\u{1F989}',
    '\u{1F9A9}',
    '\u{1F99C}',
    '\u{1F438}',
    '\u{1F40A}',
    '\u{1F422}',
    '\u{1F98E}',
    '\u{1F40D}',
    '\u{1F432}',
    '\u{1F409}',
    '\u{1F995}',
    '\u{1F996}',
    '\u{1F433}',
    '\u{1F40B}',
    '\u{1F42C}',
    '\u{1F41F}',
    '\u{1F420}',
    '\u{1F421}',
    '\u{1F988}',
    '\u{1F419}',
    '\u{1F41A}',
    '\u{1F40C}',
    '\u{1F98B}',
    '\u{1F41B}',
    '\u{1F41C}',
    '\u{1F41D}',
    '\u{1F41E}',
    '\u{1F997}',
    '\u{1F577}',
    '\u{1F578}',
    '\u{1F982}',
    '\u{1F99F}',
    '\u{1F9A0}',
    '\u{1F490}',
    '\u{1F338}',
    '\u{1F4AE}',
    '\u{1F3F5}',
    '\u{1F339}',
    '\u{1F940}',
    '\u{1F33A}',
    '\u{1F33B}',
    '\u{1F33C}',
    '\u{1F337}',
    '\u{1F331}',
    '\u{1F332}',
    '\u{1F333}',
    '\u{1F334}',
    '\u{1F335}',
    '\u{1F33E}',
    '\u{1F33F}',
    '\u{2618}',
    '\u{1F340}',
    '\u{1F341}',
    '\u{1F342}',
    '\u{1F343}'
];

export const food = [
    '\u{1F353}',
    '\u{1F347}',
    '\u{1F348}',
    '\u{1F349}',
    '\u{1F34A}',
    '\u{1F34B}',
    '\u{1F34C}',
    '\u{1F34D}',
    '\u{1F96D}',
    '\u{1F34E}',
    '\u{1F34F}',
    '\u{1F350}',
    '\u{1F351}',
    '\u{1F352}',
    '\u{1F95D}',
    '\u{1F345}',
    '\u{1F965}',
    '\u{1F951}',
    '\u{1F346}',
    '\u{1F954}',
    '\u{1F955}',
    '\u{1F33D}',
    '\u{1F336}',
    '\u{1F952}',
    '\u{1F96C}',
    '\u{1F966}',
    '\u{1F9C4}',
    '\u{1F9C5}',
    '\u{1F344}',
    '\u{1F95C}',
    '\u{1F330}',
    '\u{1F35E}',
    '\u{1F950}',
    '\u{1F956}',
    '\u{1F968}',
    '\u{1F96F}',
    '\u{1F95E}',
    '\u{1F9C7}',
    '\u{1F9C0}',
    '\u{1F356}',
    '\u{1F357}',
    '\u{1F969}',
    '\u{1F953}',
    '\u{1F354}',
    '\u{1F35F}',
    '\u{1F355}',
    '\u{1F32D}',
    '\u{1F96A}',
    '\u{1F32E}',
    '\u{1F32F}',
    '\u{1F959}',
    '\u{1F9C6}',
    '\u{1F95A}',
    '\u{1F373}',
    '\u{1F958}',
    '\u{1F372}',
    '\u{1F963}',
    '\u{1F957}',
    '\u{1F37F}',
    '\u{1F9C8}',
    '\u{1F9C2}',
    '\u{1F96B}',
    '\u{1F371}',
    '\u{1F358}',
    '\u{1F359}',
    '\u{1F35A}',
    '\u{1F35B}',
    '\u{1F35C}',
    '\u{1F35D}',
    '\u{1F360}',
    '\u{1F362}',
    '\u{1F363}',
    '\u{1F364}',
    '\u{1F365}',
    '\u{1F96E}',
    '\u{1F361}',
    '\u{1F95F}',
    '\u{1F960}',
    '\u{1F961}',
    '\u{1F980}',
    '\u{1F99E}',
    '\u{1F990}',
    '\u{1F991}',
    '\u{1F9AA}',
    '\u{1F368}',
    '\u{1F367}',
    '\u{1F366}',
    '\u{1F369}',
    '\u{1F36A}',
    '\u{1F382}',
    '\u{1F370}',
    '\u{1F9C1}',
    '\u{1F967}',
    '\u{1F36B}',
    '\u{1F36C}',
    '\u{1F36D}',
    '\u{1F36E}',
    '\u{1F36F}',
    '\u{1F37C}',
    '\u{1F95B}',
    '\u{2615}',
    '\u{1F375}',
    '\u{1F376}',
    '\u{1F37E}',
    '\u{1F377}',
    '\u{1F378}',
    '\u{1F379}',
    '\u{1F37A}',
    '\u{1F37B}',
    '\u{1F942}',
    '\u{1F943}',
    '\u{1F964}',
    '\u{1F9C3}',
    '\u{1F9C9}',
    '\u{1F9CA}',
    '\u{1F962}',
    '\u{1F37D}',
    '\u{1F374}',
    '\u{1F944}',
    '\u{1F52A}',
    '\u{1F3FA}'
];
