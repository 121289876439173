import { useState, useCallback } from 'react';
import * as types from '../../types/Types';
import { useForm, Controller } from 'react-hook-form';
import { api } from '../../api/Api';
import useLoader from '../../hooks/useLoader';
import StateView from '../../atoms/State';
import ModalForm from '../ModalForm';
import { MultiSelect } from '@mantine/core';
import { usePreventClose } from '../../hooks/usePreventClose';
import { useTranslation } from 'react-i18next';

interface FormData {
    messageText: string;
    chats: { value: types.ChatId; label: string }[];
}

export default function ShareFormView({
    message,
    closeFn
}: {
    message: string;
    closeFn: VoidFunction;
}) {
    const { t } = useTranslation();
    const { state, revalidate } = useLoader(
        useCallback(async (x) => {
            const data = await x.loadChats({});
            return data.chats
                .filter((x) => !x.archived)
                .map((chat: types.Chat) => ({
                    label: chat.title,
                    value: chat.id
                }));
        }, [])
    );
    const form = useForm<FormData>({
        defaultValues: {
            chats: [],
            messageText: message || ''
        }
    });
    usePreventClose(form.formState.isDirty);

    const [attachments, setAttachments] = useState<types.Attachment[]>([]);
    const handleFormSubmit = useCallback(
        async (data: FormData) => {
            try {
                await api.sendBatchMessage(
                    data.chats.map((val) => val.value),
                    'text/html',
                    data.messageText,
                    attachments
                );
                closeFn();
            } catch (e) {
                console.error('Unable to send messages', e);
            }
        },
        [attachments, closeFn]
    );
    const handleAttachFilesClick = useCallback(async () => {
        const newAttachments = await api.chooseFilesAsAttachments();
        if (newAttachments.length > 0) {
            setAttachments((attachments) => [...attachments, ...newAttachments]);
        }
    }, [setAttachments]);

    const handleDeleteAttachmentClick = useCallback(
        (attachmentId: types.AttachmentId) => {
            setAttachments((prev) => {
                api.deleteAttachment(attachmentId);
                return prev.filter((attachment) => attachment.id !== attachmentId);
            });
        },
        [setAttachments]
    );

    return (
        <StateView state={state} retry={revalidate}>
            {(chatsId) => (
                <ModalForm form={form} onSubmit={handleFormSubmit} onClose={closeFn}>
                    <ModalForm.Field>
                        <Controller
                            control={form.control}
                            name="chats"
                            render={({ field, fieldState }) => (
                                <MultiSelect
                                    required
                                    label={t('entityProps.chats')}
                                    error={fieldState.error?.type}
                                    value={field.value.map(
                                        (v: { value: string; label: string }) => v.value
                                    )}
                                    onChange={(val) => {
                                        field.onChange(val.map((v) => ({ label: v, value: v })));
                                    }}
                                    data={chatsId}
                                />
                            )}
                        />
                    </ModalForm.Field>
                    <ModalForm.Field>
                        <></>
                    </ModalForm.Field>
                    <ModalForm.MessageFormField
                        grow
                        label={t('entityProps.chats')}
                        name="messageText"
                        attachments={attachments}
                        attachFileClick={handleAttachFilesClick}
                        deleteAttachment={handleDeleteAttachmentClick}
                        defaultValue={message}
                    />
                </ModalForm>
            )}
        </StateView>
    );
}
