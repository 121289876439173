import { Paper, ScrollArea, ScrollAreaProps } from '@mantine/core';
import React, { createContext, useCallback, useContext, useRef } from 'react';

const ScrollContext = createContext<(to: ScrollToOptions) => void>(() => {});

export const ScrollContextProvider = ({
    children,
    value
}: {
    value: (to: ScrollToOptions) => void;
    children: React.ReactNode;
}) => {
    return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>;
};

export default function ScreenScrollContainer({
    children,
    offsetScrollbars,
    ...props
}: { children: React.ReactNode } & Omit<ScrollAreaProps, 'viewportRef'>) {
    const container = useRef<HTMLDivElement>(null);

    const scrollTo = useCallback((to: ScrollToOptions) => {
        if (container.current) {
            container.current.scrollTo(to);
        }
    }, []);

    return (
        <ScrollContextProvider value={scrollTo}>
            <Paper
                withBorder
                m="md"
                mt={0}
                p="sm"
                pr={offsetScrollbars ? 0 : undefined}
                sx={{ flexGrow: 1, display: 'flex', height: 'calc(100% - 120px)' }}>
                <ScrollArea
                    sx={{
                        flexGrow: 1
                    }}
                    w={'calc(100% - 48px)'}
                    viewportRef={container}
                    offsetScrollbars={offsetScrollbars}
                    {...props}>
                    {children}
                </ScrollArea>
            </Paper>
        </ScrollContextProvider>
    );
}

export const useScrollTo = () => {
    const scrollTo = useContext(ScrollContext);
    return scrollTo;
};
