import {
    Box,
    Group,
    rem,
    Flex,
    Stack,
    Title,
    Tooltip,
    ActionIcon,
    Divider,
    Button,
    Text,
    Paper,
    TextInput,
    MediaQuery,
    Menu,
    Kbd
} from '@mantine/core';
import {
    IconMail,
    IconVideo,
    IconBuilding,
    IconTag,
    IconPhone,
    IconHome,
    IconNote,
    IconExternalLink,
    IconEdit,
    IconDeviceFloppy,
    IconKey,
    IconChevronLeft,
    IconDotsVertical,
    IconX
} from '@tabler/icons-react';

import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import UserAvatar from '../../../atoms/UserAvatar';
import useToast from '../../../hooks/useToast';
import { CompanyBadge } from '../../../mantineAtoms/CompanyBadge';
import LinkWithFilter from '../../../mantineAtoms/SearchBar/LinkWithFilter';
import { TagList } from '../../../mantineAtoms/TagList';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
    selectCachedContactCompany,
    mutateContact,
    selectContactName
} from '../../../store/DataCacheSlice';
import {
    toggleNewComapnyModal,
    toggleNewMessageModal,
    toggleNewMeetingModal,
    toggleShareAccessModal
} from '../../../store/ModalsSlice';
import {
    Contact,
    CompanyId,
    Tag,
    MobilePhone,
    ContactAddress,
    ContactNote,
    User,
    ContactName
} from '../../../types/Types';
import { UserUtils } from '../../../utils/UserUtils';
import { useContactViewStyles } from './styles';
import { ContactField } from './ContactPageField';
import { api } from '../../../api/Api';
import { Link } from 'react-router-dom';
import { Validator } from '../../../utils/Validator';
import { getHotkeyHandler, useClickOutside, useFocusTrap, useInputState } from '@mantine/hooks';
import { PrivateContactBadge } from '../PrivateContactBadge';
import ConfirmModal from '../../../atoms/ConfirmModal';
import { Deferred } from '../../../utils/Deferred';

const FIELD_ICON_SIZE = 16;

export function ContactPageView({ contact }: { contact: Contact | User }) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const isContact = 'tags' in contact;
    const { classes, cx, theme } = useContactViewStyles();
    const ref = useClickOutside(() => handleCancelEditContactName());
    const focusTrapRef = useFocusTrap();

    const selectedContactName = useAppSelector(selectContactName(contact.username));
    const contactName = selectedContactName ?? contact.name;
    const isContactNameEditable = !selectedContactName && !!contactName;
    const [isEditingContactName, setIsEditingContactName] = useState(false);
    const [editedContactName, setEditedContactName] = useInputState(contactName);
    const [
        confirmMakeHiddenContactPublicModalDeferred,
        setConfirmMakeHiddenContactPublicModalDeferred
    ] = useState<Deferred<boolean> | undefined>(undefined);

    const contactCompany = useAppSelector(
        selectCachedContactCompany(isContact ? contact.companyId : undefined)
    );
    const name = () => {
        if (contactName) {
            return (contactName as string) === contact.email
                ? contact.username
                : (contactName as string);
        }
        return contact.username || contact.email;
    };

    const toast = useToast();

    const [temporaryPassword, setTemporaryPassword] = useState<string | undefined>(undefined);
    useEffect(() => {
        async function getTemporaryPassword() {
            const password = await api.getTemporaryPasswordForUser(contact.username);
            if (password) {
                setTemporaryPassword(password);
            }
        }
        getTemporaryPassword();
    }, [isContact, contact]);

    const contactUser = api
        .getUsersSync()
        .filter((x) => x.email === contact.email)
        .at(0);
    const wasLoggedInAlready = contactUser && contactUser.lastLoggedIn > -1;

    const handleOpenShareAccess = useCallback(() => {
        dispatch(
            toggleShareAccessModal({
                opened: true,
                username: contact.username,
                temporaryPassword: temporaryPassword
            })
        );
    }, [contact, temporaryPassword, dispatch]);

    const handleCreateCompany = useCallback(
        (querry: string) => {
            if (isContact)
                dispatch(
                    toggleNewComapnyModal({
                        opened: true,
                        initialName: querry,
                        initialUsers: [contact]
                    })
                );
        },
        [isContact, contact, dispatch]
    );
    const handleContactChange = useCallback(
        async (newValues: Partial<Contact>) => {
            if (!isContact) return;
            const oldContact = { ...contact };
            try {
                const newContact: Contact = {
                    ...contact,
                    ...newValues
                };
                dispatch(mutateContact(newContact));
                await api.updateContact(newContact);
            } catch (error) {
                dispatch(mutateContact(oldContact));
                console.error(error);
                toast('Unable to edit contact, try again later', 'error');
            }
        },
        [contact, dispatch, isContact, toast]
    );

    const handleEditContactNameClick = useCallback(() => {
        setIsEditingContactName(true);
    }, []);

    const [contactNameError, setContactNameError] = useState<null | string>(null);
    const handleSaveContactNameClick = useCallback(async () => {
        if (isContact) {
            try {
                const newName = Validator.check(editedContactName.trim());
                const newContact: Contact = {
                    ...contact,
                    name: newName as ContactName
                };
                dispatch(mutateContact(newContact));
                await api.updateContact(newContact);
            } catch (e: any) {
                setContactNameError(e.message);
            }
        }
        setIsEditingContactName(false);
    }, [isContact, contact, dispatch, editedContactName]);

    const interactionButtons = [
        {
            icon: <IconMail />,
            name: t('screen.contacts.sendMessage'),
            action: () =>
                dispatch(
                    toggleNewMessageModal({
                        open: true,
                        payload: { users: [UserUtils.userUnionToUsernameOrContactId(contact)] }
                    })
                )
        },
        {
            icon: <IconVideo />,
            name: t('screen.contacts.scheduleMeeting'),
            action: () =>
                dispatch(
                    toggleNewMeetingModal({
                        open: true,
                        payload: { users: [UserUtils.userUnionToUsernameOrContactId(contact)] }
                    })
                )
        }
    ];

    const handleCancelEditContactName = () => {
        setEditedContactName(contactName);
        setIsEditingContactName(false);
        setContactNameError(null);
    };

    const [isMakingContactPublic, setIsMakingContactPublic] = useState(false);
    const handleChangeHiddenContactToPublic = useCallback(async () => {
        if (!isContact) {
            return;
        }
        const deferred = new Deferred<boolean>();
        setConfirmMakeHiddenContactPublicModalDeferred(deferred);
        const result = await deferred.promise;
        if (!result) {
            return;
        }
        try {
            setIsMakingContactPublic(true);
            await api.makeContactPublic(contact);
        } finally {
            setIsMakingContactPublic(false);
        }
    }, [contact, isContact]);

    const handleMakeHiddenContactPublicConfirmModalYesClick = useCallback(() => {
        confirmMakeHiddenContactPublicModalDeferred?.resolve(true);
        setConfirmMakeHiddenContactPublicModalDeferred(undefined);
    }, [
        confirmMakeHiddenContactPublicModalDeferred,
        setConfirmMakeHiddenContactPublicModalDeferred
    ]);

    const handleMakeHiddenContactPublicConfirmModalNoClick = useCallback(() => {
        confirmMakeHiddenContactPublicModalDeferred?.resolve(false);
        setConfirmMakeHiddenContactPublicModalDeferred(undefined);
    }, [
        confirmMakeHiddenContactPublicModalDeferred,
        setConfirmMakeHiddenContactPublicModalDeferred
    ]);

    const contactFields = !isContact ? null : (
        <>
            {contact.isPrivate && (
                <Paper
                    className={`${classes.fieldGroup} ${classes.extraInfoFieldGroup}`}
                    mb={rem(40)}>
                    <Group spacing="xs" position="apart">
                        <Group spacing="xs">
                            <Trans i18nKey="screen.contacts.hiddenContactInfo">
                                <PrivateContactBadge />
                            </Trans>
                        </Group>
                        <Button
                            variant="white"
                            size="xs"
                            onClick={handleChangeHiddenContactToPublic}
                            loading={isMakingContactPublic}>
                            {t('screen.contacts.changeHiddenContactToPublic')}
                        </Button>
                    </Group>
                </Paper>
            )}
            <Paper className={classes.fieldGroup} mb="xl">
                <Stack>
                    <Group position="apart">
                        <Group>
                            <IconBuilding size={rem(FIELD_ICON_SIZE)} />
                            <Text color="dimmed">Company</Text>
                        </Group>
                        <div className={classes.fieldContent}>
                            {contactCompany ? (
                                <CompanyBadge
                                    onDelete={() =>
                                        handleContactChange({
                                            companyId: undefined
                                        })
                                    }
                                    size="sm"
                                    companyId={contactCompany.id}>
                                    {contactCompany.name}
                                </CompanyBadge>
                            ) : (
                                <CompanyBadge
                                    size="sm"
                                    onSelect={(id) =>
                                        handleContactChange({
                                            companyId: id as CompanyId
                                        })
                                    }
                                    onCreate={handleCreateCompany}
                                />
                            )}
                        </div>
                    </Group>
                    <Flex direction={'row'} justify={'space-between'} align={'center'} gap="md">
                        <Group sx={{ flexShrink: 0 }}>
                            <IconTag size={rem(FIELD_ICON_SIZE)} />
                            <Text color="dimmed">Tags</Text>
                        </Group>
                        <Box className={classes.fieldContent}>
                            <TagList
                                onTagSelect={(newTags) =>
                                    handleContactChange({
                                        tags: newTags as Tag[]
                                    })
                                }
                                className={cx(classes.tagList)}
                                tags={contact.tags}
                            />
                        </Box>
                    </Flex>
                </Stack>
            </Paper>
            <Paper className={classes.fieldGroup}>
                <Stack>
                    <ContactField
                        icon={<IconPhone size={rem(FIELD_ICON_SIZE)} />}
                        name="Number"
                        type="input"
                        value={contact.mobilePhone}
                        onSave={(newNumber) =>
                            handleContactChange({ mobilePhone: newNumber as MobilePhone })
                        }
                        placeholder="Add number"
                        inputProps={{ type: 'number' }}
                        validator={['safeDefault', 'phone']}
                        maxLength={{
                            message: 'Phone number cannot be longer than 20 characters',
                            value: 20
                        }}
                    />

                    <ContactField
                        icon={<IconHome size={rem(FIELD_ICON_SIZE)} />}
                        name="Adress"
                        type="input"
                        value={contact.address}
                        onSave={(newValue) => {
                            handleContactChange({ address: newValue as ContactAddress });
                        }}
                        placeholder="Add adress"
                        validator={['safeDefault']}
                        maxLength={{
                            message: 'Adress cannot be longer than 100 characters',
                            value: 100
                        }}
                    />

                    <ContactField
                        icon={<IconNote size={rem(FIELD_ICON_SIZE)} />}
                        name="Note"
                        type="textarea"
                        value={contact.note}
                        onSave={(newValue) =>
                            handleContactChange({ note: newValue as ContactNote })
                        }
                        placeholder="Add note"
                        inputProps={{ minRows: 3, maxRows: 7 }}
                        validator={['safeDefault']}
                        maxLength={{
                            message: 'Note cannot be longer than 200 characters',
                            value: 200
                        }}
                    />
                </Stack>
            </Paper>
        </>
    );
    const questionBg = theme.colorScheme === 'dark' ? 'dark.7' : 'gray.0';

    return (
        <Flex bg={questionBg} pos="relative" h="100%" direction="column">
            <MediaQuery largerThan={'md'} styles={{ display: 'none' }}>
                <ActionIcon component={Link} to="/contacts" pos="absolute" top={16} left={16}>
                    <IconChevronLeft />
                </ActionIcon>
            </MediaQuery>
            <Stack align="center" mx="auto" my="xl" mb="xl" w="auto" spacing={'sm'}>
                <UserAvatar username={contact.username} showTooltip={false} size={90} />
                <div>
                    {!isEditingContactName && (
                        <Title align="center" order={3}>
                            <Flex
                                align="center"
                                justify="center"
                                ml={isContactNameEditable ? 40 : 0}>
                                {name()}
                                {isContactNameEditable && (
                                    <ActionIcon ml="sm" onClick={handleEditContactNameClick}>
                                        <IconEdit />
                                    </ActionIcon>
                                )}
                            </Flex>
                        </Title>
                    )}
                    {isEditingContactName && (
                        // <Group my="sm">
                        //     <TextInput
                        //         error={contactNameError}
                        //         defaultValue={name()}
                        //         w={200}
                        //         ref={contactNameInputRef}
                        //     />
                        //     <Button
                        //         variant="light"
                        //         onClick={handleSaveContactNameClick}
                        //         disabled={isSavingContactName}
                        //         loading={isSavingContactName}
                        //         leftIcon={<IconDeviceFloppy />}>
                        //         Save
                        //     </Button>
                        // </Group>
                        <Box
                            display={'flex'}
                            mx="auto"
                            sx={{ flexDirection: 'row' }}
                            ref={ref}
                            component="form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSaveContactNameClick();
                            }}>
                            <Group spacing={0}>
                                <TextInput
                                    error={contactNameError}
                                    mr="sm"
                                    maxLength={50}
                                    ref={focusTrapRef}
                                    value={editedContactName}
                                    onChange={setEditedContactName}
                                    onKeyDown={getHotkeyHandler([
                                        ['Escape', handleCancelEditContactName]
                                    ])}
                                    inputWrapperOrder={['label', 'input', 'description', 'error']}
                                    description={
                                        <Trans i18nKey="action.pressEnterOrEsc">
                                            <Kbd size="xs">
                                                <></>
                                            </Kbd>
                                            <Kbd size="xs">
                                                <></>
                                            </Kbd>
                                        </Trans>
                                    }
                                />
                                <Tooltip label="Save" openDelay={500}>
                                    <ActionIcon mr={4} color="green" type="submit">
                                        <IconDeviceFloppy size={rem(14)} />
                                    </ActionIcon>
                                </Tooltip>
                                <Tooltip label="Cancel" openDelay={500}>
                                    <ActionIcon color="red" onClick={handleCancelEditContactName}>
                                        <IconX size={rem(14)} />
                                    </ActionIcon>
                                </Tooltip>
                            </Group>
                        </Box>
                    )}
                    {name() !== contact.email ? (
                        <Text align="center">{contact.email}</Text>
                    ) : (
                        <Box h={24.79} />
                    )}
                </div>
                <Group spacing={'sm'}>
                    {interactionButtons.map((button) => (
                        <Tooltip key={button.name} label={button.name} openDelay={300}>
                            <ActionIcon color="brand" component="div" onClick={button.action}>
                                {button.icon}
                            </ActionIcon>
                        </Tooltip>
                    ))}
                    {contact.username && (
                        <>
                            {!wasLoggedInAlready && contact && temporaryPassword && (
                                <Tooltip
                                    key={t('screen.contacts.shareAccess')}
                                    label={t('screen.contacts.shareAccess')}
                                    openDelay={300}>
                                    <ActionIcon
                                        color="brand"
                                        component="div"
                                        onClick={handleOpenShareAccess}>
                                        <IconKey />
                                    </ActionIcon>
                                </Tooltip>
                            )}
                            <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                                <Divider mx={8} orientation="vertical" />
                            </MediaQuery>
                            <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                                <LinkWithFilter to={'chat'} filter={{ users: [contact.username!] }}>
                                    <Button
                                        rightIcon={<IconExternalLink size={rem(14)} />}
                                        variant="outline">
                                        {t('screen.contacts.showThreads')}
                                    </Button>
                                </LinkWithFilter>
                            </MediaQuery>
                            <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                                <LinkWithFilter
                                    to={'meetings'}
                                    filter={{ users: [contact.username!] }}>
                                    <Button
                                        rightIcon={<IconExternalLink size={rem(14)} />}
                                        variant="outline">
                                        {t('screen.contacts.showMeetings')}
                                    </Button>
                                </LinkWithFilter>
                            </MediaQuery>
                            <MediaQuery styles={{ display: 'none' }} largerThan={'md'}>
                                <Menu>
                                    <Menu.Target>
                                        <ActionIcon>
                                            <IconDotsVertical />
                                        </ActionIcon>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                        <Menu.Item
                                            component={LinkWithFilter}
                                            to={'chat'}
                                            filter={{ users: [contact.username!] }}>
                                            {t('screen.contacts.showThreads')}
                                        </Menu.Item>
                                        <Menu.Item
                                            component={LinkWithFilter}
                                            to={'meetings'}
                                            filter={{ users: [contact.username!] }}>
                                            {t('screen.contacts.showMeetings')}
                                        </Menu.Item>
                                    </Menu.Dropdown>
                                </Menu>
                            </MediaQuery>
                        </>
                    )}
                </Group>
            </Stack>

            {contactFields}

            <ConfirmModal
                opened={!!confirmMakeHiddenContactPublicModalDeferred}
                onClose={handleMakeHiddenContactPublicConfirmModalNoClick}
                padding={32}
                title={t('screen.contacts.changeHiddenContactToPublicConfirm')}>
                <Button type="button" onClick={handleMakeHiddenContactPublicConfirmModalYesClick}>
                    {t('Yes')}
                </Button>
                <Button type="button" onClick={handleMakeHiddenContactPublicConfirmModalNoClick}>
                    {t('No')}
                </Button>
            </ConfirmModal>
        </Flex>
    );
}
