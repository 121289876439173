import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { favoriteApi } from '../../api/Api';
import { Utils } from '../../api/privmx/utils/Utils';
import StateView from '../../atoms/State';
import { LoadingBookmarksList } from '../../components/Loading/LoadingSection';
import { useDataSubscribion } from '../../hooks/useDataSubscribion';
import { modifyState, useSearchParamsEx } from '../../hooks/useLoader';
import { useAppDispatch } from '../../store';
import { setChatTags } from '../../store/ModalsSlice';
import { ChatId, FavoriteMessage, FullFavoriteMessage, MessageId } from '../../types/Types';
import { filterFavorites } from '../../utils/router/loaders';
import FavoritesScreenView from './FavoritesScreenView';

export default function FavoritesScreen() {
    const { state, revalidate } = useDataSubscribion(
        useCallback((x) => x.subscribeForFavorites(), [])
    );
    return (
        <StateView state={state} retry={revalidate} loading={LoadingBookmarksList}>
            {(data) => {
                return <FavoritesScreenInner favorites={data} />;
            }}
        </StateView>
    );
}

function FavoritesScreenInner(props: { favorites: FavoriteMessage[] }) {
    const { state, revalidate } = useDataSubscribion(
        useCallback(
            (x) => x.subscribeForMessagesWithChats(props.favorites.map((x) => x.messageId)),
            [props.favorites]
        )
    );
    const routeParams = useParams();
    const { searchParams, updateSearchParams, navigateKeepingSearch } = useSearchParamsEx();
    const filter = useMemo(() => searchParams.get('filter') || '', [searchParams]);

    const dispatch = useAppDispatch();
    const favoritesState = useMemo(
        () =>
            modifyState(state, (data) => {
                const fullFavoriteMessages: FullFavoriteMessage[] = [];
                for (const fav of props.favorites) {
                    const message = data.messages.find((x) => x.id === fav.messageId);
                    const chat = data.chats.find((x) => x.id === message?.threadId);
                    if (message && chat) {
                        fullFavoriteMessages.push({ message, chat });
                    }
                }
                const allTags = Utils.unique(fullFavoriteMessages.map((x) => x.chat.tags).flat());
                dispatch(setChatTags(allTags));
                const favorites = (() => {
                    return fullFavoriteMessages;
                })();
                return filterFavorites(favorites, filter);
            }),
        [state, filter, dispatch, props.favorites]
    );
    const handleFavoriteChoose = useCallback(
        (favorite: FullFavoriteMessage) =>
            navigateKeepingSearch(`./${favorite.chat.id}/${favorite.message.id}`),
        [navigateKeepingSearch]
    );
    const handleFilterSubmit = useCallback(
        (filter: string) => updateSearchParams({ filter: filter }),
        [updateSearchParams]
    );
    useEffect(() => {
        function listener() {
            revalidate(false);
        }
        favoriteApi.addEventListener('favoritesupdated', listener);
        return () => {
            favoriteApi.removeEventListener('favoritesupdated', listener);
        };
    }, [revalidate]);
    return (
        <StateView state={favoritesState} retry={revalidate} loading={LoadingBookmarksList}>
            {(loaderData) => {
                loaderData.sort((a, b) => b.message.date.valueOf() - a.message.date.valueOf());
                const activeFavoriteMessage = {
                    messageId: routeParams.messageId as MessageId,
                    chatId: routeParams.chatId as ChatId
                } as FavoriteMessage;
                return (
                    <FavoritesScreenView
                        favoriteMessages={loaderData}
                        activeFavoriteMessage={
                            activeFavoriteMessage &&
                            loaderData.some((x) => x.message.id === activeFavoriteMessage.messageId)
                                ? activeFavoriteMessage
                                : undefined
                        }
                        onFavoriteMessageChoose={handleFavoriteChoose}
                        filter={filter}
                        onFilterSubmit={handleFilterSubmit}
                    />
                );
            }}
        </StateView>
    );
}
