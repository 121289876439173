import { store } from '../../store';
import {
    selectCachedAttachment,
    selectCachedAttachmentsByThreadId,
    selectCachedForm,
    selectCachedForms,
    selectCachedThread,
    selectCachedThreads
} from '../../store/DataCacheSlice';
import * as types from '../../types/Types';
import { Debouncer } from '../../utils/Debouncer';
import { api } from '../Api';
import { KvdbCollection } from './kvdb/KvdbCollection';
import { KvdbSettingEntryX, KvdbUtils } from './kvdb/KvdbUtils';
import { PrivmxConst } from './PrivmxConst';
import { UnreadContainer } from './UnreadContainer';
import { Utils } from './utils/Utils';

export interface EventDispatcher {
    dispatchEvent<T extends { type: string }>(event: T): void;
}

interface UnreadServiceSaveOptions {
    optimizeAttachmentsDataForThreadIds?: types.ThreadId[];
    optimizeMessagesDataForThreadIds?: types.ThreadId[];
    optimizeSubmitsDataForFormIds?: types.FormId[];
}

export class UnreadService {
    private data: types.ReadItems;
    private unreadStateChangedEventDebouncer = new Debouncer(50, () =>
        this.dispatchUnreadStateChangedEventCore()
    );
    private saveDebouncer = new Debouncer(1000, (callId) => this.saveCore(callId));
    private nextSaveOptions: UnreadServiceSaveOptions = {};
    private messagesUnreadContainer: UnreadContainer<
        types.MessageId,
        types.ThreadId,
        types.Message,
        types.Thread
    >;
    private attachmentsUnreadContainer: UnreadContainer<
        types.AttachmentId,
        types.ThreadId,
        types.AttachmentEx,
        types.Thread
    >;
    private submitsUnreadContainer: UnreadContainer<
        types.FormRowId,
        types.FormId,
        types.FormRow,
        types.FormModel2
    >;

    constructor(
        private userSettingsKvdb: KvdbCollection<KvdbSettingEntryX>,
        private eventDispatcher: EventDispatcher
    ) {
        const readData = this.userSettingsKvdb.getSync(PrivmxConst.READ_ITEMS_KEY)?.secured.value;
        if (readData) {
            this.data = readData as types.ReadItems;
        } else {
            this.data = this.getEmptyReadItems();
        }

        this.messagesUnreadContainer = new UnreadContainer(
            (containerId, createEmptyData) => {
                if (!this.data.messages[containerId]) {
                    this.data.messages[containerId] = createEmptyData();
                }
                return this.data.messages[containerId];
            },
            (item) => item.threadId,
            (item) => item.date,
            (item) => item.author,
            (container) => container.mesagesCount,
            (container) => container.lastMsgDate,
            (containerId) => selectCachedThread(containerId)(store.getState()),
            (containerId) => api.getThreadMessages(containerId),
            null,
            (needsOptimizingDataForContainerIds) => {
                this.dispatchUnreadStateChangedEventDebounced();
                return this.save({
                    optimizeMessagesDataForThreadIds: needsOptimizingDataForContainerIds ?? []
                });
            }
        );
        this.attachmentsUnreadContainer = new UnreadContainer(
            (containerId, createEmptyData) => {
                if (!this.data.attachments[containerId]) {
                    this.data.attachments[containerId] = createEmptyData();
                }
                return this.data.attachments[containerId];
            },
            (item) => item.chatId,
            (item) => item.date,
            (item) => item.author,
            (container) => container.filesCount,
            (container) => {
                const dates = selectCachedAttachmentsByThreadId(container.id)(store.getState()).map(
                    (x) => x.date
                );
                const lastDate = Math.max(...dates);
                return (lastDate ?? 0) as types.Timestamp;
            },
            (containerId) => selectCachedThread(containerId)(store.getState()),
            (containerId) =>
                Promise.resolve(selectCachedAttachmentsByThreadId(containerId)(store.getState())),
            (containerId) => selectCachedAttachmentsByThreadId(containerId)(store.getState()),
            (needsOptimizingDataForContainerIds) => {
                this.dispatchUnreadStateChangedEventDebounced();
                return this.save({
                    optimizeMessagesDataForThreadIds: needsOptimizingDataForContainerIds ?? []
                });
            }
        );
        this.submitsUnreadContainer = new UnreadContainer(
            (containerId, createEmptyData) => {
                if (!this.data.submits[containerId]) {
                    this.data.submits[containerId] = createEmptyData();
                }
                return this.data.submits[containerId];
            },
            (item) => item.formId,
            (item) => item.date,
            (item) => item.filledBy,
            (container) => container.entriesCount,
            (container) => container.lastSubmitDate,
            (containerId) => selectCachedForm(containerId)(store.getState()),
            (containerId) => api.getFormSubmits(containerId),
            null,
            (needsOptimizingDataForContainerIds) => {
                this.dispatchUnreadStateChangedEventDebounced();
                return this.save({
                    optimizeSubmitsDataForFormIds: needsOptimizingDataForContainerIds ?? []
                });
            }
        );
    }

    isThreadMessageRead(message: types.Message) {
        return this.messagesUnreadContainer.isItemRead(message);
    }

    areAllMessagesInThreadRead(threadId: types.ThreadId) {
        const thread = this.getThreadFromId(threadId);
        if (!thread) {
            return true;
        }
        return this.messagesUnreadContainer.isContainerRead(thread);
    }

    areAllMessagesInThreadsRead(threadIds: types.ThreadId[]) {
        const threads = this.getThreadsFromIds(threadIds);
        return this.messagesUnreadContainer.areAllContainersRead(threads);
    }

    isAttachmentRead(attachmentId: types.AttachmentId) {
        const attachment = this.getAttachmentFromId(attachmentId);
        if (!attachment) {
            return true;
        }
        return this.attachmentsUnreadContainer.isItemRead(attachment);
    }

    areAllAttachmentsInThreadsRead(threadIds: types.ThreadId[]) {
        const threads = this.getThreadsFromIds(threadIds);
        return this.attachmentsUnreadContainer.areAllContainersRead(threads);
    }

    areAllAttachmentsRead(attachmentIds: types.AttachmentId[]) {
        const attachments = this.getAttachmentsFromIds(attachmentIds);
        return this.attachmentsUnreadContainer.areAllItemsRead(attachments);
    }

    isFormSubmitRead(formSubmit: types.FormRow) {
        return this.submitsUnreadContainer.isItemRead(formSubmit);
    }

    getUnreadFormSubmitIds(formSubmits: types.FormRow[]) {
        return formSubmits.filter((x) => !this.isFormSubmitRead(x)).map((x) => x.id);
    }

    areAllSubmitsInFormRead(formId: types.FormId) {
        const form = this.getFormFromId(formId);
        if (!form) {
            return true;
        }
        return this.submitsUnreadContainer.isContainerRead(form);
    }

    areAllSubmitsInFormsRead(formIds: types.FormId[]) {
        const forms = this.getFormsFromIds(formIds);
        return this.submitsUnreadContainer.areAllContainersRead(forms);
    }

    isFormSubmitReadDeep(formSubmit: types.FormRow) {
        if (!this.submitsUnreadContainer.isItemRead(formSubmit)) {
            return false;
        }
        const threadId = formSubmit.chatId;
        if (threadId && !this.areAllMessagesInThreadRead(threadId)) {
            return false;
        }
        if (threadId && !this.areAllAttachmentsInThreadsRead([threadId])) {
            return false;
        }
        return true;
    }

    getUnreadFormSubmitIdsDeep(formSubmits: types.FormRow[]) {
        return formSubmits.filter((x) => !this.isFormSubmitReadDeep(x)).map((x) => x.id);
    }

    areAllSubmitsInFormReadDeep(formId: types.FormId) {
        const form = this.getFormFromId(formId);
        if (!form) {
            return true;
        }
        if (!this.submitsUnreadContainer.isContainerRead(form)) {
            return false;
        }
        const submits = store
            .getState()
            .dataCache.formsWithSubmitsThatHaveChats.submits.filter(
                (x) => x.formId === formId && x.chatId
            );
        const threadIds = submits.map((x) => x.chatId!);
        for (const threadId of threadIds) {
            if (!this.areAllMessagesInThreadRead(threadId)) {
                return false;
            }
            if (!this.areAllAttachmentsInThreadsRead([threadId])) {
                return false;
            }
        }
        return true;
    }

    areAllSubmitsInFormsReadDeep(formIds: types.FormId[]) {
        const forms = this.getFormsFromIds(formIds);
        if (!this.submitsUnreadContainer.areAllContainersRead(forms)) {
            return false;
        }
        const submits = store
            .getState()
            .dataCache.formsWithSubmitsThatHaveChats.submits.filter(
                (x) => formIds.includes(x.formId) && x.chatId
            );
        const threadIds = submits.map((x) => x.chatId!);
        for (const threadId of threadIds) {
            if (!this.areAllMessagesInThreadRead(threadId)) {
                return false;
            }
            if (!this.areAllAttachmentsInThreadsRead([threadId])) {
                return false;
            }
        }
        return true;
    }

    isEverythingRead(
        threadIdsForMessagesCheck: types.ThreadId[],
        threadIdsForAttachmentsCheck: types.ThreadId[],
        formIdsForSubmitsCheck: types.FormId[],
        formIdsForSubmitsCheckDeep: types.FormId[]
    ) {
        if (!this.areAllMessagesInThreadsRead(threadIdsForMessagesCheck)) {
            return false;
        }
        if (!this.areAllSubmitsInFormsRead(formIdsForSubmitsCheck)) {
            return false;
        }
        if (!this.areAllSubmitsInFormsReadDeep(formIdsForSubmitsCheckDeep)) {
            return false;
        }
        if (!this.areAllAttachmentsInThreadsRead(threadIdsForAttachmentsCheck)) {
            return false;
        }
        return true;
    }

    markAllMessagesInThreadAsRead(threadId: types.ThreadId) {
        const thread = this.getThreadFromId(threadId);
        if (!thread) {
            return;
        }
        return this.messagesUnreadContainer.markContainerAsRead(thread);
    }

    markAllMessagesinThreadsAsRead(threadIds: types.ThreadId[]) {
        const threads = this.getThreadsFromIds(threadIds);
        return this.messagesUnreadContainer.markContainersAsRead(threads);
    }

    markMessageAsRead(message: types.Message) {
        return this.messagesUnreadContainer.markItemAsRead(message);
    }

    markMessageAsUnread(message: types.Message) {
        return this.messagesUnreadContainer.markItemAsUnread(message);
    }

    markMessagesAsRead(messages: types.Message[]) {
        return this.messagesUnreadContainer.markItemsAsRead(messages);
    }

    markMessagesAsUnread(messages: types.Message[]) {
        return this.messagesUnreadContainer.markItemsAsUnread(messages);
    }

    markAllAttachmentsInThreadAsRead(threadId: types.ThreadId) {
        const thread = this.getThreadFromId(threadId);
        if (!thread) {
            return;
        }
        return this.attachmentsUnreadContainer.markContainerAsRead(thread);
    }

    markAllAttachmentsInThreadsAsRead(threadIds: types.ThreadId[]) {
        const threads = this.getThreadsFromIds(threadIds);
        return this.attachmentsUnreadContainer.markContainersAsRead(threads);
    }

    markAttachmentAsRead(attachmentId: types.AttachmentId) {
        const attachment = this.getAttachmentFromId(attachmentId);
        if (!attachment) {
            return;
        }
        return this.attachmentsUnreadContainer.markItemAsRead(attachment);
    }

    markAttachmentAsUnread(attachmentId: types.AttachmentId) {
        const attachment = this.getAttachmentFromId(attachmentId);
        if (!attachment) {
            return;
        }
        return this.attachmentsUnreadContainer.markItemAsUnread(attachment);
    }

    markAttachmentsAsRead(attachmentIds: types.AttachmentId[]) {
        const attachments = this.getAttachmentsFromIds(attachmentIds);
        return this.attachmentsUnreadContainer.markItemsAsRead(attachments);
    }

    markAttachmentsAsUnread(attachmentIds: types.AttachmentId[]) {
        const attachments = this.getAttachmentsFromIds(attachmentIds);
        return this.attachmentsUnreadContainer.markItemsAsUnread(attachments);
    }

    markFormSubmitAsRead(formSubmit: types.FormRow) {
        return this.submitsUnreadContainer.markItemAsRead(formSubmit);
    }

    markFormSubmitAsUnread(formSubmit: types.FormRow) {
        return this.submitsUnreadContainer.markItemAsUnread(formSubmit);
    }

    markFormSubmitsAsRead(formSubmits: types.FormRow[]) {
        return this.submitsUnreadContainer.markItemsAsRead(formSubmits);
    }

    markFormSubmitsAsUnread(formSubmits: types.FormRow[]) {
        return this.submitsUnreadContainer.markItemsAsUnread(formSubmits);
    }

    markAllSubmitsInFormAsRead(formId: types.FormId) {
        const form = this.getFormFromId(formId);
        if (!form) {
            return;
        }
        return this.submitsUnreadContainer.markContainerAsRead(form);
    }

    markAllSubmitsInFormsAsRead(formIds: types.FormId[]) {
        const forms = this.getFormsFromIds(formIds);
        return this.submitsUnreadContainer.markContainersAsRead(forms);
    }

    markFormSubmitAsReadDeep(formSubmit: types.FormRow) {
        this.submitsUnreadContainer.markItemAsRead(formSubmit);
        const threadId = formSubmit.chatId;
        if (threadId) {
            this.markAllMessagesInThreadAsRead(threadId);
            this.markAllAttachmentsInThreadAsRead(threadId);
        }
    }

    markFormSubmitsAsReadDeep(formSubmits: types.FormRow[]) {
        this.submitsUnreadContainer.markItemsAsRead(formSubmits);
        const threadIds = formSubmits.map((x) => x.chatId).filter((x) => !!x) as types.ThreadId[];
        this.markAllMessagesinThreadsAsRead(threadIds);
        this.markAllAttachmentsInThreadsAsRead(threadIds);
    }

    async markAllAsRead() {
        const state = store.getState();
        const threads = selectCachedThreads(state);
        const forms = selectCachedForms(state);

        this.markAllMessagesinThreadsAsRead(threads.map((x) => x.id));
        this.markAllSubmitsInFormsAsRead(forms.map((x) => x.id));
        this.markAllAttachmentsInThreadsAsRead(threads.map((x) => x.id));
        return this.save();
    }

    private save(options?: UnreadServiceSaveOptions) {
        this.nextSaveOptions.optimizeAttachmentsDataForThreadIds = Utils.unique([
            ...(this.nextSaveOptions.optimizeAttachmentsDataForThreadIds ?? []),
            ...(options?.optimizeAttachmentsDataForThreadIds ?? [])
        ]);
        this.nextSaveOptions.optimizeMessagesDataForThreadIds = Utils.unique([
            ...(this.nextSaveOptions.optimizeMessagesDataForThreadIds ?? []),
            ...(options?.optimizeMessagesDataForThreadIds ?? [])
        ]);
        this.nextSaveOptions.optimizeSubmitsDataForFormIds = Utils.unique([
            ...(this.nextSaveOptions.optimizeSubmitsDataForFormIds ?? []),
            ...(options?.optimizeSubmitsDataForFormIds ?? [])
        ]);
        this.saveDebouncer.schedule();
    }

    private async saveCore(callId: number) {
        const {
            optimizeAttachmentsDataForThreadIds,
            optimizeMessagesDataForThreadIds,
            optimizeSubmitsDataForFormIds
        } = this.nextSaveOptions;
        this.nextSaveOptions = {};
        if (optimizeAttachmentsDataForThreadIds) {
            await this.attachmentsUnreadContainer.optimizeReadItemsData(
                optimizeAttachmentsDataForThreadIds
            );
        }
        if (optimizeMessagesDataForThreadIds) {
            await this.messagesUnreadContainer.optimizeReadItemsData(
                optimizeMessagesDataForThreadIds
            );
        }
        if (optimizeSubmitsDataForFormIds) {
            await this.submitsUnreadContainer.optimizeReadItemsData(optimizeSubmitsDataForFormIds);
        }
        if (callId !== this.saveDebouncer.getLastCallId()) {
            return;
        }
        await this.userSettingsKvdb.set(
            PrivmxConst.READ_ITEMS_KEY,
            KvdbUtils.createKvdbSettingEntry(this.data)
        );
        this.dispatchUnreadStateChangedEventCore();
    }

    private getEmptyReadItems(): types.ReadItems {
        return {
            messages: {},
            attachments: {},
            submits: {}
        };
    }

    dispatchUnreadStateChangedEventDebounced() {
        this.unreadStateChangedEventDebouncer.schedule();
    }

    dispatchUnreadStateChangedEvent() {
        this.unreadStateChangedEventDebouncer.runSync();
    }

    private dispatchUnreadStateChangedEventCore() {
        this.eventDispatcher.dispatchEvent<types.UnreadStateChangedEvent>({
            type: 'unreadstatechanged'
        });
    }

    private getThreadFromId(threadId: types.ThreadId) {
        const state = store.getState();
        const thread = selectCachedThread(threadId)(state);
        return thread;
    }

    private getThreadsFromIds(threadIds: types.ThreadId[]) {
        const state = store.getState();
        const threads = threadIds
            .map((threadId) => selectCachedThread(threadId)(state))
            .filter((x) => !!x) as types.Thread[];
        return threads;
    }

    private getFormFromId(formId: types.FormId) {
        const state = store.getState();
        const form = selectCachedForm(formId)(state);
        return form;
    }

    private getFormsFromIds(formIds: types.FormId[]) {
        const state = store.getState();
        const forms = formIds
            .map((formId) => selectCachedForm(formId)(state))
            .filter((x) => !!x) as types.FormModel2[];
        return forms;
    }

    private getAttachmentFromId(attachmentId: types.AttachmentId) {
        const state = store.getState();
        const attachment = selectCachedAttachment(attachmentId)(state);
        return attachment;
    }

    private getAttachmentsFromIds(attachmentIds: types.AttachmentId[]) {
        const state = store.getState();
        const attachments = attachmentIds
            .map((attachmentId) => selectCachedAttachment(attachmentId)(state))
            .filter((x) => !!x) as types.AttachmentEx[];
        return attachments;
    }
}
