import { useCallback, useEffect, useState } from 'react';
import { AttachmentPreviewModal } from '../components/AttachmentPreview/AttachmentPreviewModal';
import { AttachmentTagsEditModal } from '../components/AttachmentTagsEdit/AttachmentTagsEditModal';
import { AttachmentVersionsModal } from '../components/AttachmentVersions/AttachmentVersionsModal';
import { ChatEditModal } from '../components/ChatEditPage/ChatEditModal';
import { ChatModal } from '../components/ChatPage/ChatModal';
import { CompanyAddModal } from '../components/CompanyEditPage/CompanyAddModal';
import { CompanyEditModal } from '../components/CompanyEditPage/CompanyEditModal';
import { CompanyModal } from '../components/CompanyPage/CompanyModal';
import { ContactAddModal } from '../components/ContactEditPage/ContactAddModal';
import { ContactModal } from '../components/ContactPage/ContactModal';
import { CreateAccountModal } from '../components/CreateAccount/CreateAccountModal';
import { CreatedUserDataModal } from '../components/CreatedUserData/CreatedUserDataModal';
import { CreateUserModal } from '../components/CreateUser/CreateUserModal';
import { FormAddModal } from '../components/FormCreator/FormAddModal';
import { FormEditModal } from '../components/FormCreator/FormEditModal';
import { MeetingLobbyManagementModal } from '../components/MeetingLobbyManagementPage/MeetingLobbyManagementModal';
import { MeetingEditModal } from '../components/MeetingPage/MeetingModals/MeetingEditPage/MeetingEditModal';
import { MeetingModal } from '../components/MeetingPage/MeetingModal';
import { PasswordChangePromptModal } from '../components/PasswordChangePrompt/PasswordChangePromptModal';
import { SingleChatMessageModal } from '../components/SingleChatMessage/SingleChatMessageModal';
import { TwofaPromptModal } from '../components/TwofaPrompt/TwofaPromptModal';
import { VideoConferenceModal } from '../components/VideoConference/VideoConferenceModal';
import { BaseModalData, ModalId } from '../types/Modals';
import { FormRowEditModal } from '../components/FormRowEditPage/FormRowEditModal';
import { SharedFileModal } from '../components/SharedFilePage/SharedFileModal';
import { SharedFileEditModal } from '../components/SharedFileEditPage/SharedFileEditModal';
import { SharedFileAddModal } from '../components/SharedFileEditPage/SharedFileAddModal';
import { EmailInboxAddModal } from '../components/EmailInboxEditPage/EmailInboxAddModal';
import { EmailInboxEditModal } from '../components/EmailInboxEditPage/EmailInboxEditModal';
import { EmailInboxInstructionsModal } from '../components/EmailInboxInstructions/EmailInboxInstructionsModal';
import { FormShareModal } from '../components/ShareFormView/ShareFormModal';
import { DuplicatedFilesActionModal } from '../components/DuplicatedFilesActionModal/DuplicatedFilesActionModal';
import { AlertModal } from '../components/Alert/AlertModal';

type Listener = () => void;

export interface ModalController<T> {
    id: ModalId;
    update(data: T): void;
    close(): void;
}

type ModalComponent<T = any> = (props: {
    onClose: () => void;
    data: T;
    index: number;
    onFocus: () => void;
}) => JSX.Element;

export interface ModalState<T = any> {
    id: ModalId;
    component: ModalComponent<T>;
    onClose: () => void;
    data: T;
}

class ModalStore {
    private modalIndex = 0;
    private modals: ModalState[] = [];
    private listeners: Listener[] = [];

    getModals() {
        return this.modals;
    }

    get index() {
        return this.modalIndex;
    }

    focusModal(index: number) {
        const state = this.modals;
        const focusedModal = state.splice(index, 1)[0];
        state.push(focusedModal);
        this.modals = state;
        this.dispatch();
    }

    addModal<T>(component: ModalComponent<T>, data: T) {
        this.modalIndex++;
        const id = ('modal-' + this.modalIndex) as ModalId;
        const modal: ModalState<T> = {
            id: id,
            component: component,
            onClose: () => this.closeModal(id),
            data: data
        };
        this.modals.push(modal);
        this.dispatch();
        return id;
    }

    updateModal<T>(id: ModalId, data: T) {
        this.modals = this.modals.map((x) => {
            if (x.id === id) {
                const newState: ModalState = {
                    id: x.id,
                    component: x.component,
                    onClose: x.onClose,
                    data: data
                };
                return newState;
            }
            return x;
        });
        this.dispatch();
    }

    closeModal(id: ModalId) {
        const modal = this.modals.find((x) => x?.id === id);
        if (modal) {
            this.modals = this.modals.filter((x) => x?.id !== id);
            this.dispatch();
        }
        this.modals = this.modals.filter(Boolean);
    }

    addListener(listener: Listener) {
        this.listeners = [...this.listeners, listener];
    }

    removeListener(listener: Listener) {
        this.listeners = this.listeners.filter((x) => x !== listener);
    }

    private dispatch() {
        for (const listener of this.listeners) {
            try {
                listener();
            } catch (e) {
                console.error('Error during calling listener', e);
            }
        }
    }
}

export const modalStore = new ModalStore();

export function useModals() {
    const [state, setState] = useState({ modals: modalStore.getModals() });
    useEffect(() => {
        const listener = () => {
            setState({ modals: modalStore.getModals() });
        };
        modalStore.addListener(listener);
        return () => {
            modalStore.removeListener(listener);
        };
    }, []);

    const onFocus = useCallback((i: number) => {
        modalStore.focusModal(i);
    }, []);

    return {
        modals: state.modals,
        onFocus
    };
}

export default function useModal() {
    return modalService;
}

function registerModal<T extends BaseModalData>(modalComponent: ModalComponent<T>) {
    return (data: T) => {
        const modalId = modalStore.addModal(modalComponent, { ...data });
        const res: ModalController<T> = {
            id: modalId,
            update: (data) => {
                modalStore.updateModal(modalId, data);
            },
            close: () => {
                modalStore.closeModal(modalId);
            }
        };
        return res;
    };
}

export const modalService = {
    openAlertModal: registerModal(AlertModal),
    openContactModal: registerModal(ContactModal),
    openContactAddModal: registerModal(ContactAddModal),
    openCompanyModal: registerModal(CompanyModal),
    openCompanyAddModal: registerModal(CompanyAddModal),
    openCompanyEditModal: registerModal(CompanyEditModal),
    openChatModal: registerModal(ChatModal),
    openChatEditModal: registerModal(ChatEditModal),
    openMeetingModal: registerModal(MeetingModal),
    openMeetingEditModal: registerModal(MeetingEditModal),
    openMeetingLobbyManagementModal: registerModal(MeetingLobbyManagementModal),
    openFormAddModal: registerModal(FormAddModal),
    openFormEditModal: registerModal(FormEditModal),
    openEmailInboxAddModal: registerModal(EmailInboxAddModal),
    openEmailInboxEditModal: registerModal(EmailInboxEditModal),
    openEmailInboxInstructionsModal: registerModal(EmailInboxInstructionsModal),
    openTwofaPromptModal: registerModal(TwofaPromptModal),
    openCreateUserModal: registerModal(CreateUserModal),
    openAttachmentTagsEditModal: registerModal(AttachmentTagsEditModal),
    openCreateAccountModal: registerModal(CreateAccountModal),
    openAttachmentVersionsModal: registerModal(AttachmentVersionsModal),
    openSingleChatMessageModal: registerModal(SingleChatMessageModal),
    openCreatedUserDataModal: registerModal(CreatedUserDataModal),
    openPasswordChangePromptModal: registerModal(PasswordChangePromptModal),
    openAttachmentPreviewModal: registerModal(AttachmentPreviewModal),
    openVideoConferenceModal: registerModal(VideoConferenceModal),
    openFormRowEditModal: registerModal(FormRowEditModal),
    openSharedFileModal: registerModal(SharedFileModal),
    openAddSharedFileModal: registerModal(SharedFileAddModal),
    openEditSharedFileModal: registerModal(SharedFileEditModal),
    openShareFormModal: registerModal(FormShareModal),
    openDuplicatedFilesActionModal: registerModal(DuplicatedFilesActionModal)
};
