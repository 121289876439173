import { Input } from '@mantine/core';
import * as FormsTypes from '../FormsTypes';
import { isValueAString } from '../utils/FormsUtils';
export default function PhoneInput({ options }: { options: FormsTypes.RenderFormElementOptions }) {
    const { inputRef, model, updateDirty } = options;
    const defaultValue = isValueAString(model.value) ? model.value : undefined;

    const refreshDirty = (e: React.FormEvent<HTMLElement>) => {
        inputRef.current.isDirty = (e.currentTarget as any).value.length > 0;
        updateDirty();
    };
    return (
        <Input
            required={model.required}
            id={model.id}
            defaultValue={defaultValue}
            ref={inputRef}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                refreshDirty(e);
            }}
        />
    );
}
