import { createStyles, rem } from '@mantine/core';

export const useNewThreadStyles = createStyles((theme) => ({
    content: {
        maxHeight: '90vh',
        [`@media (min-width:${theme.breakpoints.md})`]: {
            maxHeight: 800,
            maxWidth: 1200
        },
        [`@media (min-width:${rem(850)})`]: {
            minWidth: 832
        }
    },
    inner: {
        alignItems: 'center'
    },
    body: {
        height: 'calc(100% - 60px)',
        paddingBottom: 0,
        [`@media (min-width:${rem(850)})`]: {
            minWidth: 832
        }
    }
}));
