import React from 'react';
import { QuestionModel, AnswerType } from '../../../types/Types';

export type FormDispatch = React.Dispatch<FormAction>;

export type FormType =
    | 'add_question'
    | 'change_title'
    | 'change_questionType'
    | 'change_content'
    | 'add_option'
    | 'change_option'
    | 'remove_option'
    | 'remove_question'
    | 'change_required'
    | 'move_question';

export interface BaseAction {
    type: FormType;
}

export interface BaseChangeAction extends BaseAction {
    newValue: string;
    index: number;
}

export interface BaseModifyAction extends BaseAction {
    index: number;
}

export interface ChangeTitle extends BaseChangeAction {
    type: 'change_title';
}

export interface ChangeQuestionType extends BaseChangeAction {
    type: 'change_questionType';
}

export interface ChangeRequired extends BaseAction {
    type: 'change_required';
    index: number;
    newValue: boolean;
}

export interface ChangeOption extends BaseChangeAction {
    type: 'change_option';
    optionIndex: number;
}

export interface RemoveOption extends BaseAction {
    index: number;
    type: 'remove_option';
    optionIndex: number;
}

export interface RemoveQuestion extends BaseModifyAction {
    type: 'remove_question';
}

export interface LoadForm {
    type: 'load_form';
    questions: QuestionModel[];
}

export interface MoveQuestion extends BaseModifyAction {
    type: 'move_question';
    direction: 'up' | 'down';
}

export interface AddOption extends BaseModifyAction {
    type: 'add_option';
}

export interface ChangeTextBlockContent extends BaseChangeAction {
    type: 'change_content';
}

export interface AddQuestion {
    type: Extract<FormType, 'add_question'>;
    questionType?: AnswerType;
}

export type FormAction =
    | ChangeTextBlockContent
    | ChangeOption
    | AddOption
    | ChangeRequired
    | ChangeTitle
    | ChangeQuestionType
    | RemoveOption
    | RemoveQuestion
    | LoadForm
    | MoveQuestion
    | AddQuestion;

export const answerType = [
    'short',
    'select',
    'single',
    'long',
    'file',
    'block',
    'email',
    'phone'
] as AnswerType[];

export const answerOptions: Record<AnswerType, string> = {
    long: 'Long text',
    short: 'Short text',
    single: 'Single choice',
    select: 'Multiple choice',
    // range: 'Range input',
    file: 'Attachment',
    block: 'Text block',
    email: 'E-mail',
    phone: 'Phone'
};
export function isAnswerType(x: string): x is AnswerType {
    if (answerType.find((t) => t === x)) return true;
    return false;
}

export type AnswerableBlock = Exclude<QuestionModel, { type: 'block' }>;
