import {
    useCallback,
    useReducer,
    createContext,
    useContext,
    useState,
    useEffect,
    useRef,
    SetStateAction,
    Dispatch
} from 'react';
import { formReducer } from './creatorReducer';
import useLoader from '../../../../../hooks/useLoader';
import { FormId, FormModel } from '../../../../../types/Types';
import StateView from '../../../../../atoms/State';
import { emptyFormModel } from '../../../../../data/formTemplates';

export function useFormCreatorState() {
    const [state, dispatch] = useReducer(formReducer, []);
    return [state, dispatch] as const;
}

export interface FormQuestionError {
    type: string;
    questionIndex: number;
    answerIndex: number;
    message: string;
    titleError?: string;
}

type FormCreatorContextType = {
    creatorState: ReturnType<typeof useFormCreatorState>['0'];
    dispatch: ReturnType<typeof useFormCreatorState>['1'];
    formModel: FormModel;
    formName: ReturnType<typeof useState<string>>;
    formAutoResponse: ReturnType<typeof useState<string>>;
    formNameError: ReturnType<typeof useState<string | null>>;
    errors: FormQuestionError[];
    setErrors: Dispatch<SetStateAction<FormQuestionError[]>>;
    revalidate: (switchToLoadingState?: boolean) => void;
};

const formCreatorContext = createContext<FormCreatorContextType>({
    creatorState: [],
    dispatch: () => {},
    formModel: { ...emptyFormModel },
    formName: ['', () => {}],
    formAutoResponse: ['', () => {}],
    formNameError: [null, () => {}],
    errors: [],
    setErrors: () => {},
    revalidate: () => {}
});

export function useFormCreatorContext() {
    return useContext(formCreatorContext);
}

export function FormContextProvider({
    formId,
    children
}: {
    formId?: string;
    children: React.ReactNode;
}) {
    const [creatorState, dispatch] = useFormCreatorState();

    const { state, revalidate } = useLoader(
        useCallback((x) => x.loadFormModel(formId as FormId), [formId])
    );
    const [errors, setErrors] = useState<FormQuestionError[]>([]);

    const formName = useState<string>();
    const formAutoResponse = useState<string>();

    const formNameError = useState<string | null>();

    const synced = useRef<boolean>(false);

    useEffect(() => {
        if (isNewFormId(formId) && !synced.current) {
            formName[1]('');
            synced.current = true;
        }
    }, [formName, formId, formAutoResponse]);

    useEffect(() => {
        if (state.type === 'done' && !isNewFormId(formId) && !synced.current) {
            formName[1](state.data.form.name);
            formAutoResponse[1](state.data.form.autoResponseData.emailFieldId);
            synced.current = true;
        }
    }, [formId, state, formName, formAutoResponse]);

    return (
        <StateView retry={revalidate} state={state}>
            {(data) => (
                <formCreatorContext.Provider
                    value={{
                        revalidate,
                        errors,
                        setErrors,
                        creatorState,
                        dispatch,
                        formModel: data.form,
                        formName,
                        formAutoResponse,
                        formNameError
                    }}>
                    {children}
                </formCreatorContext.Provider>
            )}
        </StateView>
    );
}

export function isNewFormId(formId: string | undefined) {
    if (formId === undefined) {
        return false;
    }
    return formId === 'new' || formId.startsWith('new-tpl-');
}
