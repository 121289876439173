import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../../api/Api';
import { CompanyEditLoaderData } from '../../utils/router/loaders';
import {
    Company,
    CompanyAddress,
    CompanyId,
    CompanyName,
    CompanyNote,
    CompanyWebsite,
    Email,
    MobilePhone,
    Phone,
    Timestamp,
    Username
} from '../../types/Types';
import ModalForm from '../ModalForm';
import { useSearchParamsEx } from '../../hooks/useLoader';
import { usePreventClose } from '../../hooks/usePreventClose';
import { useTranslation } from 'react-i18next';

type FormData = Pick<
    Company,
    'name' | 'email' | 'website' | 'address' | 'phone' | 'mobilePhone' | 'note'
>;

export function CompanyEditPage(props: {
    companyEdit: CompanyEditLoaderData;
    handleClose: () => void;
}) {
    const { t } = useTranslation();
    const company =
        props.companyEdit.company && typeof props.companyEdit.company === 'object'
            ? props.companyEdit.company
            : null;
    const origCompany = company;
    const companyId = company?.id;
    const tags = useMemo(() => company?.tags ?? [], [company?.tags]);
    const pinned = company?.pinned ?? false;
    const archived = company?.archived ?? false;
    const form = useForm<FormData>({
        defaultValues: {
            name: company?.name,
            email: company?.email,
            website: company?.website,
            address: company?.address,
            phone: company?.phone,
            mobilePhone: company?.mobilePhone,
            note: company?.note
        }
    });
    usePreventClose(form.formState.isDirty);
    const setError = form.setError;
    const handleClose = props.handleClose;
    const { navigateKeepingSearch } = useSearchParamsEx();

    const handleFormSubmit = useCallback(
        async (data: FormData) => {
            if (data.name.trim() === '') {
                setError('name', {});
                return;
            }
            try {
                const company: Company = {
                    createdBy: '' as Username,
                    createdDate: 0 as Timestamp,
                    lastModifiedBy: '' as Username,
                    lastModifiedDate: 0 as Timestamp,
                    ...origCompany,
                    id: companyId || ('' as CompanyId),
                    name: data.name.trim() as CompanyName,
                    email: data.email.trim() as Email,
                    website: data.email.trim() as CompanyWebsite,
                    address: data.address.trim() as CompanyAddress,
                    phone: data.phone.trim() as Phone,
                    mobilePhone: data.mobilePhone.trim() as MobilePhone,
                    note: data.note.trim() as CompanyNote,
                    tags: tags,
                    pinned: pinned,
                    archived: archived
                };
                if (companyId) {
                    await api.updateCompany(company);
                } else {
                    const newCompany = await api.addCompany(company);
                    navigateKeepingSearch(`./companies/${newCompany.id}`);
                }
            } catch (e) {}
            handleClose();
        },
        [
            companyId,
            handleClose,
            setError,
            navigateKeepingSearch,
            tags,
            pinned,
            archived,
            origCompany
        ]
    );

    return (
        <ModalForm onSubmit={handleFormSubmit} form={form} onClose={handleClose}>
            <ModalForm.TextField<FormData> name="name" required label={t('entityProps.name')} />
            <ModalForm.TextField name="address" label={t('entityProps.address')} />
            <ModalForm.TextField name="email" label={t('entityProps.email')} />
            <ModalForm.TextField name="website" label={t('entityProps.website')} />
            <ModalForm.TextField name="mobilePhone" label={t('entityProps.mobile')} />
            <ModalForm.TextField name="phone" label={t('entityProps.telephone')} />
            <ModalForm.TextareaField name="note" span={2} label={t('entityProps.note')} />
        </ModalForm>
    );
}

export default CompanyEditPage;
