import { Center, Stack, Title, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export function ContactScreenInitial() {
    const { t } = useTranslation();
    return (
        <Center h="100%">
            <Stack>
                <Title color="dimmed" align="center" order={2}>
                    {t('screen.contacts.seeUserProfiles')}
                </Title>
                <Text color="dimmed" align="center">
                    {t('screen.contacts.selectContact')}
                </Text>
            </Stack>
        </Center>
    );
}
