import { ContactImportData } from './ContactImportData';

export class Csv {
    static parse(str: string): ContactImportData[] {
        const lines = str.replace(/\r/g, '').split('\n');
        const [headersStr, ...rows] = lines;
        const headers = headersStr.split(',');
        const dataArr: ContactImportData[] = [];
        for (const row of rows) {
            const cols = row.split(',');
            const data: ContactImportData = {
                name: this.readValue(cols, headers, [
                    ['first name', 'last name'],
                    ['name'],
                    ['given name', 'family name']
                ])!,
                phone: this.readValue(cols, headers, [[/^phone [0-9] - value$/gi], [/^phone$/gi]]),
                email: this.readValue(cols, headers, [
                    [/^e-mail [0-9] - value$/gi],
                    [/^email( [0-9])? address$/gi],
                    [/e-mail/gi]
                ]),
                address: this.readValue(cols, headers, [
                    [/^(home|business|other) address$/gi],
                    [/^address [0-9] - formatted$/gi]
                ]),
                note: this.readValue(cols, headers, [['notes']])
            };
            dataArr.push(data);
        }
        return dataArr;
    }

    static readValue(
        values: string[],
        headers: string[],
        headersToRead: (string | RegExp)[][]
    ): string | undefined {
        for (const headerToRead of headersToRead) {
            const readValue = this.readValuesConcatenated(values, headers, headerToRead);
            if (readValue !== undefined && readValue.length > 0) {
                return readValue;
            }
        }
        return undefined;
    }

    static readValuesConcatenated(
        values: string[],
        headers: string[],
        headersToRead: (string | RegExp)[]
    ): string | undefined {
        const readValues: string[] = [];
        for (let i = 0; i < headersToRead.length; ++i) {
            const headerToRead = headersToRead[i];
            const header =
                typeof headerToRead === 'string' ? headerToRead.toLocaleLowerCase() : headerToRead;
            const idx =
                typeof header === 'string'
                    ? headers.findIndex((x) => x.toLocaleLowerCase() === header)
                    : headers.findIndex((x) => header.test(x));
            if (idx < 0) {
                return undefined;
            }
            const value = values[idx].trim();
            if (value.length > 0) {
                readValues.push(value);
            }
        }
        return readValues.join(' ');
    }
}
