import {
    IconFile,
    IconFileMusic,
    IconFileSpreadsheet,
    IconFileText,
    IconFileUnknown,
    IconMovie,
    IconPhoto,
    IconPresentation,
    TablerIconsProps
} from '@tabler/icons-react';
import { MimetypeCategory, fileCategory } from './types';

export function isFileCategory(type: string): type is MimetypeCategory['category'] {
    return !!fileCategory.find((x) => x === type);
}

export const mimetypes: MimetypeCategory[] = [
    { extension: '.aac', mimetype: 'audio/aac', category: 'audio' },
    {
        extension: '.avif',
        mimetype: 'image/avif',
        category: 'image'
    },
    {
        extension: '.avi',
        mimetype: 'video/x-msvideo',
        category: 'video'
    },

    {
        extension: '.bin',
        mimetype: 'application/octet-stream',
        category: 'other'
    },
    {
        extension: '.bmp',
        mimetype: 'image/bmp',
        category: 'image'
    },
    {
        extension: '.bz',
        mimetype: 'application/x-bzip',
        category: 'other'
    },
    {
        extension: '.bz2',
        mimetype: 'application/x-bzip2',
        category: 'other'
    },
    {
        extension: '.csh',
        mimetype: 'application/x-csh',
        category: 'other'
    },
    {
        extension: '.css',
        mimetype: 'text/css',
        category: 'document'
    },
    {
        extension: '.csv',
        mimetype: 'text/csv',
        category: 'spreadsheet'
    },
    {
        extension: '.doc',
        mimetype: 'application/msword',
        category: 'document'
    },
    {
        extension: '.docx',
        mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        category: 'document'
    },
    {
        extension: '.eot',
        mimetype: 'application/vnd.ms-fontobject',
        category: 'other'
    },
    {
        extension: '.epub',
        mimetype: 'application/epub+zip',
        category: 'other'
    },
    {
        extension: '.gz',
        mimetype: 'application/gzip',
        category: 'other'
    },
    {
        extension: '.gif',
        mimetype: 'image/gif',
        category: 'image'
    },

    {
        extension: '.ics',
        mimetype: 'text/calendar',
        category: 'other'
    },

    {
        extension: '.jpeg',
        mimetype: 'image/jpeg',
        category: 'image'
    },
    {
        extension: '.js',
        mimetype: 'text/javascript',
        category: 'document'
    },
    {
        extension: '.json',
        mimetype: 'application/json',
        category: 'document'
    },

    { extension: '.mid', mimetype: 'audio/midi,audio/x-midi', category: 'audio' },
    { extension: '.mjs', mimetype: 'text/javascript', category: 'document' },
    { extension: '.mp3', mimetype: 'audio/mpeg', category: 'audio' },
    { extension: '.mp4', mimetype: 'video/mp4', category: 'video' },
    { extension: '.mpeg', mimetype: 'video/mpeg', category: 'video' },
    { extension: '.mpkg', mimetype: 'application/vnd.apple.installer+xml', category: 'other' },
    {
        extension: '.odp',
        mimetype: 'application/vnd.oasis.opendocument.presentation',
        category: 'presentation'
    },
    {
        extension: '.ods',
        mimetype: 'application/vnd.oasis.opendocument.spreadsheet',
        category: 'spreadsheet'
    },
    {
        extension: '.odt',
        mimetype: 'application/vnd.oasis.opendocument.text',
        category: 'document'
    },
    { extension: '.oga', mimetype: 'audio/ogg', category: 'audio' },
    { extension: '.ogv', mimetype: 'video/ogg', category: 'video' },
    { extension: '.ogx', mimetype: 'application/ogg', category: 'other' },
    { extension: '.opus', mimetype: 'audio/opus', category: 'audio' },
    { extension: '.otf', mimetype: 'font/otf', category: 'other' },
    { extension: '.png', mimetype: 'image/png', category: 'image' },
    { extension: '.pdf', mimetype: 'application/pdf', category: 'document' },
    { extension: '.php', mimetype: 'application/x-httpd-php', category: 'document' },
    { extension: '.ppt', mimetype: 'application/vnd.ms-powerpoint', category: 'presentation' },
    {
        extension: '.pptx',
        mimetype: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        category: 'presentation'
    },
    { extension: '.rar', mimetype: 'application/vnd.rar', category: 'other' },
    { extension: '.rtf', mimetype: 'application/rtf', category: 'document' },
    { extension: '.sh', mimetype: 'application/x-sh', category: 'document' },
    { extension: '.svg', mimetype: 'image/svg+xml', category: 'image' },
    { extension: '.tar', mimetype: 'application/x-tar', category: 'other' },
    { extension: '.tif', mimetype: 'image/tiff', category: 'image' },
    { extension: '.ts', mimetype: 'video/mp2t', category: 'video' },
    { extension: '.ttf', mimetype: 'font/ttf', category: 'other' },
    { extension: '.txt', mimetype: 'text/plain', category: 'document' },
    { extension: '.vsd', mimetype: 'application/vnd.visio', category: 'other' },
    { extension: '.wav', mimetype: 'audio/wav', category: 'audio' },
    { extension: '.weba', mimetype: 'audio/webm', category: 'audio' },
    { extension: '.webm', mimetype: 'video/webm', category: 'video' },
    { extension: '.webp', mimetype: 'image/webp', category: 'image' },
    { extension: '.woff', mimetype: 'font/woff', category: 'other' },
    { extension: '.woff2', mimetype: 'font/woff2', category: 'other' },
    { extension: '.xhtml', mimetype: 'application/xhtml+xml', category: 'document' },
    { extension: '.xls', mimetype: 'application/vnd.ms-excel', category: 'spreadsheet' },
    {
        extension: '.xlsx',
        mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        category: 'spreadsheet'
    },
    { extension: '.xml', mimetype: 'application/xml', category: 'document' },
    { extension: '.xul', mimetype: '', category: 'other' },
    { extension: '.zip', mimetype: 'application/zip', category: 'other' }
];

export const mimetypeMap = new Map(mimetypes.map((x) => [x.mimetype, x.category]));
export const documentTypes: {
    type: MimetypeCategory['category'];
    icon: (props: TablerIconsProps) => JSX.Element;
}[] = [
    { type: 'document', icon: IconFileText },
    { type: 'video', icon: IconMovie },
    { type: 'image', icon: IconPhoto },
    { type: 'presentation', icon: IconPresentation },
    { type: 'spreadsheet', icon: IconFileSpreadsheet },
    { type: 'audio', icon: IconFileMusic },
    { type: 'other', icon: IconFile },
    { type: 'any', icon: IconFileUnknown }
];
