import { Attachment, InquiryAttachment, SharedFileFileAsAttachment } from '../../types/Types';
import { useCallback } from 'react';
import useModal from '../../hooks/useModal';
import * as types from '../../types/Types';
import { FileBadge } from '../../atoms/FileBadge';

export default function MessageAttachment(props: {
    attachment: Attachment | InquiryAttachment | SharedFileFileAsAttachment;
    pending: boolean;
    onOpenPreview?: (fileId: types.AttachmentId) => void;
}) {
    const modalService = useModal();
    const openAttachment = useCallback(async () => {
        if (props.onOpenPreview) {
            props.onOpenPreview(props.attachment.id);
        } else {
            modalService.openAttachmentPreviewModal({ attachment: props.attachment });
        }
    }, [modalService, props]);

    return (
        <FileBadge
            name={props.attachment.name}
            onClick={openAttachment}
            mimetype={props.attachment.contentType}
        />
    );
}
