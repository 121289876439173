export type EventCallback<T, U, V> = (a1: T, a2: U, a3: V) => void;
export type EventsCallback<T, U, V> = (events: [T, U, V][]) => void;

export class Event<T, U, V> {
    private locked: boolean;
    private lockedEvents: unknown[][];
    private callbacks: {
        callback: (...args: unknown[]) => void;
        type: string;
        events: unknown[][];
    }[];

    constructor() {
        this.locked = false;
        this.lockedEvents = [];
        this.callbacks = [];
    }

    getLockedEvnts() {
        return this.lockedEvents;
    }

    setLockedEvnts(lockedEvents: any[]) {
        this.lockedEvents = lockedEvents;
    }

    add(callback: EventCallback<T, U, V>, type?: 'single'): void;
    add(callback: EventsCallback<T, U, V>, type?: 'multi'): void;
    add(callback: EventCallback<T, U, V> | EventsCallback<T, U, V>, type?: string): void {
        type = typeof type == 'undefined' ? 'single' : type;
        if (type !== 'multi' && type !== 'single') {
            throw new Error("Invalid type '" + type + "'");
        }
        this.callbacks.push({
            callback: callback as any,
            type: type,
            events: []
        });
    }

    remove(callback: EventCallback<T, U, V>, type?: 'single' | 'both'): void;
    remove(callback: EventsCallback<T, U, V>, type?: 'multi' | 'both'): void;
    remove(callback: EventCallback<T, U, V> | EventsCallback<T, U, V>, type?: string): void {
        type = typeof type == 'undefined' ? 'both' : type;
        for (const [i, cb] of this.callbacks.entries()) {
            if (cb.callback === callback && (type === 'both' || cb.type === type)) {
                this.callbacks.splice(i, 1);
                break;
            }
        }
    }

    clear(): void {
        this.callbacks = [];
    }

    hold(): void {
        this.locked = true;
    }

    release(): void {
        this.locked = false;
        for (const x of this.callbacks) {
            x.events = x.events.concat(this.lockedEvents);
        }
        this.lockedEvents = [];
        this.flush();
    }

    trigger(...args: [T?, U?, V?]): void {
        if (this.locked) {
            this.lockedEvents.push(args);
        } else {
            for (const x of this.callbacks) {
                x.events.push(args);
            }
            this.flush();
        }
    }

    flush(): void {
        if (this.locked) {
            return;
        }
        for (const c of this.callbacks) {
            if (c.events.length === 0) {
                continue;
            }
            if (c.type === 'single') {
                while (c.events.length > 0) {
                    const event = c.events.shift() as unknown[];
                    try {
                        c.callback.apply(null, event);
                    } catch (e) {
                        console.error(e);
                    }
                }
            } else if (c.type === 'multi') {
                const events = c.events;
                c.events = [];
                try {
                    c.callback.call(null, events);
                } catch (e) {
                    console.error(e);
                }
            } else {
                c.events = [];
                console.warn("Callback has invalid type '" + c.type + "'");
            }
        }
    }

    static applyEvents<T, U, V>(
        events: [T, U, V][],
        func: EventCallback<T, U, V>,
        context?: any
    ): void {
        for (const event of events) {
            func.apply(context, event);
        }
    }
}
