import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as types from '../types/Types';
import * as loaders from '../utils/router/loaders';
import { Deferred } from '../utils/Deferred';

export type LoadingState<T> =
    | { type: 'loading'; deferred: Deferred<void> }
    | { type: 'error'; error: unknown }
    | { type: 'done'; data: T };

export interface State<T> {
    state: LoadingState<T>;
    revalidate: () => void;
}

let loadId = 1;
function nextLoadId() {
    return loadId++;
}

export function useSearchParamsEx() {
    const location = useLocation();
    const navigate = useNavigate();
    const updateSearch = useCallback(
        (search: string) => {
            navigate(search, { replace: document.location.search.length > 1 });
        },
        [navigate]
    );
    const updateSearchParams = useCallback(
        (params: Record<string, string | null>) => {
            const url = new URL(`http://localhost/${document.location.search}`);
            for (const key in params) {
                const value = params[key];
                if (value === null) {
                    url.searchParams.delete(key);
                } else {
                    url.searchParams.set(key, value);
                }
            }
            const searchString = url.searchParams.toString();
            navigate(searchString ? `?${searchString}` : '', {
                replace: document.location.search.length > 1
            });
        },
        [navigate]
    );
    const navigateKeepingSearch = useCallback(
        (url: string) => {
            navigate(`${url}${document.location.search}`);
        },
        [navigate]
    );
    const searchParams = useMemo(() => {
        const url = new URL(`http://localhost/${location.search}`);
        return url.searchParams;
    }, [location.search]);
    return {
        searchParams: searchParams,
        updateSearch,
        updateSearchParams,
        navigate,
        navigateKeepingSearch
    };
}

export function modifyState<T, U>(
    state: LoadingState<T>,
    modifier: (data: T) => U
): LoadingState<U> {
    if (state.type === 'done') {
        return { type: 'done', data: modifier(state.data) };
    }
    return state;
}

export function filterLoadingState<T, U>(
    state: LoadingState<T>,
    listExtractor: (x: T) => U[],
    extractor: (x: U) => string,
    filter: string | string[] | undefined
): LoadingState<U[]> {
    return modifyState(state, (data) => loaders.filterList(listExtractor(data), extractor, filter));
}

function setStateEx<T>(
    setState: Dispatch<SetStateAction<LoadingState<T>>>,
    destroyer: ((oldState: T) => void) | undefined,
    newState: LoadingState<T>
) {
    setState((oldValue) => {
        if (oldValue.type === 'loading') {
            if (newState.type === 'done') {
                oldValue.deferred.resolve();
            } else if (newState.type === 'error') {
                oldValue.deferred.reject(newState.error);
            }
        }
        if (destroyer && oldValue.type === 'done') {
            destroyer(oldValue.data);
        }
        return newState;
    });
}

export default function useLoader<T>(
    func: (loaderService: LoaderService) => Promise<T>,
    switchToLoadingWhenFuncChange = true,
    destroyer?: (oldState: T) => void
) {
    const [loadId, setLoadId] = useState(0);
    const [currentLoadIdHolder] = useState({ loadId: loadId });
    const [state, setState] = useState<LoadingState<T>>({
        type: 'loading',
        deferred: new Deferred<void>()
    });

    const revalidate = useCallback(
        (switchToLoadingState: boolean = true) => {
            if (switchToLoadingState) {
                setStateEx(setState, destroyer, {
                    type: 'loading',
                    deferred: new Deferred<void>()
                });
            }
            setLoadId(nextLoadId());
        },
        [destroyer]
    );
    const revalidateQuiet = useCallback(() => {
        setLoadId(nextLoadId());
    }, []);
    useEffect(() => {
        if (switchToLoadingWhenFuncChange) {
            setStateEx(setState, destroyer, { type: 'loading', deferred: new Deferred<void>() });
        }
    }, [func, destroyer, switchToLoadingWhenFuncChange]);
    useEffect(() => {
        currentLoadIdHolder.loadId = loadId;
        (async () => {
            const state = await load(func);
            if (currentLoadIdHolder.loadId === loadId) {
                setStateEx(setState, destroyer, state);
            } else {
                if (destroyer && state.type === 'done') {
                    destroyer(state.data);
                }
            }
        })();
        return () => {
            setStateEx(setState, destroyer, { type: 'loading', deferred: new Deferred<void>() });
        };
    }, [loadId, func, destroyer, currentLoadIdHolder]);

    return { state, revalidate, revalidateQuiet };
}

async function load<T>(
    func: (loaderService: LoaderService) => Promise<T>
): Promise<LoadingState<T>> {
    // Loading takes at least 300 ms
    const delay = new Promise((resolve) => setTimeout(resolve, 300));
    try {
        const result = await func(loaderService);
        await delay;
        return { type: 'done', data: result };
    } catch (e) {
        await delay;
        return { type: 'error', error: e };
    }
}

const loaderService = {
    loadChat: loaders.chatLoader.loader,
    loadChats: loaders.chatsLoader.loader,
    loadChatForAdding: loaders.chatForAddingLoader.loader,
    loadChatForEditing: loaders.chatForEditingLoader.loader,
    loadMeeting: loaders.meetingLoader.loader,
    loadMeetings: loaders.meetingsLoader.loader,
    loadMeetingForAdding: loaders.meetingForAddingLoader.loader,
    loadMeetingForEditing: loaders.meetingForEditingLoader.loader,
    loadContacts: loaders.contactsLoader.loader,
    loadContact: loaders.contactLoader.loader,
    loadContactForAdding: loaders.contactForAddingLoader.loader,
    loadContactForEditing: loaders.contactForEditingLoader.loader,
    loadCompanies: loaders.companiesLoader.loader,
    loadCompany: loaders.companyLoader.loader,
    loadCompanyForAdding: loaders.companyForAddingLoader.loader,
    loadCompanyForEditing: loaders.companyForEditingLoader.loader,
    loadForm: loaders.formLoader.loader,
    loadForms: loaders.formsLoader.loader,
    loadFormAdding: () => loaders.formModelLoader.loader('new' as types.FormId),
    loadFormModel: loaders.formModelLoader.loader,
    loadMessage: loaders.messageLoader.loader,
    loadFile: loaders.fileLoader.loader,
    loadFiles: loaders.filesLoader.loader,
    loadFileContent: loaders.fileContentLoader.loader,
    loadDraft: loaders.draftLoader.loader,
    loadDrafts: loaders.draftsLoader.loader,
    loadQueries: loaders.queriesLoader.loader,
    loadAccounts: loaders.usersWithAdminDataLoader.loader,
    loadAttachmentVersions: loaders.attachmentVersionsLoader.loader,
    loadFavorites: loaders.favoritesLoader.loader,
    loadSettings: loaders.settingsLoader.loader,
    loadFeed: loaders.feedLoader.loader,
    loadFeedList: loaders.feedListLoader.loader,
    loadContactForm: loaders.contactFormLoader.loader,
    loadPublicForm: loaders.formPublicModelLoader.loader,
    loadEmailInboxForAdding: loaders.emailInboxForAddingLoader.loader,
    loadEmailInboxForEditing: loaders.emailInboxForEditingLoader.loader
};

export type LoaderService = typeof loaderService;
