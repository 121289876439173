import * as privmxVideoConferences from 'privmx-video-conferences';

export const defaultVideoConferenceSettings: Required<privmxVideoConferences.core.VideoConferenceSettings> =
    {
        containersDisplayMode: 'tiles',
        isAudioInputEnabled: true,
        isAudioOutputEnabled: true,
        isVideoInputEnabled: true,
        showLocalParticipant: true,
        videoResolution: { width: 640, height: 360 }
    };
