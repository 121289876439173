import { useAppDispatch, useAppSelector } from '../../../store';
import { shareAccessModalState, toggleShareAccessModal } from '../../../store/ModalsSlice';
import {
    Box,
    Flex,
    Title,
    Text,
    Modal,
    Group,
    Button,
    CopyButton,
    Code,
    ActionIcon,
    rem,
    Space
} from '@mantine/core';
import { useCallback, useState } from 'react';
import { t } from 'i18next';
import { IconCopy, IconEye, IconEyeOff } from '@tabler/icons-react';

export function ShareAccessModal() {
    const modalState = useAppSelector(shareAccessModalState);
    const pass = modalState.temporaryPassword;
    const dispatch = useAppDispatch();

    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

    const onActionIconClick = useCallback(async () => {
        setPasswordVisible((prev) => !prev);
    }, []);

    const closeModal = useCallback(
        function () {
            dispatch(toggleShareAccessModal({ opened: false }));
        },
        [dispatch]
    );

    return (
        <Modal
            keepMounted={true}
            transitionProps={{
                duration: 200,
                transition: 'slide-up',
                timingFunction: 'ease-in'
            }}
            size="auto"
            centered
            onClose={closeModal}
            opened={modalState.opened}>
            <Flex
                p="xl"
                pt={'xs'}
                h="100%"
                pos="relative"
                miw={600}
                maw={'60vw'}
                direction={'column'}>
                <Box sx={{ flexGrow: 1 }} w="100%" mb="lg">
                    <Title order={2} mb="xs">
                        {t('modal.shareAccess.title')}
                    </Title>
                    <Text color="dimmed">{t('modal.shareAccess.info')}</Text>
                    <Space h="md"></Space>
                    <Flex mb="md" align="center" justify="center">
                        <CopyButton value={pass || ''}>
                            {({ copied, copy }) => (
                                <>
                                    <Code color={copied ? 'green' : undefined}>
                                        {passwordVisible ? pass || '' : '***-***'}
                                    </Code>
                                    <ActionIcon
                                        ml={4}
                                        size={'sm'}
                                        color={copied ? 'green' : undefined}
                                        onClick={onActionIconClick}>
                                        {passwordVisible ? (
                                            <IconEyeOff size={rem(16)} />
                                        ) : (
                                            <IconEye size={rem(16)} />
                                        )}
                                    </ActionIcon>
                                    <ActionIcon
                                        ml={'xs'}
                                        color={copied ? 'green' : undefined}
                                        onClick={copy}
                                        size={'sm'}>
                                        <IconCopy size={rem(16)} />
                                    </ActionIcon>
                                </>
                            )}
                        </CopyButton>
                    </Flex>
                </Box>
                <Group mx="auto">
                    <Button variant="light" onClick={closeModal}>
                        Close
                    </Button>
                </Group>
            </Flex>
        </Modal>
    );
}
