import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../../api/Api';
import * as types from '../../types/Types';
import ModalForm from '../ModalForm';
import useToast from '../../hooks/useToast';
import { usePreventClose } from '../../hooks/usePreventClose';
import { useTranslation } from 'react-i18next';

type FormData = Pick<types.SharedFileUserData, 'title' | 'note' | 'password'> & { file: File };

export function SharedFileEditPage(props: {
    sharedFile?: types.SharedFile;
    sharedFileSetFileModel?: types.SharedFileSetFileModel;
    handleClose: () => void;
}) {
    const { t } = useTranslation();
    const mode: 'add' | 'edit' = props.sharedFile ? 'edit' : 'add';
    const [isProcessing, setIsProcessing] = useState(false);
    const [sharedFileSetFileModel, setSharedFileSetFileModel] = useState<
        types.SharedFileSetFileModel | undefined
    >(props.sharedFileSetFileModel);

    const form = useForm<FormData>({
        defaultValues: { ...(props.sharedFile?.userData ?? {}) }
    });
    const setError = form.setError;
    const errors = form.formState.errors;
    usePreventClose(form.formState.isDirty);

    const toast = useToast();

    const handleClose = props.handleClose;
    const handleFormSubmit = useCallback(
        async (data: FormData) => {
            if (data.title.trim() === '') {
                setError('title', {});
                return;
            }
            if (mode === 'add' && !sharedFileSetFileModel) {
                setError('file', {});
                return;
            }
            setIsProcessing(true);
            let success = false;
            try {
                const password = (data.password ?? '').trim();
                const note = data.note.trim();
                const title = data.title.trim();
                const permission: types.SharedFile['permission'] =
                    password.length > 0 ? 'password' : 'no';
                if (mode === 'add') {
                    await api.createSharedFile(
                        permission,
                        {
                            title: title,
                            note: note,
                            password: password
                        },
                        sharedFileSetFileModel!
                    );
                } else {
                    await api.updateSharedFile(
                        props.sharedFile!,
                        permission,
                        {
                            title: title,
                            note: note,
                            password: password,
                            fileMetaEncKeyStr: props.sharedFile!.userData.fileMetaEncKeyStr
                        },
                        sharedFileSetFileModel
                    );
                }
                success = true;
            } catch (error: any) {
                console.trace(error);
                if (typeof error.msg === 'string') {
                    toast(error.msg, 'error');
                } else {
                    toast(`Could not ${mode === 'add' ? 'create' : 'update'} shared file`, 'error');
                }
            } finally {
                setIsProcessing(false);
            }
            if (success) {
                toast(`Shared file ${mode === 'add' ? 'created' : 'updated'}`, 'success');
                handleClose();
            }
        },
        [props.sharedFile, handleClose, setError, toast, mode, sharedFileSetFileModel]
    );

    const handleFileChange = useCallback((file: File | null) => {
        if (file) {
            setSharedFileSetFileModel({
                type: 'uploadNewFile',
                file: file
            });
        } else {
            setSharedFileSetFileModel(undefined);
        }
    }, []);

    const fixedFile =
        mode === 'add' && props.sharedFileSetFileModel ? props.sharedFileSetFileModel : null;

    return (
        <ModalForm
            onSubmit={handleFormSubmit}
            form={form}
            isProcesing={isProcessing}
            onClose={props.handleClose}>
            <ModalForm.TextField
                required
                error={!!errors.title}
                name="title"
                label={t('entityProps.title')}
            />
            <ModalForm.TextField name="password" label={t('entityProps.optionalPassword')} />
            <ModalForm.TextareaField name="note" span={2} label={t('entityProps.note')} />
            <>
                {fixedFile === null && (
                    <ModalForm.FormFileField
                        name="file"
                        error={!!errors.file}
                        onChange={(file) => handleFileChange(file)}
                        label={
                            mode === 'add' ? t('entityProps.file') : t('entityProps.newFileVersion')
                        }
                    />
                )}
                {fixedFile !== null && (
                    <ModalForm.TextField
                        name="x"
                        label={t('entityProps.file')}
                        disabled
                        value={
                            fixedFile.type === 'copyAttachment'
                                ? fixedFile.attachmentToCopy.name
                                : fixedFile.file.name
                        }
                    />
                )}
            </>
        </ModalForm>
    );
}
