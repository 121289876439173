import { VideoConference } from "../VideoConference";

interface Speaker {
    id: string;
    audioLevel: number;
    isSharingDesktop: boolean;
    isLocal: boolean;
}

type OnUpdateHandler = (dominantSpeakerId: string | null) => void;

type SpeakersMap = { [id: string]: Speaker };

export class CustomDominantSpeakerService {
    
    private static readonly MIN_DELAY_BETWEEN_DOMINANT_PARTICIPANT_CHANGES_MS: number = 1000;
    
    private onUpdateHandlers: OnUpdateHandler[] = [];
    private speakers: { [speakerId: string]: Speaker } = {};
    private dominantSpeakerId: string | null = null;
    private lastDominantSpeakerChangeTimestamp: number = 0;
    
    constructor() {
    }
    
    getDominantSpeakerId(): string | null {
        return this.dominantSpeakerId;
    }
    
    addOnUpdateHandler(handler: OnUpdateHandler): void {
        this.onUpdateHandlers.push(handler);
    }
    
    removeOnUpdateHandler(handler: OnUpdateHandler): void {
        const index = this.onUpdateHandlers.indexOf(handler);
        if (index >= 0) {
            this.onUpdateHandlers.splice(index, 1);
        }
    }
    
    setSpeaker(speaker: Speaker): void {
        this.speakers[speaker.id] = speaker;
        this.updateDominantSpeaker();
    }
    
    removeSpeaker(speakerId: string): void {
        if (speakerId in this.speakers) {
            delete this.speakers[speakerId];
            this.updateDominantSpeaker();
        }
    }
    
    setSpeakerAudioLevel(speakerId: string, audioLevel: number): void {
        this.updateSpeaker(speakerId, speaker => speaker.audioLevel = audioLevel);
    }
    
    setSpeakerIsSharingDesktop(speakerId: string, isSharingDesktop: boolean): void {
        this.updateSpeaker(speakerId, speaker => speaker.isSharingDesktop = isSharingDesktop);
    }
    
    private updateSpeaker(speakerId: string, propertyUpdater: (speaker: Speaker) => void): void {
        const speaker = this.speakers[speakerId];
        if (speaker) {
            propertyUpdater(speaker);
            this.updateDominantSpeaker();
        }
    }
    
    private updateDominantSpeaker(): void {
        if (!this.canUpdateDominantSpeakerNow()) {
            return;
        }
        
        const newDominantSpeakerId = this.determineDominantSpeakerId();
        if (newDominantSpeakerId && newDominantSpeakerId != this.dominantSpeakerId) {
            this.dominantSpeakerId = newDominantSpeakerId;
            this.lastDominantSpeakerChangeTimestamp = Date.now();
            this.callOnUpdateHandlers();
        }
    }
    
    private callOnUpdateHandlers(): void {
        for (const handler of this.onUpdateHandlers) {
            handler(this.dominantSpeakerId);
        }
    }
    
    private determineDominantSpeakerId(): string | null {
        const remoteSpeakersWithSharedDesktop = this.getRemoteSpeakersWithSharedDesktop();
        const speakers = Object.keys(remoteSpeakersWithSharedDesktop).length > 0 ? remoteSpeakersWithSharedDesktop : this.speakers;
        const remoteSpeakerWithHighestAudioLevel = this.getRemoteSpeakerWithHighestAudioLevel(speakers);
        if (remoteSpeakerWithHighestAudioLevel) {
            return remoteSpeakerWithHighestAudioLevel.id;
        }
        
        return null;
    }
    
    private canUpdateDominantSpeakerNow(): boolean {
        const currentTimestamp = Date.now();
        const allowUpdateAfterTimestamp = this.lastDominantSpeakerChangeTimestamp + CustomDominantSpeakerService.MIN_DELAY_BETWEEN_DOMINANT_PARTICIPANT_CHANGES_MS;
        return currentTimestamp >= allowUpdateAfterTimestamp;
    }
    
    private getRemoteSpeakersWithSharedDesktop(): SpeakersMap {
        const speakersWithSharedDesktop: SpeakersMap = {};
        
        for (const speakerId in this.speakers) {
            const speaker = this.speakers[speakerId]!;
            if (!speaker.isLocal && speaker.isSharingDesktop) {
                speakersWithSharedDesktop[speaker.id] = speaker;
            }
        }
        
        return speakersWithSharedDesktop;
    }
    
    private getRemoteSpeakerWithHighestAudioLevel(speakers: SpeakersMap): Speaker | null {
        let remoteSpeakerWithHighestAudioLevel: Speaker | null = null;
        
        const audioLevelThreshold = VideoConference.PARTICIPANT_TALKING_AUDIO_LEVEL_THRESHOLD;
        for (const speakerId in speakers) {
            const speaker = speakers[speakerId]!;
            if (!speaker.isLocal && speaker.audioLevel >= audioLevelThreshold) {
                if (!remoteSpeakerWithHighestAudioLevel || remoteSpeakerWithHighestAudioLevel.audioLevel < speaker.audioLevel) {
                    remoteSpeakerWithHighestAudioLevel = speaker;
                }
            }
        }
        
        return remoteSpeakerWithHighestAudioLevel;
    }
    
}
