import * as PmxApi from 'privmx-server-api';
import { BaseApi } from './BaseApi';

export class SinkApi extends BaseApi implements PmxApi.api.sink.ISinkApi {
    sinkGet(model: { sid: PmxApi.api.sink.Sid }): Promise<PmxApi.api.sink.Sink> {
        return this.request('sinkGet', model);
    }

    sinkGetAllMy(): Promise<PmxApi.api.sink.Sink[]> {
        return this.request('sinkGetAllMy', {});
    }

    sinkCreate(model: PmxApi.api.sink.CreateSinkModel): Promise<PmxApi.api.core.OK> {
        return this.request('sinkCreate', model);
    }

    sinkSave(model: {
        sid: PmxApi.api.sink.Sid;
        acl: PmxApi.api.sink.SinkAcl;
        data: PmxApi.api.sink.SinkData;
        options: PmxApi.api.sink.SinkOptions;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('sinkSave', model);
    }

    sinkUpdateOptions(model: {
        sid: PmxApi.api.sink.Sid;
        options: PmxApi.api.sink.SinkOptions;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('sinkUpdateOptions', model);
    }

    sinkDelete(model: { sid: PmxApi.api.sink.Sid }): Promise<PmxApi.api.core.OK> {
        return this.request('sinkDelete', model);
    }

    sinkSetLastSeenSeq(model: {
        sid: PmxApi.api.sink.Sid;
        lastSeenSeq: PmxApi.api.sink.Seq;
    }): Promise<PmxApi.api.core.OK> {
        return this.request('sinkSetLastSeenSeq', model);
    }

    sinkPoll(model: {
        sinks: PmxApi.api.sink.SinkCursor[];
        updateLastSeen: boolean;
    }): Promise<PmxApi.api.sink.SinkPollResultRaw> {
        return this.request('sinkPoll', model);
    }

    sinkWait(model: {
        sinks: PmxApi.api.sink.SinkCursor[];
        updateLastSeen: boolean;
    }): Promise<PmxApi.api.sink.SinkPollResultRaw> {
        return this.request('sinkWait', model);
    }

    sinkClear(model: {
        sid: PmxApi.api.sink.Sid;
        currentModSeq: PmxApi.api.sink.ModSeq;
    }): Promise<PmxApi.api.sink.SinkPosition> {
        return this.request('sinkClear', model);
    }

    sinkInfo(model: {
        sid: PmxApi.api.sink.Sid;
        addMidList: boolean;
    }): Promise<PmxApi.api.sink.SinkInfo> {
        return this.request('sinkInfo', model);
    }

    sinkMods(model: {
        sid: PmxApi.api.sink.Sid;
        seq: PmxApi.api.sink.Seq;
        modSeq: PmxApi.api.sink.ModSeq;
    }): Promise<PmxApi.api.sink.SinkMods> {
        return this.request('sinkMods', model);
    }

    sinkInfoAndMods(model: {
        sid: PmxApi.api.sink.Sid;
        addMidList: boolean;
        seq: PmxApi.api.sink.Seq;
        modSeq: PmxApi.api.sink.ModSeq;
    }): Promise<PmxApi.api.sink.SinkInfo & PmxApi.api.sink.SinkMods> {
        return this.request('sinkInfoAndMods', model);
    }

    sinkQuery(model: {
        sid: PmxApi.api.sink.Sid;
        query: PmxApi.api.sink.SinkQuery;
        limit: number;
        order: PmxApi.api.sink.QueryOrder;
    }): Promise<PmxApi.api.message.Mid[]> {
        return this.request('sinkQuery', model);
    }

    sinkQueryAndInfo(
        model: PmxApi.api.sink.SinkQueryAndInfoModel
    ): Promise<PmxApi.api.sink.SinkQueryAndInfoResult> {
        return this.request('sinkQueryAndInfo', model);
    }

    sinkGetAllByUser(model: {
        username: PmxApi.api.core.Username;
    }): Promise<PmxApi.api.sink.Sink[]> {
        return this.request('sinkGetAllByUser', model);
    }
}
